import { useEffect, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans, t } from '@lingui/macro';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Button, MenuItem, MenuList, Popover, useTheme } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { App_Theme, Dom_Size, Transaction_Type } from 'config/constants';
import { getContractAddress } from 'config/contracts';

import { UniLiquidityTokenRender } from 'hooks/V3/types';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { ApprovalState } from 'hooks/useApproval';
import useApprovalERC721 from 'hooks/useApprovalERC721';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { useSubmitStakeLpToken } from 'hooks/useSubmitStakeLpToken';
import { cloneDeep, debounce } from 'lodash';
import { selectValidMyUniPositions } from 'state/earn/stake/selector';
import { earnStakeBaseState } from 'state/earn/stake/slice';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { formatNumber, isPositive, shortenSymbol } from 'utils';

import Dialog, { DialogProps } from 'components/Common/Dialog';
import NoData from 'components/NoData';
import Warning from 'components/Svg/Icons/Warning';

import { StyledRadio } from './StakeDialog';
import { AddLiquidityGuide } from './StakeLpTokenList';

export default function StakeLpDialog(props: DialogProps) {
	const { open, onClose } = props;

	const { appTokenBalance, appToken, signingMap, nativeSymbol } =
		useAppSelector(txBaseState);
	const { stakeStatistic } = useAppSelector(earnStakeBaseState);
	const validMyUniPositions = useAppSelector(selectValidMyUniPositions);

	const theme = useTheme();
	const { currentChainId } = useCurrentChain();

	const [period, setPeriod] = useState<number | undefined>(undefined);
	const [multiplier, setMultiplier] = useState<number | undefined>(undefined);
	const [currentLiquidityToken, setCurrentLiquidityToken] =
		useState<UniLiquidityTokenRender | null>(null);

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleShowPop = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClosePop = () => {
		setAnchorEl(null);
	};

	const [nonfungiblePositionManagerAddress, feeDistributorContractAddress] =
		useMemo(() => {
			return [
				getContractAddress(currentChainId, 'NonfungiblePositionManager'),
				getContractAddress(currentChainId, 'FeeDistributor')
			];
		}, [currentChainId]);

	const { approvalState, approveCallback } = useApprovalERC721(
		nonfungiblePositionManagerAddress,
		currentLiquidityToken?.id,
		feeDistributorContractAddress
	);

	const { onConfirm, isConfirming, isConfirmed } = useSubmitStakeLpToken(
		currentLiquidityToken,
		period,
		multiplier,
		approvalState
	);

	const [periods, multipliers] = useMemo(() => {
		if (!stakeStatistic) {
			return [null, null];
		}
		const _stakeStatistic = cloneDeep(stakeStatistic);
		return [
			_stakeStatistic.periods.reverse(),
			_stakeStatistic.multipliers.reverse()
		];
	}, [stakeStatistic]);

	useEffect(() => {
		if (periods && multipliers && !period) {
			setPeriod(periods[0]);
			setMultiplier(multipliers[0]);
		}
	}, [periods, multipliers]);

	useUpdateEffect(() => {
		if (isConfirmed && onClose) {
			onClose();
		}
	}, [isConfirmed]);

	const [disabled, submitText] = useMemo(() => {
		if (!currentLiquidityToken || !isPositive(period)) {
			return [true, t`Confirm`];
		}
		if (!isPositive(currentLiquidityToken.baseAmount)) {
			return [true, t`Ivalid NFT`];
		}
		if (approvalState === ApprovalState.PENDING) {
			return [true, t`Approving...`];
		}
		if (approvalState === ApprovalState.NOT_APPROVED) {
			return [false, t`Approve NFT`];
		}
		if (signingMap.has(Transaction_Type.StakeLpToken)) {
			return [true, t`Loading...`];
		}
		if (isConfirming) {
			return [true, t`Submitting...`];
		}
		return [false, t`Confirm`];
	}, [
		signingMap,
		isConfirming,
		appToken,
		appTokenBalance,
		approvalState,
		currentLiquidityToken
	]);

	const onHandleSetLiquidityToken = (item: UniLiquidityTokenRender) => {
		setCurrentLiquidityToken(item);
		handleClosePop();
	};

	const onHandleConfirm = debounce(() => {
		if (approvalState === ApprovalState.NOT_APPROVED) {
			approveCallback();
			return;
		}
		onConfirm();
	}, 300);

	const onHandleSelectDuration = (index: number) => {
		if (stakeStatistic) {
			setPeriod(periods[index]);
			setMultiplier(multipliers[index]);
		}
	};
	const { isMatchMobile, isMatchPad, isMatchPc } = useAppBreakpoints();

	return (
		<Dialog
			open={open}
			width={isMatchMobile ? '100%' : Dom_Size.DIALOG_WIDTH_LARGE}
			onClose={onClose}
			title='Stake LP NFT'
			disabled={disabled}
			confirmLabel={submitText}
			onConfirm={onHandleConfirm}
		>
			<div className='space-y-4'>
				<section className='space-y-1 relative'>
					<div className='flex items-center space-x-1'>
						<Warning size={16} />
						<Typography variant='body2' color='secondary'>
							<Trans>
								Only{' '}
								<Typography
									component='span'
									variant='body2'
									color='text.primary'
								>
									<Trans>full range</Trans>
								</Typography>{' '}
								{shortenSymbol(appToken)}/{nativeSymbol} LP NFTs are acceptable.
							</Trans>
						</Typography>
					</div>
					<Button
						className='flex items-center justify-between w-full px-4'
						onClick={handleShowPop}
						color='inherit'
						sx={{
							height: 44,
							backgroundColor:
								theme.palette.mode === App_Theme.dark
									? theme.palette.background.paper
									: theme.custom.cardBg,
							color: theme.palette.text.primary
						}}
					>
						{currentLiquidityToken ? (
							<Typography variant='body2'>
								{`#${currentLiquidityToken.id}`}
							</Typography>
						) : (
							<Typography variant='body2'>
								<Trans>Select NFT</Trans>
							</Typography>
						)}
						<div className='flex items-center'>
							{currentLiquidityToken && (
								<div className='flex items-center space-x-1'>
									<Typography variant='body2' color='secondary'>
										<Trans>Est. Stake</Trans>
									</Typography>
									<Typography variant='body2'>
										{currentLiquidityToken
											? `${formatNumber(
													currentLiquidityToken.baseAmount,
													appToken.positionUnits
											  )} ${shortenSymbol(appToken)}`
											: '-'}
									</Typography>
								</div>
							)}
							{anchorEl ? (
								<ArrowDropUp
									sx={{ width: 20, color: theme.palette.secondary.main }}
								/>
							) : (
								<ArrowDropDown
									sx={{ width: 20, color: theme.palette.secondary.main }}
								/>
							)}
						</div>
					</Button>
					<Popover
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center'
						}}
						open={Boolean(anchorEl)}
						anchorEl={anchorEl}
						onClose={handleClosePop}
						sx={{
							'&>.MuiPaper-root': {
								width:
									isMatchPc || isMatchPad
										? Dom_Size.DIALOG_WIDTH_LARGE -
										  2 * Dom_Size.DIALOG_PADDING_BASE
										: `calc(100% - ${Dom_Size.DIALOG_PADDING_BASE}px)`,
								backgroundColor: `${
									theme.palette.mode === App_Theme.dark
										? '#212227'
										: theme.custom.cardBg
								} !important`,
								marginTop: '4px',
								marginLeft: isMatchMobile ? '-4px' : 'auto'
							}
						}}
					>
						{validMyUniPositions && validMyUniPositions.length > 0 ? (
							<MenuList className='max-h-[180px]'>
								{validMyUniPositions.map(
									(item: UniLiquidityTokenRender, index: number) => (
										<MenuItem
											key={index}
											className='flex justify-between'
											onClick={() => onHandleSetLiquidityToken(item)}
											sx={{
												backgroundColor:
													currentLiquidityToken &&
													currentLiquidityToken.id === item.id
														? theme.palette.action.hover
														: 'inherit'
											}}
										>
											<Typography variant='body2'>{`#${item.id}`}</Typography>
											<div className='flex space-x-1'>
												<Typography variant='body2' color='secondary'>
													<Trans>Est. Stake</Trans>
												</Typography>
												<Typography variant='body2'>{`${formatNumber(
													item.baseAmount,
													item.baseToken?.positionUnits
												)} ${shortenSymbol(item.baseToken)}`}</Typography>
											</div>
										</MenuItem>
									)
								)}
							</MenuList>
						) : (
							<NoData size='large'>
								<div className='py-8 px-8 space-y-2'>
									<Typography variant='body2' align='center'>
										<Trans>
											There are currently no LP NFTs available for staking.
										</Trans>
									</Typography>
									<div className='flex text-center'>
										<AddLiquidityGuide />
									</div>
								</div>
							</NoData>
						)}
					</Popover>
				</section>
				<section className='space-y-1'>
					{periods && multipliers && period && (
						<>
							<Typography color='text.secondary' variant='body1'>
								<Trans>Lock Duration</Trans>
							</Typography>
							<RadioGroup row className='grid grid-cols-3 gap-2' value={period}>
								{periods.map((_period: number, index: number) => {
									return (
										<StyledRadio
											key={index}
											value={_period}
											control={<Radio size='small' />}
											onClick={() => onHandleSelectDuration(index)}
											label={
												<Typography
													color={
														period === _period
															? 'text.primary'
															: 'text.secondary'
													}
													align='center'
												>
													<div>
														<Trans>{_period} days</Trans>
													</div>
													<div className='flex space-x-1'>
														<Typography
															variant='body2'
															color={
																period === _period
																	? 'inherit'
																	: 'text.secondary'
															}
														>
															<Trans>Multiplier</Trans>
														</Typography>
														<Typography variant='body2' color='text.primary'>
															{multipliers[index]}x
														</Typography>
													</div>
												</Typography>
											}
										/>
									);
								})}
							</RadioGroup>
						</>
					)}
				</section>
			</div>
		</Dialog>
	);
}
