import * as React from 'react';

import Decimal from 'decimal.js';
import momentTz from 'moment-timezone';

import PropTypes from 'prop-types';

import { widget } from '../../charting_library';
import { Dom_Size } from '../../config/constants';
import Datafeed from './api/';
import getProps from './refactorProps';

const timeZone = momentTz.tz.guess();

const localeMap = {
	en: 'en',
	ja: 'ja',
	ko: 'ko',
	fr: 'fr',
	de: 'de',
	'zh-HK': 'zh_TW',
	vn: 'vi',
	portuguese: 'pt',
	arabic: 'ar',
	turkish: 'tr',
	filipino: 'en',
	spanish: 'es',
	thai: 'th'
};

export default class TVChartContainer extends React.PureComponent {
	static defaultProps = {
		symbol: 'AAPL',
		interval: '15',
		containerId: 'tv_chart_container',
		libraryPath: '/charting_library/',
		fullscreen: false,
		autosize: true,
		studiesOverrides: {}
	};
	tvWidget = null;
	datafeed = null;

	componentDidUpdate(prevProps, prevState) {
		if (
			this.tvWidget &&
			this.props.symbol &&
			this.props.symbol !== prevProps.symbol
		) {
			this.rerenderTv();
		}
		if (
			this.tvWidget?._ready &&
			this.props.theme &&
			this.props.themes &&
			this.props.theme !== prevProps.theme
		) {
			new Promise((resolve, reject) =>
				resolve(
					this.tvWidget.changeTheme(
						this.props.theme === 'dark' ? 'Dark' : 'Light'
					)
				)
			).then(resp => {
				this.tvWidget?.applyOverrides({
					...getProps(this.props.themes),
					'mainSeriesProperties.minTick': `${new Decimal(10)
						.pow(this.props.pricescale)
						.toNumber()}, 1, true`
				});
			});
		}
		if (this.props.pricescale !== prevProps.pricescale) {
			this.rerenderTv();
		}
		if (this.props.locale !== prevProps.locale) {
			this.rerenderTv();
		}
	}

	rerenderTv() {
		const widgetOptions = {
			symbol: this.props.symbol,
			datafeed: Datafeed(this.props.baseUrl, {
				isGraph: this.props.isGraph,
				graphUrl: this.props.graphUrl,
				pricescale: this.props.pricescale,
				pericesion: this.props.pricescale,
				chainId: this.props.chainId
			}),
			debug: false,
			interval: this.props.interval,
			container: `tv_chart_container`,
			library_path: this.props.libraryPath,
			fullscreen: this.props.fullscreen,
			studies_overrides: this.props.studiesOverrides,
			height: this.props.height,
			//autosize: true,
			theme: this.props.theme === 'dark' ? 'Dark' : 'Light',
			width: '100%',
			custom_css_url: '/tv.css',
			timezone: timeZone,
			favorites: {
				intervals: ['1', '5', '15', '30', '60', '240', '1D']
			},
			locale: localeMap[this.props.locale || 'en'],
			disabled_features: [
				'left_toolbar',
				'border_around_the_chart',
				'header_symbol_search',
				'symbol_search_hot_key',
				'header_compare',
				'header_undo_redo',
				'go_to_date',
				'timeframes_toolbar',
				'header_saveload',
				'context_menus.legend_context_menu',
				'hide_last_na_study_output',
				'header_screenshot',
				'create_volume_indicator_by_default',
				'main_series_scale_menu',
				'save_chart_properties_to_local_storage',
				//"move_logo_to_main_pane",
				'hide_unresolved_symbols_in_legend',
				'hide_last_na_study_output',
				'chart_property_page_background',
				'custom_resolutions',
				this.props.isMatchMobile ? 'header_fullscreen_button' : ''
			].filter(item => item),
			// 使用项
			enabled_features: [
				'adaptive_logo',
				'header_screenshot',
				'hide_left_toolbar_by_default'
			],
			loading_screen: {
				backgroundColor:
					window.innerWidth > 768
						? this.props.themes.palette.background.paper
						: this.props.themes.palette.background.default,
				foregroundColor:
					window.innerWidth > 768
						? this.props.themes.palette.background.paper
						: this.props.themes.palette.background.default
			}
		};
		const tvWidget = new widget(widgetOptions);
		tvWidget.onChartReady(() => {
			tvWidget?.applyOverrides({
				...getProps(this.props.themes),
				'mainSeriesProperties.minTick': `${new Decimal(10)
					.pow(this.props.pricescale > 16 ? 16 : this.props.pricescale)
					.toString()}, 1, true`
			});
			tvWidget?.applyStudiesOverrides({
				'volume.precision': this.props.pricescale > 6 ? 4 : 2,
				'volume.volume.color.0': this.props.themes.palette.error.main,
				'volume.volume.color.1': this.props.themes.palette.success.main,
				'volume.volume ma.color': this.props.themes.palette.primary.main
			});
			tvWidget.activeChart().dataReady(async () => {
				if (!this.props.isMatchMobile) {
					await tvWidget.activeChart().createStudy('Volume', false, false);
					tvWidget.activeChart().getPanes()[1]?.setHeight(60);
				}
			});
			tvWidget.activeChart().setTimezone(timeZone);

			tvWidget
				.activeChart()
				.onIntervalChanged()
				.subscribe(null, (interval, timeframeObj) => {
					this.props.onChangeInterval(interval);
				});
		});
		this.tvWidget = tvWidget;
	}
	componentDidMount() {
		this.rerenderTv();
	}

	componentWillUnmount() {
		if (this.tvWidget !== null) {
			this.tvWidget.remove();
			this.tvWidget = null;
		}
	}

	render() {
		return (
			<>
				<div id={`tv_chart_container`} className='TVChartContainer h-full' />
			</>
		);
	}
}

TVChartContainer.propTypes = {
	baseUrl: PropTypes.string,
	name: PropTypes.string,
	symbol: PropTypes.string,
	interval: PropTypes.string,
	containerId: PropTypes.string,
	datafeedUrl: PropTypes.string,
	libraryPath: PropTypes.string,
	fullscreen: PropTypes.bool,
	autosize: PropTypes.bool,
	studiesOverrides: PropTypes.object,
	enabled_features: PropTypes.array,
	theme: PropTypes.string,
	themes: PropTypes.object,
	pricescale: PropTypes.number,
	timezone: PropTypes.string,
	isMatchMobile: PropTypes.bool,
	locale: PropTypes.string,
	currentprice: PropTypes.string,
	isGraph: PropTypes.bool,
	graphUrl: PropTypes.string,
	chainId: PropTypes.number,
	onChangeInterval: PropTypes.func
};
