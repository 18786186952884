import { Chain } from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { arbitrum, arbitrumGoerli } from 'config/constants/connector/chains';

export enum ExplorerDataType {
	NFT = 'nft',
	TRANSACTION = 'transaction',
	TOKEN = 'token',
	ADDRESS = 'address',
	BLOCK = 'block'
}

/**
 * Return the explorer link for the given hash and hash type
 * @param chain the chain for which to return the hash
 * @param hash the hash to return a link for
 * @param type the type of the hash
 */

const ETHERSCAN_PREFIXES: Record<number, string> = {
	[mainnet.id]: mainnet.blockExplorers.etherscan.url,
	[arbitrum.id]: arbitrum.blockExplorers.etherscan.url,
	[arbitrumGoerli.id]: arbitrumGoerli.blockExplorers.etherscan.url
};

export function getNFTUrl(
	chain: Chain,
	prefix: string,
	hash: string,
	tokenId: string
) {
	if (chain.id === arbitrumGoerli.id || chain.id === arbitrum.id) {
		return `${prefix}/token/${hash}?a=${tokenId}`;
	}
	return `${prefix}/nft/${hash}/${tokenId}`;
}
export function getExplorerLink(
	chain: Chain,
	hash: string,
	type: ExplorerDataType,
	tokenId?: string
): string {
	let prefix =
		ETHERSCAN_PREFIXES[chain.id] || mainnet.blockExplorers.default.url;
	if (prefix.endsWith('/')) prefix = prefix.slice(0, prefix.length - 1);

	switch (type) {
		case ExplorerDataType.TRANSACTION: {
			return `${prefix}/tx/${hash}`;
		}
		case ExplorerDataType.NFT: {
			return getNFTUrl(chain, prefix, hash, tokenId);
		}

		case ExplorerDataType.TOKEN: {
			return `${prefix}/token/${hash}`;
		}

		case ExplorerDataType.BLOCK: {
			return `${prefix}/block/${hash}`;
		}

		case ExplorerDataType.ADDRESS: {
			return `${prefix}/address/${hash}`;
		}
		default: {
			return `${prefix}`;
		}
	}
}
