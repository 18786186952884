import { SvgProps } from 'components/Svg/types';

// hover状态
export function PoolsActive({ fill = 'white' }: SvgProps) {
	return (
		<svg
			width='27'
			height='25'
			viewBox='0 0 27 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M25.6889 10.8492C24.7238 12.223 22.8598 13.3815 20.4546 14.1437V21.2384C23.6194 20.0671 25.6968 18.096 25.6968 15.8619C25.6968 15.7824 25.6941 15.7033 25.6889 15.6245V10.8492ZM18.9546 21.7113V14.5509C17.3591 14.9163 15.5876 15.1198 13.7221 15.1198C12.2249 15.1198 10.7883 14.9887 9.45464 14.7481V21.9492C10.7736 22.2194 12.2033 22.3673 13.6968 22.3673C15.5826 22.3673 17.3669 22.1315 18.9546 21.7113ZM7.95464 21.5756L7.95464 14.4206C5.03972 13.6677 2.77625 12.3617 1.70506 10.7764V15.618C1.69955 15.6989 1.69678 15.7803 1.69678 15.8619C1.69678 18.3271 4.22611 20.472 7.95464 21.5756Z'
				fill={fill}
			/>
			<ellipse cx='13.6968' cy='7.71582' rx='12' ry='5.58789' fill={fill} />
		</svg>
	);
}

export function Pools({ fill = 'white' }: SvgProps) {
	return (
		<svg
			width='27'
			height='25'
			viewBox='0 0 27 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M24.1972 8.59887C24.1972 9.59887 23.4372 10.8089 21.4672 11.8789C19.0519 13.0651 16.3874 13.6549 13.6972 13.5989C11.0067 13.6577 8.34153 13.0677 5.92719 11.8789C3.99719 10.8089 3.19719 9.57887 3.19719 8.59887C3.19719 7.61887 3.99719 6.37887 5.92719 5.30887C8.34153 4.12003 11.0067 3.53007 13.6972 3.58887C16.3874 3.53281 19.0519 4.12263 21.4672 5.30887C23.4372 6.37887 24.1972 7.60887 24.1972 8.59887ZM1.70719 8.83887V15.5789C1.70221 15.6588 1.70221 15.7389 1.70719 15.8189C1.70719 19.4189 7.07719 22.3289 13.7072 22.3289C20.3372 22.3289 25.7072 19.4189 25.7072 15.8189C25.7072 15.7389 25.7072 15.6689 25.7072 15.5889V8.82887C25.7072 8.74887 25.7072 8.66887 25.7072 8.59887C25.7072 4.99887 20.3272 2.08887 13.7072 2.08887C7.08719 2.08887 1.69719 4.99887 1.69719 8.59887C1.69553 8.679 1.69887 8.75915 1.70719 8.83887ZM3.20719 15.5789V11.8689C3.91863 12.5344 4.73232 13.0813 5.61719 13.4889C6.44664 13.8804 7.31026 14.1951 8.19719 14.4289V20.0489C7.4111 19.8077 6.65074 19.4895 5.92719 19.0989C3.99719 17.9989 3.19719 16.8089 3.19719 15.8189V15.5789H3.20719ZM9.69719 20.4289C11.013 20.7006 12.3536 20.8346 13.6972 20.8289C15.4579 20.8381 17.2113 20.6025 18.9072 20.1289V14.4989C17.2011 14.9093 15.452 15.114 13.6972 15.1089C12.356 15.1127 11.0173 14.9956 9.69719 14.7589V20.4289ZM20.4072 19.6089V14.0489C20.8708 13.8883 21.3249 13.7013 21.7672 13.4889C22.6562 13.0791 23.4733 12.5287 24.1872 11.8589V15.6889C24.1921 15.7321 24.1921 15.7757 24.1872 15.8189C24.1872 16.8189 23.4272 18.0389 21.4572 19.0989C21.116 19.2864 20.7655 19.4566 20.4072 19.6089Z'
				fill='#8B919D'
			/>
		</svg>
	);
}
