import React, { useEffect, useMemo, useState } from 'react';

import { Trans } from '@lingui/macro';
import { getContractAddress } from 'config/contracts';

import { useApproval } from 'hooks/useApproval';
import { useApprovalPlugin } from 'hooks/useApprovalPlugin';
import { useTokenBalance } from 'hooks/useCurrencyBalance';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { isEqualTo, isGreaterThan, shortenSymbol } from 'utils';

import CommonButton, { ExtendButton } from '../Common/StyledButton';

interface IApproveButton extends ExtendButton {
	isApproveToken?: boolean;
	isApproveOrderBook?: boolean;
	isApprovePositionRouter?: boolean;
	isApproveRewardCollector?: boolean;
	amountToApprove?: string;
}

export default React.memo(function ApproveButton({
	isApproveToken = false,
	isApproveOrderBook = false,
	isApprovePositionRouter = false,
	isApproveRewardCollector = false,
	amountToApprove = '0',
	children,
	...rest
}: IApproveButton) {
	const { currentChainId } = useCurrentChain();
	const { quoteToken } = useAppSelector(txBaseState);
	const { balance } = useTokenBalance(quoteToken);
	const currentVersion = useAppSelector(state => state.setting.currentVersion);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const {
		orderBookContractAddress,
		positionRouterContractAddress,
		routerContractAddress,
		rewardCollectorContractAddress
	} = useMemo(() => {
		return {
			orderBookContractAddress: getContractAddress(currentChainId, 'OrderBook'),
			positionRouterContractAddress: getContractAddress(
				currentChainId,
				'PositionRouter'
			),
			routerContractAddress: getContractAddress(currentChainId, 'Router'),
			rewardCollectorContractAddress: getContractAddress(
				currentChainId,
				'RewardCollector'
			)
		};
	}, [currentChainId, currentVersion]);

	const { isApproving, isConfirmed, approveCallback, currentAllowance, error } =
		useApproval(quoteToken, amountToApprove, routerContractAddress);

	const {
		isPluginApproved,
		isPluginApproving,
		onApprovePlugin,
		error: pluginError
	} = useApprovalPlugin(orderBookContractAddress);

	const {
		isPluginApproved: isPositionRouterPluginApproved,
		isPluginApproving: isPositionRouterPluginApproving,
		onApprovePlugin: onPositionRouterApprovePlugin,
		error: positionRouterPluginError
	} = useApprovalPlugin(positionRouterContractAddress);

	const {
		isPluginApproved: isRewardCollectorPluginApproved,
		isPluginApproving: isRewardCollectorPluginApproving,
		onApprovePlugin: onRewardCollectorApprovePlugin,
		error: rewardCollectorPluginError
	} = useApprovalPlugin(rewardCollectorContractAddress);

	const [isNeedApprove, disabled] = useMemo(() => {
		let _disabled = false;
		let _isNeedApprove = false;
		if (
			isLoading ||
			isApproving ||
			isPluginApproving ||
			isPositionRouterPluginApproving
		) {
			_disabled = true;
		}
		if (isGreaterThan(amountToApprove, balance)) {
			_disabled = true;
		}
		if (
			isApproveToken &&
			(isEqualTo(currentAllowance, 0) ||
				isGreaterThan(amountToApprove, currentAllowance))
		) {
			_isNeedApprove = true;
		}
		if (isApproveOrderBook && !isPluginApproved) {
			_isNeedApprove = true;
		}
		if (isApprovePositionRouter && !isPositionRouterPluginApproved) {
			_isNeedApprove = true;
		}
		if (isApproveRewardCollector && !isRewardCollectorPluginApproved) {
			_isNeedApprove = true;
		}
		return [_isNeedApprove, _disabled];
	}, [
		isLoading,
		isApproving,
		isConfirmed,
		isPluginApproving,
		isPluginApproved,
		isPositionRouterPluginApproving,
		isPositionRouterPluginApproved,
		isRewardCollectorPluginApproving,
		isRewardCollectorPluginApproved,
		currentAllowance,
		amountToApprove,
		isApproveToken,
		isApproveOrderBook,
		isApprovePositionRouter,
		isApproveRewardCollector
	]);

	const submitText = useMemo(() => {
		if (
			isApproving ||
			isPluginApproving ||
			isPositionRouterPluginApproving ||
			isRewardCollectorPluginApproving
		)
			return <Trans>Approving...</Trans>;
		if (isLoading) return <Trans>Loading...</Trans>;
		if (isGreaterThan(amountToApprove, balance)) {
			return <Trans>Insufficient Balance</Trans>;
		}
		if (
			isApproveToken &&
			(isEqualTo(currentAllowance, 0) ||
				isGreaterThan(amountToApprove, currentAllowance))
		) {
			return <Trans>Approve {shortenSymbol(quoteToken)}</Trans>;
		}
		if (isApproveOrderBook && !isPluginApproved) {
			return <Trans>Approve Operation (one-time)</Trans>;
		}
		if (isApprovePositionRouter && !isPositionRouterPluginApproved) {
			return <Trans>Approve Operation (one-time)</Trans>;
		}
		if (isApproveRewardCollector && !isRewardCollectorPluginApproved) {
			return <Trans>Approve Operation (one-time)</Trans>;
		}
	}, [
		isLoading,
		isApproving,
		isConfirmed,
		isPluginApproving,
		isPluginApproved,
		isPositionRouterPluginApproving,
		isPositionRouterPluginApproved,
		isRewardCollectorPluginApproving,
		isRewardCollectorPluginApproved,
		currentAllowance,
		amountToApprove,
		isApproveToken,
		isApproveOrderBook,
		isApprovePositionRouter,
		isApproveRewardCollector,
		balance
	]);

	useEffect(() => {
		if (isApproveToken) {
			if (error || isConfirmed) {
				setIsLoading(false);
			}
		}
	}, [isApproveToken, error, isConfirmed]);

	useEffect(() => {
		if (isApproveOrderBook) {
			if (pluginError || isPluginApproved) {
				setIsLoading(false);
			}
		}
	}, [isApproveOrderBook, pluginError, isPluginApproved]);

	useEffect(() => {
		if (isApprovePositionRouter) {
			if (positionRouterPluginError || isPositionRouterPluginApproved) {
				setIsLoading(false);
			}
		}
	}, [
		isApprovePositionRouter,
		positionRouterPluginError,
		isPositionRouterPluginApproved
	]);

	useEffect(() => {
		if (isApproveRewardCollector) {
			if (rewardCollectorPluginError || isRewardCollectorPluginApproved) {
				setIsLoading(false);
			}
		}
	}, [
		isApproveRewardCollector,
		rewardCollectorPluginError,
		isRewardCollectorPluginApproved
	]);

	const onApprove = () => {
		setIsLoading(true);
		if (
			isApproveToken &&
			(isEqualTo(currentAllowance, 0) ||
				isGreaterThan(amountToApprove, currentAllowance))
		) {
			approveCallback();
			return false;
		}
		if (isApproveOrderBook && !isPluginApproved) {
			onApprovePlugin();
			return false;
		}
		if (isApprovePositionRouter && !isPositionRouterPluginApproved) {
			onPositionRouterApprovePlugin();
			return false;
		}

		if (isApproveRewardCollector && !isRewardCollectorPluginApproved) {
			onRewardCollectorApprovePlugin();
			return false;
		}
	};

	return isNeedApprove ? (
		<CommonButton
			componentVariant='confirm'
			className='font-bold'
			fullWidth
			disabled={disabled}
			onClick={onApprove}
		>
			{submitText}
		</CommonButton>
	) : (
		<CommonButton {...rest}>{children}</CommonButton>
	);
});
