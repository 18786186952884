import { useContext, useEffect } from 'react';

import { Order_Status_V1, Side, Transaction_Type } from 'config/constants';
import { TradeContext } from 'context/TradeContext';

import { TransactionRecord } from 'entities/TransactionRecord';
import { IOrderItem } from 'graphql/useMyOrdersGraph';
import { IPositionItem } from 'graphql/useMyPositionsGraph';
import { useManageTransactions } from 'hooks/useAccountTransactions';
import _ from 'lodash';
import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { selectTransactionRecordList } from 'state/records/selector';
import { txBaseState } from 'state/tx/slice';
import { isLocalStorageFull } from 'utils';
import { RecordForCancelAllOrders } from 'types';

export default function ViewUpdater({
	positionMap,
	orderList
}: {
	positionMap: Map<string, Array<IPositionItem>> | null;
	orderList: Array<IOrderItem> | null;
}) {
	const { transactionType } = useAppSelector(txBaseState);
	const { appTimer } = useAppSelector(globalBaseState);
	const transactionRecords = useAppSelector(selectTransactionRecordList);
	const { poolInfo } = useAppSelector(poolsBaseState);
	const { setPositionInfo } = useContext(TradeContext);

	const { removeTransaction } = useManageTransactions();

	// 将当前操作的仓位放入全局
	useEffect(() => {
		if (!poolInfo || !positionMap) {
			setPositionInfo(null);
			return;
		}
		const currentPositionList = positionMap.get(poolInfo.id);
		if (!currentPositionList) {
			setPositionInfo(null);
			return;
		}
		const target = currentPositionList.find(item => {
			return (
				((transactionType === Transaction_Type.Long ||
					transactionType === Transaction_Type.LongLimitOrder) &&
					item.side === Side.LONG) ||
				((transactionType === Transaction_Type.Short ||
					transactionType === Transaction_Type.ShortLimitOrder) &&
					item.side === Side.SHORT)
			);
		});
		setPositionInfo(target || null);
	}, [positionMap, poolInfo, transactionType]);

	// remove local order transaction
	useEffect(() => {
		if (!orderList) {
			return;
		}

		let results: Array<TransactionRecord> = [];

		// 新创建的订单，没有被执行
		const createdOrderList = orderList.filter(
			(item: IOrderItem) => item.orderStatus === Order_Status_V1.Created
		);

		// 针对创建完的订单，立马被执行的场景
		// 1. 创建完，立马被执行
		// 2. 更新完，立马被执行
		const unCreatedOrderList = orderList.filter(
			(item: IOrderItem) => item.orderStatus !== Order_Status_V1.Created
		);

		// 本地 transaction records
		if (transactionRecords && transactionRecords.length > 0) {
			const orderRecords = _.filter(
				transactionRecords,
				(txRecord: TransactionRecord) => {
					const record = txRecord.record;
					return (
						// 创建
						record.transactionType === Transaction_Type.LongLimitOrder ||
						record.transactionType === Transaction_Type.ShortLimitOrder ||
						record.transactionType ===
							Transaction_Type.CreateTakeProfitPosition ||
						record.transactionType ===
							Transaction_Type.CreateStopLossPosition ||
						record.transactionType ===
							Transaction_Type.CreateTakeProfitPartial ||
						record.transactionType === Transaction_Type.CreateStopLossPartial ||
						// 更新
						record.transactionType === Transaction_Type.UpdateLimitOrder ||
						record.transactionType ===
							Transaction_Type.UpdateTakeProfitPosition ||
						record.transactionType ===
							Transaction_Type.UpdateStopLossPosition ||
						record.transactionType ===
							Transaction_Type.UpdateTakeProfitPartial ||
						record.transactionType === Transaction_Type.UpdateStopLossPartial ||
						// 取消
						record.transactionType === Transaction_Type.CancelledLimitOrder ||
						record.transactionType ===
							Transaction_Type.CancelTakeProfitPosition ||
						record.transactionType ===
							Transaction_Type.CancelStopLossPosition ||
						record.transactionType ===
							Transaction_Type.CancelTakeProfitPartial ||
						record.transactionType === Transaction_Type.CancelStopLossPartial
					);
				}
			);

			const orderCancelAllRecords = _.filter(
				transactionRecords,
				(txRecord: TransactionRecord) => {
					const record = txRecord.record;
					return (
						// 取消全部
						record.transactionType === Transaction_Type.CancelAllOrders
					);
				}
			);

			// 包含
			// 1. 新创建订单，没有被执行
			// 2. 更新订单，没有被执行
			if (orderRecords && orderRecords.length > 0) {
				results = results.concat(
					_.intersectionWith(
						orderRecords,
						createdOrderList,
						(txRecord, currentOrder) => {
							const record = txRecord.record;
							return (
								txRecord.hash === currentOrder.createdHash ||
								(record.orderId === currentOrder.id &&
									txRecord.hash === currentOrder.updatedHash)
							);
						}
					)
				);

				// 包含
				// 1. 新创建订单，立马被执行
				// 2. 更新订单，立马被执行
				// 3. 取消订单
				results = results.concat(
					_.intersectionWith(
						orderRecords,
						unCreatedOrderList,
						(txRecord, currentOrder) => {
							const record = txRecord.record;
							return (
								txRecord.hash === currentOrder.createdHash ||
								(record.orderId === currentOrder.id &&
									(txRecord.hash === currentOrder.updatedHash ||
										txRecord.hash === currentOrder.cancelledHash))
							);
						}
					)
				);
			}

			if (orderCancelAllRecords && orderCancelAllRecords.length > 0) {
				// 取消全部
				results = results.concat(
					_.intersectionWith(
						orderCancelAllRecords,
						unCreatedOrderList,
						(txRecord, currentOrder) => {
							const record = txRecord.record as RecordForCancelAllOrders;
							return record.orderIds.includes(currentOrder.index);
						}
					)
				);

			}

			// console.log('[intersectionWith result]: ', results);
		}

		results.forEach((item: TransactionRecord) => {
			item.syncing = false;
			if (!isLocalStorageFull()) {
				removeTransaction(item);
			} else {
				console.warn('isLocalStorageFull: true !!!');
			}
		});
	}, [orderList, poolInfo, transactionRecords, appTimer]);

	return null;
}
