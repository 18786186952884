import { createSelector } from '@reduxjs/toolkit';
import { Transaction_Status, Transaction_Type } from 'config/constants';

import _ from 'lodash';

import { TransactionRecord } from '../../entities/TransactionRecord';
import { recordsBaseState } from './slice';

export const selectTransactionRecordList = createSelector(
	[recordsBaseState],
	state => {
		const { transactionRecords } = state;
		const list = Array.from(
			transactionRecords.values()
		) as Array<TransactionRecord>;
		const result = _.orderBy(
			list,
			(item: TransactionRecord) => {
				return new Date(item.date).getTime();
			},
			['desc']
		);
		return result;
	}
);
export const selectTransactionPendingRecordList = createSelector(
	[selectTransactionRecordList],
	transactionRecordList => {
		if (!transactionRecordList.length) {
			return [];
		}
		const list = transactionRecordList.filter((tx: TransactionRecord) => {
			return tx.status === Transaction_Status.Pending;
		});
		return list;
	}
);

export const selectClaimStakeRecordList = createSelector(
	[selectTransactionRecordList],
	transactionRecordList => {
		if (!transactionRecordList.length) {
			return null;
		}
		return transactionRecordList.filter((tx: TransactionRecord) => {
			return tx.type === Transaction_Type.ClaimStake;
		});
	}
);

export const selectClaimPositionRecordList = createSelector(
	[selectTransactionRecordList],
	transactionRecordList => {
		if (!transactionRecordList.length) {
			return null;
		}
		return transactionRecordList.filter((tx: TransactionRecord) => {
			return tx.type === Transaction_Type.ClaimPosition;
		});
	}
);

export const selectClaimLiquidityRecordList = createSelector(
	[selectTransactionRecordList],
	transactionRecordList => {
		if (!transactionRecordList.length) {
			return null;
		}
		return transactionRecordList.filter((tx: TransactionRecord) => {
			return tx.type === Transaction_Type.ClaimLiqudiity;
		});
	}
);

export const selectClaimRBFRecordList = createSelector(
	[selectTransactionRecordList],
	transactionRecordList => {
		if (!transactionRecordList.length) {
			return null;
		}
		return transactionRecordList.filter((tx: TransactionRecord) => {
			return tx.type === Transaction_Type.ClaimRBF;
		});
	}
);

export const selectClaimArchitectRecordList = createSelector(
	[selectTransactionRecordList],
	transactionRecordList => {
		if (!transactionRecordList.length) {
			return null;
		}
		return transactionRecordList.filter((tx: TransactionRecord) => {
			return tx.type === Transaction_Type.ClaimArchitect;
		});
	}
);
