import { createSelector } from '@reduxjs/toolkit';

import _ from 'lodash';
import { ClaimCalldata, Type_Earns } from 'pages/Earn/types';
import { txBaseState } from 'state/tx/slice';
import { isGreaterThan, plus } from 'utils';

import { earnArchitectBaseState } from './slice';

export const selectClaimableArchitectList = createSelector(
	[earnArchitectBaseState],
	state => {
		const { architectList } = state;
		if (!architectList || architectList.length === 0) {
			return null;
		}
		return _.orderBy(
			architectList.filter(item => isGreaterThan(item.claimableReward, 0)),
			function (o) {
				return parseFloat(o.claimableReward);
			},
			['desc']
		);
	}
);

export const selectClaimArchitectCalldatas = createSelector(
	[txBaseState, selectClaimableArchitectList],
	(txBaseState, claimableArchitectList) => {
		const { quoteToken } = txBaseState;
		if (!claimableArchitectList || claimableArchitectList.length == 0) {
			return null;
		}
		const results = [] as Array<ClaimCalldata>;
		const ids = claimableArchitectList.map(item => item.id);
		const quoteAmount = claimableArchitectList.reduce(
			(pre, cur) => plus(pre, cur.claimableReward),
			'0'
		);
		results.push({
			type: Type_Earns.Architect,
			ids,
			rewardToken: quoteToken,
			amount: quoteAmount
		});

		return results;
	}
);

export const selectClaimableArchitectAmount = createSelector(
	[selectClaimArchitectCalldatas],
	claimArchitectCalldatas => {
		if (!claimArchitectCalldatas) return '0';
		return claimArchitectCalldatas.reduce(
			(pre, cur) => plus(pre, cur.amount),
			'0'
		);
	}
);

export const selectHasArchitect = createSelector(
	[earnArchitectBaseState],
	state => {
		const { architectList } = state;
		if (!architectList || architectList.length === 0) {
			return false;
		}
		return true;
	}
);
