import { DEFAULT_SYMBOL_LEN } from 'config/constants';
import {
	NATIVE_CURRENCY,
	WRAPPED_NATIVE_CURRENCY
} from 'config/constants/tokens';

import { getAddress } from 'ethers/lib/utils';

import { TokenBase, TokenInfo } from '../types';

export function isAddress(address: string) {
	try {
		return getAddress(address);
	} catch {
		return false;
	}
}
export function shortenAddress(address: string, chars = 4): string {
	if (!address) {
		return '';
	}
	const parsed = getAddress(address);
	if (!parsed) {
		throw Error(`Invalid 'address' parameter '${address}'.`);
	}
	return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
}

export function shortenHash(hash: string): string {
	if (!hash) {
		return '';
	}
	return `${hash.substring(0, 6)}...${hash.substring(
		hash.length - 4,
		hash.length
	)}`;
}

export function shortenName(
	value: string | undefined,
	len: number = DEFAULT_SYMBOL_LEN
) {
	if (!value) {
		return '-';
	}
	if (value.length <= len) {
		return value;
	} else {
		return value.substring(0, len) + '...';
	}
}

export function shortenSymbolNative(
	token: TokenInfo | TokenBase | null | undefined,
	chainId: number,
	len: number = DEFAULT_SYMBOL_LEN
) {
	if (token?.symbol === WRAPPED_NATIVE_CURRENCY[chainId]?.symbol) {
		return NATIVE_CURRENCY[chainId].symbol;
	} else {
		return shortenSymbol(token, len);
	}
}

export function shortenSymbol(
	token: TokenInfo | TokenBase | null,
	len: number = DEFAULT_SYMBOL_LEN
) {
	if (!token) {
		return '-';
	} else {
		if (!token.symbol) return 'UNKNOWN';
		return shortenName(token.symbol, len);
	}
}
