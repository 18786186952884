import {
	DEFAULT_PRECISION,
	DEFAULT_QUOTE_PRECISION,
	QUOTE_USD_PRECISION,
	Side,
	SideFlip
} from 'config/constants';
import Decimal from 'decimal.js';

import { cloneDeep } from 'lodash';
import {
	bigIntMulDiv,
	isZero,
	multipliedBy,
	parseUnits,
	solveQuadraticEquation
} from 'utils';

import {
	IGlobalLiquidityPosition,
	IGlobalLiquidityPositionBigInt,
	IPriceState,
	IPriceStateBigInt,
	IPriceVertex,
	IPriceVertexBigInt
} from '../../types';
import { UtilHelper } from '../UtilHelper';
import { LATEST_VERTEX, PriceUtil, Q152, VERTEX_NUM } from './PriceUtil';

const Q96 = 1n << 96n;

interface UpdatePriceStateParameter {
	side: Side;
	liquidityLeft: bigint;
	indexPriceX96: bigint;
	liquidationVertexIndex: number;
	liquidation: boolean;
}

interface IMoveStepLiquidity {
	side: Side;
	liquidityLeft: bigint;
	indexPriceX96: bigint;
	basisIndexPriceX96: bigint;
	improveBalance: boolean;
	from: IPriceVertexBigInt;
	current: IPriceVertexBigInt;
	to: IPriceVertexBigInt;
}

export class PriceUtilByPay {
	public static calculateMarketPrice(
		liquidityDelta: string,
		globalLiquidityPosition: IGlobalLiquidityPosition,
		priceState: IPriceState,
		side: Side,
		indexPriceX96: string,
		baseDecimal: number,
		quoteDecimal = QUOTE_USD_PRECISION
	) {
		// 初始化状态，直接返回流动性不足
		if (isZero(globalLiquidityPosition.liquidity)) {
			return '0';
		}
		const priceX96 = this.calculateMarketPriceX96(
			globalLiquidityPosition,
			priceState,
			side,
			liquidityDelta,
			indexPriceX96
		);

		const tradePrice = UtilHelper.calculatePrice(
			String(priceX96),
			baseDecimal,
			quoteDecimal
		);
		return tradePrice;
	}

	public static calculateMarketPriceX96(
		globalLiquidityPosition: IGlobalLiquidityPosition,
		priceState: IPriceState,
		side: Side,
		liquidityDelta: string,
		indexPriceX96: string
	) {
		const _liquidityDelta = BigInt(
			multipliedBy(liquidityDelta, new Decimal(10).pow(6))
		);
		const _indexPriceX96 = BigInt(indexPriceX96);

		globalLiquidityPosition = {
			...globalLiquidityPosition,
			netSize: multipliedBy(
				globalLiquidityPosition.netSize,
				new Decimal(10).pow(18)
			),
			liquidationBufferNetSize: multipliedBy(
				globalLiquidityPosition.liquidationBufferNetSize,
				new Decimal(10).pow(18)
			)
		};
		const _priceState = {
			...priceState,
			premiumRateX96: BigInt(priceState.premiumRateX96),
			priceVertices: priceState.priceVertices.map((item: IPriceVertex) => ({
				premiumRateX96: BigInt(item.premiumRateX96),
				size: BigInt(parseUnits(item.size, DEFAULT_PRECISION))
			})),
			liquidationBufferNetSizes: priceState.liquidationBufferNetSizes.map(
				item => BigInt(parseUnits(item, DEFAULT_PRECISION))
			),
			indexPriceX96: BigInt(indexPriceX96),
			basisIndexPriceX96: BigInt(priceState.basisIndexPriceX96)
		};
		const priceConfig = {
			maxPriceImpactLiquidity: BigInt(priceState.maxPriceImpactLiquidity),
			liquidationVertexIndex: priceState.liquidationVertexIndex,
			vertices: globalLiquidityPosition.tokenVertices.map(item => ({
				balanceRate: BigInt(item.balanceRate),
				premiumRate: BigInt(item.premiumRate)
			}))
		};
		const state = {
			priceState: _priceState,
			globalLiquidityPosition: globalLiquidityPosition
		};
		const parameter = {
			side,
			liquidityLeft: _liquidityDelta,
			indexPriceX96: _indexPriceX96,
			liquidationVertexIndex: priceState.liquidationVertexIndex,
			liquidation: false
		};

		const priceStateCache = cloneDeep(state.priceState);
		const globalLiquidityPositionCache = {
			...globalLiquidityPosition,
			side: globalLiquidityPosition.side,
			netSize: BigInt(globalLiquidityPosition.netSize),
			liquidationBufferNetSize: BigInt(
				globalLiquidityPosition.liquidationBufferNetSize
			),
			liquidity: BigInt(
				parseUnits(globalLiquidityPosition.liquidity, DEFAULT_QUOTE_PRECISION)
			)
		};

		// 当lp不持仓、且没有爆仓buffer，为平衡
		const balanced =
			(globalLiquidityPositionCache.netSize |
				globalLiquidityPositionCache.liquidationBufferNetSize) ===
			0n;
		if (balanced) {
			priceStateCache.basisIndexPriceX96 = parameter.indexPriceX96;
		}
		const improveBalance =
			parameter.side == globalLiquidityPositionCache.side && !balanced;

		let {
			tradePriceX96TimesSizeTotal, // price *  size
			// eslint-disable-next-line prefer-const
			liquidityLeft, // 到点时剩余的流动性 >=0
			// eslint-disable-next-line prefer-const
			totalBufferUsed, // 使用的爆仓 buffer size  （1：更不平衡不到原点 1次 2：更平衡不到原点 1次  3：2：更平衡到原点 2次）
			// eslint-disable-next-line prefer-const
			sizeUsedTotal: sizeUsedTotal1
		} = this._calculateMarketPriceX96(
			globalLiquidityPositionCache,
			_priceState,
			priceStateCache,
			parameter,
			improveBalance
		);
		let sizeUsedTotal = sizeUsedTotal1;

		if (!improveBalance) {
			globalLiquidityPositionCache.side = SideFlip(side);
			globalLiquidityPositionCache.netSize += sizeUsedTotal1 - totalBufferUsed;
			globalLiquidityPositionCache.liquidationBufferNetSize += totalBufferUsed;
		} else {
			// When the net position of LP decreases and reaches or crosses the vertex,
			// at least the vertex represented by (current, pending] needs to be updated
			if (
				priceStateCache.pendingVertexIndex > priceStateCache.currentVertexIndex
			) {
				PriceUtil.changePriceVertex(
					state,
					priceConfig,
					parameter.indexPriceX96,
					priceStateCache.currentVertexIndex,
					LATEST_VERTEX
				);
				priceStateCache.pendingVertexIndex = priceStateCache.currentVertexIndex;
			}

			globalLiquidityPositionCache.netSize -= sizeUsedTotal1 - totalBufferUsed;
			globalLiquidityPositionCache.liquidationBufferNetSize -= totalBufferUsed;
		}

		if (liquidityLeft > 0n) {
			globalLiquidityPositionCache.side = SideFlip(
				globalLiquidityPositionCache.side
			);
			priceStateCache.basisIndexPriceX96 = parameter.indexPriceX96;
			parameter.liquidityDelta = liquidityLeft;

			const {
				tradePriceX96TimesSizeTotal: tradePriceX96TimesSizeTotal2,
				sizeUsedTotal: sizeUsedTotal2
			} = this._calculateMarketPriceX96(
				globalLiquidityPositionCache,
				_priceState,
				priceStateCache,
				parameter,
				false
			);
			if (
				tradePriceX96TimesSizeTotal === 0n ||
				tradePriceX96TimesSizeTotal2 === 0n
			) {
				return 0n;
			}
			sizeUsedTotal += sizeUsedTotal2;
			tradePriceX96TimesSizeTotal += tradePriceX96TimesSizeTotal2;
		}

		// 价格为负，返回异常
		if (tradePriceX96TimesSizeTotal < 0n) {
			return {
				tradePriceX96: -1n,
				priceStateCache: priceState,
				globalLiquidityPositionCache: state.globalLiquidityPosition
			};
		}

		const tradePriceX96 =
			side === Side.LONG
				? bigIntMulDiv(tradePriceX96TimesSizeTotal, 1n, sizeUsedTotal, true)
				: tradePriceX96TimesSizeTotal / sizeUsedTotal;

		return tradePriceX96.toString();
	}

	private static _calculateMarketPriceX96(
		globalLiquidityPositionCache: IGlobalLiquidityPositionBigInt,
		priceState: IPriceStateBigInt,
		priceStateCache: IPriceStateBigInt,
		parameter: UpdatePriceStateParameter,
		improveBalance: boolean
	) {
		const liquidityLeft = parameter.liquidityLeft;
		const indexPriceX96 = parameter.indexPriceX96;
		const side = parameter.side;

		const step: IMoveStepLiquidity = {
			side: parameter.side,
			liquidityLeft,
			indexPriceX96,
			basisIndexPriceX96: priceStateCache.basisIndexPriceX96,
			improveBalance,
			from: { size: 0n, premiumRateX96: 0n },
			current: {
				size: globalLiquidityPositionCache.netSize,
				premiumRateX96: priceStateCache.premiumRateX96
			},
			to: { size: 0n, premiumRateX96: 0n }
		};

		let tradePriceX96TimesSizeTotal = 0n;
		let sizeUsedTotal = 0n;
		let totalBufferUsed = 0n;

		if (!step.improveBalance) {
			// 平衡性变差
			if (priceStateCache.currentVertexIndex == 0)
				priceStateCache.currentVertexIndex = 1;
			const end = VERTEX_NUM;
			for (
				let i = priceStateCache.currentVertexIndex;
				i < end && step.liquidityLeft > 0n;
				++i
			) {
				[step.from, step.to] = [
					priceStateCache.priceVertices[i - 1],
					priceStateCache.priceVertices[i]
				];

				const {
					reached,
					tradePriceX96,
					sizeUsed,
					liquidityUsed,
					premiumRateAfterX96
				} = this.simulateMove(step);

				if (reached) {
					// 超过此点
					priceStateCache.currentVertexIndex = i + 1;
					step.current = step.to;
				}

				step.liquidityLeft -= liquidityUsed;
				tradePriceX96TimesSizeTotal += tradePriceX96 * sizeUsed;
				sizeUsedTotal += sizeUsed;
				priceStateCache.premiumRateX96 = premiumRateAfterX96;
			}
			// 流动性不足
			if (step.liquidityLeft > 0n) {
				return {
					tradePriceX96TimesSizeTotal: 0n,
					liquidityLeft: step.liquidityLeft,
					totalBufferUsed,
					sizeUsedTotal
				};
			}
		} else {
			// 平衡性变优
			// @note: `i` == 0 时继续执行以消耗原点liquidationBuffer部分
			for (
				let i = priceStateCache.currentVertexIndex;
				i >= 0 && step.liquidityLeft > 0n;
				--i
			) {
				// 消耗from中buffer size:
				// from中buffer size不为0的case:
				// 假设从v5 => v4, 此时更新 currentVertexIndex = v4, 但是v4中的buffer size没有被消耗完
				// 这里消耗v4中的size
				let bufferSizeAfter = priceState.liquidationBufferNetSizes[i];
				if (bufferSizeAfter > 0n) {
					const tradePriceX96 = bigIntMulDiv(
						indexPriceX96,
						globalLiquidityPositionCache.side === Side.LONG
							? Q96 - step.current.premiumRateX96
							: Q96 + step.current.premiumRateX96,
						Q96,
						side === Side.LONG
					);
					const sizeUsed = step.liquidityLeft / tradePriceX96;
					const liquidityUsed = sizeUsed * tradePriceX96;

					const bufferSizeUsed =
						bufferSizeAfter > sizeUsed ? sizeUsed : bufferSizeAfter;
					bufferSizeAfter -= bufferSizeUsed;
					priceState.liquidationBufferNetSizes[i] = bufferSizeAfter;
					totalBufferUsed += bufferSizeUsed;

					step.liquidityLeft -= liquidityUsed;
					tradePriceX96TimesSizeTotal += tradePriceX96 * bufferSizeUsed;
				}

				if (step.liquidityLeft > 0n && i > 0) {
					[step.from, step.to] = [
						priceState.priceVertices[i],
						priceState.priceVertices[i - 1]
					];
					const {
						tradePriceX96,
						sizeUsed,
						liquidityUsed,
						reached,
						premiumRateAfterX96
					} = this.simulateMove(step);
					if (reached) {
						// 到达or超过
						priceStateCache.currentVertexIndex = i - 1;
						step.current = step.to;
					}
					step.liquidityLeft -= liquidityUsed;
					tradePriceX96TimesSizeTotal += tradePriceX96 * sizeUsed;
					sizeUsedTotal += sizeUsed;
					priceStateCache.premiumRateX96 = premiumRateAfterX96;
				}
			}
		}

		return {
			tradePriceX96TimesSizeTotal,
			sizeUsedTotal,
			liquidityLeft: step.liquidityLeft,
			totalBufferUsed
		};
	}

	private static simulateMove(step: IMoveStepLiquidity) {
		const premiumRateBeforeX96 = step.current.premiumRateX96;

		const globalSide = step.improveBalance ? step.side : SideFlip(step.side);
		const stepSizeDelta = step.improveBalance
			? step.current.size - step.to.size
			: step.to.size - step.current.size;

		// 假如直接到达to这个点，来计算出对应的liquidity变化值liquidityDelta
		const _stepBySize = {
			...step,
			sizeLeft: stepSizeDelta
		};

		const { tradePriceX96, premiumRateAfterX96 } =
			PriceUtil.simulateMove(_stepBySize);

		const _liquidityDelta = (tradePriceX96 * stepSizeDelta) / Q96;

		// 直接到达目标点
		if (step.liquidityLeft > _liquidityDelta) {
			const sizeUsed = stepSizeDelta;
			const reached = true;
			const liquidityUsed = _liquidityDelta;

			return {
				reached,
				sizeUsed,
				liquidityUsed,
				tradePriceX96,
				premiumRateAfterX96
			};
		} else {
			// 没有到达目标点

			const AX248AndBX96 = this.calculateAX248AndBX96(
				globalSide,
				step.from,
				step.to
			);
			// 把一个数与ax248的乘积转换为x96
			const aX248MulToAX96 = mulValue =>
				bigIntMulDiv(AX248AndBX96.aX248, mulValue, Q152, true);
			let bX96 = AX248AndBX96.bX96;
			if (
				(step.improveBalance && step.side === Side.LONG) ||
				(!step.improveBalance && step.side === Side.SHORT)
			) {
				bX96 = -AX248AndBX96.bX96;
			}

			let tradePriceX96;
			let premiumRateAfterX96;
			// Price = Pi + Pr * Pi_b
			// Pe = (Pb+Pa)/2
			// 设Pe为x

			// 第三象限
			if (
				(step.improveBalance && step.side === Side.LONG) ||
				(!step.improveBalance && step.side === Side.SHORT)
			) {
				// 价格更高 更加不平衡
				// Pb = IndexPrice - Prb * Pi_b
				// Pa = IndexPrice - Pra * Pi_b
				// 2Pe = Pb + Pa

				// 2Pe = 2Pi - Pi_b * Pra - Pi_b * Prb
				// 2x = 2Pi - Pi_b * (a(SizeB + L / x) + b) - Pi_b * Prb
				// 2*x^2 = 2Pi*x - Pi_b * (a*SizeB*x + a*L + b*x) - Pi_b*Prb*x
				// 2*x^2 = 2Pi*x - Pi_b*a*sizeB*x - Pi_b*a*L - Pi_b*b*x - Pi_b*Prb*x
				// 2*x^2 = x*(2Pi - Pi_b*a*sizeB - Pi_b*b - Pi_b*Prb) - Pi_b*a*L
				// a = -2, b =2Pi - Pi_b*a*sizeB - Pi_b*b - Pi_b*Prb, c = -Pi_b*a*L
				// a = -2, b =2Pi - Pi_b(a*sizeB + b + Prb), c = -Pi_b*a*L
				if (!step.improveBalance) {
					const result = solveQuadraticEquation(
						(-2n * Q96).toString(),
						(
							2n * Q96 * step.indexPriceX96 -
							step.basisIndexPriceX96 *
								(aX248MulToAX96(step.current.size) +
									bX96 +
									premiumRateBeforeX96)
						).toString(),
						aX248MulToAX96(
							-step.basisIndexPriceX96 * step.liquidityLeft
						).toString()
					);
					tradePriceX96 = UtilHelper.toBigIntForPriceX96(
						Decimal.max(result[0], result[1]),
						step.side
					);
					premiumRateAfterX96 =
						bigIntMulDiv(
							AX248AndBX96.aX248,
							step.current.size + (step.liquidityLeft * Q96) / tradePriceX96,
							Q152,
							true
						) + bX96;
				} else {
					// 价格更低 更加平衡
					// 2Pe = 2Pi - Pi_b * Pra - Pi_b * Prb
					// 2x = 2Pi - Pi_b * (a(SizeB - L / x) + b) - Pi_b * Prb
					// 2*x^2 = x*(2Pi - Pi_b*a*sizeB - Pi_b*b - Pi_b*Prb) + Pi_b*a*L
					// a = -2, b =2Pi - Pi_b*a*sizeB - Pi_b*b - Pi_b*Prb, c = Pi_b*a*L
					// a = -2, b =2Pi - Pi_b(a*sizeB + b + Prb), c = Pi_b*a*L
					const result = solveQuadraticEquation(
						(-2n * Q96).toString(),
						(
							2n * Q96 * step.indexPriceX96 -
							step.basisIndexPriceX96 *
								(aX248MulToAX96(step.current.size) +
									bX96 +
									premiumRateBeforeX96)
						).toString(),
						aX248MulToAX96(
							step.basisIndexPriceX96 * step.liquidityLeft
						).toString()
					);
					tradePriceX96 = UtilHelper.toBigIntForPriceX96(
						Decimal.max(result[0], result[1]),
						step.side
					);
					premiumRateAfterX96 =
						bigIntMulDiv(
							AX248AndBX96.aX248,
							step.current.size - (step.liquidityLeft * Q96) / tradePriceX96,
							Q152,
							true
						) + bX96;
				}
			} else {
				// 第一象限
				// 价格更高 更加不平衡
				// 2Pe = 2Pi + Pi_b * Pra + Pi_b * Prb
				// 2x = 2Pi + Pi_b * (a(SizeB + L / x) + b) + Pi_b * Prb
				// 2*x^2 = x*(2Pi + Pi_b*a*sizeB + Pi_b*b + Pi_b*Prb) + Pi_b*a*L
				// a = -2, b =2Pi + Pi_b*a*sizeB + Pi_b*b + Pi_b*Prb, c = Pi_b*a*L
				// a = -2, b =2Pi + Pi_b(a*sizeB + b + Prb), c = Pi_b*a*L
				if (!step.improveBalance) {
					const result = solveQuadraticEquation(
						(-2n * Q96).toString(),
						(
							2n * Q96 * step.indexPriceX96 +
							step.basisIndexPriceX96 *
								(aX248MulToAX96(step.current.size) +
									bX96 +
									premiumRateBeforeX96)
						).toString(),
						aX248MulToAX96(
							step.basisIndexPriceX96 * step.liquidityLeft
						).toString()
					);
					tradePriceX96 = UtilHelper.toBigIntForPriceX96(
						Decimal.max(result[0], result[1]),
						step.side
					);
					premiumRateAfterX96 =
						bigIntMulDiv(
							AX248AndBX96.aX248,
							step.current.size + (step.liquidityLeft * Q96) / tradePriceX96,
							Q152,
							true
						) + bX96;
				} else {
					// 价格更低 更加平衡
					// 2Pe = 2Pi + Pi_b * Pra + Pi_b * Prb
					// 2x = 2Pi + Pi_b * (a(SizeB - L / x) + b) + Pi_b * Prb
					// 2*x^2 = x*(2Pi + Pi_b*a*sizeB + Pi_b*b + Pi_b*Prb) - Pi_b*a*L
					// a = -2, b =2Pi + Pi_b*a*sizeB + Pi_b*b + Pi_b*Prb, c = -Pi_b*a*L
					// a = -2, b =2Pi + Pi_b(a*sizeB + b + Prb), c = -Pi_b*a*L
					const result = solveQuadraticEquation(
						(-2n * Q96).toString(),
						(
							2n * Q96 * step.indexPriceX96 +
							step.basisIndexPriceX96 *
								(aX248MulToAX96(step.current.size) +
									bX96 +
									premiumRateBeforeX96)
						).toString(),
						aX248MulToAX96(
							-step.basisIndexPriceX96 * step.liquidityLeft
						).toString()
					);
					tradePriceX96 = UtilHelper.toBigIntForPriceX96(
						Decimal.max(result[0], result[1]),
						step.side
					);
					premiumRateAfterX96 =
						bigIntMulDiv(
							AX248AndBX96.aX248,
							step.current.size - (step.liquidityLeft * Q96) / tradePriceX96,
							Q152,
							true
						) + bX96;
				}
			}
			const sizeUsed = (step.liquidityLeft * Q96) / tradePriceX96;
			const reached = sizeUsed >= stepSizeDelta;
			const liquidityUsed = reached
				? stepSizeDelta * tradePriceX96
				: step.liquidityLeft;

			return {
				reached,
				sizeUsed,
				liquidityUsed,
				tradePriceX96,
				premiumRateAfterX96
			};
		}
	}

	private static calculateAX248AndBX96(
		globalSide: Side,
		from: IPriceVertexBigInt,
		to: IPriceVertexBigInt
	) {
		if (from.size > to.size) {
			[from, to] = [to, from];
		}
		const sizeDelta = to.size - from.size;
		const aX248 = bigIntMulDiv(
			to.premiumRateX96 - from.premiumRateX96,
			1n << 152n,
			sizeDelta,
			true
		);
		let bX96: bigint;
		const numeratorPart1X96 = from.premiumRateX96 * to.size;
		const numeratorPart2X96 = to.premiumRateX96 * from.size;
		if (globalSide === Side.SHORT) {
			if (numeratorPart1X96 >= numeratorPart2X96) {
				bX96 = (numeratorPart1X96 - numeratorPart2X96) / sizeDelta;
			} else {
				bX96 = -((numeratorPart2X96 - numeratorPart1X96) / sizeDelta);
			}
		} else {
			if (numeratorPart2X96 >= numeratorPart1X96) {
				bX96 = (numeratorPart2X96 - numeratorPart1X96) / sizeDelta;
			} else {
				bX96 = -((numeratorPart1X96 - numeratorPart2X96) / sizeDelta);
			}
		}
		return { aX248, bX96 };
	}
}
