import { useContext, useMemo } from 'react';

import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import {
	DEFAULT_MAX_ELVERAGE,
	LEVERAGE_MAKERS,
	MARK_POINTS,
	MAX_PRECISION,
	Version
} from 'config/constants';

import {
	checkInputInteger,
	computeLeverageMarks,
	isGreaterThanOrEqual,
	isLessThanOrEqualTo
} from 'utils';

import { CommonStyledFilledInput } from 'components/Common/Styled';
import MarksSlider from 'components/MarksSlider';

import { useAppBreakpoints } from '../../../hooks/useAppBreakpoints';
import { useAppSelector } from '../../../state/hooks';
import { selectVersion } from '../../../state/setting/selector';
import { ComputerContext } from './ComputerContext';

export default function ComputerLeverage() {
	const { currentPool, leverage, setLeverage } = useContext(ComputerContext);
	const currentVersion = useAppSelector(selectVersion);

	const maxLeveragePerPosition: number = useMemo(() => {
		if (!currentPool) {
			return DEFAULT_MAX_ELVERAGE;
		}
		if (currentVersion === Version.V1) {
			const { maxLeveragePerPosition } = currentPool.token;
			return Number(maxLeveragePerPosition);
		} else {
			const { maxLeveragePerPosition } = currentPool;
			return Number(maxLeveragePerPosition);
		}
	}, [currentPool]);

	const leverageMarks = useMemo(() => {
		if (!maxLeveragePerPosition) {
			return LEVERAGE_MAKERS;
		}
		return computeLeverageMarks(maxLeveragePerPosition);
	}, [currentPool, maxLeveragePerPosition]);

	const onHandleChangeLeverage: React.ChangeEventHandler<
		HTMLInputElement
	> = e => {
		if (!currentPool) {
			return;
		}
		const value = e.target.value;
		if (value.trim() === '') {
			setLeverage('');
			return;
		}
		if (
			checkInputInteger(value) &&
			isGreaterThanOrEqual(value, 1) &&
			maxLeveragePerPosition &&
			isLessThanOrEqualTo(value, maxLeveragePerPosition)
		) {
			setLeverage(value);
		}
	};

	const onChangeLeverage = (value: number) => {
		console.log('value: ', value);
		setLeverage(String(value));
	};

	const onClickLabel = (value: number) => {
		setLeverage(String(value));
	};
	const { isMatchMobile } = useAppBreakpoints();

	return (
		<div className='space-y-1'>
			<CommonStyledFilledInput
				type='text'
				inputProps={{
					maxLength: MAX_PRECISION,
					inputMode: 'decimal'
				}}
				sx={{
					'& .MuiInputBase-input': {
						paddingRight: '8px'
					}
				}}
				value={leverage}
				onChange={onHandleChangeLeverage}
				fullWidth
				startAdornment={
					<Typography className='min-w-max' variant='body2' color='secondary'>
						<Trans>Leverage</Trans>
					</Typography>
				}
				endAdornment={
					<Typography
						color='secondary'
						fontSize={isMatchMobile ? 12 : 14}
						variant='h6'
					>
						x
					</Typography>
				}
			/>
			<MarksSlider
				leverage={leverage ? Number(leverage) : 1}
				marks={leverageMarks}
				max={maxLeveragePerPosition}
				onChangeLeverage={onChangeLeverage}
				onClickLabel={onClickLabel}
			/>
		</div>
	);
}
