import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDebounce } from 'react-use';

import { t } from '@lingui/macro';
import {
	Avatar,
	Box,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
	alpha
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Transaction_Status } from 'config/constants';
import { DEFAULT_CHAIN } from 'config/constants/connector/chains';
import {
	ConnectorConfig,
	ConnectorNames
} from 'config/constants/connector/types';
import { wallets } from 'config/constants/connector/wallets';
import {
	Connector,
	ResourceUnavailableError,
	UserRejectedRequestError,
	useAccount,
	useConnect,
	useDisconnect,
	useSwitchNetwork
} from 'wagmi';

import { useCurrentChain } from 'hooks/useCurrentChain';
import useLocalSnackbar from 'hooks/useLocalSnackbar';

import Dialog from 'components/Common/Dialog';
import Loading from 'components/Svg/Icons/Loading';

interface ConnectionModalProps {
	isOpen: boolean;
	onToggle: (value: boolean) => void;
}

function getReady(ethereum2) {
	const isMetaMask = Boolean(ethereum2?.isMetaMask);
	if (!isMetaMask) return;
	if (ethereum2.isBraveWallet && !ethereum2._events && !ethereum2._state)
		return;
	if (ethereum2.isApexWallet) return;
	if (ethereum2.isAvalanche) return;
	if (ethereum2.isBitKeep) return;
	if (ethereum2.isBlockWallet) return;
	if (ethereum2.isKuCoinWallet) return;
	if (ethereum2.isMathWallet) return;
	if (ethereum2.isOkxWallet || ethereum2.isOKExWallet) return;
	if (ethereum2.isOneInchIOSWallet || ethereum2.isOneInchAndroidWallet) return;
	if (ethereum2.isOpera) return;
	if (ethereum2.isPortal) return;
	if (ethereum2.isRabby) return;
	if (ethereum2.isTokenPocket) return;
	if (ethereum2.isTokenary) return;
	if (ethereum2.isZerion) return;
	return ethereum2;
}

function getMetaMaskProvider() {
	if (typeof window === 'undefined') return;
	const ethereum = window.ethereum;
	if (ethereum?.providers) return ethereum.providers.find(getReady);
	return getReady(ethereum);
}

function ConnectionModal({ isOpen, onToggle }: ConnectionModalProps) {
	const theme = useTheme();
	const [tryConnector, setTryConnector] = useState<Connector>(null);
	const [debouncedLoading, setDebouncedLoading] = useState<boolean>(false);
	const { currentChainId, checkChain } = useCurrentChain();
	const defaultChain = DEFAULT_CHAIN;
	const { connector: activeConnector, isConnected } = useAccount();
	const { switchNetworkAsync } = useSwitchNetwork();
	const { disconnect } = useDisconnect();
	const { showSnackbar } = useLocalSnackbar();

	const { connect, connectors, error, isLoading, isError, pendingConnector } =
		useConnect({
			onSettled(data, error, variables, context) {
				console.log('onSettled', data, error, variables, context);
			},
			onSuccess(data, variables, context) {
				console.log('onSuccess', data, variables, context);
				window.gtag('event', 'connect_wallet', {
					name: data.connector.name
				});
				// if (IS_USER_WHITE_MODEL && USER_WHITE_LIST.indexOf(data.account) === -1) {
				// 	enqueueSnackbar(t`Your account is not in the whitelist`, {
				// 		variant: 'warning',
				// 		autoHideDuration: 6000
				// 	});
				// 	disconnect();
				// }
				onToggle(false);
			},
			onError(error) {
				const isResourceUnavailableError =
					error instanceof ResourceUnavailableError;
				const isUserRejectedRequestError =
					error instanceof UserRejectedRequestError;
				if (
					isResourceUnavailableError ||
					error.message === 'Resource unavailable'
				) {
					const errorMessage = t`Request of type 'wallet_requestPermissions' already pending for origin ${window.location.origin}. Please wait.`;
					showSnackbar(errorMessage, undefined, Transaction_Status.Error);
				}
				if (
					isUserRejectedRequestError ||
					error.message === 'User rejected request'
				) {
					const errorMessage = t`The user rejected the request.`;
					showSnackbar(errorMessage, undefined, Transaction_Status.Error);
				}
			}
		});

	useDebounce(
		() => {
			setDebouncedLoading(isLoading);
		},
		600,
		[isLoading]
	);

	useEffect(() => {
		if (
			error?.message === 'Connector not found' ||
			error?.message === 'provider.request is not a function'
		) {
			onToggle(false);
			disconnect();
		}
	}, [error]);

	const onHandleConnect = (item: ConnectorConfig, findConnector) => {
		if (isConnected && activeConnector?.id === findConnector?.id) {
			switchNetworkAsync?.(currentChainId)
				.then(() => {
					onToggle(false);
				})
				.catch(error => {
					console.log('error', error);
				});
		} else {
			if (!isMobile) {
				if (
					!window?.ethereum?.isMetaMask &&
					item.href &&
					item.connectorId === ConnectorNames.MetaMask
				) {
					onToggle(false);
					window.open(item.href, '_blank', 'noopener noreferrer');
				} else if (
					!window.okxwallet &&
					item.href &&
					item.connectorId === ConnectorNames.Okxwallet
				) {
					onToggle(false);
					window.open(item.href, '_blank', 'noopener noreferrer');
				} else if (
					!window.bybitWallet &&
					item.href &&
					item.connectorId === ConnectorNames.Bybit
				) {
					onToggle(false);
					window.open(item.href, '_blank', 'noopener noreferrer');
				} else if (
					!(window.bitkeep && window.bitkeep.ethereum) &&
					item.href &&
					item.connectorId === ConnectorNames.Bitget
				) {
					onToggle(false);
					window.open(item.href, '_blank', 'noopener noreferrer');
				} else if (
					!(window?.ethereum && window?.ethereum?.isRabby) &&
					!window.rabby &&
					item.href &&
					item.connectorId === ConnectorNames.Rabby
				) {
					onToggle(false);
					window.open(item.href, '_blank', 'noopener noreferrer');
				} else if (
					!(window?.ethereum && window?.ethereum?.isTrust) &&
					!window.trustwallet &&
					item.href &&
					item.connectorId === ConnectorNames.Trust
				) {
					onToggle(false);
					window.open(item.href, '_blank', 'noopener noreferrer');
				} else {
					let currentConnector: Connector = null;
					if (
						(item.connectorId === ConnectorNames.MetaMask &&
							!getMetaMaskProvider()) ||
						(item.connectorId === ConnectorNames.Rabby && getMetaMaskProvider())
					) {
						currentConnector = connectors.find(
							(c: { name: any; id: any }) =>
								c.name === ConnectorNames.WalletConnect
						);
					} else {
						currentConnector = findConnector;
					}
					setTryConnector(currentConnector);
					if (checkChain(currentChainId)) {
						connect({ connector: currentConnector, chainId: currentChainId });
					} else {
						connect({ connector: currentConnector, chainId: defaultChain.id });
					}
				}
			} else {
				if (
					!window?.ethereum?.isMetaMask &&
					item.href &&
					item.connectorId === ConnectorNames.Injected
				) {
					window.location.href = `https://metamask.app.link/dapp/${window.location.host}${window.location.pathname}`;
				} else if (
					!window.okxwallet &&
					item.href &&
					item.connectorId === ConnectorNames.Okxwallet
				) {
					const encodedDappUrl = encodeURIComponent(
						`https://${window.location.host}${window.location.pathname}`
					);
					const deepLink = `okx://wallet/dapp/url?dappUrl=${encodedDappUrl}`;
					window.location.href = `https://www.okx.com/download?deeplink=${deepLink}`;
				} else if (
					!(window.bitkeep && window.bitkeep.ethereum) &&
					item.href &&
					item.connectorId === ConnectorNames.Bitget
				) {
					window.location.href = `https://bkcode.vip?action=dapp&url=https://${window.location.host}${window.location.pathname}`;
				} else if (
					!(window?.ethereum && window?.ethereum?.isTrust) &&
					!window.trustwallet &&
					item.href &&
					item.connectorId === ConnectorNames.Trust
				) {
					window.location.href = `https://link.trustwallet.com/open_url?coin_id=60&url=https://${window.location.host}${window.location.pathname}`;
				} else {
					setTryConnector(findConnector);
					if (checkChain(currentChainId)) {
						connect({ connector: findConnector, chainId: currentChainId });
					} else {
						connect({ connector: findConnector, chainId: defaultChain.id });
					}
				}
			}
		}
	};
	const onHandleClose = () => {
		onToggle(false);
	};
	return (
		<Dialog
			title={
				<Typography className='text-lg' fontWeight={500}>
					{t`Connect Wallet`}
				</Typography>
			}
			open={isOpen}
			onClose={onHandleClose}
			isConnectWallet
		>
			{debouncedLoading ? (
				<Box
					className='flex flex-col items-center justify-center space-y-4'
					sx={{ height: '182px' }}
				>
					<Loading size={28} />
					<Typography variant='body1'>{t`Connecting`}</Typography>
				</Box>
			) : (
				<List className='space-y-2 pb-4'>
					{wallets.map(item => {
						const { title, icon, connectorId } = item;
						const findConnector = connectors.find(
							(c: { name: any; id: any }) =>
								c.name === connectorId || c.id === connectorId
						);

						return (
							<ListItem key={title} className='p-0'>
								<ListItemButton
									className='px-6'
									sx={{
										height: 50,
										color: theme.palette.text.secondary,
										'&:hover': {
											backgroundColor: alpha(theme.palette.text.primary, 0.1),
											color: theme.palette.text.primary
										}
									}}
									onClick={() => onHandleConnect(item, findConnector)}
								>
									<ListItemIcon>
										<Avatar
											alt={title}
											src={icon}
											variant='square'
											sx={{ width: '30px', height: '30px' }}
										/>
									</ListItemIcon>
									<ListItemText
										className='ml-2'
										id={`wallet-connect-${title.toLowerCase()}`}
										primary={title}
									/>
								</ListItemButton>
							</ListItem>
						);
					})}
				</List>
			)}
		</Dialog>
	);
}

export default ConnectionModal;
