import { useMemo, useState } from 'react';

import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { BLOCK_GRAPH_URL, START_BLOCK_NUMBER } from 'config/env';
import moment from 'moment';

import { isLessThan } from 'utils';

import { useCurrentChain } from './useCurrentChain';
import useWeb3Provider from './useWeb3Provider';

export default function useBlockNumber() {
	const [blockNumber, setBlockNumber] = useState<number>();

	const { currentChainId } = useCurrentChain();
	const provider = useWeb3Provider();

	const refechBlockNumber = () => {
		provider
			.getBlockNumber()
			.then(block => {
				if (block !== blockNumber) setBlockNumber(block);
			})
			.catch(error => {
				console.error(
					`Failed to get block number for chainId ${currentChainId}`,
					error
				);
			});
	};

	return {
		blockNumber,
		refechBlockNumber
	};
}

const GET_BLOCK_NUMBER = gql`
	query BlockNumbers($timestamp: String!, $timestamp7d: String!) {
		blocks(first: 1, orderBy: timestamp, orderDirection: desc) {
			id
			number
			timestamp
		}
		blocksBefore: blocks(
			first: 1
			where: { timestamp_lte: $timestamp }
			orderBy: timestamp
			orderDirection: desc
		) {
			timestamp
			number
		}
		blocksBefore7d: blocks(
			first: 1
			where: { timestamp_lte: $timestamp7d }
			orderBy: timestamp
			orderDirection: desc
		) {
			timestamp
			number
		}
	}
`;

export function useGetBlockNumbers() {
	const { currentChainId, currentChain } = useCurrentChain();

	const [blockLatest, setBlockLatest] = useState<number>(0);
	const [blockLatestTimestamp, setBlockLatestTimestamp] = useState<number>(0);
	const [blockBefore24h, setBlockBefore24h] = useState<number>(0);
	const [blockBefore7d, setBlockBefore7d] = useState<number>(0);

	const blockClient = useMemo(() => {
		return new ApolloClient({
			uri: BLOCK_GRAPH_URL[currentChainId],
			cache: new InMemoryCache()
		});
	}, [currentChainId]);

	const refechBlockNumbers = () => {
		if (!currentChain.testnet) {
			return;
		}
		const timeStampBefore24h = moment().subtract(1, 'days').unix().toString();
		const timeStampBefore7d = moment().subtract(7, 'days').unix().toString();

		blockClient
			.query({
				query: GET_BLOCK_NUMBER,
				variables: {
					timestamp: timeStampBefore24h,
					timestamp7d: timeStampBefore7d
				}
			})
			.then(result => {
				const blockInfoOfLastest = result.data.blocks[0];
				const blockInfoOfBefore24h = result.data.blocksBefore[0];
				const blockInfoOfBefore7d = result.data.blocksBefore7d[0];

				setBlockLatest(blockInfoOfLastest.number);
				setBlockLatestTimestamp(blockInfoOfLastest.timestamp);

				if (
					!blockInfoOfBefore24h ||
					isLessThan(
						blockInfoOfBefore24h.number,
						START_BLOCK_NUMBER[currentChainId]
					)
				) {
					setBlockBefore24h(START_BLOCK_NUMBER[currentChainId]);
				} else {
					setBlockBefore24h(Number(blockInfoOfBefore24h.number));
				}

				if (
					!blockInfoOfBefore7d ||
					isLessThan(
						blockInfoOfBefore7d.number,
						START_BLOCK_NUMBER[currentChainId]
					)
				) {
					setBlockBefore7d(START_BLOCK_NUMBER[currentChainId]);
				} else {
					setBlockBefore7d(Number(blockInfoOfBefore7d.number));
				}
			})
			.catch(error => {
				console.error(`Failed to get block numbers: `, error);
			});
	};

	return {
		blockLatest,
		blockLatestTimestamp,
		blockBefore24h,
		blockBefore7d,
		refechBlockNumbers
	};
}
