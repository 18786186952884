import { useCallback, useEffect, useMemo, useState } from 'react';
import { DEFAULT_PRECISION } from 'config/constants';
import { Hash } from '@wagmi/core';
import ERC20ABI from 'config/abis/ERC20.json';
import FeeDistributorABI from 'config/abis/FeeDistributor.json';
import { erc20ABI, useAccount } from 'wagmi';
import { getContractAddress } from 'config/contracts';
import { BigNumber, ethers } from 'ethers';
import useWeb3Provider from 'hooks/useWeb3Provider';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { createMulticall } from 'utils';

import { TokenInfo } from '../types';
import { formatUnits, isGreaterThan, toAmountFloor } from '../utils';
import { useCurrentChain, useCurrentNativeCurrency } from './useCurrentChain';

export function useNativeCurrencyBalance() {
	const [isBalanceEnough, setIsBalanceEnough] = useState<boolean>(false);
	const [balance, setBalance] = useState<string>('');
	const [balanceText, setBalanceText] = useState<string>('');
	const { address, isConnected } = useAccount();
	const { nativeSymbol } = useCurrentNativeCurrency();
	const Web3Provider = useWeb3Provider();

	const onRefetch = useCallback(async () => {
		if (!isConnected || !Web3Provider || !address) {
			return;
		}
		Web3Provider.getBalance(address)
			.then((repBalance: BigNumber) => {
				const balance = ethers.utils.formatEther(repBalance);
				setBalance(balance);
				setBalanceText(`${toAmountFloor(balance)} ${nativeSymbol}`);
				if (isGreaterThan(balance, 0)) {
					setIsBalanceEnough(true);
				} else {
					setIsBalanceEnough(false);
				}
			})
			.catch((e: any) => {
				console.log('error', e);
			});
	}, [Web3Provider, address, isConnected]);

	return {
		nativeBalance: balance,
		nativeBalanceText: balanceText,
		isBalanceEnough,
		refetchNativeBalance: onRefetch
	};
}

export function useTokenBalance(token: TokenInfo | null) {
	const Web3Provider = useWeb3Provider();
	const { address, isConnected } = useAccount();
	const [balance, setBalance] = useState('');

	const { nativeBalance } = useAppSelector(txBaseState);

	const { currentChainId } = useCurrentChain();

	const addressOrName = useMemo(() => {
		if (!token) return '';
		return isConnected && address && !token.isNative ? token.address || '' : '';
	}, [token, address, isConnected]);

	const contract = useMemo(() => {
		if (!Web3Provider || !addressOrName) {
			return null;
		}
		return new ethers.Contract(addressOrName, erc20ABI, Web3Provider);
	}, [Web3Provider, addressOrName, erc20ABI]);

	const refetchBalance = useCallback(async () => {
		if (!contract || !token) {
			return;
		}
		try {
			const repBalance = await contract.balanceOf(address);
			setBalance(formatUnits(repBalance, token.decimals));
		} catch (e: any) {
			setBalance('');
		}
	}, [contract, token, address, currentChainId]);

	const balanceText = useMemo(() => {
		if (!balance) {
			return '-';
		}
		return toAmountFloor(balance);
	}, [balance]);

	useEffect(() => {
		if (!token || !address || !isConnected) {
			setBalance('');
			return;
		}
		if (token.isNative) {
			setBalance(nativeBalance);
		} else {
			refetchBalance();
		}
	}, [token, nativeBalance, address, isConnected, currentChainId]);

	return {
		balance,
		balanceText,
		refetchBalance
	};
}

export function useTokensBalances(tokens: Array<TokenInfo> | null) {
	const Web3Provider = useWeb3Provider();
	const { multicallv3 } = createMulticall(Web3Provider);
	const { currentChainId } = useCurrentChain();
	const { address, isConnected } = useAccount();
	const [balances, setBalances] = useState<Array<string> | null>(null);

	const contracts = useMemo(() => {
		if (!tokens || !address || !isConnected) {
			return undefined;
		}
		return tokens.map((token: TokenInfo) => {
			return {
				address: token.address as Hash,
				abi: ERC20ABI,
				name: 'balanceOf',
				params: [address]
			};
		});
	}, [tokens, address, isConnected]);

	const refetchBalances = useCallback(async () => {
		if (!contracts) {
			return;
		}
		try {
			const data = await multicallv3({
				calls: contracts ? contracts : [],
				chainId: currentChainId
			});
			if (data) {
				const results = data.map((repBalance: BigNumber, index: number) => {
					return repBalance.toString()
						? formatUnits(repBalance.toString(), tokens[index].decimals)
						: null;
				});
				setBalances(results);
			}
		} catch (e: any) {
			setBalances([]);
		}
	}, [multicallv3, contracts, address, currentChainId]);

	return {
		balances,
		refetch: refetchBalances
	};
}

export function useFeeInfo() {
	const Web3Provider = useWeb3Provider();
	const { multicallv3 } = createMulticall(Web3Provider);
	const { currentChainId } = useCurrentChain();
	const { address } = useAccount();
	const [balances, setBalances] = useState<Array<string> | null>(null);

	const contracts = useMemo(() => {
		return [{
			address: getContractAddress(currentChainId, 'FeeDistributor') as Hash,
			abi: FeeDistributorABI.abi,
			name: 'totalStakedWithMultiplier',
			params: []
		}]
	}, [currentChainId]);

	const refetchFeeInfo = useCallback(async () => {
		if (!contracts) {
			return;
		}
		try {
			const data = await multicallv3({
				calls: contracts ? contracts : [],
				chainId: currentChainId
			});

			if (data) {
				const results = data.map((repBalance: BigNumber) => {
					return repBalance.toString()
						? formatUnits(repBalance.toString(), DEFAULT_PRECISION)
						: null;
				});
				setBalances(results);
			}

		} catch (e: any) {
			setBalances([]);
		}
	}, [multicallv3, contracts, address, currentChainId]);

	return {
		feeInfo:balances,
		refetch: refetchFeeInfo
	};
}