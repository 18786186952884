import { Env } from 'config/constants';
import Decimal from 'decimal.js';

import { ethers } from 'ethers';

import { isNumeric } from './checkInputNumberic';
import { isEmptyAmount, toDecimalPlaces } from './formatCurrencyAmount';
import {
	abs,
	div,
	isEqualTo,
	isLessThan,
	isNegative,
	isPositive
} from './mathUtils';

/**
 * Formats all numbers with a dollar value
 * 123456.789 ===> 123,456.78
 * @param value
 * @param precision
 * @param tiny
 * @returns
 */
export const formatNumber = (
	value: string | number = '',
	precision = 2,
	tiny = true,
	isRound = false
) => {
	if (value === '') {
		return '-';
	}
	if (isEqualTo(value, '0')) {
		return '0';
	}
	try {
		const _value = toDecimalPlaces(
			value,
			precision,
			isRound ? Decimal.ROUND_HALF_EVEN : Decimal.ROUND_DOWN
		);
		if (tiny && isLessThan(abs(value), div(1, Decimal.pow(10, precision)))) {
			return `${isNegative(_value) ? '<-' : '<'}${div(
				1,
				Decimal.pow(10, precision)
			)}`;
		}
		const n = String(_value);
		const p = n.indexOf('.');
		return n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, (m, i) =>
			p < 0 || i < p ? `${m},` : m
		);
	} catch (error: any) {
		console.error(error.message);
		return '-';
	}
};

export function toQuoteAmount(
	value: Decimal.Value,
	precision = 2,
	roundingMode: Decimal.Rounding = Decimal.ROUND_DOWN,
	plusSymbol = false
): string {
	try {
		const isZero = isEmptyAmount(value);
		if (isZero) {
			return '0.00';
		}
		const _value = new Decimal(value);
		const threshold = div(1, Decimal.pow(10, precision));

		if (_value.gte(0) && _value.lt(threshold)) {
			const thresholdNumber = new Decimal(threshold).toFixed(
				precision,
				roundingMode
			);
			if (plusSymbol) {
				return `<+${thresholdNumber}`;
			}
			return `<${thresholdNumber}`;
		}
		if (_value.gt(`-${threshold}`) && _value.lt(0)) {
			return `<-${threshold}`;
		}
		if (isPositive(_value)) {
			if (plusSymbol) {
				return (
					'+' + formatNumber(_value.toFixed(precision, roundingMode), precision)
				);
			}
			return formatNumber(_value.toFixed(precision, roundingMode), precision);
		} else {
			return formatNumber(_value.toFixed(precision, roundingMode), precision);
		}
	} catch (e) {
		return String(value || '-');
	}
}

export function toUsd(
	value: Decimal.Value,
	precision = 2,
	roundingMode: Decimal.Rounding = Decimal.ROUND_DOWN,
	plusSymbol = false
): string {
	try {
		const isZero = isEmptyAmount(value);
		if (isZero) {
			return '$0.00';
		}
		const _value = new Decimal(value);
		const threshold = div(1, Decimal.pow(10, precision));

		if (_value.gte(0) && _value.lt(threshold)) {
			if (plusSymbol) {
				return `<+$${threshold}`;
			}
			return `<$${threshold}`;
		}
		if (_value.gt(`-${threshold}`) && _value.lt(0)) {
			return `<-$${threshold}`;
		}
		if (isPositive(_value)) {
			if (plusSymbol) {
				return (
					'+$' +
					formatNumber(_value.toFixed(precision, roundingMode), precision)
				);
			}
			return (
				'$' + formatNumber(_value.toFixed(precision, roundingMode), precision)
			);
		} else {
			return (
				'-$' +
				formatNumber(_value.abs().toFixed(precision, roundingMode), precision)
			);
		}
	} catch (e) {
		return String(value || '-');
	}
}

/**
 * Used to format the position size
 * @param value
 * @param precision
 * @returns
 */
export function formatSize(value: string | number = '', precision = 4) {
	return formatNumber(value, precision);
}

/**
 * Extension of ethers.utils.parseUnits
 * parseUnits(2.4, 6) ===> 2.4 * 10 ^ 6
 * @param value
 * @param precision
 * @returns
 */
export function parseUnits(value: string | number, precision?: number) {
	if (!isNumeric(value)) {
		return '0';
	}
	if (import.meta.env.MODE === Env.development) {
		return new Decimal(
			ethers.utils
				.parseUnits(toDecimalPlaces(value, precision), precision)
				.toString()
		).toFixed();
	}
	try {
		return new Decimal(
			ethers.utils
				.parseUnits(toDecimalPlaces(value, precision), precision)
				.toString()
		).toFixed();
	} catch (error: any) {
		console.error(error.message);
		return '0';
	}
}

/**
 * Extension of ethers.utils.formatUnits
 * parseUnits(2.4, 6) ===> 2.4 / 10 ^ 6
 * @param value
 * @param precision
 * @returns
 */
export function formatUnits(value: string, precision?: number): string {
	if (!isNumeric(value)) {
		return '0';
	}
	if (import.meta.env.MODE === Env.development) {
		return new Decimal(
			ethers.utils.formatUnits(toDecimalPlaces(value, 0), precision).toString()
		).toFixed();
	}
	try {
		return new Decimal(
			ethers.utils.formatUnits(toDecimalPlaces(value, 0), precision).toString()
		).toFixed();
	} catch (error: any) {
		console.error(error.message);
		return '0';
	}
}

export function amountFormatter(
	value: string | number,
	digits = 2,
	positionUnits = 2,
	limit = 1000,
	isZeroMark = false
): string {
	let str = '';
	try {
		const amount = new Decimal(value);
		if (amount.isZero()) {
			return isZeroMark ? '-' : '0';
		}
		if (amount.lte(limit)) {
			str = formatNumber(value, positionUnits);
			return str;
		}

		const si = [
			{ value: 1, symbol: '' },
			{ value: 1e3, symbol: 'K' },
			{ value: 1e6, symbol: 'M' },
			{ value: 1e9, symbol: 'B' },
			{ value: 1e12, symbol: 'T' },
			{ value: 1e15, symbol: 'P' },
			{ value: 1e18, symbol: 'E' }
		];
		let i;
		for (i = si.length - 1; i > 0; i--) {
			if (amount.gte(si[i].value)) {
				break;
			}
		}
		return (
			// toDecimalPlaces(num.div(si[i].value), digits, false).replace(rx, "$1") +
			amount.div(si[i].value).toFixed(digits) + si[i].symbol
		);
	} catch (e: any) {
		str = '--';
	}
	return str;
}
