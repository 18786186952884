import { useMemo } from 'react';

import { useAccount } from 'wagmi';

import _ from 'lodash';
import { IRequestItem } from 'types';

import { useIncreaseRiskBufferFundPositionRequestsQuery } from './__generated_trade__/types-and-hooks';

const useIncreaseRBFPositionRequests = () => {
	const { address } = useAccount();

	const {
		data,
		networkStatus,
		refetch
	} = useIncreaseRiskBufferFundPositionRequestsQuery({
		variables: { account: address },
		notifyOnNetworkStatusChange: true
	});

	const requestData = useMemo(() => {
		if (!data || !data.increaseRiskBufferFundPositionRequests) {
			return null;
		}
		return data.increaseRiskBufferFundPositionRequests as Array<IRequestItem>;
	}, [data]);

	return { networkStatus, requestData, refetch };
};

export default useIncreaseRBFPositionRequests;
