import { useCallback, useEffect, useMemo, useState } from 'react';

import { t } from '@lingui/macro';
import { Hash } from '@wagmi/core';
import { useAccount } from 'wagmi';

import { routerV2ABI } from '../../config/abis';
import { Transaction_Type } from '../../config/constants';
import { getContractAddressV2 } from '../../config/contracts';
import { useAppSelector } from '../../state/hooks';
import { txBaseState } from '../../state/tx/slice';
import { RecordForApprove } from '../../types';
import { useContract } from '../useContract';
import { useCheckLogin, useCurrentChain } from '../useCurrentChain';
import { useSendTransaction } from '../useSendTransaction';

const useApprovalPluginV2 = (plugInAddress: string, revokePlugin = false) => {
	const { txVisibleSuccess, quoteBalance } = useAppSelector(txBaseState);

	const { address } = useAccount();
	const isLogin = useCheckLogin();
	const { currentChainId } = useCurrentChain();

	const routerContractAddress = useMemo(() => {
		return getContractAddressV2(currentChainId, 'Router');
	}, [currentChainId]);

	const [isPluginApproved, setIsPluginApproved] = useState(true);

	const contract = useContract(routerContractAddress, routerV2ABI);
	const inputs = useMemo(
		() => (address ? [address, plugInAddress] : undefined),
		[address, plugInAddress]
	);
	const fetch = useCallback(async () => {
		try {
			if (contract && inputs) {
				const data = await contract.isPluginApproved(...inputs);
				setIsPluginApproved(data);
			}
		} catch (e) {
			console.log(
				`[useApprovalPlugin] fetch isPluginApproved ${plugInAddress} Fail`
			);
		}
	}, [contract, inputs]);

	useEffect(() => {
		if (!contract || !isLogin || !inputs) {
			return;
		}
		fetch();
	}, [contract, inputs, isLogin, address, txVisibleSuccess, quoteBalance]);

	const {
		isConfirming: isPluginApproving,
		isConfirmed,
		onSendTransaction,
		error
	} = useSendTransaction(
		{
			mode: 'recklesslyUnprepared',
			address: routerContractAddress as Hash,
			abi: routerV2ABI,
			functionName: revokePlugin ? 'revokePlugin' : 'approvePlugin',
			args: [plugInAddress]
		},
		undefined,
		true
	);

	useEffect(() => {
		if (isConfirmed) {
			setIsPluginApproved(true);
		}
	}, [isConfirmed]);

	const onApprovePlugin = useCallback(() => {
		const record = {
			transactionType: Transaction_Type.Approve,
			symbolIn: 'Operation'
		} as RecordForApprove;
		onSendTransaction(
			record,
			revokePlugin ? t`Revoking Operation` : t`Approval Successful`
		);
	}, [onSendTransaction]);

	return {
		isPluginApproved,
		isPluginApproving,
		isConfirmed,
		onApprovePlugin,
		error
	};
};

export default useApprovalPluginV2;
