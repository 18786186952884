import { ReactNode } from 'react';

import { Box, Typography } from '@mui/material';
import classNames from 'classnames';

export default function Description({
	label,
	children,
	className = '',
	labelClassName = '',
	align,
	vertical,
	valueWrap = false
}: {
	label: ReactNode;
	children: ReactNode;
	className?: string;
	labelClassName?: string;
	align?: 'left' | 'right' | 'center';
	vertical?: boolean;
	valueWrap?: boolean;
}) {
	return (
		<Box
			className={classNames('sm:block sm:leading-4', className, {
				'flex-col space-y-1': vertical,
				'flex items-center space-x-3 sm:space-x-0': !vertical
			})}
			sx={{
				'& .MuiTypography-root': {
					textAlign: align
				}
			}}
		>
			<Typography
				variant='body2'
				color='secondary'
				className={classNames('sm:mr-0', labelClassName, {
					'whitespace-nowrap': !valueWrap
				})}
				align={align}
			>
				{label}
			</Typography>
			{children}
		</Box>
	);
}
