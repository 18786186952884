import { useState } from 'react';

import { Trans } from '@lingui/macro';
import { useTheme } from '@mui/material';
import { useAccount } from 'wagmi';

import StyledTabs from '../../../components/Common/StyledTabs';
import { History_Filter_Type } from '../../../config/constants';
import { StyledButton } from '../../components/Operations/HistoryList';
import LiquidityPositionHistory from './LiquidityPositionHistory';
import LiquidityTradeHistoriesV2 from './LiquidityTradeHistoriesV2';

const LiquidityHistoryV2 = ({ market }: { market: string }) => {
	const theme = useTheme();
	const { address } = useAccount();

	const [historyType, setHistoryType] = useState(History_Filter_Type.Trade);

	return (
		<>
			<StyledTabs
				value={0}
				size='large'
				variant='scrollable'
				scrollButtons='auto'
				allowScrollButtonsMobile
				sx={{
					'& [aria-selected=false]': {
						fontWeight: 400
					},
					'& .MuiTabs-scroller': {
						margin: 0
					},
					'& .MuiTabScrollButton-root:first-of-type': {
						left: '-5px',
						top: 0,
						height: '32px',
						width: '36px',
						backgroundImage: `linear-gradient(-270deg,${theme.custom.paperBg} 72.22%,hsla(0,0%,7%,0) 100%)`,
						opacity: 1,
						zIndex: 100,
						justifyContent: 'start'
					},
					'& .MuiTabScrollButton-root:last-of-type': {
						right: '-5px',
						top: 0,
						height: '32px',
						width: '36px',
						backgroundImage: `linear-gradient(270deg,${theme.custom.paperBg} 72.22%,hsla(0,0%,7%,0) 100%)`,
						opacity: 1,
						justifyContent: 'end'
					}
				}}
			>
				<section className='flex space-x-2'>
					<StyledButton
						className='block'
						variant='outlined'
						size='small'
						onClick={() => setHistoryType(History_Filter_Type.Trade)}
						selected={historyType === History_Filter_Type.Trade}
					>
						<Trans>Trade History</Trans>
					</StyledButton>
					<StyledButton
						className='block'
						variant='outlined'
						size='small'
						color='secondary'
						onClick={() => setHistoryType(History_Filter_Type.Position)}
						selected={historyType === History_Filter_Type.Position}
					>
						<Trans>Position History</Trans>
					</StyledButton>
				</section>
			</StyledTabs>
			{historyType === History_Filter_Type.Position && (
				<div className='mt-4'>
					<LiquidityPositionHistory key={address} market={market} />
				</div>
			)}
			{historyType === History_Filter_Type.Trade && (
				<div className='mt-4'>
					<LiquidityTradeHistoriesV2 key={address} market={market} />
				</div>
			)}
		</>
	);
};

export default LiquidityHistoryV2;
