import * as React from 'react';

import { Trans } from '@lingui/macro';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Avatar,
	Box,
	List,
	ListItem,
	Menu,
	MenuItem,
	MenuItemProps,
	MenuProps,
	Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import cn from 'classnames';
import { SUPPORTED_CHAINS } from 'config/constants/connector/chains';
import { ChainConfig } from 'config/constants/connector/types';
import { useAccount, useNetwork } from 'wagmi';

import warningIcon from 'assets/svg/icon-warning.svg';
import ArbitrumLogo from 'assets/svg/networks/arbitrum-logo.svg';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import { useSwitchNetwork } from 'hooks/useSwitchNetwork';

export const StyledMenu = styled(Menu)<MenuProps>(() => {
	return {
		'.MuiMenu-list': {
			paddingTop: '24px',
			paddingBottom: '12px'
		}
	};
});

export const StyledMenuItem = styled(MenuItem)<MenuItemProps>(({ theme }) => {
	return {
		'&.Mui-selected': {
			background: 'transparent',
			'&:hover': {
				textDecoration: 'none',
				backgroundColor: theme.palette.action.hover
			}
		}
	};
});

export default function NetworkDropdown() {
	const { onSwitchNetwork } = useSwitchNetwork();
	const theme = useTheme();
	const isLogin = useCheckLogin();
	const { chain: walletCurrentChain } = useNetwork();
	const { isConnected } = useAccount();
	const { isSupportedChain, currentChain } = useCurrentChain();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const { isMatchMobile } = useAppBreakpoints();
	const onHandleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const onHandleMenuItemClick = (chain: ChainConfig) => {
		if (walletCurrentChain?.id !== currentChain.id) {
			onSwitchNetwork(chain);
		}

		setAnchorEl(null);
	};

	const onHandleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<List component='nav' aria-label='Device settings'>
				<ListItem
					id='lock-button'
					aria-haspopup='listbox'
					aria-controls='lock-menu'
					aria-label='when device is locked'
					aria-expanded={open ? 'true' : undefined}
					onClick={onHandleClickListItem}
					className='p-0'
				>
					<Box
						className='flex space-x-2'
						sx={{
							borderRadius: '4px',
							padding: '4px 8px',
							background: theme.custom.selectBg,
							fontSize: '14px',
							fontWeight: 500,
							color: theme.palette.text.primary,
							'&:hover': {
								background: theme.palette.action.hover
							}
						}}
					>
						<Box className='flex items-center cursor-pointer space-x-1 sm:space-x-0'>
							<Avatar
								className={cn(!isSupportedChain && !isMatchMobile && 'mt-0.5')}
								src={
									isConnected
										? isSupportedChain
											? ArbitrumLogo
											: warningIcon
										: ArbitrumLogo
								}
								sx={{
									width: isMatchMobile ? 18 : 22,
									height: isMatchMobile ? 18 : 22
								}}
							/>

							{anchorEl ? (
								isMatchMobile ? (
									<ArrowDropUpIcon />
								) : (
									<ExpandLessIcon />
								)
							) : isMatchMobile ? (
								<ArrowDropDownIcon />
							) : (
								<ExpandMoreIcon />
							)}
						</Box>
					</Box>
				</ListItem>
			</List>
			<StyledMenu
				id='lock-menu'
				anchorEl={anchorEl}
				className='translate-y-1.5 transform'
				open={open}
				onClose={onHandleClose}
				MenuListProps={{
					'aria-labelledby': 'lock-button',
					role: 'listbox'
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
			>
				<Typography className='font-Bold ml-4 pb-1 text-sm' color='secondary'>
					<Trans>Select a network</Trans>
				</Typography>
				{SUPPORTED_CHAINS.map(chain => (
					<StyledMenuItem
						key={chain.id}
						// selected={chain.id === currentChain.id}
						onClick={() => onHandleMenuItemClick(chain)}
					>
						<Box className='flex items-center justify-between'>
							<Box className='flex space-x-1'>
								<Avatar src={chain.icon} sx={{ width: 22, height: 22 }} />
								<Box component='div'>{chain.name}</Box>
							</Box>
							{isLogin && chain.id === currentChain.id && isSupportedChain && (
								<Box
									component='div'
									className='ml-2 h-1.5 w-1.5 rounded-full'
									sx={{
										backgroundColor: theme.palette.success.main
									}}
								></Box>
							)}

							{!isLogin && chain.id === walletCurrentChain?.id && (
								<Box
									component='div'
									className='ml-2 h-1.5 w-1.5 rounded-full'
									sx={{
										backgroundColor: theme.palette.success.main
									}}
								></Box>
							)}
						</Box>
					</StyledMenuItem>
				))}
			</StyledMenu>
		</div>
	);
}
