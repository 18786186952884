import { Reducer, createSlice } from '@reduxjs/toolkit';

import { IPositionMiningPools } from 'graphql/usePositionRewardsGraph';
import { RootState } from 'state';

interface StateProps {
	isLoadingPositionList: boolean;
	positionList: Array<IPositionMiningPools> | null;
	signatureForAll: string;
}

const initialState: StateProps = {
	positionList: null,
	isLoadingPositionList: true,
	signatureForAll: ''
};

export const slice = createSlice({
	name: 'earn',
	initialState,
	reducers: {
		setIsLoadingPositionList(state, { payload }) {
			state.isLoadingPositionList = payload;
		},
		setPositionList(state, { payload }) {
			state.positionList = payload;
		},
		setSignatureForAll(state, { payload }) {
			state.signatureForAll = payload;
		}
	}
});

export const { setIsLoadingPositionList, setPositionList, setSignatureForAll } =
	slice.actions;

export const earnPositionBaseState = (state: RootState) => state.earnPosition;

export default slice.reducer as Reducer<typeof initialState>;
