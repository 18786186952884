import { Trans } from '@lingui/macro';
import { Typography, useTheme } from '@mui/material';

import discountIcon from '../../assets/svg/icon-discount.svg';
import freeIcon from '../../assets/svg/icon-free.svg';
import warningIcon from '../../assets/svg/icon-warning.svg';
import Tooltip from '../../components/Common/Tooltip';

export enum Type_Free_Trading_Fee {
	Warning = 'Warning',
	Free = 'Free',
	Discount = 'Discount'
}

const FreeTradingFeeWarning = ({
	type = Type_Free_Trading_Fee.Free
}: {
	type?: Type_Free_Trading_Fee;
}) => {
	const theme = useTheme();
	return (
		<Tooltip
			placement='bottom'
			title={
				<>
					{type !== Type_Free_Trading_Fee.Discount && (
						<Trans>
							<Typography
								component='span'
								variant='body2'
								color={theme.custom.orangeColor}
							>
								0 Trading Fee
							</Typography>{' '}
							for BTC, effective until May 31, 2024. Eligibility applies to
							anyone binding a referral code.
						</Trans>
					)}
					{type === Type_Free_Trading_Fee.Discount && (
						<Trans>
							<Typography
								component='span'
								variant='body2'
								color={theme.custom.orangeColor}
							>
								90% Off
							</Typography>{' '}
							on trading fees for BTC & ETH, effective until March 1, 2024.
							Eligibility applies to anyone binding a referral code.
						</Trans>
					)}
				</>
			}
		>
			{type === Type_Free_Trading_Fee.Free ? (
				<img className='mt-0.5' width={26} src={freeIcon} alt='' />
			) : type === Type_Free_Trading_Fee.Warning ? (
				<img className='mt-0.5' width={14} src={warningIcon} alt='' />
			) : (
				<img
					className='mt-0.5'
					width={42}
					height={14}
					src={discountIcon}
					alt=''
				/>
			)}
		</Tooltip>
	);
};

export default FreeTradingFeeWarning;
