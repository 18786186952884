import { Reducer, createSlice } from '@reduxjs/toolkit';

import { IPoolInfo } from 'graphql/usePoolInfoGraph';
import { RootState } from 'state';
import { IPoolItem } from 'types';

export interface StateProps {
	pools: Array<IPoolItem>;
	poolMap: Map<string, IPoolItem>;
	liquidityPools: Array<any>;
	poolAddress: string | undefined;
	poolInfo: IPoolInfo | null;
}

export const initialState: StateProps = {
	pools: [],
	poolMap: new Map<string, IPoolItem>(),
	liquidityPools: [],
	poolAddress: undefined,
	poolInfo: null
};

export const reducers = {
	setV2Pools(state, { payload }) {
		const selectPoolById = (id: string) => {
			return state.V2.pools.find(pool => pool.id === id);
		};
		const refactorPools = payload.map((pool: IPoolItem) => {
			const currentPool = selectPoolById(pool.id);
			if (Number(pool.market_price) > Number(currentPool?.market_price)) {
				return { ...pool, priceChangeDirection: 'up' };
			} else if (
				Number(pool.market_price) < Number(currentPool?.market_price)
			) {
				return { ...pool, priceChangeDirection: 'down' };
			} else {
				return {
					...pool,
					priceChangeDirection:
						Number(pool.market_price) === Number(currentPool?.market_price)
							? currentPool?.priceChangeDirection
							: ''
				};
			}
		});
		state.V2.pools = refactorPools;
	},
	setV2PoolMap(state, { payload }) {
		const map = new Map();
		payload.forEach((item) => {
			map.set(item.id, item)
		})
		state.V2.poolMap = map;
	},
	setV2LiquidityPools(state, { payload }) {
		state.V2.liquidityPools = payload;
	},
	setV2PoolAddress(state, { payload }) {
		state.V2.poolAddress = payload;
	},
	setV2PoolInfo(state, { payload }) {
		state.V2.poolInfo = payload;
	}
}

export const poolsV2BaseState = (state: RootState) => state.pools.V2;
