import { ReactNode, useCallback, useMemo, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { Box, Divider, IconButton, Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import {
	MAX_AMOUNT_FORMATTER_LIMIT,
	Order_Open_Type_V1, // Order_Open_Type,
	// Order_Status,
	Profit_Loss_Type,
	QUOTE_USD,
	Side,
	Transaction_Status,
	Transaction_Type,
	Version
} from 'config/constants';
import Decimal from 'decimal.js';
import moment from 'moment';

import { TransactionRecord } from 'entities/TransactionRecord';
import { IOrderItem } from 'graphql/useMyOrdersGraph';
import { IPositionItem } from 'graphql/useMyPositionsGraph';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { useSubmitCancelLimitOrder } from 'hooks/useSubmitCancelLimitOrder';
import { useSubmitCancelProfitLoss } from 'hooks/useSubmitCancelProfitLoss';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { selectTransactionRecordList } from 'state/records/selector';
import {
	selectOrderOpenType,
	selectOrderStatus,
	selectVersion
} from 'state/setting/selector';
import { txBaseState } from 'state/tx/slice';
import type { Merge } from 'type-fest';
import {
	RecordForCancelAllOrders,
	RecordForCreateProfitLossPartial,
	RecordForCreateProfitLossPosition,
	RecordForLimitOrder
} from 'types';
import {
	amountFormatter,
	checkOrderIsExpired,
	formatSize,
	formatUnixDate,
	getPrefixForLimit,
	getPrefixForProfitLoss,
	getProfitLossTitle,
	isGreaterThan,
	isPositive,
	shortenSymbolNative,
	toQuoteAmount
} from 'utils';

import { DirectionCell } from 'components/Common/Cell';
import StyledButton from 'components/Common/StyledButton';
import NoData from 'components/NoData';
import Stronger from 'components/Stronger';
import Edit from 'components/Svg/Icons/Edit';
import Loading from 'components/Svg/Icons/Loading';
import StyledTable from 'components/Table/StickyTable';

import ModifyTisDialog from '../ModifyTisDialog';
import UpdateProfitLossDialog from '../ProfitLoss/UpdateProfitLossDialog';
import TradeTriggerPriceDialog from '../TradeOrderPriceDialog';
import CancelAllOrders from './CancelAllOrders';

interface Column {
	id:
		| 'time'
		| 'market'
		| 'pool'
		| 'type'
		| 'side'
		| 'size'
		| 'payment'
		| 'triggerPrice'
		| 'marketPrice'
		| 'acceptablePrice'
		| 'operate';
	label: string | React.ReactNode;
	minWidth?: number;
	align?: 'right';
	format: (row: any) => void;
}

function Expired({
	tipType = Profit_Loss_Type.Entire
}: {
	tipType?: Profit_Loss_Type;
}) {
	const theme = useTheme();
	return (
		<Stronger
			value={
				<Box>
					<Typography
						variant='body2'
						className='opacity-50 leading-none'
						sx={{
							borderBottom: `1px dashed ${theme.palette.secondary.main}`,
							display: 'inline'
						}}
					>
						<Trans>Expired</Trans>
					</Typography>
				</Box>
			}
			tooltip={
				<div>
					<Typography color='textPrimary' variant='body2'>
						{tipType === Profit_Loss_Type.Entire ? (
							<Trans>
								This order has been temporarily invalidated. The order will
								remain in effect after a new position is opened.
							</Trans>
						) : (
							<Trans>
								The available closing quantity is insufficient. The order will
								not take effect until there is enough position quantity.
							</Trans>
						)}
					</Typography>
				</div>
			}
			isUnderline={false}
		/>
	);
}

export interface IOrderItemWithPosition extends IOrderItem {
	position: IPositionItem | null;
}

function converLimitOrder(txRecord: TransactionRecord) {
	const record = txRecord.record as RecordForLimitOrder;
	return {
		id: '',
		transactionType: record.transactionType,
		type: Order_Open_Type_V1.Increase,
		poolId: record.pool,
		txHash: txRecord.hash,
		index: '',
		side:
			record.transactionType === Transaction_Type.LongLimitOrder
				? Side.LONG
				: Side.SHORT,
		account: '',
		triggerAbove: record.triggerAbove,
		liquidityDelta: record.liquidityDelta,
		marginDelta: record.quoteAmount,
		sizeDelta: record.positionAmount,
		triggerMarketPrice: record.triggerMarketPrice,
		acceptableTradePrice: record.acceptableTradePrice,
		indexPrice: '',
		baseToken: record.baseToken,
		createdTimestamp: moment(txRecord.date).unix(),
		status: Transaction_Status.Pending,
		position: null,
		expired: false
	} as IOrderItemWithPosition;
}

function converProfitLossOrder(txRecord: TransactionRecord) {
	const record = txRecord.record as Merge<
		RecordForCreateProfitLossPosition,
		RecordForCreateProfitLossPartial
	>;
	return {
		id: '',
		transactionType: txRecord.type,
		type: Order_Open_Type_V1.Decrease,
		poolId: record.pool,
		txHash: txRecord.hash,
		index: '',
		side: record.side,
		account: '',
		triggerAbove: record.triggerAbove,
		liquidityDelta: '',
		marginDelta: '',
		sizeDelta: record.sizeDelta,
		triggerMarketPrice: record.triggerPrice,
		acceptableTradePrice: record.acceptablePrice,
		indexPrice: '',
		baseToken: record.baseToken,
		createdTimestamp: moment(txRecord.date).unix(),
		status: Transaction_Status.Pending,
		position: null,
		expired: false
	} as IOrderItemWithPosition;
}

export default function OpenOrdersList({
	list,
	positionMap,
	poolId,
	full = true
}: {
	list: Array<IOrderItem> | null;
	positionMap: Map<string, Array<IPositionItem>> | null;
	poolId?: string;
	full?: boolean;
}) {
	const theme = useTheme();
	const { signingMap } = useAppSelector(txBaseState);
	const transactionRecords = useAppSelector(selectTransactionRecordList);
	const { poolMap } = useAppSelector(poolsBaseState);
	const Order_Status = useAppSelector(selectOrderStatus);
	const Order_Open_Type = useAppSelector(selectOrderOpenType);
	const currentVersion = useAppSelector(selectVersion);

	const { isMatchMobile, isMatchPad } = useAppBreakpoints();

	const [modifyTisIsOpen, setModifyTisIsOpen] = useState(false);

	const [updateLimitOrderIsOpen, setUpdateLimitOrderIsOpen] = useState(false);
	const [updateProfitLossIsOpen, setUpdateProfitLossIsOpen] = useState(false);

	const [currentOrder, setCurrentOrder] =
		useState<IOrderItemWithPosition | null>(null);
	const { currentChainId } = useCurrentChain();

	const signingCanceledRecord = useMemo(() => {
		const signingCanceledRecord =
			signingMap.get(Transaction_Type.CancelledLimitOrder) ||
			signingMap.get(Transaction_Type.CancelTakeProfitPosition) ||
			signingMap.get(Transaction_Type.CancelStopLossPosition) ||
			signingMap.get(Transaction_Type.CancelTakeProfitPartial) ||
			signingMap.get(Transaction_Type.CancelStopLossPartial) ||
			null;
		return signingCanceledRecord;
	}, [signingMap]);

	const signingCanceledAllRecord = useMemo(() => {
		return signingMap.get(
			Transaction_Type.CancelAllOrders
		) as RecordForCancelAllOrders;
	}, [signingMap]);

	const { createdList, createdLimitList, createdProfitLossList } =
		useMemo(() => {
			if (!list || list.length === 0) {
				return {
					createdList: null,
					createdLimitList: null,
					createdProfitLossList: null
				};
			}
			const filteredList = list.filter((item: IOrderItem) => {
				return item.orderStatus === Order_Status.Created;
			});
			const createdListWithPostion: Array<IOrderItemWithPosition> =
				filteredList.map((item: IOrderItem) => {
					if (!positionMap || !positionMap.has(item.poolId)) {
						return {
							...item,
							position: null
						};
					}
					const currentPositionList = positionMap.get(item.poolId);
					const targetPosition =
						currentPositionList?.find((positionItem: IPositionItem) => {
							return item.side === positionItem.side;
						}) || null;
					return {
						...item,
						position: targetPosition
					};
				});
			const createdList = _.orderBy(
				createdListWithPostion,
				'createdTimestamp',
				'desc'
			);
			const createdLimitList = createdList.filter(
				(item: IOrderItemWithPosition) => {
					return item.type === Order_Open_Type.Increase;
				}
			);
			const createdProfitLossList = createdList.filter(
				(item: IOrderItemWithPosition) => {
					return item.type === Order_Open_Type.Decrease;
				}
			);
			return {
				createdList,
				createdLimitList,
				createdProfitLossList
			};
		}, [list, positionMap]);

	const {
		filteredRecordsFromLocal,
		addedOrderListFromLocal,
		updatedOrCanceledRecordsFromLocal
	} = useMemo(() => {
		if (!transactionRecords || !transactionRecords.length) {
			return {
				filteredRecordsFromLocal: null,
				addedOrderListFromLocal: null,
				updatedOrCanceledRecordsFromLocal: null
			};
		}
		const filteredRecordsFromLocal = [] as Array<TransactionRecord>;
		const addedOrderListFromLocal = [] as Array<IOrderItemWithPosition>;
		const updatedOrCanceledRecordsFromLocal = [] as Array<TransactionRecord>;
		let filteredTransactionRecords = transactionRecords;
		if (poolId) {
			filteredTransactionRecords = transactionRecords.filter(
				(item: TransactionRecord) => {
					return item.record.pool === poolId;
				}
			);
		}
		filteredTransactionRecords.forEach((txRecord: TransactionRecord) => {
			const record = txRecord.record;

			if (
				// 创建 Limit 订单
				record.transactionType === Transaction_Type.LongLimitOrder ||
				record.transactionType === Transaction_Type.ShortLimitOrder
			) {
				const convertedOrder = converLimitOrder(txRecord);
				addedOrderListFromLocal.push(convertedOrder);
				filteredRecordsFromLocal.push(txRecord);
				return;
			}

			if (
				// 创建止盈和止损
				record.transactionType === Transaction_Type.CreateTakeProfitPosition ||
				record.transactionType === Transaction_Type.CreateStopLossPosition ||
				record.transactionType === Transaction_Type.CreateTakeProfitPartial ||
				record.transactionType === Transaction_Type.CreateStopLossPartial
			) {
				const convertedOrder = converProfitLossOrder(txRecord);
				addedOrderListFromLocal.push(convertedOrder);
				filteredRecordsFromLocal.push(txRecord);
				return;
			}

			if (
				// 更新 Limit 订单
				record.transactionType === Transaction_Type.UpdateLimitOrder ||
				record.transactionType === Transaction_Type.CancelledLimitOrder
			) {
				updatedOrCanceledRecordsFromLocal.push(txRecord);
				filteredRecordsFromLocal.push(txRecord);
				return;
			}

			if (
				// 更新止盈和止损
				record.transactionType === Transaction_Type.UpdateTakeProfitPosition ||
				record.transactionType === Transaction_Type.UpdateStopLossPosition ||
				record.transactionType === Transaction_Type.UpdateTakeProfitPartial ||
				record.transactionType === Transaction_Type.UpdateStopLossPartial ||
				// 取消止盈和止损
				record.transactionType === Transaction_Type.CancelTakeProfitPosition ||
				record.transactionType === Transaction_Type.CancelStopLossPosition ||
				record.transactionType === Transaction_Type.CancelTakeProfitPartial ||
				record.transactionType === Transaction_Type.CancelStopLossPartial
			) {
				updatedOrCanceledRecordsFromLocal.push(txRecord);
				filteredRecordsFromLocal.push(txRecord);
			}
		});

		return {
			filteredRecordsFromLocal,
			addedOrderListFromLocal,
			updatedOrCanceledRecordsFromLocal
		};
	}, [poolId, transactionRecords]);

	const hasCancelAllOrdersFromLocal = useMemo(() => {
		if (!transactionRecords || !transactionRecords.length) {
			return false;
		}
		const records = transactionRecords.filter((txRecord: TransactionRecord) => {
			return txRecord.type === Transaction_Type.CancelAllOrders;
		});
		return records && records.length > 0;
	}, [transactionRecords]);

	const singleLockDisabled = useMemo(() => {
		return (
			hasCancelAllOrdersFromLocal ||
			Boolean(signingCanceledRecord) ||
			Boolean(signingCanceledAllRecord)
		);
	}, [
		hasCancelAllOrdersFromLocal,
		signingCanceledRecord,
		signingCanceledAllRecord
	]);

	const computedList = useMemo(() => {
		const targetList = createdList;
		const targetAddedOrderList = addedOrderListFromLocal;

		// 1. 本地没有记录，直接显示订单列表，此时每一条记录都是Success状态
		if (!filteredRecordsFromLocal || filteredRecordsFromLocal.length === 0) {
			targetList?.forEach((item: IOrderItem) => {
				item.status = Transaction_Status.Success;
			});
			return targetList;
		}

		// 2. 没有graph order列表，将本地记录插入进来，此时每一条记录都是Pending状态
		if (!targetList || targetList.length === 0) {
			return addedOrderListFromLocal;
		}

		// 3. 如果当前order存在的话，设置loading状态，处理更新或者取消的数据
		const resultList = targetList.map((item: IOrderItemWithPosition) => {
			const targetLocalItem = _.find(
				updatedOrCanceledRecordsFromLocal,
				(txRecord: TransactionRecord) => {
					return txRecord.record.orderId === item.id;
				}
			);
			if (targetLocalItem) {
				return {
					...item,
					status: Transaction_Status.Pending
				};
			}
			return item;
		});

		const orderlist = _.orderBy(
			targetAddedOrderList
				? targetAddedOrderList.concat(resultList)
				: resultList,
			'createdTimestamp',
			'desc'
		);
		return orderlist;
	}, [
		createdList,
		addedOrderListFromLocal,
		updatedOrCanceledRecordsFromLocal,
		filteredRecordsFromLocal
	]);

	const computedListWithExpired = useMemo(() => {
		const list =
			computedList &&
			computedList.map(item => {
				if (checkOrderIsExpired(item, positionMap, Order_Open_Type)) {
					return { ...item, expired: true };
				}
				return item;
			});
		return list;
	}, [computedList]);

	const {
		onConfirm: onConfirmCancelProfitLoss,
		isConfirming: isConfirmingCancelProfitLoss,
		isConfirmed: isConfirmedCancelProfitLoss
	} = useSubmitCancelProfitLoss();

	const {
		onConfirm: onConfirmCancelLimitOrder,
		isConfirming: isConfirmingCancelLimitOrder,
		isConfirmed: isConfirmedCancelLimitOrder
	} = useSubmitCancelLimitOrder();

	const onOpenUpdateLimitOrderDialog = (item: IOrderItemWithPosition) => {
		setCurrentOrder(item);
		setUpdateLimitOrderIsOpen(true);
	};

	const onOpenUpdateProfitLossDialog = (item: IOrderItemWithPosition) => {
		setCurrentOrder(item);
		setUpdateProfitLossIsOpen(true);
	};

	const onCancelLimitOrderDialog = debounce((item: IOrderItemWithPosition) => {
		onConfirmCancelLimitOrder(
			item.poolId,
			item.index,
			item.id,
			item.triggerMarketPrice,
			item.triggerAbove,
			item.liquidityDelta,
			item.side,
			item.baseToken,
			item.sizeDelta
		);
	}, 300);

	const onCancelProfitLoss = debounce((item: IOrderItemWithPosition) => {
		onConfirmCancelProfitLoss(
			item.poolId,
			item.transactionType,
			item.index,
			item.triggerMarketPrice,
			item.side,
			item.baseToken,
			item.triggerAbove,
			isPositive(item.sizeDelta) ? item.sizeDelta : item.position?.size
		);
	}, 300);

	const onCloseUpdateLimitOrderDialog = () => {
		setCurrentOrder(null);
		setUpdateLimitOrderIsOpen(false);
	};

	const onCloseUpdateProfitLossDialog = () => {
		setCurrentOrder(null);
		setUpdateProfitLossIsOpen(false);
	};

	const onCloseModifyTisDialog = () => {
		setModifyTisIsOpen(false);
		setUpdateProfitLossIsOpen(true);
	};

	const columns: Column[] = useMemo(() => {
		return [
			{
				id: 'time',
				label: t`Time`,
				minWidth: 80,
				format: (row: IOrderItemWithPosition) => (
					<Typography
						variant='body2'
						className={row.expired ? 'opacity-50' : ''}
					>
						{formatUnixDate(row.createdTimestamp)}
					</Typography>
				)
			},
			{
				id: 'market',
				label: t`Market`,
				minWidth: 80,
				format: (row: IOrderItemWithPosition) => (
					<Typography
						variant='body2'
						className={row.expired ? 'opacity-50' : ''}
					>
						{`${shortenSymbolNative(
							row.baseToken,
							currentChainId
						)}/${QUOTE_USD}`}
					</Typography>
				)
			},
			{
				id: 'type',
				label: t`Type`,
				minWidth: 100,
				format: (row: IOrderItemWithPosition) => {
					const title = getProfitLossTitle(row.transactionType);
					if (row.type === Order_Open_Type.Increase) {
						return <Trans>Limit</Trans>;
					}
					let ComputedExpired: ReactNode | null;
					// 部分止盈止损或者新创建的记录
					if (isPositive(row.sizeDelta)) {
						if (row.position) {
							// 部分止盈止损：有效
							if (isGreaterThan(row.sizeDelta as string, row.position.size)) {
								// 数量不足：失效
								ComputedExpired = (
									<Expired tipType={Profit_Loss_Type.Partial} />
								);
							} else {
								ComputedExpired = null;
							}
						} else {
							// 新创建：有效
							if (row.status === Transaction_Status.Pending) {
								ComputedExpired = null;
							} else {
								// 部分止盈止损：失效
								ComputedExpired = (
									<Expired tipType={Profit_Loss_Type.Partial} />
								);
							}
						}
					} else {
						// 全仓止盈止损
						// 有效
						if (row.position) {
							ComputedExpired = null;
						} else {
							// 失效
							ComputedExpired = <Expired tipType={Profit_Loss_Type.Entire} />;
						}
					}
					if (isMatchMobile) {
						return (
							<div className='flex items-center space-x-1'>
								<Typography
									variant='body2'
									className={row.expired ? 'opacity-50' : ''}
								>
									{title}
								</Typography>
								{ComputedExpired}
							</div>
						);
					}
					return (
						<div>
							<Typography
								variant='body2'
								className={row.expired ? 'opacity-50' : ''}
							>
								{title}
							</Typography>
							{ComputedExpired}
						</div>
					);
				}
			},
			{
				id: 'side',
				label: t`Side`,
				minWidth: 60,
				format: (row: IOrderItemWithPosition) => (
					<Typography
						variant='body2'
						className={row.expired ? 'opacity-50' : ''}
						sx={{
							color:
								row.side === Side.LONG
									? theme.palette.success.main
									: theme.palette.error.main
						}}
					>
						{row.side === Side.LONG ? (
							<span>
								<Trans>Long</Trans>
							</span>
						) : (
							<span>
								<Trans>Short</Trans>
							</span>
						)}
					</Typography>
				)
			},
			{
				id: 'size',
				label: t`Size`,
				minWidth: 100,
				format: (row: IOrderItemWithPosition) => (
					<Typography
						variant='body2'
						className={classNames('truncate', {
							'opacity-50': row.expired
						})}
					>
						{isPositive(row.sizeDelta)
							? `${amountFormatter(
									row.sizeDelta,
									2,
									row.baseToken?.positionUnits,
									MAX_AMOUNT_FORMATTER_LIMIT
							  )} ${shortenSymbolNative(row.baseToken, currentChainId)}`
							: row.position
							? `${amountFormatter(
									row.position.size,
									2,
									row.baseToken?.positionUnits,
									MAX_AMOUNT_FORMATTER_LIMIT
							  )} ${shortenSymbolNative(row.baseToken, currentChainId)}`
							: '-'}
					</Typography>
				)
			},
			{
				id: 'triggerPrice',
				label: t`Trigger Price`,
				minWidth: 130,
				format: (row: IOrderItemWithPosition) => {
					let prefix = '';
					if (row.type === Order_Open_Type.Increase) {
						prefix = getPrefixForLimit(row.triggerAbove);
					} else if (row.type === Order_Open_Type.Decrease) {
						prefix = getPrefixForProfitLoss(row.triggerAbove, row.side);
					}
					return (
						<Box className='flex items-center sm:justify-end'>
							<Typography
								variant='body2'
								className={row.expired ? 'opacity-50' : ''}
							>
								{`${prefix}${toQuoteAmount(
									row.triggerMarketPrice,
									row.baseToken.precision,
									Decimal.ROUND_HALF_CEIL
								)}`}
							</Typography>
							{row.type === Order_Open_Type.Increase ? (
								<IconButton
									className={classNames({
										hidden: isMatchMobile,
										'opacity-50':
											row.status === Transaction_Status.Pending ||
											singleLockDisabled
									})}
									onClick={() => {
										onOpenUpdateLimitOrderDialog(row);
									}}
									disabled={
										row.status === Transaction_Status.Pending ||
										singleLockDisabled
									}
								>
									<Edit />
								</IconButton>
							) : row.type === Order_Open_Type.Decrease ? (
								<IconButton
									className={classNames({
										hidden: isMatchMobile,
										'opacity-50':
											row.status === Transaction_Status.Pending ||
											row.expired ||
											singleLockDisabled
									})}
									disabled={
										row.status === Transaction_Status.Pending ||
										row.expired ||
										singleLockDisabled
									}
									onClick={() => {
										onOpenUpdateProfitLossDialog(row);
									}}
								>
									<Edit />
								</IconButton>
							) : null}
						</Box>
					);
				}
			},
			{
				id: 'marketPrice',
				label: t`Market Price`,
				minWidth: 100,
				format: (row: IOrderItemWithPosition) => {
					const currentPool = poolMap.size && poolMap.get(row.poolId);
					return (
						<Typography
							variant='body2'
							className={row.expired ? 'opacity-50' : ''}
						>
							{currentPool
								? toQuoteAmount(currentPool?.price, row.baseToken.precision)
								: '-'}
						</Typography>
					);
				}
			},
			{
				id: 'acceptablePrice',
				label: t`Acceptable Price`,
				minWidth: 130,
				format: (row: IOrderItemWithPosition) => {
					const isPositionTPorSL =
						row.transactionType === Transaction_Type.CreateTakeProfitPosition ||
						row.transactionType === Transaction_Type.CreateStopLossPosition;
					return (
						<Typography
							variant='body2'
							className={row.expired ? 'opacity-50' : ''}
						>
							{row.acceptableTradePrice && !isPositionTPorSL ? (
								row.type === Order_Open_Type.Increase ? (
									row.side === Side.LONG ? (
										<span>{`≤${toQuoteAmount(
											row.acceptableTradePrice,
											row.baseToken.precision,
											Decimal.ROUND_HALF_CEIL
										)} `}</span>
									) : (
										<span>{`≥${toQuoteAmount(
											row.acceptableTradePrice,
											row.baseToken.precision,
											Decimal.ROUND_HALF_CEIL
										)} `}</span>
									)
								) : row.side === Side.LONG ? (
									<span>{`≥${toQuoteAmount(
										row.acceptableTradePrice,
										row.baseToken.precision,
										Decimal.ROUND_HALF_CEIL
									)} `}</span>
								) : (
									<span>{`≤${toQuoteAmount(
										row.acceptableTradePrice,
										row.baseToken.precision,
										Decimal.ROUND_HALF_CEIL
									)} `}</span>
								)
							) : (
								'-'
							)}
						</Typography>
					);
				}
			},
			{
				id: 'operate',
				label: !isMatchMobile && !isMatchPad && (
					<CancelAllOrders list={createdList} />
				),
				minWidth: 100,
				align: 'right',
				format: (row: IOrderItemWithPosition) => (
					<Box className='flex items-center justify-end sm:justify-start md:justify-start'>
						{row.status === Transaction_Status.Pending ||
						(signingCanceledRecord &&
							signingCanceledRecord.orderId === row.id) ||
						(signingCanceledAllRecord &&
							signingCanceledAllRecord.orderIds.includes(row.id)) ? (
							<Loading size={20} />
						) : (
							<StyledButton
								componentVariant='mini'
								size='small'
								color='secondary'
								disabled={singleLockDisabled}
								onClick={() => {
									if (row.type === Order_Open_Type.Increase) {
										onCancelLimitOrderDialog(row);
									} else {
										onCancelProfitLoss(row);
									}
								}}
							>
								<Typography color='textPrimary' variant='body2'>
									<Trans>Cancel</Trans>
								</Typography>
							</StyledButton>
						)}
					</Box>
				)
			}
		];
	}, [
		signingCanceledRecord,
		signingCanceledAllRecord,
		poolMap,
		isMatchPad,
		createdList
	]);

	const computedColumns = useMemo(() => {
		if (!full) {
			return _.filter(columns, item => {
				return item.id !== 'market' && item.id !== 'marketPrice';
			});
		}
		return columns;
	}, [columns, full]);

	const filteredLabel = ['market', 'time', 'type', 'side', 'operate'];

	const filteredColumns = useMemo(() => {
		return isMatchMobile
			? columns.filter(item => !filteredLabel.includes(item.id))
			: columns;
	}, [isMatchMobile, columns]);

	const columnItem = useCallback(
		(id: string) => {
			return columns.find(item => item.id === id);
		},
		[columns]
	);

	return (
		<>
			<article className='space-y-2'>
				{computedListWithExpired?.length ? (
					isMatchMobile ? (
						<ul>
							{computedListWithExpired.map((row, index) => {
								return (
									<li key={index}>
										<div className='space-y-4'>
											<div className=''>
												<div className='flex items-center space-x-2'>
													<Typography
														variant='h6'
														className={classNames({
															'opacity-50': row.expired
														})}
													>
														{`${shortenSymbolNative(
															row.baseToken,
															currentChainId
														)}/${QUOTE_USD}`}
													</Typography>
													{columnItem('side')?.format(row)}
													<Typography
														variant='body2'
														sx={{
															background: theme.custom.cardBg
														}}
														className='px-1 py-0.5 rounded-sm'
													>
														{columnItem('type')?.format(row)}
													</Typography>
													<Box>
														{row.status === Transaction_Status.Pending ||
														(signingCanceledRecord &&
															signingCanceledRecord.orderId === row.id) ||
														(signingCanceledAllRecord &&
															signingCanceledAllRecord.orderIds.includes(
																row.id
															)) ? (
															<Loading size={20} />
														) : null}
													</Box>
												</div>
												<Typography variant='body2' color='text.secondary'>
													{formatUnixDate(row.createdTimestamp)}
												</Typography>
											</div>
											<div className='grid grid-cols-2 gap-2'>
												{filteredColumns.map((column, columnIndex) => (
													<DirectionCell
														label={column.label}
														align={columnIndex % 2 === 0 ? 'left' : 'right'}
														value={column.format(row)}
														key={columnIndex}
													/>
												))}
											</div>
											<div className='flex items-center space-x-3'>
												<StyledButton
													componentVariant='mini'
													size='small'
													color='secondary'
													disabled={singleLockDisabled}
													onClick={() => {
														if (row.type === Order_Open_Type.Increase) {
															onCancelLimitOrderDialog(row);
														} else {
															onCancelProfitLoss(row);
														}
													}}
												>
													<Typography color='textPrimary' variant='body2'>
														<Trans>Cancel</Trans>
													</Typography>
												</StyledButton>
												<StyledButton
													className={classNames({ 'opacity-50': row.expired })}
													componentVariant='mini'
													size='small'
													color='secondary'
													disabled={row.expired || singleLockDisabled}
													onClick={() => {
														if (row.type === Order_Open_Type.Increase) {
															onOpenUpdateLimitOrderDialog(row);
														} else {
															onOpenUpdateProfitLossDialog(row);
														}
													}}
												>
													<Typography color='textPrimary' variant='body2'>
														<Trans>Adjust Trigger Price</Trans>
													</Typography>
												</StyledButton>
											</div>
										</div>
										{index !== computedListWithExpired.length - 1 && (
											<Divider
												className='my-3'
												sx={{ borderStyle: 'dashed' }}
											/>
										)}
									</li>
								);
							})}
						</ul>
					) : (
						<StyledTable
							columns={computedColumns}
							rows={computedListWithExpired}
						/>
					)
				) : (
					<NoData>
						<Trans>No open orders</Trans>
					</NoData>
				)}
			</article>

			{/* 修改Limit订单价格 */}
			{updateLimitOrderIsOpen && currentOrder && (
				<TradeTriggerPriceDialog
					currentOrder={currentOrder}
					onClose={onCloseUpdateLimitOrderDialog}
				/>
			)}
			{/* 提示止盈止损 */}
			{modifyTisIsOpen && <ModifyTisDialog onClose={onCloseModifyTisDialog} />}
			{/* 止盈止损 */}
			{updateProfitLossIsOpen && currentOrder && (
				<UpdateProfitLossDialog
					item={currentOrder}
					onClose={onCloseUpdateProfitLossDialog}
				/>
			)}
		</>
	);
}
