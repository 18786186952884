import { Reducer, createSlice } from '@reduxjs/toolkit';
import { IArchitectItem } from 'graphql/useArchitectRewardsGraph';

import { RootState } from 'state';

interface StateProps {
	architectList: Array<IArchitectItem> | null;
}

const initialState: StateProps = {
	architectList: null,
};

export const slice = createSlice({
	name: 'earnArchitect',
	initialState,
	reducers: {
		setArchitectList(state, { payload }) {
			state.architectList = payload;
		},
	}
});

export const {
	setArchitectList
} = slice.actions;

export const earnArchitectBaseState = (state: RootState) => state.earnArchitect;

export default slice.reducer as Reducer<typeof initialState>;
