import { useMemo, useState } from 'react';

import { Box, Paper } from '@mui/material';
import classNames from 'classnames';
import { Dom_Size, Version } from 'config/constants';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { isEmpty, isMap } from 'lodash';
import { useAppSelector } from 'state/hooks';
import {
	selectCurrentPool,
	selectCurrentPriceChange
} from 'state/pools/selector';
import { poolsBaseState } from 'state/pools/slice';
import { IPoolItem } from 'types';

import { CommonStyledDivider } from 'components/Common/Styled';
import SectionLoading from 'components/SectionLoading';

import ChartsPool, { Chart_Tab_Type } from '../components/ChartsPool';
import Overview from './Overview';

export default function TradeMain() {
	const { poolAddress, poolMap } = useAppSelector(poolsBaseState);
	const currentPriceChange = useAppSelector(selectCurrentPriceChange);
	const currentPool = useAppSelector(selectCurrentPool);

	const { positions, fundingRate, price, indexPrice } = useMemo(() => {
		if (!currentPool) {
			return {
				positions: '',
				fundingRate: '',
				price: '',
				indexPrice: ''
			};
		}
		return {
			positions: currentPool.positions,
			fundingRate: currentPool.globalPosition.fundingRate,
			price: currentPool.price,
			indexPrice: currentPool.indexPrice
		};
	}, [currentPool]);

	const [chartType, onSetChartType] = useState<Chart_Tab_Type>(
		Chart_Tab_Type.Price
	);

	const { isMatchMobile } = useAppBreakpoints();
	const currentVersion = useAppSelector(state => state.setting.currentVersion);

	return (
		<Paper
			className='flex flex-col'
			sx={{
				height: isMatchMobile
					? chartType !== Chart_Tab_Type.Detail
						? Dom_Size.CHART + 180
						: 'auto'
					: currentVersion === Version.V1
					? Dom_Size.CHART + 148
					: `calc(50vh + 148px)`,
				minHeight:
					chartType !== Chart_Tab_Type.Detail ? Dom_Size.CHART + 148 : 'auto'
			}}
		>
			{isEmpty(poolMap) ||
			!poolMap ||
			(isMap(poolMap) && !poolMap.get(poolAddress)) ? (
				<Box className='h-full'>
					<SectionLoading full={true} />
				</Box>
			) : (
				<>
					<Overview
						indexPrice={indexPrice}
						price={price}
						priceChange={currentPriceChange}
						positions={positions}
						fundingRate={fundingRate}
					/>
					<CommonStyledDivider className='my-0' type='solid' />
					<section
						className={classNames('flex-1', {
							flex: currentVersion === Version.V2
						})}
					>
						<ChartsPool
							pool={poolMap && (poolMap?.get(poolAddress) as IPoolItem)}
							activeTab={chartType}
							price={price}
							height={currentVersion === Version.V2 ? '100%' : void 0}
							onHandleChangeActiveTab={(newTab: number) =>
								onSetChartType(newTab)
							}
						/>
					</section>
				</>
			)}
		</Paper>
	);
}
