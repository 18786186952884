import {
	DEFAULT_PRECISION,
	DEFAULT_QUOTE_PRECISION,
	QUOTE_USD_PRECISION,
	Side,
	SideFlip
} from 'config/constants';

import { cloneDeep } from 'lodash';
import {
	IGlobalLiquidityPosition,
	IGlobalLiquidityPositionBigInt,
	IPriceState,
	IPriceStateBigInt,
	IPriceVertex,
	IPriceVertexBigInt
} from 'types';
import {
	bigIntMulDiv,
	bigIntMulDiv2,
	calculatePrice,
	isZero,
	parseUnits
} from 'utils';

export interface IMoveStep {
	side: Side;
	sizeLeft: bigint;
	indexPriceX96: bigint;
	improveBalance: boolean;
	from: IPriceVertexBigInt;
	current: IPriceVertexBigInt;
	to: IPriceVertexBigInt;
}

export const VERTEX_NUM = 7;
export const LATEST_VERTEX = VERTEX_NUM - 1;
export const VERTEX_BASIS_POINT_DIVISOR = 100_000_000;
export const Q96 = 1n << 96n;

export class PriceUtil {
	// For interface
	public static calculateMarketPrice(
		sizeDelta: string,
		globalLiquidityPosition: IGlobalLiquidityPosition,
		priceState: IPriceState,
		side: Side,
		indexPriceX96: string,
		maxPriceX96: string,
		baseDecimal: number,
		quoteDecimal = QUOTE_USD_PRECISION
	) {
		// 初始化状态，直接返回流动性不足
		if (isZero(globalLiquidityPosition.liquidity)) {
			return '0';
		}
		const _sizeDelta = BigInt(parseUnits(sizeDelta, DEFAULT_PRECISION));
		const _indexPriceX96 = BigInt(indexPriceX96);
		const _maxPriceX96 = BigInt(maxPriceX96);
		const _globalLiquidityPosition = {
			...globalLiquidityPosition,
			netSize: BigInt(
				parseUnits(globalLiquidityPosition.netSize, DEFAULT_PRECISION)
			),
			liquidationBufferNetSize: BigInt(
				parseUnits(
					globalLiquidityPosition.liquidationBufferNetSize,
					DEFAULT_PRECISION
				)
			),
			liquidity: BigInt(
				parseUnits(globalLiquidityPosition.liquidity, DEFAULT_QUOTE_PRECISION)
			)
		} as IGlobalLiquidityPositionBigInt;
		const _priceState = {
			...priceState,
			maxPriceImpactLiquidity: BigInt(priceState.maxPriceImpactLiquidity),
			premiumRateX96: BigInt(priceState.premiumRateX96),
			priceVertices: priceState.priceVertices.map((item: IPriceVertex) => ({
				premiumRateX96: BigInt(item.premiumRateX96),
				size: BigInt(parseUnits(item.size, DEFAULT_PRECISION))
			})),
			liquidationBufferNetSizes: priceState.liquidationBufferNetSizes.map(
				item => BigInt(parseUnits(item, DEFAULT_PRECISION))
			)
		} as IPriceStateBigInt;

		const { tradePriceX96 } = this.updatePriceState(
			_globalLiquidityPosition,
			_priceState,
			side,
			_sizeDelta,
			_indexPriceX96,
			_maxPriceX96,
			false
		);

		return calculatePrice(String(tradePriceX96), baseDecimal, quoteDecimal);
	}

	public static updatePriceState(
		globalLiquidityPosition: IGlobalLiquidityPositionBigInt,
		priceState: IPriceStateBigInt,
		side: Side,
		sizeDelta: bigint,
		indexPriceX96: bigint,
		maxPriceX96: bigint,
		liquidation = false
	) {
		const globalLiquidityPositionCache = cloneDeep(globalLiquidityPosition);
		const priceStateCache = cloneDeep(priceState);

		const improveBalance =
			(globalLiquidityPositionCache.netSize |
				globalLiquidityPositionCache.liquidationBufferNetSize) >
				0n && side === globalLiquidityPositionCache.side;

		// eslint-disable-next-line prefer-const
		let { tradePriceX96TimesSizeTotal, sizeLeft, totalBufferUsed } =
			this._updatePriceState(
				globalLiquidityPositionCache,
				priceState,
				priceStateCache,
				side,
				improveBalance,
				sizeDelta,
				indexPriceX96,
				liquidation
			);

		if (!improveBalance) {
			globalLiquidityPositionCache.side = SideFlip(side);
			globalLiquidityPositionCache.netSize += sizeDelta - totalBufferUsed;
			globalLiquidityPositionCache.liquidationBufferNetSize += totalBufferUsed;
		} else {
			// When the net position of LP decreases and reaches or crosses the vertex,
			// at least the vertex represented by (current, pending] needs to be updated
			if (
				priceStateCache.pendingVertexIndex > priceStateCache.currentVertexIndex
			) {
				this.changePriceVertex(
					priceStateCache.currentVertexIndex,
					priceStateCache.pendingVertexIndex,
					priceStateCache,
					globalLiquidityPosition,
					maxPriceX96
				);
				priceStateCache.pendingVertexIndex = priceStateCache.currentVertexIndex;
			}

			globalLiquidityPositionCache.netSize -=
				sizeDelta - sizeLeft - totalBufferUsed;
			globalLiquidityPositionCache.liquidationBufferNetSize -= totalBufferUsed;
		}

		if (sizeLeft > 0n) {
			globalLiquidityPositionCache.side = SideFlip(
				globalLiquidityPositionCache.side
			);
			const {
				tradePriceX96TimesSizeTotal: tradePriceX96TimesSizeTotal2,
				totalBufferUsed: totalBufferUsed2
			} = this._updatePriceState(
				globalLiquidityPositionCache,
				priceState,
				priceStateCache,
				side,
				false,
				sizeLeft,
				indexPriceX96,
				liquidation
			);
			if (
				tradePriceX96TimesSizeTotal === 0n ||
				tradePriceX96TimesSizeTotal2 === 0n
			) {
				return {
					tradePriceX96: 0n,
					priceStateCache,
					globalLiquidityPositionCache
				};
			}
			tradePriceX96TimesSizeTotal += tradePriceX96TimesSizeTotal2;
			globalLiquidityPositionCache.netSize = sizeLeft - totalBufferUsed2;
			globalLiquidityPositionCache.liquidationBufferNetSize = totalBufferUsed2;
		}

		const tradePriceX96 =
			side === Side.LONG
				? bigIntMulDiv(tradePriceX96TimesSizeTotal, 1n, sizeDelta, true)
				: tradePriceX96TimesSizeTotal / sizeDelta;

		return {
			tradePriceX96,
			priceStateCache,
			globalLiquidityPositionCache
		};
	}

	private static _updatePriceState(
		globalPositionCache: IGlobalLiquidityPositionBigInt,
		priceState: IPriceStateBigInt,
		priceStateCache: IPriceStateBigInt,
		side: Side,
		improveBalance: boolean,
		sizeDelta: bigint,
		indexPriceX96: bigint,
		liquidation: boolean
	) {
		const sizeLeft = sizeDelta;
		const step = {
			side,
			sizeLeft: sizeDelta,
			indexPriceX96,
			improveBalance,
			from: { size: 0n, premiumRateX96: 0n },
			current: {
				size: globalPositionCache.netSize,
				premiumRateX96: priceStateCache.premiumRateX96
			},
			to: { size: 0n, premiumRateX96: 0n }
		} as IMoveStep;

		let tradePriceX96TimesSizeTotal = 0n;
		let totalBufferUsed = 0n;
		if (!step.improveBalance) {
			// 平衡性变差
			if (priceStateCache.currentVertexIndex == 0)
				priceStateCache.currentVertexIndex = 1;
			const end = liquidation
				? priceStateCache.liquidationVertexIndex + 1
				: VERTEX_NUM;
			for (
				let i = priceStateCache.currentVertexIndex;
				i < end && step.sizeLeft > 0n;
				++i
			) {
				[step.from, step.to] = [
					priceStateCache.priceVertices[i - 1],
					priceStateCache.priceVertices[i]
				];
				const { tradePriceX96, sizeUsed, premiumRateAfterX96 } =
					this.simulateMove(step);
				if (
					sizeUsed < step.sizeLeft &&
					!(liquidation && i == priceStateCache.liquidationVertexIndex)
				) {
					// 超过此点
					priceStateCache.currentVertexIndex = i + 1;
					step.current = step.to;
				}
				// 当sizeUsed >= step.sizeLeft时， sizeUsed == step.sizeLeft
				step.sizeLeft -= sizeUsed;
				tradePriceX96TimesSizeTotal += tradePriceX96 * sizeUsed;
				priceStateCache.premiumRateX96 = premiumRateAfterX96;
			}
			// 流动性不足
			if (step.sizeLeft > 0n) {
				if (!liquidation) {
					return { tradePriceX96TimesSizeTotal: 0n, sizeLeft, totalBufferUsed };
				}
				totalBufferUsed += step.sizeLeft;
				const liquidationVertexIndex = priceStateCache.liquidationVertexIndex;
				const liquidationBufferNetSizeAfter =
					priceState.liquidationBufferNetSizes[liquidationVertexIndex] +
					step.sizeLeft;
				priceState.liquidationBufferNetSizes[liquidationVertexIndex] =
					liquidationBufferNetSizeAfter;
				return { tradePriceX96TimesSizeTotal, sizeLeft, totalBufferUsed };
			}
		} else {
			// 平衡性变优
			// @note: `i` == 0 时继续执行以消耗原点liquidationBuffer部分
			for (
				let i = priceStateCache.currentVertexIndex;
				i >= 0 && step.sizeLeft > 0n;
				--i
			) {
				// 消耗from中buffer size:
				// from中buffer size不为0的case:
				// 假设从v5 => v4, 此时更新 currentVertexIndex = v4, 但是v4中的buffer size没有被消耗完
				// 这里消耗v4中的size
				let bufferSizeAfter = priceState.liquidationBufferNetSizes[i];
				if (bufferSizeAfter > 0n) {
					const sizeUsed =
						bufferSizeAfter > step.sizeLeft ? step.sizeLeft : bufferSizeAfter;
					const tradePriceX96 = this.calculateMarketPriceX96(
						globalPositionCache.side,
						side,
						indexPriceX96,
						step.current.premiumRateX96
					);
					bufferSizeAfter -= sizeUsed;
					priceState.liquidationBufferNetSizes[i] = bufferSizeAfter;
					totalBufferUsed += sizeUsed;

					step.sizeLeft -= sizeUsed;
					tradePriceX96TimesSizeTotal += tradePriceX96 * sizeUsed;
				}

				if (step.sizeLeft > 0n && i > 0) {
					[step.from, step.to] = [
						priceState.priceVertices[i],
						priceState.priceVertices[i - 1]
					];
					const { tradePriceX96, sizeUsed, reached, premiumRateAfterX96 } =
						this.simulateMove(step);
					if (reached) {
						// 到达or超过
						priceStateCache.currentVertexIndex = i - 1;
						step.current = step.to;
					}
					step.sizeLeft -= sizeUsed;
					tradePriceX96TimesSizeTotal += tradePriceX96 * sizeUsed;
					priceStateCache.premiumRateX96 = premiumRateAfterX96;
				}
			}
		}
		return {
			tradePriceX96TimesSizeTotal,
			sizeLeft: step.sizeLeft,
			totalBufferUsed
		};
	}

	private static _calculatePriceVertex(
		_balanceRate: bigint,
		_premiumRate: bigint,
		_liquidity: bigint,
		_indexPriceX96: bigint
	) {
		return {
			size: bigIntMulDiv(
				(Q96 * _balanceRate) / BigInt(VERTEX_BASIS_POINT_DIVISOR),
				_liquidity,
				_indexPriceX96
			),
			premiumRateX96: (Q96 * _premiumRate) / BigInt(VERTEX_BASIS_POINT_DIVISOR)
		};
	}

	public static changePriceVertex(
		startExclusive: number,
		endInclusive: number,
		priceStateCache: IPriceStateBigInt,
		globalLiquidityPosition: IGlobalLiquidityPositionBigInt,
		maxPriceX96: bigint
	) {
		if (endInclusive < LATEST_VERTEX) {
			const previous = priceStateCache.priceVertices[endInclusive];
			const next = priceStateCache.priceVertices[endInclusive + 1];

			if (
				previous.size >= next.size ||
				previous.premiumRateX96 >= next.premiumRateX96
			) {
				endInclusive = LATEST_VERTEX;
			}
		}

		return this._changePriceVertex(
			startExclusive,
			endInclusive,
			priceStateCache,
			globalLiquidityPosition,
			maxPriceX96
		);
	}

	private static _changePriceVertex(
		startExclusive: number,
		endInclusive: number,
		priceStateCache: IPriceStateBigInt,
		globalLiquidityPosition: IGlobalLiquidityPositionBigInt,
		maxPriceX96: bigint
	) {
		const indexPriceX96 = maxPriceX96;
		const tokenVertices = globalLiquidityPosition.tokenVertices;
		const liquidity =
			globalLiquidityPosition.liquidity <=
			priceStateCache.maxPriceImpactLiquidity
				? globalLiquidityPosition.liquidity
				: priceStateCache.maxPriceImpactLiquidity;

		for (let index = startExclusive + 1; index <= endInclusive; ++index) {
			const { balanceRate, premiumRate } = tokenVertices[index];
			let { size: sizeAfter, premiumRateX96: premiumRateAfterX96 } =
				this._calculatePriceVertex(
					BigInt(balanceRate),
					BigInt(premiumRate),
					liquidity,
					indexPriceX96
				);
			if (index > 1) {
				const previous = priceStateCache.priceVertices[index - 1];
				if (
					previous.size >= sizeAfter ||
					previous.premiumRateX96 >= premiumRateAfterX96
				)
					[sizeAfter, premiumRateAfterX96] = [
						previous.size,
						previous.premiumRateX96
					];
			}

			priceStateCache.priceVertices[index].size = sizeAfter;
			priceStateCache.priceVertices[index].premiumRateX96 = premiumRateAfterX96;

			// If the vertex represented by end is the same as the vertex represented by end + 1,
			// then the vertices in range (start, LATEST_VERTEX] need to be updated
			if (index == endInclusive && endInclusive < LATEST_VERTEX) {
				const next = priceStateCache.priceVertices[index + 1];
				if (
					sizeAfter >= next.size ||
					premiumRateAfterX96 >= next.premiumRateX96
				)
					endInclusive = LATEST_VERTEX;
			}
		}

		return {
			endInclusive,
			priceStateCache
		};
	}

	private static calculateMarketPriceX96(
		_globalSide: Side,
		_side: Side,
		_indexPriceX96: bigint,
		_premiumRateX96: bigint
	) {
		return bigIntMulDiv(
			_indexPriceX96,
			_side === Side.LONG ? Q96 - _premiumRateX96 : Q96 + _premiumRateX96,
			Q96,
			_side === Side.LONG
		);
	}

	public static simulateMove(step: IMoveStep) {
		const { reached, sizeUsed } = this.calculateReachedAndSizeUsed(step);
		const premiumRateAfterX96 = this.calculatePremiumRateAfterX96(
			step,
			reached,
			sizeUsed
		);
		const premiumRateBeforeX96 = step.current.premiumRateX96;
		const { down: tradePriceX96Down, up: tradePriceX96Up } = bigIntMulDiv2(
			step.indexPriceX96,
			(step.improveBalance && step.side === Side.LONG) ||
				(!step.improveBalance && step.side === Side.SHORT)
				? ((1n << 96n) << 1n) - premiumRateBeforeX96 - premiumRateAfterX96
				: ((1n << 96n) << 1n) + premiumRateBeforeX96 + premiumRateAfterX96,
			(1n << 96n) << 1n
		);
		const tradePriceX96 =
			step.side === Side.LONG ? tradePriceX96Up : tradePriceX96Down;
		return { reached, sizeUsed, tradePriceX96, premiumRateAfterX96 };
	}

	private static calculateReachedAndSizeUsed(step: IMoveStep) {
		let reached;
		let sizeUsed;
		if (step.improveBalance) {
			reached = step.sizeLeft >= step.current.size - step.to.size;
			sizeUsed = reached ? step.current.size - step.to.size : step.sizeLeft;
		} else {
			reached = step.sizeLeft >= step.to.size - step.current.size;
			sizeUsed = reached ? step.to.size - step.current.size : step.sizeLeft;
		}
		return {
			reached,
			sizeUsed
		};
	}

	private static calculatePremiumRateAfterX96(
		step: IMoveStep,
		reached: boolean,
		sizeUsed: bigint
	) {
		let premiumRateAfterX96;
		if (reached) {
			premiumRateAfterX96 = step.to.premiumRateX96;
		} else {
			const globalSide = step.improveBalance ? step.side : SideFlip(step.side);
			const AX96AndBX96 = this.calculateAX96AndBX96(
				globalSide,
				step.from,
				step.to
			);
			const aX96 = AX96AndBX96.aX96;
			let bX96 = AX96AndBX96.bX96;

			const sizeAfter = step.improveBalance
				? step.current.size - sizeUsed
				: step.current.size + sizeUsed;
			if (globalSide === Side.LONG) {
				bX96 = BigInt(-bX96);
			}
			premiumRateAfterX96 = aX96 * sizeAfter + bX96;
		}
		return premiumRateAfterX96;
	}

	private static calculateAX96AndBX96(
		globalSide: Side,
		from: IPriceVertexBigInt,
		to: IPriceVertexBigInt
	) {
		if (from.size > to.size) {
			[from, to] = [to, from];
		}
		const sizeDelta = to.size - from.size;
		const aX96 = bigIntMulDiv(
			to.premiumRateX96 - from.premiumRateX96,
			1n,
			sizeDelta,
			true
		);
		let bX96;
		const numeratorPart1X96 = from.premiumRateX96 * to.size;
		const numeratorPart2X96 = to.premiumRateX96 * from.size;
		if (globalSide === Side.SHORT) {
			if (numeratorPart1X96 >= numeratorPart2X96) {
				bX96 = (numeratorPart1X96 - numeratorPart2X96) / sizeDelta;
			} else {
				bX96 = -((numeratorPart2X96 - numeratorPart1X96) / sizeDelta);
			}
		} else {
			if (numeratorPart2X96 >= numeratorPart1X96) {
				bX96 = (numeratorPart2X96 - numeratorPart1X96) / sizeDelta;
			} else {
				bX96 = -((numeratorPart1X96 - numeratorPart2X96) / sizeDelta);
			}
		}
		return { aX96, bX96 };
	}
}
