import { useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans, t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Transaction_Type, Version } from 'config/constants';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useSubmitClaimStake } from 'hooks/useSubmitClaimStake';
import _, { filter } from 'lodash';
import { selectClaimableStakeList } from 'state/earn/stake/selector';
import { useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';
import { txBaseState } from 'state/tx/slice';
import { IStakeDetail } from 'types';
import { formatNumber, isPositive, plus, shortenSymbol } from 'utils';

import ApproveButton from 'components/ApproveButton';
import ApproveButtonV2 from 'components/ApproveButtonV2';
import Dialog, { DialogProps } from 'components/Common/Dialog';
import { StyleCheckbox } from 'components/Common/StyleCheckbox';
import { CommonStyledDivider } from 'components/Common/Styled';
import Table from 'components/Table/StickyTable';

import EmptyClaim from '../EmptyClaim';

export default function ClaimStakeDialog(props: DialogProps) {
	const { onClose } = props;

	const currentVersion = useAppSelector(selectVersion);
	const { quoteToken, appToken, signingMap } = useAppSelector(txBaseState);
	const claimableStakeList = useAppSelector(selectClaimableStakeList);

	const theme = useTheme();

	const [selected, setSelected] = useState<string[]>([]);

	const isSelected = (id: string) => selected.indexOf(id) !== -1;

	const [stakeIDs, appQuoteAmount] = useMemo(() => {
		if (!claimableStakeList || !selected || selected.length === 0) {
			return [null, '0'];
		}
		const list = filter(claimableStakeList, item =>
			selected.includes(item.stakeID)
		);
		if (_.isEmpty(list)) {
			return [null, '0'];
		}
		const stakeIDs = list.map(item => item.stakeID);
		const quoteAmount = list.reduce(
			(pre, cur) => plus(pre, cur.claimableReward),
			'0'
		);
		return [stakeIDs, quoteAmount];
	}, [claimableStakeList, selected]);

	const { onConfirm, isConfirmed, isConfirming } = useSubmitClaimStake(
		stakeIDs,
		appQuoteAmount
	);

	useUpdateEffect(() => {
		if (isConfirmed) {
			onClose();
		}
	}, [isConfirmed]);

	const [disabled, submitText] = useMemo(() => {
		if (!isPositive(appQuoteAmount)) {
			return [true, t`Claim`];
		}
		if (signingMap.has(Transaction_Type.ClaimStake)) {
			return [true, t`Loading...`];
		}
		if (isConfirming) {
			return [true, t`Submitting...`];
		}
		return [false, t`Claim`];
	}, [appQuoteAmount, isConfirming, signingMap]);

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setSelected(claimableStakeList.map(n => n.stakeID));
			return;
		}
		setSelected([]);
	};

	const handleToggleSelect = (event: React.ChangeEvent, id: string) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const columnsForToken = [
		{
			key: 'stakedAmount',
			width: '65%',
			label: <Trans>Staked EQU</Trans>,
			align: 'left',
			format: (row: IStakeDetail) => {
				const isItemSelected = isSelected(row.stakeID);
				return (
					<div className='flex items-center'>
						<StyleCheckbox
							checked={isItemSelected}
							size='small'
							className='-ml-2.5'
							onChange={event => handleToggleSelect(event, row.stakeID)}
						/>
						<Typography variant='body2'>
							{`${formatNumber(
								row.stakedAmount,
								appToken.positionUnits
							)} ${shortenSymbol(appToken)}`}
						</Typography>
					</div>
				);
			}
		},
		{
			key: 'claimableReward',
			width: '35%',
			label: (
				<Typography variant='body2'>{`${shortenSymbol(
					quoteToken
				)} ${t`Amount`}`}</Typography>
			),
			align: 'right',
			format: (row: IStakeDetail) => {
				return (
					<Typography variant='body2'>{`${formatNumber(
						row.claimableReward
					)} ${shortenSymbol(quoteToken)}`}</Typography>
				);
			}
		}
	];
	const selectedCount = selected.length;

	const { isMatchPad } = useAppBreakpoints();

	return (
		<Dialog title={t`Claim`} open={true} onClose={onClose}>
			{(!claimableStakeList || claimableStakeList.length === 0) && (
				<EmptyClaim />
			)}
			{claimableStakeList && claimableStakeList.length !== 0 && (
				<div className='space-y-4'>
					<Typography color='text.secondary'>
						<Trans>Choosing more rewards will consume more gas fee.</Trans>
					</Typography>

					<div className='-mx-4'>
						<Table
							columns={columnsForToken}
							rows={claimableStakeList}
							cellColor={theme.custom.cardBg}
							origin={isMatchPad}
						/>
					</div>

					<CommonStyledDivider />

					<div className='flex items-center'>
						<StyleCheckbox
							checked={selected.length === claimableStakeList?.length}
							size='small'
							className='-ml-2.5'
							onChange={handleSelectAllClick}
						/>
						<div className='flex items-center space-x-2'>
							<Typography color='text.secondary'>
								<Trans>{selectedCount} selected, total</Trans>
							</Typography>
							<Typography variant='body2'>{`${formatNumber(
								appQuoteAmount
							)} ${shortenSymbol(quoteToken)}`}</Typography>
						</div>
					</div>
					<section>
						{currentVersion === Version.V2 ? (
							<ApproveButtonV2
								componentVariant='confirm'
								fullWidth
								onClick={onConfirm}
								disabled={disabled}
								isApproveRewardCollectorToV1
							>
								{submitText}
							</ApproveButtonV2>
						) : (
							<ApproveButton
								componentVariant='confirm'
								fullWidth
								onClick={onConfirm}
								disabled={disabled}
								isApproveRewardCollector
							>
								{submitText}
							</ApproveButton>
						)}
					</section>
				</div>
			)}
		</Dialog>
	);
}
