import { useEffect, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Hash } from '@wagmi/core';
import {
	DAYS_YEAR,
	DEFAULT_PRECISION,
	QUOTE_USD_PRECISION,
	Version
} from 'config/constants';
import { useAccount } from 'wagmi';

import { LiquidityPositionUtil } from 'entities/LiquidityPositionUtil';
import { useAllTokens } from 'hooks/useAllTokens';
import _ from 'lodash';
import { Reward_Type } from 'pages/Earn/types';
import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { txBaseState } from 'state/tx/slice';
import { IGlobalUnrealizedLossMetrics, ILiquidityPosition } from 'types';

import {
	useFarmClaimDetailsV2Request,
	useMultiTokens
} from '../fetch/useRequest';
import { selectVersion } from '../state/setting/selector';
import {
	catchFn,
	div,
	formatRate,
	formatUnits,
	isGreaterThan,
	isPositive,
	minus,
	multipliedBy,
	parseUnits,
	plus,
	toDecimalPlaces
} from '../utils';
import { useLiquidityPoolsQuery } from './__generated_trade__/types-and-hooks';
import { useMiningPoolsGraph } from './useMiningPoolsGraph';
import { useRewardsConfigGraph } from './useRewardsConfigGraph';

const useLiquidityPoolsGraph = () => {
	const { blockHeightBefore24h } = useAppSelector(globalBaseState);
	const { address } = useAccount();
	const allTokens = useAllTokens();
	const { appTokenUsdPrice } = useAppSelector(txBaseState);
	const { quoteToken } = useAppSelector(txBaseState);
	const { appTimer } = useAppSelector(globalBaseState);
	const currentVersion = useAppSelector(selectVersion);

	const { data: miningPools } = useMiningPoolsGraph();
	const { data: rewardsConfig } = useRewardsConfigGraph();
	const {
		loading,
		data = {
			pools: [],
			pastPools: [],
			adjustLiquidityPositionMarginRequests: [],
			closeLiquidityPositionRequests: [],
			openLiquidityPositionRequests: []
		},
		refetch
	} = useLiquidityPoolsQuery({
		variables: {
			account: address,
			block24h: Number(blockHeightBefore24h)
		},
		skip: !isPositive(blockHeightBefore24h) || currentVersion === Version.V2
	});
	const {
		data: detailsList,
		isLoading: isLoadingDetails,
		refetch: refetchDetails
	} = useFarmClaimDetailsV2Request(address, Reward_Type.LIQUIDITY);
	useUpdateEffect(() => {
		refetch();
	}, [appTimer]);

	const adjustLiquidityPositionMarginRequestsMap = new Map<
		Hash,
		(typeof data.adjustLiquidityPositionMarginRequests)[number]
	>();

	const closeLiquidityPositionRequestsMap = new Map<
		Hash,
		(typeof data.closeLiquidityPositionRequests)[number]
	>();

	const openLiquidityPositionRequestsMap = new Map<
		Hash,
		(typeof data.openLiquidityPositionRequests)[number]
	>();

	data.adjustLiquidityPositionMarginRequests?.forEach(request =>
		adjustLiquidityPositionMarginRequestsMap.set(request.createdHash, request)
	);
	data.closeLiquidityPositionRequests?.forEach(request =>
		closeLiquidityPositionRequestsMap.set(request.createdHash, request)
	);
	data.openLiquidityPositionRequests?.forEach(request =>
		openLiquidityPositionRequestsMap.set(request.createdHash, request)
	);
	const requestData = useMemo(
		() => ({
			adjustLiquidityPositionMarginRequestsMap,
			closeLiquidityPositionRequestsMap,
			openLiquidityPositionRequestsMap
		}),
		[data]
	);

	const [isLoading, setIsLoading] = useState(true);

	const { poolMap } = useAppSelector(poolsBaseState);

	useEffect(() => {
		if (!loading) {
			setIsLoading(loading);
		}
	}, [loading]);

	const { tokensMultiPrice = { tokens: [] } } = useMultiTokens(
		data.pools?.map(pool => pool.token.id)
	);

	const list = useMemo(() => {
		const _pools = data.pools
			?.map(item => ({
				volumeUSD: item.volumeUSD,
				token: item.token,
				id: item.id as string,
				address: item.id,
				totalRealizedPnL: item.globalLiquidityPosition.realizedProfit,
				tradingFee: item.globalLiquidityPosition.tradingFee,
				globalLiquidityPosition: {
					...item.globalLiquidityPosition,
					netLiquidity: toDecimalPlaces(
						multipliedBy(
							item.globalLiquidityPosition.netSize,
							item.globalLiquidityPosition.entryPrice
						),
						QUOTE_USD_PRECISION
					),
					tokenVertices: item.token.vertices
				},
				globalUnrealizedLossMetrics:
					item.globalUnrealizedLossMetrics as IGlobalUnrealizedLossMetrics,
				globalRiskBufferFund: item.globalRiskBufferFund,
				liquidityPositions: item.liquidityPositions,
				priceState1Hs: [...item.priceState1Hs].reverse()
			}))
			.map(item => {
				const indexPriceX96 =
					tokensMultiPrice.tokens.find(
						(token: any) => token.address.toLowerCase() === item.token.id
					)?.index_price_x96 || 0;
				const indexPrice =
					tokensMultiPrice.tokens.find(
						(token: any) => token.address.toLowerCase() === item.token.id
					)?.index_price || 0;

				const globalUnrealizedLoss = isPositive(indexPriceX96)
					? catchFn(() => {
							return LiquidityPositionUtil.calculateGlobalUnrealizedLoss(
								item.globalLiquidityPosition.side,
								parseUnits(
									plus(
										item.globalLiquidityPosition.netSize,
										item.globalLiquidityPosition.liquidationBufferNetSize
									),
									item.token.decimals
								),
								item.globalLiquidityPosition.entryPriceX96,
								indexPriceX96,
								parseUnits(
									item.globalRiskBufferFund.riskBufferFund || 0,
									quoteToken?.decimals
								)
							);
					  }, '0')
					: '0';
				const liquidityItem = detailsList
					? detailsList.find(
							pli => pli.pool.toLowerCase() === item.id.toLowerCase()
					  )
					: null;
				const liquidityRewardPerDay = liquidityItem
					? liquidityItem.daily_emission_amount
					: 0;
				const rbfApr =
					item.globalLiquidityPosition.liquidity &&
					isGreaterThan(item.globalLiquidityPosition.liquidity, 0) &&
					appTokenUsdPrice
						? multipliedBy(
								div(
									multipliedBy(
										multipliedBy(liquidityRewardPerDay, appTokenUsdPrice),
										item.token.maxLeveragePerLiquidityPosition
									),
									item.globalLiquidityPosition.liquidity
								),
								DAYS_YEAR
						  )
						: '0';
				const rbfAvgApr =
					item.globalLiquidityPosition.liquidity &&
					isGreaterThan(item.globalLiquidityPosition.liquidity, 0) &&
					appTokenUsdPrice
						? multipliedBy(
								div(
									multipliedBy(
										multipliedBy(liquidityRewardPerDay, appTokenUsdPrice),
										div(
											item.globalLiquidityPosition.liquidity,
											item.globalLiquidityPosition.margin
										)
									),
									item.globalLiquidityPosition.liquidity
								),
								DAYS_YEAR
						  )
						: '0';
				const dayRevenue =
					item.totalRealizedPnL &&
					data.pastPools &&
					minus(
						item.totalRealizedPnL,
						data.pastPools.find(pool => pool.id === item.address)
							?.globalLiquidityPosition.realizedProfit || 0
					);

				const tradingFee24h =
					data.pastPools &&
					minus(
						item.globalLiquidityPosition.tradingFee,
						data.pastPools.find(pool => pool.id === item.address)
							?.globalLiquidityPosition.tradingFee || 0
					);

				const volume24h = minus(
					item.volumeUSD || 0,
					data.pastPools.find(pool => pool.id === item.address)?.volumeUSD || 0
				);

				const baseToken = allTokens.get(item.token.id) || null;

				const liquidityApr = isGreaterThan(
					item.globalLiquidityPosition.liquidity,
					0
				)
					? catchFn(() => {
							return LiquidityPositionUtil.calculateApr(
								dayRevenue,
								item.globalLiquidityPosition.liquidity,
								item.token.maxLeveragePerLiquidityPosition
							);
					  }, '')
					: '';
				const liquidityAvgApr = isGreaterThan(
					item.globalLiquidityPosition.liquidity,
					0
				)
					? catchFn(() => {
							return LiquidityPositionUtil.calculateApr(
								dayRevenue,
								item.globalLiquidityPosition.liquidity,
								div(
									item.globalLiquidityPosition.liquidity,
									item.globalLiquidityPosition.margin
								)
							);
					  }, '')
					: '';

				return {
					...item,
					indexPrice,
					indexPriceX96,
					baseSymbol: item.token.symbol,
					totalRevenue: item.totalRealizedPnL,
					dayRevenue,
					tradingFee24h,
					volume24h: volume24h,
					rbfApr,
					rbfAvgApr,
					liquidityApr,
					liquidityAvgApr,
					maxApr: plus(rbfApr, liquidityApr),
					avgApr: plus(rbfAvgApr, liquidityAvgApr),
					globalUnrealizedLoss,
					liquidity: item.globalLiquidityPosition.liquidity,
					myLiquidity: item.liquidityPositions
						.filter(position => position.status === 'Open')
						.reduce((pre, cur) => plus(pre, cur.liquidity), '0'),

					baseToken,
					referralDiscountRate: formatRate(item.token.referralDiscountRate),
					liquidityPositions: item.liquidityPositions
						.filter(position => position.status === 'Open')
						.map(position => {
							const floatRealizedProfit = catchFn(() => {
								return LiquidityPositionUtil.calculatePositionRealizedProfit(
									item.globalLiquidityPosition.realizedProfitGrowth,
									position.entryRealizedProfitGrowth,
									position.liquidity
								);
							}, '0');

							const totalRealizedProfit = plus(
								position.profitBalance,
								floatRealizedProfit
							);

							const marginNet = plus(
								position.initialMargin,
								totalRealizedProfit
							);

							const leverage = div(position.liquidity, marginNet);

							const unrealizedLoss = catchFn(() => {
								const _liquidityPosition = {
									margin: position.margin,
									liquidity: position.liquidity,
									entryUnrealizedLoss: position.entryUnrealizedLoss,
									entryRealizedProfitGrowthX64:
										position.entryRealizedProfitGrowthX64,
									entryTime: position.entryTime,
									account: item.address
								} as ILiquidityPosition;

								return LiquidityPositionUtil.calculatePositionUnrealizedLoss(
									_liquidityPosition,
									item.globalUnrealizedLossMetrics,
									item.globalLiquidityPosition.liquidity,
									globalUnrealizedLoss
								);
							}, '0');

							const riskRatio = catchFn(() => {
								return LiquidityPositionUtil.calcRiskRate(
									unrealizedLoss,
									formatUnits(
										item.token.liquidationExecutionFee,
										QUOTE_USD_PRECISION
									),
									marginNet,
									formatRate(item.token.maxRiskRatePerLiquidityPosition)
								);
							}, '0');

							const balanceRate = div(
								multipliedBy(indexPrice, item.globalLiquidityPosition.netSize),
								item.globalLiquidityPosition.liquidity
							);

							const utilizedLeverage = multipliedBy(leverage, balanceRate);

							// console.log(`${item.token.symbol} unrealizedLoss: `, unrealizedLoss);
							// console.log(`${item.token.symbol} marginNet: `, marginNet);

							return {
								id: position.positionID as string,
								baseSymbol: item.token.symbol,
								baseToken: allTokens.get(item.token.id) || null,
								address: item.address,
								myLiquidity: position.liquidity,
								globalLiquidityPosition: item.globalLiquidityPosition,
								globalLiquidity: item.globalLiquidityPosition.liquidity,
								globalNetLiquidity: item.globalLiquidityPosition.netLiquidity,
								floatRealizedProfit: floatRealizedProfit,
								realizedProfit: position.realizedProfit,
								totalRealizedProfit,
								initialMargin: position.initialMargin,
								margin: position.margin,
								marginNet,
								unrealizedLoss,
								marginRate: LiquidityPositionUtil.calcMarginRate(
									marginNet,
									position.liquidity
								),
								riskRatio,
								entryTxHash: position.entryTxHash,
								liquidationExecutionFee: formatUnits(
									item.token.liquidationExecutionFee,
									QUOTE_USD_PRECISION
								),
								maxLeveragePerLiquidityPosition:
									item.token.maxLeveragePerLiquidityPosition,
								maxRiskRatePerLiquidityPosition: formatRate(
									item.token.maxRiskRatePerLiquidityPosition
								),
								leverage,
								balanceRate,
								status: position.status,
								utilizedLeverage
							};
						})
				};
			});

		return _.chain(_pools)
			.orderBy(['baseToken.sort'], ['asc'])
			.filter(item => isPositive(item.indexPrice))
			.value();
	}, [
		data,
		poolMap,
		tokensMultiPrice,
		miningPools,
		rewardsConfig,
		appTokenUsdPrice,
		detailsList
	]);

	return {
		data: list,
		loading: isLoading,
		requestData
	};
};

export default useLiquidityPoolsGraph;
