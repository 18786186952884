import React, {
	ReactNode,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef
} from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import { ArrowBackIos } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Box,
	IconButton,
	Menu,
	MenuProps,
	Typography,
	styled,
	useTheme
} from '@mui/material';
import classNames from 'classnames';
import { BACKEND_API_SWAGGER, Page, Version } from 'config/constants';
import { App_Theme, Dom_Size } from 'config/constants/enum';
// import { EVENT_SITE } from 'config/env';
import { useAccount } from 'wagmi';

import LogoLight from 'assets/svg/logo-light.svg';
// import HotIcon from 'assets/svg/hot-icon.svg';
import Logo from 'assets/svg/logo.svg';
import MobileLogoLight from 'assets/svg/m-logo-light.svg';
import MobileLogo from 'assets/svg/m-logo.svg';
import { useUniV3SwapLink } from 'hooks/V3/useUniV3LiquidityLink';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCurrentPage } from 'hooks/useCurrentPage';
import throttle from 'lodash/throttle';
import { useAppSelector } from 'state/hooks';
import { settingBaseState } from 'state/setting/slice';
import { tradeBaseState } from 'state/trade/slice';
import { txBaseState } from 'state/tx/slice';
import { toUsd } from 'utils';

import TokenLogo from 'components/Svg/Icons/TokenLogo';
import SwitchVersion from 'components/SwitchVersion';
import ConnectWalletButton from 'components/Wallet/ConnectWalletButton';

import LanguagePopover from '../../components/Language/LanguagePopover';
import Account from './Account';
import Drawer from './Drawer';
import Network from './Network';
import Setting from './Setting';

export const StyledMenu = styled(Menu)<MenuProps>(() => {
	return {
		'.MuiMenu-list': {
			minWidth: '128px'
		}
	};
});

const LinkTab = ({
	route,
	label,
	selected,
	className,
	onClick,
	id,
	variant,
	...props
}: {
	route: string;
	label: ReactNode;
	selected?: boolean;
	className?: any;
	onClick?: any;
	id?: string;
	variant?: string;
}) => {
	const theme = useTheme();
	const { isMatchPad } = useAppBreakpoints();
	return (
		<NavLink
			id={id}
			to={route}
			className={className}
			onClick={() => {
				if (onClick) {
					onClick();
				}
				window.gtag('event', 'navigate_page', {
					page: route
				});
			}}
			{...props}
		>
			{({ isActive }) => {
				return (
					<Typography
						variant={variant || isMatchPad ? 'h6' : 'h5'}
						color={
							isActive || selected
								? theme.palette.primary.main
								: 'text.secondary'
						}
						fontWeight={isActive || selected ? 500 : 400}
						className='rounded cursor-pointer px-4 md:px-2 whitespace-nowrap'
						sx={{
							'&:hover': {
								color: theme.palette.primary.main
							}
						}}
					>
						{label}
					</Typography>
				);
			}}
		</NavLink>
	);
};

export default function Header() {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const { isBindReferralCode } = useAppSelector(tradeBaseState);
	const { currentVersion } = useAppSelector(settingBaseState);
	const { appTokenUsdPrice } = useAppSelector(txBaseState);
	const { isConnected } = useAccount();
	const { isMatchMobile, isMatchPad, isMatchPc } = useAppBreakpoints();
	const currentPage = useCurrentPage();
	const { pathname } = useLocation();
	const theme = useTheme();
	const uniV3SwapLink = useUniV3SwapLink();
	const open = Boolean(anchorEl);
	const navigate = useNavigate();

	const isVisibleBack = useMemo(() => {
		if (currentPage === Page.Pools && pathname.split('/')[2] && isMatchMobile) {
			return true;
		}
		return false;
	}, [currentPage, pathname, isMatchMobile]);

	const onHandleClickMore = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const onHandleClose = () => {
		setAnchorEl(null);
	};

	const onHandleLinkClick = (link: string) => {
		onHandleClose();
		window.open(link);
	};

	useEffect(() => {
		if (!isMatchMobile) {
			return;
		}
		setAnchorEl(null);
	}, [isMatchMobile]);

	const targetDomWidth = useRef(0);
	const isHidden = useRef(false);

	useLayoutEffect(() => {
		const checkOverflow = throttle(() => {
			const navNullWidth =
				document.getElementById('nav-null')?.clientWidth || 0;
			const targetDom = document.getElementById('referrals');
			if (targetDom?.clientWidth !== 0) {
				targetDomWidth.current = targetDom?.clientWidth;
			}

			if (navNullWidth === 0 && targetDom && !isHidden.current && isMatchPc) {
				isHidden.current = true;
				targetDom.classList.add('hidden');
			}
			if (
				targetDom &&
				isHidden.current &&
				navNullWidth > targetDomWidth.current &&
				isMatchPc
			) {
				isHidden.current = false;
				targetDom.classList.remove('hidden');
			}
		}, 30);

		checkOverflow();
		window.addEventListener('resize', checkOverflow);
		return () => {
			window.removeEventListener('resize', checkOverflow);
		};
	}, []);

	return (
		<div>
			<Box
				className='flex justify-between px-6 sm:px-3 md:px-4 py-3'
				sx={{
					backgroundColor: theme.palette.background.paper,
					height: Dom_Size.HEIGHT_HEADER
				}}
			>
				<section className='flex items-center space-x-12 md:space-x-4 grow'>
					<Box className='shrink-0'>
						{isVisibleBack ? (
							<IconButton
								onClick={() => {
									navigate(
										currentVersion === Version.V1 ? Page.PoolsV1 : Page.Pools
									);
								}}
								sx={{
									width: 40,
									color: theme.custom.reverseColor
								}}
							>
								<ArrowBackIos color={'inherit'} />
							</IconButton>
						) : (
							<Link
								to={import.meta.env.VITE_WEBSITE_ORG}
								className='flex space-x-2'
							>
								<img
									src={
										isMatchMobile || isMatchPad
											? theme.palette.mode === App_Theme.dark
												? MobileLogo
												: MobileLogoLight
											: theme.palette.mode === App_Theme.dark
											? Logo
											: LogoLight
									}
									className='h-[28px]'
									alt='Logo'
								/>
							</Link>
						)}
					</Box>
					<div className='flex items-center sm:hidden no-scrollbar overflow-auto grow'>
						<Box id='nav-container' className='flex overflow-auto no-scrollbar'>
							<LinkTab
								route={
									currentVersion === Version.V1 ? Page.IndexV1 : Page.Index
								}
								label={<Trans>Trade</Trans>}
								selected={
									currentPage !== Page.MarketsV1 &&
									currentPage !== Page.PoolsV1 &&
									currentPage !== Page.Markets &&
									currentPage !== Page.Pools &&
									currentPage !== Page.Referrals &&
									currentPage !== Page.ReferralsV1 &&
									currentPage !== Page.Earn &&
									currentPage !== Page.EarnV1 &&
									currentPage !== Page.LeaderboardV1 &&
									currentPage !== Page.Leaderboard
								}
								end
							/>
							<LinkTab
								route={
									currentVersion === Version.V1 ? Page.MarketsV1 : Page.Markets
								}
								label={<Trans>Markets</Trans>}
							/>
							<LinkTab
								route={
									currentVersion === Version.V1 ? Page.PoolsV1 : Page.Pools
								}
								label={<Trans>Pools</Trans>}
							/>
							<LinkTab
								route={currentVersion === Version.V1 ? Page.EarnV1 : Page.Earn}
								label={<Trans>Earn</Trans>}
							/>

							<LinkTab
								id='referrals'
								route={
									currentVersion === Version.V1
										? isBindReferralCode
											? Page.ReferralsEFCMembersV1
											: Page.ReferralsTradersV1
										: isBindReferralCode
										? Page.ReferralsEFCMembers
										: Page.ReferralsTraders
								}
								label={<Trans>Referrals</Trans>}
								selected={currentPage === Page.Referrals}
							/>
						</Box>
						<Typography
							variant={isMatchPad ? 'h6' : 'h5'}
							component='a'
							className='flex items-center rounded px-4 cursor-pointer md:px-3 whitespace-nowrap'
							color='secondary'
							onClick={onHandleClickMore}
							sx={{
								'&:hover': {
									color: theme.palette.primary.main
								}
							}}
						>
							<Trans>More</Trans>
							{anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</Typography>
						<div id='nav-null' className='flex grow' />
						<StyledMenu
							anchorEl={anchorEl}
							className='translate-y-1.5 transform'
							open={open}
							onClose={onHandleClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left'
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left'
							}}
						>
							<LinkTab
								variant='h6'
								route={
									isBindReferralCode
										? Page.ReferralsEFCMembers
										: Page.ReferralsTraders
								}
								label={<Trans>Referrals</Trans>}
								selected={currentPage === Page.Referrals}
								className={classNames(
									'flex items-center py-3 cursor-pointer md:px-1',
									{
										hidden: !isHidden.current
									}
								)}
								onClick={() => {
									onHandleClose();
								}}
							/>
							{currentVersion === Version.V1 && (
								<LinkTab
									variant='h6'
									label={<Trans>Leaderboard</Trans>}
									selected={currentPage === Page.LeaderboardV1}
									className={classNames(
										'flex items-center py-3 cursor-pointer md:px-1'
									)}
									route={Page.LeaderboardV1}
									onClick={() => {
										onHandleClose();
									}}
								/>
							)}
							{currentVersion === Version.V2 && (
								<Typography
									onClick={() =>
										onHandleLinkClick(
											'https://docs.equation.org/whitepaper-v2/overview'
										)
									}
									className='flex items-center px-4 py-3 cursor-pointer md:px-3'
									color='secondary'
									sx={{
										'&:hover': {
											color: theme.palette.primary.main,
											background: theme.palette.action.hover
										}
									}}
								>
									<Typography variant={'h6'} component='span'>
										<Trans>Docs</Trans>
									</Typography>
								</Typography>
							)}
							<Typography
								onClick={() =>
									onHandleLinkClick(import.meta.env.VITE_WEBSITE_VOTE)
								}
								className='flex items-center px-4 py-3 cursor-pointer md:px-3'
								color='secondary'
								sx={{
									'&:hover': {
										color: theme.palette.primary.main,
										background: theme.palette.action.hover
									}
								}}
							>
								<Typography variant={'h6'} component='span'>
									<Trans>Voting</Trans>
								</Typography>
							</Typography>

							<Typography
								onClick={() =>
									onHandleLinkClick('https://commonwealth.im/equationdao')
								}
								className='flex items-center px-4 py-3 cursor-pointer md:px-3'
								color='secondary'
								sx={{
									'&:hover': {
										color: theme.palette.primary.main,
										background: theme.palette.action.hover
									}
								}}
							>
								<Typography variant={'h6'} component='span'>
									<Trans>Forum</Trans>
								</Typography>
							</Typography>
							<Typography
								onClick={() =>
									onHandleLinkClick(
										`${
											currentVersion === Version.V2
												? import.meta.env.VITE_WEBSITE_STATS
												: import.meta.env.VITE_WEBSITE_STATS_V1
										}`
									)
								}
								className='flex items-center px-4 py-3 cursor-pointer md:px-3'
								color='secondary'
								sx={{
									'&:hover': {
										color: theme.palette.primary.main,
										background: theme.palette.action.hover
									}
								}}
							>
								<Typography variant={'h6'} component='span'>
									<Trans>Stats</Trans>
								</Typography>
							</Typography>
							{currentVersion === Version.V2 && (
								<Typography
									onClick={() => onHandleLinkClick(BACKEND_API_SWAGGER)}
									className='flex items-center px-4 py-3 cursor-pointer md:px-3'
									color='secondary'
									sx={{
										'&:hover': {
											color: theme.palette.primary.main,
											background: theme.palette.action.hover
										}
									}}
								>
									<Typography variant={'h6'} component='span'>
										<Trans>API</Trans>
									</Typography>
								</Typography>
							)}
						</StyledMenu>
					</div>
				</section>

				<div className='flex items-center space-x-4 sm:space-x-2'>
					{isMatchPc && (
						<Typography
							component='a'
							href={uniV3SwapLink}
							target='_blank'
							className='flex items-center space-x-2 rounded'
							sx={{
								padding: '4px 8px',
								background: theme.custom.selectBg,
								color: theme.palette.text.primary,
								'&:hover': {
									background: theme.palette.action.hover
								}
							}}
						>
							<TokenLogo size={24} />
							<Typography
								component='span'
								className='flex items-center'
								variant='body1'
								color='text.primary'
							>
								{toUsd(appTokenUsdPrice, 4)}
							</Typography>
						</Typography>
					)}
					{isConnected && <Account />}
					{!isConnected && (
						<ConnectWalletButton size='small' className='px-4 py-1' />
					)}
					<Network />
					{!(isMatchMobile || isMatchPad) && <LanguagePopover />}
					{isMatchMobile || isMatchPad ? <Drawer /> : <Setting />}
				</div>
			</Box>
		</div>
	);
}
