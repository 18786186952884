import { Trans } from '@lingui/macro';
import {
	Button,
	ButtonProps,
	IconButton,
	Typography,
	styled,
	useTheme
} from '@mui/material';
import { Transaction_Status, Version } from 'config/constants';

import { IPositionItem } from 'graphql/useMyPositionsGraph';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useFormatPropertyColor } from 'hooks/useFormatProperty';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import {
	formatNumber,
	isGreaterThan,
	isNegative,
	isNumeric,
	isPositive,
	shortenSymbol,
	toPercent,
	toQuoteAmount
} from 'utils';

import Cell from 'components/Common/Cell';
import Description from 'components/Description';
import Stronger from 'components/Stronger';
import Edit from 'components/Svg/Icons/Edit';

import Share from '../../../components/Svg/Icons/Share';

export const StyledButton = styled(Button)<ButtonProps>(({ theme }) => {
	const { isMatchMobile } = useAppBreakpoints();
	return {
		height: isMatchMobile ? 28 : 26,
		padding: '0 16px',
		'&:hover': {
			border: `1px solid ${theme.palette.primary.main}`
		},
		'&:disabled': {
			opacity: theme.palette.action.disabledOpacity,
			'& .MuiTypography-root': {
				opacity: theme.palette.action.disabledOpacity
			}
		}
	};
});

export function CellButtons({
	item,
	onTakeProfitStopLoss,
	onOpenClosePositionDialog,
	onOpenAdjustMarginDialog,
	className,
	align = 'right'
}: {
	item: IPositionItem;
	onTakeProfitStopLoss: (item: IPositionItem) => void;
	onOpenClosePositionDialog: (item: IPositionItem) => void;
	onOpenAdjustMarginDialog?: (item: IPositionItem) => void;
	className?: string;
	align?: 'left' | 'right';
	size?: 'small' | 'large';
}) {
	const currentVersion = useAppSelector(state => state.setting.currentVersion);
	return (
		<div
			className={`flex pt-2 items-center ${
				align === 'right' ? 'justify-end' : 'justify-start'
			} space-x-3 ${className}`}
		>
			{onOpenAdjustMarginDialog && (
				<StyledButton
					variant='outlined'
					size='small'
					color='secondary'
					disabled={item.status === Transaction_Status.Pending}
					onClick={() => onOpenAdjustMarginDialog(item)}
				>
					<Typography color='textPrimary' variant='body2'>
						<Trans>Adjust Margin</Trans>
					</Typography>
				</StyledButton>
			)}
			<StyledButton
				variant='outlined'
				size='small'
				color='secondary'
				disabled={item.status === Transaction_Status.Pending}
				onClick={() => onTakeProfitStopLoss(item)}
			>
				<Typography color='textPrimary' variant='body2'>
					<Trans>TP/SL</Trans>
				</Typography>
			</StyledButton>
			<StyledButton
				variant='outlined'
				size='small'
				color='secondary'
				disabled={
					item.status === Transaction_Status.Pending ||
					isNegative(item.netMargin)
				}
				onClick={() => onOpenClosePositionDialog(item)}
			>
				<Typography color='textPrimary' variant='body2'>
					<Trans>Close</Trans>
				</Typography>
			</StyledButton>
		</div>
	);
}

export function CellMargin({
	item,
	onOpenAdjustMarginDialog,
	className
}: {
	item: IPositionItem;
	onOpenAdjustMarginDialog?: (item: IPositionItem) => void;
	className?: string;
}) {
	const { quoteToken } = useAppSelector(txBaseState);
	const theme = useTheme();
	const currentVersion = useAppSelector(state => state.setting.currentVersion);

	return (
		<Description className={className || ''} label={<Trans>Margin</Trans>}>
			<Typography variant='body2'>
				<div>
					<Stronger
						value={`${formatNumber(item.netMargin)} ${shortenSymbol(
							quoteToken
						)}`}
						tooltip={
							<div className='space-y-3'>
								<div>
									<Typography color='textPrimary' variant='body2'>
										<Trans>
											Margin = Total Added Margin + Net Funding + Reduced &
											Settled
										</Trans>
									</Typography>
								</div>
								<div>
									<Cell
										label={<Trans>Total Added Margin</Trans>}
										value={toQuoteAmount(item.marginIncreased)}
									/>
									<Cell
										label={<Trans>Net Funding</Trans>}
										value={
											<Typography
												variant='body2'
												color={
													isPositive(item.fundingFeeTotal)
														? theme.palette.success.main
														: isNegative(item.fundingFeeTotal)
														? theme.palette.error.main
														: theme.palette.text.primary
												}
											>
												{toQuoteAmount(item.fundingFeeTotal)}
											</Typography>
										}
									/>
									<Cell
										label={<Trans>Reduced & Settled</Trans>}
										value={toQuoteAmount(item.reducedAndSettled)}
									/>
								</div>
							</div>
						}
					/>
					{onOpenAdjustMarginDialog && (
						<IconButton
							className={`${
								item.status === Transaction_Status.Pending
									? 'opacity-30'
									: 'opacity-70'
							} hover:opacity-100`}
							disabled={item.status === Transaction_Status.Pending}
							onClick={() => onOpenAdjustMarginDialog(item)}
						>
							<Edit />
						</IconButton>
					)}
				</div>
			</Typography>
		</Description>
	);
}

export function CellUnRealizedPnL({
	item,
	onOpenShareDialog,
	className,
	align = 'left'
}: {
	item: IPositionItem;
	onOpenShareDialog?: (item: IPositionItem) => void;
	className?: string;
	align?: 'left' | 'right';
}) {
	const color = useFormatPropertyColor(
		isNumeric(item.unrealizedPnL) ? item.unrealizedPnL : ''
	);
	const { isMatchMobile } = useAppBreakpoints();
	const currentVersion = useAppSelector(state => state.setting.currentVersion);

	return (
		<Description
			className={className || ''}
			labelClassName='flex justify-end items-center h-1/2'
			align={align}
			label={
				<Stronger
					value={<Trans>Unrealized PnL</Trans>}
					tooltip={
						<Typography variant='body2'>
							<Trans>Unrealized PnL is calculated based on Index Price.</Trans>
						</Typography>
					}
				/>
			}
		>
			<Typography
				className='flex sm:justify-end items-center flex-wrap w-auto'
				variant='body2'
				align={align}
				color={color}
			>
				<span className='mr-1'>{`${toQuoteAmount(
					item.unrealizedPnL,
					2,
					1,
					currentVersion === Version.V2
				)}`}</span>
				{isNumeric(item.unrealizedPnLRate) &&
					isGreaterThan(item.netMargin, 0) && (
						<span>
							(
							{`${isPositive(item.unrealizedPnLRate) ? '+' : ''}${toPercent(
								item.unrealizedPnLRate
							)}`}
							)
						</span>
					)}
				{onOpenShareDialog && !isMatchMobile && (
					<IconButton
						onClick={() => onOpenShareDialog(item)}
						className={`opacity-70 hover:opacity-100 sm:absolute sm:top-2 sm:-right-2 ${
							item.status === Transaction_Status.Pending && 'opacity-30'
						}`}
						// sx={isMatchMobile ? { top: 8, right: -8 } : {}}
						disabled={item.status === Transaction_Status.Pending}
					>
						<Share size={16} />
					</IconButton>
				)}
			</Typography>
		</Description>
	);
}

export function CellNetFunding({
	item,
	className,
	align = 'left'
}: {
	item: IPositionItem;
	className?: string;
	align?: 'left' | 'right';
}) {
	const color = useFormatPropertyColor(item.fundingFeeTotal);
	const currentVersion = useAppSelector(state => state.setting.currentVersion);
	return (
		<Description
			className={className || ''}
			align={align}
			label={<Trans>Net Funding</Trans>}
		>
			<Typography align='inherit' sx={{ color: color }} variant='body2'>
				{toQuoteAmount(item.fundingFeeTotal)}
			</Typography>
		</Description>
	);
}
