import JSBI from 'jsbi';

import { TokenInfo } from 'types';

export const TICK_SPACINGS = {
	500: 5,
	3000: 30,
	10000: 100
};

export enum Fee_Amount {
	LOWEST = 100,
	LOW = 500,
	MEDIUM = 3000,
	HIGH = 10000
}

export interface IV3Pool {
	// readonly token0: TokenInfo;
	// readonly token1: TokenInfo;
	readonly fee: Fee_Amount;
	readonly sqrtRatioX96: JSBI;
	// readonly liquidity: JSBI;
	readonly tick: number;
	readonly sqrtPrice: string;
}

// Uni仓位合约返回值
export interface UniLiquidityToken {
	id: string;
	poolAddress: string;
	liquidity: string;
	fee: number;
	priceLower: string;
	priceUpper: string;
	basePriceLower: string;
	basePriceUpper: string;
	tickLower: number;
	tickUpper: number;
	baseToken: TokenInfo;
	quoteToken: TokenInfo;
	token0: TokenInfo;
	token1: TokenInfo;
	closed: boolean;
}

// Uni仓位渲染值
export interface UniLiquidityTokenRender extends UniLiquidityToken {
	price: string;
	priceFromSqrt: string;
	baseAmount: string;
	quoteAmount: string;
	pool: IV3Pool;
	inRange: boolean;
}
