import { useMemo } from 'react';

import { orderBookABI } from 'config/abis';
import {
	Contract_Write_Mode,
	QUOTE_USD,
	Side,
	Transaction_Type
} from 'config/constants';
import { getContractAddress } from 'config/contracts';
import Decimal from 'decimal.js';
import { UseContractWriteConfig, UsePrepareContractWriteConfig } from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { selectOrderOpenType } from 'state/setting/selector'
import { OrderId, RecordForUpdateLimitOrder, TokenInfo } from 'types';
import { computePriceX96, isPositive, shortenSymbolNative, toQuoteAmount } from 'utils';

import { useComputeGasLimit } from './useComputeGasLimit';
import { useCurrentChain } from './useCurrentChain';
import { useSendTransaction } from './useSendTransaction';

export function useSubmitUpdateLimitOrder(
	poolId: string,
	orderIndex: string,
	triggerPrice: string,
	triggerAbove: boolean,
	side: Side,
	baseToken: TokenInfo,
	liquidityDelta: string,
	acceptableTradePrice: string
) {
	const { quoteToken } = useAppSelector(txBaseState);
	const Order_Open_Type = useAppSelector(selectOrderOpenType);
	const { currentChainId } = useCurrentChain();

	const { args, record, description } = useMemo(() => {
		if (!baseToken || !quoteToken || !isPositive(triggerPrice) || !orderIndex) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		console.log('useSubmitUpdateOrder args begin ...');

		const triggerPriceX96 = computePriceX96(
			triggerPrice,
			baseToken.decimals,
			quoteToken.decimals
		);

		const acceptableTradePriceX96 = computePriceX96(
			acceptableTradePrice,
			baseToken.decimals,
			quoteToken.decimals
		);

		const args = [orderIndex, triggerPriceX96, acceptableTradePriceX96];

		const record = {
			transactionType: Transaction_Type.UpdateLimitOrder,
			pool: poolId,
			orderId: `${Order_Open_Type.Increase}-${orderIndex}` as OrderId,
			tokenId: baseToken.address,
			baseToken,
			triggerPrice,
			liquidityDelta,
			side
		} as RecordForUpdateLimitOrder;

		const description = TransactionDescription.UpdatedLimitOrder(
			`${shortenSymbolNative(baseToken, currentChainId)}/${QUOTE_USD}`,
			toQuoteAmount(triggerPrice, baseToken.precision, Decimal.ROUND_HALF_CEIL),
			triggerAbove,
			side
		);

		return {
			args,
			record,
			description
		};
	}, [baseToken, quoteToken, triggerPrice, orderIndex]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address: getContractAddress(currentChainId, 'OrderBook'),
			abi: orderBookABI,
			functionName: 'updateIncreaseOrder',
			args
		} as UseContractWriteConfig;
	}, [currentChainId, args]);

	const { gasLimit } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirmed, isConfirming } = useSendTransaction(
		contractWriteArgs,
		gasLimit
	);

	const onConfirm = () => {
		if (!record) return;
		onSendTransaction(record, description);
	};

	return {
		onConfirm,
		isConfirming,
		isConfirmed
	};
}
