import '@fontsource/noto-sans';
import '@fontsource/noto-sans/400-italic.css';
import '@fontsource/noto-sans/400.css';
import '@fontsource/noto-sans/500.css';
import '@fontsource/noto-sans/600.css';
import '@fontsource/noto-sans/700.css';
import { ThemeOptions, alpha, createTheme, darken } from '@mui/material';
import { App_Theme } from 'config/constants/enum';

declare module '@mui/material/styles' {
	interface Theme {
		mode: string;
		custom: {
			positiveColor: string;
			reverseColor: string;
			replenishColor: string;
			loadingLinearStartColor: string;
			loadingLinearStopColor: string;
			cardBg: string;
			paperBg: string;
			dialogBg: string;
			warningColor: string;
			orangeColor: string;
			selectBg: string;
			selectActive: string;
			ListBg: string;
			ListHoverBg: string;
			ListSelectBg: string;
			ListBorder: string;
			SearchColor: string;
			BlinkTagColor: string;
		};
	}

	// allow configuration using `createTheme`
	interface ThemeOptions {
		mode: string;
		custom?: {
			positiveColor: string;
			reverseColor: string;
			replenishColor: string;
			loadingLinearStartColor: string;
			loadingLinearStopColor: string;
			cardBg: string;
			paperBg: string;
			dialogBg: string;
			warningColor: string;
			orangeColor: string;
			selectBg: string;
			selectActive: string;
			ListBg: string;
			ListHoverBg: string;
			ListSelectBg: string;
			ListBorder: string;
			SearchColor: string;
			BlinkTagColor: string;
		};
	}
}

interface ColorsProps {
	positiveColor: string;
	reverseColor: string;
	dividerColor: string;
	primaryColor: string;
	secondaryColor: string;
	textSecondary: string;
	replenishColor: string;
	paperBg: string;
	defaultBg: string;
	actionHover: string;
	textPrimary: string;
	dialogBg: string;
	cardBg: string;
	loadingLinearStartColor: string;
	loadingLinearStopColor: string;
	warningColor: string;
	orangeColor: string;
	selectBg: string;
	selectActive: string;
	ListBg: string;
	ListHoverBg: string;
	ListSelectBg: string;
	ListBorder: string;
	SearchColor: string;
	BlinkTagColor: string;
}

interface ThemeConfigProps {
	[key: string]: ColorsProps;
}
const white = '#FFF';
const black = '#000';
const themeConfig: ThemeConfigProps = {
	dark: {
		positiveColor: black,
		reverseColor: white,
		dividerColor: alpha(white, 0.1),
		primaryColor: '#C7EE00',
		secondaryColor: '#8B919D',
		replenishColor: '#C7EE00',
		paperBg: '#18191E',
		defaultBg: '#111',
		actionHover: alpha(white, 0.1),
		textPrimary: alpha(white, 0.9),
		textSecondary: '#8B919D',
		dialogBg: '#292B30',
		cardBg: '#292B30',
		loadingLinearStartColor: 'rgba(254, 200, 62, 0)',
		loadingLinearStopColor: '#C7EE00',
		warningColor: '#E0A200',
		orangeColor: '#FFB800',
		selectBg: alpha(white, 0.08),
		selectActive: alpha(white, 0.05),
		ListBg: alpha(white, 0.04),
		ListSelectBg: alpha(white, 0.08),
		ListHoverBg: alpha(white, 0.1),
		ListBorder: alpha(white, 0.16),
		SearchColor: alpha(white, 0.9),
		BlinkTagColor: black
	},
	light: {
		positiveColor: '#F9F9F9',
		reverseColor: black,
		dividerColor: alpha(black, 0.08),
		primaryColor: '#9FBE00',
		secondaryColor: '#8B919D',
		replenishColor: '#C7EE00',
		paperBg: '#F9F9F9',
		defaultBg: '#EBEDF0',
		actionHover: alpha(black, 0.1),
		textPrimary: alpha(black, 0.9),
		textSecondary: '#8B919D',
		dialogBg: '#F9F9F9',
		cardBg: '#EDEEF0',
		loadingLinearStartColor: 'rgba(199, 238, 0 , 0)',
		loadingLinearStopColor: 'rgba(199, 238, 0, 1)',
		warningColor: '#E0A200',
		orangeColor: '#FFB800',
		selectBg: '#EBEDF0',
		selectActive: alpha(black, 0.05),
		ListBg: '#EDEEF0',
		ListHoverBg: '#E1E4E9',
		ListSelectBg: '#E1E4E9',
		ListBorder: alpha(black, 0.16),
		SearchColor: '#18191E',
		BlinkTagColor: alpha(white, 0.9)
	}
};

const BOX_SHADOW = '0px 2px 8px rgba(0, 0, 0, 0.2)';

const createThemeOptions = (mode: App_Theme): ThemeOptions => {
	const {
		positiveColor,
		reverseColor,
		dividerColor,
		primaryColor,
		secondaryColor,
		replenishColor,
		paperBg,
		defaultBg,
		actionHover,
		textPrimary,
		textSecondary,
		dialogBg,
		cardBg,
		loadingLinearStartColor,
		loadingLinearStopColor,
		warningColor,
		orangeColor,
		selectBg,
		selectActive,
		ListBg,
		ListHoverBg,
		ListSelectBg,
		ListBorder,
		SearchColor,
		BlinkTagColor
	} = themeConfig[mode];

	return {
		mode: mode,
		custom: {
			positiveColor,
			reverseColor,
			replenishColor,
			loadingLinearStartColor,
			loadingLinearStopColor,
			cardBg,
			paperBg,
			dialogBg,
			warningColor,
			orangeColor,
			selectBg,
			selectActive,
			ListBg,
			ListHoverBg,
			ListSelectBg,
			ListBorder,
			SearchColor,
			BlinkTagColor
		},
		breakpoints: {
			values: {
				xs: 0,
				sm: 640,
				md: 768,
				lg: 1024.05,
				xl: 1200
			}
		},
		palette: {
			mode: mode,
			primary: {
				main: primaryColor,
				dark: darken(primaryColor, 0.3),
				contrastText: '#fff'
			},
			secondary: {
				main: secondaryColor,
				contrastText: '#fff'
			},
			background: {
				default: defaultBg,
				paper: paperBg
			},
			text: {
				primary: textPrimary,
				secondary: textSecondary,
				disabled: 'rgba(43,46,69,0.5)'
			},
			error: {
				main: '#FE385C'
			},
			success: {
				main: '#0CBA71',
				dark: darken('#0CBA71', 0.3)
			},
			warning: {
				main: warningColor,
				contrastText: '#FE730F'
			},
			info: {
				main: '#4499CE'
			},
			action: {
				disabled: 'rgba(152,153,179,0.75)',
				// disabledBackground: 'rgba(43,46,69,0.5)',
				disabledOpacity: 0.7,
				hover: actionHover,
				selectedOpacity: 0.5
			},
			divider: dividerColor
		},
		shape: {
			borderRadius: 4
		},
		typography: {
			fontFamily: 'Noto Sans',
			fontWeightLight: 300,
			fontWeightRegular: 400,
			fontWeightMedium: 500,
			fontWeightBold: 600,
			h1: {
				fontSize: '32px'
			},
			h2: {
				fontSize: '28px'
			},
			h3: {
				fontSize: '24px'
			},
			h4: {
				fontSize: '20px'
			},
			h5: {
				fontSize: '18px'
			},
			h6: {
				fontSize: '16px'
			},
			body1: {
				fontSize: '0.875rem',
				fontWeight: 400
			},
			body2: {
				fontSize: '0.875rem',
				fontWeight: 400
			}
		},
		components: {
			MuiTypography: {
				defaultProps: {
					variantMapping: {
						body1: 'div',
						body2: 'div'
					}
				},
				styleOverrides: {
					root: {
						fontWeight: 400
					},
					body1: {
						'@media screen and (max-width: 768px)': {
							fontSize: '0.75rem'
						}
					},
					body2: {
						'@media screen and (max-width: 768px)': {
							fontSize: '0.75rem'
						}
					}
				}
			},
			MuiAvatar: {
				styleOverrides: {
					root: {
						width: 24,
						height: 24
					}
				}
			},
			MuiBadge: {
				styleOverrides: {
					standard: {
						background: cardBg,
						color: primaryColor
					},
					colorPrimary: {
						background: replenishColor,
						color: '#000'
					}
				}
			},
			MuiButtonBase: {
				defaultProps: {
					disableRipple: true
				},
				styleOverrides: {}
			},
			MuiToggleButton: {
				styleOverrides: {
					root: {
						backgroundColor: 'inherit'
					}
				}
			},
			MuiInputBase: {
				styleOverrides: {
					root: {
						lineHeight: 1,
						backgroundColor: cardBg,
						padding: 0,
						outline: 0,
						borderRadius: 4
					},
					input: {
						padding: '8px 12px',
						height: 'auto',
						backgroundColor: cardBg,
						borderRadius: 4,
						fontSize: 16
					},
					adornedStart: {
						backgroundColor: cardBg
					},
					adornedEnd: {
						backgroundColor: cardBg
					}
				}
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						background: cardBg,
						borderRadius: 4
					},
					input: {
						background: mode === App_Theme.dark ? cardBg : 'inherit',
						fontSize: 14,
						padding: 14,
						'&:focus': {
							boxShadow: 'none'
						}
					},
					notchedOutline: {
						'&:hover': {
							borderColor: alpha(cardBg, 0.2)
						}
					}
				}
			},
			MuiTooltip: {
				styleOverrides: {
					tooltip: {
						maxWidth: 420,
						color: textPrimary,
						backgroundColor: dialogBg,
						fontSize: '13px',
						padding: '16px',
						fontWeight: 400,
						boxShadow: BOX_SHADOW,
						filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.14))',
						overflowWrap: 'break-word',
						'@media screen and (max-width: 768px)': {
							maxWidth: 320
						}
					},
					arrow: {
						color: dialogBg
					},
					tooltipPlacementBottom: {
						marginTop: '10px !important'
					}
				}
			},
			MuiTableContainer: {
				styleOverrides: {
					root: {
						width: 'calc(100% + 32px)',
						transform: 'translate(-16px, 0)',
						backgroundColor: paperBg
						// borderRadius: 4,
						// paddingBottom: '12px'
					}
				}
			},
			MuiSlider: {
				styleOverrides: {
					root: {
						'@media screen and (max-width: 768px)': {
							paddingBottom: '4px !important',
							paddingTop: '15px'
						}
					}
				}
			},
			MuiTable: {
				styleOverrides: {
					root: {
						'& .MuiTableCell-root:first-of-type': {
							paddingLeft: 16
						},
						'& .MuiTableCell-root:last-of-type': {
							paddingRight: 16
						}
					}
				}
			},
			MuiTableBody: {
				styleOverrides: {
					root: {}
				}
			},
			MuiTableRow: {
				styleOverrides: {
					head: {
						borderTopLeftRadius: 8,
						borderTopRightRadius: 8
					},
					hover: {
						background: `${actionHover} !important`
					}
				}
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						borderBottom: 0,
						padding: `8px 0`,
						'@media screen and (max-width: 768px)': {
							fontSize: 12
						}
					},
					head: {
						backgroundColor: 'inherit',
						color: textSecondary,
						fontWeight: 'normal'
					}
				}
			},
			MuiDrawer: {
				styleOverrides: {
					paper: {
						width: 'auto',
						padding: '16px',
						boxSizing: 'border-box'
					}
				}
			},
			MuiCard: {
				styleOverrides: {
					root: {
						position: 'relative',
						backgroundColor: cardBg,
						borderRadius: 4,
						borderColor: dividerColor,
						'&.MuiPaper-outlined': {
							backgroundColor: paperBg
						}
					}
				}
			},
			MuiCardContent: {
				styleOverrides: {
					root: {
						'&:last-child': {
							paddingBottom: '16px'
						}
					}
				}
			},
			MuiCardActionArea: {
				styleOverrides: {
					root: {}
				}
			},
			MuiDivider: {
				styleOverrides: {
					root: {}
				}
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						borderRadius: 4,
						boxShadow: 'none',
						background: paperBg
					}
				}
			},
			MuiContainer: {
				styleOverrides: {
					root: {
						// width: 1000
					}
				}
			},
			MuiTabs: {
				styleOverrides: {
					root: {
						minHeight: 24
					},
					scrollButtons: {
						width: 'auto'
					}
				}
			},
			MuiIconButton: {
				styleOverrides: {
					root: {
						padding: `6px`,
						'&:hover': {
							background: actionHover
						}
					}
				}
			},
			MuiButton: {
				styleOverrides: {
					root: {
						whiteSpace: 'nowrap',
						textTransform: 'inherit',
						fontWeight: 500,
						boxShadow: 'none',
						'&:hover': {
							boxShadow: 'none',
							background: 'none'
						}
					},
					contained: {
						color: '#000'
					},
					containedInherit: {
						background: mode === App_Theme.dark ? dividerColor : defaultBg,
						'&:hover': {
							background: actionHover
						}
					},
					containedSuccess: {
						color: '#fff'
					},
					text: {
						fontWeight: 500,
						'&:hover': {
							background: actionHover
						}
					},
					startIcon: {
						marginRight: 4
					},
					endIcon: {
						marginLeft: 4
					},
					sizeSmall: {
						height: 32
					},
					sizeMedium: {
						height: 40
					},
					sizeLarge: {
						height: 48
					}
				}
			},
			MuiTab: {
				styleOverrides: {
					root: {
						minWidth: 'auto !important',
						maxWidth: 'auto',
						padding: '12px 24px',
						textTransform: 'capitalize',
						minHeight: 'auto'
					},
					textColorPrimary: {
						color: textSecondary,
						'&.Mui-selected': {
							color: primaryColor,
							fontWeight: 500,
							fontfamily: 'Noto Bold'
						}
					}
				}
			},
			MuiChip: {
				styleOverrides: {
					root: {
						height: 'auto',
						backgroundColor: actionHover,
						'&:hover': {
							backgroundColor: actionHover
						}
					},
					filled: {
						// backgroundColor: alpha("#000", mode === "dark" ? 0.4 : 0.1),
					},
					outlined: {
						border: `1px solid ${dividerColor}`
					}
				}
			},
			MuiList: {
				styleOverrides: {
					root: {
						padding: 0
					}
				}
			},
			MuiListItem: {
				styleOverrides: {
					root: {
						paddingLeft: 0,
						paddingRight: 0,
						'&:last-of-type': {
							borderBottom: 'none !important'
						}
					}
				}
			},
			MuiListItemButton: {
				styleOverrides: {
					root: {
						padding: [8, 16],
						backgroundColor: mode === App_Theme.dark ? cardBg : white,
						boxShadow: 'none',
						'&:hover': {
							backgroundColor: cardBg
						}
					}
				}
			},
			MuiListItemIcon: {
				styleOverrides: {
					root: {
						color: textPrimary,
						minWidth: '28px !important'
						// justifyContent: 'flex-end'
					}
				}
			},
			MuiListSubheader: {
				styleOverrides: {
					gutters: {
						paddingLeft: 8,
						paddingRight: 8
					}
				}
			},
			MuiCollapse: {
				styleOverrides: {
					root: {
						border: `1px solid ${dividerColor}`
					}
				}
			},
			MuiDialog: {
				styleOverrides: {
					root: {
						'& .MuiCard-root': {
							backgroundColor: mode === App_Theme.dark ? paperBg : cardBg
						},
						'& .MuiInputBase-root': {
							backgroundColor: mode === App_Theme.dark ? paperBg : cardBg
						},
						'& .MuiInputBase-input': {
							backgroundColor: mode === App_Theme.dark ? paperBg : cardBg
						},
						'& .MuiTableContainer-root': {
							width: 'calc(100%)',
							transform: 'translate(0, 0)'
						}
					},
					paper: {
						background: dialogBg,
						margin: 0,
						minWidth: 360,
						'@media screen and (max-width: 767px)': {
							minWidth: 'auto'
						}
					},
					paperWidthSm: {
						width: 420,
						maxWidth: 750,
						'@media screen and (max-width: 767px)': {
							position: 'absolute',
							width: '100%',
							bottom: 0,
							maxHeight: '85%',
							height: 'auto',
							overflowY: 'auto'
						}
					},
					paperWidthMd: {
						width: 550,
						maxWidth: 750
					}
				}
			},
			MuiDialogContent: {
				styleOverrides: {
					root: {
						padding: '24px',
						'@media screen and (max-width: 640px)': {
							padding: '16px 24px 24px'
						}
					}
				}
			},
			MuiDialogActions: {
				styleOverrides: {
					root: {
						padding: '24px',
						'@media screen and (max-width: 640px)': {
							padding: '12px 24px 16px'
						}
					}
				}
			},
			MuiSvgIcon: {
				styleOverrides: {
					fontSizeSmall: {
						fontSize: '1rem'
					}
				}
			},
			MuiSelect: {
				styleOverrides: {
					select: {
						minHeight: 'auto',
						border: '0 none',
						fontSize: '12px',
						'&.Mui-selected': {
							backgroundColor: actionHover
						}
					},
					filled: {},
					outlined: {
						// borderRadius: 32,
						padding: 0
					},
					icon: {
						right: 8,
						color: textSecondary
					}
				}
			},
			MuiMenuItem: {
				styleOverrides: {
					root: {
						fontSize: '0.875rem',
						padding: '12px 16px'
					}
				}
			},
			MuiPopover: {
				styleOverrides: {
					paper: {
						backgroundColor: dialogBg,
						maxWidth: `calc(100% - 24px)`,
						boxShadow: BOX_SHADOW
					}
				}
			},
			MuiToggleButtonGroup: {
				styleOverrides: {
					root: {
						backgroundColor: defaultBg
					},
					grouped: {
						color: textSecondary,
						margin: '4px',
						padding: `4px 8px`,
						border: 0,
						'&.Mui-selected': {
							color: textPrimary,
							backgroundColor: paperBg,
							'&:hover': {
								backgroundColor: paperBg
							}
						},
						'&.Mui-disabled': {
							border: 0
						},
						'&:not(:first-of-type)': {
							borderRadius: '8px'
						},
						'&:first-of-type': {
							borderRadius: 4,
							marginRight: 0
						}
					}
				}
			},
			MuiSwitch: {
				styleOverrides: {
					root: {
						'& .MuiSwitch-track': {
							backgroundColor: defaultBg
						}
					}
				}
			},
			MuiAlert: {
				styleOverrides: {
					root: {
						background: 'none',
						padding: '6px 0'
					},
					action: {
						marginRight: -4
					}
				}
			},
			MuiSnackbar: {
				styleOverrides: {
					root: {
						borderRadius: 4,
						boxShadow: BOX_SHADOW
					}
				}
			},
			MuiStepConnector: {
				styleOverrides: {
					root: {},
					line: {
						borderTopStyle: 'dashed'
					}
				}
			},
			MuiStepIcon: {
				styleOverrides: {
					root: {
						color: textSecondary,
						paddingRight: 4
					},
					text: {
						fill: paperBg,
						fontSize: 15,
						fontWeight: 600
					}
				}
			},
			MuiFormControlLabel: {
				styleOverrides: {
					root: {
						color: textSecondary
					}
				}
			},
			MuiFormHelperText: {
				styleOverrides: {
					root: {
						marginLeft: 0,
						marginRight: 0
					}
				}
			},
			MuiCheckbox: {
				styleOverrides: {
					root: {
						color: alpha(textSecondary, 0.75),
						'&.Mui-disabled': {
							color: alpha(textSecondary, 0.35)
						},
						'&:hover': {
							background: 'none',
							'.MuiSvgIcon-root': {
								'@media screen and (min-width: 640px)': {
									color: primaryColor
								}
							}
						}
					}
				}
			},
			MuiCircularProgress: {
				styleOverrides: {
					root: {
						color: textPrimary
					}
				}
			}
		}
	};
};

export default createTheme(createThemeOptions(App_Theme.dark));

export const getTheme = (theme: App_Theme.dark | App_Theme.light) =>
	createTheme(createThemeOptions(theme));
