import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import App from 'App';
import * as buffer from 'buffer';
import { client } from 'config/constants/connector/wagmi';
import Decimal from 'decimal.js';

import { enableMapSet } from 'immer';
import { persistStore } from 'reduxjs-toolkit-persist';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { store } from 'state';
import { SWRConfig } from 'swr';
import theme from 'theme';

import { LanguageProvider } from 'components/Language/LanguageProvider';
import LogoLoading from 'components/Svg/Icons/NewLogoLoading';
import { WagmiProvider } from 'components/Wallet/WalletProvider';

import './index.css';

if (typeof (window as any).global === 'undefined') {
	(window as any).global = window;
}
if (typeof (window as any).Buffer === 'undefined') {
	(window as any).Buffer = buffer.Buffer;
}
enableMapSet();

Decimal.set({ precision: 64 });
Decimal.set({ toExpPos: 64 });
Decimal.set({ rounding: Decimal.ROUND_DOWN });

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [
		// eslint-disable-next-line import/namespace
		new Sentry.BrowserTracing()
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

window.addEventListener('vite:preloadError', () => {
	window.location.reload(); // 例如，刷新页面
});

const container = document.querySelector('#root');
if (container) {
	const root = createRoot(container);
	root.render(
		<Suspense fallback={<LogoLoading />}>
			<BrowserRouter>
				<Provider store={store}>
					<PersistGate
						loading={<LogoLoading />}
						persistor={persistStore(store)}
					>
						<ThemeProvider theme={theme}>
							<LanguageProvider>
								<WagmiProvider client={client}>
									<SWRConfig
										value={{
											revalidateIfStale: true,
											refreshInterval: 0,
											dedupingInterval: 0
										}}
									>
										<App />
									</SWRConfig>
								</WagmiProvider>
							</LanguageProvider>
						</ThemeProvider>
					</PersistGate>
				</Provider>
			</BrowserRouter>
		</Suspense>
	);
}
