import React, { useContext, useEffect, useState } from 'react';

import { Trans } from '@lingui/macro';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Divider, Typography, useTheme } from '@mui/material';
import { Side } from 'config/constants';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useGetPool } from 'hooks/useGetPool';
import { IPoolItem } from 'types';

import Dialog from 'components/Common/Dialog';
import StyledTab from 'components/Common/StyledTab';
import StyledTabs from 'components/Common/StyledTabs';
import SelectPools from 'components/SelectPools';

import { ComputerContext, ComputerContextProvider } from './ComputerContext';
import ComputerEntryPrice from './ComputerEntryPrice';
import ComputerLiquidationPrice from './ComputerLiquidationPrice';
import ComputerPnL from './ComputerPnL';
import ComputerTargetPrice from './ComputerTargetPrice';

export enum Type_Computer {
	PnL = 'PnL',
	TargetPrice = 'TargetPrice',
	LiquiditionPrice = 'LiquiditionPrice',
	EntryPrice = 'EntryPrice'
}

const ComputerDialog = ({
	pool,
	side,
	onClose,
	back
}: {
	pool: IPoolItem | null;
	side: Side;
	onClose: () => void;
	back?: () => void;
}) => {
	const { isMatchPc, isMatchMobile } = useAppBreakpoints();

	const { currentPool, setCurrentPool, setSide, setMarketPrice } =
		useContext(ComputerContext);

	const targetPool = useGetPool(currentPool?.id);

	useEffect(() => {
		if (pool?.id) {
			setCurrentPool(pool);
		}
	}, [pool?.id]);

	useEffect(() => {
		if (targetPool?.price) {
			setMarketPrice(targetPool?.price);
		}
	}, [targetPool]);

	useEffect(() => {
		setSide(side);
	}, [side]);

	const [computerType, setComputerType] = useState<Type_Computer>(
		Type_Computer.PnL
	);

	const onHandleChangeType = (
		event: React.SyntheticEvent,
		newValue: Type_Computer
	) => {
		setComputerType(newValue);
	};
	const theme = useTheme();

	return (
		<Dialog
			open
			height={isMatchMobile ? '100%' : 'auto'}
			width={isMatchPc ? 692 : '100%'}
			onClose={onClose}
			titleAlign='left'
			title={
				<div className='flex items-center gap-6'>
					{back && (
						<ArrowBackIosNewIcon
							onClick={back}
							className='cursor-pointer'
							fontSize='small'
						/>
					)}
					<SelectPools
						pool={currentPool}
						onSelect={setCurrentPool}
						simpleMode
					/>
				</div>
			}
		>
			<article className='space-y-4'>
				<div className='space-y-2'>
					<StyledTabs
						variant='scrollable'
						scrollButtons='auto'
						allowScrollButtonsMobile
						value={computerType}
						onChange={onHandleChangeType}
						sx={{
							'& [aria-selected=false]': {
								fontWeight: 400
							},
							'& .MuiTabs-scroller': {
								margin: 0
							},
							'& .MuiTabScrollButton-root:first-of-type': {
								left: '-5px',
								top: 0,
								height: '28px',
								width: '36px',
								backgroundImage: `linear-gradient(-270deg,${theme.custom.dialogBg} 72.22%,hsla(0,0%,7%,0) 100%)`,
								opacity: 1,
								zIndex: 100,
								justifyContent: 'start'
							},
							'& .MuiTabScrollButton-root:last-of-type': {
								right: '-5px',
								top: 0,
								height: '28px',
								width: '36px',
								backgroundImage: `linear-gradient(270deg,${theme.custom.dialogBg} 72.22%,hsla(0,0%,7%,0) 100%)`,
								opacity: 1,
								justifyContent: 'end'
							}
						}}
					>
						<StyledTab
							className='pl-1 pr-5'
							label={<Trans>PnL</Trans>}
							value={Type_Computer.PnL}
						/>
						<StyledTab
							className='px-5'
							label={<Trans>Target Price</Trans>}
							value={Type_Computer.TargetPrice}
						/>
						<StyledTab
							className='px-5'
							label={<Trans>Liquidation Price</Trans>}
							value={Type_Computer.LiquiditionPrice}
						/>
						<StyledTab
							className='px-5'
							label={<Trans>Entry Price</Trans>}
							value={Type_Computer.EntryPrice}
						/>
					</StyledTabs>
					<Divider />
				</div>
				{computerType === Type_Computer.PnL && <ComputerPnL />}
				{computerType === Type_Computer.TargetPrice && <ComputerTargetPrice />}
				{computerType === Type_Computer.LiquiditionPrice && (
					<ComputerLiquidationPrice />
				)}
				{computerType === Type_Computer.EntryPrice && <ComputerEntryPrice />}
				<Typography fontSize={12} variant='body2' color='secondary'>
					<Trans>
						*The calculation is for reference only and does not include trading
						fee, execution fee and other actual costs.
					</Trans>
				</Typography>
			</article>
		</Dialog>
	);
};

export default function Computer({
	pool,
	side,
	onClose,
	back
}: {
	pool: IPoolItem;
	side: Side;
	onClose: () => void;
	back?: () => void;
}) {
	return (
		<ComputerContextProvider>
			<ComputerDialog pool={pool} onClose={onClose} side={side} back={back} />
		</ComputerContextProvider>
	);
}
