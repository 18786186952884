import { ReactNode, createContext, useEffect, useMemo, useState } from 'react';

import { Side, Trade_Type, Transaction_Type, Version } from 'config/constants';
import { useAccount } from 'wagmi';

import { IPositionItem } from 'graphql/useMyPositionsGraph';
import { useCheckLogin } from 'hooks/useCurrentChain';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';
import { setTransactionType } from 'state/tx/slice';

import { useCurrentPage } from '../hooks/useCurrentPage';

export interface ITradeContext {
	tradeDirection: Side;
	tradeType: Trade_Type;
	setTradeDirection: (value: Side) => void;
	setTradeType: (value: Trade_Type) => void;
	positionInfo: IPositionItem | null;
	setPositionInfo: (value: IPositionItem | null) => void;
	dialogIsVisible: boolean;
	setDialogIsVisible: (dialogIsVisible: boolean) => void;
}

export const TradeContext = createContext<ITradeContext>({
	tradeDirection: Side.LONG,
	tradeType: Trade_Type.Limit,
	setTradeDirection: () => void 0,
	setTradeType: () => void 0,
	positionInfo: null,
	setPositionInfo: () => void 0,
	dialogIsVisible: false,
	setDialogIsVisible: () => void 0
});

export function TradeContextProvider({ children }: { children: ReactNode }) {
	const dispatch = useAppDispatch();
	const isLogin = useCheckLogin();
	const { address } = useAccount();
	const currentVersion = useAppSelector(selectVersion);

	const currentPage = useCurrentPage();

	const [positionInfo, setPositionInfo] = useState<IPositionItem | null>(null);
	const [tradeDirection, setTradeDirection] = useState<Side>(Side.LONG);
	const [tradeType, setTradeType] = useState<Trade_Type>(Trade_Type.Market);
	const [dialogIsVisible, setDialogIsVisible] = useState(false);

	// 监听当前交易类型TransactionType的设置
	useEffect(() => {
		if (currentVersion === Version.V2 && currentPage === '/markets') {
			return;
		}

		if (tradeDirection === Side.LONG) {
			if (tradeType === Trade_Type.Market) {
				currentVersion === Version.V1
					? dispatch(setTransactionType(Transaction_Type.Long))
					: dispatch(setTransactionType(Transaction_Type.LongV2));
			} else {
				currentVersion === Version.V1
					? dispatch(setTransactionType(Transaction_Type.LongLimitOrder))
					: dispatch(setTransactionType(Transaction_Type.LongLimitOrderV2));
			}
		} else {
			if (tradeType === Trade_Type.Market) {
				currentVersion === Version.V1
					? dispatch(setTransactionType(Transaction_Type.Short))
					: dispatch(setTransactionType(Transaction_Type.ShortV2));
			} else {
				currentVersion === Version.V1
					? dispatch(setTransactionType(Transaction_Type.ShortLimitOrder))
					: dispatch(setTransactionType(Transaction_Type.ShortLimitOrderV2));
			}
		}
	}, [tradeDirection, tradeType, currentVersion, currentPage]);

	// 退出登录，需要清空当前全局已有仓位信息
	useEffect(() => {
		if (!isLogin) {
			setPositionInfo(null);
		}
	}, [isLogin, address]);

	const value = useMemo(() => {
		return {
			tradeDirection,
			setTradeDirection,
			tradeType,
			setTradeType,
			positionInfo,
			setPositionInfo,
			dialogIsVisible,
			setDialogIsVisible
		};
	}, [
		tradeDirection,
		setTradeDirection,
		tradeType,
		setTradeType,
		positionInfo,
		setPositionInfo,
		dialogIsVisible,
		setDialogIsVisible
	]);

	return (
		<TradeContext.Provider value={value}>{children}</TradeContext.Provider>
	);
}
