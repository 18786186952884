import { arbitrumGoerli } from './connector/chains';
import { Env } from './enum';

export const UNI_WETH_ARBITRUM_GOERLI_DEV = {
	name: 'Wrapped Ether',
	address: '0x980B62Da83eFf3D4576C647993b0c1D7faf17c73',
	symbol: 'WETH',
	decimals: 18,
	chainId: arbitrumGoerli.id,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
	precision: 2,
	positionUnits: 3,
	sort: -1
};

export const UNI_WETH_ARBITRUM_GOERLI_PROD = {
	name: 'Wrapped Ether',
	address: '0xe39Ab88f8A4777030A534146A9Ca3B52bd5D43A3',
	symbol: 'WETH',
	decimals: 18,
	chainId: arbitrumGoerli.id,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
	precision: 2,
	positionUnits: 3,
	sort: -1
};

export const EQU_ARBITRUM_GOERLI_PROD = {
	name: 'EQU Coin',
	address: '0x015A5F94860bee9c0752895e613493ca93E3334B',
	symbol: 'EQU',
	decimals: 18,
	chainId: arbitrumGoerli.id,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
	precision: 4,
	positionUnits: 4,
	sort: -1
};

export const EQU_ARBITRUM_GOERLI_DEV = {
	name: 'EQU Coin',
	address: '0x70Ad55cD2A18D8A49A330114EF9A1c378454Fab8',
	symbol: 'EQU',
	decimals: 18,
	chainId: arbitrumGoerli.id,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
	precision: 4,
	positionUnits: 4,
	sort: -1
};

export const veEQU_ARBITRUM_GOERLI_PROD = {
	name: 'EQU Coin',
	address: '0x2493436dCB04C49c98107F7bfF591C962A696bCF',
	symbol: 'EQU',
	decimals: 18,
	chainId: arbitrumGoerli.id,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
	precision: 4,
	positionUnits: 2,
	sort: -1
};

export const veEQU_ARBITRUM_GOERLI_DEV = {
	name: 'EQU Coin',
	address: '0x98f8027e66E7B1A91E4aa64B4070be295836676D',
	symbol: 'EQU',
	decimals: 18,
	chainId: arbitrumGoerli.id,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
	precision: 4,
	positionUnits: 2,
	sort: -1
};

export const USDT_ARBITRUM_GOERLI_PROD = {
	name: 'USD Coin',
	address: '0x130a10D76E53eC70C2d1c05e9C2EcfB5C3350fe0',
	symbol: 'USDT',
	decimals: 6,
	chainId: arbitrumGoerli.id,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
	precision: 2,
	positionUnits: 2,
	sort: 999
};

export const USDT_ARBITRUM_GOERLI_DEV = {
	name: 'USD Coin',
	address: '0x130a10D76E53eC70C2d1c05e9C2EcfB5C3350fe0',
	symbol: 'USDT',
	decimals: 6,
	chainId: arbitrumGoerli.id,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
	precision: 2,
	positionUnits: 2,
	sort: 999
};

export const WBTC_ARBITRUM_GOERLI = {
	name: 'Wrapped BTC',
	address: '0xaE7c6F449fC29bb07EF9C8b2a9400bC5247E0157',
	symbol: 'BTC',
	decimals: 18,
	chainId: arbitrumGoerli.id,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	precision: 2,
	positionUnits: 4,
	sort: 2
};

export const WETH_ARBITRUM_GOERLI = {
	name: 'Wrapped Ether',
	address: '0xa20Aa54C853348248C472717a65EFfEDb8357e9D',
	symbol: 'WETH',
	decimals: 18,
	chainId: arbitrumGoerli.id,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
	precision: 2,
	positionUnits: 3,
	sort: 1
};

export const XRP_ARBITRUM_GOERLI = {
	name: 'XRP',
	address: '0x3743ED87a3CD6D597151B4A27CE7Dfc5A7BC2149',
	symbol: 'XRP',
	decimals: 18,
	chainId: arbitrumGoerli.id,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
	precision: 5,
	positionUnits: 1,
	sort: 998
};

export const DOGE_ARBITRUM_GOERLI = {
	name: 'Doge',
	address: '0x91F728b5BA9AE11Aea0895fb001f61D7e407D14A',
	symbol: 'DOGE',
	decimals: 18,
	chainId: arbitrumGoerli.id,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
	precision: 6,
	positionUnits: 1,
	sort: 997
};

export const ARB_ARBITRUM_GOERLI = {
	name: 'Arbitrum',
	address: '0x83693222545BB53f4Bf32C1016d69633B0DD6226',
	symbol: 'ARB',
	decimals: 18,
	chainId: arbitrumGoerli.id,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
	precision: 4,
	positionUnits: 1,
	sort: 3
};

export const DAI_ARBITRUM_GOERLI = {
	name: 'Dai',
	address: '0x8ddfe2752c91f4aa20a5adb7b187c6d6020283d7',
	symbol: 'DAI',
	decimals: 18,
	chainId: arbitrumGoerli.id,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
	precision: 4,
	positionUnits: 2,
	sort: 5
};

export const LINK_ARBITRUM_GOERLI = {
	name: 'Chainlink',
	address: '0x3dCc3Ef1d97269fD1e4464C0C35ED87d79c20552',
	symbol: 'LINK',
	decimals: 18,
	chainId: arbitrumGoerli.id,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
	precision: 3,
	positionUnits: 1,
	sort: 4
};

export const eLINK_ARBITRUM_GOERLI = {
	name: 'Chainlink',
	address: '0xf819e547928b4abf6e80cb62dc4ed14e8090feab',
	symbol: 'eLINK',
	decimals: 18,
	chainId: arbitrumGoerli.id,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
	precision: 4,
	positionUnits: 1,
	sort: 4
};

export const WETH_ARBITRUM_GOERLI_V2 = {
	name: 'Wrapped Ether',
	address: '0x9Ec3F13cC1214D70f051bdA27fE48b72D8F55985',
	symbol: 'WETH',
	decimals: 18,
	chainId: arbitrumGoerli.id,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
	precision: 2,
	positionUnits: 3,
	sort: 1
};

export const WBTC_ARBITRUM_GOERLI_V2 = {
	name: 'Wrapped BTC',
	address: '0x8075F2fB8BcAB75E01288e398615425f77D6B8cb',
	symbol: 'BTC',
	decimals: 18,
	chainId: arbitrumGoerli.id,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	precision: 2,
	positionUnits: 4,
	sort: 2
};

export const SOL_ARBITRUM_GOERLI_V2 = {
	name: 'Wrapped SOL',
	address: '0x5220a37c9f693aC673006DCEA72De32B8fb00411',
	symbol: 'SOL',
	decimals: 18,
	chainId: arbitrumGoerli.id,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	precision: 2,
	positionUnits: 4,
	sort: 2
};

export const TOKENS_ARBITRUM_GOERLI = [
	(import.meta.env.MODE as Env) === Env.development
		? USDT_ARBITRUM_GOERLI_DEV
		: USDT_ARBITRUM_GOERLI_PROD,
	(import.meta.env.MODE as Env) === Env.development
		? EQU_ARBITRUM_GOERLI_DEV
		: EQU_ARBITRUM_GOERLI_PROD,
	UNI_WETH_ARBITRUM_GOERLI_DEV,
	WBTC_ARBITRUM_GOERLI,
	WETH_ARBITRUM_GOERLI,
	XRP_ARBITRUM_GOERLI,
	DOGE_ARBITRUM_GOERLI,
	ARB_ARBITRUM_GOERLI,
	DAI_ARBITRUM_GOERLI,
	LINK_ARBITRUM_GOERLI,
	eLINK_ARBITRUM_GOERLI
];
