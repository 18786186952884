import { useEffect, useState } from 'react';

import { QUOTE_USD_PRECISION, Version } from 'config/constants';
import { useMultiTokens, useTokensPrice } from 'fetch/useRequest';
import moment from 'moment/moment';

import { UtilHelper } from 'entities/UtilHelper';
import { useAllTokens } from 'hooks/useAllTokens';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { sortBy } from 'lodash';
import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';
import {
	IGlobalFundingRateSample,
	IGlobalLiquidityPosition,
	IGlobalPosition,
	IPoolItem,
	IPriceState
} from 'types';
import {
	catchFn,
	formatRate,
	formatUnits,
	isPositive,
	minus,
	multipliedBy,
	shortenSymbolNative
} from 'utils';

import { FundingRateUtil } from '../entities/FundingRateUtil';
import { usePoolsQuery } from './__generated_trade__/types-and-hooks';

export function usePoolsGraph() {
	const { blockHeightBefore24h } = useAppSelector(globalBaseState);
	const currentVersion = useAppSelector(selectVersion);
	const { currentChainId } = useCurrentChain();
	const allTokens = useAllTokens();
	const [loading, setLoading] = useState<boolean>(true);
	const [poolList, setPoolList] = useState<Array<IPoolItem>>([]);
	const [poolMap, setPoolMap] = useState<Map<string, IPoolItem> | null>(null);

	const {
		data,
		loading: isLoading,
		refetch
	} = usePoolsQuery({
		variables: { blockBefore: Number(blockHeightBefore24h) },
		skip: !isPositive(blockHeightBefore24h) && !(currentVersion === Version.V1)
	});

	const { tokensPrice } = useTokensPrice(
		data?.pools?.map(pool => pool.token.id) || null
	);

	const { tokensMultiPrice } = useMultiTokens(
		data?.pools?.map(pool => pool.token.id) || null
	);

	useEffect(() => {
		if (!isLoading) {
			setLoading(isLoading);
		}
	}, [isLoading]);

	useEffect(() => {
		if (!data || !data.pools || !tokensPrice || !tokensMultiPrice) {
			return;
		}
		const map = new Map<string, IPoolItem>();
		const { pools, poolsBefore } = data;

		const list = [] as Array<IPoolItem>;
		pools.forEach((item, index) => {
			const id = item.id;
			const tokenPriceItem = tokensPrice?.overviews?.find(
				(tokenItem: any) => tokenItem.address.toLowerCase() === item.token.id
			);
			const tokenMultiPriceItem = tokensMultiPrice?.tokens?.find(
				(tokenItem: any) => tokenItem.address.toLowerCase() === item.token.id
			);

			if (!tokenPriceItem || !tokenMultiPriceItem) {
				return;
			}

			const {
				id: tokenId,
				tradingFeeRate,
				maxPriceImpactLiquidity,
				vertices,
				liquidationVertexIndex,
				referralDiscountRate,
				liquidationExecutionFee,
				liquidationFeeRatePerPosition,
				maxLeveragePerPosition,
				maxLeveragePerLiquidityPosition,
				minMarginPerLiquidityPosition,
				minMarginPerPosition
			} = item.token;
			const baseToken = allTokens.get(tokenId);

			// console.log('referralDiscountRate: ', baseToken.symbol, referralDiscountRate);

			if (!baseToken) {
				return;
			}

			const _globalLiquidityPosition = item.globalLiquidityPosition;
			const globalLiquidityPosition = {
				..._globalLiquidityPosition,
				tokenVertices: vertices
			} as IGlobalLiquidityPosition;

			const { longSize, shortSize, lastAdjustFundingRateTime } =
				item.globalPosition;
			const positions = multipliedBy(Math.max(longSize, shortSize), 2);

			const poolBefore = poolsBefore[index];
			let volume24h = item.volumeUSD;
			let realizedProfit24h = item.globalLiquidityPosition.realizedProfit;
			if (poolBefore) {
				volume24h = minus(item.volumeUSD, poolBefore.volumeUSD);
				realizedProfit24h = minus(
					item.globalLiquidityPosition.realizedProfit,
					poolBefore.globalLiquidityPosition.realizedProfit
				);
			}

			const { sampleCount, cumulativePremiumRateX96 } =
				item.globalFundingRateSample;
			const isValidSample = moment(
				Number(lastAdjustFundingRateTime) * 1000
			).isSame(moment(), 'hours');
			const sample = {
				lastAdjustFundingRateTime: isValidSample
					? lastAdjustFundingRateTime
					: moment().set({ minute: 0, seconds: 0 }).unix().toString(),
				sampleCount: isValidSample ? sampleCount : '0',
				cumulativePremiumRateX96: isValidSample ? cumulativePremiumRateX96 : '0'
			} as IGlobalFundingRateSample;

			const {
				premiumRateX96,
				pendingVertexIndex,
				priceVertices,
				liquidationBufferNetSizes
			} = item.priceState;

			const _priceVertices = priceVertices.map((item, index: number) => {
				return {
					id: item.id as string,
					premiumRateX96: item.premiumRateX96 as string,
					premiumRate: formatRate(vertices[index].premiumRate),
					size: item.size as string
				};
			});

			const priceState = {
				maxPriceImpactLiquidity,
				premiumRateX96,
				pendingVertexIndex,
				liquidationVertexIndex,
				currentVertexIndex: UtilHelper.computeCurrentVertexIndex(
					globalLiquidityPosition.netSize,
					_priceVertices
				),
				priceVertices: _priceVertices,
				liquidationBufferNetSizes: liquidationBufferNetSizes
			} as IPriceState;

			const fundingRate = catchFn(() => {
				return FundingRateUtil.caculateFundingRate(
					sample,
					globalLiquidityPosition,
					priceState,
					item.token.interestRate,
					item.token.maxFundingRate
				);
			}, '');

			const poolItem = {
				...tokenPriceItem,
				...tokenMultiPriceItem,
				...item,
				baseSymbol: shortenSymbolNative(baseToken, currentChainId),
				baseToken,
				price: tokenMultiPriceItem?.market_price,
				tradingFeeRate: formatRate(tradingFeeRate),
				maxPriceImpactLiquidity,
				volume24h,
				realizedProfit24h,
				positions,
				priceChange: tokenPriceItem?.price_change_rate,
				marketPrice: tokenMultiPriceItem?.market_price,
				indexPrice: tokenMultiPriceItem?.index_price,
				globalPosition: {
					...item.globalPosition,
					fundingRate
				} as IGlobalPosition,
				globalLiquidityPosition,
				sample,
				priceState,
				maxPriceX96: tokenMultiPriceItem?.max_index_price_x96,
				referralDiscountRate: formatRate(referralDiscountRate),
				longSize,
				shortSize,
				liquidationExecutionFee: formatUnits(
					liquidationExecutionFee,
					QUOTE_USD_PRECISION
				),
				liquidationFeeRatePerPosition: formatRate(
					liquidationFeeRatePerPosition
				),
				maxLeveragePerPosition,
				maxLeveragePerLiquidityPosition,
				minMarginPerLiquidityPosition,
				minMarginPerPosition
			} as IPoolItem;

			map.set(id, poolItem);

			list.push(poolItem);
		});

		const sortList = sortBy(list, o => {
			return o.baseToken.sort;
		});
		setPoolList(sortList as Array<IPoolItem>);
		setPoolMap(map);
	}, [data, allTokens, tokensPrice, tokensMultiPrice]);

	return {
		poolList,
		poolMap,
		loading,
		refetchPools: refetch
	};
}
