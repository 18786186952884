import { useMemo } from 'react';

import { QUOTE_USD, QUOTE_USD_PRECISION } from 'config/constants';
import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { selectPositionMinExecutionFee } from 'state/global/selector';
import { RecordForAdjustMargin, TokenInfo } from 'types';
import {
	formatNumber,
	isPositive,
	parseUnits,
	shortenSymbolNative,
	toQuoteAmount
} from 'utils';

import { positionRouterABI } from '../config/abis';
import { Contract_Write_Mode, Transaction_Type } from '../config/constants';
import { getContractAddress } from '../config/contracts';
import { useAppSelector } from '../state/hooks';
import { txBaseState } from '../state/tx/slice';
import { useComputeGasLimit } from './useComputeGasLimit';
import { useCurrentChain } from './useCurrentChain';
import { useSendTransaction } from './useSendTransaction';

const useSubmitAdjustMarginLiquidity = (
	poolAddress: string,
	positionId: string,
	marginDelta: string,
	transactionType: Transaction_Type,
	baseToken: TokenInfo
) => {
	const { quoteToken } = useAppSelector(txBaseState);
	const positionMinExecutionFee = useAppSelector(selectPositionMinExecutionFee);

	const { currentChainId } = useCurrentChain();

	const { address } = useAccount();

	const { args, record, description } = useMemo(() => {
		if (!baseToken || !quoteToken || !isPositive(marginDelta)) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		console.log('useSubmitAdjustMarginLiquidity begin ...');

		const margin =
			transactionType === Transaction_Type.IncreaseMarginLiquidity
				? parseUnits(marginDelta, QUOTE_USD_PRECISION)
				: `-${parseUnits(marginDelta, QUOTE_USD_PRECISION)}`;

		const args = [poolAddress, positionId, margin, address];

		const record = {
			transactionType,
			pool: poolAddress,
			positionId,
			baseToken,
			quoteAmount: marginDelta
		} as RecordForAdjustMargin;

		const description =
			TransactionDescription.CreateAdjustLiquidityPositionMargin(
				`${shortenSymbolNative(baseToken, currentChainId)}/${QUOTE_USD}`,
				formatNumber(marginDelta),
				transactionType === Transaction_Type.IncreaseMarginLiquidity
			);

		return {
			args,
			record,
			description
		};
	}, [
		transactionType,
		quoteToken,
		poolAddress,
		positionId,
		marginDelta,
		transactionType,
		currentChainId
	]);

	const overrides = useMemo(() => {
		if (!address || !isPositive(positionMinExecutionFee)) {
			return undefined;
		}
		return {
			from: address,
			value: positionMinExecutionFee
		};
	}, [address, positionMinExecutionFee]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address: getContractAddress(currentChainId, 'PositionRouter'),
			abi: positionRouterABI,
			functionName: 'createAdjustLiquidityPositionMargin',
			args,
			overrides
		} as UseContractWriteConfig;
	}, [currentChainId, args, overrides]);

	const { gasLimit } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, setTxSuccessCallback, isConfirmed, isConfirming } =
		useSendTransaction(contractWriteArgs, gasLimit);

	const onConfirm = () => {
		if (!record) return;
		onSendTransaction(record, description);
	};

	return { onConfirm, setTxSuccessCallback, isConfirmed, isConfirming };
};

export default useSubmitAdjustMarginLiquidity;
