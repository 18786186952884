import { isMobile } from 'react-device-detect';

import CoinbaseWallet from 'assets/images/wallets/coinbase.png';
import Metamask from 'assets/images/wallets/metamask.png';
import Okx from 'assets/images/wallets/okx.png';
import WalletConnect from 'assets/images/wallets/walletconnect.png';
import Bitget from 'assets/images/wallets/bitget.png';
import Rabby from 'assets/images/wallets/rabby.svg';
import Trust from 'assets/images/wallets/trust.png';
import Bybit from 'assets/images/wallets/bybit.png';

import { ConnectorConfig, ConnectorNames } from './types';

export const wallets: ConnectorConfig[] = [
	{
		id: 'metaMask',
		title: 'MetaMask',
		icon: Metamask,
		connectorId: isMobile ? ConnectorNames.Injected : ConnectorNames.MetaMask,
		href: 'https://metamask.io/download/'
	},
	{
		id: 'coinbase',
		title: 'Coinbase Wallet',
		icon: CoinbaseWallet,
		connectorId: ConnectorNames.Coinbase
	},
	{
		id: 'okx',
		title: 'OKX Wallet',
		icon: Okx,
		connectorId: ConnectorNames.Okxwallet,
		href: 'https://www.okx.com/web3'
	},
	{	
		id: 'bitget',
		title: "Bitget Wallet",
		icon: Bitget,
		connectorId: ConnectorNames.Bitget,
		href: "https://web3.bitget.com/",
	},
	{	
		id: 'rabby',
		title: "Rabby Wallet",
		icon: Rabby,
		connectorId: ConnectorNames.Rabby,
		href: "https://rabby.io/",
	},
	{
		id: 'trust',
		title: 'Trust Wallet',
		icon: Trust,
		connectorId: ConnectorNames.Trust,
		href: 'https://trustwallet.com/',
	},
	{
		id: 'bybit',
		title: 'Bybit Wallet',
		icon: Bybit,
		connectorId: ConnectorNames.Bybit,
		href: 'https://www.bybit.com/en/web3/',
	},
	{
		id: 'walletconnect',
		title: 'WalletConnect',
		icon: WalletConnect,
		connectorId: ConnectorNames.WalletConnect
	}
];
