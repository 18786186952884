import {
	arbitrum as defaultArbitrum,
	avalanche as defaultAvalanche,
	avalancheFuji as defaultAvalancheFuji,
	goerli as defaultGoerli,
	mainnet as defaultMainnet,
	optimism as defaultOptimism
} from 'wagmi/chains';

import EthereumLogo from 'assets/images/ethereum-logo.png';
import ArbitrumLogo from 'assets/svg/networks/arbitrum-logo.svg';
import AvalancheFujiLogo from 'assets/svg/networks/avalanche-fuji-logo.svg';
import AvalancheLogo from 'assets/svg/networks/avalanche-logo.svg';
import OptimismLogo from 'assets/svg/networks/optimism-logo.svg';

import { Env } from '../enum';
import { ChainConfig } from './types';

export const mainnet: ChainConfig = {
	...defaultMainnet,
	icon: EthereumLogo,
	rpcUrls: {
		...defaultMainnet.rpcUrls,
		public: {
			http: ['https://eth.llamarpc.com', 'https://ethereum.publicnode.com']
		}
	}
};

export const goerli: ChainConfig = {
	...defaultGoerli,
	icon: EthereumLogo,
	rpcUrls: {
		...defaultGoerli.rpcUrls,
		public: {
			http: [
				'https://goerli.blockpi.network/v1/rpc/public',
				'https://rpc.ankr.com/eth_goerli',
				'https://endpoints.omniatech.io/v1/eth/goerli/public'
			]
		}
	}
};

export const arbitrum: ChainConfig = {
	icon: ArbitrumLogo,
	...defaultArbitrum,
	rpcUrls: {
		...defaultArbitrum.rpcUrls,
		public: {
			http: [
				'https://arb1.arbitrum.io/rpc',
				'https://rpc.ankr.com/arbitrum',
				'https://1rpc.io/arb',
				'https://endpoints.omniatech.io/v1/arbitrum/one/public',
				'https://arbitrum.blockpi.network/v1/rpc/public'
			]
		}
	}
};

export const arbitrumGoerli: ChainConfig = {
	testnet: true,
	icon: ArbitrumLogo,
	id: 421614,
	name: 'Arbitrum Sepolia',
	network: 'arbitrum-sepolia',
	nativeCurrency: {
		decimals: 18,
		name: 'Arbitrum Sepolia Ether',
		symbol: 'ETH'
	},
	blockExplorers: {
		default: {
			name: 'arbiscan',
			url: 'https://sepolia.arbiscan.io/'
		},
		etherscan: {
			name: 'arbiscan',
			url: 'https://sepolia.arbiscan.io/'
		}
	},
	rpcUrls: {
		default: {
			http: ['https://arbitrum-sepolia.blockpi.network/v1/rpc/public']
		},
		public: {
			http: ['https://arbitrum-sepolia.blockpi.network/v1/rpc/public']
		}
	}
};

export const optimism: ChainConfig = {
	...defaultOptimism,
	icon: OptimismLogo,
	rpcUrls: {
		...defaultOptimism.rpcUrls,
		public: {
			http: [
				'https://mainnet.optimism.io',
				'https://rpc.ankr.com/optimism',
				'https://endpoints.omniatech.io/v1/op/mainnet/public',
				'https://optimism-mainnet.public.blastapi.io'
			]
		}
	}
};

export const avalanche: ChainConfig = {
	...defaultAvalanche,
	icon: AvalancheLogo,
	rpcUrls: {
		...defaultAvalanche.rpcUrls,
		public: {
			http: [
				'https://avalanche.public-rpc.com',
				'https://rpc.ankr.com/avalanche',
				'https://endpoints.omniatech.io/v1/avax/mainnet/public',
				'https://avalanche.blockpi.network/v1/rpc/public'
			]
		}
	}
};

export const avalancheFuji: ChainConfig = {
	...defaultAvalancheFuji,
	icon: AvalancheFujiLogo,
	rpcUrls: {
		...defaultAvalanche.rpcUrls,
		public: {
			http: [
				'https://ava-testnet.public.blastapi.io/ext/bc/C/rpc',
				'https://rpc.ankr.com/avalanche_fuji',
				'https://endpoints.omniatech.io/v1/avax/fuji/public',
				'https://avalanche-fuji-c-chain.publicnode.com'
			]
		}
	}
};

export const SUPPORTED_CHAINS = [
	(import.meta.env.MODE as Env) === Env.development ? arbitrumGoerli : arbitrum
];

export const SUPPORTED_CHAIN_IDS = SUPPORTED_CHAINS.map(c => c.id);

export const DEFAULT_CHAIN =
	(import.meta.env.MODE as Env) === Env.development ? arbitrumGoerli : arbitrum;
