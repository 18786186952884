import { useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans, t } from '@lingui/macro';
import { FormControlLabel, MenuList, Typography } from '@mui/material';
import { Claim_Type, Transaction_Type, Version } from 'config/constants';
import { useFarmClaimV2RequestByPools } from 'fetch/useRequest';
import { useAccount } from 'wagmi';

import { useSubmitClaimPool2 } from 'hooks/useSubmitClaimPool2';
import ClaimPeriods from 'pages/components/ClaimPeriods';
import { StyledFormControlLabel } from 'pages/components/StyledFormControlLabel';
import { selectClaimableLpStakeQuoteAmount } from 'state/earn/pool2/selector';
import { earnPool2BaseState } from 'state/earn/pool2/slice';
import { selectClaimableLpStakeList } from 'state/earn/stake/selector';
import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';
import { txBaseState } from 'state/tx/slice';
import {
	formatClaimType,
	formatNumber,
	isEqualTo,
	isNumeric,
	isPositive,
	plus,
	shortenSymbol
} from 'utils';

import ApproveButton from 'components/ApproveButton';
import ApproveButtonV2 from 'components/ApproveButtonV2';
import Dialog, { DialogProps } from 'components/Common/Dialog';
import { StyleCheckbox } from 'components/Common/StyleCheckbox';
import { CommonStyledDivider } from 'components/Common/Styled';
import SectionLoading from 'components/SectionLoading';

import EmptyClaim from '../EmptyClaim';
import { Lockup_Period, Reward_Type } from '../types';

export default function ClaimPool2Dialog(props: DialogProps) {
	const { onClose } = props;

	const currentVersion = useAppSelector(selectVersion);
	const { signingMap, appToken, quoteToken } = useAppSelector(txBaseState);
	const { appTimer } = useAppSelector(globalBaseState);
	const { isLoadingPool2Rewards } = useAppSelector(earnPool2BaseState);
	const { claimableAppAmount } = useAppSelector(earnPool2BaseState);
	const claimableStakeQuoteAmount = useAppSelector(
		selectClaimableLpStakeQuoteAmount
	);
	const claimableLpStakeList = useAppSelector(selectClaimableLpStakeList);

	const { address } = useAccount();

	const [period, setPeriod] = useState<Lockup_Period>(Lockup_Period.HIGH);
	const [selectedStakingLp, setSelectedStakingLp] = useState<boolean>(false);
	const [selectedPool2, setSelectedPool2] = useState<boolean>(false);

	const claimableCount = useMemo(() => {
		let count = 0;
		if (isPositive(claimableStakeQuoteAmount)) {
			count++;
		}
		if (isPositive(claimableAppAmount)) {
			count++;
		}
		return count;
	}, [claimableStakeQuoteAmount, claimableAppAmount]);

	const { selectedCount, quoteAmount, appAmount } = useMemo(() => {
		let selectedCount = 0;
		let appAmount = '0';
		let quoteAmount = '0';

		if (selectedStakingLp && isPositive(claimableStakeQuoteAmount)) {
			selectedCount++;
			quoteAmount = plus(quoteAmount, claimableStakeQuoteAmount);
		}
		if (selectedPool2 && isPositive(claimableAppAmount)) {
			selectedCount++;
			appAmount = plus(appAmount, claimableAppAmount);
		}

		return {
			selectedCount,
			quoteAmount,
			appAmount
		};
	}, [
		claimableStakeQuoteAmount,
		claimableAppAmount,
		selectedStakingLp,
		selectedPool2
	]);

	const selectedAll = useMemo(() => {
		if (isEqualTo(selectedCount, claimableCount)) {
			return true;
		}
		return false;
	}, [selectedCount, claimableCount]);

	const { data, isLoading, refetch } = useFarmClaimV2RequestByPools(
		address,
		null,
		useMemo(() => [Reward_Type.POOL2], []),
		period
	);

	useUpdateEffect(() => {
		refetch();
	}, [appTimer]);

	const tokenIds = useMemo(() => {
		if (!claimableLpStakeList || !selectedStakingLp) {
			return null;
		}
		return claimableLpStakeList.map(item => item.stakeID);
	}, [claimableLpStakeList, selectedStakingLp]);

	const { onConfirm, isConfirmed, isConfirming } = useSubmitClaimPool2(
		tokenIds,
		useMemo(() => (selectedPool2 ? data : undefined), [data, selectedPool2]),
		appAmount,
		quoteAmount
	);

	const hasClaimableAmount = useMemo(() => {
		return (
			isPositive(claimableStakeQuoteAmount) || isPositive(claimableAppAmount)
		);
	}, [claimableStakeQuoteAmount, claimableAppAmount]);

	const [disabled, submitText] = useMemo(() => {
		if (!isPositive(quoteAmount) && !isPositive(appAmount)) {
			return [true, t`Claim`];
		}
		if (isPositive(appAmount) && !isNumeric(period)) {
			return [true, t`Claim`];
		}
		if (isLoading) {
			return [true, t`Claim`];
		}
		if (signingMap.has(Transaction_Type.ClaimPool2)) {
			return [true, t`Loading...`];
		}
		if (isConfirming) {
			return [true, t`Submitting...`];
		}
		return [false, t`Claim`];
	}, [isConfirming, signingMap, isLoading, period, quoteAmount, appAmount]);

	useUpdateEffect(() => {
		if (isConfirmed) {
			onClose();
		}
	}, [isConfirmed]);

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setSelectedStakingLp(true);
			setSelectedPool2(true);
		} else {
			setSelectedStakingLp(false);
			setSelectedPool2(false);
		}
	};

	return (
		<Dialog size='large' title={t`Claim`} open={true} onClose={onClose}>
			{isLoadingPool2Rewards ? (
				<SectionLoading />
			) : hasClaimableAmount ? (
				<div className='space-y-4'>
					<section className='space-y-1'>
						<div className='flex'>
							<FormControlLabel
								control={
									<StyleCheckbox
										checked={selectedAll}
										size='small'
										onChange={handleSelectAllClick}
									/>
								}
								label={
									<div className='flex items-center justify-between'>
										<Typography variant='body2' color='secondary'>
											<Trans>Rewards Source</Trans>
										</Typography>
										<Typography
											className='flex-1'
											variant='body2'
											color='secondary'
											align='right'
										>
											<Trans>Claimable Rewards</Trans>
										</Typography>
									</div>
								}
								sx={{
									width: 'calc(100% + 11px)',
									marginRight: 0,
									'& .MuiFormControlLabel-label': {
										flex: 1
									}
								}}
							/>
						</div>

						<MenuList>
							{isPositive(claimableStakeQuoteAmount) && (
								<StyledFormControlLabel
									control={
										<StyleCheckbox
											checked={selectedStakingLp}
											size='small'
											onChange={event =>
												setSelectedStakingLp(event.target.checked)
											}
										/>
									}
									label={
										<div className='w-full flex items-center justify-between'>
											<Typography variant='body2' color='text.primary'>
												{formatClaimType(Claim_Type.StakingLp)}
											</Typography>
											<Typography variant='body2' color='text.primary'>
												{`${formatNumber(
													claimableStakeQuoteAmount,
													quoteToken.positionUnits
												)} ${shortenSymbol(quoteToken)}`}
											</Typography>
										</div>
									}
								/>
							)}
							{isPositive(claimableAppAmount) && (
								<StyledFormControlLabel
									control={
										<StyleCheckbox
											checked={selectedPool2}
											size='small'
											onChange={event => setSelectedPool2(event.target.checked)}
										/>
									}
									label={
										<div className='w-full flex items-center justify-between'>
											<Typography variant='body2' color='text.primary'>
												{formatClaimType(Claim_Type.Pool2)}
											</Typography>
											<Typography variant='body2' color='text.primary'>
												{`${formatNumber(
													claimableAppAmount,
													appToken?.positionUnits
												)} ${shortenSymbol(appToken)}`}
											</Typography>
										</div>
									}
								/>
							)}
						</MenuList>
					</section>
					<CommonStyledDivider />
					<ClaimPeriods
						totalAmount={appAmount}
						quoteAmount={quoteAmount}
						period={period}
						setPeriod={setPeriod}
					/>
					<section>
						{currentVersion === Version.V2 ? (
							<ApproveButtonV2
								componentVariant='confirm'
								fullWidth
								onClick={onConfirm}
								disabled={disabled}
								isApproveRewardCollectorToV1
							>
								{submitText}
							</ApproveButtonV2>
						) : (
							<ApproveButton
								componentVariant='confirm'
								fullWidth
								onClick={onConfirm}
								disabled={disabled}
								isApproveRewardCollector
							>
								{submitText}
							</ApproveButton>
						)}
					</section>
				</div>
			) : (
				<EmptyClaim />
			)}
		</Dialog>
	);
}
