import { useCallback, useEffect, useMemo, useState } from 'react';

import { useERC721Contract } from './useContract';
import { useAccount } from 'wagmi';
import { useCheckLogin } from './useCurrentChain';
import { Hash, erc721ABI } from '@wagmi/core';
import { Transaction_Type } from 'config/constants';
import { RecordForApprove } from 'types';
import { useSendTransaction } from './useSendTransaction';
import { ApprovalState } from './useApproval';
import { t } from '@lingui/macro';

const useApprovalERC721 = (
	contractAddress: string,
	tokenId: string | undefined,
	spender: string,
) => {
	const { address } = useAccount();
	const isLogin = useCheckLogin();

	const contract = useERC721Contract(contractAddress);

	const [isApproved, setIsApproved] = useState<boolean | undefined>(undefined);

	useEffect(() => {
		if (!isLogin || !contract || !tokenId) {
			return;
		}
		const checkApprovalStatus = async () => {
			try {
				let approved = await contract.isApprovedForAll(address, spender);
				if (!approved) {
					const approvedAddress = await contract.getApproved(tokenId);
					approved = approvedAddress === spender;
				}
				setIsApproved(approved);
			} catch (error) {
				console.error('Error checking approval status:', error);
			}
		};

		checkApprovalStatus();
	}, [contract, contractAddress, spender, isLogin, address, tokenId]);

	const {
		isConfirming: isApproving,
		isConfirmed,
		onSendTransaction,
	} = useSendTransaction(
		{
			mode: 'recklesslyUnprepared',
			address: contractAddress as Hash,
			abi: erc721ABI,
			functionName: 'setApprovalForAll',
			args: useMemo(
				() => [spender, true],
				[spender]
			)
		},
		undefined,
		true
	);

	useEffect(() => {
		if (isConfirmed) {
			setIsApproved(true);
		}
	}, [isConfirmed]);

	const approvalState = useMemo(() => {
		if (!spender || !tokenId || !address) {
			return ApprovalState.UNKNOWN;
		}
		if (isApproving) {
			return ApprovalState.PENDING;
		}
		if (isApproved === undefined) {
			return ApprovalState.UNKNOWN;
		}

		if (isApproved === false) {
			return ApprovalState.NOT_APPROVED;
		} else {
			return ApprovalState.APPROVED;
		}
	}, [spender, isApproving, address, isApproved]);

	const handleApproveCallback = useCallback(() => {
		const record = {
			transactionType: Transaction_Type.Approve,
		} as RecordForApprove;
		onSendTransaction(record, t`Approval Successful`);
	}, [onSendTransaction]);

	return { 
		approvalState,
		isApproving,
		isConfirmed,
		approveCallback: handleApproveCallback,
	 };
};

export default useApprovalERC721;
