import {POOL_INIT_CODE_HASH} from "./constants";

import { defaultAbiCoder } from '@ethersproject/abi'
import { getCreate2Address } from '@ethersproject/address'
import { keccak256 } from '@ethersproject/solidity';
import { TokenInfo } from "types";
import { sortsBefore } from "utils";
import { Fee_Amount } from "./types";

export function formattedFeeAmount(feeAmount: Fee_Amount): number {
  return feeAmount / 10000
}

export function getPercentByFeeAmount(fee: Fee_Amount | undefined) {
  if (fee) {
    return `${formattedFeeAmount(fee)}%`;
  } else {
    return "-";
  }
}

/**
 * Computes a pool address
 * @param factoryAddress The Uniswap V3 factory address
 * @param tokenA The first token of the pair, irrespective of sort order
 * @param tokenB The second token of the pair, irrespective of sort order
 * @param fee The fee tier of the pool
 * @param initCodeHashManualOverride Override the init code hash used to compute the pool address if necessary
 * @returns The pool address
 */
export function computePoolAddress({
  factoryAddress,
  tokenA,
  tokenB,
  fee,
  initCodeHashManualOverride
}: {
  factoryAddress: string
  tokenA: TokenInfo
  tokenB: TokenInfo
  fee: Fee_Amount
  initCodeHashManualOverride?: string
}): string {
  const [token0, token1] = sortsBefore(tokenA, tokenB) ? [tokenA, tokenB] : [tokenB, tokenA] // does safety checks
  return getCreate2Address(
    factoryAddress,
    keccak256(
      ['bytes'],
      [defaultAbiCoder.encode(['address', 'address', 'uint24'], [token0.address, token1.address, fee])]
    ),
    initCodeHashManualOverride ?? POOL_INIT_CODE_HASH
  )
}

export function getUniV3PositionDetailLink(positionId: string) {
	return `https://app.uniswap.org/pools/${positionId}`;
}
