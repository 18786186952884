import { isNegative } from "./mathUtils";

export const calcRiskRateColor = (risk: number) => {
	const green = '#0CBA71';
	const yellow = '#FF9E2D';
	const red = '#F04A54';

	let lightCount = Math.ceil(risk / 0.25);
	let mainColor;

	if (isNegative(risk)) {
		lightCount = 4;
		mainColor = red;
		return {
			lightCount,
			mainColor
		}
	}

	if (lightCount < 1) {
		lightCount = 1;
	}
	if (lightCount <= 2) {
		mainColor = green;
	} else if (lightCount === 3) {
		mainColor = yellow;
	} else {
		mainColor = red;
	}

	return { lightCount, mainColor };
};
