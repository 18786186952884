import { useCallback, useContext, useEffect, useMemo } from 'react';

import Decimal from 'decimal.js';

import {
	Input_Direction,
	MAX_PRECISION,
	NATIVE_BUFFER_FOR_MAX,
	QUOTE_USD_PRECISION,
	Side,
	Transaction_Type
} from '../../config/constants';
import { TradeContext } from '../../context/TradeContext';
import { PriceUtil } from '../../entities/V2/PriceUtil';
import { PriceUtilByPay } from '../../entities/V2/PriceUtilByPay';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
	selectCurrenDirection,
	selectCurrentMarginDelta,
	selectCurrentPositionAmount
} from '../../state/market/selector';
import {
	setDirectionForLong,
	setDirectionForShort,
	setEntryPrice,
	setImpactFeeForLong,
	setImpactFeeForShort,
	setImpactFeeRateForLong,
	setImpactFeeRateForShort,
	setInitialMarginForLong,
	setInitialMarginForShort,
	setMarginDeltaForLong,
	setMarginDeltaForShort,
	setOriginTradingFeeForLong,
	setOriginTradingFeeForShort,
	setSizeForLong,
	setSizeForShort,
	setTradingFeeForLong,
	setTradingFeeForShort
} from '../../state/market/slice';
import { selectCurrentPool } from '../../state/pools/selector';
import { poolsBaseState } from '../../state/pools/slice';
import { settingBaseState } from '../../state/setting/slice';
import { setLeverage, tradeBaseState } from '../../state/trade/slice';
import { txBaseState } from '../../state/tx/slice';
import {
	catchFn,
	checkInputNumberic,
	computeMaxAmount,
	div,
	isLessThan,
	isPositive,
	isZero,
	minus,
	multipliedBy,
	plus,
	toAmountString,
	toDecimalPlaces
} from '../../utils';
import { useCheckLogin } from '../useCurrentChain';
import { useIndexPriceForTrade } from '../useIndexPriceForTrade';
import { useLeverageByTolerance } from '../useSlippageTolerance';

const useListenMarketAmountV2 = () => {
	const dispatch = useAppDispatch();

	const { quoteToken, quoteBalance, transactionType } =
		useAppSelector(txBaseState);
	const { leverage } = useAppSelector(tradeBaseState);
	const { checkedTradeLeverage } = useAppSelector(settingBaseState);

	const currentMarginDelta = useAppSelector(selectCurrentMarginDelta);
	const currentPositionAmount = useAppSelector(selectCurrentPositionAmount);
	const currenDirection = useAppSelector(selectCurrenDirection);
	const { poolInfo } = useAppSelector(poolsBaseState);

	const { tradeDirection } = useContext(TradeContext);
	const isLogin = useCheckLogin();

	const side =
		transactionType === Transaction_Type.LongV2 ? Side.LONG : Side.SHORT;

	const { maxIndexPriceX96 } = useIndexPriceForTrade(side);
	const indexPriceX96 = poolInfo?.indexPriceX96;

	const slippagedLeverage = useLeverageByTolerance(leverage);

	useEffect(() => {
		if (checkedTradeLeverage) {
			// 操作过pay
			if (currenDirection === Input_Direction.In) {
				if (isPositive(currentMarginDelta)) {
					onSetValuesByMarginDelta(currentMarginDelta);
				} else if (isPositive(currentPositionAmount)) {
					onSetValuesByPositionAmount(currentPositionAmount);
				}
			}

			// 操作过size
			if (currenDirection === Input_Direction.Out) {
				if (isPositive(currentPositionAmount)) {
					onSetValuesByPositionAmount(currentPositionAmount);
				} else if (isPositive(currentMarginDelta)) {
					onSetValuesByMarginDelta(currentMarginDelta);
				}
			}
		} else {
			onSetValuesByNoneLeverage(currentMarginDelta, currentPositionAmount);
		}
	}, [checkedTradeLeverage, leverage, poolInfo, indexPriceX96]);

	useEffect(() => {
		if (!poolInfo) {
			dispatch(setEntryPrice(''));
			return;
		}

		if (!isPositive(currentPositionAmount)) {
			return;
		}

		const tradePrice = catchFn(() => {
			return PriceUtil.calculateMarketPrice(
				currentPositionAmount,
				poolInfo.globalLiquidityPosition,
				poolInfo.priceState,
				side,
				indexPriceX96,
				poolInfo.baseToken.decimals
			);
		}, '');

		dispatch(setEntryPrice(tradePrice));
	}, [side, indexPriceX96, maxIndexPriceX96, poolInfo, currentPositionAmount]);

	useEffect(() => {
		dispatch(setEntryPrice(''));
	}, [tradeDirection]);

	/**
	 * 支付金额变化
	 */
	const onSetValuesByMarginDelta = (pay: string) => {
		if (
			!poolInfo ||
			!isPositive(pay) ||
			!isPositive(slippagedLeverage) ||
			!poolInfo ||
			!isPositive(indexPriceX96)
		) {
			if (transactionType === Transaction_Type.LongV2) {
				dispatch(setTradingFeeForLong(''));
				dispatch(setInitialMarginForLong(''));
				dispatch(setSizeForLong(''));
			} else {
				dispatch(setTradingFeeForShort(''));
				dispatch(setInitialMarginForShort(''));
				dispatch(setSizeForShort(''));
			}
			dispatch(setEntryPrice(''));
			return;
		}

		// 因为：Pay = LiquidityDelta / Leverage + LiquidityDelta * TradingFeeRate
		// 所以：LiquidityDelta = Pay / (1 / Leverage + TradingFeeRate)
		const liquidityDelta = toDecimalPlaces(
			div(
				pay,
				plus(div(1, slippagedLeverage), poolInfo.discountedTradingFeeRate)
			),
			QUOTE_USD_PRECISION
		);

		const originLiquidityDelta = toDecimalPlaces(
			div(pay, plus(div(1, slippagedLeverage), poolInfo.tradingFeeRate)),
			QUOTE_USD_PRECISION
		);

		const tradingFee = multipliedBy(
			liquidityDelta,
			poolInfo.discountedTradingFeeRate
		);
		const originTradingFee = multipliedBy(
			originLiquidityDelta,
			poolInfo.tradingFeeRate
		);
		const _netMargin = minus(pay, tradingFee);

		let size = '';
		const tradePrice = catchFn(() => {
			return PriceUtilByPay.calculateMarketPrice(
				liquidityDelta,
				poolInfo.globalLiquidityPosition,
				poolInfo.priceState,
				side,
				indexPriceX96,
				poolInfo.baseToken.decimals
			);
		}, '');

		dispatch(setEntryPrice(tradePrice));

		// 流动性不足
		if (isZero(tradePrice)) {
			if (transactionType === Transaction_Type.LongV2) {
				dispatch(setTradingFeeForLong(''));
				dispatch(setOriginTradingFeeForLong(''));
				dispatch(setInitialMarginForLong(''));
				dispatch(setSizeForLong(''));
			} else {
				dispatch(setTradingFeeForShort(''));
				dispatch(setOriginTradingFeeForShort(''));
				dispatch(setInitialMarginForShort(''));
				dispatch(setSizeForShort(''));
			}
			return;
		}

		if (isPositive(_netMargin)) {
			// const _size = div(multipliedBy(_netMargin, leverage), tradePrice);
			const _size = div(liquidityDelta, tradePrice);
			size = toAmountString(
				toDecimalPlaces(_size, poolInfo.baseToken.decimals),
				MAX_PRECISION
			);
		} else {
			// 用户支付 < 交易手续费
			size = '0';
		}

		if (transactionType === Transaction_Type.LongV2) {
			dispatch(setTradingFeeForLong(tradingFee));
			dispatch(setOriginTradingFeeForLong(originTradingFee));
			dispatch(setInitialMarginForLong(_netMargin));
			dispatch(setSizeForLong(size));
		} else {
			dispatch(setTradingFeeForShort(tradingFee));
			dispatch(setOriginTradingFeeForShort(originTradingFee));
			dispatch(setInitialMarginForShort(_netMargin));
			dispatch(setSizeForShort(size));
		}
	};

	/**
	 * 仓位数量变化
	 */
	const onSetValuesByPositionAmount = (size: string) => {
		if (
			!poolInfo ||
			!quoteToken ||
			!isPositive(slippagedLeverage) ||
			!isPositive(size)
		) {
			if (transactionType === Transaction_Type.LongV2) {
				dispatch(setTradingFeeForLong(''));
				dispatch(setInitialMarginForLong(''));
				dispatch(setMarginDeltaForLong(''));
			} else {
				dispatch(setTradingFeeForShort(''));
				dispatch(setInitialMarginForShort(''));
				dispatch(setMarginDeltaForShort(''));
			}
			dispatch(setEntryPrice(''));
			return;
		}
		const tradePrice = catchFn(() => {
			return PriceUtil.calculateMarketPrice(
				size,
				poolInfo.globalLiquidityPosition,
				poolInfo.priceState,
				side,
				indexPriceX96,
				poolInfo.baseToken.decimals
			);
		}, '');

		// 流动性不足
		if (isZero(tradePrice)) {
			if (transactionType === Transaction_Type.LongV2) {
				dispatch(setTradingFeeForLong(''));
				dispatch(setInitialMarginForLong(''));
				dispatch(setMarginDeltaForLong(''));
			} else {
				dispatch(setTradingFeeForShort(''));
				dispatch(setInitialMarginForShort(''));
				dispatch(setMarginDeltaForShort(''));
			}
			return;
		}

		const _liquidityDelta = multipliedBy(size, tradePrice);

		const tradingFee = multipliedBy(
			_liquidityDelta,
			poolInfo.discountedTradingFeeRate
		);
		const originTradingFee = multipliedBy(
			_liquidityDelta,
			poolInfo.tradingFeeRate
		);
		const netMargin = div(_liquidityDelta, slippagedLeverage);
		// const _pay = div(
		// 	netMargin,
		// 	minus(1, multipliedBy(leverage, poolInfo.tradingFeeRate))
		// );
		const _pay = plus(netMargin, tradingFee);
		const pay = toDecimalPlaces(_pay, quoteToken.decimals, Decimal.ROUND_CEIL);
		if (transactionType === Transaction_Type.LongV2) {
			dispatch(setTradingFeeForLong(tradingFee));
			dispatch(setOriginTradingFeeForLong(originTradingFee));
			dispatch(setInitialMarginForLong(netMargin));
			dispatch(setMarginDeltaForLong(pay));
		} else {
			dispatch(setTradingFeeForShort(tradingFee));
			dispatch(setOriginTradingFeeForShort(originTradingFee));
			dispatch(setInitialMarginForShort(netMargin));
			dispatch(setMarginDeltaForShort(pay));
		}
	};

	const onReset = useCallback(() => {
		if (transactionType === Transaction_Type.LongV2) {
			dispatch(setMarginDeltaForLong(''));
			dispatch(setInitialMarginForLong(''));
			dispatch(setSizeForLong(''));
		} else {
			dispatch(setMarginDeltaForShort(''));
			dispatch(setInitialMarginForShort(''));
			dispatch(setSizeForShort(''));
		}
	}, [transactionType]);

	const hasMaxButton = useMemo(() => {
		if (!quoteToken || !isPositive(quoteBalance) || !isLogin) {
			return false;
		}
		if (
			quoteToken.isNative &&
			isLessThan(quoteBalance, NATIVE_BUFFER_FOR_MAX)
		) {
			return false;
		}
		return true;
	}, [quoteToken, quoteBalance, isLogin]);

	const onHandleChangeMarginDelta = (event: any) => {
		const value = event.target.value;
		if (value.trim() === '') {
			if (transactionType === Transaction_Type.LongV2) {
				dispatch(setMarginDeltaForLong(''));
				dispatch(setInitialMarginForLong(''));
				dispatch(setTradingFeeForLong(''));
				dispatch(setEntryPrice(''));
				if (checkedTradeLeverage) {
					dispatch(setSizeForLong(''));
				}
			} else {
				dispatch(setMarginDeltaForShort(''));
				dispatch(setInitialMarginForShort(''));
				dispatch(setTradingFeeForShort(''));
				dispatch(setEntryPrice(''));
				if (checkedTradeLeverage) {
					dispatch(setSizeForShort(''));
				}
			}
			if (!checkedTradeLeverage) {
				dispatch(setLeverage(0));
			}
			return;
		}
		if (checkInputNumberic(value, quoteToken?.decimals)) {
			if (transactionType === Transaction_Type.LongV2) {
				dispatch(setMarginDeltaForLong(value));
				dispatch(setDirectionForLong(Input_Direction.In));
			} else {
				dispatch(setMarginDeltaForShort(value));
				dispatch(setDirectionForShort(Input_Direction.In));
			}
			if (checkedTradeLeverage) {
				onSetValuesByMarginDelta(value);
			} else {
				onSetValuesByNoneLeverage(value, currentPositionAmount);
			}
		}
	};

	const onHandleSetMaxMarginDelta = () => {
		if (isPositive(quoteBalance)) {
			const value = computeMaxAmount(quoteBalance, quoteToken);

			if (transactionType === Transaction_Type.LongV2) {
				dispatch(setMarginDeltaForLong(value));
				dispatch(setDirectionForLong(Input_Direction.In));
			} else {
				dispatch(setMarginDeltaForShort(value));
				dispatch(setDirectionForShort(Input_Direction.In));
			}

			if (checkedTradeLeverage) {
				onSetValuesByMarginDelta(value);
			} else {
				onSetValuesByNoneLeverage(value, currentPositionAmount);
			}
		}
	};

	const onHandleChangePositionAmount = (event: any) => {
		const value = event.target.value;
		if (value.trim() === '') {
			if (transactionType === Transaction_Type.LongV2) {
				dispatch(setSizeForLong(''));
				dispatch(setTradingFeeForLong(''));
				dispatch(setEntryPrice(''));
				if (checkedTradeLeverage) {
					dispatch(setMarginDeltaForLong(''));
					dispatch(setInitialMarginForLong(''));
				}
			} else {
				dispatch(setSizeForShort(''));
				dispatch(setTradingFeeForShort(''));
				dispatch(setEntryPrice(''));
				if (checkedTradeLeverage) {
					dispatch(setMarginDeltaForShort(''));
					dispatch(setInitialMarginForShort(''));
				}
			}
			if (!checkedTradeLeverage) {
				dispatch(setLeverage(0));
			}
			return;
		}
		if (checkInputNumberic(value, poolInfo?.baseToken?.decimals)) {
			if (transactionType === Transaction_Type.LongV2) {
				dispatch(setSizeForLong(value));
				dispatch(setDirectionForLong(Input_Direction.Out));
			} else {
				dispatch(setSizeForShort(value));
				dispatch(setDirectionForShort(Input_Direction.Out));
			}

			if (checkedTradeLeverage) {
				onSetValuesByPositionAmount(value);
			} else {
				onSetValuesByNoneLeverage(currentMarginDelta, value);
			}
		}
	};

	const onCalculateValuesByNoneLeverage = (pay: string, size: string) => {
		if (!poolInfo) {
			return {
				tradingFee: '',
				netMargin: '',
				leverage: '0'
			};
		}
		const tradePrice = PriceUtil.calculateMarketPrice(
			size,
			poolInfo.globalLiquidityPosition,
			poolInfo.priceState,
			side,
			indexPriceX96,
			poolInfo.baseToken.decimals
		);

		dispatch(setEntryPrice(tradePrice));

		const _liquidityDelta = multipliedBy(size, tradePrice);

		// 因为：Pay = LiquidityDelta / Leverage * LiquidityDelta * TradingFeeRate
		// 所以：Leverage = LiquidityDelta / (Pay -LiquidityDelta * TradingFeeRate)
		const tradingFee = multipliedBy(
			_liquidityDelta,
			poolInfo.discountedTradingFeeRate
		);
		const originTradingFee = multipliedBy(
			_liquidityDelta,
			poolInfo.tradingFeeRate
		);
		const netMargin = minus(pay, tradingFee);
		const leverage = div(_liquidityDelta, netMargin);

		return {
			tradingFee,
			originTradingFee,
			netMargin,
			leverage
		};
	};

	/**
	 * 不锁定杠杆
	 */
	const onSetValuesByNoneLeverage = (pay: string, size: string) => {
		if (
			!poolInfo ||
			!quoteToken ||
			!transactionType ||
			!isPositive(pay) ||
			!isPositive(size)
		) {
			if (transactionType === Transaction_Type.LongV2) {
				dispatch(setImpactFeeForLong(''));
				dispatch(setImpactFeeRateForLong(''));
				dispatch(setTradingFeeForLong(''));
				dispatch(setInitialMarginForLong(''));
			} else {
				dispatch(setImpactFeeForShort(''));
				dispatch(setImpactFeeRateForShort(''));
				dispatch(setTradingFeeForShort(''));
				dispatch(setInitialMarginForShort(''));
			}
			dispatch(setLeverage(0));
			dispatch(setEntryPrice(''));
			return;
		}

		const { tradingFee, originTradingFee, netMargin, leverage } =
			onCalculateValuesByNoneLeverage(pay, size);

		dispatch(setLeverage(leverage));
		if (transactionType === Transaction_Type.LongV2) {
			dispatch(setDirectionForLong(Input_Direction.Out));
			dispatch(setMarginDeltaForLong(pay));
			dispatch(setInitialMarginForLong(netMargin));
			dispatch(setTradingFeeForLong(tradingFee));
			dispatch(setOriginTradingFeeForLong(originTradingFee));
			dispatch(setSizeForLong(size));
		} else {
			dispatch(setDirectionForShort(Input_Direction.Out));
			dispatch(setMarginDeltaForShort(pay));
			dispatch(setInitialMarginForShort(netMargin));
			dispatch(setTradingFeeForShort(tradingFee));
			dispatch(setOriginTradingFeeForShort(originTradingFee));
			dispatch(setSizeForShort(size));
		}
	};

	return {
		hasMaxButton,
		onReset,
		onCalculateValuesByNoneLeverage,
		onSetValuesByMarginDelta,
		onSetValuesByNoneLeverage,
		onHandleChangeMarginDelta,
		onHandleSetMaxMarginDelta,
		onHandleChangePositionAmount
	};
};

export default useListenMarketAmountV2;
