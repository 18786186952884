import React from 'react';

import { Trans } from '@lingui/macro';
import {
	Box,
	FormControlLabel,
	Paper,
	Typography,
	useTheme
} from '@mui/material';
import classNames from 'classnames';
import { Dom_Size, Version } from 'config/constants';
import { TradeContextProvider } from 'context/TradeContext';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import PoolInfo from 'pages/components/PoolInfo';
import TradeUpdaterV1 from 'pages/components/TradeUpdaterV1';
import TradeUpdaterV2 from 'pages/components/TradeUpdaterV2';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { selectVersion } from 'state/setting/selector';

import { StyleCheckbox } from '../../components/Common/StyleCheckbox';
import HotIcon from '../../components/Svg/Icons/Hot';
import { useCheckLogin } from '../../hooks/useCurrentChain';
import {
	setOnlyUsedMarkets,
	settingBaseState
} from '../../state/setting/slice';
import TradeForm from '../components/TradeForm';
import Banner from './Banner';
import Links from './Links';
import Summary from './Summary';
import TradeTableV1 from './TradeTableV1';
import TradeTableV2 from './TradeTableV2';

export default function Market() {
	const { pools } = useAppSelector(poolsBaseState);
	const currentVersion = useAppSelector(selectVersion);
	const { isMatchPad, isMatchMobile } = useAppBreakpoints();
	const dispatch = useAppDispatch();
	const { onlyUsedMarkets } = useAppSelector(settingBaseState);

	const isLogin = useCheckLogin();
	const theme = useTheme();

	return (
		<TradeContextProvider>
			<Box
				className={classNames(
					'flex md:block space-x-2 md:space-x-0 md:space-y-2 sm:border-t',
					{ 'flex-1': currentVersion === Version.V2 }
				)}
				sx={{
					borderColor: theme.palette.divider
				}}
			>
				<Paper
					className='flex-1 sm:flex-0 overflow-hidden'
					sx={{
						background: isMatchMobile
							? 'transparent'
							: theme.palette.background.paper
					}}
				>
					{isMatchMobile ? (
						<div className='space-y-4 my-2'>
							<Banner />
							<Summary />
							<Links />
							<Typography
								variant='h5'
								className='flex justify-between px-3 pt-2'
							>
								<div className='flex items-center space-x-1'>
									<HotIcon />
									<span>
										<Trans>Markets</Trans>
									</span>
								</div>
								{isLogin && currentVersion === Version.V2 && (
									<div className='flex'>
										<StyleCheckbox
											checked={onlyUsedMarkets}
											onChange={e =>
												dispatch(setOnlyUsedMarkets(e.target.checked))
											}
											size='small'
											sx={{ height: '16px' }}
										/>
										<Typography color='text.secondary' variant='body2'>
											<Trans>My Positions Only</Trans>
										</Typography>
									</div>
								)}
							</Typography>
						</div>
					) : null}
					{currentVersion === Version.V1 ? (
						<TradeTableV1 poolList={pools} />
					) : (
						<TradeTableV2 poolList={pools} />
					)}
				</Paper>
				{currentVersion === Version.V1 && (
					<Box
						component='aside'
						className='sm:hidden md:flex space-y-2 md:space-y-0 md:space-x-2'
						sx={{
							width: isMatchPad ? 'auto' : `${Dom_Size.TRADE_FORM}px`
						}}
					>
						<TradeForm className='flex-1' />
						<PoolInfo className='flex-1' />
					</Box>
				)}
			</Box>
			{currentVersion === Version.V1 ? <TradeUpdaterV1 /> : <TradeUpdaterV2 />}
		</TradeContextProvider>
	);
}
