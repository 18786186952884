import { useTokenMetaQuery } from './__generated_trade__/types-and-hooks';

export function useTokenMetaGraph(address: string | undefined) {
	const { data, loading, refetch } = useTokenMetaQuery({
		variables: { id: address ? address.toLowerCase() : '' },
		skip: !address
	});

	return {
		data,
		loading,
		refetch
	};
}
