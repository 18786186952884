import { useMemo } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans, t } from '@lingui/macro';
import { ListItem, MenuList, Typography } from '@mui/material';
import { Claim_Type, Transaction_Type } from 'config/constants';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useSubmitClaimAllEarns } from 'hooks/useSubmitClaimAllEarns';
import {
	selectClaimRBFCalldatas,
	selectPreviousClaimableRBFAmount
} from 'state/earn/RBF/selector';
import {
	selectClaimLiquidityCalldatas,
	selectPreviousClaimableLiquidityAmount
} from 'state/earn/liquidity/selector';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import {
	formatClaimType,
	formatNumber,
	isPositive,
	plus,
	shortenSymbol
} from 'utils';

import ApproveButton from 'components/ApproveButton';
import Dialog, { DialogProps } from 'components/Common/Dialog';
import { CommonStyledDivider } from 'components/Common/Styled';

import EmptyClaim from './EmptyClaim';
import { ClaimCalldata } from './types';

export default function ClaimAllDialog(props: DialogProps) {
	const { onClose } = props;

	const { appToken, signingMap } = useAppSelector(txBaseState);

	const claimLiquidityCalldatas = useAppSelector(selectClaimLiquidityCalldatas);
	const claimRBFCalldatas = useAppSelector(selectClaimRBFCalldatas);

	const previousClaimableLiquidityAmount = useAppSelector(
		selectPreviousClaimableLiquidityAmount
	);
	const previousClaimableRBFAmount = useAppSelector(
		selectPreviousClaimableRBFAmount
	);

	const { isMatchMobile, isMatchPad } = useAppBreakpoints();

	const claimableCount = useMemo(() => {
		let count = 0;
		if (claimLiquidityCalldatas) {
			count++;
		}
		if (claimRBFCalldatas) {
			count++;
		}
		return count;
	}, [claimLiquidityCalldatas, claimRBFCalldatas]);

	const { claimAllCalldatas, appAmount } = useMemo(() => {
		let appAmount = '0';
		let results = [] as Array<ClaimCalldata>;

		if (claimLiquidityCalldatas) {
			results = results.concat(claimLiquidityCalldatas);
			appAmount = plus(appAmount, previousClaimableLiquidityAmount);
		}
		if (claimRBFCalldatas) {
			results = results.concat(claimRBFCalldatas);
			appAmount = plus(appAmount, previousClaimableRBFAmount);
		}
		return {
			claimAllCalldatas: results,
			appAmount
		};
	}, [
		claimLiquidityCalldatas,
		claimRBFCalldatas,
		previousClaimableLiquidityAmount,
		previousClaimableRBFAmount
	]);

	const { onConfirm, isConfirmed, isConfirming } =
		useSubmitClaimAllEarns(claimAllCalldatas);

	useUpdateEffect(() => {
		if (isConfirmed) {
			onClose();
		}
	}, [isConfirmed]);

	const [disabled, submitText] = useMemo(() => {
		if (!isPositive(appAmount)) {
			return [true, t`Claim`];
		}
		if (signingMap.has(Transaction_Type.ClaimPreviousAllEarns)) {
			return [true, t`Loading...`];
		}
		if (isConfirming) {
			return [true, t`Submitting...`];
		}
		return [false, t`Claim`];
	}, [appAmount, isConfirming, signingMap]);

	return (
		<Dialog
			height={isMatchMobile || isMatchPad ? 300 : 'auto'}
			title={t`Claim previous ${shortenSymbol(appToken)} rewards`}
			open={true}
			size='large'
			onClose={onClose}
		>
			{claimableCount ? (
				<div className='space-y-4'>
					<section className='space-y-1'>
						<div className='flex'>
							<Typography className='flex-4' variant='body2' color='secondary'>
								<Trans>Rewards Source</Trans>
							</Typography>
							<Typography variant='body2' color='secondary' align='right'>
								<Trans>Claimable Rewards</Trans>
							</Typography>
						</div>
						<MenuList>
							{claimLiquidityCalldatas && (
								<ListItem className='w-full flex items-center justify-between'>
									<Typography variant='body2'>
										{formatClaimType(Claim_Type.Liquidity)}
									</Typography>
									<Typography variant='body2'>
										{`${formatNumber(
											previousClaimableLiquidityAmount,
											appToken.positionUnits
										)} ${shortenSymbol(appToken)}`}
									</Typography>
								</ListItem>
							)}
							{claimRBFCalldatas && (
								<ListItem className='w-full flex items-center justify-between'>
									<Typography variant='body2'>
										{formatClaimType(Claim_Type.RiskBufferFund)}
									</Typography>
									<Typography variant='body2'>
										{`${formatNumber(
											previousClaimableRBFAmount,
											appToken.positionUnits
										)} ${shortenSymbol(appToken)}`}
									</Typography>
								</ListItem>
							)}
						</MenuList>
					</section>
					<CommonStyledDivider />
					<section className='flex items-center justify-between'>
						<Typography color='text.secondary' variant='body2'>
							<Trans>Total</Trans>
						</Typography>
						<Typography variant='body2'>{`${formatNumber(
							appAmount,
							appToken.positionUnits
						)} ${shortenSymbol(appToken)}`}</Typography>
					</section>
					<section>
						<ApproveButton
							componentVariant='confirm'
							fullWidth
							onClick={onConfirm}
							disabled={disabled}
							isApproveRewardCollector
						>
							{submitText}
						</ApproveButton>
					</section>
				</div>
			) : (
				<EmptyClaim />
			)}
		</Dialog>
	);
}
