import { Reducer, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'state';
import { IAggregateClaimHistory } from 'types';

interface StateProps {
	claimHistories: Array<IAggregateClaimHistory> | null;
}

const initialState: StateProps = {
	claimHistories: null,
};

export const slice = createSlice({
	name: 'earn',
	initialState,
	reducers: {
		setClaimHistories(state, { payload }) {
			state.claimHistories = payload;
		},
	}
});

export const {
	setClaimHistories,
} = slice.actions;

export const earnBaseState = (state: RootState) => state.earn;

export default slice.reducer as Reducer<typeof initialState>;
