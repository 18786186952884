import { useMemo } from 'react';

import { ALL_TOKENS } from 'config/constants/tokens';

import { ethers } from 'ethers';
import _ from 'lodash';
import { TokenInfo } from 'types';

import { useCurrentChain } from './useCurrentChain';

export function useAllTokens() {
	const { currentChainId } = useCurrentChain();

	return useMemo(() => {
		const list = ALL_TOKENS[currentChainId];
		const result = new Map<string, TokenInfo>();
		_.forEach(list, o => {
			result.set(
				ethers.utils.isAddress(o.address) ? o.address.toLowerCase() : o.address,
				o
			);
		});
		return result;
	}, [ALL_TOKENS, currentChainId]);
}
