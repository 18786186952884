import { ForwardedRef, ReactNode, forwardRef } from 'react';

import { Trans } from '@lingui/macro';
import { Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import cn from 'classnames';
import { App_Theme, Dom_Size } from 'config/constants';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';

import Cell, { DirectionCell } from 'components/Common/Cell';

import NoData from '../NoData';
import SectionLoading from '../SectionLoading';

export interface HeaderProps {
	key: string;
	width: string;
	label: ReactNode;
	align: 'inherit' | 'left' | 'center' | 'right' | 'justify';
	rowHeight?: string;
	format: (row: any) => JSX.Element;
}

interface TableProps {
	columns: any;
	rows: any;
	noDataNode?: React.ReactNode;
	loadingMore?: boolean;
	isPointer?: boolean;
	cellColor?: string;
	columnCellColor?:string;
	fontSize?: string | number;
	onRowClick?: (row: any) => void;
	padOrigin?: boolean;
	origin?: boolean; //Rows Display be like web
	rowHeight?: number;
	rowsRender?: React.ReactNode; //Customize the table rows display, Only for under large screen!!!,
	height?: string | number
}

const StickyHeadTable = (
	{
		columns,
		rows,
		noDataNode = (
			<NoData size="large">
				<Trans>No Data</Trans>
			</NoData>
		),
		loadingMore,
		isPointer = false,
		cellColor,
		columnCellColor,
		fontSize,
		onRowClick,
		rowHeight = 44,
		origin = false,
		rowsRender,
		height = Dom_Size.LIST_SCOLL
	}: TableProps,
	ref: ForwardedRef<HTMLDivElement>
) => {
	const onRowClickProxy = row => {
		if (onRowClick) {
			onRowClick(row);
		}
	};

	const { isMatchMobile, isMatchPad } = useAppBreakpoints();

	const theme = useTheme();

	return (
		<div
			className='w-full'
		>
			{!origin && (isMatchMobile || isMatchPad) ? (
					<div className='space-y-3'>
						{!rows.length ? (
							<div className='flex justify-center'>
								{noDataNode}
							</div>
						) : (
							rowsRender ? rowsRender : (
								<>
									{rows.map((item: any, index: number) => (
										<div key={index}>
											<Box className='w-full overflow-hidden'>
												<div
													className={cn(
															`grid grid-cols-${columns.length > 2 ? 3 : 2} gap-4`,
															(isMatchPad
																? `grid-cols-${columns.length > 2 ? 4 : 2}`
																: `grid-cols-${columns.length > 2 ? 3 : 2}`),
													)}
												>
													{columns.map((column: any, columnIndex: number) => (
														<DirectionCell
															key={columnIndex}
															align={
																column.align
																	? column.align
																	: columnIndex &&
																		(columnIndex + 1) % (isMatchPad ? 4 : 3) === 0
																	? 'right'
																	: 'left'
															}
															label={column.label}
															value={column.format(item)}
														/>
													))}
												</div>
											</Box>
										</div>
									))}
								</>
							)
						)}
					</div>
				) : (
					<TableContainer
						ref={ref}
						sx={{
							maxHeight: height,
							backgroundColor: cellColor
								? theme.palette.mode === App_Theme.dark
									? cellColor
									: theme.custom.positiveColor
								: theme.palette.mode === App_Theme.dark
								? theme.palette.background.paper
								: theme.custom.positiveColor
						}}
					>
						<Table
							stickyHeader
							aria-label='sticky table'
							sx={{
								'& .MuiTypography-root': {
									fontSize: fontSize ? `${fontSize}px !important` : 'inherit'
								}
							}}
						>
							<TableHead>
								<TableRow>
									{columns.map((column: any, index: number) => {
										return (
											<TableCell
												key={index}
												align={column.align}
												style={{
													minWidth: column.minWidth,
													width: column.width,
													backgroundColor: cellColor
														? theme.palette.mode === App_Theme.dark
															? cellColor
															: theme.custom.positiveColor
														: theme.palette.mode === App_Theme.dark
														? theme.palette.background.paper
														: theme.custom.positiveColor,
													verticalAlign: 'top'
												}}
												className='capitalize pt-3 pb-2'
											>
												<Typography variant='body2' component='div'>
													{column.label}
												</Typography>
											</TableCell>
										);
									})}
								</TableRow>
							</TableHead>
							<TableBody>
								{!rows.length ? (
									<TableRow>
										<TableCell colSpan={columns.length}>
											<div className='flex justify-center'>
												{noDataNode}
											</div>
										</TableCell>
									</TableRow>
								
								) : (
									rows.map((row: any, index: number) => {
										return (
											<TableRow
												hover
												role='checkbox'
												tabIndex={-1}
												key={index}
												sx={{
													height: (isMatchMobile || isMatchPad) ? `${Number(rowHeight) - 8 }px !important`  :  `${rowHeight}px !important`,
													borderBottom: '1px dotted #ffffff',
													cursor: isPointer ? 'pointer' : 'default',
													backgroundColor: cellColor
														? theme.palette.mode === App_Theme.dark
															? cellColor
															: theme.custom.positiveColor
														: theme.palette.mode === App_Theme.dark
														? theme.palette.background.paper
														: theme.custom.positiveColor
												}}
												onClick={() => onRowClickProxy(row)}
											>
												{columns.map((column: any, index: number) => {
													return (
														<TableCell
															key={index}
															align={column.align}
															className='py-0.5'
															sx={{
																backgroundColor: (columnCellColor && row.isOwner) && columnCellColor
															}}
														>
															<Typography variant='body2' component='div'>
																{column.format(row)}
															</Typography>
														</TableCell>
													);
												})}
											</TableRow>
										);
									})
								)}
							</TableBody>
						</Table>
						{loadingMore && <SectionLoading full />}
					</TableContainer>
				)}
		</div>
	);
};

export default forwardRef(StickyHeadTable);
