import { useTheme } from '@mui/material';

interface SVGProps {
	size?: number;
	fill?: string;
}

function Minus({ size = 14, fill }: SVGProps) {
	const theme = useTheme();
	return (
		<svg
			width='6'
			height='2'
			viewBox='0 0 6 2'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M-0.00390625 0.430664H5.57412V1.87402L-0.00390625 1.87402V0.430664Z'
				fill={fill || theme.palette.error.main}
			/>
		</svg>
	);
}

export default Minus;
