import { ReactNode } from 'react';

import {
	IconButton,
	IconButtonProps,
	Typography,
	alpha,
	styled
} from '@mui/material';

import Tooltip from 'components/Common/Tooltip';
import Tip from 'components/Svg/Icons/Tip';

export const StyledTipIconButton = styled(IconButton)<IconButtonProps>(
	({ theme }) => ({
		border: `1px solid ${alpha(theme.palette.divider, 0.4)}`,
		margin: `0 ${theme.spacing(0.5)}`,
		width: 14,
		height: 14,
		cursor: 'default'
	})
);

export default function Tipping(props: {
	title: ReactNode;
	type?: string;
	placement?: 'bottom' | 'top';
}) {
	const { title, type, placement = 'top', ...restProps } = props;
	return (
		<Tooltip title={title} placement={placement} {...restProps}>
			<Typography className='mx-0.5 leading-none' component='span'>
				<Tip color={type === 'warning' ? 'orange' : '#8B919D'} />
			</Typography>
		</Tooltip>
	);
}
