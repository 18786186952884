import { useEffect, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans } from '@lingui/macro';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import {
	Claim_Type,
	MAX_PAGE_SIZE,
	Transaction_Type,
	Version
} from 'config/constants';
import { useAccount } from 'wagmi';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin } from 'hooks/useCurrentChain';
import ClaimPrevious from 'pages/components/ClaimPrevious';
import { ModuleCard } from 'pages/components/ModuleCard';
import { selectClaimableRBFAmountV3 } from 'state/earn/RBF/selector';
import {
	selectClaimableArchitectAmount,
	selectHasArchitect
} from 'state/earn/architect/selector';
import { selectclaimableLiquidityAmount } from 'state/earn/liquidity/selector';
import { selectClaimableLpStakeQuoteAmount } from 'state/earn/pool2/selector';
import { earnPool2BaseState } from 'state/earn/pool2/slice';
import { selectClaimablePositionAmount } from 'state/earn/position/selector';
import {
	selectTotalAlaimableAmount,
	selectVisibleClaimPrevious
} from 'state/earn/selector';
import { selectClaimableStakeAmount } from 'state/earn/stake/selector';
import { useAppSelector } from 'state/hooks';
import { selectTransactionRecordList } from 'state/records/selector';
import { selectVersion } from 'state/setting/selector';
import { txBaseState } from 'state/tx/slice';
import {
	formatClaimType,
	formatNumber,
	isGreaterThan,
	shortenSymbol
} from 'utils';

import Cell from 'components/Common/Cell';
import CommonButton from 'components/Common/StyledButton';
import Tooltip from 'components/Common/Tooltip';
import Stronger from 'components/Stronger';
import Tip from 'components/Svg/Icons/Tip';

import { useApolloClients } from '../../context/ApolloClientsContext';
import {
	ClaimHistoriesQueryQuery,
	useClaimHistoriesQueryLazyQuery
} from '../../graphql/__generated_referral__/types-and-hooks';
import { useManageTransactions } from '../../hooks/useAccountTransactions';
import { globalBaseState } from '../../state/global/slice';
import ClaimHistoryDialog from '../components/ClaimHistoryDialog';
import ClaimAllDialog from './ClaimAllDialog';
import ClaimAllDialogV3 from './ClaimAllDialogV3';

export default function Summary({
	isClaimAllLoading
}: {
	isClaimAllLoading: boolean;
}) {
	const theme = useTheme();
	const { quoteToken, appToken } = useAppSelector(txBaseState);
	const transactionRecords = useAppSelector(selectTransactionRecordList);
	const hasArchitect = useAppSelector(selectHasArchitect);
	const visibleClaimPrevious = useAppSelector(selectVisibleClaimPrevious);
	const currentVersion = useAppSelector(selectVersion);

	const [histories, setHistories] = useState<
		ClaimHistoriesQueryQuery['aggregationClaimHistories']
	>([]);
	const [loading, setLoading] = useState(false);
	const [fetching, setFetching] = useState(false);
	const [hasMore, setHasMore] = useState(true);

	const { address } = useAccount();
	const { clientReferral } = useApolloClients();
	const [fetch] = useClaimHistoriesQueryLazyQuery({
		client: clientReferral
	});
	const { removeTransaction } = useManageTransactions();

	const historyTypes = useMemo(() => {
		if (currentVersion === Version.V1) {
			return [
				Claim_Type.Staking,
				Claim_Type.StakingLp,
				Claim_Type.Pool2,
				Claim_Type.Position,
				Claim_Type.Liquidity,
				Claim_Type.RiskBufferFund,
				Claim_Type.Architect
			];
		}
		return [
			Claim_Type.Staking,
			Claim_Type.StakingLp,
			Claim_Type.Pool2,
			Claim_Type.PositionV2,
			Claim_Type.LiquidityV2,
			Claim_Type.Architect
		];
	}, [currentVersion]);

	const fetchAction = (index: number, isRefresh?: boolean) => {
		if (isRefresh === true || (hasMore && !fetching)) {
			setFetching(true);
			return fetch({
				variables: {
					account: address,
					type_in: historyTypes,
					skip: index === 0 ? 0 : histories.length
				}
			})
				.then(res => {
					if (!res.data) {
						return;
					}
					const map = new Map();
					const data = res.data.aggregationClaimHistories;
					data.forEach(history => map.set(history.txHash, history));
					transactionRecords.forEach(record => {
						if (map.has(record.hash)) {
							removeTransaction(record);
						}
					});
					setHasMore(data.length === MAX_PAGE_SIZE);
					setHistories(index === 0 ? data : [...histories, ...data]);
				})
				.finally(() => setFetching(false));
		} else {
			return Promise.resolve();
		}
	};

	useEffect(() => {
		setLoading(true);
		setHasMore(true);
		setFetching(false);
		setHistories([]);
		fetchAction(0, true).finally(() => setLoading(false));
	}, [address, currentVersion]);

	const { appTimer } = useAppSelector(globalBaseState);

	useUpdateEffect(() => {
		fetch({
			variables: {
				account: address,
				type_in: historyTypes,
				skip: 0
			}
		}).then(res => {
			if (!res.data) {
				return;
			}
			const map = new Map();
			const data = res.data.aggregationClaimHistories;
			data.forEach(history => map.set(history.txHash, history));
			transactionRecords.forEach(record => {
				if (map.has(record.hash)) {
					removeTransaction(record);
				}
			});
			const newData = data.filter(
				history => !histories.find(item => item.txHash === history.txHash)
			);
			setHistories([...newData, ...histories]);
		});
	}, [appTimer]);

	const { isMatchMobile, isMatchPad } = useAppBreakpoints();
	const isLogin = useCheckLogin();

	const [claimableQuoteAmount, totalAlaimableAmount] = useAppSelector(
		selectTotalAlaimableAmount
	);
	const claimableStakeAmount = useAppSelector(selectClaimableStakeAmount);
	const claimableStakeLpQuoteAmount = useAppSelector(
		selectClaimableLpStakeQuoteAmount
	);
	const claimablePositionAmount = useAppSelector(selectClaimablePositionAmount);
	const { claimableAppAmount: claimablePool2AppAmount } =
		useAppSelector(earnPool2BaseState);
	const claimableLiquidityAmountV3 = useAppSelector(
		selectclaimableLiquidityAmount
	);
	const claimableRBFAmount = useAppSelector(selectClaimableRBFAmountV3);
	const claimableArchitectAmount = useAppSelector(
		selectClaimableArchitectAmount
	);

	const [visibleClaimAll, setVisibleClaimAll] = useState<boolean>(false);
	const [visibleClaimHistory, setVisibleClaimHistory] =
		useState<boolean>(false);

	const claimAllRecords = useMemo(() => {
		return transactionRecords.filter(
			record => record.type === Transaction_Type.ClaimAllEarns
		);
	}, [transactionRecords]);

	const onOpenClaimHistory = () => {
		setVisibleClaimHistory(true);
	};

	const onOpenClaimAll = () => {
		setVisibleClaimAll(true);
	};

	const [visibleClaimAllV3, setVisibleClaimAllV3] = useState<boolean>(false);
	const onOpenClaimAllV2 = () => {
		setVisibleClaimAllV3(true);
	};

	return (
		<ModuleCard
			title={
				<div className='flex items-baseline sm:items-center space-x-2 sm:space-x-1 justify-between w-full'>
					<Typography
						variant='h6'
						fontSize={isMatchMobile || isMatchPad ? 14 : 16}
						fontWeight={500}
					>
						<Trans>Claimable Rewards</Trans>
					</Typography>
					{currentVersion === Version.V2 && (
						<Tooltip
							title={
								<Typography variant='body2'>
									<Trans>
										Staking and Pool2 Mining are data common to both V1/V2,
										while Position Mining and Liquidity Mining are specific to
										V2.
									</Trans>
								</Typography>
							}
						>
							<div>
								<Tip />
							</div>
						</Tooltip>
					)}
				</div>
			}
			extra={
				<div className='shrink-0'>
					{isLogin && isMatchMobile && (
						<Typography
							className='cursor-pointer'
							color='text.primary'
							variant='body1'
							align='left'
							onClick={onOpenClaimHistory}
							sx={{
								'&:hover': {
									color: theme.palette.primary.main
								}
							}}
						>
							<Trans>Claim History</Trans>
							<KeyboardArrowRight color='inherit' fontSize='small' />
						</Typography>
					)}
				</div>
			}
		>
			<div className='flex sm:block items-center sm:h-auto sm:space-y-3'>
				<div className='flex-2 md:flex-auto flex'>
					<div className='flex-1'>
						<Stronger
							value={
								<Typography
									variant={isMatchMobile || isMatchPad ? 'h6' : 'h4'}
									className='leading-none'
								>{`${formatNumber(
									claimableQuoteAmount,
									quoteToken?.positionUnits
								)} ${shortenSymbol(quoteToken)}`}</Typography>
							}
							tooltip={
								<div>
									<Typography className='mb-2' align='inherit' variant='body2'>
										<Trans>The trading fee income is settled every hour.</Trans>
									</Typography>
									<Cell
										label={formatClaimType(Claim_Type.Staking)}
										value={`${formatNumber(
											claimableStakeAmount,
											quoteToken?.positionUnits
										)} ${shortenSymbol(quoteToken)}`}
									/>
									<Cell
										label={formatClaimType(Claim_Type.StakingLp)}
										value={`${formatNumber(
											claimableStakeLpQuoteAmount,
											quoteToken?.positionUnits
										)} ${shortenSymbol(quoteToken)}`}
									/>
									{hasArchitect && (
										<Cell
											label={formatClaimType(Claim_Type.Architect)}
											value={`${formatNumber(
												claimableArchitectAmount
											)} ${shortenSymbol(quoteToken)}`}
										/>
									)}
								</div>
							}
						/>
					</div>
					<div className='flex-1 sm:text-right'>
						<Stronger
							value={
								<Typography
									className='leading-none'
									variant={isMatchMobile || isMatchPad ? 'h6' : 'h4'}
								>{`${formatNumber(
									totalAlaimableAmount,
									appToken?.positionUnits
								)} ${shortenSymbol(appToken)}`}</Typography>
							}
							tooltip={
								<div>
									<Cell
										label={formatClaimType(Claim_Type.Pool2)}
										value={`${formatNumber(
											claimablePool2AppAmount,
											appToken?.positionUnits
										)} ${shortenSymbol(appToken)}`}
									/>
									<Cell
										label={formatClaimType(
											currentVersion === Version.V1
												? Claim_Type.Position
												: Claim_Type.PositionV2
										)}
										value={`${formatNumber(
											claimablePositionAmount,
											appToken?.positionUnits
										)} ${shortenSymbol(appToken)}`}
									/>
									<Cell
										label={formatClaimType(
											currentVersion === Version.V1
												? Claim_Type.Liquidity
												: Claim_Type.LiquidityV2
										)}
										value={`${formatNumber(
											claimableLiquidityAmountV3,
											appToken?.positionUnits
										)} ${shortenSymbol(appToken)}`}
									/>
									{currentVersion === Version.V1 && (
										<Cell
											label={formatClaimType(Claim_Type.RiskBufferFund)}
											value={`${formatNumber(
												claimableRBFAmount,
												appToken?.positionUnits
											)} ${shortenSymbol(appToken)}`}
										/>
									)}
								</div>
							}
						/>
					</div>
				</div>
				<div className='flex-3 md:flex-auto flex sm:block items-center justify-between sm:space-y-3'>
					<div className='flex sm:block items-center space-x-4 sm:space-x-0'>
						<CommonButton
							className='py-1 font-medium sm:mb-2'
							fullWidth={isMatchMobile}
							onClick={onOpenClaimAllV2}
							disabled={
								isClaimAllLoading || isGreaterThan(claimAllRecords.length, 0)
							}
						>
							{isGreaterThan(claimAllRecords.length, 0) ? (
								<Trans>Claiming...</Trans>
							) : (
								<Trans>Claim All</Trans>
							)}
						</CommonButton>
						<ClaimPrevious
							className='flex justify-center mt-1'
							isVisible={isLogin && isMatchMobile && visibleClaimPrevious}
							onClaim={onOpenClaimAll}
						/>
						{isLogin && !isMatchMobile && (
							<Typography
								className='cursor-pointer'
								color='text.primary'
								variant='body1'
								align='left'
								onClick={onOpenClaimHistory}
								sx={{
									'&:hover': {
										color: theme.palette.primary.main
									}
								}}
							>
								<Trans>Claim History</Trans>
								<KeyboardArrowRight color='inherit' fontSize='small' />
							</Typography>
						)}
						<ClaimPrevious
							isVisible={isLogin && !isMatchMobile && visibleClaimPrevious}
							onClaim={onOpenClaimAll}
						/>
					</div>
				</div>
			</div>

			{visibleClaimAll && (
				<ClaimAllDialog
					open={true}
					onClose={() => {
						setVisibleClaimAll(false);
					}}
				/>
			)}

			{visibleClaimAllV3 && (
				<ClaimAllDialogV3
					open={true}
					onClose={() => {
						setVisibleClaimAllV3(false);
					}}
				/>
			)}

			{visibleClaimHistory && (
				<ClaimHistoryDialog
					open={true}
					loading={loading}
					claimHistories={histories}
					fetchAction={fetchAction}
					onClose={() => {
						setVisibleClaimHistory(false);
					}}
				/>
			)}
		</ModuleCard>
	);
}
