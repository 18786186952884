import React from 'react';

import { Typography } from '@mui/material';

import Tooltip from '../Common/Tooltip';

const TippingUnderline = ({
	tooltip,
	label,
	placement = 'top',
	underlineColor
}: {
	tooltip: React.ReactNode;
	label: React.ReactNode;
	placement?: React.ComponentProps<typeof Tooltip>['placement'];
	underlineColor?: string;
}) => {
	return (
		<Tooltip placement={placement} title={tooltip}>
			<Typography
				className='inline-block cursor-default underline decoration-dashed underline-offset-2 decoration-1'
				component='div'
				variant='body2'
				color={underlineColor}
			>
				{label}
			</Typography>
		</Tooltip>
	);
};

export default TippingUnderline;
