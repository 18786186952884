import { useNavigate } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import { useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {
	APP_TOKEN_SYMBOL,
	Page,
	QUOTE_USD,
	Side,
	Version
} from 'config/constants';
import { IPositionItem } from 'fetch/useMyPositionsFetch';

import { IAprs, IPositionMiningPools } from 'graphql/usePositionRewardsGraph';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import { earnPositionBaseState } from 'state/earn/position/slice';
import { useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';
import { txBaseState } from 'state/tx/slice';
import {
	amountFormatter,
	formatNumber,
	isGreaterThan,
	isPositive,
	isZero,
	shortenSymbolNative,
	toDecimalPlaces,
	toPercent,
	toQuoteAmount
} from 'utils';

import Cell from 'components/Common/Cell';
import { CommonStyledOutlinedButton } from 'components/Common/Styled';
import StyledLink from 'components/Common/StyledLink';
import NoData from 'components/NoData';
import SectionLoading from 'components/SectionLoading';
import Stronger from 'components/Stronger';
import Table from 'components/Table/StickyTable';

import TippingUnderline from '../../../components/TippingUnderline';

export default function LiquidityMiningPoolsList() {
	const theme = useTheme();
	const { appToken } = useAppSelector(txBaseState);
	const { isLoadingPositionList, positionList } = useAppSelector(
		earnPositionBaseState
	);
	const currentVersion = useAppSelector(selectVersion);
	const navigate = useNavigate();
	const isLogin = useCheckLogin();
	const { currentChainId } = useCurrentChain();
	const { isMatchMobile, isMatchPad } = useAppBreakpoints();

	const columnsAprTooltip = [
		{
			key: 'Type',
			width: '30%',
			label: '',
			align: 'left',
			format: (row: IAprs) => {
				return (
					<Typography variant='body2' color='text.secondary'>
						{row.type === 'Average' && <Trans>Average</Trans>}
						{row.type === 'Maximum' && <Trans>Maximum</Trans>}
					</Typography>
				);
			}
		},
		{
			key: 'Apr',
			width: '25%',
			label: <Trans>APR</Trans>,
			align: 'left',
			format: (row: IAprs) => {
				return (
					<Typography
						variant='body2'
						color={
							row.apr && isGreaterThan(row.apr, 0)
								? 'success.main'
								: 'secondary'
						}
					>
						{toPercent(row.apr)}
					</Typography>
				);
			}
		},
		{
			key: 'leverage',
			width: '25%',
			label: <Trans>Leverage</Trans>,
			align: 'left',
			format: (row: IAprs) => {
				return (
					<Typography variant='body2'>
						{`${formatNumber(row.leverage, 2, true)}x`}
					</Typography>
				);
			}
		},
		{
			key: 'Coefficient',
			width: '20%',
			label: <Trans>Coefficient</Trans>,
			align: 'right',
			format: (row: IAprs) => {
				return (
					<Typography variant='body2'>
						{`${formatNumber(row.coefficient, 2, true)}x`}
					</Typography>
				);
			}
		}
	];
	const columnsPositionsTooltip = [
		{
			key: 'Side',
			width: '40%',
			label: <Trans>Side</Trans>,
			align: 'left',
			format: (row: IPositionItem) => {
				return (
					<Typography
						variant='body2'
						color={
							row.side === Side.LONG
								? theme.palette.success.main
								: theme.palette.error.main
						}
					>
						{row.side === Side.LONG ? t`Long` : t`Short`}
					</Typography>
				);
			}
		},
		{
			key: 'Size',
			width: '40%',
			label: <Trans>Size</Trans>,
			align: 'left',
			format: (row: IPositionItem) => {
				return (
					<Typography variant='body2' fontWeight={500}>
						{`${toQuoteAmount(
							row.size,
							row.baseToken.positionUnits
						)} ${shortenSymbolNative(row.baseToken, currentChainId)}`}
					</Typography>
				);
			}
		},
		{
			key: 'Coefficient',
			width: '20%',
			label: <Trans>Coefficient</Trans>,
			align: 'right',
			format: (row: IPositionItem) => {
				return (
					<Typography variant='body2'>
						{`${formatNumber(row.coefficient, 2, true)}x`}
					</Typography>
				);
			}
		}
	];

	const columns = [
		{
			key: 'LPs',
			width: '12%',
			label: <Trans>Market</Trans>,
			align: 'left',
			format: (row: IPositionMiningPools) => {
				return (
					<div className='flex space-x-1'>
						<Typography
							variant='h6'
							fontSize={isMatchMobile || isMatchPad ? 14 : 16}
							fontWeight={500}
						>
							{`${shortenSymbolNative(
								row.baseToken,
								currentChainId
							)}/${QUOTE_USD}`}
						</Typography>
					</div>
				);
			}
		},
		{
			key: 'APR',
			width: '12%',
			label: (
				<div className='flex justify-start items-center'>
					<TippingUnderline
						tooltip={
							<div>
								<Typography className='break-words tracking-tight'>
									<Trans>
										Avg. APR is calculated based on the average leverage and
										coefficient of the markets.
									</Trans>
								</Typography>
							</div>
						}
						label={<Trans>Avg. APR</Trans>}
					/>
				</div>
			),
			align: 'left',
			format: (row: IPositionMiningPools) => {
				return (
					<div>
						<Stronger
							value={
								<Typography
									variant='body2'
									color={
										row.apr && isGreaterThan(row.avgApr, 0)
											? 'success.main'
											: 'secondary'
									}
									className='leading-none'
								>
									{row.avgApr ? toPercent(row.avgApr) : toPercent(0)}
								</Typography>
							}
							tooltip={
								isMatchMobile || isMatchPad ? (
									<div className='flex'>
										<div>
											{columnsAprTooltip.map((item, index) => {
												return (
													<Typography key={index} color='text.secondary'>
														<div key={index} style={{ height: '32px' }}>
															{item.label}
														</div>
													</Typography>
												);
											})}
										</div>

										{row.aprs.map((item, index) => {
											return (
												<div key={index} className='ml-4'>
													{columnsAprTooltip.map((column, columnIndex) => {
														return (
															<div
																key={columnIndex}
																style={{ height: '32px', textAlign: 'right' }}
															>
																{column.format(item)}
															</div>
														);
													})}
												</div>
											);
										})}
									</div>
								) : (
									<div className='w-[380px] sm:w-auto'>
										<Table
											columns={columnsAprTooltip}
											rows={row.aprs}
											cellColor={theme.custom.cardBg}
										/>
									</div>
								)
							}
						/>

						{/* <Typography
							variant='body1'
							className='mx-1 inline'
							color='text.secondary'
							fontWeight={400}
						>
							/
						</Typography>
						<Typography
							variant='body1'
							className='leading-none inline'
							color={
								row.apr && isGreaterThan(row.apr, 0)
									? 'success.main'
									: 'secondary'
							}
							fontWeight={400}
						>
							{isPositive(row.apr) ? `${toPercent(row.apr)} ` : '-'}
						</Typography> */}
					</div>
				);
			}
		},
		{
			key: 'OutputRate',
			width: '14%',
			label: <Trans>Daily Emission</Trans>,
			align: 'left',
			format: (row: IPositionMiningPools) => {
				return (
					<Typography variant='body2' fontWeight={400}>
						{row.dailyEmissionAmount
							? `${formatNumber(
									row.dailyEmissionAmount,
									2,
									true
							  )} ${APP_TOKEN_SYMBOL}`
							: '-'}
					</Typography>
				);
			}
		},
		{
			key: 'TotalPositions',
			width: '14%',
			label: <Trans>Total Positions</Trans>,
			align: 'left',
			format: (row: IPositionMiningPools) => {
				return (
					<Typography variant='body2' fontWeight={400}>
						{row.totalPositions
							? `${amountFormatter(
									row.totalPositions,
									2,
									row.baseToken.positionUnits
							  )} ${shortenSymbolNative(row.baseToken, currentChainId)}`
							: '-'}
					</Typography>
				);
			}
		},
		{
			key: 'AvgCoefficient',
			width: '12%',
			label: (
				<div className='flex justify-start items-center'>
					<TippingUnderline
						tooltip={
							<div>
								<Typography className='break-words tracking-tight'>
									<Trans>
										Avg. Coefficient is the average Position Mining Coefficient
										of all position-holding users.
									</Trans>{' '}
									<StyledLink
										type='white'
										href='https://snapshot.org/#/equationdao.eth/proposal/0x0a7f3d164046dcb5d4a87745b577eba52f2b048f6ab7cf32dc8ad0d278b6fd9a'
										target={isMatchMobile ? '_self' : '_blank'}
									>
										<Trans>
											Learn more about{' '}
											<div>Position Mining Coefficient &gt;&gt;</div>
										</Trans>
									</StyledLink>
								</Typography>
							</div>
						}
						label={<Trans>Avg. Coefficient</Trans>}
					/>
				</div>
			),
			align: 'left',
			format: (row: IPositionMiningPools) => {
				return (
					<Typography variant='body2'>
						{`${toDecimalPlaces(row.avgCoefficient, 2)}x`}
					</Typography>
				);
			}
		},
		{
			key: 'MyPositions',
			width: '12%',
			label: <Trans>My Positions</Trans>,
			align: 'left',
			format: (row: IPositionMiningPools) => {
				if (!isLogin || !row.baseToken) {
					return (
						<Typography variant='body2' fontWeight={400}>
							-
						</Typography>
					);
				}
				if (!row.myPositions || isZero(row.myPositions)) {
					return (
						<Typography variant='body2' fontWeight={400}>
							{`0 ${shortenSymbolNative(row.baseToken, currentChainId)}`}
						</Typography>
					);
				}
				return (
					<Stronger
						value={
							<Typography
								variant='body2'
								className='leading-none'
								fontWeight={400}
							>
								{`${amountFormatter(
									row.myPositions,
									2,
									row.baseToken.positionUnits
								)} ${shortenSymbolNative(row.baseToken, currentChainId)}`}
							</Typography>
						}
						tooltip={
							<div>
								<div className='w-[320px] underLg:w-auto'>
									<Table
										columns={columnsPositionsTooltip}
										rows={row.positions}
										cellColor={theme.custom.cardBg}
										rowHeight={32}
										rowsRender={
											<div className='flex justify-between overflow-hidden'>
												<div>
													{columnsPositionsTooltip.map((item, index) => {
														return (
															<Typography key={index} color='text.secondary'>
																<div key={index} style={{ height: '32px' }}>
																	{item.label}
																</div>
															</Typography>
														);
													})}
												</div>

												{row.positions.map((item, index) => {
													return (
														<div
															key={index}
															style={{
																textAlign:
																	index !== row.positions.length - 1
																		? 'left'
																		: 'right'
															}}
														>
															{columnsPositionsTooltip.map(
																(column, columnIndex) => {
																	return (
																		<div
																			key={columnIndex}
																			style={{
																				height: '32px'
																			}}
																		>
																			{column.format(item)}
																		</div>
																	);
																}
															)}
														</div>
													);
												})}
											</div>
										}
									/>
								</div>
								<div className='mt-2 sm:text-xs'>
									<Typography
										variant='body2'
										component='span'
										color='text.secondary'
									>
										<Trans>
											Learn more about{' '}
											<StyledLink
												href='https://snapshot.org/#/equationdao.eth/proposal/0x0a7f3d164046dcb5d4a87745b577eba52f2b048f6ab7cf32dc8ad0d278b6fd9a'
												target={isMatchMobile ? '_self' : '_blank'}
											>
												<Trans>Position Mining Coefficient</Trans>
												{` >>`}
											</StyledLink>
										</Trans>
									</Typography>
								</div>
							</div>
						}
						// tooltip={
						// 	<div className='w-[310px] sm:w-auto'>
						// 		<Table
						// 			columns={columnsPositionsTooltip}
						// 			rows={row.positions}
						// 			cellColor={theme.custom.cardBg}
						// 			rowHeight='32px'
						// 		/>
						// 		<div className='mt-2 sm:text-xs'>
						// 			<Typography
						// 				variant='body2'
						// 				component='span'
						// 				color='text.secondary'
						// 			>
						// 				<Trans>Learn more about</Trans>
						// 			</Typography>{' '}
						// 			<StyledLink
						// 				href='https://snapshot.org/#/equationdao.eth/proposal/0x0a7f3d164046dcb5d4a87745b577eba52f2b048f6ab7cf32dc8ad0d278b6fd9a'
						// 				target={isMatchMobile ? '_self' : '_blank'}
						// 			>
						// 				<Trans>Position Mining Coefficient</Trans>
						// 				{` >>`}
						// 			</StyledLink>
						// 		</div>
						// 	</div>
						// }
					/>
				);
			}
		},
		{
			key: 'ClaimableRewards',
			width: '12%',
			label: (
				<div className='flex justify-end items-center'>
					<TippingUnderline
						tooltip={
							<Trans>
								There will be a delay in the display of rewards after opening a
								position, which typically does not exceed 2 hours.
							</Trans>
						}
						label={<Trans>Claimable Rewards</Trans>}
					/>
				</div>
			),
			align: 'right',
			format: (row: IPositionMiningPools) => {
				if (!isLogin) {
					return (
						<Typography variant='body2' fontWeight={400}>
							-
						</Typography>
					);
				}
				if (!row.claimableAmount || isZero(row.claimableAmount)) {
					return (
						<Typography variant='body2' fontWeight={400}>
							{`${formatNumber(0, appToken.positionUnits)} ${APP_TOKEN_SYMBOL}`}
						</Typography>
					);
				}
				return (
					<Typography variant='body2' fontWeight={400}>
						{`${formatNumber(
							row.claimableAmount,
							appToken.positionUnits
						)} ${APP_TOKEN_SYMBOL}`}
					</Typography>
				);
			}
		},
		{
			key: 'op',
			width: '12%',
			label: '',
			align: isMatchMobile ? 'left' : 'right',
			format: (row: IPositionMiningPools) => {
				return (
					<CommonStyledOutlinedButton
						variant='outlined'
						color='secondary'
						onClick={() => {
							navigate(
								`${currentVersion === Version.V1 ? Page.TradeV1 : Page.Trade}/${
									row.id
								}`
							);
						}}
						disabled={!isLogin}
					>
						<Trans>Trade</Trans>
					</CommonStyledOutlinedButton>
				);
			}
		}
	];

	return (
		<div>
			{isLoadingPositionList || !positionList ? (
				<SectionLoading />
			) : positionList.length > 0 ? (
				<Table
					height='auto'
					fontSize={14}
					columns={columns}
					rows={positionList}
					rowsRender={
						<div
							className='grid grid-cols-2 sm:grid-cols-1 gap-2'
							style={{ background: theme.palette.background.default }}
						>
							{positionList.map((item, liquidityIndex) => {
								return (
									<Paper key={liquidityIndex} className='space-y-1 px-3 py-4'>
										<div className='flex items-center justify-between'>
											<div>
												{columns
													.find(column => column.key === 'LPs')
													?.format(item)}
											</div>
											<div>
												{columns
													.find(column => column.key === 'op')
													?.format(item)}
											</div>
										</div>
										{columns
											.filter(column => !['LPs', 'op'].includes(column.key))
											.map((column, columnIndex) => {
												return (
													<Cell
														label={column.label}
														value={column.format(item)}
														key={columnIndex}
													/>
												);
											})}
									</Paper>
								);
							})}
						</div>
					}
				/>
			) : (
				<NoData size='large'>
					<Trans>No available rewards to claim</Trans>
				</NoData>
			)}
		</div>
	);
}
