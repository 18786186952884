import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useInterval, useUpdateEffect } from 'react-use';

import {
	DEFAULT_DOCUMENT_TITLE,
	EVENT_CHART_PRICE,
	Page,
	QUOTE_USD,
	STORAGE_KEY_PRICES
} from 'config/constants';
import { DEFAULT_POOL_ADDRESS } from 'config/env';

import { usePoolInfoGraph } from 'graphql/usePoolInfoGraph';
import { usePoolsGraph } from 'graphql/usePoolsGraph';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { useCurrentPage } from 'hooks/useCurrentPage';
import { isEmpty } from 'lodash';
import { globalBaseState } from 'state/global/slice';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { reset as resetLimit } from 'state/limit/slice';
import { reset as resetLiqduiity } from 'state/liquidity/slice';
import { reset as resetMarket } from 'state/market/slice';
import { selectCurrentPool } from 'state/pools/selector';
import {
	poolsBaseState,
	setPoolAddress,
	setPoolInfo,
	setPoolMap,
	setPools
} from 'state/pools/slice';
import {
	setLiquidityLeverage,
	setTradeLeverage,
	settingBaseState
} from 'state/setting/slice';
import store from 'store2';
import { IPoolItem } from 'types';
import {
	isAddress,
	isGreaterThan,
	shortenSymbolNative,
	toQuoteAmount
} from 'utils';

// Markets、Trade、Liquidity页面公共初始化的部分
export default function TradeUpdaterV1() {
	const dispatch = useAppDispatch();

	const { appTimer } = useAppSelector(globalBaseState);
	const { poolAddress = '', pools } = useAppSelector(poolsBaseState);
	const { tradeLeverage, liquidityLeverage } = useAppSelector(settingBaseState);
	const currentPool = useAppSelector(selectCurrentPool);

	const { currentChainId, isNetworkError } = useCurrentChain();
	const currentPage = useCurrentPage();
	const navigate = useNavigate();

	const { poolList, poolMap, refetchPools } = usePoolsGraph();
	const { pool = '' } = useParams();

	const isValidPool = useMemo(() => {
		const selectPool = pools.find(item => item?.id === pool.toLowerCase());
		return pool && !isEmpty(selectPool);
	}, [pool, pools]);

	const targetPool = useMemo(() => {
		if (isValidPool) {
			return pool.toLowerCase();
		} else {
			if (poolAddress) {
				return poolAddress;
			} else {
				return DEFAULT_POOL_ADDRESS[currentChainId].toLowerCase();
			}
		}
	}, [pool, poolAddress, currentChainId, isValidPool]);

	const { data: poolInfo, refetch: refetchPoolInfo } =
		usePoolInfoGraph(targetPool);

	useEffect(() => {
		if (!pool || isValidPool) {
			return;
		}
		setTimeout(() => {
			navigate(`${currentPage}`);
		}, 300);
	}, [pool, isValidPool]);

	// 初始化当前poolAddress
	useEffect(() => {
		if (!targetPool) {
			return;
		}
		dispatch(setPoolAddress(targetPool));
	}, [targetPool]);

	useEffect(() => {
		dispatch(resetMarket());
		dispatch(resetLimit());
		dispatch(resetLiqduiity());
	}, [poolAddress, isNetworkError]);
	// 提供K线使用
	useEffect(() => {
		if (poolList && poolList.length > 0) {
			dispatch(setPools(poolList));
			dispatch(setPoolMap(poolMap));
			const mapPriceByTokenId: Record<string, string> = {};
			poolList.forEach((item: IPoolItem) => {
				mapPriceByTokenId[item.baseToken.address] = item.price;
			});
			store(STORAGE_KEY_PRICES, mapPriceByTokenId);
			window.dispatchEvent(new Event(EVENT_CHART_PRICE));
		}
	}, [poolList, poolMap]);

	// 给Trade和Liquidity页面设置baseToken
	useUpdateEffect(() => {
		if (!poolInfo) {
			return;
		}
		dispatch(setPoolInfo(poolInfo));
	}, [poolInfo]);

	// Trade和Liquidity页面设置baseToken，初始化判断杠杆相关
	useEffect(() => {
		if (!poolInfo) {
			return;
		}
		if (currentPage === Page.PoolsV1) {
			// 当前流动性杠杆 > 当前池子的最大杠杆
			if (
				isGreaterThan(
					liquidityLeverage,
					poolInfo.maxLeveragePerLiquidityPosition
				)
			) {
				dispatch(
					setLiquidityLeverage(poolInfo.maxLeveragePerLiquidityPosition)
				);
			}
			return;
		}
		// 当前交易杠杆 > 当前池子的最大杠杆
		if (isGreaterThan(tradeLeverage, poolInfo.maxLeveragePerPosition)) {
			dispatch(setTradeLeverage(poolInfo.maxLeveragePerPosition));
		}
	}, [poolInfo]);

	// 资金费率倒计时
	const [currentHours, setCurrentHours] = useState(new Date().getHours());
	useInterval(() => {
		if (new Date().getHours() !== currentHours) {
			setCurrentHours(new Date().getHours());
		}
	}, 1000);

	useUpdateEffect(() => {
		refetchPools();
		refetchPoolInfo();
	}, [currentChainId, currentHours, appTimer]);

	// 设置页面title
	useEffect(() => {
		if (!currentPool) {
			return;
		}
		document.title = `${toQuoteAmount(
			currentPool.price,
			currentPool.baseToken.precision
		)} ${shortenSymbolNative(
			currentPool.baseToken,
			currentChainId
		)}/${QUOTE_USD} | ${DEFAULT_DOCUMENT_TITLE}`;
	}, [currentPool]);

	return null;
}
