import { useMemo } from 'react';

import { RewardCollectorABI, RewardCollectorV2ABI } from 'config/abis';
import { Version } from 'config/constants';

import { Interface } from 'ethers/lib/utils.js';
import { useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';

export function useSelectRewardConnectorByVersion() {
	const currentVersion = useAppSelector(selectVersion);

	const rewardCollectorInterface = useMemo(() => {
		return currentVersion === Version.V1
			? new Interface(RewardCollectorABI)
			: new Interface(RewardCollectorV2ABI);
	}, [currentVersion]);

	const rewardCollectorABI = useMemo(() => {
		return currentVersion === Version.V1
			? RewardCollectorABI
			: RewardCollectorV2ABI;
	}, [currentVersion]);

	return {
		rewardCollectorInterface,
		rewardCollectorABI
	};
}
