import { useMemo } from 'react';

import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { selectPositionMinExecutionFee } from 'state/global/selector';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { formatNumber, isPositive, shortenSymbolNative, toQuoteAmount } from 'utils';

import { positionRouterABI } from '../config/abis';
import {
	Contract_Write_Mode,
	QUOTE_USD,
	Transaction_Type
} from '../config/constants';
import { getContractAddress } from '../config/contracts';
import { RecordForRemoveLiquidity, TokenInfo } from '../types';
import { useComputeGasLimit } from './useComputeGasLimit';
import { useCurrentChain } from './useCurrentChain';
import { useSendTransaction } from './useSendTransaction';

const useSubmitRemoveLiquidity = (
	poolAddress: string,
	positionId: string,
	marginDelta: string,
	liquidityDelta: string,
	baseToken: TokenInfo
) => {
	const { quoteToken } = useAppSelector(txBaseState);
	const positionMinExecutionFee = useAppSelector(selectPositionMinExecutionFee);

	const { currentChainId } = useCurrentChain();

	const { address } = useAccount();

	const { args, record, description } = useMemo(() => {
		if (!baseToken || !quoteToken) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		const args = [poolAddress, positionId, address];

		const record = {
			transactionType: Transaction_Type.RemoveLiquidity,
			pool: poolAddress,
			positionId,
			baseToken
		} as RecordForRemoveLiquidity;

		const description = TransactionDescription.CreateCloseLiquidityPosition(
			`${shortenSymbolNative(baseToken, currentChainId)}/${QUOTE_USD}`,
			toQuoteAmount(liquidityDelta),
			formatNumber(marginDelta)
		);

		return {
			args,
			record,
			description
		};
	}, [
		quoteToken,
		poolAddress,
		positionId,
		marginDelta,
		liquidityDelta,
		currentChainId
	]);

	const overrides = useMemo(() => {
		if (!address || !isPositive(positionMinExecutionFee)) {
			return undefined;
		}
		return {
			from: address,
			value: positionMinExecutionFee
		};
	}, [address, positionMinExecutionFee]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address: getContractAddress(currentChainId, 'PositionRouter'),
			abi: positionRouterABI,
			functionName: 'createCloseLiquidityPosition',
			args,
			overrides
		} as UseContractWriteConfig;
	}, [currentChainId, args, overrides]);

	const { gasLimit } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, setTxSuccessCallback, isConfirmed, isConfirming } =
		useSendTransaction(contractWriteArgs, gasLimit);

	const onConfirm = () => {
		if (!record) return;
		onSendTransaction(record, description);
	};

	return { onConfirm, setTxSuccessCallback, isConfirmed, isConfirming };
};

export default useSubmitRemoveLiquidity;
