import { useCallback, useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { Trans, t } from '@lingui/macro';
import { Box, Divider, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import Decimal from 'decimal.js';
import moment from 'moment';
import { useAccount } from 'wagmi';

import { DirectionCell } from '../../../components/Common/Cell';
import NoData from '../../../components/NoData';
import SectionLoading from '../../../components/SectionLoading';
import VirtualTable from '../../../components/Table/VirtualTable';
import {
	DEFAULT_PAGE_SIZE,
	MAX_AMOUNT_FORMATTER_LIMIT,
	Side
} from '../../../config/constants';
import { usePassivePositionHistoriesRequest } from '../../../fetch/useRequest';
import { useAppBreakpoints } from '../../../hooks/useAppBreakpoints';
import { useCheckLogin, useCurrentChain } from '../../../hooks/useCurrentChain';
import { globalBaseState } from '../../../state/global/slice';
import { useAppSelector } from '../../../state/hooks';
import { poolsBaseState } from '../../../state/pools/slice';
import {
	amountFormatter,
	formatDate,
	neg,
	shortenSymbolNative,
	toQuoteAmount
} from '../../../utils';

const PassivePositionHistories = ({
	market,
	liquidity_position_history_id
}: {
	market: string;
	liquidity_position_history_id?: number;
}) => {
	const { address } = useAccount();
	const theme = useTheme();
	const isLogin = useCheckLogin();
	const { isMatchPad, isMatchPc, isMatchMobile } = useAppBreakpoints();
	const { poolInfo } = useAppSelector(poolsBaseState);
	const { currentChainId } = useCurrentChain();
	const { appTimer } = useAppSelector(globalBaseState);

	const [from, setFrom] = useState(0);
	const [data, setData] = useState([]);
	const [newDataMap, setNewDataMap] = useState(new Map());
	const [hasMore, setHasMore] = useState(true);
	const [fetching, setFetching] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isInitRequest, setIsInitRequest] = useState(true);

	// 滚动加载的请求，往后请求
	const { trigger } = usePassivePositionHistoriesRequest({
		account: address,
		market,
		from,
		limit: DEFAULT_PAGE_SIZE,
		liquidity_position_history_id
	});
	// 用于轮询，往前请求
	const { trigger: getFreshData } = usePassivePositionHistoriesRequest({
		account: address,
		market,
		from: 0,
		limit: DEFAULT_PAGE_SIZE
	});

	const fetchAction = useCallback(
		(index: number) => {
			if (!isLogin) {
				return Promise.resolve();
			}

			if (hasMore && !fetching) {
				setFetching(true);
				return trigger()
					.then(res => {
						if (!res.data) {
							return;
						}
						setHasMore(res.data.histories.length === DEFAULT_PAGE_SIZE);
						setData(
							index === 0
								? res.data.histories
								: [...data, ...res.data.histories]
						);
						setFrom(res.data.histories[res.data.histories.length - 1].id);
					})
					.finally(() => setFetching(false));
			} else {
				return Promise.resolve();
			}
		},
		[hasMore, fetching, isLogin]
	);

	useEffect(() => {
		setData([]);
		setIsInitRequest(true);
		setLoading(true);
		fetchAction(0).finally(() => {
			setLoading(false);
			setIsInitRequest(false);
		});
	}, []);
	useEffect(() => {
		if (!liquidity_position_history_id && isLogin && !isInitRequest) {
			getFreshData().then(res => {
				const result = res.data || [];
				const newData = result.histories.filter(
					history => !data.find(item => item.id === history.id)
				);
				const map = new Map();
				newData.forEach(item => {
					map.set(item.id, item);
				});
				setNewDataMap(map);
				setData([...newData, ...data]);
			});
		}
	}, [appTimer]);

	const INCREASE_TYPE = 'INCREASE';
	const blinkColor = theme.custom.ListBorder;
	const checkIsNew = (row: (typeof data)[number]) => {
		return newDataMap.has(row.id);
	};

	const columns = [
		{
			id: 'time',
			label: t`Time`,
			width: '24%',
			format: (row: (typeof data)[number]) => (
				<Box
					className='pl-1 whitespace-nowrap sm:pl-0'
					sx={{
						background: checkIsNew(row) ? blinkColor : void 0,
						transition: 'background 1s ease-out'
					}}
				>
					{formatDate(moment(row.when), 'MMM D, HH:mm:ss')}
				</Box>
			)
		},
		{
			id: 'type',
			label: t`Operation`,
			width: '16%',
			format: (row: (typeof data)[number]) => (
				<Box
					sx={{
						background: checkIsNew(row) ? blinkColor : void 0,
						transition: 'background 1s ease-out'
					}}
				>
					{row.op === INCREASE_TYPE ? (
						<Trans>Open</Trans>
					) : (
						<Trans>Close</Trans>
					)}
				</Box>
			)
		},
		{
			id: 'side',
			label: t`Side`,
			width: '10%',
			format: (row: (typeof data)[number]) => (
				<Typography
					variant='body2'
					sx={{
						background: checkIsNew(row) ? blinkColor : void 0,
						transition: 'background 1s ease-out'
					}}
					color={
						row.side === Side.LONG
							? theme.palette.success.main
							: theme.palette.error.main
					}
				>
					{row.side === Side.LONG ? t`Long` : t`Short`}
				</Typography>
			)
		},
		{
			id: 'size',
			label: (
				<Typography
					align={isMatchPad ? 'right' : 'left'}
					variant='inherit'
				>{t`Size`}</Typography>
			),
			width: '10%',
			format: (row: (typeof data)[number]) => (
				<Typography
					sx={{
						background: checkIsNew(row) ? blinkColor : void 0,
						transition: 'background 1s ease-out'
					}}
					align={isMatchPad ? 'right' : 'left'}
					variant='inherit'
					className='break-words'
				>
					{row.op === INCREASE_TYPE ? (
						<>
							{'+'}
							{amountFormatter(
								row.sizeDelta,
								2,
								poolInfo.baseToken.positionUnits,
								MAX_AMOUNT_FORMATTER_LIMIT
							)}
						</>
					) : (
						<>
							{'-'}
							{amountFormatter(
								row.sizeDelta,
								2,
								poolInfo.baseToken.positionUnits,
								MAX_AMOUNT_FORMATTER_LIMIT
							)}
						</>
					)}
					&nbsp;
					{shortenSymbolNative(poolInfo.baseToken, currentChainId)}
				</Typography>
			)
		},
		{
			id: 'price',
			label: (
				<Typography
					align={isMatchMobile ? 'left' : 'right'}
					variant='inherit'
				>{t`Price`}</Typography>
			),
			width: '20%',
			format: (row: (typeof data)[number]) => (
				<Typography
					sx={{
						background: checkIsNew(row) ? blinkColor : void 0,
						transition: 'background 1s ease-out'
					}}
					align={isMatchMobile ? 'left' : 'right'}
					variant='body2'
				>
					{toQuoteAmount(row.price, poolInfo.baseToken.precision)}
				</Typography>
			)
		}
	];

	return (
		<div className='mt-4'>
			{isLogin ? (
				loading ? (
					<SectionLoading />
				) : isMatchMobile ? (
					<div className='mt-3'>
						{data.length > 0 && (
							<Virtuoso
								endReached={fetchAction}
								style={{ height: 400 }}
								className={classNames({ 'pr-4': isMatchPad })}
								data={data}
								itemContent={(columnIndex, item) => {
									return (
										<div className={classNames({ 'pr-4': isMatchPad })}>
											<div
												className={classNames(
													`grid grid-cols-${
														columns.length > 2 ? 3 : 2
													} gap-4 gap-y-3`,
													isMatchPad
														? `grid-cols-${columns.length > 2 ? 5 : 2}`
														: `grid-cols-${columns.length > 2 ? 3 : 2}`
												)}
											>
												{columns.map((column: any, columnIndex: number) => (
													<DirectionCell
														key={columnIndex}
														label={column.label}
														align={
															column.align
																? column.align
																: columnIndex &&
																  (columnIndex + 1) % (isMatchPad ? 5 : 3) === 0
																? 'left'
																: 'left'
														}
														value={column.format(item)}
													/>
												))}
											</div>
											<Divider className='my-4' />
										</div>
									);
								}}
							/>
						)}
						{data.length === 0 && (
							<NoData>
								<Trans>No history</Trans>
							</NoData>
						)}
					</div>
				) : (
					<div className='-mx-4 underLg:mx-0'>
						<VirtualTable
							rows={data}
							columns={columns}
							itemHeight={42}
							cellColor={
								liquidity_position_history_id
									? theme.custom.dialogBg
									: theme.custom.paperBg
							}
							loadMore={fetchAction}
							noDataNode={
								<NoData className='mx-4 underLg:mx-0'>
									<Trans>No history</Trans>
								</NoData>
							}
						/>
					</div>
				)
			) : (
				<NoData className='mx-0'>
					<Trans>No history</Trans>
				</NoData>
			)}
		</div>
	);
};

export default PassivePositionHistories;
