import { Hash } from '@wagmi/core';
import { useSnackbar } from 'notistack';

import { Transaction_Status } from '../config/constants';
import { useAppDispatch } from '../state/hooks';
import { setTxVisibleError, setTxVisibleSuccess } from '../state/tx/slice';

const useLocalSnackbar = () => {
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useAppDispatch();

	const showSnackbar = (
		description: string,
		txHash: Hash | undefined,
		type: Transaction_Status,
		more?: string
	) => {
		enqueueSnackbar('ready', {
			variant: 'TransactionSnackbar',
			description,
			txHash,
			type,
			more
		});
		if (type === Transaction_Status.Success) {
			dispatch(setTxVisibleSuccess(true));
		} else if (type === Transaction_Status.Error) {
			dispatch(setTxVisibleError(true));
		}
	};

	return { showSnackbar };
};

export default useLocalSnackbar;
