interface SVGProps {
	size?: number;
	color?: string;
}

function Tip({ size = 16, color = '#8B919D' }: SVGProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			style={{
				display: 'inline'
			}}
		>
			<path
				d='M7.98981 0.993164C4.1251 0.993164 0.992188 4.12611 0.992188 7.99079C0.992188 11.8555 4.12514 14.9884 7.98981 14.9884C11.8545 14.9884 14.9874 11.8555 14.9874 7.99079C14.9874 4.12611 11.8545 0.993164 7.98981 0.993164ZM7.98981 13.9833C4.68021 13.9833 1.99728 11.3004 1.99728 7.99079C1.99728 4.68119 4.6802 1.99826 7.98981 1.99826C11.2994 1.99826 13.9823 4.68119 13.9823 7.99079C13.9823 11.3004 11.2994 13.9833 7.98981 13.9833Z'
				fill={color}
			/>
			<path
				d='M7.14917 7.23145L8.83059 7.23145V11.6481H7.14917V7.23145Z'
				fill={color}
			/>
			<ellipse
				cx='7.98991'
				cy='5.11491'
				rx='1.00163'
				ry='1.00163'
				fill={color}
			/>
		</svg>
	);
}

export default Tip;
