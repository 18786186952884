import type { ReactElement } from 'react';
import React, { ForwardedRef, forwardRef } from 'react';

import { Trans } from '@lingui/macro';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';

import SectionLoading from 'components/SectionLoading';

import NoData from '../../../components/NoData';
import { div, plus, toPercent } from '../../../utils';

function CollapseTableV2<T>(
	{
		isLoading = false,
		focusedRowIndex,
		headers,
		contentRender,
		rows,
		onClickRow,
		noData = (
			<NoData size='large'>
				<Trans>No Data</Trans>
			</NoData>
		)
	}: {
		isLoading: boolean;
		focusedRowIndex?: number;
		headers: any[];
		rows: T[];
		contentRender?: (item: T, index: number) => React.ReactElement;
		onClickRow: (item: T, index: number) => void;
		noData?: React.ReactElement;
	},
	ref: ForwardedRef<HTMLDivElement>
): ReactElement {
	const { isMatchMobile } = useAppBreakpoints();
	const theme = useTheme();

	const regex = /(\d+(?:\.\d+)?)(?=%)/;
	const filteredHeader = headers.filter(item => !item.invisible);
	const totalWidth = filteredHeader.reduce(
		(prev, cur) => plus(prev, cur.width.match(regex)[1]),
		'0'
	);
	const headersProxy = filteredHeader.map(item => {
		return {
			...item,
			width: toPercent(div(item.width.match(regex)[1], totalWidth))
		};
	});

	return (
		<Box className='p-4 pt-1 flex flex-col overflow-hidden'>
			<div>
				<div className='flex w-full sm:hidden'>
					{headersProxy.map((header, index) => (
						<Typography
							key={index}
							align={header.align}
							width={header.width}
							variant='body2'
							color='secondary'
							className='py-4 px-4'
						>
							{header.label}
						</Typography>
					))}
				</div>
				<div
					ref={ref}
					style={{
						maxHeight: 'calc(100vh - 80px)'
					}}
					className={classNames(
						'overflow-auto space-y-3 md:space-y-2 no-scrollbar',
						{
							'flex justify-center h-[300px] items-center': isLoading
						}
					)}
				>
					{isLoading && <SectionLoading />}
					{!isLoading && rows.length === 0 && noData}
					{!isLoading &&
						rows.map((row, rowIndex) => (
							<Box
								className='relative rounded'
								key={rowIndex}
								sx={{
									border:
										focusedRowIndex === rowIndex
											? `1px solid ${theme.custom.ListBorder}`
											: `1px solid ${theme.custom.ListSelectBg}`,
									borderWidth: isMatchMobile ? '0px' : '1px'
								}}
							>
								<Box
									className={classNames('relative', {
										'cursor-pointer': focusedRowIndex !== rowIndex,
										rounded: focusedRowIndex !== rowIndex
									})}
									onClick={() => onClickRow(row, rowIndex)}
									sx={{
										background:
											focusedRowIndex === rowIndex
												? theme.custom.ListSelectBg
												: theme.custom.ListBg,
										'&:hover': {
											background: theme.custom.ListHoverBg
										}
									}}
								>
									{focusedRowIndex === rowIndex && (
										<FiberManualRecordIcon
											className='absolute top-1 left-1'
											sx={{
												width: 10,
												height: 10,
												color: theme.palette.primary.main
											}}
										/>
									)}

									<div className='flex items-center sm:hidden h-[48px]'>
										{headersProxy.map(header => (
											<Typography
												component='div'
												key={header.key}
												width={header.width}
												align={header.align}
												variant='body2'
												color='text.main'
												className='px-4'
											>
												{header.cellRender
													? header.cellRender(row)
													: row[header.key]}
											</Typography>
										))}
									</div>
								</Box>
								{contentRender && (
									<Box key={`render-${rowIndex}`}>
										{contentRender(row, rowIndex)}
									</Box>
								)}
							</Box>
						))}
				</div>
			</div>
		</Box>
	);
}

export default forwardRef(CollapseTableV2);
