import { useCallback, useEffect, useMemo, useState } from 'react';
import Countdown from 'react-countdown-simple';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { MAX_PRECISION, QUOTE_USD, Transaction_Type } from 'config/constants';
import { useAccount } from 'wagmi';

import { useRiskBufferFundPositionGraph } from 'graphql/useRiskBufferFundPositionGraph';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import useSubmitRiskBufferFund from 'hooks/useSubmitAdjustRiskBufferFund';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { TokenInfo } from 'types';
import {
	checkInputNumberic,
	formatNumber,
	isGreaterThan,
	isLessThanOrEqualTo,
	isNegative,
	isPositive,
	minus,
	multipliedBy,
	plus,
	shortenSymbol,
	shortenSymbolNative,
	toDecimalPlaces,
	toQuoteAmount
} from 'utils';

import ApproveButton from 'components/ApproveButton';
import Cell from 'components/Common/Cell';
import {
	CommonStyledAlertWarning,
	CommonStyledBaseInput,
	CommonStyledDivider,
	CommonStyledMaxButton
} from 'components/Common/Styled';
import Stronger from 'components/Stronger';
import Loading from 'components/Svg/Icons/Loading';
import TextWarning from 'components/TextWarning';
import ToNext from 'components/ToNext';
import ConnectWalletButton from 'components/Wallet/ConnectWalletButton';

import Dialog from '../../components/Common/Dialog';
import Button from '../../components/Common/StyledButton';

const RiskBufferFundDialog = ({
	onClose,
	realizedPnL,
	unrealizedPnL,
	totalContribution,
	poolAddress,
	baseToken
}: {
	onClose: () => void;
	realizedPnL: string;
	unrealizedPnL: string;
	totalContribution: string;
	poolAddress: string;
	baseToken: TokenInfo;
}) => {
	const { currentChainId } = useCurrentChain();
	const { address } = useAccount();
	const { quoteToken, quoteBalance } = useAppSelector(txBaseState);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [quoteAmountForAdd, setQuoteAmountForAdd] = useState<string>('');
	const [quoteAmountForReduce, setQuoteAmountForReduce] = useState<string>('');
	const [adjustType, setAdjustType] = useState<Transaction_Type>(
		Transaction_Type.ReduceRiskBufferFund
	);

	// const { data, loading } = useRiskBufferFundRewardGraph(
	// 	(address + ':' + poolAddress) as string
	// );
	const isLogin = useCheckLogin();
	const { data, loading } = useRiskBufferFundPositionGraph(
		(poolAddress + ':' + address) as string
	);
	const [contributed, unlockTime, unlockTimestamp] = useMemo(() => {
		let _contributed = '0';
		let _unlockTime = undefined;
		let _unlockTimestamp = undefined;
		if (data && data.liquidity) {
			_contributed = data.liquidity;
		}
		if (data && data.unlockTime) {
			_unlockTimestamp = parseInt(multipliedBy(data.unlockTime, 1000));
			_unlockTime = new Date(_unlockTimestamp).toISOString();
		}
		return [_contributed, _unlockTime, _unlockTimestamp];
	}, [data]);

	const netPerformance = useMemo(() => {
		if (realizedPnL && unrealizedPnL) {
			return plus(realizedPnL, unrealizedPnL);
		}
		return '0';
	}, [realizedPnL, unrealizedPnL]);
	const maxReducedMargin = useMemo(() => {
		if (
			unlockTimestamp &&
			isGreaterThan(unlockTimestamp, new Date().getTime())
		) {
			return '0';
		}
		if (contributed && netPerformance) {
			return !isNegative(netPerformance) ? contributed : '0';
		}
		return '0';
	}, [contributed, netPerformance]);

	const onHandleChangeLiquidityDelta = useCallback(
		(event: any) => {
			const value = event.target.value;
			if (value.trim() === '') {
				if (adjustType === Transaction_Type.IncreaseRiskBufferFund) {
					setQuoteAmountForAdd(value);
				} else {
					setQuoteAmountForReduce(value);
				}
			}
			if (checkInputNumberic(value, quoteToken?.decimals)) {
				if (adjustType === Transaction_Type.IncreaseRiskBufferFund) {
					setQuoteAmountForAdd(value);
				} else {
					setQuoteAmountForReduce(value);
				}
			}
		},
		[adjustType, quoteToken]
	);
	const currentLiquidityDelta = useMemo(() => {
		if (adjustType === Transaction_Type.IncreaseRiskBufferFund) {
			return quoteAmountForAdd;
		} else {
			return quoteAmountForReduce;
		}
	}, [quoteAmountForAdd, quoteAmountForReduce, adjustType]);

	const nextValue = useMemo(() => {
		if (contributed && isPositive(currentLiquidityDelta)) {
			if (adjustType === Transaction_Type.IncreaseRiskBufferFund) {
				return plus(contributed, currentLiquidityDelta);
			} else {
				return minus(maxReducedMargin, currentLiquidityDelta);
			}
		}
		return '';
	}, [
		contributed,
		adjustType,
		currentLiquidityDelta,
		maxReducedMargin,
		unlockTimestamp
	]);
	const { onConfirm, isConfirmed, isConfirming, error } =
		useSubmitRiskBufferFund(
			poolAddress as string,
			currentLiquidityDelta,
			adjustType,
			baseToken
		);
	const disabled = useMemo(() => {
		if (isConfirming) {
			return true;
		}
		if (isLoading) {
			return true;
		}

		if (!isPositive(currentLiquidityDelta)) {
			return true;
		}
		if (adjustType === Transaction_Type.IncreaseRiskBufferFund) {
			if (isGreaterThan(currentLiquidityDelta, quoteBalance)) {
				return true;
			}
		}
		if (adjustType === Transaction_Type.ReduceRiskBufferFund) {
			if (
				unlockTimestamp &&
				isGreaterThan(unlockTimestamp, new Date().getTime())
			) {
				return true;
			}
			if (isLessThanOrEqualTo(netPerformance, 0)) {
				return true;
			}
			if (isGreaterThan(currentLiquidityDelta, contributed)) {
				return true;
			}
			if (!isPositive(maxReducedMargin)) {
				return true;
			}
		}
		return false;
	}, [
		adjustType,
		currentLiquidityDelta,
		quoteBalance,
		maxReducedMargin,
		isConfirming,
		isLoading,
		unlockTimestamp
	]);
	const { i18n } = useLingui();
	const submitText = useMemo(() => {
		if (isConfirming) {
			return <Trans>Submitting...</Trans>;
		}
		if (isLoading) {
			return <Trans>Loading...</Trans>;
		}

		if (isPositive(currentLiquidityDelta)) {
			if (adjustType === Transaction_Type.IncreaseRiskBufferFund) {
				if (isGreaterThan(currentLiquidityDelta, quoteBalance)) {
					return <Trans>Insufficient Balance</Trans>;
				}
			}

			if (adjustType === Transaction_Type.ReduceRiskBufferFund) {
				if (isLessThanOrEqualTo(netPerformance, 0)) {
					return <Trans>Net performance ≤0 </Trans>;
				}
				if (isGreaterThan(currentLiquidityDelta, maxReducedMargin)) {
					return <Trans>Exceeds max withdrawable</Trans>;
				}

				if (!isPositive(maxReducedMargin)) {
					return <Trans>Confirm</Trans>;
				}
			}
		}
		return <Trans>Confirm</Trans>;
	}, [
		adjustType,
		currentLiquidityDelta,
		quoteBalance,
		maxReducedMargin,
		isConfirming,
		isLoading,
		contributed,
		netPerformance,
		i18n.locale
	]);

	const onSubmitConfirm = () => {
		setIsLoading(true);
		// approveCallback();
		// return;
		onConfirm();
	};
	const onHandleChangeTab = (value: Transaction_Type) => setAdjustType(value);
	const onHandleSetMaxAmount = () => {
		// setCloseSize(item.size);
		if (adjustType === Transaction_Type.IncreaseRiskBufferFund) {
			setQuoteAmountForAdd(quoteBalance);
		} else {
			setQuoteAmountForReduce(toDecimalPlaces(maxReducedMargin, 6));
		}
	};
	useEffect(() => {
		if (isConfirmed) {
			onClose();
		}
	}, [isConfirmed]);
	useEffect(() => {
		if (error) {
			setIsLoading(false);
		}
	}, [error]);
	return (
		<Dialog
			open
			onClose={onClose}
			title={
				<Typography variant='h6'>
					<Trans>Withdraw</Trans> RBF
				</Typography>
			}
		>
			<article className='space-y-4'>
				<Box className='flex'>
					{/* <Box className='relative z-9 flex-1'>
						<Button
							fullWidth
							componentVariant={
								adjustType === Transaction_Type.IncreaseRiskBufferFund
									? 'mainOutlined'
									: 'outlined'
							}
							onClick={() =>
								onHandleChangeTab(Transaction_Type.IncreaseRiskBufferFund)
							}
						>
							<Trans>Contribute</Trans>
						</Button>
					</Box> */}
					{/* <Box className='relative z-9 flex-1'>
						<Button
							fullWidth
							componentVariant={
								adjustType === Transaction_Type.IncreaseRiskBufferFund
									? 'outlined'
									: 'mainOutlined'
							}
							onClick={() =>
								onHandleChangeTab(Transaction_Type.ReduceRiskBufferFund)
							}
						>
							<Trans>Withdraw</Trans> RBF
						</Button>
					</Box> */}
				</Box>

				<Box>
					<Card>
						<CardContent>
							<Cell
								className='mb-2.5'
								label={
									<Typography variant='body1'>
										<Trans>Amount</Trans>
									</Typography>
								}
								prefix={
									adjustType === Transaction_Type.IncreaseRiskBufferFund ? (
										<Trans>Balance:</Trans>
									) : (
										<Trans>Withdrawable:</Trans>
									)
								}
								value={`${
									adjustType === Transaction_Type.IncreaseRiskBufferFund
										? formatNumber(quoteBalance, 2, true)
										: formatNumber(maxReducedMargin, 2, true)
								} ${shortenSymbol(quoteToken)}`}
							/>

							<CommonStyledBaseInput
								type='text'
								inputProps={{ maxLength: MAX_PRECISION, inputMode: 'decimal' }}
								className='input'
								sx={{
									'& .MuiInputBase-input': {
										height: 24
									}
								}}
								value={currentLiquidityDelta}
								placeholder='0.0'
								onChange={onHandleChangeLiquidityDelta}
								fullWidth
								endAdornment={
									<div className='flex items-center space-x-2'>
										<CommonStyledMaxButton
											variant='outlined'
											color='secondary'
											onClick={() => onHandleSetMaxAmount()}
											className='font-normal'
										>
											<Trans>Max</Trans>
										</CommonStyledMaxButton>
									</div>
								}
							/>
						</CardContent>
					</Card>
				</Box>
				<Typography>
					{`${shortenSymbolNative(baseToken, currentChainId)}/${QUOTE_USD}`}
				</Typography>
				<Box>
					<Cell
						label={
							<Typography variant='body2'>
								<Trans>Net Performance</Trans>
							</Typography>
						}
						value={
							<Stronger
								value={`${toQuoteAmount(netPerformance)} ${QUOTE_USD}`}
								tooltip={
									<Box>
										<Cell
											label={<Trans>Realized PnL</Trans>}
											value={`${toQuoteAmount(realizedPnL, 2)} ${QUOTE_USD}`}
										/>
										<Cell
											label={<Trans>Unrealized PnL</Trans>}
											value={`${toQuoteAmount(unrealizedPnL, 2)} ${QUOTE_USD}`}
										/>
									</Box>
								}
							/>
						}
					/>
					<Cell
						label={
							<Typography variant='body2'>
								<Trans>Total Contribution</Trans>
							</Typography>
						}
						value={
							totalContribution
								? `${toQuoteAmount(totalContribution)} ${QUOTE_USD}`
								: '-'
						}
					/>
					<CommonStyledDivider />
					<Cell
						label={
							<Typography variant='body2'>
								<Trans>My Contribution</Trans>
							</Typography>
						}
						value={
							<ToNext
								value={
									loading ? (
										<Loading size={20} />
									) : isPositive(contributed) ? (
										`${toQuoteAmount(contributed)} ${QUOTE_USD}`
									) : (
										'0.00'
									)
								}
								nextValue={toQuoteAmount(nextValue)}
								hasNext={!isNegative(nextValue) && nextValue !== ''}
							/>
						}
					/>
					<div className='flex items-start justify-between space-x-2'>
						<div className='flex items-center'>
							<Typography
								variant='body2'
								component='span'
								color='text.secondary'
							>
								<Typography variant='body2' className='flex items-center'>
									<Trans>Unlocking Countdown</Trans>
								</Typography>
							</Typography>
						</div>
						<Box
							sx={{
								'& .react-countdown-simple': {
									padding: 0
								}
							}}
							className='flex items-center space-x-1 text-right'
						>
							<Typography
								className='w-full'
								component='div'
								variant='body2'
								color='text.primary'
							>
								{unlockTime ? (
									<Countdown
										targetDate={unlockTime}
										formatType='dd_hh_mm_ss'
										renderer={({ days, hours, minutes, seconds }) => (
											<Box className='flex'>
												<Box sx={{ width: '32px' }}>
													<Typography
														variant='body2'
														color='text.primary'
														component='span'
													>
														{days}
													</Typography>
													<Typography
														variant='body2'
														color='text.secondary'
														component='span'
													>
														d
													</Typography>
												</Box>

												<Box sx={{ width: '32px' }}>
													<Typography
														variant='body2'
														color='text.primary'
														component='span'
														className='ml-1'
													>
														{hours > 9 ? hours : '0' + hours.toString()}
													</Typography>
													<Typography
														variant='body2'
														color='text.secondary'
														component='span'
													>
														h
													</Typography>
												</Box>

												<Box sx={{ width: '32px' }}>
													<Typography
														variant='body2'
														color='text.primary'
														component='span'
														className='ml-1'
													>
														{minutes > 9 ? minutes : '0' + minutes.toString()}
													</Typography>
													<Typography
														variant='body2'
														color='text.secondary'
														component='span'
													>
														m
													</Typography>
												</Box>

												<Box sx={{ width: '32px' }}>
													<Typography
														variant='body2'
														color='text.primary'
														component='span'
													>
														{seconds > 9 ? seconds : '0' + seconds.toString()}
													</Typography>
													<Typography
														variant='body2'
														color='text.secondary'
														component='span'
													>
														s
													</Typography>
												</Box>
											</Box>
										)}
									/>
								) : (
									'-'
								)}
							</Typography>
						</Box>
					</div>
				</Box>
				<CommonStyledAlertWarning>
					{/* <TextWarning>
						<Trans>
							Your contribution will be locked for 90 days (from the last time
							of contribution). After the lock-up period ends, you can withdraw
							your contribution at any time if the net performance of the fund
							is positive.
						</Trans>
					</TextWarning> */}
					<TextWarning>
						<Trans>
							You cannot withdraw your contribution if the fund&#39;s net
							performance is negative, even after the lock-up period has
							expired, until the net performance turns positive.
						</Trans>
					</TextWarning>
				</CommonStyledAlertWarning>
				<Box>
					{!isLogin ? (
						<ConnectWalletButton className='w-full' />
					) : (
						<ApproveButton
							componentVariant='confirm'
							fullWidth
							onClick={onSubmitConfirm}
							disabled={disabled}
							amountToApprove={currentLiquidityDelta}
							isApproveToken={
								adjustType === Transaction_Type.IncreaseRiskBufferFund
							}
							isApprovePositionRouter
						>
							{submitText}
						</ApproveButton>
					)}
				</Box>
			</article>
		</Dialog>
	);
};

export default RiskBufferFundDialog;
