import { Reducer, createSlice } from '@reduxjs/toolkit';
import { ILiquidityMiningPools } from 'graphql/useLiquidityRewardsGraph';

import { RootState } from 'state';

interface StateProps {
	isLoadingLiquidityList: boolean;
	liquidityList: Array<ILiquidityMiningPools> | null;
	previousLiquidityList: Array<any> | null;
}

const initialState: StateProps = {
	liquidityList: null,
	isLoadingLiquidityList: true,
	previousLiquidityList: null
};

export const slice = createSlice({
	name: 'earn',
	initialState,
	reducers: {
		setIsLoadingLiquidityList(state, { payload }) {
			state.isLoadingLiquidityList = payload;
		},
		setLiquidityList(state, { payload }) {
			state.liquidityList = payload;
		},
		setPreviousLiquidityList(state, { payload }) {
			state.previousLiquidityList = payload;
		},
	}
});

export const {
	setIsLoadingLiquidityList,
	setLiquidityList,
	setPreviousLiquidityList
} = slice.actions;

export const earnLiquidityBaseState = (state: RootState) => state.earnLiquidity;

export default slice.reducer as Reducer<typeof initialState>;
