import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import Chip from '@mui/material/Chip';

import { formatLeverage, isNegative } from 'utils';

export default function Leverage({
	value,
	margin
}: {
	value: string;
	margin?: string;
}) {
	return (
		<Typography component='span' variant='body1'>
			{margin && isNegative(margin) ? (
				<Trans>Liquidating</Trans>
			) : (
				formatLeverage(value)
			)}
		</Typography>
	);
}

export const LeverageCard = ({ value = '' }: { value: string | number }) => {
	return <Chip label={`${value}x`} />;
};
