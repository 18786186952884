import { Button, ButtonProps, alpha, lighten } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import cn from 'classnames';
import { App_Theme } from 'config/constants';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';

export interface ExtendButton extends ButtonProps {
	componentVariant?:
		| 'default'
		| 'outlined'
		| 'mainOutlined'
		| 'confirm'
		| 'cancel'
		| 'long'
		| 'short'
		| 'mini'
		| 'miniCancel'
		| 'text'
		| 'transparent'
		| 'transparentVersion'
		| 'version'
		| 'action'
		| 'withoutHoverAction';
	to?: string;
}

export default function CommonButton({
	children,
	className,
	componentVariant,
	...rest
}: ExtendButton) {
	const theme = useTheme();
	const { isMatchMobile } = useAppBreakpoints();
	if (componentVariant === 'short') {
		return (
			<Button
				variant='contained'
				className={`px-6 py-1.5 font-bold ${className}`}
				{...rest}
				sx={{
					backgroundColor: theme.palette.error.main,
					color: theme.palette.common.white,
					'&:hover': {
						backgroundColor: lighten(theme.palette.error.main, 0.2)
					},
					'&:disabled': {
						color:
							theme.palette.mode === App_Theme.dark
								? theme.palette.text.primary
								: theme.custom.positiveColor,
						opacity: theme.palette.action.disabledOpacity,
						background: theme.palette.error.main
					}
				}}
			>
				{children}
			</Button>
		);
	}
	if (componentVariant === 'long') {
		return (
			<Button
				variant='contained'
				className={`px-6 py-1.5 font-bold ${className}`}
				{...rest}
				sx={{
					backgroundColor: theme.palette.success.main,
					color: theme.palette.common.white,
					'&:hover': {
						backgroundColor: lighten(theme.palette.success.main, 0.2)
					},
					'&:disabled': {
						color:
							theme.palette.mode === App_Theme.dark
								? theme.palette.text.primary
								: theme.custom.positiveColor,
						opacity: theme.palette.action.disabledOpacity,
						background: theme.palette.success.main
					}
				}}
			>
				{children}
			</Button>
		);
	}
	if (componentVariant === 'version') {
		return (
			<Button
				variant='contained'
				className={`font-bold ${className}`}
				{...rest}
				sx={{
					minWidth: '34px !important',
					height: '30px !important',
					padding: '6px 8px !important',
					backgroundColor: theme.palette.primary.main,
					color: theme.palette.background.default,
					'&:hover': {
						backgroundColor: lighten(theme.palette.primary.main, 0.2)
					},
					'&:disabled': {
						color:
							theme.palette.mode === App_Theme.dark
								? theme.palette.text.primary
								: theme.custom.positiveColor,
						opacity: theme.palette.action.disabledOpacity,
						background: theme.palette.primary.main
					}
				}}
			>
				{children}
			</Button>
		);
	}
	if (componentVariant === 'transparentVersion') {
		return (
			<Button
				variant='contained'
				className={cn(`font-bold ${className}`)}
				{...rest}
				sx={{
					minWidth: '34px !important',
					height: '30px !important',
					padding: '6px 8px !important',
					backgroundColor: theme.custom.cardBg,
					color: theme.palette.text.primary,
					'&:hover': {
						backgroundColor: lighten(theme.custom.cardBg, 0.02)
					}
				}}
			>
				{children}
			</Button>
		);
	}

	if (componentVariant === 'transparent') {
		return (
			<Button
				variant='contained'
				className={cn(`font-bold ${className}`)}
				{...rest}
				sx={{
					backgroundColor: theme.custom.cardBg,
					color: theme.palette.secondary.main,
					'&:hover': {
						backgroundColor: lighten(theme.custom.cardBg, 0.02)
					}
				}}
			>
				{children}
			</Button>
		);
	}

	if (componentVariant === 'action') {
		return (
			<Button
				variant='contained'
				className={cn(`font-bold ${className}`)}
				{...rest}
				sx={{
					backgroundColor: theme.palette.action.hover,
					color: theme.palette.secondary.main,
					'&:hover': {
						backgroundColor: lighten(theme.custom.cardBg, 0.02)
					}
				}}
			>
				{children}
			</Button>
		);
	}

	if (componentVariant === 'cancel') {
		return (
			<Button
				variant='contained'
				color='secondary'
				className={cn(className)}
				{...rest}
				sx={{
					backgroundColor: alpha(theme.palette.secondary.main, 0.24),
					color: theme.palette.text.primary,
					'&:hover': {
						backgroundColor: alpha(theme.palette.secondary.main, 0.24)
					}
				}}
			>
				{children}
			</Button>
		);
	}
	// if (componentVariant === 'confirm') {
	//   return (
	//     <Button variant="contained" color="secondary" className={`px-6 py-1.5 ${className}`} onClick={onHandleClick} disabled={disabled} {...rest}
	//       sx={{
	//         backgroundColor: alpha(theme.palette.secondary.main, 0.24),
	//         color: theme.palette.text.primary,
	//       }}
	//     >
	//       {children}
	//     </Button>
	//   );
	// }

	if (componentVariant === 'outlined') {
		return (
			<Button
				variant='outlined'
				color='secondary'
				className={cn(className)}
				{...rest}
				sx={{
					color: theme.palette.text.secondary,
					border: `1px solid ${theme.palette.divider}`,
					'&:hover': {
						border: `1px solid ${theme.palette.primary.main}`
						//backgroundColor: alpha(theme.palette.divider, 0.1)
					}
				}}
			>
				{children}
			</Button>
		);
	}

	if (componentVariant === 'mainOutlined') {
		return (
			<Button
				variant='outlined'
				color='secondary'
				className={cn(className)}
				{...rest}
				sx={{
					color: theme.palette.primary.main,
					border: `1px solid ${theme.palette.primary.main}`,
					'&:hover': {
						border: `1px solid ${theme.palette.primary.main}`
						// backgroundColor: alpha(theme.palette.divider, 0.1)
					}
				}}
			>
				{children}
			</Button>
		);
	}
	if (componentVariant === 'mini') {
		return (
			<Button
				variant='outlined'
				color='secondary'
				className={cn(className)}
				{...rest}
				sx={{
					height: 26,
					padding: '0 16px',
					'&:hover': {
						border: `1px solid ${theme.palette.primary.main}`
					},

					'&:disabled': {
						opacity: theme.palette.action.disabledOpacity
					}
				}}
			>
				{children}
			</Button>
		);
	}
	if (componentVariant === 'miniCancel') {
		return (
			<Button
				variant='outlined'
				color='secondary'
				className={cn(className)}
				{...rest}
				sx={{
					height: 26,
					padding: '0 16px',
					'&:hover': {
						border: `1px solid rgba(139, 145, 157, 0.5)`
					},
					'&:active': {
						border: isMatchMobile && `1px solid ${theme.palette.primary.main}`
					},
					'&:disabled': {
						opacity: theme.palette.action.disabledOpacity
					}
				}}
			>
				{children}
			</Button>
		);
	}
	if (componentVariant === 'text') {
		return (
			<Button
				variant='text'
				color='primary'
				className={cn(className)}
				{...rest}
				sx={{
					padding: '0',
					minWidth: 0,
					'&:hover': {
						backgroundColor: 'transparent',
						color: `${alpha(theme.palette.primary.main, 0.7)} !important`
					}
				}}
			>
				{children}
			</Button>
		);
	}

	if (componentVariant === 'withoutHoverAction') {
		return (
			<Button
				variant='contained'
				className={cn(`font-bold ${className}`)}
				{...rest}
				sx={{
					backgroundColor: theme.palette.action.hover,
					color: theme.palette.secondary.main
				}}
			>
				{children}
			</Button>
		);
	}

	return (
		<Button
			variant='contained'
			color='primary'
			className={cn(`font-bold ${className}`)}
			sx={{
				background: theme.custom.replenishColor,
				'&:hover': {
					backgroundColor: '#B3D600'
				},
				'&:disabled': {
					cursor: 'not-allowed',
					color:
						theme.palette.mode === App_Theme.dark
							? theme.palette.common.black
							: theme.palette.text.primary,
					opacity: 0.4,
					background: theme.palette.primary.main
				}
			}}
			{...rest}
		>
			{children}
		</Button>
	);
}
