import { useEffect, useMemo, useState } from 'react';
import { useDebounce, useUpdateEffect } from 'react-use';

import {
	DAYS_YEAR,
	EQUIP2_START_TIME,
	TIME_ONE_MINUTE_TO_SECOND,
	Version
} from 'config/constants';
import Decimal from 'decimal.js';
import { IPositionItem, useMyPostionsFetch } from 'fetch/useMyPositionsFetch';
import { useFarmClaimDetailsV2Request } from 'fetch/useRequest';
import moment from 'moment';
import { useAccount } from 'wagmi';

import { sortBy } from 'lodash';
import { Reward_Type } from 'pages/Earn/types';
import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { selectVersion } from 'state/setting/selector';
import { txBaseState } from 'state/tx/slice';
import { IPoolItem, TokenInfo } from 'types';

import {
	catchFn,
	ceilDiv,
	div,
	isGreaterThan,
	isLessThan,
	isPositive,
	minus,
	multipliedBy,
	plus,
	toDecimalPlaces
} from '../utils';
import { useMyPostionsGraph } from './useMyPositionsGraph';

export type IAprs = {
	type: string;
	apr: string;
	leverage: string;
	coefficient: string;
};

export type IPositionMiningPools = {
	id: string;
	claimableAmount: string;
	dailyEmissionAmount: string;
	avgCoefficient: string;
	baseToken?: TokenInfo;
	totalPositions?: string;
	myPositions?: string;
	apr?: string;
	avgApr?: string;
	positions?: IPositionItem[];
	aprs?: IAprs[];
};

export function usePositionRewardsGraph() {
	const { address } = useAccount();
	const { appTokenUsdPrice } = useAppSelector(txBaseState);
	const { blockTimestamp } = useAppSelector(globalBaseState);
	const { pools: poolList } = useAppSelector(poolsBaseState);
	const currentVersion = useAppSelector(selectVersion);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [debouncedPoolList, setDebouncedPoolList] =
		useState<Array<IPoolItem> | null>(null);

	const rewardType = useMemo(() => {
		if (currentVersion === Version.V1) {
			return Reward_Type.POSITION;
		}
		return Reward_Type.V2_POSITION;
	}, [currentVersion]);

	const {
		data: detailsList,
		isLoading: isLoadingDetails,
		refetch: refetchDetails
	} = useFarmClaimDetailsV2Request(address, rewardType);

	const {
		positionMap: positionMapV2,
		isLoadingPositions: isLoadingPositionsV2,
		refetchPositions: refetchPositionsV2
	} = useMyPostionsFetch({
		poolId: '',
		hashes: undefined,
		enabled: currentVersion === Version.V2
	});

	const {
		positionMap: positionMapV1,
		isLoadingPositions: isLoadingPositionsV1,
		refetchPositions: refetchPositionsV1
	} = useMyPostionsGraph({
		poolId: '',
		hashes: undefined,
		enabled: currentVersion === Version.V1
	});

	const positionMap = useMemo(() => {
		if (currentVersion === Version.V2) {
			return positionMapV2;
		}
		return positionMapV1;
	}, [currentVersion, positionMapV2, positionMapV1]);

	const isLoadingPositions = useMemo(() => {
		if (currentVersion === Version.V2) {
			return isLoadingPositionsV2;
		}
		return isLoadingPositionsV1;
	}, [currentVersion, isLoadingPositionsV2, isLoadingPositionsV1]);

	const refetchPositions = useMemo(() => {
		if (currentVersion === Version.V2) {
			return refetchPositionsV2;
		}
		return refetchPositionsV1;
	}, [currentVersion, refetchPositionsV2, refetchPositionsV1]);

	const refetch = () => {
		refetchDetails();
		refetchPositions();
	};

	// 页面首次加载，进行初始化赋值一次
	useUpdateEffect(() => {
		if (debouncedPoolList === null && poolList && poolList.length > 0) {
			setDebouncedPoolList(poolList);
		}
	}, [poolList]);

	useDebounce(
		() => {
			setDebouncedPoolList(poolList);
		},
		TIME_ONE_MINUTE_TO_SECOND / 2,
		[poolList]
	);

	useEffect(() => {
		if (!isLoadingDetails && !isLoadingPositions) {
			setIsLoading(isLoadingDetails);
		}
	}, [isLoadingDetails, isLoadingPositions]);

	return useMemo(() => {
		if (!detailsList || poolList.length === 0) {
			return {
				data: [],
				loading: isLoading,
				refetch
			};
		}
		const dataList: IPositionMiningPools[] = [];
		detailsList?.map(item => {
			const dataItem: IPositionMiningPools = {
				id: item.pool,
				claimableAmount: item.claimable_amount,
				dailyEmissionAmount: item.daily_emission_amount,
				avgCoefficient: item.coefficient || '0'
			};
			const poolId = item.pool.toLowerCase();
			const poolListItem = poolList.find(pli => pli.id === poolId);
			if (poolListItem) {
				dataItem.baseToken = poolListItem.baseToken;
				dataItem.totalPositions = plus(
					poolListItem.longSize,
					poolListItem.shortSize
				);
				dataItem.aprs = [];

				if (isPositive(dataItem.totalPositions)) {
					dataItem.apr = catchFn(() => {
						const _indexPrice = toDecimalPlaces(
							poolListItem.index_price,
							poolListItem.baseToken.precision
						);
						return multipliedBy(
							multipliedBy(
								div(
									div(
										multipliedBy(
											dataItem.dailyEmissionAmount,
											appTokenUsdPrice
										),
										multipliedBy(dataItem.totalPositions, _indexPrice)
									),
									item.coefficient
								),
								poolListItem.maxLeveragePerPosition ||
									poolListItem.token.maxLeveragePerPosition
							),
							DAYS_YEAR
						);
					}, '0');
					dataItem.avgApr = catchFn(() => {
						const _indexPrice = toDecimalPlaces(
							poolListItem.index_price,
							poolListItem.baseToken.precision
						);
						return multipliedBy(
							div(
								multipliedBy(
									multipliedBy(dataItem.dailyEmissionAmount, appTokenUsdPrice),
									div(
										poolListItem.globalPosition.liquidity,
										poolListItem.globalPosition.margin
									)
								),
								multipliedBy(dataItem.totalPositions, _indexPrice)
							),
							DAYS_YEAR
						);
					}, '0');
					dataItem.aprs.push({
						type: 'Average',
						apr: dataItem.avgApr,
						leverage: div(
							poolListItem.globalPosition.liquidity,
							poolListItem.globalPosition.margin
						),
						coefficient: dataItem.avgCoefficient
					});
					dataItem.aprs.push({
						type: 'Maximum',
						apr: dataItem.apr,
						leverage:
							poolListItem.maxLeveragePerPosition ||
							poolListItem.token.maxLeveragePerPosition,
						coefficient: '1'
					});
				} else {
					dataItem.apr = '0';
					dataItem.avgApr = '0';
				}
			}
			dataItem.myPositions = '0';
			dataItem.positions = [] as Array<IPositionItem>;
			if (positionMap && positionMap.has(poolId)) {
				const itemPositions = positionMap.get(poolId);
				if (itemPositions) {
					for (let i = 0; i < itemPositions.length; i++) {
						const targetPosition = itemPositions[i];
						const position = { ...targetPosition } as IPositionItem;
						const _entryTime =
							currentVersion === Version.V2
								? moment(targetPosition.entryTime).unix()
								: targetPosition.entryTime;
						const startTime = isGreaterThan(EQUIP2_START_TIME, _entryTime)
							? EQUIP2_START_TIME
							: _entryTime;
						const coefficient = isLessThan(minus(blockTimestamp, startTime), 0)
							? '1'
							: div(
									1,
									new Decimal(
										ceilDiv(minus(blockTimestamp, startTime), 60 * 60 * 24)
									).sqrt()
							  );
						position['coefficient'] = coefficient;
						position['baseToken'] = dataItem.baseToken;
						dataItem.positions.push(position);
						dataItem.myPositions = plus(
							dataItem.myPositions,
							targetPosition.size
						);
					}
				}
			}

			dataList.push(dataItem);
		});
		const sortList = sortBy(dataList, o => {
			return o?.baseToken?.sort;
		});
		return {
			data: sortList,
			loading: false,
			refetch
		};
	}, [
		debouncedPoolList,
		isLoading,
		detailsList,
		appTokenUsdPrice,
		positionMap,
		currentVersion
	]);
}
