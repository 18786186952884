import { useCallback, useContext, useMemo } from 'react';

import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { PositionRouterV2ABI } from '../../config/abis';
import {
	Contract_Write_Mode,
	Side,
	Transaction_Type
} from '../../config/constants';
import { getContractAddressV2 } from '../../config/contracts';
import { TradeContext } from '../../context/TradeContext';
import { TransactionDescription } from '../../entities/TransactionDescription';
import { selectPositionMinExecutionFee } from '../../state/global/selector';
import { useAppSelector } from '../../state/hooks';
import {
	selectCurrentBasicFees,
	selectCurrentInitialMargin,
	selectCurrentMarginDelta,
	selectCurrentPositionAmount
} from '../../state/market/selector';
import { marketBaseState } from '../../state/market/slice';
import { poolsBaseState } from '../../state/pools/slice';
import { tradeBaseState } from '../../state/trade/slice';
import { txBaseState } from '../../state/tx/slice';
import { RecordForTrade } from '../../types';
import {
	computePriceX96,
	formatSize,
	isGreaterThan,
	isLessThan,
	isLessThanOrEqualTo,
	isPositive,
	parseUnits,
	shortenSymbolNative,
	toDecimalPlaces,
	toQuoteAmount
} from '../../utils';
import { ApprovalState } from '../useApproval';
import { useComputeGasLimit } from '../useComputeGasLimit';
import { useCheckLogin, useCurrentChain } from '../useCurrentChain';
import { useSendTransaction } from '../useSendTransaction';
import {
	usePriceRangesByTolerance,
	useSlippageTolerance
} from '../useSlippageTolerance';

const useSubmitIncreaseTradeMarketV2 = (
	currentApproveState: ApprovalState | undefined,
	isPluginApproved: boolean
) => {
	const { transactionType, quoteToken, quoteBalance } =
		useAppSelector(txBaseState);
	const { leverage } = useAppSelector(tradeBaseState);
	const { entryPrice } = useAppSelector(marketBaseState);
	const { poolInfo } = useAppSelector(poolsBaseState);

	const currentMarginDelta = useAppSelector(selectCurrentMarginDelta);
	const currentPositionAmount = useAppSelector(selectCurrentPositionAmount);
	const currentBasicFees = useAppSelector(selectCurrentBasicFees);
	const currentInitialMargin = useAppSelector(selectCurrentInitialMargin);
	const positionMinExecutionFee = useAppSelector(selectPositionMinExecutionFee);

	const { currentChainId } = useCurrentChain();
	const isLogin = useCheckLogin();
	const { address } = useAccount();

	const { positionInfo } = useContext(TradeContext);

	const { exactOutRate } = useSlippageTolerance();
	const { minPrice, maxPrice } = usePriceRangesByTolerance(entryPrice);

	const { args, record, description } = useMemo(() => {
		if (
			!isLogin ||
			!poolInfo ||
			!quoteToken ||
			!isPositive(quoteBalance) ||
			!isPositive(currentMarginDelta) ||
			!isPositive(currentPositionAmount) ||
			(!positionInfo &&
				isLessThan(currentMarginDelta, poolInfo.minMarginPerPosition)) ||
			!isLessThanOrEqualTo(currentMarginDelta, quoteBalance) ||
			!isPositive(leverage) ||
			isGreaterThan(leverage, poolInfo.maxLeveragePerPosition) ||
			!isPositive(currentInitialMargin) ||
			!isPositive(entryPrice) ||
			currentApproveState !== ApprovalState.APPROVED ||
			!isPluginApproved
		) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		const { address: market } = poolInfo;

		const side =
			transactionType === Transaction_Type.LongV2 ? Side.LONG : Side.SHORT;

		// 使用用户支付的初始保证金
		const marginDelta = parseUnits(currentMarginDelta, quoteToken.decimals);

		const _acceptablePrice =
			transactionType === Transaction_Type.LongV2 ? maxPrice : minPrice;
		const acceptablePriceX96 = computePriceX96(
			_acceptablePrice,
			poolInfo.baseToken.decimals,
			quoteToken.decimals
		);
		const size = parseUnits(
			toDecimalPlaces(currentPositionAmount, poolInfo.baseToken.decimals),
			poolInfo.baseToken.decimals
		);

		const args = [market, side, marginDelta, size, acceptablePriceX96];

		const description = TransactionDescription.CreatePosition(
			shortenSymbolNative(poolInfo.baseToken, currentChainId),
			formatSize(currentPositionAmount, poolInfo.baseToken.positionUnits),
			toQuoteAmount(_acceptablePrice, poolInfo.baseToken.precision),
			side
		);

		const record = {
			transactionType: `${transactionType}`,
			side,
			pool: market,
			baseToken: poolInfo.baseToken,
			quoteAmount: currentMarginDelta,
			positionAmount: currentPositionAmount,
			entryPrice,
			acceptablePrice: _acceptablePrice,
			initialMargin: currentInitialMargin
		} as RecordForTrade;

		return {
			args,
			record,
			description
		};
	}, [
		isLogin,
		transactionType,
		poolInfo,
		quoteToken,
		currentMarginDelta,
		currentPositionAmount,
		currentBasicFees,
		currentApproveState,
		isPluginApproved,
		quoteBalance,
		leverage,
		minPrice,
		maxPrice,
		entryPrice,
		exactOutRate,
		currentInitialMargin,
		currentChainId,
		positionInfo
	]);

	const overrides = useMemo(() => {
		if (!address || !isPositive(positionMinExecutionFee)) {
			return undefined;
		}
		return {
			from: address,
			value: positionMinExecutionFee
		};
	}, [address, positionMinExecutionFee]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address: getContractAddressV2(currentChainId, 'PositionRouter'),
			abi: PositionRouterV2ABI,
			functionName: 'createIncreasePosition',
			args,
			overrides
		} as UseContractWriteConfig;
	}, [currentChainId, args, overrides]);

	const { gasLimit, errMsg } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirming, isConfirmed } = useSendTransaction(
		contractWriteArgs,
		gasLimit
	);

	return {
		onConfirm: useCallback(() => {
			if (!record) return;
			onSendTransaction(record, description);
		}, [transactionType, onSendTransaction, record, description]),
		isConfirming,
		isConfirmed,
		errMsg
	};
};

export default useSubmitIncreaseTradeMarketV2;
