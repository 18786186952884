import React, { useEffect, useMemo } from 'react';
import { useInterval, useUpdateEffect } from 'react-use';

import { TIME_ONE_MINUTE_TO_SECOND } from 'config/constants';
import { useAccount } from 'wagmi';

import { LiquidityMath } from 'entities/LiquidityMath';
import { RewardsUtil } from 'entities/RewardsUtil';
import { useMyStakesGraph } from 'graphql/useMyStakesGraph';
import { useStakeStatisticGraph } from 'graphql/useStakeStatisticGraph';
import { TickMath } from 'hooks/V3/TickMath';
import {
	IV3Pool,
	UniLiquidityToken,
	UniLiquidityTokenRender
} from 'hooks/V3/types';
import {
	useMyUniPositionIds,
	useUniV3PositionsByTokenIds
} from 'hooks/V3/useUniV3Positions';
import { useCheckLogin } from 'hooks/useCurrentChain';
import useIsWindowVisible from 'hooks/useIsWindowVisible';
import { globalBaseState } from 'state/global/slice';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { IStakeDetail } from 'types';
import { catchFn, sortsEqual } from 'utils';

import {
	earnStakeBaseState,
	setIsLoadingStakeList,
	setLpStakeList,
	setMaxMultiplier,
	setMyUniPositions,
	setStakeList,
	setStakeStatistic,
} from './slice';

export default React.memo(function EarnStakeUpdater() {
	const dispatch = useAppDispatch();

	const { uniV3PoolMap } = useAppSelector(txBaseState);
	const { appTimer } = useAppSelector(globalBaseState);
	const { stakeStatistic, visibleStakeLpDialog } =
		useAppSelector(earnStakeBaseState);

	const { address } = useAccount();
	const isLogin = useCheckLogin();
	const isWindowVisible = useIsWindowVisible();

	const { data: stakeStatisticData, refetch: refetchStakeStatistic } = useStakeStatisticGraph();

	useEffect(() => {
		if (stakeStatisticData) {
			dispatch(setStakeStatistic(stakeStatisticData));
			const _multipliers = stakeStatisticData.multipliers;
			dispatch(setMaxMultiplier(_multipliers[_multipliers.length - 1]));
		}
	}, [stakeStatisticData]);

	useUpdateEffect(() => {
		refetchStakeStatistic();
	}, [appTimer]);

	// 我的已质押列表 和 我的待质押列表
	{
		const {
			myUniPositionIds,
			refetchUniPositionIds,
			isLoadingMyUniPositionIds
		} = useMyUniPositionIds();

		const {
			loading,
			lpStakeList,
			stakeList,
			refetch: refetchStakeList
		} = useMyStakesGraph();

		const { uniV3Positions, refetchV3Positions } =
			useUniV3PositionsByTokenIds(myUniPositionIds);

		const myUniV3Positions = useMemo(() => {
			if (!myUniPositionIds || !uniV3Positions) {
				return null;
			}
			return uniV3Positions.filter(uniPosition => {
				return myUniPositionIds.includes(uniPosition.id);
			});
		}, [uniV3Positions, myUniPositionIds]);

		const computedMyUniV3Positions = useMemo(() => {
			if (!myUniV3Positions || myUniV3Positions.length === 0 || !uniV3PoolMap) {
				return null;
			}
			return catchFn(() => {
				const result = [] as Array<UniLiquidityTokenRender>;
				myUniV3Positions.forEach((item: UniLiquidityToken) => {
					const pool = uniV3PoolMap.get(item.poolAddress) as IV3Pool;

					if (pool) {
						const priceFromSqrt = TickMath.getPriceBySqrtPrice(pool.sqrtPrice);
						const { amount0, amount1 } = LiquidityMath.getAmountByLiquidity(
							item.basePriceLower,
							item.basePriceUpper === TickMath.MAX_TICK_VALUE
								? Infinity
								: item.basePriceUpper,
							priceFromSqrt,
							item.liquidity
						);
						const [baseAmount, quoteAmount] = sortsEqual(
							item.token0,
							item.baseToken
						)
							? [amount0, amount1]
							: [amount1, amount0];
						let inRange;
						if (pool.tick >= item.tickLower && pool.tick <= item.tickUpper) {
							inRange = true;
						} else {
							inRange = false;
						}
						const price = TickMath.getPriceByTick(pool.tick);
						result.push({
							...item,
							price,
							priceFromSqrt: pool.sqrtPrice,
							baseAmount,
							quoteAmount,
							pool,
							inRange
						} as UniLiquidityTokenRender);
					}
				});
				return result;
			}, []);
		}, [myUniV3Positions, uniV3PoolMap]);

		const computedStakeList = useMemo(() => {
			if (!stakeStatistic || !stakeList) {
				return null;
			}
			return stakeList.map((item: IStakeDetail) => {
				const claimableReward = catchFn(() => {
					return RewardsUtil.calculateStakeReward(
						item.perShareGrowthX64,
						stakeStatistic.perShareGrowthX64,
						item.stakedAmount,
						item.multiplier
					);
				}, '');
				return {
					...item,
					claimableReward
				};
			});
		}, [stakeStatistic, stakeList]);

		const computedLpStakeList: Array<IStakeDetail> | null = useMemo(() => {
			if (!stakeStatistic || !lpStakeList) {
				return null;
			}
			return lpStakeList.map((item: IStakeDetail) => {
				const claimableReward = catchFn(() => {
					return RewardsUtil.calculateStakeReward(
						item.perShareGrowthX64,
						stakeStatistic.perShareGrowthX64,
						item.stakedAmount,
						item.multiplier
					);
				}, '');
				return {
					...item,
					claimableReward
				} as IStakeDetail;
			});
		}, [stakeStatistic, lpStakeList]);

		useEffect(() => {
			dispatch(setIsLoadingStakeList(loading));
		}, [loading]);

		useEffect(() => {
			dispatch(setStakeList(computedStakeList));
		}, [computedStakeList]);

		useEffect(() => {
			dispatch(setLpStakeList(computedLpStakeList));
		}, [computedLpStakeList]);

		useEffect(() => {
			if (computedMyUniV3Positions) {
				dispatch(setMyUniPositions(computedMyUniV3Positions));
			} else {
				dispatch(setMyUniPositions(null));
			}
		}, [computedMyUniV3Positions]);

		useUpdateEffect(() => {
			if (isLogin) {
				refetchStakeList();
			}
		}, [isLogin, address, appTimer]);

		useEffect(() => {
			refetchUniPositionIds();
		}, [address]);

		// 打开质押对话框时，需要重新获取uni相关的所有信息
		useEffect(() => {
			if (visibleStakeLpDialog) {
				refetchUniPositionIds();
				refetchV3Positions();
			}
		}, [visibleStakeLpDialog]);

		useUpdateEffect(() => {
			// console.log('myUniPositionIds: ', myUniPositionIds);
			if (!isLoadingMyUniPositionIds && myUniPositionIds) {
				refetchV3Positions();
			}
		}, [isLoadingMyUniPositionIds, myUniPositionIds]);

		useInterval(
			() => {
				refetchUniPositionIds();
				refetchV3Positions();
			},
			isWindowVisible ? TIME_ONE_MINUTE_TO_SECOND / 2 : null
		);
	}

	return null;
});
