/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';

import { Trans, t } from '@lingui/macro';
import { Avatar, Box, IconButton, Typography, useTheme } from '@mui/material';
import cn from 'classnames';
import {
	APP_TOKEN_SYMBOL,
	App_Theme,
	Leaderboard_Rank,
	dataRanges
} from 'config/constants';
import { getContractAddress } from 'config/contracts';
import { useReferralLeaderboard } from 'fetch/useRequest';
import moment from 'moment';
import { useAccount } from 'wagmi';

import CopperIcon from 'assets/svg/leaderboard/copper.svg';
import GoldIcon from 'assets/svg/leaderboard/gold.svg';
import LightShareIcon from 'assets/svg/leaderboard/lighShare.svg';
import ShareIcon from 'assets/svg/leaderboard/share.svg';
import SilverIcon from 'assets/svg/leaderboard/silver.svg';
import { useMembersLeaderboardGraph } from 'graphql/useMembersLeaderboardGraph';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import useMemberPositionValue from 'hooks/useMemberPositionValue';
import { cloneDeep, filter, find, isEqual, map, sortBy } from 'lodash';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import {
	ExplorerDataType,
	formatNumber,
	formatTimeString,
	getExplorerLink,
	shortenAddress,
	shortenSymbol,
	toQuoteAmount
} from 'utils';

import StyledLink from 'components/Common/StyledLink';
import Ranges from 'components/Ranges';
import SectionLoading from 'components/SectionLoading';
import Table from 'components/Table/StickyTable';
import TippingUnderline from 'components/TippingUnderline';

import warningIcon from '../../../assets/svg/icon-warning.svg';
import NoData from '../../../components/NoData';
import PositionValueDialog from '../Members/PositionValueDialog';
import MemberInvationDialog from './MemberInvationDialog';
import MemberShareDialog from './MemberShareDialog';

export default function Leaderboard() {
	const [members, setMembers] = useState([]);
	const prevMembers = usePrevious(members);
	const [range, setRange] = useState<any>(dataRanges[0]);
	const { isMatchMobile, isMatchPad, isMatchPc } = useAppBreakpoints();
	const theme = useTheme();
	const { currentChain: chain, currentChainId } = useCurrentChain();
	const { quoteToken, appToken } = useAppSelector(txBaseState);
	const isLogin = useCheckLogin();
	const { address } = useAccount();
	const { data: referralLeaderboard } = useReferralLeaderboard(range.value);

	const {
		membersRewardList,
		refereesAddressList,
		data,
		loading: loadingMembers,
		refetch: refetchMembers,
		refetchRewards: refetchMembersRewards,
		error
	} = useMembersLeaderboardGraph(
		members,
		referralLeaderboard?.block_number,
		range.value
	);

	const { positionValueMap, loading: positionValueLoading } =
		useMemberPositionValue(
			refereesAddressList,
			referralLeaderboard?.block_number
		);

	const [referralAddressOfMember, setReferralAddressOfMember] = useState<
		string[]
	>([]);

	const [RefereesDialogOpen, setRefereesDialogOpen] = useState(false);
	const onOpenRefereesDialog = (address: typeof referralAddressOfMember) => {
		setRefereesDialogOpen(true);
		setReferralAddressOfMember(address);
	};
	const onCloseRefereesDialog = () => {
		setRefereesDialogOpen(false);
		setReferralAddressOfMember([]);
	};

	const [positionValueDialogOpen, setPositionValueDialogOpen] = useState(false);
	const onOpenPositionValueDialog = (
		address: typeof referralAddressOfMember
	) => {
		setReferralAddressOfMember(address);
		setPositionValueDialogOpen(true);
	};
	const onClosePositionValueDialog = () => {
		setPositionValueDialogOpen(false);
		setReferralAddressOfMember([]);
	};

	const [currentMember, setCurrentMember] = useState(null);
	const [shareDialogOpen, setShareDialogOpen] = useState(false);

	useEffect(() => {
		if (referralLeaderboard && referralLeaderboard?.leaderboards.length > 0) {
			const membersArray = [] as Array<string>;
			referralLeaderboard?.leaderboards.forEach((item: any) => {
				membersArray.push(item.member_id.toString());
			});
			setMembers(membersArray);
		}
	}, [referralLeaderboard]);

	useEffect(() => {
		if (members && members.length > 0 && isEqual(members, prevMembers)) {
			refetchMembers();
			refetchMembersRewards();
		}
	}, [members, prevMembers]);

	const leaderboardList = useMemo(() => {
		if (referralLeaderboard) {
			const list = referralLeaderboard?.leaderboards.map(item => {
				const currentMember = find(membersRewardList, {
					id: item.member_id.toString()
				});
				const referralCodes =
					find(data || [], { id: item.member_id.toString() })?.referralCodes ||
					[];
				if (currentMember) {
					return { ...item, ...currentMember, referralCodes };
				}
				return item;
			});
			if (isLogin) {
				const sortList = sortBy(list, 'rank');
				const filterList = map(
					sortBy(cloneDeep(filter(sortList, { address: address })), 'rank'),
					item => ({ ...cloneDeep(item), isOwner: true })
				);
				return [...filterList, ...sortList];
			} else {
				return sortBy(list, item => item.rank);
			}
		} else {
			return [];
		}
	}, [data, referralLeaderboard, membersRewardList, isLogin, address]);
	const isLoading = loadingMembers || !data || positionValueLoading;

	const changeRange = (period: any) => {
		setRange(period);
	};

	const columns = [
		{
			key: 'Rank',
			width: !isMatchMobile ? '10%' : 60,
			label: (
				<Typography
					fontSize={14}
					variant='h1'
					component='div'
					className='h-[34px] flex items-center'
				>
					<Trans>Rank</Trans>
				</Typography>
			),
			align: 'left',
			format: (row: any) => {
				return (
					<Box className='flex items-center sm:h-[40px]'>
						{row.rank === Leaderboard_Rank.Gold && !row.isOwner && (
							<Avatar
								src={GoldIcon}
								sx={{
									width: isMatchMobile ? 14 : 21,
									height: isMatchMobile ? 16 : 24
								}}
							/>
						)}
						{row.rank === Leaderboard_Rank.Silver && !row.isOwner && (
							<Avatar
								src={SilverIcon}
								sx={{
									width: isMatchMobile ? 14 : 21,
									height: isMatchMobile ? 16 : 24
								}}
							/>
						)}
						{row.rank === Leaderboard_Rank.Copper && !row.isOwner && (
							<Avatar
								src={CopperIcon}
								sx={{
									width: isMatchMobile ? 14 : 21,
									height: isMatchMobile ? 16 : 24
								}}
							/>
						)}
						{((row.rank !== Leaderboard_Rank.Gold &&
							row.rank !== Leaderboard_Rank.Silver &&
							row.rank !== Leaderboard_Rank.Copper) ||
							row.isOwner) && (
							<Typography fontSize={14} variant='h1' component='div'>
								<Typography
									className='flex justify-center items-center'
									sx={{
										width: isMatchMobile ? 14 : 21,
										height: isMatchMobile ? 16 : 24
									}}
									fontSize={14}
									variant='h1'
									component='div'
								>
									{row.rank}
								</Typography>
							</Typography>
						)}
						{row.isOwner && isMatchMobile && (
							<Avatar
								component='div'
								onClick={() => {
									setShareDialogOpen(true);
									setCurrentMember({
										...row,
										time: referralLeaderboard.last_updated_time
									});
								}}
								className='ml-2 cursor-pointer'
								src={theme.mode === App_Theme.dark ? ShareIcon : LightShareIcon}
								sx={{ width: 14, height: 14 }}
							/>
						)}
					</Box>
				);
			}
		},
		{
			key: 'Address',
			width: !isMatchMobile ? '20%' : 60,
			label: (
				<Typography
					fontSize={14}
					variant='h1'
					component='div'
					className='h-[34px] flex items-center'
				>
					{!isMatchMobile ? <Trans>Member ID</Trans> : <Trans>ID</Trans>}
				</Typography>
			),
			align: 'left',
			format: (row: any) => {
				const EFCAddress = getContractAddress(currentChainId, 'EFC');
				return (
					<div className='opacity-70 flex items-center sm:h-[40px]'>
						{row.isOwner ? (
							<Box className='flex items-center sm:block'>
								<span>YOU</span>&nbsp;
								<div className=''>
									<StyledLink
										type='white'
										target={isMatchMobile ? '_self' : '_blank'}
										href={getExplorerLink(
											chain,
											EFCAddress,
											ExplorerDataType.NFT,
											row.member_id
										)}
									>
										#{row.member_id}
									</StyledLink>
								</div>
							</Box>
						) : (
							<Box className='flex items-center'>
								<StyledLink
									type='white'
									target={isMatchMobile ? '_self' : '_blank'}
									href={getExplorerLink(
										chain,
										EFCAddress,
										ExplorerDataType.NFT,
										row.member_id
									)}
								>
									#{row.member_id}
								</StyledLink>
								<StyledLink
									className='ml-3 underLg:hidden'
									type='white'
									target={isMatchMobile ? '_self' : '_blank'}
									href={getExplorerLink(
										chain,
										row.address || '',
										ExplorerDataType.ADDRESS
									)}
								>
									{shortenAddress(row.address)}
								</StyledLink>
							</Box>
						)}
					</div>
				);
			}
		},
		{
			key: 'Referees',
			width: !isMatchMobile ? '5%' : 30,
			label: (
				<Typography
					fontSize={14}
					variant='h1'
					component='div'
					className={cn(
						`h-[34px] flex items-center`,
						isMatchMobile ? 'justify-end' : 'justify-start'
					)}
				>
					<Trans>Referees</Trans>
				</Typography>
			),
			align: !isMatchMobile ? 'right' : 'left',
			format: (row: any) => {
				return (
					<Box
						className='flex justify-start items-center sm:h-[40px]'
						// sx={{
						// 	'&:hover': {
						// 		color: `${theme.palette.primary.main} !important`
						// 	}
						// }}
						// 	onClick={() => {
						// 		const address = refereesAddressList
						// 			.filter(item => item.memberId === row.id)
						// 			.reduce((prev, cur) => [...prev, cur.address], []);
						// onOpenRefereesDialog(address);
						// 	}}
					>
						<Typography variant='body2' fontWeight={400} component='span'>
							{`${formatNumber(row?.refereeCount || 0, 0)}`}
						</Typography>
					</Box>
				);
			}
		},
		{
			key: 'Position',
			hidden: isMatchMobile,
			width: '20%',
			label: (
				<Typography
					fontSize={14}
					variant='h1'
					component='div'
					className='h-[34px] flex items-center justify-end'
				>
					{!isMatchMobile ? (
						<Trans>Referees’ Position Value</Trans>
					) : (
						<Trans>Position</Trans>
					)}
				</Typography>
			),
			align: 'right',
			format: (row: any) => {
				return (
					<Typography
						component='span'
						variant='body2'
						className='flex items-center justify-end sm:h-[40px]'
						// sx={{
						// 	'&:hover': {
						// 		color: theme.palette.primary.main
						// 	}
						// }}
						// onClick={() => {
						// 	const address = refereesAddressList
						// 		.filter(item => item.memberId === row.id)
						// 		.reduce((prev, cur) => [...prev, cur.address], []);
						// 	onOpenPositionValueDialog(address);
						// }}
					>
						{`${toQuoteAmount(row?.position || 0)}`}
					</Typography>
				);
			}
		},
		{
			key: 'Position',
			width: !isMatchMobile ? '20%' : '22%',
			label: (
				<Typography
					fontSize={14}
					variant='h1'
					component='div'
					className='h-[34px] flex items-center justify-end'
				>
					{!isMatchMobile ? (
						<Trans>Total Trading Volume</Trans>
					) : (
						<Trans>Volume</Trans>
					)}
				</Typography>
			),
			align: 'right',
			format: (row: any) => {
				return (
					<Typography
						component='span'
						variant='body2'
						className='flex items-center justify-end sm:h-[40px]'
					>
						{`${toQuoteAmount(positionValueMap.get(row.id))}`}
					</Typography>
				);
			}
		},
		{
			key: 'Rewards',
			label: (
				<div className='h-[34px] flex items-center justify-end'>
					<TippingUnderline
						tooltip={
							<Trans>
								All referral rewards generated under this Member NFT (including
								unclaimed rewards).
							</Trans>
						}
						label={<Trans>Rewards</Trans>}
					/>
				</div>
			),
			align: 'right',
			format: (row: any) => {
				return (
					<Box
						className={cn(
							'flex justify-end space-x-2 items-center sm:h-[40px]'
						)}
					>
						<div className='flex items-center space-x-2 sm:block'>
							<Typography variant='body2' fontWeight={400}>{`${formatNumber(
								row?.equ_amount,
								appToken?.positionUnits || 0
							)} ${APP_TOKEN_SYMBOL}`}</Typography>
							<Typography variant='body2' fontWeight={400}>
								{`${formatNumber(row?.totalFeeReward || 0)} ${shortenSymbol(
									quoteToken
								)}`}
							</Typography>
						</div>
						{row.isOwner && !isMatchMobile && (
							<Avatar
								component='div'
								onClick={() => {
									setShareDialogOpen(true);
									setCurrentMember({
										...row,
										time: referralLeaderboard.last_updated_time
									});
								}}
								className='ml-2 cursor-pointer'
								src={theme.mode === App_Theme.dark ? ShareIcon : LightShareIcon}
								sx={{ width: 14, height: 14 }}
							/>
						)}
					</Box>
				);
			}
		}
	].filter(item => !item.hidden);

	return (
		<div className='space-y-3 sm:mx-3'>
			<Box className='flex justify-between md:mx-3 items-center sm:block sm:space-y-2'>
				<Typography color='text.secondary'>
					<Trans>
						Ranking based on the EQU rewards obtained from Referral Mining,
						updated every hour.
					</Trans>
				</Typography>
				<Typography className='flex items-center justify-end sm:justify-between'>
					<Typography color='text.secondary' className='mr-4 sm:text-xs'>
						{referralLeaderboard?.last_updated_time && (
							<Trans>
								Update at:{' '}
								{moment(referralLeaderboard?.last_updated_time).format(
									'MMM D, YYYY HH:mm:ss'
								)}
							</Trans>
						)}
					</Typography>
					<Ranges value={range?.value} onChange={changeRange} />
				</Typography>
			</Box>
			<div className='mx-4 sm:-mx-3 underLg:px-3 sm:overflow-hidden'>
				{isLoading && <SectionLoading />}
				{!isLoading && (
					<Table
						columns={columns}
						rows={error ? [] : leaderboardList}
						noDataNode={
							error ? (
								<NoData size='large'>
									<img
										className='mt-0.5 mr-1'
										width={14}
										src={warningIcon}
										alt=''
									/>
									<Trans>
										Network error. Please optimize your network environment and
										try again.
									</Trans>
								</NoData>
							) : (
								<NoData size='large'>
									<Trans>No Data</Trans>
								</NoData>
							)
						}
						origin={!isMatchPc}
						columnCellColor={theme.custom.ListBg}
						height='100%'
					/>
				)}
			</div>
			{positionValueDialogOpen && (
				<PositionValueDialog
					accounts={referralAddressOfMember}
					onClose={onClosePositionValueDialog}
					blockNumber={referralLeaderboard?.block_number}
				/>
			)}
			{RefereesDialogOpen && (
				<MemberInvationDialog
					address={referralAddressOfMember}
					onClose={onCloseRefereesDialog}
					blockNumber={referralLeaderboard?.block_number}
				/>
			)}
			{shareDialogOpen && (
				<MemberShareDialog
					member={currentMember}
					isAllTime={range.value === dataRanges[1].value}
					onClose={() => {
						setShareDialogOpen(false);
						setCurrentMember(null);
					}}
				/>
			)}
		</div>
	);
}
