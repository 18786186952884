import { TokenInfo } from "../types";
import { useAccount } from "wagmi";
import { useState, useMemo, useCallback } from "react";

export function useAddTokenToWallet() {
  const [tokenAdded, setTokenAdded] = useState({});
  const { connector: activeConnector } = useAccount();

  const sendProvider = useMemo(() => {
    return window?.ethereum?.providers
      ? window?.ethereum?.providers?.find((item) => item.isMetaMask)
      : window?.ethereum;
  }, [activeConnector]);

  const clearAddedStatus = useCallback(() => {
    setTokenAdded({});
  }, [setTokenAdded]);

  const onSubmit = useCallback(
    (token: TokenInfo | null) => {
      if (!token || !sendProvider) {
        return null;
      }
      return sendProvider
        ?.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20", // In the future, other standards will be supported
            options: {
              address: token.address, // The address of the token contract
              symbol: token.symbol,
              decimals: token.decimals, // The number of token decimals
              //image: token.logoURI || ''
            },
          },
        })
        .then((success: any) => {
          if (success) {
            setTokenAdded((state: any) => ({
              ...state,
              [token.address]: true,
            }));
          }
          return true;
        })
        .catch((error: Error) => {
          setTokenAdded((state: any) => ({ ...state, [token.address]: false }));
          return false;
        });
    },
    [sendProvider]
  );

  return {
    onSubmit,
    tokenAdded,
    clearAddedStatus,
  };
}
