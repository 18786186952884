import { useEffect, useMemo, useState } from 'react';
import { usePrevious, useUpdateEffect } from 'react-use';

import { Hash } from '@wagmi/core';
import { LOADING_INTERVAL_TIMEOUT, Transaction_Status } from 'config/constants';
import { useApolloClients } from 'context/ApolloClientsContext';
import { useAccount } from 'wagmi';

import _, { find, flatMap, map, merge } from 'lodash';
import { isGreaterThan, isPositive, plus } from 'utils';

import { codeCount } from '../pages/Referrals/Members';
import { useAppDispatch } from '../state/hooks';
import { setMembersAddress } from '../state/referrals/slice';
import { useMembersReferralsByIdQuery } from './__generated_referral__/types-and-hooks';
import { useReferralsRewardsByBlockNumberGraph } from './useReferralsRewardsByBlockNumberGraph';

export interface IRefereeItem {
	id: string;
	txHash: Hash;
}

export interface IReferralCodeItem {
	id: string;
	memberId: string;
	txHash: Hash;
	referees: Array<IRefereeItem>;
	status?: Transaction_Status;
	blockTimestamp: string;
}

export interface IReferralFeeRewardItem {
	claimed: string;
	unclaimed: string;
}

export interface IMemberItem {
	id: string;
	account: string;
	mintTimestamp: string;
	mintTxHash: string;
	referralCodes: Array<IReferralCodeItem>;
	referralFeeReward: Array<IReferralFeeRewardItem>;
}

export function useMembersLeaderboardGraph(
	members: string[],
	blockNumber: number,
	rangeValue: string
) {
	const dispatch = useAppDispatch();
	const { clientReferral } = useApolloClients();
	const { address } = useAccount();
	const [allReferralCodes, setAllReferralCodes] =
		useState<Array<IReferralCodeItem> | null>(null);
	const [refereesAddressList, setRefereesAddressList] = useState<Array<any>>(
		[]
	);
	const prevRangeValue = usePrevious(rangeValue);

	const [error, setError] = useState(false);
	const {
		rewards,
		EQURewards,
		refetch: refetchRewards,
		loading: isLoadingRewards,
		error: rewardsError
	} = useReferralsRewardsByBlockNumberGraph(members, blockNumber);
	const {
		data,
		loading: isLoadingMembersReferrals,
		refetch,
		error: referralsError
	} = useMembersReferralsByIdQuery({
		variables: {
			token: members,
			number: blockNumber,
			first: codeCount
		},
		skip: !(members.length > 0),
		client: clientReferral,
		notifyOnNetworkStatusChange: true
	});

	useEffect(() => {
		setError(Boolean(rewardsError) || Boolean(referralsError));
	}, [rewardsError, referralsError]);

	// const {
	// 	data: detailsList,
	// 	poolData:poolPositionList,
	// 	isLoading: isLoadingDetails,
	// 	refetch: refetchDetails
	// } = useReferralClaimDetailsV2Request(address, Referral_Request_Type.MEMBER);

	// const newRewardsEQUData = useMemo(() => {
	// 	if(!detailsList){
	// 		return undefined;
	// 	}
	// 	return detailsList.map((item: any) => {
	// 		const totalClaimableAmount = item.token_rewards.reduce((sum, reward) => plus(sum, reward.claimable_amount), '0');
	// 		const totalAmount = item.token_rewards.reduce((sum, reward) => plus(sum, reward.total_amount), '0');
	// 		return {
	// 			id:item.token_id.toString(),
	// 			equ_rewards:item.token_rewards,
	// 			total_claimable_amount:totalClaimableAmount,
	// 			total_amount:totalAmount
	// 		};
	// 	})

	// },[detailsList])

	useEffect(() => {
		if (!data || !data?.referrers || !data?.referrers?.length) {
			return;
		}
		const referralCodesAddress = flatMap(data?.referrers, referrer =>
			flatMap(referrer.referralCodes, referralCode =>
				map(referralCode.referees, referee => ({
					memberId: referrer.id,
					address: referee.id
				}))
			)
		);
		setRefereesAddressList(referralCodesAddress);
	}, [data]);

	const membersRewardList = useMemo(() => {
		if (!data || !data?.referrers || !data?.referrers?.length) {
			return undefined;
		}

		const feeRewardPools = _.map(data?.referrers, item => {
			const feePools = _.chain(item.referralFeeRewardDetails)
				.filter(item => isPositive(item?.unclaimed))
				.map(reward => reward.pool.id)
				.value();
			return { id: item.id, feePools };
		});

		const refereesResult = data?.referrers || [];
		const feeRewardMap = new Map();

		refereesResult.forEach(item => {
			const {
				id,
				referralFeeReward: { claimed, unclaimed, totalClaimed },
				referralCodes
			} = item;
			// referralCodes.forEach(addr => {

			// })
			const totalFeeReward = plus(totalClaimed, unclaimed);
			if (feeRewardMap.has(id)) {
				feeRewardMap.get(id).unclaimed = plus(
					feeRewardMap.get(id).unclaimed,
					unclaimed
				);
				feeRewardMap.get(id).claimed = plus(
					feeRewardMap.get(id).claimed,
					claimed
				);
				feeRewardMap.get(id).totalFeeReward = plus(
					feeRewardMap.get(id).totalFeeReward,
					totalFeeReward
				);
				feeRewardMap.get(id).rewardsEQU = '0';
			} else {
				feeRewardMap.set(id, {
					id,
					unclaimed,
					claimed,
					totalFeeReward,
					rewardsEQU: '0'
				});
			}
		});

		const filterRewardList = Array.from(feeRewardMap.values());
		const rewardList =
			rewards &&
			Object.values(
				rewards.reduce((accumulator, currentValue) => {
					const id = currentValue.id;
					const liquidity = currentValue.liquidity ?? '0';
					const position = currentValue.position ?? '0';
					const liquidityClaimed = currentValue.liquidityClaimed ?? '0';
					const positionClaimed = currentValue.positionClaimed ?? '0';
					const liquidityUnclaimed = currentValue.liquidityUnclaimed ?? '0';
					const positionUnclaimed = currentValue.positionUnclaimed ?? '0';

					if (accumulator[id]) {
						accumulator[id].liquidity = plus(
							accumulator[id].liquidity,
							liquidity
						);
						accumulator[id].position = plus(accumulator[id].position, position);
						accumulator[id].liquidityClaimed = plus(
							accumulator[id].liquidityClaimed,
							liquidityClaimed
						);
						accumulator[id].positionClaimed = plus(
							accumulator[id].positionClaimed,
							positionClaimed
						);
						accumulator[id].liquidityUnclaimed = plus(
							accumulator[id].liquidityUnclaimed,
							liquidityUnclaimed
						);
						accumulator[id].positionUnclaimed = plus(
							accumulator[id].positionUnclaimed,
							positionUnclaimed
						);
					} else {
						accumulator[id] = {
							positionUnclaimed: positionUnclaimed,
							liquidityUnclaimed: liquidityUnclaimed,
							positionClaimed: positionClaimed,
							liquidityClaimed: liquidityClaimed,
							liquidity: liquidity.toString(),
							position: position,
							id: id
						};
					}
					return accumulator;
				}, {})
			);

		const _membersRewardList = map(filterRewardList, item => {
			const feeRewardObj = find(feeRewardPools, { id: item.id });
			const rewardObj = find(rewardList, { id: item.id });
			const refereesObj = find(refereesResult, { id: item.id });
			const mergedObj = merge({}, item, rewardObj, feeRewardObj, refereesObj);
			// if (item?.unclaimed !== '0') {
			// 	mergedObj.unclaimed = EQURewards ? item?.unclaimed : '0';
			// }
			// if (EQURewardObj && EQURewardObj?.rewardsEQU !== '0') {
			// 	mergedObj.rewardsEQU = EQURewardObj?.rewardsEQU;
			// }
			// if (newEQURewardObj && newEQURewardObj?.total_claimable_amount !== '0') {
			// 	mergedObj.newRewardsEQU = newEQURewardObj?.total_claimable_amount;
			// }else{
			// 	mergedObj.newRewardsEQU = '0'
			// }
			// if (newEQURewardObj && newEQURewardObj?.total_amount !== '0') {
			// 	mergedObj.newRewardsTotalEQU = newEQURewardObj?.total_amount;
			// }else{
			// 	mergedObj.newRewardsTotalEQU = '0'
			// }
			mergedObj.rewards = (rewards || []).filter(
				reward => reward.id === item.id
			);
			return mergedObj;
		});

		// const membersRewardList = _membersRewardList.filter(item => {
		// 	return (
		// 		isGreaterThan(item.unclaimed, 0) ||
		// 		isGreaterThan(item.newRewardsEQU, 0) ||
		// 		isGreaterThan(item.rewardsEQU, 0) ||
		// 		isGreaterThan(item.liquidity, 0) ||
		// 		isGreaterThan(item.position, 0) ||
		// 		isGreaterThan(item.totalFeeReward, 0) ||
		// 		isGreaterThan(item.newRewardsTotalEQU, 0) ||
		// 		isGreaterThan(item.totalReferees, 0)
		// 	);
		// });
		return _membersRewardList;
	}, [data, refetch, rewards, allReferralCodes]);

	return {
		data: data?.referrers as unknown as Array<IMemberItem>,
		membersRewardList,
		refereesAddressList,
		allReferralCodes,
		loading: isLoadingMembersReferrals || isLoadingRewards,
		refetch,
		refetchRewards,
		error
	};
}
