import { useEffect, useMemo, useRef } from 'react';

import jazzicon from '@metamask/jazzicon';
import { useAccount } from 'wagmi';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';

export default function AccountAvatar(props: { size?: number }) {
	const { size = 18 } = props;
	const { isMatchMobile } = useAppBreakpoints();
	const { address } = useAccount();

	const icon = useMemo(() => {
		return address && jazzicon(size, parseInt(address.slice(2, 10), 16));
	}, [address, size]);

	const iconRef = useRef<HTMLSpanElement>(null);
	useEffect(() => {
		const current = iconRef.current;
		if (icon) {
			current?.appendChild(icon);
			return () => {
				try {
					current?.removeChild(icon);
				} catch (e) {
					console.error('Avatar icon not found');
				}
			};
		}
		return;
	}, [icon, iconRef]);

	return (
		<span
			ref={iconRef}
			className={isMatchMobile ? `h-[18px]` : `h-[22px]`}
		></span>
	);
}
