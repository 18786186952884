import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import { useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { APP_TOKEN_SYMBOL, Page, QUOTE_USD, Version } from 'config/constants';

import { ILiquidityMiningPools } from 'graphql/useLiquidityRewardsGraph';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import { earnLiquidityBaseState } from 'state/earn/liquidity/slice';
import { useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';
import { txBaseState } from 'state/tx/slice';
import {
	amountFormatter,
	formatNumber,
	isGreaterThan,
	isZero,
	shortenSymbolNative,
	toPercent,
	toQuoteAmount
} from 'utils';

import Cell from 'components/Common/Cell';
import { CommonStyledOutlinedButton } from 'components/Common/Styled';
import NoData from 'components/NoData';
import SectionLoading from 'components/SectionLoading';
import Stronger from 'components/Stronger';
import Table from 'components/Table/StickyTable';

import TippingUnderline from '../../../components/TippingUnderline';
import LiquidityApr from '../../components/V2/LiquidityApr';

export default function LiquidityMiningPoolsList() {
	const theme = useTheme();
	const { appToken } = useAppSelector(txBaseState);
	const { isLoadingLiquidityList, liquidityList } = useAppSelector(
		earnLiquidityBaseState
	);
	const currentVersion = useAppSelector(selectVersion);

	const navigate = useNavigate();
	const isLogin = useCheckLogin();
	const { currentChainId } = useCurrentChain();
	const { isMatchMobile, isMatchPad } = useAppBreakpoints();

	const columns = [
		{
			key: 'LPs',
			width: '10%',
			label: <Trans>Pool</Trans>,
			align: 'left',
			format: (row: ILiquidityMiningPools) => {
				return (
					<div className='flex space-x-1'>
						<Typography
							variant='h6'
							fontSize={isMatchMobile || isMatchPad ? 14 : 16}
							fontWeight={500}
						>
							{`${shortenSymbolNative(
								row.baseToken,
								currentChainId
							)}/${QUOTE_USD}`}
						</Typography>
						{/* {row.status === Transaction_Status.Pending && <Loading size={20} />} */}
					</div>
				);
			}
		},
		{
			key: 'APR',
			width: currentVersion === Version.V1 ? '16%' : '10%',
			label: (
				<div className='flex justify-end items-center'>
					<TippingUnderline
						tooltip={
							<div>
								{currentVersion === Version.V1 && (
									<>
										<Typography>
											<Trans>
												Max APR is calculated based on LPs&#39; trading fee
												income in the past 24 hours, EQU daily emission, and the
												Max Leverage of the pool.
											</Trans>
										</Typography>
										<Typography className='mt-2'>
											<Trans>Max APR = Trading Fee Max APR + EQU Max APR</Trans>
										</Typography>
									</>
								)}
								{currentVersion === Version.V2 && (
									<Typography>
										<Trans>
											Max APR is calculated based on LPs&apos; fee income in the
											last 7 days, EQU daily emission, and the Max Leverage of
											the pool. PnL from passive position is not included.
										</Trans>
									</Typography>
								)}
							</div>
						}
						label={<Trans>Max APR</Trans>}
					/>
				</div>
			),
			align: 'right',
			format: (row: ILiquidityMiningPools) => {
				return currentVersion === Version.V1 ? (
					<Stronger
						value={
							<Typography
								variant='body1'
								className='leading-none'
								color={
									row.maxApr && isGreaterThan(row.maxApr, 0)
										? 'success.main'
										: 'secondary'
								}
								fontWeight={400}
							>
								{row.maxApr ? toPercent(row.maxApr) : toPercent(0)}
							</Typography>
						}
						tooltip={
							<div>
								<Typography className='mb-1.5' color={theme.custom.orangeColor}>
									<Trans>
										Due to the maximum leverage being adjusted from 200x to
										100x, the Max APR figure may be lower than the Avg. APR.
									</Trans>
								</Typography>
								<Cell
									label={<Trans>Trading Fees Max APR</Trans>}
									value={
										row.feeMaxApr ? toPercent(row.feeMaxApr) : toPercent(0)
									}
								/>
								<Cell
									label='EQU Max APR'
									value={
										row.rewardMaxApr
											? toPercent(row.rewardMaxApr)
											: toPercent(0)
									}
								/>
							</div>
						}
					/>
				) : (
					<LiquidityApr
						color='success.main'
						item={{
							liquidityMaxApr: row.maxApr,
							feeMaxApr: row.feeMaxApr,
							rewardMaxApr: row.rewardMaxApr,
							fundingFeeMaxApr: row.fundingFeeMaxApr
						}}
					/>
				);
			}
		},
		{
			key: 'OutputRate',
			width: '14%',
			label: <Trans>Daily Emission</Trans>,
			align: 'right',
			format: (row: ILiquidityMiningPools) => {
				return (
					<Typography variant='body2' fontWeight={400}>
						{row.liquidityRewardPerDay
							? `${formatNumber(
									row.liquidityRewardPerDay,
									2,
									true,
									true
							  )} ${APP_TOKEN_SYMBOL}`
							: '-'}
					</Typography>
				);
			}
		},
		{
			key: 'TotalLiquidity',
			width: '16%',
			label: <Trans>Total Liquidity</Trans>,
			align: 'right',
			format: (row: ILiquidityMiningPools) => {
				return (
					<Typography variant='body2' fontWeight={400}>
						{row.liquidity ? amountFormatter(row.liquidity, 2, 2) : '-'}
					</Typography>
				);
			}
		},
		{
			key: 'MyLiquidity',
			width: '16%',
			label: <Trans>My Liquidity</Trans>,
			align: isMatchPad ? 'left' : 'right',
			format: (row: ILiquidityMiningPools) => {
				if (!isLogin) {
					return (
						<Typography variant='body2' fontWeight={400}>
							-
						</Typography>
					);
				}
				if (!row.myLiquidity || isZero(row.myLiquidity)) {
					return (
						<Typography variant='body2' fontWeight={400}>
							0
						</Typography>
					);
				}
				return (
					<Typography variant='body2' fontWeight={400}>
						{amountFormatter(row.myLiquidity, 2, 2)}
					</Typography>
				);
			}
		},
		{
			key: 'ClaimableRewards',
			width: '16%',
			label: <Trans>Claimable Rewards</Trans>,
			align: 'right',
			format: (row: ILiquidityMiningPools) => {
				if (!isLogin) {
					return (
						<Typography variant='body2' fontWeight={400}>
							-
						</Typography>
					);
				}
				if (!row.claimableIncome || isZero(row.claimableIncome)) {
					return (
						<Typography variant='body2' fontWeight={400}>
							{`${formatNumber(0, appToken.positionUnits)} ${APP_TOKEN_SYMBOL}`}
						</Typography>
					);
				}
				return (
					<Typography variant='body2' fontWeight={400}>
						{`${formatNumber(
							row.claimableIncome,
							appToken.positionUnits
						)} ${APP_TOKEN_SYMBOL}`}
					</Typography>
				);
			}
		},
		{
			key: 'op',
			width: '12%',
			label: '',
			align: isMatchMobile ? 'left' : 'right',
			format: (row: ILiquidityMiningPools) => {
				return (
					<CommonStyledOutlinedButton
						variant='outlined'
						color='secondary'
						onClick={() => {
							navigate(
								`${currentVersion === Version.V1 ? Page.PoolsV1 : Page.Pools}/${
									row.id
								}`
							);
						}}
						disabled={!isLogin}
					>
						<Trans>Add</Trans>
					</CommonStyledOutlinedButton>
				);
			}
		}
	];

	return (
		<div>
			{isLoadingLiquidityList ? (
				<SectionLoading />
			) : liquidityList && liquidityList.length > 0 ? (
				<Table
					height='auto'
					fontSize={14}
					columns={columns}
					rows={liquidityList}
					rowsRender={
						<div
							className='grid grid-cols-2 sm:grid-cols-1 gap-2'
							style={{ background: theme.palette.background.default }}
						>
							{liquidityList.map((item, liquidityIndex) => {
								return (
									<Paper key={liquidityIndex} className='space-y-1 px-3 py-4'>
										<div className='flex items-center justify-between'>
											<div>
												{columns
													.find(column => column.key === 'LPs')
													?.format(item)}
											</div>
											<div>
												{columns
													.find(column => column.key === 'op')
													?.format(item)}
											</div>
										</div>
										{columns
											.filter(column => !['LPs', 'op'].includes(column.key))
											.map((column, columnIndex) => {
												return (
													<Cell
														label={column.label}
														value={column.format(item)}
														key={columnIndex}
													/>
												);
											})}
									</Paper>
								);
							})}
						</div>
					}
				/>
			) : (
				<NoData size='large'>
					<Trans>No available rewards to claim</Trans>
				</NoData>
			)}
		</div>
	);
}
