import { useEffect, useState } from 'react';

import {
	MAX_PAGE_SIZE,
	Order_Open_Type_V1,
	Order_Status_V1,
	Side,
	Transaction_Status,
	Transaction_Type
} from 'config/constants';
import { useAccount } from 'wagmi';

import { useAllTokens } from 'hooks/useAllTokens';
import { toNumber } from 'lodash';
import { OrderId, TokenInfo } from 'types';
import {
	getTransactionTypeForLimit,
	getTransactionTypeForProfitLoss,
	multipliedBy
} from 'utils';

import { useMyOrdersQuery, useOrdersStatusLazyQuery } from './__generated_trade__/types-and-hooks';

export interface IOrderItem {
	id: string;
	// pool id
	poolId: string;
	type: Order_Open_Type_V1;
	/*
		针对Limit订单
		LongLimitOrder = 'LongLimitOrder',
		ShortLimitOrder = 'ShortLimitOrder',
		针对止盈止损订单
		CreateTakeProfitPosition = 'CreateTakeProfitPosition',
		CreateTakeProfitPartial = 'CreateTakeProfitPartial',
		CreateStopLossPosition = 'CreateTakeProfitPosition',
		CreateStopLossPartial = 'CreateTakeProfitPartial',
	*/
	transactionType: Transaction_Type;
	// current tx hash
	txHash: string;
	// created tx hash
	createdHash: string;
	updatedHash: string;
	// executed tx hash
	executedHash: string;
	// cancelled tx hash
	cancelledHash: string;
	// pool Position index
	index: string;
	// type
	side: Side;
	// user
	account: string;
	// orders add
	triggerAbove: boolean;
	// orders by $
	liquidityDelta: string;
	// pay
	marginDelta: string;
	// trigger price
	triggerMarketPrice: string;
	// acceptable price
	acceptableTradePrice: string;
	// mark Price
	indexPrice: string;
	baseToken: TokenInfo;
	createdTimestamp: string | number;
	cancelledTimestamp: string | number;
	executedTimestamp: string | number;
	updatedTimestamp: string | number;
	lastOperationTimestamp: string | number;
	// Created  Executed Cancelled
	status?: Transaction_Status;
	orderStatus?: Order_Status_V1;
	positionAmount?: string;
	sizeDelta?: string;
	expired?: boolean;
}

export function useMyOrdersGraph(poolId?: string) {
	const [loading, setLoading] = useState<boolean>(true);
	const [allOrderList, setAllOrderList] = useState<Array<IOrderItem> | null>(null);
	const [orderList, setOrderList] = useState<Array<IOrderItem> | null>(null);
	const [ordersMap, setOrdersMap] = useState<Map<
		string,
		Array<IOrderItem>
	> | null>(null);
	const { address } = useAccount();
	const allTokens = useAllTokens();
	const {
		data,
		loading: isLoading,
		refetch
	} = useMyOrdersQuery({
		variables: {
			account: address,
			first: MAX_PAGE_SIZE
		},
		skip: !address
	});

	useEffect(() => {
		if (!data || !data.orders) {
			return;
		}

		const allOrderList = [] as Array<IOrderItem>;
		const orderList = [] as Array<IOrderItem>;
		const ordersMap = new Map<string, Array<IOrderItem>>();

		data.orders.forEach(item => {
			allOrderList.push({
				...item,
				orderStatus: item.status as Order_Status_V1,
			} as unknown as IOrderItem);
			if (poolId && item.pool.id !== poolId) {
				return;
			}
			const transactionType =
				item.type === Order_Open_Type_V1.Increase
					? getTransactionTypeForLimit(item.side)
					: getTransactionTypeForProfitLoss(
							item.triggerAbove,
							item.side,
							item.sizeDelta
					  );

			const orderStatus = item.status as Order_Status_V1;

			const orderItem = {
				...item,
				id: item.id as OrderId,
				transactionType,
				txHash:
					orderStatus === Order_Status_V1.Created
						? item.createdHash
						: orderStatus === Order_Status_V1.Cancelled
						? item.cancelledHash
						: item.executedHash,
				poolId: item.pool.id,
				liquidityDelta: multipliedBy(item.sizeDelta, item.triggerMarketPrice),
				indexPrice: item.pool.token.price,
				baseToken: allTokens.get(item.pool.token.id),
				expired: false,
				orderStatus
			} as IOrderItem;

			orderList.push(orderItem);

			const target = ordersMap.get(orderItem.poolId);
			if (!target) {
				ordersMap.set(orderItem.poolId, [orderItem]);
			} else {
				target.push(orderItem);
			}
		});

		setAllOrderList(allOrderList);
		setOrdersMap(ordersMap);
		setOrderList(orderList);
	}, [data, allTokens, poolId]);

	useEffect(() => {
		if (!isLoading) {
			setLoading(isLoading);
		}
	}, [isLoading]);

	return {
		allOrderList,
		orderList,
		ordersMap,
		loading,
		refetch
	};
}

export function useOrdersStatusLazyGraph() {
	const [fetch] = useOrdersStatusLazyQuery();
	return { fetch };
}


