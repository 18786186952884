import React, { useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Box } from '@mui/material';

const BlinkTag: React.FC<
	React.PropsWithChildren<{
		blinkKey: string;
		bgColor: string;
		activeColor: string;
		hidden?: boolean;
	}>
> = ({ blinkKey, activeColor, bgColor, hidden = false, children }) => {
	const [isChanged, setIsChanged] = useState(false);
	const [invisible, setInvisible] = useState(true);
	const [bgColorState, setBgColorState] = useState(bgColor);
	const [childrenState, setChildrenState] = useState(children);

	useUpdateEffect(() => {
		setIsChanged(true);
		setBgColorState(activeColor);
		setInvisible(false);
		setChildrenState(children);

		setTimeout(() => {
			setIsChanged(false);
			setBgColorState(bgColor);
			setChildrenState(children);
		}, 1000);
		setTimeout(() => setInvisible(true), 1500);
	}, [blinkKey]);

	return (
		(!hidden || !invisible) && (
			<Box
				sx={{
					borderRadius: '4px',
					background: bgColorState,
					transition: !isChanged && 'background 1s ease-out'
				}}
			>
				{hidden ? childrenState : children}
			</Box>
		)
	);
};

export default BlinkTag;
