import React, { useMemo } from 'react';

import { Typography } from '@mui/material';
import classNames from 'classnames';
import Decimal from 'decimal.js';

import { Mark } from 'types';
import { div, formatNumber, multipliedBy } from 'utils';

import StyledSlider from 'components/Common/StyledSlider';

export default React.memo(function MarksSlider({
	leverage,
	marks,
	max,
	min = 1,
	type = 'dark',
	onChangeLeverage,
	onClickLabel
}: {
	leverage: number | string;
	marks: Array<Mark>;
	max: number;
	min?: number;
	type?: 'dark' | 'light';
	onChangeLeverage: (value: number) => void;
	onClickLabel: (value: number) => void;
}) {
	const stepTransform = useMemo(() => {
		return div(100, marks.length - 1);
	}, [marks]);

	const onHandleChangeLeverage = (
		event: Event,
		newValue: number | number[]
	) => {
		onChangeLeverage(newValue as number);
	};
	function valueLabelFormat(value: number) {
		return new Decimal(value).isInt() ? value : formatNumber(value);
	}
	return (
		<div className='pl-0.25 pr-2'>
			<StyledSlider
				value={Number(leverage)}
				min={Number(min)}
				max={Number(max)}
				step={1}
				onChange={onHandleChangeLeverage}
				marks={marks}
				type={type}
				valueLabelDisplay='auto'
				valueLabelFormat={valueLabelFormat}
			/>
			<div className='-ml-0.5 -mr-1 -mt-2 relative h-4'>
				{marks.map((mark: Mark, index) => (
					<Typography
						className={classNames('absolute top-0 cursor-pointer')}
						component='span'
						key={mark.value}
						color='text.secondary'
						onClick={() => onClickLabel(mark.value)}
						sx={{
							left: `${multipliedBy(stepTransform, index)}%`,
							fontSize: '12px',
							transform:
								index === 0
									? 'scale(0.9)'
									: index === marks.length - 1
									? 'translate(-100%, 0) scale(0.9)'
									: 'translate(-50%, 0) scale(0.9)'
						}}
					>
						{mark.label}
					</Typography>
				))}
			</div>
		</div>
	);
});
