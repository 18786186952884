import { FormControlLabel, FormControlLabelProps, styled } from "@mui/material";

export const StyledFormControlLabel = styled(FormControlLabel)<FormControlLabelProps>(
	({ theme }) => ({
		marginRight: 0,
    width: 'calc(100% + 12px)',
    '& .MuiFormControlLabel-label': {
      flex: 1,
    },
    // '& .MuiTypography-root': {
    //   color: theme.palette.text.primary
    // }
	})
);