import { useMemo, useEffect} from 'react';

import { Trans } from '@lingui/macro';
import { Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import classNames from 'classnames';
import { DAYS_YEAR ,
	Stake_Type,
	Transaction_Status,
	Transaction_Type
} from 'config/constants';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { earnStakeBaseState } from 'state/earn/stake/slice';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { div, multipliedBy, plus } from 'utils';

import TippingUnderline from '../../../components/TippingUnderline';
import StakeAppTokenList from './StakeAppTokenList';
import Summary from './Summary'
import Divider from '@mui/material/Divider';


import { TransactionRecord } from 'entities/TransactionRecord';
import { useManageTransactions } from 'hooks/useAccountTransactions';
import { cloneDeep, intersectionWith } from 'lodash';
import { selectTransactionRecordList } from 'state/records/selector';
import {
	IStakeDetail,
	RecordStakeAppToken,
	RecordUnStakeAppToken
} from 'types';


export function RemainingDurationTipping({
	className
}: {
	className?: string;
}) {
	const theme = useTheme();
	const { isMatchMobile } = useAppBreakpoints();
	return (
		<Box
			component='span'
			className={classNames('flex justify-end items-center', className)}
		>
			<TippingUnderline
				tooltip={
					<div className=''>
						<Trans>
							The first 7 days of the next lock-up period are the &#39;unlocking
							period&#39;, during which you can freely unlock your assets.
							Missing this period will result in a re-locking for the same
							duration.
						</Trans>
						<Typography
							component='a'
							variant='body2'
							target={isMatchMobile ? '_self' : '_blank'}
							href='https://docs.equation.org/whitepaper/tokenomics/equ-equation'
							sx={{
								'&:hover': {
									color: theme.palette.primary.main
								}
							}}
							className='ml-1 text-xs inline cursor-pointer underline underline-offset-2 decoration-1'
						>
							<Trans>Learn more</Trans> &gt;&gt;
						</Typography>
					</div>
				}
				label={<Trans>Unlockable In</Trans>}
			/>
		</Box>
	);
}

function converStakeDetail(txRecord: TransactionRecord) {
	const record = txRecord.record as RecordStakeAppToken;
	return {
		id: txRecord.hash,
		type: Stake_Type.EQU,
		perShareGrowthX64: '0',
		multiplier: record.multiplier,
		period: record.period,
		duration: record.period,
		stakeTxHash: txRecord.hash,
		stakedAmount: record.amount,
		enableUnstake: false,
		claimableReward: '0',
		status: Transaction_Status.Pending
	} as IStakeDetail;
}

export default function StakeList() {
	const { appTokenUsdPrice } = useAppSelector(txBaseState);

	const { stakeStatistic, stakeList, maxMultiplier } =
		useAppSelector(earnStakeBaseState);
	const transactionRecordList = useAppSelector(selectTransactionRecordList);

	const { removeTransaction } = useManageTransactions();

	const { stakeAppTokenRecords, unStakeAppTokenRecords } = useMemo(() => {
		const stakeAppTokenRecords = transactionRecordList.filter(
			(tx: TransactionRecord) => {
				return tx.type === Transaction_Type.StakeAppToken;
			}
		);
		const unStakeAppTokenRecords = transactionRecordList.filter(
			(tx: TransactionRecord) => {
				return tx.type === Transaction_Type.UnStakeAppToken;
			}
		);
		return {
			stakeAppTokenRecords,
			unStakeAppTokenRecords
		};
	}, [transactionRecordList]);

	// 同步质押记录
	const mergedStakeList0 = useMemo(() => {
		if (stakeList && stakeAppTokenRecords) {
			const convertedList = stakeAppTokenRecords.map(record => {
				return converStakeDetail(record);
			});
			return convertedList.concat(stakeList);
		}
		if (stakeList) {
			return stakeList;
		}
		if (stakeAppTokenRecords || stakeAppTokenRecords.length > 0) {
			return stakeAppTokenRecords.map(record => {
				return converStakeDetail(record);
			});
		}
	}, [stakeList, stakeAppTokenRecords]);

	// 同步解除质押记录, 将解除质押EQU的记录，进行Pending更新
	const mergedStakeList = useMemo(() => {
		if (!unStakeAppTokenRecords || unStakeAppTokenRecords.length === 0) {
			return mergedStakeList0;
		}
		const mergedStakeList0Cache = cloneDeep(mergedStakeList0);
		const results = intersectionWith(
			mergedStakeList0Cache,
			unStakeAppTokenRecords,
			(current: IStakeDetail, txRecord: TransactionRecord) => {
				return (
					current.stakeID ===
					(txRecord.record as RecordUnStakeAppToken)?.tokenId
				);
			}
		);
		results.forEach((item: IStakeDetail) => {
			item.status = Transaction_Status.Pending;
		});
		return mergedStakeList0Cache;
	}, [mergedStakeList0, unStakeAppTokenRecords]);

	// 1. 同步移除，质押EQU的记录
	useEffect(() => {
		if (
			!stakeList ||
			!stakeAppTokenRecords ||
			stakeAppTokenRecords.length === 0
		) {
			return;
		}
		const results = intersectionWith(
			stakeAppTokenRecords,
			stakeList,
			(txRecord, current: IStakeDetail) => {
				return txRecord.hash === current.stakeTxHash;
			}
		);

		results.forEach((item: TransactionRecord) => {
			removeTransaction(item);
		});
	}, [stakeAppTokenRecords, stakeList]);

	// 2. 同步移除，解除质押EQU的记录
	useEffect(() => {
		if (
			!stakeList ||
			!unStakeAppTokenRecords ||
			unStakeAppTokenRecords.length === 0
		) {
			return;
		}
		unStakeAppTokenRecords.forEach((txRecord: TransactionRecord) => {
			const record = txRecord.record as RecordUnStakeAppToken;
			// 在质押列表中寻找是否具有和本地记录一致的目标数据
			// 如果没有，则说明这条移除的记录已经同步完成
			const target = stakeList.find(item => item.stakeID === record.tokenId);
			if (!target) {
				removeTransaction(txRecord);
			}
		});
	}, [unStakeAppTokenRecords, stakeList]);

	const equAPR = useMemo(() => {
		if (!stakeStatistic || !appTokenUsdPrice || !maxMultiplier) {
			return '';
		}
		const {
			stakeReward24h,
			liquidityStakedAmountWithMultiplier,
			equStakedAmountWithMultiplier
		} = stakeStatistic;
		const _percent = div(
			equStakedAmountWithMultiplier,
			plus(liquidityStakedAmountWithMultiplier, equStakedAmountWithMultiplier)
		);
		return multipliedBy(
			multipliedBy(
				div(
					multipliedBy(stakeReward24h, _percent),
					multipliedBy(appTokenUsdPrice, equStakedAmountWithMultiplier)
				),
				maxMultiplier
			),
			DAYS_YEAR
		);
	}, [stakeStatistic, appTokenUsdPrice, maxMultiplier]);

	return (
		<Box className='underLg:space-y-2 -mx-4 underLg:mx-0'>
			<Summary 
				equAPR={equAPR}
				stakedList={mergedStakeList}
			/>
			<Divider className='sm:hidden md:hidden' />
			<div className='underLg:space-y-2'>
				<StakeAppTokenList equAPR={equAPR} stakedList={mergedStakeList}/>
			</div>
		</Box>
	);
}
