/* eslint-disable import/no-extraneous-dependencies */
import { ReactElement, useEffect, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

import {
	ApolloClient,
	ApolloProvider,
	DefaultOptions,
	HttpLink,
	InMemoryCache,
	concat
} from '@apollo/client';
import setupLocatorUI from '@locator/runtime';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { Hash } from '@wagmi/core';
import { DEFAULT_DOCUMENT_TITLE, PageMap, Version } from 'config/constants';
import { GRAPH_API_URL } from 'config/env';
import { ApolloClientsProvider } from 'context/ApolloClientsContext';
import moment from 'moment';
import {
	SnackbarOrigin,
	SnackbarProvider,
	SnackbarProviderProps
} from 'notistack';
import VConsole from 'vconsole';
import { useAccount } from 'wagmi';

import ApolloLinkTimeout from 'apollo-link-timeout';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCurrentChain } from 'hooks/useCurrentChain';
import useRouterRedirect from 'hooks/useRouterRedirect';
import Layout from 'layouts';
import { globalBaseState } from 'state/global/slice';
import GlobalUpdater from 'state/global/updater';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import RecordsUpdater from 'state/records/updater';
import { setCurrentVersion, settingBaseState } from 'state/setting/slice';
import TradeUpdater from 'state/trade/updater';
import TxUpdater from 'state/tx/updater';
import store from 'store2';

import GlobalComponents from 'components/Modal/GlobalComponents';
import TransactionSnackbar from 'components/TransactionSnackbar';

import Routes from './route';
import { getTheme } from './theme';
import { changeThemeForHtml } from './utils';

declare module 'notistack' {
	interface VariantOverrides {
		TransactionSnackbar: {
			description: string;
			txHash: Hash | undefined;
			type: string;
			more?: string;
		};
	}
}

const SnackbarProviderComponent = (props: SnackbarProviderProps) => {
	const { isMatchMobile } = useAppBreakpoints();
	const anchorOrigin = useMemo(() => {
		if (isMatchMobile) {
			return {
				vertical: 'top',
				horizontal: 'center'
			} as SnackbarOrigin;
		}
		return {
			vertical: 'top',
			horizontal: 'right'
		} as SnackbarOrigin;
	}, [isMatchMobile]);

	return (
		<SnackbarProvider
			maxSnack={5}
			Components={{
				TransactionSnackbar
			}}
			autoHideDuration={8000}
			dense={true}
			anchorOrigin={anchorOrigin}
		>
			{props.children}
		</SnackbarProvider>
	);
};

function Updaters() {
	return (
		<>
			<GlobalUpdater />
			<TxUpdater />
			<TradeUpdater />
			<RecordsUpdater />
		</>
	);
}

export default function FullApp(): ReactElement {
	const dispatch = useAppDispatch();
	const { theme } = useAppSelector(globalBaseState);
	const { currentVersion } = useAppSelector(settingBaseState);
	const { currentChainId } = useCurrentChain();

	useRouterRedirect();

	useEffect(() => {
		changeThemeForHtml(theme);
	}, [theme]);

	useEffect(() => {
		if (currentVersion !== Version.V1) {
			dispatch(setCurrentVersion(Version.V1));
		}
	}, [currentVersion]);

	const createThemeMode = useMemo(() => {
		return getTheme(theme);
	}, [theme, getTheme]);
	const modeTheme = createTheme(createThemeMode);

	const httpLink = useMemo(() => {
		return new HttpLink({
			uri: GRAPH_API_URL[currentChainId].Trade
		});
	}, [currentChainId]);

	const httpLinkTradeV2 = useMemo(() => {
		return new HttpLink({
			uri: GRAPH_API_URL[currentChainId].TradeV2
		});
	}, [currentChainId]);

	const httpLinkReferrals = useMemo(() => {
		return new HttpLink({
			uri: GRAPH_API_URL[currentChainId].Referral
		});
	}, [currentChainId]);

	const httpLinkStats = useMemo(() => {
		return new HttpLink({
			uri: GRAPH_API_URL[currentChainId].Stats
		});
	}, [currentChainId]);

	const httpLinkStatsV2 = useMemo(() => {
		return new HttpLink({
			uri: GRAPH_API_URL[currentChainId].StatsV2
		});
	}, [currentChainId]);

	const defaultOptions: DefaultOptions = useMemo(() => {
		return {
			watchQuery: { fetchPolicy: 'no-cache', errorPolicy: 'ignore' },
			query: { fetchPolicy: 'no-cache', errorPolicy: 'all' }
		};
	}, []);

	const timeoutLink = new ApolloLinkTimeout(30000);

	const clientTrade = useMemo(() => {
		return new ApolloClient({
			link: concat(timeoutLink, httpLink),
			cache: new InMemoryCache(),
			defaultOptions
		});
	}, [httpLink]);

	const clientTradeV2 = useMemo(() => {
		return new ApolloClient({
			link: concat(timeoutLink, httpLinkTradeV2),
			cache: new InMemoryCache(),
			defaultOptions
		});
	}, [httpLinkTradeV2]);

	const clientReferral = useMemo(() => {
		return new ApolloClient({
			link: concat(timeoutLink, httpLinkReferrals),
			cache: new InMemoryCache(),
			defaultOptions
		});
	}, [httpLinkReferrals]);

	const clientStats = useMemo(() => {
		return new ApolloClient({
			link: concat(timeoutLink, httpLinkStats),
			cache: new InMemoryCache(),
			defaultOptions
		});
	}, [httpLinkStats]);

	const clientStatsV2 = useMemo(() => {
		return new ApolloClient({
			link: concat(timeoutLink, httpLinkStatsV2),
			cache: new InMemoryCache(),
			defaultOptions
		});
	}, [httpLinkStatsV2]);

	if (import.meta.env.MODE === 'development' && isMobile) {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		// const vConsole = new VConsole();
	}
	useEffect(() => {
		if (
			!store.get('LOCATOR_OPTIONS') ||
			(store.get('LOCATOR_OPTIONS') as { showInfto?: boolean }).showInfto
		) {
			store.set('LOCATOR_OPTIONS', { showIntro: false });
			if (import.meta.env.MODE === 'development') {
				setupLocatorUI();
			}
		} else if (import.meta.env.MODE === 'development') {
			setupLocatorUI();
		}
	}, []);

	const location = useLocation();

	useEffect(() => {
		if (PageMap.some(item => location.pathname.startsWith(item.path))) {
			const currentRoute = PageMap.find(item =>
				location.pathname.startsWith(item.path)
			);
			document.title = `${currentRoute.title} | ${DEFAULT_DOCUMENT_TITLE}`;
		}
	}, [location]);
	const { address } = useAccount();
	useEffect(() => {
		if (address) {
			window.gtag('event', 'user_address', {
				address: `/${address}`
			});
		}
	}, [address]);
	useEffect(() => {
		window.gtag('event', 'user_address', {
			zone: moment.tz.guess()
		});
	}, [moment.tz.guess()]);

	return (
		<ThemeProvider theme={modeTheme}>
			<ApolloProvider client={clientTrade}>
				<ApolloClientsProvider
					clients={{
						clientTrade,
						clientReferral,
						clientStats,
						clientTradeV2,
						clientStatsV2
					}}
				>
					<SnackbarProviderComponent>
						<Layout>
							<Updaters />
							<GlobalComponents />
							<Routes />
						</Layout>
					</SnackbarProviderComponent>
				</ApolloClientsProvider>
			</ApolloProvider>
		</ThemeProvider>
	);
}
