import { useEffect, useMemo, useState } from 'react';

import { QUOTE_USD_PRECISION, Side } from 'config/constants';
import Decimal from 'decimal.js';
import {
	useFarmClaimDetailsV2Request,
	useMultiTokensV2,
	usePoolsRequest,
	useTokensPriceV2
} from 'fetch/useRequest';
import moment from 'moment/moment';
import { useAccount } from 'wagmi';

import { UtilHelper } from 'entities/UtilHelper';
import { useAllTokens } from 'hooks/useAllTokens';
import { isEmpty, isEqual, sortBy } from 'lodash';
import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import {
	IGlobalFundingRateSample,
	IGlobalLiquidityPosition,
	IGlobalPosition,
	IPoolItem,
	IPriceState
} from 'types';
import {
	catchFn,
	div,
	formatRate,
	formatUnits,
	isZero,
	minus,
	multipliedBy,
	neg,
	plus,
	toDecimalPlaces
} from 'utils';

import { LiquidityPositionUtil } from '../entities/LiquidityPositionUtil';
import { FundingRateUtil } from '../entities/V2/FundingRateUtil';
import useMarketGraph from '../graphql/useMarketGraph';
import { Reward_Type } from '../pages/Earn/types';
import { txBaseState } from '../state/tx/slice';

export function usePoolsFetch() {
	const allTokens = useAllTokens();
	const { address } = useAccount();
	const { appTokenUsdPrice } = useAppSelector(txBaseState);

	const [loading, setLoading] = useState<boolean>(true);
	const [poolList, setPoolList] = useState<Array<IPoolItem>>([]);
	const [poolMap, setPoolMap] = useState<Map<string, IPoolItem> | null>(null);

	const { data: dataPoolsGraph, isInit } = useMarketGraph();
	const { data: detailsList } = useFarmClaimDetailsV2Request(
		address,
		Reward_Type.V2_LIQUIDITY
	);

	const { data, isLoading, refetch } = usePoolsRequest();

	const tokenAddresses = useMemo(() => {
		if (!data || !data?.markets) {
			return null;
		}
		return data.markets.map(pool => pool.address);
	}, [data]);

	const { tokensPrice } = useTokensPriceV2(tokenAddresses);
	const { tokensMultiPrice } = useMultiTokensV2(tokenAddresses);

	useEffect(() => {
		if (!isLoading) {
			setLoading(isLoading);
		}
	}, [isLoading]);

	useEffect(() => {
		if (
			isEmpty(data) ||
			isEmpty(tokensPrice) ||
			isEmpty(tokensMultiPrice) ||
			isInit
		) {
			return;
		}
		const map = new Map<string, IPoolItem>();
		const { markets } = data;
		const list = [] as Array<IPoolItem>;
		markets.forEach(item => {
			const tokenPriceItem = tokensPrice?.overviews?.find(
				(tokenItem: any) => tokenItem.address === item.address
			);

			const tokenMultiPriceItem = tokensMultiPrice?.marketPrices?.find(
				(tokenItem: any) => tokenItem.address === item.address
			);

			if (!tokenPriceItem || !tokenMultiPriceItem) {
				return;
			}

			const { liquidationVertexIndex, maxPriceImpactLiquidity, vertices } =
				item.marketConfig.priceConfig;

			const { referralDiscountRate, tradingFeeRate } =
				item.marketConfig.feeRateConfig;

			const {
				interestRate,
				maxFundingRate,
				liquidationExecutionFee,
				liquidationFeeRatePerPosition,
				liquidationFeeRatePerLiquidityPosition,
				maxLeveragePerPosition,
				maxLeveragePerLiquidityPosition,
				minMarginPerLiquidityPosition,
				minMarginPerPosition
			} = item.marketConfig.baseConfig;

			const id = item.address.toLowerCase();
			const baseToken = allTokens.get(id);

			const liquidityRewardPerDay =
				detailsList?.find(item => item.pool.toLowerCase() === id)
					?.daily_emission_amount || 0;

			if (!baseToken) {
				return;
			}

			const _globalLiquidityPosition = item.globalLiquidityPosition;
			const globalLiquidityPosition = {
				..._globalLiquidityPosition,
				tokenVertices: vertices
			} as IGlobalLiquidityPosition;

			const { longSize, shortSize, lastAdjustFundingRateTime } =
				item.globalPosition;
			const positions = multipliedBy(Math.max(longSize, shortSize), 2);

			const _poolNow = dataPoolsGraph.markets.find(
				innerItem => innerItem.id === id
			);
			const _poolBefore = dataPoolsGraph.past.find(
				innerItem => innerItem.id === id
			);
			const _poolBefore24h = dataPoolsGraph.dayPast.find(
				innerItem => innerItem.id === id
			);
			const volumeUsd = _poolNow?.volumeUSD || 0;
			const volume = _poolNow.volume || 0;

			let volume24h = _poolNow?.volumeUSD || 0;
			let volumeValue24h = volume;
			let fee7d = _poolNow?.globalLiquidityPosition.fee || 0;
			let fundingFee7d = _poolNow?.globalLiquidityPosition.fundingFee || 0;
			if (_poolBefore24h) {
				volume24h = minus(volume24h, _poolBefore24h.volumeUSD);
				volumeValue24h = minus(volumeValue24h, _poolBefore24h.volume);
			}
			if (_poolBefore) {
				fee7d = minus(fee7d, _poolBefore.globalLiquidityPosition.fee);
				fundingFee7d = minus(
					fundingFee7d,
					_poolBefore.globalLiquidityPosition.fundingFee
				);
			}
			const feeAvgApr = catchFn(() => {
				if (isZero(item.globalLiquidityPosition.liquidity)) {
					return 0;
				}
				return LiquidityPositionUtil.calculateApr(
					div(fee7d, 7),
					item.globalLiquidityPosition.liquidity,
					item.globalLiquidityPosition.avgLeverage || 0
				);
			}, '0');
			const fundingFeeAvgApr = catchFn(() => {
				if (isZero(item.globalLiquidityPosition.liquidity)) {
					return 0;
				}
				return LiquidityPositionUtil.calculateApr(
					div(fundingFee7d, 7),
					item.globalLiquidityPosition.liquidity,
					item.globalLiquidityPosition.avgLeverage || 0
				);
			}, '0');
			const rewardAvgApr = catchFn(() => {
				if (isZero(item.globalLiquidityPosition.liquidity)) {
					return 0;
				}
				return LiquidityPositionUtil.calculateApr(
					multipliedBy(appTokenUsdPrice, liquidityRewardPerDay),
					item.globalLiquidityPosition.liquidity,
					item.globalLiquidityPosition.avgLeverage || 0
				);
			}, '0');
			const liquidityAvgApr = new Decimal(feeAvgApr)
				.plus(fundingFeeAvgApr)
				.plus(rewardAvgApr)
				.toString();

			const feeMaxApr = catchFn(() => {
				if (isZero(item.globalLiquidityPosition.liquidity)) {
					return 0;
				}
				return LiquidityPositionUtil.calculateApr(
					div(fee7d, 7),
					item.globalLiquidityPosition.liquidity,
					maxLeveragePerLiquidityPosition
				);
			}, '0');
			const fundingFeeMaxApr = catchFn(() => {
				if (isZero(item.globalLiquidityPosition.liquidity)) {
					return 0;
				}
				return LiquidityPositionUtil.calculateApr(
					div(fundingFee7d, 7),
					item.globalLiquidityPosition.liquidity,
					maxLeveragePerLiquidityPosition
				);
			}, '0');
			const rewardMaxApr = catchFn(() => {
				if (isZero(item.globalLiquidityPosition.liquidity)) {
					return 0;
				}
				return LiquidityPositionUtil.calculateApr(
					multipliedBy(appTokenUsdPrice, liquidityRewardPerDay),
					item.globalLiquidityPosition.liquidity,
					maxLeveragePerLiquidityPosition
				);
			}, '0');
			const liquidityMaxApr = new Decimal(feeMaxApr)
				.plus(fundingFeeMaxApr)
				.plus(rewardMaxApr)
				.toString();

			const { sampleCount, cumulativePremiumRateX96 } =
				item.globalFundingRateSample;
			const _lastAdjustFundingRateTime = new Date(
				lastAdjustFundingRateTime
			).getTime();
			const isValidSample = moment(_lastAdjustFundingRateTime).isSame(
				moment(),
				'hours'
			);
			const sample = {
				lastAdjustFundingRateTime: isValidSample
					? _lastAdjustFundingRateTime / 1000
					: moment().set({ minute: 0, seconds: 0 }).unix().toString(),
				sampleCount: isValidSample ? sampleCount : '0',
				cumulativePremiumRateX96: isValidSample ? cumulativePremiumRateX96 : '0'
			} as IGlobalFundingRateSample;

			const {
				premiumRateX96,
				pendingVertexIndex,
				priceVertices,
				liquidationBufferNetSizes,
				indexPriceUsedX96
			} = item.priceState;

			const _priceVertices = priceVertices.map((item, index: number) => {
				return {
					id: item.id as string,
					premiumRateX96: item.premiumRateX96 as string,
					premiumRate: formatRate(vertices[index].premiumRate),
					size: item.size as string
				};
			});

			const priceState = {
				...item.priceState,
				maxPriceImpactLiquidity,
				premiumRateX96,
				pendingVertexIndex,
				liquidationVertexIndex,
				currentVertexIndex: UtilHelper.computeCurrentVertexIndex(
					globalLiquidityPosition.netSize,
					_priceVertices
				),
				priceVertices: _priceVertices,
				liquidationBufferNetSizes: liquidationBufferNetSizes,
				indexPriceUsedX96,
				indexPriceX96: tokenMultiPriceItem?.indexPriceX96
			} as IPriceState;

			const fundingRate = catchFn(() => {
				return FundingRateUtil.caculateFundingRate(
					sample,
					globalLiquidityPosition,
					priceState,
					interestRate || 0,
					maxFundingRate || 0
				);
			}, '');

			const balanceRate = catchFn(() => {
				let totalNetSize = plus(
					globalLiquidityPosition?.netSize,
					globalLiquidityPosition?.liquidationBufferNetSize
				);
				totalNetSize =
					globalLiquidityPosition?.side === Side.LONG
						? neg(totalNetSize)
						: totalNetSize;
				const _globalNetLiquidity = toDecimalPlaces(
					multipliedBy(totalNetSize, tokenMultiPriceItem?.indexPrice),
					QUOTE_USD_PRECISION
				);
				if (isZero(globalLiquidityPosition?.liquidity)) {
					return '0';
				}
				return div(_globalNetLiquidity, globalLiquidityPosition?.liquidity);
			}, '0');

			const poolItem = {
				...tokenPriceItem,
				...tokenMultiPriceItem,
				index_price: tokenMultiPriceItem?.indexPrice,
				index_price_x96: tokenMultiPriceItem?.indexPriceX96,
				market_price: tokenMultiPriceItem?.marketPrice,
				market_price_x96: tokenMultiPriceItem?.marketPriceX96,
				max_index_price: tokenMultiPriceItem?.maxIndexPrice,
				max_index_price_x96: tokenMultiPriceItem?.maxIndexPriceX96,
				max_market_price: tokenMultiPriceItem?.maxMarketPrice,
				max_market_price_x96: tokenMultiPriceItem?.maxMarketPriceX96,
				min_index_price: tokenMultiPriceItem?.minIndexPrice,
				min_index_price_x96: tokenMultiPriceItem?.minIndexPriceX96,
				min_market_price: tokenMultiPriceItem?.minMarketPrice,
				min_market_price_x96: tokenMultiPriceItem?.minMarketPriceX96,
				baseSymbol: item.symbol,
				address: id,
				id,
				baseToken,
				balanceRate,
				price: tokenMultiPriceItem?.marketPrice,
				tradingFeeRate: formatRate(tradingFeeRate),
				maxPriceImpactLiquidity,
				volume24h,
				volumeUSD: volumeUsd,
				volumeValue24h,
				fee7d,
				fundingFee7d,
				liquidityRewardPerDay,
				feeAvgApr,
				fundingFeeAvgApr,
				rewardAvgApr,
				liquidityAvgApr,
				feeMaxApr,
				fundingFeeMaxApr,
				rewardMaxApr,
				liquidityMaxApr,
				positions,
				priceChange: tokenPriceItem?.priceChangeRate,
				marketPrice: tokenMultiPriceItem?.marketPrice,
				indexPrice: tokenMultiPriceItem?.indexPrice,
				globalPosition: {
					...item.globalPosition,
					fundingRate
				} as IGlobalPosition,
				globalLiquidityPosition,
				sample,
				priceState,
				maxPriceX96: tokenMultiPriceItem?.maxIndexPriceX96,
				referralDiscountRate: formatRate(referralDiscountRate),
				longSize,
				shortSize,
				liquidationExecutionFee: formatUnits(
					liquidationExecutionFee,
					QUOTE_USD_PRECISION
				),
				liquidationFeeRatePerPosition: formatRate(
					liquidationFeeRatePerPosition
				),
				liquidationFeeRatePerLiquidityPosition: formatRate(
					liquidationFeeRatePerLiquidityPosition
				),

				maxLeveragePerPosition,
				maxLeveragePerLiquidityPosition,
				minMarginPerLiquidityPosition: formatUnits(
					minMarginPerLiquidityPosition,
					QUOTE_USD_PRECISION
				),
				minMarginPerPosition: formatUnits(
					minMarginPerPosition,
					QUOTE_USD_PRECISION
				),
				globalUnrealizedLossMetrics: item.globalUnrealizedLossMetrics
			} as IPoolItem;

			map.set(id, poolItem);

			list.push(poolItem);
		});

		const sortList = sortBy(list, o => {
			return o.baseToken.sort;
		});

		if (!isEqual(poolList, sortList)) {
			setPoolList(sortList as Array<IPoolItem>);
			setPoolMap(map);
		}
	}, [
		data,
		allTokens,
		tokensPrice,
		tokensMultiPrice,
		dataPoolsGraph,
		detailsList
	]);

	return {
		poolList,
		poolMap,
		loading,
		refetchPools: refetch
	};
}
