import { Reducer, createSlice } from '@reduxjs/toolkit';
import {
	DEFAULT_BALANCE_IMPACT_FEE_RATE,
	DEFAULT_LOCALE,
	DEFAULT_SETTING_SLIPPAGE,
	Impact_Type,
	Price_Benchmark,
	Version
} from 'config/constants';

import { RootState } from 'state';

interface StateProps {
	slippage: string;
	impactFeeRate: string;
	balanceType: Impact_Type;
	slippageType: Impact_Type;
	activeLocale: string;
	checkedTradeLeverage: boolean;
	tradeLeverage: number;
	checkedLiquidityLeverage: boolean;
	liquidityLeverage: number;
	priceBenchmark: Price_Benchmark;
	takeProfitStopLossTis: null;
	takeProfitStopLossModifyTis: boolean;
	onlyCurrent: boolean;
	onlyUsedMarkets: boolean;
	onlyUsedPools: boolean;
	currentVersion: Version;
	tvInterval: any;
}

const initialState: StateProps = {
	slippage: DEFAULT_SETTING_SLIPPAGE,
	impactFeeRate: DEFAULT_BALANCE_IMPACT_FEE_RATE,
	balanceType: Impact_Type.Auto,
	slippageType: Impact_Type.Auto,
	activeLocale: DEFAULT_LOCALE,
	checkedTradeLeverage: true,
	tradeLeverage: 10,
	checkedLiquidityLeverage: true,
	liquidityLeverage: 10,
	priceBenchmark: Price_Benchmark.Mark,
	takeProfitStopLossTis: null,
	takeProfitStopLossModifyTis: false,
	onlyCurrent: true,
	onlyUsedMarkets: false,
	onlyUsedPools: false,
	currentVersion: Version.V1,
	tvInterval: {}
};

export const slice = createSlice({
	name: 'setting',
	initialState,
	reducers: {
		setSlippage(state, { payload }) {
			state.slippage = payload;
		},
		setImpactFeeRate(state, { payload }) {
			state.impactFeeRate = payload;
		},
		setBalanceType(state, { payload }) {
			state.balanceType = payload;
		},
		setSlippageType(state, { payload }) {
			state.slippageType = payload;
		},
		setActiveLocale(state, { payload }) {
			state.activeLocale = payload;
		},
		setCheckedTradeLeverage(state, { payload }) {
			state.checkedTradeLeverage = payload;
		},
		setTradeLeverage(state, { payload }) {
			state.tradeLeverage = payload;
		},
		setCheckedLiquidityLeverage(state, { payload }) {
			state.checkedLiquidityLeverage = payload;
		},
		setLiquidityLeverage(state, { payload }) {
			state.liquidityLeverage = payload;
		},
		setPriceBenchmark(state, { payload }) {
			state.priceBenchmark = payload;
		},
		setTakeProfitStopLossTis(state, { payload }) {
			state.takeProfitStopLossTis = payload;
		},
		setTakeProfitStopLossModifyTis(state, { payload }) {
			state.takeProfitStopLossModifyTis = payload;
		},
		setOnlyCurrent(state, { payload }) {
			state.onlyCurrent = payload;
		},
		setOnlyUsedMarkets(state, { payload }) {
			state.onlyUsedMarkets = payload;
		},
		setOnlyUsedPools(state, { payload }) {
			state.onlyUsedPools = payload;
		},
		setCurrentVersion(state, { payload }) {
			state.currentVersion = payload;
		},
		updateSelectedTVInterval(state, { payload }) {
			state.tvInterval = {
				...state.tvInterval,
				...payload
			};
		}
	}
});

export const {
	setSlippage,
	setImpactFeeRate,
	setTakeProfitStopLossTis,
	setTakeProfitStopLossModifyTis,
	setBalanceType,
	setSlippageType,
	setCheckedTradeLeverage,
	setTradeLeverage,
	setCheckedLiquidityLeverage,
	setLiquidityLeverage,
	setPriceBenchmark,
	setActiveLocale,
	setOnlyCurrent,
	setOnlyUsedMarkets,
	setOnlyUsedPools,
	setCurrentVersion,
	updateSelectedTVInterval
} = slice.actions;

export const settingBaseState = (state: RootState) => state.setting;

export default slice.reducer as Reducer<typeof initialState>;
