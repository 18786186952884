import { useCallback, useMemo } from 'react';

import { useAccount } from 'wagmi';

import _ from 'lodash';
import { addOrUpdateRecord, removeRecord } from 'state/records/slice';
import store from 'store2';

import { TransactionRecord } from '../entities/TransactionRecord';
import { globalBaseState } from '../state/global/slice';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { getLocalStorageKey, isLocalStorageFull } from '../utils';

export function useTransactionsLocalStorageKey() {
	const { chain } = useAppSelector(globalBaseState);
	const { address } = useAccount();

	return useMemo(() => {
		if (!chain || !address) {
			return '';
		}
		return getLocalStorageKey('transactions', [chain.name, address]);
	}, [chain, address]);
}

export function useManageTransactions() {
	const dispatch = useAppDispatch();
	const storeKey = useTransactionsLocalStorageKey();

	const storeTransactionList = useCallback(
		(list: any) => {
			if (!isLocalStorageFull()) {
				store.set(storeKey, list);
			} else {
				console.warn('isLocalStorageFull: true !!!');
			}
		},
		[storeKey]
	);

	const addTransaction = (record: TransactionRecord) => {
		console.log('addTransaction begin ...');
		const txList = store.get(storeKey, []);
		txList.push(record.tx);
		storeTransactionList(txList);
		dispatch(addOrUpdateRecord(record));
	};

	const updateTransaction = useCallback(
		(record: TransactionRecord) => {
			const txList = store.get(storeKey, []);
			const target = _.find(
				txList,
				item => item.hash === record.hash && item.type === record.type
			);
			if (target) {
				target.status = record.status;
				target.description = record.description;
				target.record = record.record;
				target.receipt = record.receipt;
				storeTransactionList(txList);
				dispatch(addOrUpdateRecord(record));
			}
		},
		[storeKey, storeTransactionList]
	);

	const removeTransaction = useCallback(
		(record: TransactionRecord) => {
			const txList = store.get(storeKey, []);
			_.remove(txList, (item: TransactionRecord) => item.hash === record.hash);
			storeTransactionList(txList);
			dispatch(removeRecord(record));
		},
		[storeKey, storeTransactionList]
	);

	return { updateTransaction, addTransaction, removeTransaction };
}
