import { useCallback, useMemo } from 'react';

import { PositionRouterV2ABI, positionRouterABI } from 'config/abis';
import {
	Contract_Write_Mode,
	DEFAULT_SETTING_SLIPPAGE,
	Side,
	Transaction_Type,
	Version
} from 'config/constants';
import { getContractAddress, getContractAddressV2 } from 'config/contracts';
import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { IPositionItem } from 'graphql/useMyPositionsGraph';
import { selectPositionMinExecutionFee } from 'state/global/selector';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { RecordForAdjustMargin } from 'types';
import {
	formatNumber,
	isGreaterThan,
	isPositive,
	parseUnits,
	shortenSymbolNative,
	toDecimalPlaces
} from 'utils';

import { useComputeGasLimit } from './useComputeGasLimit';
import { useCheckLogin, useCurrentChain } from './useCurrentChain';
import { useSendTransaction } from './useSendTransaction';
import { usePriceRangesByTolerance } from './useSlippageTolerance';

export function useSubmitAdjustMarginTrade(
	targetPosition: IPositionItem,
	transactionType: Transaction_Type,
	quoteAmount: string,
	sizeDelta: string,
	slippage: string | number = DEFAULT_SETTING_SLIPPAGE
) {
	const { quoteToken, quoteBalance } = useAppSelector(txBaseState);
	const positionMinExecutionFee = useAppSelector(selectPositionMinExecutionFee);
	const currentVersion = useAppSelector(state => state.setting.currentVersion);
	const { currentChainId } = useCurrentChain();
	const isLogin = useCheckLogin();
	const { address } = useAccount();

	const { baseToken, poolId, side, maxMarketPrice, minMarketPrice } =
		targetPosition;

	const { maxPrice: maxPriceForMax, exactInRate } = usePriceRangesByTolerance(
		maxMarketPrice,
		slippage
	);

	const { minPrice: minPriceForMin } = usePriceRangesByTolerance(
		minMarketPrice,
		slippage
	);

	const { args, record, description } = useMemo(() => {
		if (!isLogin || !baseToken || !quoteToken || !isPositive(quoteAmount)) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		if (
			(transactionType.startsWith(Transaction_Type.IncreaseMargin) ||
				transactionType.startsWith(Transaction_Type.IncreaseMarginV2)) &&
			isGreaterThan(quoteAmount, quoteBalance)
		) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		console.log('useSubmitAdjustMarginTrade begin ...');

		// 使用用户支付的初始保证金
		const marginDelta = parseUnits(quoteAmount, quoteToken.decimals);
		const _sizeDelta = toDecimalPlaces(sizeDelta, baseToken.decimals);
		// console.log(_sizeDelta);
		const formatsizeDelta = parseUnits(_sizeDelta, baseToken.decimals);

		const _acceptablePrice =
			transactionType.startsWith(Transaction_Type.IncreaseMargin) ||
			transactionType.startsWith(Transaction_Type.IncreaseMarginV2)
				? side === Side.LONG
					? maxPriceForMax
					: minPriceForMin
				: side === Side.LONG
				? minPriceForMin
				: maxPriceForMax;

		const args =
			transactionType.startsWith(Transaction_Type.IncreaseMargin) ||
			transactionType.startsWith(Transaction_Type.IncreaseMarginV2)
				? [poolId, side, marginDelta, formatsizeDelta, 0]
				: [poolId, side, marginDelta, formatsizeDelta, 0, address];

		const record = {
			transactionType: transactionType,
			pool: poolId,
			side,
			baseToken,
			quoteAmount,
			entryPrice: _acceptablePrice
		} as RecordForAdjustMargin;

		const description =
			transactionType.startsWith(Transaction_Type.IncreaseMargin) ||
			transactionType.startsWith(Transaction_Type.IncreaseMarginV2)
				? TransactionDescription.CreateIncreasePosition(
						shortenSymbolNative(baseToken, currentChainId),
						formatNumber(quoteAmount),
						side
				  )
				: TransactionDescription.CreateDecreasePosition(
						shortenSymbolNative(baseToken, currentChainId),
						formatNumber(quoteAmount),
						side
				  );

		return {
			args,
			record,
			description
		};
	}, [
		transactionType,
		poolId,
		isLogin,
		baseToken,
		quoteToken,
		quoteBalance,
		quoteAmount,
		side,
		minPriceForMin,
		maxPriceForMax,
		exactInRate,
		currentChainId
	]);

	const overrides = useMemo(() => {
		if (!address || !isPositive(positionMinExecutionFee)) {
			return undefined;
		}
		return {
			from: address,
			value: positionMinExecutionFee
		};
	}, [address, positionMinExecutionFee]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address:
				currentVersion === Version.V2
					? getContractAddressV2(currentChainId, 'PositionRouter')
					: getContractAddress(currentChainId, 'PositionRouter'),
			abi:
				currentVersion === Version.V2 ? PositionRouterV2ABI : positionRouterABI,
			functionName:
				transactionType.startsWith(Transaction_Type.IncreaseMargin) ||
				transactionType.startsWith(Transaction_Type.IncreaseMarginV2)
					? 'createIncreasePosition'
					: 'createDecreasePosition',
			args,
			overrides
		} as UseContractWriteConfig;
	}, [
		currentChainId,
		args,
		overrides,
		transactionType,
		currentVersion,
		PositionRouterV2ABI,
		positionRouterABI
	]);

	const { gasLimit } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirming, isConfirmed, error } =
		useSendTransaction(contractWriteArgs, gasLimit);

	return {
		onSubmitAdjust: useCallback(() => {
			if (!record) return;
			onSendTransaction(record, description);
		}, [transactionType, onSendTransaction, record, description]),
		isConfirming,
		isConfirmed,
		error
	};
}
