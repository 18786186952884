import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
  /** 8 bytes signed integer */
  Int8: any;
};

export type BlockChangedFilter = {
  number_gte: Scalars['Int'];
};

export type Block_Height = {
  hash?: InputMaybe<Scalars['Bytes']>;
  number?: InputMaybe<Scalars['Int']>;
  number_gte?: InputMaybe<Scalars['Int']>;
};

export type GlobalLiquidityPosition = {
  __typename?: 'GlobalLiquidityPosition';
  /** the entry price of the net position held by all LPs */
  entryPrice: Scalars['BigDecimal'];
  /** the entry price of the net position held by all LPs, as a Q64.96 */
  entryPriceX96: Scalars['BigInt'];
  /** pool address */
  id: Scalars['Bytes'];
  /** total liquidity held by all LPs */
  liquidity: Scalars['BigDecimal'];
  /** total margin held by all LPs */
  margin: Scalars['BigDecimal'];
  /** the size of the net position held by all LPs */
  netSize: Scalars['BigDecimal'];
  /** pointer to pool */
  pool: Pool;
  /** realized profit of the pool */
  realizedProfit: Scalars['BigDecimal'];
  /** the accumulated realized profit growth per liquidity unit */
  realizedProfitGrowth: Scalars['BigDecimal'];
  /** the accumulated realized profit growth per liquidity unit, as a Q192.64 */
  realizedProfitGrowthX64: Scalars['BigInt'];
  /** the total liquidity of the risk buffer fund */
  riskBufferFundLiquidity: Scalars['BigDecimal'];
  /** the side of the position, 1 for long and 2 for short */
  side: Scalars['Int'];
  /** all trading fee of the pool */
  tradingFee: Scalars['BigDecimal'];
};

export type GlobalLiquidityPosition1D = {
  __typename?: 'GlobalLiquidityPosition1D';
  /** format: <pool>:<timestamp> */
  id: Scalars['ID'];
  /** leverage ratio of liquidity positions */
  leverage: Scalars['BigDecimal'];
  /** pointer to pool */
  pool: Pool;
  /** the total liquidity of the risk buffer fund */
  riskBufferFundLiquidity: Scalars['BigDecimal'];
  /** timestamp of this statistical data */
  ts: Scalars['BigInt'];
};

export type GlobalLiquidityPosition1D_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<GlobalLiquidityPosition1D_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  leverage?: InputMaybe<Scalars['BigDecimal']>;
  leverage_gt?: InputMaybe<Scalars['BigDecimal']>;
  leverage_gte?: InputMaybe<Scalars['BigDecimal']>;
  leverage_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  leverage_lt?: InputMaybe<Scalars['BigDecimal']>;
  leverage_lte?: InputMaybe<Scalars['BigDecimal']>;
  leverage_not?: InputMaybe<Scalars['BigDecimal']>;
  leverage_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<GlobalLiquidityPosition1D_Filter>>>;
  pool?: InputMaybe<Scalars['String']>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars['String']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_ends_with?: InputMaybe<Scalars['String']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_gt?: InputMaybe<Scalars['String']>;
  pool_gte?: InputMaybe<Scalars['String']>;
  pool_in?: InputMaybe<Array<Scalars['String']>>;
  pool_lt?: InputMaybe<Scalars['String']>;
  pool_lte?: InputMaybe<Scalars['String']>;
  pool_not?: InputMaybe<Scalars['String']>;
  pool_not_contains?: InputMaybe<Scalars['String']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_not_in?: InputMaybe<Array<Scalars['String']>>;
  pool_not_starts_with?: InputMaybe<Scalars['String']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pool_starts_with?: InputMaybe<Scalars['String']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']>;
  riskBufferFundLiquidity?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundLiquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundLiquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundLiquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  riskBufferFundLiquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundLiquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundLiquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundLiquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  ts?: InputMaybe<Scalars['BigInt']>;
  ts_gt?: InputMaybe<Scalars['BigInt']>;
  ts_gte?: InputMaybe<Scalars['BigInt']>;
  ts_in?: InputMaybe<Array<Scalars['BigInt']>>;
  ts_lt?: InputMaybe<Scalars['BigInt']>;
  ts_lte?: InputMaybe<Scalars['BigInt']>;
  ts_not?: InputMaybe<Scalars['BigInt']>;
  ts_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum GlobalLiquidityPosition1D_OrderBy {
  Id = 'id',
  Leverage = 'leverage',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolPairString = 'pool__pairString',
  PoolVolume = 'pool__volume',
  PoolVolumeUsd = 'pool__volumeUSD',
  RiskBufferFundLiquidity = 'riskBufferFundLiquidity',
  Ts = 'ts'
}

export type GlobalLiquidityPosition_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<GlobalLiquidityPosition_Filter>>>;
  entryPrice?: InputMaybe<Scalars['BigDecimal']>;
  entryPriceX96?: InputMaybe<Scalars['BigInt']>;
  entryPriceX96_gt?: InputMaybe<Scalars['BigInt']>;
  entryPriceX96_gte?: InputMaybe<Scalars['BigInt']>;
  entryPriceX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryPriceX96_lt?: InputMaybe<Scalars['BigInt']>;
  entryPriceX96_lte?: InputMaybe<Scalars['BigInt']>;
  entryPriceX96_not?: InputMaybe<Scalars['BigInt']>;
  entryPriceX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  entryPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  entryPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  entryPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  entryPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  entryPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  entryPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  liquidity?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin?: InputMaybe<Scalars['BigDecimal']>;
  margin_gt?: InputMaybe<Scalars['BigDecimal']>;
  margin_gte?: InputMaybe<Scalars['BigDecimal']>;
  margin_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin_lt?: InputMaybe<Scalars['BigDecimal']>;
  margin_lte?: InputMaybe<Scalars['BigDecimal']>;
  margin_not?: InputMaybe<Scalars['BigDecimal']>;
  margin_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  netSize?: InputMaybe<Scalars['BigDecimal']>;
  netSize_gt?: InputMaybe<Scalars['BigDecimal']>;
  netSize_gte?: InputMaybe<Scalars['BigDecimal']>;
  netSize_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  netSize_lt?: InputMaybe<Scalars['BigDecimal']>;
  netSize_lte?: InputMaybe<Scalars['BigDecimal']>;
  netSize_not?: InputMaybe<Scalars['BigDecimal']>;
  netSize_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<GlobalLiquidityPosition_Filter>>>;
  pool?: InputMaybe<Scalars['String']>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars['String']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_ends_with?: InputMaybe<Scalars['String']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_gt?: InputMaybe<Scalars['String']>;
  pool_gte?: InputMaybe<Scalars['String']>;
  pool_in?: InputMaybe<Array<Scalars['String']>>;
  pool_lt?: InputMaybe<Scalars['String']>;
  pool_lte?: InputMaybe<Scalars['String']>;
  pool_not?: InputMaybe<Scalars['String']>;
  pool_not_contains?: InputMaybe<Scalars['String']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_not_in?: InputMaybe<Array<Scalars['String']>>;
  pool_not_starts_with?: InputMaybe<Scalars['String']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pool_starts_with?: InputMaybe<Scalars['String']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']>;
  realizedProfit?: InputMaybe<Scalars['BigDecimal']>;
  realizedProfitGrowth?: InputMaybe<Scalars['BigDecimal']>;
  realizedProfitGrowthX64?: InputMaybe<Scalars['BigInt']>;
  realizedProfitGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  realizedProfitGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  realizedProfitGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  realizedProfitGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  realizedProfitGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  realizedProfitGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  realizedProfitGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  realizedProfitGrowth_gt?: InputMaybe<Scalars['BigDecimal']>;
  realizedProfitGrowth_gte?: InputMaybe<Scalars['BigDecimal']>;
  realizedProfitGrowth_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  realizedProfitGrowth_lt?: InputMaybe<Scalars['BigDecimal']>;
  realizedProfitGrowth_lte?: InputMaybe<Scalars['BigDecimal']>;
  realizedProfitGrowth_not?: InputMaybe<Scalars['BigDecimal']>;
  realizedProfitGrowth_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  realizedProfit_gt?: InputMaybe<Scalars['BigDecimal']>;
  realizedProfit_gte?: InputMaybe<Scalars['BigDecimal']>;
  realizedProfit_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  realizedProfit_lt?: InputMaybe<Scalars['BigDecimal']>;
  realizedProfit_lte?: InputMaybe<Scalars['BigDecimal']>;
  realizedProfit_not?: InputMaybe<Scalars['BigDecimal']>;
  realizedProfit_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  riskBufferFundLiquidity?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundLiquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundLiquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundLiquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  riskBufferFundLiquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundLiquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundLiquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundLiquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  side?: InputMaybe<Scalars['Int']>;
  side_gt?: InputMaybe<Scalars['Int']>;
  side_gte?: InputMaybe<Scalars['Int']>;
  side_in?: InputMaybe<Array<Scalars['Int']>>;
  side_lt?: InputMaybe<Scalars['Int']>;
  side_lte?: InputMaybe<Scalars['Int']>;
  side_not?: InputMaybe<Scalars['Int']>;
  side_not_in?: InputMaybe<Array<Scalars['Int']>>;
  tradingFee?: InputMaybe<Scalars['BigDecimal']>;
  tradingFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  tradingFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  tradingFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  tradingFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  tradingFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  tradingFee_not?: InputMaybe<Scalars['BigDecimal']>;
  tradingFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum GlobalLiquidityPosition_OrderBy {
  EntryPrice = 'entryPrice',
  EntryPriceX96 = 'entryPriceX96',
  Id = 'id',
  Liquidity = 'liquidity',
  Margin = 'margin',
  NetSize = 'netSize',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolPairString = 'pool__pairString',
  PoolVolume = 'pool__volume',
  PoolVolumeUsd = 'pool__volumeUSD',
  RealizedProfit = 'realizedProfit',
  RealizedProfitGrowth = 'realizedProfitGrowth',
  RealizedProfitGrowthX64 = 'realizedProfitGrowthX64',
  RiskBufferFundLiquidity = 'riskBufferFundLiquidity',
  Side = 'side',
  TradingFee = 'tradingFee'
}

export type GlobalPosition = {
  __typename?: 'GlobalPosition';
  /**
   * The change in funding rate, a positive value means longs pay shorts,
   * when a negative value means shorts pay longs
   */
  fundingRate: Scalars['BigDecimal'];
  /**
   * The change in funding rate, a positive value means longs pay shorts,
   * when a negative value means shorts pay longs, as a Q160.96
   */
  fundingRateX96: Scalars['BigInt'];
  /** pool address */
  id: Scalars['Bytes'];
  /** the timestamp of the last funding rate adjustment */
  lastAdjustFundingRateTime: Scalars['BigInt'];
  /** the sum of all position liquidity */
  liquidity: Scalars['BigDecimal'];
  /** the average price of all long positions */
  longAvgPrice: Scalars['BigDecimal'];
  /** the funding rate growth per unit of long position sizes */
  longFundingRateGrowth: Scalars['BigDecimal'];
  /** the funding rate growth per unit of long position sizes, as a Q96.96 */
  longFundingRateGrowthX96: Scalars['BigInt'];
  /** the quantity of long positions */
  longQuantity: Scalars['BigInt'];
  /** the sum of long position sizes */
  longSize: Scalars['BigDecimal'];
  /** the sum of all position margins */
  margin: Scalars['BigDecimal'];
  /** value of open contracts of the pool */
  openInterest: Scalars['BigDecimal'];
  /** value of open long contracts of the pool */
  openInterestLong: Scalars['BigDecimal'];
  /** value of open short contracts of the pool */
  openInterestShort: Scalars['BigDecimal'];
  /** pointer to pool */
  pool: Pool;
  /** the average price of all long positions */
  shortAvgPrice: Scalars['BigDecimal'];
  /** the funding rate growth per unit of short position sizes */
  shortFundingRateGrowth: Scalars['BigDecimal'];
  /** the funding rate growth per unit of short position sizes, as a Q96.96 */
  shortFundingRateGrowthX96: Scalars['BigInt'];
  /** the quantity of short positions */
  shortQuantity: Scalars['BigInt'];
  /** the sum of short position sizes */
  shortSize: Scalars['BigDecimal'];
};

export type GlobalPosition1D = {
  __typename?: 'GlobalPosition1D';
  /** format: <pool>:<timestamp> */
  id: Scalars['ID'];
  /** leverage ratio of positions */
  leverage: Scalars['BigDecimal'];
  /** the sum of long position sizes */
  longSize: Scalars['BigDecimal'];
  /** value of open contracts of the pool */
  openInterest: Scalars['BigDecimal'];
  /** value of open long contracts of the pool */
  openInterestLong: Scalars['BigDecimal'];
  /** value of open short contracts of the pool */
  openInterestShort: Scalars['BigDecimal'];
  /** pointer to pool */
  pool: Pool;
  /** the sum of short position sizes */
  shortSize: Scalars['BigDecimal'];
  /** timestamp of this statistical data */
  ts: Scalars['BigInt'];
};

export type GlobalPosition1D_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<GlobalPosition1D_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  leverage?: InputMaybe<Scalars['BigDecimal']>;
  leverage_gt?: InputMaybe<Scalars['BigDecimal']>;
  leverage_gte?: InputMaybe<Scalars['BigDecimal']>;
  leverage_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  leverage_lt?: InputMaybe<Scalars['BigDecimal']>;
  leverage_lte?: InputMaybe<Scalars['BigDecimal']>;
  leverage_not?: InputMaybe<Scalars['BigDecimal']>;
  leverage_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  longSize?: InputMaybe<Scalars['BigDecimal']>;
  longSize_gt?: InputMaybe<Scalars['BigDecimal']>;
  longSize_gte?: InputMaybe<Scalars['BigDecimal']>;
  longSize_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  longSize_lt?: InputMaybe<Scalars['BigDecimal']>;
  longSize_lte?: InputMaybe<Scalars['BigDecimal']>;
  longSize_not?: InputMaybe<Scalars['BigDecimal']>;
  longSize_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  openInterest?: InputMaybe<Scalars['BigDecimal']>;
  openInterestLong?: InputMaybe<Scalars['BigDecimal']>;
  openInterestLong_gt?: InputMaybe<Scalars['BigDecimal']>;
  openInterestLong_gte?: InputMaybe<Scalars['BigDecimal']>;
  openInterestLong_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  openInterestLong_lt?: InputMaybe<Scalars['BigDecimal']>;
  openInterestLong_lte?: InputMaybe<Scalars['BigDecimal']>;
  openInterestLong_not?: InputMaybe<Scalars['BigDecimal']>;
  openInterestLong_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  openInterestShort?: InputMaybe<Scalars['BigDecimal']>;
  openInterestShort_gt?: InputMaybe<Scalars['BigDecimal']>;
  openInterestShort_gte?: InputMaybe<Scalars['BigDecimal']>;
  openInterestShort_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  openInterestShort_lt?: InputMaybe<Scalars['BigDecimal']>;
  openInterestShort_lte?: InputMaybe<Scalars['BigDecimal']>;
  openInterestShort_not?: InputMaybe<Scalars['BigDecimal']>;
  openInterestShort_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  openInterest_gt?: InputMaybe<Scalars['BigDecimal']>;
  openInterest_gte?: InputMaybe<Scalars['BigDecimal']>;
  openInterest_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  openInterest_lt?: InputMaybe<Scalars['BigDecimal']>;
  openInterest_lte?: InputMaybe<Scalars['BigDecimal']>;
  openInterest_not?: InputMaybe<Scalars['BigDecimal']>;
  openInterest_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<GlobalPosition1D_Filter>>>;
  pool?: InputMaybe<Scalars['String']>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars['String']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_ends_with?: InputMaybe<Scalars['String']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_gt?: InputMaybe<Scalars['String']>;
  pool_gte?: InputMaybe<Scalars['String']>;
  pool_in?: InputMaybe<Array<Scalars['String']>>;
  pool_lt?: InputMaybe<Scalars['String']>;
  pool_lte?: InputMaybe<Scalars['String']>;
  pool_not?: InputMaybe<Scalars['String']>;
  pool_not_contains?: InputMaybe<Scalars['String']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_not_in?: InputMaybe<Array<Scalars['String']>>;
  pool_not_starts_with?: InputMaybe<Scalars['String']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pool_starts_with?: InputMaybe<Scalars['String']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']>;
  shortSize?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_gt?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_gte?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  shortSize_lt?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_lte?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_not?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  ts?: InputMaybe<Scalars['BigInt']>;
  ts_gt?: InputMaybe<Scalars['BigInt']>;
  ts_gte?: InputMaybe<Scalars['BigInt']>;
  ts_in?: InputMaybe<Array<Scalars['BigInt']>>;
  ts_lt?: InputMaybe<Scalars['BigInt']>;
  ts_lte?: InputMaybe<Scalars['BigInt']>;
  ts_not?: InputMaybe<Scalars['BigInt']>;
  ts_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum GlobalPosition1D_OrderBy {
  Id = 'id',
  Leverage = 'leverage',
  LongSize = 'longSize',
  OpenInterest = 'openInterest',
  OpenInterestLong = 'openInterestLong',
  OpenInterestShort = 'openInterestShort',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolPairString = 'pool__pairString',
  PoolVolume = 'pool__volume',
  PoolVolumeUsd = 'pool__volumeUSD',
  ShortSize = 'shortSize',
  Ts = 'ts'
}

export type GlobalPosition_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<GlobalPosition_Filter>>>;
  fundingRate?: InputMaybe<Scalars['BigDecimal']>;
  fundingRateX96?: InputMaybe<Scalars['BigInt']>;
  fundingRateX96_gt?: InputMaybe<Scalars['BigInt']>;
  fundingRateX96_gte?: InputMaybe<Scalars['BigInt']>;
  fundingRateX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  fundingRateX96_lt?: InputMaybe<Scalars['BigInt']>;
  fundingRateX96_lte?: InputMaybe<Scalars['BigInt']>;
  fundingRateX96_not?: InputMaybe<Scalars['BigInt']>;
  fundingRateX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  fundingRate_gt?: InputMaybe<Scalars['BigDecimal']>;
  fundingRate_gte?: InputMaybe<Scalars['BigDecimal']>;
  fundingRate_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fundingRate_lt?: InputMaybe<Scalars['BigDecimal']>;
  fundingRate_lte?: InputMaybe<Scalars['BigDecimal']>;
  fundingRate_not?: InputMaybe<Scalars['BigDecimal']>;
  fundingRate_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  lastAdjustFundingRateTime?: InputMaybe<Scalars['BigInt']>;
  lastAdjustFundingRateTime_gt?: InputMaybe<Scalars['BigInt']>;
  lastAdjustFundingRateTime_gte?: InputMaybe<Scalars['BigInt']>;
  lastAdjustFundingRateTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  lastAdjustFundingRateTime_lt?: InputMaybe<Scalars['BigInt']>;
  lastAdjustFundingRateTime_lte?: InputMaybe<Scalars['BigInt']>;
  lastAdjustFundingRateTime_not?: InputMaybe<Scalars['BigInt']>;
  lastAdjustFundingRateTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  liquidity?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  longAvgPrice?: InputMaybe<Scalars['BigDecimal']>;
  longAvgPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  longAvgPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  longAvgPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  longAvgPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  longAvgPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  longAvgPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  longAvgPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  longFundingRateGrowth?: InputMaybe<Scalars['BigDecimal']>;
  longFundingRateGrowthX96?: InputMaybe<Scalars['BigInt']>;
  longFundingRateGrowthX96_gt?: InputMaybe<Scalars['BigInt']>;
  longFundingRateGrowthX96_gte?: InputMaybe<Scalars['BigInt']>;
  longFundingRateGrowthX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  longFundingRateGrowthX96_lt?: InputMaybe<Scalars['BigInt']>;
  longFundingRateGrowthX96_lte?: InputMaybe<Scalars['BigInt']>;
  longFundingRateGrowthX96_not?: InputMaybe<Scalars['BigInt']>;
  longFundingRateGrowthX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  longFundingRateGrowth_gt?: InputMaybe<Scalars['BigDecimal']>;
  longFundingRateGrowth_gte?: InputMaybe<Scalars['BigDecimal']>;
  longFundingRateGrowth_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  longFundingRateGrowth_lt?: InputMaybe<Scalars['BigDecimal']>;
  longFundingRateGrowth_lte?: InputMaybe<Scalars['BigDecimal']>;
  longFundingRateGrowth_not?: InputMaybe<Scalars['BigDecimal']>;
  longFundingRateGrowth_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  longQuantity?: InputMaybe<Scalars['BigInt']>;
  longQuantity_gt?: InputMaybe<Scalars['BigInt']>;
  longQuantity_gte?: InputMaybe<Scalars['BigInt']>;
  longQuantity_in?: InputMaybe<Array<Scalars['BigInt']>>;
  longQuantity_lt?: InputMaybe<Scalars['BigInt']>;
  longQuantity_lte?: InputMaybe<Scalars['BigInt']>;
  longQuantity_not?: InputMaybe<Scalars['BigInt']>;
  longQuantity_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  longSize?: InputMaybe<Scalars['BigDecimal']>;
  longSize_gt?: InputMaybe<Scalars['BigDecimal']>;
  longSize_gte?: InputMaybe<Scalars['BigDecimal']>;
  longSize_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  longSize_lt?: InputMaybe<Scalars['BigDecimal']>;
  longSize_lte?: InputMaybe<Scalars['BigDecimal']>;
  longSize_not?: InputMaybe<Scalars['BigDecimal']>;
  longSize_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin?: InputMaybe<Scalars['BigDecimal']>;
  margin_gt?: InputMaybe<Scalars['BigDecimal']>;
  margin_gte?: InputMaybe<Scalars['BigDecimal']>;
  margin_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin_lt?: InputMaybe<Scalars['BigDecimal']>;
  margin_lte?: InputMaybe<Scalars['BigDecimal']>;
  margin_not?: InputMaybe<Scalars['BigDecimal']>;
  margin_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  openInterest?: InputMaybe<Scalars['BigDecimal']>;
  openInterestLong?: InputMaybe<Scalars['BigDecimal']>;
  openInterestLong_gt?: InputMaybe<Scalars['BigDecimal']>;
  openInterestLong_gte?: InputMaybe<Scalars['BigDecimal']>;
  openInterestLong_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  openInterestLong_lt?: InputMaybe<Scalars['BigDecimal']>;
  openInterestLong_lte?: InputMaybe<Scalars['BigDecimal']>;
  openInterestLong_not?: InputMaybe<Scalars['BigDecimal']>;
  openInterestLong_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  openInterestShort?: InputMaybe<Scalars['BigDecimal']>;
  openInterestShort_gt?: InputMaybe<Scalars['BigDecimal']>;
  openInterestShort_gte?: InputMaybe<Scalars['BigDecimal']>;
  openInterestShort_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  openInterestShort_lt?: InputMaybe<Scalars['BigDecimal']>;
  openInterestShort_lte?: InputMaybe<Scalars['BigDecimal']>;
  openInterestShort_not?: InputMaybe<Scalars['BigDecimal']>;
  openInterestShort_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  openInterest_gt?: InputMaybe<Scalars['BigDecimal']>;
  openInterest_gte?: InputMaybe<Scalars['BigDecimal']>;
  openInterest_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  openInterest_lt?: InputMaybe<Scalars['BigDecimal']>;
  openInterest_lte?: InputMaybe<Scalars['BigDecimal']>;
  openInterest_not?: InputMaybe<Scalars['BigDecimal']>;
  openInterest_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<GlobalPosition_Filter>>>;
  pool?: InputMaybe<Scalars['String']>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars['String']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_ends_with?: InputMaybe<Scalars['String']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_gt?: InputMaybe<Scalars['String']>;
  pool_gte?: InputMaybe<Scalars['String']>;
  pool_in?: InputMaybe<Array<Scalars['String']>>;
  pool_lt?: InputMaybe<Scalars['String']>;
  pool_lte?: InputMaybe<Scalars['String']>;
  pool_not?: InputMaybe<Scalars['String']>;
  pool_not_contains?: InputMaybe<Scalars['String']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_not_in?: InputMaybe<Array<Scalars['String']>>;
  pool_not_starts_with?: InputMaybe<Scalars['String']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pool_starts_with?: InputMaybe<Scalars['String']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']>;
  shortAvgPrice?: InputMaybe<Scalars['BigDecimal']>;
  shortAvgPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  shortAvgPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  shortAvgPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  shortAvgPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  shortAvgPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  shortAvgPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  shortAvgPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  shortFundingRateGrowth?: InputMaybe<Scalars['BigDecimal']>;
  shortFundingRateGrowthX96?: InputMaybe<Scalars['BigInt']>;
  shortFundingRateGrowthX96_gt?: InputMaybe<Scalars['BigInt']>;
  shortFundingRateGrowthX96_gte?: InputMaybe<Scalars['BigInt']>;
  shortFundingRateGrowthX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  shortFundingRateGrowthX96_lt?: InputMaybe<Scalars['BigInt']>;
  shortFundingRateGrowthX96_lte?: InputMaybe<Scalars['BigInt']>;
  shortFundingRateGrowthX96_not?: InputMaybe<Scalars['BigInt']>;
  shortFundingRateGrowthX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  shortFundingRateGrowth_gt?: InputMaybe<Scalars['BigDecimal']>;
  shortFundingRateGrowth_gte?: InputMaybe<Scalars['BigDecimal']>;
  shortFundingRateGrowth_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  shortFundingRateGrowth_lt?: InputMaybe<Scalars['BigDecimal']>;
  shortFundingRateGrowth_lte?: InputMaybe<Scalars['BigDecimal']>;
  shortFundingRateGrowth_not?: InputMaybe<Scalars['BigDecimal']>;
  shortFundingRateGrowth_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  shortQuantity?: InputMaybe<Scalars['BigInt']>;
  shortQuantity_gt?: InputMaybe<Scalars['BigInt']>;
  shortQuantity_gte?: InputMaybe<Scalars['BigInt']>;
  shortQuantity_in?: InputMaybe<Array<Scalars['BigInt']>>;
  shortQuantity_lt?: InputMaybe<Scalars['BigInt']>;
  shortQuantity_lte?: InputMaybe<Scalars['BigInt']>;
  shortQuantity_not?: InputMaybe<Scalars['BigInt']>;
  shortQuantity_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  shortSize?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_gt?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_gte?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  shortSize_lt?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_lte?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_not?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum GlobalPosition_OrderBy {
  FundingRate = 'fundingRate',
  FundingRateX96 = 'fundingRateX96',
  Id = 'id',
  LastAdjustFundingRateTime = 'lastAdjustFundingRateTime',
  Liquidity = 'liquidity',
  LongAvgPrice = 'longAvgPrice',
  LongFundingRateGrowth = 'longFundingRateGrowth',
  LongFundingRateGrowthX96 = 'longFundingRateGrowthX96',
  LongQuantity = 'longQuantity',
  LongSize = 'longSize',
  Margin = 'margin',
  OpenInterest = 'openInterest',
  OpenInterestLong = 'openInterestLong',
  OpenInterestShort = 'openInterestShort',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolPairString = 'pool__pairString',
  PoolVolume = 'pool__volume',
  PoolVolumeUsd = 'pool__volumeUSD',
  ShortAvgPrice = 'shortAvgPrice',
  ShortFundingRateGrowth = 'shortFundingRateGrowth',
  ShortFundingRateGrowthX96 = 'shortFundingRateGrowthX96',
  ShortQuantity = 'shortQuantity',
  ShortSize = 'shortSize'
}

export type LiquidityPosition = {
  __typename?: 'LiquidityPosition';
  /** the owner of the position */
  account: Scalars['Bytes'];
  /** the snapshot of GlobalLiquidityPosition.realizedProfitGrowthX64 at the time of opening the position */
  entryRealizedProfitGrowth: Scalars['BigDecimal'];
  /** the snapshot of GlobalLiquidityPosition.realizedProfitGrowthX64 at the time of opening the position, as a Q192.64 */
  entryRealizedProfitGrowthX64: Scalars['BigInt'];
  /** the snapshot of unrealized loss of LP at the time of opening the position */
  entryUnrealizedLoss: Scalars['BigDecimal'];
  /** format: <pool address>:<position id> */
  id: Scalars['ID'];
  /** the liquidity (value) of the position */
  liquidity: Scalars['BigDecimal'];
  /** the margin of the position */
  margin: Scalars['BigDecimal'];
  /** pointer to pool */
  pool: Pool;
  /** the position id */
  positionID: Scalars['BigInt'];
  /** the realized profit of the position */
  realizedProfit: Scalars['BigDecimal'];
};

export type LiquidityPosition_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['Bytes']>;
  account_contains?: InputMaybe<Scalars['Bytes']>;
  account_gt?: InputMaybe<Scalars['Bytes']>;
  account_gte?: InputMaybe<Scalars['Bytes']>;
  account_in?: InputMaybe<Array<Scalars['Bytes']>>;
  account_lt?: InputMaybe<Scalars['Bytes']>;
  account_lte?: InputMaybe<Scalars['Bytes']>;
  account_not?: InputMaybe<Scalars['Bytes']>;
  account_not_contains?: InputMaybe<Scalars['Bytes']>;
  account_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  and?: InputMaybe<Array<InputMaybe<LiquidityPosition_Filter>>>;
  entryRealizedProfitGrowth?: InputMaybe<Scalars['BigDecimal']>;
  entryRealizedProfitGrowthX64?: InputMaybe<Scalars['BigInt']>;
  entryRealizedProfitGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  entryRealizedProfitGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  entryRealizedProfitGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryRealizedProfitGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  entryRealizedProfitGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  entryRealizedProfitGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  entryRealizedProfitGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryRealizedProfitGrowth_gt?: InputMaybe<Scalars['BigDecimal']>;
  entryRealizedProfitGrowth_gte?: InputMaybe<Scalars['BigDecimal']>;
  entryRealizedProfitGrowth_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  entryRealizedProfitGrowth_lt?: InputMaybe<Scalars['BigDecimal']>;
  entryRealizedProfitGrowth_lte?: InputMaybe<Scalars['BigDecimal']>;
  entryRealizedProfitGrowth_not?: InputMaybe<Scalars['BigDecimal']>;
  entryRealizedProfitGrowth_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  entryUnrealizedLoss?: InputMaybe<Scalars['BigDecimal']>;
  entryUnrealizedLoss_gt?: InputMaybe<Scalars['BigDecimal']>;
  entryUnrealizedLoss_gte?: InputMaybe<Scalars['BigDecimal']>;
  entryUnrealizedLoss_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  entryUnrealizedLoss_lt?: InputMaybe<Scalars['BigDecimal']>;
  entryUnrealizedLoss_lte?: InputMaybe<Scalars['BigDecimal']>;
  entryUnrealizedLoss_not?: InputMaybe<Scalars['BigDecimal']>;
  entryUnrealizedLoss_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  liquidity?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin?: InputMaybe<Scalars['BigDecimal']>;
  margin_gt?: InputMaybe<Scalars['BigDecimal']>;
  margin_gte?: InputMaybe<Scalars['BigDecimal']>;
  margin_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin_lt?: InputMaybe<Scalars['BigDecimal']>;
  margin_lte?: InputMaybe<Scalars['BigDecimal']>;
  margin_not?: InputMaybe<Scalars['BigDecimal']>;
  margin_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<LiquidityPosition_Filter>>>;
  pool?: InputMaybe<Scalars['String']>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars['String']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_ends_with?: InputMaybe<Scalars['String']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_gt?: InputMaybe<Scalars['String']>;
  pool_gte?: InputMaybe<Scalars['String']>;
  pool_in?: InputMaybe<Array<Scalars['String']>>;
  pool_lt?: InputMaybe<Scalars['String']>;
  pool_lte?: InputMaybe<Scalars['String']>;
  pool_not?: InputMaybe<Scalars['String']>;
  pool_not_contains?: InputMaybe<Scalars['String']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_not_in?: InputMaybe<Array<Scalars['String']>>;
  pool_not_starts_with?: InputMaybe<Scalars['String']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pool_starts_with?: InputMaybe<Scalars['String']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']>;
  positionID?: InputMaybe<Scalars['BigInt']>;
  positionID_gt?: InputMaybe<Scalars['BigInt']>;
  positionID_gte?: InputMaybe<Scalars['BigInt']>;
  positionID_in?: InputMaybe<Array<Scalars['BigInt']>>;
  positionID_lt?: InputMaybe<Scalars['BigInt']>;
  positionID_lte?: InputMaybe<Scalars['BigInt']>;
  positionID_not?: InputMaybe<Scalars['BigInt']>;
  positionID_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  realizedProfit?: InputMaybe<Scalars['BigDecimal']>;
  realizedProfit_gt?: InputMaybe<Scalars['BigDecimal']>;
  realizedProfit_gte?: InputMaybe<Scalars['BigDecimal']>;
  realizedProfit_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  realizedProfit_lt?: InputMaybe<Scalars['BigDecimal']>;
  realizedProfit_lte?: InputMaybe<Scalars['BigDecimal']>;
  realizedProfit_not?: InputMaybe<Scalars['BigDecimal']>;
  realizedProfit_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum LiquidityPosition_OrderBy {
  Account = 'account',
  EntryRealizedProfitGrowth = 'entryRealizedProfitGrowth',
  EntryRealizedProfitGrowthX64 = 'entryRealizedProfitGrowthX64',
  EntryUnrealizedLoss = 'entryUnrealizedLoss',
  Id = 'id',
  Liquidity = 'liquidity',
  Margin = 'margin',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolPairString = 'pool__pairString',
  PoolVolume = 'pool__volume',
  PoolVolumeUsd = 'pool__volumeUSD',
  PositionId = 'positionID',
  RealizedProfit = 'realizedProfit'
}

export enum Operation {
  CreateLiquidity = 'CreateLiquidity',
  LiquidityLiquidation = 'LiquidityLiquidation',
  PositionLiquidation = 'PositionLiquidation',
  RemoveLiquidity = 'RemoveLiquidity',
  Trade = 'Trade'
}

export type OperationVolume1D = {
  __typename?: 'OperationVolume1D';
  /** format: <operation>:<timestamp> */
  id: Scalars['ID'];
  /** operation */
  operation: Operation;
  /** timestamp of this statistical data */
  ts: Scalars['BigInt'];
  /** trading volume of the operation in one day */
  volumeUSD: Scalars['BigDecimal'];
};

export type OperationVolume1D_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<OperationVolume1D_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  operation?: InputMaybe<Operation>;
  operation_in?: InputMaybe<Array<Operation>>;
  operation_not?: InputMaybe<Operation>;
  operation_not_in?: InputMaybe<Array<Operation>>;
  or?: InputMaybe<Array<InputMaybe<OperationVolume1D_Filter>>>;
  ts?: InputMaybe<Scalars['BigInt']>;
  ts_gt?: InputMaybe<Scalars['BigInt']>;
  ts_gte?: InputMaybe<Scalars['BigInt']>;
  ts_in?: InputMaybe<Array<Scalars['BigInt']>>;
  ts_lt?: InputMaybe<Scalars['BigInt']>;
  ts_lte?: InputMaybe<Scalars['BigInt']>;
  ts_not?: InputMaybe<Scalars['BigInt']>;
  ts_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  volumeUSD?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_lt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum OperationVolume1D_OrderBy {
  Id = 'id',
  Operation = 'operation',
  Ts = 'ts',
  VolumeUsd = 'volumeUSD'
}

/** Defines the order direction, either ascending or descending */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export enum Period {
  Daily = 'Daily',
  Hourly = 'Hourly'
}

export type Pool = {
  __typename?: 'Pool';
  globalLiquidityPosition: GlobalLiquidityPosition;
  globalLiquidityPosition1Ds: Array<GlobalLiquidityPosition1D>;
  globalPosition: GlobalPosition;
  globalPosition1Ds: Array<GlobalPosition1D>;
  /** pool address */
  id: Scalars['Bytes'];
  /** pair string of the pool that formed of token symbol and usd token symbol. eg: BTC/USDT */
  pairString: Scalars['String'];
  poolAPR1Ds: Array<PoolApr1D>;
  poolVolume1Ds: Array<PoolVolume1D>;
  /** base token */
  token: Token;
  /** all time volume */
  volume: Scalars['BigDecimal'];
  /** all time volume in derived USD */
  volumeUSD: Scalars['BigDecimal'];
};


export type PoolGlobalLiquidityPosition1DsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GlobalLiquidityPosition1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GlobalLiquidityPosition1D_Filter>;
};


export type PoolGlobalPosition1DsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GlobalPosition1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GlobalPosition1D_Filter>;
};


export type PoolPoolApr1DsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PoolApr1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PoolApr1D_Filter>;
};


export type PoolPoolVolume1DsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PoolVolume1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PoolVolume1D_Filter>;
};

export type PoolApr1D = {
  __typename?: 'PoolAPR1D';
  /** annual percentage rate */
  APR: Scalars['BigDecimal'];
  /** snapshot of entryRealizedProfitGrowth at the end of a day */
  endEntryRealizedProfitGrowth: Scalars['BigDecimal'];
  /** format: <pool>:<timestamp> */
  id: Scalars['ID'];
  /** pointer to pool */
  pool: Pool;
  /** snapshot of entryRealizedProfitGrowth at the start of a day */
  startEntryRealizedProfitGrowth: Scalars['BigDecimal'];
  /** timestamp of this statistical data */
  ts: Scalars['BigInt'];
};

export type PoolApr1D_Filter = {
  APR?: InputMaybe<Scalars['BigDecimal']>;
  APR_gt?: InputMaybe<Scalars['BigDecimal']>;
  APR_gte?: InputMaybe<Scalars['BigDecimal']>;
  APR_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  APR_lt?: InputMaybe<Scalars['BigDecimal']>;
  APR_lte?: InputMaybe<Scalars['BigDecimal']>;
  APR_not?: InputMaybe<Scalars['BigDecimal']>;
  APR_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PoolApr1D_Filter>>>;
  endEntryRealizedProfitGrowth?: InputMaybe<Scalars['BigDecimal']>;
  endEntryRealizedProfitGrowth_gt?: InputMaybe<Scalars['BigDecimal']>;
  endEntryRealizedProfitGrowth_gte?: InputMaybe<Scalars['BigDecimal']>;
  endEntryRealizedProfitGrowth_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  endEntryRealizedProfitGrowth_lt?: InputMaybe<Scalars['BigDecimal']>;
  endEntryRealizedProfitGrowth_lte?: InputMaybe<Scalars['BigDecimal']>;
  endEntryRealizedProfitGrowth_not?: InputMaybe<Scalars['BigDecimal']>;
  endEntryRealizedProfitGrowth_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<PoolApr1D_Filter>>>;
  pool?: InputMaybe<Scalars['String']>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars['String']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_ends_with?: InputMaybe<Scalars['String']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_gt?: InputMaybe<Scalars['String']>;
  pool_gte?: InputMaybe<Scalars['String']>;
  pool_in?: InputMaybe<Array<Scalars['String']>>;
  pool_lt?: InputMaybe<Scalars['String']>;
  pool_lte?: InputMaybe<Scalars['String']>;
  pool_not?: InputMaybe<Scalars['String']>;
  pool_not_contains?: InputMaybe<Scalars['String']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_not_in?: InputMaybe<Array<Scalars['String']>>;
  pool_not_starts_with?: InputMaybe<Scalars['String']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pool_starts_with?: InputMaybe<Scalars['String']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']>;
  startEntryRealizedProfitGrowth?: InputMaybe<Scalars['BigDecimal']>;
  startEntryRealizedProfitGrowth_gt?: InputMaybe<Scalars['BigDecimal']>;
  startEntryRealizedProfitGrowth_gte?: InputMaybe<Scalars['BigDecimal']>;
  startEntryRealizedProfitGrowth_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  startEntryRealizedProfitGrowth_lt?: InputMaybe<Scalars['BigDecimal']>;
  startEntryRealizedProfitGrowth_lte?: InputMaybe<Scalars['BigDecimal']>;
  startEntryRealizedProfitGrowth_not?: InputMaybe<Scalars['BigDecimal']>;
  startEntryRealizedProfitGrowth_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  ts?: InputMaybe<Scalars['BigInt']>;
  ts_gt?: InputMaybe<Scalars['BigInt']>;
  ts_gte?: InputMaybe<Scalars['BigInt']>;
  ts_in?: InputMaybe<Array<Scalars['BigInt']>>;
  ts_lt?: InputMaybe<Scalars['BigInt']>;
  ts_lte?: InputMaybe<Scalars['BigInt']>;
  ts_not?: InputMaybe<Scalars['BigInt']>;
  ts_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum PoolApr1D_OrderBy {
  Apr = 'APR',
  EndEntryRealizedProfitGrowth = 'endEntryRealizedProfitGrowth',
  Id = 'id',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolPairString = 'pool__pairString',
  PoolVolume = 'pool__volume',
  PoolVolumeUsd = 'pool__volumeUSD',
  StartEntryRealizedProfitGrowth = 'startEntryRealizedProfitGrowth',
  Ts = 'ts'
}

export type PoolVolume1D = {
  __typename?: 'PoolVolume1D';
  /** format: <pool>:<timestamp> */
  id: Scalars['ID'];
  /** liquidation value in one day */
  liquidations: Scalars['BigDecimal'];
  /** liquidation value of liquidity in one day */
  liquidityLiquidations: Scalars['BigDecimal'];
  /** pointer to pool */
  pool: Pool;
  /** protocol fee in one day */
  protocolFee: Scalars['BigDecimal'];
  /** timestamp of this statistical data */
  ts: Scalars['BigInt'];
  /** trading volume in one day */
  volume: Scalars['BigDecimal'];
  /** daily trading volume in derived USD */
  volumeUSD: Scalars['BigDecimal'];
};

export type PoolVolume1D_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PoolVolume1D_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  liquidations?: InputMaybe<Scalars['BigDecimal']>;
  liquidations_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidations_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidations_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidations_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidations_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidations_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidations_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidityLiquidations?: InputMaybe<Scalars['BigDecimal']>;
  liquidityLiquidations_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityLiquidations_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityLiquidations_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidityLiquidations_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityLiquidations_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityLiquidations_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidityLiquidations_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<PoolVolume1D_Filter>>>;
  pool?: InputMaybe<Scalars['String']>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars['String']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_ends_with?: InputMaybe<Scalars['String']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_gt?: InputMaybe<Scalars['String']>;
  pool_gte?: InputMaybe<Scalars['String']>;
  pool_in?: InputMaybe<Array<Scalars['String']>>;
  pool_lt?: InputMaybe<Scalars['String']>;
  pool_lte?: InputMaybe<Scalars['String']>;
  pool_not?: InputMaybe<Scalars['String']>;
  pool_not_contains?: InputMaybe<Scalars['String']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_not_in?: InputMaybe<Array<Scalars['String']>>;
  pool_not_starts_with?: InputMaybe<Scalars['String']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pool_starts_with?: InputMaybe<Scalars['String']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']>;
  protocolFee?: InputMaybe<Scalars['BigDecimal']>;
  protocolFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  protocolFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  protocolFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  protocolFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  protocolFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  protocolFee_not?: InputMaybe<Scalars['BigDecimal']>;
  protocolFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  ts?: InputMaybe<Scalars['BigInt']>;
  ts_gt?: InputMaybe<Scalars['BigInt']>;
  ts_gte?: InputMaybe<Scalars['BigInt']>;
  ts_in?: InputMaybe<Array<Scalars['BigInt']>>;
  ts_lt?: InputMaybe<Scalars['BigInt']>;
  ts_lte?: InputMaybe<Scalars['BigInt']>;
  ts_not?: InputMaybe<Scalars['BigInt']>;
  ts_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  volume?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_lt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volume_gt?: InputMaybe<Scalars['BigDecimal']>;
  volume_gte?: InputMaybe<Scalars['BigDecimal']>;
  volume_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volume_lt?: InputMaybe<Scalars['BigDecimal']>;
  volume_lte?: InputMaybe<Scalars['BigDecimal']>;
  volume_not?: InputMaybe<Scalars['BigDecimal']>;
  volume_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum PoolVolume1D_OrderBy {
  Id = 'id',
  Liquidations = 'liquidations',
  LiquidityLiquidations = 'liquidityLiquidations',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolPairString = 'pool__pairString',
  PoolVolume = 'pool__volume',
  PoolVolumeUsd = 'pool__volumeUSD',
  ProtocolFee = 'protocolFee',
  Ts = 'ts',
  Volume = 'volume',
  VolumeUsd = 'volumeUSD'
}

export type Pool_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Pool_Filter>>>;
  globalLiquidityPosition?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition1Ds_?: InputMaybe<GlobalLiquidityPosition1D_Filter>;
  globalLiquidityPosition_?: InputMaybe<GlobalLiquidityPosition_Filter>;
  globalLiquidityPosition_contains?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_contains_nocase?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_ends_with?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_ends_with_nocase?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_gt?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_gte?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_in?: InputMaybe<Array<Scalars['String']>>;
  globalLiquidityPosition_lt?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_lte?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_not?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_not_contains?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_not_contains_nocase?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_not_ends_with?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_not_in?: InputMaybe<Array<Scalars['String']>>;
  globalLiquidityPosition_not_starts_with?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_starts_with?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_starts_with_nocase?: InputMaybe<Scalars['String']>;
  globalPosition?: InputMaybe<Scalars['String']>;
  globalPosition1Ds_?: InputMaybe<GlobalPosition1D_Filter>;
  globalPosition_?: InputMaybe<GlobalPosition_Filter>;
  globalPosition_contains?: InputMaybe<Scalars['String']>;
  globalPosition_contains_nocase?: InputMaybe<Scalars['String']>;
  globalPosition_ends_with?: InputMaybe<Scalars['String']>;
  globalPosition_ends_with_nocase?: InputMaybe<Scalars['String']>;
  globalPosition_gt?: InputMaybe<Scalars['String']>;
  globalPosition_gte?: InputMaybe<Scalars['String']>;
  globalPosition_in?: InputMaybe<Array<Scalars['String']>>;
  globalPosition_lt?: InputMaybe<Scalars['String']>;
  globalPosition_lte?: InputMaybe<Scalars['String']>;
  globalPosition_not?: InputMaybe<Scalars['String']>;
  globalPosition_not_contains?: InputMaybe<Scalars['String']>;
  globalPosition_not_contains_nocase?: InputMaybe<Scalars['String']>;
  globalPosition_not_ends_with?: InputMaybe<Scalars['String']>;
  globalPosition_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  globalPosition_not_in?: InputMaybe<Array<Scalars['String']>>;
  globalPosition_not_starts_with?: InputMaybe<Scalars['String']>;
  globalPosition_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  globalPosition_starts_with?: InputMaybe<Scalars['String']>;
  globalPosition_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  or?: InputMaybe<Array<InputMaybe<Pool_Filter>>>;
  pairString?: InputMaybe<Scalars['String']>;
  pairString_contains?: InputMaybe<Scalars['String']>;
  pairString_contains_nocase?: InputMaybe<Scalars['String']>;
  pairString_ends_with?: InputMaybe<Scalars['String']>;
  pairString_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pairString_gt?: InputMaybe<Scalars['String']>;
  pairString_gte?: InputMaybe<Scalars['String']>;
  pairString_in?: InputMaybe<Array<Scalars['String']>>;
  pairString_lt?: InputMaybe<Scalars['String']>;
  pairString_lte?: InputMaybe<Scalars['String']>;
  pairString_not?: InputMaybe<Scalars['String']>;
  pairString_not_contains?: InputMaybe<Scalars['String']>;
  pairString_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pairString_not_ends_with?: InputMaybe<Scalars['String']>;
  pairString_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pairString_not_in?: InputMaybe<Array<Scalars['String']>>;
  pairString_not_starts_with?: InputMaybe<Scalars['String']>;
  pairString_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pairString_starts_with?: InputMaybe<Scalars['String']>;
  pairString_starts_with_nocase?: InputMaybe<Scalars['String']>;
  poolAPR1Ds_?: InputMaybe<PoolApr1D_Filter>;
  poolVolume1Ds_?: InputMaybe<PoolVolume1D_Filter>;
  token?: InputMaybe<Scalars['String']>;
  token_?: InputMaybe<Token_Filter>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_lt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volume_gt?: InputMaybe<Scalars['BigDecimal']>;
  volume_gte?: InputMaybe<Scalars['BigDecimal']>;
  volume_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volume_lt?: InputMaybe<Scalars['BigDecimal']>;
  volume_lte?: InputMaybe<Scalars['BigDecimal']>;
  volume_not?: InputMaybe<Scalars['BigDecimal']>;
  volume_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum Pool_OrderBy {
  GlobalLiquidityPosition = 'globalLiquidityPosition',
  GlobalLiquidityPosition1Ds = 'globalLiquidityPosition1Ds',
  GlobalLiquidityPositionEntryPrice = 'globalLiquidityPosition__entryPrice',
  GlobalLiquidityPositionEntryPriceX96 = 'globalLiquidityPosition__entryPriceX96',
  GlobalLiquidityPositionId = 'globalLiquidityPosition__id',
  GlobalLiquidityPositionLiquidity = 'globalLiquidityPosition__liquidity',
  GlobalLiquidityPositionMargin = 'globalLiquidityPosition__margin',
  GlobalLiquidityPositionNetSize = 'globalLiquidityPosition__netSize',
  GlobalLiquidityPositionRealizedProfit = 'globalLiquidityPosition__realizedProfit',
  GlobalLiquidityPositionRealizedProfitGrowth = 'globalLiquidityPosition__realizedProfitGrowth',
  GlobalLiquidityPositionRealizedProfitGrowthX64 = 'globalLiquidityPosition__realizedProfitGrowthX64',
  GlobalLiquidityPositionRiskBufferFundLiquidity = 'globalLiquidityPosition__riskBufferFundLiquidity',
  GlobalLiquidityPositionSide = 'globalLiquidityPosition__side',
  GlobalLiquidityPositionTradingFee = 'globalLiquidityPosition__tradingFee',
  GlobalPosition = 'globalPosition',
  GlobalPosition1Ds = 'globalPosition1Ds',
  GlobalPositionFundingRate = 'globalPosition__fundingRate',
  GlobalPositionFundingRateX96 = 'globalPosition__fundingRateX96',
  GlobalPositionId = 'globalPosition__id',
  GlobalPositionLastAdjustFundingRateTime = 'globalPosition__lastAdjustFundingRateTime',
  GlobalPositionLiquidity = 'globalPosition__liquidity',
  GlobalPositionLongAvgPrice = 'globalPosition__longAvgPrice',
  GlobalPositionLongFundingRateGrowth = 'globalPosition__longFundingRateGrowth',
  GlobalPositionLongFundingRateGrowthX96 = 'globalPosition__longFundingRateGrowthX96',
  GlobalPositionLongQuantity = 'globalPosition__longQuantity',
  GlobalPositionLongSize = 'globalPosition__longSize',
  GlobalPositionMargin = 'globalPosition__margin',
  GlobalPositionOpenInterest = 'globalPosition__openInterest',
  GlobalPositionOpenInterestLong = 'globalPosition__openInterestLong',
  GlobalPositionOpenInterestShort = 'globalPosition__openInterestShort',
  GlobalPositionShortAvgPrice = 'globalPosition__shortAvgPrice',
  GlobalPositionShortFundingRateGrowth = 'globalPosition__shortFundingRateGrowth',
  GlobalPositionShortFundingRateGrowthX96 = 'globalPosition__shortFundingRateGrowthX96',
  GlobalPositionShortQuantity = 'globalPosition__shortQuantity',
  GlobalPositionShortSize = 'globalPosition__shortSize',
  Id = 'id',
  PairString = 'pairString',
  PoolApr1Ds = 'poolAPR1Ds',
  PoolVolume1Ds = 'poolVolume1Ds',
  Token = 'token',
  TokenDecimals = 'token__decimals',
  TokenId = 'token__id',
  TokenLiquidityFeeRate = 'token__liquidityFeeRate',
  TokenMaxPrice = 'token__maxPrice',
  TokenMaxPriceX96 = 'token__maxPriceX96',
  TokenMinPrice = 'token__minPrice',
  TokenMinPriceX96 = 'token__minPriceX96',
  TokenPool = 'token__pool',
  TokenPrice = 'token__price',
  TokenPriceX96 = 'token__priceX96',
  TokenProtocolFeeRate = 'token__protocolFeeRate',
  TokenReferralParentReturnFeeRate = 'token__referralParentReturnFeeRate',
  TokenReferralReturnFeeRate = 'token__referralReturnFeeRate',
  Volume = 'volume',
  VolumeUsd = 'volumeUSD'
}

export type Position = {
  __typename?: 'Position';
  /** the owner of the position */
  account: Scalars['Bytes'];
  /** the entry price of the position */
  entryPrice: Scalars['BigDecimal'];
  /** the entry price of the position, as a Q64.96 */
  entryPriceX96: Scalars['BigInt'];
  /** accumulated funding fee of the position */
  fundingFee: Scalars['BigDecimal'];
  /** format: <address>:<pool address>:<side> */
  id: Scalars['ID'];
  lastBlockTimestamp: Scalars['BigInt'];
  /** the liquidity of the position */
  liquidity: Scalars['BigDecimal'];
  /** the margin of the position */
  margin: Scalars['BigDecimal'];
  /** pointer to pool */
  pool: Pool;
  /** position history ID */
  positionHistoryID: Scalars['String'];
  /** accumulated realized PnL of the position */
  realizedPnL: Scalars['BigDecimal'];
  /** the side of the position, 1 for long and 2 for short */
  side: Scalars['Int'];
  /** the size of the position */
  size: Scalars['BigDecimal'];
  /** accumulated trading fee of the position */
  tradingFee: Scalars['BigDecimal'];
  volumeUSDDelta: Scalars['BigDecimal'];
};

export type PositionHistory = {
  __typename?: 'PositionHistory';
  /** the owner of the position */
  account: Scalars['Bytes'];
  /** the close time of the position */
  closeTime: Scalars['BigInt'];
  /** the entry time of the position */
  entryTime: Scalars['BigInt'];
  /** format: <transaction hash>:<log index> */
  id: Scalars['ID'];
  /** accumulated margin of the position */
  margin: Scalars['BigDecimal'];
  /** pointer to pool */
  pool: Pool;
  /** accumulated realized PnL of the position */
  realizedPnL: Scalars['BigDecimal'];
  /** the side of the position, 1 for long and 2 for short */
  side: Scalars['Int'];
};

export type PositionHistory_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['Bytes']>;
  account_contains?: InputMaybe<Scalars['Bytes']>;
  account_gt?: InputMaybe<Scalars['Bytes']>;
  account_gte?: InputMaybe<Scalars['Bytes']>;
  account_in?: InputMaybe<Array<Scalars['Bytes']>>;
  account_lt?: InputMaybe<Scalars['Bytes']>;
  account_lte?: InputMaybe<Scalars['Bytes']>;
  account_not?: InputMaybe<Scalars['Bytes']>;
  account_not_contains?: InputMaybe<Scalars['Bytes']>;
  account_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  and?: InputMaybe<Array<InputMaybe<PositionHistory_Filter>>>;
  closeTime?: InputMaybe<Scalars['BigInt']>;
  closeTime_gt?: InputMaybe<Scalars['BigInt']>;
  closeTime_gte?: InputMaybe<Scalars['BigInt']>;
  closeTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  closeTime_lt?: InputMaybe<Scalars['BigInt']>;
  closeTime_lte?: InputMaybe<Scalars['BigInt']>;
  closeTime_not?: InputMaybe<Scalars['BigInt']>;
  closeTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryTime?: InputMaybe<Scalars['BigInt']>;
  entryTime_gt?: InputMaybe<Scalars['BigInt']>;
  entryTime_gte?: InputMaybe<Scalars['BigInt']>;
  entryTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryTime_lt?: InputMaybe<Scalars['BigInt']>;
  entryTime_lte?: InputMaybe<Scalars['BigInt']>;
  entryTime_not?: InputMaybe<Scalars['BigInt']>;
  entryTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  margin?: InputMaybe<Scalars['BigDecimal']>;
  margin_gt?: InputMaybe<Scalars['BigDecimal']>;
  margin_gte?: InputMaybe<Scalars['BigDecimal']>;
  margin_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin_lt?: InputMaybe<Scalars['BigDecimal']>;
  margin_lte?: InputMaybe<Scalars['BigDecimal']>;
  margin_not?: InputMaybe<Scalars['BigDecimal']>;
  margin_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<PositionHistory_Filter>>>;
  pool?: InputMaybe<Scalars['String']>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars['String']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_ends_with?: InputMaybe<Scalars['String']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_gt?: InputMaybe<Scalars['String']>;
  pool_gte?: InputMaybe<Scalars['String']>;
  pool_in?: InputMaybe<Array<Scalars['String']>>;
  pool_lt?: InputMaybe<Scalars['String']>;
  pool_lte?: InputMaybe<Scalars['String']>;
  pool_not?: InputMaybe<Scalars['String']>;
  pool_not_contains?: InputMaybe<Scalars['String']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_not_in?: InputMaybe<Array<Scalars['String']>>;
  pool_not_starts_with?: InputMaybe<Scalars['String']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pool_starts_with?: InputMaybe<Scalars['String']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']>;
  realizedPnL?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_gt?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_gte?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  realizedPnL_lt?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_lte?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_not?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  side?: InputMaybe<Scalars['Int']>;
  side_gt?: InputMaybe<Scalars['Int']>;
  side_gte?: InputMaybe<Scalars['Int']>;
  side_in?: InputMaybe<Array<Scalars['Int']>>;
  side_lt?: InputMaybe<Scalars['Int']>;
  side_lte?: InputMaybe<Scalars['Int']>;
  side_not?: InputMaybe<Scalars['Int']>;
  side_not_in?: InputMaybe<Array<Scalars['Int']>>;
};

export enum PositionHistory_OrderBy {
  Account = 'account',
  CloseTime = 'closeTime',
  EntryTime = 'entryTime',
  Id = 'id',
  Margin = 'margin',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolPairString = 'pool__pairString',
  PoolVolume = 'pool__volume',
  PoolVolumeUsd = 'pool__volumeUSD',
  RealizedPnL = 'realizedPnL',
  Side = 'side'
}

export type Position_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['Bytes']>;
  account_contains?: InputMaybe<Scalars['Bytes']>;
  account_gt?: InputMaybe<Scalars['Bytes']>;
  account_gte?: InputMaybe<Scalars['Bytes']>;
  account_in?: InputMaybe<Array<Scalars['Bytes']>>;
  account_lt?: InputMaybe<Scalars['Bytes']>;
  account_lte?: InputMaybe<Scalars['Bytes']>;
  account_not?: InputMaybe<Scalars['Bytes']>;
  account_not_contains?: InputMaybe<Scalars['Bytes']>;
  account_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  and?: InputMaybe<Array<InputMaybe<Position_Filter>>>;
  entryPrice?: InputMaybe<Scalars['BigDecimal']>;
  entryPriceX96?: InputMaybe<Scalars['BigInt']>;
  entryPriceX96_gt?: InputMaybe<Scalars['BigInt']>;
  entryPriceX96_gte?: InputMaybe<Scalars['BigInt']>;
  entryPriceX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryPriceX96_lt?: InputMaybe<Scalars['BigInt']>;
  entryPriceX96_lte?: InputMaybe<Scalars['BigInt']>;
  entryPriceX96_not?: InputMaybe<Scalars['BigInt']>;
  entryPriceX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  entryPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  entryPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  entryPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  entryPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  entryPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  entryPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fundingFee?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fundingFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_not?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  lastBlockTimestamp?: InputMaybe<Scalars['BigInt']>;
  lastBlockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  lastBlockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  lastBlockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  lastBlockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  lastBlockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  lastBlockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  lastBlockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  liquidity?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin?: InputMaybe<Scalars['BigDecimal']>;
  margin_gt?: InputMaybe<Scalars['BigDecimal']>;
  margin_gte?: InputMaybe<Scalars['BigDecimal']>;
  margin_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin_lt?: InputMaybe<Scalars['BigDecimal']>;
  margin_lte?: InputMaybe<Scalars['BigDecimal']>;
  margin_not?: InputMaybe<Scalars['BigDecimal']>;
  margin_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<Position_Filter>>>;
  pool?: InputMaybe<Scalars['String']>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars['String']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_ends_with?: InputMaybe<Scalars['String']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_gt?: InputMaybe<Scalars['String']>;
  pool_gte?: InputMaybe<Scalars['String']>;
  pool_in?: InputMaybe<Array<Scalars['String']>>;
  pool_lt?: InputMaybe<Scalars['String']>;
  pool_lte?: InputMaybe<Scalars['String']>;
  pool_not?: InputMaybe<Scalars['String']>;
  pool_not_contains?: InputMaybe<Scalars['String']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_not_in?: InputMaybe<Array<Scalars['String']>>;
  pool_not_starts_with?: InputMaybe<Scalars['String']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pool_starts_with?: InputMaybe<Scalars['String']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']>;
  positionHistoryID?: InputMaybe<Scalars['String']>;
  positionHistoryID_contains?: InputMaybe<Scalars['String']>;
  positionHistoryID_contains_nocase?: InputMaybe<Scalars['String']>;
  positionHistoryID_ends_with?: InputMaybe<Scalars['String']>;
  positionHistoryID_ends_with_nocase?: InputMaybe<Scalars['String']>;
  positionHistoryID_gt?: InputMaybe<Scalars['String']>;
  positionHistoryID_gte?: InputMaybe<Scalars['String']>;
  positionHistoryID_in?: InputMaybe<Array<Scalars['String']>>;
  positionHistoryID_lt?: InputMaybe<Scalars['String']>;
  positionHistoryID_lte?: InputMaybe<Scalars['String']>;
  positionHistoryID_not?: InputMaybe<Scalars['String']>;
  positionHistoryID_not_contains?: InputMaybe<Scalars['String']>;
  positionHistoryID_not_contains_nocase?: InputMaybe<Scalars['String']>;
  positionHistoryID_not_ends_with?: InputMaybe<Scalars['String']>;
  positionHistoryID_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  positionHistoryID_not_in?: InputMaybe<Array<Scalars['String']>>;
  positionHistoryID_not_starts_with?: InputMaybe<Scalars['String']>;
  positionHistoryID_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  positionHistoryID_starts_with?: InputMaybe<Scalars['String']>;
  positionHistoryID_starts_with_nocase?: InputMaybe<Scalars['String']>;
  realizedPnL?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_gt?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_gte?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  realizedPnL_lt?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_lte?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_not?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  side?: InputMaybe<Scalars['Int']>;
  side_gt?: InputMaybe<Scalars['Int']>;
  side_gte?: InputMaybe<Scalars['Int']>;
  side_in?: InputMaybe<Array<Scalars['Int']>>;
  side_lt?: InputMaybe<Scalars['Int']>;
  side_lte?: InputMaybe<Scalars['Int']>;
  side_not?: InputMaybe<Scalars['Int']>;
  side_not_in?: InputMaybe<Array<Scalars['Int']>>;
  size?: InputMaybe<Scalars['BigDecimal']>;
  size_gt?: InputMaybe<Scalars['BigDecimal']>;
  size_gte?: InputMaybe<Scalars['BigDecimal']>;
  size_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  size_lt?: InputMaybe<Scalars['BigDecimal']>;
  size_lte?: InputMaybe<Scalars['BigDecimal']>;
  size_not?: InputMaybe<Scalars['BigDecimal']>;
  size_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  tradingFee?: InputMaybe<Scalars['BigDecimal']>;
  tradingFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  tradingFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  tradingFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  tradingFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  tradingFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  tradingFee_not?: InputMaybe<Scalars['BigDecimal']>;
  tradingFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volumeUSDDelta?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSDDelta_gt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSDDelta_gte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSDDelta_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volumeUSDDelta_lt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSDDelta_lte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSDDelta_not?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSDDelta_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum Position_OrderBy {
  Account = 'account',
  EntryPrice = 'entryPrice',
  EntryPriceX96 = 'entryPriceX96',
  FundingFee = 'fundingFee',
  Id = 'id',
  LastBlockTimestamp = 'lastBlockTimestamp',
  Liquidity = 'liquidity',
  Margin = 'margin',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolPairString = 'pool__pairString',
  PoolVolume = 'pool__volume',
  PoolVolumeUsd = 'pool__volumeUSD',
  PositionHistoryId = 'positionHistoryID',
  RealizedPnL = 'realizedPnL',
  Side = 'side',
  Size = 'size',
  TradingFee = 'tradingFee',
  VolumeUsdDelta = 'volumeUSDDelta'
}

export type ProfitLossStatistic = {
  __typename?: 'ProfitLossStatistic';
  /** account address */
  id: Scalars['Bytes'];
  /** the margin amount for the account */
  margin: Scalars['BigDecimal'];
  /** the profit margin percentage for the account */
  profitMargin: Scalars['BigDecimal'];
  /** the realized profit or loss amount for the account */
  realizedPnL: Scalars['BigDecimal'];
};

export type ProfitLossStatistic_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ProfitLossStatistic_Filter>>>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  margin?: InputMaybe<Scalars['BigDecimal']>;
  margin_gt?: InputMaybe<Scalars['BigDecimal']>;
  margin_gte?: InputMaybe<Scalars['BigDecimal']>;
  margin_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin_lt?: InputMaybe<Scalars['BigDecimal']>;
  margin_lte?: InputMaybe<Scalars['BigDecimal']>;
  margin_not?: InputMaybe<Scalars['BigDecimal']>;
  margin_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<ProfitLossStatistic_Filter>>>;
  profitMargin?: InputMaybe<Scalars['BigDecimal']>;
  profitMargin_gt?: InputMaybe<Scalars['BigDecimal']>;
  profitMargin_gte?: InputMaybe<Scalars['BigDecimal']>;
  profitMargin_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  profitMargin_lt?: InputMaybe<Scalars['BigDecimal']>;
  profitMargin_lte?: InputMaybe<Scalars['BigDecimal']>;
  profitMargin_not?: InputMaybe<Scalars['BigDecimal']>;
  profitMargin_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  realizedPnL?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_gt?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_gte?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  realizedPnL_lt?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_lte?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_not?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum ProfitLossStatistic_OrderBy {
  Id = 'id',
  Margin = 'margin',
  ProfitMargin = 'profitMargin',
  RealizedPnL = 'realizedPnL'
}

export type ProtocolState = {
  __typename?: 'ProtocolState';
  /** format: protocol_state:<timestamp> */
  id: Scalars['ID'];
  /** value of open contracts of all pools */
  openInterest: Scalars['BigDecimal'];
  /** total protocol fee of all pools */
  totalFee: Scalars['BigDecimal'];
  /** total liquidity(value) of all pools */
  totalLiquidity: Scalars['BigDecimal'];
  /** user count of all pools */
  totalUserCount: Scalars['BigInt'];
  /** total volume of all pools in derived USD */
  totalVolumeUSD: Scalars['BigDecimal'];
  /** timestamp of this statistical data */
  ts: Scalars['Int'];
};

export type ProtocolState_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ProtocolState_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  openInterest?: InputMaybe<Scalars['BigDecimal']>;
  openInterest_gt?: InputMaybe<Scalars['BigDecimal']>;
  openInterest_gte?: InputMaybe<Scalars['BigDecimal']>;
  openInterest_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  openInterest_lt?: InputMaybe<Scalars['BigDecimal']>;
  openInterest_lte?: InputMaybe<Scalars['BigDecimal']>;
  openInterest_not?: InputMaybe<Scalars['BigDecimal']>;
  openInterest_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<ProtocolState_Filter>>>;
  totalFee?: InputMaybe<Scalars['BigDecimal']>;
  totalFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  totalFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  totalFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  totalFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  totalFee_not?: InputMaybe<Scalars['BigDecimal']>;
  totalFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalLiquidity?: InputMaybe<Scalars['BigDecimal']>;
  totalLiquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  totalLiquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  totalLiquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalLiquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  totalLiquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  totalLiquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  totalLiquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalUserCount?: InputMaybe<Scalars['BigInt']>;
  totalUserCount_gt?: InputMaybe<Scalars['BigInt']>;
  totalUserCount_gte?: InputMaybe<Scalars['BigInt']>;
  totalUserCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  totalUserCount_lt?: InputMaybe<Scalars['BigInt']>;
  totalUserCount_lte?: InputMaybe<Scalars['BigInt']>;
  totalUserCount_not?: InputMaybe<Scalars['BigInt']>;
  totalUserCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  totalVolumeUSD?: InputMaybe<Scalars['BigDecimal']>;
  totalVolumeUSD_gt?: InputMaybe<Scalars['BigDecimal']>;
  totalVolumeUSD_gte?: InputMaybe<Scalars['BigDecimal']>;
  totalVolumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalVolumeUSD_lt?: InputMaybe<Scalars['BigDecimal']>;
  totalVolumeUSD_lte?: InputMaybe<Scalars['BigDecimal']>;
  totalVolumeUSD_not?: InputMaybe<Scalars['BigDecimal']>;
  totalVolumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  ts?: InputMaybe<Scalars['Int']>;
  ts_gt?: InputMaybe<Scalars['Int']>;
  ts_gte?: InputMaybe<Scalars['Int']>;
  ts_in?: InputMaybe<Array<Scalars['Int']>>;
  ts_lt?: InputMaybe<Scalars['Int']>;
  ts_lte?: InputMaybe<Scalars['Int']>;
  ts_not?: InputMaybe<Scalars['Int']>;
  ts_not_in?: InputMaybe<Array<Scalars['Int']>>;
};

export enum ProtocolState_OrderBy {
  Id = 'id',
  OpenInterest = 'openInterest',
  TotalFee = 'totalFee',
  TotalLiquidity = 'totalLiquidity',
  TotalUserCount = 'totalUserCount',
  TotalVolumeUsd = 'totalVolumeUSD',
  Ts = 'ts'
}

export type ProtocolStatistic = {
  __typename?: 'ProtocolStatistic';
  /** total architect fee of all pools */
  architectFee: Scalars['BigDecimal'];
  /** total connector fee of all pools */
  connectorFee: Scalars['BigDecimal'];
  /** format: <period>:<timestamp> */
  id: Scalars['ID'];
  /** total invitation count */
  invitationCount: Scalars['BigInt'];
  /** total liquidity fee of all pools */
  liquidityFee: Scalars['BigDecimal'];
  /** total member fee of all pools */
  memberFee: Scalars['BigDecimal'];
  /** new users count */
  newUsersCount: Scalars['BigInt'];
  /** period of this statistical data */
  period: Period;
  /** protocol fee */
  protocolFee: Scalars['BigDecimal'];
  /** trading fee of all referee */
  refereeTradingFee: Scalars['BigDecimal'];
  /** total RBF fee of all pools */
  riskBufferFundFee: Scalars['BigDecimal'];
  /** total stake fee of all pools */
  stakeFee: Scalars['BigDecimal'];
  /** timestamp of this statistical data */
  ts: Scalars['BigInt'];
  /** unique user count */
  userCount: Scalars['BigInt'];
  /** trading volume in derived USD */
  volumeUSD: Scalars['BigDecimal'];
};

export type ProtocolStatistic_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ProtocolStatistic_Filter>>>;
  architectFee?: InputMaybe<Scalars['BigDecimal']>;
  architectFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  architectFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  architectFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  architectFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  architectFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  architectFee_not?: InputMaybe<Scalars['BigDecimal']>;
  architectFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  connectorFee?: InputMaybe<Scalars['BigDecimal']>;
  connectorFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  connectorFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  connectorFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  connectorFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  connectorFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  connectorFee_not?: InputMaybe<Scalars['BigDecimal']>;
  connectorFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  invitationCount?: InputMaybe<Scalars['BigInt']>;
  invitationCount_gt?: InputMaybe<Scalars['BigInt']>;
  invitationCount_gte?: InputMaybe<Scalars['BigInt']>;
  invitationCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  invitationCount_lt?: InputMaybe<Scalars['BigInt']>;
  invitationCount_lte?: InputMaybe<Scalars['BigInt']>;
  invitationCount_not?: InputMaybe<Scalars['BigInt']>;
  invitationCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  liquidityFee?: InputMaybe<Scalars['BigDecimal']>;
  liquidityFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidityFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityFee_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidityFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  memberFee?: InputMaybe<Scalars['BigDecimal']>;
  memberFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  memberFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  memberFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  memberFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  memberFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  memberFee_not?: InputMaybe<Scalars['BigDecimal']>;
  memberFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  newUsersCount?: InputMaybe<Scalars['BigInt']>;
  newUsersCount_gt?: InputMaybe<Scalars['BigInt']>;
  newUsersCount_gte?: InputMaybe<Scalars['BigInt']>;
  newUsersCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  newUsersCount_lt?: InputMaybe<Scalars['BigInt']>;
  newUsersCount_lte?: InputMaybe<Scalars['BigInt']>;
  newUsersCount_not?: InputMaybe<Scalars['BigInt']>;
  newUsersCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  or?: InputMaybe<Array<InputMaybe<ProtocolStatistic_Filter>>>;
  period?: InputMaybe<Period>;
  period_in?: InputMaybe<Array<Period>>;
  period_not?: InputMaybe<Period>;
  period_not_in?: InputMaybe<Array<Period>>;
  protocolFee?: InputMaybe<Scalars['BigDecimal']>;
  protocolFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  protocolFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  protocolFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  protocolFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  protocolFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  protocolFee_not?: InputMaybe<Scalars['BigDecimal']>;
  protocolFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  refereeTradingFee?: InputMaybe<Scalars['BigDecimal']>;
  refereeTradingFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  refereeTradingFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  refereeTradingFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  refereeTradingFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  refereeTradingFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  refereeTradingFee_not?: InputMaybe<Scalars['BigDecimal']>;
  refereeTradingFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  riskBufferFundFee?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  riskBufferFundFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundFee_not?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  stakeFee?: InputMaybe<Scalars['BigDecimal']>;
  stakeFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  stakeFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  stakeFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  stakeFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  stakeFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  stakeFee_not?: InputMaybe<Scalars['BigDecimal']>;
  stakeFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  ts?: InputMaybe<Scalars['BigInt']>;
  ts_gt?: InputMaybe<Scalars['BigInt']>;
  ts_gte?: InputMaybe<Scalars['BigInt']>;
  ts_in?: InputMaybe<Array<Scalars['BigInt']>>;
  ts_lt?: InputMaybe<Scalars['BigInt']>;
  ts_lte?: InputMaybe<Scalars['BigInt']>;
  ts_not?: InputMaybe<Scalars['BigInt']>;
  ts_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  userCount?: InputMaybe<Scalars['BigInt']>;
  userCount_gt?: InputMaybe<Scalars['BigInt']>;
  userCount_gte?: InputMaybe<Scalars['BigInt']>;
  userCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  userCount_lt?: InputMaybe<Scalars['BigInt']>;
  userCount_lte?: InputMaybe<Scalars['BigInt']>;
  userCount_not?: InputMaybe<Scalars['BigInt']>;
  userCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  volumeUSD?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_lt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum ProtocolStatistic_OrderBy {
  ArchitectFee = 'architectFee',
  ConnectorFee = 'connectorFee',
  Id = 'id',
  InvitationCount = 'invitationCount',
  LiquidityFee = 'liquidityFee',
  MemberFee = 'memberFee',
  NewUsersCount = 'newUsersCount',
  Period = 'period',
  ProtocolFee = 'protocolFee',
  RefereeTradingFee = 'refereeTradingFee',
  RiskBufferFundFee = 'riskBufferFundFee',
  StakeFee = 'stakeFee',
  Ts = 'ts',
  UserCount = 'userCount',
  VolumeUsd = 'volumeUSD'
}

export type Query = {
  __typename?: 'Query';
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  globalLiquidityPosition?: Maybe<GlobalLiquidityPosition>;
  globalLiquidityPosition1D?: Maybe<GlobalLiquidityPosition1D>;
  globalLiquidityPosition1Ds: Array<GlobalLiquidityPosition1D>;
  globalLiquidityPositions: Array<GlobalLiquidityPosition>;
  globalPosition?: Maybe<GlobalPosition>;
  globalPosition1D?: Maybe<GlobalPosition1D>;
  globalPosition1Ds: Array<GlobalPosition1D>;
  globalPositions: Array<GlobalPosition>;
  liquidityPosition?: Maybe<LiquidityPosition>;
  liquidityPositions: Array<LiquidityPosition>;
  operationVolume1D?: Maybe<OperationVolume1D>;
  operationVolume1Ds: Array<OperationVolume1D>;
  pool?: Maybe<Pool>;
  poolAPR1D?: Maybe<PoolApr1D>;
  poolAPR1Ds: Array<PoolApr1D>;
  poolVolume1D?: Maybe<PoolVolume1D>;
  poolVolume1Ds: Array<PoolVolume1D>;
  pools: Array<Pool>;
  position?: Maybe<Position>;
  positionHistories: Array<PositionHistory>;
  positionHistory?: Maybe<PositionHistory>;
  positions: Array<Position>;
  profitLossStatistic?: Maybe<ProfitLossStatistic>;
  profitLossStatistics: Array<ProfitLossStatistic>;
  protocolState?: Maybe<ProtocolState>;
  protocolStates: Array<ProtocolState>;
  protocolStatistic?: Maybe<ProtocolStatistic>;
  protocolStatistics: Array<ProtocolStatistic>;
  realizedPnL1D?: Maybe<RealizedPnL1D>;
  realizedPnL1Ds: Array<RealizedPnL1D>;
  referee?: Maybe<Referee>;
  referees: Array<Referee>;
  token?: Maybe<Token>;
  tokens: Array<Token>;
  user?: Maybe<User>;
  userTransactionStatistic?: Maybe<UserTransactionStatistic>;
  userTransactionStatistics: Array<UserTransactionStatistic>;
  users: Array<User>;
};


export type Query_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};


export type QueryGlobalLiquidityPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryGlobalLiquidityPosition1DArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryGlobalLiquidityPosition1DsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GlobalLiquidityPosition1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalLiquidityPosition1D_Filter>;
};


export type QueryGlobalLiquidityPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GlobalLiquidityPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalLiquidityPosition_Filter>;
};


export type QueryGlobalPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryGlobalPosition1DArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryGlobalPosition1DsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GlobalPosition1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalPosition1D_Filter>;
};


export type QueryGlobalPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GlobalPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalPosition_Filter>;
};


export type QueryLiquidityPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryLiquidityPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LiquidityPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidityPosition_Filter>;
};


export type QueryOperationVolume1DArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryOperationVolume1DsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OperationVolume1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OperationVolume1D_Filter>;
};


export type QueryPoolArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolApr1DArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolApr1DsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PoolApr1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolApr1D_Filter>;
};


export type QueryPoolVolume1DArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolVolume1DsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PoolVolume1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolVolume1D_Filter>;
};


export type QueryPoolsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Pool_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Pool_Filter>;
};


export type QueryPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPositionHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PositionHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PositionHistory_Filter>;
};


export type QueryPositionHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Position_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Position_Filter>;
};


export type QueryProfitLossStatisticArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryProfitLossStatisticsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProfitLossStatistic_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ProfitLossStatistic_Filter>;
};


export type QueryProtocolStateArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryProtocolStatesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProtocolState_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ProtocolState_Filter>;
};


export type QueryProtocolStatisticArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryProtocolStatisticsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProtocolStatistic_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ProtocolStatistic_Filter>;
};


export type QueryRealizedPnL1DArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRealizedPnL1DsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RealizedPnL1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RealizedPnL1D_Filter>;
};


export type QueryRefereeArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRefereesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Referee_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Referee_Filter>;
};


export type QueryTokenArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokensArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Token_Filter>;
};


export type QueryUserArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryUserTransactionStatisticArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryUserTransactionStatisticsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserTransactionStatistic_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UserTransactionStatistic_Filter>;
};


export type QueryUsersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<User_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<User_Filter>;
};

export type RealizedPnL1D = {
  __typename?: 'RealizedPnL1D';
  /** format: <timestamp> */
  id: Scalars['ID'];
  /** loss in one day */
  loss: Scalars['BigDecimal'];
  /** net in one day */
  net: Scalars['BigDecimal'];
  /** profit in one day */
  profit: Scalars['BigDecimal'];
  /** timestamp of this statistical data */
  ts: Scalars['BigInt'];
};

export type RealizedPnL1D_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<RealizedPnL1D_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  loss?: InputMaybe<Scalars['BigDecimal']>;
  loss_gt?: InputMaybe<Scalars['BigDecimal']>;
  loss_gte?: InputMaybe<Scalars['BigDecimal']>;
  loss_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  loss_lt?: InputMaybe<Scalars['BigDecimal']>;
  loss_lte?: InputMaybe<Scalars['BigDecimal']>;
  loss_not?: InputMaybe<Scalars['BigDecimal']>;
  loss_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  net?: InputMaybe<Scalars['BigDecimal']>;
  net_gt?: InputMaybe<Scalars['BigDecimal']>;
  net_gte?: InputMaybe<Scalars['BigDecimal']>;
  net_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  net_lt?: InputMaybe<Scalars['BigDecimal']>;
  net_lte?: InputMaybe<Scalars['BigDecimal']>;
  net_not?: InputMaybe<Scalars['BigDecimal']>;
  net_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<RealizedPnL1D_Filter>>>;
  profit?: InputMaybe<Scalars['BigDecimal']>;
  profit_gt?: InputMaybe<Scalars['BigDecimal']>;
  profit_gte?: InputMaybe<Scalars['BigDecimal']>;
  profit_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  profit_lt?: InputMaybe<Scalars['BigDecimal']>;
  profit_lte?: InputMaybe<Scalars['BigDecimal']>;
  profit_not?: InputMaybe<Scalars['BigDecimal']>;
  profit_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  ts?: InputMaybe<Scalars['BigInt']>;
  ts_gt?: InputMaybe<Scalars['BigInt']>;
  ts_gte?: InputMaybe<Scalars['BigInt']>;
  ts_in?: InputMaybe<Array<Scalars['BigInt']>>;
  ts_lt?: InputMaybe<Scalars['BigInt']>;
  ts_lte?: InputMaybe<Scalars['BigInt']>;
  ts_not?: InputMaybe<Scalars['BigInt']>;
  ts_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum RealizedPnL1D_OrderBy {
  Id = 'id',
  Loss = 'loss',
  Net = 'net',
  Profit = 'profit',
  Ts = 'ts'
}

export type Referee = {
  __typename?: 'Referee';
  id: Scalars['ID'];
  latestTransactionTs: Scalars['BigInt'];
};

export type Referee_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Referee_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  latestTransactionTs?: InputMaybe<Scalars['BigInt']>;
  latestTransactionTs_gt?: InputMaybe<Scalars['BigInt']>;
  latestTransactionTs_gte?: InputMaybe<Scalars['BigInt']>;
  latestTransactionTs_in?: InputMaybe<Array<Scalars['BigInt']>>;
  latestTransactionTs_lt?: InputMaybe<Scalars['BigInt']>;
  latestTransactionTs_lte?: InputMaybe<Scalars['BigInt']>;
  latestTransactionTs_not?: InputMaybe<Scalars['BigInt']>;
  latestTransactionTs_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  or?: InputMaybe<Array<InputMaybe<Referee_Filter>>>;
};

export enum Referee_OrderBy {
  Id = 'id',
  LatestTransactionTs = 'latestTransactionTs'
}

export type Subscription = {
  __typename?: 'Subscription';
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  globalLiquidityPosition?: Maybe<GlobalLiquidityPosition>;
  globalLiquidityPosition1D?: Maybe<GlobalLiquidityPosition1D>;
  globalLiquidityPosition1Ds: Array<GlobalLiquidityPosition1D>;
  globalLiquidityPositions: Array<GlobalLiquidityPosition>;
  globalPosition?: Maybe<GlobalPosition>;
  globalPosition1D?: Maybe<GlobalPosition1D>;
  globalPosition1Ds: Array<GlobalPosition1D>;
  globalPositions: Array<GlobalPosition>;
  liquidityPosition?: Maybe<LiquidityPosition>;
  liquidityPositions: Array<LiquidityPosition>;
  operationVolume1D?: Maybe<OperationVolume1D>;
  operationVolume1Ds: Array<OperationVolume1D>;
  pool?: Maybe<Pool>;
  poolAPR1D?: Maybe<PoolApr1D>;
  poolAPR1Ds: Array<PoolApr1D>;
  poolVolume1D?: Maybe<PoolVolume1D>;
  poolVolume1Ds: Array<PoolVolume1D>;
  pools: Array<Pool>;
  position?: Maybe<Position>;
  positionHistories: Array<PositionHistory>;
  positionHistory?: Maybe<PositionHistory>;
  positions: Array<Position>;
  profitLossStatistic?: Maybe<ProfitLossStatistic>;
  profitLossStatistics: Array<ProfitLossStatistic>;
  protocolState?: Maybe<ProtocolState>;
  protocolStates: Array<ProtocolState>;
  protocolStatistic?: Maybe<ProtocolStatistic>;
  protocolStatistics: Array<ProtocolStatistic>;
  realizedPnL1D?: Maybe<RealizedPnL1D>;
  realizedPnL1Ds: Array<RealizedPnL1D>;
  referee?: Maybe<Referee>;
  referees: Array<Referee>;
  token?: Maybe<Token>;
  tokens: Array<Token>;
  user?: Maybe<User>;
  userTransactionStatistic?: Maybe<UserTransactionStatistic>;
  userTransactionStatistics: Array<UserTransactionStatistic>;
  users: Array<User>;
};


export type Subscription_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};


export type SubscriptionGlobalLiquidityPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionGlobalLiquidityPosition1DArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionGlobalLiquidityPosition1DsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GlobalLiquidityPosition1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalLiquidityPosition1D_Filter>;
};


export type SubscriptionGlobalLiquidityPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GlobalLiquidityPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalLiquidityPosition_Filter>;
};


export type SubscriptionGlobalPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionGlobalPosition1DArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionGlobalPosition1DsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GlobalPosition1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalPosition1D_Filter>;
};


export type SubscriptionGlobalPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GlobalPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalPosition_Filter>;
};


export type SubscriptionLiquidityPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionLiquidityPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LiquidityPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidityPosition_Filter>;
};


export type SubscriptionOperationVolume1DArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionOperationVolume1DsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OperationVolume1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OperationVolume1D_Filter>;
};


export type SubscriptionPoolArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolApr1DArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolApr1DsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PoolApr1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolApr1D_Filter>;
};


export type SubscriptionPoolVolume1DArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolVolume1DsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PoolVolume1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolVolume1D_Filter>;
};


export type SubscriptionPoolsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Pool_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Pool_Filter>;
};


export type SubscriptionPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPositionHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PositionHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PositionHistory_Filter>;
};


export type SubscriptionPositionHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Position_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Position_Filter>;
};


export type SubscriptionProfitLossStatisticArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionProfitLossStatisticsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProfitLossStatistic_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ProfitLossStatistic_Filter>;
};


export type SubscriptionProtocolStateArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionProtocolStatesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProtocolState_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ProtocolState_Filter>;
};


export type SubscriptionProtocolStatisticArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionProtocolStatisticsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProtocolStatistic_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ProtocolStatistic_Filter>;
};


export type SubscriptionRealizedPnL1DArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRealizedPnL1DsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RealizedPnL1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RealizedPnL1D_Filter>;
};


export type SubscriptionRefereeArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRefereesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Referee_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Referee_Filter>;
};


export type SubscriptionTokenArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokensArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Token_Filter>;
};


export type SubscriptionUserArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionUserTransactionStatisticArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionUserTransactionStatisticsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserTransactionStatistic_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<UserTransactionStatistic_Filter>;
};


export type SubscriptionUsersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<User_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<User_Filter>;
};

/** entity that represents a token */
export type Token = {
  __typename?: 'Token';
  /** token decimals */
  decimals: Scalars['Int'];
  /** token address */
  id: Scalars['Bytes'];
  /** the liquidity fee rate as a percentage of trading fee, denominated in ten thousandths of a bip (i.e. 1e-8) */
  liquidityFeeRate: Scalars['BigInt'];
  /** token maximum price */
  maxPrice: Scalars['BigDecimal'];
  /** token maximum price, as a Q64.96 */
  maxPriceX96: Scalars['BigInt'];
  /** token minimum price */
  minPrice: Scalars['BigDecimal'];
  /** token minimum price, as a Q64.96 */
  minPriceX96: Scalars['BigInt'];
  /** pool address */
  pool: Scalars['Bytes'];
  /** token price */
  price: Scalars['BigDecimal'];
  /** token price, as a Q64.96 */
  priceX96: Scalars['BigInt'];
  /** the protocol fee rate as a percentage of trading fee, denominated in ten thousandths of a bip (i.e. 1e-8) */
  protocolFeeRate: Scalars['BigInt'];
  /** the referral parent return fee rate as a percentage of trading fee, denominated in ten thousandths of a bip (i.e. 1e-8) */
  referralParentReturnFeeRate: Scalars['BigInt'];
  /** the referral return fee rate as a percentage of trading fee, denominated in ten thousandths of a bip (i.e. 1e-8) */
  referralReturnFeeRate: Scalars['BigInt'];
};

export type Token_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Token_Filter>>>;
  decimals?: InputMaybe<Scalars['Int']>;
  decimals_gt?: InputMaybe<Scalars['Int']>;
  decimals_gte?: InputMaybe<Scalars['Int']>;
  decimals_in?: InputMaybe<Array<Scalars['Int']>>;
  decimals_lt?: InputMaybe<Scalars['Int']>;
  decimals_lte?: InputMaybe<Scalars['Int']>;
  decimals_not?: InputMaybe<Scalars['Int']>;
  decimals_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  liquidityFeeRate?: InputMaybe<Scalars['BigInt']>;
  liquidityFeeRate_gt?: InputMaybe<Scalars['BigInt']>;
  liquidityFeeRate_gte?: InputMaybe<Scalars['BigInt']>;
  liquidityFeeRate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  liquidityFeeRate_lt?: InputMaybe<Scalars['BigInt']>;
  liquidityFeeRate_lte?: InputMaybe<Scalars['BigInt']>;
  liquidityFeeRate_not?: InputMaybe<Scalars['BigInt']>;
  liquidityFeeRate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maxPrice?: InputMaybe<Scalars['BigDecimal']>;
  maxPriceX96?: InputMaybe<Scalars['BigInt']>;
  maxPriceX96_gt?: InputMaybe<Scalars['BigInt']>;
  maxPriceX96_gte?: InputMaybe<Scalars['BigInt']>;
  maxPriceX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maxPriceX96_lt?: InputMaybe<Scalars['BigInt']>;
  maxPriceX96_lte?: InputMaybe<Scalars['BigInt']>;
  maxPriceX96_not?: InputMaybe<Scalars['BigInt']>;
  maxPriceX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maxPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  maxPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  maxPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  maxPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  maxPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  maxPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  maxPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  minPrice?: InputMaybe<Scalars['BigDecimal']>;
  minPriceX96?: InputMaybe<Scalars['BigInt']>;
  minPriceX96_gt?: InputMaybe<Scalars['BigInt']>;
  minPriceX96_gte?: InputMaybe<Scalars['BigInt']>;
  minPriceX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  minPriceX96_lt?: InputMaybe<Scalars['BigInt']>;
  minPriceX96_lte?: InputMaybe<Scalars['BigInt']>;
  minPriceX96_not?: InputMaybe<Scalars['BigInt']>;
  minPriceX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  minPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  minPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  minPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  minPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  minPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  minPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  minPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<Token_Filter>>>;
  pool?: InputMaybe<Scalars['Bytes']>;
  pool_contains?: InputMaybe<Scalars['Bytes']>;
  pool_gt?: InputMaybe<Scalars['Bytes']>;
  pool_gte?: InputMaybe<Scalars['Bytes']>;
  pool_in?: InputMaybe<Array<Scalars['Bytes']>>;
  pool_lt?: InputMaybe<Scalars['Bytes']>;
  pool_lte?: InputMaybe<Scalars['Bytes']>;
  pool_not?: InputMaybe<Scalars['Bytes']>;
  pool_not_contains?: InputMaybe<Scalars['Bytes']>;
  pool_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  price?: InputMaybe<Scalars['BigDecimal']>;
  priceX96?: InputMaybe<Scalars['BigInt']>;
  priceX96_gt?: InputMaybe<Scalars['BigInt']>;
  priceX96_gte?: InputMaybe<Scalars['BigInt']>;
  priceX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  priceX96_lt?: InputMaybe<Scalars['BigInt']>;
  priceX96_lte?: InputMaybe<Scalars['BigInt']>;
  priceX96_not?: InputMaybe<Scalars['BigInt']>;
  priceX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  price_gt?: InputMaybe<Scalars['BigDecimal']>;
  price_gte?: InputMaybe<Scalars['BigDecimal']>;
  price_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  price_lt?: InputMaybe<Scalars['BigDecimal']>;
  price_lte?: InputMaybe<Scalars['BigDecimal']>;
  price_not?: InputMaybe<Scalars['BigDecimal']>;
  price_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  protocolFeeRate?: InputMaybe<Scalars['BigInt']>;
  protocolFeeRate_gt?: InputMaybe<Scalars['BigInt']>;
  protocolFeeRate_gte?: InputMaybe<Scalars['BigInt']>;
  protocolFeeRate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  protocolFeeRate_lt?: InputMaybe<Scalars['BigInt']>;
  protocolFeeRate_lte?: InputMaybe<Scalars['BigInt']>;
  protocolFeeRate_not?: InputMaybe<Scalars['BigInt']>;
  protocolFeeRate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralParentReturnFeeRate?: InputMaybe<Scalars['BigInt']>;
  referralParentReturnFeeRate_gt?: InputMaybe<Scalars['BigInt']>;
  referralParentReturnFeeRate_gte?: InputMaybe<Scalars['BigInt']>;
  referralParentReturnFeeRate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralParentReturnFeeRate_lt?: InputMaybe<Scalars['BigInt']>;
  referralParentReturnFeeRate_lte?: InputMaybe<Scalars['BigInt']>;
  referralParentReturnFeeRate_not?: InputMaybe<Scalars['BigInt']>;
  referralParentReturnFeeRate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralReturnFeeRate?: InputMaybe<Scalars['BigInt']>;
  referralReturnFeeRate_gt?: InputMaybe<Scalars['BigInt']>;
  referralReturnFeeRate_gte?: InputMaybe<Scalars['BigInt']>;
  referralReturnFeeRate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralReturnFeeRate_lt?: InputMaybe<Scalars['BigInt']>;
  referralReturnFeeRate_lte?: InputMaybe<Scalars['BigInt']>;
  referralReturnFeeRate_not?: InputMaybe<Scalars['BigInt']>;
  referralReturnFeeRate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum Token_OrderBy {
  Decimals = 'decimals',
  Id = 'id',
  LiquidityFeeRate = 'liquidityFeeRate',
  MaxPrice = 'maxPrice',
  MaxPriceX96 = 'maxPriceX96',
  MinPrice = 'minPrice',
  MinPriceX96 = 'minPriceX96',
  Pool = 'pool',
  Price = 'price',
  PriceX96 = 'priceX96',
  ProtocolFeeRate = 'protocolFeeRate',
  ReferralParentReturnFeeRate = 'referralParentReturnFeeRate',
  ReferralReturnFeeRate = 'referralReturnFeeRate'
}

export type User = {
  __typename?: 'User';
  /** user address */
  id: Scalars['ID'];
  /** latest transaction timestamp */
  latestTransactionTs: Scalars['BigInt'];
  userTransactionStatistics: Array<UserTransactionStatistic>;
};


export type UserUserTransactionStatisticsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserTransactionStatistic_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserTransactionStatistic_Filter>;
};

export type UserTransactionStatistic = {
  __typename?: 'UserTransactionStatistic';
  /** close position volume */
  closeVolumeUSD: Scalars['BigDecimal'];
  /** trading fee */
  fee: Scalars['BigDecimal'];
  /** opening time of the first position */
  firstOpentimestamp: Scalars['BigInt'];
  /** format: <account>:<pool> */
  id: Scalars['ID'];
  /** close time of the last position */
  lastClosetimestamp: Scalars['BigInt'];
  /** opening time of the last position */
  lastOpentimestamp: Scalars['BigInt'];
  /** liquidation position volume */
  liquidationVolumeUSD: Scalars['BigDecimal'];
  /** open position volume */
  openVolumeUSD: Scalars['BigDecimal'];
  /** pool address */
  pool: Pool;
  /** accumulated realized PnL of the position */
  realizedPnL: Scalars['BigDecimal'];
  /** number of transactions */
  transactionCount: Scalars['BigInt'];
  /** pointer to user */
  user: User;
  /** transaction volume */
  volumeUSD: Scalars['BigDecimal'];
};

export type UserTransactionStatistic_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<UserTransactionStatistic_Filter>>>;
  closeVolumeUSD?: InputMaybe<Scalars['BigDecimal']>;
  closeVolumeUSD_gt?: InputMaybe<Scalars['BigDecimal']>;
  closeVolumeUSD_gte?: InputMaybe<Scalars['BigDecimal']>;
  closeVolumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  closeVolumeUSD_lt?: InputMaybe<Scalars['BigDecimal']>;
  closeVolumeUSD_lte?: InputMaybe<Scalars['BigDecimal']>;
  closeVolumeUSD_not?: InputMaybe<Scalars['BigDecimal']>;
  closeVolumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fee?: InputMaybe<Scalars['BigDecimal']>;
  fee_gt?: InputMaybe<Scalars['BigDecimal']>;
  fee_gte?: InputMaybe<Scalars['BigDecimal']>;
  fee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fee_lt?: InputMaybe<Scalars['BigDecimal']>;
  fee_lte?: InputMaybe<Scalars['BigDecimal']>;
  fee_not?: InputMaybe<Scalars['BigDecimal']>;
  fee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  firstOpentimestamp?: InputMaybe<Scalars['BigInt']>;
  firstOpentimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  firstOpentimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  firstOpentimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  firstOpentimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  firstOpentimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  firstOpentimestamp_not?: InputMaybe<Scalars['BigInt']>;
  firstOpentimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  lastClosetimestamp?: InputMaybe<Scalars['BigInt']>;
  lastClosetimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  lastClosetimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  lastClosetimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  lastClosetimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  lastClosetimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  lastClosetimestamp_not?: InputMaybe<Scalars['BigInt']>;
  lastClosetimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  lastOpentimestamp?: InputMaybe<Scalars['BigInt']>;
  lastOpentimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  lastOpentimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  lastOpentimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  lastOpentimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  lastOpentimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  lastOpentimestamp_not?: InputMaybe<Scalars['BigInt']>;
  lastOpentimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  liquidationVolumeUSD?: InputMaybe<Scalars['BigDecimal']>;
  liquidationVolumeUSD_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidationVolumeUSD_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidationVolumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidationVolumeUSD_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidationVolumeUSD_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidationVolumeUSD_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidationVolumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  openVolumeUSD?: InputMaybe<Scalars['BigDecimal']>;
  openVolumeUSD_gt?: InputMaybe<Scalars['BigDecimal']>;
  openVolumeUSD_gte?: InputMaybe<Scalars['BigDecimal']>;
  openVolumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  openVolumeUSD_lt?: InputMaybe<Scalars['BigDecimal']>;
  openVolumeUSD_lte?: InputMaybe<Scalars['BigDecimal']>;
  openVolumeUSD_not?: InputMaybe<Scalars['BigDecimal']>;
  openVolumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<UserTransactionStatistic_Filter>>>;
  pool?: InputMaybe<Scalars['String']>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars['String']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_ends_with?: InputMaybe<Scalars['String']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_gt?: InputMaybe<Scalars['String']>;
  pool_gte?: InputMaybe<Scalars['String']>;
  pool_in?: InputMaybe<Array<Scalars['String']>>;
  pool_lt?: InputMaybe<Scalars['String']>;
  pool_lte?: InputMaybe<Scalars['String']>;
  pool_not?: InputMaybe<Scalars['String']>;
  pool_not_contains?: InputMaybe<Scalars['String']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_not_in?: InputMaybe<Array<Scalars['String']>>;
  pool_not_starts_with?: InputMaybe<Scalars['String']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pool_starts_with?: InputMaybe<Scalars['String']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']>;
  realizedPnL?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_gt?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_gte?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  realizedPnL_lt?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_lte?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_not?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  transactionCount?: InputMaybe<Scalars['BigInt']>;
  transactionCount_gt?: InputMaybe<Scalars['BigInt']>;
  transactionCount_gte?: InputMaybe<Scalars['BigInt']>;
  transactionCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionCount_lt?: InputMaybe<Scalars['BigInt']>;
  transactionCount_lte?: InputMaybe<Scalars['BigInt']>;
  transactionCount_not?: InputMaybe<Scalars['BigInt']>;
  transactionCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  user?: InputMaybe<Scalars['String']>;
  user_?: InputMaybe<User_Filter>;
  user_contains?: InputMaybe<Scalars['String']>;
  user_contains_nocase?: InputMaybe<Scalars['String']>;
  user_ends_with?: InputMaybe<Scalars['String']>;
  user_ends_with_nocase?: InputMaybe<Scalars['String']>;
  user_gt?: InputMaybe<Scalars['String']>;
  user_gte?: InputMaybe<Scalars['String']>;
  user_in?: InputMaybe<Array<Scalars['String']>>;
  user_lt?: InputMaybe<Scalars['String']>;
  user_lte?: InputMaybe<Scalars['String']>;
  user_not?: InputMaybe<Scalars['String']>;
  user_not_contains?: InputMaybe<Scalars['String']>;
  user_not_contains_nocase?: InputMaybe<Scalars['String']>;
  user_not_ends_with?: InputMaybe<Scalars['String']>;
  user_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  user_not_in?: InputMaybe<Array<Scalars['String']>>;
  user_not_starts_with?: InputMaybe<Scalars['String']>;
  user_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  user_starts_with?: InputMaybe<Scalars['String']>;
  user_starts_with_nocase?: InputMaybe<Scalars['String']>;
  volumeUSD?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_lt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum UserTransactionStatistic_OrderBy {
  CloseVolumeUsd = 'closeVolumeUSD',
  Fee = 'fee',
  FirstOpentimestamp = 'firstOpentimestamp',
  Id = 'id',
  LastClosetimestamp = 'lastClosetimestamp',
  LastOpentimestamp = 'lastOpentimestamp',
  LiquidationVolumeUsd = 'liquidationVolumeUSD',
  OpenVolumeUsd = 'openVolumeUSD',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolPairString = 'pool__pairString',
  PoolVolume = 'pool__volume',
  PoolVolumeUsd = 'pool__volumeUSD',
  RealizedPnL = 'realizedPnL',
  TransactionCount = 'transactionCount',
  User = 'user',
  UserId = 'user__id',
  UserLatestTransactionTs = 'user__latestTransactionTs',
  VolumeUsd = 'volumeUSD'
}

export type User_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<User_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  latestTransactionTs?: InputMaybe<Scalars['BigInt']>;
  latestTransactionTs_gt?: InputMaybe<Scalars['BigInt']>;
  latestTransactionTs_gte?: InputMaybe<Scalars['BigInt']>;
  latestTransactionTs_in?: InputMaybe<Array<Scalars['BigInt']>>;
  latestTransactionTs_lt?: InputMaybe<Scalars['BigInt']>;
  latestTransactionTs_lte?: InputMaybe<Scalars['BigInt']>;
  latestTransactionTs_not?: InputMaybe<Scalars['BigInt']>;
  latestTransactionTs_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  or?: InputMaybe<Array<InputMaybe<User_Filter>>>;
  userTransactionStatistics_?: InputMaybe<UserTransactionStatistic_Filter>;
};

export enum User_OrderBy {
  Id = 'id',
  LatestTransactionTs = 'latestTransactionTs',
  UserTransactionStatistics = 'userTransactionStatistics'
}

export type _Block_ = {
  __typename?: '_Block_';
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>;
  /** The block number */
  number: Scalars['Int'];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: '_Meta_';
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean'];
};

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = 'allow',
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = 'deny'
}

export type UserTransactionQueryQueryVariables = Exact<{
  account_in?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  number: Scalars['Int'];
}>;


export type UserTransactionQueryQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, userTransactionStatistics: Array<{ __typename?: 'UserTransactionStatistic', volumeUSD: any }> }> };


export const UserTransactionQueryDocument = gql`
    query UserTransactionQuery($account_in: [ID!], $number: Int!) {
  users(where: {id_in: $account_in}, first: 1000, block: {number: $number}) {
    id
    userTransactionStatistics {
      volumeUSD
    }
  }
}
    `;

/**
 * __useUserTransactionQueryQuery__
 *
 * To run a query within a React component, call `useUserTransactionQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTransactionQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTransactionQueryQuery({
 *   variables: {
 *      account_in: // value for 'account_in'
 *      number: // value for 'number'
 *   },
 * });
 */
export function useUserTransactionQueryQuery(baseOptions: Apollo.QueryHookOptions<UserTransactionQueryQuery, UserTransactionQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTransactionQueryQuery, UserTransactionQueryQueryVariables>(UserTransactionQueryDocument, options);
      }
export function useUserTransactionQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTransactionQueryQuery, UserTransactionQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTransactionQueryQuery, UserTransactionQueryQueryVariables>(UserTransactionQueryDocument, options);
        }
export type UserTransactionQueryQueryHookResult = ReturnType<typeof useUserTransactionQueryQuery>;
export type UserTransactionQueryLazyQueryHookResult = ReturnType<typeof useUserTransactionQueryLazyQuery>;
export type UserTransactionQueryQueryResult = Apollo.QueryResult<UserTransactionQueryQuery, UserTransactionQueryQueryVariables>;