import React, { useEffect, useMemo, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import ArrowDownIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';

import { useReferralCodeRefereesGraph } from 'graphql/useReferralCodeRefereesGraph';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { sortBy } from 'lodash';
import type { Merge } from 'type-fest';
import {
	ExplorerDataType,
	formatNumber,
	getExplorerLink,
	isEqualTo,
	isGreaterThan,
	plus,
	shortenAddress,
	shortenSymbolNative,
	toQuoteAmount
} from 'utils';

import Dialog, { DialogProps } from 'components/Common/Dialog';
import StyledLink from 'components/Common/StyledLink';
import NoData from 'components/NoData';
import SectionLoading from 'components/SectionLoading';

import { QUOTE_USD, Side } from '../../../config/constants';
import { useApolloClients } from '../../../context/ApolloClientsContext';
import { useUserTransactionQueryQuery } from '../../../graphql/__generated_stats__/types-and-hooks';
import usePositionList from '../../../graphql/usePositionList';
import { useAllTokens } from '../../../hooks/useAllTokens';
import { globalBaseState } from '../../../state/global/slice';
import { useAppSelector } from '../../../state/hooks';

const MemberInvationDialog = (props: {
	onClose: () => void;
	address: string[];
	blockNumber: number;
}) => {
	const { onClose, address, blockNumber } = props;

	const { isMatchMobile } = useAppBreakpoints();
	const { currentChain: chain } = useCurrentChain();
	const theme = useTheme();
	const { currentChainId } = useCurrentChain();
	const allTokens = useAllTokens();
	const { clientStats } = useApolloClients();

	const { positionMap, loading: positionLoading } = usePositionList(
		address,
		blockNumber
	);
	const { data: userData, loading: userLoading } = useUserTransactionQueryQuery(
		{
			variables: {
				account_in: address,
				number: Number(blockNumber)
			},
			client: clientStats
		}
	);
	const userDataMap = useMemo(() => {
		const map = new Map();
		if (!userData) {
			return map;
		}
		userData.users.forEach(user => {
			map.set(user.id, user.userTransactionStatistics);
		});
		return map;
	}, [userData]);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		if (!positionLoading && !userLoading) {
			setLoading(false);
		}
	}, [positionLoading, userLoading]);

	const bgColor =
		theme.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)';

	type Position = (typeof positionMap extends Map<any, infer V>
		? V
		: never)[number];
	const addressWithPositionValue = useMemo(
		() =>
			address
				.map(item => {
					const positionList = positionMap.get(item) || [];
					const userTransactionStatistics = userDataMap.get(item) || [];
					const vol = userTransactionStatistics.reduce(
						(prev, cur) => plus(prev, cur.volumeUSD),
						'0'
					);

					const positionValue = positionList.reduce(
						(prev, cur) => plus(prev, cur.liquidity),
						'0'
					);
					return {
						id: item,
						positionValue,
						vol
					};
				})
				.sort((a, b) => {
					if (!isEqualTo(a.positionValue, b.positionValue)) {
						return isGreaterThan(a.positionValue, b.positionValue) ? -1 : 1;
					}
					return isGreaterThan(a.vol, b.vol) ? -1 : 1;
				}),
		[positionMap, userDataMap]
	);

	const [expandId, setExpandId] = useState<string | null>(null);
	useEffect(() => {
		if (addressWithPositionValue.length > 0) {
			setExpandId(addressWithPositionValue[0].id);
		}
	}, [addressWithPositionValue]);

	const outerColumns = [
		{
			key: 'address',
			label: <Trans>Address</Trans>,
			className: 'flex-2 flex items-center',
			format: (row: (typeof addressWithPositionValue)[number]) => {
				return (
					<StyledLink
						target='_blank'
						href={getExplorerLink(
							chain,
							row.id || '',
							ExplorerDataType.ADDRESS
						)}
						className='underline'
						type='white'
					>
						{shortenAddress(row.id)}
					</StyledLink>
				);
			}
		},
		{
			key: 'vol',
			className: 'flex justify-end items-center text-right flex-3',
			label: <Trans>Total Trading Volume</Trans>,
			format: (row: (typeof addressWithPositionValue)[number]) => {
				return (
					<Typography variant='body2'>{toQuoteAmount(row.vol)}</Typography>
				);
			}
		},
		{
			key: 'value',
			className: 'flex justify-end items-center text-right flex-3',
			label: <Trans>Total Position Value</Trans>,
			format: (row: (typeof addressWithPositionValue)[number]) => {
				const isExpand = expandId === row.id;
				return (
					<div className='flex items-center'>
						<Typography variant='body2'>
							{toQuoteAmount(row.positionValue)}
						</Typography>
						<Typography color='text.secondary' component='span'>
							<ArrowDownIcon
								className={classNames(isExpand ? 'rotate-180' : 'rotate-0')}
							/>
						</Typography>
					</div>
				);
			}
		}
	];
	const innerColumns = [
		{
			key: 'market',
			width: '30%',
			label: <Trans>Market</Trans>,
			className: 'flex-1.5',
			format: (row: Position) => {
				const token = allTokens.get(row.pool.token.id);
				return (
					<Typography variant='body2'>
						{`${shortenSymbolNative(token, currentChainId)}/${QUOTE_USD}`}
					</Typography>
				);
			}
		},
		{
			key: 'side',
			width: '30%',
			label: <Trans>Side</Trans>,
			className: 'flex-1.5',
			format: (row: Position) => {
				return (
					<Typography
						variant='body2'
						color={row.side === Side.SHORT ? 'error.main' : 'success.main'}
					>
						{row.side === Side.SHORT ? t`Short` : t`Long`}
					</Typography>
				);
			}
		},
		{
			key: 'size',
			width: '30%',
			label: <Trans>Size</Trans>,
			className: 'flex-1.5',
			format: (row: Position) => {
				const token = allTokens.get(row.pool.token.id);
				return (
					<Typography variant='body2'>
						{formatNumber(row.size, token.positionUnits)}{' '}
						{shortenSymbolNative(token, currentChainId)}
					</Typography>
				);
			}
		},
		{
			key: 'coefficient',
			width: '30%',
			label: <Trans>Coefficient</Trans>,
			className: 'flex flex-1 justify-end',
			format: (row: Position) => {
				return (
					<Typography variant='body2'>
						{`${formatNumber(row.coefficient, 2, true)}x`}
					</Typography>
				);
			}
		}
	];

	return (
		<Dialog
			className='px-0 sm:px-3'
			open
			width={600}
			height={isMatchMobile ? 300 : 'auto'}
			onClose={onClose}
			title={t`Referral Details`}
		>
			{loading && <SectionLoading />}
			{!loading &&
				(addressWithPositionValue && addressWithPositionValue.length > 0 ? (
					<div className='px-6 sm:px-0'>
						<div className='mb-3 flex gap-4'>
							{outerColumns.map(column => (
								<div className={column.className} key={column.key}>
									<Typography
										color={theme.palette.text.secondary}
										variant='body2'
										component='div'
									>
										{column.label}
									</Typography>
								</div>
							))}
						</div>
						<Box className='space-y-2'>
							{addressWithPositionValue.map(item => {
								const positionList = sortBy(
									positionMap.get(item.id),
									item => item.token.sort
								);
								return (
									<div key={item.id}>
										<Box
											className='flex cursor-pointer gap-4 p-3'
											sx={{
												background: bgColor,
												borderRadius: '4px'
											}}
											onClick={_ => setExpandId(item.id)}
										>
											{outerColumns.map(column => (
												<div className={column.className} key={column.key}>
													<Typography variant='body2' component='div'>
														{column.format(item)}
													</Typography>
												</div>
											))}
										</Box>
										{expandId === item.id && (
											<Box
												className='p-3 mt-1 space-y-2'
												sx={{
													background: bgColor,
													borderRadius: '0 0 4px 4px'
												}}
											>
												{positionList.length !== 0 ? (
													<>
														<div className='flex'>
															{innerColumns.map(column => (
																<Typography
																	key={column.key}
																	className={column.className}
																	color={theme.palette.text.secondary}
																	variant='body2'
																	component='div'
																>
																	{column.label}
																</Typography>
															))}
														</div>
														{positionList.map(item => (
															<div
																className='flex'
																key={`${item.pool.id}-${item.side}`}
															>
																{innerColumns.map(column => (
																	<Typography
																		key={column.key}
																		className={column.className}
																		variant='body2'
																		component='div'
																	>
																		{column.format(item)}
																	</Typography>
																))}
															</div>
														))}
													</>
												) : (
													<NoData>
														<Trans>No open positions</Trans>
													</NoData>
												)}
											</Box>
										)}
									</div>
								);
							})}
						</Box>
					</div>
				) : (
					<NoData size='large'>
						<Trans>Your referees currently have no positions yet.</Trans>
					</NoData>
				))}
		</Dialog>
	);
};

export default MemberInvationDialog;
