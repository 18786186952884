import { useMemo } from 'react';

import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import Minus from 'components/Svg/Icons/Minus';

import {
	abs,
	isEqualTo,
	isNumeric,
	isPositive,
	toDecimalPlaces,
	toPercent
} from '../../utils';

export default function PriceChange({
	value,
	size = 12
}: {
	value: string | undefined;
	size?: number | undefined;
}) {
	return useMemo(() => {
		if (!value || !isNumeric(value)) {
			return (
				<Typography
					variant='body2'
					fontFamily='inherit'
					sx={{ fontSize: `${size}px !important` }}
				>
					-
				</Typography>
			);
		}
		const _value = toDecimalPlaces(value, 4);
		if (isPositive(_value)) {
			return (
				<Typography
					fontFamily='inherit'
					fontWeight={500}
					color='success.main'
					sx={{ fontSize: `${size}px !important` }}
				>
					{`+${toPercent(_value)}`}
				</Typography>
			);
		} else if (isEqualTo(_value, 0)) {
			return (
				<Typography
					fontFamily='inherit'
					fontWeight={500}
					sx={{ fontSize: `${size}px !important`, color: 'inherit' }}
				>
					{toPercent(_value)}
				</Typography>
			);
		} else {
			return (
				<Typography
					className='flex items-center space-x-0.25'
					fontFamily='inherit'
					color='error.main'
					fontWeight={500}
					sx={{ fontSize: `${size}px !important` }}
				>
					<Minus />
					<span>{`${toPercent(abs(_value))}`}</span>
				</Typography>
			);
		}
	}, [value]);
}

export const StyledPriceDirection = styled(Typography)`
	width: 100px;
	color: inherit;
	&.up {
		color: ${({ theme }) => theme.palette.success.main};
	}
	&.down {
		color: ${({ theme }) => theme.palette.error.main};
	}
`;

export const StyledPriceChangeContainer = styled<any>('div')`
	padding-top: 6px;
	padding-bottom: 6px;
	font-size: 14px;
	line-height: 1.5;
	border-radius: 4px;
	width: 80px;
	text-align: center;
	font-weight: 600;
	color: ${({ theme }) => theme.palette.common.white};
	background: ${({ value, theme }) =>
		value > 0 ? theme.palette.success.main : theme.palette.error.main};
`;
