import { useEffect, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans, t } from '@lingui/macro';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import {
	APP_TOKEN_EMISSIONS_ONE_DAY_STAGE_1,
	APP_TOKEN_EMISSIONS_ONE_DAY_STAGE_2,
	APP_TOKEN_EMISSIONS_ONE_DAY_STAGE_3,
	BLACK_ADDRESS,
	EMISSIONS_START_TIME_STAGE1,
	EMISSIONS_START_TIME_STAGE2,
	EMISSIONS_START_TIME_STAGE3
} from 'config/constants';
import Decimal from 'decimal.js';
import moment from 'moment';

import { useUniV3SwapLink } from 'hooks/V3/useUniV3LiquidityLink';
import { useAddTokenToWallet } from 'hooks/useAddTokenToWallet';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import { useTokenBurnedBalance, useTokenSupply } from 'hooks/useTokenSupply';
import { toNumber } from 'lodash';
import { ModuleCard } from 'pages/components/ModuleCard';
import { earnStakeBaseState } from 'state/earn/stake/slice';
import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import {
	ExplorerDataType,
	div,
	formatNumber,
	getExplorerLink,
	plus,
	shortenSymbol,
	toUsd
} from 'utils';

import Cell from 'components/Common/Cell';
import { CommonStyledOutlinedButton } from 'components/Common/Styled';
import Description from 'components/Description';
import Stronger from 'components/Stronger';
import TokenLogo from 'components/Svg/Icons/TokenLogo';

import TippingUnderline from '../../components/TippingUnderline';
import { getServerTimeStamp } from '../../fetch/useRequest';

export default function SummaryEQU() {
	const { appTimer } = useAppSelector(globalBaseState);
	const { appToken, appTokenUsdPrice, nativeSymbol } =
		useAppSelector(txBaseState);
	const { stakeStatistic } = useAppSelector(earnStakeBaseState);

	const { isMatchMobile, isMatchPad } = useAppBreakpoints();
	const uniV3SwapLink = useUniV3SwapLink();
	const { onSubmit } = useAddTokenToWallet();
	const { currentChainId, currentChain } = useCurrentChain();
	const theme = useTheme();

	const isLogin = useCheckLogin();

	const { totalSupply, refetchTotalSupply } = useTokenSupply(appToken);

	const { burnedBalance, refetchBurnedBalance } =
		useTokenBurnedBalance(appToken);

	const totalStaked = useMemo(() => {
		if (!stakeStatistic) {
			return '';
		}
		const totalStaked = plus(
			stakeStatistic.equStakedAmount,
			stakeStatistic.liquidityStakedAmount
		);
		return totalStaked;
	}, [stakeStatistic]);

	useUpdateEffect(() => {
		refetchTotalSupply();
		refetchBurnedBalance();
	}, [currentChainId, appTimer]);

	const [emissions, setEmissions] = useState(0);
	const [serverTime, setServerTime] = useState(0);
	const { data, refetch } = getServerTimeStamp();
	useEffect(() => {
		if (data?.server_time_unix) {
			setServerTime(data.server_time_unix * 1000);
		}
	}, [data]);
	useEffect(() => {
		let timer;
		if (serverTime !== 0) {
			const countPerSecondStage1 = div(
				APP_TOKEN_EMISSIONS_ONE_DAY_STAGE_1,
				24 * 60 * 60
			);
			const countPerSecondStage2 = div(
				APP_TOKEN_EMISSIONS_ONE_DAY_STAGE_2,
				24 * 60 * 60
			);
			const countPerSecondStage3 = div(
				APP_TOKEN_EMISSIONS_ONE_DAY_STAGE_3,
				24 * 60 * 60
			);
			const getEmissions = () => {
				const Stage1 = new Decimal(countPerSecondStage1)
					.mul(
						moment
							.unix(EMISSIONS_START_TIME_STAGE2)
							.diff(moment.unix(EMISSIONS_START_TIME_STAGE1), 'seconds')
					)
					.toNumber();
				const Stage2 = new Decimal(countPerSecondStage2)
					.mul(
						moment
							.unix(EMISSIONS_START_TIME_STAGE3)
							.diff(moment.unix(EMISSIONS_START_TIME_STAGE2), 'seconds')
					)
					.toNumber();
				const Stage3 = new Decimal(countPerSecondStage3)
					.mul(
						moment(serverTime).diff(
							moment.unix(EMISSIONS_START_TIME_STAGE3),
							'seconds'
						)
					)
					.toNumber();
				const emissionsStage = toNumber(plus(plus(Stage1, Stage2), Stage3));
				setEmissions(emissionsStage);
			};
			getEmissions();
			timer = setInterval(() => {
				refetch().then(getEmissions);
				clearInterval(timer);
			}, 5 * 60 * 1000);
		}
		return () => clearInterval(timer);
	}, [serverTime]);

	return (
		<ModuleCard
			checkLogin={false}
			title={
				<div className='flex space-x-1'>
					<Typography variant='inherit' fontWeight='inherit'>
						<Trans>{shortenSymbol(appToken)} Statistics</Trans>
					</Typography>
				</div>
			}
			extra={
				<div className='flex space-x-2'>
					{isMatchMobile ? (
						<CommonStyledOutlinedButton
							variant='outlined'
							color='secondary'
							href={uniV3SwapLink}
							startIcon={<TokenLogo size={24} />}
						>
							{toUsd(appTokenUsdPrice, 4)}
						</CommonStyledOutlinedButton>
					) : (
						<CommonStyledOutlinedButton
							variant='outlined'
							color='secondary'
							href={uniV3SwapLink}
							target='_blank'
						>
							<Trans>Buy</Trans>
						</CommonStyledOutlinedButton>
					)}

					{isLogin && (
						<CommonStyledOutlinedButton
							variant='outlined'
							color='secondary'
							onClick={() => onSubmit(appToken)}
						>
							<Trans>Add to wallet</Trans>
						</CommonStyledOutlinedButton>
					)}
				</div>
			}
		>
			<div className='flex justify-between sm:grid sm:grid-cols-2 gap-2 sm:gap-4'>
				<Description
					label={<Trans>Total Emissions</Trans>}
					vertical={true}
					align={isMatchMobile ? 'left' : 'left'}
				>
					<Typography
						fontSize={isMatchMobile || isMatchPad ? 16 : 20}
						variant='h4'
					>
						{`${formatNumber(emissions)}`}
					</Typography>
				</Description>
				{/* 第2列 */}
				<Description
					label={<Trans>Total Claimed</Trans>}
					vertical={true}
					align={isMatchMobile ? 'right' : 'center'}
				>
					<Typography
						fontSize={isMatchMobile || isMatchPad ? 16 : 20}
						variant='h4'
					>
						{`${formatNumber(totalSupply)}`}
					</Typography>
				</Description>
				{/* 第3列 */}
				<Description
					label={<Trans>Total Burned</Trans>}
					vertical={true}
					align={isMatchMobile ? 'left' : 'center'}
				>
					<Typography
						className='leading-8'
						fontSize={isMatchMobile || isMatchPad ? 16 : 20}
						variant='h4'
						component='a'
						target='_blank'
						href={getExplorerLink(
							currentChain,
							`${appToken?.address}?a=${BLACK_ADDRESS}`,
							ExplorerDataType.TOKEN
						)}
						sx={{
							'&:hover': {
								color: theme.palette.primary.main
							}
						}}
					>
						{`${formatNumber(burnedBalance)}`}
					</Typography>
				</Description>
				{/* 第4列 */}
				<Description
					label={
						<div className='flex space-x-0.5 justify-center sm:justify-end items-center'>
							<TippingUnderline
								tooltip={
									<Typography variant='body2'>
										<Trans>
											The calculated figure may slightly differ from the actual
											amount because the {shortenSymbol(appToken)} in the{' '}
											{shortenSymbol(appToken)}/{nativeSymbol} LP NFT is based
											on the {shortenSymbol(appToken)} quantity in the LP Token
											at the time of staking.
										</Trans>
									</Typography>
								}
								label={
									<Typography variant='body2' color='secondary'>
										<Trans>Total Staked</Trans>
									</Typography>
								}
							/>
						</div>
					}
					vertical={true}
					align={isMatchMobile ? 'right' : 'center'}
				>
					<div
						className={classNames(
							'flex',
							isMatchMobile ? 'justify-end' : 'justify-center'
						)}
					>
						<Stronger
							value={
								<Typography
									fontSize={isMatchMobile || isMatchPad ? 16 : 20}
									variant='h4'
									align='right'
								>
									{`${formatNumber(totalStaked)}`}
								</Typography>
							}
							tooltip={
								<div>
									<Typography className='mb-2' align='inherit' variant='body2'>
										<Trans>
											Total Staked = Staked {shortenSymbol(appToken)} in{' '}
											{shortenSymbol(appToken)}/{nativeSymbol} LP NFT + Staked{' '}
											{shortenSymbol(appToken)}
										</Trans>
									</Typography>
									<Cell
										label={
											<Trans>
												Staked {shortenSymbol(appToken)} in{' '}
												{shortenSymbol(appToken)}/{nativeSymbol} LP NFT
											</Trans>
										}
										value={`${formatNumber(
											stakeStatistic?.liquidityStakedAmount,
											appToken?.positionUnits
										)}`}
									/>

									<Cell
										label={<Trans>Staked {shortenSymbol(appToken)}</Trans>}
										value={`${formatNumber(
											stakeStatistic?.equStakedAmount,
											appToken?.positionUnits
										)}`}
									/>
								</div>
							}
						/>
					</div>
				</Description>

				{/* 第5列 */}
				<Description
					label={<Trans>Current Emissions</Trans>}
					vertical={true}
					align={isMatchMobile ? 'left' : 'right'}
				>
					<Typography
						fontSize={isMatchMobile || isMatchPad ? 16 : 20}
						variant='h4'
					>
						{`${formatNumber(
							APP_TOKEN_EMISSIONS_ONE_DAY_STAGE_3,
							0
						)} / ${t`day`}`}
					</Typography>
				</Description>
				{/* <Description
					label={
						<div
							className={classNames(
								'flex space-x-1 items-center -mr-0.5',
								isMatchMobile ? ' justify-start' : ' justify-center'
							)}
						>
							<TippingUnderline
								tooltip={
									<Typography variant='body2' className='w-[280px]'>
										<Trans>
											The current price is calculated based on Uniswap{' '}
											{shortenSymbol(appToken)}/{nativeSymbol}{' '}
											{formattedFeeAmount(DEFAULT_UNI_POOL_FEE_AMOUNT)}% pool.
										</Trans>
									</Typography>
								}
								label={<Trans>Current Price</Trans>}
							/>
						</div>
					}
					vertical={true}
					align={isMatchMobile ? 'left' : 'right'}
				>
					<Typography
						fontSize={isMatchMobile || isMatchPad ? 16 : 20}
						variant='h4'
						color='text.primary'
					>
						{toUsd(appTokenUsdPrice, 4)}
					</Typography>
				</Description> */}
			</div>
		</ModuleCard>
	);
}
