import { useMemo } from 'react';

import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';

import { arbitrum, arbitrumGoerli } from 'config/constants/connector/chains';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { Fee_Amount } from './types';

const UNI_SWAP_LINK: Record<number, string> = {
	[arbitrumGoerli.id]: 'https://app.uniswap.org/swap',
	[arbitrum.id]: 'https://app.uniswap.org/tokens/arbitrum/0x87aaffdf26c6885f6010219208d5b161ec7609c0?chain=arbitrum'
};

export function useUniV3LiquidityLink() {
	const { appToken } = useAppSelector(txBaseState);

	return useMemo(() => {
		return `https://app.uniswap.org/add/ETH/${appToken?.address}/${Fee_Amount.HIGH}?minPrice=0.0000000000000000000000000000000000000029543&maxPrice=338490000000000000000000000000000000000`;
	}, [appToken]);
}

export function useUniV3SwapLink() {
	const { appToken } = useAppSelector(txBaseState);
	const { currentChainId } = useCurrentChain();

	return useMemo(() => {
		return UNI_SWAP_LINK[currentChainId];
	}, [appToken, currentChainId]);
}