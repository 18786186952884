import { useEffect, useMemo, useState } from 'react';

import { useApolloClients } from 'context/ApolloClientsContext';

import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import { minus } from 'utils';

import { useStakeStatisticQuery } from './__generated_referral__/types-and-hooks';

export interface IStakeStatistic {
	architectPerShareGrowthX64: string;
	architectReward: string;
	equStakedAmount: string;
	id: string;
	liquidityStakedAmount: string;
	multipliers: Array<number>;
	perShareGrowthX64: string;
	periods: Array<number>;
	stakeReward: string;
	stakeReward24h: string;
	liquidityStakedAmountWithMultiplier: string;
	equStakedAmountWithMultiplier: string;
}

export function useStakeStatisticGraph() {
	const { clientReferral } = useApolloClients();
	const { blockHeightBefore24h } = useAppSelector(globalBaseState);

	const [loading, setLoading] = useState<boolean>(true);

	const {
		data,
		loading: isLoading,
		refetch
	} = useStakeStatisticQuery({
		variables: { blockBefore: Number(blockHeightBefore24h) },
		client: clientReferral
	});

	useEffect(() => {
		if (!isLoading) {
			setLoading(isLoading);
		}
	}, [isLoading]);

	return useMemo(() => {
		if (!data || !data.stakeStatistic) {
			return {
				data: null,
				loading,
				refetch
			};
		}

		const stakeReward24h = minus(
			data.stakeStatistic.stakeReward,
			data.stakeStatisticBefore?.stakeReward ?? '0'
		);

		const _data = {
			...data.stakeStatistic,
			stakeReward24h
		} as IStakeStatistic;

		return {
			loading,
			data: _data,
			refetch
		};
	}, [data, loading, refetch]);
}
