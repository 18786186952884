import React, { useCallback, useMemo, useState } from 'react';

import { Trans } from '@lingui/macro';
import { Card, CardContent, Typography } from '@mui/material';
import {
	LEVERAGE_MAKERS,
	MARK_POINTS,
	MAX_PRECISION,
	QUOTE_USD
} from 'config/constants';

import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import { useListenLiqudityAmount } from 'hooks/useListenLiquidityAmount';
import { globalBaseState } from 'state/global/slice';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { liquidityBaseState } from 'state/liquidity/slice';
import { poolsBaseState } from 'state/pools/slice';
import {
	setCheckedLiquidityLeverage,
	setLiquidityLeverage,
	settingBaseState
} from 'state/setting/slice';
import { selectQuoteBalanceText } from 'state/tx/selector';
import { txBaseState } from 'state/tx/slice';
import {
	computeLeverageMarks,
	div,
	formatLeverage,
	formatNumber,
	multipliedBy,
	shortenSymbol,
	toPercent,
	toQuoteAmount,
	toUsd
} from 'utils';

import Cell from 'components/Common/Cell';
import { StyleCheckbox } from 'components/Common/StyleCheckbox';
import {
	CommonStyledArticle,
	CommonStyledBaseInput,
	CommonStyledMaxButton
} from 'components/Common/Styled';
import LeverageListener from 'components/LeverageListener';
import MarksSlider from 'components/MarksSlider';

import LiquidityButton from './LiquidityButton';

export default React.memo(function LiquidityForm() {
	const dispatch = useAppDispatch();

	const { quoteToken } = useAppSelector(txBaseState);
	const { positionMinExecutionFee, nativePriceUsd } =
		useAppSelector(globalBaseState);
	const { quoteAmount, liquidityAmount, leverage } =
		useAppSelector(liquidityBaseState);
	const { checkedLiquidityLeverage } = useAppSelector(settingBaseState);
	const { poolInfo } = useAppSelector(poolsBaseState);

	const quoteBalanceText = useAppSelector(selectQuoteBalanceText);

	const { currentChain } = useCurrentChain();

	const isLogin = useCheckLogin();

	const leverageMarks = useMemo(() => {
		if (!poolInfo) {
			return LEVERAGE_MAKERS;
		}
		return computeLeverageMarks(
			Number(poolInfo.maxLeveragePerLiquidityPosition)
		);
	}, [poolInfo]);

	const {
		hasMaxButton,
		onHandleChangeMarginDelta,
		onHandleChangeLiquidityAmount,
		onHandleSetMaxMarginDelta
	} = useListenLiqudityAmount();

	const onChangeLeverage = (value: number | number[]) => {
		dispatch(setLiquidityLeverage(value));
	};

	const onClickLabel = (value: number) => {
		dispatch(setLiquidityLeverage(value));
	};

	const onHandleChangeCheckedLeverage = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			dispatch(setCheckedLiquidityLeverage(event.target.checked));
		},
		[]
	);

	const [activeLeverage, setActiveLeverage] = useState<boolean>(false);

	const onChangeLeverageInput = useCallback((value: string) => {
		dispatch(setLiquidityLeverage(value));
	}, []);

	return (
		<CommonStyledArticle className='space-y-3'>
			<div className='space-y-3'>
				<Card>
					<CardContent>
						<div className='flex justify-between py-1'>
							<Typography variant='body1' color='secondary'>
								<Trans>Margin</Trans>
							</Typography>
							{isLogin && (
								<div className='flex item-baseline space-x-1'>
									<Typography variant='body2' color='secondary'>
										<Trans>Balance:</Trans>
									</Typography>
									<Typography variant='body2'>{quoteBalanceText}</Typography>
								</div>
							)}
						</div>

						<CommonStyledBaseInput
							type='text'
							inputProps={{ maxLength: MAX_PRECISION, inputMode: 'decimal' }}
							className='input'
							value={quoteAmount}
							placeholder='0.0'
							onChange={onHandleChangeMarginDelta}
							fullWidth
							endAdornment={
								<div className='flex items-center space-x-2'>
									{hasMaxButton && (
										<CommonStyledMaxButton
											variant='outlined'
											color='secondary'
											onClick={() => onHandleSetMaxMarginDelta()}
										>
											<Trans>Max</Trans>
										</CommonStyledMaxButton>
									)}
									<Typography className='font-normal' variant='h5'>
										{shortenSymbol(quoteToken)}
									</Typography>
								</div>
							}
							sx={{
								'& .MuiInputBase-input': {
									fontSize: 20
								}
							}}
						/>
					</CardContent>
				</Card>
				<Card>
					<CardContent>
						<div className='flex justify-between'>
							<Typography variant='body1' color='secondary'>
								<Trans>Liquidity</Trans>
							</Typography>
							{checkedLiquidityLeverage && (
								<LeverageListener
									active={activeLeverage}
									leverage={leverage}
									maxLeverage={poolInfo?.maxLeveragePerLiquidityPosition}
									onChangeLeverage={onChangeLeverageInput}
									onToggleActive={(value: boolean) => {
										setActiveLeverage(value);
									}}
								/>
							)}
						</div>
						<CommonStyledBaseInput
							type='text'
							inputProps={{ maxLength: MAX_PRECISION, inputMode: 'decimal' }}
							className='input'
							value={liquidityAmount}
							placeholder='0.0'
							onChange={onHandleChangeLiquidityAmount}
							fullWidth
							endAdornment={
								<Typography className='font-normal' variant='h5'>
									{QUOTE_USD}
								</Typography>
							}
							sx={{
								'& .MuiInputBase-input': {
									fontSize: 20
								}
							}}
						/>
					</CardContent>
				</Card>
			</div>

			<div>
				<div className={checkedLiquidityLeverage ? '-mb-1' : '-mb-2'}>
					<Cell
						label={<Trans>Leverage Slider</Trans>}
						value={
							<StyleCheckbox
								checked={checkedLiquidityLeverage}
								size='small'
								className='-mr-1.5'
								onChange={onHandleChangeCheckedLeverage}
							/>
						}
					/>
				</div>
				{poolInfo && checkedLiquidityLeverage && (
					<MarksSlider
						leverage={leverage}
						marks={leverageMarks}
						max={Number(poolInfo.maxLeveragePerLiquidityPosition)}
						onChangeLeverage={onChangeLeverage}
						onClickLabel={onClickLabel}
					/>
				)}
			</div>
			<div>
				{!checkedLiquidityLeverage && (
					<Cell
						label={<Trans>Leverage</Trans>}
						value={leverage ? formatLeverage(leverage) : '-'}
					/>
				)}
				<Cell
					label={<Trans>Liquidity</Trans>}
					value={toQuoteAmount(liquidityAmount)}
				/>
				<Cell
					label={<Trans>Margin</Trans>}
					value={toQuoteAmount(quoteAmount)}
				/>
				<Cell
					label={<Trans>Margin Ratio</Trans>}
					value={
						Number(liquidityAmount)
							? toPercent(div(quoteAmount || '0', liquidityAmount))
							: '-'
					}
				/>
				<Cell
					label={<Trans>Execution Fee</Trans>}
					value={`${formatNumber(-positionMinExecutionFee, 5)} ${
						currentChain.nativeCurrency.symbol
					} (${toUsd(multipliedBy(nativePriceUsd, -positionMinExecutionFee))})`}
				/>
			</div>
			<LiquidityButton />
		</CommonStyledArticle>
	);
});
