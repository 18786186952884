import React, { useCallback } from 'react';

import { Trans } from '@lingui/macro';
import {
	Alert,
	AlertProps,
	Button,
	SnackbarContent,
	SnackbarContentProps,
	Typography,
	styled,
	useTheme
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { Hash } from '@wagmi/core';
import { App_Theme, Transaction_Status } from 'config/constants';
import { CustomContentProps, useSnackbar } from 'notistack';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { globalBaseState } from 'state/global/slice';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setTxVisibleError, setTxVisibleSuccess } from 'state/tx/slice';
import { ExplorerDataType, getExplorerLink } from 'utils';

import ArrowOutward from 'components/Svg/Icons/ArrowOutward';
import Fail from 'components/Svg/Icons/Fail';
import Success from 'components/Svg/Icons/Success';

export const StyledSnackbarContent = styled(
	SnackbarContent
)<SnackbarContentProps>(({ theme }) => {
	const { isMatchMobile } = useAppBreakpoints();
	return {
		backgroundColor:
			theme.palette.mode === App_Theme.dark
				? theme.custom.cardBg
				: theme.custom.positiveColor,
		color: theme.palette.text.primary,
		borderRadius: '8px',
		maxWidth: '400px !important',
		boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
		position: isMatchMobile ? 'static' : 'relative',
	};
});

export const StyledAlert = styled(Alert)<AlertProps>(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === App_Theme.dark
			? theme.custom.cardBg
			: theme.custom.positiveColor,
	color: theme.palette.text.primary,
	padding: '0 24px 0 0',
	'& .MuiSvgIcon-root': {
		width: 24,
		height: 24
	},
	'& .MuiAlert-icon': {
		marginRight: 8,
		marginTop: -4
	},
	'& .MuiAlert-action': {
		position: 'absolute',
		top: 0,
		right: theme.spacing(1)
	},
	'& .MuiAlert-message': {
		overflow: 'initial'
	}
}));

interface TransactionSnackbarProps extends CustomContentProps {
	description?: string;
	txHash?: Hash | undefined;
	type: string;
	more?: string;
}

const TransactionSnackbar = React.forwardRef<
	HTMLDivElement,
	TransactionSnackbarProps
>(({ id, description, txHash, type, more }, ref) => {
	const dispatch = useAppDispatch();
	const [isMore, setIsMore] = React.useState(false);
	const { chain } = useAppSelector(globalBaseState);

	const { closeSnackbar } = useSnackbar();
	const theme = useTheme();

	const onHandleDismiss = useCallback(() => {
		closeSnackbar(id);
		if (type === Transaction_Status.Success) {
			dispatch(setTxVisibleSuccess(false));
		}
		if (type === Transaction_Status.Error) {
			dispatch(setTxVisibleError(false));
		}
	}, [id, closeSnackbar, type]);
	const onHandleMore = () => {
		setIsMore(!isMore);
	};
	return (
		<StyledSnackbarContent
			ref={ref}
			message={
				<StyledAlert
					icon={type === Transaction_Status.Success ? <Success /> : <Fail />}
					onClose={onHandleDismiss}
					severity='error'
					className='items-center'
				>
					<Typography
						variant='body2'
						sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
						className='tracking-tight'
					>
						{description}
					</Typography>
					{txHash && (
						<Button
							className='px-0'
							variant='text'
							sx={{
								color: theme.palette.primary.main,
								'&:hover': { background: 'transparent' }
							}}
							size='small'
							href={getExplorerLink(
								chain,
								txHash,
								ExplorerDataType.TRANSACTION
							)}
							target='_blank'
							endIcon={<ArrowOutward color={theme.palette.primary.main} />}
						>
							<Trans>View on Explorer</Trans>
						</Button>
					)}
					{more && (
						<Button
							className='px-0'
							variant='text'
							color='primary'
							size='small'
							onClick={onHandleMore}
						>
							{isMore ? <Trans>Hide error</Trans> : <Trans>Show error</Trans>}
						</Button>
					)}
					{more && (
						<Collapse
							in={isMore}
							sx={{ border: 'none', maxHeight: 440, overflowY: 'auto' }}
						>
							<Typography
								variant='body2'
								color={theme.palette.text.secondary}
								sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
							>
								{more}
							</Typography>
						</Collapse>
					)}
				</StyledAlert>
			}
		/>
	);
});

TransactionSnackbar.displayName = 'TransactionSnackbar';

export default TransactionSnackbar;
