import React, { useMemo, useState } from 'react';
import { Group, Image, Layer, Rect, Stage, Text } from 'react-konva';

// import { Html, Portal } from 'react-konva-utils';
import { Trans, t } from '@lingui/macro';
// import { Close } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Avatar,
	Box,
	FormControlLabel, // IconButton, // IconButton,
	List,
	ListItem,
	MenuItem,
	Popover,
	Tooltip,
	Typography,
	useTheme
} from '@mui/material';
import classNames from 'classnames';
import { QUOTE_USD, Side, Version } from 'config/constants';
import moment from 'moment';
import { useAccount } from 'wagmi';

import CopyLink from 'assets/svg/copy-link.svg';
import SaveImage from 'assets/svg/save-image.svg';
import Bg from 'assets/svg/share-bg.svg';
import Illustration from 'assets/svg/share-illustration.svg';
// import Discord from 'assets/svg/discord.svg';
import Logo from 'assets/svg/share-logo.svg';
import Telegram from 'assets/svg/telegram.svg';
import Twitter from 'assets/svg/twitter.svg';
import V2Logo from 'assets/svg/v2/shareLogo.svg';
import V2Trade from 'assets/svg/v2/shareTrade.svg';
import V2TradeDiscount from 'assets/svg/v2/shareTradeDiscount.svg';
import { useMembersReferralsGraph } from 'graphql/useMembersReferralsGraph';
import { IPositionItem } from 'graphql/useMyPositionsGraph';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import useCopyClipboard from 'hooks/useCopyClipboard';
import { useCurrentChain } from 'hooks/useCurrentChain';
import _ from 'lodash';
import QRCode from 'qrcode';
import { useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';
import useImage from 'use-image';
import {
	formatLeverage,
	isGreaterThan,
	isPositive,
	shortenAddress,
	shortenSymbolNative,
	toPercent,
	toQuoteAmount
} from 'utils';

import { StyleCheckbox } from 'components/Common/StyleCheckbox';

import Dialog from '../../components/Common/Dialog';

const BgImage = props => {
	const [image] = useImage(Bg);
	return <Image {...props} image={image} />;
};
const IllustrationImage = props => {
	const [image] = useImage(Illustration);
	return <Image {...props} image={image} />;
};
const LogoImage = props => {
	const [image] = useImage(Logo);
	return <Image {...props} image={image} width={115} height={30} />;
};

const V2LogoImage = props => {
	const [image] = useImage(V2Logo);
	return <Image {...props} image={image} width={30} height={21} />;
};

const V2TradeImage = props => {
	const [image] = useImage(V2Trade);
	return <Image {...props} image={image} width={170} height={43} />;
};

const V2TradeDiscountImage = props => {
	const [image] = useImage(V2TradeDiscount);
	return <Image {...props} image={image} width={170} height={43} />;
};

const QrImage = props => {
	// eslint-disable-next-line react/prop-types
	const [image] = useImage(props.qrUrl);
	return <Image {...props} image={image} />;
};

const ShareDialog = ({
	item,
	onClose
}: {
	item: IPositionItem;
	onClose: () => void;
}) => {
	const currentVersion = useAppSelector(selectVersion);

	const time = `${moment
		.utc(Number(item.blockTimestamp) * 1000)
		.format('MMM D, YYYY HH:mm:ss (UTC)')}`;
	const { currentChainId } = useCurrentChain();
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const { isMatchMobile } = useAppBreakpoints();
	// const dpr = window.devicePixelRatio || 1;
	// console.log(dpr);

	// const ratio = getPixelRatio(context);
	const [isAddress, setIsAddress] = useState(true);
	const [isLeverage, setIsLeverage] = useState(true);
	const [isPnL, setIsPnL] = useState(true);
	const [isPrice, setIsPrice] = useState(true);
	const [activeCode, setActiveCode] = useState(null);
	const [qrUrl, setQrUrl] = useState('');
	const [isCopied, setCopied] = useCopyClipboard();

	const { data: membersList } = useMembersReferralsGraph();

	const referralCodes = useMemo(() => {
		let codes = [];
		if (membersList && membersList.length > 0) {
			membersList.map(item => {
				codes = codes.concat(item.referralCodes);
			});
			codes = _.orderBy(codes, ['blockTimestamp'], ['desc']);
			if (codes.length > 0 && !activeCode) setActiveCode(codes[0].id);
		}

		return codes;
	}, [membersList]);
	const { address } = useAccount();
	const screenWidth = document.documentElement.clientWidth;
	const [stageWidth, stageHeight, scale] = useMemo(() => {
		const width = 400;
		const height = 424;
		const mobilWidth = screenWidth;
		const mobileRate = mobilWidth / width;
		const mobileHeight = mobileRate * height;
		const stageWidth = isMatchMobile ? mobilWidth : width;
		const stageHeight = isMatchMobile ? mobileHeight : height;
		const scale = isMatchMobile ? mobileRate : 1;
		return [stageWidth, stageHeight, scale];
	}, [screenWidth, isMatchMobile]);
	// console.log(stageWidth, stageHeight, scale);
	const pool = `${shortenSymbolNative(
		item.baseToken,
		currentChainId
	)}/${QUOTE_USD}`;
	// const leverageX = (pool.length - 1) * 6.5 + 52;
	const unrealizedPnLRate =
		isGreaterThan(item.netMargin, 0) &&
		`${isPositive(item.unrealizedPnLRate) ? '+' : ''}${toPercent(
			item.unrealizedPnLRate
		)}`;
	// let unrealizedPnLX = (unrealizedPnLRate.length - 3) * 23.1 + 90;
	// if (isGreaterThan(item.unrealizedPnLRate, 0)) {
	// 	unrealizedPnLX = unrealizedPnLX + 10;
	// }
	const unrealizedPnL = `${
		isPositive(item.unrealizedPnL) ? '(+' : '('
	}${toQuoteAmount(item.unrealizedPnL)} ${QUOTE_USD})`;
	const marginLeft = 24;
	const stageRef = React.useRef(null);
	const poolRef = React.useRef(null);
	const unrealizedPnLRateRef = React.useRef(null);
	const entryPriceRef = React.useRef(null);
	const leverageX = useMemo(() => {
		let width = 0;
		if (poolRef.current !== null) {
			width = poolRef.current.getTextWidth() + 8 + marginLeft;
		}
		return width;
	}, [poolRef.current]);

	const unrealizedPnLX = useMemo(() => {
		let width = 0;
		if (unrealizedPnLRateRef.current !== null) {
			width = unrealizedPnLRateRef.current.getTextWidth() + 8 + marginLeft;
		}
		return width;
	}, [unrealizedPnLRateRef.current]);

	const indexPriceX = useMemo(() => {
		let width = 0;
		if (entryPriceRef.current !== null) {
			width = entryPriceRef.current.getTextWidth() + 24 + marginLeft;
		}
		return width;
	}, [entryPriceRef.current]);

	// if (testRef.current !== null) {
	// 	console.log(stageRef);
	// 	console.log(testRef);
	// 	console.log(testRef.current.getTextWidth());
	// }

	// stageRef.current.bufferHitCanvas.height(100)
	// console.log(stageRef.current.bufferHitCanvas.height);

	const generateRandomNumFour = quantity => {
		let result = '';
		for (let i = 0; i < quantity; i++) {
			result += Math.floor(Math.random() * 10).toString();
		}
		return result;
	};
	const handleExport = () => {
		const uri = stageRef.current.toDataURL({ pixelRatio: 2 });
		downloadURI(uri, `equation_sharing_${generateRandomNumFour(4)}.png`);
	};

	const downloadURI = (uri, name) => {
		const link = document.createElement('a');
		link.download = name;
		link.href = uri;
		link.target = '_blank';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};
	const shareUrl = useMemo(() => {
		const url = `${document.location.origin}${
			activeCode ? '/referrals/traders?code=' + activeCode : ''
		}`;

		QRCode.toDataURL(
			url,
			{ errorCorrectionLevel: 'L', width: 204, height: 204, margin: 2 },
			function (err, url) {
				if (err) {
					console.log(err);
				}
				if (url) {
					setQrUrl(url);
				}
			}
		);
		return url;
	}, [activeCode]);

	const onHandleCopy = () => {
		setCopied(shareUrl);
	};
	const onHandleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const onSelectCode = (code: string) => {
		setActiveCode(code);
		setAnchorEl(null);
	};

	const onHandleClose = () => {
		setAnchorEl(null);
	};

	const isDiscountToken =
		item.baseToken.name === 'ETH' || item.baseToken.name === 'BTC';

	return (
		<Dialog
			open
			title={t`Share`}
			isTitle={false}
			onClose={onClose}
			width={isMatchMobile ? '100%' : 'auto'}
			height={isMatchMobile ? '100%' : 'auto'}
		>
			<article>
				{/* {!isMatchMobile && (
					<IconButton
						sx={{
							color: theme.palette.text.primary,
							position: 'absolute',
							right: isMatchMobile ? '4px' : '8px',
							top: isMatchMobile ? '4px' : '8px',
							background: theme.palette.action.hover
						}}
						onClick={onClose}
					>
						<Close
							sx={{
								width: isMatchMobile ? '16px' : 14,
								height: isMatchMobile ? '16px' : 14,
								fontWeight: 'bold'
							}}
						/>
					</IconButton>
				)} */}

				<Box
					width={stageWidth}
					height={stageHeight}
					className='relative sm:-left-3'
				>
					<Box
						width={stageWidth}
						height={stageHeight}
						className='relative left-0 top-0 z-20'
					></Box>
					<Box className='absolute left-0 top-0 z-10'>
						<Stage
							width={stageWidth}
							height={stageHeight}
							scaleX={scale}
							scaleY={scale}
							ref={stageRef}
						>
							<Layer>
								<Rect x={0} y={0} width={400} height={424} fill='#111111' />
								<LogoImage x={24} y={24} />
								{currentVersion === Version.V2 && (
									<V2LogoImage x={145} y={27} />
								)}
								{currentVersion === Version.V2 && (
									<>
										<V2TradeImage x={230} y={75} />
									</>
								)}
								<BgImage x={19.57} y={0} />
								<IllustrationImage x={259} y={265} />
								{time && (
									<Text
										x={240}
										y={32}
										text={time}
										fill='white'
										fontSize={12}
										opacity={0.9}
									/>
								)}
								{isAddress && (
									<Group>
										<Rect
											x={24}
											y={98}
											width={108}
											height={24}
											cornerRadius={4}
											fillLinearGradientStartPoint={{ x: 0, y: 0 }}
											fillLinearGradientEndPoint={{ x: 132, y: 24 }}
											fillLinearGradientColorStops={[
												0,
												'#0E2E0E',
												0.96,
												'#1C362B',
												1,
												'#1C362B'
											]}
											// shadowBlur={10}
										/>
										<Text
											x={36}
											y={105}
											text={shortenAddress(address || '')}
											fill='white'
											fontSize={12}
											opacity={0.9}
											fontFamily='Noto Sans'
										/>
									</Group>
								)}
								<Group y={-4}>
									<Text
										x={24}
										y={143}
										text={pool}
										fill='white'
										fontSize={14}
										opacity={0.9}
										fontFamily='Noto Sans'
										ref={poolRef}
									/>
									{isLeverage && isGreaterThan(leverageX, 0) && (
										<Text
											x={leverageX}
											y={143}
											text={`${
												item.side === Side.LONG ? t`Long` : t`Short`
											} ${formatLeverage(item.leverage)} `}
											fill={item.side === Side.LONG ? '#0CBA71' : '#F04A54'}
											fontSize={14}
											fontFamily='Noto Sans'
										/>
									)}
									{/* <Text x={24} y={177} text='PnL%' fill='#8B919D' fontSize={12} /> */}
									<Text
										x={24}
										y={182}
										text={unrealizedPnLRate}
										fill={
											isPositive(item.unrealizedPnLRate) ? '#0CBA71' : '#F04A54'
										}
										fontSize={40}
										fontFamily='Noto Sans'
										fontStyle='600'
										ref={unrealizedPnLRateRef}
									/>
									{isPnL && isGreaterThan(unrealizedPnLX, 0) && (
										<Text
											x={unrealizedPnLX}
											y={202}
											text={unrealizedPnL}
											fill={
												isPositive(item.unrealizedPnL) ? '#0CBA71' : '#F04A54'
											}
											fontSize={14}
											fontFamily='Noto Sans'
										/>
									)}

									{isPrice && (
										<>
											<Text
												x={24}
												y={253}
												text={t`Entry Price (${QUOTE_USD})`}
												fill='#8B919D'
												fontSize={12}
												ref={entryPriceRef}
											/>
											<Text
												x={24}
												y={272}
												text={toQuoteAmount(
													item.entryPrice,
													item?.baseToken.precision
												)}
												fill='white'
												fontSize={16}
												opacity={0.9}
											/>
											{isGreaterThan(indexPriceX, 0) && (
												<>
													<Text
														x={indexPriceX}
														y={253}
														text={t`Index Price (${QUOTE_USD})`}
														fill='#8B919D'
														fontSize={12}
													/>

													<Text
														x={indexPriceX}
														y={272}
														text={toQuoteAmount(
															item.indexPrice,
															item?.baseToken.precision
														)}
														fill='white'
														fontSize={16}
														opacity={0.9}
													/>
												</>
											)}
										</>
									)}
								</Group>
								{activeCode && (
									<Group>
										<Text
											x={82}
											y={340}
											text={t`Referral Code`}
											fill='#8B919D'
											fontSize={12}
										/>
										<Text
											x={82}
											y={359}
											text={activeCode}
											fill='white'
											fontSize={14}
											opacity={0.9}
										/>
									</Group>
								)}
								<Text
									x={82}
									y={activeCode ? 378 : 359}
									text={t`Scan to Explore`}
									fill='#8B919D'
									fontSize={12}
								/>
								{qrUrl !== '' && (
									<QrImage
										width={50}
										height={50}
										x={24}
										y={340}
										qrUrl={qrUrl}
									/>
								)}
							</Layer>
						</Stage>
					</Box>
				</Box>
				<Typography className='mt-5' sx={{ fontSize: 12 }}>
					<Trans>Choose whether to share the following information:</Trans>
				</Typography>
				<Box className='mt-2'>
					<FormControlLabel
						className='mr-5'
						control={
							<StyleCheckbox
								className='py-0 -mr-1'
								onChange={event => {
									setIsAddress(event.target.checked);
								}}
								size='small'
								checked={isAddress}
							/>
						}
						label={<Trans>Address</Trans>}
						sx={{ '.MuiTypography-root': { fontSize: 12 } }}
					/>
					<FormControlLabel
						className='mr-5'
						control={
							<StyleCheckbox
								className='py-0 -mr-1'
								onChange={event => {
									setIsLeverage(event.target.checked);
								}}
								size='small'
								checked={isLeverage}
							/>
						}
						label={<Trans>Leverage</Trans>}
						sx={{ '.MuiTypography-root': { fontSize: 12 } }}
					/>
					<FormControlLabel
						className='mr-5'
						control={
							<StyleCheckbox
								className='py-0 -mr-1'
								onChange={event => {
									setIsPnL(event.target.checked);
								}}
								size='small'
								checked={isPnL}
							/>
						}
						label={<Trans>PnL</Trans>}
						sx={{ '.MuiTypography-root': { fontSize: 12 } }}
					/>
					<FormControlLabel
						className='mr-5'
						control={
							<StyleCheckbox
								className='py-0 -mr-1'
								onChange={event => {
									setIsPrice(event.target.checked);
								}}
								size='small'
								checked={isPrice}
							/>
						}
						label={<Trans>Price</Trans>}
						sx={{ '.MuiTypography-root': { fontSize: 12 } }}
					/>
				</Box>
				<Box className='flex mt-5 space-x-8'>
					<Box
						className='flex flex-col items-center cursor-pointer'
						onClick={onHandleCopy}
					>
						<Tooltip
							title={<Trans>Copied</Trans>}
							placement='top'
							open={isCopied}
							arrow
						>
							<Avatar
								alt='Copy Link'
								src={CopyLink}
								sx={{ width: 32, height: 32 }}
							/>
						</Tooltip>
						<Typography className='mt-1' sx={{ fontSize: 12 }}>
							<Trans>Copy link</Trans>
						</Typography>
					</Box>
					{!isMatchMobile && (
						<Box
							className='flex flex-col items-center cursor-pointer'
							onClick={handleExport}
						>
							<Avatar
								alt='Save Image'
								src={SaveImage}
								sx={{ width: 32, height: 32 }}
							/>
							<Typography className='mt-1' sx={{ fontSize: 12 }}>
								<Trans>Save image</Trans>
							</Typography>
						</Box>
					)}

					<a
						href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${t`Equation - The Next Generation of Decentralized Perpetual Exchange 
						Up to 200x leverage. No sign up or deposit required. Equation is set to revolutionize the experience of perpetual trading and the future of DeFi.
						`}`}
						target='_blank'
						rel='noopener noreferrer'
					>
						<Box className='flex flex-col items-center cursor-pointer'>
							<Avatar
								alt='Twitter'
								src={Twitter}
								sx={{ width: 32, height: 32 }}
							/>
							<Typography className='mt-1' sx={{ fontSize: 12 }}>
								<Trans>Twitter</Trans>
							</Typography>
						</Box>
					</a>
					<a
						href={`https://t.me/share/url?url=${shareUrl}`}
						target='_blank'
						rel='noopener noreferrer'
					>
						<Box className='flex flex-col items-center'>
							<Avatar
								alt='Telegram'
								src={Telegram}
								sx={{ width: 32, height: 32 }}
							/>
							<Typography className='mt-1' sx={{ fontSize: 12 }}>
								<Trans>Telegram</Trans>
							</Typography>
						</Box>
					</a>
					{/* <Box className='flex flex-col items-center cursor-pointer'>
						<Avatar
							alt='Discord'
							src={Discord}
							sx={{ width: 32, height: 32 }}
						/>
						<Typography className='mt-1' sx={{ fontSize: 12 }}>
							<Trans>Discord</Trans>
						</Typography>
					</Box> */}
				</Box>
				<Box className='mt-5'>
					{referralCodes.length > 0 && (
						<Box className='flex items-center'>
							<Typography sx={{ fontSize: 12 }} className='mr-1'>
								<Trans>Referral Code:</Trans>
							</Typography>
							<List component='nav' aria-label='Device settings'>
								<ListItem
									id='lock-button'
									aria-haspopup='listbox'
									aria-controls='lock-menu'
									aria-label='when device is locked'
									aria-expanded={open ? 'true' : undefined}
									onClick={onHandleClickListItem}
									className='p-0'
								>
									<Box
										className='flex '
										sx={{
											borderRadius: '4px',
											padding: '4px 12px',
											background: theme.custom.selectBg,
											fontSize: '12px',
											fontWeight: isMatchMobile ? 400 : 500,
											color: theme.palette.text.primary,
											'&:hover': {
												background: theme.palette.action.hover
											}
										}}
									>
										<Box className='flex items-center justify-between cursor-pointer sm:space-x-0 w-[116px] sm:w-[126px]'>
											{activeCode}
											{anchorEl ? (
												isMatchMobile ? (
													<ArrowDropUpIcon />
												) : (
													<ExpandLessIcon />
												)
											) : isMatchMobile ? (
												<ArrowDropDownIcon />
											) : (
												<ExpandMoreIcon />
											)}
										</Box>
									</Box>
								</ListItem>
							</List>
							<Popover
								open={open}
								anchorEl={anchorEl}
								className={`${
									isMatchMobile ? '-translate-y-1' : 'translate-y-1'
								} transform`}
								onClose={onHandleClose}
								anchorOrigin={{
									vertical: isMatchMobile ? 'top' : 'bottom',
									horizontal: 'center'
								}}
								transformOrigin={{
									vertical: isMatchMobile ? 'bottom' : 'top',
									horizontal: 'center'
								}}
							>
								<Box className='overflow-y-auto max-h-52'>
									{referralCodes.map(item => {
										return (
											<MenuItem
												className={`flex ${
													referralCodes.length < 5 ? 'w-[140px]' : 'w-[134px]'
												} cursor-pointer items-center justify-between sm:w-[150px]`}
												component='div'
												key={item.id}
												onClick={() => onSelectCode(item.id)}
												sx={{ paddingRight: '5px' }}
											>
												<Typography
													variant='inherit'
													className='truncate'
													color={
														item.id === activeCode
															? 'text.primary'
															: 'text.secondary'
													}
												>
													{item.id}
												</Typography>

												<DoneRoundedIcon
													fontSize='small'
													className={classNames(' font-bold', {
														'opacity-0': item.id !== activeCode
													})}
													sx={{ color: theme.palette.text.primary }}
												/>
											</MenuItem>
										);
									})}
								</Box>
							</Popover>
						</Box>
					)}
				</Box>
			</article>
		</Dialog>
	);
};

export default ShareDialog;
