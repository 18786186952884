import { ReactNode } from 'react';

import { Box, Card, Typography, useTheme } from '@mui/material';

import { useCheckLogin } from 'hooks/useCurrentChain';

import {
	CommonStyledArticle,
	CommonStyledDivider
} from 'components/Common/Styled';
import ConnectWalletButton from 'components/Wallet/ConnectWalletButton';

import { useAppBreakpoints } from '../../hooks/useAppBreakpoints';

export function ModuleCard({
	title,
	children,
	extra,
	checkLogin = true
}: {
	title: ReactNode;
	children?: ReactNode;
	extra?: ReactNode;
	checkLogin?: boolean;
}) {
	const isLogin = useCheckLogin();
	const theme = useTheme();
	const { isMatchMobile, isMatchPad } = useAppBreakpoints();

	return (
		<Card
			className='px-4 py-5 underLg:px-3 underLg:py-4 space-y-6 underLg:space-y-4'
			sx={{
				backgroundColor: theme.palette.background.paper
			}}
		>
			<div className='flex justify-between items-center'>
				<Typography
					variant='h6'
					fontSize={isMatchMobile || isMatchPad ? 14 : 16}
					fontWeight={500}
				>
					{title}
				</Typography>
				{extra}
			</div>
			{!checkLogin ? (
				children
			) : isLogin ? (
				children
			) : (
				<div className='flex items-center justify-center'>
					<ConnectWalletButton className='px-6' />
				</div>
			)}
		</Card>
	);
}

export function ModuleMember(props: {
	title: ReactNode;
	extra?: ReactNode;
	size?: string;
	disabled?: boolean;
	children?: ReactNode;
	headerChildren?: ReactNode;
	divider?: boolean;
}) {
	const {
		title,
		extra,
		size = 'large',
		disabled,
		divider = true,
		children,
		headerChildren,
		...restProps
	} = props;
	return (
		<CommonStyledArticle
			className='space-y-3'
			disabled={disabled}
			{...restProps}
		>
			<header>
				<Box className='flex items-center justify-between h-8'>
					<Typography variant={size === 'large' ? 'h6' : 'body2'}>
						{title}
					</Typography>
					{extra}
				</Box>
				{headerChildren}
			</header>
			{children}
			{divider && <CommonStyledDivider />}
		</CommonStyledArticle>
	);
}
