import { createSelector } from '@reduxjs/toolkit';

import { isGreaterThan, plus, toDecimalPlaces } from 'utils';

import { referralBaseState } from './slice';

import { uniq, flatMap, flatten, values, groupBy} from 'lodash';

export const selectClaimableMembersRewardList = createSelector(
	[referralBaseState],
	state => {
		const { membersRewardList } = state;
		if (!membersRewardList) {
			return null;
		}
		return membersRewardList.filter(
			item =>
				isGreaterThan(item.unclaimed, 0) || isGreaterThan(item.newRewardsEQU, 0)
		);

	}
);

export const selectClaimableMembers = createSelector(
	[selectClaimableMembersRewardList],
	claimMembersRewardList => {
		let unclaimed = '0',
			newRewardsEQU = '0';
		const ids = [];
		if (!claimMembersRewardList)
			return { type: 'Members', unclaimed, newRewardsEQU, ids };
		claimMembersRewardList.forEach(item => {
			unclaimed = plus(unclaimed, toDecimalPlaces(item.unclaimed, 4));
			newRewardsEQU = plus(newRewardsEQU, item.newRewardsEQU);
			ids.push(item.id);
		});
		return { type: 'Members', unclaimed, newRewardsEQU, ids };
	}
);

export const selectClaimableConnectorsRewardList = createSelector(
	[referralBaseState],
	state => {
		const { connectorsRewardList } = state;
		if (!connectorsRewardList) {
			return null;
		}
		return connectorsRewardList.filter(
			item =>
				isGreaterThan(item.unclaimed, 0) || isGreaterThan(item.newRewardsEQU, 0)
		);
	}
);

export const selectClaimableConnectors = createSelector(
	[selectClaimableConnectorsRewardList],
	claimConnectorsRewardList => {
		let unclaimed = '0',
			newRewardsEQU = '0';
		const ids = [];
		if (!claimConnectorsRewardList)
			return { type: 'Connectors', unclaimed, newRewardsEQU, ids };
		claimConnectorsRewardList.forEach(item => {
			unclaimed = plus(unclaimed, toDecimalPlaces(item.unclaimed, 4));
			newRewardsEQU = plus(newRewardsEQU, item.newRewardsEQU);
			ids.push(item.id);
		});
		return { type: 'Connectors', unclaimed, newRewardsEQU, ids };
	}
);

export const selectClaimableFeeAmount = createSelector(
	[selectClaimableMembers, selectClaimableConnectors],
	(claimableMembers, claimableConnectors) => {
		return plus(claimableMembers.unclaimed, claimableConnectors.unclaimed);
	}
);

export const selectClaimableEquAmount = createSelector(
	[selectClaimableMembers, selectClaimableConnectors],
	(claimableMembers, claimableConnectors) => {
		return plus(claimableMembers.rewardsEQU, claimableConnectors.rewardsEQU);
	}
);

export const selectClaimableReferralList = createSelector(
	[selectClaimableMembers, selectClaimableConnectors],
	(claimableMembers, claimableConnectors) => {
		const claimableReferralList = [];
		if (
			claimableMembers.unclaimed !== '0' ||
			claimableMembers.rewardsEQU !== '0'
		) {
			if (
				claimableConnectors.unclaimed !== '0' ||
				claimableConnectors.rewardsEQU !== '0'
			) {
				claimableReferralList.push(claimableMembers, claimableConnectors);
			} else {
				claimableReferralList.push(claimableMembers);
			}
		} else if (
			claimableConnectors.unclaimed !== '0' ||
			claimableConnectors.rewardsEQU !== '0'
		) {
			claimableReferralList.push(claimableConnectors);
		} else if (
			claimableMembers.unclaimed === '0' &&
			claimableMembers.rewardsEQU === '0' &&
			claimableConnectors.unclaimed === '0' &&
			claimableConnectors.rewardsEQU === '0'
		) {
			return [];
		}
		return claimableReferralList;
	}
);

export const selectHasConnectors = createSelector(
	[referralBaseState],
	state => {
		const { connectorsList } = state;
		if (!connectorsList || connectorsList.length === 0) {
			return false;
		}
		return true;
	}
);

export const selectMembersRewardEquAmount = createSelector(
	[referralBaseState],
	state => {
		const { membersRewardList } = state;
		if (!membersRewardList || membersRewardList.length === 0) {
			return '0';
		}
		let rewardsEQUSum= '0'
		membersRewardList.forEach(item => {
			rewardsEQUSum = plus(rewardsEQUSum, item.rewardsEQU);
		});
		return rewardsEQUSum;
	}
);

export const selectMembersRewardEquPools = createSelector(
	[referralBaseState],
	state => {
		const { membersRewardList } = state;
		if (!membersRewardList || membersRewardList.length === 0) {
			return [];
		}
		return uniq(flatMap(membersRewardList, 'pools'));
		
	}
);

export const selectConnectorsRewardEquAmount = createSelector(
	[referralBaseState],
	state => {
		const { connectorsRewardList } = state;
		if (!connectorsRewardList || connectorsRewardList.length === 0) {
			return '0';
		}
		let rewardsEQUSum= '0'
		connectorsRewardList.forEach(item => {
			rewardsEQUSum = plus(rewardsEQUSum, item.rewardsEQU);
		});
		return rewardsEQUSum;
	}
);

export const selectConnectorsRewardEquPools = createSelector(
	[referralBaseState],
	state => {
		const { connectorsRewardList } = state;
		if (!connectorsRewardList || connectorsRewardList.length === 0) {
			return [];
		}
		return uniq(flatMap(connectorsRewardList, 'pools'));
		
	}
);

export const selectMembersRefereesAddressList = createSelector(
	[referralBaseState],
	state => {
		const { membersRefereesAddressList } = state;
		if (!membersRefereesAddressList || membersRefereesAddressList.length === 0) {
			return [];
		}
		return values(groupBy(membersRefereesAddressList, 'referralToken')).map(group => ({
			address: flatten(group.map(item => item.address)),
			referralToken: group[0].referralToken,
		}));
	}
);







