import { useWeb3LibraryContext } from 'components/Wallet/WalletProvider';
import { useCurrentChain, useCheckLogin } from 'hooks/useCurrentChain';
import { useMemo } from 'react';
import { useAccount, useProvider } from 'wagmi';

const useWeb3Provider = (withSignerIfPossible = true) => {
	const { address } = useAccount();
	const isLogin = useCheckLogin();
	const { currentChainId } = useCurrentChain();
	const provider = useProvider({
		chainId: currentChainId
	});
	const Web3Provider = useWeb3LibraryContext();
	return useMemo(() => {
		if (withSignerIfPossible && address && isLogin && Web3Provider) {
			return Web3Provider;
		}
		return provider;
	}, [address, isLogin, provider, Web3Provider, withSignerIfPossible]);
};

export default useWeb3Provider;
