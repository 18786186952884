import { useEffect, useMemo, useState } from 'react';

import { useApolloClients } from 'context/ApolloClientsContext';

import {
	RewardFarmConfig,
	useRewardsConfigQuery
} from './__generated_referral__/types-and-hooks';

export function useRewardsConfigGraph() {
	const { clientReferral } = useApolloClients();
	const [loading, setLoading] = useState<boolean>(true);
	const {
		data,
		loading: isLoading,
		refetch
	} = useRewardsConfigQuery({
		client: clientReferral
	});

	useEffect(() => {
		if (!isLoading) {
			setLoading(isLoading);
		}
	}, [isLoading]);

	return useMemo(() => {
		if (!data || !data?.rewardFarmConfigs || !data?.rewardFarmConfigs?.length) {
			return {
				data: undefined,
				loading,
				refetch
			};
		}
		return {
			data: (data.rewardFarmConfigs.length
				? data.rewardFarmConfigs[0]
				: data.rewardFarmConfigs) as RewardFarmConfig,
			loading,
			refetch
		};
	}, [data, refetch, loading]);
}
