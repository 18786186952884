import { useMemo } from 'react';

import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { IPoolItem } from 'types';

export function useGetPool(poolId: string | undefined) {
	const { poolMap } = useAppSelector(poolsBaseState);

	return useMemo(() => {
		if (!poolId || !poolMap || !poolMap.size) {
			return null;
		}
		return poolMap.get(poolId) as IPoolItem;
	}, [poolMap, poolId]);
}
