import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

import { Trans } from '@lingui/macro';
import {
	Box,
	IconButton,
	ListItem,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import classNames from 'classnames';
import {
	Dom_Size,
	MAX_AMOUNT_FORMATTER_LIMIT,
	Page,
	QUOTE_USD,
	Side,
	Transaction_Status,
	Transaction_Type,
	Version
} from 'config/constants';
import moment from 'moment';

import { TransactionRecord } from 'entities/TransactionRecord';
import { IPositionItem } from 'graphql/useMyPositionsGraph';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { find, isEmpty, orderBy } from 'lodash';
import RiskRate from 'pages/Liquidity/RiskRate';
import { useAppSelector } from 'state/hooks';
import { selectTransactionRecordList } from 'state/records/selector';
import { settingBaseState } from 'state/setting/slice';
import { PositionId, RecordForTrade } from 'types';
import {
	amountFormatter,
	formatNumber,
	shortenSymbolNative,
	toQuoteAmount
} from 'utils';

import { CommonStyledList } from 'components/Common/Styled';
import Description from 'components/Description';
import { Grids } from 'components/Grids';
import Leverage from 'components/Leverage';
import LiqPrice from 'components/LiqPrice';
import NoData from 'components/NoData';
import Stronger from 'components/Stronger';
import Loading from 'components/Svg/Icons/Loading';

import Share from '../../../components/Svg/Icons/Share';
import AdjustMarginDialog from '../AdjustMarginDialog';
import ClosePositionDialog from '../ClosePositionDialog';
import ProfitLossDialog from '../ProfitLoss';
import ShareDialog from '../ShareDialog';
import TipsDialog from '../TipsDialog';
import AdjustMarginDialogV2 from '../V2/AdjustMarginDialogV2';
import {
	CellButtons,
	CellMargin,
	CellNetFunding,
	CellUnRealizedPnL
} from './PositionCells';

function convertRecord(txRecord: TransactionRecord) {
	const record = txRecord.record as RecordForTrade;
	return {
		transactionType: record.transactionType,
		side: record.side,
		leverage: '',
		netMargin: '',
		balanceImpactFee: '',
		fundingFeeTotal: '',
		entryPrice: '',
		unrealizedPnL: '0',
		unrealizedPnLRate: '',
		size: record.positionAmount,
		realizedPnL: '',
		baseToken: record.baseToken,
		status: Transaction_Status.Pending
	} as Partial<IPositionItem>;
}

// Market: list是指定池子的仓位列表
// Trade: list是所有池子的仓位列表
export default function PositionsList({
	list,
	poolId
}: {
	list: Array<IPositionItem> | null | undefined;
	poolId?: string;
}) {
	const { takeProfitStopLossTis } = useAppSelector(settingBaseState);
	const transactionRecords = useAppSelector(selectTransactionRecordList);
	const currentVersion = useAppSelector(state => state.setting.currentVersion);

	const { currentChainId } = useCurrentChain();
	const { isMatchMobile } = useAppBreakpoints();
	const theme = useTheme();
	const navigate = useNavigate();

	const [tisDialogIsOpen, setTisDialogIsOpen] = useState(false);
	const [takeProfitStopLossDialogIsOpen, setTakeProfitStopLossDialogIsOpen] =
		useState(false);
	const [adjustMarginDialogIsOpen, setAdjustMarginDialogIsOpen] =
		useState(false);
	const [closePositionDialogIsOpen, setClosePositionDialogIsOpen] =
		useState(false);
	const [shareDialogIsOpen, setShareDialogIsOpen] = useState(false);
	const [currentItem, setCurrentItem] = useState<IPositionItem | null>(null);
	const [shareItem, setShartItem] = useState<IPositionItem | null>(null);

	const isMatch2XL = useMediaQuery(`(min-width: ${Dom_Size.PAGE_CONTAINER}px)`);

	// 开多与开空的所有本地记录order list
	// Subgraph: 用户仓位映射
	// key: poolId + side
	const positionMap = useMemo(() => {
		if (!list) {
			return new Map();
		}
		const result = new Map<PositionId, IPositionItem>();
		list.forEach((item: IPositionItem) => {
			result.set(`${item.poolId}:${item.side}`, item);
		});
		return result;
	}, [list]);

	// Local: 开多与开空的所有本地记录, List and Map
	const [positionListFromLocal, positionMapFromLocal] = useMemo(() => {
		if (!transactionRecords || isEmpty(transactionRecords)) {
			return [[], new Map()];
		}
		const positionListFromLocal = [] as Array<IPositionItem>;
		const positionMapFromLocal = new Map<PositionId, IPositionItem>();
		let filteredTransactionRecords = transactionRecords;
		if (poolId) {
			filteredTransactionRecords = transactionRecords.filter(
				(item: TransactionRecord) => {
					return item.record.pool === poolId;
				}
			);
		}
		filteredTransactionRecords.forEach((txRecord: TransactionRecord) => {
			const record = txRecord.record;
			if (currentVersion === Version.V1) {
				if (
					// txRecord.record.pool === poolId &&
					record.transactionType === Transaction_Type.Long ||
					record.transactionType === Transaction_Type.Short ||
					record.transactionType === Transaction_Type.IncreaseMargin ||
					record.transactionType === Transaction_Type.ReduceMargin ||
					record.transactionType === Transaction_Type.Close
				) {
					const _convertedPosition = convertRecord(txRecord) as IPositionItem;
					positionListFromLocal.push(_convertedPosition);
					positionMapFromLocal.set(
						`${record.pool}:${record.side as Side}`,
						_convertedPosition
					);
				}
			} else {
				if (
					// txRecord.record.pool === poolId &&
					record.transactionType === Transaction_Type.LongV2 ||
					record.transactionType === Transaction_Type.ShortV2 ||
					record.transactionType === Transaction_Type.IncreaseMarginV2 ||
					record.transactionType === Transaction_Type.ReduceMarginV2 ||
					record.transactionType === Transaction_Type.CloseV2
				) {
					const _convertedPosition = convertRecord(txRecord) as IPositionItem;
					positionListFromLocal.push(_convertedPosition);
					positionMapFromLocal.set(
						`${record.pool}:${record.side as Side}`,
						_convertedPosition
					);
				}
			}
		});
		return [positionListFromLocal, positionMapFromLocal];
	}, [poolId, transactionRecords, currentVersion]);

	const computedList = useMemo(() => {
		// 本地没有记录，直接显示仓位列表，此时每一条记录都是Success状态
		if (positionMapFromLocal.size === 0) {
			list?.forEach((item: IPositionItem) => {
				item.status = Transaction_Status.Success;
			});
			return list;
		}

		// 没有graph仓位列表，将本地记录插入进来，此时每一条记录都是Pending状态
		if (isEmpty(list)) {
			return positionListFromLocal;
		}

		// 有了仓位列表，具有以下两种操作形态
		// 1. 创建，将这条新纪录放入最上方，状态为Pending
		// 2. 更新，将指定操作的记录，更新状态为Pending
		Array.from(positionMap.values()).forEach(item => {
			delete item.status;
		});
		const resultMap = new Map<string, IPositionItem>([
			...positionMapFromLocal,
			...positionMap
		]);
		Array.from(resultMap.values()).forEach((item: IPositionItem) => {
			const positionId = `${item.poolId}:${item.side}` as PositionId;
			if (positionMapFromLocal.has(positionId)) {
				item.status = Transaction_Status.Pending;
			}
		});

		const _results = Array.from(resultMap.values());
		const _sortedResults = orderBy(
			_results,
			(item: IPositionItem) => {
				return item.entryTime;
			},
			['desc']
		);
		return _sortedResults;
	}, [
		list,
		positionMap,
		positionListFromLocal,
		positionMapFromLocal,
		transactionRecords
	]);

	const onTakeProfitStopLoss = (item: IPositionItem) => {
		setCurrentItem(item);
		if (
			takeProfitStopLossTis &&
			moment().isBefore(moment(takeProfitStopLossTis).add(1, 'day'))
		) {
			setTakeProfitStopLossDialogIsOpen(true);
		} else {
			setTisDialogIsOpen(true);
			return;
		}
	};
	const onOpenAdjustMarginDialog = (item: IPositionItem) => {
		setCurrentItem(item);
		setAdjustMarginDialogIsOpen(true);
	};
	const onOpenClosePositionDialog = (item: IPositionItem) => {
		setCurrentItem(item);
		setClosePositionDialogIsOpen(true);
	};
	const onCloseAdjustMarginDialog = () => {
		setAdjustMarginDialogIsOpen(false);
	};
	const onCloseClosePositionDialog = () => {
		setClosePositionDialogIsOpen(false);
	};
	const onCloseTipsDialog = () => {
		setTisDialogIsOpen(false);
	};
	const onConfirmTipsDialog = () => {
		setTisDialogIsOpen(false);
		setTakeProfitStopLossDialogIsOpen(true);
	};
	const onCloseTakeProfitStopLossDialog = () => {
		setTakeProfitStopLossDialogIsOpen(false);
	};
	const onOpenShareDialog = (item: IPositionItem) => {
		setShartItem(item);
		setShareDialogIsOpen(true);
	};
	const onCloseShareDialog = () => {
		setShareDialogIsOpen(false);
	};
	useUpdateEffect(() => {
		if (!isEmpty(computedList) && currentItem) {
			const positionId = currentItem.id;
			const updateItem = find(computedList, o => {
				return o.id === positionId;
			});
			if (updateItem) {
				setCurrentItem(updateItem);
			}
		}
	}, [computedList]);

	return (
		<CommonStyledList className='space-y-3 sm:space-y-0'>
			{!isEmpty(computedList) ? (
				computedList.map((item, index) => (
					<ListItem key={index} className='sm:py-3'>
						<Box className='w-full space-y-2'>
							<Box className='flex items-center justify-between'>
								<div
									className={classNames('flex items-center space-x-4 flex-1.5')}
								>
									<section className='flex items-center space-x-1'>
										<Typography
											className='cursor-pointer'
											onClick={() =>
												navigate(
													`${
														currentVersion === Version.V1
															? Page.TradeV1
															: Page.Trade
													}/${item.poolId}`
												)
											}
										>{`${shortenSymbolNative(
											item.baseToken,
											currentChainId
										)}/${QUOTE_USD}`}</Typography>
										<Typography
											className='space-x-1'
											variant='body1'
											sx={{
												color:
													item.side === Side.LONG
														? theme.palette.success.main
														: theme.palette.error.main
											}}
										>
											{item.side === Side.LONG ? (
												<Trans>Long</Trans>
											) : (
												<Trans>Short</Trans>
											)}
											{item.leverage && (
												<span className='ml-1'>
													<Leverage
														value={item.leverage as string}
														margin={item.netMargin}
													/>
												</span>
											)}
										</Typography>
									</section>
									<RiskRate
										risk={item.marginRate}
										size='small'
										tooltip={
											<div>
												<div>
													<Trans>
														The value is used to measure position risk. A
														liquidation will be triggered when the margin ratio
														reaches 100%.
													</Trans>
												</div>
												<div>
													<Trans>
														Margin Ratio = Maintenance Margin of Current
														Position / (Margin + Unrealized PnL)
													</Trans>
												</div>
											</div>
										}
									/>
									{isMatchMobile && (
										<IconButton
											onClick={() => onOpenShareDialog(item)}
											className={`opacity-70 p-0 hover:opacity-100 ${
												item.status === Transaction_Status.Pending &&
												'opacity-30'
											}`}
											// sx={isMatchMobile ? { top: 8, right: -8 } : {}}
											disabled={item.status === Transaction_Status.Pending}
										>
											<Share size={16} />
										</IconButton>
									)}

									{item.status === Transaction_Status.Pending && (
										<Loading size={16} />
									)}
								</div>
								{!isMatch2XL && !isMatchMobile && (
									<CellButtons
										item={item}
										onTakeProfitStopLoss={onTakeProfitStopLoss}
										onOpenClosePositionDialog={onOpenClosePositionDialog}
										className='flex-1.5'
									/>
								)}
							</Box>
							{!isMatchMobile ? (
								// PC端
								<section className='flex items-start'>
									<div className='flex-1 grid gap-2 grid-cols-10 py-1'>
										<Description
											label={<Trans>Size</Trans>}
											className='col-span-3'
										>
											<Typography variant='body2'>{`${amountFormatter(
												item.size,
												2,
												item.baseToken.positionUnits,
												MAX_AMOUNT_FORMATTER_LIMIT
											)} ${shortenSymbolNative(
												item.baseToken,
												currentChainId
											)}`}</Typography>
										</Description>
										<Description
											label={<Trans>Entry Price</Trans>}
											className='col-span-3'
										>
											<Typography variant='body2'>
												{toQuoteAmount(
													item.entryPrice,
													item.baseToken.precision
												)}
											</Typography>
										</Description>
										<CellUnRealizedPnL
											className='col-span-4 relative'
											onOpenShareDialog={onOpenShareDialog}
											item={item}
										/>
										{/* 第二行 */}
										<CellMargin
											item={item}
											onOpenAdjustMarginDialog={onOpenAdjustMarginDialog}
											className='col-span-3'
										/>
										<Description
											label={
												<Stronger
													value={<Trans>Liq. Price</Trans>}
													tooltip={
														<Typography variant='body2'>
															<Trans>
																If{' '}
																<Typography
																	className='inline'
																	variant='body2'
																	color={theme.custom.orangeColor}
																>
																	Index Price
																</Typography>{' '}
																reaches Liq. Price, the position will be
																liquidated, and all margins will be lost.
															</Trans>
														</Typography>
													}
												/>
											}
											className='col-span-3'
										>
											<LiqPrice
												value={item.liqPrice}
												margin={item.netMargin}
												precision={item.baseToken.precision}
											/>
										</Description>
										<CellNetFunding item={item} className='col-span-4' />
									</div>
									{isMatch2XL && (
										<CellButtons
											item={item}
											onTakeProfitStopLoss={onTakeProfitStopLoss}
											onOpenClosePositionDialog={onOpenClosePositionDialog}
										/>
									)}
								</section>
							) : (
								// 移动端
								<div className='space-y-3'>
									<div className='space-y-2'>
										{/* 移动端：第一行 */}
										<Grids>
											<Description label={<Trans>Size</Trans>}>
												<Typography variant='body2'>{`${formatNumber(
													item.size,
													item.baseToken.positionUnits,
													true
												)} ${shortenSymbolNative(
													item.baseToken,
													currentChainId
												)}`}</Typography>
											</Description>
											<Description label={<Trans>Entry Price</Trans>}>
												<Typography variant='body2'>
													{toQuoteAmount(
														item.entryPrice,
														item.baseToken.precision
													)}
												</Typography>
											</Description>
											<CellUnRealizedPnL
												onOpenShareDialog={onOpenShareDialog}
												item={item}
												align='right'
												className='h-full'
											/>
										</Grids>

										{/* 第二行 */}
										<Grids>
											<CellMargin item={item} />
											<Description
												label={
													<Stronger
														value={<Trans>Liq. Price</Trans>}
														tooltip={
															<Typography variant='body2'>
																<Trans>
																	If{' '}
																	<Typography
																		className='inline'
																		variant='body2'
																		color={theme.custom.orangeColor}
																	>
																		Index Price
																	</Typography>{' '}
																	reaches Liq. Price, the position will be
																	liquidated, and all margins will be lost.
																</Trans>
															</Typography>
														}
													/>
												}
											>
												<Typography variant='body2'>
													<LiqPrice
														value={item.liqPrice}
														margin={item.netMargin}
														precision={item.baseToken.precision}
													/>
												</Typography>
											</Description>
											<CellNetFunding item={item} align='right' />
										</Grids>
									</div>
									<CellButtons
										item={item}
										align='left'
										onTakeProfitStopLoss={onTakeProfitStopLoss}
										onOpenClosePositionDialog={onOpenClosePositionDialog}
										onOpenAdjustMarginDialog={onOpenAdjustMarginDialog}
									/>
								</div>
							)}
						</Box>
					</ListItem>
				))
			) : (
				<NoData>
					<Trans>No open positions</Trans>
				</NoData>
			)}
			{adjustMarginDialogIsOpen &&
				currentItem &&
				currentVersion === Version.V1 && (
					<AdjustMarginDialog
						item={currentItem}
						onClose={onCloseAdjustMarginDialog}
					/>
				)}

			{adjustMarginDialogIsOpen &&
				currentItem &&
				currentVersion === Version.V2 && (
					<AdjustMarginDialogV2
						item={currentItem}
						onClose={onCloseAdjustMarginDialog}
					/>
				)}

			{closePositionDialogIsOpen && currentItem && (
				<ClosePositionDialog
					item={currentItem}
					onClose={onCloseClosePositionDialog}
				/>
			)}
			{takeProfitStopLossDialogIsOpen && currentItem && (
				<ProfitLossDialog
					item={currentItem}
					onClose={onCloseTakeProfitStopLossDialog}
				/>
			)}
			{tisDialogIsOpen && (
				<TipsDialog
					onClose={onCloseTipsDialog}
					onConfirm={onConfirmTipsDialog}
				/>
			)}
			{shareDialogIsOpen && (
				<ShareDialog item={shareItem} onClose={onCloseShareDialog} />
			)}
		</CommonStyledList>
	);
}
