import React from 'react';

import { Trans } from '@lingui/macro';
import { Divider, Typography } from '@mui/material';

import Cell from '../../../components/Common/Cell';
import TippingUnderline from '../../../components/TippingUnderline';
import { div, isGreaterThan, toPercent } from '../../../utils';

const LiquidityApr = (props: {
	item: {
		liquidityMaxApr: string;
		feeMaxApr: string;
		fundingFeeMaxApr: string;
		rewardMaxApr: string;
	};
	color?: string;
}) => {
	const { color = 'textPrimary' } = props;
	return (
		<TippingUnderline
			underlineColor='secondary'
			label={
				<Typography
					variant='body1'
					className='leading-none'
					color={
						props.item.liquidityMaxApr &&
						isGreaterThan(props.item.liquidityMaxApr, 0)
							? color
							: 'secondary'
					}
					fontWeight={400}
				>
					{toPercent(props.item.liquidityMaxApr)}
				</Typography>
			}
			placement='bottom'
			tooltip={
				<div>
					<Cell
						label={<Trans>Trading Fees APR</Trans>}
						value={toPercent(props.item.feeMaxApr)}
					/>
					<Cell
						label={<Trans>Funding Fees APR</Trans>}
						value={toPercent(props.item.fundingFeeMaxApr)}
					/>
					<Cell
						label={<Trans>EQU Mining APR</Trans>}
						value={toPercent(props.item.rewardMaxApr)}
					/>
					<Divider className='my-3' sx={{ borderStyle: 'dashed' }} />
					<div className='flex justify-between gap-8'>
						<Typography variant='body2'>
							<Trans>Total APR</Trans>
						</Typography>
						<div>{toPercent(props.item.liquidityMaxApr)}</div>
					</div>
				</div>
			}
		/>
	);
};

export default LiquidityApr;
