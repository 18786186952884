import React, { useEffect } from 'react';
import { useUpdateEffect } from 'react-use';

import { useAccount } from 'wagmi';

import { TransactionRecord } from 'entities/TransactionRecord';
import useIncreaseRBFPositionRequests from 'graphql/useIncreaseRBFPositionRequests';
import { useRiskBufferFundRewardsGraph } from 'graphql/useRiskBufferFundRewardsGraph';
import { useManageTransactions } from 'hooks/useAccountTransactions';
import { useCheckLogin } from 'hooks/useCurrentChain';
import _ from 'lodash';
import { globalBaseState } from 'state/global/slice';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { selectTransactionRecordList } from 'state/records/selector';
import { isLocalStorageFull } from 'utils';

import { setIsLoadingRBFList, setPreviousRBFList, setRBFList } from './slice';
import { useRiskBufferFundRewardsQuery } from 'graphql/__generated_referral__/types-and-hooks';
import { useApolloClients } from 'context/ApolloClientsContext';

export default React.memo(function EarnRBFUpdater() {
	const dispatch = useAppDispatch();

	const { appTimer } = useAppSelector(globalBaseState);
	const transactionRecords = useAppSelector(selectTransactionRecordList);

	const { clientReferral } = useApolloClients();
	const isLogin = useCheckLogin();
	const { address } = useAccount();
	const { removeTransaction } = useManageTransactions();

	const {
		data: RBFList,
		loading,
		refetch: refetchRBFList
	} = useRiskBufferFundRewardsGraph();

	const { requestData, refetch: refetchRequests } =
		useIncreaseRBFPositionRequests();

	useEffect(() => {
		if (requestData) {
			refetchRBFList();
		}
	}, [requestData]);

	useEffect(() => {
		dispatch(setIsLoadingRBFList(loading));
	}, [loading]);

	useUpdateEffect(() => {
		dispatch(setRBFList(RBFList));
	}, [RBFList]);

	useEffect(() => {
		if (!requestData) {
			return;
		}

		const results = _.intersectionWith(
			transactionRecords,
			requestData,
			(txRecord, request) => {
				return txRecord.hash === request.createdHash;
			}
		);

		results.forEach((item: TransactionRecord) => {
			item.syncing = false;
			if (!isLocalStorageFull()) {
				removeTransaction(item);
			} else {
				console.warn('isLocalStorageFull: true !!!');
			}
		});
	}, [RBFList]);

	const { data: riskBufferFundRewardsRep, refetch: refetchPreviouRiskBufferFundRewards } = useRiskBufferFundRewardsQuery({
		variables: { account: address ? address.toLowerCase() : '' },
		skip: !address,
		client: clientReferral
	});

	useEffect(() => {
		if (riskBufferFundRewardsRep) {
			dispatch(setPreviousRBFList(riskBufferFundRewardsRep.riskBufferFundRewards));
		}
	}, [riskBufferFundRewardsRep]);

	useUpdateEffect(() => {
		if (isLogin) {
			refetchRequests();
			refetchPreviouRiskBufferFundRewards();
		}
	}, [isLogin, address, appTimer]);

	return null;
});
