import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
  /** 8 bytes signed integer */
  Int8: any;
};

export type BlockChangedFilter = {
  number_gte: Scalars['Int'];
};

export type Block_Height = {
  hash?: InputMaybe<Scalars['Bytes']>;
  number?: InputMaybe<Scalars['Int']>;
  number_gte?: InputMaybe<Scalars['Int']>;
};

export type Candle1D = {
  __typename?: 'Candle1D';
  /** the latest price of the candle */
  close: Scalars['BigDecimal'];
  /** the highest price of the candle */
  high: Scalars['BigDecimal'];
  /** format: <token address>:<truncate timestamp> */
  id: Scalars['ID'];
  /** the lowest price of the candle */
  low: Scalars['BigDecimal'];
  /** the market that contains this candle */
  market: Market;
  /** the first price of the candle */
  open: Scalars['BigDecimal'];
  /** the start time of the candle */
  ts: Scalars['BigInt'];
  /** the number of transactions */
  txCount: Scalars['BigInt'];
  /** the trading volume of token */
  volume: Scalars['BigDecimal'];
  /** usd amount of the volume */
  volumeUSD: Scalars['BigDecimal'];
};

export type Candle1D_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Candle1D_Filter>>>;
  close?: InputMaybe<Scalars['BigDecimal']>;
  close_gt?: InputMaybe<Scalars['BigDecimal']>;
  close_gte?: InputMaybe<Scalars['BigDecimal']>;
  close_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  close_lt?: InputMaybe<Scalars['BigDecimal']>;
  close_lte?: InputMaybe<Scalars['BigDecimal']>;
  close_not?: InputMaybe<Scalars['BigDecimal']>;
  close_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  high?: InputMaybe<Scalars['BigDecimal']>;
  high_gt?: InputMaybe<Scalars['BigDecimal']>;
  high_gte?: InputMaybe<Scalars['BigDecimal']>;
  high_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  high_lt?: InputMaybe<Scalars['BigDecimal']>;
  high_lte?: InputMaybe<Scalars['BigDecimal']>;
  high_not?: InputMaybe<Scalars['BigDecimal']>;
  high_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  low?: InputMaybe<Scalars['BigDecimal']>;
  low_gt?: InputMaybe<Scalars['BigDecimal']>;
  low_gte?: InputMaybe<Scalars['BigDecimal']>;
  low_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  low_lt?: InputMaybe<Scalars['BigDecimal']>;
  low_lte?: InputMaybe<Scalars['BigDecimal']>;
  low_not?: InputMaybe<Scalars['BigDecimal']>;
  low_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  open?: InputMaybe<Scalars['BigDecimal']>;
  open_gt?: InputMaybe<Scalars['BigDecimal']>;
  open_gte?: InputMaybe<Scalars['BigDecimal']>;
  open_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  open_lt?: InputMaybe<Scalars['BigDecimal']>;
  open_lte?: InputMaybe<Scalars['BigDecimal']>;
  open_not?: InputMaybe<Scalars['BigDecimal']>;
  open_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<Candle1D_Filter>>>;
  ts?: InputMaybe<Scalars['BigInt']>;
  ts_gt?: InputMaybe<Scalars['BigInt']>;
  ts_gte?: InputMaybe<Scalars['BigInt']>;
  ts_in?: InputMaybe<Array<Scalars['BigInt']>>;
  ts_lt?: InputMaybe<Scalars['BigInt']>;
  ts_lte?: InputMaybe<Scalars['BigInt']>;
  ts_not?: InputMaybe<Scalars['BigInt']>;
  ts_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txCount?: InputMaybe<Scalars['BigInt']>;
  txCount_gt?: InputMaybe<Scalars['BigInt']>;
  txCount_gte?: InputMaybe<Scalars['BigInt']>;
  txCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txCount_lt?: InputMaybe<Scalars['BigInt']>;
  txCount_lte?: InputMaybe<Scalars['BigInt']>;
  txCount_not?: InputMaybe<Scalars['BigInt']>;
  txCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  volume?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_lt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volume_gt?: InputMaybe<Scalars['BigDecimal']>;
  volume_gte?: InputMaybe<Scalars['BigDecimal']>;
  volume_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volume_lt?: InputMaybe<Scalars['BigDecimal']>;
  volume_lte?: InputMaybe<Scalars['BigDecimal']>;
  volume_not?: InputMaybe<Scalars['BigDecimal']>;
  volume_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum Candle1D_OrderBy {
  Close = 'close',
  High = 'high',
  Id = 'id',
  Low = 'low',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  Open = 'open',
  Ts = 'ts',
  TxCount = 'txCount',
  Volume = 'volume',
  VolumeUsd = 'volumeUSD'
}

export type Candle1H = {
  __typename?: 'Candle1H';
  /** the latest price of the candle */
  close: Scalars['BigDecimal'];
  /** the highest price of the candle */
  high: Scalars['BigDecimal'];
  /** format: <token address>:<truncate timestamp> */
  id: Scalars['ID'];
  /** the lowest price of the candle */
  low: Scalars['BigDecimal'];
  /** the market that contains this candle */
  market: Market;
  /** the first price of the candle */
  open: Scalars['BigDecimal'];
  /** the start time of the candle */
  ts: Scalars['BigInt'];
  /** the number of transactions */
  txCount: Scalars['BigInt'];
  /** the trading volume of token */
  volume: Scalars['BigDecimal'];
  /** usd amount of the volume */
  volumeUSD: Scalars['BigDecimal'];
};

export type Candle1H_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Candle1H_Filter>>>;
  close?: InputMaybe<Scalars['BigDecimal']>;
  close_gt?: InputMaybe<Scalars['BigDecimal']>;
  close_gte?: InputMaybe<Scalars['BigDecimal']>;
  close_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  close_lt?: InputMaybe<Scalars['BigDecimal']>;
  close_lte?: InputMaybe<Scalars['BigDecimal']>;
  close_not?: InputMaybe<Scalars['BigDecimal']>;
  close_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  high?: InputMaybe<Scalars['BigDecimal']>;
  high_gt?: InputMaybe<Scalars['BigDecimal']>;
  high_gte?: InputMaybe<Scalars['BigDecimal']>;
  high_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  high_lt?: InputMaybe<Scalars['BigDecimal']>;
  high_lte?: InputMaybe<Scalars['BigDecimal']>;
  high_not?: InputMaybe<Scalars['BigDecimal']>;
  high_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  low?: InputMaybe<Scalars['BigDecimal']>;
  low_gt?: InputMaybe<Scalars['BigDecimal']>;
  low_gte?: InputMaybe<Scalars['BigDecimal']>;
  low_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  low_lt?: InputMaybe<Scalars['BigDecimal']>;
  low_lte?: InputMaybe<Scalars['BigDecimal']>;
  low_not?: InputMaybe<Scalars['BigDecimal']>;
  low_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  open?: InputMaybe<Scalars['BigDecimal']>;
  open_gt?: InputMaybe<Scalars['BigDecimal']>;
  open_gte?: InputMaybe<Scalars['BigDecimal']>;
  open_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  open_lt?: InputMaybe<Scalars['BigDecimal']>;
  open_lte?: InputMaybe<Scalars['BigDecimal']>;
  open_not?: InputMaybe<Scalars['BigDecimal']>;
  open_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<Candle1H_Filter>>>;
  ts?: InputMaybe<Scalars['BigInt']>;
  ts_gt?: InputMaybe<Scalars['BigInt']>;
  ts_gte?: InputMaybe<Scalars['BigInt']>;
  ts_in?: InputMaybe<Array<Scalars['BigInt']>>;
  ts_lt?: InputMaybe<Scalars['BigInt']>;
  ts_lte?: InputMaybe<Scalars['BigInt']>;
  ts_not?: InputMaybe<Scalars['BigInt']>;
  ts_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txCount?: InputMaybe<Scalars['BigInt']>;
  txCount_gt?: InputMaybe<Scalars['BigInt']>;
  txCount_gte?: InputMaybe<Scalars['BigInt']>;
  txCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txCount_lt?: InputMaybe<Scalars['BigInt']>;
  txCount_lte?: InputMaybe<Scalars['BigInt']>;
  txCount_not?: InputMaybe<Scalars['BigInt']>;
  txCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  volume?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_lt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volume_gt?: InputMaybe<Scalars['BigDecimal']>;
  volume_gte?: InputMaybe<Scalars['BigDecimal']>;
  volume_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volume_lt?: InputMaybe<Scalars['BigDecimal']>;
  volume_lte?: InputMaybe<Scalars['BigDecimal']>;
  volume_not?: InputMaybe<Scalars['BigDecimal']>;
  volume_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum Candle1H_OrderBy {
  Close = 'close',
  High = 'high',
  Id = 'id',
  Low = 'low',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  Open = 'open',
  Ts = 'ts',
  TxCount = 'txCount',
  Volume = 'volume',
  VolumeUsd = 'volumeUSD'
}

export type Candle1M = {
  __typename?: 'Candle1M';
  /** the latest price of the candle */
  close: Scalars['BigDecimal'];
  /** the highest price of the candle */
  high: Scalars['BigDecimal'];
  /** format: <token address>:<truncate timestamp> */
  id: Scalars['ID'];
  /** the lowest price of the candle */
  low: Scalars['BigDecimal'];
  /** the market that contains this candle */
  market: Market;
  /** the first price of the candle */
  open: Scalars['BigDecimal'];
  /** the start time of the candle */
  ts: Scalars['BigInt'];
  /** the number of transactions */
  txCount: Scalars['BigInt'];
  /** the trading volume of token */
  volume: Scalars['BigDecimal'];
  /** usd amount of the volume */
  volumeUSD: Scalars['BigDecimal'];
};

export type Candle1M_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Candle1M_Filter>>>;
  close?: InputMaybe<Scalars['BigDecimal']>;
  close_gt?: InputMaybe<Scalars['BigDecimal']>;
  close_gte?: InputMaybe<Scalars['BigDecimal']>;
  close_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  close_lt?: InputMaybe<Scalars['BigDecimal']>;
  close_lte?: InputMaybe<Scalars['BigDecimal']>;
  close_not?: InputMaybe<Scalars['BigDecimal']>;
  close_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  high?: InputMaybe<Scalars['BigDecimal']>;
  high_gt?: InputMaybe<Scalars['BigDecimal']>;
  high_gte?: InputMaybe<Scalars['BigDecimal']>;
  high_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  high_lt?: InputMaybe<Scalars['BigDecimal']>;
  high_lte?: InputMaybe<Scalars['BigDecimal']>;
  high_not?: InputMaybe<Scalars['BigDecimal']>;
  high_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  low?: InputMaybe<Scalars['BigDecimal']>;
  low_gt?: InputMaybe<Scalars['BigDecimal']>;
  low_gte?: InputMaybe<Scalars['BigDecimal']>;
  low_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  low_lt?: InputMaybe<Scalars['BigDecimal']>;
  low_lte?: InputMaybe<Scalars['BigDecimal']>;
  low_not?: InputMaybe<Scalars['BigDecimal']>;
  low_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  open?: InputMaybe<Scalars['BigDecimal']>;
  open_gt?: InputMaybe<Scalars['BigDecimal']>;
  open_gte?: InputMaybe<Scalars['BigDecimal']>;
  open_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  open_lt?: InputMaybe<Scalars['BigDecimal']>;
  open_lte?: InputMaybe<Scalars['BigDecimal']>;
  open_not?: InputMaybe<Scalars['BigDecimal']>;
  open_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<Candle1M_Filter>>>;
  ts?: InputMaybe<Scalars['BigInt']>;
  ts_gt?: InputMaybe<Scalars['BigInt']>;
  ts_gte?: InputMaybe<Scalars['BigInt']>;
  ts_in?: InputMaybe<Array<Scalars['BigInt']>>;
  ts_lt?: InputMaybe<Scalars['BigInt']>;
  ts_lte?: InputMaybe<Scalars['BigInt']>;
  ts_not?: InputMaybe<Scalars['BigInt']>;
  ts_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txCount?: InputMaybe<Scalars['BigInt']>;
  txCount_gt?: InputMaybe<Scalars['BigInt']>;
  txCount_gte?: InputMaybe<Scalars['BigInt']>;
  txCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txCount_lt?: InputMaybe<Scalars['BigInt']>;
  txCount_lte?: InputMaybe<Scalars['BigInt']>;
  txCount_not?: InputMaybe<Scalars['BigInt']>;
  txCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  volume?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_lt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volume_gt?: InputMaybe<Scalars['BigDecimal']>;
  volume_gte?: InputMaybe<Scalars['BigDecimal']>;
  volume_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volume_lt?: InputMaybe<Scalars['BigDecimal']>;
  volume_lte?: InputMaybe<Scalars['BigDecimal']>;
  volume_not?: InputMaybe<Scalars['BigDecimal']>;
  volume_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum Candle1M_OrderBy {
  Close = 'close',
  High = 'high',
  Id = 'id',
  Low = 'low',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  Open = 'open',
  Ts = 'ts',
  TxCount = 'txCount',
  Volume = 'volume',
  VolumeUsd = 'volumeUSD'
}

export type Candle4H = {
  __typename?: 'Candle4H';
  /** the latest price of the candle */
  close: Scalars['BigDecimal'];
  /** the highest price of the candle */
  high: Scalars['BigDecimal'];
  /** format: <token address>:<truncate timestamp> */
  id: Scalars['ID'];
  /** the lowest price of the candle */
  low: Scalars['BigDecimal'];
  /** the market that contains this candle */
  market: Market;
  /** the first price of the candle */
  open: Scalars['BigDecimal'];
  /** the start time of the candle */
  ts: Scalars['BigInt'];
  /** the number of transactions */
  txCount: Scalars['BigInt'];
  /** the trading volume of token */
  volume: Scalars['BigDecimal'];
  /** usd amount of the volume */
  volumeUSD: Scalars['BigDecimal'];
};

export type Candle4H_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Candle4H_Filter>>>;
  close?: InputMaybe<Scalars['BigDecimal']>;
  close_gt?: InputMaybe<Scalars['BigDecimal']>;
  close_gte?: InputMaybe<Scalars['BigDecimal']>;
  close_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  close_lt?: InputMaybe<Scalars['BigDecimal']>;
  close_lte?: InputMaybe<Scalars['BigDecimal']>;
  close_not?: InputMaybe<Scalars['BigDecimal']>;
  close_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  high?: InputMaybe<Scalars['BigDecimal']>;
  high_gt?: InputMaybe<Scalars['BigDecimal']>;
  high_gte?: InputMaybe<Scalars['BigDecimal']>;
  high_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  high_lt?: InputMaybe<Scalars['BigDecimal']>;
  high_lte?: InputMaybe<Scalars['BigDecimal']>;
  high_not?: InputMaybe<Scalars['BigDecimal']>;
  high_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  low?: InputMaybe<Scalars['BigDecimal']>;
  low_gt?: InputMaybe<Scalars['BigDecimal']>;
  low_gte?: InputMaybe<Scalars['BigDecimal']>;
  low_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  low_lt?: InputMaybe<Scalars['BigDecimal']>;
  low_lte?: InputMaybe<Scalars['BigDecimal']>;
  low_not?: InputMaybe<Scalars['BigDecimal']>;
  low_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  open?: InputMaybe<Scalars['BigDecimal']>;
  open_gt?: InputMaybe<Scalars['BigDecimal']>;
  open_gte?: InputMaybe<Scalars['BigDecimal']>;
  open_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  open_lt?: InputMaybe<Scalars['BigDecimal']>;
  open_lte?: InputMaybe<Scalars['BigDecimal']>;
  open_not?: InputMaybe<Scalars['BigDecimal']>;
  open_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<Candle4H_Filter>>>;
  ts?: InputMaybe<Scalars['BigInt']>;
  ts_gt?: InputMaybe<Scalars['BigInt']>;
  ts_gte?: InputMaybe<Scalars['BigInt']>;
  ts_in?: InputMaybe<Array<Scalars['BigInt']>>;
  ts_lt?: InputMaybe<Scalars['BigInt']>;
  ts_lte?: InputMaybe<Scalars['BigInt']>;
  ts_not?: InputMaybe<Scalars['BigInt']>;
  ts_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txCount?: InputMaybe<Scalars['BigInt']>;
  txCount_gt?: InputMaybe<Scalars['BigInt']>;
  txCount_gte?: InputMaybe<Scalars['BigInt']>;
  txCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txCount_lt?: InputMaybe<Scalars['BigInt']>;
  txCount_lte?: InputMaybe<Scalars['BigInt']>;
  txCount_not?: InputMaybe<Scalars['BigInt']>;
  txCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  volume?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_lt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volume_gt?: InputMaybe<Scalars['BigDecimal']>;
  volume_gte?: InputMaybe<Scalars['BigDecimal']>;
  volume_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volume_lt?: InputMaybe<Scalars['BigDecimal']>;
  volume_lte?: InputMaybe<Scalars['BigDecimal']>;
  volume_not?: InputMaybe<Scalars['BigDecimal']>;
  volume_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum Candle4H_OrderBy {
  Close = 'close',
  High = 'high',
  Id = 'id',
  Low = 'low',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  Open = 'open',
  Ts = 'ts',
  TxCount = 'txCount',
  Volume = 'volume',
  VolumeUsd = 'volumeUSD'
}

export type Candle5M = {
  __typename?: 'Candle5M';
  /** the latest price of the candle */
  close: Scalars['BigDecimal'];
  /** the highest price of the candle */
  high: Scalars['BigDecimal'];
  /** format: <token address>:<truncate timestamp> */
  id: Scalars['ID'];
  /** the lowest price of the candle */
  low: Scalars['BigDecimal'];
  /** the market that contains this candle */
  market: Market;
  /** the first price of the candle */
  open: Scalars['BigDecimal'];
  /** the start time of the candle */
  ts: Scalars['BigInt'];
  /** the number of transactions */
  txCount: Scalars['BigInt'];
  /** the trading volume of token */
  volume: Scalars['BigDecimal'];
  /** usd amount of the volume */
  volumeUSD: Scalars['BigDecimal'];
};

export type Candle5M_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Candle5M_Filter>>>;
  close?: InputMaybe<Scalars['BigDecimal']>;
  close_gt?: InputMaybe<Scalars['BigDecimal']>;
  close_gte?: InputMaybe<Scalars['BigDecimal']>;
  close_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  close_lt?: InputMaybe<Scalars['BigDecimal']>;
  close_lte?: InputMaybe<Scalars['BigDecimal']>;
  close_not?: InputMaybe<Scalars['BigDecimal']>;
  close_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  high?: InputMaybe<Scalars['BigDecimal']>;
  high_gt?: InputMaybe<Scalars['BigDecimal']>;
  high_gte?: InputMaybe<Scalars['BigDecimal']>;
  high_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  high_lt?: InputMaybe<Scalars['BigDecimal']>;
  high_lte?: InputMaybe<Scalars['BigDecimal']>;
  high_not?: InputMaybe<Scalars['BigDecimal']>;
  high_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  low?: InputMaybe<Scalars['BigDecimal']>;
  low_gt?: InputMaybe<Scalars['BigDecimal']>;
  low_gte?: InputMaybe<Scalars['BigDecimal']>;
  low_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  low_lt?: InputMaybe<Scalars['BigDecimal']>;
  low_lte?: InputMaybe<Scalars['BigDecimal']>;
  low_not?: InputMaybe<Scalars['BigDecimal']>;
  low_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  open?: InputMaybe<Scalars['BigDecimal']>;
  open_gt?: InputMaybe<Scalars['BigDecimal']>;
  open_gte?: InputMaybe<Scalars['BigDecimal']>;
  open_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  open_lt?: InputMaybe<Scalars['BigDecimal']>;
  open_lte?: InputMaybe<Scalars['BigDecimal']>;
  open_not?: InputMaybe<Scalars['BigDecimal']>;
  open_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<Candle5M_Filter>>>;
  ts?: InputMaybe<Scalars['BigInt']>;
  ts_gt?: InputMaybe<Scalars['BigInt']>;
  ts_gte?: InputMaybe<Scalars['BigInt']>;
  ts_in?: InputMaybe<Array<Scalars['BigInt']>>;
  ts_lt?: InputMaybe<Scalars['BigInt']>;
  ts_lte?: InputMaybe<Scalars['BigInt']>;
  ts_not?: InputMaybe<Scalars['BigInt']>;
  ts_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txCount?: InputMaybe<Scalars['BigInt']>;
  txCount_gt?: InputMaybe<Scalars['BigInt']>;
  txCount_gte?: InputMaybe<Scalars['BigInt']>;
  txCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txCount_lt?: InputMaybe<Scalars['BigInt']>;
  txCount_lte?: InputMaybe<Scalars['BigInt']>;
  txCount_not?: InputMaybe<Scalars['BigInt']>;
  txCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  volume?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_lt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volume_gt?: InputMaybe<Scalars['BigDecimal']>;
  volume_gte?: InputMaybe<Scalars['BigDecimal']>;
  volume_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volume_lt?: InputMaybe<Scalars['BigDecimal']>;
  volume_lte?: InputMaybe<Scalars['BigDecimal']>;
  volume_not?: InputMaybe<Scalars['BigDecimal']>;
  volume_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum Candle5M_OrderBy {
  Close = 'close',
  High = 'high',
  Id = 'id',
  Low = 'low',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  Open = 'open',
  Ts = 'ts',
  TxCount = 'txCount',
  Volume = 'volume',
  VolumeUsd = 'volumeUSD'
}

export type Candle15M = {
  __typename?: 'Candle15M';
  /** the latest price of the candle */
  close: Scalars['BigDecimal'];
  /** the highest price of the candle */
  high: Scalars['BigDecimal'];
  /** format: <token address>:<truncate timestamp> */
  id: Scalars['ID'];
  /** the lowest price of the candle */
  low: Scalars['BigDecimal'];
  /** the market that contains this candle */
  market: Market;
  /** the first price of the candle */
  open: Scalars['BigDecimal'];
  /** the start time of the candle */
  ts: Scalars['BigInt'];
  /** the number of transactions */
  txCount: Scalars['BigInt'];
  /** the trading volume of token */
  volume: Scalars['BigDecimal'];
  /** usd amount of the volume */
  volumeUSD: Scalars['BigDecimal'];
};

export type Candle15M_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Candle15M_Filter>>>;
  close?: InputMaybe<Scalars['BigDecimal']>;
  close_gt?: InputMaybe<Scalars['BigDecimal']>;
  close_gte?: InputMaybe<Scalars['BigDecimal']>;
  close_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  close_lt?: InputMaybe<Scalars['BigDecimal']>;
  close_lte?: InputMaybe<Scalars['BigDecimal']>;
  close_not?: InputMaybe<Scalars['BigDecimal']>;
  close_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  high?: InputMaybe<Scalars['BigDecimal']>;
  high_gt?: InputMaybe<Scalars['BigDecimal']>;
  high_gte?: InputMaybe<Scalars['BigDecimal']>;
  high_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  high_lt?: InputMaybe<Scalars['BigDecimal']>;
  high_lte?: InputMaybe<Scalars['BigDecimal']>;
  high_not?: InputMaybe<Scalars['BigDecimal']>;
  high_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  low?: InputMaybe<Scalars['BigDecimal']>;
  low_gt?: InputMaybe<Scalars['BigDecimal']>;
  low_gte?: InputMaybe<Scalars['BigDecimal']>;
  low_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  low_lt?: InputMaybe<Scalars['BigDecimal']>;
  low_lte?: InputMaybe<Scalars['BigDecimal']>;
  low_not?: InputMaybe<Scalars['BigDecimal']>;
  low_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  open?: InputMaybe<Scalars['BigDecimal']>;
  open_gt?: InputMaybe<Scalars['BigDecimal']>;
  open_gte?: InputMaybe<Scalars['BigDecimal']>;
  open_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  open_lt?: InputMaybe<Scalars['BigDecimal']>;
  open_lte?: InputMaybe<Scalars['BigDecimal']>;
  open_not?: InputMaybe<Scalars['BigDecimal']>;
  open_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<Candle15M_Filter>>>;
  ts?: InputMaybe<Scalars['BigInt']>;
  ts_gt?: InputMaybe<Scalars['BigInt']>;
  ts_gte?: InputMaybe<Scalars['BigInt']>;
  ts_in?: InputMaybe<Array<Scalars['BigInt']>>;
  ts_lt?: InputMaybe<Scalars['BigInt']>;
  ts_lte?: InputMaybe<Scalars['BigInt']>;
  ts_not?: InputMaybe<Scalars['BigInt']>;
  ts_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txCount?: InputMaybe<Scalars['BigInt']>;
  txCount_gt?: InputMaybe<Scalars['BigInt']>;
  txCount_gte?: InputMaybe<Scalars['BigInt']>;
  txCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txCount_lt?: InputMaybe<Scalars['BigInt']>;
  txCount_lte?: InputMaybe<Scalars['BigInt']>;
  txCount_not?: InputMaybe<Scalars['BigInt']>;
  txCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  volume?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_lt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volume_gt?: InputMaybe<Scalars['BigDecimal']>;
  volume_gte?: InputMaybe<Scalars['BigDecimal']>;
  volume_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volume_lt?: InputMaybe<Scalars['BigDecimal']>;
  volume_lte?: InputMaybe<Scalars['BigDecimal']>;
  volume_not?: InputMaybe<Scalars['BigDecimal']>;
  volume_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum Candle15M_OrderBy {
  Close = 'close',
  High = 'high',
  Id = 'id',
  Low = 'low',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  Open = 'open',
  Ts = 'ts',
  TxCount = 'txCount',
  Volume = 'volume',
  VolumeUsd = 'volumeUSD'
}

export type Candle30M = {
  __typename?: 'Candle30M';
  /** the latest price of the candle */
  close: Scalars['BigDecimal'];
  /** the highest price of the candle */
  high: Scalars['BigDecimal'];
  /** format: <token address>:<truncate timestamp> */
  id: Scalars['ID'];
  /** the lowest price of the candle */
  low: Scalars['BigDecimal'];
  /** the market that contains this candle */
  market: Market;
  /** the first price of the candle */
  open: Scalars['BigDecimal'];
  /** the start time of the candle */
  ts: Scalars['BigInt'];
  /** the number of transactions */
  txCount: Scalars['BigInt'];
  /** the trading volume of token */
  volume: Scalars['BigDecimal'];
  /** usd amount of the volume */
  volumeUSD: Scalars['BigDecimal'];
};

export type Candle30M_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Candle30M_Filter>>>;
  close?: InputMaybe<Scalars['BigDecimal']>;
  close_gt?: InputMaybe<Scalars['BigDecimal']>;
  close_gte?: InputMaybe<Scalars['BigDecimal']>;
  close_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  close_lt?: InputMaybe<Scalars['BigDecimal']>;
  close_lte?: InputMaybe<Scalars['BigDecimal']>;
  close_not?: InputMaybe<Scalars['BigDecimal']>;
  close_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  high?: InputMaybe<Scalars['BigDecimal']>;
  high_gt?: InputMaybe<Scalars['BigDecimal']>;
  high_gte?: InputMaybe<Scalars['BigDecimal']>;
  high_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  high_lt?: InputMaybe<Scalars['BigDecimal']>;
  high_lte?: InputMaybe<Scalars['BigDecimal']>;
  high_not?: InputMaybe<Scalars['BigDecimal']>;
  high_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  low?: InputMaybe<Scalars['BigDecimal']>;
  low_gt?: InputMaybe<Scalars['BigDecimal']>;
  low_gte?: InputMaybe<Scalars['BigDecimal']>;
  low_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  low_lt?: InputMaybe<Scalars['BigDecimal']>;
  low_lte?: InputMaybe<Scalars['BigDecimal']>;
  low_not?: InputMaybe<Scalars['BigDecimal']>;
  low_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  open?: InputMaybe<Scalars['BigDecimal']>;
  open_gt?: InputMaybe<Scalars['BigDecimal']>;
  open_gte?: InputMaybe<Scalars['BigDecimal']>;
  open_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  open_lt?: InputMaybe<Scalars['BigDecimal']>;
  open_lte?: InputMaybe<Scalars['BigDecimal']>;
  open_not?: InputMaybe<Scalars['BigDecimal']>;
  open_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<Candle30M_Filter>>>;
  ts?: InputMaybe<Scalars['BigInt']>;
  ts_gt?: InputMaybe<Scalars['BigInt']>;
  ts_gte?: InputMaybe<Scalars['BigInt']>;
  ts_in?: InputMaybe<Array<Scalars['BigInt']>>;
  ts_lt?: InputMaybe<Scalars['BigInt']>;
  ts_lte?: InputMaybe<Scalars['BigInt']>;
  ts_not?: InputMaybe<Scalars['BigInt']>;
  ts_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txCount?: InputMaybe<Scalars['BigInt']>;
  txCount_gt?: InputMaybe<Scalars['BigInt']>;
  txCount_gte?: InputMaybe<Scalars['BigInt']>;
  txCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txCount_lt?: InputMaybe<Scalars['BigInt']>;
  txCount_lte?: InputMaybe<Scalars['BigInt']>;
  txCount_not?: InputMaybe<Scalars['BigInt']>;
  txCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  volume?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_lt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volume_gt?: InputMaybe<Scalars['BigDecimal']>;
  volume_gte?: InputMaybe<Scalars['BigDecimal']>;
  volume_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volume_lt?: InputMaybe<Scalars['BigDecimal']>;
  volume_lte?: InputMaybe<Scalars['BigDecimal']>;
  volume_not?: InputMaybe<Scalars['BigDecimal']>;
  volume_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum Candle30M_OrderBy {
  Close = 'close',
  High = 'high',
  Id = 'id',
  Low = 'low',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  Open = 'open',
  Ts = 'ts',
  TxCount = 'txCount',
  Volume = 'volume',
  VolumeUsd = 'volumeUSD'
}

/** entity that represents LPs' request to decrease liquidity position */
export type DecreaseLiquidityPositionRequest = {
  __typename?: 'DecreaseLiquidityPositionRequest';
  /** the min margin after to accept */
  acceptableMinMargin: Scalars['BigDecimal'];
  /** account that create the request */
  account: Scalars['Bytes'];
  /** valid if the request cancelled, hash of the cancellation tx */
  cancelledHash: Scalars['Bytes'];
  /** tx hash of the request creation */
  createdHash: Scalars['Bytes'];
  /** valid if the request executed, hash of the execution tx */
  executedHash: Scalars['Bytes'];
  /** execution fee paid to executor to carry out the request */
  executionFee: Scalars['BigInt'];
  id: Scalars['ID'];
  /** the request index */
  index: Scalars['BigInt'];
  /** liquidity to increase */
  liquidityDelta: Scalars['BigDecimal'];
  /** margin used to open the position */
  marginDelta: Scalars['BigDecimal'];
  /** in which market to decrease liquidity position */
  market: Market;
  /** margin receiver */
  receiver: Scalars['Bytes'];
  /** status of the request, enum{Created, Cancelled, Executed} */
  status: Status;
};

export type DecreaseLiquidityPositionRequest_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  acceptableMinMargin?: InputMaybe<Scalars['BigDecimal']>;
  acceptableMinMargin_gt?: InputMaybe<Scalars['BigDecimal']>;
  acceptableMinMargin_gte?: InputMaybe<Scalars['BigDecimal']>;
  acceptableMinMargin_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  acceptableMinMargin_lt?: InputMaybe<Scalars['BigDecimal']>;
  acceptableMinMargin_lte?: InputMaybe<Scalars['BigDecimal']>;
  acceptableMinMargin_not?: InputMaybe<Scalars['BigDecimal']>;
  acceptableMinMargin_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  account?: InputMaybe<Scalars['Bytes']>;
  account_contains?: InputMaybe<Scalars['Bytes']>;
  account_gt?: InputMaybe<Scalars['Bytes']>;
  account_gte?: InputMaybe<Scalars['Bytes']>;
  account_in?: InputMaybe<Array<Scalars['Bytes']>>;
  account_lt?: InputMaybe<Scalars['Bytes']>;
  account_lte?: InputMaybe<Scalars['Bytes']>;
  account_not?: InputMaybe<Scalars['Bytes']>;
  account_not_contains?: InputMaybe<Scalars['Bytes']>;
  account_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  and?: InputMaybe<Array<InputMaybe<DecreaseLiquidityPositionRequest_Filter>>>;
  cancelledHash?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_contains?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_gt?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_gte?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  cancelledHash_lt?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_lte?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_not?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  createdHash?: InputMaybe<Scalars['Bytes']>;
  createdHash_contains?: InputMaybe<Scalars['Bytes']>;
  createdHash_gt?: InputMaybe<Scalars['Bytes']>;
  createdHash_gte?: InputMaybe<Scalars['Bytes']>;
  createdHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  createdHash_lt?: InputMaybe<Scalars['Bytes']>;
  createdHash_lte?: InputMaybe<Scalars['Bytes']>;
  createdHash_not?: InputMaybe<Scalars['Bytes']>;
  createdHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  createdHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  executedHash?: InputMaybe<Scalars['Bytes']>;
  executedHash_contains?: InputMaybe<Scalars['Bytes']>;
  executedHash_gt?: InputMaybe<Scalars['Bytes']>;
  executedHash_gte?: InputMaybe<Scalars['Bytes']>;
  executedHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  executedHash_lt?: InputMaybe<Scalars['Bytes']>;
  executedHash_lte?: InputMaybe<Scalars['Bytes']>;
  executedHash_not?: InputMaybe<Scalars['Bytes']>;
  executedHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  executedHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  executionFee?: InputMaybe<Scalars['BigInt']>;
  executionFee_gt?: InputMaybe<Scalars['BigInt']>;
  executionFee_gte?: InputMaybe<Scalars['BigInt']>;
  executionFee_in?: InputMaybe<Array<Scalars['BigInt']>>;
  executionFee_lt?: InputMaybe<Scalars['BigInt']>;
  executionFee_lte?: InputMaybe<Scalars['BigInt']>;
  executionFee_not?: InputMaybe<Scalars['BigInt']>;
  executionFee_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  index?: InputMaybe<Scalars['BigInt']>;
  index_gt?: InputMaybe<Scalars['BigInt']>;
  index_gte?: InputMaybe<Scalars['BigInt']>;
  index_in?: InputMaybe<Array<Scalars['BigInt']>>;
  index_lt?: InputMaybe<Scalars['BigInt']>;
  index_lte?: InputMaybe<Scalars['BigInt']>;
  index_not?: InputMaybe<Scalars['BigInt']>;
  index_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  liquidityDelta?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDelta_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDelta_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDelta_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidityDelta_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDelta_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDelta_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDelta_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  marginDelta?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_gt?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_gte?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  marginDelta_lt?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_lte?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_not?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<DecreaseLiquidityPositionRequest_Filter>>>;
  receiver?: InputMaybe<Scalars['Bytes']>;
  receiver_contains?: InputMaybe<Scalars['Bytes']>;
  receiver_gt?: InputMaybe<Scalars['Bytes']>;
  receiver_gte?: InputMaybe<Scalars['Bytes']>;
  receiver_in?: InputMaybe<Array<Scalars['Bytes']>>;
  receiver_lt?: InputMaybe<Scalars['Bytes']>;
  receiver_lte?: InputMaybe<Scalars['Bytes']>;
  receiver_not?: InputMaybe<Scalars['Bytes']>;
  receiver_not_contains?: InputMaybe<Scalars['Bytes']>;
  receiver_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  status?: InputMaybe<Status>;
  status_in?: InputMaybe<Array<Status>>;
  status_not?: InputMaybe<Status>;
  status_not_in?: InputMaybe<Array<Status>>;
};

export enum DecreaseLiquidityPositionRequest_OrderBy {
  AcceptableMinMargin = 'acceptableMinMargin',
  Account = 'account',
  CancelledHash = 'cancelledHash',
  CreatedHash = 'createdHash',
  ExecutedHash = 'executedHash',
  ExecutionFee = 'executionFee',
  Id = 'id',
  Index = 'index',
  LiquidityDelta = 'liquidityDelta',
  MarginDelta = 'marginDelta',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  Receiver = 'receiver',
  Status = 'status'
}

/** entity that represents Traders' request to close/decrease a position */
export type DecreasePositionRequest = {
  __typename?: 'DecreasePositionRequest';
  /** worst trade price trader can accept */
  acceptableTradePrice: Scalars['BigDecimal'];
  /** worst trade price trader can accept, as a Q64.96 */
  acceptableTradePriceX96: Scalars['BigInt'];
  /** account that create the request */
  account: Scalars['Bytes'];
  /** valid if the request cancelled, hash of the cancellation tx */
  cancelledHash: Scalars['Bytes'];
  /** tx hash of the request creation */
  createdHash: Scalars['Bytes'];
  /** valid if the request executed, hash of the execution tx */
  executedHash: Scalars['Bytes'];
  /** execution fee paid to executor to carry out the request */
  executionFee: Scalars['BigInt'];
  id: Scalars['ID'];
  /** the request index */
  index: Scalars['BigInt'];
  /** delta of the margin to decrease */
  marginDelta: Scalars['BigDecimal'];
  /** in which market to decrease position */
  market: Market;
  /** margin receiver */
  receiver: Scalars['Bytes'];
  /** side of the request, 1 for long and 2 for short */
  side: Scalars['Int'];
  /** size of the base token to decrease */
  sizeDelta: Scalars['BigDecimal'];
  /** status of the request, enum{Created, Cancelled, Executed} */
  status: Status;
};

export type DecreasePositionRequest_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  acceptableTradePrice?: InputMaybe<Scalars['BigDecimal']>;
  acceptableTradePriceX96?: InputMaybe<Scalars['BigInt']>;
  acceptableTradePriceX96_gt?: InputMaybe<Scalars['BigInt']>;
  acceptableTradePriceX96_gte?: InputMaybe<Scalars['BigInt']>;
  acceptableTradePriceX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  acceptableTradePriceX96_lt?: InputMaybe<Scalars['BigInt']>;
  acceptableTradePriceX96_lte?: InputMaybe<Scalars['BigInt']>;
  acceptableTradePriceX96_not?: InputMaybe<Scalars['BigInt']>;
  acceptableTradePriceX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  acceptableTradePrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  acceptableTradePrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  acceptableTradePrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  acceptableTradePrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  acceptableTradePrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  acceptableTradePrice_not?: InputMaybe<Scalars['BigDecimal']>;
  acceptableTradePrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  account?: InputMaybe<Scalars['Bytes']>;
  account_contains?: InputMaybe<Scalars['Bytes']>;
  account_gt?: InputMaybe<Scalars['Bytes']>;
  account_gte?: InputMaybe<Scalars['Bytes']>;
  account_in?: InputMaybe<Array<Scalars['Bytes']>>;
  account_lt?: InputMaybe<Scalars['Bytes']>;
  account_lte?: InputMaybe<Scalars['Bytes']>;
  account_not?: InputMaybe<Scalars['Bytes']>;
  account_not_contains?: InputMaybe<Scalars['Bytes']>;
  account_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  and?: InputMaybe<Array<InputMaybe<DecreasePositionRequest_Filter>>>;
  cancelledHash?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_contains?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_gt?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_gte?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  cancelledHash_lt?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_lte?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_not?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  createdHash?: InputMaybe<Scalars['Bytes']>;
  createdHash_contains?: InputMaybe<Scalars['Bytes']>;
  createdHash_gt?: InputMaybe<Scalars['Bytes']>;
  createdHash_gte?: InputMaybe<Scalars['Bytes']>;
  createdHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  createdHash_lt?: InputMaybe<Scalars['Bytes']>;
  createdHash_lte?: InputMaybe<Scalars['Bytes']>;
  createdHash_not?: InputMaybe<Scalars['Bytes']>;
  createdHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  createdHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  executedHash?: InputMaybe<Scalars['Bytes']>;
  executedHash_contains?: InputMaybe<Scalars['Bytes']>;
  executedHash_gt?: InputMaybe<Scalars['Bytes']>;
  executedHash_gte?: InputMaybe<Scalars['Bytes']>;
  executedHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  executedHash_lt?: InputMaybe<Scalars['Bytes']>;
  executedHash_lte?: InputMaybe<Scalars['Bytes']>;
  executedHash_not?: InputMaybe<Scalars['Bytes']>;
  executedHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  executedHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  executionFee?: InputMaybe<Scalars['BigInt']>;
  executionFee_gt?: InputMaybe<Scalars['BigInt']>;
  executionFee_gte?: InputMaybe<Scalars['BigInt']>;
  executionFee_in?: InputMaybe<Array<Scalars['BigInt']>>;
  executionFee_lt?: InputMaybe<Scalars['BigInt']>;
  executionFee_lte?: InputMaybe<Scalars['BigInt']>;
  executionFee_not?: InputMaybe<Scalars['BigInt']>;
  executionFee_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  index?: InputMaybe<Scalars['BigInt']>;
  index_gt?: InputMaybe<Scalars['BigInt']>;
  index_gte?: InputMaybe<Scalars['BigInt']>;
  index_in?: InputMaybe<Array<Scalars['BigInt']>>;
  index_lt?: InputMaybe<Scalars['BigInt']>;
  index_lte?: InputMaybe<Scalars['BigInt']>;
  index_not?: InputMaybe<Scalars['BigInt']>;
  index_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  marginDelta?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_gt?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_gte?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  marginDelta_lt?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_lte?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_not?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<DecreasePositionRequest_Filter>>>;
  receiver?: InputMaybe<Scalars['Bytes']>;
  receiver_contains?: InputMaybe<Scalars['Bytes']>;
  receiver_gt?: InputMaybe<Scalars['Bytes']>;
  receiver_gte?: InputMaybe<Scalars['Bytes']>;
  receiver_in?: InputMaybe<Array<Scalars['Bytes']>>;
  receiver_lt?: InputMaybe<Scalars['Bytes']>;
  receiver_lte?: InputMaybe<Scalars['Bytes']>;
  receiver_not?: InputMaybe<Scalars['Bytes']>;
  receiver_not_contains?: InputMaybe<Scalars['Bytes']>;
  receiver_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  side?: InputMaybe<Scalars['Int']>;
  side_gt?: InputMaybe<Scalars['Int']>;
  side_gte?: InputMaybe<Scalars['Int']>;
  side_in?: InputMaybe<Array<Scalars['Int']>>;
  side_lt?: InputMaybe<Scalars['Int']>;
  side_lte?: InputMaybe<Scalars['Int']>;
  side_not?: InputMaybe<Scalars['Int']>;
  side_not_in?: InputMaybe<Array<Scalars['Int']>>;
  sizeDelta?: InputMaybe<Scalars['BigDecimal']>;
  sizeDelta_gt?: InputMaybe<Scalars['BigDecimal']>;
  sizeDelta_gte?: InputMaybe<Scalars['BigDecimal']>;
  sizeDelta_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  sizeDelta_lt?: InputMaybe<Scalars['BigDecimal']>;
  sizeDelta_lte?: InputMaybe<Scalars['BigDecimal']>;
  sizeDelta_not?: InputMaybe<Scalars['BigDecimal']>;
  sizeDelta_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  status?: InputMaybe<Status>;
  status_in?: InputMaybe<Array<Status>>;
  status_not?: InputMaybe<Status>;
  status_not_in?: InputMaybe<Array<Status>>;
};

export enum DecreasePositionRequest_OrderBy {
  AcceptableTradePrice = 'acceptableTradePrice',
  AcceptableTradePriceX96 = 'acceptableTradePriceX96',
  Account = 'account',
  CancelledHash = 'cancelledHash',
  CreatedHash = 'createdHash',
  ExecutedHash = 'executedHash',
  ExecutionFee = 'executionFee',
  Id = 'id',
  Index = 'index',
  MarginDelta = 'marginDelta',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  Receiver = 'receiver',
  Side = 'side',
  SizeDelta = 'sizeDelta',
  Status = 'status'
}

export type FundingRateHistory = {
  __typename?: 'FundingRateHistory';
  /** block which transaction was included in */
  blockNumber: Scalars['BigInt'];
  /** timestamp of the confirmed transaction */
  blockTimestamp: Scalars['BigInt'];
  /**
   * the change in funding rate, a positive value means longs pay shorts,
   * when a negative value means shorts pay longs
   */
  fundingRate: Scalars['BigDecimal'];
  /** format: <market address>:<last adjust time> */
  id: Scalars['ID'];
  /** pointer to market */
  market: Market;
  /** the timestamp of the last funding rate adjustment */
  ts: Scalars['BigInt'];
  /** tx hash of the confirmed transaction */
  txHash: Scalars['Bytes'];
};

export type FundingRateHistory_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<FundingRateHistory_Filter>>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  fundingRate?: InputMaybe<Scalars['BigDecimal']>;
  fundingRate_gt?: InputMaybe<Scalars['BigDecimal']>;
  fundingRate_gte?: InputMaybe<Scalars['BigDecimal']>;
  fundingRate_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fundingRate_lt?: InputMaybe<Scalars['BigDecimal']>;
  fundingRate_lte?: InputMaybe<Scalars['BigDecimal']>;
  fundingRate_not?: InputMaybe<Scalars['BigDecimal']>;
  fundingRate_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<FundingRateHistory_Filter>>>;
  ts?: InputMaybe<Scalars['BigInt']>;
  ts_gt?: InputMaybe<Scalars['BigInt']>;
  ts_gte?: InputMaybe<Scalars['BigInt']>;
  ts_in?: InputMaybe<Array<Scalars['BigInt']>>;
  ts_lt?: InputMaybe<Scalars['BigInt']>;
  ts_lte?: InputMaybe<Scalars['BigInt']>;
  ts_not?: InputMaybe<Scalars['BigInt']>;
  ts_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txHash?: InputMaybe<Scalars['Bytes']>;
  txHash_contains?: InputMaybe<Scalars['Bytes']>;
  txHash_gt?: InputMaybe<Scalars['Bytes']>;
  txHash_gte?: InputMaybe<Scalars['Bytes']>;
  txHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  txHash_lt?: InputMaybe<Scalars['Bytes']>;
  txHash_lte?: InputMaybe<Scalars['Bytes']>;
  txHash_not?: InputMaybe<Scalars['Bytes']>;
  txHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  txHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
};

export enum FundingRateHistory_OrderBy {
  BlockNumber = 'blockNumber',
  BlockTimestamp = 'blockTimestamp',
  FundingRate = 'fundingRate',
  Id = 'id',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  Ts = 'ts',
  TxHash = 'txHash'
}

export type GlobalFundingRateSample = {
  __typename?: 'GlobalFundingRateSample';
  /** the cumulative premium rate of the samples taken since the last funding rate adjustment */
  cumulativePremiumRate: Scalars['BigDecimal'];
  /** the cumulative premium rate of the samples taken since the last funding rate adjustment, as a Q80.96 */
  cumulativePremiumRateX96: Scalars['BigInt'];
  /** market address */
  id: Scalars['Bytes'];
  /** pointer to market */
  market: Market;
  /** the number of samples taken since the last funding rate adjustment */
  sampleCount: Scalars['Int'];
};

export type GlobalFundingRateSample_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<GlobalFundingRateSample_Filter>>>;
  cumulativePremiumRate?: InputMaybe<Scalars['BigDecimal']>;
  cumulativePremiumRateX96?: InputMaybe<Scalars['BigInt']>;
  cumulativePremiumRateX96_gt?: InputMaybe<Scalars['BigInt']>;
  cumulativePremiumRateX96_gte?: InputMaybe<Scalars['BigInt']>;
  cumulativePremiumRateX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  cumulativePremiumRateX96_lt?: InputMaybe<Scalars['BigInt']>;
  cumulativePremiumRateX96_lte?: InputMaybe<Scalars['BigInt']>;
  cumulativePremiumRateX96_not?: InputMaybe<Scalars['BigInt']>;
  cumulativePremiumRateX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  cumulativePremiumRate_gt?: InputMaybe<Scalars['BigDecimal']>;
  cumulativePremiumRate_gte?: InputMaybe<Scalars['BigDecimal']>;
  cumulativePremiumRate_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  cumulativePremiumRate_lt?: InputMaybe<Scalars['BigDecimal']>;
  cumulativePremiumRate_lte?: InputMaybe<Scalars['BigDecimal']>;
  cumulativePremiumRate_not?: InputMaybe<Scalars['BigDecimal']>;
  cumulativePremiumRate_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<GlobalFundingRateSample_Filter>>>;
  sampleCount?: InputMaybe<Scalars['Int']>;
  sampleCount_gt?: InputMaybe<Scalars['Int']>;
  sampleCount_gte?: InputMaybe<Scalars['Int']>;
  sampleCount_in?: InputMaybe<Array<Scalars['Int']>>;
  sampleCount_lt?: InputMaybe<Scalars['Int']>;
  sampleCount_lte?: InputMaybe<Scalars['Int']>;
  sampleCount_not?: InputMaybe<Scalars['Int']>;
  sampleCount_not_in?: InputMaybe<Array<Scalars['Int']>>;
};

export enum GlobalFundingRateSample_OrderBy {
  CumulativePremiumRate = 'cumulativePremiumRate',
  CumulativePremiumRateX96 = 'cumulativePremiumRateX96',
  Id = 'id',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  SampleCount = 'sampleCount'
}

export type GlobalLiquidationFund = {
  __typename?: 'GlobalLiquidationFund';
  /** market address */
  id: Scalars['Bytes'];
  /** the global liquidation fund */
  liquidationFund: Scalars['BigDecimal'];
  /** the global liquidity */
  liquidity: Scalars['BigDecimal'];
  /** pointer to market */
  market: Market;
};

export type GlobalLiquidationFund_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<GlobalLiquidationFund_Filter>>>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  liquidationFund?: InputMaybe<Scalars['BigDecimal']>;
  liquidationFund_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidationFund_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidationFund_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidationFund_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidationFund_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidationFund_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidationFund_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<GlobalLiquidationFund_Filter>>>;
};

export enum GlobalLiquidationFund_OrderBy {
  Id = 'id',
  LiquidationFund = 'liquidationFund',
  Liquidity = 'liquidity',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD'
}

export type GlobalLiquidityPosition = {
  __typename?: 'GlobalLiquidityPosition';
  /** total liquidity fee */
  fee: Scalars['BigDecimal'];
  /** the accumulated trading fee growth per liquidity unit, as a Q192.64 */
  feeGrowthX64: Scalars['BigInt'];
  /** total funding fee */
  fundingFee: Scalars['BigDecimal'];
  /** the accumulated funding fee growth per liquidity unit, as a Q192.64 */
  fundingFeeGrowthX64: Scalars['BigInt'];
  /** market address */
  id: Scalars['Bytes'];
  /** the size of the net position held by all LPs in the liquidation buffer */
  liquidationBufferNetSize: Scalars['BigDecimal'];
  /** total liquidity held by all LPs */
  liquidity: Scalars['BigDecimal'];
  /** total margin held by all LPs */
  margin: Scalars['BigDecimal'];
  /** pointer to market */
  market: Market;
  /** the size of the net position held by all LPs */
  netSize: Scalars['BigDecimal'];
  /** the accumulated PnL growth per liquidity unit, as a Q192.64 */
  pnlGrowthX64: Scalars['BigInt'];
  /** the previous settlement point price */
  previousSPPrice: Scalars['BigDecimal'];
  /** the previous settlement point price, as a Q64.96 */
  previousSPPriceX96: Scalars['BigInt'];
  /** the side of the position, 1 for long and 2 for short */
  side: Scalars['Int'];
  /** the accumulated unrealized PnL growth per liquidity unit, as a Q192.64 */
  unrealizedPnLGrowthX64: Scalars['BigInt'];
};

export type GlobalLiquidityPosition_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<GlobalLiquidityPosition_Filter>>>;
  fee?: InputMaybe<Scalars['BigDecimal']>;
  feeGrowthX64?: InputMaybe<Scalars['BigInt']>;
  feeGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  feeGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  feeGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  feeGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  feeGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  feeGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  feeGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  fee_gt?: InputMaybe<Scalars['BigDecimal']>;
  fee_gte?: InputMaybe<Scalars['BigDecimal']>;
  fee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fee_lt?: InputMaybe<Scalars['BigDecimal']>;
  fee_lte?: InputMaybe<Scalars['BigDecimal']>;
  fee_not?: InputMaybe<Scalars['BigDecimal']>;
  fee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fundingFee?: InputMaybe<Scalars['BigDecimal']>;
  fundingFeeGrowthX64?: InputMaybe<Scalars['BigInt']>;
  fundingFeeGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  fundingFeeGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  fundingFeeGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  fundingFeeGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  fundingFeeGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  fundingFeeGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  fundingFeeGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  fundingFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fundingFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_not?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  liquidationBufferNetSize?: InputMaybe<Scalars['BigDecimal']>;
  liquidationBufferNetSize_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidationBufferNetSize_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidationBufferNetSize_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidationBufferNetSize_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidationBufferNetSize_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidationBufferNetSize_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidationBufferNetSize_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin?: InputMaybe<Scalars['BigDecimal']>;
  margin_gt?: InputMaybe<Scalars['BigDecimal']>;
  margin_gte?: InputMaybe<Scalars['BigDecimal']>;
  margin_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin_lt?: InputMaybe<Scalars['BigDecimal']>;
  margin_lte?: InputMaybe<Scalars['BigDecimal']>;
  margin_not?: InputMaybe<Scalars['BigDecimal']>;
  margin_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  netSize?: InputMaybe<Scalars['BigDecimal']>;
  netSize_gt?: InputMaybe<Scalars['BigDecimal']>;
  netSize_gte?: InputMaybe<Scalars['BigDecimal']>;
  netSize_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  netSize_lt?: InputMaybe<Scalars['BigDecimal']>;
  netSize_lte?: InputMaybe<Scalars['BigDecimal']>;
  netSize_not?: InputMaybe<Scalars['BigDecimal']>;
  netSize_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<GlobalLiquidityPosition_Filter>>>;
  pnlGrowthX64?: InputMaybe<Scalars['BigInt']>;
  pnlGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  pnlGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  pnlGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  pnlGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  pnlGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  pnlGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  pnlGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  previousSPPrice?: InputMaybe<Scalars['BigDecimal']>;
  previousSPPriceX96?: InputMaybe<Scalars['BigInt']>;
  previousSPPriceX96_gt?: InputMaybe<Scalars['BigInt']>;
  previousSPPriceX96_gte?: InputMaybe<Scalars['BigInt']>;
  previousSPPriceX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  previousSPPriceX96_lt?: InputMaybe<Scalars['BigInt']>;
  previousSPPriceX96_lte?: InputMaybe<Scalars['BigInt']>;
  previousSPPriceX96_not?: InputMaybe<Scalars['BigInt']>;
  previousSPPriceX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  previousSPPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  previousSPPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  previousSPPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  previousSPPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  previousSPPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  previousSPPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  previousSPPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  side?: InputMaybe<Scalars['Int']>;
  side_gt?: InputMaybe<Scalars['Int']>;
  side_gte?: InputMaybe<Scalars['Int']>;
  side_in?: InputMaybe<Array<Scalars['Int']>>;
  side_lt?: InputMaybe<Scalars['Int']>;
  side_lte?: InputMaybe<Scalars['Int']>;
  side_not?: InputMaybe<Scalars['Int']>;
  side_not_in?: InputMaybe<Array<Scalars['Int']>>;
  unrealizedPnLGrowthX64?: InputMaybe<Scalars['BigInt']>;
  unrealizedPnLGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  unrealizedPnLGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  unrealizedPnLGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  unrealizedPnLGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  unrealizedPnLGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  unrealizedPnLGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  unrealizedPnLGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum GlobalLiquidityPosition_OrderBy {
  Fee = 'fee',
  FeeGrowthX64 = 'feeGrowthX64',
  FundingFee = 'fundingFee',
  FundingFeeGrowthX64 = 'fundingFeeGrowthX64',
  Id = 'id',
  LiquidationBufferNetSize = 'liquidationBufferNetSize',
  Liquidity = 'liquidity',
  Margin = 'margin',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  NetSize = 'netSize',
  PnlGrowthX64 = 'pnlGrowthX64',
  PreviousSpPrice = 'previousSPPrice',
  PreviousSpPriceX96 = 'previousSPPriceX96',
  Side = 'side',
  UnrealizedPnLGrowthX64 = 'unrealizedPnLGrowthX64'
}

export type GlobalPosition = {
  __typename?: 'GlobalPosition';
  /**
   * the change in funding rate, a positive value means longs pay shorts,
   * when a negative value means shorts pay longs
   */
  fundingRate: Scalars['BigDecimal'];
  /**
   * the change in funding rate, a positive value means longs pay shorts,
   * when a negative value means shorts pay longs, as a Q160.96
   */
  fundingRateX96: Scalars['BigInt'];
  /** market address */
  id: Scalars['Bytes'];
  /** the timestamp of the last funding rate adjustment */
  lastAdjustFundingRateTime: Scalars['BigInt'];
  /** the sum of all position liquidity */
  liquidity: Scalars['BigDecimal'];
  /** the funding rate growth per unit of long position sizes, as a Q96.96 */
  longFundingRateGrowthX96: Scalars['BigInt'];
  /** the sum of long position size */
  longSize: Scalars['BigDecimal'];
  /** the sum of all position liquidity */
  margin: Scalars['BigDecimal'];
  /** pointer to market */
  market: Market;
  /** the maximum available size of all positions */
  maxSize: Scalars['BigDecimal'];
  /** the maximum available size of per position */
  maxSizePerPosition: Scalars['BigDecimal'];
  /** the previous funding rate growth per unit of long position sizes */
  previousLongFundingRateGrowthX96: Scalars['BigInt'];
  /** the previous funding rate growth per unit of short position sizes */
  previousShortFundingRateGrowthX96: Scalars['BigInt'];
  /** the funding rate growth per unit of short position sizes, as a Q96.96 */
  shortFundingRateGrowthX96: Scalars['BigInt'];
  /** the sum of short position size */
  shortSize: Scalars['BigDecimal'];
};

export type GlobalPosition_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<GlobalPosition_Filter>>>;
  fundingRate?: InputMaybe<Scalars['BigDecimal']>;
  fundingRateX96?: InputMaybe<Scalars['BigInt']>;
  fundingRateX96_gt?: InputMaybe<Scalars['BigInt']>;
  fundingRateX96_gte?: InputMaybe<Scalars['BigInt']>;
  fundingRateX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  fundingRateX96_lt?: InputMaybe<Scalars['BigInt']>;
  fundingRateX96_lte?: InputMaybe<Scalars['BigInt']>;
  fundingRateX96_not?: InputMaybe<Scalars['BigInt']>;
  fundingRateX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  fundingRate_gt?: InputMaybe<Scalars['BigDecimal']>;
  fundingRate_gte?: InputMaybe<Scalars['BigDecimal']>;
  fundingRate_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fundingRate_lt?: InputMaybe<Scalars['BigDecimal']>;
  fundingRate_lte?: InputMaybe<Scalars['BigDecimal']>;
  fundingRate_not?: InputMaybe<Scalars['BigDecimal']>;
  fundingRate_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  lastAdjustFundingRateTime?: InputMaybe<Scalars['BigInt']>;
  lastAdjustFundingRateTime_gt?: InputMaybe<Scalars['BigInt']>;
  lastAdjustFundingRateTime_gte?: InputMaybe<Scalars['BigInt']>;
  lastAdjustFundingRateTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  lastAdjustFundingRateTime_lt?: InputMaybe<Scalars['BigInt']>;
  lastAdjustFundingRateTime_lte?: InputMaybe<Scalars['BigInt']>;
  lastAdjustFundingRateTime_not?: InputMaybe<Scalars['BigInt']>;
  lastAdjustFundingRateTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  liquidity?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  longFundingRateGrowthX96?: InputMaybe<Scalars['BigInt']>;
  longFundingRateGrowthX96_gt?: InputMaybe<Scalars['BigInt']>;
  longFundingRateGrowthX96_gte?: InputMaybe<Scalars['BigInt']>;
  longFundingRateGrowthX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  longFundingRateGrowthX96_lt?: InputMaybe<Scalars['BigInt']>;
  longFundingRateGrowthX96_lte?: InputMaybe<Scalars['BigInt']>;
  longFundingRateGrowthX96_not?: InputMaybe<Scalars['BigInt']>;
  longFundingRateGrowthX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  longSize?: InputMaybe<Scalars['BigDecimal']>;
  longSize_gt?: InputMaybe<Scalars['BigDecimal']>;
  longSize_gte?: InputMaybe<Scalars['BigDecimal']>;
  longSize_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  longSize_lt?: InputMaybe<Scalars['BigDecimal']>;
  longSize_lte?: InputMaybe<Scalars['BigDecimal']>;
  longSize_not?: InputMaybe<Scalars['BigDecimal']>;
  longSize_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin?: InputMaybe<Scalars['BigDecimal']>;
  margin_gt?: InputMaybe<Scalars['BigDecimal']>;
  margin_gte?: InputMaybe<Scalars['BigDecimal']>;
  margin_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin_lt?: InputMaybe<Scalars['BigDecimal']>;
  margin_lte?: InputMaybe<Scalars['BigDecimal']>;
  margin_not?: InputMaybe<Scalars['BigDecimal']>;
  margin_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  maxSize?: InputMaybe<Scalars['BigDecimal']>;
  maxSizePerPosition?: InputMaybe<Scalars['BigDecimal']>;
  maxSizePerPosition_gt?: InputMaybe<Scalars['BigDecimal']>;
  maxSizePerPosition_gte?: InputMaybe<Scalars['BigDecimal']>;
  maxSizePerPosition_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  maxSizePerPosition_lt?: InputMaybe<Scalars['BigDecimal']>;
  maxSizePerPosition_lte?: InputMaybe<Scalars['BigDecimal']>;
  maxSizePerPosition_not?: InputMaybe<Scalars['BigDecimal']>;
  maxSizePerPosition_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  maxSize_gt?: InputMaybe<Scalars['BigDecimal']>;
  maxSize_gte?: InputMaybe<Scalars['BigDecimal']>;
  maxSize_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  maxSize_lt?: InputMaybe<Scalars['BigDecimal']>;
  maxSize_lte?: InputMaybe<Scalars['BigDecimal']>;
  maxSize_not?: InputMaybe<Scalars['BigDecimal']>;
  maxSize_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<GlobalPosition_Filter>>>;
  previousLongFundingRateGrowthX96?: InputMaybe<Scalars['BigInt']>;
  previousLongFundingRateGrowthX96_gt?: InputMaybe<Scalars['BigInt']>;
  previousLongFundingRateGrowthX96_gte?: InputMaybe<Scalars['BigInt']>;
  previousLongFundingRateGrowthX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  previousLongFundingRateGrowthX96_lt?: InputMaybe<Scalars['BigInt']>;
  previousLongFundingRateGrowthX96_lte?: InputMaybe<Scalars['BigInt']>;
  previousLongFundingRateGrowthX96_not?: InputMaybe<Scalars['BigInt']>;
  previousLongFundingRateGrowthX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  previousShortFundingRateGrowthX96?: InputMaybe<Scalars['BigInt']>;
  previousShortFundingRateGrowthX96_gt?: InputMaybe<Scalars['BigInt']>;
  previousShortFundingRateGrowthX96_gte?: InputMaybe<Scalars['BigInt']>;
  previousShortFundingRateGrowthX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  previousShortFundingRateGrowthX96_lt?: InputMaybe<Scalars['BigInt']>;
  previousShortFundingRateGrowthX96_lte?: InputMaybe<Scalars['BigInt']>;
  previousShortFundingRateGrowthX96_not?: InputMaybe<Scalars['BigInt']>;
  previousShortFundingRateGrowthX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  shortFundingRateGrowthX96?: InputMaybe<Scalars['BigInt']>;
  shortFundingRateGrowthX96_gt?: InputMaybe<Scalars['BigInt']>;
  shortFundingRateGrowthX96_gte?: InputMaybe<Scalars['BigInt']>;
  shortFundingRateGrowthX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  shortFundingRateGrowthX96_lt?: InputMaybe<Scalars['BigInt']>;
  shortFundingRateGrowthX96_lte?: InputMaybe<Scalars['BigInt']>;
  shortFundingRateGrowthX96_not?: InputMaybe<Scalars['BigInt']>;
  shortFundingRateGrowthX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  shortSize?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_gt?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_gte?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  shortSize_lt?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_lte?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_not?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum GlobalPosition_OrderBy {
  FundingRate = 'fundingRate',
  FundingRateX96 = 'fundingRateX96',
  Id = 'id',
  LastAdjustFundingRateTime = 'lastAdjustFundingRateTime',
  Liquidity = 'liquidity',
  LongFundingRateGrowthX96 = 'longFundingRateGrowthX96',
  LongSize = 'longSize',
  Margin = 'margin',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  MaxSize = 'maxSize',
  MaxSizePerPosition = 'maxSizePerPosition',
  PreviousLongFundingRateGrowthX96 = 'previousLongFundingRateGrowthX96',
  PreviousShortFundingRateGrowthX96 = 'previousShortFundingRateGrowthX96',
  ShortFundingRateGrowthX96 = 'shortFundingRateGrowthX96',
  ShortSize = 'shortSize'
}

/** entity that represents LPs' request to increase liquidity position */
export type IncreaseLiquidityPositionRequest = {
  __typename?: 'IncreaseLiquidityPositionRequest';
  /** the min margin after to accept */
  acceptableMinMargin: Scalars['BigDecimal'];
  /** account that create the request */
  account: Scalars['Bytes'];
  /** valid if the request cancelled, hash of the cancellation tx */
  cancelledHash: Scalars['Bytes'];
  /** tx hash of the request creation */
  createdHash: Scalars['Bytes'];
  /** valid if the request executed, hash of the execution tx */
  executedHash: Scalars['Bytes'];
  /** execution fee paid to executor to carry out the request */
  executionFee: Scalars['BigInt'];
  id: Scalars['ID'];
  /** the request index */
  index: Scalars['BigInt'];
  /** liquidity to increase */
  liquidityDelta: Scalars['BigDecimal'];
  /** margin used to open the position */
  marginDelta: Scalars['BigDecimal'];
  /** in which market to increase liquidity position */
  market: Market;
  /** status of the request, enum{Created, Cancelled, Executed} */
  status: Status;
};

export type IncreaseLiquidityPositionRequest_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  acceptableMinMargin?: InputMaybe<Scalars['BigDecimal']>;
  acceptableMinMargin_gt?: InputMaybe<Scalars['BigDecimal']>;
  acceptableMinMargin_gte?: InputMaybe<Scalars['BigDecimal']>;
  acceptableMinMargin_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  acceptableMinMargin_lt?: InputMaybe<Scalars['BigDecimal']>;
  acceptableMinMargin_lte?: InputMaybe<Scalars['BigDecimal']>;
  acceptableMinMargin_not?: InputMaybe<Scalars['BigDecimal']>;
  acceptableMinMargin_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  account?: InputMaybe<Scalars['Bytes']>;
  account_contains?: InputMaybe<Scalars['Bytes']>;
  account_gt?: InputMaybe<Scalars['Bytes']>;
  account_gte?: InputMaybe<Scalars['Bytes']>;
  account_in?: InputMaybe<Array<Scalars['Bytes']>>;
  account_lt?: InputMaybe<Scalars['Bytes']>;
  account_lte?: InputMaybe<Scalars['Bytes']>;
  account_not?: InputMaybe<Scalars['Bytes']>;
  account_not_contains?: InputMaybe<Scalars['Bytes']>;
  account_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  and?: InputMaybe<Array<InputMaybe<IncreaseLiquidityPositionRequest_Filter>>>;
  cancelledHash?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_contains?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_gt?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_gte?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  cancelledHash_lt?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_lte?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_not?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  createdHash?: InputMaybe<Scalars['Bytes']>;
  createdHash_contains?: InputMaybe<Scalars['Bytes']>;
  createdHash_gt?: InputMaybe<Scalars['Bytes']>;
  createdHash_gte?: InputMaybe<Scalars['Bytes']>;
  createdHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  createdHash_lt?: InputMaybe<Scalars['Bytes']>;
  createdHash_lte?: InputMaybe<Scalars['Bytes']>;
  createdHash_not?: InputMaybe<Scalars['Bytes']>;
  createdHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  createdHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  executedHash?: InputMaybe<Scalars['Bytes']>;
  executedHash_contains?: InputMaybe<Scalars['Bytes']>;
  executedHash_gt?: InputMaybe<Scalars['Bytes']>;
  executedHash_gte?: InputMaybe<Scalars['Bytes']>;
  executedHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  executedHash_lt?: InputMaybe<Scalars['Bytes']>;
  executedHash_lte?: InputMaybe<Scalars['Bytes']>;
  executedHash_not?: InputMaybe<Scalars['Bytes']>;
  executedHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  executedHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  executionFee?: InputMaybe<Scalars['BigInt']>;
  executionFee_gt?: InputMaybe<Scalars['BigInt']>;
  executionFee_gte?: InputMaybe<Scalars['BigInt']>;
  executionFee_in?: InputMaybe<Array<Scalars['BigInt']>>;
  executionFee_lt?: InputMaybe<Scalars['BigInt']>;
  executionFee_lte?: InputMaybe<Scalars['BigInt']>;
  executionFee_not?: InputMaybe<Scalars['BigInt']>;
  executionFee_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  index?: InputMaybe<Scalars['BigInt']>;
  index_gt?: InputMaybe<Scalars['BigInt']>;
  index_gte?: InputMaybe<Scalars['BigInt']>;
  index_in?: InputMaybe<Array<Scalars['BigInt']>>;
  index_lt?: InputMaybe<Scalars['BigInt']>;
  index_lte?: InputMaybe<Scalars['BigInt']>;
  index_not?: InputMaybe<Scalars['BigInt']>;
  index_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  liquidityDelta?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDelta_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDelta_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDelta_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidityDelta_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDelta_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDelta_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDelta_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  marginDelta?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_gt?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_gte?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  marginDelta_lt?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_lte?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_not?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<IncreaseLiquidityPositionRequest_Filter>>>;
  status?: InputMaybe<Status>;
  status_in?: InputMaybe<Array<Status>>;
  status_not?: InputMaybe<Status>;
  status_not_in?: InputMaybe<Array<Status>>;
};

export enum IncreaseLiquidityPositionRequest_OrderBy {
  AcceptableMinMargin = 'acceptableMinMargin',
  Account = 'account',
  CancelledHash = 'cancelledHash',
  CreatedHash = 'createdHash',
  ExecutedHash = 'executedHash',
  ExecutionFee = 'executionFee',
  Id = 'id',
  Index = 'index',
  LiquidityDelta = 'liquidityDelta',
  MarginDelta = 'marginDelta',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  Status = 'status'
}

/** entity that represents Traders' request to open/increase a position */
export type IncreasePositionRequest = {
  __typename?: 'IncreasePositionRequest';
  /** worst trade price trader can accept */
  acceptableTradePrice: Scalars['BigDecimal'];
  /** worst trade price trader can accept, as a Q64.96 */
  acceptableTradePriceX96: Scalars['BigInt'];
  /** account that create the request */
  account: Scalars['Bytes'];
  /** valid if the request cancelled, hash of the cancellation tx */
  cancelledHash: Scalars['Bytes'];
  /** tx hash of the request creation */
  createdHash: Scalars['Bytes'];
  /** valid if the request executed, hash of the execution tx */
  executedHash: Scalars['Bytes'];
  /** execution fee paid to executor to carry out the request */
  executionFee: Scalars['BigInt'];
  id: Scalars['ID'];
  /** the request index */
  index: Scalars['BigInt'];
  /** must be non-zero for opening position */
  marginDelta: Scalars['BigDecimal'];
  /** in which market to increase position */
  market: Market;
  /** side of the request, 1 for long and 2 for short */
  side: Scalars['Int'];
  /** size of the base token to increase */
  sizeDelta: Scalars['BigDecimal'];
  /** status of the request, enum{Created, Cancelled, Executed} */
  status: Status;
};

export type IncreasePositionRequest_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  acceptableTradePrice?: InputMaybe<Scalars['BigDecimal']>;
  acceptableTradePriceX96?: InputMaybe<Scalars['BigInt']>;
  acceptableTradePriceX96_gt?: InputMaybe<Scalars['BigInt']>;
  acceptableTradePriceX96_gte?: InputMaybe<Scalars['BigInt']>;
  acceptableTradePriceX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  acceptableTradePriceX96_lt?: InputMaybe<Scalars['BigInt']>;
  acceptableTradePriceX96_lte?: InputMaybe<Scalars['BigInt']>;
  acceptableTradePriceX96_not?: InputMaybe<Scalars['BigInt']>;
  acceptableTradePriceX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  acceptableTradePrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  acceptableTradePrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  acceptableTradePrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  acceptableTradePrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  acceptableTradePrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  acceptableTradePrice_not?: InputMaybe<Scalars['BigDecimal']>;
  acceptableTradePrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  account?: InputMaybe<Scalars['Bytes']>;
  account_contains?: InputMaybe<Scalars['Bytes']>;
  account_gt?: InputMaybe<Scalars['Bytes']>;
  account_gte?: InputMaybe<Scalars['Bytes']>;
  account_in?: InputMaybe<Array<Scalars['Bytes']>>;
  account_lt?: InputMaybe<Scalars['Bytes']>;
  account_lte?: InputMaybe<Scalars['Bytes']>;
  account_not?: InputMaybe<Scalars['Bytes']>;
  account_not_contains?: InputMaybe<Scalars['Bytes']>;
  account_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  and?: InputMaybe<Array<InputMaybe<IncreasePositionRequest_Filter>>>;
  cancelledHash?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_contains?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_gt?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_gte?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  cancelledHash_lt?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_lte?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_not?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  createdHash?: InputMaybe<Scalars['Bytes']>;
  createdHash_contains?: InputMaybe<Scalars['Bytes']>;
  createdHash_gt?: InputMaybe<Scalars['Bytes']>;
  createdHash_gte?: InputMaybe<Scalars['Bytes']>;
  createdHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  createdHash_lt?: InputMaybe<Scalars['Bytes']>;
  createdHash_lte?: InputMaybe<Scalars['Bytes']>;
  createdHash_not?: InputMaybe<Scalars['Bytes']>;
  createdHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  createdHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  executedHash?: InputMaybe<Scalars['Bytes']>;
  executedHash_contains?: InputMaybe<Scalars['Bytes']>;
  executedHash_gt?: InputMaybe<Scalars['Bytes']>;
  executedHash_gte?: InputMaybe<Scalars['Bytes']>;
  executedHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  executedHash_lt?: InputMaybe<Scalars['Bytes']>;
  executedHash_lte?: InputMaybe<Scalars['Bytes']>;
  executedHash_not?: InputMaybe<Scalars['Bytes']>;
  executedHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  executedHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  executionFee?: InputMaybe<Scalars['BigInt']>;
  executionFee_gt?: InputMaybe<Scalars['BigInt']>;
  executionFee_gte?: InputMaybe<Scalars['BigInt']>;
  executionFee_in?: InputMaybe<Array<Scalars['BigInt']>>;
  executionFee_lt?: InputMaybe<Scalars['BigInt']>;
  executionFee_lte?: InputMaybe<Scalars['BigInt']>;
  executionFee_not?: InputMaybe<Scalars['BigInt']>;
  executionFee_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  index?: InputMaybe<Scalars['BigInt']>;
  index_gt?: InputMaybe<Scalars['BigInt']>;
  index_gte?: InputMaybe<Scalars['BigInt']>;
  index_in?: InputMaybe<Array<Scalars['BigInt']>>;
  index_lt?: InputMaybe<Scalars['BigInt']>;
  index_lte?: InputMaybe<Scalars['BigInt']>;
  index_not?: InputMaybe<Scalars['BigInt']>;
  index_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  marginDelta?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_gt?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_gte?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  marginDelta_lt?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_lte?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_not?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<IncreasePositionRequest_Filter>>>;
  side?: InputMaybe<Scalars['Int']>;
  side_gt?: InputMaybe<Scalars['Int']>;
  side_gte?: InputMaybe<Scalars['Int']>;
  side_in?: InputMaybe<Array<Scalars['Int']>>;
  side_lt?: InputMaybe<Scalars['Int']>;
  side_lte?: InputMaybe<Scalars['Int']>;
  side_not?: InputMaybe<Scalars['Int']>;
  side_not_in?: InputMaybe<Array<Scalars['Int']>>;
  sizeDelta?: InputMaybe<Scalars['BigDecimal']>;
  sizeDelta_gt?: InputMaybe<Scalars['BigDecimal']>;
  sizeDelta_gte?: InputMaybe<Scalars['BigDecimal']>;
  sizeDelta_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  sizeDelta_lt?: InputMaybe<Scalars['BigDecimal']>;
  sizeDelta_lte?: InputMaybe<Scalars['BigDecimal']>;
  sizeDelta_not?: InputMaybe<Scalars['BigDecimal']>;
  sizeDelta_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  status?: InputMaybe<Status>;
  status_in?: InputMaybe<Array<Status>>;
  status_not?: InputMaybe<Status>;
  status_not_in?: InputMaybe<Array<Status>>;
};

export enum IncreasePositionRequest_OrderBy {
  AcceptableTradePrice = 'acceptableTradePrice',
  AcceptableTradePriceX96 = 'acceptableTradePriceX96',
  Account = 'account',
  CancelledHash = 'cancelledHash',
  CreatedHash = 'createdHash',
  ExecutedHash = 'executedHash',
  ExecutionFee = 'executionFee',
  Id = 'id',
  Index = 'index',
  MarginDelta = 'marginDelta',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  Side = 'side',
  SizeDelta = 'sizeDelta',
  Status = 'status'
}

export type LiquidationFundPosition = {
  __typename?: 'LiquidationFundPosition';
  /** the account of the position */
  account: Scalars['Bytes'];
  id: Scalars['ID'];
  /** the liquidity of the position */
  liquidity: Scalars['BigDecimal'];
  /** the market of the position */
  market: Market;
};

export type LiquidationFundPosition_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['Bytes']>;
  account_contains?: InputMaybe<Scalars['Bytes']>;
  account_gt?: InputMaybe<Scalars['Bytes']>;
  account_gte?: InputMaybe<Scalars['Bytes']>;
  account_in?: InputMaybe<Array<Scalars['Bytes']>>;
  account_lt?: InputMaybe<Scalars['Bytes']>;
  account_lte?: InputMaybe<Scalars['Bytes']>;
  account_not?: InputMaybe<Scalars['Bytes']>;
  account_not_contains?: InputMaybe<Scalars['Bytes']>;
  account_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  and?: InputMaybe<Array<InputMaybe<LiquidationFundPosition_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  liquidity?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<LiquidationFundPosition_Filter>>>;
};

export enum LiquidationFundPosition_OrderBy {
  Account = 'account',
  Id = 'id',
  Liquidity = 'liquidity',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD'
}

export type LiquidityPosition = {
  __typename?: 'LiquidityPosition';
  /** the owner of the liquidity position */
  account: Scalars['Bytes'];
  /** the close block of the liquidity position */
  closeBlockNumber: Scalars['BigInt'];
  /** the close time of the liquidity position */
  closeTime: Scalars['BigInt'];
  /** the close tx hash of the liquidity position */
  closeTxHash: Scalars['Bytes'];
  /** the entry block of the liquidity position */
  entryBlockNumber: Scalars['BigInt'];
  /** the entry time of the liquidity position */
  entryTime: Scalars['BigInt'];
  /** the entry tx hash of the liquidity position */
  entryTxHash: Scalars['Bytes'];
  /** accumulated trading fee of the liquidity position */
  fee: Scalars['BigDecimal'];
  /** the accumulated trading fee growth per liquidity unit, as a Q192.64 */
  feeGrowthX64: Scalars['BigInt'];
  /** the address that receives the liquidation execution fee */
  feeReceiver: Scalars['Bytes'];
  /** accumulated funding fee of the liquidity position */
  fundingFee: Scalars['BigDecimal'];
  /** the accumulated funding fee growth per liquidity unit, as a Q192.64 */
  fundingFeeGrowthX64: Scalars['BigInt'];
  /** format: <market address>:<account> */
  id: Scalars['ID'];
  /** the liquidation execution fee for LP */
  liquidationExecutionFee: Scalars['BigDecimal'];
  /** the liquidity of the liquidity position */
  liquidity: Scalars['BigDecimal'];
  /** total decrease liquidity */
  liquidityDecreased: Scalars['BigDecimal'];
  /** total increase liquidity */
  liquidityIncreased: Scalars['BigDecimal'];
  /** liquidity position history ID */
  liquidityPositionHistoryID: Scalars['String'];
  /** the margin of the liquidity position */
  margin: Scalars['BigDecimal'];
  /** total decrease margin */
  marginDecreased: Scalars['BigDecimal'];
  /** total increase margin */
  marginIncreased: Scalars['BigDecimal'];
  /** pointer to market */
  market: Market;
  /** accumulated PnL of the liquidity position */
  pnl: Scalars['BigDecimal'];
  /** the accumulated PnL growth per liquidity unit, as a Q192.64 */
  pnlGrowthX64: Scalars['BigInt'];
  /** the realized PnL of the liquidity position */
  realizedPnL: Scalars['BigDecimal'];
  /** status of the liquidity position, enum{Opened, Closed, Liquidated} */
  status: LiquidityPositionStatus;
  /** the accumulated unrealized PnL growth per liquidity unit, as a Q192.64 */
  unrealizedPnLGrowthX64: Scalars['BigInt'];
};

export type LiquidityPositionHistory = {
  __typename?: 'LiquidityPositionHistory';
  /** the owner of the liquidity position */
  account: Scalars['Bytes'];
  /** the timestamp of the last closing of the liquidity position */
  closedTime: Scalars['BigInt'];
  /** the entry block of the liquidity position */
  entryBlockNumber: Scalars['BigInt'];
  /** the entry time of the liquidity position */
  entryTime: Scalars['BigInt'];
  /** the entry tx hash of the liquidity position */
  entryTxHash: Scalars['Bytes'];
  /** accumulated trading fee of the liquidity position */
  fee: Scalars['BigDecimal'];
  /** accumulated funding fee of the liquidity position */
  fundingFee: Scalars['BigDecimal'];
  /** format: <transaction hash>:<log index> */
  id: Scalars['ID'];
  /** the liquidation liquidity of the liquidity position */
  liquidationLiquidity: Scalars['BigDecimal'];
  /** the liquidation price of the liquidity position */
  liquidationPrice: Scalars['BigDecimal'];
  /** the liquidity of the liquidity position */
  liquidity: Scalars['BigDecimal'];
  /** total decrease liquidity */
  liquidityDecreased: Scalars['BigDecimal'];
  /** total increase liquidity */
  liquidityIncreased: Scalars['BigDecimal'];
  /** the margin of the liquidity position */
  margin: Scalars['BigDecimal'];
  /** total decrease margin */
  marginDecreased: Scalars['BigDecimal'];
  /** total increase margin */
  marginIncreased: Scalars['BigDecimal'];
  /** pointer to market */
  market: Market;
  /** accumulated PnL of the liquidity position */
  pnl: Scalars['BigDecimal'];
  /** accumulated realized PnL of the liquidity position */
  realizedPnL: Scalars['BigDecimal'];
  /**
   * status of the liquidity position history.
   * enum{PartialClosed,Closed,Liquidated,Opened,IncreaseMargin,
   * DecreaseMargin,IncreasePosition,DecreasePosition}
   */
  status: LiquidityPositionHistoryStatus;
};

export enum LiquidityPositionHistoryStatus {
  Closed = 'Closed',
  DecreaseMargin = 'DecreaseMargin',
  DecreasePosition = 'DecreasePosition',
  IncreaseMargin = 'IncreaseMargin',
  IncreasePosition = 'IncreasePosition',
  Liquidated = 'Liquidated',
  Opened = 'Opened',
  PartialClosed = 'PartialClosed'
}

export type LiquidityPositionHistory_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['Bytes']>;
  account_contains?: InputMaybe<Scalars['Bytes']>;
  account_gt?: InputMaybe<Scalars['Bytes']>;
  account_gte?: InputMaybe<Scalars['Bytes']>;
  account_in?: InputMaybe<Array<Scalars['Bytes']>>;
  account_lt?: InputMaybe<Scalars['Bytes']>;
  account_lte?: InputMaybe<Scalars['Bytes']>;
  account_not?: InputMaybe<Scalars['Bytes']>;
  account_not_contains?: InputMaybe<Scalars['Bytes']>;
  account_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  and?: InputMaybe<Array<InputMaybe<LiquidityPositionHistory_Filter>>>;
  closedTime?: InputMaybe<Scalars['BigInt']>;
  closedTime_gt?: InputMaybe<Scalars['BigInt']>;
  closedTime_gte?: InputMaybe<Scalars['BigInt']>;
  closedTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  closedTime_lt?: InputMaybe<Scalars['BigInt']>;
  closedTime_lte?: InputMaybe<Scalars['BigInt']>;
  closedTime_not?: InputMaybe<Scalars['BigInt']>;
  closedTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryBlockNumber?: InputMaybe<Scalars['BigInt']>;
  entryBlockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  entryBlockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  entryBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryBlockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  entryBlockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  entryBlockNumber_not?: InputMaybe<Scalars['BigInt']>;
  entryBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryTime?: InputMaybe<Scalars['BigInt']>;
  entryTime_gt?: InputMaybe<Scalars['BigInt']>;
  entryTime_gte?: InputMaybe<Scalars['BigInt']>;
  entryTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryTime_lt?: InputMaybe<Scalars['BigInt']>;
  entryTime_lte?: InputMaybe<Scalars['BigInt']>;
  entryTime_not?: InputMaybe<Scalars['BigInt']>;
  entryTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryTxHash?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_contains?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_gt?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_gte?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  entryTxHash_lt?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_lte?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_not?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  fee?: InputMaybe<Scalars['BigDecimal']>;
  fee_gt?: InputMaybe<Scalars['BigDecimal']>;
  fee_gte?: InputMaybe<Scalars['BigDecimal']>;
  fee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fee_lt?: InputMaybe<Scalars['BigDecimal']>;
  fee_lte?: InputMaybe<Scalars['BigDecimal']>;
  fee_not?: InputMaybe<Scalars['BigDecimal']>;
  fee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fundingFee?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fundingFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_not?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  liquidationLiquidity?: InputMaybe<Scalars['BigDecimal']>;
  liquidationLiquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidationLiquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidationLiquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidationLiquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidationLiquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidationLiquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidationLiquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidationPrice?: InputMaybe<Scalars['BigDecimal']>;
  liquidationPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidationPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidationPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidationPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidationPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidationPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidationPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDecreased?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDecreased_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDecreased_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDecreased_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidityDecreased_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDecreased_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDecreased_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDecreased_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidityIncreased?: InputMaybe<Scalars['BigDecimal']>;
  liquidityIncreased_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityIncreased_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityIncreased_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidityIncreased_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityIncreased_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityIncreased_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidityIncreased_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin?: InputMaybe<Scalars['BigDecimal']>;
  marginDecreased?: InputMaybe<Scalars['BigDecimal']>;
  marginDecreased_gt?: InputMaybe<Scalars['BigDecimal']>;
  marginDecreased_gte?: InputMaybe<Scalars['BigDecimal']>;
  marginDecreased_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  marginDecreased_lt?: InputMaybe<Scalars['BigDecimal']>;
  marginDecreased_lte?: InputMaybe<Scalars['BigDecimal']>;
  marginDecreased_not?: InputMaybe<Scalars['BigDecimal']>;
  marginDecreased_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  marginIncreased?: InputMaybe<Scalars['BigDecimal']>;
  marginIncreased_gt?: InputMaybe<Scalars['BigDecimal']>;
  marginIncreased_gte?: InputMaybe<Scalars['BigDecimal']>;
  marginIncreased_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  marginIncreased_lt?: InputMaybe<Scalars['BigDecimal']>;
  marginIncreased_lte?: InputMaybe<Scalars['BigDecimal']>;
  marginIncreased_not?: InputMaybe<Scalars['BigDecimal']>;
  marginIncreased_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin_gt?: InputMaybe<Scalars['BigDecimal']>;
  margin_gte?: InputMaybe<Scalars['BigDecimal']>;
  margin_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin_lt?: InputMaybe<Scalars['BigDecimal']>;
  margin_lte?: InputMaybe<Scalars['BigDecimal']>;
  margin_not?: InputMaybe<Scalars['BigDecimal']>;
  margin_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<LiquidityPositionHistory_Filter>>>;
  pnl?: InputMaybe<Scalars['BigDecimal']>;
  pnl_gt?: InputMaybe<Scalars['BigDecimal']>;
  pnl_gte?: InputMaybe<Scalars['BigDecimal']>;
  pnl_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  pnl_lt?: InputMaybe<Scalars['BigDecimal']>;
  pnl_lte?: InputMaybe<Scalars['BigDecimal']>;
  pnl_not?: InputMaybe<Scalars['BigDecimal']>;
  pnl_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  realizedPnL?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_gt?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_gte?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  realizedPnL_lt?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_lte?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_not?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  status?: InputMaybe<LiquidityPositionHistoryStatus>;
  status_in?: InputMaybe<Array<LiquidityPositionHistoryStatus>>;
  status_not?: InputMaybe<LiquidityPositionHistoryStatus>;
  status_not_in?: InputMaybe<Array<LiquidityPositionHistoryStatus>>;
};

export enum LiquidityPositionHistory_OrderBy {
  Account = 'account',
  ClosedTime = 'closedTime',
  EntryBlockNumber = 'entryBlockNumber',
  EntryTime = 'entryTime',
  EntryTxHash = 'entryTxHash',
  Fee = 'fee',
  FundingFee = 'fundingFee',
  Id = 'id',
  LiquidationLiquidity = 'liquidationLiquidity',
  LiquidationPrice = 'liquidationPrice',
  Liquidity = 'liquidity',
  LiquidityDecreased = 'liquidityDecreased',
  LiquidityIncreased = 'liquidityIncreased',
  Margin = 'margin',
  MarginDecreased = 'marginDecreased',
  MarginIncreased = 'marginIncreased',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  Pnl = 'pnl',
  RealizedPnL = 'realizedPnL',
  Status = 'status'
}

export enum LiquidityPositionStatus {
  Closed = 'Closed',
  Liquidated = 'Liquidated',
  Opened = 'Opened'
}

export type LiquidityPosition_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['Bytes']>;
  account_contains?: InputMaybe<Scalars['Bytes']>;
  account_gt?: InputMaybe<Scalars['Bytes']>;
  account_gte?: InputMaybe<Scalars['Bytes']>;
  account_in?: InputMaybe<Array<Scalars['Bytes']>>;
  account_lt?: InputMaybe<Scalars['Bytes']>;
  account_lte?: InputMaybe<Scalars['Bytes']>;
  account_not?: InputMaybe<Scalars['Bytes']>;
  account_not_contains?: InputMaybe<Scalars['Bytes']>;
  account_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  and?: InputMaybe<Array<InputMaybe<LiquidityPosition_Filter>>>;
  closeBlockNumber?: InputMaybe<Scalars['BigInt']>;
  closeBlockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  closeBlockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  closeBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  closeBlockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  closeBlockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  closeBlockNumber_not?: InputMaybe<Scalars['BigInt']>;
  closeBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  closeTime?: InputMaybe<Scalars['BigInt']>;
  closeTime_gt?: InputMaybe<Scalars['BigInt']>;
  closeTime_gte?: InputMaybe<Scalars['BigInt']>;
  closeTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  closeTime_lt?: InputMaybe<Scalars['BigInt']>;
  closeTime_lte?: InputMaybe<Scalars['BigInt']>;
  closeTime_not?: InputMaybe<Scalars['BigInt']>;
  closeTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  closeTxHash?: InputMaybe<Scalars['Bytes']>;
  closeTxHash_contains?: InputMaybe<Scalars['Bytes']>;
  closeTxHash_gt?: InputMaybe<Scalars['Bytes']>;
  closeTxHash_gte?: InputMaybe<Scalars['Bytes']>;
  closeTxHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  closeTxHash_lt?: InputMaybe<Scalars['Bytes']>;
  closeTxHash_lte?: InputMaybe<Scalars['Bytes']>;
  closeTxHash_not?: InputMaybe<Scalars['Bytes']>;
  closeTxHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  closeTxHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  entryBlockNumber?: InputMaybe<Scalars['BigInt']>;
  entryBlockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  entryBlockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  entryBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryBlockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  entryBlockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  entryBlockNumber_not?: InputMaybe<Scalars['BigInt']>;
  entryBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryTime?: InputMaybe<Scalars['BigInt']>;
  entryTime_gt?: InputMaybe<Scalars['BigInt']>;
  entryTime_gte?: InputMaybe<Scalars['BigInt']>;
  entryTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryTime_lt?: InputMaybe<Scalars['BigInt']>;
  entryTime_lte?: InputMaybe<Scalars['BigInt']>;
  entryTime_not?: InputMaybe<Scalars['BigInt']>;
  entryTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryTxHash?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_contains?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_gt?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_gte?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  entryTxHash_lt?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_lte?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_not?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  fee?: InputMaybe<Scalars['BigDecimal']>;
  feeGrowthX64?: InputMaybe<Scalars['BigInt']>;
  feeGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  feeGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  feeGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  feeGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  feeGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  feeGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  feeGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  feeReceiver?: InputMaybe<Scalars['Bytes']>;
  feeReceiver_contains?: InputMaybe<Scalars['Bytes']>;
  feeReceiver_gt?: InputMaybe<Scalars['Bytes']>;
  feeReceiver_gte?: InputMaybe<Scalars['Bytes']>;
  feeReceiver_in?: InputMaybe<Array<Scalars['Bytes']>>;
  feeReceiver_lt?: InputMaybe<Scalars['Bytes']>;
  feeReceiver_lte?: InputMaybe<Scalars['Bytes']>;
  feeReceiver_not?: InputMaybe<Scalars['Bytes']>;
  feeReceiver_not_contains?: InputMaybe<Scalars['Bytes']>;
  feeReceiver_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  fee_gt?: InputMaybe<Scalars['BigDecimal']>;
  fee_gte?: InputMaybe<Scalars['BigDecimal']>;
  fee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fee_lt?: InputMaybe<Scalars['BigDecimal']>;
  fee_lte?: InputMaybe<Scalars['BigDecimal']>;
  fee_not?: InputMaybe<Scalars['BigDecimal']>;
  fee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fundingFee?: InputMaybe<Scalars['BigDecimal']>;
  fundingFeeGrowthX64?: InputMaybe<Scalars['BigInt']>;
  fundingFeeGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  fundingFeeGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  fundingFeeGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  fundingFeeGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  fundingFeeGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  fundingFeeGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  fundingFeeGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  fundingFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fundingFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_not?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  liquidationExecutionFee?: InputMaybe<Scalars['BigDecimal']>;
  liquidationExecutionFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidationExecutionFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidationExecutionFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidationExecutionFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidationExecutionFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidationExecutionFee_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidationExecutionFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDecreased?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDecreased_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDecreased_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDecreased_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidityDecreased_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDecreased_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDecreased_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidityDecreased_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidityIncreased?: InputMaybe<Scalars['BigDecimal']>;
  liquidityIncreased_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityIncreased_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityIncreased_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidityIncreased_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityIncreased_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityIncreased_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidityIncreased_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidityPositionHistoryID?: InputMaybe<Scalars['String']>;
  liquidityPositionHistoryID_contains?: InputMaybe<Scalars['String']>;
  liquidityPositionHistoryID_contains_nocase?: InputMaybe<Scalars['String']>;
  liquidityPositionHistoryID_ends_with?: InputMaybe<Scalars['String']>;
  liquidityPositionHistoryID_ends_with_nocase?: InputMaybe<Scalars['String']>;
  liquidityPositionHistoryID_gt?: InputMaybe<Scalars['String']>;
  liquidityPositionHistoryID_gte?: InputMaybe<Scalars['String']>;
  liquidityPositionHistoryID_in?: InputMaybe<Array<Scalars['String']>>;
  liquidityPositionHistoryID_lt?: InputMaybe<Scalars['String']>;
  liquidityPositionHistoryID_lte?: InputMaybe<Scalars['String']>;
  liquidityPositionHistoryID_not?: InputMaybe<Scalars['String']>;
  liquidityPositionHistoryID_not_contains?: InputMaybe<Scalars['String']>;
  liquidityPositionHistoryID_not_contains_nocase?: InputMaybe<Scalars['String']>;
  liquidityPositionHistoryID_not_ends_with?: InputMaybe<Scalars['String']>;
  liquidityPositionHistoryID_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  liquidityPositionHistoryID_not_in?: InputMaybe<Array<Scalars['String']>>;
  liquidityPositionHistoryID_not_starts_with?: InputMaybe<Scalars['String']>;
  liquidityPositionHistoryID_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  liquidityPositionHistoryID_starts_with?: InputMaybe<Scalars['String']>;
  liquidityPositionHistoryID_starts_with_nocase?: InputMaybe<Scalars['String']>;
  liquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin?: InputMaybe<Scalars['BigDecimal']>;
  marginDecreased?: InputMaybe<Scalars['BigDecimal']>;
  marginDecreased_gt?: InputMaybe<Scalars['BigDecimal']>;
  marginDecreased_gte?: InputMaybe<Scalars['BigDecimal']>;
  marginDecreased_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  marginDecreased_lt?: InputMaybe<Scalars['BigDecimal']>;
  marginDecreased_lte?: InputMaybe<Scalars['BigDecimal']>;
  marginDecreased_not?: InputMaybe<Scalars['BigDecimal']>;
  marginDecreased_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  marginIncreased?: InputMaybe<Scalars['BigDecimal']>;
  marginIncreased_gt?: InputMaybe<Scalars['BigDecimal']>;
  marginIncreased_gte?: InputMaybe<Scalars['BigDecimal']>;
  marginIncreased_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  marginIncreased_lt?: InputMaybe<Scalars['BigDecimal']>;
  marginIncreased_lte?: InputMaybe<Scalars['BigDecimal']>;
  marginIncreased_not?: InputMaybe<Scalars['BigDecimal']>;
  marginIncreased_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin_gt?: InputMaybe<Scalars['BigDecimal']>;
  margin_gte?: InputMaybe<Scalars['BigDecimal']>;
  margin_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin_lt?: InputMaybe<Scalars['BigDecimal']>;
  margin_lte?: InputMaybe<Scalars['BigDecimal']>;
  margin_not?: InputMaybe<Scalars['BigDecimal']>;
  margin_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<LiquidityPosition_Filter>>>;
  pnl?: InputMaybe<Scalars['BigDecimal']>;
  pnlGrowthX64?: InputMaybe<Scalars['BigInt']>;
  pnlGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  pnlGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  pnlGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  pnlGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  pnlGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  pnlGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  pnlGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  pnl_gt?: InputMaybe<Scalars['BigDecimal']>;
  pnl_gte?: InputMaybe<Scalars['BigDecimal']>;
  pnl_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  pnl_lt?: InputMaybe<Scalars['BigDecimal']>;
  pnl_lte?: InputMaybe<Scalars['BigDecimal']>;
  pnl_not?: InputMaybe<Scalars['BigDecimal']>;
  pnl_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  realizedPnL?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_gt?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_gte?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  realizedPnL_lt?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_lte?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_not?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  status?: InputMaybe<LiquidityPositionStatus>;
  status_in?: InputMaybe<Array<LiquidityPositionStatus>>;
  status_not?: InputMaybe<LiquidityPositionStatus>;
  status_not_in?: InputMaybe<Array<LiquidityPositionStatus>>;
  unrealizedPnLGrowthX64?: InputMaybe<Scalars['BigInt']>;
  unrealizedPnLGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  unrealizedPnLGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  unrealizedPnLGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  unrealizedPnLGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  unrealizedPnLGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  unrealizedPnLGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  unrealizedPnLGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum LiquidityPosition_OrderBy {
  Account = 'account',
  CloseBlockNumber = 'closeBlockNumber',
  CloseTime = 'closeTime',
  CloseTxHash = 'closeTxHash',
  EntryBlockNumber = 'entryBlockNumber',
  EntryTime = 'entryTime',
  EntryTxHash = 'entryTxHash',
  Fee = 'fee',
  FeeGrowthX64 = 'feeGrowthX64',
  FeeReceiver = 'feeReceiver',
  FundingFee = 'fundingFee',
  FundingFeeGrowthX64 = 'fundingFeeGrowthX64',
  Id = 'id',
  LiquidationExecutionFee = 'liquidationExecutionFee',
  Liquidity = 'liquidity',
  LiquidityDecreased = 'liquidityDecreased',
  LiquidityIncreased = 'liquidityIncreased',
  LiquidityPositionHistoryId = 'liquidityPositionHistoryID',
  Margin = 'margin',
  MarginDecreased = 'marginDecreased',
  MarginIncreased = 'marginIncreased',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  Pnl = 'pnl',
  PnlGrowthX64 = 'pnlGrowthX64',
  RealizedPnL = 'realizedPnL',
  Status = 'status',
  UnrealizedPnLGrowthX64 = 'unrealizedPnLGrowthX64'
}

export type Market = {
  __typename?: 'Market';
  /** market base config */
  baseConfig: MarketBaseConfig;
  candles1D: Array<Candle1D>;
  candles1H: Array<Candle1H>;
  candles1M: Array<Candle1M>;
  candles4H: Array<Candle4H>;
  candles5M: Array<Candle5M>;
  candles15M: Array<Candle15M>;
  candles30M: Array<Candle30M>;
  /** market fee rate config */
  feeRateConfig: MarketFeeRateConfig;
  /** pointer to global funding rate sample */
  globalFundingRateSample: GlobalFundingRateSample;
  /** pointer to global liquidation fund */
  globalLiquidationFund: GlobalLiquidationFund;
  /** pointer to global liquidity position */
  globalLiquidityPosition: GlobalLiquidityPosition;
  /** pointer to global position */
  globalPosition: GlobalPosition;
  /** address of the market */
  id: Scalars['Bytes'];
  /** accumulated usage of liquidation fund */
  liquidationFundGovUsed: Scalars['BigDecimal'];
  liquidationFundPositions: Array<LiquidationFundPosition>;
  liquidityPositions: Array<LiquidityPosition>;
  positons: Array<Position>;
  /** market price config */
  priceConfig: MarketPriceConfig;
  /** pointer to price state */
  priceState: PriceState;
  /** pointer to price state 1H */
  priceState1Hs: Array<PriceState1H>;
  /** protocol fee balance of the market */
  protocolFee: Scalars['BigDecimal'];
  /** referral fee balance of the market */
  referralFee: Scalars['BigDecimal'];
  /** symbol of the market */
  symbol: Scalars['String'];
  /** total protocol fee of the market */
  totalProtocolFee: Scalars['BigDecimal'];
  /** total referral fee of the market */
  totalReferralFee: Scalars['BigDecimal'];
  /** trading fee of the market */
  tradingFee: Scalars['BigDecimal'];
  /** all time volume */
  volume: Scalars['BigDecimal'];
  /** all time volume in USD */
  volumeUSD: Scalars['BigDecimal'];
};


export type MarketCandles1DArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Candle1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Candle1D_Filter>;
};


export type MarketCandles1HArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Candle1H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Candle1H_Filter>;
};


export type MarketCandles1MArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Candle1M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Candle1M_Filter>;
};


export type MarketCandles4HArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Candle4H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Candle4H_Filter>;
};


export type MarketCandles5MArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Candle5M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Candle5M_Filter>;
};


export type MarketCandles15MArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Candle15M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Candle15M_Filter>;
};


export type MarketCandles30MArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Candle30M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Candle30M_Filter>;
};


export type MarketLiquidationFundPositionsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LiquidationFundPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LiquidationFundPosition_Filter>;
};


export type MarketLiquidityPositionsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LiquidityPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LiquidityPosition_Filter>;
};


export type MarketPositonsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Position_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Position_Filter>;
};


export type MarketPriceState1HsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PriceState1H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PriceState1H_Filter>;
};

export type MarketBaseConfig = {
  __typename?: 'MarketBaseConfig';
  id: Scalars['Bytes'];
  /** the interest rate used to calculate the funding rate, denominated in ten thousandths of a bip (i.e. 1e-8) */
  interestRate: Scalars['BigInt'];
  /** the liquidation execution fee for LP and trader positions */
  liquidationExecutionFee: Scalars['BigInt'];
  /** the liquidation fee rate for LP positions, */
  liquidationFeeRatePerLiquidityPosition: Scalars['BigInt'];
  /** the liquidation fee rate for trader positions, denominated in ten thousandths of a bip (i.e. 1e-8) */
  liquidationFeeRatePerPosition: Scalars['BigInt'];
  market: Market;
  /** the maximum funding rate, denominated in ten thousandths of a bip (i.e. 1e-8) */
  maxFundingRate: Scalars['BigInt'];
  /** the maximum leverage for LP positions */
  maxLeveragePerLiquidityPosition: Scalars['BigInt'];
  /** the maximum leverage for trader positions */
  maxLeveragePerPosition: Scalars['BigInt'];
  /** the maximum available liquidity used to calculate the maximum size of the trader's position */
  maxPositionLiquidity: Scalars['BigInt'];
  /** the maximum value of all positions relative to `maxPositionLiquidity`, denominated in ten thousandths of a bip (i.e. 1e-8) */
  maxPositionValueRate: Scalars['BigInt'];
  /** the maximum size of per position relative to `maxSize`, denominated in ten thousandths of a bip (i.e. 1e-8) */
  maxSizeRatePerPosition: Scalars['BigInt'];
  /** the minimum entry margin required for LP positions */
  minMarginPerLiquidityPosition: Scalars['BigInt'];
  /** the minimum entry margin required for trader positions */
  minMarginPerPosition: Scalars['BigInt'];
};

export type MarketBaseConfig_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<MarketBaseConfig_Filter>>>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  interestRate?: InputMaybe<Scalars['BigInt']>;
  interestRate_gt?: InputMaybe<Scalars['BigInt']>;
  interestRate_gte?: InputMaybe<Scalars['BigInt']>;
  interestRate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  interestRate_lt?: InputMaybe<Scalars['BigInt']>;
  interestRate_lte?: InputMaybe<Scalars['BigInt']>;
  interestRate_not?: InputMaybe<Scalars['BigInt']>;
  interestRate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  liquidationExecutionFee?: InputMaybe<Scalars['BigInt']>;
  liquidationExecutionFee_gt?: InputMaybe<Scalars['BigInt']>;
  liquidationExecutionFee_gte?: InputMaybe<Scalars['BigInt']>;
  liquidationExecutionFee_in?: InputMaybe<Array<Scalars['BigInt']>>;
  liquidationExecutionFee_lt?: InputMaybe<Scalars['BigInt']>;
  liquidationExecutionFee_lte?: InputMaybe<Scalars['BigInt']>;
  liquidationExecutionFee_not?: InputMaybe<Scalars['BigInt']>;
  liquidationExecutionFee_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  liquidationFeeRatePerLiquidityPosition?: InputMaybe<Scalars['BigInt']>;
  liquidationFeeRatePerLiquidityPosition_gt?: InputMaybe<Scalars['BigInt']>;
  liquidationFeeRatePerLiquidityPosition_gte?: InputMaybe<Scalars['BigInt']>;
  liquidationFeeRatePerLiquidityPosition_in?: InputMaybe<Array<Scalars['BigInt']>>;
  liquidationFeeRatePerLiquidityPosition_lt?: InputMaybe<Scalars['BigInt']>;
  liquidationFeeRatePerLiquidityPosition_lte?: InputMaybe<Scalars['BigInt']>;
  liquidationFeeRatePerLiquidityPosition_not?: InputMaybe<Scalars['BigInt']>;
  liquidationFeeRatePerLiquidityPosition_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  liquidationFeeRatePerPosition?: InputMaybe<Scalars['BigInt']>;
  liquidationFeeRatePerPosition_gt?: InputMaybe<Scalars['BigInt']>;
  liquidationFeeRatePerPosition_gte?: InputMaybe<Scalars['BigInt']>;
  liquidationFeeRatePerPosition_in?: InputMaybe<Array<Scalars['BigInt']>>;
  liquidationFeeRatePerPosition_lt?: InputMaybe<Scalars['BigInt']>;
  liquidationFeeRatePerPosition_lte?: InputMaybe<Scalars['BigInt']>;
  liquidationFeeRatePerPosition_not?: InputMaybe<Scalars['BigInt']>;
  liquidationFeeRatePerPosition_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  maxFundingRate?: InputMaybe<Scalars['BigInt']>;
  maxFundingRate_gt?: InputMaybe<Scalars['BigInt']>;
  maxFundingRate_gte?: InputMaybe<Scalars['BigInt']>;
  maxFundingRate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maxFundingRate_lt?: InputMaybe<Scalars['BigInt']>;
  maxFundingRate_lte?: InputMaybe<Scalars['BigInt']>;
  maxFundingRate_not?: InputMaybe<Scalars['BigInt']>;
  maxFundingRate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maxLeveragePerLiquidityPosition?: InputMaybe<Scalars['BigInt']>;
  maxLeveragePerLiquidityPosition_gt?: InputMaybe<Scalars['BigInt']>;
  maxLeveragePerLiquidityPosition_gte?: InputMaybe<Scalars['BigInt']>;
  maxLeveragePerLiquidityPosition_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maxLeveragePerLiquidityPosition_lt?: InputMaybe<Scalars['BigInt']>;
  maxLeveragePerLiquidityPosition_lte?: InputMaybe<Scalars['BigInt']>;
  maxLeveragePerLiquidityPosition_not?: InputMaybe<Scalars['BigInt']>;
  maxLeveragePerLiquidityPosition_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maxLeveragePerPosition?: InputMaybe<Scalars['BigInt']>;
  maxLeveragePerPosition_gt?: InputMaybe<Scalars['BigInt']>;
  maxLeveragePerPosition_gte?: InputMaybe<Scalars['BigInt']>;
  maxLeveragePerPosition_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maxLeveragePerPosition_lt?: InputMaybe<Scalars['BigInt']>;
  maxLeveragePerPosition_lte?: InputMaybe<Scalars['BigInt']>;
  maxLeveragePerPosition_not?: InputMaybe<Scalars['BigInt']>;
  maxLeveragePerPosition_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maxPositionLiquidity?: InputMaybe<Scalars['BigInt']>;
  maxPositionLiquidity_gt?: InputMaybe<Scalars['BigInt']>;
  maxPositionLiquidity_gte?: InputMaybe<Scalars['BigInt']>;
  maxPositionLiquidity_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maxPositionLiquidity_lt?: InputMaybe<Scalars['BigInt']>;
  maxPositionLiquidity_lte?: InputMaybe<Scalars['BigInt']>;
  maxPositionLiquidity_not?: InputMaybe<Scalars['BigInt']>;
  maxPositionLiquidity_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maxPositionValueRate?: InputMaybe<Scalars['BigInt']>;
  maxPositionValueRate_gt?: InputMaybe<Scalars['BigInt']>;
  maxPositionValueRate_gte?: InputMaybe<Scalars['BigInt']>;
  maxPositionValueRate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maxPositionValueRate_lt?: InputMaybe<Scalars['BigInt']>;
  maxPositionValueRate_lte?: InputMaybe<Scalars['BigInt']>;
  maxPositionValueRate_not?: InputMaybe<Scalars['BigInt']>;
  maxPositionValueRate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maxSizeRatePerPosition?: InputMaybe<Scalars['BigInt']>;
  maxSizeRatePerPosition_gt?: InputMaybe<Scalars['BigInt']>;
  maxSizeRatePerPosition_gte?: InputMaybe<Scalars['BigInt']>;
  maxSizeRatePerPosition_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maxSizeRatePerPosition_lt?: InputMaybe<Scalars['BigInt']>;
  maxSizeRatePerPosition_lte?: InputMaybe<Scalars['BigInt']>;
  maxSizeRatePerPosition_not?: InputMaybe<Scalars['BigInt']>;
  maxSizeRatePerPosition_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  minMarginPerLiquidityPosition?: InputMaybe<Scalars['BigInt']>;
  minMarginPerLiquidityPosition_gt?: InputMaybe<Scalars['BigInt']>;
  minMarginPerLiquidityPosition_gte?: InputMaybe<Scalars['BigInt']>;
  minMarginPerLiquidityPosition_in?: InputMaybe<Array<Scalars['BigInt']>>;
  minMarginPerLiquidityPosition_lt?: InputMaybe<Scalars['BigInt']>;
  minMarginPerLiquidityPosition_lte?: InputMaybe<Scalars['BigInt']>;
  minMarginPerLiquidityPosition_not?: InputMaybe<Scalars['BigInt']>;
  minMarginPerLiquidityPosition_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  minMarginPerPosition?: InputMaybe<Scalars['BigInt']>;
  minMarginPerPosition_gt?: InputMaybe<Scalars['BigInt']>;
  minMarginPerPosition_gte?: InputMaybe<Scalars['BigInt']>;
  minMarginPerPosition_in?: InputMaybe<Array<Scalars['BigInt']>>;
  minMarginPerPosition_lt?: InputMaybe<Scalars['BigInt']>;
  minMarginPerPosition_lte?: InputMaybe<Scalars['BigInt']>;
  minMarginPerPosition_not?: InputMaybe<Scalars['BigInt']>;
  minMarginPerPosition_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  or?: InputMaybe<Array<InputMaybe<MarketBaseConfig_Filter>>>;
};

export enum MarketBaseConfig_OrderBy {
  Id = 'id',
  InterestRate = 'interestRate',
  LiquidationExecutionFee = 'liquidationExecutionFee',
  LiquidationFeeRatePerLiquidityPosition = 'liquidationFeeRatePerLiquidityPosition',
  LiquidationFeeRatePerPosition = 'liquidationFeeRatePerPosition',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  MaxFundingRate = 'maxFundingRate',
  MaxLeveragePerLiquidityPosition = 'maxLeveragePerLiquidityPosition',
  MaxLeveragePerPosition = 'maxLeveragePerPosition',
  MaxPositionLiquidity = 'maxPositionLiquidity',
  MaxPositionValueRate = 'maxPositionValueRate',
  MaxSizeRatePerPosition = 'maxSizeRatePerPosition',
  MinMarginPerLiquidityPosition = 'minMarginPerLiquidityPosition',
  MinMarginPerPosition = 'minMarginPerPosition'
}

export type MarketFeeRateConfig = {
  __typename?: 'MarketFeeRateConfig';
  id: Scalars['Bytes'];
  market: Market;
  /** the protocol fee rate as a percentage of trading fee, denominated in ten thousandths of a bip (i.e. 1e-8) */
  protocolFeeRate: Scalars['BigInt'];
  /** the discount rate for referrals, denominated in ten thousandths of a bip (i.e. 1e-8) */
  referralDiscountRate: Scalars['BigInt'];
  /** the referral parent return fee rate as a percentage of trading fee, denominated in ten thousandths of a bip (i.e. 1e-8) */
  referralParentReturnFeeRate: Scalars['BigInt'];
  /** the referral return fee rate as a percentage of trading fee, denominated in ten thousandths of a bip (i.e. 1e-8) */
  referralReturnFeeRate: Scalars['BigInt'];
  /** the trading fee rate for trader increase or decrease positions, denominated in ten thousandths of a bip (i.e. 1e-8) */
  tradingFeeRate: Scalars['BigInt'];
};

export type MarketFeeRateConfig_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<MarketFeeRateConfig_Filter>>>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<MarketFeeRateConfig_Filter>>>;
  protocolFeeRate?: InputMaybe<Scalars['BigInt']>;
  protocolFeeRate_gt?: InputMaybe<Scalars['BigInt']>;
  protocolFeeRate_gte?: InputMaybe<Scalars['BigInt']>;
  protocolFeeRate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  protocolFeeRate_lt?: InputMaybe<Scalars['BigInt']>;
  protocolFeeRate_lte?: InputMaybe<Scalars['BigInt']>;
  protocolFeeRate_not?: InputMaybe<Scalars['BigInt']>;
  protocolFeeRate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralDiscountRate?: InputMaybe<Scalars['BigInt']>;
  referralDiscountRate_gt?: InputMaybe<Scalars['BigInt']>;
  referralDiscountRate_gte?: InputMaybe<Scalars['BigInt']>;
  referralDiscountRate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralDiscountRate_lt?: InputMaybe<Scalars['BigInt']>;
  referralDiscountRate_lte?: InputMaybe<Scalars['BigInt']>;
  referralDiscountRate_not?: InputMaybe<Scalars['BigInt']>;
  referralDiscountRate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralParentReturnFeeRate?: InputMaybe<Scalars['BigInt']>;
  referralParentReturnFeeRate_gt?: InputMaybe<Scalars['BigInt']>;
  referralParentReturnFeeRate_gte?: InputMaybe<Scalars['BigInt']>;
  referralParentReturnFeeRate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralParentReturnFeeRate_lt?: InputMaybe<Scalars['BigInt']>;
  referralParentReturnFeeRate_lte?: InputMaybe<Scalars['BigInt']>;
  referralParentReturnFeeRate_not?: InputMaybe<Scalars['BigInt']>;
  referralParentReturnFeeRate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralReturnFeeRate?: InputMaybe<Scalars['BigInt']>;
  referralReturnFeeRate_gt?: InputMaybe<Scalars['BigInt']>;
  referralReturnFeeRate_gte?: InputMaybe<Scalars['BigInt']>;
  referralReturnFeeRate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralReturnFeeRate_lt?: InputMaybe<Scalars['BigInt']>;
  referralReturnFeeRate_lte?: InputMaybe<Scalars['BigInt']>;
  referralReturnFeeRate_not?: InputMaybe<Scalars['BigInt']>;
  referralReturnFeeRate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tradingFeeRate?: InputMaybe<Scalars['BigInt']>;
  tradingFeeRate_gt?: InputMaybe<Scalars['BigInt']>;
  tradingFeeRate_gte?: InputMaybe<Scalars['BigInt']>;
  tradingFeeRate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tradingFeeRate_lt?: InputMaybe<Scalars['BigInt']>;
  tradingFeeRate_lte?: InputMaybe<Scalars['BigInt']>;
  tradingFeeRate_not?: InputMaybe<Scalars['BigInt']>;
  tradingFeeRate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum MarketFeeRateConfig_OrderBy {
  Id = 'id',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  ProtocolFeeRate = 'protocolFeeRate',
  ReferralDiscountRate = 'referralDiscountRate',
  ReferralParentReturnFeeRate = 'referralParentReturnFeeRate',
  ReferralReturnFeeRate = 'referralReturnFeeRate',
  TradingFeeRate = 'tradingFeeRate'
}

export type MarketPriceConfig = {
  __typename?: 'MarketPriceConfig';
  id: Scalars['Bytes'];
  /** the index used to store the net position of the liquidation */
  liquidationVertexIndex: Scalars['Int'];
  market: Market;
  /** the maximum available liquidity used to calculate the premium rate when trader increase or decrease positions */
  maxPriceImpactLiquidity: Scalars['BigInt'];
  /** the vertexes used to calculate the premium rate when trader increase or decrease positions */
  vertices: Array<VertexConfig>;
};


export type MarketPriceConfigVerticesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<VertexConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VertexConfig_Filter>;
};

export type MarketPriceConfig_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<MarketPriceConfig_Filter>>>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  liquidationVertexIndex?: InputMaybe<Scalars['Int']>;
  liquidationVertexIndex_gt?: InputMaybe<Scalars['Int']>;
  liquidationVertexIndex_gte?: InputMaybe<Scalars['Int']>;
  liquidationVertexIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  liquidationVertexIndex_lt?: InputMaybe<Scalars['Int']>;
  liquidationVertexIndex_lte?: InputMaybe<Scalars['Int']>;
  liquidationVertexIndex_not?: InputMaybe<Scalars['Int']>;
  liquidationVertexIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  maxPriceImpactLiquidity?: InputMaybe<Scalars['BigInt']>;
  maxPriceImpactLiquidity_gt?: InputMaybe<Scalars['BigInt']>;
  maxPriceImpactLiquidity_gte?: InputMaybe<Scalars['BigInt']>;
  maxPriceImpactLiquidity_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maxPriceImpactLiquidity_lt?: InputMaybe<Scalars['BigInt']>;
  maxPriceImpactLiquidity_lte?: InputMaybe<Scalars['BigInt']>;
  maxPriceImpactLiquidity_not?: InputMaybe<Scalars['BigInt']>;
  maxPriceImpactLiquidity_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  or?: InputMaybe<Array<InputMaybe<MarketPriceConfig_Filter>>>;
  vertices_?: InputMaybe<VertexConfig_Filter>;
};

export enum MarketPriceConfig_OrderBy {
  Id = 'id',
  LiquidationVertexIndex = 'liquidationVertexIndex',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  MaxPriceImpactLiquidity = 'maxPriceImpactLiquidity',
  Vertices = 'vertices'
}

export type Market_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Market_Filter>>>;
  baseConfig?: InputMaybe<Scalars['String']>;
  baseConfig_?: InputMaybe<MarketBaseConfig_Filter>;
  baseConfig_contains?: InputMaybe<Scalars['String']>;
  baseConfig_contains_nocase?: InputMaybe<Scalars['String']>;
  baseConfig_ends_with?: InputMaybe<Scalars['String']>;
  baseConfig_ends_with_nocase?: InputMaybe<Scalars['String']>;
  baseConfig_gt?: InputMaybe<Scalars['String']>;
  baseConfig_gte?: InputMaybe<Scalars['String']>;
  baseConfig_in?: InputMaybe<Array<Scalars['String']>>;
  baseConfig_lt?: InputMaybe<Scalars['String']>;
  baseConfig_lte?: InputMaybe<Scalars['String']>;
  baseConfig_not?: InputMaybe<Scalars['String']>;
  baseConfig_not_contains?: InputMaybe<Scalars['String']>;
  baseConfig_not_contains_nocase?: InputMaybe<Scalars['String']>;
  baseConfig_not_ends_with?: InputMaybe<Scalars['String']>;
  baseConfig_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  baseConfig_not_in?: InputMaybe<Array<Scalars['String']>>;
  baseConfig_not_starts_with?: InputMaybe<Scalars['String']>;
  baseConfig_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  baseConfig_starts_with?: InputMaybe<Scalars['String']>;
  baseConfig_starts_with_nocase?: InputMaybe<Scalars['String']>;
  candles1D_?: InputMaybe<Candle1D_Filter>;
  candles1H_?: InputMaybe<Candle1H_Filter>;
  candles1M_?: InputMaybe<Candle1M_Filter>;
  candles4H_?: InputMaybe<Candle4H_Filter>;
  candles5M_?: InputMaybe<Candle5M_Filter>;
  candles15M_?: InputMaybe<Candle15M_Filter>;
  candles30M_?: InputMaybe<Candle30M_Filter>;
  feeRateConfig?: InputMaybe<Scalars['String']>;
  feeRateConfig_?: InputMaybe<MarketFeeRateConfig_Filter>;
  feeRateConfig_contains?: InputMaybe<Scalars['String']>;
  feeRateConfig_contains_nocase?: InputMaybe<Scalars['String']>;
  feeRateConfig_ends_with?: InputMaybe<Scalars['String']>;
  feeRateConfig_ends_with_nocase?: InputMaybe<Scalars['String']>;
  feeRateConfig_gt?: InputMaybe<Scalars['String']>;
  feeRateConfig_gte?: InputMaybe<Scalars['String']>;
  feeRateConfig_in?: InputMaybe<Array<Scalars['String']>>;
  feeRateConfig_lt?: InputMaybe<Scalars['String']>;
  feeRateConfig_lte?: InputMaybe<Scalars['String']>;
  feeRateConfig_not?: InputMaybe<Scalars['String']>;
  feeRateConfig_not_contains?: InputMaybe<Scalars['String']>;
  feeRateConfig_not_contains_nocase?: InputMaybe<Scalars['String']>;
  feeRateConfig_not_ends_with?: InputMaybe<Scalars['String']>;
  feeRateConfig_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  feeRateConfig_not_in?: InputMaybe<Array<Scalars['String']>>;
  feeRateConfig_not_starts_with?: InputMaybe<Scalars['String']>;
  feeRateConfig_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  feeRateConfig_starts_with?: InputMaybe<Scalars['String']>;
  feeRateConfig_starts_with_nocase?: InputMaybe<Scalars['String']>;
  globalFundingRateSample?: InputMaybe<Scalars['String']>;
  globalFundingRateSample_?: InputMaybe<GlobalFundingRateSample_Filter>;
  globalFundingRateSample_contains?: InputMaybe<Scalars['String']>;
  globalFundingRateSample_contains_nocase?: InputMaybe<Scalars['String']>;
  globalFundingRateSample_ends_with?: InputMaybe<Scalars['String']>;
  globalFundingRateSample_ends_with_nocase?: InputMaybe<Scalars['String']>;
  globalFundingRateSample_gt?: InputMaybe<Scalars['String']>;
  globalFundingRateSample_gte?: InputMaybe<Scalars['String']>;
  globalFundingRateSample_in?: InputMaybe<Array<Scalars['String']>>;
  globalFundingRateSample_lt?: InputMaybe<Scalars['String']>;
  globalFundingRateSample_lte?: InputMaybe<Scalars['String']>;
  globalFundingRateSample_not?: InputMaybe<Scalars['String']>;
  globalFundingRateSample_not_contains?: InputMaybe<Scalars['String']>;
  globalFundingRateSample_not_contains_nocase?: InputMaybe<Scalars['String']>;
  globalFundingRateSample_not_ends_with?: InputMaybe<Scalars['String']>;
  globalFundingRateSample_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  globalFundingRateSample_not_in?: InputMaybe<Array<Scalars['String']>>;
  globalFundingRateSample_not_starts_with?: InputMaybe<Scalars['String']>;
  globalFundingRateSample_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  globalFundingRateSample_starts_with?: InputMaybe<Scalars['String']>;
  globalFundingRateSample_starts_with_nocase?: InputMaybe<Scalars['String']>;
  globalLiquidationFund?: InputMaybe<Scalars['String']>;
  globalLiquidationFund_?: InputMaybe<GlobalLiquidationFund_Filter>;
  globalLiquidationFund_contains?: InputMaybe<Scalars['String']>;
  globalLiquidationFund_contains_nocase?: InputMaybe<Scalars['String']>;
  globalLiquidationFund_ends_with?: InputMaybe<Scalars['String']>;
  globalLiquidationFund_ends_with_nocase?: InputMaybe<Scalars['String']>;
  globalLiquidationFund_gt?: InputMaybe<Scalars['String']>;
  globalLiquidationFund_gte?: InputMaybe<Scalars['String']>;
  globalLiquidationFund_in?: InputMaybe<Array<Scalars['String']>>;
  globalLiquidationFund_lt?: InputMaybe<Scalars['String']>;
  globalLiquidationFund_lte?: InputMaybe<Scalars['String']>;
  globalLiquidationFund_not?: InputMaybe<Scalars['String']>;
  globalLiquidationFund_not_contains?: InputMaybe<Scalars['String']>;
  globalLiquidationFund_not_contains_nocase?: InputMaybe<Scalars['String']>;
  globalLiquidationFund_not_ends_with?: InputMaybe<Scalars['String']>;
  globalLiquidationFund_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  globalLiquidationFund_not_in?: InputMaybe<Array<Scalars['String']>>;
  globalLiquidationFund_not_starts_with?: InputMaybe<Scalars['String']>;
  globalLiquidationFund_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  globalLiquidationFund_starts_with?: InputMaybe<Scalars['String']>;
  globalLiquidationFund_starts_with_nocase?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_?: InputMaybe<GlobalLiquidityPosition_Filter>;
  globalLiquidityPosition_contains?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_contains_nocase?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_ends_with?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_ends_with_nocase?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_gt?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_gte?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_in?: InputMaybe<Array<Scalars['String']>>;
  globalLiquidityPosition_lt?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_lte?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_not?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_not_contains?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_not_contains_nocase?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_not_ends_with?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_not_in?: InputMaybe<Array<Scalars['String']>>;
  globalLiquidityPosition_not_starts_with?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_starts_with?: InputMaybe<Scalars['String']>;
  globalLiquidityPosition_starts_with_nocase?: InputMaybe<Scalars['String']>;
  globalPosition?: InputMaybe<Scalars['String']>;
  globalPosition_?: InputMaybe<GlobalPosition_Filter>;
  globalPosition_contains?: InputMaybe<Scalars['String']>;
  globalPosition_contains_nocase?: InputMaybe<Scalars['String']>;
  globalPosition_ends_with?: InputMaybe<Scalars['String']>;
  globalPosition_ends_with_nocase?: InputMaybe<Scalars['String']>;
  globalPosition_gt?: InputMaybe<Scalars['String']>;
  globalPosition_gte?: InputMaybe<Scalars['String']>;
  globalPosition_in?: InputMaybe<Array<Scalars['String']>>;
  globalPosition_lt?: InputMaybe<Scalars['String']>;
  globalPosition_lte?: InputMaybe<Scalars['String']>;
  globalPosition_not?: InputMaybe<Scalars['String']>;
  globalPosition_not_contains?: InputMaybe<Scalars['String']>;
  globalPosition_not_contains_nocase?: InputMaybe<Scalars['String']>;
  globalPosition_not_ends_with?: InputMaybe<Scalars['String']>;
  globalPosition_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  globalPosition_not_in?: InputMaybe<Array<Scalars['String']>>;
  globalPosition_not_starts_with?: InputMaybe<Scalars['String']>;
  globalPosition_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  globalPosition_starts_with?: InputMaybe<Scalars['String']>;
  globalPosition_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  liquidationFundGovUsed?: InputMaybe<Scalars['BigDecimal']>;
  liquidationFundGovUsed_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidationFundGovUsed_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidationFundGovUsed_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidationFundGovUsed_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidationFundGovUsed_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidationFundGovUsed_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidationFundGovUsed_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidationFundPositions_?: InputMaybe<LiquidationFundPosition_Filter>;
  liquidityPositions_?: InputMaybe<LiquidityPosition_Filter>;
  or?: InputMaybe<Array<InputMaybe<Market_Filter>>>;
  positons_?: InputMaybe<Position_Filter>;
  priceConfig?: InputMaybe<Scalars['String']>;
  priceConfig_?: InputMaybe<MarketPriceConfig_Filter>;
  priceConfig_contains?: InputMaybe<Scalars['String']>;
  priceConfig_contains_nocase?: InputMaybe<Scalars['String']>;
  priceConfig_ends_with?: InputMaybe<Scalars['String']>;
  priceConfig_ends_with_nocase?: InputMaybe<Scalars['String']>;
  priceConfig_gt?: InputMaybe<Scalars['String']>;
  priceConfig_gte?: InputMaybe<Scalars['String']>;
  priceConfig_in?: InputMaybe<Array<Scalars['String']>>;
  priceConfig_lt?: InputMaybe<Scalars['String']>;
  priceConfig_lte?: InputMaybe<Scalars['String']>;
  priceConfig_not?: InputMaybe<Scalars['String']>;
  priceConfig_not_contains?: InputMaybe<Scalars['String']>;
  priceConfig_not_contains_nocase?: InputMaybe<Scalars['String']>;
  priceConfig_not_ends_with?: InputMaybe<Scalars['String']>;
  priceConfig_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  priceConfig_not_in?: InputMaybe<Array<Scalars['String']>>;
  priceConfig_not_starts_with?: InputMaybe<Scalars['String']>;
  priceConfig_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  priceConfig_starts_with?: InputMaybe<Scalars['String']>;
  priceConfig_starts_with_nocase?: InputMaybe<Scalars['String']>;
  priceState?: InputMaybe<Scalars['String']>;
  priceState1Hs_?: InputMaybe<PriceState1H_Filter>;
  priceState_?: InputMaybe<PriceState_Filter>;
  priceState_contains?: InputMaybe<Scalars['String']>;
  priceState_contains_nocase?: InputMaybe<Scalars['String']>;
  priceState_ends_with?: InputMaybe<Scalars['String']>;
  priceState_ends_with_nocase?: InputMaybe<Scalars['String']>;
  priceState_gt?: InputMaybe<Scalars['String']>;
  priceState_gte?: InputMaybe<Scalars['String']>;
  priceState_in?: InputMaybe<Array<Scalars['String']>>;
  priceState_lt?: InputMaybe<Scalars['String']>;
  priceState_lte?: InputMaybe<Scalars['String']>;
  priceState_not?: InputMaybe<Scalars['String']>;
  priceState_not_contains?: InputMaybe<Scalars['String']>;
  priceState_not_contains_nocase?: InputMaybe<Scalars['String']>;
  priceState_not_ends_with?: InputMaybe<Scalars['String']>;
  priceState_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  priceState_not_in?: InputMaybe<Array<Scalars['String']>>;
  priceState_not_starts_with?: InputMaybe<Scalars['String']>;
  priceState_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  priceState_starts_with?: InputMaybe<Scalars['String']>;
  priceState_starts_with_nocase?: InputMaybe<Scalars['String']>;
  protocolFee?: InputMaybe<Scalars['BigDecimal']>;
  protocolFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  protocolFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  protocolFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  protocolFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  protocolFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  protocolFee_not?: InputMaybe<Scalars['BigDecimal']>;
  protocolFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  referralFee?: InputMaybe<Scalars['BigDecimal']>;
  referralFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  referralFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  referralFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  referralFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  referralFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  referralFee_not?: InputMaybe<Scalars['BigDecimal']>;
  referralFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  symbol?: InputMaybe<Scalars['String']>;
  symbol_contains?: InputMaybe<Scalars['String']>;
  symbol_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_ends_with?: InputMaybe<Scalars['String']>;
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_gt?: InputMaybe<Scalars['String']>;
  symbol_gte?: InputMaybe<Scalars['String']>;
  symbol_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_lt?: InputMaybe<Scalars['String']>;
  symbol_lte?: InputMaybe<Scalars['String']>;
  symbol_not?: InputMaybe<Scalars['String']>;
  symbol_not_contains?: InputMaybe<Scalars['String']>;
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_starts_with?: InputMaybe<Scalars['String']>;
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
  totalProtocolFee?: InputMaybe<Scalars['BigDecimal']>;
  totalProtocolFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  totalProtocolFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  totalProtocolFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalProtocolFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  totalProtocolFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  totalProtocolFee_not?: InputMaybe<Scalars['BigDecimal']>;
  totalProtocolFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalReferralFee?: InputMaybe<Scalars['BigDecimal']>;
  totalReferralFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  totalReferralFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  totalReferralFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalReferralFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  totalReferralFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  totalReferralFee_not?: InputMaybe<Scalars['BigDecimal']>;
  totalReferralFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  tradingFee?: InputMaybe<Scalars['BigDecimal']>;
  tradingFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  tradingFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  tradingFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  tradingFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  tradingFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  tradingFee_not?: InputMaybe<Scalars['BigDecimal']>;
  tradingFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volume?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_gte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volumeUSD_lt?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_lte?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not?: InputMaybe<Scalars['BigDecimal']>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volume_gt?: InputMaybe<Scalars['BigDecimal']>;
  volume_gte?: InputMaybe<Scalars['BigDecimal']>;
  volume_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  volume_lt?: InputMaybe<Scalars['BigDecimal']>;
  volume_lte?: InputMaybe<Scalars['BigDecimal']>;
  volume_not?: InputMaybe<Scalars['BigDecimal']>;
  volume_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum Market_OrderBy {
  BaseConfig = 'baseConfig',
  BaseConfigId = 'baseConfig__id',
  BaseConfigInterestRate = 'baseConfig__interestRate',
  BaseConfigLiquidationExecutionFee = 'baseConfig__liquidationExecutionFee',
  BaseConfigLiquidationFeeRatePerLiquidityPosition = 'baseConfig__liquidationFeeRatePerLiquidityPosition',
  BaseConfigLiquidationFeeRatePerPosition = 'baseConfig__liquidationFeeRatePerPosition',
  BaseConfigMaxFundingRate = 'baseConfig__maxFundingRate',
  BaseConfigMaxLeveragePerLiquidityPosition = 'baseConfig__maxLeveragePerLiquidityPosition',
  BaseConfigMaxLeveragePerPosition = 'baseConfig__maxLeveragePerPosition',
  BaseConfigMaxPositionLiquidity = 'baseConfig__maxPositionLiquidity',
  BaseConfigMaxPositionValueRate = 'baseConfig__maxPositionValueRate',
  BaseConfigMaxSizeRatePerPosition = 'baseConfig__maxSizeRatePerPosition',
  BaseConfigMinMarginPerLiquidityPosition = 'baseConfig__minMarginPerLiquidityPosition',
  BaseConfigMinMarginPerPosition = 'baseConfig__minMarginPerPosition',
  Candles1D = 'candles1D',
  Candles1H = 'candles1H',
  Candles1M = 'candles1M',
  Candles4H = 'candles4H',
  Candles5M = 'candles5M',
  Candles15M = 'candles15M',
  Candles30M = 'candles30M',
  FeeRateConfig = 'feeRateConfig',
  FeeRateConfigId = 'feeRateConfig__id',
  FeeRateConfigProtocolFeeRate = 'feeRateConfig__protocolFeeRate',
  FeeRateConfigReferralDiscountRate = 'feeRateConfig__referralDiscountRate',
  FeeRateConfigReferralParentReturnFeeRate = 'feeRateConfig__referralParentReturnFeeRate',
  FeeRateConfigReferralReturnFeeRate = 'feeRateConfig__referralReturnFeeRate',
  FeeRateConfigTradingFeeRate = 'feeRateConfig__tradingFeeRate',
  GlobalFundingRateSample = 'globalFundingRateSample',
  GlobalFundingRateSampleCumulativePremiumRate = 'globalFundingRateSample__cumulativePremiumRate',
  GlobalFundingRateSampleCumulativePremiumRateX96 = 'globalFundingRateSample__cumulativePremiumRateX96',
  GlobalFundingRateSampleId = 'globalFundingRateSample__id',
  GlobalFundingRateSampleSampleCount = 'globalFundingRateSample__sampleCount',
  GlobalLiquidationFund = 'globalLiquidationFund',
  GlobalLiquidationFundId = 'globalLiquidationFund__id',
  GlobalLiquidationFundLiquidationFund = 'globalLiquidationFund__liquidationFund',
  GlobalLiquidationFundLiquidity = 'globalLiquidationFund__liquidity',
  GlobalLiquidityPosition = 'globalLiquidityPosition',
  GlobalLiquidityPositionFee = 'globalLiquidityPosition__fee',
  GlobalLiquidityPositionFeeGrowthX64 = 'globalLiquidityPosition__feeGrowthX64',
  GlobalLiquidityPositionFundingFee = 'globalLiquidityPosition__fundingFee',
  GlobalLiquidityPositionFundingFeeGrowthX64 = 'globalLiquidityPosition__fundingFeeGrowthX64',
  GlobalLiquidityPositionId = 'globalLiquidityPosition__id',
  GlobalLiquidityPositionLiquidationBufferNetSize = 'globalLiquidityPosition__liquidationBufferNetSize',
  GlobalLiquidityPositionLiquidity = 'globalLiquidityPosition__liquidity',
  GlobalLiquidityPositionMargin = 'globalLiquidityPosition__margin',
  GlobalLiquidityPositionNetSize = 'globalLiquidityPosition__netSize',
  GlobalLiquidityPositionPnlGrowthX64 = 'globalLiquidityPosition__pnlGrowthX64',
  GlobalLiquidityPositionPreviousSpPrice = 'globalLiquidityPosition__previousSPPrice',
  GlobalLiquidityPositionPreviousSpPriceX96 = 'globalLiquidityPosition__previousSPPriceX96',
  GlobalLiquidityPositionSide = 'globalLiquidityPosition__side',
  GlobalLiquidityPositionUnrealizedPnLGrowthX64 = 'globalLiquidityPosition__unrealizedPnLGrowthX64',
  GlobalPosition = 'globalPosition',
  GlobalPositionFundingRate = 'globalPosition__fundingRate',
  GlobalPositionFundingRateX96 = 'globalPosition__fundingRateX96',
  GlobalPositionId = 'globalPosition__id',
  GlobalPositionLastAdjustFundingRateTime = 'globalPosition__lastAdjustFundingRateTime',
  GlobalPositionLiquidity = 'globalPosition__liquidity',
  GlobalPositionLongFundingRateGrowthX96 = 'globalPosition__longFundingRateGrowthX96',
  GlobalPositionLongSize = 'globalPosition__longSize',
  GlobalPositionMargin = 'globalPosition__margin',
  GlobalPositionMaxSize = 'globalPosition__maxSize',
  GlobalPositionMaxSizePerPosition = 'globalPosition__maxSizePerPosition',
  GlobalPositionPreviousLongFundingRateGrowthX96 = 'globalPosition__previousLongFundingRateGrowthX96',
  GlobalPositionPreviousShortFundingRateGrowthX96 = 'globalPosition__previousShortFundingRateGrowthX96',
  GlobalPositionShortFundingRateGrowthX96 = 'globalPosition__shortFundingRateGrowthX96',
  GlobalPositionShortSize = 'globalPosition__shortSize',
  Id = 'id',
  LiquidationFundGovUsed = 'liquidationFundGovUsed',
  LiquidationFundPositions = 'liquidationFundPositions',
  LiquidityPositions = 'liquidityPositions',
  Positons = 'positons',
  PriceConfig = 'priceConfig',
  PriceConfigId = 'priceConfig__id',
  PriceConfigLiquidationVertexIndex = 'priceConfig__liquidationVertexIndex',
  PriceConfigMaxPriceImpactLiquidity = 'priceConfig__maxPriceImpactLiquidity',
  PriceState = 'priceState',
  PriceState1Hs = 'priceState1Hs',
  PriceStateBasisIndexPriceX96 = 'priceState__basisIndexPriceX96',
  PriceStateId = 'priceState__id',
  PriceStateIndexPriceUsedX96 = 'priceState__indexPriceUsedX96',
  PriceStatePendingVertexIndex = 'priceState__pendingVertexIndex',
  PriceStatePendingVertexIndexBitmap = 'priceState__pendingVertexIndexBitmap',
  PriceStatePremiumRateX96 = 'priceState__premiumRateX96',
  ProtocolFee = 'protocolFee',
  ReferralFee = 'referralFee',
  Symbol = 'symbol',
  TotalProtocolFee = 'totalProtocolFee',
  TotalReferralFee = 'totalReferralFee',
  TradingFee = 'tradingFee',
  Volume = 'volume',
  VolumeUsd = 'volumeUSD'
}

/** entity that represents a limit order */
export type Order = {
  __typename?: 'Order';
  /** worst trade price user can accept */
  acceptableTradePrice: Scalars['BigDecimal'];
  /** worst trade price user can accept, as a Q64.96 */
  acceptableTradePriceX96: Scalars['BigInt'];
  /** account that create the request */
  account: Scalars['Bytes'];
  /** valid if the order cancelled, hash of the cancellation tx */
  cancelledHash: Scalars['Bytes'];
  /** timestamp that the order was cancelled */
  cancelledTimestamp: Scalars['BigInt'];
  /** tx hash of the request creation */
  createdHash: Scalars['Bytes'];
  /** timestamp that the order was created */
  createdTimestamp: Scalars['BigInt'];
  /** valid if the order executed, hash of the execution tx */
  executedHash: Scalars['Bytes'];
  /** timestamp that the order was executed */
  executedTimestamp: Scalars['BigInt'];
  id: Scalars['ID'];
  /** the request index */
  index: Scalars['BigInt'];
  /** timestamp that the order was operated(e.g. creation, cancellation, update) */
  lastOperationTimestamp: Scalars['BigInt'];
  /** margin paid by the user, transferred to market manager when to execute */
  marginDelta: Scalars['BigDecimal'];
  /** in which market to execute the order */
  market: Market;
  /** valid for decrease orders */
  receiver: Scalars['Bytes'];
  /** side of the request, 1 for long and 2 for short */
  side: Scalars['Int'];
  /** size delta of base token */
  sizeDelta: Scalars['BigDecimal'];
  /** status of the order, enum{Created, Cancelled, Executed} */
  status: Scalars['String'];
  /** whether to trigger when market price is higher than trigger price */
  triggerAbove: Scalars['Boolean'];
  /** trigger price of the limit order */
  triggerMarketPrice: Scalars['BigDecimal'];
  /** trigger price of the limit order, as a Q64.96 */
  triggerMarketPriceX96: Scalars['BigInt'];
  /** type of the order, enum{Increase, Decrease} */
  type: OrderType;
  /** tx hash that the order was last updated at */
  updatedHash: Scalars['Bytes'];
  /** timestamp that the order was last updated */
  updatedTimestamp: Scalars['BigInt'];
};

/** entity that represents the order book configuration */
export type OrderBookConfig = {
  __typename?: 'OrderBookConfig';
  id: Scalars['ID'];
  /** minimum fee user must pay for executor to carry out their orders */
  minExecutionFee: Scalars['BigInt'];
};

export type OrderBookConfig_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<OrderBookConfig_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  minExecutionFee?: InputMaybe<Scalars['BigInt']>;
  minExecutionFee_gt?: InputMaybe<Scalars['BigInt']>;
  minExecutionFee_gte?: InputMaybe<Scalars['BigInt']>;
  minExecutionFee_in?: InputMaybe<Array<Scalars['BigInt']>>;
  minExecutionFee_lt?: InputMaybe<Scalars['BigInt']>;
  minExecutionFee_lte?: InputMaybe<Scalars['BigInt']>;
  minExecutionFee_not?: InputMaybe<Scalars['BigInt']>;
  minExecutionFee_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  or?: InputMaybe<Array<InputMaybe<OrderBookConfig_Filter>>>;
};

export enum OrderBookConfig_OrderBy {
  Id = 'id',
  MinExecutionFee = 'minExecutionFee'
}

/** Defines the order direction, either ascending or descending */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export enum OrderType {
  Decrease = 'Decrease',
  Increase = 'Increase'
}

export type Order_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  acceptableTradePrice?: InputMaybe<Scalars['BigDecimal']>;
  acceptableTradePriceX96?: InputMaybe<Scalars['BigInt']>;
  acceptableTradePriceX96_gt?: InputMaybe<Scalars['BigInt']>;
  acceptableTradePriceX96_gte?: InputMaybe<Scalars['BigInt']>;
  acceptableTradePriceX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  acceptableTradePriceX96_lt?: InputMaybe<Scalars['BigInt']>;
  acceptableTradePriceX96_lte?: InputMaybe<Scalars['BigInt']>;
  acceptableTradePriceX96_not?: InputMaybe<Scalars['BigInt']>;
  acceptableTradePriceX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  acceptableTradePrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  acceptableTradePrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  acceptableTradePrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  acceptableTradePrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  acceptableTradePrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  acceptableTradePrice_not?: InputMaybe<Scalars['BigDecimal']>;
  acceptableTradePrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  account?: InputMaybe<Scalars['Bytes']>;
  account_contains?: InputMaybe<Scalars['Bytes']>;
  account_gt?: InputMaybe<Scalars['Bytes']>;
  account_gte?: InputMaybe<Scalars['Bytes']>;
  account_in?: InputMaybe<Array<Scalars['Bytes']>>;
  account_lt?: InputMaybe<Scalars['Bytes']>;
  account_lte?: InputMaybe<Scalars['Bytes']>;
  account_not?: InputMaybe<Scalars['Bytes']>;
  account_not_contains?: InputMaybe<Scalars['Bytes']>;
  account_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  and?: InputMaybe<Array<InputMaybe<Order_Filter>>>;
  cancelledHash?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_contains?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_gt?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_gte?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  cancelledHash_lt?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_lte?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_not?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  cancelledHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  cancelledTimestamp?: InputMaybe<Scalars['BigInt']>;
  cancelledTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  cancelledTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  cancelledTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  cancelledTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  cancelledTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  cancelledTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  cancelledTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  createdHash?: InputMaybe<Scalars['Bytes']>;
  createdHash_contains?: InputMaybe<Scalars['Bytes']>;
  createdHash_gt?: InputMaybe<Scalars['Bytes']>;
  createdHash_gte?: InputMaybe<Scalars['Bytes']>;
  createdHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  createdHash_lt?: InputMaybe<Scalars['Bytes']>;
  createdHash_lte?: InputMaybe<Scalars['Bytes']>;
  createdHash_not?: InputMaybe<Scalars['Bytes']>;
  createdHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  createdHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  createdTimestamp?: InputMaybe<Scalars['BigInt']>;
  createdTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  createdTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  createdTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  createdTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  createdTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  createdTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  createdTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  executedHash?: InputMaybe<Scalars['Bytes']>;
  executedHash_contains?: InputMaybe<Scalars['Bytes']>;
  executedHash_gt?: InputMaybe<Scalars['Bytes']>;
  executedHash_gte?: InputMaybe<Scalars['Bytes']>;
  executedHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  executedHash_lt?: InputMaybe<Scalars['Bytes']>;
  executedHash_lte?: InputMaybe<Scalars['Bytes']>;
  executedHash_not?: InputMaybe<Scalars['Bytes']>;
  executedHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  executedHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  executedTimestamp?: InputMaybe<Scalars['BigInt']>;
  executedTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  executedTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  executedTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  executedTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  executedTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  executedTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  executedTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  index?: InputMaybe<Scalars['BigInt']>;
  index_gt?: InputMaybe<Scalars['BigInt']>;
  index_gte?: InputMaybe<Scalars['BigInt']>;
  index_in?: InputMaybe<Array<Scalars['BigInt']>>;
  index_lt?: InputMaybe<Scalars['BigInt']>;
  index_lte?: InputMaybe<Scalars['BigInt']>;
  index_not?: InputMaybe<Scalars['BigInt']>;
  index_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  lastOperationTimestamp?: InputMaybe<Scalars['BigInt']>;
  lastOperationTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  lastOperationTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  lastOperationTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  lastOperationTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  lastOperationTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  lastOperationTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  lastOperationTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  marginDelta?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_gt?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_gte?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  marginDelta_lt?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_lte?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_not?: InputMaybe<Scalars['BigDecimal']>;
  marginDelta_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<Order_Filter>>>;
  receiver?: InputMaybe<Scalars['Bytes']>;
  receiver_contains?: InputMaybe<Scalars['Bytes']>;
  receiver_gt?: InputMaybe<Scalars['Bytes']>;
  receiver_gte?: InputMaybe<Scalars['Bytes']>;
  receiver_in?: InputMaybe<Array<Scalars['Bytes']>>;
  receiver_lt?: InputMaybe<Scalars['Bytes']>;
  receiver_lte?: InputMaybe<Scalars['Bytes']>;
  receiver_not?: InputMaybe<Scalars['Bytes']>;
  receiver_not_contains?: InputMaybe<Scalars['Bytes']>;
  receiver_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  side?: InputMaybe<Scalars['Int']>;
  side_gt?: InputMaybe<Scalars['Int']>;
  side_gte?: InputMaybe<Scalars['Int']>;
  side_in?: InputMaybe<Array<Scalars['Int']>>;
  side_lt?: InputMaybe<Scalars['Int']>;
  side_lte?: InputMaybe<Scalars['Int']>;
  side_not?: InputMaybe<Scalars['Int']>;
  side_not_in?: InputMaybe<Array<Scalars['Int']>>;
  sizeDelta?: InputMaybe<Scalars['BigDecimal']>;
  sizeDelta_gt?: InputMaybe<Scalars['BigDecimal']>;
  sizeDelta_gte?: InputMaybe<Scalars['BigDecimal']>;
  sizeDelta_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  sizeDelta_lt?: InputMaybe<Scalars['BigDecimal']>;
  sizeDelta_lte?: InputMaybe<Scalars['BigDecimal']>;
  sizeDelta_not?: InputMaybe<Scalars['BigDecimal']>;
  sizeDelta_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  status?: InputMaybe<Scalars['String']>;
  status_contains?: InputMaybe<Scalars['String']>;
  status_contains_nocase?: InputMaybe<Scalars['String']>;
  status_ends_with?: InputMaybe<Scalars['String']>;
  status_ends_with_nocase?: InputMaybe<Scalars['String']>;
  status_gt?: InputMaybe<Scalars['String']>;
  status_gte?: InputMaybe<Scalars['String']>;
  status_in?: InputMaybe<Array<Scalars['String']>>;
  status_lt?: InputMaybe<Scalars['String']>;
  status_lte?: InputMaybe<Scalars['String']>;
  status_not?: InputMaybe<Scalars['String']>;
  status_not_contains?: InputMaybe<Scalars['String']>;
  status_not_contains_nocase?: InputMaybe<Scalars['String']>;
  status_not_ends_with?: InputMaybe<Scalars['String']>;
  status_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  status_not_in?: InputMaybe<Array<Scalars['String']>>;
  status_not_starts_with?: InputMaybe<Scalars['String']>;
  status_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  status_starts_with?: InputMaybe<Scalars['String']>;
  status_starts_with_nocase?: InputMaybe<Scalars['String']>;
  triggerAbove?: InputMaybe<Scalars['Boolean']>;
  triggerAbove_in?: InputMaybe<Array<Scalars['Boolean']>>;
  triggerAbove_not?: InputMaybe<Scalars['Boolean']>;
  triggerAbove_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  triggerMarketPrice?: InputMaybe<Scalars['BigDecimal']>;
  triggerMarketPriceX96?: InputMaybe<Scalars['BigInt']>;
  triggerMarketPriceX96_gt?: InputMaybe<Scalars['BigInt']>;
  triggerMarketPriceX96_gte?: InputMaybe<Scalars['BigInt']>;
  triggerMarketPriceX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  triggerMarketPriceX96_lt?: InputMaybe<Scalars['BigInt']>;
  triggerMarketPriceX96_lte?: InputMaybe<Scalars['BigInt']>;
  triggerMarketPriceX96_not?: InputMaybe<Scalars['BigInt']>;
  triggerMarketPriceX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  triggerMarketPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  triggerMarketPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  triggerMarketPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  triggerMarketPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  triggerMarketPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  triggerMarketPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  triggerMarketPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  type?: InputMaybe<OrderType>;
  type_in?: InputMaybe<Array<OrderType>>;
  type_not?: InputMaybe<OrderType>;
  type_not_in?: InputMaybe<Array<OrderType>>;
  updatedHash?: InputMaybe<Scalars['Bytes']>;
  updatedHash_contains?: InputMaybe<Scalars['Bytes']>;
  updatedHash_gt?: InputMaybe<Scalars['Bytes']>;
  updatedHash_gte?: InputMaybe<Scalars['Bytes']>;
  updatedHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  updatedHash_lt?: InputMaybe<Scalars['Bytes']>;
  updatedHash_lte?: InputMaybe<Scalars['Bytes']>;
  updatedHash_not?: InputMaybe<Scalars['Bytes']>;
  updatedHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  updatedHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  updatedTimestamp?: InputMaybe<Scalars['BigInt']>;
  updatedTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  updatedTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  updatedTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  updatedTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  updatedTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  updatedTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  updatedTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum Order_OrderBy {
  AcceptableTradePrice = 'acceptableTradePrice',
  AcceptableTradePriceX96 = 'acceptableTradePriceX96',
  Account = 'account',
  CancelledHash = 'cancelledHash',
  CancelledTimestamp = 'cancelledTimestamp',
  CreatedHash = 'createdHash',
  CreatedTimestamp = 'createdTimestamp',
  ExecutedHash = 'executedHash',
  ExecutedTimestamp = 'executedTimestamp',
  Id = 'id',
  Index = 'index',
  LastOperationTimestamp = 'lastOperationTimestamp',
  MarginDelta = 'marginDelta',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  Receiver = 'receiver',
  Side = 'side',
  SizeDelta = 'sizeDelta',
  Status = 'status',
  TriggerAbove = 'triggerAbove',
  TriggerMarketPrice = 'triggerMarketPrice',
  TriggerMarketPriceX96 = 'triggerMarketPriceX96',
  Type = 'type',
  UpdatedHash = 'updatedHash',
  UpdatedTimestamp = 'updatedTimestamp'
}

export type Position = {
  __typename?: 'Position';
  /** the owner of the position */
  account: Scalars['Bytes'];
  /** the entry block of the position */
  entryBlockNumber: Scalars['BigInt'];
  /** the entry price of the position */
  entryPrice: Scalars['BigDecimal'];
  /** the entry price of the position, as a Q64.96 */
  entryPriceX96: Scalars['BigInt'];
  /** the entry time of the position */
  entryTime: Scalars['BigInt'];
  /** the entry tx hash of the position */
  entryTxHash: Scalars['Bytes'];
  /** accumulated funding fee of the position */
  fundingFee: Scalars['BigDecimal'];
  /** the snapshot of the funding rate growth at the time the position was opened/closed, as a Q96.96 */
  fundingRateGrowthX96: Scalars['BigInt'];
  /** format: <address>:<market address>:<side> */
  id: Scalars['ID'];
  /** the block of the last update for the position */
  lastBlockNumber: Scalars['BigInt'];
  /** the timestamp of the last update for the position */
  lastBlockTimestamp: Scalars['BigInt'];
  /** the ID of the last transaction history */
  lastTransactionHistory: TransactionHistory;
  /** the tx hash of the last update for the position */
  lastTxHash: Scalars['Bytes'];
  /** the liquidity of the position */
  liquidity: Scalars['BigDecimal'];
  /** the margin of the position */
  margin: Scalars['BigDecimal'];
  /** total decreased margin */
  marginDecreased: Scalars['BigDecimal'];
  /** total increased margin */
  marginIncreased: Scalars['BigDecimal'];
  /** pointer to market */
  market: Market;
  /** position history ID */
  positionHistory: PositionHistory;
  /** accumulated realized PnL of the position */
  realizedPnL: Scalars['BigDecimal'];
  /** the side of the position, 1 for long and 2 for short */
  side: Scalars['Int'];
  /** the size of the position */
  size: Scalars['BigDecimal'];
  /** accumulated trading fee of the position */
  tradingFee: Scalars['BigDecimal'];
  /** accumulated closing trading fee of the position */
  tradingFeeByClosing: Scalars['BigDecimal'];
};

export type PositionHistory = {
  __typename?: 'PositionHistory';
  /** the owner of the position */
  account: Scalars['Bytes'];
  /** the accumulated liquidity from closing of the position */
  closedLiquidity: Scalars['BigDecimal'];
  /** the accumulated size from closing of the position */
  closedSize: Scalars['BigDecimal'];
  /** the timestamp of the last closing of the position */
  closedTime: Scalars['BigInt'];
  /** the entry price of the position, as a Q64.96 */
  entryPrice: Scalars['BigDecimal'];
  /** the entry time of the position */
  entryTime: Scalars['BigInt'];
  /** the entry tx hash of the position */
  entryTxHash: Scalars['Bytes'];
  /** accumulated funding fee of the position */
  fundingFee: Scalars['BigDecimal'];
  /** format: <transaction hash>:<log index> */
  id: Scalars['ID'];
  /** the liquidation fee of the position */
  liquidationFee: Scalars['BigDecimal'];
  /** the liquidation price of the position */
  liquidationPrice: Scalars['BigDecimal'];
  /** the liquidation size of the position */
  liquidationSize: Scalars['BigDecimal'];
  /** position is closed by the liquidator */
  liquidatorClosed: Scalars['Boolean'];
  /** the margin of the position */
  margin: Scalars['BigDecimal'];
  /** pointer to market */
  market: Market;
  /** pointer to position */
  position: Position;
  /** accumulated realized PnL of the position */
  realizedPnL: Scalars['BigDecimal'];
  /** realized PnL rate of the position */
  realizedPnLRate: Scalars['BigDecimal'];
  /** the side of the position, 1 for long and 2 for short */
  side: Scalars['Int'];
  /** the size of the position */
  size: Scalars['BigDecimal'];
  /** stats of the position history, enum{Closed,PartiallyClosed,Liquidated} */
  status: PositionHistoryStatus;
};

export enum PositionHistoryStatus {
  Closed = 'Closed',
  DecreaseMargin = 'DecreaseMargin',
  DecreasePosition = 'DecreasePosition',
  IncreaseMargin = 'IncreaseMargin',
  IncreasePosition = 'IncreasePosition',
  Liquidated = 'Liquidated',
  Opened = 'Opened',
  PartialClosed = 'PartialClosed'
}

export type PositionHistory_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['Bytes']>;
  account_contains?: InputMaybe<Scalars['Bytes']>;
  account_gt?: InputMaybe<Scalars['Bytes']>;
  account_gte?: InputMaybe<Scalars['Bytes']>;
  account_in?: InputMaybe<Array<Scalars['Bytes']>>;
  account_lt?: InputMaybe<Scalars['Bytes']>;
  account_lte?: InputMaybe<Scalars['Bytes']>;
  account_not?: InputMaybe<Scalars['Bytes']>;
  account_not_contains?: InputMaybe<Scalars['Bytes']>;
  account_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  and?: InputMaybe<Array<InputMaybe<PositionHistory_Filter>>>;
  closedLiquidity?: InputMaybe<Scalars['BigDecimal']>;
  closedLiquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  closedLiquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  closedLiquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  closedLiquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  closedLiquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  closedLiquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  closedLiquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  closedSize?: InputMaybe<Scalars['BigDecimal']>;
  closedSize_gt?: InputMaybe<Scalars['BigDecimal']>;
  closedSize_gte?: InputMaybe<Scalars['BigDecimal']>;
  closedSize_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  closedSize_lt?: InputMaybe<Scalars['BigDecimal']>;
  closedSize_lte?: InputMaybe<Scalars['BigDecimal']>;
  closedSize_not?: InputMaybe<Scalars['BigDecimal']>;
  closedSize_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  closedTime?: InputMaybe<Scalars['BigInt']>;
  closedTime_gt?: InputMaybe<Scalars['BigInt']>;
  closedTime_gte?: InputMaybe<Scalars['BigInt']>;
  closedTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  closedTime_lt?: InputMaybe<Scalars['BigInt']>;
  closedTime_lte?: InputMaybe<Scalars['BigInt']>;
  closedTime_not?: InputMaybe<Scalars['BigInt']>;
  closedTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryPrice?: InputMaybe<Scalars['BigDecimal']>;
  entryPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  entryPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  entryPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  entryPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  entryPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  entryPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  entryPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  entryTime?: InputMaybe<Scalars['BigInt']>;
  entryTime_gt?: InputMaybe<Scalars['BigInt']>;
  entryTime_gte?: InputMaybe<Scalars['BigInt']>;
  entryTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryTime_lt?: InputMaybe<Scalars['BigInt']>;
  entryTime_lte?: InputMaybe<Scalars['BigInt']>;
  entryTime_not?: InputMaybe<Scalars['BigInt']>;
  entryTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryTxHash?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_contains?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_gt?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_gte?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  entryTxHash_lt?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_lte?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_not?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  fundingFee?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fundingFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_not?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  liquidationFee?: InputMaybe<Scalars['BigDecimal']>;
  liquidationFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidationFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidationFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidationFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidationFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidationFee_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidationFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidationPrice?: InputMaybe<Scalars['BigDecimal']>;
  liquidationPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidationPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidationPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidationPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidationPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidationPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidationPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidationSize?: InputMaybe<Scalars['BigDecimal']>;
  liquidationSize_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidationSize_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidationSize_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidationSize_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidationSize_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidationSize_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidationSize_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidatorClosed?: InputMaybe<Scalars['Boolean']>;
  liquidatorClosed_in?: InputMaybe<Array<Scalars['Boolean']>>;
  liquidatorClosed_not?: InputMaybe<Scalars['Boolean']>;
  liquidatorClosed_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  margin?: InputMaybe<Scalars['BigDecimal']>;
  margin_gt?: InputMaybe<Scalars['BigDecimal']>;
  margin_gte?: InputMaybe<Scalars['BigDecimal']>;
  margin_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin_lt?: InputMaybe<Scalars['BigDecimal']>;
  margin_lte?: InputMaybe<Scalars['BigDecimal']>;
  margin_not?: InputMaybe<Scalars['BigDecimal']>;
  margin_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<PositionHistory_Filter>>>;
  position?: InputMaybe<Scalars['String']>;
  position_?: InputMaybe<Position_Filter>;
  position_contains?: InputMaybe<Scalars['String']>;
  position_contains_nocase?: InputMaybe<Scalars['String']>;
  position_ends_with?: InputMaybe<Scalars['String']>;
  position_ends_with_nocase?: InputMaybe<Scalars['String']>;
  position_gt?: InputMaybe<Scalars['String']>;
  position_gte?: InputMaybe<Scalars['String']>;
  position_in?: InputMaybe<Array<Scalars['String']>>;
  position_lt?: InputMaybe<Scalars['String']>;
  position_lte?: InputMaybe<Scalars['String']>;
  position_not?: InputMaybe<Scalars['String']>;
  position_not_contains?: InputMaybe<Scalars['String']>;
  position_not_contains_nocase?: InputMaybe<Scalars['String']>;
  position_not_ends_with?: InputMaybe<Scalars['String']>;
  position_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  position_not_in?: InputMaybe<Array<Scalars['String']>>;
  position_not_starts_with?: InputMaybe<Scalars['String']>;
  position_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  position_starts_with?: InputMaybe<Scalars['String']>;
  position_starts_with_nocase?: InputMaybe<Scalars['String']>;
  realizedPnL?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnLRate?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnLRate_gt?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnLRate_gte?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnLRate_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  realizedPnLRate_lt?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnLRate_lte?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnLRate_not?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnLRate_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  realizedPnL_gt?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_gte?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  realizedPnL_lt?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_lte?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_not?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  side?: InputMaybe<Scalars['Int']>;
  side_gt?: InputMaybe<Scalars['Int']>;
  side_gte?: InputMaybe<Scalars['Int']>;
  side_in?: InputMaybe<Array<Scalars['Int']>>;
  side_lt?: InputMaybe<Scalars['Int']>;
  side_lte?: InputMaybe<Scalars['Int']>;
  side_not?: InputMaybe<Scalars['Int']>;
  side_not_in?: InputMaybe<Array<Scalars['Int']>>;
  size?: InputMaybe<Scalars['BigDecimal']>;
  size_gt?: InputMaybe<Scalars['BigDecimal']>;
  size_gte?: InputMaybe<Scalars['BigDecimal']>;
  size_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  size_lt?: InputMaybe<Scalars['BigDecimal']>;
  size_lte?: InputMaybe<Scalars['BigDecimal']>;
  size_not?: InputMaybe<Scalars['BigDecimal']>;
  size_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  status?: InputMaybe<PositionHistoryStatus>;
  status_in?: InputMaybe<Array<PositionHistoryStatus>>;
  status_not?: InputMaybe<PositionHistoryStatus>;
  status_not_in?: InputMaybe<Array<PositionHistoryStatus>>;
};

export enum PositionHistory_OrderBy {
  Account = 'account',
  ClosedLiquidity = 'closedLiquidity',
  ClosedSize = 'closedSize',
  ClosedTime = 'closedTime',
  EntryPrice = 'entryPrice',
  EntryTime = 'entryTime',
  EntryTxHash = 'entryTxHash',
  FundingFee = 'fundingFee',
  Id = 'id',
  LiquidationFee = 'liquidationFee',
  LiquidationPrice = 'liquidationPrice',
  LiquidationSize = 'liquidationSize',
  LiquidatorClosed = 'liquidatorClosed',
  Margin = 'margin',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  Position = 'position',
  PositionAccount = 'position__account',
  PositionEntryBlockNumber = 'position__entryBlockNumber',
  PositionEntryPrice = 'position__entryPrice',
  PositionEntryPriceX96 = 'position__entryPriceX96',
  PositionEntryTime = 'position__entryTime',
  PositionEntryTxHash = 'position__entryTxHash',
  PositionFundingFee = 'position__fundingFee',
  PositionFundingRateGrowthX96 = 'position__fundingRateGrowthX96',
  PositionId = 'position__id',
  PositionLastBlockNumber = 'position__lastBlockNumber',
  PositionLastBlockTimestamp = 'position__lastBlockTimestamp',
  PositionLastTxHash = 'position__lastTxHash',
  PositionLiquidity = 'position__liquidity',
  PositionMargin = 'position__margin',
  PositionMarginDecreased = 'position__marginDecreased',
  PositionMarginIncreased = 'position__marginIncreased',
  PositionRealizedPnL = 'position__realizedPnL',
  PositionSide = 'position__side',
  PositionSize = 'position__size',
  PositionTradingFee = 'position__tradingFee',
  PositionTradingFeeByClosing = 'position__tradingFeeByClosing',
  RealizedPnL = 'realizedPnL',
  RealizedPnLRate = 'realizedPnLRate',
  Side = 'side',
  Size = 'size',
  Status = 'status'
}

/** entity that represents the position router configuration */
export type PositionRouterConfig = {
  __typename?: 'PositionRouterConfig';
  id: Scalars['ID'];
  /** minimum fee user must pay for executor to carry out their requests */
  minExecutionFee: Scalars['BigInt'];
};

export type PositionRouterConfig_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PositionRouterConfig_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  minExecutionFee?: InputMaybe<Scalars['BigInt']>;
  minExecutionFee_gt?: InputMaybe<Scalars['BigInt']>;
  minExecutionFee_gte?: InputMaybe<Scalars['BigInt']>;
  minExecutionFee_in?: InputMaybe<Array<Scalars['BigInt']>>;
  minExecutionFee_lt?: InputMaybe<Scalars['BigInt']>;
  minExecutionFee_lte?: InputMaybe<Scalars['BigInt']>;
  minExecutionFee_not?: InputMaybe<Scalars['BigInt']>;
  minExecutionFee_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  or?: InputMaybe<Array<InputMaybe<PositionRouterConfig_Filter>>>;
};

export enum PositionRouterConfig_OrderBy {
  Id = 'id',
  MinExecutionFee = 'minExecutionFee'
}

export type Position_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['Bytes']>;
  account_contains?: InputMaybe<Scalars['Bytes']>;
  account_gt?: InputMaybe<Scalars['Bytes']>;
  account_gte?: InputMaybe<Scalars['Bytes']>;
  account_in?: InputMaybe<Array<Scalars['Bytes']>>;
  account_lt?: InputMaybe<Scalars['Bytes']>;
  account_lte?: InputMaybe<Scalars['Bytes']>;
  account_not?: InputMaybe<Scalars['Bytes']>;
  account_not_contains?: InputMaybe<Scalars['Bytes']>;
  account_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  and?: InputMaybe<Array<InputMaybe<Position_Filter>>>;
  entryBlockNumber?: InputMaybe<Scalars['BigInt']>;
  entryBlockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  entryBlockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  entryBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryBlockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  entryBlockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  entryBlockNumber_not?: InputMaybe<Scalars['BigInt']>;
  entryBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryPrice?: InputMaybe<Scalars['BigDecimal']>;
  entryPriceX96?: InputMaybe<Scalars['BigInt']>;
  entryPriceX96_gt?: InputMaybe<Scalars['BigInt']>;
  entryPriceX96_gte?: InputMaybe<Scalars['BigInt']>;
  entryPriceX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryPriceX96_lt?: InputMaybe<Scalars['BigInt']>;
  entryPriceX96_lte?: InputMaybe<Scalars['BigInt']>;
  entryPriceX96_not?: InputMaybe<Scalars['BigInt']>;
  entryPriceX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  entryPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  entryPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  entryPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  entryPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  entryPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  entryPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  entryTime?: InputMaybe<Scalars['BigInt']>;
  entryTime_gt?: InputMaybe<Scalars['BigInt']>;
  entryTime_gte?: InputMaybe<Scalars['BigInt']>;
  entryTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryTime_lt?: InputMaybe<Scalars['BigInt']>;
  entryTime_lte?: InputMaybe<Scalars['BigInt']>;
  entryTime_not?: InputMaybe<Scalars['BigInt']>;
  entryTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  entryTxHash?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_contains?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_gt?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_gte?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  entryTxHash_lt?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_lte?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_not?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  entryTxHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  fundingFee?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fundingFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_not?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fundingRateGrowthX96?: InputMaybe<Scalars['BigInt']>;
  fundingRateGrowthX96_gt?: InputMaybe<Scalars['BigInt']>;
  fundingRateGrowthX96_gte?: InputMaybe<Scalars['BigInt']>;
  fundingRateGrowthX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  fundingRateGrowthX96_lt?: InputMaybe<Scalars['BigInt']>;
  fundingRateGrowthX96_lte?: InputMaybe<Scalars['BigInt']>;
  fundingRateGrowthX96_not?: InputMaybe<Scalars['BigInt']>;
  fundingRateGrowthX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  lastBlockNumber?: InputMaybe<Scalars['BigInt']>;
  lastBlockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  lastBlockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  lastBlockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  lastBlockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  lastBlockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  lastBlockNumber_not?: InputMaybe<Scalars['BigInt']>;
  lastBlockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  lastBlockTimestamp?: InputMaybe<Scalars['BigInt']>;
  lastBlockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  lastBlockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  lastBlockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  lastBlockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  lastBlockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  lastBlockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  lastBlockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  lastTransactionHistory?: InputMaybe<Scalars['String']>;
  lastTransactionHistory_?: InputMaybe<TransactionHistory_Filter>;
  lastTransactionHistory_contains?: InputMaybe<Scalars['String']>;
  lastTransactionHistory_contains_nocase?: InputMaybe<Scalars['String']>;
  lastTransactionHistory_ends_with?: InputMaybe<Scalars['String']>;
  lastTransactionHistory_ends_with_nocase?: InputMaybe<Scalars['String']>;
  lastTransactionHistory_gt?: InputMaybe<Scalars['String']>;
  lastTransactionHistory_gte?: InputMaybe<Scalars['String']>;
  lastTransactionHistory_in?: InputMaybe<Array<Scalars['String']>>;
  lastTransactionHistory_lt?: InputMaybe<Scalars['String']>;
  lastTransactionHistory_lte?: InputMaybe<Scalars['String']>;
  lastTransactionHistory_not?: InputMaybe<Scalars['String']>;
  lastTransactionHistory_not_contains?: InputMaybe<Scalars['String']>;
  lastTransactionHistory_not_contains_nocase?: InputMaybe<Scalars['String']>;
  lastTransactionHistory_not_ends_with?: InputMaybe<Scalars['String']>;
  lastTransactionHistory_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  lastTransactionHistory_not_in?: InputMaybe<Array<Scalars['String']>>;
  lastTransactionHistory_not_starts_with?: InputMaybe<Scalars['String']>;
  lastTransactionHistory_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  lastTransactionHistory_starts_with?: InputMaybe<Scalars['String']>;
  lastTransactionHistory_starts_with_nocase?: InputMaybe<Scalars['String']>;
  lastTxHash?: InputMaybe<Scalars['Bytes']>;
  lastTxHash_contains?: InputMaybe<Scalars['Bytes']>;
  lastTxHash_gt?: InputMaybe<Scalars['Bytes']>;
  lastTxHash_gte?: InputMaybe<Scalars['Bytes']>;
  lastTxHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  lastTxHash_lt?: InputMaybe<Scalars['Bytes']>;
  lastTxHash_lte?: InputMaybe<Scalars['Bytes']>;
  lastTxHash_not?: InputMaybe<Scalars['Bytes']>;
  lastTxHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  lastTxHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  liquidity?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin?: InputMaybe<Scalars['BigDecimal']>;
  marginDecreased?: InputMaybe<Scalars['BigDecimal']>;
  marginDecreased_gt?: InputMaybe<Scalars['BigDecimal']>;
  marginDecreased_gte?: InputMaybe<Scalars['BigDecimal']>;
  marginDecreased_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  marginDecreased_lt?: InputMaybe<Scalars['BigDecimal']>;
  marginDecreased_lte?: InputMaybe<Scalars['BigDecimal']>;
  marginDecreased_not?: InputMaybe<Scalars['BigDecimal']>;
  marginDecreased_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  marginIncreased?: InputMaybe<Scalars['BigDecimal']>;
  marginIncreased_gt?: InputMaybe<Scalars['BigDecimal']>;
  marginIncreased_gte?: InputMaybe<Scalars['BigDecimal']>;
  marginIncreased_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  marginIncreased_lt?: InputMaybe<Scalars['BigDecimal']>;
  marginIncreased_lte?: InputMaybe<Scalars['BigDecimal']>;
  marginIncreased_not?: InputMaybe<Scalars['BigDecimal']>;
  marginIncreased_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin_gt?: InputMaybe<Scalars['BigDecimal']>;
  margin_gte?: InputMaybe<Scalars['BigDecimal']>;
  margin_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  margin_lt?: InputMaybe<Scalars['BigDecimal']>;
  margin_lte?: InputMaybe<Scalars['BigDecimal']>;
  margin_not?: InputMaybe<Scalars['BigDecimal']>;
  margin_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<Position_Filter>>>;
  positionHistory?: InputMaybe<Scalars['String']>;
  positionHistory_?: InputMaybe<PositionHistory_Filter>;
  positionHistory_contains?: InputMaybe<Scalars['String']>;
  positionHistory_contains_nocase?: InputMaybe<Scalars['String']>;
  positionHistory_ends_with?: InputMaybe<Scalars['String']>;
  positionHistory_ends_with_nocase?: InputMaybe<Scalars['String']>;
  positionHistory_gt?: InputMaybe<Scalars['String']>;
  positionHistory_gte?: InputMaybe<Scalars['String']>;
  positionHistory_in?: InputMaybe<Array<Scalars['String']>>;
  positionHistory_lt?: InputMaybe<Scalars['String']>;
  positionHistory_lte?: InputMaybe<Scalars['String']>;
  positionHistory_not?: InputMaybe<Scalars['String']>;
  positionHistory_not_contains?: InputMaybe<Scalars['String']>;
  positionHistory_not_contains_nocase?: InputMaybe<Scalars['String']>;
  positionHistory_not_ends_with?: InputMaybe<Scalars['String']>;
  positionHistory_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  positionHistory_not_in?: InputMaybe<Array<Scalars['String']>>;
  positionHistory_not_starts_with?: InputMaybe<Scalars['String']>;
  positionHistory_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  positionHistory_starts_with?: InputMaybe<Scalars['String']>;
  positionHistory_starts_with_nocase?: InputMaybe<Scalars['String']>;
  realizedPnL?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_gt?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_gte?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  realizedPnL_lt?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_lte?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_not?: InputMaybe<Scalars['BigDecimal']>;
  realizedPnL_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  side?: InputMaybe<Scalars['Int']>;
  side_gt?: InputMaybe<Scalars['Int']>;
  side_gte?: InputMaybe<Scalars['Int']>;
  side_in?: InputMaybe<Array<Scalars['Int']>>;
  side_lt?: InputMaybe<Scalars['Int']>;
  side_lte?: InputMaybe<Scalars['Int']>;
  side_not?: InputMaybe<Scalars['Int']>;
  side_not_in?: InputMaybe<Array<Scalars['Int']>>;
  size?: InputMaybe<Scalars['BigDecimal']>;
  size_gt?: InputMaybe<Scalars['BigDecimal']>;
  size_gte?: InputMaybe<Scalars['BigDecimal']>;
  size_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  size_lt?: InputMaybe<Scalars['BigDecimal']>;
  size_lte?: InputMaybe<Scalars['BigDecimal']>;
  size_not?: InputMaybe<Scalars['BigDecimal']>;
  size_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  tradingFee?: InputMaybe<Scalars['BigDecimal']>;
  tradingFeeByClosing?: InputMaybe<Scalars['BigDecimal']>;
  tradingFeeByClosing_gt?: InputMaybe<Scalars['BigDecimal']>;
  tradingFeeByClosing_gte?: InputMaybe<Scalars['BigDecimal']>;
  tradingFeeByClosing_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  tradingFeeByClosing_lt?: InputMaybe<Scalars['BigDecimal']>;
  tradingFeeByClosing_lte?: InputMaybe<Scalars['BigDecimal']>;
  tradingFeeByClosing_not?: InputMaybe<Scalars['BigDecimal']>;
  tradingFeeByClosing_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  tradingFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  tradingFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  tradingFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  tradingFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  tradingFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  tradingFee_not?: InputMaybe<Scalars['BigDecimal']>;
  tradingFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum Position_OrderBy {
  Account = 'account',
  EntryBlockNumber = 'entryBlockNumber',
  EntryPrice = 'entryPrice',
  EntryPriceX96 = 'entryPriceX96',
  EntryTime = 'entryTime',
  EntryTxHash = 'entryTxHash',
  FundingFee = 'fundingFee',
  FundingRateGrowthX96 = 'fundingRateGrowthX96',
  Id = 'id',
  LastBlockNumber = 'lastBlockNumber',
  LastBlockTimestamp = 'lastBlockTimestamp',
  LastTransactionHistory = 'lastTransactionHistory',
  LastTransactionHistoryAccount = 'lastTransactionHistory__account',
  LastTransactionHistoryBlockNumber = 'lastTransactionHistory__blockNumber',
  LastTransactionHistoryBlockTimestamp = 'lastTransactionHistory__blockTimestamp',
  LastTransactionHistoryData = 'lastTransactionHistory__data',
  LastTransactionHistoryFundingFee = 'lastTransactionHistory__fundingFee',
  LastTransactionHistoryId = 'lastTransactionHistory__id',
  LastTransactionHistoryOperation = 'lastTransactionHistory__operation',
  LastTransactionHistorySide = 'lastTransactionHistory__side',
  LastTransactionHistoryTxHash = 'lastTransactionHistory__txHash',
  LastTransactionHistoryType = 'lastTransactionHistory__type',
  LastTxHash = 'lastTxHash',
  Liquidity = 'liquidity',
  Margin = 'margin',
  MarginDecreased = 'marginDecreased',
  MarginIncreased = 'marginIncreased',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  PositionHistory = 'positionHistory',
  PositionHistoryAccount = 'positionHistory__account',
  PositionHistoryClosedLiquidity = 'positionHistory__closedLiquidity',
  PositionHistoryClosedSize = 'positionHistory__closedSize',
  PositionHistoryClosedTime = 'positionHistory__closedTime',
  PositionHistoryEntryPrice = 'positionHistory__entryPrice',
  PositionHistoryEntryTime = 'positionHistory__entryTime',
  PositionHistoryEntryTxHash = 'positionHistory__entryTxHash',
  PositionHistoryFundingFee = 'positionHistory__fundingFee',
  PositionHistoryId = 'positionHistory__id',
  PositionHistoryLiquidationFee = 'positionHistory__liquidationFee',
  PositionHistoryLiquidationPrice = 'positionHistory__liquidationPrice',
  PositionHistoryLiquidationSize = 'positionHistory__liquidationSize',
  PositionHistoryLiquidatorClosed = 'positionHistory__liquidatorClosed',
  PositionHistoryMargin = 'positionHistory__margin',
  PositionHistoryRealizedPnL = 'positionHistory__realizedPnL',
  PositionHistoryRealizedPnLRate = 'positionHistory__realizedPnLRate',
  PositionHistorySide = 'positionHistory__side',
  PositionHistorySize = 'positionHistory__size',
  PositionHistoryStatus = 'positionHistory__status',
  RealizedPnL = 'realizedPnL',
  Side = 'side',
  Size = 'size',
  TradingFee = 'tradingFee',
  TradingFeeByClosing = 'tradingFeeByClosing'
}

export type PriceInfo = {
  __typename?: 'PriceInfo';
  /** market address */
  id: Scalars['Bytes'];
  /** token maximum price */
  maxPrice: Scalars['BigDecimal'];
  /** token maximum price, as a Q64.96 */
  maxPriceX96: Scalars['BigInt'];
  /** token minimum price */
  minPrice: Scalars['BigDecimal'];
  /** token minimum price, as a Q64.96 */
  minPriceX96: Scalars['BigInt'];
  /** token price */
  price: Scalars['BigDecimal'];
  /** pointer to price state */
  priceState: PriceState;
  /** token price, as a Q64.96 */
  priceX96: Scalars['BigInt'];
};

export type PriceInfo_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PriceInfo_Filter>>>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  maxPrice?: InputMaybe<Scalars['BigDecimal']>;
  maxPriceX96?: InputMaybe<Scalars['BigInt']>;
  maxPriceX96_gt?: InputMaybe<Scalars['BigInt']>;
  maxPriceX96_gte?: InputMaybe<Scalars['BigInt']>;
  maxPriceX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maxPriceX96_lt?: InputMaybe<Scalars['BigInt']>;
  maxPriceX96_lte?: InputMaybe<Scalars['BigInt']>;
  maxPriceX96_not?: InputMaybe<Scalars['BigInt']>;
  maxPriceX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  maxPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  maxPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  maxPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  maxPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  maxPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  maxPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  maxPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  minPrice?: InputMaybe<Scalars['BigDecimal']>;
  minPriceX96?: InputMaybe<Scalars['BigInt']>;
  minPriceX96_gt?: InputMaybe<Scalars['BigInt']>;
  minPriceX96_gte?: InputMaybe<Scalars['BigInt']>;
  minPriceX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  minPriceX96_lt?: InputMaybe<Scalars['BigInt']>;
  minPriceX96_lte?: InputMaybe<Scalars['BigInt']>;
  minPriceX96_not?: InputMaybe<Scalars['BigInt']>;
  minPriceX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  minPrice_gt?: InputMaybe<Scalars['BigDecimal']>;
  minPrice_gte?: InputMaybe<Scalars['BigDecimal']>;
  minPrice_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  minPrice_lt?: InputMaybe<Scalars['BigDecimal']>;
  minPrice_lte?: InputMaybe<Scalars['BigDecimal']>;
  minPrice_not?: InputMaybe<Scalars['BigDecimal']>;
  minPrice_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<PriceInfo_Filter>>>;
  price?: InputMaybe<Scalars['BigDecimal']>;
  priceState?: InputMaybe<Scalars['String']>;
  priceState_?: InputMaybe<PriceState_Filter>;
  priceState_contains?: InputMaybe<Scalars['String']>;
  priceState_contains_nocase?: InputMaybe<Scalars['String']>;
  priceState_ends_with?: InputMaybe<Scalars['String']>;
  priceState_ends_with_nocase?: InputMaybe<Scalars['String']>;
  priceState_gt?: InputMaybe<Scalars['String']>;
  priceState_gte?: InputMaybe<Scalars['String']>;
  priceState_in?: InputMaybe<Array<Scalars['String']>>;
  priceState_lt?: InputMaybe<Scalars['String']>;
  priceState_lte?: InputMaybe<Scalars['String']>;
  priceState_not?: InputMaybe<Scalars['String']>;
  priceState_not_contains?: InputMaybe<Scalars['String']>;
  priceState_not_contains_nocase?: InputMaybe<Scalars['String']>;
  priceState_not_ends_with?: InputMaybe<Scalars['String']>;
  priceState_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  priceState_not_in?: InputMaybe<Array<Scalars['String']>>;
  priceState_not_starts_with?: InputMaybe<Scalars['String']>;
  priceState_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  priceState_starts_with?: InputMaybe<Scalars['String']>;
  priceState_starts_with_nocase?: InputMaybe<Scalars['String']>;
  priceX96?: InputMaybe<Scalars['BigInt']>;
  priceX96_gt?: InputMaybe<Scalars['BigInt']>;
  priceX96_gte?: InputMaybe<Scalars['BigInt']>;
  priceX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  priceX96_lt?: InputMaybe<Scalars['BigInt']>;
  priceX96_lte?: InputMaybe<Scalars['BigInt']>;
  priceX96_not?: InputMaybe<Scalars['BigInt']>;
  priceX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  price_gt?: InputMaybe<Scalars['BigDecimal']>;
  price_gte?: InputMaybe<Scalars['BigDecimal']>;
  price_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  price_lt?: InputMaybe<Scalars['BigDecimal']>;
  price_lte?: InputMaybe<Scalars['BigDecimal']>;
  price_not?: InputMaybe<Scalars['BigDecimal']>;
  price_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum PriceInfo_OrderBy {
  Id = 'id',
  MaxPrice = 'maxPrice',
  MaxPriceX96 = 'maxPriceX96',
  MinPrice = 'minPrice',
  MinPriceX96 = 'minPriceX96',
  Price = 'price',
  PriceState = 'priceState',
  PriceStateBasisIndexPriceX96 = 'priceState__basisIndexPriceX96',
  PriceStateId = 'priceState__id',
  PriceStateIndexPriceUsedX96 = 'priceState__indexPriceUsedX96',
  PriceStatePendingVertexIndex = 'priceState__pendingVertexIndex',
  PriceStatePendingVertexIndexBitmap = 'priceState__pendingVertexIndexBitmap',
  PriceStatePremiumRateX96 = 'priceState__premiumRateX96',
  PriceX96 = 'priceX96'
}

export type PriceState = {
  __typename?: 'PriceState';
  /** the basis index price, as a Q64.96 */
  basisIndexPriceX96: Scalars['BigInt'];
  /** market address */
  id: Scalars['Bytes'];
  /** the index price used */
  indexPriceUsedX96: Scalars['BigInt'];
  /** the net sizes of the liquidation buffer */
  liquidationBufferNetSizes?: Maybe<Array<Scalars['BigDecimal']>>;
  /** pointer to market */
  market: Market;
  /** the index used to track the pending update of the price vertex */
  pendingVertexIndex: Scalars['Int'];
  /** the bitmap used to calculate pending vertex index */
  pendingVertexIndexBitmap: Scalars['Int'];
  /** the premium rate during the last position adjustment by the trader, as a Q32.96 */
  premiumRateX96: Scalars['BigInt'];
  /** pointer to price info */
  priceInfo: PriceInfo;
  /** the price vertices used to determine the price curve */
  priceVertices: Array<PriceVertex>;
};


export type PriceStatePriceVerticesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PriceVertex_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PriceVertex_Filter>;
};

export type PriceState1H = {
  __typename?: 'PriceState1H';
  /** the balance rate, denominated in a bip (i.e. 1e-8) */
  balanceRate: Scalars['BigDecimal'];
  /** format: <market address>:<truncate timestamp> */
  id: Scalars['ID'];
  /** the size of the net position held by all LPs in the liquidation buffer */
  liquidationBufferNetSize: Scalars['BigDecimal'];
  /** total liquidity held by all LPs */
  liquidity: Scalars['BigDecimal'];
  /** pointer to market */
  market: Market;
  /** the size of the net position held by all LPs */
  netSize: Scalars['BigDecimal'];
  /** the side of the LP position, 1 for long and 2 for short */
  side: Scalars['Int'];
  /** the start time of the price state */
  ts: Scalars['BigInt'];
  /** the number of transactions */
  txCount: Scalars['BigInt'];
};

export type PriceState1H_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PriceState1H_Filter>>>;
  balanceRate?: InputMaybe<Scalars['BigDecimal']>;
  balanceRate_gt?: InputMaybe<Scalars['BigDecimal']>;
  balanceRate_gte?: InputMaybe<Scalars['BigDecimal']>;
  balanceRate_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  balanceRate_lt?: InputMaybe<Scalars['BigDecimal']>;
  balanceRate_lte?: InputMaybe<Scalars['BigDecimal']>;
  balanceRate_not?: InputMaybe<Scalars['BigDecimal']>;
  balanceRate_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  liquidationBufferNetSize?: InputMaybe<Scalars['BigDecimal']>;
  liquidationBufferNetSize_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidationBufferNetSize_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidationBufferNetSize_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidationBufferNetSize_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidationBufferNetSize_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidationBufferNetSize_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidationBufferNetSize_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  netSize?: InputMaybe<Scalars['BigDecimal']>;
  netSize_gt?: InputMaybe<Scalars['BigDecimal']>;
  netSize_gte?: InputMaybe<Scalars['BigDecimal']>;
  netSize_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  netSize_lt?: InputMaybe<Scalars['BigDecimal']>;
  netSize_lte?: InputMaybe<Scalars['BigDecimal']>;
  netSize_not?: InputMaybe<Scalars['BigDecimal']>;
  netSize_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<PriceState1H_Filter>>>;
  side?: InputMaybe<Scalars['Int']>;
  side_gt?: InputMaybe<Scalars['Int']>;
  side_gte?: InputMaybe<Scalars['Int']>;
  side_in?: InputMaybe<Array<Scalars['Int']>>;
  side_lt?: InputMaybe<Scalars['Int']>;
  side_lte?: InputMaybe<Scalars['Int']>;
  side_not?: InputMaybe<Scalars['Int']>;
  side_not_in?: InputMaybe<Array<Scalars['Int']>>;
  ts?: InputMaybe<Scalars['BigInt']>;
  ts_gt?: InputMaybe<Scalars['BigInt']>;
  ts_gte?: InputMaybe<Scalars['BigInt']>;
  ts_in?: InputMaybe<Array<Scalars['BigInt']>>;
  ts_lt?: InputMaybe<Scalars['BigInt']>;
  ts_lte?: InputMaybe<Scalars['BigInt']>;
  ts_not?: InputMaybe<Scalars['BigInt']>;
  ts_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txCount?: InputMaybe<Scalars['BigInt']>;
  txCount_gt?: InputMaybe<Scalars['BigInt']>;
  txCount_gte?: InputMaybe<Scalars['BigInt']>;
  txCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txCount_lt?: InputMaybe<Scalars['BigInt']>;
  txCount_lte?: InputMaybe<Scalars['BigInt']>;
  txCount_not?: InputMaybe<Scalars['BigInt']>;
  txCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum PriceState1H_OrderBy {
  BalanceRate = 'balanceRate',
  Id = 'id',
  LiquidationBufferNetSize = 'liquidationBufferNetSize',
  Liquidity = 'liquidity',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  NetSize = 'netSize',
  Side = 'side',
  Ts = 'ts',
  TxCount = 'txCount'
}

export type PriceState_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PriceState_Filter>>>;
  basisIndexPriceX96?: InputMaybe<Scalars['BigInt']>;
  basisIndexPriceX96_gt?: InputMaybe<Scalars['BigInt']>;
  basisIndexPriceX96_gte?: InputMaybe<Scalars['BigInt']>;
  basisIndexPriceX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  basisIndexPriceX96_lt?: InputMaybe<Scalars['BigInt']>;
  basisIndexPriceX96_lte?: InputMaybe<Scalars['BigInt']>;
  basisIndexPriceX96_not?: InputMaybe<Scalars['BigInt']>;
  basisIndexPriceX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  indexPriceUsedX96?: InputMaybe<Scalars['BigInt']>;
  indexPriceUsedX96_gt?: InputMaybe<Scalars['BigInt']>;
  indexPriceUsedX96_gte?: InputMaybe<Scalars['BigInt']>;
  indexPriceUsedX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  indexPriceUsedX96_lt?: InputMaybe<Scalars['BigInt']>;
  indexPriceUsedX96_lte?: InputMaybe<Scalars['BigInt']>;
  indexPriceUsedX96_not?: InputMaybe<Scalars['BigInt']>;
  indexPriceUsedX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  liquidationBufferNetSizes?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidationBufferNetSizes_contains?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidationBufferNetSizes_contains_nocase?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidationBufferNetSizes_not?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidationBufferNetSizes_not_contains?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidationBufferNetSizes_not_contains_nocase?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<PriceState_Filter>>>;
  pendingVertexIndex?: InputMaybe<Scalars['Int']>;
  pendingVertexIndexBitmap?: InputMaybe<Scalars['Int']>;
  pendingVertexIndexBitmap_gt?: InputMaybe<Scalars['Int']>;
  pendingVertexIndexBitmap_gte?: InputMaybe<Scalars['Int']>;
  pendingVertexIndexBitmap_in?: InputMaybe<Array<Scalars['Int']>>;
  pendingVertexIndexBitmap_lt?: InputMaybe<Scalars['Int']>;
  pendingVertexIndexBitmap_lte?: InputMaybe<Scalars['Int']>;
  pendingVertexIndexBitmap_not?: InputMaybe<Scalars['Int']>;
  pendingVertexIndexBitmap_not_in?: InputMaybe<Array<Scalars['Int']>>;
  pendingVertexIndex_gt?: InputMaybe<Scalars['Int']>;
  pendingVertexIndex_gte?: InputMaybe<Scalars['Int']>;
  pendingVertexIndex_in?: InputMaybe<Array<Scalars['Int']>>;
  pendingVertexIndex_lt?: InputMaybe<Scalars['Int']>;
  pendingVertexIndex_lte?: InputMaybe<Scalars['Int']>;
  pendingVertexIndex_not?: InputMaybe<Scalars['Int']>;
  pendingVertexIndex_not_in?: InputMaybe<Array<Scalars['Int']>>;
  premiumRateX96?: InputMaybe<Scalars['BigInt']>;
  premiumRateX96_gt?: InputMaybe<Scalars['BigInt']>;
  premiumRateX96_gte?: InputMaybe<Scalars['BigInt']>;
  premiumRateX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  premiumRateX96_lt?: InputMaybe<Scalars['BigInt']>;
  premiumRateX96_lte?: InputMaybe<Scalars['BigInt']>;
  premiumRateX96_not?: InputMaybe<Scalars['BigInt']>;
  premiumRateX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  priceInfo?: InputMaybe<Scalars['String']>;
  priceInfo_?: InputMaybe<PriceInfo_Filter>;
  priceInfo_contains?: InputMaybe<Scalars['String']>;
  priceInfo_contains_nocase?: InputMaybe<Scalars['String']>;
  priceInfo_ends_with?: InputMaybe<Scalars['String']>;
  priceInfo_ends_with_nocase?: InputMaybe<Scalars['String']>;
  priceInfo_gt?: InputMaybe<Scalars['String']>;
  priceInfo_gte?: InputMaybe<Scalars['String']>;
  priceInfo_in?: InputMaybe<Array<Scalars['String']>>;
  priceInfo_lt?: InputMaybe<Scalars['String']>;
  priceInfo_lte?: InputMaybe<Scalars['String']>;
  priceInfo_not?: InputMaybe<Scalars['String']>;
  priceInfo_not_contains?: InputMaybe<Scalars['String']>;
  priceInfo_not_contains_nocase?: InputMaybe<Scalars['String']>;
  priceInfo_not_ends_with?: InputMaybe<Scalars['String']>;
  priceInfo_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  priceInfo_not_in?: InputMaybe<Array<Scalars['String']>>;
  priceInfo_not_starts_with?: InputMaybe<Scalars['String']>;
  priceInfo_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  priceInfo_starts_with?: InputMaybe<Scalars['String']>;
  priceInfo_starts_with_nocase?: InputMaybe<Scalars['String']>;
  priceVertices_?: InputMaybe<PriceVertex_Filter>;
};

export enum PriceState_OrderBy {
  BasisIndexPriceX96 = 'basisIndexPriceX96',
  Id = 'id',
  IndexPriceUsedX96 = 'indexPriceUsedX96',
  LiquidationBufferNetSizes = 'liquidationBufferNetSizes',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  PendingVertexIndex = 'pendingVertexIndex',
  PendingVertexIndexBitmap = 'pendingVertexIndexBitmap',
  PremiumRateX96 = 'premiumRateX96',
  PriceInfo = 'priceInfo',
  PriceInfoId = 'priceInfo__id',
  PriceInfoMaxPrice = 'priceInfo__maxPrice',
  PriceInfoMaxPriceX96 = 'priceInfo__maxPriceX96',
  PriceInfoMinPrice = 'priceInfo__minPrice',
  PriceInfoMinPriceX96 = 'priceInfo__minPriceX96',
  PriceInfoPrice = 'priceInfo__price',
  PriceInfoPriceX96 = 'priceInfo__priceX96',
  PriceVertices = 'priceVertices'
}

export type PriceVertex = {
  __typename?: 'PriceVertex';
  /** format: <market address>:<index> */
  id: Scalars['ID'];
  /** the premium rate during the last position adjustment by the LP, as a Q32.96 */
  premiumRateX96: Scalars['BigInt'];
  /** pointer to price state */
  priceState: PriceState;
  /** size required to achieve the current premium rate */
  size: Scalars['BigDecimal'];
};

export type PriceVertex_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PriceVertex_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<PriceVertex_Filter>>>;
  premiumRateX96?: InputMaybe<Scalars['BigInt']>;
  premiumRateX96_gt?: InputMaybe<Scalars['BigInt']>;
  premiumRateX96_gte?: InputMaybe<Scalars['BigInt']>;
  premiumRateX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  premiumRateX96_lt?: InputMaybe<Scalars['BigInt']>;
  premiumRateX96_lte?: InputMaybe<Scalars['BigInt']>;
  premiumRateX96_not?: InputMaybe<Scalars['BigInt']>;
  premiumRateX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  priceState?: InputMaybe<Scalars['String']>;
  priceState_?: InputMaybe<PriceState_Filter>;
  priceState_contains?: InputMaybe<Scalars['String']>;
  priceState_contains_nocase?: InputMaybe<Scalars['String']>;
  priceState_ends_with?: InputMaybe<Scalars['String']>;
  priceState_ends_with_nocase?: InputMaybe<Scalars['String']>;
  priceState_gt?: InputMaybe<Scalars['String']>;
  priceState_gte?: InputMaybe<Scalars['String']>;
  priceState_in?: InputMaybe<Array<Scalars['String']>>;
  priceState_lt?: InputMaybe<Scalars['String']>;
  priceState_lte?: InputMaybe<Scalars['String']>;
  priceState_not?: InputMaybe<Scalars['String']>;
  priceState_not_contains?: InputMaybe<Scalars['String']>;
  priceState_not_contains_nocase?: InputMaybe<Scalars['String']>;
  priceState_not_ends_with?: InputMaybe<Scalars['String']>;
  priceState_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  priceState_not_in?: InputMaybe<Array<Scalars['String']>>;
  priceState_not_starts_with?: InputMaybe<Scalars['String']>;
  priceState_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  priceState_starts_with?: InputMaybe<Scalars['String']>;
  priceState_starts_with_nocase?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['BigDecimal']>;
  size_gt?: InputMaybe<Scalars['BigDecimal']>;
  size_gte?: InputMaybe<Scalars['BigDecimal']>;
  size_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  size_lt?: InputMaybe<Scalars['BigDecimal']>;
  size_lte?: InputMaybe<Scalars['BigDecimal']>;
  size_not?: InputMaybe<Scalars['BigDecimal']>;
  size_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum PriceVertex_OrderBy {
  Id = 'id',
  PremiumRateX96 = 'premiumRateX96',
  PriceState = 'priceState',
  PriceStateBasisIndexPriceX96 = 'priceState__basisIndexPriceX96',
  PriceStateId = 'priceState__id',
  PriceStateIndexPriceUsedX96 = 'priceState__indexPriceUsedX96',
  PriceStatePendingVertexIndex = 'priceState__pendingVertexIndex',
  PriceStatePendingVertexIndexBitmap = 'priceState__pendingVertexIndexBitmap',
  PriceStatePremiumRateX96 = 'priceState__premiumRateX96',
  Size = 'size'
}

export type Query = {
  __typename?: 'Query';
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  candle1D?: Maybe<Candle1D>;
  candle1Ds: Array<Candle1D>;
  candle1H?: Maybe<Candle1H>;
  candle1Hs: Array<Candle1H>;
  candle1M?: Maybe<Candle1M>;
  candle1Ms: Array<Candle1M>;
  candle4H?: Maybe<Candle4H>;
  candle4Hs: Array<Candle4H>;
  candle5M?: Maybe<Candle5M>;
  candle5Ms: Array<Candle5M>;
  candle15M?: Maybe<Candle15M>;
  candle15Ms: Array<Candle15M>;
  candle30M?: Maybe<Candle30M>;
  candle30Ms: Array<Candle30M>;
  decreaseLiquidityPositionRequest?: Maybe<DecreaseLiquidityPositionRequest>;
  decreaseLiquidityPositionRequests: Array<DecreaseLiquidityPositionRequest>;
  decreasePositionRequest?: Maybe<DecreasePositionRequest>;
  decreasePositionRequests: Array<DecreasePositionRequest>;
  fundingRateHistories: Array<FundingRateHistory>;
  fundingRateHistory?: Maybe<FundingRateHistory>;
  globalFundingRateSample?: Maybe<GlobalFundingRateSample>;
  globalFundingRateSamples: Array<GlobalFundingRateSample>;
  globalLiquidationFund?: Maybe<GlobalLiquidationFund>;
  globalLiquidationFunds: Array<GlobalLiquidationFund>;
  globalLiquidityPosition?: Maybe<GlobalLiquidityPosition>;
  globalLiquidityPositions: Array<GlobalLiquidityPosition>;
  globalPosition?: Maybe<GlobalPosition>;
  globalPositions: Array<GlobalPosition>;
  increaseLiquidityPositionRequest?: Maybe<IncreaseLiquidityPositionRequest>;
  increaseLiquidityPositionRequests: Array<IncreaseLiquidityPositionRequest>;
  increasePositionRequest?: Maybe<IncreasePositionRequest>;
  increasePositionRequests: Array<IncreasePositionRequest>;
  liquidationFundPosition?: Maybe<LiquidationFundPosition>;
  liquidationFundPositions: Array<LiquidationFundPosition>;
  liquidityPosition?: Maybe<LiquidityPosition>;
  liquidityPositionHistories: Array<LiquidityPositionHistory>;
  liquidityPositionHistory?: Maybe<LiquidityPositionHistory>;
  liquidityPositions: Array<LiquidityPosition>;
  market?: Maybe<Market>;
  marketBaseConfig?: Maybe<MarketBaseConfig>;
  marketBaseConfigs: Array<MarketBaseConfig>;
  marketFeeRateConfig?: Maybe<MarketFeeRateConfig>;
  marketFeeRateConfigs: Array<MarketFeeRateConfig>;
  marketPriceConfig?: Maybe<MarketPriceConfig>;
  marketPriceConfigs: Array<MarketPriceConfig>;
  markets: Array<Market>;
  order?: Maybe<Order>;
  orderBookConfig?: Maybe<OrderBookConfig>;
  orderBookConfigs: Array<OrderBookConfig>;
  orders: Array<Order>;
  position?: Maybe<Position>;
  positionHistories: Array<PositionHistory>;
  positionHistory?: Maybe<PositionHistory>;
  positionRouterConfig?: Maybe<PositionRouterConfig>;
  positionRouterConfigs: Array<PositionRouterConfig>;
  positions: Array<Position>;
  priceInfo?: Maybe<PriceInfo>;
  priceInfos: Array<PriceInfo>;
  priceState?: Maybe<PriceState>;
  priceState1H?: Maybe<PriceState1H>;
  priceState1Hs: Array<PriceState1H>;
  priceStates: Array<PriceState>;
  priceVertex?: Maybe<PriceVertex>;
  priceVertexes: Array<PriceVertex>;
  transactionHistories: Array<TransactionHistory>;
  transactionHistory?: Maybe<TransactionHistory>;
  vertexConfig?: Maybe<VertexConfig>;
  vertexConfigs: Array<VertexConfig>;
};


export type Query_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};


export type QueryCandle1DArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCandle1DsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Candle1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle1D_Filter>;
};


export type QueryCandle1HArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCandle1HsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Candle1H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle1H_Filter>;
};


export type QueryCandle1MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCandle1MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Candle1M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle1M_Filter>;
};


export type QueryCandle4HArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCandle4HsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Candle4H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle4H_Filter>;
};


export type QueryCandle5MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCandle5MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Candle5M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle5M_Filter>;
};


export type QueryCandle15MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCandle15MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Candle15M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle15M_Filter>;
};


export type QueryCandle30MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryCandle30MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Candle30M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle30M_Filter>;
};


export type QueryDecreaseLiquidityPositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryDecreaseLiquidityPositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DecreaseLiquidityPositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DecreaseLiquidityPositionRequest_Filter>;
};


export type QueryDecreasePositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryDecreasePositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DecreasePositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DecreasePositionRequest_Filter>;
};


export type QueryFundingRateHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FundingRateHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<FundingRateHistory_Filter>;
};


export type QueryFundingRateHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryGlobalFundingRateSampleArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryGlobalFundingRateSamplesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GlobalFundingRateSample_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalFundingRateSample_Filter>;
};


export type QueryGlobalLiquidationFundArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryGlobalLiquidationFundsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GlobalLiquidationFund_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalLiquidationFund_Filter>;
};


export type QueryGlobalLiquidityPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryGlobalLiquidityPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GlobalLiquidityPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalLiquidityPosition_Filter>;
};


export type QueryGlobalPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryGlobalPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GlobalPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalPosition_Filter>;
};


export type QueryIncreaseLiquidityPositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryIncreaseLiquidityPositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<IncreaseLiquidityPositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<IncreaseLiquidityPositionRequest_Filter>;
};


export type QueryIncreasePositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryIncreasePositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<IncreasePositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<IncreasePositionRequest_Filter>;
};


export type QueryLiquidationFundPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryLiquidationFundPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LiquidationFundPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidationFundPosition_Filter>;
};


export type QueryLiquidityPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryLiquidityPositionHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LiquidityPositionHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidityPositionHistory_Filter>;
};


export type QueryLiquidityPositionHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryLiquidityPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LiquidityPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidityPosition_Filter>;
};


export type QueryMarketArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryMarketBaseConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryMarketBaseConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MarketBaseConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MarketBaseConfig_Filter>;
};


export type QueryMarketFeeRateConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryMarketFeeRateConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MarketFeeRateConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MarketFeeRateConfig_Filter>;
};


export type QueryMarketPriceConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryMarketPriceConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MarketPriceConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MarketPriceConfig_Filter>;
};


export type QueryMarketsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Market_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Market_Filter>;
};


export type QueryOrderArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryOrderBookConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryOrderBookConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBookConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OrderBookConfig_Filter>;
};


export type QueryOrdersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Order_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Order_Filter>;
};


export type QueryPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPositionHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PositionHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PositionHistory_Filter>;
};


export type QueryPositionHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPositionRouterConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPositionRouterConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PositionRouterConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PositionRouterConfig_Filter>;
};


export type QueryPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Position_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Position_Filter>;
};


export type QueryPriceInfoArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPriceInfosArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PriceInfo_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PriceInfo_Filter>;
};


export type QueryPriceStateArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPriceState1HArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPriceState1HsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PriceState1H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PriceState1H_Filter>;
};


export type QueryPriceStatesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PriceState_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PriceState_Filter>;
};


export type QueryPriceVertexArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPriceVertexesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PriceVertex_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PriceVertex_Filter>;
};


export type QueryTransactionHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TransactionHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TransactionHistory_Filter>;
};


export type QueryTransactionHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryVertexConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryVertexConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<VertexConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<VertexConfig_Filter>;
};

export enum Status {
  Cancelled = 'Cancelled',
  Created = 'Created',
  Executed = 'Executed'
}

export type Subscription = {
  __typename?: 'Subscription';
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  candle1D?: Maybe<Candle1D>;
  candle1Ds: Array<Candle1D>;
  candle1H?: Maybe<Candle1H>;
  candle1Hs: Array<Candle1H>;
  candle1M?: Maybe<Candle1M>;
  candle1Ms: Array<Candle1M>;
  candle4H?: Maybe<Candle4H>;
  candle4Hs: Array<Candle4H>;
  candle5M?: Maybe<Candle5M>;
  candle5Ms: Array<Candle5M>;
  candle15M?: Maybe<Candle15M>;
  candle15Ms: Array<Candle15M>;
  candle30M?: Maybe<Candle30M>;
  candle30Ms: Array<Candle30M>;
  decreaseLiquidityPositionRequest?: Maybe<DecreaseLiquidityPositionRequest>;
  decreaseLiquidityPositionRequests: Array<DecreaseLiquidityPositionRequest>;
  decreasePositionRequest?: Maybe<DecreasePositionRequest>;
  decreasePositionRequests: Array<DecreasePositionRequest>;
  fundingRateHistories: Array<FundingRateHistory>;
  fundingRateHistory?: Maybe<FundingRateHistory>;
  globalFundingRateSample?: Maybe<GlobalFundingRateSample>;
  globalFundingRateSamples: Array<GlobalFundingRateSample>;
  globalLiquidationFund?: Maybe<GlobalLiquidationFund>;
  globalLiquidationFunds: Array<GlobalLiquidationFund>;
  globalLiquidityPosition?: Maybe<GlobalLiquidityPosition>;
  globalLiquidityPositions: Array<GlobalLiquidityPosition>;
  globalPosition?: Maybe<GlobalPosition>;
  globalPositions: Array<GlobalPosition>;
  increaseLiquidityPositionRequest?: Maybe<IncreaseLiquidityPositionRequest>;
  increaseLiquidityPositionRequests: Array<IncreaseLiquidityPositionRequest>;
  increasePositionRequest?: Maybe<IncreasePositionRequest>;
  increasePositionRequests: Array<IncreasePositionRequest>;
  liquidationFundPosition?: Maybe<LiquidationFundPosition>;
  liquidationFundPositions: Array<LiquidationFundPosition>;
  liquidityPosition?: Maybe<LiquidityPosition>;
  liquidityPositionHistories: Array<LiquidityPositionHistory>;
  liquidityPositionHistory?: Maybe<LiquidityPositionHistory>;
  liquidityPositions: Array<LiquidityPosition>;
  market?: Maybe<Market>;
  marketBaseConfig?: Maybe<MarketBaseConfig>;
  marketBaseConfigs: Array<MarketBaseConfig>;
  marketFeeRateConfig?: Maybe<MarketFeeRateConfig>;
  marketFeeRateConfigs: Array<MarketFeeRateConfig>;
  marketPriceConfig?: Maybe<MarketPriceConfig>;
  marketPriceConfigs: Array<MarketPriceConfig>;
  markets: Array<Market>;
  order?: Maybe<Order>;
  orderBookConfig?: Maybe<OrderBookConfig>;
  orderBookConfigs: Array<OrderBookConfig>;
  orders: Array<Order>;
  position?: Maybe<Position>;
  positionHistories: Array<PositionHistory>;
  positionHistory?: Maybe<PositionHistory>;
  positionRouterConfig?: Maybe<PositionRouterConfig>;
  positionRouterConfigs: Array<PositionRouterConfig>;
  positions: Array<Position>;
  priceInfo?: Maybe<PriceInfo>;
  priceInfos: Array<PriceInfo>;
  priceState?: Maybe<PriceState>;
  priceState1H?: Maybe<PriceState1H>;
  priceState1Hs: Array<PriceState1H>;
  priceStates: Array<PriceState>;
  priceVertex?: Maybe<PriceVertex>;
  priceVertexes: Array<PriceVertex>;
  transactionHistories: Array<TransactionHistory>;
  transactionHistory?: Maybe<TransactionHistory>;
  vertexConfig?: Maybe<VertexConfig>;
  vertexConfigs: Array<VertexConfig>;
};


export type Subscription_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};


export type SubscriptionCandle1DArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCandle1DsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Candle1D_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle1D_Filter>;
};


export type SubscriptionCandle1HArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCandle1HsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Candle1H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle1H_Filter>;
};


export type SubscriptionCandle1MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCandle1MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Candle1M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle1M_Filter>;
};


export type SubscriptionCandle4HArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCandle4HsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Candle4H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle4H_Filter>;
};


export type SubscriptionCandle5MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCandle5MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Candle5M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle5M_Filter>;
};


export type SubscriptionCandle15MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCandle15MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Candle15M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle15M_Filter>;
};


export type SubscriptionCandle30MArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionCandle30MsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Candle30M_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Candle30M_Filter>;
};


export type SubscriptionDecreaseLiquidityPositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionDecreaseLiquidityPositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DecreaseLiquidityPositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DecreaseLiquidityPositionRequest_Filter>;
};


export type SubscriptionDecreasePositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionDecreasePositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DecreasePositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DecreasePositionRequest_Filter>;
};


export type SubscriptionFundingRateHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FundingRateHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<FundingRateHistory_Filter>;
};


export type SubscriptionFundingRateHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionGlobalFundingRateSampleArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionGlobalFundingRateSamplesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GlobalFundingRateSample_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalFundingRateSample_Filter>;
};


export type SubscriptionGlobalLiquidationFundArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionGlobalLiquidationFundsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GlobalLiquidationFund_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalLiquidationFund_Filter>;
};


export type SubscriptionGlobalLiquidityPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionGlobalLiquidityPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GlobalLiquidityPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalLiquidityPosition_Filter>;
};


export type SubscriptionGlobalPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionGlobalPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<GlobalPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<GlobalPosition_Filter>;
};


export type SubscriptionIncreaseLiquidityPositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionIncreaseLiquidityPositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<IncreaseLiquidityPositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<IncreaseLiquidityPositionRequest_Filter>;
};


export type SubscriptionIncreasePositionRequestArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionIncreasePositionRequestsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<IncreasePositionRequest_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<IncreasePositionRequest_Filter>;
};


export type SubscriptionLiquidationFundPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionLiquidationFundPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LiquidationFundPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidationFundPosition_Filter>;
};


export type SubscriptionLiquidityPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionLiquidityPositionHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LiquidityPositionHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidityPositionHistory_Filter>;
};


export type SubscriptionLiquidityPositionHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionLiquidityPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LiquidityPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidityPosition_Filter>;
};


export type SubscriptionMarketArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionMarketBaseConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionMarketBaseConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MarketBaseConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MarketBaseConfig_Filter>;
};


export type SubscriptionMarketFeeRateConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionMarketFeeRateConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MarketFeeRateConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MarketFeeRateConfig_Filter>;
};


export type SubscriptionMarketPriceConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionMarketPriceConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MarketPriceConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MarketPriceConfig_Filter>;
};


export type SubscriptionMarketsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Market_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Market_Filter>;
};


export type SubscriptionOrderArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionOrderBookConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionOrderBookConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBookConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OrderBookConfig_Filter>;
};


export type SubscriptionOrdersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Order_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Order_Filter>;
};


export type SubscriptionPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPositionHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PositionHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PositionHistory_Filter>;
};


export type SubscriptionPositionHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPositionRouterConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPositionRouterConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PositionRouterConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PositionRouterConfig_Filter>;
};


export type SubscriptionPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Position_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Position_Filter>;
};


export type SubscriptionPriceInfoArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPriceInfosArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PriceInfo_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PriceInfo_Filter>;
};


export type SubscriptionPriceStateArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPriceState1HArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPriceState1HsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PriceState1H_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PriceState1H_Filter>;
};


export type SubscriptionPriceStatesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PriceState_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PriceState_Filter>;
};


export type SubscriptionPriceVertexArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPriceVertexesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PriceVertex_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PriceVertex_Filter>;
};


export type SubscriptionTransactionHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TransactionHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TransactionHistory_Filter>;
};


export type SubscriptionTransactionHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionVertexConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionVertexConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<VertexConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<VertexConfig_Filter>;
};

/** entity that represents a transaction history */
export type TransactionHistory = {
  __typename?: 'TransactionHistory';
  /** the owner of the transaction history */
  account: Scalars['Bytes'];
  /** block which transaction was included in */
  blockNumber: Scalars['BigInt'];
  /** timestamp of the confirmed transaction */
  blockTimestamp: Scalars['BigInt'];
  /** JSON-formatted trade data */
  data: Scalars['String'];
  /** the funding fee */
  fundingFee: Scalars['BigDecimal'];
  /** format: <transaction hash>:<log index> */
  id: Scalars['ID'];
  /** pointer to market */
  market: Market;
  /**
   * operation of the transaction history.
   * enum{Open,Close,AdjustMargin,Liquidate,GovUseLiquidationFund,
   * OpenPosition,IncreaseMargin,IncreasePosition,DecreasePosition,ClosePosition,DecreaseMargin,
   * CreateIncreaseOrder,UpdateIncreaseOrder,CancelIncreaseOrder,ExecuteIncreaseOrder,
   * CreateDecreaseOrder,UpdateDecreaseOrder,CancelDecreaseOrder,ExecuteDecreaseOrder,
   * CreateIncreaseLiquidityPosition,CancelIncreaseLiquidityPosition,
   * CreateDecreaseLiquidityPosition,CancelDecreaseLiquidityPosition,
   * CreateIncreasePosition,CancelIncreasePosition,
   * CreateDecreasePosition,CancelDecreasePosition}
   */
  operation: TransactionHistoryOperation;
  /** the side of the position, 1 for long and 2 for short */
  side: Scalars['Int'];
  /** tx hash of the confirmed transaction */
  txHash: Scalars['Bytes'];
  /** type of the transaction history, enum{Liquidity,Position,Orderbook,PositionRouter} */
  type: TransactionHistoryType;
};

export enum TransactionHistoryOperation {
  CancelDecreaseLiquidityPosition = 'CancelDecreaseLiquidityPosition',
  CancelDecreaseOrder = 'CancelDecreaseOrder',
  CancelDecreasePosition = 'CancelDecreasePosition',
  CancelIncreaseLiquidityPosition = 'CancelIncreaseLiquidityPosition',
  CancelIncreaseOrder = 'CancelIncreaseOrder',
  CancelIncreasePosition = 'CancelIncreasePosition',
  ClosePosition = 'ClosePosition',
  CreateDecreaseLiquidityPosition = 'CreateDecreaseLiquidityPosition',
  CreateDecreaseOrder = 'CreateDecreaseOrder',
  CreateDecreasePosition = 'CreateDecreasePosition',
  CreateIncreaseLiquidityPosition = 'CreateIncreaseLiquidityPosition',
  CreateIncreaseOrder = 'CreateIncreaseOrder',
  CreateIncreasePosition = 'CreateIncreasePosition',
  DecreaseMargin = 'DecreaseMargin',
  DecreasePosition = 'DecreasePosition',
  ExecuteDecreaseOrder = 'ExecuteDecreaseOrder',
  ExecuteIncreaseOrder = 'ExecuteIncreaseOrder',
  IncreaseMargin = 'IncreaseMargin',
  IncreasePosition = 'IncreasePosition',
  IncreasedByLiquidation = 'IncreasedByLiquidation',
  Liquidate = 'Liquidate',
  LiquidityPositionLiquidated = 'LiquidityPositionLiquidated',
  OpenPosition = 'OpenPosition',
  UpdateDecreaseOrder = 'UpdateDecreaseOrder',
  UpdateIncreaseOrder = 'UpdateIncreaseOrder'
}

export enum TransactionHistoryType {
  LiquidationFund = 'LiquidationFund',
  Liquidity = 'Liquidity',
  Orderbook = 'Orderbook',
  Position = 'Position',
  PositionRouter = 'PositionRouter'
}

export type TransactionHistory_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['Bytes']>;
  account_contains?: InputMaybe<Scalars['Bytes']>;
  account_gt?: InputMaybe<Scalars['Bytes']>;
  account_gte?: InputMaybe<Scalars['Bytes']>;
  account_in?: InputMaybe<Array<Scalars['Bytes']>>;
  account_lt?: InputMaybe<Scalars['Bytes']>;
  account_lte?: InputMaybe<Scalars['Bytes']>;
  account_not?: InputMaybe<Scalars['Bytes']>;
  account_not_contains?: InputMaybe<Scalars['Bytes']>;
  account_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  and?: InputMaybe<Array<InputMaybe<TransactionHistory_Filter>>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  data?: InputMaybe<Scalars['String']>;
  data_contains?: InputMaybe<Scalars['String']>;
  data_contains_nocase?: InputMaybe<Scalars['String']>;
  data_ends_with?: InputMaybe<Scalars['String']>;
  data_ends_with_nocase?: InputMaybe<Scalars['String']>;
  data_gt?: InputMaybe<Scalars['String']>;
  data_gte?: InputMaybe<Scalars['String']>;
  data_in?: InputMaybe<Array<Scalars['String']>>;
  data_lt?: InputMaybe<Scalars['String']>;
  data_lte?: InputMaybe<Scalars['String']>;
  data_not?: InputMaybe<Scalars['String']>;
  data_not_contains?: InputMaybe<Scalars['String']>;
  data_not_contains_nocase?: InputMaybe<Scalars['String']>;
  data_not_ends_with?: InputMaybe<Scalars['String']>;
  data_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  data_not_in?: InputMaybe<Array<Scalars['String']>>;
  data_not_starts_with?: InputMaybe<Scalars['String']>;
  data_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  data_starts_with?: InputMaybe<Scalars['String']>;
  data_starts_with_nocase?: InputMaybe<Scalars['String']>;
  fundingFee?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  fundingFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_not?: InputMaybe<Scalars['BigDecimal']>;
  fundingFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  operation?: InputMaybe<TransactionHistoryOperation>;
  operation_in?: InputMaybe<Array<TransactionHistoryOperation>>;
  operation_not?: InputMaybe<TransactionHistoryOperation>;
  operation_not_in?: InputMaybe<Array<TransactionHistoryOperation>>;
  or?: InputMaybe<Array<InputMaybe<TransactionHistory_Filter>>>;
  side?: InputMaybe<Scalars['Int']>;
  side_gt?: InputMaybe<Scalars['Int']>;
  side_gte?: InputMaybe<Scalars['Int']>;
  side_in?: InputMaybe<Array<Scalars['Int']>>;
  side_lt?: InputMaybe<Scalars['Int']>;
  side_lte?: InputMaybe<Scalars['Int']>;
  side_not?: InputMaybe<Scalars['Int']>;
  side_not_in?: InputMaybe<Array<Scalars['Int']>>;
  txHash?: InputMaybe<Scalars['Bytes']>;
  txHash_contains?: InputMaybe<Scalars['Bytes']>;
  txHash_gt?: InputMaybe<Scalars['Bytes']>;
  txHash_gte?: InputMaybe<Scalars['Bytes']>;
  txHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  txHash_lt?: InputMaybe<Scalars['Bytes']>;
  txHash_lte?: InputMaybe<Scalars['Bytes']>;
  txHash_not?: InputMaybe<Scalars['Bytes']>;
  txHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  txHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  type?: InputMaybe<TransactionHistoryType>;
  type_in?: InputMaybe<Array<TransactionHistoryType>>;
  type_not?: InputMaybe<TransactionHistoryType>;
  type_not_in?: InputMaybe<Array<TransactionHistoryType>>;
};

export enum TransactionHistory_OrderBy {
  Account = 'account',
  BlockNumber = 'blockNumber',
  BlockTimestamp = 'blockTimestamp',
  Data = 'data',
  FundingFee = 'fundingFee',
  Id = 'id',
  Market = 'market',
  MarketId = 'market__id',
  MarketLiquidationFundGovUsed = 'market__liquidationFundGovUsed',
  MarketProtocolFee = 'market__protocolFee',
  MarketReferralFee = 'market__referralFee',
  MarketSymbol = 'market__symbol',
  MarketTotalProtocolFee = 'market__totalProtocolFee',
  MarketTotalReferralFee = 'market__totalReferralFee',
  MarketTradingFee = 'market__tradingFee',
  MarketVolume = 'market__volume',
  MarketVolumeUsd = 'market__volumeUSD',
  Operation = 'operation',
  Side = 'side',
  TxHash = 'txHash',
  Type = 'type'
}

export type VertexConfig = {
  __typename?: 'VertexConfig';
  /** the balance rate of the vertex, denominated in a bip (i.e. 1e-8) */
  balanceRate: Scalars['BigInt'];
  id: Scalars['ID'];
  /** the market price config */
  marketPriceConfig: MarketPriceConfig;
  /** the premium rate of the vertex, denominated in a bip (i.e. 1e-8) */
  premiumRate: Scalars['BigInt'];
};

export type VertexConfig_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<VertexConfig_Filter>>>;
  balanceRate?: InputMaybe<Scalars['BigInt']>;
  balanceRate_gt?: InputMaybe<Scalars['BigInt']>;
  balanceRate_gte?: InputMaybe<Scalars['BigInt']>;
  balanceRate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  balanceRate_lt?: InputMaybe<Scalars['BigInt']>;
  balanceRate_lte?: InputMaybe<Scalars['BigInt']>;
  balanceRate_not?: InputMaybe<Scalars['BigInt']>;
  balanceRate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  marketPriceConfig?: InputMaybe<Scalars['String']>;
  marketPriceConfig_?: InputMaybe<MarketPriceConfig_Filter>;
  marketPriceConfig_contains?: InputMaybe<Scalars['String']>;
  marketPriceConfig_contains_nocase?: InputMaybe<Scalars['String']>;
  marketPriceConfig_ends_with?: InputMaybe<Scalars['String']>;
  marketPriceConfig_ends_with_nocase?: InputMaybe<Scalars['String']>;
  marketPriceConfig_gt?: InputMaybe<Scalars['String']>;
  marketPriceConfig_gte?: InputMaybe<Scalars['String']>;
  marketPriceConfig_in?: InputMaybe<Array<Scalars['String']>>;
  marketPriceConfig_lt?: InputMaybe<Scalars['String']>;
  marketPriceConfig_lte?: InputMaybe<Scalars['String']>;
  marketPriceConfig_not?: InputMaybe<Scalars['String']>;
  marketPriceConfig_not_contains?: InputMaybe<Scalars['String']>;
  marketPriceConfig_not_contains_nocase?: InputMaybe<Scalars['String']>;
  marketPriceConfig_not_ends_with?: InputMaybe<Scalars['String']>;
  marketPriceConfig_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  marketPriceConfig_not_in?: InputMaybe<Array<Scalars['String']>>;
  marketPriceConfig_not_starts_with?: InputMaybe<Scalars['String']>;
  marketPriceConfig_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  marketPriceConfig_starts_with?: InputMaybe<Scalars['String']>;
  marketPriceConfig_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<VertexConfig_Filter>>>;
  premiumRate?: InputMaybe<Scalars['BigInt']>;
  premiumRate_gt?: InputMaybe<Scalars['BigInt']>;
  premiumRate_gte?: InputMaybe<Scalars['BigInt']>;
  premiumRate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  premiumRate_lt?: InputMaybe<Scalars['BigInt']>;
  premiumRate_lte?: InputMaybe<Scalars['BigInt']>;
  premiumRate_not?: InputMaybe<Scalars['BigInt']>;
  premiumRate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum VertexConfig_OrderBy {
  BalanceRate = 'balanceRate',
  Id = 'id',
  MarketPriceConfig = 'marketPriceConfig',
  MarketPriceConfigId = 'marketPriceConfig__id',
  MarketPriceConfigLiquidationVertexIndex = 'marketPriceConfig__liquidationVertexIndex',
  MarketPriceConfigMaxPriceImpactLiquidity = 'marketPriceConfig__maxPriceImpactLiquidity',
  PremiumRate = 'premiumRate'
}

export type _Block_ = {
  __typename?: '_Block_';
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>;
  /** The block number */
  number: Scalars['Int'];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: '_Meta_';
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean'];
};

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = 'allow',
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = 'deny'
}

export type PositionsListV2QueryVariables = Exact<{
  account_in?: InputMaybe<Array<Scalars['Bytes']> | Scalars['Bytes']>;
  number: Scalars['Int'];
}>;


export type PositionsListV2Query = { __typename?: 'Query', positions: Array<{ __typename?: 'Position', side: number, size: any, liquidity: any, entryTime: any, account: any, id: string, market: { __typename?: 'Market', id: any, symbol: string } }> };

export type MarketsQueryVariables = Exact<{
  block7d?: InputMaybe<Scalars['Int']>;
  block24h?: InputMaybe<Scalars['Int']>;
}>;


export type MarketsQuery = { __typename?: 'Query', dayPast: Array<{ __typename?: 'Market', id: any, volumeUSD: any, volume: any }>, past: Array<{ __typename?: 'Market', id: any, globalLiquidityPosition: { __typename?: 'GlobalLiquidityPosition', fee: any, fundingFee: any } }>, markets: Array<{ __typename?: 'Market', id: any, volumeUSD: any, volume: any, globalLiquidityPosition: { __typename?: 'GlobalLiquidityPosition', fee: any, fundingFee: any } }> };


export const PositionsListV2Document = gql`
    query PositionsListV2($account_in: [Bytes!], $number: Int!) {
  positions(
    block: {number: $number}
    where: {account_in: $account_in, size_gt: 0}
    orderBy: entryTime
    orderDirection: desc
    first: 1000
  ) {
    side
    size
    liquidity
    entryTime
    account
    market {
      id
      symbol
    }
    id
  }
}
    `;

/**
 * __usePositionsListV2Query__
 *
 * To run a query within a React component, call `usePositionsListV2Query` and pass it any options that fit your needs.
 * When your component renders, `usePositionsListV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionsListV2Query({
 *   variables: {
 *      account_in: // value for 'account_in'
 *      number: // value for 'number'
 *   },
 * });
 */
export function usePositionsListV2Query(baseOptions: Apollo.QueryHookOptions<PositionsListV2Query, PositionsListV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PositionsListV2Query, PositionsListV2QueryVariables>(PositionsListV2Document, options);
      }
export function usePositionsListV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PositionsListV2Query, PositionsListV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PositionsListV2Query, PositionsListV2QueryVariables>(PositionsListV2Document, options);
        }
export type PositionsListV2QueryHookResult = ReturnType<typeof usePositionsListV2Query>;
export type PositionsListV2LazyQueryHookResult = ReturnType<typeof usePositionsListV2LazyQuery>;
export type PositionsListV2QueryResult = Apollo.QueryResult<PositionsListV2Query, PositionsListV2QueryVariables>;
export const MarketsDocument = gql`
    query Markets($block7d: Int, $block24h: Int) {
  dayPast: markets(block: {number: $block24h}) {
    id
    volumeUSD
    volume
  }
  past: markets(block: {number: $block7d}) {
    id
    globalLiquidityPosition {
      fee
      fundingFee
    }
  }
  markets {
    id
    volumeUSD
    volume
    globalLiquidityPosition {
      fee
      fundingFee
    }
  }
}
    `;

/**
 * __useMarketsQuery__
 *
 * To run a query within a React component, call `useMarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketsQuery({
 *   variables: {
 *      block7d: // value for 'block7d'
 *      block24h: // value for 'block24h'
 *   },
 * });
 */
export function useMarketsQuery(baseOptions?: Apollo.QueryHookOptions<MarketsQuery, MarketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketsQuery, MarketsQueryVariables>(MarketsDocument, options);
      }
export function useMarketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketsQuery, MarketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketsQuery, MarketsQueryVariables>(MarketsDocument, options);
        }
export type MarketsQueryHookResult = ReturnType<typeof useMarketsQuery>;
export type MarketsLazyQueryHookResult = ReturnType<typeof useMarketsLazyQuery>;
export type MarketsQueryResult = Apollo.QueryResult<MarketsQuery, MarketsQueryVariables>;