import { useMemo } from 'react';

import { useAllTokens } from 'hooks/useAllTokens';
import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { chain, toNumber } from 'lodash';
import { ITradesHistoryItem } from 'types';
import { toCamelCase } from 'fetch/useRequest'
import { catchFn, plus } from 'utils';

import { useTradesHistoryLazyQuery } from './__generated_trade__/types-and-hooks';

export function useTradesHistoryLazyGraph() {
	const [fetch] = useTradesHistoryLazyQuery();
	return { fetch };
}

export function formatTradesHistoryV1(data: {
	trades: Array<ITradesHistoryItem>;
}) {
	const allTokens = useAllTokens();

	return useMemo(() => {
		if (!data) {
			return null;
		}
		const { trades } = data;
		const result: Array<ITradesHistoryItem> = [];
		trades.forEach((item: ITradesHistoryItem) => {
			const data = catchFn(() => {
				return JSON.parse(item.data as string);
			}, {});

			const realizedPnLTotal = plus(
				plus(data.realizedPnL, data.fundingFee),
				data.liquidationFee
			);
			const transationItem = {
				blockTimestamp: toNumber(item.blockTimestamp) * 1000,
				id: item.id,
				type: item.type,
				operation: item.operation,
				side: item.side,
				txHash: item.txHash,
				data,
				realizedPnLTotal,
				poolId: item.pool.id,
				baseSymbol: item.pool.token.symbol,
				decimal: item.pool.token.decimals,
				baseToken: allTokens.get(item.pool.token.id)
			} as unknown as ITradesHistoryItem;

			result.push(transationItem);
		});
		return chain(result)
			.sortBy(item => -item.blockTimestamp)
			.uniqBy('id')
			.value();
	}, [data]);
}

export function formatTradesHistoryV2(data: {
	transactionHistories: Array<ITradesHistoryItem>;
}) {
	const { pools } = useAppSelector(poolsBaseState);
	return useMemo(() => {
		if (!data) {
			return null;
		}
		const { transactionHistories } = data;
		const result: Array<ITradesHistoryItem> = [];
		transactionHistories.forEach((item: ITradesHistoryItem) => {
			const filteredPool = pools.filter(pool => {
				return pool.id.toLowerCase() === item.marketAddress.toLowerCase();
			});
			
			const baseToken = filteredPool.length > 0 ? filteredPool[0].baseToken : {};
			const data = catchFn(() => {
				return toCamelCase(JSON.parse(item.data as string));
			}, {});
			
			const realizedPnlTotal = plus(
				plus(data.realizedPnl, data.fundingFee),
				data.liquidationFee
			);
			
			const transationItem = {
				blockTimestamp: item.blockTimestamp,
				id: item.id,
				type: item.type,
				operation: item.operation,
				side: item.side,
				txHash: item.txHash,
				data,
				realizedPnlTotal,
				poolId: item.poolAddress,
				baseSymbol: baseToken.symbol,
				decimal: baseToken.decimals,
				baseToken: baseToken
			} as unknown as ITradesHistoryItem;

			result.push(transationItem);
		});
		return chain(result)
			.sortBy(item => -item.blockTimestamp)
			.uniqBy('id')
			.value();
	}, [data]);
}