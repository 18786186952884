import { createSelector } from '@reduxjs/toolkit';

import { plus } from 'utils';

import { txBaseState } from 'state/tx/slice';
import { selectClaimableLpStakeList } from '../stake/selector';
import { ClaimCalldata, Type_Earns } from 'pages/Earn/types';

export const selectClaimLpStakeCalldatas = createSelector(
	[txBaseState, selectClaimableLpStakeList],
	(txBaseState, claimableLpStakeList) => {
		const { quoteToken } = txBaseState;
		if (!claimableLpStakeList || claimableLpStakeList.length === 0) {
			return null;
		}
		const results = [] as Array<ClaimCalldata>;
		if (claimableLpStakeList) {
			const tokenIds = claimableLpStakeList.map(item => item.stakeID);
			const quoteAmount = claimableLpStakeList.reduce(
				(pre, cur) => plus(pre, cur.claimableReward),
				'0'
			);
			results.push({
				type: Type_Earns.StakingLp,
				ids: tokenIds,
				rewardToken: quoteToken,
				amount: quoteAmount
			});
		}
		return results;
	}
);

// 可领取的USDT奖励
export const selectClaimableLpStakeQuoteAmount = createSelector(
	[selectClaimLpStakeCalldatas],
	claimStakeCalldatas => {
		if (!claimStakeCalldatas) return '0';
		return claimStakeCalldatas.reduce((pre, cur) => plus(pre, cur.amount), '0');
	}
);