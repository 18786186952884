import { useApolloClients } from '../context/ApolloClientsContext';
import {useAccount} from 'wagmi';
import { useAccountInformationQuery } from './__generated_referral__/types-and-hooks';
import {useMemo} from 'react';

export default function  useAccountQuery(){
  const { clientReferral } = useApolloClients();
	const { address } = useAccount();

	const { data, ...restProps } = useAccountInformationQuery({
		variables: {
			account: address
		},
		skip: !address,
		client: clientReferral
	});

	return {
		data: data,
		...restProps
	}
}