import { useEffect, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Hash } from '@wagmi/core';
import { Transaction_Status, Version } from 'config/constants';
import { useApolloClients } from 'context/ApolloClientsContext';
import { useReferralClaimDetailsV2Request } from 'fetch/useRequest';
import { useAccount } from 'wagmi';

import _, { find, map, merge } from 'lodash';
import {
	Referral_Request_Reward_Type,
	Referral_Request_Type
} from 'pages/Referrals/types';
import { isGreaterThan, isPositive, plus } from 'utils';

import { codeCount } from '../pages/Referrals/Members';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { setMembersAddress } from '../state/referrals/slice';
import { settingBaseState } from '../state/setting/slice';
import { useMembersReferralsQuery } from './__generated_referral__/types-and-hooks';
import { useRefereesAddressGraph } from './useRefereesAddressGraph';
import { useReferralsRewardsGraph } from './useReferralsMembersRewardsGraph';

export interface IRefereeItem {
	id: string;
	txHash: Hash;
}

export interface IReferralCodeItem {
	id: string;
	txHash: Hash;
	referees: Array<IRefereeItem>;
	status?: Transaction_Status;
	blockTimestamp: string;
}

export interface IReferralFeeRewardItem {
	claimed: string;
	unclaimed: string;
}

export interface IMemberItem {
	id: string;
	account: string;
	mintTimestamp: string;
	mintTxHash: string;
	referralCodes: Array<IReferralCodeItem>;
	referralFeeReward: Array<IReferralFeeRewardItem>;
}

export function useMembersReferralsGraph() {
	const dispatch = useAppDispatch();
	const { clientReferral } = useApolloClients();
	const { address } = useAccount();
	const { currentVersion } = useAppSelector(settingBaseState);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [members, setMembers] = useState([]);
	const [allReferralCodes, setAllReferralCodes] =
		useState<Array<IReferralCodeItem> | null>(null);

	const { list: refereesAddressList, refetch: refetchRefereesAddress } =
		useRefereesAddressGraph(members);

	const {
		rewards,
		rewardsV2,
		EQURewards,
		refetch: refetchRewards
	} = useReferralsRewardsGraph(members);

	const {
		data,
		loading: isLoadingMembersReferrals,
		refetch
	} = useMembersReferralsQuery({
		variables: {
			account: address ? address.toLowerCase() : '',
			first: codeCount
		},
		skip: !address,
		client: clientReferral,
		notifyOnNetworkStatusChange: true
	});

	const {
		data: detailsList,
		poolData: poolPositionList,
		// isLoading: isLoadingDetails,
		refetch: refetchDetails
	} = useReferralClaimDetailsV2Request(
		address,
		Referral_Request_Type.MEMBER,
		currentVersion === Version.V1
			? ''
			: Referral_Request_Reward_Type.V2_REFERRAL_POSITION
	);

	const newRewardsEQUData = useMemo(() => {
		if (!detailsList) {
			return undefined;
		}
		return detailsList.map((item: any) => {
			const totalClaimableAmount = item.token_rewards.reduce(
				(sum, reward) => plus(sum, reward.claimable_amount),
				'0'
			);
			const totalAmount = item.token_rewards.reduce(
				(sum, reward) => plus(sum, reward.total_amount),
				'0'
			);
			return {
				id: item.token_id.toString(),
				equ_rewards: item.token_rewards,
				total_claimable_amount: totalClaimableAmount,
				total_amount: totalAmount
			};
		});
	}, [detailsList]);

	useUpdateEffect(() => {
		if (!isLoadingMembersReferrals) {
			setIsLoading(isLoadingMembersReferrals);
			dispatch(setMembersAddress(address));
		}
	}, [isLoadingMembersReferrals]);

	useUpdateEffect(() => {
		if (data?.referrers && data?.referrers.length > 0) {
			const membersArray = [] as Array<string>;
			data?.referrers.forEach((item: any) => {
				membersArray.push(item.id);
			});
			setMembers(membersArray);
		}
	}, [data, data?.referrers, address]);

	useEffect(() => {
		if (!data || !data?.referrers || !data?.referrers?.length) {
			return;
		}
		let allReferralCodes = [] as Array<IReferralCodeItem>;
		data.referrers.forEach(item => {
			item.referralCodes.forEach(innerItem => {
				allReferralCodes = allReferralCodes.concat(
					innerItem as unknown as Array<IReferralCodeItem>
				);
			});
		});
		setAllReferralCodes(allReferralCodes);
	}, [data]);

	const membersRewardList = useMemo(() => {
		if (!data || !data?.referrers || !data?.referrers?.length) {
			return undefined;
		}

		const feeRewardPools = _.map(data?.referrers, item => {
			const feePools = _.chain(item.referralFeeRewardDetails)
				.filter(item => isPositive(item?.unclaimed))
				.map(reward => reward.pool.id)
				.value();
			return { id: item.id, feePools };
		});

		const feeRewardPoolsV2 = _.map(data?.referrers, item => {
			const feePools = _.chain(item.referralFeeRewardDetailsV2)
				.filter(item => isPositive(item?.unclaimed))
				.map(reward => reward.market.id)
				.value();
			return { id: item.id, feePools };
		});

		const feeRewardMap = new Map();
		data?.referrers.forEach(item => {
			const { id } = item;
			let referralFee;
			if (currentVersion === Version.V1) {
				referralFee = item.referralFeeReward;
			} else {
				referralFee = item.referralFeeRewardV2;
			}
			const claimed = referralFee.claimed;
			const unclaimed = referralFee.unclaimed;
			const totalFeeReward = plus(claimed, unclaimed);

			if (feeRewardMap.has(id)) {
				feeRewardMap.get(id).unclaimed = plus(
					feeRewardMap.get(id).unclaimed,
					unclaimed
				);
				feeRewardMap.get(id).claimed = plus(
					feeRewardMap.get(id).claimed,
					claimed
				);
				feeRewardMap.get(id).totalFeeReward = plus(
					feeRewardMap.get(id).totalFeeReward,
					totalFeeReward
				);
				feeRewardMap.get(id).rewardsEQU = '0';
			} else {
				feeRewardMap.set(id, {
					id,
					unclaimed,
					claimed,
					totalFeeReward,
					rewardsEQU: '0'
				});
			}
		});

		const filterRewardList = Array.from(feeRewardMap.values());
		const rewardList =
			currentVersion === Version.V1
				? rewards &&
				  Object.values(
						rewards.reduce((accumulator, currentValue) => {
							const id = currentValue.id;
							const liquidity = currentValue.liquidity ?? '0';
							const position = currentValue.position ?? '0';
							const liquidityClaimed = currentValue.liquidityClaimed ?? '0';
							const positionClaimed = currentValue.positionClaimed ?? '0';
							const liquidityUnclaimed = currentValue.liquidityUnclaimed ?? '0';
							const positionUnclaimed = currentValue.positionUnclaimed ?? '0';

							if (accumulator[id]) {
								accumulator[id].liquidity = plus(
									accumulator[id].liquidity,
									liquidity
								);
								accumulator[id].position = plus(
									accumulator[id].position,
									position
								);
								accumulator[id].liquidityClaimed = plus(
									accumulator[id].liquidityClaimed,
									liquidityClaimed
								);
								accumulator[id].positionClaimed = plus(
									accumulator[id].positionClaimed,
									positionClaimed
								);
								accumulator[id].liquidityUnclaimed = plus(
									accumulator[id].liquidityUnclaimed,
									liquidityUnclaimed
								);
								accumulator[id].positionUnclaimed = plus(
									accumulator[id].positionUnclaimed,
									positionUnclaimed
								);
							} else {
								accumulator[id] = {
									positionUnclaimed: positionUnclaimed,
									liquidityUnclaimed: liquidityUnclaimed,
									positionClaimed: positionClaimed,
									liquidityClaimed: liquidityClaimed,
									liquidity: liquidity.toString(),
									position: position,
									id: id
								};
							}
							return accumulator;
						}, {})
				  )
				: rewardsV2 &&
				  Object.values(
						rewardsV2.reduce((accumulator, currentValue) => {
							const id = currentValue.id;
							const liquidity = '0';
							const position = currentValue.position ?? '0';
							const liquidityClaimed = '0';
							const positionClaimed = '0';
							const liquidityUnclaimed = '0';
							const positionUnclaimed = '0';

							if (accumulator[id]) {
								accumulator[id].position = plus(
									accumulator[id].position,
									position
								);
							} else {
								accumulator[id] = {
									positionUnclaimed: positionUnclaimed,
									liquidityUnclaimed: liquidityUnclaimed,
									positionClaimed: positionClaimed,
									liquidityClaimed: liquidityClaimed,
									liquidity: liquidity.toString(),
									position: position,
									id: id
								};
							}
							return accumulator;
						}, {})
				  );
		const _membersRewardList = map(filterRewardList, item => {
			const feeRewardObj =
				currentVersion === Version.V1
					? find(feeRewardPools, { id: item.id })
					: find(feeRewardPoolsV2, { id: item.id });
			const rewardObj = find(rewardList, { id: item.id });
			const EQURewardObj = find(EQURewards, { id: item.id });
			const newEQURewardObj = find(newRewardsEQUData, { id: item.id });
			const mergedObj = merge(
				{},
				item,
				rewardObj,
				EQURewardObj,
				feeRewardObj,
				newEQURewardObj
			);

			if (item?.unclaimed !== '0') {
				mergedObj.unclaimed = item?.unclaimed;
			}
			if (EQURewardObj && EQURewardObj?.rewardsEQU !== '0') {
				mergedObj.rewardsEQU = EQURewardObj?.rewardsEQU;
			}
			if (newEQURewardObj && newEQURewardObj?.total_claimable_amount !== '0') {
				mergedObj.newRewardsEQU = newEQURewardObj?.total_claimable_amount;
			} else {
				mergedObj.newRewardsEQU = '0';
			}
			if (newEQURewardObj && newEQURewardObj?.total_amount !== '0') {
				mergedObj.newRewardsTotalEQU = newEQURewardObj?.total_amount;
			} else {
				mergedObj.newRewardsTotalEQU = '0';
			}
			mergedObj.rewards = (rewards || []).filter(
				reward => reward.id === item.id
			);
			return mergedObj;
		});

		const membersRewardList = _membersRewardList.filter(item => {
			return (
				isGreaterThan(item.unclaimed, 0) ||
				isGreaterThan(item.newRewardsEQU, 0) ||
				isGreaterThan(item.rewardsEQU, 0) ||
				isGreaterThan(item.liquidity, 0) ||
				isGreaterThan(item.position, 0) ||
				isGreaterThan(item.totalFeeReward, 0) ||
				isGreaterThan(item.newRewardsTotalEQU, 0)
			);
		});
		return membersRewardList;
	}, [data, refetch, rewards, rewardsV2, EQURewards, currentVersion]);

	return {
		data: data?.referrers as unknown as Array<IMemberItem>,
		membersRewardList,
		poolPositionList,
		refereesAddressList,
		allReferralCodes,
		loading: isLoading,
		refetch,
		refetchRewards,
		refetchDetails,
		refetchRefereesAddress
	};
}
