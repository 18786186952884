import { Hash } from '@wagmi/core';
import { Version } from 'config/constants';

import { arbitrum, arbitrumGoerli } from './constants/connector/chains';

type ContractMap = Record<string, Record<number, string>>;
type EnvContractMap = Record<string, Record<string, string>>;

const PROD_CONTRACTS: Record<string, string> = {
	PositionRouter: '0xc3B609357539A35673cc50e5Ca4fA57da1BFeC7b',
	OrderBook: '0x0A41c964781312413Ac51d1A11efff1D0cfF2832',
	OrderBookAssistant: '0x43cB3494633ea5fb4D0fa9De808A7002A7c03000',
	Router: '0x911a71DDa951958913219f7cBD7e4a297ca52B3B',
	EFC: '0xe6bf4e8A735d4F83a662d5aD430159Aa97eAE37E',
	FeeDistributor: '0x3C77EEB8eC4716a6389a522eD590FbbD261ABE8e',
	RewardCollector: '0x2920bD2FC3ca629F98DD8B24C89C6E01b3dDd710',
	RewardFarm: '0xb46a80b157b7eA025530062B761D01AA92f0e354',
	PoolFactory: '0xA91680161fBCeA942e164B42445aD6130D01541F',
	NonfungiblePositionManager: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
	UniPoolFactory: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
	Multicall: '0xcA11bde05977b3631167028862bE2a173976CA11'
};

const EnvMapArbitrumGoerli: EnvContractMap = {
	development: {
		PositionRouter: '0xb8FE3e2330a967a9f7272a20d31218DE7bB8F0d4',
		OrderBook: '0xF98e2E522D04a693C1E20B413abeFe2A5d8B3f95',
		OrderBookAssistant: '0x49B8158F21B6161c9E457198eE2c235bF8c5a207',
		Router: '0xbEB81D003CB2E0a334196E8E462fD1fD7EA5a868',
		EFC: '0xE4687B2675d487ddab1244993f92bA7b46CF86C0',
		FeeDistributor: '0x01D588a5F7f67f02F68f750e2C4d10A791C6F035',
		RewardCollector: '0x882e0d6a270B7f8eA051F65dC23A3207E5e8cfAA',
		RewardFarm: '0xB66D690943563bA2faE3E661DeC2358817dcb94a',
		PoolFactory: '0xFFd61fB11452C1d67677A8F328cb4f07c835E655',
		NonfungiblePositionManager: '0x6b2937Bde17889EDCf8fbD8dE31C3C2a70Bc4d65',
		UniPoolFactory: '0x248AB79Bbb9bC29bB72f7Cd42F17e054Fc40188e',
		Multicall: '0xcA11bde05977b3631167028862bE2a173976CA11'
	},
	production: {
		PositionRouter: '0xb8FE3e2330a967a9f7272a20d31218DE7bB8F0d4',
		OrderBook: '0xF98e2E522D04a693C1E20B413abeFe2A5d8B3f95',
		OrderBookAssistant: '0x49B8158F21B6161c9E457198eE2c235bF8c5a207',
		Router: '0xbEB81D003CB2E0a334196E8E462fD1fD7EA5a868',
		EFC: '0xE4687B2675d487ddab1244993f92bA7b46CF86C0',
		FeeDistributor: '0x01D588a5F7f67f02F68f750e2C4d10A791C6F035',
		RewardCollector: '0x882e0d6a270B7f8eA051F65dC23A3207E5e8cfAA',
		RewardFarm: '0xB66D690943563bA2faE3E661DeC2358817dcb94a',
		PoolFactory: '0xFFd61fB11452C1d67677A8F328cb4f07c835E655',
		NonfungiblePositionManager: '0x6b2937Bde17889EDCf8fbD8dE31C3C2a70Bc4d65',
		UniPoolFactory: '0x248AB79Bbb9bC29bB72f7Cd42F17e054Fc40188e',
		Multicall: '0xcA11bde05977b3631167028862bE2a173976CA11'
	}
};

const EnvMapArbitrumGoerliV2: EnvContractMap = {
	development: {
		PositionRouter: '0x0cDEaF036C8A6e4cDa48D9E62236C00f7479BD23',
		OrderBook: '0x0405b742E102e5cf86cC58cd132450542F3Ba43A',
		Router: '0x5d60eF308A286EA34441aE8d904563aA05e1A46C',
		RewardCollector: '0xa2b5A6767576B5A9f89CB513D0b7Eec7166D9661'
	},
	production: {
		PositionRouter: '0x4C22dC6f5107f60511b90384d1c8f2f04bc74d72',
		OrderBook: '0x139bCA8f7C4DF3782f4Bf63B255Baa71D269C9D4',
		Router: '0x589f9e7F972a1D5bE0f94F879b84EBDAAaB2c086',
		RewardCollector: '0x881CD336cD9d96751DfD862702b90f6b9ebde4D7'
	}
};

const PROD_CONTRACTS_V2: Record<string, string> = {
	PositionRouter: '0x0C2e09f9a752a0C9E2E9218aB239d212cdE6afd3',
	OrderBook: '0xF0cffc35eD6A82646fC0465f7c92C31a1A884D21',
	Router: '0x6903c646D737Ea7EFC78B515050F3bBa9808D2F8',
	RewardCollector: '0x1eB79ac7f26a667CD3A40888f6c92f22877A0d8d'
};

const EnvMap: EnvContractMap = {
	development: PROD_CONTRACTS,
	production: PROD_CONTRACTS
};

export const contractArbitrumGoerli =
	EnvMapArbitrumGoerli[import.meta.env.MODE];
export const contractArbitrumGoerliV2 =
	EnvMapArbitrumGoerliV2[import.meta.env.MODE];
export const contract = EnvMap[import.meta.env.MODE];
export const contractV2 = PROD_CONTRACTS_V2;

export const CONTRACTS: ContractMap = {
	PositionRouter: {
		[arbitrumGoerli.id]: contractArbitrumGoerli.PositionRouter,
		[arbitrum.id]: contract.PositionRouter
	},
	OrderBook: {
		[arbitrumGoerli.id]: contractArbitrumGoerli.OrderBook,
		[arbitrum.id]: contract.OrderBook
	},
	OrderBookAssistant: {
		[arbitrumGoerli.id]: contractArbitrumGoerli.OrderBookAssistant,
		[arbitrum.id]: contract.OrderBookAssistant
	},
	Router: {
		[arbitrumGoerli.id]: contractArbitrumGoerli.Router,
		[arbitrum.id]: contract.Router
	},
	EFC: {
		[arbitrumGoerli.id]: contractArbitrumGoerli.EFC,
		[arbitrum.id]: contract.EFC
	},
	FeeDistributor: {
		[arbitrumGoerli.id]: contractArbitrumGoerli.FeeDistributor,
		[arbitrum.id]: contract.FeeDistributor
	},
	RewardCollector: {
		[arbitrumGoerli.id]: contractArbitrumGoerli.RewardCollector,
		[arbitrum.id]: contract.RewardCollector
	},
	NonfungiblePositionManager: {
		[arbitrumGoerli.id]: contractArbitrumGoerli.NonfungiblePositionManager,
		[arbitrum.id]: contract.NonfungiblePositionManager
	},
	PoolFactory: {
		[arbitrumGoerli.id]: contractArbitrumGoerli.PoolFactory,
		[arbitrum.id]: contract.NonfungiblePositionManager
	},
	UniPoolFactory: {
		[arbitrumGoerli.id]: contractArbitrumGoerli.UniPoolFactory,
		[arbitrum.id]: contract.UniPoolFactory
	},
	Multicall: {
		[arbitrumGoerli.id]: contractArbitrumGoerli.Multicall,
		[arbitrum.id]: contract.Multicall
	}
};

export const CONTRACTSV2: ContractMap = {
	PositionRouter: {
		[arbitrumGoerli.id]: contractArbitrumGoerliV2.PositionRouter,
		[arbitrum.id]: contractV2.PositionRouter
	},
	OrderBook: {
		[arbitrumGoerli.id]: contractArbitrumGoerliV2.OrderBook,
		[arbitrum.id]: contractV2.OrderBook
	},
	Router: {
		[arbitrumGoerli.id]: contractArbitrumGoerliV2.Router,
		[arbitrum.id]: contractV2.Router
	},
	RewardCollector: {
		[arbitrumGoerli.id]: contractArbitrumGoerliV2.RewardCollector,
		[arbitrum.id]: contractV2.RewardCollector
	}
};

export const getContractAddress = (chainId: number, contractName: string) => {
	if (!CONTRACTS[contractName][chainId]) {
		console.warn(`Unknown contract "${contractName}" for chainId ${chainId}`);
		return '' as Hash;
	}

	return CONTRACTS[contractName][chainId] as Hash;
};

export const getContractAddressV2 = (chainId: number, contractName: string) => {
	if (!CONTRACTSV2[contractName][chainId]) {
		console.warn(`Unknown contract "${contractName}" for chainId ${chainId}`);
		return '' as Hash;
	}

	return CONTRACTSV2[contractName][chainId] as Hash;
};

export const selectContractByVersion = (
	version: Version,
	chainId: number,
	contractName: string
) => {
	if (version === Version.V2) {
		return getContractAddressV2(chainId, contractName);
	} else {
		return getContractAddress(chainId, contractName);
	}
};
