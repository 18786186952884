import { useEffect, useState } from 'react';

import { orderBookV2ABI } from 'config/abis';
import {
	Contract_Write_Mode,
	QUOTE_USD,
	Side,
	Transaction_Type
} from 'config/constants';
import { getContractAddressV2 } from 'config/contracts';
import Decimal from 'decimal.js';
import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { updateSigning } from 'state/tx/slice';
import {
	BaseRecord,
	OrderId,
	RecordForCancelProfitLossPartial,
	RecordForCancelProfitLossPosition,
	TokenInfo
} from 'types';
import { formatSize, shortenSymbolNative, toQuoteAmount } from 'utils';

import { useComputeGasLimit } from 'hooks/useComputeGasLimit';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { useSendTransaction } from 'hooks/useSendTransaction';
import { selectOrderOpenType } from 'state/setting/selector'

export function useSubmitCancelProfitLossV2() {
	const dispatch = useAppDispatch();
	const Order_Open_Type = useAppSelector(selectOrderOpenType);
    
	const { currentChainId } = useCurrentChain();
	const { address } = useAccount();

	const [contractWriteArgs, setContractWriteArgs] =
		useState<UseContractWriteConfig | null>(null);
	const [record, setRecord] = useState<
		RecordForCancelProfitLossPosition | RecordForCancelProfitLossPartial | null
	>(null);
	const [description, setDescription] = useState<string>('');

	const { gasLimit, prepared } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirming, isConfirmed, error } =
		useSendTransaction(contractWriteArgs, gasLimit);

	const onConfirm = (
		poolId: string,
		transactionType: Transaction_Type,
		orderIndex: string,
		triggerPrice: string,
		side: Side,
		baseToken: TokenInfo,
		triggerAbove: boolean,
		sizeDelta = '0'
	) => {
		const args = [orderIndex, address];

		const _baseSymbol = shortenSymbolNative(baseToken, currentChainId);
		const _poolSymbol = `${_baseSymbol}/${QUOTE_USD}`;
		const _triggerPrice = toQuoteAmount(
			triggerPrice,
			baseToken.precision,
			Decimal.ROUND_HALF_CEIL
		);
		const _sizeDelta = formatSize(sizeDelta, baseToken.positionUnits);
		const orderId = `${Order_Open_Type.Decrease}-${orderIndex}` as OrderId;

		let record = null;
		let description = '';
		switch (transactionType) {
			// 全仓：修改止盈
			case Transaction_Type.CreateTakeProfitPositionV2:
				record = {
					transactionType: Transaction_Type.CancelTakeProfitPositionV2,
					pool: poolId,
					orderId,
					baseToken,
					side,
					triggerPrice,
					triggerAbove
				} as RecordForCancelProfitLossPosition;
				description = TransactionDescription.CancelTakeProfitPositionOrders(
					_poolSymbol,
					_baseSymbol,
					_triggerPrice,
					side,
					triggerAbove,
					_sizeDelta
				);
				break;
			// 全仓：修改止损
			case Transaction_Type.CreateStopLossPositionV2:
				record = {
					transactionType: Transaction_Type.CancelStopLossPositionV2,
					pool: poolId,
					orderId,
					baseToken,
					side,
					triggerPrice,
					triggerAbove
				} as RecordForCancelProfitLossPosition;
				description = TransactionDescription.CancelStopLossPositionOrders(
					_poolSymbol,
					_baseSymbol,
					_triggerPrice,
					side,
					triggerAbove,
					_sizeDelta
				);
				break;
			// 部分：修改止盈
			case Transaction_Type.CreateTakeProfitPartialV2:
				record = {
					transactionType: Transaction_Type.CancelTakeProfitPartialV2,
					pool: poolId,
					orderId,
					baseToken,
					side,
					triggerPrice
				} as RecordForCancelProfitLossPartial;
				description = TransactionDescription.CancelTakeProfitPartialOrders(
					_poolSymbol,
					_baseSymbol,
					_triggerPrice,
					side,
					triggerAbove,
					_sizeDelta
				);
				break;
			// 部分：修改止损
			case Transaction_Type.CreateStopLossPartialV2:
				record = {
					transactionType: Transaction_Type.CancelStopLossPartialV2,
					pool: poolId,
					orderId,
					baseToken,
					side,
					triggerPrice
				} as RecordForCancelProfitLossPartial;
				description = TransactionDescription.CancelStopLossPartialOrders(
					_poolSymbol,
					_baseSymbol,
					_triggerPrice,
					side,
					triggerAbove,
					_sizeDelta
				);
				break;
		}

		const contractWriteArgs = {
			mode: Contract_Write_Mode.Unprepared,
			address: getContractAddressV2(currentChainId, 'OrderBook'),
			abi: orderBookV2ABI,
			functionName: 'cancelDecreaseOrder',
			args
		} as UseContractWriteConfig;

		setRecord(record);
		setDescription(description);
		setContractWriteArgs(contractWriteArgs);

		dispatch(updateSigning(record));
	};

	useEffect(() => {
		if (prepared) {
			onSendTransaction(record as BaseRecord, description);
		}
	}, [prepared]);

	useEffect(() => {
		if (error) {
			setContractWriteArgs(null);
		}
	}, [error]);

	return {
		onConfirm,
		isConfirming,
		isConfirmed
	};
}
