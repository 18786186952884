import { useEffect, useState } from 'react';

import { globalBaseState } from '../state/global/slice';
import { useAppSelector } from '../state/hooks';
import { usePoolsGlobalQuery } from './__generated_trade__/types-and-hooks';

const usePoolsGlobal = () => {
	const [isLoading, setIsLoading] = useState(true);
	const { appTimer } = useAppSelector(globalBaseState);
	const { data, loading, refetch } = usePoolsGlobalQuery();
	useEffect(() => {
		refetch();
	}, [appTimer]);

	useEffect(() => {
		if (!loading) {
			setIsLoading(loading);
		}
	}, [loading]);

	return {
		data: data ? data.pools : undefined,
		loading: isLoading,
		refetch
	};
};

export default usePoolsGlobal;
