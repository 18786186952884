import { useEffect, useMemo, useState } from 'react';

import {
	Stake_Type,
	TIME_7DAY_TO_SECOND,
	TIME_DAY_TO_SECOND
} from 'config/constants';
import { useApolloClients } from 'context/ApolloClientsContext';
import { useAccount } from 'wagmi';

import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import { IStakeDetail } from 'types';
import {
	div,
	formatDuration,
	isLessThan,
	minus,
	mod,
	multipliedBy,
	plus
} from 'utils';

import { useStakeDetailsQuery } from './__generated_referral__/types-and-hooks';

export function useMyStakesGraph() {
	const { blockTimestamp } = useAppSelector(globalBaseState);
	const { clientReferral } = useApolloClients();
	const { address } = useAccount();

	const [loading, setLoading] = useState<boolean>(true);

	const {
		data,
		loading: isLoading,
		refetch
	} = useStakeDetailsQuery({
		variables: { account: address },
		skip: !address,
		client: clientReferral
	});

	useEffect(() => {
		if (!isLoading) {
			setLoading(isLoading);
		}
	}, [isLoading]);

	return useMemo(() => {
		if (!data || !data.stakeList || !data.stakeLpList) {
			return {
				loading,
				list: null,
				lpStakeList: null,
				stakeList: null,
				refetch
			};
		}

		const _mergedList = data.stakeList.concat(data.stakeLpList);

		const _data: Array<IStakeDetail> = _mergedList.map(item => {
			let duration;
			let enableUnstake = false;
			let beginTime;
			let endTime;

			// 标准结束质押的时间
			const firstEndTimestamp = plus(
				item.stakeTimestamp,
				multipliedBy(item.period, TIME_DAY_TO_SECOND)
			);

			// 第一轮如果没有结束
			if (isLessThan(blockTimestamp, firstEndTimestamp)) {
				enableUnstake = false;
				duration = formatDuration(firstEndTimestamp, blockTimestamp);
				duration = Math.min(Number(duration), item.period);
				beginTime = item.stakeTimestamp;
				endTime = firstEndTimestamp;
			} else {
				// 30天以后，进行每隔一个锁仓周期，进行7天之内解锁
				// 一个周期秒数
				const periodToSeconds = multipliedBy(item.period, TIME_DAY_TO_SECOND);
				// 质押开始日期到现在的秒数
				const minusedTimeStamp = minus(blockTimestamp, item.stakeTimestamp);
				// 取余数之后剩下的秒数
				const modedTimestamp = mod(minusedTimeStamp, periodToSeconds);
				// 第几个周期
				const endCoefficient = Math.floor(
					Number(div(minusedTimeStamp, periodToSeconds))
				);
				// 如果 取余数之后剩下的秒数 < 7天对应的秒数，那么可解锁
				if (isLessThan(modedTimestamp, TIME_7DAY_TO_SECOND)) {
					duration = 0;
					enableUnstake = true;
					beginTime = plus(
						item.stakeTimestamp,
						multipliedBy(periodToSeconds, endCoefficient)
					);
					endTime = plus(beginTime, TIME_7DAY_TO_SECOND);
				} else {
					// 下一个周期的质押开始日期
					beginTime = plus(
						item.stakeTimestamp,
						multipliedBy(periodToSeconds, endCoefficient)
					);
					// 下一个周期的解锁日期
					endTime = plus(
						item.stakeTimestamp,
						multipliedBy(periodToSeconds, plus(endCoefficient, 1))
					);
					duration = formatDuration(endTime, blockTimestamp);
				}
			}

			return {
				...item,
				duration,
				enableUnstake,
				beginTime,
				endTime
			} as unknown as IStakeDetail;
		});

		const lpStakeList: Array<IStakeDetail> = _data.filter(item => {
			return item.type === Stake_Type.LP;
		});

		const stakeList: Array<IStakeDetail> = _data.filter(item => {
			return item.type === Stake_Type.EQU;
		});

		return {
			loading,
			list: _data,
			lpStakeList,
			stakeList,
			refetch
		};
	}, [data, loading, refetch, blockTimestamp]);
}
