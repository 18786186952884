import { useMemo } from 'react';

import { useApolloClients } from '../context/ApolloClientsContext';
import { useUserTransactionQueryQuery } from '../graphql/__generated_stats__/types-and-hooks';
import { plus } from '../utils';

const useMemberPositionValue = (
	list: { memberId: string; address: string }[],
	blockNumber?: number | string
) => {
	const { clientStats } = useApolloClients();

	const accounts = list.map(item => item.address);

	const { data: userData, loading } = useUserTransactionQueryQuery({
		variables: {
			account_in: accounts,
			number: Number(blockNumber)
		},
		client: clientStats
	});
	const addressToPositionValueMap = useMemo(() => {
		const tempMap = new Map();
		if (!userData || !userData.users) {
			return tempMap;
		}
		userData.users.forEach(item => {
			tempMap.set(
				item.id,
				item.userTransactionStatistics.reduce(
					(prev, cur) => plus(prev, cur.volumeUSD),
					'0'
				)
			);
		});

		return tempMap;
	}, [list, userData]);

	const positionValueMap = useMemo(() => {
		const tempMap = new Map();
		if (!list) {
			return tempMap;
		}

		list.forEach(item => {
			const volumeUSD = addressToPositionValueMap.get(item.address) || 0;
			if (tempMap.has(item.memberId)) {
				tempMap.set(item.memberId, plus(tempMap.get(item.memberId), volumeUSD));
			} else {
				tempMap.set(item.memberId, volumeUSD);
			}
		});

		return tempMap;
	}, [list, userData, addressToPositionValueMap]);

	return { positionValueMap, loading };
};

export default useMemberPositionValue;
