import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Page } from 'config/constants';

export function useCurrentPage() {
	const { pathname } = useLocation();
	return useMemo(() => {
		if (pathname === '/') {
			return Page.Index;
		}
		if(pathname === '/v1'){
			return Page.IndexV1;
		}
		if(pathname.includes('v1')){
			return `/${pathname.split('/')[1]}/${pathname.split('/')[2]}` as Page;
		}
		return `/${pathname.split('/')[1]}` as Page;
	}, [pathname]);
}
