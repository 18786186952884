import { Route, Routes } from 'react-router-dom';

import { Page } from 'config/constants';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import ApproveTest from 'pages/ApproveTest';
import Earn from 'pages/Earn';
import Leaderboard from 'pages/Leaderboard';
import Liquidity from 'pages/Liquidity';
import LiquidityDetail from 'pages/Liquidity/Detail';
import Market from 'pages/Market';
import Referrals from 'pages/Referrals';
import Trade from 'pages/Trade';

export default function RoutesList() {
	const { isMatchMobile } = useAppBreakpoints();
	return (
		<Routes>
			<Route
				path={Page.Index}
				element={isMatchMobile ? <Market /> : <Trade />}
			/>
			<Route
				path={Page.IndexV1}
				element={isMatchMobile ? <Market /> : <Trade />}
			/>
			<Route path={`${Page.Trade}`} element={<Trade />}>
				<Route path=':pool?' element={<Trade />} />
			</Route>
			<Route path={`${Page.TradeV1}`} element={<Trade />}>
				<Route path=':pool?' element={<Trade />} />
			</Route>
			<Route path={`${Page.Markets}`} element={<Market />}>
				<Route path=':pool?' element={<Market />} />
			</Route>
			<Route path={`${Page.MarketsV1}`} element={<Market />}>
				<Route path=':pool?' element={<Market />} />
			</Route>
			{/* <Route path={`${Page.Leaderboard}`} element={<Leaderboard />}>
				<Route path=':type?' element={<Leaderboard />} />
			</Route> */}
			<Route path={`${Page.LeaderboardV1}`} element={<Leaderboard />}>
				<Route path=':type?' element={<Leaderboard />} />
			</Route>
			<Route path={Page.Pools} element={<Liquidity />}>
				<Route path=':pool?' element={<LiquidityDetail />} />
			</Route>
			<Route path={Page.PoolsV1} element={<Liquidity />}>
				<Route path=':pool?' element={<LiquidityDetail />} />
			</Route>
			<Route path={`/${Page.Referrals}`} element={<Referrals />}>
				<Route path=':type' element={<Referrals />} />
			</Route>
			<Route path={`/${Page.ReferralsV1}`} element={<Referrals />}>
				<Route path=':type' element={<Referrals />} />
			</Route>
			<Route path={`/${Page.Earn}`} element={<Earn />}>
				<Route path=':type' element={<Earn />} />
			</Route>
			<Route path={`/${Page.EarnV1}`} element={<Earn />}>
				<Route path=':type' element={<Earn />} />
			</Route>
			<Route path='/test' element={<ApproveTest />} />
			<Route path='*' element={<Trade />} />
		</Routes>
	);
}
