import React, { useContext, useState } from 'react';

import { Trans } from '@lingui/macro';
import { IconButton, Paper } from '@mui/material';
import { App_Theme, Trade_Type, Version } from 'config/constants/enum';
import { TradeContext } from 'context/TradeContext';

import { useGetPool } from 'hooks/useGetPool';
import V2Logo from 'pages/components/V2/V2Logo';
import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { selectVersion } from 'state/setting/selector';

import { CommonStyledArticle } from 'components/Common/Styled';
import StyledTab from 'components/Common/StyledTab';
import StyledTabs from 'components/Common/StyledTabs';
import ComputerIcon from 'components/Svg/Icons/Computer';

import Computer from '../Computer';
import LimitForm from './LimitForm';
import MarketForm from './MarketForm';
import ToggleDirection from './ToggleDirection';

export default React.memo(function TradeForm({
	className
}: {
	className?: string;
}) {
	const { theme } = useAppSelector(globalBaseState);
	const { poolAddress } = useAppSelector(poolsBaseState);
	const currentVersion = useAppSelector(selectVersion);
	const { tradeType, setTradeType, tradeDirection } = useContext(TradeContext);

	const currentPool = useGetPool(poolAddress);

	const [visibleComputer, setVisibleComputer] = useState<boolean>(false);

	const onOpenComputerDialog = () => {
		setVisibleComputer(true);
	};

	const onCloseComputerDialog = () => {
		setVisibleComputer(false);
	};

	const onHandleChangeType = (
		event: React.SyntheticEvent,
		newValue: Trade_Type
	) => {
		setTradeType(newValue);
	};

	return (
		<CommonStyledArticle className={className}>
			<Paper className='space-y-3 p-4'>
				<ToggleDirection />
				<div className='flex justify-between items-center'>
					<div className='flex items-center'>
						<StyledTabs value={tradeType} onChange={onHandleChangeType}>
							<StyledTab
								className='pl-0'
								label={<Trans>trade.Market</Trans>}
								value={Trade_Type.Market}
							/>
							<StyledTab
								label={<Trans>Limit</Trans>}
								value={Trade_Type.Limit}
							/>
						</StyledTabs>
						{currentVersion === Version.V2 && (
							<V2Logo className='ml-0.5' width={18} height={14} />
						)}
					</div>
					<IconButton className='-mr-1' onClick={onOpenComputerDialog}>
						<ComputerIcon
							color={theme === App_Theme.dark ? 'white' : 'black'}
						/>
					</IconButton>
				</div>

				{tradeType === Trade_Type.Market ? <MarketForm /> : <LimitForm />}
			</Paper>

			{visibleComputer && (
				<Computer
					onClose={onCloseComputerDialog}
					pool={currentPool}
					side={tradeDirection}
				/>
			)}
		</CommonStyledArticle>
	);
});
