import { useEffect, useMemo } from 'react';

import Decimal from 'decimal.js';
import { useAccount } from 'wagmi';

import { Q64 } from '../config/constants';
import { useApolloClients } from '../context/ApolloClientsContext';
import { globalBaseState } from '../state/global/slice';
import { useAppSelector } from '../state/hooks';
import { txBaseState } from '../state/tx/slice';
import { formatUnits } from '../utils';
import { useArchitectRewardsQueryQuery } from './__generated_referral__/types-and-hooks';

export interface IArchitectItem {
	id: string;
	claimedReward: string;
	perShareGrowthX64: string;
	account: string;
	claimableReward?: string;
}

const useArchitectRewardsGraph = () => {
	const { clientReferral } = useApolloClients();
	const { address } = useAccount();
	const { appTimer } = useAppSelector(globalBaseState);

	const { data, loading, refetch } = useArchitectRewardsQueryQuery({
		variables: {
			account: address
		},
		skip: !address,
		client: clientReferral
	});
	const { quoteToken } = useAppSelector(txBaseState);

	const formattedData = useMemo(() => {
		if (!data) {
			return [];
		}
		return data.architects.map(item => {
			return {
				...item,
				claimableReward: formatUnits(
					new Decimal(data.stakeStatistic.architectPerShareGrowthX64)
						.minus(item.perShareGrowthX64)
						.div(Q64)
						.toString(),
					quoteToken.decimals
				)
			} as IArchitectItem;
		});
	}, [data]);

	useEffect(() => {
		refetch();
	}, [appTimer]);

	return { data: formattedData, loading };
};

export default useArchitectRewardsGraph;
