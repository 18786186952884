import { useMemo, useState } from 'react';

import { Hash } from '@wagmi/core';
import { UniswapV3PoolABI } from 'config/abis';

import { UtilHelper } from 'entities/UtilHelper';
import useWeb3Provider from 'hooks/useWeb3Provider';
import { createMulticall } from 'utils';

import { useCurrentChain } from '../useCurrentChain';
import { Fee_Amount, IV3Pool } from './types';
import { useUpdateEffect } from 'react-use';

export function useUniV3Pools(
	poolAddresses: Array<string> | null,
	fees: Array<Fee_Amount>
) {
	const { currentChainId } = useCurrentChain();
	const Web3Provider = useWeb3Provider();
	const { multicallv3 } = createMulticall(Web3Provider);

	const [pools, setPools] = useState<Array<IV3Pool> | null>(null);
	const [poolMap, setPoolMap] = useState<Map<string, IV3Pool> | null>(null);

	const poolsContracts = useMemo(() => {
		if (!poolAddresses) {
			return undefined;
		}
		return poolAddresses.map((address: string) => {
			return {
				address: address as Hash,
				abi: UniswapV3PoolABI,
				name: 'slot0'
			};
		});
	}, [currentChainId, poolAddresses]);

	const fetchContractData = async () => {
		if (poolsContracts && poolsContracts.length > 0) {
			try {
				const rep = await multicallv3({
					calls: poolsContracts ? poolsContracts : [],
					chainId: currentChainId
				});
				const pools: Array<IV3Pool> = [];
				const poolMap = new Map<string, IV3Pool>();
				const _filteredRep = rep.filter(
					item => item !== null && item !== undefined
				);
				if (_filteredRep && _filteredRep.length > 0) {
					rep.forEach((item, index) => {
						if (item) {
							const pool = {
								fee: fees[index],
								sqrtRatioX96: item['sqrtPriceX96'],
								tick: item['tick'],
								sqrtPrice: UtilHelper.formatFromX96(item['sqrtPriceX96'])
							} as IV3Pool;
							pools.push(pool);
							poolMap.set(poolAddresses[index], pool);
						}
					});
				}
				setPools(pools);
				setPoolMap(poolMap);
			} catch (error) {
				setPools(null);
				setPoolMap(null);
			}
		}
	};

	useUpdateEffect(() => {
		if (poolsContracts) {
			fetchContractData();
		}
	}, [poolsContracts, Web3Provider]);

	return {
		uniV3Pools: pools,
		uniV3PoolMap: poolMap,
		refetchV3Pools: fetchContractData
	};
}
