import { Trans } from '@lingui/macro';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
	Box,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	Typography,
	alpha,
	useTheme
} from '@mui/material';
import { TIME_STAMP_END_TRADING_FEE, Version } from 'config/constants';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin } from 'hooks/useCurrentChain';
import { useAppSelector } from 'state/hooks';
import { tradeBaseState } from 'state/trade/slice';
import { isAfterEndTime } from 'utils';

import Module from 'components/Module';
import SectionLoading from 'components/SectionLoading';
import ConnectWalletButton from 'components/Wallet/ConnectWalletButton';

import InputReferralCode from './InputReferralCode';

function Guide() {
	const isLogin = useCheckLogin();
	const theme = useTheme();
	const { isBindReferralCode } = useAppSelector(tradeBaseState);
	const currentVersion = useAppSelector(state => state.setting.currentVersion);
	return (
		<List className='space-y-1'>
			<ListItem className='p-0'>
				<ListItemIcon
					sx={{
						minWidth: '16px !important'
					}}
				>
					<FiberManualRecordIcon
						sx={{ width: 6, height: 6, color: theme.palette.primary.main }}
					/>
				</ListItemIcon>
				<ListItemText
					className='leading-7'
					primary={
						<Typography variant='body2' color='text.secondary'>
							{currentVersion === Version.V1 ? (
								<Trans>
									Enjoy a
									<Typography
										sx={{ display: 'inline' }}
										component='span'
										variant='body1'
										color='primary'
										className='mx-1'
									>
										10%
									</Typography>
									discount on trading fee.
								</Trans>
							) : (
								<Trans>
									Enjoy a
									<Typography
										sx={{ display: 'inline' }}
										component='span'
										variant='body1'
										color='primary'
										className='mx-1'
									>
										50%
									</Typography>
									discount on trading fee.
								</Trans>
							)}
						</Typography>
					}
				/>
			</ListItem>
			{((currentVersion === Version.V2 && !isLogin) ||
				(currentVersion === Version.V2 && !isBindReferralCode)) && (
				<ListItem className='p-0'>
					<ListItemIcon
						sx={{
							minWidth: '16px !important'
						}}
					>
						<FiberManualRecordIcon
							sx={{ width: 6, height: 6, color: theme.palette.primary.main }}
						/>
					</ListItemIcon>
					<ListItemText
						className='leading-7'
						primary={
							<Typography variant='body2' color='text.secondary'>
								<Trans>
									<Typography
										sx={{ display: 'inline' }}
										component='span'
										variant='body2'
										color='primary'
									>
										0 Trading Fees
									</Typography>{' '}
									for BTC, effective until May 31, 2024.
								</Trans>
							</Typography>
						}
					/>
				</ListItem>
			)}
			<ListItem className='p-0'>
				<ListItemIcon
					sx={{
						minWidth: '16px !important'
					}}
				>
					<FiberManualRecordIcon
						sx={{ width: 6, height: 6, color: theme.palette.primary.main }}
					/>
				</ListItemIcon>
				<ListItemText
					className='leading-7'
					primary={
						<Typography variant='body2' color='text.secondary'>
							<Trans>
								Earn a
								<Typography
									sx={{ display: 'inline' }}
									component='span'
									variant='body1'
									color='primary'
									className='mx-1'
								>
									1.1x
								</Typography>
								boasted multiplier for liquidity mining.
							</Trans>
						</Typography>
					}
				/>
			</ListItem>
		</List>
	);
}

export default function Traders() {
	const isLogin = useCheckLogin();
	const { isBindReferralCode, bindReferralCodeLoading } =
		useAppSelector(tradeBaseState);
	const { isMatchMobile } = useAppBreakpoints();
	const theme = useTheme();
	const currentVersion = useAppSelector(state => state.setting.currentVersion);
	return (
		<Paper>
			<Module>
				<Box className='flex py-6 sm:flex-col sm:py-0'>
					<Box className='flex-1'>
						<Box className='space-y-4'>
							<Typography
								variant='h6'
								fontWeight={500}
								fontSize={isMatchMobile ? 14 : 16}
							>
								<Trans>Bind Referral Code</Trans>
							</Typography>
							{isLogin ? (
								bindReferralCodeLoading ? (
									<SectionLoading />
								) : (
									<>
										<InputReferralCode />
										{isBindReferralCode ? (
											<div>
												<Typography
													className='sm:font-normal sm:-mt-2'
													variant='body2'
													color='text.secondary'
												>
													<Trans>You have already bound a referral code.</Trans>
												</Typography>
											</div>
										) : (
											<Guide />
										)}
									</>
								)
							) : (
								<>
									<ConnectWalletButton className='sm:w-full' />
									<Guide />
								</>
							)}
						</Box>
					</Box>
					<Divider
						className='mx-10 my-4 sm:mx-0 md:mx-4'
						orientation={isMatchMobile ? 'horizontal' : 'vertical'}
						flexItem
					/>
					<Box className='flex-1'>
						{currentVersion === Version.V1 ? (
							<Box className='flex align-center'>
								<Typography
									className='sm:text-sm'
									variant='h6'
									color={alpha(theme.custom.reverseColor, 0.7)}
								>
									<Trans>Trading fee rate</Trans>
								</Typography>
								<Typography
									className='ml-4 text-base sm:text-sm'
									component='span'
									fontWeight={500}
								>
									{isLogin && isBindReferralCode ? '0.045%' : '0.05%'}
								</Typography>
							</Box>
						) : (
							<Box className='flex align-center'>
								<Typography
									className='sm:text-sm'
									variant='h6'
									color={alpha(theme.custom.reverseColor, 0.7)}
								>
									<Trans>Trading Fee Discount</Trans>
								</Typography>
								<Typography
									className='ml-4 text-base sm:text-sm'
									component='span'
									fontWeight={500}
								>
									{isLogin && isBindReferralCode ? '50%' : '0'}
								</Typography>
							</Box>
						)}
						<Typography
							className='sm:mt-1'
							variant='body2'
							color='text.secondary'
						>
							{currentVersion === Version.V1 ? (
								<Trans>
									Your current fee rate for opening and closing positions.
								</Trans>
							) : isLogin && isBindReferralCode ? (
								<Trans>
									Your current trading fee discount for opening and closing
									positions.
								</Trans>
							) : (
								<Trans>
									Depending on the market, your current opening and closing fees
									range from 0.02% to 0.1%.
								</Trans>
							)}
						</Typography>
						{/* {currentVersion === Version.V2 && isBindReferralCode && (
							<Typography variant='body2' color='text.secondary'>
								<Trans>
									<Typography
										sx={{ display: 'inline' }}
										component='span'
										variant='body1'
										color='primary'
									>
										90% Off
									</Typography>{' '}
									on trading fees for BTC & ETH, effective until March 1, 2024
								</Trans>
							</Typography>
						)} */}

						{isLogin && isBindReferralCode && currentVersion === Version.V2 && (
							<Typography variant='body2' color='text.secondary'>
								<Trans>
									<Typography
										sx={{ display: 'inline' }}
										component='span'
										variant='body2'
										color='primary'
									>
										0 Trading Fees
									</Typography>{' '}
									for BTC, effective until May 31, 2024.
								</Trans>
							</Typography>
						)}
						<Divider className='my-6 sm:hidden' flexItem />
						<Box className='flex align-center sm:mt-6'>
							<Typography
								className='sm:text-sm'
								variant='h6'
								color={alpha(theme.custom.reverseColor, 0.7)}
							>
								<Trans>Mining multiplier</Trans>
							</Typography>
							<Typography
								className='ml-4 text-base sm:text-sm'
								component='span'
								fontWeight={500}
							>
								{isLogin && isBindReferralCode ? '1.1x' : '1.0x'}
							</Typography>
						</Box>
						<Typography
							className='sm:mt-1'
							variant='body2'
							color='text.secondary'
						>
							<Trans>Your current liquidity mining multiplier.</Trans>
						</Typography>
					</Box>
				</Box>
			</Module>
		</Paper>
	);
}
