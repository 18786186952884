import { isMobile } from 'react-device-detect';

import { Chain, configureChains, createClient } from 'wagmi';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { SafeConnector } from 'wagmi/connectors/safe';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';

import { sample } from 'lodash';
import memoize from 'lodash/memoize';

import { SUPPORTED_CHAINS } from './chains';
import { ConnectorNames } from './types';

export const getPublicNodeURL = (networkName: string) => {
	if (!networkName) return null;
	const currentChainInfo: Chain | undefined = SUPPORTED_CHAINS.find(item => {
		return item.network === networkName;
	});
	return sample(currentChainInfo?.rpcUrls.public.http);
};

export const getNodeRealUrl = (networkName: string) => {
	const host = getPublicNodeURL(networkName);
	return {
		http: host,
		webSocket: host.replace(/^https/i, 'wss')
	};
};

export const { provider, chains } = configureChains(SUPPORTED_CHAINS, [
	jsonRpcProvider({
		rpc: chain => {
			return getNodeRealUrl(chain.network);
		}
	})
]);

export const injectedConnector = new InjectedConnector({
	chains,
	options: {
		name: ConnectorNames.Injected,
		shimDisconnect: true
	}
});

export const rabbywalletConnector = new InjectedConnector({
	chains,
	options: {
		name: ConnectorNames.Rabby,
		getProvider: () =>
			typeof window !== 'undefined'
				? isMobile
					? window?.ethereum
					: window.rabby
				: undefined,
		shimDisconnect: true
	}
});

export const trustwalletConnector = new InjectedConnector({
	chains,
	options: {
		name: ConnectorNames.Trust,
		getProvider: () =>
			typeof window !== 'undefined'
				? isMobile
					? window?.ethereum
					: window.trustwallet
				: undefined,
		shimDisconnect: true
	}
});

export const okxwalletConnector = new InjectedConnector({
	chains,
	options: {
		name: ConnectorNames.Okxwallet,
		getProvider: () =>
			typeof window !== 'undefined' ? window.okxwallet : undefined,
		shimDisconnect: true
	}
});

export const bybitwalletConnector = new InjectedConnector({
	chains,
	options: {
		name: ConnectorNames.Bybit,
		getProvider: () =>
			typeof window !== 'undefined' ? window.bybitWallet : undefined,
		shimDisconnect: true
	}
});

export const bitgetConnitector = new InjectedConnector({
	chains,
	options: {
		name: ConnectorNames.Bitget,
		getProvider: () =>
			typeof window !== 'undefined'
				? window.bitkeep && window.bitkeep.ethereum
				: undefined,
		shimDisconnect: true
	}
});

export const coinbaseConnector = new CoinbaseWalletConnector({
	chains,
	options: {
		appName: 'Equation',
		appLogoUrl: ''
	}
});

export const walletConnectConnector = new WalletConnectConnector({
	chains,
	options: {
		projectId: import.meta.env.VITE_WALLETCONNECT_PROJECTID,
		showQrModal: true
	}
});

export const metaMaskConnector = new MetaMaskConnector({
	chains,
	options: {
		shimDisconnect: true,
		UNSTABLE_shimOnConnectSelectAccount: true
	}
});

const safeConnector = new SafeConnector({
	chains,
	options: {
		allowedDomains: [/gnosis-safe.io$/, /app.safe.global$/],
		debug: false
	}
});

export const client = createClient({
	autoConnect: true,
	connectors: [
		safeConnector,
		metaMaskConnector,
		injectedConnector,
		okxwalletConnector,
		coinbaseConnector,
		bitgetConnitector,
		walletConnectConnector,
		rabbywalletConnector,
		bybitwalletConnector,
		trustwalletConnector
	],
	provider
});

export const CHAIN_IDS = chains.map(c => c.id);

export const isChainSupported = memoize((chainId: number) =>
	CHAIN_IDS.includes(chainId)
);
export const isChainTestnet = memoize(
	(chainId: number) => chains.find(c => c.id === chainId)?.testnet
);
