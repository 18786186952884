import { Trans } from '@lingui/macro';
import { IconButton, Tooltip, useTheme } from '@mui/material';

import useCopyClipboard from 'hooks/useCopyClipboard';

import CopyIcon from 'components/Svg/Icons/Copy';

export default function Copy({ value }: { value: string }) {
	const theme = useTheme();

	const [isCopied, setCopied] = useCopyClipboard();

	const onHandleCopy = (value: string) => {
		setCopied(value);
	};

	return (
		<Tooltip
			title={<Trans>Copied</Trans>}
			placement='top'
			open={isCopied}
			arrow
		>
			<IconButton onClick={() => onHandleCopy(value)}>
				<CopyIcon fill={theme.palette.text.primary} />
			</IconButton>
		</Tooltip>
	);
}
