import { ReactNode, createContext, useEffect, useMemo, useState } from 'react';

export interface IReferralsClaim {
    rewardsEQU: string;
    unclaimed: string;
    id: any;
	memberId: string;
	referralLiquidityReward: string;
	referralPositionReward: string;
	referralFeeReward: string;
}

export interface IRewardConfigItem {
	id: string;
	liquidityRate: string;
	referralParentTokenRate: string;
	referralTokenRate: string;
	rewardCap: string;
	riskBufferFundLiquidityRate: string;
}

export interface IReferralRewardClaimItem {
    id: string;
    rewardsEQU: string;
	newRewardsEQU: string;
	totalFeeReward:string;
    unclaimed: string;
    pools:Array<string>;
}

export interface IReferralsContext {
    referralConnectors: Array<IReferralsClaim>;
	setReferralConnectors: (value: Array<IReferralsClaim>) => void;
	referralMembers: Array<IReferralsClaim>;
	setReferralMembers: (value: Array<IReferralsClaim>) => void;
	rewardsConfig: IRewardConfigItem | null;
	setRewardsConfig: (value: IRewardConfigItem) => void;
}

export const ReferralsContext = createContext<IReferralsContext>({
    referralConnectors: [],
	setReferralConnectors: () => void 0,
	referralMembers: [],
	setReferralMembers: () => void 0,
	rewardsConfig: null,
	setRewardsConfig: () => void 0
});

export function ReferralsContextProvider({
	children
}: {
	children: ReactNode;
}) {
	const [referralMembers, setReferralMembers] = useState<
		Array<IReferralsClaim>
	>([]);
    const [referralConnectors, setReferralConnectors] = useState<
		Array<IReferralsClaim>
	>([]);
	const [rewardsConfig, setRewardsConfig] = useState<IRewardConfigItem | null>(
		null
	);

	const value = useMemo(() => {
		return {
            referralConnectors,
            setReferralConnectors,
			referralMembers,
			setReferralMembers,
			rewardsConfig,
			setRewardsConfig
		};
	}, [referralConnectors, setReferralConnectors, referralMembers, setReferralMembers, rewardsConfig, setRewardsConfig]);

    useEffect(()=>{
        // if(referralMembers && referralConnectors && referralMembers.length && referralConnectors.length){
            
        // }
    },[referralMembers])

	return (
		<ReferralsContext.Provider value={value}>
			{children}
		</ReferralsContext.Provider>
	);
}
