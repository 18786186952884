import { Reducer, createSlice } from '@reduxjs/toolkit';

import { IPoolInfo } from 'graphql/usePoolInfoGraph';
import { RootState } from 'state';
import { IPoolItem } from 'types';
import { slice } from '../slice';

interface StateProps {
	pools: Array<IPoolItem>;
	poolMap: Map<string, IPoolItem>;
	liquidityPools: Array<any>;
	poolAddress: string | undefined;
	poolInfo: IPoolInfo | null;
}

export const initialState: StateProps = {
	pools: [],
	poolMap: new Map<string, IPoolItem>(),
	liquidityPools: [],
	poolAddress: undefined,
	poolInfo: null
};

export const reducers = {
	setPools(state, { payload }) {
		const selectPoolById = (id: string) => {
			return state.V1.pools.find(pool => pool.id === id);
		};
		const refactorPools = payload.map((pool: IPoolItem) => {
			const currentPool = selectPoolById(pool.id);
			if (Number(pool.market_price) > Number(currentPool?.market_price)) {
				return { ...pool, priceChangeDirection: 'up' };
			} else if (
				Number(pool.market_price) < Number(currentPool?.market_price)
			) {
				return { ...pool, priceChangeDirection: 'down' };
			} else {
				return {
					...pool,
					priceChangeDirection:
						Number(pool.market_price) === Number(currentPool?.market_price)
							? currentPool?.priceChangeDirection
							: ''
				};
			}
		});
		state.V1.pools = refactorPools;
	},
	setPoolMap(state, { payload }) {
		const map = new Map();
		payload.forEach((item) => {
			map.set(item.id, item)
		})
		state.V1.poolMap = map;
	},
	setLiquidityPools(state, { payload }) {
		state.V1.liquidityPools = payload;
	},
	setPoolAddress(state, { payload }) {
		state.V1.poolAddress = payload;
	},
	setPoolInfo(state, { payload }) {
		state.V1.poolInfo = payload;
	}
}

export const poolsV1BaseState = (state: RootState) => state.pools.V1;