import { useEffect, useMemo } from 'react';

import moment from 'moment';

import { ITransaction, TransactionRecord } from 'entities/TransactionRecord';
import {
	useManageTransactions,
	useTransactionsLocalStorageKey
} from 'hooks/useAccountTransactions';
import { globalBaseState } from 'state/global/slice';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import store from 'store2';
import { RecordKey } from 'types';
import { isLocalStorageFull } from 'utils';

import { useWeb3LibraryContext } from 'components/Wallet/WalletProvider';

import { selectTransactionRecordList } from './selector';
import {
	addOrUpdateRecord,
	setTimeoutRecords,
	setTransactionRecords
} from './slice';

export const RECORD_TIMEOUT = 5 * 60 * 1000; // 5 minutes

export default function RecordUpdater() {
	const dispatch = useAppDispatch();
	const { appTimer } = useAppSelector(globalBaseState);
	const transactionRecords = useAppSelector(selectTransactionRecordList);
	const provider = useWeb3LibraryContext();

	const storeKey = useTransactionsLocalStorageKey();

	const { updateTransaction, removeTransaction } = useManageTransactions();

	const transactionList = useMemo(() => {
		if (!storeKey) {
			return [];
		}
		return store.get(storeKey, [] as Array<ITransaction>);
	}, [storeKey, appTimer]);

	// on store transactions change
	useEffect(() => {
		window.addEventListener('storage', e => {
			if (e.key === storeKey) {
				const oldValue: Array<ITransaction> = e.oldValue
					? JSON.parse(e.oldValue)
					: [];
				const newValue: Array<ITransaction> = e.newValue
					? JSON.parse(e.newValue)
					: [];
				if (e.oldValue && e.newValue && oldValue.length !== newValue.length) {
					const newTransactions = newValue.filter(
						(elm: any) =>
							!oldValue
								.map((elm: any) => JSON.stringify(elm))
								.includes(JSON.stringify(elm))
					);
					for (const tx of newTransactions) {
						new TransactionRecord(
							tx,
							provider,
							(current: TransactionRecord) => {
								dispatch(addOrUpdateRecord(current));
							}
						);
					}
				}
			}
		});
		return () => {
			window.removeEventListener('storage', () => {
				void 0;
			});
		};
	}, [provider]);

	useEffect(() => {
		if (!transactionList.length || !provider) {
			dispatch(setTransactionRecords(new Map()));
			return;
		}
		const result = new Map<RecordKey, TransactionRecord>();
		transactionList.forEach((tx: ITransaction) => {
			result.set(
				`${tx.hash}:${tx.type}`,
				new TransactionRecord(tx, provider, (current: TransactionRecord) => {
					updateTransaction(current);
				})
			);
		});
		dispatch(setTransactionRecords(result));
	}, [transactionList, provider]);

	// 超时的记录，进行移除
	const timeoutRecords = useMemo(() => {
		return transactionRecords.filter((txRecord: TransactionRecord) => {
			return checkTimeoutRecord(txRecord);
		});
	}, [transactionRecords]);

	useEffect(() => {
		timeoutRecords.forEach((item: TransactionRecord) => {
			dispatch(setTimeoutRecords(item));
			item.syncing = false;
			if (!isLocalStorageFull()) {
				removeTransaction(item);
			} else {
				console.warn('isLocalStorageFull: true !!!');
			}
		});
	}, [timeoutRecords]);

	return null;
}

function checkTimeoutRecord(txRecord: TransactionRecord) {
	return moment().isAfter(
		moment(moment(txRecord.date).valueOf() + RECORD_TIMEOUT).format(
			'YYYY MM DD HH:mm:ss'
		),
		'second'
	);
}
