import { useEffect, useMemo } from 'react';
import { useUpdateEffect } from 'react-use';

import { Page, Transaction_Status, Transaction_Type } from 'config/constants';
import { useAccount } from 'wagmi';

import { TransactionRecord } from 'entities/TransactionRecord';
import { useMiningPoolsGraph } from 'graphql/useMiningPoolsGraph';
import { useTradersReferralGraph } from 'graphql/useTradersReferralGraph';
import { useManageTransactions } from 'hooks/useAccountTransactions';
import { useCheckLogin } from 'hooks/useCurrentChain';
import { useCurrentPage } from 'hooks/useCurrentPage';
import { globalBaseState } from 'state/global/slice';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { selectTransactionRecordList } from 'state/records/selector';
import { settingBaseState } from 'state/setting/slice';

import {
	setBindReferralCodeLoading,
	setIsBindReferralCode,
	setIsEnableMining,
	setLeverage,
	setReferralCode
} from './slice';

export default function TradeUpdater() {
	const dispatch = useAppDispatch();
	const { appTimer } = useAppSelector(globalBaseState);
	const { address } = useAccount();
	const isLogin = useCheckLogin();
	const currentPage = useCurrentPage();

	const { checkedTradeLeverage, tradeLeverage } =
		useAppSelector(settingBaseState);
	const transactionRecords = useAppSelector(selectTransactionRecordList);

	const { removeTransaction } = useManageTransactions();

	const approveTransactioinRecords = useMemo(() => {
		return transactionRecords.filter(
			(item: TransactionRecord) =>
				item.type === Transaction_Type.Approve &&
				item.status !== Transaction_Status.Pending
		);
	}, [transactionRecords]);

	// 初始化默认杠杆
	useEffect(() => {
		if (checkedTradeLeverage) {
			dispatch(setLeverage(tradeLeverage));
		} else {
			dispatch(setLeverage(0));
		}
	}, [checkedTradeLeverage, tradeLeverage]);

	// 移除授权类的本地交易记录
	useEffect(() => {
		approveTransactioinRecords.forEach((item: TransactionRecord) => {
			item.syncing = false;
			removeTransaction(item);
		});
	}, [approveTransactioinRecords]);

	const {
		data,
		refetch: tradersReferralRefetch,
		loading
	} = useTradersReferralGraph();
	const { isEnableMining } = useMiningPoolsGraph();

	useEffect(() => {
		dispatch(setBindReferralCodeLoading(loading));

		if (data) {
			dispatch(setIsBindReferralCode(true));
			dispatch(setReferralCode(data?.referralCode.id));
		} else {
			dispatch(setIsBindReferralCode(false));
			dispatch(setReferralCode(''));
		}
	}, [data, loading]);

	useUpdateEffect(() => {
		dispatch(setIsBindReferralCode(undefined));
	}, [address]);

	useEffect(() => {
		dispatch(setIsEnableMining(isEnableMining));
	}, [isEnableMining]);

	useEffect(() => {
		if (isLogin && currentPage === Page.Referrals) {
			tradersReferralRefetch();
		}
	}, [currentPage, address, appTimer, isLogin]);

	return null;
}
