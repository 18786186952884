import React, { useMemo } from 'react';

import { Trans } from '@lingui/macro';
import { Radio, RadioGroup, Typography } from '@mui/material';

import { keyBy } from 'lodash';
import { StyledRadio } from 'pages/Earn/Stake/StakeDialog';
import { Lockup_Period } from 'pages/Earn/types';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import {
	formatNumber,
	isNumeric,
	isPositive,
	minus,
	multipliedBy,
	shortenSymbol,
	toPercent
} from 'utils';

import Cell from 'components/Common/Cell';
import { CommonStyledDivider } from 'components/Common/Styled';

interface PeriodItem {
	period: Lockup_Period;
	rate: number;
}

const PERIOD_TO_LOCK_AMOUNT_RATES: Array<PeriodItem> = [
	{
		period: Lockup_Period.HIGH,
		rate: 1
	},
	{
		period: Lockup_Period.MEDIUM,
		rate: 0.5
	},
	{
		period: Lockup_Period.LOW,
		rate: 0.25
	},
	{
		period: Lockup_Period.LOWEST,
		rate: 0
	}
];

const PERIOD_TO_MAP = keyBy(PERIOD_TO_LOCK_AMOUNT_RATES, 'period');

const CLIAMABLE_RATE = 0.1;

export default React.memo(function ClaimPeriods({
	period,
	setPeriod,
	totalAmount,
	quoteAmount
}: {
	period: Lockup_Period;
	setPeriod: (value: Lockup_Period) => void;
	totalAmount: string;
	quoteAmount?: string;
}) {
	const { appToken, quoteToken } = useAppSelector(txBaseState);

	const { stakeAmount, burnAmount, claimableAmount } = useMemo(() => {
		if (!isPositive(totalAmount) || !isNumeric(period)) {
			return {
				stakeAmount: '',
				burnAmount: '',
				claimableAmount: ''
			};
		}
		const targetItem = PERIOD_TO_LOCK_AMOUNT_RATES.find(
			item => item.period === period
		);
		const stakeAmount = multipliedBy(totalAmount, targetItem.rate);
		const burnAmount =
			period === Lockup_Period.LOWEST
				? multipliedBy(totalAmount, minus(1, CLIAMABLE_RATE))
				: multipliedBy(totalAmount, minus(1, targetItem.rate));
		let claimableAmount = '0';
		if (period === Lockup_Period.LOWEST) {
			claimableAmount = multipliedBy(totalAmount, CLIAMABLE_RATE);
		}
		return {
			stakeAmount,
			burnAmount,
			claimableAmount
		};
	}, [totalAmount, period]);

	const handleChangePeriod = (value: Lockup_Period) => {
		setPeriod(value);
	};

	return (
		<article className='space-y-2'>
			{isPositive(totalAmount) && (
				<section className='space-y-2'>
					<div className='space-y-1'>
						<Typography variant='body2' color='secondary'>
							<Trans>{shortenSymbol(appToken)} Lock Duration</Trans>
						</Typography>
						<RadioGroup row className='grid grid-cols-4 gap-2' value={period}>
							{PERIOD_TO_LOCK_AMOUNT_RATES.map(
								(item: PeriodItem, index: number) => {
									return (
										<StyledRadio
											key={index}
											value={item.period}
											control={<Radio size='small' />}
											onClick={() => handleChangePeriod(item.period)}
											label={
												<Typography
													color={
														period === item.period
															? 'text.primary'
															: 'text.secondary'
													}
													align='center'
												>
													{item.period === Lockup_Period.LOWEST ? (
														<Trans>No Lock</Trans>
													) : (
														<Trans>{item.period} days</Trans>
													)}
												</Typography>
											}
										/>
									);
								}
							)}
						</RadioGroup>
					</div>
					<div className='space-y-2'>
						{period !== Lockup_Period.LOWEST ? (
							<Cell
								label={
									<div className='flex items-center space-x-1'>
										<Typography variant='body2'>
											<Trans>Stake Amount</Trans>
										</Typography>
										<Typography variant='body2'>
											{`(${toPercent(PERIOD_TO_MAP[period].rate, 0)})`}
										</Typography>
									</div>
								}
								value={
									<Typography variant='body2'>
										{`${formatNumber(
											stakeAmount,
											appToken.positionUnits
										)} ${shortenSymbol(appToken)}`}
									</Typography>
								}
							/>
						) : (
							<Cell
								label={
									<div className='flex items-center space-x-1'>
										<Typography variant='body2'>
											<Trans>Claim Amount</Trans>
										</Typography>
										<Typography variant='body2'>
											{`(${toPercent(CLIAMABLE_RATE, 0)})`}
										</Typography>
									</div>
								}
								value={
									<Typography variant='body2'>
										{`${formatNumber(
											claimableAmount,
											appToken.positionUnits
										)} ${shortenSymbol(appToken)}`}
									</Typography>
								}
							/>
						)}
						<Cell
							label={
								<div className='flex items-center space-x-1'>
									<Typography variant='body2'>
										<Trans>Burn Amount</Trans>
									</Typography>
									<Typography variant='body2'>
										{period !== Lockup_Period.LOWEST
											? `(${toPercent(
													minus(1, PERIOD_TO_MAP[period].rate),
													0
											  )})`
											: `(${toPercent(minus(1, CLIAMABLE_RATE), 0)})`}
									</Typography>
								</div>
							}
							value={
								<Typography variant='body2'>
									{`${formatNumber(
										burnAmount,
										appToken.positionUnits
									)} ${shortenSymbol(appToken)}`}
								</Typography>
							}
						/>
					</div>
					<CommonStyledDivider />
				</section>
			)}
			<section>
				<Cell
					label={
						<Typography color='text.secondary' variant='body2'>
							<Trans>You will receive</Trans>
						</Typography>
					}
					value={
						<div className='flex space-x-3'>
							{!isPositive(quoteAmount) && claimableAmount === '' ? (
								<Typography variant='body2'>-</Typography>
							) : isPositive(quoteAmount) && claimableAmount !== '' ? (
								<>
									<Typography variant='body2'>{`${formatNumber(
										quoteAmount
									)} ${shortenSymbol(quoteToken)}`}</Typography>
									<Typography variant='body2'>
										{`${formatNumber(
											claimableAmount,
											appToken.positionUnits
										)} ${shortenSymbol(appToken)}`}
									</Typography>
								</>
							) : isPositive(quoteAmount) ? (
								<Typography variant='body2'>{`${formatNumber(
									quoteAmount
								)} ${shortenSymbol(quoteToken)}`}</Typography>
							) : (
								<Typography variant='body2'>
									{`${formatNumber(
										claimableAmount,
										appToken.positionUnits
									)} ${shortenSymbol(appToken)}`}
								</Typography>
							)}
						</div>
					}
				/>
			</section>
		</article>
	);
});
