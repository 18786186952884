import { useEffect, useMemo } from 'react';

import { Trans, t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { Transaction_Type } from 'config/constants';

import useSubmitUnStakeAppToken from 'hooks/useSubmitUnStakeAppToken';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import type { Merge } from 'type-fest';
import { IStakeDetail } from 'types';
import { formatNumber, shortenSymbol } from 'utils';

import Cell from 'components/Common/Cell';
import Dialog, { DialogProps } from 'components/Common/Dialog';

const UnStakeDialog = (
	props: Merge<DialogProps, { item: IStakeDetail | null }>
) => {
	const { onClose, item } = props;

	const { signingMap, quoteToken, appToken } = useAppSelector(txBaseState);

	const { onConfirm, isConfirmed, isConfirming } = useSubmitUnStakeAppToken(
		item?.stakeID,
		item?.stakedAmount,
		item?.claimableReward
	);

	const disabled = useMemo(() => {
		if (signingMap.get(Transaction_Type.UnStakeAppToken)) {
			return true;
		}
		if (isConfirming) {
			return true;
		}
	}, [signingMap, isConfirming]);

	const submitText = useMemo(() => {
		if (signingMap.get(Transaction_Type.UnStakeAppToken) || isConfirming) {
			return <Trans>Loading...</Trans>;
		}
		return <Trans>Confirm</Trans>;
	}, [signingMap, isConfirming]);

	useEffect(() => {
		if (isConfirmed) {
			onClose();
		}
	}, [isConfirmed]);

	return (
		<Dialog
			onConfirm={onConfirm}
			open
			onClose={onClose}
			title={`${t`Unstake`} ${shortenSymbol(appToken)}`}
			disabled={disabled}
			confirmLabel={submitText}
		>
			<Typography variant='body2' color='secondary'>
				<Trans>You will receive:</Trans>
			</Typography>
			{item && (
				<div className='mt-3 space-y-1'>
					<Cell
						label={
							<Typography variant='body2'>{shortenSymbol(appToken)}</Typography>
						}
						value={`${formatNumber(item.stakedAmount)} ${shortenSymbol(
							appToken
						)}`}
					/>

					<Cell
						label={
							<Typography variant='body2'>
								<Trans>Claimable Rewards</Trans>
							</Typography>
						}
						value={`${formatNumber(item.claimableReward)} ${shortenSymbol(
							quoteToken
						)}`}
					/>
				</div>
			)}
		</Dialog>
	);
};

export default UnStakeDialog;
