import { useMemo } from 'react';

import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { selectPositionMinExecutionFee } from 'state/global/selector';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { formatNumber, isPositive, shortenSymbolNative, toQuoteAmount, parseUnits, isEqualTo } from 'utils';
import { getContractAddressV2 } from 'config/contracts';
import { PositionRouterV2ABI } from 'config/abis';
import { Contract_Write_Mode, QUOTE_USD, Transaction_Type} from 'config/constants';
import { RecordForRemoveLiquidity, TokenInfo } from '../../../../types';
import { useComputeGasLimit } from 'hooks/useComputeGasLimit';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { useSendTransaction } from 'hooks/useSendTransaction';
import { usePriceRangesByTolerance } from 'hooks/useSlippageTolerance';

const useSubmitRemoveLiquidityV2 = (
	marketAddress: string,
	marginDelta: string,
	afterMargin:string,
	liquidityDelta: string,
    baseToken:TokenInfo,
	myLiquidity:string,
) => {
	const { quoteToken } = useAppSelector(txBaseState);
	const positionMinExecutionFee = useAppSelector(selectPositionMinExecutionFee);

	const { currentChainId } = useCurrentChain();
	const { address } = useAccount();
	const marginNet = isEqualTo(liquidityDelta, myLiquidity) ? '0': marginDelta
    const { minPrice:minMargin} = usePriceRangesByTolerance(afterMargin);

	const { args, record, description } = useMemo(() => {
		if (!baseToken || !quoteToken) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}
        
        const margin = parseUnits(marginNet, quoteToken.decimals); 
		const liquidity = parseUnits(liquidityDelta, quoteToken.decimals);
		const acceptableMinMargin = parseUnits(minMargin, quoteToken.decimals);
		const args = [marketAddress, margin, liquidity, acceptableMinMargin, address];
		
		const record = {
			transactionType: Transaction_Type.RemoveLiquidityV2,
			pool: marketAddress,
			positionId:'',
			baseToken
		} as RecordForRemoveLiquidity;

		const description = TransactionDescription.CreateCloseLiquidityPosition(
			`${shortenSymbolNative(baseToken, currentChainId)}/${QUOTE_USD}`,
			toQuoteAmount(liquidityDelta),
			formatNumber(marginDelta)
		);

		return {
			args,
			record,
			description
		};
	}, [
        address,
		quoteToken,
		marketAddress,
		marginDelta,
		liquidityDelta,
		currentChainId,
		minMargin
	]);

	const overrides = useMemo(() => {
		if (!address || !isPositive(positionMinExecutionFee)) {
			return undefined;
		}
		return {
			from: address,
			value: positionMinExecutionFee
		};
	}, [address, positionMinExecutionFee]);
   
	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address: getContractAddressV2(currentChainId, 'PositionRouter'),
			abi: PositionRouterV2ABI,
			functionName: 'createDecreaseLiquidityPosition',
			args,
			overrides
		} as UseContractWriteConfig;
	}, [currentChainId, args, address, overrides]);

	const { gasLimit } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, setTxSuccessCallback, isConfirmed, isConfirming } =
		useSendTransaction(contractWriteArgs, gasLimit);

	const onConfirm = () => {
		if (!record) return;
		onSendTransaction(record, description);
	};

	return { onConfirm, setTxSuccessCallback, isConfirmed, isConfirming };
};

export default useSubmitRemoveLiquidityV2;
