import { t } from '@lingui/macro';
import { MSG_ERROR_APPROVE } from 'config/constants';
import { Chain } from 'wagmi';

export function formatError(error: any, chain?: Chain) {
	if (!error) {
		return '';
	}

	let errMsg = 'Something went wrong. Please try again.';
	if (typeof error === 'string') {
		errMsg = error;
	} else if (error?.reason) {
		errMsg = error.reason;
	} else if (error?.data?.message) {
		errMsg = error.data.message;
	} else if (error?.message) {
		errMsg = error.message;
	}
	if (Array.isArray(error) && error.length === 1) {
		return formatError(error[0], chain);
	}

	const rpcEndpointsDocs = 'https://chainlist.org/chain/' + chain?.id;

	// TODO: handle custom error messages elsewhere (and better)
	if (
		errMsg.includes('not enough funds for gas') ||
		errMsg.includes('insufficient funds') ||
		errMsg.includes('Insufficient funds')
	) {
		const feeToken = chain?.nativeCurrency.symbol || 'funds';
		errMsg = `Insufficient balance. Please add ${feeToken} to pay for tx fees. `;
	} else if (
		errMsg.includes('NetworkError when attempting to fetch resource')
	) {
		errMsg = `${errMsg} Please check your wallet network settings are correct and try again. More info: ${rpcEndpointsDocs}`;
	} else if (errMsg.includes('[ethjs-query]')) {
		errMsg = prettifyErrorMessage(errMsg);
	} else if (errMsg.includes('while formatting outputs from RPC')) {
		errMsg = `An RPC error occurred. Please check your wallet network settings are correct and refresh page to try again. More info: ${rpcEndpointsDocs}. `;
	} else if (
		errMsg.includes('Failed to fetch') ||
		errMsg.includes('could not detect network') ||
		errMsg.includes('Not Found') ||
		errMsg.includes('Non-200 status code')
	) {
		errMsg = `There was a network error. Please disable any ad blockers and check your wallet network settings are correct and refresh page to try again. More info: ${rpcEndpointsDocs}. `;
	} else if (
		errMsg.includes('unsupported block number') ||
		errMsg.includes('rlp: expected List') ||
		errMsg.includes('PermissionDenied, permission denied for tx type: Call')
	) {
		errMsg = `An RPC error occurred. Please refresh page to try again. `;
	} else if (errMsg.includes('transaction underpriced')) {
		errMsg = `An RPC error occurred. The transaction is underpriced. Please try again and increase gas price. If you are seeing is error a lot, try resetting the nonce for your wallet account. `;
	} else if (
		errMsg.includes('header not found') ||
		errMsg.includes('intrinsic gas too low')
	) {
		errMsg = `An RPC error occurred. Please check your wallet network settings are correct and try again. Consider using a different RPC provider if you are seeing this error frequently. More info: ${rpcEndpointsDocs}. `;
	} else if (
		errMsg.includes('sequencer transaction forwarding not configured') ||
		errMsg.includes('rate limit') ||
		errMsg.includes('compute units') ||
		errMsg.includes('Optimism sequencer global transaction limit exceeded') ||
		errMsg.includes('request timed out')
	) {
		errMsg = `An RPC error occurred. Please try again. Consider using a different RPC provider if you are seeing this error often. More info: ${rpcEndpointsDocs}. `;
	} else if (errMsg.includes('already minted')) {
		errMsg =
			'Account has already minted tokens. Only one mint per account is allowed.';
	} else if (
		errMsg.includes('user rejected transaction') ||
		errMsg.includes('ACTION_REJECTED') ||
		error.code === 4001
	) {
		errMsg = t`Transaction was cancelled.`;
	} else if (errMsg.includes('Cannot transfer staked or escrowed SNX')) {
		errMsg = `Cannot transfer staked or escrowed SNX. `;
	} else if (
		errMsg.includes('Internal JSON-RPC error') ||
		errMsg.includes('Internal error')
	) {
		const feeToken = chain?.nativeCurrency.symbol || 'funds';
		errMsg = `An RPC error occurred. Please check you have enough ${feeToken} to pay for fees and check your wallet network settings are correct. Refresh to try again. More info: ${rpcEndpointsDocs}. `;
	} else if (
		errMsg.includes('call revert exception') ||
		errMsg.includes('missing revert data')
	) {
		errMsg = `An RPC error occurred. Please check your wallet network settings are correct and refresh page to try again. More info: ${rpcEndpointsDocs}. `;
	} else if (errMsg.includes(MSG_ERROR_APPROVE)) {
		errMsg = MSG_ERROR_APPROVE;
	}

	return errMsg;
}

export const prettifyErrorMessage = (str = '') => {
	if (!str) {
		return '';
	}
	return str.replace(/.*\[ethjs-query\].*"message":"(.*)"\}.*/, '$1');
};
