interface SVGProps {
    size?: number;
}

function TokenLogo({ size = 24 }: SVGProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 32 32"
        >
            <g clipPath="url(#clip0_16658_44563)">
                <circle cx="16" cy="16" r="16" fill="#111"></circle>
                <path
                    fill="#C7EE00"
                    d="M24.723 10.563l-8.55 4.412a.36.36 0 01-.33 0l-8.606-4.433a.36.36 0 01.002-.64l8.58-4.383a.36.36 0 01.328 0l8.575 4.403a.36.36 0 01.001.64zM6.506 22.104l8.088 4.215a.36.36 0 00.527-.32v-9.27a.36.36 0 00-.195-.32l-8.089-4.171a.36.36 0 00-.525.32v9.226a.36.36 0 00.194.32z"
                ></path>
                <path
                    fill="#111"
                    fillRule="evenodd"
                    d="M12.619 22.412a.372.372 0 01-.52.465l-.468-.25a.371.371 0 01-.17-.19l-.794-1.989-.74.951a.372.372 0 01-.463.102l-.509-.262a.372.372 0 01-.122-.559l1.275-1.632-1.19-2.985a.372.372 0 01.516-.467l.472.244c.079.04.14.11.174.192l.805 2.022.75-.96a.372.372 0 01.464-.102l.498.257a.372.372 0 01.122.558l-1.278 1.645 1.178 2.96z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#C7EE00"
                    d="M25.494 22.103l-8.088 4.215a.36.36 0 01-.527-.32v-9.27a.36.36 0 01.195-.32l8.089-4.171a.36.36 0 01.525.32v9.226a.36.36 0 01-.194.32z"
                ></path>
                <path
                    fill="#111"
                    fillRule="evenodd"
                    d="M16.908 7.677a.372.372 0 01.345.002l.593.316c.265.14.262.52-.004.658L12.709 11.3a.372.372 0 01-.345-.002l-.593-.316a.371.371 0 01.004-.658l5.133-2.647zm2.66 1.33a.371.371 0 01.345.002l.593.316c.264.14.262.52-.004.658l-5.196 2.682a.372.372 0 01-.345-.002l-.593-.316a.372.372 0 01.005-.658l5.195-2.682zM21.972 16.186a.372.372 0 00-.533-.334l-.566.272a.371.371 0 00-.21.335v2.447l-2.206 1.06a.371.371 0 00-.21.335v.628c0 .274.285.453.532.335l1.884-.906v2.09c0 .274.286.454.532.336l.566-.272a.372.372 0 00.211-.335v-2.448l2.205-1.06a.371.371 0 00.211-.335v-.628a.372.372 0 00-.532-.335l-1.884.905v-2.09z"
                    clipRule="evenodd"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_16658_44563">
                    <path fill="#fff" d="M0 0H32V32H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default TokenLogo;
