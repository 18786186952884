import React, { useContext, useMemo, useState } from 'react';

import { Trans } from '@lingui/macro';
import { CardContent, Typography } from '@mui/material';
import {
	MAX_PRECISION,
	QUOTE_USD,
	QUOTE_USD_PRECISION,
	Side
} from 'config/constants';

import { useCurrentChain } from 'hooks/useCurrentChain';
import {
	checkInputNumberic,
	div,
	formatNumber,
	isEqualTo,
	isPositive,
	minus,
	multipliedBy,
	shortenSymbolNative,
	toDecimalPlaces,
	toFormattedPercent
} from 'utils';

import Cell from 'components/Common/Cell';
import {
	CommonStyledDivider,
	CommonStyledFilledInput,
	CommonStyledMaxButton
} from 'components/Common/Styled';
import CommonButton from 'components/Common/StyledButton';

import { useAppBreakpoints } from '../../../hooks/useAppBreakpoints';
import { ComputerContext } from './ComputerContext';
import ComputerDirection from './ComputerDirection';
import ComputerLeverage from './ComputerLeverage';
import { StyledCard } from './StyledCard';

export default React.memo(function ComputerPnL() {
	const { currentPool, side, leverage, marketPrice } =
		useContext(ComputerContext);
	const { currentChainId } = useCurrentChain();

	const [entryPrice, setEntryPrice] = useState<string>('');
	const [takeProfitPrice, setTakeProfitPrice] = useState<string>('');
	const [stopLossPrice, setStopLossPrice] = useState<string>('');
	const [amount, setAmount] = useState<string>('');

	const [margin, setMargin] = useState<string>('');
	const [riskReward, setRiskReward] = useState<string>('');
	const [takeProfitPnL, setTakeProfitPnL] = useState<string>('');
	const [takeProfitROI, setTakeProfitROI] = useState<string>('');
	const [stopLossPnL, setStopLossPnL] = useState<string>('');
	const [stopLossROI, setStopLossROI] = useState<string>('');

	const onHandleConfirm = () => {
		const margin = div(multipliedBy(entryPrice, amount), leverage);
		let riskReward = '0';
		if (!isEqualTo(takeProfitPrice, entryPrice)) {
			if (side === Side.LONG) {
				riskReward = div(
					minus(entryPrice, stopLossPrice),
					minus(takeProfitPrice, entryPrice)
				);
			} else {
				riskReward = div(
					minus(stopLossPrice, entryPrice),
					minus(entryPrice, takeProfitPrice)
				);
			}
		}
		const takeProfitPnL =
			side === Side.LONG
				? multipliedBy(minus(takeProfitPrice, entryPrice), amount)
				: multipliedBy(minus(entryPrice, takeProfitPrice), amount);
		const takeProfitROI = div(takeProfitPnL, margin);
		const stopLossPnL =
			side === Side.LONG
				? multipliedBy(minus(stopLossPrice, entryPrice), amount)
				: multipliedBy(minus(entryPrice, stopLossPrice), amount);
		const stopLossROI = div(stopLossPnL, margin);

		setMargin(margin);
		setRiskReward(riskReward);
		setTakeProfitPnL(takeProfitPnL);
		setTakeProfitROI(takeProfitROI);
		setStopLossPnL(stopLossPnL);
		setStopLossROI(stopLossROI);
	};

	const disabled = useMemo(() => {
		if (
			!isPositive(leverage) ||
			!isPositive(entryPrice) ||
			!isPositive(takeProfitPrice) ||
			!isPositive(stopLossPrice) ||
			!isPositive(amount)
		) {
			return true;
		}
		return false;
	}, [leverage, entryPrice, takeProfitPrice, stopLossPrice, amount]);

	const onChangeEntryPrice: React.ChangeEventHandler<HTMLInputElement> = e => {
		const value = e.target.value;
		if (value.trim() === '') {
			setEntryPrice('');
		}
		if (checkInputNumberic(value, QUOTE_USD_PRECISION)) {
			setEntryPrice(value);
		}
	};

	const onChangeTakeProfitPrice: React.ChangeEventHandler<
		HTMLInputElement
	> = e => {
		const value = e.target.value;
		if (value.trim() === '') {
			setTakeProfitPrice('');
		}
		if (checkInputNumberic(value, QUOTE_USD_PRECISION)) {
			setTakeProfitPrice(value);
		}
	};

	const onChangeStopLossPrice: React.ChangeEventHandler<
		HTMLInputElement
	> = e => {
		const value = e.target.value;
		if (value.trim() === '') {
			setStopLossPrice('');
		}
		if (checkInputNumberic(value, QUOTE_USD_PRECISION)) {
			setStopLossPrice(value);
		}
	};

	const onChangeAmount: React.ChangeEventHandler<HTMLInputElement> = e => {
		const value = e.target.value;
		if (value.trim() === '') {
			setAmount('');
		}
		if (checkInputNumberic(value, currentPool?.baseToken.decimals)) {
			setAmount(value);
		}
	};

	const onHandleSetMarketPrice = () => {
		if (isPositive(marketPrice)) {
			setEntryPrice(
				toDecimalPlaces(marketPrice, currentPool?.baseToken.precision)
			);
		}
	};
	const { isMatchMobile } = useAppBreakpoints();

	return (
		<article className='flex sm:flex-col-reverse space-x-4 sm:space-x-0'>
			<section className='flex-1 space-y-4 sm:mt-4'>
				<ComputerDirection />
				<ComputerLeverage />
				<div className='space-y-2'>
					<CommonStyledFilledInput
						type='text'
						inputProps={{
							maxLength: MAX_PRECISION,
							inputMode: 'decimal'
						}}
						sx={{
							'& .MuiInputBase-input': {
								paddingRight: '8px'
							}
						}}
						value={entryPrice}
						onChange={onChangeEntryPrice}
						fullWidth
						startAdornment={
							<div className='flex items-center space-x-1'>
								<Typography
									className='min-w-max'
									variant='body2'
									color='secondary'
								>
									<Trans>Entry price</Trans>
								</Typography>
								<CommonStyledMaxButton
									className='px-2 font-normal text-xs'
									variant='outlined'
									color='secondary'
									onClick={onHandleSetMarketPrice}
								>
									<Trans>trade.Market</Trans>
								</CommonStyledMaxButton>
							</div>
						}
						endAdornment={
							<Typography
								className='text-right'
								variant='body2'
								color='secondary'
							>
								{QUOTE_USD}
							</Typography>
						}
					/>
					<CommonStyledFilledInput
						type='text'
						inputProps={{
							maxLength: MAX_PRECISION,
							inputMode: 'decimal'
						}}
						sx={{
							'& .MuiInputBase-input': {
								paddingRight: '8px'
							}
						}}
						value={takeProfitPrice}
						onChange={onChangeTakeProfitPrice}
						fullWidth
						startAdornment={
							<Typography
								className='min-w-max'
								variant='body2'
								color='secondary'
							>
								<Trans>TP trigger price</Trans>
							</Typography>
						}
						endAdornment={
							<Typography
								className='text-right'
								variant='body2'
								color='secondary'
							>
								{QUOTE_USD}
							</Typography>
						}
					/>
					<CommonStyledFilledInput
						type='text'
						inputProps={{
							maxLength: MAX_PRECISION,
							inputMode: 'decimal'
						}}
						sx={{
							'& .MuiInputBase-input': {
								paddingRight: '8px'
							}
						}}
						value={stopLossPrice}
						onChange={onChangeStopLossPrice}
						fullWidth
						startAdornment={
							<Typography
								className='min-w-max'
								variant='body2'
								color='secondary'
							>
								<Trans>SL trigger price</Trans>
							</Typography>
						}
						endAdornment={
							<Typography
								className='text-right'
								variant='body2'
								color='secondary'
							>
								{QUOTE_USD}
							</Typography>
						}
					/>
					<CommonStyledFilledInput
						type='text'
						inputProps={{
							maxLength: MAX_PRECISION,
							inputMode: 'decimal'
						}}
						sx={{
							'& .MuiInputBase-input': {
								paddingRight: '8px'
							}
						}}
						value={amount}
						onChange={onChangeAmount}
						fullWidth
						startAdornment={
							<Typography
								className='min-w-max'
								variant='body2'
								color='secondary'
							>
								<Trans>Size</Trans>
							</Typography>
						}
						endAdornment={
							<Typography
								className='text-right'
								variant='body2'
								color='secondary'
							>
								{shortenSymbolNative(currentPool?.baseToken, currentChainId)}
							</Typography>
						}
					/>
				</div>
				<CommonButton
					className='flex flex-col justify-center'
					variant='contained'
					fullWidth
					disabled={disabled}
					onClick={onHandleConfirm}
				>
					<Typography
						className='whitespace-normal text-sm'
						color='inherit'
						variant='h6'
						fontWeight={500}
					>
						<Trans>Calculate</Trans>
					</Typography>
				</CommonButton>
			</section>
			<StyledCard isMatchMobile={isMatchMobile} className='flex-1'>
				<CardContent className='space-y-4'>
					<Typography variant='h6'>
						<Trans>Result</Trans>
					</Typography>
					<CommonStyledDivider />
					<div className='space-y-2 sm:space-y-1'>
						<Cell
							label={<Trans>Margin</Trans>}
							value={
								<Typography variant='body2' noWrap title={formatNumber(margin)}>
									{formatNumber(margin)}
								</Typography>
							}
						/>
						<Cell
							label={<Trans>Risk / Reward</Trans>}
							value={
								<Typography
									variant='body2'
									noWrap
									title={riskReward ? toFormattedPercent(riskReward) : '-'}
								>
									{riskReward ? toFormattedPercent(riskReward) : '-'}
								</Typography>
							}
						/>
						<Cell
							label={<Trans>TP PnL</Trans>}
							value={
								<Typography
									variant='body2'
									noWrap
									title={formatNumber(takeProfitPnL)}
								>
									{formatNumber(takeProfitPnL)}
								</Typography>
							}
						/>
						<Cell
							label={<Trans>TP PnL%</Trans>}
							value={
								<Typography
									variant='body2'
									noWrap
									title={
										takeProfitROI ? toFormattedPercent(takeProfitROI) : '-'
									}
								>
									{takeProfitROI ? toFormattedPercent(takeProfitROI) : '-'}
								</Typography>
							}
						/>
						<Cell
							label={<Trans>SL PnL</Trans>}
							value={
								<Typography
									variant='body2'
									noWrap
									title={formatNumber(stopLossPnL)}
								>
									{formatNumber(stopLossPnL)}
								</Typography>
							}
						/>
						<Cell
							label={<Trans>SL PnL%</Trans>}
							value={
								<Typography
									variant='body2'
									noWrap
									title={stopLossROI ? toFormattedPercent(stopLossROI) : '-'}
								>
									{stopLossROI ? toFormattedPercent(stopLossROI) : '-'}
								</Typography>
							}
						/>
					</div>
					<CommonStyledDivider />
					<Typography variant='body2' fontSize={12} color='secondary'>
						{side === Side.LONG ? (
							<Trans>
								The risk/reward ratio is calculated by dividing the potential
								risk in trading by the expected rewards. The calculation formula
								is: Risk/Reward Ratio = (Entry Price - Stop Loss Trigger Price)
								/ (Take Profit Trigger Price - Entry Price) * 100%
							</Trans>
						) : (
							<Trans>
								The risk/reward ratio is calculated by dividing the potential
								risk in trading by the expected rewards. The calculation formula
								is: Risk/Reward Ratio = (Stop Loss Trigger Price - Entry Price)
								/ (Entry Price - Take Profit Trigger Price) * 100%
							</Trans>
						)}
					</Typography>
				</CardContent>
			</StyledCard>
		</article>
	);
});
