import moment from 'moment';

import { isGreaterThan, isZero } from './mathUtils';

moment.locale('en-ca');

export function formatDate(
	value: string | number | moment.MomentInput,
	pattern = 'MMM D, YYYY HH:mm:ss'
) {
	return moment(Number(value)).format(pattern);
}

/**
 * Format Unix timestamp
 * @param value
 * @param pattern
 * @returns
 */
export function formatUnixDate(
	value: string | number | moment.MomentInput,
	pattern = 'MMM D, HH:mm:ss'
) {
	return moment(Number(value) * 1000).format(pattern);
}

export function formatDuration(
	value: string | number,
	currentTimestamp: string | number,
	unitOfTime: moment.unitOfTime.Diff = 'days'
) {
	const _currentTimestamp = Number(currentTimestamp);
	if (isZero(_currentTimestamp)) {
		return '0';
	}
	if (isGreaterThan(_currentTimestamp, value)) {
		return '0';
	}
	return (
		moment(Number(value) * 1000).diff(_currentTimestamp * 1000, unitOfTime) + 1
	);
}

export function unitTime(dateMap, mapTimeValue, number, key) {
	const newSecond =
		mapTimeValue[key[number + 1]] -
		dateMap[key[number]].unit * mapTimeValue[key[number]];
	const firstUnit =
		mapTimeValue[key[number]] > 1
			? dateMap[key[number]].plural
			: dateMap[key[number]].odd;
	const secondUnit =
		newSecond > 1
			? dateMap[key[number + 1]].plural
			: dateMap[key[number + 1]].odd;
	const firstTime = mapTimeValue[key[number]];
	const secondTime = newSecond;
	const formatTime =
		secondTime && secondTime > 0
			? `${firstTime}${firstUnit} ${secondTime}${secondUnit}`
			: `${firstTime} ${firstUnit}`;
	return {
		firstTime,
		secondTime,
		firstUnit,
		secondUnit,
		formatTime
	};
}

export function timeDiffFormat(time) {
	const start = moment(time);
	const end = moment();
	let timeObj = '';
	const dateMap = {
		day: {
			key: 'days',
			plural: 'days',
			odd: 'day',
			unit: 24
		},
		hour: {
			key: 'hours',
			plural: 'hrs',
			odd: 'hr',
			unit: 60
		},
		minute: {
			key: 'minutes',
			plural: 'mins',
			odd: 'min',
			unit: 60
		},
		second: {
			key: 'seconds',
			plural: 'secs',
			odd: 'sec'
		}
	};

	const mapTimeValue = {};

	for (const i in dateMap) {
		mapTimeValue[i] = end.diff(start, dateMap[i].key);
	}

	const key = ['day', 'hour', 'minute', 'second'];
	// let key = ['year','month','day','hour','minute','second']

	let firstUnit = '';
	const secondUnit = '';
	let firstTime = '';
	const secondTime = '';

	if (mapTimeValue[key[0]] >= 1) {
		timeObj = unitTime(dateMap, mapTimeValue, 0, key);
	} else if (mapTimeValue[key[1]] >= 1) {
		timeObj = unitTime(dateMap, mapTimeValue, 1, key);
	} else if (mapTimeValue[key[2]] >= 1) {
		timeObj = unitTime(dateMap, mapTimeValue, 2, key);
	} else {
		firstUnit =
			mapTimeValue[key[3]] > 1 ? dateMap[key[3]].plural : dateMap[key[3]].odd;
		firstTime = mapTimeValue[key[3]];
		const formatTime =
			Number(firstTime) < 1 ? 'less than 1sec' : `${firstTime}${firstUnit}`;

		timeObj = {
			firstTime,
			secondTime: 0,
			firstUnit,
			secondUnit: null,
			formatTime: formatTime
		};
	}
	return timeObj;
}

export function formatTimeString(time) {
	const obj = timeDiffFormat(time);
	return obj?.formatTime + ' ago';
}
