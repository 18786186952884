import React from 'react';

import { Typography, TypographyProps } from '@mui/material';
import cn from 'classnames';
import { Dom_Size } from 'config/constants';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';

interface NoDataProps extends TypographyProps {
	size?: 'large' | 'small';
	className?: string;
}

export default React.memo(function NoData({
	children,
	size = 'small',
	className,
	height = Dom_Size.SECTION_LOADING,
	...restProps
}: NoDataProps) {
	const { isMatchMobile } = useAppBreakpoints();
	if (size === 'large') {
		return (
			<Typography
				variant='body2'
				color='secondary'
				className={cn(
					'flex items-center',
					restProps.align === 'left' ? '' : 'justify-center',
					className
					// isMatchMobile ? 'pt-3 pb-4' : ''
				)}
				sx={{
					minHeight: height
				}}
				{...restProps}
			>
				{children}
			</Typography>
		);
	}
	return (
		<Typography
			variant='body2'
			color='secondary'
			className={`${className}`}
			{...restProps}
		>
			{children}
		</Typography>
	);
});
