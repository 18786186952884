import React, {
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useUpdate, useUpdateEffect } from 'react-use';

import { Trans } from '@lingui/macro';
import ArrowDownIcon from '@mui/icons-material/ExpandMore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import {
	// App_Theme,
	Dom_Size,
	Order_Open_Type_V1, // Order_Open_Type,
	Order_Status_V1,
	Page,
	QUOTE_USD,
	Side, // Transaction_Status
	Version
} from 'config/constants';
import { useAccount } from 'wagmi';

// import warningIcon from 'assets/svg/icon-warning.svg';
import { IOrderItem, useMyOrdersGraph } from 'graphql/useMyOrdersGraph';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import { useV1TradePositionRequest } from 'hooks/useLoopMyPositions';
import _ from 'lodash';
import ChartsPool, { Chart_Tab_Type } from 'pages/components/ChartsPool';
import FreeTradingFeeWarning from 'pages/components/FreeTradingFeeWarning';
import FundingRate from 'pages/components/FundingRate';
import HistoryList from 'pages/components/Operations/HistoryList';
import OpenOrdersList from 'pages/components/Operations/OpenOrdersList';
import PositionsList from 'pages/components/Operations/PositionsList';
import ViewUpdaterV1 from 'pages/components/ViewUpdaterV1';
import { globalBaseState } from 'state/global/slice';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { poolsBaseState, setPoolAddress } from 'state/pools/slice';
import { selectVersion } from 'state/setting/selector';
import { IPoolItem } from 'types';
import {
	amountFormatter,
	checkOrderIsExpired,
	formatModuleNumber,
	formatNumber,
	isLessThanOrEqualTo,
	isPositive,
	isZero,
	multipliedBy,
	shortenSymbolNative,
	toPercent,
	toQuoteAmount,
	toUsd
} from 'utils';

import { DirectionCell } from 'components/Common/Cell';
import { CommonStyledDivider } from 'components/Common/Styled';
import StyledTab from 'components/Common/StyledTab';
import StyledTabs from 'components/Common/StyledTabs';
// import Tooltip from 'components/Common/Tooltip';
import FundingFeeTimer from 'components/FundingFeeTimer';
import PriceChange, {
	StyledPriceChangeContainer
} from 'components/PriceChange';
import SectionLoading from 'components/SectionLoading';

import TippingUnderline from '../../components/TippingUnderline';
// import { IPositionItem } from '../../graphql/useMyPositionsGraph';
import { setOrders } from '../../state/trade/slice';
import ExpiredOrderWarning from '../components/ExpiredOrderWarning';

export enum Tab_Type {
	Positions = 0,
	Orders = 1,
	History = 2
}

interface HeaderProps {
	key: string;
	width: string;
	label: ReactNode;
	align: 'inherit' | 'left' | 'center' | 'right' | 'justify';
	cellRender: (row: IPoolItem) => JSX.Element;
}

interface RowState {
	tabType: Tab_Type;
	expand: boolean;
	chartType: Chart_Tab_Type;
}

const initalRowState = {
	tabType: Tab_Type.Positions,
	expand: false,
	chartType: Chart_Tab_Type.Price
};

const FORMATED_POSITION_AMOUNT_MAX = 999_999_999;

export default React.memo(function TradeTable({
	poolList
}: {
	poolList: Array<IPoolItem>;
}) {
	const { appTimer } = useAppSelector(globalBaseState);
	const { poolAddress } = useAppSelector(poolsBaseState);
	const currentVersion = useAppSelector(selectVersion);

	const [rowStates, setRowStates] = useState<Record<string, RowState>>({});

	const dispatch = useAppDispatch();

	const update = useUpdate();
	const theme = useTheme();
	const { address } = useAccount();
	const isLogin = useCheckLogin();
	const { currentChainId } = useCurrentChain();
	const { isMatchMobile } = useAppBreakpoints();
	const navigator = useNavigate();

	const { positionMap, isLoadingPositions } = useV1TradePositionRequest();

	const {
		ordersMap,
		loading: isLoadingOrders,
		refetch: refetchOrders
	} = useMyOrdersGraph();

	const onResetRowStates = useCallback((poolId: string) => {
		setRowStates(state => {
			return {
				[poolId]: state[poolId] || initalRowState
			};
		});
	}, []);

	useEffect(() => {
		if (!poolAddress) {
			return;
		}
		onResetRowStates(poolAddress);
	}, [poolAddress]);

	const [orderList, createdOrderList] = useMemo(() => {
		if (!poolAddress || !ordersMap || !ordersMap.has(poolAddress)) {
			return [null, null];
		}
		const orderList = ordersMap.get(poolAddress) || [];
		const createdOrderList = orderList.filter((item: IOrderItem) => {
			return item.orderStatus === Order_Status_V1.Created;
		});
		return [orderList, createdOrderList];
	}, [ordersMap, poolAddress]);

	const expiredOrders = useMemo(() => {
		if (!createdOrderList) {
			return [];
		}
		return createdOrderList.filter(order =>
			checkOrderIsExpired(order, positionMap, Order_Open_Type_V1)
		);
	}, [createdOrderList, positionMap]);
	useEffect(() => {
		dispatch(setOrders(expiredOrders));
	}, [expiredOrders]);

	const onHandleSetCurrentPool = (row: IPoolItem) => {
		dispatch(setPoolAddress(row.id));
		if (isMatchMobile) {
			navigator(`${Page.TradeV1}/${row.id}`);
		} else {
			navigator(`${Page.MarketsV1}/${row.id}`);
		}
	};

	const onHandleChangeOperationType = (value: Tab_Type, rowState: RowState) => {
		rowState.tabType = value;
		update();
	};

	const onHandleChangeChartType = (value: number, rowState: RowState) => {
		rowState.chartType = value;
		update();
	};

	const onHandleShowChart = (e: any, pool: IPoolItem) => {
		e.stopPropagation();
		onResetRowStates(pool.id);
		dispatch(setPoolAddress(pool.id));
		setRowStates(state => {
			return {
				[pool.id]: { ...state[pool.id], expand: !state[pool.id].expand }
			};
		});
	};

	const targetPoolsId = useMemo(() => {
		if (!poolAddress) {
			return null;
		}
		return [poolAddress];
	}, [poolAddress]);

	const headers: Array<HeaderProps> = [
		{
			key: 'baseSymbol',
			width: '13%',
			label: <Trans>Market</Trans>,
			align: 'left',
			cellRender: (row: IPoolItem) => {
				return (
					<div className='flex space-x-1'>
						<Typography fontWeight='medium' variant='h6'>
							{`${shortenSymbolNative(
								row.baseToken,
								currentChainId
							)}/${QUOTE_USD}`}
						</Typography>
						{/* {isZero(row.referralDiscountRate) && <FreeTradingFeeWarning />} */}
					</div>
				);
			}
		},
		{
			key: 'marketPrice',
			width: '17%',
			label: <Trans>Price / Index</Trans>,
			align: 'left',
			cellRender: (row: IPoolItem) => {
				return (
					<div>
						<Typography variant='h6'>
							{toQuoteAmount(row.price, row.baseToken?.precision)}
						</Typography>
						<Typography variant='body2' color='secondary'>
							{toQuoteAmount(row.indexPrice, row.baseToken?.precision)}
						</Typography>
					</div>
				);
			}
		},
		{
			key: 'price',
			width: '15%',
			label: <Trans>24h Change</Trans>,
			align: 'left',
			cellRender: (row: IPoolItem) => {
				return <PriceChange size={16} value={row.priceChange}></PriceChange>;
			}
		},
		{
			key: 'fundingRate',
			width: '15%',
			label: (
				<Typography variant='body2' color='text.secondary' align='left'>
					<TippingUnderline
						label={<Trans>1h Funding</Trans>}
						tooltip={
							<div className='space-y-2'>
								<Typography variant='body2'>
									<Trans>
										Funding fees are settled every hour. When the funding rate
										is positive, long positions pay short positions; when
										it&#39;s negative, short positions pay long positions.
									</Trans>
								</Typography>
							</div>
						}
					/>
				</Typography>
			),
			align: 'left',
			cellRender: (row: IPoolItem) => {
				return (
					<Box>
						<FundingRate value={row.globalPosition.fundingRate} />
						<FundingFeeTimer />
					</Box>
				);
			}
		},
		{
			key: 'positions',
			width: '18%',
			label: <Trans>Open Interest</Trans>,
			align: 'left',
			cellRender: (row: IPoolItem) => {
				const total = multipliedBy(row.price, row.positions);
				return (
					<div className='sm:flex sm:justify-end sm:flex-col'>
						<Typography variant='body2'>
							{amountFormatter(row.positions)}{' '}
							{shortenSymbolNative(row.baseToken, currentChainId)}
						</Typography>
						<Typography variant='body2' color='secondary'>
							{toUsd(total)}
						</Typography>
					</div>
				);
			}
		},
		{
			key: 'myPositions',
			width: '18%',
			label: <Trans>My Positions</Trans>,
			align: 'left',
			cellRender: (row: IPoolItem) => {
				if (!positionMap || !positionMap.size || !isLogin) {
					return (
						<Typography variant='body1' fontWeight={500}>
							-
						</Typography>
					);
				}
				const positions = positionMap.get(row.id);
				if (!positions) {
					return (
						<Typography variant='body1' fontWeight={500}>
							-
						</Typography>
					);
				}
				const longPosition = positions.find(item => item.side === Side.LONG);
				const shortPosition = positions.find(item => item.side === Side.SHORT);
				if (!longPosition?.size && !shortPosition?.size) {
					return (
						<Typography variant='body1' fontWeight={500}>
							-
						</Typography>
					);
				}

				return (
					<div
						className={classNames('flex items-center space-x-1', {
							'justify-end': isMatchMobile
						})}
					>
						<aside>
							{longPosition && (
								<Typography color='success.main' noWrap>
									<span>
										<Trans>Long</Trans>
									</span>
								</Typography>
							)}
							{shortPosition && (
								<Typography color='error.main' noWrap>
									<span>
										<Trans>Short</Trans>
									</span>
								</Typography>
							)}
						</aside>
						<div>
							{longPosition && (
								<Typography color='text.primary'>
									{isLessThanOrEqualTo(
										longPosition.size,
										FORMATED_POSITION_AMOUNT_MAX
									)
										? formatNumber(
												longPosition.size,
												row.baseToken.positionUnits
										  )
										: amountFormatter(longPosition.size)}{' '}
									{shortenSymbolNative(row.baseToken, currentChainId)}
								</Typography>
							)}
							{shortPosition && (
								<Typography color='text.primary'>
									{isLessThanOrEqualTo(
										shortPosition.size,
										FORMATED_POSITION_AMOUNT_MAX
									)
										? formatNumber(
												shortPosition.size,
												row.baseToken.positionUnits
										  )
										: amountFormatter(shortPosition.size)}{' '}
									{shortenSymbolNative(row.baseToken, currentChainId)}
								</Typography>
							)}
						</div>
					</div>
				);
			}
		},
		{
			key: 'index',
			width: '4%',
			label: '',
			align: 'right',
			cellRender: (row: IPoolItem) => {
				return (
					<div className='flex items-center justify-end'>
						<Typography
							onClick={e => onHandleShowChart(e, row)}
							color='text.secondary'
							className={classNames(
								'relative text-base -right-2 cursor-pointer rounded-full p-0.5 sm:hidden',
								row.id === poolAddress && rowStates[row.id]?.expand
									? 'rotate-180'
									: 'rotate-0'
							)}
							component='span'
							sx={{
								'&:hover': {
									backgroundColor: theme.palette.action.hover
								}
							}}
						>
							<ArrowDownIcon color='inherit' />
						</Typography>
					</div>
				);
			}
		}
	];

	useUpdateEffect(() => {
		if (isLogin) {
			refetchOrders();
		}
	}, [isLogin, address, appTimer]);

	return (
		<Box className='px-4 pt-1 pb-4 sm:p-0'>
			<div className='px-4 md:px-3 sm:hidden flex'>
				{headers.map((header, index) => (
					<Typography
						key={index}
						align={header.align}
						width={header.width}
						variant='body2'
						color='secondary'
						className='py-4'
					>
						{header.label}
					</Typography>
				))}
			</div>
			<div
				className={classNames('space-y-4 md:space-y-2 sm:space-y-2', {
					'flex justify-center h-[300px] items-center': poolList.length === 0
				})}
				style={{
					background: isMatchMobile ? theme.palette.background.default : ''
				}}
			>
				{poolList.length === 0 ? (
					<SectionLoading />
				) : (
					poolList.map((row: IPoolItem, rowIndex: number) => (
						<Box
							key={rowIndex}
							className='rounded flex-col'
							sx={{
								border:
									row.id === poolAddress
										? `1px solid ${theme.custom.ListBorder}`
										: `1px solid ${theme.custom.ListSelectBg}`,
								borderWidth: isMatchMobile ? '0px' : '1px'
							}}
						>
							{/* 统计类数据 */}
							<Box
								className={classNames(
									'relative px-4 md:px-3 sm:px-3 py-3 sm:py-5',
									{
										'cursor-pointer': row.id !== poolAddress,
										rounded: row.id !== poolAddress
									}
								)}
								onClick={() => onHandleSetCurrentPool(row)}
								sx={{
									background: isMatchMobile
										? theme.palette.background.paper
										: row.id === poolAddress
										? theme.custom.ListSelectBg
										: theme.custom.ListBg,
									'&:hover': {
										background:
											row.id !== poolAddress ? theme.custom.ListHoverBg : ''
									}
								}}
							>
								{row.id === poolAddress && (
									<FiberManualRecordIcon
										className='absolute top-2 left-2 sm:hidden'
										sx={{
											width: 10,
											height: 10,
											color: theme.palette.primary.main
										}}
									/>
								)}

								<div className='flex items-center sm:hidden'>
									{headers.map(header => (
										<Typography
											component='div'
											key={header.key}
											width={header.width}
											align={header.align}
											variant='body2'
											color='text.main'
										>
											{header.cellRender(row)}
										</Typography>
									))}
								</div>
								<div className='hidden sm:block'>
									<div className='space-y-4'>
										<div className='flex items-center space-x-3'>
											<div className='flex-1 flex justify-between'>
												{headers[0].cellRender(row)}
												<Typography variant='h6'>
													{toQuoteAmount(row.price, row.baseToken?.precision)}
												</Typography>
											</div>
											<div className='flex justify-end'>
												<StyledPriceChangeContainer value={row.priceChange}>
													{`${
														isPositive(row.priceChange) ? '+' : ''
													}${toPercent(row.priceChange)}`}
												</StyledPriceChangeContainer>
											</div>
										</div>
										<div className='flex items-center justify-between space-x-3'>
											<div className='flex-1 space-y-3'>
												<div className='flex-1 flex gap-1'>
													<DirectionCell
														label={headers[3].label}
														value={headers[3].cellRender(row)}
													/>
													<DirectionCell
														label={headers[4].label}
														value={headers[4].cellRender(row)}
													/>
													<DirectionCell
														label={headers[5].label}
														value={headers[5].cellRender(row)}
														align='right'
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Box>

							{row.id === poolAddress &&
							rowStates[row.id] &&
							rowStates[row.id].expand ? (
								<Paper
									className='sm:hidden'
									sx={{
										height: rowStates[row.id].expand
											? Dom_Size.CHART + 80
											: 'auto'
									}}
								>
									<ChartsPool
										pool={row}
										activeTab={rowStates[row.id].chartType}
										price={row.price}
										onHandleChangeActiveTab={(newTab: number) =>
											onHandleChangeChartType(newTab, rowStates[row.id])
										}
									/>
								</Paper>
							) : null}

							{row.id === poolAddress && (
								<Box
									component='section'
									className={classNames(
										'rounded-none sm:hidden',
										row.id === poolAddress ? 'p-4' : undefined
									)}
									sx={{
										borderTop:
											positionMap && positionMap.has(row.id)
												? '1px solid rgba(255, 255, 255, 0.08)'
												: '0 none'
									}}
								>
									<StyledTabs
										value={rowStates[row.id]?.tabType || Tab_Type.Positions}
										onChange={(event, newType) =>
											onHandleChangeOperationType(newType, rowStates[row.id])
										}
									>
										<StyledTab
											className='pl-0'
											label={
												<div className='space-x-0.5'>
													<span>
														<Trans>Positions</Trans>
													</span>
													{isLogin &&
														positionMap &&
														positionMap.has(row.id) && (
															<span>
																{formatModuleNumber(
																	positionMap.get(row.id)?.length
																)}
															</span>
														)}
												</div>
											}
											value={Tab_Type.Positions}
										/>
										<StyledTab
											label={
												<Box className='flex items-center gap-1'>
													<div className='space-x-0.5'>
														<Trans>Orders</Trans>
														{isLogin &&
															createdOrderList &&
															createdOrderList.length > 0 && (
																<span>
																	{formatModuleNumber(createdOrderList.length)}
																</span>
															)}
													</div>
													<ExpiredOrderWarning
														createdOrderList={isLogin ? createdOrderList : []}
														positionMap={positionMap}
													/>
												</Box>
											}
											value={Tab_Type.Orders}
										/>
										<StyledTab
											label={<Trans>History</Trans>}
											value={Tab_Type.History}
										/>
									</StyledTabs>
									<CommonStyledDivider type='solid' />
									<div className=' min-h-[20px]'>
										{rowStates[row.id]?.tabType === Tab_Type.Positions &&
											(isLoadingPositions || (isLogin && !positionMap) ? (
												<SectionLoading inline={true} />
											) : (
												<PositionsList
													list={
														!isLogin
															? null
															: positionMap && positionMap.has(row.id)
															? positionMap.get(row.id)
															: null
													}
													poolId={row.id}
												/>
											))}

										{rowStates[row.id]?.tabType === Tab_Type.Orders &&
											(isLoadingOrders ? (
												<SectionLoading />
											) : (
												<OpenOrdersList
													list={isLogin ? orderList : null}
													poolId={row.id}
													positionMap={positionMap}
													full={false}
												/>
											))}
										{rowStates[row.id]?.tabType === Tab_Type.History && (
											<HistoryList targetPoolsId={targetPoolsId} />
										)}
									</div>
								</Box>
							)}
						</Box>
					))
				)}
			</div>
			<ViewUpdaterV1 positionMap={positionMap} orderList={orderList} />
		</Box>
	);
});
