import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';

import { useCurrentChain } from 'hooks/useCurrentChain';
import { useAppSelector } from 'state/hooks';
import { selectMarginRatio } from 'state/liquidity/selector';
import { liquidityBaseState } from 'state/liquidity/slice';
import { txBaseState } from 'state/tx/slice';
import {
	formatNumber,
	shortenSymbol,
	shortenSymbolNative,
	toPercent,
	toQuoteAmount
} from 'utils';

import Cell from 'components/Common/Cell';
import Dialog from 'components/Common/Dialog';
import { CommonStyledArticle } from 'components/Common/Styled';
import { poolsBaseState } from 'state/pools/slice';

export default function ConfirmIncreaseLiquidity(props: {
	onHandleClose: () => void;
	open: boolean;
	onSubmit: () => void;
}) {
	const { onHandleClose, open, onSubmit } = props;

	const { quoteToken } = useAppSelector(txBaseState);
	const { poolInfo } = useAppSelector(poolsBaseState);
	const { quoteAmount, liquidityAmount } = useAppSelector(liquidityBaseState);

	const marginRatio = useAppSelector(selectMarginRatio);

	const { currentChainId } = useCurrentChain();

	const onHandleSubmit = () => {
		onHandleClose();
		onSubmit();
	};

	return (
		<Dialog
			title={
				<Typography fontWeight={500} className='space-x-1'>
					<Trans>Add Liquidity</Trans>
				</Typography>
			}
			open={open}
			onClose={onHandleClose}
			onCancel={onHandleClose}
			onConfirm={onHandleSubmit}
		>
			<CommonStyledArticle className='space-y-3'>
				<section className='flex items-center space-x-1'>
					<Typography variant='body1'>
						{`${shortenSymbolNative(poolInfo?.baseToken, currentChainId)}/${shortenSymbol(
							quoteToken
						)}`}
					</Typography>
				</section>
				<section className='space-y-1'>
					<Cell
						label={<Trans>Liquidity</Trans>}
						value={toQuoteAmount(liquidityAmount)}
					/>
					<Cell
						label={
							<Typography variant='body2'>
								<Trans>Margin</Trans>
							</Typography>
						}
						value={formatNumber(quoteAmount)}
						unit={shortenSymbol(quoteToken)}
					/>
					<Cell
						label={
							<Typography variant='body2'>
								<Trans>Margin Ratio</Trans>
							</Typography>
						}
						value={toPercent(marginRatio)}
					/>
					<Cell label={<Trans>Risk Rate</Trans>} value={`1%`} />
				</section>
			</CommonStyledArticle>
		</Dialog>
	);
}
