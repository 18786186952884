import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import { Box, Typography, useTheme } from '@mui/material';
import { Dom_Size, Page, QUOTE_USD, Version } from 'config/constants';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import FreeTradingFeeWarning, {
	Type_Free_Trading_Fee
} from 'pages/components/FreeTradingFeeWarning';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
	isGreaterThan,
	isNumeric,
	isZero,
	plus,
	shortenSymbolNative,
	toPercent,
	toQuoteAmount
} from 'utils';

import { DirectionCell } from 'components/Common/Cell';

import { StyleCheckbox } from '../../components/Common/StyleCheckbox';
import SectionLoading from '../../components/SectionLoading';
import { setOnlyUsedPools, settingBaseState } from '../../state/setting/slice';

const LiquidityCard = ({
	rows,
	isLoading,
	onClickRow
}: {
	rows: any[];
	isLoading: boolean;
	onClickRow: (params: string) => void;
}) => {
	const { currentChainId } = useCurrentChain();
	const isLogin = useCheckLogin();
	const theme = useTheme();
	const navigate = useNavigate();
	const { isMatchMobile } = useAppBreakpoints();
	const dispatch = useAppDispatch();
	const { onlyUsedPools } = useAppSelector(settingBaseState);
	const currentVersion = useAppSelector(state => state.setting.currentVersion);
	const loadingHeight = useMemo(() => {
		return (
			window.innerHeight -
			Dom_Size.HEIGHT_HEADER -
			Dom_Size.MOBILE_HEIGHT_BOTTOM
		);
	}, [window.innerHeight]);

	return (
		<Box>
			{isLogin && currentVersion === Version.V2 && (
				<div className='flex justify-between p-4'>
					<Trans>Pools</Trans>
					<div className='flex'>
						<StyleCheckbox
							checked={onlyUsedPools}
							onChange={e => dispatch(setOnlyUsedPools(e.target.checked))}
							size='small'
							sx={{ height: '16px' }}
						/>
						<Typography color='text.secondary' variant='body2'>
							<Trans>My Liquidity Only</Trans>
						</Typography>
					</div>
				</div>
			)}
			{isLoading && (
				<Box
					sx={{
						backgroundColor: theme.palette.background.default,
						height: loadingHeight
					}}
				>
					<SectionLoading full={true} />
				</Box>
			)}
			{!isLoading &&
				rows.map(row => {
					return (
						<Box
							key={row.id}
							className='flex justify-between px-3 py-4 mb-2 last:mb-0 space-x-4'
							sx={{
								background: isMatchMobile
									? theme.palette.background.paper
									: theme.custom.ListBg
							}}
							onClick={() => {
								onClickRow(row.id);
								navigate(
									`${
										currentVersion === Version.V1 ? Page.PoolsV1 : Page.Pools
									}/${row.id}`
								);
							}}
						>
							<Box className='flex-1 space-y-7'>
								<Typography className='font-medium' variant='h6'>
									{`${shortenSymbolNative(
										row.baseToken,
										currentChainId
									)}/${QUOTE_USD}`}
								</Typography>
								<Box component='div'>
									<Typography
										variant='h6'
										fontWeight={500}
										color={
											isNumeric(row.maxApr) && isGreaterThan(row.maxApr, 0)
												? 'success.main'
												: 'secondary'
										}
									>
										{isNumeric(row.maxApr)
											? toPercent(row.maxApr)
											: toPercent(0)}
									</Typography>

									<Typography
										className='text-xs'
										color='text.secondary'
										variant='body2'
									>
										<Trans>Max APR</Trans>
									</Typography>
								</Box>
							</Box>
							<div className='flex-2 flex space-x-4 justify-between'>
								<Box className='space-y-6'>
									{currentVersion === Version.V1 && (
										<DirectionCell
											label={<Trans>24h Volume ({QUOTE_USD})</Trans>}
											value={
												<Typography variant='body1'>
													{toQuoteAmount(row.volume24h)}
												</Typography>
											}
										/>
									)}
									<DirectionCell
										label={<Trans>Liquidity</Trans>}
										value={
											<Typography variant='body1'>
												{toQuoteAmount(row.liquidity)}
											</Typography>
										}
									/>
								</Box>
								<Box className='space-y-6'>
									<DirectionCell
										align='right'
										label={
											currentVersion === Version.V1 ? (
												<Trans>24h Fee</Trans>
											) : (
												<Trans>24h Fee Income</Trans>
											)
										}
										value={
											<div className='flex items-center justify-end space-x-1'>
												<Typography variant='body1'>
													{currentVersion === Version.V1
														? toQuoteAmount(row.tradingFee24h)
														: toQuoteAmount(
																plus(row.fee24h, row.fundingFee24h)
														  )}
												</Typography>
												{/* {isZero(row.referralDiscountRate) && (
													<FreeTradingFeeWarning
														type={Type_Free_Trading_Fee.Warning}
													/>
												)} */}
											</div>
										}
									/>
									<DirectionCell
										align='right'
										label={<Trans>My Liquidity</Trans>}
										value={
											<Typography variant='body1'>
												{isLogin ? toQuoteAmount(row.myLiquidity) : '-'}
											</Typography>
										}
									/>
								</Box>
							</div>
						</Box>
					);
				})}
		</Box>
	);
};

export default LiquidityCard;
