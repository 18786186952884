import { useEffect, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans, t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
	APP_TOKEN_SYMBOL,
	QUOTE_USD,
	Transaction_Type,
	Version
} from 'config/constants';
import { useFarmClaimV2RequestByPools } from 'fetch/useRequest';
import { useAccount } from 'wagmi';

import { IPositionMiningPools } from 'graphql/usePositionRewardsGraph';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { useSubmitClaimPosition } from 'hooks/useSubmitClaimPosition';
import { filter } from 'lodash';
import ClaimPeriods from 'pages/components/ClaimPeriods';
import { StyledFormControlLabel } from 'pages/components/StyledFormControlLabel';
import { selectClaimablePositionList } from 'state/earn/position/selector';
import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';
import { txBaseState } from 'state/tx/slice';
import {
	formatNumber,
	formatSize,
	isNumeric,
	isPositive,
	plus,
	shortenSymbolNative
} from 'utils';

import ApproveButton from 'components/ApproveButton';
import ApproveButtonV2 from 'components/ApproveButtonV2';
import Dialog, { DialogProps } from 'components/Common/Dialog';
import { StyleCheckbox } from 'components/Common/StyleCheckbox';
import { CommonStyledDivider } from 'components/Common/Styled';
import SectionLoading from 'components/SectionLoading';
import Table from 'components/Table/StickyTable';

import EmptyClaim from '../EmptyClaim';
import { Lockup_Period, Reward_Type } from '../types';

export default function ClaimPositionDialog(props: DialogProps) {
	const { onClose } = props;

	const { currentChainId } = useCurrentChain();
	const { signingMap, appToken } = useAppSelector(txBaseState);
	const currentVersion = useAppSelector(selectVersion);
	const { appTimer } = useAppSelector(globalBaseState);
	const claimablePositionList = useAppSelector(selectClaimablePositionList);

	const theme = useTheme();
	const { address } = useAccount();

	const [appAmount, setAppAmount] = useState<string>('0');
	const [selected, setSelected] = useState<string[]>([]);
	const [period, setPeriod] = useState<Lockup_Period>(Lockup_Period.HIGH);

	const isSelected = (poolAddress: string) =>
		selected.indexOf(poolAddress) !== -1;

	const rewardType = useMemo(() => {
		if (currentVersion === Version.V1) {
			return Reward_Type.POSITION;
		}
		return Reward_Type.V2_POSITION;
	}, [currentVersion]);

	const { data, isLoading, refetch } = useFarmClaimV2RequestByPools(
		address,
		selected,
		useMemo(() => [rewardType], [rewardType]),
		period
	);

	useUpdateEffect(() => {
		refetch();
	}, [appTimer]);

	// 总的可领取的奖励列表
	const allPoolClaimableList = useMemo(() => {
		if (!claimablePositionList) {
			return null;
		}
		return claimablePositionList;
	}, [claimablePositionList]);

	const { onConfirm, isConfirmed, isConfirming } = useSubmitClaimPosition(
		data,
		appAmount
	);

	const [disabled, submitText] = useMemo(() => {
		if (!isPositive(appAmount)) {
			return [true, t`Claim`];
		}
		if (!isNumeric(period)) {
			return [true, t`Claim`];
		}
		if (isLoading) {
			return [true, t`Claim`];
		}
		if (
			signingMap.has(
				currentVersion === Version.V1
					? Transaction_Type.ClaimPosition
					: Transaction_Type.ClaimPositionV2
			)
		) {
			return [true, t`Loading...`];
		}
		if (isConfirming) {
			return [true, t`Submitting...`];
		}
		return [false, t`Claim`];
	}, [appAmount, isConfirming, signingMap, isLoading, period, currentVersion]);

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = allPoolClaimableList.map(item => item.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event: React.ChangeEvent, id: string) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	useEffect(() => {
		if (selected && selected.length) {
			const filteredList = filter(allPoolClaimableList, item =>
				selected.includes(item.id)
			);
			let appAmount = '0';
			filteredList.forEach(item => {
				appAmount = plus(appAmount, item.claimableAmount);
			});
			setAppAmount(appAmount);
		} else {
			setAppAmount('0');
		}
	}, [selected, allPoolClaimableList]);

	useUpdateEffect(() => {
		if (isConfirmed) {
			onClose();
		}
	}, [isConfirmed]);

	const columns = [
		{
			key: 'pool_address',
			width: '32%',
			label: (
				<StyledFormControlLabel
					control={
						<StyleCheckbox
							checked={selected.length === allPoolClaimableList?.length}
							size='small'
							onChange={handleSelectAllClick}
						/>
					}
					label={
						<Typography variant='body2' color='inherit'>
							<Trans>Market</Trans>
						</Typography>
					}
				/>
			),
			align: 'left',
			format: (row: IPositionMiningPools) => {
				const isItemSelected = isSelected(row.id);
				return (
					<div className='flex items-center'>
						<StyleCheckbox
							checked={isItemSelected}
							size='small'
							className='-ml-2.5'
							onChange={event => handleClick(event, row.id)}
						/>
						{`${shortenSymbolNative(
							row.baseToken,
							currentChainId
						)}/${QUOTE_USD}`}
					</div>
				);
			}
		},
		{
			key: 'total_amount',
			width: '32%',
			label: <Trans>My Positions</Trans>,
			align: 'right',
			format: (row: IPositionMiningPools) => {
				return (
					<Typography className='flex-1' variant='body2'>
						{`${formatSize(
							row.myPositions,
							row.baseToken.positionUnits
						)} ${shortenSymbolNative(row.baseToken, currentChainId)}`}
					</Typography>
				);
			}
		},
		{
			key: 'claimable_amount',
			width: '36%',
			label: (
				<Typography variant='body2'>
					{APP_TOKEN_SYMBOL} <Trans>Amount</Trans>
				</Typography>
			),
			align: 'right',
			format: (row: IPositionMiningPools) => {
				return (
					<Typography className='flex-1' variant='body2'>{`${formatNumber(
						row.claimableAmount,
						appToken.positionUnits
					)} ${APP_TOKEN_SYMBOL}`}</Typography>
				);
			}
		}
	];

	const { isMatchPad } = useAppBreakpoints();

	return (
		<Dialog size='large' title={t`Claim`} open={true} onClose={onClose}>
			{!allPoolClaimableList ? (
				<SectionLoading />
			) : allPoolClaimableList.length > 0 ? (
				<div className='space-y-4'>
					<div className='-mx-4'>
						<Table
							columns={columns}
							rows={allPoolClaimableList}
							cellColor={theme.custom.cardBg}
							origin={isMatchPad}
						/>
					</div>
					<CommonStyledDivider />
					<ClaimPeriods
						totalAmount={appAmount}
						period={period}
						setPeriod={setPeriod}
					/>
					<section>
						{currentVersion === Version.V2 ? (
							<ApproveButtonV2
								componentVariant='confirm'
								fullWidth
								onClick={onConfirm}
								disabled={disabled}
								isApproveRewardCollector={true}
							>
								{submitText}
							</ApproveButtonV2>
						) : (
							<ApproveButton
								componentVariant='confirm'
								fullWidth
								onClick={onConfirm}
								disabled={disabled}
								isApproveRewardCollector={true}
							>
								{submitText}
							</ApproveButton>
						)}
					</section>
				</div>
			) : (
				<EmptyClaim />
			)}
		</Dialog>
	);
}
