import { useEffect, useState } from 'react';

import { Interface } from '@ethersproject/abi';
import { orderBookV2ABI } from 'config/abis';
import { Contract_Write_Mode, Transaction_Type } from 'config/constants';
import { getContractAddressV2 } from 'config/contracts';
import { UseContractWriteConfig, UsePrepareContractWriteConfig } from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { useAppDispatch } from 'state/hooks';
import { updateSigning } from 'state/tx/slice';
import { BaseRecord, RecordForCancelAllOrders } from 'types';

import { useComputeGasLimit } from 'hooks/useComputeGasLimit';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { useSendTransaction } from 'hooks/useSendTransaction';

const orderBookV2Interface = new Interface(orderBookV2ABI);

export function useSubmitCancelAllOrdersV2() {
	const dispatch = useAppDispatch();

	const { currentChainId } = useCurrentChain();

	const [contractWriteArgs, setContractWriteArgs] =
		useState<UseContractWriteConfig | null>(null);
	const [record, setRecord] = useState<RecordForCancelAllOrders | null>(null);
	const [description, setDescription] = useState<string>('');

	const { gasLimit, prepared } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirming, isConfirmed, error } =
		useSendTransaction(contractWriteArgs, gasLimit);

	const onConfirm = (
		limitOrderIds: Array<string> | null,
		profitLossOrderIds: Array<string> | null
	) => {
		console.log('useSubmitCancelAllOrders args begin ...');

		let increaseOrders = [] as Array<string>;
		let decreaseOrders = [] as Array<string>;
		let orderIds = [] as Array<string>;

		if (limitOrderIds && limitOrderIds.length > 0) {
			// results.push(
			// 	orderBookV2Interface.encodeFunctionData(
			// 		'cancelIncreaseOrdersBatch',
			// 		[limitOrderIds]
			// 	)
			// );
			increaseOrders = increaseOrders.concat(limitOrderIds);
			console.log('limitOrderIds: ', limitOrderIds);
		}

		if (profitLossOrderIds && profitLossOrderIds.length > 0) {
			// results.push(
			// 	orderBookV2Interface.encodeFunctionData(
			// 		'cancelDecreaseOrdersBatch',
			// 		[profitLossOrderIds]
			// 	)
			// );
			decreaseOrders = decreaseOrders.concat(profitLossOrderIds);
			console.log('profitLossOrderIds---------------------: ', profitLossOrderIds);
		}
		orderIds = orderIds.concat(increaseOrders).concat(decreaseOrders);

		const record = {
			transactionType: Transaction_Type.CancelAllOrdersV2,
			orderIds
		} as RecordForCancelAllOrders;

		const description = TransactionDescription.CancelAllOrders();

		console.log('getContractAddressV2(currentChainId', getContractAddressV2(currentChainId, 'OrderBook'));

		const contractWriteArgs = {
			mode: Contract_Write_Mode.Unprepared,
			address: getContractAddressV2(currentChainId, 'OrderBook'),
			abi: orderBookV2ABI,
			functionName: 'cancelOrdersBatch',
			args: [increaseOrders, decreaseOrders]
		} as UseContractWriteConfig;

		setRecord(record);
		setDescription(description);
		setContractWriteArgs(contractWriteArgs);

		dispatch(updateSigning(record));
	};

	useEffect(() => {
		if (prepared) {
			onSendTransaction(record as BaseRecord, description);
		}
	}, [prepared]);

	useEffect(() => {
		if (error) {
			setContractWriteArgs(null);
		}
	}, [error]);

	return {
		onConfirm,
		isConfirming,
		isConfirmed
	};
}
