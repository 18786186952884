import React, { useMemo, useRef, useState } from 'react';
import { Text } from 'react-konva';

import { Trans, t } from '@lingui/macro';
import {
	Avatar,
	Box,
	IconButton,
	Typography,
	alpha,
	useTheme
} from '@mui/material';
import { App_Theme, Leaderboard_Rank, dataRanges } from 'config/constants';
import { useTradeLeaderboard } from 'fetch/useRequest';
import moment from 'moment';
import { useAccount } from 'wagmi';

import CopperIcon from 'assets/svg/leaderboard/copper.svg';
import GoldIcon from 'assets/svg/leaderboard/gold.svg';
import LightShareIcon from 'assets/svg/leaderboard/lighShare.svg';
import ShareIcon from 'assets/svg/leaderboard/share.svg';
import SilverIcon from 'assets/svg/leaderboard/silver.svg';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import { isEmpty } from 'lodash';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import {
	ExplorerDataType,
	formatNumber,
	getExplorerLink,
	shortenAddress,
	toPercent
} from 'utils';

import StyledLink from 'components/Common/StyledLink';
import Ranges from 'components/Ranges';
import SectionLoading from 'components/SectionLoading';
import FilterIcon from 'components/Svg/Icons/Filter';
import Table from 'components/Table/StickyTable';
import TippingUnderline from 'components/TippingUnderline';

import ShareDialog from './ShareDialog';

export default function TradeRanking() {
	const [range, setRange] = useState<any>(dataRanges[0]);
	const sortTypes = [
		{
			label: `PnL`,
			rankLabel: 'PnL Rank',
			value: 'PROFIT_AMOUNT',
			labelOrigin: 'PnL'
		},
		{
			rankLabel: `PnL% Rank`,
			label: 'PnL%',
			value: 'PROFIT_MARGIN',
			labelOrigin: 'PnL%'
		}
	];
	const [sortType, setSortType] = useState(sortTypes[0]);
	const { isMatchMobile, isMatchPad, isMatchPc } = useAppBreakpoints();
	const { quoteToken, appToken } = useAppSelector(txBaseState);
	const changeRange = (period: any) => {
		setRange(period);
	};
	const { address = '' } = useAccount();
	const isLogin = useCheckLogin();
	const { currentChain: chain, currentChainId } = useCurrentChain();
	const theme = useTheme();
	const [shareDialogOpen, setShareDialogOpen] = useState(false);
	const params = useMemo(() => {
		return {
			resolution: range.value,
			sort_by: sortType.value
		};
	}, [range, sortType]);

	const { data, isLoading } = useTradeLeaderboard(params);

	const refactorData = useMemo(() => {
		if (isEmpty(data)) {
			return [];
		}
		if (isLogin && address) {
			const userData = data.leaderboards
				.filter(item => item.account === address)
				.map(item => ({ ...item, isOwner: item.account === address }));
			return [...userData, ...data.leaderboards];
		} else {
			return data.leaderboards;
		}
	}, [address, isLogin, data]);

	const [shareItem, setShareItem] = useState(null);
	const columns = [
		{
			key: 'Rank',
			width: !isMatchMobile ? '15%' : 60,
			label: (
				<Typography
					fontSize={14}
					variant='h1'
					component='div'
					className='flex items-center sm:h-[30px]'
				>
					<Trans>Rank</Trans>
				</Typography>
			),
			align: 'left',
			format: (row: any) => {
				return (
					<Box className='flex items-center sm:h-[40px]'>
						{row.rank === Leaderboard_Rank.Gold && !row.isOwner && (
							<Avatar
								src={GoldIcon}
								sx={{
									width: isMatchMobile ? 14 : 21,
									height: isMatchMobile ? 16 : 24
								}}
							/>
						)}
						{row.rank === Leaderboard_Rank.Silver && !row.isOwner && (
							<Avatar
								src={SilverIcon}
								sx={{
									width: isMatchMobile ? 14 : 21,
									height: isMatchMobile ? 16 : 24
								}}
							/>
						)}
						{row.rank === Leaderboard_Rank.Copper && !row.isOwner && (
							<Avatar
								src={CopperIcon}
								sx={{
									width: isMatchMobile ? 14 : 21,
									height: isMatchMobile ? 16 : 24
								}}
							/>
						)}
						{((row.rank !== Leaderboard_Rank.Gold &&
							row.rank !== Leaderboard_Rank.Silver &&
							row.rank !== Leaderboard_Rank.Copper) ||
							row.isOwner) && (
							<Typography fontSize={14} variant='h1' component='div'>
								<Typography
									className='flex justify-center items-center'
									sx={{
										width: isMatchMobile ? 14 : 21,
										height: isMatchMobile ? 16 : 24
									}}
									fontSize={14}
									variant='h1'
									component='div'
								>
									{row.rank}
								</Typography>
							</Typography>
						)}
						{row.isOwner && isMatchMobile && (
							<Avatar
								component='div'
								className='ml-2 cursor-pointer'
								src={theme.mode === App_Theme.dark ? ShareIcon : LightShareIcon}
								sx={{ width: 14, height: 14 }}
								onClick={() => {
									setShareDialogOpen(true);
									setShareItem({
										...row,
										time: moment(data.last_updated_time)
											.utc()
											.format('MMM D, YYYY HH:mm:ss')
									});
								}}
							/>
						)}
					</Box>
				);
			}
		},
		{
			key: 'Address',
			width: !isMatchMobile ? '25%' : 60,
			label: (
				<Typography
					fontSize={14}
					variant='h1'
					component='div'
					className='flex items-center sm:h-[30px]'
				>
					<Trans>Address</Trans>
				</Typography>
			),
			align: 'left',
			format: (row: any) => {
				return (
					<div className='opacity-70 flex items-center sm:h-[40px]'>
						{row.isOwner ? (
							<Box className='flex items-center sm:block'>
								<span>YOU</span>&nbsp;
							</Box>
						) : (
							<StyledLink
								type='white'
								target={isMatchMobile ? '_self' : '_blank'}
								href={getExplorerLink(
									chain,
									row.account || '',
									ExplorerDataType.ADDRESS
								)}
							>
								{shortenAddress(row.account)}
							</StyledLink>
						)}
					</div>
				);
			}
		},
		{
			key: 'ProfitMargin',
			label: (
				<Typography className='flex items-center justify-end sm:h-[30px]'>
					<span>
						<Trans>PnL%</Trans>
					</span>
					<IconButton
						disabled={sortType.value === sortTypes[1].value}
						onClick={() => handleChangeSortType(sortTypes[1])}
					>
						<FilterIcon
							fill={
								sortType.value === sortTypes[1].value
									? alpha(theme.palette.text.primary, 0.9)
									: theme.palette.text.secondary
							}
						/>
					</IconButton>
				</Typography>
			),
			align: 'right',
			format: row => {
				return (
					<Typography
						color={
							sortType.value === sortTypes[1].value ? 'success.main' : 'inherit'
						}
					>
						{toPercent(row.profit_margin)}
					</Typography>
				);
			}
		},
		{
			key: 'ProfitAmount',
			label: (
				<Typography
					className='flex items-center justify-end space-x-1 sm:h-[30px]'
					align='right'
				>
					<TippingUnderline
						label={
							<span>
								{t`PnL`} ({quoteToken?.symbol})
							</span>
						}
						tooltip={
							<Trans>
								It is the sum of realized PnL after closing the position.
							</Trans>
						}
					/>
					<IconButton
						disabled={sortType.value === sortTypes[0].value}
						onClick={() => handleChangeSortType(sortTypes[0])}
					>
						<FilterIcon
							fill={
								sortType.value === sortTypes[0].value
									? alpha(theme.palette.text.primary, 0.9)
									: theme.palette.text.secondary
							}
						/>
					</IconButton>
				</Typography>
			),
			align: 'right',
			format: row => {
				return (
					<div className='flex items-center justify-end'>
						<Typography
							color={
								sortType.value === sortTypes[0].value
									? 'success.main'
									: 'inherit'
							}
						>
							${formatNumber(row.profit_amount, quoteToken.positionUnits)}
						</Typography>
						{row.isOwner && !isMatchMobile && (
							<Avatar
								component='div'
								onClick={() => {
									setShareDialogOpen(true);
									setShareItem({
										...row,
										time: `${moment(data?.last_updated_time)
											.utc()
											.format('MMM D, YYYY HH:mm:ss')}`
									});
								}}
								className='ml-2 cursor-pointer'
								src={theme.mode === App_Theme.dark ? ShareIcon : LightShareIcon}
								sx={{ width: 14, height: 14 }}
							/>
						)}
					</div>
				);
			}
		}
	];

	const firstRef = useRef();
	const shareOptionsInital = useMemo(() => {
		return [
			{
				label: <Trans>Address</Trans>,
				value: 'address',
				checked: true
			},
			{
				...sortTypes[0],
				label: <Trans>PnL</Trans>,
				checked: true
			}
		];
	}, []);
	const [shareOptions, updateShareOptions] =
		useState<any[]>(shareOptionsInital);
	const handleUpdateShareOptions = param => {
		const key = Object.keys(param)[0];
		const checked = Object.values(param)[0];
		updateShareOptions(state => {
			const refactorState = [];
			state.forEach(item => {
				if (item.value === key) {
					refactorState.push({
						...state.find(item => item.value === key),
						checked: checked
					});
				} else {
					refactorState.push(item);
				}
			});
			return refactorState;
		});
	};

	const handleChangeSortType = type => {
		setSortType(type);
	};

	const shareProfit = useMemo(() => {
		return (
			shareOptions.find(item => item.value === sortTypes[0].value).checked ||
			false
		);
	}, [shareOptions]);

	return (
		<div>
			<Box className='flex justify-between underLg:mx-3 items-center sm:block sm:space-y-2'>
				<Typography color='text.secondary'>
					<Trans>Ranking based on PnL or PnL%, updated every hour.</Trans>
				</Typography>
				<Typography className='flex items-center justify-end sm:justify-between'>
					<Typography color='text.secondary' className='mr-4 sm:text-xs'>
						{data?.last_updated_time && (
							<Trans>
								Updated at{' '}
								{moment(data?.last_updated_time).format('MMM D, YYYY HH:mm:ss')}
							</Trans>
						)}
					</Typography>
					<Ranges value={range?.value} onChange={changeRange} />
				</Typography>
			</Box>
			<div className='mx-4 sm:-mx-0 sm:px-3 sm:overflow-hidden mt-2'>
				{isLoading ? (
					<SectionLoading />
				) : (
					<Table
						columns={columns}
						rows={refactorData}
						origin={!isMatchPc}
						columnCellColor={theme.custom.ListBg}
						height='100%'
					/>
				)}
			</div>
			{shareDialogOpen && (
				<ShareDialog
					member={{
						...shareItem,
						title:
							sortType.value === sortTypes[1].value ? t`PnL% Rank` : t`PnL Rank`
					}}
					timeTag={range.value === dataRanges[1].value}
					onClose={() => {
						setShareDialogOpen(false);
					}}
					shareOptions={shareOptions}
					handleUpdateShareOptions={handleUpdateShareOptions}
					renderContent={
						<>
							<Text
								x={24}
								y={216}
								text={t`PnL`}
								fontFamily='Noto Sans'
								fill={theme.palette.text.secondary}
								fontSize={14}
							/>
							<Text
								x={24}
								y={242}
								text={`${toPercent(shareItem.profit_margin)}`}
								fontFamily='Noto Sans'
								fill={theme.palette.success.main}
								fontSize={20}
								opacity={0.9}
								ref={firstRef}
							/>
							{firstRef.current && shareProfit ? (
								<Text
									x={28 + firstRef?.current?.getTextWidth()}
									y={246}
									text={`(+${formatNumber(
										shareItem.profit_amount,
										quoteToken.positionUnits
									)} ${quoteToken.symbol})`}
									fontFamily='Noto Sans'
									fill={theme.palette.success.main}
									fontSize={14}
									opacity={0.9}
								/>
							) : null}
						</>
					}
				/>
			)}
		</div>
	);
}
