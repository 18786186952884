import EFCJson from './EFC.json';
import FeeDistributorJson from './FeeDistributor.json';
import Multicall3Json from './Multicall3.json';
import NonfungiblePositionManagerJson from './NonfungiblePositionManager.json';
import OrderBookJson from './OrderBook.json';
import OrderBookAssistantJson from './OrderBookAssistant.json';
import OrderBookV2Json from './OrderBookV2.json';
import PositionRouterJson from './PositionRouter.json';
import PositionRouterV2Json from './PositionRouterV2.json';
import RewardCollectorJson from './RewardCollector.json';
import RewardCollectorV2Json from './RewardCollectorV2.json';
import RewardFarmJson from './RewardFarm.json';
import RouterJson from './Router.json';
import RouterV2son from './RouterV2.json';
import UniswapV3FactoryJson from './UniswapV3Factory.json';
import UniswapV3PoolJson from './UniswapV3Pool.json';

const { abi: positionRouterABI } = PositionRouterJson;
const { abi: PositionRouterV2ABI } = PositionRouterV2Json;

const { abi: orderBookABI } = OrderBookJson;
const { abi: orderBookV2ABI } = OrderBookV2Json;
const { abi: OrderBookAssistantABI } = OrderBookAssistantJson;
const { abi: routerABI } = RouterJson;
const { abi: routerV2ABI } = RouterV2son;
const { abi: EFCABI } = EFCJson;
const { abi: FeeDistributorABI } = FeeDistributorJson;
const { abi: RewardCollectorABI } = RewardCollectorJson;
const { abi: RewardCollectorV2ABI } = RewardCollectorV2Json;
const { abi: RewardFarmABI } = RewardFarmJson;
const { abi: NonfungiblePositionManagerABI } = NonfungiblePositionManagerJson;
const { abi: UniswapV3PoolABI } = UniswapV3PoolJson;
const { abi: UniswapV3FactoryABI } = UniswapV3FactoryJson;
const { abi: Multicall3ABI } = Multicall3Json;

export {
	positionRouterABI,
	PositionRouterV2ABI,
	orderBookABI,
	orderBookV2ABI,
	OrderBookAssistantABI,
	routerABI,
	routerV2ABI,
	EFCABI,
	FeeDistributorABI,
	RewardCollectorABI,
	RewardCollectorV2ABI,
	RewardFarmABI,
	NonfungiblePositionManagerABI,
	UniswapV3PoolABI,
	UniswapV3FactoryABI,
	Multicall3ABI
};
