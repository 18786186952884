import moment from 'moment';

import axios from 'axios';
import { getAddress } from 'ethers/lib/utils';
import qs from 'qs';

import { QUOTE_USD } from '../../../config/constants';
import { ALL_TOKENS } from '../../../config/constants/tokens';
import { shortenSymbolNative, toDecimalPlaces } from '../../../utils';

const history = {};

const querySehema = {
	5: 'candle5Ms',
	15: 'candle15Ms',
	30: 'candle30Ms',
	60: 'candle1Hs',
	240: 'candle4Hs',
	'1D': 'candle1Ds'
};

export default function (api, { isGraph = true, chainId, currentPrice }) {
	return {
		history: history,
		resolveSymbol: function (symbolName) {
			const fetchUrl = isGraph
				? api
				: `${api}symbols?${qs.stringify({ symbol: symbolName })}`;
			if (isGraph) {
				return axios
					.post(
						api,
						{
							query: `{
                token(id: "${symbolName.toLowerCase()}") {
									decimals
									id
									name
									symbol
                }
              }`
						},
						{
							headers: {
								'Content-Type': 'application/json'
							}
						}
					)
					.then(resp => {
						const data = resp.data.data;
						const { token } = data;
						const tokens = ALL_TOKENS[chainId];
						const currentToken = tokens.find(
							tokenItem =>
								tokenItem.address.toLowerCase() === token.id.toLowerCase()
						);
						const symbolSub = {
							has_daily: true,
							has_empty_bars: false,
							has_intraday: true,
							has_no_volume: false,
							minmov: 1,
							minmove2: 0,
							name: `${shortenSymbolNative(currentToken, chainId)}/${QUOTE_USD}`,
							pricescale: 1000000000000000,
							session: '24x7',
							supported_resolutions: ['5', '15', '30', '60', '240', '1D'],
							ticker: symbolName,
							token: token.id
						};
						return symbolSub;
					});
			} else {
				const symbolToken = symbolName.split('-')[1]
				return axios.get(fetchUrl).then(resp => {
					const tokens = ALL_TOKENS[chainId];
					const token = tokens.find(
						tokenItem =>
							tokenItem.address.toLowerCase() === symbolToken.toLowerCase()
					);
					return {
						...resp.data,
						name: `${shortenSymbolNative(token, chainId)}/${QUOTE_USD}`
					};
				});
			}
		},
		getBars: function (symbolInfo, resolution, from, to, first, limit) {
			const query = {
				symbol: isGraph ? symbolInfo.token : symbolInfo.ticker,
				resolution,
				from: from,
				to,
				countback: limit
			};
			const { pericesion } = symbolInfo;
			const fetchUrl = isGraph ? api : `${api}history?${qs.stringify(query)}`;
			if (isGraph) {
				const resolutionSchema = querySehema[resolution];
				return axios
					.post(
						api,
						{
							query: `{
            ${resolutionSchema}(
              first: ${limit}
              skip: 0
              where: {token: "${symbolInfo.token}", ts_gt: ${from}, ts_lte: ${to}}
            ) {
              close
              high
              low
              open
              ts
              volume
              volumeUSD
            }
          }`
						},
						{
							headers: {
								'Content-Type': 'application/json'
							}
						}
					)
					.then(data => {
						const originData = data.data.data[resolutionSchema] || [];
						var bars = originData.map((el, index) => {
							const { volume, volumeUSD } = el;
							const volumeValue = volume;
							return {
								time: Number(el.ts) * 1000,
								low: Number(toDecimalPlaces(el.low, pericesion)),
								high: Number(toDecimalPlaces(el.high, pericesion)),
								open: Number(toDecimalPlaces(el.open, pericesion)),
								close: Number(toDecimalPlaces(el.close, pericesion)),
								volume: Number(volumeValue)
							};
						});
						if (first) {
							var lastBar = bars[bars.length - 1];
							history[symbolInfo.ticker] = {
								lastBar: lastBar,
								symbolInfo: { ...symbolInfo, pericesion: pericesion },
								resolution: resolution
							};
						}
						return bars;
					})
					.catch(error => {
						return [];
					});
			} else {
				return axios.get(fetchUrl).then(data => {
					if (data.Response && data.Response === 'Error') {
						console.log('error', data.Message);
						return [];
					}
					if (data.data) {
						const { t, o, c, v, l, h, s } = data.data;
						if (s === 'no_data') {
							return [];
						}
						var bars = t.map((el, index) => {
							return {
								time: el * 1000, //TradingView requires bar time in ms
								low: Number(toDecimalPlaces(l[index], pericesion)),
								high: Number(toDecimalPlaces(h[index], pericesion)),
								open: Number(toDecimalPlaces(o[index], pericesion)),
								close: Number(toDecimalPlaces(c[index], pericesion)),
								volume: Number(v[index])
							};
						});
						if (first) {
							var lastBar = bars[bars.length - 1];
							history[symbolInfo.ticker] = {
								lastBar: lastBar,
								symbolInfo: { ...symbolInfo, pericesion: pericesion },
								resolution: resolution
							};
						}
						return bars;
					} else {
						return [];
					}
				});
			}
		},
		updateBar: function (symbolInfo, resolution) {
			const query = {
				symbol: isGraph ? symbolInfo.token : symbolInfo.ticker,
				resolution: resolution,
				from: moment().subtract(10, 'seconds').unix(),
				to: moment().unix(),
				countback: 2
			};
			const fetchUrl = isGraph ? api : `${api}history?${qs.stringify(query)}`;
			if (isGraph) {
				const resolutionSchema = querySehema[resolution];
				return axios
					.post(
						api,
						{
							query: `{
            ${resolutionSchema}(
              first: 2
              skip: 0
              orderBy: ts
              orderDirection: desc
              where: {token: "${symbolInfo.token}", ts_lte: ${moment().unix()}}
            ) {
              close
              high
              low
              open
              ts
              volume
              volumeUSD
            }
          }`
						},
						{
							headers: {
								'Content-Type': 'application/json'
							}
						}
					)
					.then(data => {
						const originData = data.data.data[resolutionSchema] || [];
						if (!originData.length) {
							return history[symbolInfo.ticker].lastBar;
						}
						const { pericesion } = symbolInfo;
						const lastBarData = originData.slice(-1);
						const { volume } = lastBarData;
						const bar = {
							time: Number(lastBarData[0].ts) * 1000, //TradingView requires bar time in ms
							low: Number(toDecimalPlaces(lastBarData[0].low, pericesion)),
							high: Number(toDecimalPlaces(lastBarData[0].high, pericesion)),
							open: Number(toDecimalPlaces(lastBarData[0].open, pericesion)),
							close: Number(toDecimalPlaces(lastBarData[0].close, pericesion)),
							volume: Number(volume)
						};
						return bar;
					})
					.catch(error => {
						return history[symbolInfo.ticker].lastBar;
					});
			} else {
				return axios.get(fetchUrl).then(resp => {
					const { t, o, c, v, l, h, s } = resp.data;
					if (s === 'no_data') {
						return {};
					}
					const { pericesion } = symbolInfo;
					const bars = t.map((item, index) => {
						return {
							time: item * 1000, //TradingView requires bar time in ms
							low: Number(toDecimalPlaces(l[index], pericesion)),
							high: Number(toDecimalPlaces(h[index], pericesion)),
							open: Number(toDecimalPlaces(o[index], pericesion)),
							close: Number(toDecimalPlaces(c[index], pericesion)),
							volume: Number(v[index])
						}
					})
					return bars;
				});
			}
		},
		getTime(){
			const fetchUrl = isGraph ? api : `${api}time`;
			return axios.get(fetchUrl).then(resp => {
				return resp.data
			});
		}
	};
}
