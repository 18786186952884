import { createContext, useContext } from 'react';

type defaultValue = any;
const ApolloClientsContext = createContext<defaultValue>({});

export const ApolloClientsProvider = ({
	clients,
	children
}: {
	clients: any;
	children: any;
}) => (
	<ApolloClientsContext.Provider value={clients}>
		{children}
	</ApolloClientsContext.Provider>
);

export const useApolloClients = () => useContext(ApolloClientsContext);
