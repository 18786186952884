import { Reducer, createSlice } from '@reduxjs/toolkit';
import { IRBFMiningPool } from 'graphql/useRiskBufferFundRewardsGraph';

import { RootState } from 'state';

interface StateProps {
	isLoadingRBFList: boolean;
	RBFList: Array<IRBFMiningPool> | null;
	previousRBFList: Array<any> | null;
}

const initialState: StateProps = {
	isLoadingRBFList: true,
	RBFList: null,
	previousRBFList: null,
};

export const slice = createSlice({
	name: 'earnRBF',
	initialState,
	reducers: {
		setIsLoadingRBFList(state, { payload }) {
			state.isLoadingRBFList = payload;
		},
		setRBFList(state, { payload }) {
			state.RBFList = payload;
		},
		setPreviousRBFList(state, { payload }) {
			state.previousRBFList = payload;
		},
	}
});

export const {
	setIsLoadingRBFList,
	setRBFList,
	setPreviousRBFList
} = slice.actions;

export const earnRBFBaseState = (state: RootState) => state.earnRBF;

export default slice.reducer as Reducer<typeof initialState>;
