import { useRef, useEffect } from 'react';

export default function useLatest<T>(value: T) {
	const ref = useRef(value);
	ref.current = value;

	return ref;
}

export const usePrevious = (value) => {
	const ref = useRef();
  
	useEffect(() => {
	  ref.current = value;
	}, [value]);
  
	return ref.current;
  };
  
