import { Side } from 'config/constants';

import { TokenInfo } from 'types';

export enum TransationOperationV1 {
	CreateOpenLiquidityPosition = 'CreateOpenLiquidityPosition',
	CreateCloseLiquidityPosition = 'CreateCloseLiquidityPosition',
	CreateAdjustLiquidityPositionMargin = 'CreateAdjustLiquidityPositionMargin',
	CreateIncreasePosition = 'CreateIncreasePosition',
	CreateDecreasePosition = 'CreateDecreasePosition',
	CancelOpenLiquidityPosition = 'CancelOpenLiquidityPosition',
	CancelCloseLiquidityPosition = 'CancelCloseLiquidityPosition',
	CancelAdjustLiquidityPositionMargin = 'CancelAdjustLiquidityPositionMargin',
	CancelIncreasePosition = 'CancelIncreasePosition',
	CancelDecreasePosition = 'CancelDecreasePosition',
	LiquidityPositionOpened = 'Open',
	LiquidityPositionClosed = 'Close',
	LiquidityPositionMarginAdjusted = 'AdjustMargin',
	LiquidityPositionLiquidated = 'Liquidate',
	PositionOpen = 'OpenPosition',
	PositionIncreased = 'IncreasePosition',
	PositionClose = 'ClosePosition',
	PositionDecreased = 'DecreasePosition',
	PositionLiquidated = 'Liquidate',
	IncreaseOrderCreated = 'CreateIncreaseOrder',
	IncreaseOrderUpdated = 'UpdateIncreaseOrder',
	IncreaseOrderCancelled = 'CancelIncreaseOrder',
	IncreaseOrderExecuted = 'ExecuteIncreaseOrder'
}

export enum TransationOperationV2 {
	CreateOpenLiquidityPosition = 'CREATE_OPEN_LIQUIDITY_POSITION',
	CreateCloseLiquidityPosition = 'CREATE_CLOSE_LIQUIDITY_POSITION',
	CreateAdjustLiquidityPositionMargin = 'CREATE_ADJUST_LIQUIDITY_POSITION_MARGIN',
	CreateIncreasePosition = 'CREATE_INCREASE_POSITION',
	CreateDecreasePosition = 'CREATE_DECREASE_POSITION',
	CancelOpenLiquidityPosition = 'CANCEL_OPEN_LIQUIDITY_POSITION',
	CancelCloseLiquidityPosition = 'CANCEL_CLOSE_LIQUIDITY_POSITION',
	CancelAdjustLiquidityPositionMargin = 'CANCEL_ADJUST_LIQUIDITY_POSITION_MARGIN',
	CancelIncreasePosition = 'CANCEL_INCREASE_POSITION',
	CancelDecreasePosition = 'CANCEL_DECREASE_POSITION',
	LiquidityPositionOpened = 'OPEN_POSITION',
	LiquidityPositionClosed = 'CLOSE_POSITION',
	LiquidityPositionMarginAdjusted = 'ADJUST_MARGIN',
	LiquidityPositionLiquidated = 'LIQUIDATE',
	LiquidityIncreaseMargin = 'INCREASE_MARGIN',
	LiquidityDecreaseMargin = 'DECREASE_MARGIN',
	PositionOpen = 'OPEN_POSITION',
	PositionIncreased = 'INCREASE_POSITION',
	PositionClose = 'CLOSE_POSITION',
	PositionDecreased = 'DECREASE_POSITION',
	PositionLiquidated = 'LIQUIDATE',
	IncreaseOrderCreated = 'CREATE_INCREASE_ORDER',
	IncreaseOrderUpdated = 'UPDATE_INCREASE_ORDER',
	IncreaseOrderCancelled = 'CANCEL_INCREASE_ORDER',
	IncreaseOrderExecuted = 'EXECUTE_INCREASE_ORDER'
}

export interface ITransationItem {
	id: string;
	// transation type
	type: string;
	// operation type
	operation: string;
	side: Side;
	// time
	blockTimestamp: number;
	// transaction hash
	txHash: string;
	// operation data
	data: object;
	// user
	account: string;
	// pool id
	poolId: string;
	// token decimal
	decimal: number;
	// token price
	price: string;
	// translate text
	description: string | undefined;
	// margin delta
	marginDelta: string;
	// liquidity delta
	liquidityDelta: string;
	baseToken: TokenInfo;
}
