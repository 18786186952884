import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { Trans, t } from '@lingui/macro';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import {
	DEFAULT_PAGE_SIZE,
	MAX_AMOUNT_FORMATTER_LIMIT,
	MAX_PAGE_SIZE,
	QUOTE_USD,
	Side,
	Version
} from 'config/constants';
import { useTradeHistoriesRequest } from 'fetch/useRequest';
import moment from 'moment';
import { useAccount } from 'wagmi';
import * as xlsx from 'xlsx';

import axios from 'axios';
import {
	formatTradesHistoryV1,
	formatTradesHistoryV2,
	useTradesHistoryLazyGraph
} from 'graphql/useTradesHistoryGraph';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import { filter, isEqual } from 'lodash';
import { useAppSelector } from 'state/hooks';
import { selectPoolIds } from 'state/pools/selector';
import {
	selectTransationOperation,
	selectVersion
} from 'state/setting/selector';
import {
	ExplorerDataType,
	amountFormatter,
	formatDate,
	formatTradeOperationV1,
	formatTradeOperationV2,
	formatUnixDate,
	getExplorerLink,
	isNegative,
	isPositive,
	multipliedBy,
	plus,
	shortenSymbolNative,
	toDecimalPlaces,
	toQuoteAmount
} from 'utils';

import Cell, { DirectionCell } from 'components/Common/Cell';
import NoData from 'components/NoData';
import SectionLoading from 'components/SectionLoading';
import Stronger from 'components/Stronger';

import VirtualTable from '../../../components/Table/VirtualTable';
import { BACKEND_HOST_URL } from '../../../config/backend';
import { poolsBaseState } from '../../../state/pools/slice';
import { TokenInfo } from '../../../types';

interface Column {
	id:
		| 'time'
		| 'market'
		| 'pool'
		| 'type'
		| 'side'
		| 'size'
		| 'operate'
		| 'price'
		| 'tradingFee'
		| 'realizedPnL'
		| 'transactionfee';
	label: string | React.ReactNode;
	minWidth?: number;
	align?: 'right' | 'left' | 'center';
	format: (row: any) => void;
}

export default function TradeHistoryList({
	poolIds,
	full = true,
	download,
	finishDownload
}: {
	poolIds: string[];
	full?: boolean;
	download: boolean;
	finishDownload: () => void;
}) {
	const { address } = useAccount();
	const theme = useTheme();
	const { currentChainId, currentChain: chain } = useCurrentChain();
	const { isMatchMobile, isMatchPad, isMatchPc } = useAppBreakpoints();
	const { pools } = useAppSelector(poolsBaseState);
	const isLogin = useCheckLogin();
	const allPoolIds = useAppSelector(selectPoolIds);
	const currentVersion = useAppSelector(selectVersion);
	const TransationOperation = useAppSelector(selectTransationOperation);
	const { fetch } = useTradesHistoryLazyGraph();
	const [from, setFrom] = useState(0);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [fetching, setFetching] = useState(false);
	const [hasMore, setHasMore] = useState(true);

	const targetPool = useMemo(() => {
		if (isEqual(poolIds, allPoolIds)) {
			return '';
		}
		return poolIds && poolIds[0];
	}, [poolIds]);

	const { trigger } = useTradeHistoriesRequest(
		address,
		targetPool,
		from,
		DEFAULT_PAGE_SIZE
	);

	const formatHistorySize = (size: string | number, baseToken: TokenInfo) => {
		return `${
			isPositive(size)
				? amountFormatter(
						size,
						2,
						baseToken.positionUnits,
						MAX_AMOUNT_FORMATTER_LIMIT
				  )
				: `-${amountFormatter(
						Math.abs(size),
						2,
						baseToken.positionUnits,
						MAX_AMOUNT_FORMATTER_LIMIT
				  )}`
		} ${shortenSymbolNative(baseToken, currentChainId)}`;
	};

	const exportData = async () => {
		const response = await axios.get(
			`${
				BACKEND_HOST_URL[currentChainId].V2
			}/trade-histories/${address}?&market=${targetPool}&from=${0}&limit=${1000}`
		);
		const result = response.data.data.transaction_histories.map(item => {
			const baseToken = pools.find(pool => {
				return pool.id.toLowerCase() === item.market_address.toLowerCase();
			}).baseToken;
			const data = JSON.parse(item.data);
			return {
				Time: formatUnixDate(moment(item.block_timestamp).unix()),
				Market: `${shortenSymbolNative(
					baseToken,
					currentChainId
				)}/${QUOTE_USD}`,
				Operation: formatTradeOperationV2(item.operation, false),
				Side: item.side === Side.LONG ? 'Long' : 'Short',
				Size: formatHistorySize(data.size_delta, baseToken),
				TransactionPrice:
					item.operation === TransationOperation.PositionOpen
						? toQuoteAmount(data.entry_price, baseToken.precision)
						: toQuoteAmount(data.trade_price, baseToken.precision),
				TradingFee: toQuoteAmount(data.trading_fee, 16),
				RealizedPnL:
					item.operation === TransationOperation.PositionLiquidated
						? toQuoteAmount(
								plus(
									plus(data.realized_pnl, data.funding_fee),
									data.liquidation_fee
								),
								16
						  )
						: toQuoteAmount(data.realized_pnl_delta, 16)
			};
		});
		const jsonWorkSheet = xlsx.utils.json_to_sheet(result);
		const workBook = {
			SheetNames: ['tradeHistory'],
			Sheets: {
				['tradeHistory']: jsonWorkSheet
			}
		};
		xlsx.writeFile(
			workBook,
			`tradeHistory_${moment().format('YYYY/MM/DD HH:mm')}.csv`
		);
		finishDownload();
	};
	useEffect(() => {
		if (download) {
			exportData();
		}
	}, [download]);

	const fetchActionV1 = useCallback(
		(index: number) => {
			if (hasMore && !fetching && address) {
				setFetching(true);
				return fetch({
					variables: {
						account: address,
						poolId: poolIds,
						skip: index === 0 ? 0 : data.length,
						first: isMatchMobile ? MAX_PAGE_SIZE : DEFAULT_PAGE_SIZE
					}
				})
					.then(res => {
						if (!res.data) {
							return;
						}
						setHasMore(res.data.trades.length === DEFAULT_PAGE_SIZE);
						setData(
							index === 0 ? res.data.trades : [...data, ...res.data.trades]
						);
					})
					.finally(() => setFetching(false));
			} else {
				return Promise.resolve();
			}
		},
		[address, poolIds, data, hasMore, fetching]
	);

	const fetchActionV2 = useCallback(
		(index: number) => {
			if (hasMore && !fetching && address) {
				setFetching(true);
				return trigger()
					.then(res => {
						if (!res.data) {
							return;
						}
						setHasMore(res.data.hasMore);
						const _transactionHistories = res.data.transactionHistories;
						const _len = _transactionHistories.length;
						setData(
							index === 0
								? _transactionHistories
								: [...data, ..._transactionHistories]
						);
						setFrom(
							_len === 0
								? 0
								: res.data.hasMore
								? _transactionHistories[_len - 1].id
								: from
						);
					})
					.finally(() => setFetching(false));
			} else {
				return Promise.resolve();
			}
		},
		[address, poolIds, data, hasMore, fetching]
	);

	useEffect(() => {
		setData([]);
		if (currentVersion === Version.V1) {
			fetchActionV1(0).finally(() => setLoading(false));
		}
		if (currentVersion === Version.V2) {
			fetchActionV2(0).finally(() => setLoading(false));
		}
	}, [currentVersion]);

	const tradesHistories =
		currentVersion === Version.V1
			? formatTradesHistoryV1({ trades: data })
			: formatTradesHistoryV2({ transactionHistories: data });

	const onRowClick = (row: any) => {
		const link = getExplorerLink(
			chain,
			row.txHash || '',
			ExplorerDataType.TRANSACTION
		);
		window.open(link);
	};

	const columns: readonly Column[] = [
		{
			id: 'time',
			label: t`Time`,
			minWidth: 120,
			format: row => (
				<Typography variant='body2'>
					{currentVersion === Version.V1 &&
						formatDate(row.blockTimestamp, 'MMM D, HH:mm:ss')}
					{currentVersion === Version.V2 &&
						formatUnixDate(moment(row.blockTimestamp).unix())}
				</Typography>
			)
		},
		{
			id: 'market',
			label: t`Market`,
			minWidth: 80,
			format: row => (
				<Typography variant='body2'>
					{`${shortenSymbolNative(row.baseToken, currentChainId)}/${QUOTE_USD}`}
				</Typography>
			)
		},
		{
			id: 'operate',
			label: t`Operation`,
			minWidth: 80,
			format: row => (
				<Typography variant='body2' color='inherit'>
					{currentVersion === Version.V1 &&
						formatTradeOperationV1(row.operation)}
					{currentVersion === Version.V2 &&
						formatTradeOperationV2(row.operation)}
				</Typography>
			)
		},
		{
			id: 'side',
			label: t`Side`,
			align: isMatchMobile ? 'right' : 'left',
			minWidth: 80,
			format: row => (
				<Typography
					variant='body2'
					sx={{
						color:
							row.side === Side.LONG
								? theme.palette.success.main
								: theme.palette.error.main
					}}
				>
					{row.side === Side.LONG ? (
						<span>
							<Trans>Long</Trans>
						</span>
					) : (
						<span>
							<Trans>Short</Trans>
						</span>
					)}
				</Typography>
			)
		},
		{
			id: 'size',
			label: t`Size`,
			align: 'left',
			minWidth: 120,
			format: row => (
				<Typography variant='body2'>
					{formatHistorySize(row.data.sizeDelta, row.baseToken)}
				</Typography>
			)
		},
		{
			id: 'price',
			label: t`Transaction Price`,
			align: isMatchMobile ? 'right' : 'left',
			minWidth: 120,
			format: row => (
				<Typography variant='body2'>
					{row.operation === TransationOperation.PositionOpen
						? toQuoteAmount(row.data.entryPrice, row.baseToken.precision)
						: toQuoteAmount(row.data.tradePrice, row.baseToken.precision)}
				</Typography>
			)
		},
		{
			id: 'tradingFee',
			label: t`Trading Fee`,
			minWidth: 100,
			format: row => (
				<Typography variant='body2'>
					{toQuoteAmount(row.data.tradingFee)}
				</Typography>
			)
		},
		{
			id: 'realizedPnL',
			label: t`Realized PnL`,
			minWidth: 120,
			align: isMatchMobile ? 'right' : 'left',
			format: row => (
				<>
					{currentVersion === Version.V1 ? (
						<Box>
							{row.operation === TransationOperation.PositionLiquidated ? (
								<Stronger
									value={
										<Typography
											className='leading-none'
											align='inherit'
											color={
												isPositive(
													toDecimalPlaces(
														multipliedBy(row.data.realizedPnLDelta, 100),
														2
													)
												)
													? theme.palette.success.main
													: isNegative(
															toDecimalPlaces(
																multipliedBy(row.data.realizedPnLDelta, 100),
																2
															)
													  )
													? theme.palette.error.main
													: theme.palette.text.primary
											}
											variant='body2'
										>
											{toQuoteAmount(row.realizedPnLTotal)}
										</Typography>
									}
									tooltip={
										<div>
											<Typography
												className='mb-2 w-[280px]'
												align='inherit'
												variant='body2'
											>
												{row.operation ===
												TransationOperation.PositionLiquidated ? (
													<Trans>
														Closed PnL = Closed Position PnL + Cumulative
														Funding Fees + Liquidation Fee
													</Trans>
												) : (
													<Trans>
														Closing PnL = Closed Position PnL + Cumulative
														Funding Fees
													</Trans>
												)}
											</Typography>

											<Cell
												label={<Trans>Closed Position PnL</Trans>}
												value={toQuoteAmount(row.data.realizedPnL)}
											/>

											<Cell
												label={<Trans>Cumulative Funding Fees</Trans>}
												value={toQuoteAmount(row.data.fundingFee)}
											/>

											{row.operation ===
												TransationOperation.PositionLiquidated && (
												<Cell
													label={<Trans>Liquidation Fee</Trans>}
													value={toQuoteAmount(row.data.liquidationFee)}
												/>
											)}
										</div>
									}
								/>
							) : (
								<Typography
									color={
										isPositive(toDecimalPlaces(row.data.realizedPnLDelta, 2))
											? theme.palette.success.main
											: isNegative(
													toDecimalPlaces(row.data.realizedPnLDelta, 2)
											  )
											? theme.palette.error.main
											: theme.palette.text.primary
									}
									variant='body2'
								>
									{toQuoteAmount(row.data.realizedPnLDelta)}
								</Typography>
							)}
						</Box>
					) : (
						<Box>
							{row.operation === TransationOperation.PositionLiquidated ? (
								<Stronger
									value={
										<Typography
											className='leading-none'
											align='inherit'
											color={
												isPositive(
													toDecimalPlaces(
														multipliedBy(row.data.realizedPnlDelta, 100),
														2
													)
												)
													? theme.palette.success.main
													: isNegative(
															toDecimalPlaces(
																multipliedBy(row.data.realizedPnlDelta, 100),
																2
															)
													  )
													? theme.palette.error.main
													: theme.palette.text.primary
											}
											variant='body2'
										>
											{toQuoteAmount(row.realizedPnlTotal)}
										</Typography>
									}
									tooltip={
										<div>
											<Typography
												className='mb-2 w-[280px]'
												align='inherit'
												variant='body2'
											>
												{row.operation ===
												TransationOperation.PositionLiquidated ? (
													<Trans>
														Closed PnL = Closed Position PnL + Cumulative
														Funding Fees + Liquidation Fee
													</Trans>
												) : (
													<Trans>
														Closing PnL = Closed Position PnL + Cumulative
														Funding Fees
													</Trans>
												)}
											</Typography>

											<Cell
												label={<Trans>Closed Position PnL</Trans>}
												value={toQuoteAmount(row.data.realizedPnl)}
											/>

											<Cell
												label={<Trans>Cumulative Funding Fees</Trans>}
												value={toQuoteAmount(row.data.fundingFee)}
											/>

											{row.operation ===
												TransationOperation.PositionLiquidated && (
												<Cell
													label={<Trans>Liquidation Fee</Trans>}
													value={toQuoteAmount(row.data.liquidationFee)}
												/>
											)}
										</div>
									}
								/>
							) : (
								<Typography
									color={
										isPositive(toDecimalPlaces(row.data.realizedPnlDelta, 2))
											? theme.palette.success.main
											: isNegative(
													toDecimalPlaces(row.data.realizedPnlDelta, 2)
											  )
											? theme.palette.error.main
											: theme.palette.text.primary
									}
									variant='body2'
								>
									{toQuoteAmount(row.data.realizedPnlDelta)}
								</Typography>
							)}
						</Box>
					)}
				</>
			)
		}
	];

	const filteredLabel = ['market', 'time', 'type', 'side', 'operate'];

	const filteredColumns = useMemo(() => {
		if (isMatchMobile) {
			return columns.filter(item => !filteredLabel.includes(item.id));
		}
		if (full) {
			return columns;
		}

		return filter(columns, item => {
			return item.id !== 'market';
		});
	}, [isMatchMobile, columns, full]);

	const columnItem = useCallback(
		(id: string) => {
			return columns.find(item => item.id === id);
		},
		[columns]
	);

	return (
		<>
			{loading && <SectionLoading />}
			{!loading &&
				(isLogin && tradesHistories?.length ? (
					<>
						{isMatchMobile && (
							<div>
								<Virtuoso
									endReached={
										currentVersion === Version.V1
											? fetchActionV1
											: fetchActionV2
									}
									style={{ height: 380 }}
									data={tradesHistories}
									itemContent={(index, row) => (
										<div className='py-2'>
											<div className='space-y-4'>
												<div className='flex items-center justify-between'>
													<div className=''>
														<div className='flex items-center space-x-2'>
															<Typography variant='h6'>
																{`${shortenSymbolNative(
																	row?.baseToken,
																	currentChainId
																)}/${QUOTE_USD}`}
															</Typography>
															{columnItem('side')?.format(row)}
														</div>
														<Typography variant='body2' color='text.secondary'>
															{currentVersion === Version.V1 &&
																formatDate(
																	row.blockTimestamp,
																	'MMM D, HH:mm:ss'
																)}
															{currentVersion === Version.V2 &&
																formatUnixDate(
																	moment(row.blockTimestamp).unix()
																)}
														</Typography>
													</div>
													<div>
														<DirectionCell
															label={columnItem('operate').format(row)}
															align='right'
															value=''
														/>
													</div>
												</div>
												<div className='grid grid-cols-2 gap-2 sm:gap-4'>
													{filteredColumns.map((column, columnIndex) => (
														<DirectionCell
															label={column.label}
															align={column.align}
															value={column.format(row)}
															key={columnIndex}
														/>
													))}
												</div>
											</div>
											<Divider
												className='my-3'
												sx={{ borderStyle: 'dashed' }}
											/>
										</div>
									)}
								/>
							</div>
						)}
						{isMatchPad && (
							<div className='mt-3'>
								<Virtuoso
									endReached={
										currentVersion === Version.V1
											? fetchActionV1
											: fetchActionV2
									}
									style={{ height: 400 }}
									className='pr-4'
									data={tradesHistories}
									itemContent={(columnIndex, item) => {
										return (
											<div className={classNames({ 'pr-4': isMatchPad })}>
												<div
													className={classNames(
														`grid grid-cols-${
															filteredColumns.length > 2 ? 3 : 2
														} gap-4 gap-y-3`,
														isMatchPad
															? `grid-cols-${
																	filteredColumns.length > 2 ? 4 : 2
															  }`
															: `grid-cols-${
																	filteredColumns.length > 2 ? 3 : 2
															  }`
													)}
												>
													{filteredColumns.map(
														(column: any, columnIndex: number) => (
															<DirectionCell
																key={columnIndex}
																label={column.label}
																align={
																	column.align
																		? column.align
																		: columnIndex &&
																		  (columnIndex + 1) %
																				(isMatchPad ? 4 : 3) ===
																				0
																		? 'right'
																		: 'left'
																}
																value={column.format(item)}
															/>
														)
													)}
												</div>
												<Divider className='my-4' />
											</div>
										);
									}}
								/>
							</div>
						)}
						{!isMatchPad && isMatchPc && (
							<div className='-mx-4'>
								<VirtualTable
									rows={tradesHistories}
									columns={filteredColumns}
									itemHeight={44}
									cellColor={theme.custom.paperBg}
									loadMore={
										currentVersion === Version.V1
											? fetchActionV1
											: fetchActionV2
									}
									onRowClick={onRowClick}
									isPointer
								/>
							</div>
						)}
					</>
				) : (
					<NoData>
						<Trans>No trade history</Trans>
					</NoData>
				))}
		</>
	);
}
