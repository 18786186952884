import { useEffect, useMemo } from 'react';
import { useUpdateEffect } from 'react-use';

import {
	EQU_CURRENCY,
	UNI_WRAPPED_NATIVE_CURRENCY,
	USDT_CURRENCY,
	WRAPPED_NATIVE_CURRENCY
} from 'config/constants/tokens';
import { useMultiTokens, useNativeTokenPrice } from 'fetch/useRequest';
import moment from 'moment';
import { useAccount } from 'wagmi';

import { TickMath } from 'hooks/V3/TickMath';
import { DEFAULT_UNI_POOL_FEE_AMOUNT } from 'hooks/V3/constants';
import { Fee_Amount, IV3Pool } from 'hooks/V3/types';
import { useComputeUniPoolAddresses } from 'hooks/V3/useComputePoolAddress';
import { useUniV3Pools } from 'hooks/V3/useUniV3Pools';
import {
	useFeeInfo,
	useNativeCurrencyBalance,
	useTokensBalances
} from 'hooks/useCurrencyBalance';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import useIsWindowVisible from 'hooks/useIsWindowVisible';
import { isEmpty } from 'lodash';
import { globalBaseState } from 'state/global/slice';
import { div, multipliedBy, sortsBefore } from 'utils';

import { useAppDispatch, useAppSelector } from '../hooks';
import {
	setAppToken,
	setAppTokenBalance,
	setAppTokenUsdPrice,
	setNativeBalance,
	setNativeSymbol,
	setNativeUsdPrice,
	setQuoteBalance,
	setQuoteToken,
	setTotalStakedWithMultiplier,
	setUniV3PoolMap,
	setUniV3Pools,
	setUniWeth,
	txBaseState
} from './slice';

export default function TxUpdater() {
	const { appTimer } = useAppSelector(globalBaseState);
	const { quoteToken, appToken, txVisibleSuccess, nativeUsdPrice, uniWeth } =
		useAppSelector(txBaseState);

	const dispatch = useAppDispatch();

	const { address } = useAccount();
	const { currentChainId, currentChain } = useCurrentChain();
	const isLogin = useCheckLogin();
	const isWindowVisible = useIsWindowVisible();

	const { nativeBalance, refetchNativeBalance } = useNativeCurrencyBalance();
	const uniPoolAddresses = useComputeUniPoolAddresses();

	// 默认计价币种
	useEffect(() => {
		dispatch(setQuoteToken(USDT_CURRENCY[currentChainId]));
		dispatch(setAppToken(EQU_CURRENCY[currentChainId]));
	}, [currentChainId]);

	// ETH余额
	useEffect(() => {
		dispatch(setNativeBalance(nativeBalance));
	}, [nativeBalance]);

	useEffect(() => {
		dispatch(setNativeSymbol(currentChain.nativeCurrency.symbol));
	}, [currentChain]);

	const targetTokens = useMemo(() => {
		if (!quoteToken || !appToken) {
			return null;
		}
		return [quoteToken, appToken];
	}, [quoteToken, appToken]);

	const { balances, refetch: refetchTokensBalances } =
		useTokensBalances(targetTokens);

	const { feeInfo, refetch: refetchFeeInfo } = useFeeInfo();

	// USDC余额 和 EQU余额
	useEffect(() => {
		if (balances) {
			const [quoteBalance, appTokenBalance] = balances;
			dispatch(setAppTokenBalance(appTokenBalance));
			dispatch(setQuoteBalance(quoteBalance));
		}
	}, [balances]);

	// FeeDistributor totalStakedWithMultiplier
	useEffect(() => {
		if (feeInfo) {
			const [totalStakedWithMultiplier] = feeInfo;
			dispatch(setTotalStakedWithMultiplier(totalStakedWithMultiplier));
		}
	}, [feeInfo]);

	useEffect(() => {
		if (isLogin) {
			refetchNativeBalance();
			refetchTokensBalances();
		}
		refetchFeeInfo();
	}, [address, isLogin, appTimer]);

	// 任何交易成功之后，需要刷新余额
	useUpdateEffect(() => {
		if (isLogin && txVisibleSuccess) {
			refetchNativeBalance();
			refetchTokensBalances();
		}
	}, [isLogin, txVisibleSuccess]);

	// UNI相关
	{
		// ETH的美元价格
		const wrappedNativeTokenId = useMemo(() => {
			if (!WRAPPED_NATIVE_CURRENCY[currentChainId]) {
				return null;
			}
			return [WRAPPED_NATIVE_CURRENCY[currentChainId].address];
		}, [currentChainId]);

		const { tokensMultiPrice } = useMultiTokens(wrappedNativeTokenId);

		useEffect(() => {
			if (
				tokensMultiPrice &&
				tokensMultiPrice.tokens &&
				tokensMultiPrice.tokens.length > 0
			) {
				const indexPrice = tokensMultiPrice.tokens[0].index_price;
				dispatch(setNativeUsdPrice(indexPrice));
			}
		}, [tokensMultiPrice]);

		useEffect(() => {
			const WETH = UNI_WRAPPED_NATIVE_CURRENCY[currentChainId];
			dispatch(setUniWeth(WETH));
		}, [currentChainId]);

		const fees = useMemo(() => {
			if (currentChain.testnet) {
				return [Fee_Amount.LOW, Fee_Amount.MEDIUM, Fee_Amount.HIGH];
			}
			return [
				Fee_Amount.LOWEST,
				Fee_Amount.LOW,
				Fee_Amount.MEDIUM,
				Fee_Amount.HIGH
			];
		}, [currentChain]);

		const { uniV3Pools, uniV3PoolMap, refetchV3Pools } = useUniV3Pools(
			uniPoolAddresses,
			fees
		);

		const isPoolToBase = useMemo(() => {
			if (!appToken) {
				return true;
			}
			return sortsBefore(appToken, uniWeth);
		}, [uniWeth, appToken]);

		const { data: apiTokenPrice, isLoading } = useNativeTokenPrice();

		useEffect(() => {
			// The price is not valid when market_price_last_updated_at longer than 2 minutes
			if (
				!isLoading &&
				!isEmpty(apiTokenPrice) &&
				apiTokenPrice?.market_price &&
				moment().utc().subtract(2, 'minutes').unix() <=
					moment(apiTokenPrice.market_price_last_updated_at).utc().unix()
			) {
				dispatch(setAppTokenUsdPrice(apiTokenPrice.market_price));
				return;
			}
			if (uniV3Pools && nativeUsdPrice) {
				dispatch(setUniV3Pools(uniV3Pools));
				let targetPool = uniV3Pools[0];
				const targetPoolDefault = uniV3Pools.find(
					(item: IV3Pool) => item.fee === DEFAULT_UNI_POOL_FEE_AMOUNT
				);
				if (targetPoolDefault) {
					targetPool = targetPoolDefault;
				}
				if (targetPool) {
					const _price = TickMath.getPriceBySqrtPrice(targetPool.sqrtPrice);
					let price = _price;
					if (!isPoolToBase) {
						price = div(1, _price);
					}
					const appTokenUsdPrice = multipliedBy(nativeUsdPrice, price);
					// console.log('EQU usd price : ', appTokenUsdPrice);
					dispatch(setAppTokenUsdPrice(appTokenUsdPrice));
				}
			}
		}, [uniV3Pools, nativeUsdPrice, isPoolToBase, apiTokenPrice, isLoading]);

		useEffect(() => {
			if (uniV3PoolMap) {
				dispatch(setUniV3PoolMap(uniV3PoolMap));
			}
		}, [uniV3PoolMap]);

		useUpdateEffect(() => {
			if (isWindowVisible) {
				refetchV3Pools();
			}
		}, [appTimer]);
	}

	return null;
}
