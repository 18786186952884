import React from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { abs, isPositive, isZero, toPercent } from 'utils';

import Minus from 'components/Svg/Icons/Minus';

export default React.memo(function FundingRate({
	value
}: {
	value: string | number | undefined;
}) {
	const theme = useTheme();
	return (
		<Box>
			{isPositive(value) ? (
				<Typography variant='body2' color='warning.main'>
					{`${toPercent(value, 6)}`}
				</Typography>
			) : isZero(value) ? (
				<Typography variant='body2'>{toPercent(value, 6)}</Typography>
			) : (
				<Typography
					variant='body2'
					color='warning.main'
					className='flex items-center space-x-0.25'
				>
					<Minus fill={theme.palette.warning.main} />
					<span>{`${toPercent(abs(value), 6)}`}</span>
				</Typography>
			)}
		</Box>
	);
});
