import { Link } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Page, Version } from 'config/constants';

import { useUniV3SwapLink } from 'hooks/V3/useUniV3LiquidityLink';
import { useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';
import { tradeBaseState } from 'state/trade/slice';
import { txBaseState } from 'state/tx/slice';
import { shortenSymbol } from 'utils';

import {
	AppToken,
	Leaderboard,
	Referrals,
	Stats,
	Vote
} from '../../components/Svg/Icons/Links';

export default function Links() {
	const { appToken } = useAppSelector(txBaseState);
	const { isBindReferralCode } = useAppSelector(tradeBaseState);
	const currentVersion = useAppSelector(selectVersion);
	const uniV3SwapLink = useUniV3SwapLink();
	const theme = useTheme();

	return (
		<Box
			className={`flex justify-around py-4 `}
			sx={{
				backgroundColor:
					theme.mode === 'light'
						? theme.custom.positiveColor
						: theme.palette.action.hover
			}}
		>
			<Typography
				className='flex flex-col items-center space-y-0.5'
				component='a'
				href={uniV3SwapLink}
				target='_blank'
			>
				<AppToken />
				<Typography variant='body1' fontWeight={500}>
					<Trans>Buy {shortenSymbol(appToken)}</Trans>
				</Typography>
			</Typography>
			<Typography
				className='flex flex-col items-center space-y-0.5'
				component={Link}
				to={
					isBindReferralCode
						? currentVersion === Version.V1
							? Page.ReferralsEFCMembersV1
							: Page.ReferralsEFCMembers
						: currentVersion === Version.V1
						? Page.ReferralsTradersV1
						: Page.ReferralsTraders
				}
			>
				<Referrals />
				<Typography variant='body1' fontWeight={500}>
					<Trans>Referrals</Trans>
				</Typography>
			</Typography>
			{currentVersion === Version.V1 && (
				<Typography
					className='flex flex-col items-center space-y-0.5'
					component={Link}
					to={Page.LeaderboardV1}
				>
					<Leaderboard />
					<Typography variant='body1' fontWeight={500}>
						<Trans>Leaderboard</Trans>
					</Typography>
				</Typography>
			)}

			<Typography
				className='flex flex-col items-center space-y-0.5'
				component='a'
				href={`${
					currentVersion === Version.V2
						? import.meta.env.VITE_WEBSITE_STATS
						: import.meta.env.VITE_WEBSITE_STATS_V1
				}`}
				target='_blank'
			>
				<Stats />
				<Typography variant='body1' fontWeight={500}>
					<Trans>Stats</Trans>
				</Typography>
			</Typography>

			<Typography
				className='flex flex-col items-center space-y-0.5'
				component='a'
				href={import.meta.env.VITE_WEBSITE_VOTE}
				target='_blank'
			>
				<Vote />
				<Typography variant='body1' fontWeight={500}>
					<Trans>Voting</Trans>
				</Typography>
			</Typography>
		</Box>
	);
}
