import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePrevious, useUpdate } from 'react-use';

import { Trans, t } from '@lingui/macro';
import ArrowDownIcon from '@mui/icons-material/ExpandMore';
import {
	Box,
	Button,
	Paper,
	Typography,
	alpha,
	useMediaQuery,
	useTheme
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import Decimal from 'decimal.js';
import { useAccount } from 'wagmi';

import _ from 'lodash';
import type { Merge } from 'type-fest';

import { StyleCheckbox } from 'components/Common/StyleCheckbox';
import StyledLink from 'components/Common/StyledLink';
import Tooltip from 'components/Common/Tooltip';
import Leverage from 'components/Leverage';
import LiqPrice from 'components/LiqPrice';
import Tip from 'components/Svg/Icons/Tip';

import Cell from '../../../components/Common/Cell';
import Dialog from '../../../components/Common/Dialog';
import { CommonStyledDivider } from '../../../components/Common/Styled';
import CommonButton from '../../../components/Common/StyledButton';
import StyledTab from '../../../components/Common/StyledTab';
import StyledTabs from '../../../components/Common/StyledTabs';
import Description from '../../../components/Description';
import NoData from '../../../components/NoData';
import Stronger from '../../../components/Stronger';
import ComputerIcon from '../../../components/Svg/Icons/Computer';
import Edit from '../../../components/Svg/Icons/Edit';
import Loading from '../../../components/Svg/Icons/Loading';
import TippingUnderline from '../../../components/TippingUnderline';
import {
	BACKEND_API_SWAGGER,
	Dom_Size,
	MAX_AMOUNT_FORMATTER_LIMIT,
	Page,
	QUOTE_USD,
	Side,
	Transaction_Status,
	Transaction_Type,
	Version
} from '../../../config/constants';
import { TransactionRecord } from '../../../entities/TransactionRecord';
import { useAppBreakpoints } from '../../../hooks/useAppBreakpoints';
import { useCheckLogin, useCurrentChain } from '../../../hooks/useCurrentChain';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import {
	liquidityBaseState,
	setDialogIsVisible
} from '../../../state/liquidity/slice';
import { selectCurrentPool } from '../../../state/pools/selector';
import { poolsBaseState, setV2PoolAddress } from '../../../state/pools/slice';
import { selectTransactionRecordList } from '../../../state/records/selector';
import { selectVersion } from '../../../state/setting/selector';
import {
	setOnlyUsedPools,
	settingBaseState
} from '../../../state/setting/slice';
import { txBaseState } from '../../../state/tx/slice';
import { RecordForLiqudility, RecordForRemoveLiquidity } from '../../../types';
import {
	amountFormatter,
	div,
	formatLeverage,
	formatNumber,
	isGreaterThan,
	isNegative,
	isPositive,
	isZero,
	minus,
	plus,
	shortenSymbol,
	shortenSymbolNative,
	toPercent,
	toQuoteAmount
} from '../../../utils';
import FreeTradingFeeWarning, {
	Type_Free_Trading_Fee
} from '../../components/FreeTradingFeeWarning';
import { StyledButton } from '../../components/Operations/PositionCells';
import LiquidityApr from '../../components/V2/LiquidityApr';
import V2Logo from '../../components/V2/V2Logo';
import Detail from '../Detail';
import LiquidityComputerDialog from '../LiquidityComputerDialog';
import LiquidityForm from '../LiquidityForm';
import { RowState, Tab_Chart_Type, Tab_Type } from '../LiquidityTable';
import RiskRate from '../RiskRate';
import AdjustLiquidityMargin from './AdjustLiquidityMarginV2';
import BalanceRateChart from './BalanceRateChart';
import BlinkTag from './BlinkTag';
import CollapseTableV2 from './CollapseTableV2';
import LiquidityCardV2 from './LiquidityCardV2';
import LiquidityFormTips from './LiquidityFormTips';
import LiquidityFormV2 from './LiquidityFormV2';
import LiquidityHistoryV2 from './LiquidityHistoryV2';
import PassivePositionHistories from './PassivePositionHistories';
import RemoveLiquidityV2 from './RemoveLiquidityV2';

const LiquidityTableV2 = ({ loading }: { loading: boolean }) => {
	const { poolInfo, poolAddress, liquidityPools } =
		useAppSelector(poolsBaseState);
	const { quoteToken } = useAppSelector(txBaseState);
	const transactionRecords = useAppSelector(selectTransactionRecordList);
	const currentVersion = useAppSelector(selectVersion);
	const currentPool = useAppSelector(selectCurrentPool);
	const { dialogIsVisible } = useAppSelector(liquidityBaseState);

	const { pool } = useParams();
	const navigator = useNavigate();

	const theme = useTheme();

	const [adjustMarginIsOpen, setAdjustMarginIsOpen] = useState(false);
	const [removeMarginIsOpen, setRemoveMarginIsOpen] = useState(false);
	const [currentPosition, setCurrentPosition] = useState({});
	const { onlyUsedPools } = useAppSelector(settingBaseState);

	const dispatch = useAppDispatch();
	const update = useUpdate();

	const [rowStates, setRowStates] = useState<Record<string, RowState>>({});

	const { currentChainId } = useCurrentChain();
	const { isMatchMobile, isMatchPc, isMatchPad } = useAppBreakpoints();
	const isLogin = useCheckLogin();

	const isMatchDown2XL = useMediaQuery(
		`(max-width: ${Dom_Size.PAGE_CONTAINER}px)`
	);

	const rows = useMemo(() => {
		return liquidityPools.map(item => {
			const currentPoolTransactionRecords = transactionRecords
				.filter(record => record.record)
				.filter(record => record.record.pool === item.address)
				.filter(record => record.status !== Transaction_Status.Error);

			const increaseLiquidityTransactionRecords =
				currentPoolTransactionRecords.filter(
					record =>
						record.record.transactionType ===
						Transaction_Type.IncreaseLiquidityV2
				) as Merge<TransactionRecord, { record: RecordForLiqudility }>[];
			const appendPositionTransactionRecords =
				currentPoolTransactionRecords.filter(
					record =>
						record.record.transactionType === Transaction_Type.AppendLiquidityV2
				) as Merge<TransactionRecord, { record: RecordForLiqudility }>[];
			const adjustMarginTransactionRecords =
				currentPoolTransactionRecords.filter(record =>
					[
						Transaction_Type.IncreaseMarginLiquidityV2,
						Transaction_Type.ReduceMarginLiquidityV2
					].includes(record.record.transactionType)
				);
			const removeLiquidityTransactionRecords =
				currentPoolTransactionRecords.filter(
					record =>
						record.record.transactionType === Transaction_Type.RemoveLiquidityV2
				) as Merge<TransactionRecord, { record: RecordForRemoveLiquidity }>[];

			return {
				...item,
				liquidityPositions: [
					...(increaseLiquidityTransactionRecords.map(record => ({
						myLiquidity: Number(record.record.liquidityAmount),
						margin: record.record.quoteAmount,
						initialMargin: record.record.quoteAmount,
						marginNet: record.record.quoteAmount,
						leverage: div(
							record.record.liquidityAmount,
							record.record.quoteAmount
						),
						utilizedLeverage: div(
							record.record.liquidityAmount,
							record.record.quoteAmount
						),
						riskRatio: 0,
						status: Transaction_Status.Pending,
						passivePosition: {}
					})) as unknown as (typeof liquidityPools)[number]['liquidityPositions']),
					...item.liquidityPositions.map(position => {
						const currentAdjustMarginTransactionRecord =
							adjustMarginTransactionRecords.find(
								record => record.record.positionId === position.id
							);

						const currentRemoveLiquidityTransactionRecord =
							removeLiquidityTransactionRecords.find(
								record => record.record.positionId === position.id
							);

						const currentAppendPositionTransactionRecords =
							appendPositionTransactionRecords.find(
								record => record.record.id === position.id
							);

						return {
							...position,
							status:
								(currentAdjustMarginTransactionRecord ||
									currentRemoveLiquidityTransactionRecord ||
									currentAppendPositionTransactionRecords) &&
								Transaction_Status.Pending
						};
					})
				]
			};
		});
	}, [liquidityPools, transactionRecords.length]);
	const filteredRows = useMemo(
		() =>
			rows.filter(item =>
				onlyUsedPools && isLogin ? isPositive(item.myLiquidity) : true
			),
		[rows, onlyUsedPools, isLogin]
	);

	const previousPassivePositions =
		usePrevious(
			filteredRows.map(item =>
				item.liquidityPositions.length
					? {
							...item.liquidityPositions[0].liquidityPassivePosition,
							marketAddress: item.address
					  }
					: { marketAddress: item.address }
			)
		) || [];
	const getSizeDiff = passivePosition => {
		if (!passivePosition) {
			return 0;
		}
		const prevPassivePosition = previousPassivePositions.find(
			item => item.marketAddress === passivePosition.address
		);
		if (!prevPassivePosition) {
			return 0;
		}
		if (prevPassivePosition.side !== passivePosition.side) {
			return passivePosition.size;
		} else {
			return minus(passivePosition.size, prevPassivePosition.size);
		}
	};

	const focusedRowIndex = useMemo(() => {
		if (!filteredRows || !filteredRows.length || !poolInfo) {
			return -1;
		}
		return filteredRows.findIndex(item => item.id === poolAddress);
	}, [filteredRows, poolAddress]);

	const focusedRow = useMemo(() => {
		if (!filteredRows || !filteredRows.length || !poolInfo) {
			return {};
		}
		return filteredRows.find(item => item.id === poolAddress);
	}, [filteredRows, poolAddress]);
	const itemHeight = 46;
	const ref = useRef(null);
	useEffect(() => {
		if (ref.current) {
			const prevItem = focusedRowIndex;
			ref.current.scrollTop = prevItem * (itemHeight + 16);
		}
	}, [focusedRowIndex]);

	const onResetRowStates = useCallback(
		(poolId: string) => {
			const result = {} as Record<string, RowState>;
			_.map(rowStates, (item: RowState, key: string) => {
				if (key !== poolId) {
					result[key] = {
						tabType: Tab_Type.Positions,
						expand: false
					};
				} else {
					result[key] = item;
				}
			});
			setRowStates(result);
		},
		[rowStates, setRowStates]
	);

	const onHandleSetCurrentPool = (row: any) => {
		dispatch(setV2PoolAddress(row.id));
		navigator(
			`${currentVersion === Version.V1 ? Page.PoolsV1 : Page.Pools}/${row.id}`
		);
		onResetRowStates(row.id);
	};

	const setCurrentPositionProxy = (
		item: (typeof filteredRows)[number]['liquidityPositions'][number]
	) =>
		setCurrentPosition({
			...item,
			liquidity: item.myLiquidity
		});

	const onOpenAdjustMargin = (
		item: (typeof filteredRows)[number]['liquidityPositions'][number]
	) => {
		setCurrentPositionProxy(item);
		setAdjustMarginIsOpen(true);
	};
	const onOpenRemoveMargin = (
		item: (typeof filteredRows)[number]['liquidityPositions'][number]
	) => {
		setCurrentPositionProxy(item);
		setRemoveMarginIsOpen(true);
	};
	const onCloseAdjustMarginDialog = () => setAdjustMarginIsOpen(false);
	const onCloseRemoveMarginDialog = () => setRemoveMarginIsOpen(false);

	const onHandleShowChart = (e: any, pool: any) => {
		e.stopPropagation();
		onResetRowStates(pool.id);

		onHandleSetCurrentPool(pool);
		setRowStates((state: any) => ({
			...state,
			[pool.id]: { ...state[pool.id], expand: !state[pool.id]?.expand }
		}));
	};

	useEffect(() => {
		if (rows.length > 0) {
			const result = {} as Record<string, RowState>;
			rows.forEach((item: any) => {
				result[item.id] = {
					tabType: Tab_Type.Positions,
					expand: false
				};
			});
			setRowStates(result);
		}
	}, [rows.length]);

	useEffect(() => {
		if (adjustMarginIsOpen || removeMarginIsOpen) {
			if (
				focusedRow &&
				focusedRow.liquidityPositions &&
				isPositive(focusedRow.liquidityPositions.length)
			) {
				if (focusedRow.liquidityPositions.length === 1) {
					setCurrentPositionProxy(focusedRow.liquidityPositions[0]);
				} else if (focusedRow.liquidityPositions.length === 2) {
					setCurrentPositionProxy(focusedRow.liquidityPositions[1]);
				} else {
					setCurrentPositionProxy(focusedRow.liquidityPositions[0]);
				}
			}
		}
	}, [adjustMarginIsOpen, removeMarginIsOpen, focusedRow]);

	const headers: {
		[key: string]: any;
		cellRender?: (item: (typeof liquidityPools)[number]) => void;
	}[] = [
		{
			key: 'baseSymbol',
			label: <Trans>Pool</Trans>,
			align: 'left',
			width: '15%',
			cellRender: row => {
				return (
					<div className='flex items-center'>
						<Box
							sx={{ maxWidth: 'calc(100% - 28px)' }}
							className='flex space-x-1 flex-wrap'
						>
							<Typography
								className='overflow-hidden break-words'
								fontWeight='medium'
								variant={isMatchMobile ? 'inherit' : 'h6'}
							>
								{`${shortenSymbolNative(
									row.baseToken,
									currentChainId
								)}/${QUOTE_USD}`}
							</Typography>
						</Box>
						<div className='flex items-center justify-end'>
							<Typography
								onClick={e => onHandleShowChart(e, row)}
								color='text.secondary'
								className={classNames(
									'relative text-base -right-2 cursor-pointer rounded-full p-0.5',
									rowStates[row.id]?.expand ? 'rotate-180' : 'rotate-0'
								)}
								component='span'
								sx={{
									'&:hover': {
										backgroundColor: theme.palette.action.hover
									}
								}}
							>
								<ArrowDownIcon color='inherit' />
							</Typography>
						</div>
					</div>
				);
			}
		},
		{
			key: 'apr',
			label: (
				<div className='flex items-center'>
					<TippingUnderline
						placement='bottom'
						tooltip={
							<div>
								<Typography>
									<Trans>
										Max APR is calculated based on LPs&apos; fee income in the
										last 7 days, EQU daily emission, and the Max Leverage of the
										pool. PnL from passive position is not included.
									</Trans>
								</Typography>
							</div>
						}
						label={
							<Typography variant='body2'>
								<Trans>Max APR</Trans>
							</Typography>
						}
					/>
				</div>
			),
			align: 'left',
			width: '13%',
			cellRender: row => {
				return (
					<Typography className='flex gap-1' variant='body2'>
						<LiquidityApr item={row} />
					</Typography>
				);
			}
		},
		{
			key: 'dayRevenue',
			label: (
				<TippingUnderline
					label={<Trans>Fee Income (7d)</Trans>}
					tooltip={
						<Trans>
							LP&apos;s trading fee and funding fee income in the last 7 days.
						</Trans>
					}
				/>
			),
			align: 'left',
			width: '10%',
			cellRender: row => (
				<div className='flex items-center space-x-1'>
					<TippingUnderline
						underlineColor='secondary'
						label={
							<Typography color='textPrimary' variant='body2'>
								{toQuoteAmount(plus(row.fee7d, row.fundingFee7d))}
							</Typography>
						}
						tooltip={
							<div>
								<Cell
									label={<Trans>Trading Fees</Trans>}
									value={toQuoteAmount(row.fee7d)}
								/>
								<Cell
									label={<Trans>Funding Fees</Trans>}
									value={toQuoteAmount(row.fundingFee7d)}
								/>
							</div>
						}
					/>
					{isZero(row.referralDiscountRate) && (
						<FreeTradingFeeWarning type={Type_Free_Trading_Fee.Warning} />
					)}
				</div>
			)
		},
		{
			key: 'liquidity',
			label: <Trans>Liquidity</Trans>,
			align: 'left',
			width: '10%',
			cellRender: row => <> {amountFormatter(row.liquidity, 2, 2)}</>
		},
		{
			key: 'balanceRate',
			label: <Trans>Balance Rate</Trans>,
			align: 'left',
			width: '10%',
			cellRender: row => <> {toPercent(row.balanceRate)}</>,
			invisible: !isMatchPc
		},
		{
			key: 'myLiquidity',
			label: (
				<>
					{!isMatchMobile && isLogin && (
						<StyleCheckbox
							checked={onlyUsedPools}
							onChange={e => dispatch(setOnlyUsedPools(e.target.checked))}
							size='small'
							sx={{ height: '16px', paddingLeft: 0 }}
						/>
					)}
					<Trans>My Liquidity</Trans> / <Trans>Passive Position</Trans>
				</>
			),
			align: 'left',
			width: '20%',
			cellRender: row => {
				if (!isLogin) {
					return '-';
				}
				const liquidityPassivePosition =
					row.liquidityPositions[0]?.liquidityPassivePosition || {};
				const myLiquidity = row.liquidityPositions.reduce(
					(pre, cur) => plus(pre, cur.liquidity),
					'0'
				);
				const sizeDiff = getSizeDiff(liquidityPassivePosition);
				return (
					<div className='flex space-x-2 items-center'>
						<Typography variant='body2'>
							{isZero(myLiquidity) ? '-' : amountFormatter(myLiquidity, 2, 2)}
						</Typography>
						{liquidityPassivePosition && liquidityPassivePosition.size && (
							<BlinkTag
								key={theme.mode}
								blinkKey={`${liquidityPassivePosition.side}-${liquidityPassivePosition.size}`}
								activeColor={alpha(
									isGreaterThan(sizeDiff, 0)
										? theme.palette.success.main
										: theme.palette.error.main,
									0.16
								)}
								bgColor={theme.custom.paperBg}
							>
								<div className='flex gap-4 px-4 py-2'>
									<Typography
										variant='body2'
										className='flex gap-1'
										color={
											liquidityPassivePosition.side === Side.SHORT
												? 'error.main'
												: 'success.main'
										}
									>
										{isMatchPad && (
											<>
												{liquidityPassivePosition.side === Side.SHORT
													? 'S'
													: 'L'}
											</>
										)}
										{!isMatchPad && (
											<>
												{liquidityPassivePosition.side === Side.SHORT
													? t`Short`
													: t`Long`}
											</>
										)}

										<div>
											{formatLeverage(liquidityPassivePosition.myLeverage)}
										</div>
									</Typography>
									{!isMatchPad && (
										<RiskRate
											risk={row.liquidityPositions[0]?.riskRatio}
											size={'small'}
										/>
									)}
								</div>
							</BlinkTag>
						)}
					</div>
				);
			}
		},
		{
			key: 'operation',
			width: '15%',
			label: '',
			align: 'right',
			cellRender: row => (
				<Button
					variant='outlined'
					onClick={() => setAddConfirmTips(true)}
					className='font-normal'
					sx={{
						height: '32px',
						color: alpha(theme.palette.primary.main, 0.8),
						border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
						'&:hover': {
							border: `1px solid ${theme.palette.primary.main}`,
							background: alpha(theme.palette.primary.main, 0.12)
						}
					}}
				>
					<Trans>Add Liquidity</Trans>
				</Button>
			)
		}
	];

	const onHandleChangeOperationType = (value: Tab_Type, rowState: RowState) => {
		rowState.tabType = value;
		update();
	};
	const { address } = useAccount();

	const contentRender = (row: (typeof filteredRows)[number]) => (
		<Box key={row.id}>
			{rowStates[row.id]?.expand ? (
				<Box className='relative py-4 px-4 sm:p-3 sm:hidden'>
					<StyledTabs value={Tab_Chart_Type.BalanceRate}>
						<StyledTab
							label={<Trans>Balance Rate</Trans>}
							value={Tab_Chart_Type.BalanceRate}
							className='pl-0'
						/>
					</StyledTabs>
					<CommonStyledDivider type='solid' />
					<BalanceRateChart />
				</Box>
			) : null}
			{row.id === poolAddress && (
				<Box className='relative py-4 px-4 sm:p-3'>
					<StyledTabs
						value={rowStates[row.id]?.tabType || Tab_Type.Positions}
						onChange={(_, value) =>
							onHandleChangeOperationType(value, rowStates[row.id])
						}
					>
						<StyledTab
							className='pl-0'
							label={<Trans>Position</Trans>}
							value={Tab_Type.Positions}
						/>
						<StyledTab
							label={<Trans>Passive Position Changes (Live)</Trans>}
							value={Tab_Type.PassivePositionHistory}
						/>
						<StyledTab
							label={<Trans>History</Trans>}
							value={Tab_Type.TradeHistory}
						/>
					</StyledTabs>
					<CommonStyledDivider type='solid' />
					{rowStates[row.id]?.tabType === Tab_Type.TradeHistory ? (
						<LiquidityHistoryV2 market={row.id} />
					) : rowStates[row.id]?.tabType === Tab_Type.PassivePositionHistory ? (
						<PassivePositionHistories key={address} market={row.id} />
					) : (
						<Box
							className='space-y-4 pr-2 sm:pr-0'
							sx={{
								maxHeight: !isMatchMobile ? Dom_Size.LIST_SCOLL : 'auto',
								overflowY: 'auto'
							}}
						>
							{row.liquidityPositions.length === 0 || !isLogin ? (
								<NoData>
									<Trans>No open positions</Trans>
								</NoData>
							) : (
								row.liquidityPositions.map((item, itemIndex) => {
									const liquidityPassivePosition =
										item.liquidityPassivePosition || {};
									const havePassivePosition = Boolean(
										liquidityPassivePosition.size
									);
									const sizeDiff = getSizeDiff(liquidityPassivePosition);

									return (
										<>
											<Box
												sx={{
													borderBottom:
														itemIndex !== row.liquidityPositions.length - 1
															? '1px dashed rgba(255, 255, 255, 0.1)'
															: ''
												}}
												className={classNames(
													'flex justify-between sm:flex-col sm:space-y-4',
													{
														'pb-5':
															itemIndex !== row.liquidityPositions.length - 1
													}
												)}
												key={itemIndex}
											>
												<Box className='flex-1'>
													<div className='grid grid-cols-3 gap-y-3'>
														{/* 第一行 */}
														<>
															<Description
																vertical={isMatchDown2XL}
																label={<Trans>Liquidity</Trans>}
															>
																<div className='flex space-x-3'>
																	<Typography variant='body2'>
																		{toQuoteAmount(item.myLiquidity)}
																	</Typography>
																	{item.status ===
																		Transaction_Status.Pending && (
																		<Loading size={16} />
																	)}
																</div>
															</Description>
															<Description
																vertical={isMatchDown2XL}
																label={<Trans>Leverage</Trans>}
															>
																<Typography variant='body2'>
																	{isPositive(item.leverage)
																		? formatLeverage(item.leverage)
																		: '-'}
																</Typography>
															</Description>
															<Description
																vertical={isMatchDown2XL}
																label={<Trans>Trading Fees</Trans>}
															>
																<Typography
																	variant='body2'
																	color={
																		isPositive(item.tradingFee)
																			? theme.palette.success.main
																			: theme.palette.text.primary
																	}
																>
																	{toQuoteAmount(
																		item.tradingFee,
																		2,
																		Decimal.ROUND_DOWN,
																		true
																	)}
																</Typography>
															</Description>
														</>
														{/* 第二行 */}
														<>
															<Description
																vertical={isMatchDown2XL}
																label={<Trans>Margin</Trans>}
															>
																<div className='flex items-center'>
																	<Stronger
																		tooltip={
																			<div className='space-y-1'>
																				<Cell
																					label={
																						<Trans>Total Added Margin</Trans>
																					}
																					value={`${formatNumber(
																						item.marginIncreased
																					)} ${shortenSymbol(quoteToken)}`}
																				/>
																				<Cell
																					label={
																						<Trans>Total Reduced Margin</Trans>
																					}
																					value={`${formatNumber(
																						item.marginDecreased
																					)} ${shortenSymbol(quoteToken)}`}
																				/>
																				<Cell
																					label={<Trans>Realized PnL</Trans>}
																					value={
																						<Typography
																							variant='body2'
																							color={
																								isPositive(item.pnl)
																									? theme.palette.success.main
																									: isNegative(item.pnl)
																									? theme.palette.error.main
																									: theme.palette.text.primary
																							}
																						>
																							{toQuoteAmount(
																								item.pnl,
																								2,
																								Decimal.ROUND_DOWN,
																								true
																							)}
																						</Typography>
																					}
																				/>
																				<Cell
																					label={<Trans>Trading Fees</Trans>}
																					value={
																						<Typography
																							variant='body2'
																							color={
																								isPositive(item.tradingFee)
																									? theme.palette.success.main
																									: theme.palette.text.primary
																							}
																						>
																							{toQuoteAmount(
																								item.tradingFee,
																								2,
																								Decimal.ROUND_DOWN,
																								true
																							)}
																						</Typography>
																					}
																				/>
																				<Cell
																					label={<Trans>Funding Fees</Trans>}
																					value={
																						<Typography
																							variant='body2'
																							color={
																								isPositive(item.fundingFee)
																									? theme.palette.success.main
																									: isNegative(item.fundingFee)
																									? theme.palette.error.main
																									: theme.palette.text.primary
																							}
																						>
																							{toQuoteAmount(
																								item.fundingFee,
																								2,
																								Decimal.ROUND_DOWN,
																								true
																							)}
																						</Typography>
																					}
																				/>
																			</div>
																		}
																		value={`${formatNumber(
																			item.marginNet
																		)} ${shortenSymbol(quoteToken)}`}
																	/>
																	<IconButton
																		className={classNames(
																			{
																				'opacity-30':
																					item.status ===
																					Transaction_Status.Pending
																			},
																			'cursor-pointer sm:hidden'
																		)}
																		disabled={
																			item.status === Transaction_Status.Pending
																		}
																		onClick={() => onOpenAdjustMargin(item)}
																	>
																		<Edit />
																	</IconButton>
																</div>
															</Description>

															<Description
																valueWrap={isMatchDown2XL}
																vertical={isMatchDown2XL}
																label={<Trans>Risk</Trans>}
															>
																<Typography variant='body2'>
																	<RiskRate
																		risk={item.riskRatio}
																		tooltip={
																			<Trans>
																				Indicates the risk rate of the current
																				position. When the risk rate reaches
																				100%, a liquidation will be triggered.
																			</Trans>
																		}
																	/>
																</Typography>
															</Description>
															<Description
																vertical={isMatchDown2XL}
																label={<Trans>Funding Fees</Trans>}
															>
																<Typography
																	className='space-x-1 whitespace-nowrap leading-none'
																	component='span'
																	variant='body2'
																	color={
																		isPositive(item.fundingFee)
																			? theme.palette.success.main
																			: isNegative(item.fundingFee)
																			? theme.palette.error.main
																			: theme.palette.text.primary
																	}
																>
																	{toQuoteAmount(
																		item.fundingFee,
																		2,
																		Decimal.ROUND_DOWN,
																		true
																	)}
																</Typography>
															</Description>
														</>
													</div>
												</Box>
												<div className='flex items-center space-x-3'>
													{isMatchMobile && (
														<CommonButton
															onClick={() => onOpenAdjustMargin(item)}
															componentVariant='outlined'
															size='small'
															color='secondary'
															disabled={
																item.status === Transaction_Status.Pending
															}
														>
															<Typography color='textPrimary' variant='body2'>
																<Trans>Adjust Margin</Trans>
															</Typography>
														</CommonButton>
													)}
													<StyledButton
														variant='outlined'
														size='small'
														color='secondary'
														disabled={
															item.status === Transaction_Status.Pending
														}
														onClick={() => onOpenRemoveMargin(item)}
													>
														<Typography color='textPrimary' variant='body2'>
															<Trans>Remove</Trans>
														</Typography>
													</StyledButton>
												</div>
											</Box>
											{/* 被动持仓 */}
											<Box
												className='p-3 sm:px-0'
												sx={{
													background: theme.custom.ListBg,
													borderRadius: '4px'
												}}
											>
												<div className='grid grid-cols-3 gap-y-3 sm:grid-cols-3'>
													<div className='col-span-3 flex items-center gap-1'>
														<Typography color='textPrimary' variant='body2'>
															<Trans>Passive Position</Trans>
															<Tooltip
																title={
																	<>
																		<Typography>
																			<Trans>
																				To manage net open position risk, it is
																				recommended to use hedging strategies.
																			</Trans>{' '}
																		</Typography>
																		<Typography>
																			<StyledLink
																				type='white'
																				href={BACKEND_API_SWAGGER}
																				target={
																					isMatchMobile ? '_self' : '_blank'
																				}
																				sx={{
																					textDecoration: 'underline'
																				}}
																			>
																				<Trans>
																					Please refer to the API documentation
																					&gt;&gt;
																				</Trans>
																			</StyledLink>
																		</Typography>
																	</>
																}
															>
																<div className='inline-block ml-1 mb-0.25'>
																	<Tip />
																</div>
															</Tooltip>
														</Typography>
														{havePassivePosition && (
															<Typography
																variant='body2'
																color={
																	liquidityPassivePosition.side === Side.SHORT
																		? 'error.main'
																		: 'success.main'
																}
															>
																{liquidityPassivePosition.side === Side.SHORT
																	? t`Short`
																	: t`Long`}
																{liquidityPassivePosition?.myLeverage && (
																	<span className='ml-1'>
																		<Leverage
																			value={
																				liquidityPassivePosition?.myLeverage as string
																			}
																			margin={item.marginNet}
																		/>
																	</span>
																)}
															</Typography>
														)}
													</div>
													<>
														<Description
															label={
																<Typography variant='body2'>
																	<Trans>Size</Trans>
																</Typography>
															}
														>
															<div className='flex flex-wrap gap-2'>
																<Typography
																	variant='body2'
																	className='break-words overflow-hidden'
																>
																	{amountFormatter(
																		liquidityPassivePosition.size || '0',
																		2,
																		row.baseToken.positionUnits,
																		MAX_AMOUNT_FORMATTER_LIMIT,
																		true
																	)}
																	&nbsp;
																	{havePassivePosition &&
																		shortenSymbolNative(
																			row.baseToken,
																			currentChainId
																		)}
																</Typography>
																<Typography variant='body2'>
																	<BlinkTag
																		key={theme.mode}
																		blinkKey={`${liquidityPassivePosition.side}-${liquidityPassivePosition.size}`}
																		activeColor={
																			!isZero(sizeDiff)
																				? alpha(
																						isGreaterThan(sizeDiff, 0)
																							? theme.palette.success.main
																							: theme.palette.error.main,
																						0.16
																				  )
																				: 'transparent'
																		}
																		bgColor='transparent'
																		hidden
																	>
																		<Box
																			className='px-2'
																			color={
																				isGreaterThan(sizeDiff, 0)
																					? 'success.main'
																					: 'error.main'
																			}
																		>
																			{sizeDiff &&
																				toQuoteAmount(
																					sizeDiff,
																					poolInfo.baseToken.positionUnits,
																					Decimal.ROUND_DOWN,
																					true
																				)}
																		</Box>
																	</BlinkTag>
																</Typography>
															</div>
														</Description>
														<Description label={<Trans>Entry Price</Trans>}>
															<Typography variant='body2'>
																{toQuoteAmount(
																	liquidityPassivePosition.entryPrice,
																	row.baseToken.precision
																)}
															</Typography>
														</Description>
														<Description label={<Trans>Unrealized PnL</Trans>}>
															<Typography
																variant='body2'
																color={
																	isPositive(liquidityPassivePosition.pnl)
																		? theme.palette.success.main
																		: isNegative(liquidityPassivePosition.pnl)
																		? theme.palette.error.main
																		: theme.palette.text.primary
																}
															>
																{toQuoteAmount(
																	liquidityPassivePosition.size
																		? liquidityPassivePosition.pnl
																		: '',
																	2,
																	Decimal.ROUND_DOWN,
																	true
																)}
															</Typography>
														</Description>
													</>

													{/* 第二行 */}
													<>
														<Description label={<Trans>Index Price</Trans>}>
															<Typography variant='body2'>
																{toQuoteAmount(
																	liquidityPassivePosition.indexPrice,
																	row.baseToken.precision
																)}
															</Typography>
														</Description>
														<Description label={<Trans>Liq. Price</Trans>}>
															<Typography variant='body2'>
																{!havePassivePosition && '-'}
																{havePassivePosition &&
																	isPositive(
																		liquidityPassivePosition.liquidationPrice
																	) && (
																		<LiqPrice
																			value={
																				liquidityPassivePosition.liquidationPrice
																			}
																			precision={row.baseToken.precision}
																		/>
																	)}
																{havePassivePosition &&
																	isPositive(item.margin) &&
																	!isPositive(
																		liquidityPassivePosition.liquidationPrice
																	) &&
																	'-'}
																{havePassivePosition &&
																	!isPositive(item.margin) &&
																	!isPositive(
																		liquidityPassivePosition.liquidationPrice
																	) &&
																	'-'}
															</Typography>
														</Description>
														<Description
															label={
																<TippingUnderline
																	tooltip={
																		<Typography>
																			<Trans>
																				The cumulative realized PnL of the
																				passive positions under this liquidity
																				position. The passive positions are
																				automatically adjusted by the system
																				based on market conditions. Positions
																				may be opened or closed frequently, and
																				the Realized PnL will also change
																				accordingly.
																			</Trans>
																		</Typography>
																	}
																	label={
																		<Typography variant='body2'>
																			<Trans>Realized PnL</Trans>
																		</Typography>
																	}
																/>
															}
														>
															<Typography
																variant='body2'
																color={
																	isPositive(item.pnl)
																		? theme.palette.success.main
																		: isNegative(item.pnl)
																		? theme.palette.error.main
																		: theme.palette.text.primary
																}
															>
																{toQuoteAmount(
																	item.pnl,
																	2,
																	Decimal.ROUND_DOWN,
																	true
																)}
															</Typography>
														</Description>
													</>
												</div>
											</Box>
										</>
									);
								})
							)}
						</Box>
					)}
				</Box>
			)}
		</Box>
	);

	const setIsVisibleForm = (value: boolean) => {
		dispatch(setDialogIsVisible(value));
	};
	const onHandleCloseForm = () => setIsVisibleForm(false);

	const [addConfirmTips, setAddConfirmTips] = useState(false);
	const [visibleComputer, setVisibleComputer] = useState(false);
	const onOpenComputerDialog = () => {
		setVisibleComputer(true);
		setIsVisibleForm(false);
	};
	const onClose = () => setVisibleComputer(false);

	return (
		<Paper
			className='flex-1 sm:border-t'
			sx={{
				borderColor: theme.palette.divider,
				background: isMatchMobile
					? 'transparent'
					: theme.palette.background.paper
			}}
		>
			{isMatchMobile && pool ? (
				<Detail
					rows={rows}
					contentRender={contentRender}
					poolsChartState={rowStates}
				/>
			) : (
				<Paper
					className='flex-1'
					sx={{
						background: isMatchMobile
							? 'transparent'
							: theme.palette.background.paper
					}}
				>
					{isMatchMobile ? (
						<LiquidityCardV2
							isLoading={onlyUsedPools && loading}
							rows={filteredRows}
							onClickRow={onHandleSetCurrentPool}
							getSizeDiff={getSizeDiff}
						/>
					) : (
						<CollapseTableV2
							isLoading={onlyUsedPools && loading}
							headers={headers}
							focusedRowIndex={focusedRowIndex}
							rows={filteredRows}
							noData={
								<NoData size='large'>
									<Trans>No open positions</Trans>
								</NoData>
							}
							contentRender={contentRender}
							onClickRow={onHandleSetCurrentPool}
							ref={ref}
						/>
					)}
				</Paper>
			)}

			{adjustMarginIsOpen && (
				<AdjustLiquidityMargin
					currentPosition={currentPosition}
					onClose={onCloseAdjustMarginDialog}
				/>
			)}

			{removeMarginIsOpen && (
				<RemoveLiquidityV2
					currentPosition={currentPosition}
					onClose={onCloseRemoveMarginDialog}
				/>
			)}

			<Dialog
				open={dialogIsVisible}
				onClose={onHandleCloseForm}
				title={
					<div className='flex flex-1 items-center gap-3.5'>
						<div className='flex items-center'>
							<Trans>
								Add {shortenSymbolNative(poolInfo?.baseToken, currentChainId)}{' '}
								Liquidity
							</Trans>
							{currentVersion === Version.V2 && (
								<V2Logo className='ml-1' width={18} height={14} />
							)}
						</div>
						<IconButton className='-mr-1' onClick={onOpenComputerDialog}>
							<ComputerIcon
								color={theme.palette.mode === 'dark' ? 'white' : 'black'}
							/>
						</IconButton>
					</div>
				}
			>
				{currentVersion === Version.V1 ? (
					<LiquidityForm />
				) : (
					<LiquidityFormV2 />
				)}
			</Dialog>
			{visibleComputer && (
				<LiquidityComputerDialog
					poolData={liquidityPools}
					onClose={onClose}
					back={() => {
						setVisibleComputer(false);
						setIsVisibleForm(true);
					}}
				/>
			)}
			{addConfirmTips && (
				<LiquidityFormTips
					onConfirm={() => setIsVisibleForm(true)}
					onClose={() => setAddConfirmTips(false)}
				/>
			)}
		</Paper>
	);
};

export default LiquidityTableV2;
