import React, { useCallback, useContext, useMemo, useState } from 'react';

import { Trans } from '@lingui/macro';
import { Card, CardContent, Typography } from '@mui/material';
import {
	LEVERAGE_MAKERS,
	MAX_PRECISION,
	Side,
	Transaction_Type,
	Version
} from 'config/constants';
import { TradeContext } from 'context/TradeContext';

import warningIcon from 'assets/svg/icon-warning.svg';
import { PositionUtil } from 'entities/PositionUtil';
import useAcceptableEntryPrice from 'hooks/useAcceptableEntryPrice';
import { useCalculateLiqPrice } from 'hooks/useCalculateLiqPrice';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import { useIndexPriceForTrade } from 'hooks/useIndexPriceForTrade';
import { useListenMarketAmount } from 'hooks/useListenMarketAmount';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
	selectCurrentInitialMargin,
	selectCurrentLiquidityDelta,
	selectCurrentMarginDelta,
	selectCurrentOriginTradingFee,
	selectCurrentPositionAmount,
	selectCurrentTradingFee
} from 'state/market/selector';
import { marketBaseState } from 'state/market/slice';
import { poolsBaseState } from 'state/pools/slice';
import {
	setCheckedTradeLeverage,
	setTradeLeverage,
	settingBaseState
} from 'state/setting/slice';
import { selectLeverageText } from 'state/trade/selector';
import { tradeBaseState } from 'state/trade/slice';
import { selectQuoteBalanceText } from 'state/tx/selector';
import { txBaseState } from 'state/tx/slice';
import {
	computeLeverageMarks,
	div,
	formatLeverage,
	formatNumber,
	isEqualTo,
	isLessThanOrEqualTo,
	isNegative,
	isNumeric,
	isPositive,
	multipliedBy,
	plus,
	shortenSymbol,
	shortenSymbolNative,
	toPercent,
	toQuoteAmount
} from 'utils';

import Cell from 'components/Common/Cell';
import { StyleCheckbox } from 'components/Common/StyleCheckbox';
import {
	CommonStyledBaseInput,
	CommonStyledMaxButton
} from 'components/Common/Styled';
import Tooltip from 'components/Common/Tooltip';
import LeverageListener from 'components/LeverageListener';
import LiqPrice from 'components/LiqPrice';
import MarksSlider from 'components/MarksSlider';
import PriceImpact from 'components/PriceImpact';
import TextWarning from 'components/TextWarning';
import ToNext from 'components/ToNext';

import useListenMarketAmountV2 from '../../../hooks/V2/useListenMarketAmountV2';
import useDangerPosition from '../../../hooks/useDangerPosition';
import TradeButtonMarketV1 from './TradeButtonMarketV1';
import TradeButtonMarketV2 from './TradeButtonMarketV2';
import TradeFees from './TradeFees';

export default React.memo(function MarketForm() {
	const dispatch = useAppDispatch();

	const { transactionType, quoteToken } = useAppSelector(txBaseState);
	const { leverage } = useAppSelector(tradeBaseState);
	const { entryPrice } = useAppSelector(marketBaseState);
	const { checkedTradeLeverage } = useAppSelector(settingBaseState);
	const { poolInfo } = useAppSelector(poolsBaseState);
	const currentInitialMargin = useAppSelector(selectCurrentInitialMargin);
	const currentLiquidityDelta = useAppSelector(selectCurrentLiquidityDelta);
	const currentMarginDelta = useAppSelector(selectCurrentMarginDelta);
	const currentPositionAmount = useAppSelector(selectCurrentPositionAmount);
	const leverageText = useAppSelector(selectLeverageText);
	const quoteBalanceText = useAppSelector(selectQuoteBalanceText);
	const { slippage } = useAppSelector(settingBaseState);
	const currentTradingFee = useAppSelector(selectCurrentTradingFee);
	const currentOriginTradingFee = useAppSelector(selectCurrentOriginTradingFee);
	const { currentVersion } = useAppSelector(settingBaseState);

	const isLogin = useCheckLogin();
	const { currentChainId } = useCurrentChain();

	const { positionInfo, tradeType, tradeDirection } = useContext(TradeContext);

	const { indexPrice } = useIndexPriceForTrade(tradeDirection);

	const {
		hasMaxButton,
		onCalculateValuesByNoneLeverage,
		onSetValuesByNoneLeverage,
		onHandleChangeMarginDelta,
		onHandleSetMaxMarginDelta,
		onHandleChangePositionAmount
	} =
		currentVersion === Version.V1
			? useListenMarketAmount()
			: useListenMarketAmountV2();

	const { dangerInfo } = useDangerPosition(
		onSetValuesByNoneLeverage,
		onCalculateValuesByNoneLeverage
	);

	const leverageMarks = useMemo(() => {
		if (!poolInfo) {
			return LEVERAGE_MAKERS;
		}
		return computeLeverageMarks(poolInfo.maxLeveragePerPosition);
	}, [poolInfo]);

	const targetInitialMargin = useMemo(() => {
		if (!positionInfo || !isPositive(currentInitialMargin)) {
			return '';
		}
		return plus(currentInitialMargin, positionInfo.netMargin);
	}, [currentInitialMargin, positionInfo]);

	// 具有仓位
	const {
		targetEntryPrice,
		targetLeverage,
		targetMargin,
		targetLiquidity,
		targetSize
	} = useMemo(() => {
		if (
			!positionInfo ||
			!isPositive(currentMarginDelta) ||
			!isPositive(currentPositionAmount) ||
			!entryPrice
		) {
			return {
				targetEntryPrice: '',
				targetLeverage: '',
				targetMargin: '',
				targetLiquidity: '',
				targetSize: ''
			};
		}
		const targetMargin = plus(positionInfo.netMargin, currentMarginDelta);
		const targetLiquidity = plus(
			positionInfo.liquidity,
			multipliedBy(currentPositionAmount, entryPrice)
		);
		const targetSize = plus(positionInfo.size, currentPositionAmount);
		const targetEntryPrice = div(targetLiquidity, targetSize);
		const targetLeverage = div(
			targetLiquidity,
			plus(positionInfo.margin, currentMarginDelta)
		);
		return {
			targetEntryPrice,
			targetLeverage,
			targetMargin,
			targetLiquidity,
			targetSize
		};
	}, [
		poolInfo,
		positionInfo,
		currentMarginDelta,
		currentPositionAmount,
		leverage,
		tradeType,
		entryPrice,
		currentLiquidityDelta,
		tradeDirection,
		indexPrice,
		currentInitialMargin
	]);

	const [finalMargin, finalUnrealizedPnL] = useMemo(() => {
		if (!isPositive(indexPrice) || !isPositive(entryPrice)) {
			return [undefined, undefined];
		}

		// 没有仓位的情况
		if (!positionInfo) {
			if (
				!isPositive(currentMarginDelta) ||
				!isPositive(currentPositionAmount)
			) {
				return [undefined, undefined];
			}
			const finalMargin = currentInitialMargin;
			const finalUnrealizedPnL = PositionUtil.calculateUnrealizedPnL(
				tradeDirection,
				currentPositionAmount,
				entryPrice,
				indexPrice
			);
			return [finalMargin, finalUnrealizedPnL];
		}

		// 有仓位的情况
		if (!isPositive(targetEntryPrice) || !isPositive(targetSize)) {
			return [undefined, undefined];
		}
		const finalMargin = plus(currentInitialMargin, positionInfo.netMargin);
		const finalUnrealizedPnL = PositionUtil.calculateUnrealizedPnL(
			tradeDirection,
			targetSize,
			targetEntryPrice,
			indexPrice
		);
		return [finalMargin, finalUnrealizedPnL];
	}, [
		positionInfo,
		currentMarginDelta,
		currentInitialMargin,
		currentPositionAmount,
		tradeDirection,
		targetSize,
		targetEntryPrice,
		entryPrice,
		indexPrice
	]);

	const isDangerTrade = useMemo(() => {
		if (finalMargin === undefined || finalUnrealizedPnL === undefined) {
			return undefined;
		}
		const result = plus(finalMargin, finalUnrealizedPnL);
		const isDangerTrade = isLessThanOrEqualTo(result, 0);
		return isDangerTrade;
	}, [finalMargin, finalUnrealizedPnL]);

	// 当前开仓的爆仓价格
	const currentLiqPrice = useCalculateLiqPrice(
		tradeDirection,
		currentInitialMargin,
		currentPositionAmount,
		currentLiquidityDelta,
		poolInfo?.liquidationFeeRatePerPosition || '',
		poolInfo?.discountedTradingFeeRate || poolInfo?.tradingFeeRate,
		poolInfo?.liquidationExecutionFee || '',
		entryPrice
	);

	const targetLiqPrice = useCalculateLiqPrice(
		tradeDirection,
		targetMargin,
		targetSize,
		targetLiquidity,
		poolInfo?.liquidationFeeRatePerPosition || '',
		poolInfo?.discountedTradingFeeRate || poolInfo?.tradingFeeRate,
		poolInfo?.liquidationExecutionFee || '',
		targetEntryPrice
	);
	const positionIsNeverBust = isNegative(positionInfo?.liqPrice);
	const { acceptableEntryPrice, Component } =
		useAcceptableEntryPrice(entryPrice);

	const onChangeLeverage = (value: number | number[]) => {
		dispatch(setTradeLeverage(value));
	};

	const onClickLabel = (value: number) => {
		dispatch(setTradeLeverage(value));
	};

	const onHandleChangeCheckedLeverage = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			dispatch(setCheckedTradeLeverage(event.target.checked));
		},
		[]
	);

	const [activeLeverage, setActiveLeverage] = useState<boolean>(false);

	const onChangeLeverageInput = useCallback((value: string) => {
		dispatch(setTradeLeverage(value));
	}, []);

	return (
		<article className='space-y-3'>
			<div className='space-y-3'>
				<Card>
					<CardContent>
						<div className='flex justify-between py-1'>
							<Typography variant='body1' color='secondary'>
								<Trans>Pay</Trans>
							</Typography>
							{isLogin && (
								<div className='flex item-baseline space-x-1'>
									<Typography variant='body2' color='secondary'>
										<Trans>Balance:</Trans>
									</Typography>
									<Typography variant='body2'>{quoteBalanceText}</Typography>
								</div>
							)}
						</div>

						<CommonStyledBaseInput
							type='text'
							inputProps={{ maxLength: MAX_PRECISION, inputMode: 'decimal' }}
							className='input'
							value={currentMarginDelta}
							placeholder='0.0'
							onChange={onHandleChangeMarginDelta}
							fullWidth
							endAdornment={
								<div className='flex items-center space-x-2'>
									{hasMaxButton && (
										<CommonStyledMaxButton
											variant='outlined'
											color='secondary'
											onClick={() => onHandleSetMaxMarginDelta()}
										>
											<Trans>Max</Trans>
										</CommonStyledMaxButton>
									)}

									<Typography className='font-normal' variant='h5'>
										{shortenSymbol(quoteToken)}
									</Typography>
								</div>
							}
							sx={{
								'& .MuiInputBase-input': {
									fontSize: 20
								}
							}}
						/>
					</CardContent>
				</Card>
				<Card>
					<CardContent>
						<div className='flex justify-between'>
							<Typography variant='body1' color='secondary'>
								<Trans>Size</Trans>
							</Typography>
							{checkedTradeLeverage && (
								<LeverageListener
									active={activeLeverage}
									leverage={leverage}
									maxLeverage={poolInfo?.maxLeveragePerPosition}
									onChangeLeverage={onChangeLeverageInput}
									onToggleActive={(value: boolean) => {
										setActiveLeverage(value);
									}}
								/>
							)}
						</div>
						<CommonStyledBaseInput
							type='text'
							inputProps={{ maxLength: MAX_PRECISION, inputMode: 'decimal' }}
							className='input'
							value={currentPositionAmount}
							placeholder='0.0'
							onChange={onHandleChangePositionAmount}
							fullWidth
							endAdornment={
								<Typography className='ml-2 font-normal' variant='h5'>
									{shortenSymbolNative(poolInfo?.baseToken, currentChainId)}
								</Typography>
							}
							sx={{
								'& .MuiInputBase-input': {
									fontSize: 20
								}
							}}
						/>
					</CardContent>
				</Card>
			</div>

			{(dangerInfo.isDangerPosition || isDangerTrade) && (
				<TextWarning dark={true}>
					<Trans>
						Due to the significant Price Impact, this operation may lead to
						immediate liquidation of your position, causing unnecessary losses.
						Please reduce your size or leverage to avoid this.
					</Trans>
				</TextWarning>
			)}

			<div>
				<div className={checkedTradeLeverage ? '-mb-1' : '-mb-2'}>
					<Cell
						label={<Trans>Leverage Slider</Trans>}
						value={
							<StyleCheckbox
								checked={checkedTradeLeverage}
								size='small'
								className='-mr-1.5'
								onChange={onHandleChangeCheckedLeverage}
							/>
						}
					/>
				</div>
				{poolInfo && checkedTradeLeverage && (
					<MarksSlider
						leverage={leverage}
						marks={leverageMarks}
						max={poolInfo.maxLeveragePerPosition}
						onChangeLeverage={onChangeLeverage}
						onClickLabel={onClickLabel}
					/>
				)}
			</div>
			<div>
				{!checkedTradeLeverage && (
					<Cell
						label={<Trans>Leverage</Trans>}
						value={
							<ToNext
								value={
									targetLeverage && positionInfo
										? formatLeverage(positionInfo.leverage)
										: leverageText
								}
								nextValue={formatLeverage(targetLeverage)}
								hasNext={isPositive(targetLeverage)}
							/>
						}
					/>
				)}
				<Cell
					label={<Trans>Entry Price</Trans>}
					value={
						<ToNext
							value={
								<Typography variant='body2' className='flex'>
									{(isPositive(currentMarginDelta) &&
										isPositive(currentPositionAmount) &&
										!isPositive(entryPrice)) ||
									(isPositive(currentMarginDelta) &&
										isPositive(currentPositionAmount) &&
										targetLiqPrice &&
										positionInfo &&
										!isPositive(positionInfo?.entryPrice)) ? (
										<Tooltip
											title={
												<Trans>
													Due to significant market volatility in the current
													contract market, there have been abnormal fluctuations
													in market prices and opening prices, making it
													impossible to open positions. You can continue opening
													positions once the prices stabilize.
												</Trans>
											}
										>
											<img
												className='mt-0.5 mr-0.5'
												width={16}
												src={warningIcon}
												alt=''
											/>
										</Tooltip>
									) : null}
									{targetLiqPrice && positionInfo
										? toQuoteAmount(
												positionInfo.entryPrice,
												poolInfo?.baseToken?.precision
										  )
										: isEqualTo(entryPrice || 0, 0)
										? '-'
										: `${toQuoteAmount(
												entryPrice || 0,
												poolInfo?.baseToken?.precision
										  )}`}
								</Typography>
							}
							nextValue={
								<Typography variant='body2' className='flex'>
									{isPositive(currentMarginDelta) &&
									isPositive(currentPositionAmount) &&
									!isPositive(targetEntryPrice) ? (
										<Tooltip
											title={
												<Trans>
													Due to significant market volatility in the current
													contract market, there have been abnormal fluctuations
													in market prices and opening prices, making it
													impossible to open positions. You can continue opening
													positions once the prices stabilize.
												</Trans>
											}
										>
											<img
												className='mt-0.5 mr-0.5'
												width={16}
												src={warningIcon}
												alt=''
											/>
										</Tooltip>
									) : null}
									{toQuoteAmount(
										targetEntryPrice,
										poolInfo?.baseToken?.precision
									)}
								</Typography>
							}
							hasNext={isPositive(targetEntryPrice)}
						/>
					}
				/>
				<PriceImpact
					tradePrice={entryPrice}
					side={
						transactionType === Transaction_Type.Long ||
						transactionType === Transaction_Type.LongV2
							? Side.LONG
							: Side.SHORT
					}
				/>
				<Cell
					label={<Trans>Acceptable Price</Trans>}
					value={
						<Typography variant='body2' className='flex items-center'>
							{`${
								acceptableEntryPrice
									? transactionType === Transaction_Type.Long ||
									  transactionType === Transaction_Type.LongV2
										? '≤'
										: '≥'
									: ''
							}${toQuoteAmount(
								acceptableEntryPrice,
								poolInfo?.baseToken?.precision
							)}`}
							<span className='ml-1'>{`(${toPercent(
								slippage,
								2,
								false
							)})`}</span>
							&nbsp;
							{Component}
						</Typography>
					}
				/>
				<Cell
					label={
						<Typography variant='body2'>
							<Trans>Liq. Price</Trans>
						</Typography>
					}
					value={
						<ToNext
							value={
								targetLiqPrice && positionInfo && !positionIsNeverBust ? (
									<LiqPrice
										value={positionInfo.liqPrice}
										precision={poolInfo?.baseToken?.precision}
									/>
								) : (
									<LiqPrice
										value={currentLiqPrice}
										precision={poolInfo?.baseToken?.precision}
									/>
								)
							}
							nextValue={
								<LiqPrice
									value={targetLiqPrice}
									precision={poolInfo?.baseToken?.precision}
								/>
							}
							hasNext={targetLiqPrice !== '' && !positionIsNeverBust}
						/>
					}
				/>
				<Cell
					label={<Trans>Est. Margin</Trans>}
					value={
						<ToNext
							value={
								targetInitialMargin && positionInfo
									? formatNumber(positionInfo.netMargin)
									: formatNumber(currentInitialMargin)
							}
							nextValue={formatNumber(targetInitialMargin)}
							hasNext={isPositive(targetInitialMargin)}
						/>
					}
					unit={
						isNumeric(currentInitialMargin) ? shortenSymbol(quoteToken) : ''
					}
				/>
				<TradeFees
					tradingFeeRate={poolInfo?.tradingFeeRate}
					referralDiscountRate={poolInfo?.referralDiscountRate}
					tradingFee={currentTradingFee}
					originTradingFee={currentOriginTradingFee}
				/>
			</div>
			{currentVersion === Version.V1 ? (
				<TradeButtonMarketV1 />
			) : (
				<TradeButtonMarketV2 />
			)}
		</article>
	);
});
