import { t } from '@lingui/macro';
import { MAX_ORDERSNUMBER_DISPLAY, Transaction_Status } from 'config/constants';

import {
	TransationOperationV1,
	TransationOperationV2
} from 'graphql/useTransactionHistoriesGraph';

import { IOrderItem } from '../graphql/useMyOrdersGraph';
import { IPositionItem } from '../graphql/useMyPositionsGraph';
import { isGreaterThan, isPositive } from './mathUtils';

export function formatTradeOperationV1(operation: TransationOperationV1) {
	if (!operation) {
		return '';
	}
	switch (operation) {
		case TransationOperationV1.PositionOpen:
			return t`Open`;
		case TransationOperationV1.PositionClose:
			return t`Close`;
		case TransationOperationV1.PositionLiquidated:
			return t`Liquidation`;
		case TransationOperationV1.PositionIncreased:
			return t`Open`;
		case TransationOperationV1.PositionDecreased:
			return t`Close`;
		default:
			return '';
	}
}
export function formatTradeOperationV2(
	operation: TransationOperationV2,
	useI18n = true
) {
	if (!operation) {
		return '';
	}
	switch (operation) {
		case TransationOperationV2.PositionOpen:
			return useI18n ? t`Open` : 'Open';
		case TransationOperationV2.PositionClose:
			return useI18n ? t`Close` : 'Close';
		case TransationOperationV2.PositionLiquidated:
			return useI18n ? t`Liquidation` : 'Liquidation';
		case TransationOperationV2.PositionIncreased:
			return useI18n ? t`Open` : 'Open';
		case TransationOperationV2.PositionDecreased:
			return useI18n ? t`Close` : 'Close';
		default:
			return '';
	}
}
/**
 * Used to display the total number of business modules
 * If value>99, it will show 99+
 * @param value
 * @returns
 */
export function formatModuleNumber(value: string | number | undefined) {
	if (!value) {
		return '(0)';
	}
	if (isGreaterThan(value, MAX_ORDERSNUMBER_DISPLAY)) {
		return `(${MAX_ORDERSNUMBER_DISPLAY}+)`;
	}
	return `(${value})`;
}

export const checkOrderIsExpired = (
	order: IOrderItem,
	positionMap: Map<string, IPositionItem[]>,
	Order_Open_Type: any
) => {
	if (order.type === Order_Open_Type.Decrease) {
		const position =
			(positionMap &&
				positionMap.get(order.poolId)?.find(positionItem => {
					return order.side === positionItem.side;
				})) ||
			null;
		if (isPositive(order.sizeDelta)) {
			if (position) {
				if (isGreaterThan(order.sizeDelta, position.size)) {
					return true;
				}
			} else {
				if (order.status !== Transaction_Status.Pending) {
					return true;
				}
			}
		} else {
			if (!position) {
				return true;
			}
		}
	} else {
		return false;
	}
};
