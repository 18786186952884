import { createSelector } from '@reduxjs/toolkit';

import { formatLeverage, isPositive } from 'utils';

import { tradeBaseState } from './slice';

export const selectLeverageText = createSelector([tradeBaseState], state => {
	const { leverage } = state;
	if (isPositive(leverage)) {
		return formatLeverage(leverage);
	} else {
		return '-';
	}
});
