import { Reducer, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'state';

import { IOrderItem } from '../../graphql/useMyOrdersGraph';

interface StateProps {
	leverage: number | string;
	tradePrice: string;
	isEnableMining: boolean;
	isBindReferralCode: boolean;
	bindReferralCodeLoading: boolean | undefined;
	referralCode: string;
	orders: IOrderItem[];
}

const initialState: StateProps = {
	leverage: 0,
	tradePrice: '',
	isEnableMining: false,
	isBindReferralCode: undefined,
	bindReferralCodeLoading: false,
	referralCode: '',
	orders: []
};

export const slice = createSlice({
	name: 'trade',
	initialState,
	reducers: {
		setLeverage(state, { payload }) {
			state.leverage = payload;
		},
		setIsEnableMining(state, { payload }) {
			state.isEnableMining = payload;
		},
		setIsBindReferralCode(state, { payload }) {
			state.isBindReferralCode = payload;
		},
		setBindReferralCodeLoading(state, { payload }) {
			state.bindReferralCodeLoading = payload;
		},
		setReferralCode(state, { payload }) {
			state.referralCode = payload;
		},
		setOrders(state, { payload }) {
			state.orders = payload;
		}
	}
});

export const {
	setLeverage,
	setIsEnableMining,
	setIsBindReferralCode,
	setBindReferralCodeLoading,
	setReferralCode,
	setOrders
} = slice.actions;

export const tradeBaseState = (state: RootState) => state.trade;

export default slice.reducer as Reducer<typeof initialState>;
