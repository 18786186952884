import React, { useContext, useMemo, useState } from 'react';
import { useSetState, useUpdateEffect } from 'react-use';

import { Trans } from '@lingui/macro';
import { Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import { RISK_RATE } from 'config/constants';

import { LiquidityPositionUtil } from 'entities/LiquidityPositionUtil';
import { debounce } from 'lodash';

import StyledButton from '../../../components/Common/StyledButton';
import ConnectWalletButton from '../../../components/Wallet/ConnectWalletButton';
import { MSG_ERROR_APPROVE, Transaction_Type } from '../../../config/constants';
import { getContractAddressV2 } from '../../../config/contracts';
import { MobileActionContext } from '../../../context/MobileActionsContext';
import useApprovalPluginV2 from '../../../hooks/V2/useApprovalPluginV2';
import { ApprovalState, useApproval } from '../../../hooks/useApproval';
import { useCheckLogin, useCurrentChain } from '../../../hooks/useCurrentChain';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import {
	liquidityBaseState,
	reset,
	setDialogIsVisible
} from '../../../state/liquidity/slice';
import { poolsBaseState } from '../../../state/pools/slice';
import { txBaseState } from '../../../state/tx/slice';
import {
	catchFn,
	div,
	isGreaterThan,
	isLessThan,
	isPositive,
	plus,
	shortenSymbol
} from '../../../utils';
import ConfirmIncreaseLiquidity from '../ConfirmIncreaseLiquidity';
import useSubmitIncreaseLiquidityV2 from './hooks/useSubmitIncreaseLiquidityV2';

const LiquidityButtonV2 = () => {
	const dispatch = useAppDispatch();
	const { quoteToken, quoteBalance, signingMap } = useAppSelector(txBaseState);
	const { leverage, quoteAmount, liquidityAmount, isPureAdd } =
		useAppSelector(liquidityBaseState);
	const { poolInfo, liquidityPools } = useAppSelector(poolsBaseState);

	const { setIsVisibleForm } = useContext(MobileActionContext);

	const liquidityPool = useMemo(() => {
		const targetPoolAddress = poolInfo?.address.toLowerCase();
		return liquidityPools.find(item => item.address === targetPoolAddress);
	}, [poolInfo, liquidityPools]);

	const nextMargin = useMemo(() => {
		return plus(
			plus(
				(isPositive(liquidityPool?.liquidityPositions.length) &&
					liquidityPool?.liquidityPositions[0].marginNet) ||
					'0',
				quoteAmount || '0'
			),
			liquidityPool?.liquidityPositions[0]?.liquidityPassivePosition?.pnl || '0'
		);
	}, [liquidityPool, quoteAmount]);

	const nextLiquidity = useMemo(() => {
		return plus(liquidityPool?.myLiquidity || '0', liquidityAmount);
	}, [liquidityPool, liquidityAmount]);

	const nextLeverage = useMemo(() => {
		if (isPositive(nextLiquidity) && isPositive(nextMargin)) {
			return div(nextLiquidity, nextMargin);
		}
		return '1';
	}, [nextLiquidity, nextMargin]);

	const isLogin = useCheckLogin();
	const { currentChainId } = useCurrentChain();

	const routerContractAddress = useMemo(() => {
		return getContractAddressV2(currentChainId, 'Router');
	}, [currentChainId]);

	const positionRouterContractAddress = useMemo(() => {
		return getContractAddressV2(currentChainId, 'PositionRouter');
	}, [currentChainId]);

	const { currentApproveState, approveCallback } = useApproval(
		quoteToken,
		quoteAmount,
		routerContractAddress
	);

	const riskRatio = catchFn(() => {
		if (
			isPositive(liquidityPool?.liquidityPositions.length) &&
			liquidityPool &&
			isPositive(liquidityAmount)
		) {
			return LiquidityPositionUtil.calcV2RiskRate(
				plus(liquidityPool?.liquidityPositions[0].myLiquidity, liquidityAmount),
				liquidityPool.liquidationFeeRatePerLiquidityPosition,
				liquidityPool.liquidationExecutionFee,
				plus(
					liquidityPool?.liquidityPositions[0].marginNet,
					quoteAmount || '0'
				),
				liquidityPool?.liquidityPositions[0].liquidityPassivePosition.pnl
			);
		} else {
			return '0';
		}
	}, '0');

	const { isPluginApproved, isPluginApproving, onApprovePlugin } =
		useApprovalPluginV2(positionRouterContractAddress);

	const { onConfirm, isConfirming, isConfirmed, errMsg } =
		useSubmitIncreaseLiquidityV2(currentApproveState, isPluginApproved);

	const canSubmit = useMemo(() => {
		if (
			!poolInfo ||
			(!isPositive(quoteBalance) && !isPureAdd) ||
			(!isPositive(quoteAmount) && !isPureAdd) ||
			!isPositive(liquidityAmount)
		) {
			return false;
		}
		if (isLessThan(quoteBalance, quoteAmount) && !isPureAdd) {
			return false;
		}
		if (currentApproveState === ApprovalState.NOT_APPROVED) {
			return true;
		}
		if (currentApproveState === ApprovalState.PENDING) {
			return false;
		}
		if (isPluginApproving) {
			return false;
		}
		if (!isPluginApproved && isPositive(quoteAmount)) {
			return true;
		}
		if (isGreaterThan(riskRatio, RISK_RATE)) {
			return false;
		}
		if (
			isLessThan(quoteAmount, poolInfo.minMarginPerLiquidityPosition) &&
			!isPureAdd &&
			!isPositive(liquidityPool?.liquidityPositions.length)
		) {
			return false;
		}
		if (
			(isPositive(leverage) &&
				isGreaterThan(leverage, poolInfo.maxLeveragePerLiquidityPosition)) ||
			(isPositive(nextLeverage) &&
				isGreaterThan(nextLeverage, poolInfo.maxLeveragePerLiquidityPosition))
		) {
			return false;
		}
		if (
			signingMap.get(Transaction_Type.IncreaseLiquidityV2) ||
			signingMap.get(Transaction_Type.AppendLiquidityV2)
		) {
			return false;
		}
		if (isConfirming) {
			return false;
		}

		if (errMsg && errMsg.indexOf(MSG_ERROR_APPROVE) >= 0) {
			return true;
		}
		return true;
	}, [
		poolInfo,
		quoteAmount,
		liquidityAmount,
		isPureAdd,
		currentApproveState,
		isPluginApproving,
		quoteBalance,
		leverage,
		nextLeverage,
		isConfirming,
		signingMap,
		errMsg
	]);

	const submitText = useMemo(() => {
		if (poolInfo) {
			// token balance
			if (isLessThan(quoteBalance, quoteAmount) && !isPureAdd) {
				return <Trans>Insufficient Balance</Trans>;
			}
			if (currentApproveState === ApprovalState.NOT_APPROVED) {
				return (
					<span>
						<Trans>Approve {`${shortenSymbol(quoteToken)}`}</Trans>
					</span>
				);
			}
			// approve state
			if (currentApproveState === ApprovalState.PENDING) {
				return (
					<span>
						<Trans>Approving...</Trans>
					</span>
				);
			}
			if (isPluginApproving) {
				return <Trans>Approving...</Trans>;
			}
			if (!isPluginApproved && isPositive(quoteAmount)) {
				return <Trans>Approve Operation (one-time)</Trans>;
			}
			if (isGreaterThan(riskRatio, RISK_RATE)) {
				return <Trans>Position risk rate {`>`} 90%</Trans>;
			}

			if (
				isLessThan(quoteAmount, poolInfo.minMarginPerLiquidityPosition) &&
				!isPureAdd &&
				!isPositive(liquidityPool?.liquidityPositions.length)
			) {
				return (
					<Trans>
						Min payment: {poolInfo.minMarginPerLiquidityPosition}{' '}
						{shortenSymbol(quoteToken)}
					</Trans>
				);
			}

			if (
				(isPositive(leverage) &&
					isGreaterThan(leverage, poolInfo.maxLeveragePerLiquidityPosition)) ||
				(isPositive(nextLeverage) &&
					isGreaterThan(nextLeverage, poolInfo.maxLeveragePerLiquidityPosition))
			) {
				return (
					<Trans>
						Exceeds the max leverage of{' '}
						{poolInfo.maxLeveragePerLiquidityPosition}x
					</Trans>
				);
			}
			if (
				signingMap.get(Transaction_Type.IncreaseLiquidityV2) ||
				signingMap.get(Transaction_Type.AppendLiquidityV2)
			) {
				return <Trans>Loading...</Trans>;
			}
			if (isConfirming) {
				return <Trans>Submitting...</Trans>;
			}

			if (errMsg && errMsg.indexOf(MSG_ERROR_APPROVE) >= 0) {
				return (
					<span>
						<Trans>Approve {`${shortenSymbol(quoteToken)}`}</Trans>
					</span>
				);
			}
		}

		return <Trans>Add</Trans>;
	}, [
		poolInfo,
		quoteAmount,
		isPureAdd,
		currentApproveState,
		isPluginApproved,
		isPluginApproving,
		quoteBalance,
		leverage,
		isConfirming,
		signingMap,
		errMsg
	]);

	useUpdateEffect(() => {
		if (isConfirming) {
			dispatch(reset());
		}
	}, [isConfirming]);

	useUpdateEffect(() => {
		if (isConfirmed) {
			setIsVisibleForm(false);
			dispatch(setDialogIsVisible(false));
		}
	}, [isConfirmed]);

	const [confirmOpen, onSetConfirmOpen] = useState(false);

	const onHandleConfirm = debounce(() => {
		if (
			currentApproveState === ApprovalState.NOT_APPROVED ||
			(errMsg && errMsg.indexOf(MSG_ERROR_APPROVE) >= 0)
		) {
			approveCallback();
			return;
		}
		if (isPluginApproved === false) {
			onApprovePlugin();
		} else {
			onConfirm();
		}
	}, 300);

	return (
		<div>
			{!isLogin ? (
				<ConnectWalletButton fullWidth />
			) : (
				<div className={classNames(!canSubmit && 'cursor-not-allowed')}>
					<StyledButton
						className='flex flex-col justify-center'
						variant='contained'
						size='large'
						fullWidth
						disabled={!canSubmit}
						onClick={onHandleConfirm}
					>
						<Typography
							className='whitespace-normal'
							color='inherit'
							variant='h6'
						>
							{submitText}
						</Typography>
					</StyledButton>
				</div>
			)}

			{confirmOpen && (
				<ConfirmIncreaseLiquidity
					open={true}
					onHandleClose={() => onSetConfirmOpen(false)}
					onSubmit={() => onConfirm()}
				/>
			)}
		</div>
	);
};

export default LiquidityButtonV2;
