import { useEffect, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { Box, IconButton, Paper, Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import {
	Dom_Size,
	QUOTE_USD,
	Transaction_Status,
	Transaction_Type,
	Version
} from 'config/constants';

import { useManageTransactions } from 'hooks/useAccountTransactions';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCurrentChain } from 'hooks/useCurrentChain';
import useLocalSnackbar from 'hooks/useLocalSnackbar';
import TradeUpdaterV1 from 'pages/components/TradeUpdaterV1';
import TradeUpdaterV2 from 'pages/components/TradeUpdaterV2';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import LiquidityUpdater from 'state/liquidity/updater';
import {
	poolsBaseState,
	setLiquidityPools,
	setV2LiquidityPools
} from 'state/pools/slice';
import { selectTransactionRecordList } from 'state/records/selector';
import { selectVersion } from 'state/setting/selector';
import { shortenSymbolNative } from 'utils';

import ComputerIcon from '../../components/Svg/Icons/Computer';
import useCombineLpPositions from '../../graphql/useCombineLpPositions';
import LiquidityComputerDialog from './LiquidityComputerDialog';
import LiquidityForm from './LiquidityForm';
import LiquidityInfo from './LiquidityInfo';
import LiquidityTable from './LiquidityTable';
import LiquidityTableV2 from './V2/LiquidityTableV2';

function Liquidity() {
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const { liquidityPools, poolInfo } = useAppSelector(poolsBaseState);
	const transactionRecords = useAppSelector(selectTransactionRecordList);
	const currentVersion = useAppSelector(selectVersion);
	const { currentChainId } = useCurrentChain();
	const { isMatchPad } = useAppBreakpoints();
	const { showSnackbar } = useLocalSnackbar();

	const { data, requestData, loading } = useCombineLpPositions();
	const { removeTransaction } = useManageTransactions();

	useEffect(() => {
		if (data && data.length > 0) {
			if (currentVersion === Version.V1) {
				dispatch(setLiquidityPools(data));
			}
			if (currentVersion === Version.V2) {
				dispatch(setV2LiquidityPools(data));
			}
		}
	}, [currentVersion, data]);

	useEffect(() => {
		transactionRecords.forEach(record => {
			const _openRequest = requestData.openLiquidityPositionRequestsMap.get(
				record.hash
			);
			const _closeRequest = requestData.closeLiquidityPositionRequestsMap.get(
				record.hash
			);
			const _adjustRequest =
				requestData.adjustLiquidityPositionMarginRequestsMap.get(record.hash);

			const checkIsExecuted = (status: string) =>
				['Executed', 'EXECUTED'].includes(status);
			const checkIsCancelled = (status: string) =>
				['Cancelled', 'CANCELLED'].includes(status);

			const hasTargetPosition =
				currentVersion === Version.V1
					? liquidityPools.find(item =>
							item.liquidityPositions.find(
								position => position.entryTxHash === _openRequest?.executedHash
							)
					  )
					: true;
			const openRequestIsExecuted =
				requestData.openLiquidityPositionRequestsMap.has(record.hash) &&
				checkIsExecuted(_openRequest?.status) &&
				hasTargetPosition;
			const appendRequestIsExecuted =
				record.record.transactionType === Transaction_Type.AppendLiquidityV2 &&
				requestData.openLiquidityPositionRequestsMap.has(record.hash) &&
				checkIsExecuted(_openRequest?.status);
			const openRequestIsCancelled = checkIsCancelled(_openRequest?.status);

			const closeRequestIsExecuted =
				requestData.closeLiquidityPositionRequestsMap.has(record.hash) &&
				checkIsExecuted(_closeRequest?.status);
			const closeRequestIsCancelled = checkIsCancelled(_closeRequest?.status);

			const adjustRequestIsExecuted =
				requestData.adjustLiquidityPositionMarginRequestsMap.has(record.hash) &&
				checkIsExecuted(_adjustRequest?.status);
			const adjustRequestIsCancelled = checkIsCancelled(_adjustRequest?.status);
			if (
				openRequestIsExecuted ||
				appendRequestIsExecuted ||
				openRequestIsCancelled ||
				closeRequestIsExecuted ||
				closeRequestIsCancelled ||
				adjustRequestIsExecuted ||
				adjustRequestIsCancelled
			) {
				removeTransaction(record);
			}

			if (openRequestIsCancelled) {
				showSnackbar(
					t`Fail to increase liquidity`,
					_openRequest.cancelledHash,
					Transaction_Status.Error
				);
			}
			if (closeRequestIsCancelled) {
				showSnackbar(
					currentVersion === Version.V1
						? t`Fail to remove liquidity`
						: t`Transaction execution failed`,
					_closeRequest.cancelledHash,
					Transaction_Status.Error
				);
			}
			if (adjustRequestIsCancelled) {
				showSnackbar(
					t`Fail to adjust margin`,
					_adjustRequest.cancelledHash,
					Transaction_Status.Error
				);
			}
		});
	}, [liquidityPools, requestData, transactionRecords, currentVersion]);

	const [visibleComputer, setVisibleComputer] = useState(false);
	const onOpenComputerDialog = () => setVisibleComputer(true);
	const onClose = () => setVisibleComputer(false);

	useEffect(() => {
		window?.scrollTo(0, 0);
	}, []);

	return (
		<Box
			className={classNames(
				'flex md:block space-x-2 md:space-x-0 md:space-y-2 h-full',
				{
					'flex-1': currentVersion === Version.V2
				}
			)}
			sx={{
				background: theme.palette.background.default
			}}
			id='liquidityContainer'
		>
			{currentVersion === Version.V1 ? (
				<LiquidityTable />
			) : (
				<LiquidityTableV2 loading={loading} />
			)}
			{currentVersion === Version.V1 && (
				<Box
					component='aside'
					className='sm:hidden md:flex space-y-2 md:space-y-0 md:space-x-2'
					sx={{
						width: isMatchPad ? 'auto' : `${Dom_Size.TRADE_FORM}px`
					}}
				>
					<Paper className='flex-1 space-y-3 p-4'>
						<div className='flex justify-between'>
							<Box className='flex items-center'>
								<Typography variant='h6'>
									<Trans>
										Add{' '}
										{shortenSymbolNative(poolInfo?.baseToken, currentChainId)}/
										{QUOTE_USD} Liquidity
									</Trans>
								</Typography>
							</Box>

							<IconButton className='-mr-1' onClick={onOpenComputerDialog}>
								<ComputerIcon
									color={theme.palette.mode === 'dark' ? 'white' : 'black'}
								/>
							</IconButton>
						</div>
						<LiquidityForm />
					</Paper>
					<LiquidityInfo className='flex-1' />
				</Box>
			)}
			<LiquidityUpdater />
			{currentVersion === Version.V1 ? <TradeUpdaterV1 /> : <TradeUpdaterV2 />}
			{visibleComputer && (
				<LiquidityComputerDialog poolData={liquidityPools} onClose={onClose} />
			)}
		</Box>
	);
}

export default Liquidity;
