import { createSelector } from '@reduxjs/toolkit';
import {
	Order_Open_Type_V1,
	Order_Open_Type_V2,
	Order_Status_V1,
	Order_Status_V2,
	Version
} from 'config/constants';

import {
	TransationOperationV1,
	TransationOperationV2
} from 'graphql/useTransactionHistoriesGraph';
import { div } from 'utils';

import { settingBaseState } from './slice';

export const selectImpactFeeRateValue = createSelector(
	[settingBaseState],
	state => {
		const { impactFeeRate } = state;
		if (impactFeeRate) {
			return div(impactFeeRate, 100);
		} else {
			return '-';
		}
	}
);

export const selectVersion = createSelector(
	settingBaseState,
	state => state.currentVersion
);

export const selectOrderStatus = createSelector(settingBaseState, state => {
	const { currentVersion } = state;
	if (currentVersion === Version.V1) {
		return Order_Status_V1;
	} else {
		return Order_Status_V2;
	}
});

export const selectOrderOpenType = createSelector(settingBaseState, state => {
	const { currentVersion } = state;
	if (currentVersion === Version.V1) {
		return Order_Open_Type_V1;
	} else {
		return Order_Open_Type_V2;
	}
});

export const selectTransationOperation = createSelector(
	settingBaseState,
	state => {
		const { currentVersion } = state;
		if (currentVersion === Version.V1) {
			return TransationOperationV1;
		} else {
			return TransationOperationV2;
		}
	}
);
