import { useEffect, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { APP_TOKEN_SYMBOL, Transaction_Type } from 'config/constants';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useSubmitClaimReferrals } from 'hooks/useSubmitClaimReferrals';
import EmptyClaim from 'pages/Earn/EmptyClaim';
import { ClaimPreviousTip } from 'pages/components/ClaimPrevious';
import { useAppSelector } from 'state/hooks';
import {
	selectConnectorsRewardEquAmount,
	selectConnectorsRewardEquPools,
	selectMembersRewardEquAmount,
	selectMembersRewardEquPools
} from 'state/referrals/selector';
import { referralBaseState } from 'state/referrals/slice';
import { txBaseState } from 'state/tx/slice';
import type { Merge } from 'type-fest';
import { formatNumber, isPositive, shortenSymbol } from 'utils';

import ApproveButton from 'components/ApproveButton';
import Dialog, { DialogProps } from 'components/Common/Dialog';
import { CommonStyledDivider } from 'components/Common/Styled';
import Table from 'components/Table/StickyTable';

import { Type_Referrals } from './types';

export default function DirectClaimDialog(
	props: Merge<DialogProps, { type: Type_Referrals }>
) {
	const { onClose, type } = props;
	const { appToken, signingMap } = useAppSelector(txBaseState);
	const { membersRewardList, connectorsRewardList } =
		useAppSelector(referralBaseState);
	const theme = useTheme();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [quoteAmount, setQuoteAmount] = useState<string>('0');
	// const [appAmount, setAppAmount] = useState<string>('0');
	const [selected, setSelected] = useState<string[]>([]);
	const [referralFeePools, setReferralFeePools] = useState<string[]>([]);
	// const [referralEQUPoolIds, setReferralEQUPoolIds] = useState<string[]>([]);
	const membersRewardEquAmount = useAppSelector(selectMembersRewardEquAmount);
	const membersRewardEquPools = useAppSelector(selectMembersRewardEquPools);
	const connectorsRewardEquAmount = useAppSelector(
		selectConnectorsRewardEquAmount
	);
	const connectorsRewardEquPools = useAppSelector(
		selectConnectorsRewardEquPools
	);

	const { isMatchMobile, isMatchPc, isMatchPad } = useAppBreakpoints();

	const transactionType = useMemo(() => {
		if (type === Type_Referrals.Members) {
			return Transaction_Type.ClaimMembers;
		}
		return Transaction_Type.ClaimConnectors;
	}, [type]);

	const appAmount = useMemo(() => {
		if (type === 'members') {
			return membersRewardEquAmount;
		} else if (type === 'connectors') {
			return connectorsRewardEquAmount;
		} else {
			return '0';
		}
	}, [membersRewardEquAmount, connectorsRewardEquAmount, type]);

	const referralEQUPoolIds = useMemo(() => {
		if (type === 'members') {
			return membersRewardEquPools;
		} else if (type === 'connectors') {
			return connectorsRewardEquPools;
		} else {
			return [];
		}
	}, [membersRewardEquPools, connectorsRewardEquPools, type]);

	const { onConfirm, isConfirmed, isConfirming, error } =
		useSubmitClaimReferrals(
			transactionType,
			selected,
			referralFeePools,
			referralEQUPoolIds,
			quoteAmount,
			appAmount
		);

	const referralList = useMemo(() => {
		if (type === 'members') {
			return membersRewardList;
		} else if (type === 'connectors') {
			return connectorsRewardList;
		} else {
			return [];
		}
	}, [membersRewardList, connectorsRewardList, type]);

	const { i18n } = useLingui();
	const [disabled, submitText] = useMemo(() => {
		if (!isPositive(quoteAmount) && !isPositive(appAmount)) {
			return [true, t`Claim`];
		}
		if (signingMap.has(transactionType)) {
			return [true, t`Loading...`];
		}
		if (isConfirming) {
			return [true, t`Submitting...`];
		}
		return [false, t`Claim`];
	}, [
		quoteAmount,
		appAmount,
		isLoading,
		isConfirming,
		signingMap,
		transactionType,
		i18n.locale
	]);

	useEffect(() => {
		if (referralList) {
			const newSelected = referralList.map(n => n.id);
			setSelected(newSelected);
		}
	}, [referralList]);

	useUpdateEffect(() => {
		if (error) {
			setIsLoading(false);
		}
	}, [error]);

	useUpdateEffect(() => {
		if (isConfirmed) {
			setIsLoading(false);
			onClose();
		}
	}, [isConfirmed]);

	const columns = [
		{
			key: 'type',
			width: '50%',
			label: (
				<Typography variant='body2'>
					<Trans>Rewards Source</Trans>
				</Typography>
			),
			align: 'left',
			format: (row: any) => {
				return (
					<div className='flex items-center sm:h-8 sm:leading-8'>
						<Typography className='capitalize whitespace-nowrap'>{`EFC ${type}`}</Typography>
					</div>
				);
			}
		},
		{
			key: 'rewardsEQU',
			width: '50%',
			label: (
				<Typography variant='body2'>
					{APP_TOKEN_SYMBOL} <Trans>Amount</Trans>
				</Typography>
			),
			align: 'right',
			format: (row: any) => {
				return (
					<Typography
						className='flex-1 sm:h-8 sm:leading-8'
						variant='body2'
					>{`${formatNumber(
						appAmount,
						appToken.positionUnits
					)} ${APP_TOKEN_SYMBOL}`}</Typography>
				);
			}
		}
	];

	return (
		<Dialog
			width={isMatchPc ? 480 : '100%'}
			height={isMatchMobile ? 300 : 'auto'}
			title={t`Claim previous ${shortenSymbol(appToken)} rewards`}
			open={true}
			onClose={onClose}
		>
			{(referralList?.length === 0 || !referralList) && <EmptyClaim />}
			{referralList?.length !== 0 && referralList && (
				<div className='space-y-4'>
					<div className='-mx-4 sm:mx-0'>
						<Table
							columns={columns}
							rows={referralList ? [referralList[0]] : []}
							cellColor={theme.custom.cardBg}
							origin={isMatchPad}
						/>
					</div>
					<CommonStyledDivider />
					<div className='flex items-center justify-between'>
						<Typography color='text.secondary'>
							<Trans>Total</Trans>
						</Typography>
						<Typography variant='body2'>{`${formatNumber(
							appAmount,
							4
						)} ${APP_TOKEN_SYMBOL}`}</Typography>
					</div>
					<section>
						<ApproveButton
							componentVariant='confirm'
							fullWidth
							onClick={onConfirm}
							disabled={disabled}
							isApproveRewardCollector
						>
							{submitText}
						</ApproveButton>
					</section>
				</div>
			)}
		</Dialog>
	);
}
