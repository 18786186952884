import { ReactNode, useMemo } from 'react';

import { Trans } from '@lingui/macro';
import { useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { DAYS_YEAR, QUOTE_USD } from 'config/constants';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import { isEmpty } from 'lodash';
import { selectClaimableLpStakeQuoteAmount } from 'state/earn/pool2/selector';
import { earnPool2BaseState } from 'state/earn/pool2/slice';
import { earnStakeBaseState } from 'state/earn/stake/slice';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import {
	div,
	formatNumber,
	isNumeric,
	isPositive,
	multipliedBy,
	plus,
	shortenSymbol,
	toPercent
} from 'utils';

import Cell from 'components/Common/Cell';
import Module from 'components/Module';
import Stronger from 'components/Stronger';
import TippingUnderline from 'components/TippingUnderline';

import useAccountQuery from '../../../graphql/useAccount';

function StakeLpTiping() {
	return (
		<TippingUnderline
			tooltip={
				<Typography variant='body2'>
					<Trans>
						Staked amount is calculated as the amount of EQU in LP Token at the
						time of staking.
					</Trans>
				</Typography>
			}
			label={
				<Typography variant='body2'>
					<Trans>Staked EQU in LP NFT</Trans>
				</Typography>
			}
		/>
	);
}

function LoginContainer({ children }: { children: ReactNode }) {
	const isLogin = useCheckLogin();
	const { isMatchMobile } = useAppBreakpoints();

	if (!isLogin) {
		return (
			<Typography variant='body2' fontWeight={isMatchMobile ? 600 : 400}>
				-
			</Typography>
		);
	}
	return <>{children}</>;
}

export default function StakeSummary() {
	const theme = useTheme();
	const {
		appToken,
		totalStakedWithMultiplier,
		nativeSymbol,
		appTokenUsdPrice
	} = useAppSelector(txBaseState);
	const { stakeStatistic, lpStakeList, maxMultiplier } =
		useAppSelector(earnStakeBaseState);
	const { totalAppAmount, claimableAppAmount, dailyEmission, uniAPR, uniTvl } =
		useAppSelector(earnPool2BaseState);
	const claimableLpStakeQuoteAmount = useAppSelector(
		selectClaimableLpStakeQuoteAmount
	);

	const { currentChain } = useCurrentChain();
	const { data: accountInformation, loading } = useAccountQuery();
	const { isMatchMobile, isMatchPad } = useAppBreakpoints();

	const totalUserStakedEqu = useMemo(() => {
		return lpStakeList?.reduce((all: number | string, current) => {
			return plus(all, current.stakedAmount);
		}, 0);
	}, [lpStakeList]);

	const avgUserMultiplier = useMemo(() => {
		if (isEmpty(lpStakeList) || !totalUserStakedEqu) {
			return 0;
		}
		const multiplier = lpStakeList?.reduce((all: number | string, current) => {
			return plus(all, multipliedBy(current.multiplier, current.stakedAmount));
		}, 0);
		return div(multiplier, totalUserStakedEqu);
	}, [lpStakeList, totalUserStakedEqu]);

	const equAPR = useMemo(() => {
		if (!stakeStatistic || !appTokenUsdPrice || !maxMultiplier) {
			return '';
		}
		const {
			stakeReward24h,
			liquidityStakedAmountWithMultiplier,
			equStakedAmountWithMultiplier
		} = stakeStatistic;
		const lpPercent = div(
			liquidityStakedAmountWithMultiplier,
			plus(liquidityStakedAmountWithMultiplier, equStakedAmountWithMultiplier)
		);
		// Staking APR (3x) =
		// 24h EQU in LP NFT Rewards Value / Σ(Staked EQU in LP NFT * EQU Price * 2 * Multiplier) * Max Multiplier * 365 * 100%
		return multipliedBy(
			multipliedBy(
				div(
					multipliedBy(stakeReward24h, lpPercent),
					multipliedBy(
						appTokenUsdPrice,
						multipliedBy(liquidityStakedAmountWithMultiplier, 2)
					)
				),
				maxMultiplier
			),
			DAYS_YEAR
		);
	}, [
		stakeStatistic,
		totalStakedWithMultiplier,
		appTokenUsdPrice,
		maxMultiplier
	]);

	const miningAPR = useMemo(() => {
		if (
			!stakeStatistic ||
			!dailyEmission ||
			!maxMultiplier ||
			currentChain.testnet
		) {
			return 0;
		}

		// Staking APR (3x)=
		// 24h EQU in LP NFT Rewards Value / Σ(Staked EQU in LP NFT * EQU Price * 2 * Multiplier) * Max Multiplier * 365 * 100%
		const { liquidityStakedAmountWithMultiplier } = stakeStatistic;
		const apr = multipliedBy(
			multipliedBy(
				div(
					dailyEmission,
					multipliedBy(liquidityStakedAmountWithMultiplier, 2)
				),
				maxMultiplier
			),
			DAYS_YEAR
		);
		return apr;
	}, [dailyEmission, stakeStatistic, maxMultiplier, currentChain]);

	const totalAPR = useMemo(() => {
		if (currentChain.testnet) {
			return 0;
			// return plus(plus(uniAPR || 0, equAPR), miningAPR);
		}
		return plus(plus(uniAPR, equAPR), miningAPR);
	}, [uniAPR, equAPR, miningAPR, currentChain]);

	const totalClaimableRewards = useMemo(() => {
		if (isEmpty(lpStakeList)) {
			return 0;
		}
		return lpStakeList.reduce((all: number | string, current) => {
			return plus(all, current.claimableReward);
		}, 0);
	}, [lpStakeList]);

	const totalRewards = useMemo(() => {
		if (
			!accountInformation ||
			!accountInformation ||
			!accountInformation.account
		) {
			return 0;
		}

		return plus(
			totalClaimableRewards,
			accountInformation?.account.claimedRewardFromLiquidity
		);
	}, [totalClaimableRewards, accountInformation]);

	return (
		<Paper
			className='sm:space-y-0 -mt-4 underLg:mt-0'
			sx={{
				background:
					isMatchMobile || isMatchPad
						? 'transparent'
						: theme.palette.background.paper
			}}
		>
			<div className='grid grid-cols-2 sm:grid-cols-1 gap-7 underLg:gap-2'>
				<Module
					title={<Trans>Overview</Trans>}
					sx={{
						background:
							isMatchMobile || isMatchPad
								? theme.palette.background.paper
								: 'transparent'
					}}
					className='sm:space-y-1'
				>
					<Cell
						label={<Trans>APR (Multiplier = {maxMultiplier}x)</Trans>}
						value={
							<div className='flex items-center space-x-1'>
								<Stronger
									value={
										<Typography
											component='span'
											className='leading-none'
											variant='body2'
											color={isNumeric(totalAPR) ? 'success.main' : 'secondary'}
										>
											{isNumeric(totalAPR) ? toPercent(totalAPR) : '-'}
										</Typography>
									}
									tooltip={
										<div>
											<Typography
												className='mb-2'
												align='inherit'
												variant='body2'
											>
												<Trans>
													APR (Multiplier = {maxMultiplier}x) ={' '}
													{shortenSymbol(appToken)}/{nativeSymbol} LP APR
													(Uniswap) + Staking APR ({maxMultiplier}x) + Mining
													APR ({maxMultiplier}x)
												</Trans>
											</Typography>
											<Cell
												label={
													<Trans>
														{shortenSymbol(appToken)}/{nativeSymbol} LP APR
														(Uniswap)
													</Trans>
												}
												value={
													isNumeric(uniAPR) && isPositive(uniAPR)
														? toPercent(uniAPR)
														: '-'
												}
											/>

											<Cell
												label={
													<div className='flex items-center space-x-1'>
														<Typography variant='body2' color='secondary'>
															<Trans>
																Staking APR ({maxMultiplier}
																x)
															</Trans>
														</Typography>
													</div>
												}
												value={isNumeric(equAPR) ? toPercent(equAPR) : '-'}
											/>
											<Cell
												label={<Trans>Mining APR ({maxMultiplier}x)</Trans>}
												value={
													isNumeric(miningAPR) ? toPercent(miningAPR) : '-'
												}
											/>
										</div>
									}
								/>
							</div>
						}
					/>
					<Cell
						label={<Trans>Daily Emission</Trans>}
						value={
							<Typography variant='body2'>
								{`${formatNumber(dailyEmission, 2, true, true)} ${shortenSymbol(
									appToken
								)}`}
							</Typography>
						}
					/>
					<Cell
						label={<StakeLpTiping />}
						value={
							<Typography variant='body2'>
								{`${formatNumber(
									stakeStatistic?.liquidityStakedAmount,
									appToken?.positionUnits
								)} ${shortenSymbol(appToken)}`}
							</Typography>
						}
					/>
					<Cell
						label={<Trans>Total Value Locked (TVL)</Trans>}
						value={
							<Typography variant='body2'>
								{uniTvl ? `$${formatNumber(uniTvl, 2)}` : '-'}
							</Typography>
						}
					/>
				</Module>
				<Module
					title={<Trans>My Data</Trans>}
					sx={{
						background:
							isMatchMobile || isMatchPad
								? theme.palette.background.paper
								: 'transparent'
					}}
					className='sm:space-y-1'
				>
					<Cell
						label={<StakeLpTiping />}
						value={
							<LoginContainer>
								<Typography variant='body2'>
									{`${formatNumber(
										totalUserStakedEqu,
										appToken?.positionUnits
									)} ${shortenSymbol(appToken)}`}
								</Typography>
							</LoginContainer>
						}
					/>
					<Cell
						label={<Trans>Avg. Multiplier</Trans>}
						value={
							<LoginContainer>
								<Typography variant='body2'>
									{`${formatNumber(avgUserMultiplier, 1)}x`}
								</Typography>
							</LoginContainer>
						}
					/>
					<Cell
						label={<Trans>Total Rewards</Trans>}
						value={
							<LoginContainer>
								<div className='flex justify-end space-x-3'>
									<Typography variant='body2'>
										{loading
											? '-'
											: `${formatNumber(totalRewards, 2)} ${QUOTE_USD}`}
									</Typography>
									<Typography variant='body2'>
										{`${formatNumber(
											totalAppAmount,
											appToken?.positionUnits
										)} ${shortenSymbol(appToken)}`}
									</Typography>
								</div>
							</LoginContainer>
						}
					/>
					<Cell
						label={<Trans>Claimable Rewards</Trans>}
						value={
							<LoginContainer>
								<div className='flex justify-end space-x-3'>
									<Typography variant='body2'>
										{`${formatNumber(
											claimableLpStakeQuoteAmount
										)} ${QUOTE_USD}`}
									</Typography>
									<Typography variant='body2'>
										{`${formatNumber(
											claimableAppAmount,
											appToken?.positionUnits
										)} ${shortenSymbol(appToken)}`}
									</Typography>
								</div>
							</LoginContainer>
						}
					/>
				</Module>
			</div>
		</Paper>
	);
}
