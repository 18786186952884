import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import { Grid, Typography, styled, useTheme } from '@mui/material';
import cn from 'classnames';
import { Dom_Size, Page, Version } from 'config/constants';

import { useCurrentPage } from 'hooks/useCurrentPage';
import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';

import { Earn, EarnActive } from 'components/Svg/Icons/footer/Earn';
import { Home, HomeActive } from 'components/Svg/Icons/footer/Home';
import { Pools, PoolsActive } from 'components/Svg/Icons/footer/Pools';
import { Trade, TradeActive } from 'components/Svg/Icons/footer/Trade';

const StyledFooter = styled('footer')<any>(({ theme }) => ({
	width: '100%',
	height: Dom_Size.MOBILE_HEIGHT_BOTTOM,
	position: 'fixed',
	left: 0,
	bottom: 0,
	zIndex: 2,
	background: theme.custom.positiveColor,
	boxShadow: '0px -1px 6px rgba(0, 0, 0, 0.06)'
}));

const LinkTab = ({
	route,
	label,
	icon,
	iconActive,
	selected
}: {
	route: string;
	label: ReactNode;
	icon: ReactNode;
	iconActive: ReactNode;
	selected?: boolean;
}) => {
	const rootLayout = document.getElementById('root-layout');

	return (
		<NavLink
			to={route}
			className='flex-1'
			onClick={() => {
				rootLayout.scrollTop = 0;
				window.gtag('event', 'navigate_page', {
					page: route
				});
			}}
		>
			{({ isActive }) => (
				<div className='flex flex-col items-center'>
					{isActive || selected ? iconActive : icon}
					<Typography
						className='scale-90'
						variant='body2'
						color={isActive || selected ? 'textPrimary' : 'textSecondary'}
					>
						{label}
					</Typography>
				</div>
			)}
		</NavLink>
	);
};

const MobileFooter = () => {
	const { theme: localTheme } = useAppSelector(globalBaseState);
	const currentVersion = useAppSelector(selectVersion);
	const currentPage = useCurrentPage();
	const theme = useTheme();

	return (
		<StyledFooter className={cn('flex items-center')}>
			<LinkTab
				route={currentVersion === Version.V2 ? Page.Index : Page.IndexV2}
				label={<Trans>Home</Trans>}
				icon={<Home />}
				iconActive={<HomeActive fill={theme.palette.text.primary} />}
				selected={
					currentVersion === Version.V2
						? currentPage === Page.Markets
						: currentPage === Page.MarketsV1
				}
			/>
			<LinkTab
				route={currentVersion === Version.V2 ? Page.Trade : Page.TradeV1}
				label={<Trans>Trade</Trans>}
				icon={<Trade />}
				iconActive={<TradeActive fill={theme.palette.text.primary} />}
			/>
			<LinkTab
				route={currentVersion === Version.V2 ? Page.Pools : Page.PoolsV1}
				label={<Trans>Pools</Trans>}
				icon={<Pools />}
				iconActive={<PoolsActive fill={theme.palette.text.primary} />}
			/>
			<LinkTab
				route={currentVersion === Version.V2 ? Page.Earn : Page.EarnV1}
				label={<Trans>Earn</Trans>}
				icon={<Earn />}
				iconActive={<EarnActive fill={theme.palette.text.primary} />}
			/>
		</StyledFooter>
	);
};

export default MobileFooter;
