import Slider, { SliderProps } from '@mui/material/Slider';
import { alpha, styled } from '@mui/material/styles';
import { App_Theme } from 'config/constants';

// import { color } from 'highcharts';

interface StyledSliderProps extends SliderProps {
	type?: 'dark' | 'light';
}
const shadow =
	'0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const StyledSlider = styled(Slider)<StyledSliderProps>(({ theme, type }) => ({
	color: theme.palette.secondary.main,
	height: 4,
	padding: '15px 0 4px 0',
	marginBottom: 0,
	'& .MuiSlider-thumb': {
		height: 10,
		width: 10,
		transform: 'translate(-25%, -50%)',
		backgroundColor: '#fff',
		boxShadow: shadow,
		'&:focus, &:hover, &.Mui-active': {
			boxShadow:
				'0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
			// Reset on touch devices, it doesn't add specificity
			'@media (hover: none)': {
				boxShadow: shadow
			}
		}
	},
	'& .MuiSlider-valueLabel': {
		lineHeight: 1.2,
		fontSize: 12,
		background: 'unset',
		padding: 4,
		minWidth: 32,
		height: 26,
		borderRadius: '4px',
		backgroundColor:
			theme.palette.mode === App_Theme.dark
				? theme.custom.cardBg
				: theme.custom.paperBg,
		color: theme.palette.text.primary,
		border: `1px solid ${theme.palette.divider}`,
		'&:before': {
			borderRight: `1px solid ${theme.palette.divider}`,
			borderBottom: `1px solid ${theme.palette.divider}`
		}
		/* transformOrigin: 'bottom left',
			transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
			'&:before': { display: 'none' },
			'&.MuiSlider-valueLabelOpen': {
				transform: 'translate(50%, -100%) rotate(-45deg) scale(1)'
			},
			'& > *': {
				transform: 'rotate(45deg)'
			} */
	},
	'& .MuiSlider-markLabel': {
		display: 'none',
		fontSize: 10,
		fontWeight: 'normal',
		color: theme.palette.secondary.main,
		'&:nth-of-type(4)': {
			transform: 'translateX(-10%)'
		},
		'&:nth-last-of-type(2)': {
			transform: 'translateX(-80%)'
		}
	},
	'& .MuiSlider-track': {
		height: 4,
		background:
			theme.palette.mode === App_Theme.dark
				? theme.palette.secondary.main
				: '#464A52',
		borderColor:
			theme.palette.mode === App_Theme.dark
				? theme.palette.secondary.main
				: '#464A52'
	},
	'& .MuiSlider-rail': {
		height: 4,
		background: alpha(theme.custom.reverseColor, 0.1),
		borderRadius: 8,
		opacity: 1
	},
	'& .MuiSlider-mark': {
		backgroundColor: alpha(theme.custom.reverseColor, 0.1),
		height: 8,
		width: 8,
		border: `2px solid ${theme.palette.secondary.main}`,
		borderRadius: 8,
		'&.MuiSlider-markActive': {
			opacity: 1
		}
	}
}));

export default StyledSlider;
