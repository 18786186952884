import React, { useCallback, useContext, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import {
	App_Theme,
	Dom_Size,
	Side,
	Trade_Type,
	Version
} from 'config/constants';
import {
	MobileActionContext,
	MobileActionsContextProvider
} from 'context/MobileActionsContext';
import { TradeContext } from 'context/TradeContext';

import Computer from 'pages/components/Computer';
import LimitForm from 'pages/components/TradeForm/LimitForm';
import MarketForm from 'pages/components/TradeForm/MarketForm';
import ToggleDirection from 'pages/components/TradeForm/ToggleDirection';
import { selectCurrentPool } from 'state/pools/selector';

import Dialog from 'components/Common/Dialog';
import CommonStyledButton from 'components/Common/StyledButton';
import StyledTab from 'components/Common/StyledTab';
import StyledTabs from 'components/Common/StyledTabs';

import ComputerIcon from '../../components/Svg/Icons/Computer';
import { globalBaseState } from '../../state/global/slice';
import { useAppSelector } from '../../state/hooks';
import { selectVersion } from '../../state/setting/selector';
import V2Logo from '../components/V2/V2Logo';

export function MobileActionsContent() {
	const currentPool = useAppSelector(selectCurrentPool);
	const currentVersion = useAppSelector(selectVersion);

	const { isVisibleForm, setIsVisibleForm } = useContext(MobileActionContext);

	const { tradeType, setTradeType, tradeDirection, setTradeDirection } =
		useContext(TradeContext);

	const onHandleChangeType = useCallback(
		(event: React.SyntheticEvent, newValue: Trade_Type) => {
			setTradeType(newValue);
		},
		[]
	);

	const onHandleOpenForm = (side: Side) => {
		setTradeDirection(side);
		setIsVisibleForm(true);
	};

	const onHandleCloseForm = () => {
		setTradeType(Trade_Type.Market);
		setIsVisibleForm(false);
	};

	const { theme } = useAppSelector(globalBaseState);
	const [visibleComputer, setVisibleComputer] = useState(false);
	const onOpenComputerDialog = () => {
		setVisibleComputer(true);
	};
	const onCloseComputerDialog = () => {
		setVisibleComputer(false);
	};

	return (
		<article>
			<Paper
				className='flex space-x-3 px-3 z-10'
				sx={{
					borderRadius: 0,
					position: 'fixed',
					width: '100%',
					bottom: Dom_Size.MOBILE_HEIGHT_BOTTOM
				}}
			>
				<CommonStyledButton
					className='flex flex-col justify-center'
					variant='contained'
					componentVariant={'long'}
					size='large'
					fullWidth
					onClick={() => onHandleOpenForm(Side.LONG)}
				>
					<Typography
						className='whitespace-normal'
						color='inherit'
						variant='h6'
						fontWeight={500}
					>
						<Trans>Long</Trans>
					</Typography>
				</CommonStyledButton>
				<CommonStyledButton
					className='flex flex-col justify-center'
					variant='contained'
					componentVariant={'short'}
					size='large'
					fullWidth
					onClick={() => onHandleOpenForm(Side.SHORT)}
				>
					<Typography
						className='whitespace-normal'
						color='inherit'
						variant='h6'
						fontWeight={500}
					>
						<Trans>Short</Trans>
					</Typography>
				</CommonStyledButton>
			</Paper>
			<Dialog open={isVisibleForm} onClose={onHandleCloseForm}>
				{visibleComputer && (
					<Computer
						onClose={onCloseComputerDialog}
						pool={currentPool}
						side={tradeDirection}
					/>
				)}
				<Box className='space-y-3 mt-2'>
					<ToggleDirection />
					<div className='flex justify-between'>
						<div className='flex items-center'>
							<StyledTabs value={tradeType} onChange={onHandleChangeType}>
								<StyledTab
									className='pl-0'
									label={<Trans>trade.Market</Trans>}
									value={Trade_Type.Market}
								/>

								<StyledTab
									label={<Trans>Limit</Trans>}
									value={Trade_Type.Limit}
								/>
							</StyledTabs>
							{currentVersion === Version.V2 && (
								<V2Logo className='ml-1' width={18} height={14} />
							)}
						</div>
						<IconButton className='-mr-1' onClick={onOpenComputerDialog}>
							<ComputerIcon
								color={theme === App_Theme.dark ? 'white' : 'black'}
							/>
						</IconButton>
					</div>
					{tradeType === Trade_Type.Market ? <MarketForm /> : <LimitForm />}
				</Box>
			</Dialog>
		</article>
	);
}

export default function MobileActions() {
	return (
		<MobileActionsContextProvider>
			<MobileActionsContent />
		</MobileActionsContextProvider>
	);
}
