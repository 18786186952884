import { useEffect, useState } from 'react';

import { Interface } from '@ethersproject/abi';
import { OrderBookAssistantABI } from 'config/abis';
import { Contract_Write_Mode, Transaction_Type } from 'config/constants';
import { getContractAddress } from 'config/contracts';
import { UseContractWriteConfig, UsePrepareContractWriteConfig } from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { useAppDispatch } from 'state/hooks';
import { updateSigning } from 'state/tx/slice';
import { BaseRecord, RecordForCancelAllOrders } from 'types';

import { useComputeGasLimit } from './useComputeGasLimit';
import { useCurrentChain } from './useCurrentChain';
import { useSendTransaction } from './useSendTransaction';

const orderBookAssistantInterface = new Interface(OrderBookAssistantABI);

export function useSubmitCancelAllOrders() {
	const dispatch = useAppDispatch();

	const { currentChainId } = useCurrentChain();

	const [contractWriteArgs, setContractWriteArgs] =
		useState<UseContractWriteConfig | null>(null);
	const [record, setRecord] = useState<RecordForCancelAllOrders | null>(null);
	const [description, setDescription] = useState<string>('');

	const { gasLimit, prepared } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirming, isConfirmed, error } =
		useSendTransaction(contractWriteArgs, gasLimit);

	const onConfirm = (
		limitOrderIds: Array<string> | null,
		profitLossOrderIds: Array<string> | null
	) => {
		console.log('useSubmitCancelAllOrders args begin ...');

		const results = [] as Array<string>;
		let orderIds = [] as Array<string>;

		if (limitOrderIds && limitOrderIds.length > 0) {
			results.push(
				orderBookAssistantInterface.encodeFunctionData(
					'cancelIncreaseOrderBatch',
					[limitOrderIds]
				)
			);
			orderIds = orderIds.concat(limitOrderIds);
		}

		if (profitLossOrderIds && profitLossOrderIds.length > 0) {
			results.push(
				orderBookAssistantInterface.encodeFunctionData(
					'cancelDecreaseOrderBatch',
					[profitLossOrderIds]
				)
			);
			orderIds = orderIds.concat(profitLossOrderIds);
		}

		const record = {
			transactionType: Transaction_Type.CancelAllOrders,
			orderIds
		} as RecordForCancelAllOrders;

		const description = TransactionDescription.CancelAllOrders();

		const contractWriteArgs = {
			mode: Contract_Write_Mode.Unprepared,
			address: getContractAddress(currentChainId, 'OrderBookAssistant'),
			abi: OrderBookAssistantABI,
			functionName: 'multicall',
			args: [results]
		} as UseContractWriteConfig;

		setRecord(record);
		setDescription(description);
		setContractWriteArgs(contractWriteArgs);

		dispatch(updateSigning(record));
	};

	useEffect(() => {
		if (prepared) {
			onSendTransaction(record as BaseRecord, description);
		}
	}, [prepared]);

	useEffect(() => {
		if (error) {
			setContractWriteArgs(null);
		}
	}, [error]);

	return {
		onConfirm,
		isConfirming,
		isConfirmed
	};
}
