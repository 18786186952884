import { useCallback, useMemo } from 'react';

import { PositionRouterV2ABI, positionRouterABI } from 'config/abis';
import {
	Contract_Write_Mode,
	DEFAULT_SETTING_SLIPPAGE,
	QUOTE_USD,
	Side,
	Transaction_Type,
	Version
} from 'config/constants';
import { getContractAddress, getContractAddressV2 } from 'config/contracts';
import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { IPositionItem } from 'graphql/useMyPositionsGraph';
import { selectPositionMinExecutionFee } from 'state/global/selector';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { RecordForClosePosition } from 'types';
import {
	computePriceX96,
	formatSize,
	isPositive,
	parseUnits,
	shortenSymbolNative,
	toDecimalPlaces,
	toQuoteAmount
} from 'utils';

import { useComputeGasLimit } from './useComputeGasLimit';
import { useCheckLogin, useCurrentChain } from './useCurrentChain';
import { useSendTransaction } from './useSendTransaction';
import { usePriceRangesByTolerance } from './useSlippageTolerance';

export function useSubmitCloseTrade(
	targetPosition: IPositionItem,
	acceptablePrice: string,
	quoteAmount: string,
	size: string,
	slippage: string | number = DEFAULT_SETTING_SLIPPAGE
) {
	const { quoteToken } = useAppSelector(txBaseState);
	const positionMinExecutionFee = useAppSelector(selectPositionMinExecutionFee);
	const currentVersion = useAppSelector(state => state.setting.currentVersion);

	const { currentChainId } = useCurrentChain();
	const isLogin = useCheckLogin();
	const { address } = useAccount();

	const {
		baseToken,
		poolId,
		side,
		maxIndexPrice: poolMaxPrice,
		minIndexPrice: poolMinPrice
	} = targetPosition;

	const entryPrice = useMemo(() => {
		if (side === Side.LONG) {
			return poolMinPrice;
		} else {
			return poolMaxPrice;
		}
	}, [poolMaxPrice, poolMinPrice, side]);

	const { minPrice, maxPrice, exactInRate } = usePriceRangesByTolerance(
		entryPrice,
		slippage
	);
	const { args, record, description } = useMemo(() => {
		if (!isLogin || !baseToken || !quoteToken || !isPositive(size)) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		console.log('useSubmitCloseTrade begin ...');
		// console.log('size---------------: ', size);

		// 使用用户支付的初始保证金
		if (!isPositive(quoteAmount)) {
			quoteAmount = '0';
		}
		if (acceptablePrice === 'Infinity') {
			acceptablePrice = '0';
		}
		const _marginDelta = toDecimalPlaces(quoteAmount, quoteToken.decimals);
		const marginDelta = parseUnits(_marginDelta, quoteToken.decimals);
		const _SizeDelta = toDecimalPlaces(size, baseToken.decimals);
		const formatSizeDelta = parseUnits(_SizeDelta, baseToken.decimals);

		const acceptablePriceX96 = computePriceX96(
			acceptablePrice || '0',
			baseToken.decimals,
			quoteToken.decimals
		);

		const args = [
			poolId,
			side,
			marginDelta,
			formatSizeDelta,
			acceptablePriceX96,
			address
		];

		const record = {
			transactionType:
				currentVersion === Version.V1
					? Transaction_Type.Close
					: Transaction_Type.CloseV2,
			pool: poolId,
			side,
			baseToken,
			quoteAmount,
			entryPrice
		} as RecordForClosePosition;

		const baseSymbolNative = shortenSymbolNative(baseToken, currentChainId);
		const poolSymbol = `${shortenSymbolNative(
			baseToken,
			currentChainId
		)}/${QUOTE_USD}`;

		const description = TransactionDescription.ClosePosition(
			poolSymbol,
			baseSymbolNative,
			formatSize(size, baseToken.positionUnits),
			toQuoteAmount(acceptablePrice, baseToken.precision),
			side
		);

		return {
			args,
			record,
			description
		};
	}, [
		poolId,
		entryPrice,
		isLogin,
		baseToken,
		quoteToken,
		quoteAmount,
		size,
		side,
		minPrice,
		maxPrice,
		exactInRate
	]);

	const overrides = useMemo(() => {
		if (!address || !isPositive(positionMinExecutionFee)) {
			return undefined;
		}
		return {
			from: address,
			value: positionMinExecutionFee
		};
	}, [address, positionMinExecutionFee]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address:
				currentVersion === Version.V2
					? getContractAddressV2(currentChainId, 'PositionRouter')
					: getContractAddress(currentChainId, 'PositionRouter'),
			abi:
				currentVersion === Version.V2 ? PositionRouterV2ABI : positionRouterABI,
			functionName: 'createDecreasePosition',
			args,
			overrides
		} as UseContractWriteConfig;
	}, [
		currentChainId,
		args,
		overrides,
		currentVersion,
		PositionRouterV2ABI,
		positionRouterABI
	]);

	const { gasLimit } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirming, isConfirmed, error } =
		useSendTransaction(contractWriteArgs, gasLimit);
	// useSendTransaction(contractWriteArgs, '600000');

	return {
		onConfirm: useCallback(() => {
			if (!record) return;
			onSendTransaction(record, description);
		}, [onSendTransaction, record, description]),
		isConfirming,
		isConfirmed,
		error
	};
}
