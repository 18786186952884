import { IPoolItem, TokenInfo } from 'types';

export enum Type_Earns {
	Staking = 'staking',
	StakingLp = 'staking_lp',
	Liquidity = 'liquidity',
	Position = 'position',
	Pool2 = 'pool2',
	RBF = 'rbf',
	Architect = 'architect'
}

export interface ClaimCalldata {
	type: Type_Earns;
	ids: Array<string>;
	rewardToken: TokenInfo;
	amount: string;
	poolTotalReward?: Array<PoolTotalReward>;
}

// 持仓挖矿
export interface PoolTotalReward {
	pool: string;
	totalReward: string;
}

export interface IPoolClaimable {
	total_amount: string;
	total_amount_raw: string;
	claimable_amount: string;
	pool_address: string;
	pool?: IPoolItem;
}

export interface IPositionFarmClaimRep {
	pool_claimable: Array<IPoolClaimable>;
	nonce: string;
	signature: string;
	claimed: boolean;
}

export interface IPoolReward {
	pool: string;
	claimable_amount: string;
	daily_emission_amount: string;
	coefficient: string;
}

export enum Reward_Type {
	POSITION = 'POSITION',
	LIQUIDITY = 'LIQUIDITY',
	POOL2 = 'POOL2',
	RBF = 'RBF',
	V2_POSITION = 'V2_POSITION',
	V2_LIQUIDITY = 'V2_LIQUIDITY'
}

export enum Lockup_Period {
	HIGH = 360,
	MEDIUM = 180,
	LOW = 90,
	LOWEST = 0
}
