import { useMemo } from 'react';

import { Version } from '../config/constants';
import { useAppSelector } from '../state/hooks';
import { selectVersion } from '../state/setting/selector';
import useLiquidityPoolsGraph from './useLiquidityPoolsGraph';
import useLpPositions from './useLpPositions';

const useCombineLpPositions = () => {
	const currentVersion = useAppSelector(selectVersion);

	const { data: v1Data, requestData: v1RequestData } = useLiquidityPoolsGraph();
	const {
		data: v2Data,
		requestData: v2RequestData,
		isLoading: v2Loading
	} = useLpPositions();

	const loading = useMemo(() => {
		if (currentVersion === Version.V1) {
			return false;
		} else {
			return v2Loading;
		}
	}, [v2Loading]);
	const data = useMemo(() => {
		if (currentVersion === Version.V1) {
			return v1Data;
		} else {
			return v2Data;
		}
	}, [v1Data, v2Data]);
	const requestData = useMemo(() => {
		if (currentVersion === Version.V1) {
			return v1RequestData;
		} else {
			return v2RequestData;
		}
	}, [v1RequestData, v2RequestData]);

	return { data, requestData, loading };
};

export default useCombineLpPositions;
