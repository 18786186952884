/**
 * Leverage slider marks
 * @param maxLeverage
 * @returns
 */
export function computeLeverageMarks(
	maxLeverage: number
): Array<{ value: number; label: string }> {
	const marksMap = {
		10: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
		20: [1, 5, 10, 15, 20],
		40: [1, 10, 20, 30, 40],
		50: [1, 10, 20, 30, 40, 50],
		100: [1, 25, 50, 75, 100],
		200: [1, 50, 100, 150, 200]
	};
	const marks = marksMap[maxLeverage];
	return marks.map(mark => ({ value: mark, label: `${mark}x` }));
}
