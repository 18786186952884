import React from 'react';
import { useUpdateEffect } from 'react-use';

import useArchitectRewardsGraph from 'graphql/useArchitectRewardsGraph';
import { useAppDispatch } from 'state/hooks';

import { setArchitectList } from './slice';
import { useCheckLogin } from 'hooks/useCurrentChain';

export default React.memo(function EarnArchitectUpdater() {
	const dispatch = useAppDispatch();

	const isLogin = useCheckLogin();

	const { data } = useArchitectRewardsGraph();

	useUpdateEffect(() => {
		if (isLogin && data) {
			dispatch(setArchitectList(data));
		} else {
			dispatch(setArchitectList(null));
		}
	}, [data, isLogin]);

	return null;
});
