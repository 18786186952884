import { Reducer, createSlice } from '@reduxjs/toolkit';

import { produce } from 'immer';
import { RootState } from 'state';
import { RecordKey } from 'types';

import { TransactionRecord } from '../../entities/TransactionRecord';

interface StateProps {
	transactionRecords: Map<RecordKey, TransactionRecord>;
	timeoutRecords: Map<string, TransactionRecord>;
}

const initialState: StateProps = {
	transactionRecords: new Map<RecordKey, TransactionRecord>(),
	timeoutRecords: new Map<string, TransactionRecord>()
};

export const slice = createSlice({
	name: 'records',
	initialState,
	reducers: {
		setTransactionRecords(state, { payload }) {
			state.transactionRecords = payload;
		},
		addOrUpdateRecord(state, { payload }: { payload: TransactionRecord }) {
			console.log('addOrUpdateRecord beign ...');
			produce(state.transactionRecords, () => {
				const current = new Map(state.transactionRecords);
				current.set(`${payload.hash}:${payload.type}`, payload);
				state.transactionRecords = current;
			});
		},
		removeRecord(state, { payload }: { payload: TransactionRecord }) {
			produce(state.transactionRecords, () => {
				const current = new Map(state.transactionRecords);
				current.delete(`${payload.hash}:${payload.type}`);
				state.transactionRecords = current;
			});
		},
		setTimeoutRecords(state, { payload }: { payload: TransactionRecord }) {
			const current = new Map(state.timeoutRecords);
			current.set(payload.hash, payload);
			state.timeoutRecords = current;
		}
	}
});

export const {
	setTransactionRecords,
	addOrUpdateRecord,
	removeRecord,
	setTimeoutRecords
} = slice.actions;

export const recordsBaseState = (state: RootState) => state.records;

export default slice.reducer as Reducer<typeof initialState>;
