import { SvgProps } from 'components/Svg/types';

// hover状态
export function HomeActive({ fill = 'white' }: SvgProps) {
	return (
		<svg
			width='26'
			height='25'
			viewBox='0 0 26 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_12533_76761)'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M2.4348 12.5936L3.73307 11.5698V21.1839C3.73307 21.4913 3.85518 21.786 4.07252 22.0034C4.28987 22.2207 4.58465 22.3428 4.89203 22.3428H10.3552V15.2593C10.3552 14.9279 10.6239 14.6593 10.9552 14.6593H16.1617C16.4931 14.6593 16.7617 14.9279 16.7617 15.2593V22.3428H21.5733C21.8807 22.3428 22.1755 22.2207 22.3928 22.0034C22.6102 21.786 22.7323 21.4913 22.7323 21.1839V11.5365L24.0722 12.5936C24.1821 12.6802 24.3219 12.7196 24.4609 12.7032C24.5998 12.6868 24.7265 12.6159 24.8132 12.506L25.1404 12.0917C25.1834 12.0373 25.2152 11.9749 25.234 11.9082C25.2529 11.8415 25.2584 11.7717 25.2503 11.7028C25.2421 11.634 25.2205 11.5674 25.1866 11.5069C25.1527 11.4464 25.1073 11.3931 25.0528 11.3502L13.581 2.29922C13.4878 2.2256 13.3725 2.18555 13.2538 2.18555C13.135 2.18555 13.0197 2.2256 12.9266 2.29922L1.45476 11.3508C1.34492 11.4374 1.27399 11.5642 1.25756 11.7031C1.24113 11.842 1.28055 11.9818 1.36715 12.0917L1.69383 12.506C1.7805 12.6159 1.90723 12.6868 2.04618 12.7032C2.18512 12.7196 2.3249 12.6802 2.4348 12.5936Z'
					fill={fill}
				/>
			</g>
			<defs>
				<clipPath id='clip0_12533_76761'>
					<rect
						width='25.9615'
						height='24.0385'
						fill={fill}
						transform='translate(0.0192871 0.19043)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
}

export function Home({ fill = 'white' }: SvgProps) {
	return (
		<svg
			width='26'
			height='25'
			viewBox='0 0 26 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_12533_76765)'>
				<path
					d='M5.00331 10.3831V20.7104H9.82935V15.0733C9.82935 14.8707 9.99401 14.7065 10.1967 14.7065H15.7951C15.9983 14.7065 16.1624 14.8707 16.1624 15.0733V20.7104H21.0048V10.3493L13 4.12021L5.00331 10.3831ZM3.42004 11.6323L2.12177 12.6561C2.01187 12.7427 1.87209 12.7821 1.73315 12.7657C1.5942 12.7493 1.46746 12.6784 1.3808 12.5685L1.05412 12.1542C0.967518 12.0443 0.9281 11.9045 0.944528 11.7656C0.960956 11.6267 1.03189 11.4999 1.14172 11.4133L12.6728 2.36172C12.766 2.2881 12.8812 2.24805 13 2.24805C13.1187 2.24805 13.234 2.2881 13.3272 2.36172L24.9086 11.4127C24.9631 11.4556 25.0086 11.5089 25.0424 11.5694C25.0763 11.6299 25.0979 11.6965 25.1061 11.7653C25.1142 11.8342 25.1087 11.904 25.0899 11.9707C25.071 12.0374 25.0392 12.0998 24.9962 12.1542L24.669 12.5685C24.5824 12.6784 24.4556 12.7493 24.3167 12.7657C24.1777 12.7821 24.038 12.7427 23.9281 12.6561L22.5881 11.599V21.0891C22.5881 21.3965 22.466 21.6913 22.2486 21.9086C22.0313 22.1259 21.7365 22.248 21.4291 22.248H4.57899C4.27162 22.248 3.97684 22.1259 3.75949 21.9086C3.54215 21.6913 3.42004 21.3965 3.42004 21.0891V11.6323ZM11.3106 20.7104H14.6813V16.2636H11.3106V20.7104Z'
					fill='#8B919D'
				/>
			</g>
			<defs>
				<clipPath id='clip0_12533_76765'>
					<rect
						width='25.9615'
						height='24.0385'
						fill='white'
						transform='translate(0.0192871 0.228516)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
