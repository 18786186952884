import { t } from '@lingui/macro';
import { Claim_Type } from 'config/constants';

export function formatClaimType(type: Claim_Type) {
	if (!type) {
		return '';
	}
	switch (type) {
		case Claim_Type.Architect:
			return t`EFC Architects`;
		case Claim_Type.Connector:
			return t`EFC Connectors`;
		case Claim_Type.ConnectorV2:
			return t`EFC Connectors V2`;
		case Claim_Type.Liquidity:
			return t`Liquidity Mining`;
		case Claim_Type.LiquidityV2:
			return t`Liquidity Mining V2`;
		case Claim_Type.Position:
			return t`Position Mining`;
		case Claim_Type.PositionV2:
			return t`Position Mining V2`;
		case Claim_Type.Pool2:
			return t`Pool2 Mining`;
		case Claim_Type.Member:
			return t`EFC Members`;
		case Claim_Type.MemberV2:
			return t`EFC Members V2`;
		case Claim_Type.RiskBufferFund:
			return t`RBF Mining`;
		case Claim_Type.Staking:
			return t`Staking EQU`;
		case Claim_Type.StakingLp:
			return t`Staking LP NFT`;
		default:
			return '';
	}
}
