import { useCallback, useMemo } from 'react';

import { IS_USER_WHITE_MODEL, USER_WHITE_LIST } from 'config/constants';
import { SUPPORTED_CHAIN_IDS } from 'config/constants/connector/chains';
import { NATIVE_CURRENCY } from 'config/constants/tokens';
import { useAccount, useDisconnect, useNetwork } from 'wagmi';

import { globalBaseState } from 'state/global/slice';

import { useAppSelector } from '../state/hooks';

// 获取当前Chain，以及是否是支持的链
export function useCurrentChain() {
	const { chain } = useAppSelector(globalBaseState);
	const { chain: walletCurrentChain } = useNetwork();
	const checkChain = useCallback(
		(chainId: number) => {
			return SUPPORTED_CHAIN_IDS.some(item => item === chainId);
		},
		[chain]
	);
	const isNetworkError = !checkChain(walletCurrentChain?.id);
	return useMemo(() => {
		return {
			currentChain: chain,
			currentChainId: chain.id,
			isSupportedChain: SUPPORTED_CHAIN_IDS.some(
				item => item === (walletCurrentChain && walletCurrentChain.id)
			),
			checkChain,
			isNetworkError
		};
	}, [chain, checkChain, walletCurrentChain]);
}

// 判断登录态，即在正确的链下，已连接钱包
export function useCheckLogin() {
	const { chain } = useNetwork();
	const { address, isConnected } = useAccount();
	const { disconnect } = useDisconnect();
	return useMemo(() => {
		if (
			address &&
			IS_USER_WHITE_MODEL &&
			USER_WHITE_LIST.indexOf(address || '') === -1
		) {
			disconnect();
		}
		return SUPPORTED_CHAIN_IDS.some(item => item === chain?.id) && isConnected;
	}, [chain, isConnected, address]);
}

export function useCurrentNativeCurrency() {
	const localChain = useAppSelector(state => state.global.chain);

	const nativeSymbol = useMemo(() => {
		if (localChain.nativeCurrency) {
			return localChain.nativeCurrency.symbol;
		} else {
			return 'ETH';
		}
	}, [localChain]);

	const nativeCurrency = useMemo(() => {
		return NATIVE_CURRENCY[localChain.id];
	}, [localChain]);

	const checkNativeAddress = useCallback(
		(address: string) => {
			if (!address) {
				return false;
			}
			if (address.toLowerCase() === nativeSymbol.toLowerCase()) {
				return true;
			}
			return false;
		},
		[nativeSymbol]
	);

	return {
		nativeSymbol,
		nativeCurrency,
		checkNativeAddress,
	};
}
