import React from 'react';
import { useUpdateEffect } from 'react-use';

import { t } from '@lingui/macro';
import { Close } from '@mui/icons-material';
import {
	Box,
	Breakpoint,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	Dialog as MuiDialog,
	Slide
} from '@mui/material';
import { DialogContentProps } from '@mui/material/DialogContent/DialogContent';
import { useTheme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import cn from 'classnames';
import { Dom_Size } from 'config/constants';
import { useAccount, useNetwork } from 'wagmi';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import type { Merge } from 'type-fest';

import CommonButton from './StyledButton';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export type DialogProps = Merge<
	DialogContentProps & {
		open: boolean;
		titleAlign: 'left' | 'center' | 'right';
		confirmLabel?: React.ReactNode;
		onConfirm?: () => unknown | Promise<unknown>;
		cancelLabel?: React.ReactNode;
		onCancel?: () => unknown | Promise<unknown>;
		onClose?: () => void;
		maxWidth?: Breakpoint | false;
		width?: number | string;
		height?: number | string;
		disabled?: boolean;
		loading?: boolean;
		isConnectWallet?: boolean;
		isRejected?: boolean;
		isTitle?: boolean;
		size?: 'large' | 'medium' | 'small';
	},
	{ title?: React.ReactNode }
>;

const Dialog: React.FC<React.PropsWithChildren<DialogProps>> = props => {
	const theme = useTheme();
	const { address } = useAccount();
	const { chain: walletCurrentChain } = useNetwork();

	const {
		title,
		titleAlign = 'center',
		children,
		open,
		onClose,
		maxWidth,
		width,
		height,
		disabled,
		confirmLabel = t`Confirm`,
		onConfirm,
		cancelLabel = t`Cancel`,
		onCancel,
		isConnectWallet = false,
		isRejected = false,
		isTitle = true,
		size,
		...restProps
	} = props;

	const { isMatchMobile } = useAppBreakpoints();

	useUpdateEffect(() => {
		if (onClose) {
			onClose();
		}
	}, [address, walletCurrentChain]);

	const onHandleConfirm = () => {
		if (onConfirm) {
			onConfirm();
		}
	};
	const onHandleCancel = () => {
		if (onCancel) {
			onCancel();
		}
	};

	return (
		<MuiDialog
			open={open}
			onClose={onClose}
			maxWidth={maxWidth}
			fullScreen={isMatchMobile}
			TransitionComponent={isMatchMobile ? Transition : undefined}
			className={cn('m-auto', {
				'z-20': isConnectWallet,
				'z-10': !isConnectWallet
			})}
			sx={{
				'& >.MuiDialog-container': {
					overflow: 'hidden'
				},
				'& .MuiPaper-root': {
					width:
						size === 'large'
							? isMatchMobile
								? '100%'
								: Dom_Size.DIALOG_WIDTH_LARGE
							: isMatchMobile
							? '100%'
							: width
				},
				'& .MuiDialog-paper': {
					minHeight: height
				}
			}}
		>
			{title && (
				<DialogTitle
					className={cn(
						`flex flex-col px-6 pt-6 pm-20 sm:px-3`,
						isConnectWallet ? 'py-0' : ''
					)}
				>
					<Box
						className={cn(
							`flex text-base font-medium sm:px-0`,
							isConnectWallet ? 'pt-0 pb-4' : '',
							{
								'justify-start': titleAlign === 'left',
								'justify-center': titleAlign === 'center',
								'justify-end': titleAlign === 'right'
							}
						)}
					>
						{isTitle ? title : <div className='pb-2'></div>}

						{onClose && (
							<IconButton
								sx={{
									color: theme.palette.text.primary,
									position: 'absolute',
									right: isMatchMobile
										? isTitle
											? '12px'
											: '8px'
										: isTitle
										? '22px'
										: '8px',
									top: isTitle ? '22px' : '8px',
									background: theme.palette.action.hover
								}}
								onClick={onClose}
							>
								<Close
									sx={{
										width: isMatchMobile ? 16 : 20,
										height: isMatchMobile ? 16 : 20,
										fontWeight: 'bold'
									}}
								/>
							</IconButton>
						)}
					</Box>
					{isConnectWallet && !isRejected && <Divider className='mb-3' />}
				</DialogTitle>
			)}

			<DialogContent
				className={cn(isConnectWallet ? 'p-0 sm:pb-14' : 'px-4 sm:px-3')}
				{...restProps}
			>
				{children}
			</DialogContent>

			{(onConfirm || onCancel) && (
				<DialogActions className='px-6 pt-0 sm:px-3'>
					{!onCancel && (
						<CommonButton
							componentVariant='confirm'
							className='font-bold'
							fullWidth
							onClick={onHandleConfirm}
							disabled={disabled}
						>
							{confirmLabel}
						</CommonButton>
					)}

					{onCancel && (
						<div className='flex flex-1 space-x-3'>
							<CommonButton
								fullWidth
								componentVariant='cancel'
								onClick={onHandleCancel}
							>
								{cancelLabel}
							</CommonButton>
							<CommonButton
								fullWidth
								variant='contained'
								disabled={disabled}
								onClick={onHandleConfirm}
							>
								{confirmLabel}
							</CommonButton>
						</div>
					)}
				</DialogActions>
			)}
		</MuiDialog>
	);
};

export default Dialog;
