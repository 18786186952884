import { useApolloClients } from 'context/ApolloClientsContext';

import { useMiningPoolsQuery } from './__generated_referral__/types-and-hooks';

export function useMiningPoolsGraph() {
	const { clientReferral } = useApolloClients();
	const { data, loading, refetch } = useMiningPoolsQuery({
		client: clientReferral
	});

	return {
		data: data && data.pools ? data.pools : undefined,
        isEnableMining: data && data.pools.length > 0 ? true : false,
		loading,
		refetch
	};
}
