import { useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { useApolloClients } from 'context/ApolloClientsContext';
import { useAccount } from 'wagmi';

import { useTradersReferralQuery } from './__generated_referral__/types-and-hooks';

export function useTradersReferralGraph() {
	const { clientReferral } = useApolloClients();
	const { address } = useAccount();
	const [loading, setLoading] = useState<boolean>(true);
	const {
		data,
		refetch,
		loading: isLoading,
		networkStatus
	} = useTradersReferralQuery({
		variables: {
			account: address as string
		},
		skip: !address,
		client: clientReferral
	});

	useUpdateEffect(() => {
		if (!isLoading) {
			setLoading(isLoading);
		}
	}, [isLoading]);

	return useMemo(() => {
		if (networkStatus !== 7) {
			return {
				data: undefined,
				loading,
				refetch
			};
		}
		if (!data || !data.referee) {
			return {
				data: false,
				loading,
				refetch
			};
		}
		return {
			data: data.referee,
			loading,
			refetch
		};
	}, [data, refetch, loading, networkStatus]);
}
