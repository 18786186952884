import { Reducer, createSlice } from '@reduxjs/toolkit';
import { Input_Direction } from 'config/constants';

import { RootState } from 'state';

interface StateProps {
	marginDeltaForLong: string;
	marginDeltaForShort: string;
	sizeForLong: string;
	sizeForShort: string;
	impactFeeForLong: string;
	impactFeeRateForLong: string;
	tradingFeeForLong: string;
	tradingFeeForShort: string;
	originTradingFeeForLong: string;
	originTradingFeeForShort: string;
	impactFeeForShort: string;
	impactFeeRateForShort: string;
	initialMarginForLong: string;
	initialMarginForShort: string;
	directionForLong: Input_Direction;
	directionForShort: Input_Direction;
	priceForLong: string;
	priceForShort: string;
	acceptableTradePriceForLong: string;
	acceptableTradePriceForShort: string;
}

const initialState: StateProps = {
	marginDeltaForLong: '',
	marginDeltaForShort: '',
	sizeForLong: '',
	sizeForShort: '',
	impactFeeForLong: '',
	impactFeeRateForLong: '',
	tradingFeeForLong: '',
	tradingFeeForShort: '',
	originTradingFeeForLong: '',
	originTradingFeeForShort: '',
	impactFeeForShort: '',
	impactFeeRateForShort: '',
	initialMarginForLong: '',
	initialMarginForShort: '',
	directionForLong: Input_Direction.In,
	directionForShort: Input_Direction.In,
	priceForLong: '',
	priceForShort: '',
	acceptableTradePriceForLong: '',
	acceptableTradePriceForShort: ''
};

export const slice = createSlice({
	name: 'limit',
	initialState,
	reducers: {
		setMarginDeltaForLong(state, { payload }) {
			state.marginDeltaForLong = payload;
		},
		setMarginDeltaForShort(state, { payload }) {
			state.marginDeltaForShort = payload;
		},
		setSizeForLong(state, { payload }) {
			state.sizeForLong = payload;
		},
		setSizeForShort(state, { payload }) {
			state.sizeForShort = payload;
		},
		setImpactFeeForLong(state, { payload }) {
			state.impactFeeForLong = payload;
		},
		setImpactFeeRateForLong(state, { payload }) {
			state.impactFeeRateForLong = payload;
		},
		setTradingFeeForLong(state, { payload }) {
			state.tradingFeeForLong = payload;
		},
		setTradingFeeForShort(state, { payload }) {
			state.tradingFeeForShort = payload;
		},
		setOriginTradingFeeForLong(state, { payload }) {
			state.originTradingFeeForLong = payload;
		},
		setOriginTradingFeeForShort(state, { payload }) {
			state.originTradingFeeForShort = payload;
		},
		setImpactFeeForShort(state, { payload }) {
			state.impactFeeForShort = payload;
		},
		setImpactFeeRateForShort(state, { payload }) {
			state.impactFeeRateForShort = payload;
		},
		setInitialMarginForLong(state, { payload }) {
			state.initialMarginForLong = payload;
		},
		setInitialMarginForShort(state, { payload }) {
			state.initialMarginForShort = payload;
		},
		setDirectionForLong(state, { payload }) {
			state.directionForLong = payload;
		},
		setDirectionForShort(state, { payload }) {
			state.directionForShort = payload;
		},
		setPriceForLong(state, { payload }) {
			state.priceForLong = payload;
		},
		setPriceForShort(state, { payload }) {
			state.priceForShort = payload;
		},
		setAcceptableTradePriceForLong(state, { payload }) {
			state.acceptableTradePriceForLong = payload;
		},
		setAcceptableTradePriceForShort(state, { payload }) {
			state.acceptableTradePriceForShort = payload;
		},
		reset(state) {
			state.marginDeltaForLong = '';
			state.marginDeltaForShort = '';
			state.sizeForLong = '';
			state.sizeForShort = '';
			state.initialMarginForLong = '';
			state.initialMarginForShort = '';
			state.tradingFeeForLong = '';
			state.tradingFeeForShort = '';
			state.impactFeeForLong = '';
			state.impactFeeForShort = '';
			state.impactFeeRateForLong = '';
			state.impactFeeRateForShort = '';
			state.priceForLong = '';
			state.priceForShort = '';
			state.acceptableTradePriceForLong = '';
			state.acceptableTradePriceForShort = '';
		}
	}
});

export const {
	setMarginDeltaForLong,
	setMarginDeltaForShort,
	setSizeForLong,
	setSizeForShort,
	setImpactFeeForLong,
	setImpactFeeRateForLong,
	setTradingFeeForLong,
	setTradingFeeForShort,
	setOriginTradingFeeForLong,
	setOriginTradingFeeForShort,
	setImpactFeeForShort,
	setImpactFeeRateForShort,
	setInitialMarginForLong,
	setInitialMarginForShort,
	setDirectionForLong,
	setDirectionForShort,
	setPriceForLong,
	setPriceForShort,
	setAcceptableTradePriceForLong,
	setAcceptableTradePriceForShort,
	reset
} = slice.actions;

export const limitBaseState = (state: RootState) => state.limit;

export default slice.reducer as Reducer<typeof initialState>;
