import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { filter } from 'lodash';
import { selectClaimableArchitectList } from 'state/earn/architect/selector';
import { selectVersion } from 'state/setting/selector';

import ApproveButtonV2 from 'components/ApproveButtonV2';
import { StyleCheckbox } from 'components/Common/StyleCheckbox';

import ApproveButton from '../../../components/ApproveButton';
import Dialog, { DialogProps } from '../../../components/Common/Dialog';
import { CommonStyledDivider } from '../../../components/Common/Styled';
import Table from '../../../components/Table/StickyTable';
import { Transaction_Type, Version } from '../../../config/constants';
import useSubmitClaimArchitect from '../../../hooks/useSubmitClaimArchitect';
import { useAppSelector } from '../../../state/hooks';
import { txBaseState } from '../../../state/tx/slice';
import { formatNumber, isPositive, plus, shortenSymbol } from '../../../utils';
import EmptyClaim from '../EmptyClaim';
import { Type_Earns } from '../types';

const ArchitectClaimDialog = (props: DialogProps) => {
	const { onClose } = props;

	const claimableArchitectList = useAppSelector(selectClaimableArchitectList);

	const theme = useTheme();
	const currentVersion = useAppSelector(selectVersion);
	const { quoteToken, signingMap } = useAppSelector(txBaseState);

	const [selected, setSelected] = useState<string[]>([]);
	const [quoteAmount, setQuoteAmount] = useState('0');
	const { onConfirm, isConfirming, isConfirmed } = useSubmitClaimArchitect(
		selected,
		quoteAmount
	);

	const isSelected = (id: string) => selected.indexOf(id) !== -1;

	useEffect(() => {
		if (selected && selected.length) {
			const filterMembers = filter(claimableArchitectList, item =>
				selected.includes(item.id)
			);
			let unclaimedSum = '0';
			filterMembers.forEach(item => {
				unclaimedSum = plus(unclaimedSum, item.claimableReward);
			});
			setQuoteAmount(unclaimedSum);
		} else {
			setQuoteAmount('0');
		}
	}, [selected]);

	useEffect(() => {
		if (isConfirmed) {
			onClose();
		}
	}, [isConfirmed]);

	const handleClick = (event: ChangeEvent<HTMLInputElement>, id: string) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const columns = [
		{
			key: 'type',
			width: '40%',
			label: <Trans>Rewards Source</Trans>,
			align: 'left',
			format: (row: any) => {
				const isItemSelected = isSelected(row.id);
				return (
					<div className='flex items-center'>
						<StyleCheckbox
							checked={isItemSelected}
							size='small'
							className='-ml-2.5'
							onChange={event => handleClick(event, row.id)}
						/>
						<Typography className='capitalize'>{`${Type_Earns.Architect} #${row.id}`}</Typography>
					</div>
				);
			}
		},
		{
			key: 'count',
			width: '60%',
			label: (
				<Typography variant='body2'>{`${shortenSymbol(
					quoteToken
				)} ${t`Amount`}`}</Typography>
			),
			align: 'right',
			format: (row: any) => {
				return (
					<div className='flex'>
						<Typography className='flex-1' variant='body2'>{`${formatNumber(
							row.claimableReward
						)} ${shortenSymbol(quoteToken)}`}</Typography>
					</div>
				);
			}
		}
	];

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = claimableArchitectList.map(n => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const [disabled, submitText] = useMemo(() => {
		if (!isPositive(quoteAmount)) {
			return [true, t`Claim`];
		}
		if (signingMap.has(Transaction_Type.ClaimArchitect)) {
			return [true, t`Loading...`];
		}
		if (isConfirming) {
			return [true, t`Submitting...`];
		}
		return [false, t`Claim`];
	}, [quoteAmount, isConfirming, signingMap]);
	const selectedCount = selected.length;

	const { isMatchPad } = useAppBreakpoints();

	return (
		<Dialog title={t`Claim`} open={true} onClose={onClose}>
			{claimableArchitectList.length === 0 && <EmptyClaim />}
			{claimableArchitectList.length !== 0 && (
				<div className='space-y-4'>
					<Typography color='text.secondary'>
						<Trans>
							The following {claimableArchitectList.length} rewards will be
							settled for you.
						</Trans>
					</Typography>
					<div className='-mx-4'>
						<Table
							columns={columns}
							rows={claimableArchitectList}
							cellColor={theme.custom.cardBg}
							origin={isMatchPad}
						/>
					</div>
					<CommonStyledDivider />
					<div className='flex items-center'>
						<StyleCheckbox
							checked={selected.length === claimableArchitectList.length}
							size='small'
							className='-ml-2.5'
							onChange={handleSelectAllClick}
						/>
						<div className='flex items-center space-x-2'>
							<Typography color='text.secondary'>
								<Trans>{selectedCount} selected, total</Trans>
							</Typography>
							<Typography variant='body2'>{`${formatNumber(
								quoteAmount
							)} ${shortenSymbol(quoteToken)}`}</Typography>
						</div>
					</div>
					<section>
						{currentVersion === Version.V2 ? (
							<ApproveButtonV2
								componentVariant='confirm'
								fullWidth
								onClick={onConfirm}
								disabled={disabled}
								isApproveRewardCollectorToV1
							>
								{submitText}
							</ApproveButtonV2>
						) : (
							<ApproveButton
								componentVariant='confirm'
								fullWidth
								onClick={onConfirm}
								disabled={disabled}
								isApproveRewardCollector
							>
								{submitText}
							</ApproveButton>
						)}
					</section>
				</div>
			)}
		</Dialog>
	);
};

export default ArchitectClaimDialog;
