import { Reducer, createSelector, createSlice } from '@reduxjs/toolkit';

import { IPoolInfo } from 'graphql/usePoolInfoGraph';
import { RootState } from 'state';
import { reducers as reducersV1 } from 'state/pools/poolV1/slice';
import { reducers as reducersV2 } from 'state/pools/poolV2/slice';
import { IPoolItem } from 'types';

import { selectVersion } from '../setting/selector';

interface StateProps {
	pools: Array<IPoolItem>;
	poolMap: Map<string, IPoolItem>;
	liquidityPools: Array<any>;
	poolAddress: string | undefined;
	poolInfo: IPoolInfo | null;
}

const initialState: { [version: string]: StateProps } = {
	V1: {
		pools: [],
		poolMap: new Map<string, IPoolItem>(),
		liquidityPools: [],
		poolAddress: undefined,
		poolInfo: null
	},
	V2: {
		pools: [],
		poolMap: new Map<string, IPoolItem>(),
		liquidityPools: [],
		poolAddress: undefined,
		poolInfo: null
	}
};

export const slice = createSlice({
	name: 'pools',
	initialState,
	reducers: {
		...reducersV1,
		...reducersV2
	}
});

export const {
	setPools,
	setPoolMap,
	setLiquidityPools,
	setPoolAddress,
	setPoolInfo,
	setV2Pools,
	setV2PoolMap,
	setV2LiquidityPools,
	setV2PoolAddress,
	setV2PoolInfo
} = slice.actions;

export const poolsState = (state: RootState) => state.pools;

export const poolsBaseState = createSelector(
	selectVersion,
	poolsState,
	(version, state) => {
		return state[version];
	}
);

export default slice.reducer as Reducer<typeof initialState>;
