import { createSelector } from '@reduxjs/toolkit';

import { div, formatLeverage, isPositive } from 'utils';

import { liquidityBaseState } from './slice';

export const selectLeverageText = createSelector(
	[liquidityBaseState],
	state => {
		const { leverage } = state;
		if (isPositive(leverage)) {
			return formatLeverage(leverage);
		} else {
			return '-';
		}
	}
);

export const selectMarginRatio = createSelector([liquidityBaseState], state => {
	const { leverage } = state;
	if (isPositive(leverage)) {
		return div(1, leverage);
	} else {
		return '-';
	}
});
