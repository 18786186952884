import React from 'react';
import { Virtuoso } from 'react-virtuoso';

import { Trans, t } from '@lingui/macro';
import { Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import { APP_TOKEN_SYMBOL, App_Theme } from 'config/constants';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import type { Merge } from 'type-fest';
import { IAggregateClaimHistory } from 'types';
import {
	ExplorerDataType,
	formatClaimType,
	formatNumber,
	getExplorerLink,
	shortenHash,
	shortenSymbol
} from 'utils';

import Dialog, { DialogProps } from 'components/Common/Dialog';
import StyledLink from 'components/Common/StyledLink';
import NoData from 'components/NoData';
import SectionLoading from 'components/SectionLoading';
import Stronger from 'components/Stronger';

import { DirectionCell } from '../../components/Common/Cell';
import VirtualTable from '../../components/Table/VirtualTable';
import TippingUnderline from '../../components/TippingUnderline';
import { ClaimHistoriesQueryQuery } from '../../graphql/__generated_referral__/types-and-hooks';

export default function ClaimHistoryDialog(
	props: Merge<
		DialogProps,
		{
			claimHistories: ClaimHistoriesQueryQuery['aggregationClaimHistories'];
			fetchAction: (index: number) => void;
			loading: boolean;
		}
	>
) {
	const { onClose, claimHistories, fetchAction, loading } = props;
	const { isMatchMobile, isMatchPc } = useAppBreakpoints();
	const { quoteToken, appToken } = useAppSelector(txBaseState);

	const theme = useTheme();
	const { currentChain } = useCurrentChain();

	const columns = [
		{
			key: 'type',
			width: '28%',
			label: <Trans>Type</Trans>,
			align: 'left',
			format: (row: IAggregateClaimHistory) => {
				return (
					<Typography variant='body2'>{formatClaimType(row.type)}</Typography>
				);
			}
		},
		{
			key: 'unclaimed',
			width: '20%',
			label: (
				<Typography variant='body2'>{`${shortenSymbol(
					quoteToken
				)} ${t`Amount`}`}</Typography>
			),
			align: 'right',
			format: (row: any) => {
				return (
					<Typography variant='body2'>{`${formatNumber(
						row.feeAmount,
						quoteToken?.positionUnits
					)}`}</Typography>
				);
			}
		},
		{
			key: 'count',
			width: '26%',
			label: (
				<Typography variant='body2'>
					<Stronger
						value={
							<>
								{APP_TOKEN_SYMBOL}&nbsp;<Trans>Amount</Trans>
							</>
						}
						tooltip={
							<Trans>
								The {APP_TOKEN_SYMBOL} amount after the burn mechanism took
								effect is the sum of the burn amount and the stake/claim amount.
							</Trans>
						}
					/>
				</Typography>
			),
			align: 'right',
			format: (row: IAggregateClaimHistory) => {
				return (
					<Typography className='flex-1' variant='body2'>{`${formatNumber(
						row.amount,
						appToken?.positionUnits
					)}`}</Typography>
				);
			}
		},
		{
			key: 'hash',
			width: '26%',
			label: <Trans>Tx Hash</Trans>,
			align: isMatchMobile ? 'left' : 'right',
			format: (row: IAggregateClaimHistory) => {
				return (
					<StyledLink
						underline='always'
						target={isMatchMobile ? '_self' : '_blank'}
						href={getExplorerLink(
							currentChain,
							row.txHash,
							ExplorerDataType.TRANSACTION
						)}
						type='white'
					>
						{shortenHash(row.txHash)}
					</StyledLink>
				);
			}
		}
	];

	const mobileColumns = [
		{
			key: 'type',
			width: '10%',
			label: (
				<Typography variant='body2'>
					<Trans>Type</Trans>
				</Typography>
			),
			align: 'left',
			format: (row: IAggregateClaimHistory) => {
				return (
					<Typography variant='body2'>{formatClaimType(row.type)}</Typography>
				);
			}
		},
		{
			key: 'unclaimed',
			width: '45%',
			label: (
				<Typography variant='body2'>{`${shortenSymbol(
					quoteToken
				)} ${t`Amount`}`}</Typography>
			),
			align: 'right',
			format: (row: any) => {
				return (
					<Typography variant='body2'>{`${formatNumber(
						row.feeAmount,
						quoteToken?.positionUnits
					)}`}</Typography>
				);
			}
		},
		{
			key: 'count',
			width: '45%',
			label: (
				<Typography variant='body2'>
					<TippingUnderline
						tooltip={
							<Trans>
								The {APP_TOKEN_SYMBOL} amount after the burn mechanism took
								effect is the sum of the burn amount and the stake/claim amount.
							</Trans>
						}
						label={
							<>
								{APP_TOKEN_SYMBOL} <Trans>Amount</Trans>
							</>
						}
					/>
				</Typography>
			),
			align: 'right',
			format: (row: IAggregateClaimHistory) => {
				return (
					<Typography className='flex-1' variant='body2'>{`${formatNumber(
						row.amount,
						appToken?.positionUnits
					)}`}</Typography>
				);
			}
		}
	];

	return (
		<Dialog
			open
			width={isMatchPc ? 600 : '100%'}
			height={isMatchMobile ? 300 : 'auto'}
			onClose={onClose}
			title={t`Claim History`}
		>
			{loading ? (
				<SectionLoading />
			) : claimHistories.length > 0 ? (
				<div className='-mx-4 sm:mx-0 sm:pb-8'>
					{isMatchMobile ? (
						<Virtuoso
							endReached={fetchAction}
							style={{ height: 400 }}
							data={claimHistories}
							itemContent={(columnIndex, item) => {
								return (
									<div>
										{columnIndex === 0 && (
											<div className='grid grid-cols-3 gap-4'>
												{mobileColumns.map(
													(column: any, columnIndex: number) => (
														<Typography
															key={columnIndex}
															component='div'
															align={column.align}
															className='capitalize'
															color='secondary'
															variant='inherit'
															noWrap
														>
															{column.label}
														</Typography>
													)
												)}
											</div>
										)}
										<div
											className={classNames(
												`grid grid-cols-${
													mobileColumns.length > 2 ? 3 : 2
												} gap-4 gap-y-3 mt-3`
											)}
										>
											{mobileColumns.map((column: any, columnIndex: number) => (
												<DirectionCell
													key={columnIndex}
													label=''
													align={
														column.align
															? column.align
															: columnIndex && (columnIndex + 1) % 3 === 0
															? 'right'
															: 'left'
													}
													value={column.format(item)}
												/>
											))}
										</div>
									</div>
								);
							}}
						/>
					) : (
						<VirtualTable
							columns={columns}
							rows={claimHistories}
							itemHeight={44}
							cellColor={
								theme.mode === App_Theme.dark ? theme.custom.cardBg : ''
							}
							loadMore={fetchAction}
						/>
					)}
				</div>
			) : (
				<NoData size='large'>
					<Trans>No claim history</Trans>
				</NoData>
			)}
		</Dialog>
	);
}
