import React, { useCallback, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans, t } from '@lingui/macro';
import { Typography } from '@mui/material';
import {
	MAX_PRECISION,
	QUOTE_USD,
	Side,
	Transaction_Status,
	Transaction_Type,
	Version
} from 'config/constants';
import Decimal from 'decimal.js';
import { useAccount } from 'wagmi';

import { useOrdersStatusLazyGraph } from 'graphql/useMyOrdersGraph';
import { useOrdersStatusRequest } from 'fetch/useRequest';
import useLocalSnackbar from 'hooks/useLocalSnackbar';
import { useSubmitUpdateProfitLoss } from 'hooks/useSubmitUpdateProfitLoss';
import { useSubmitUpdateProfitLossV2 } from 'hooks/V2/useSubmitUpdateProfitLossV2';
import { useAppSelector } from 'state/hooks';
import { settingBaseState } from 'state/setting/slice';
import { selectVersion, selectOrderStatus } from 'state/setting/selector'
import {
	checkInputNumberic,
	div,
	getProfitLossTitle,
	isPositive,
	minus,
	multipliedBy,
	plus,
	toQuoteAmount
} from 'utils';

import Dialog from 'components/Common/Dialog';
import { CommonStyledFilledInput } from 'components/Common/Styled';

import { IOrderItemWithPosition } from '../Operations/OpenOrdersList';

export default React.memo(function UpdateProfitLossDialog({
	item,
	onClose
}: {
	item: IOrderItemWithPosition;
	onClose: () => void;
}) {
	const { slippage } = useAppSelector(settingBaseState);
	const Order_Status = useAppSelector(selectOrderStatus);
	const currentVersion = useAppSelector(selectVersion);
	const { fetch } = useOrdersStatusLazyGraph();
	const { trigger } = useOrdersStatusRequest(item?.orderV2Id)
	const { showSnackbar } = useLocalSnackbar();
	const { address } = useAccount();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const triggerMarketPrice = new Decimal(item.triggerMarketPrice).toFixed(
		item.baseToken.precision,
		Decimal.ROUND_HALF_CEIL
	);
	const [triggerPrice, setTriggerPrice] = useState<string>(triggerMarketPrice);

	const onChangeTriggerPrice: React.ChangeEventHandler<
		HTMLInputElement
	> = e => {
		const value = e.target.value;
		if (value.trim() === '') {
			setTriggerPrice('');
		}
		if (checkInputNumberic(value, item.baseToken.precision)) {
			setTriggerPrice(value);
		}
	};

	const acceptableTradePrice = useMemo(() => {
		if (!isPositive(triggerPrice)) {
			return '';
		}
		const currentPrice =
			triggerPrice === triggerMarketPrice
				? item.triggerMarketPrice
				: triggerPrice;
		const price =
			item.side === Side.LONG
				? multipliedBy(currentPrice, minus(1, div(slippage, 100)))
				: multipliedBy(currentPrice, plus(1, div(slippage, 100)));

		return price;
	}, [
		item.side,
		slippage,
		triggerPrice,
		triggerMarketPrice,
		item.triggerMarketPrice
	]);

	const { onConfirm:onConfirmV1, isConfirming:isConfirmingV1, isConfirmed:isConfirmedV1, error: errorV1} =
		useSubmitUpdateProfitLoss(
			item.poolId,
			item.transactionType,
			item.index,
			triggerPrice,
			item.side,
			item.baseToken,
			item.triggerAbove
		);

	const { onConfirm:onConfirmV2, isConfirming:isConfirmingV2, isConfirmed:isConfirmedV2, error:errorV2 } =
		useSubmitUpdateProfitLossV2(
			item.poolId,
			item.transactionType,
			item.index,
			triggerPrice,
			item.side,
			item.baseToken,
			item.triggerAbove
		);

	const disabled = useMemo(() => {
		if (!isPositive(triggerPrice)) {
			return true;
		}
		if (isLoading) {
			return true;
		}
		if (isConfirmingV1 || isConfirmingV2) {
			return true;
		}
		return false;
	}, [triggerPrice, isConfirmingV1, isConfirmingV2, isLoading]);

	const submitText = useMemo(() => {
		if (isConfirmingV1 || isConfirmingV2) {
			return <Trans>Submitting...</Trans>;
		}
		if (isLoading) {
			return <Trans>Loading...</Trans>;
		}
		return <Trans>Confirm</Trans>;
	}, [isConfirmingV1, isConfirmingV2, isLoading]);

	useUpdateEffect(() => {
		if (isConfirmedV1 || isConfirmedV2) {
			onClose();
		}
	}, [isConfirmedV1,isConfirmedV2]);

	useUpdateEffect(() => {
		if (errorV1 || errorV2) {
			setIsLoading(false);
		}
	}, [errorV1 || errorV2]);

	const fetchActionV1 = useCallback(() => {
		return fetch({
			variables: {
				account: address,
				id: item.id
			}
		});
	}, [fetch, address, item]);

	const fetchActionV2 = useCallback(() => {
		return trigger();
	}, [fetch]);

	const onHandleConfirmV1 = useCallback(() => {
		return fetchActionV1().then(res => {
			if (
				res.data &&
				res.data.orders.length > 0 &&
				res.data.orders[0].status === Order_Status.Created
			) {
				setIsLoading(true);
				onConfirmV1();
			} else {
				onClose();
				showSnackbar(
					t`Order has been executed`,
					undefined,
					Transaction_Status.Error
				);
			}
		});
	}, [fetch, address, item, onConfirmV1, setIsLoading, onClose, showSnackbar]);

	const onHandleConfirmV2 = useCallback(() => {
		return fetchActionV2().then(res => {
			if (
				res.data &&
				res.data?.status === Order_Status.Created && 
				res.data?.id === item?.orderV2Id
			) {
				setIsLoading(true);
				onConfirmV2();
			} else {
				onClose();
				showSnackbar(
					t`Order has been executed`,
					undefined,
					Transaction_Status.Error
				);
			}
		});
	}, [fetch, address, item, onConfirmV2, setIsLoading, onClose, showSnackbar]);

	const title = useMemo(() => {
		if (item.transactionType === Transaction_Type.CreateTakeProfitPosition || item.transactionType === Transaction_Type.CreateTakeProfitPositionV2) {
			return t`Modify Position TP`;
		} else if (
			item.transactionType === Transaction_Type.CreateStopLossPosition || item.transactionType === Transaction_Type.CreateStopLossPositionV2
		) {
			return t`Modify Position SL`;
		} else if (
			item.transactionType === Transaction_Type.CreateTakeProfitPartial || item.transactionType === Transaction_Type.CreateTakeProfitPartialV2
		) {
			return t`Modify Partial TP`;
		} else if (
			item.transactionType === Transaction_Type.CreateStopLossPartial || item.transactionType === Transaction_Type.CreateStopLossPartialV2
		) {
			return t`Modify Partial SL`;
		}
	}, [item.transactionType]);

	const isPositionTPorSL = item.transactionType === Transaction_Type.CreateTakeProfitPosition || 
							item.transactionType === Transaction_Type.CreateStopLossPosition ||
							item.transactionType === Transaction_Type.CreateTakeProfitPositionV2 ||
							item.transactionType === Transaction_Type.CreateStopLossPositionV2;

	return (
		<Dialog
			onConfirm={currentVersion  === Version.V1 ? onHandleConfirmV1 : onHandleConfirmV2}
			open
			onClose={onClose}
			title={title}
			disabled={disabled}
			confirmLabel={submitText}
		>
			<div className='mt-3 space-y-1'>
				<div className='flex justify-between'>
					<Typography variant='body2' color='secondary'>
						<Trans>Current trigger price</Trans>
					</Typography>
					<Typography variant='body2'>
						{toQuoteAmount(
							item.triggerMarketPrice,
							item.baseToken.precision,
							Decimal.ROUND_HALF_CEIL
						)}
					</Typography>
				</div>
			</div>
			<div className='mt-2'>
				<CommonStyledFilledInput
					type='text'
					inputProps={{
						maxLength: MAX_PRECISION,
						inputMode: 'decimal'
					}}
					className='input'
					value={triggerPrice}
					placeholder='0.0'
					onChange={onChangeTriggerPrice}
					fullWidth
					endAdornment={
						<Typography variant='body1' color='secondary'>
							{QUOTE_USD}
						</Typography>
					}
				/>
			</div>
			<div className='mt-2 space-y-1'>
				<div className='flex justify-between'>
					<Typography variant='body2' color='secondary'>
						<Trans>Acceptable Price</Trans>
					</Typography>
					<Typography variant='body2'>
						{acceptableTradePrice && !isPositionTPorSL ? (
							<span>{`${item.side === Side.LONG ? '≥' : '≤'}${toQuoteAmount(
								acceptableTradePrice,
								item.baseToken.precision,
								Decimal.ROUND_HALF_CEIL
							)}`}</span>
						) : (
							'-'
						)}
					</Typography>
				</div>
			</div>
			<div className='mt-4 space-y-1'>
				<Typography variant='body2' color='secondary'>
					{
						isPositionTPorSL ? <Trans>
						After modifying the trigger price, the system will execute the
						trigger based on the latest trigger price.
					</Trans> : <Trans>
						After modifying the trigger price, the system will execute the
						trigger based on the latest trigger price. Acceptable Price will be
						updated.
					</Trans>
					}
					
				</Typography>
			</div>
		</Dialog>
	);
});
