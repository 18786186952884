import { useMemo } from 'react';

import { useApolloClients } from 'context/ApolloClientsContext';
import { useAccount } from 'wagmi';

import { RewardsUtil } from 'entities/RewardsUtil';
import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import { catchFn, plus } from 'utils';

import { useReferralsRewardsQuery } from './__generated_referral__/types-and-hooks';
import { useRewardsConfigGraph } from './useRewardsConfigGraph';

export function useReferralsRewardsGraph(token: string[]) {
	const { clientReferral } = useApolloClients();
	const { blockTimestamp } = useAppSelector(globalBaseState);
	const { address } = useAccount();
	const { data: rewardsConfig } = useRewardsConfigGraph();
	const { data, loading, refetch } = useReferralsRewardsQuery({
		variables: {
			token: token
		},
		skip: !address && Boolean(token.length),
		client: clientReferral,
		notifyOnNetworkStatusChange: true
	});

	return useMemo(() => {
		if (!data || !data?.referralRewards || !data?.referralRewards?.length) {
			return {
				data: undefined,
				loading,
				refetch
			};
		}

		const rewardsList = [] as Array<any>;
		const rewardsMap = new Map();
		const EQURewardsArrayWithPool = [];
		const EQURewardsMap = new Map();

		data.referralRewards.forEach(rewardItem => {
			const { id } = rewardItem.referralToken;
			rewardsList.push({ ...rewardItem, id });
			const target = rewardsMap.get(rewardItem.poolId);
			if (!target) {
				rewardsMap.set(rewardItem.pool.id, rewardItem);
			}
		});

		for (let i = 0; i < rewardsList.length; i++) {
			const rewardItem = rewardsList[i];
			const { pool, referralToken } = rewardItem;
			const EQUReward = catchFn(() => {
				return RewardsUtil.calculateConnectorsRewardV1(
					rewardItem,
				);
			}, {
				id: referralToken.id,
				poolId: pool.id,
				rewardsEQU: rewardItem.liquidityUnclaimed
			});
			EQURewardsArrayWithPool.push(EQUReward);
		}
		EQURewardsArrayWithPool.forEach(item => {
			const { id, rewardsEQU, poolId } = item;
			if (EQURewardsMap.has(id)) {
				EQURewardsMap.get(id).rewardsEQU = plus(
					EQURewardsMap.get(id).rewardsEQU,
					rewardsEQU
				);
				EQURewardsMap.get(id).pools.push(poolId);
				EQURewardsMap.get(id).unclaimed = '0';
			} else {
				EQURewardsMap.set(id, {
					id,
					rewardsEQU,
					pools: [poolId],
					unclaimed: '0'
				});
			}
		});
		const EQURewardsArrayWithId = Array.from(EQURewardsMap.values());
		return {
			rewards: rewardsList,
			rewardsMap: rewardsMap,
			EQURewards: EQURewardsArrayWithId,
			loading,
			refetch
		};
	}, [data, refetch, loading]);
}
