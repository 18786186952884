import { createSelector } from '@reduxjs/toolkit';
import { Claim_Type } from 'config/constants';

import { isPositive, plus } from 'utils';

import {
	selectClaimableRBFAmountV3,
	selectPreviousClaimableRBFAmount
} from './RBF/selector';
import { selectClaimableArchitectAmount } from './architect/selector';
import {
	selectPreviousClaimableLiquidityAmount,
	selectclaimableLiquidityAmount
} from './liquidity/selector';
import { selectClaimableLpStakeQuoteAmount } from './pool2/selector';
import { earnPool2BaseState } from './pool2/slice';
import { selectClaimablePositionAmount } from './position/selector';
import { earnBaseState } from './slice';
import { selectClaimableStakeAmount } from './stake/selector';

export const selectTotalAlaimableAmount = createSelector(
	[
		selectClaimableStakeAmount,
		selectClaimablePositionAmount,
		selectClaimableLpStakeQuoteAmount,
		selectclaimableLiquidityAmount,
		selectClaimableRBFAmountV3,
		selectClaimableArchitectAmount,
		earnPool2BaseState
	],
	(
		claimableStakeAmount,
		claimablePositionAmount,
		claimableLpStakeQuoteAmount,
		claimableLiquidityAmount,
		claimableRBFAmount,
		claimableArchitectAmount,
		earnPool2BaseState
	) => {
		const { claimableAppAmount } = earnPool2BaseState;
		return [
			plus(
				claimableStakeAmount,
				plus(claimableLpStakeQuoteAmount, claimableArchitectAmount)
			),
			plus(
				plus(claimablePositionAmount, claimableAppAmount || 0),
				plus(claimableLiquidityAmount, claimableRBFAmount)
			)
		];
	}
);

// export const selectRefferalsClaimHistories = createSelector(
// 	[earnBaseState],
// 	state => {
// 		const { claimHistories } = state;
// 		if (!claimHistories) {
// 			return null;
// 		}
// 		return claimHistories.filter(
// 			item =>
// 				item.type === Claim_Type.Member || item.type === Claim_Type.Connector
// 		);
// 	}
// );

// export const selectReferralsClaimHistoriesByType = (type: string) =>
// 	createSelector([selectRefferalsClaimHistories], claimHistories => {
// 		if (!claimHistories || !type) {
// 			return null;
// 		}
// 		return claimHistories.filter(item => item.type === type);
// 	});

export const selectVisibleClaimPrevious = createSelector(
	[selectPreviousClaimableLiquidityAmount, selectPreviousClaimableRBFAmount],
	(previousClaimableLiquidityAmount, previousClaimableRBFAmount) => {
		return (
			isPositive(previousClaimableLiquidityAmount) ||
			isPositive(previousClaimableRBFAmount)
		);
	}
);
