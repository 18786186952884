import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { Trans, t } from '@lingui/macro';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import {
	DEFAULT_PAGE_SIZE,
	MAX_AMOUNT_FORMATTER_LIMIT,
	MAX_PAGE_SIZE, // Order_Open_Type,
	// Order_Status,
	Orders_Filter_Type,
	Page,
	QUOTE_USD,
	Side,
	Transaction_Type,
	Version
} from 'config/constants';
import Decimal from 'decimal.js';
import { useOrdersRequest } from 'fetch/useRequest';
import moment from 'moment';
import { useAccount } from 'wagmi';

import {
	formatMyOrderHistoryV1,
	formatMyOrderHistoryV2,
	useMyOrderHistoryLazyGraph
} from 'graphql/useMyOrderHistoryGraph';
import { IOrderItem } from 'graphql/useMyOrdersGraph';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import { filter, isEqual } from 'lodash';
import { useAppSelector } from 'state/hooks';
import { selectPoolIds } from 'state/pools/selector';
import {
	selectOrderOpenType,
	selectOrderStatus,
	selectVersion
} from 'state/setting/selector';
import {
	ExplorerDataType,
	amountFormatter,
	formatSize,
	formatUnixDate,
	getExplorerLink,
	getPrefixForLimit,
	getPrefixForProfitLoss,
	getProfitLossTitle,
	isPositive,
	shortenSymbolNative,
	toQuoteAmount
} from 'utils';

import { DirectionCell } from 'components/Common/Cell';
import NoData from 'components/NoData';
import SectionLoading from 'components/SectionLoading';

import VirtualTable from '../../../components/Table/VirtualTable';
import TippingUnderline from '../../../components/TippingUnderline';
import { MyOrderHistoryQuery } from '../../../graphql/__generated_trade__/types-and-hooks';
import { useCurrentPage } from '../../../hooks/useCurrentPage';

interface Column {
	id:
		| 'time'
		| 'market'
		| 'pool'
		| 'type'
		| 'side'
		| 'size'
		| 'payment'
		| 'triggerPrice'
		| 'marketPrice'
		| 'acceptablePrice'
		| 'status';
	label: string | React.ReactNode;
	minWidth?: number;
	align?: 'right' | 'left';
	format: (row: any) => void;
}

export default function OrderHistoryList({
	poolIds,
	full = true
}: {
	poolIds: string[];
	full?: boolean;
}) {
	const Order_Status = useAppSelector(selectOrderStatus);
	const Order_Open_Type = useAppSelector(selectOrderOpenType);
	const allPoolIds = useAppSelector(selectPoolIds);
	const currentVersion = useAppSelector(selectVersion);
	const { address } = useAccount();
	const theme = useTheme();
	const { currentChainId, currentChain: chain } = useCurrentChain();
	const isLogin = useCheckLogin();

	const [orderType, setOrderType] = useState<Orders_Filter_Type>(
		Orders_Filter_Type.All
	);
	const currentPage = useCurrentPage();
	const orderTypeArray = useMemo(() => {
		if (orderType === Orders_Filter_Type.All) {
			return [Order_Open_Type.Increase, Order_Open_Type.Decrease];
		} else if (orderType === Orders_Filter_Type.Limit) {
			return [Order_Open_Type.Increase];
		} else {
			return [Order_Open_Type.Decrease];
		}
	}, [orderType]);
	const { isMatchMobile, isMatchPad, isMatchPc } = useAppBreakpoints();

	const { fetch } = useMyOrderHistoryLazyGraph();
	const [from, setFrom] = useState<string | undefined>(undefined);
	const [data, setData] = useState<MyOrderHistoryQuery['orders']>([]);
	const [loading, setLoading] = useState(true);
	const [fetching, setFetching] = useState(false);
	const [hasMore, setHasMore] = useState(true);

	const ordersParams = useMemo(() => {
		return {
			account: address,
			market: isEqual(poolIds, allPoolIds) ? '' : poolIds[0],
			status: 'CLOSED',
			limit: DEFAULT_PAGE_SIZE,
			from: from,
			hashes: []
		};
	}, [address, poolIds, allPoolIds, from]);

	const { trigger } = useOrdersRequest(ordersParams);

	const fetchActionV1 = useCallback(
		(index: number) => {
			if (hasMore && !fetching && address) {
				setFetching(true);
				return fetch({
					variables: {
						account: address,
						poolId: poolIds,
						type: orderTypeArray,
						first: isMatchMobile ? MAX_PAGE_SIZE : DEFAULT_PAGE_SIZE,
						skip: index === 0 ? 0 : data.length
					}
				})
					.then(res => {
						setHasMore(res.data.orders.length === DEFAULT_PAGE_SIZE);
						setData(
							index === 0 ? res.data.orders : [...data, ...res.data.orders]
						);
					})
					.finally(() => setFetching(false));
			} else {
				return Promise.resolve();
			}
		},
		[address, poolIds, data, hasMore, orderTypeArray, isMatchMobile, fetching]
	);

	const fetchActionV2 = useCallback(
		(index: number) => {
			if (hasMore && !fetching && address) {
				setFetching(true);
				return trigger()
					.then(res => {
						setHasMore(res.data.hasMore);
						setData(
							index === 0 ? res.data.orders : [...data, ...res.data.orders]
						);
						setFrom(
							res.data.orders.length === 0
								? undefined
								: res.data.hasMore
								? res.data.next
								: from
						);
					})
					.finally(() => setFetching(false));
			} else {
				return Promise.resolve();
			}
		},
		[
			address,
			poolIds,
			data,
			hasMore,
			orderTypeArray,
			isMatchMobile,
			fetching,
			ordersParams
		]
	);

	useEffect(() => {
		setData([]);
		if (currentVersion === Version.V1) {
			fetchActionV1(0).finally(() => setLoading(false));
		}
		if (currentVersion === Version.V2) {
			fetchActionV2(0).finally(() => setLoading(false));
		}
	}, [currentVersion]);

	const myOrderHistories =
		currentVersion === Version.V1
			? formatMyOrderHistoryV1({ orders: data })
			: formatMyOrderHistoryV2({ orders: data });

	const columns: readonly Column[] = [
		{
			id: 'time',
			label: t`Execution Time`,
			minWidth: 80,
			format: row => {
				// eslint-disable-next-line no-case-declarations
				let formatOrderTime: string;
				switch (row.orderStatus) {
					case Order_Status.Created:
						formatOrderTime =
							currentVersion === Version.V1
								? formatUnixDate(row.createdTimestamp)
								: formatUnixDate(moment(row.createdTimestamp).unix());
						break;
					case Order_Status.Cancelled:
						formatOrderTime =
							currentVersion === Version.V1
								? formatUnixDate(row.cancelledTimestamp)
								: formatUnixDate(moment(row.cancelledTimestamp).unix());
						break;
					case Order_Status.Executed:
						formatOrderTime =
							currentVersion === Version.V1
								? formatUnixDate(row.executedTimestamp)
								: formatUnixDate(moment(row.executedTimestamp).unix());
						break;
				}
				return formatOrderTime;
			}
		},
		{
			id: 'market',
			label: t`Market`,
			minWidth: 80,
			format: row => (
				<Typography variant='body2' className='items-center flex'>
					{`${shortenSymbolNative(row.baseToken, currentChainId)}/${QUOTE_USD}`}
				</Typography>
			)
		},
		{
			id: 'type',
			label: t`Type`,
			minWidth: 100,
			format: (row: IOrderItem) => (
				<Typography className='items-center flex' variant='body2'>
					{row.type === Order_Open_Type.Increase ? (
						<Trans>Limit</Trans>
					) : (
						getProfitLossTitle(row.transactionType)
					)}
				</Typography>
			)
		},
		{
			id: 'side',
			label: t`Side`,
			minWidth: 80,
			format: row => (
				<Typography
					variant='body2'
					sx={{
						color:
							row.side === Side.LONG
								? theme.palette.success.main
								: theme.palette.error.main
					}}
				>
					{row.side === Side.LONG ? (
						<span>
							<Trans>Long</Trans>
						</span>
					) : (
						<span>
							<Trans>Short</Trans>
						</span>
					)}
				</Typography>
			)
		},
		{
			id: 'size',
			label: t`Size`,
			minWidth: 100,
			format: (row: IOrderItem) => (
				<Typography variant='body2'>
					{isPositive(row.sizeDelta) ? (
						`${amountFormatter(
							row.sizeDelta,
							2,
							row.baseToken?.positionUnits,
							MAX_AMOUNT_FORMATTER_LIMIT
						)} ${shortenSymbolNative(row.baseToken, currentChainId)}`
					) : (
						<Trans>Entire</Trans>
					)}
				</Typography>
			)
		},
		{
			id: 'triggerPrice',
			label: t`Trigger Price`,
			minWidth: 130,
			format: (row: IOrderItem) => {
				let prefix = '';
				if (row.type === Order_Open_Type.Increase) {
					prefix = getPrefixForLimit(row.triggerAbove);
				} else if (row.type === Order_Open_Type.Decrease) {
					prefix = getPrefixForProfitLoss(row.triggerAbove, row.side);
				}
				return (
					<Typography variant='body2'>
						{`${prefix}${toQuoteAmount(
							row.triggerMarketPrice,
							row.baseToken.precision,
							Decimal.ROUND_HALF_CEIL
						)}`}
					</Typography>
				);
			}
		},
		{
			id: 'acceptablePrice',
			label: t`Acceptable Price`,
			minWidth: 130,
			format: row => {
				const isPositionTPorSL =
					row.transactionType === Transaction_Type.CreateTakeProfitPosition ||
					row.transactionType === Transaction_Type.CreateStopLossPosition;
				return (
					<Typography variant='body2'>
						{row.acceptableTradePrice && !isPositionTPorSL ? (
							row.type === Order_Open_Type.Increase ? (
								row.side === Side.LONG ? (
									<span>{`≤${toQuoteAmount(
										row.acceptableTradePrice,
										row.baseToken.precision,
										Decimal.ROUND_HALF_CEIL
									)} `}</span>
								) : (
									<span>{`≥${toQuoteAmount(
										row.acceptableTradePrice,
										row.baseToken.precision,
										Decimal.ROUND_HALF_CEIL
									)} `}</span>
								)
							) : row.side === Side.LONG ? (
								<span>{`≥${toQuoteAmount(
									row.acceptableTradePrice,
									row.baseToken.precision,
									Decimal.ROUND_HALF_CEIL
								)} `}</span>
							) : (
								<span>{`≤${toQuoteAmount(
									row.acceptableTradePrice,
									row.baseToken.precision,
									Decimal.ROUND_HALF_CEIL
								)} `}</span>
							)
						) : (
							'-'
						)}
					</Typography>
				);
			}
		},
		{
			id: 'status',
			label: (
				<div
					className={classNames('flex items-center', {
						'justify-end': !(isMatchPad && currentPage === Page.Markets),
						'justify-start': isMatchPad && currentPage === Page.Markets
					})}
				>
					<TippingUnderline
						tooltip={
							<div>
								<div>
									<Trans>
										There are two situations when an order is cancelled by the
										system:
									</Trans>
								</div>
								<div>
									<Trans>
										1. Trigger price verification failed. When the order was
										triggered, operations made by other users resulted in the
										market price not meeting the trigger price you set,
										resulting in order cancellation.
									</Trans>
								</div>
								<Trans>
									2. Slippage is too high. The slippage in the actual execution
									exceeded the acceptable slippage range you set, resulting in
									order cancellation.
								</Trans>
							</div>
						}
						label={<Trans>Status</Trans>}
					/>
				</div>
			),
			minWidth: 100,
			align: isMatchPad && currentPage === Page.Markets ? 'left' : 'right',
			format: row => (
				<Typography
					color={isMatchMobile ? 'inherit' : 'textPrimary'}
					variant='body2'
				>
					{row.orderStatus === Order_Status.Created && 'Created'}
					{row.orderStatus === Order_Status.Executed && <Trans>Executed</Trans>}
					{row.orderStatus === Order_Status.Cancelled && (
						<Trans>Cancelled</Trans>
					)}
				</Typography>
			)
		}
	];

	const computedColumns = useMemo(() => {
		if (!full) {
			return filter(columns, item => {
				return item.id !== 'market' && item.id !== 'marketPrice';
			});
		}
		return columns;
	}, [columns, full]);

	const onRowClick = (row: any) => {
		const link = getExplorerLink(
			chain,
			row.txHash || '',
			ExplorerDataType.TRANSACTION
		);
		window.open(link);
	};

	const filteredLabel = ['market', 'time', 'type', 'side', 'status'];

	const filteredColumns = useMemo(() => {
		return isMatchMobile
			? computedColumns.filter(item => !filteredLabel.includes(item.id))
			: computedColumns;
	}, [isMatchMobile, computedColumns]);

	const columnItem = useCallback(
		(id: string) => {
			return computedColumns.find(item => item.id === id);
		},
		[computedColumns]
	);

	return (
		<article className='space-y-2'>
			{loading && <SectionLoading />}
			{!loading && (
				<Box>
					{isLogin && myOrderHistories?.length ? (
						<>
							{isMatchMobile && (
								<div className='mt-4'>
									<Virtuoso
										endReached={
											currentVersion === Version.V1
												? fetchActionV1
												: fetchActionV2
										}
										style={{ height: 380 }}
										data={myOrderHistories}
										itemContent={(index, row) => (
											<div>
												<div className='space-y-4'>
													<div className='flex items-center justify-between'>
														<div className=''>
															<div className='flex items-center space-x-2'>
																<Typography variant='h6'>
																	{`${shortenSymbolNative(
																		row.baseToken,
																		currentChainId
																	)}/${QUOTE_USD}`}
																</Typography>
																{columnItem('side')?.format(row)}
																<Box
																	sx={{
																		background: theme.custom.cardBg
																	}}
																	className='px-1 py-0.5 rounded-sm'
																>
																	{columnItem('type')?.format(row)}
																</Box>
															</div>
															<Typography
																variant='body2'
																color='text.secondary'
															>
																{columnItem('time')?.format(row)}
															</Typography>
														</div>
														<div>
															<DirectionCell
																label={columnItem('status').format(row)}
																align='right'
																value=''
															/>
														</div>
													</div>
													<div className='flex space-x-2'>
														{filteredColumns.map((column, columnIndex) => (
															<DirectionCell
																label={column.label}
																align={
																	columnIndex && columnIndex % 2 === 0
																		? 'right'
																		: 'left'
																}
																value={column.format(row)}
																key={columnIndex}
															/>
														))}
													</div>
												</div>
												<Divider
													className='my-3'
													sx={{ borderStyle: 'dashed' }}
												/>
											</div>
										)}
									/>
								</div>
							)}
							{isMatchPad && (
								<div className='mt-3'>
									<Virtuoso
										endReached={
											currentVersion === Version.V1
												? fetchActionV1
												: fetchActionV2
										}
										style={{ height: 400 }}
										className='pr-4'
										data={myOrderHistories}
										itemContent={(columnIndex, item) => {
											return (
												<div className={classNames({ 'pr-4': isMatchPad })}>
													<div
														className={classNames(
															`grid grid-cols-${
																filteredColumns.length > 2 ? 3 : 2
															} gap-4 gap-y-3`,
															isMatchPad
																? `grid-cols-${
																		filteredColumns.length > 2 ? 4 : 2
																  }`
																: `grid-cols-${
																		filteredColumns.length > 2 ? 3 : 2
																  }`
														)}
													>
														{filteredColumns.map(
															(column: any, columnIndex: number) => (
																<DirectionCell
																	key={columnIndex}
																	label={column.label}
																	align={
																		column.align
																			? column.align
																			: columnIndex &&
																			  (columnIndex + 1) %
																					(isMatchPad ? 4 : 3) ===
																					0
																			? 'right'
																			: 'left'
																	}
																	value={column.format(item)}
																/>
															)
														)}
													</div>
													<Divider className='my-4' />
												</div>
											);
										}}
									/>
								</div>
							)}
							{!isMatchPad && isMatchPc && (
								<div className='-mx-4'>
									<VirtualTable
										rows={myOrderHistories}
										columns={filteredColumns}
										itemHeight={44}
										cellColor={theme.custom.paperBg}
										loadMore={
											currentVersion === Version.V1
												? fetchActionV1
												: fetchActionV2
										}
										onRowClick={onRowClick}
										isPointer
									/>
								</div>
							)}
						</>
					) : (
						<NoData>
							<Trans>No order history</Trans>
						</NoData>
					)}
				</Box>
			)}
		</article>
	);
}
