interface SVGProps {
	size?: number;
}

function Warning({ size = 22 }: SVGProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox='0 0 22 22'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M11 19.8C15.84 19.8 19.8 15.84 19.8 11C19.8 6.16 15.84 2.2 11 2.2C6.16 2.2 2.2 6.16 2.2 11C2.2 15.84 6.16 19.8 11 19.8ZM11 0C17.05 0 22 4.95 22 11C22 17.05 17.05 22 11 22C4.95 22 0 17.05 0 11C0 4.95 4.95 0 11 0Z'
				fill='#FE730F'
			/>
			<circle cx='11' cy='15.293' r='1.56349' fill='#FE730F' />
			<line
				x1='11.053'
				y1='5.81567'
				x2='11.053'
				y2='11.3972'
				stroke='#FE730F'
				strokeWidth='2.0625'
				strokeLinecap='round'
			/>
		</svg>
	);
}

export default Warning;
