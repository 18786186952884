import { useCallback, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Hash } from '@wagmi/core';
import { NonfungiblePositionManagerABI } from 'config/abis';
import { DEFAULT_PRECISION } from 'config/constants';
import { UNI_WRAPPED_NATIVE_CURRENCY } from 'config/constants/tokens';
import { getContractAddress } from 'config/contracts';
import { useAccount } from 'wagmi';

import { ethers } from 'ethers';
import { useAllTokens } from 'hooks/useAllTokens';
import useWeb3Provider from 'hooks/useWeb3Provider';
import { orderBy } from 'lodash';

import {
	createMulticall,
	div,
	formatUnits,
	isEmptyAmount,
	isZero,
	sortsEqual
} from '../../utils';
import { useCheckLogin, useCurrentChain } from '../useCurrentChain';
import { TickMath } from './TickMath';
import { Fee_Amount, UniLiquidityToken } from './types';
import { computePoolAddress } from './utils';

export function useMyUniPositionIds() {
	const { currentChainId } = useCurrentChain();
	const isLogin = useCheckLogin();
	const Web3Provider = useWeb3Provider();
	const { address } = useAccount();
	const { multicallv3 } = createMulticall(Web3Provider);

	const [myUniPositionIds, setMyUniPositionIds] =
		useState<Array<string> | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const contract = useMemo(() => {
		if (!Web3Provider || !isLogin) {
			return null;
		}
		const addressOrName = getContractAddress(
			currentChainId,
			'NonfungiblePositionManager'
		);
		return new ethers.Contract(
			addressOrName,
			NonfungiblePositionManagerABI,
			Web3Provider
		);
	}, [isLogin, Web3Provider, currentChainId]);

	const refetchUniPositionIds = useCallback(async () => {
		if (!contract || !isLogin) {
			return;
		}
		if (import.meta.env.MODE === 'development') {
			return;
		}
		const repBalance = await contract.balanceOf(address);
		const _balance = repBalance.toNumber();

		if (isZero(_balance)) {
			setMyUniPositionIds(null);
			setIsLoading(false);
			return;
		}

		const tokenOfOwnerByIndexContracts = Array.from(
			{ length: repBalance.toNumber() },
			(_, i) => {
				return {
					address: getContractAddress(
						currentChainId,
						'NonfungiblePositionManager'
					) as Hash,
					abi: NonfungiblePositionManagerABI,
					name: 'tokenOfOwnerByIndex',
					params: [address, i]
				};
			}
		);

		const myPositionIds = [] as Array<string>;

		try {
			const rep = await multicallv3({
				calls: tokenOfOwnerByIndexContracts,
				chainId: currentChainId
			});

			if (rep) {
				const _filteredList = rep.filter(
					item => item !== null && item !== undefined
				);
				setIsLoading(false);
				_filteredList.forEach(positionId => {
					myPositionIds.push(positionId.toString());
				});
				setMyUniPositionIds(myPositionIds.sort().reverse());
			}
		} catch (error) {
			console.error(error);
		}
	}, [contract, address, isLogin, currentChainId]);

	useUpdateEffect(() => {
		refetchUniPositionIds();
	}, [Web3Provider]);

	return {
		isLoadingMyUniPositionIds: isLoading,
		myUniPositionIds,
		refetchUniPositionIds
	};
}

export function useUniV3PositionsByTokenIds(tokenIds: Array<string> | null) {
	const isLogin = useCheckLogin();
	const { currentChainId } = useCurrentChain();
	const Web3Provider = useWeb3Provider();
	const { multicallv3 } = createMulticall(Web3Provider);

	const allTokens = useAllTokens();

	const [loading, setLoading] = useState<boolean>(true);
	const [positions, setPositions] = useState<Array<UniLiquidityToken> | null>(
		null
	);
	const [positionMap, setPositionMap] = useState<Map<
		string,
		UniLiquidityToken
	> | null>(null);

	const [factoryAddress, WETH] = useMemo(() => {
		return [
			getContractAddress(currentChainId, 'UniPoolFactory'),
			UNI_WRAPPED_NATIVE_CURRENCY[currentChainId]
		];
	}, [currentChainId, UNI_WRAPPED_NATIVE_CURRENCY]);

	const positionsContracts = useMemo(() => {
		if (!isLogin || !tokenIds || tokenIds.length === 0) {
			return undefined;
		}
		return tokenIds.map((tokenId: string) => {
			return {
				address: getContractAddress(
					currentChainId,
					'NonfungiblePositionManager'
				),
				abi: NonfungiblePositionManagerABI,
				name: 'positions',
				params: [tokenId]
			};
		});
	}, [currentChainId, tokenIds, isLogin]);

	const fetchContractData = useCallback(async () => {
		if (positionsContracts && positionsContracts.length > 0) {
			try {
				const rep = await multicallv3({
					calls: positionsContracts,
					chainId: currentChainId
				});

				setLoading(false);
				const positions: Array<UniLiquidityToken> = [];
				const currentPositionMap = new Map<string, UniLiquidityToken>();
				const _filteredList = rep.filter(
					item => item !== null && item !== undefined
				);
				_filteredList.forEach((item, index) => {
					const _fee = item['fee'] as Fee_Amount;
					const _liquidity = formatUnits(
						item['liquidity'].toString(),
						DEFAULT_PRECISION
					);
					const [token0, token1] = [
						allTokens.get(item['token0'].toLowerCase()),
						allTokens.get(item['token1'].toLowerCase())
					];
					if (!token0 || !token1) {
						return;
					}
					const _tickLower = item['tickLower'];
					const _tickUppper = item['tickUpper'];
					if (
						TickMath.isLowestTick(_tickLower, _fee) &&
						TickMath.isHighestTick(_tickUppper, _fee)
					) {
						const poolAddress = computePoolAddress({
							factoryAddress,
							tokenA: token0,
							tokenB: token1,
							fee: _fee
						});
						const isPoolToBase = sortsEqual(token1, WETH);
						const [baseToken, quoteToken] = isPoolToBase
							? [token0, token1]
							: [token1, token0];
						const _priceLower = TickMath.getFormattedPriceByTick(
							_tickLower,
							_fee
						);
						const _priceUpper = TickMath.getFormattedPriceByTick(
							_tickUppper,
							_fee
						);
						const [priceLower, priceUpper] = !isPoolToBase
							? [_priceLower, _priceUpper]
							: [
									_priceUpper === TickMath.MAX_TICK_VALUE
										? TickMath.MIN_TICK_VALUE
										: div(1, _priceUpper),
									_priceLower === TickMath.MIN_TICK_VALUE
										? TickMath.MAX_TICK_VALUE
										: div(1, _priceLower)
							  ];
						const closed = isEmptyAmount(_liquidity);
						const liquidityToken = {
							id: tokenIds[index],
							poolAddress,
							liquidity: _liquidity,
							fee: _fee,
							baseToken,
							quoteToken,
							token0,
							token1,
							tickLower: _tickLower,
							tickUpper: _tickUppper,
							priceLower,
							priceUpper,
							basePriceLower: _priceLower,
							basePriceUpper: _priceUpper,
							closed
						} as UniLiquidityToken;
						currentPositionMap.set(tokenIds[index], liquidityToken);
						positions.push(liquidityToken);
					}
				});

				const sortedPositions = orderBy(
					positions,
					(item: UniLiquidityToken) => {
						return parseFloat(item.id);
					},
					['desc']
				);

				setPositions(sortedPositions);
				if (positionMap) {
					setPositionMap(
						new Map<string, UniLiquidityToken>([
							...currentPositionMap,
							...positionMap
						])
					);
				} else {
					setPositionMap(currentPositionMap);
				}
			} catch (error) {
				console.error(error);
			}
		}
	}, [positionsContracts, factoryAddress, WETH, positionMap]);

	return {
		loading,
		uniV3Positions: positions,
		uniV3PositionMap: positionMap,
		refetchV3Positions: fetchContractData
	};
}
