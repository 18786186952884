import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import { Dom_Size } from 'config/constants';

const EmptyClaim = () => {
	return (
		<Typography
			component='div'
			className={`flex justify-center items-center`}
			color='text.secondary'
			sx={{
				minHeight: Dom_Size.CLAIM_SECTION_LOADING
			}}
		>
			<Trans>No available rewards to claim</Trans>
		</Typography>
	);
};

export default EmptyClaim;
