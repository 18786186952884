import { useEffect, useMemo, useState } from 'react';

import { Box, useTheme } from '@mui/material';

import EditIcon from 'components/Svg/Icons/Edit';

import { Transaction_Type } from '../config/constants';
import AcceptableEntryPriceSettingDialog from '../pages/components/AcceptableEntryPriceSetting/AcceptableEntryPriceSettingDialog';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import {
	setAcceptableTradePriceForLong,
	setAcceptableTradePriceForShort
} from '../state/limit/slice';
import { settingBaseState } from '../state/setting/slice';
import { txBaseState } from '../state/tx/slice';
import { isPositive } from '../utils';
import { usePriceRangesByTolerance } from './useSlippageTolerance';

const useAcceptableEntryPrice = (price: string | undefined) => {
	const dispatch = useAppDispatch();
	const [dialogIsOpen, setDialogIsOpen] = useState(false);
	const { slippage } = useAppSelector(settingBaseState);
	const { transactionType } = useAppSelector(txBaseState);
	const { minPrice, maxPrice } = usePriceRangesByTolerance(price, slippage);

	const [acceptableEntryPrice, setAcceptableEntryPrice] = useState<string>('');

	useEffect(() => {
		if (!isPositive(price)) {
			dispatch(setAcceptableTradePriceForLong(''));
			dispatch(setAcceptableTradePriceForShort(''));
			setAcceptableEntryPrice('');
			return;
		}
		if (
			transactionType === Transaction_Type.Long ||
			transactionType === Transaction_Type.LongLimitOrder || 
			transactionType === Transaction_Type.LongV2 ||
			transactionType === Transaction_Type.LongLimitOrderV2
		) {
			dispatch(setAcceptableTradePriceForLong(maxPrice));
			setAcceptableEntryPrice(maxPrice);
		} else if (
			transactionType === Transaction_Type.Short ||
			transactionType === Transaction_Type.ShortLimitOrder || 
			transactionType === Transaction_Type.ShortV2 ||
			transactionType === Transaction_Type.ShortLimitOrderV2
		) {
			dispatch(setAcceptableTradePriceForShort(minPrice));
			setAcceptableEntryPrice(minPrice);
		}
	}, [slippage, price, minPrice, maxPrice]);

	const onOpenDialog = () => {
		setDialogIsOpen(true);
	};

	const onCloseDialog = () => {
		setDialogIsOpen(false);
	};
	const theme = useTheme();

	const Component = (
		<>
			<Box
				sx={{
					background: 'none'
				}}
				className='cursor-pointer'
				onClick={() => onOpenDialog()}
			>
				<EditIcon />
			</Box>
			{dialogIsOpen && (
				<AcceptableEntryPriceSettingDialog onClose={onCloseDialog} />
			)}
		</>
	);

	return { Component, acceptableEntryPrice };
};

export default useAcceptableEntryPrice;
