import { useEffect, useState } from 'react';

import { Trans } from '@lingui/macro';
import { Box } from '@mui/material';
import { Transaction_Type } from 'config/constants';

import { usePriceRangesByTolerance } from 'hooks/useSlippageTolerance';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
	setAcceptableTradePriceForLong,
	setAcceptableTradePriceForShort
} from 'state/limit/slice';
import { poolsBaseState } from 'state/pools/slice';
import { settingBaseState } from 'state/setting/slice';
import { txBaseState } from 'state/tx/slice';
import { isNumeric, isPositive, toPercent, toQuoteAmount } from 'utils';

import Cell from 'components/Common/Cell';
import EditIcon from 'components/Svg/Icons/Edit';

import AcceptableEntryPriceSettingDialog from './AcceptableEntryPriceSettingDialog';

export default function AcceptableEntryPriceSetting({
	price
}: {
	price: string;
}) {
	const dispatch = useAppDispatch();
	const { poolInfo } = useAppSelector(poolsBaseState);

	const [dialogIsOpen, setDialogIsOpen] = useState(false);
	const [acceptableEntryPrice, setAcceptableEntryPrice] = useState<string>('');
	const { slippage } = useAppSelector(settingBaseState);
	const { transactionType } = useAppSelector(txBaseState);
	const { minPrice, maxPrice } = usePriceRangesByTolerance(price, slippage);

	useEffect(() => {
		if (!isPositive(price)) {
			dispatch(setAcceptableTradePriceForLong(''));
			dispatch(setAcceptableTradePriceForShort(''));
			setAcceptableEntryPrice('');
			return;
		}
		if (
			transactionType === Transaction_Type.Long ||
			transactionType === Transaction_Type.LongLimitOrder ||
			transactionType === Transaction_Type.LongV2 ||
			transactionType === Transaction_Type.LongLimitOrderV2
		) {
			dispatch(setAcceptableTradePriceForLong(maxPrice));
			setAcceptableEntryPrice(maxPrice);
		}
		if (
			transactionType === Transaction_Type.Short ||
			transactionType === Transaction_Type.ShortLimitOrder ||
			transactionType === Transaction_Type.ShortV2 ||
			transactionType === Transaction_Type.ShortLimitOrderV2
		) {
			dispatch(setAcceptableTradePriceForShort(minPrice));
			setAcceptableEntryPrice(minPrice);
		}
	}, [slippage, price, minPrice, maxPrice]);

	const onOpenDialog = () => {
		setDialogIsOpen(true);
	};

	const onCloseDialog = () => {
		setDialogIsOpen(false);
	};

	return (
		<>
			<Cell
				label={
					<div className='flex items-center space-x-1'>
						<Trans>Acceptable Price</Trans>
					</div>
				}
				value={
					isPositive(acceptableEntryPrice) ? (
						<div className='flex items-center space-x-1'>
							{isNumeric(acceptableEntryPrice) ? (
								<>
									{(transactionType === Transaction_Type.Long ||
										transactionType === Transaction_Type.LongLimitOrder ||
										transactionType === Transaction_Type.LongV2 ||
										transactionType === Transaction_Type.LongLimitOrderV2) && (
										<span>{`≤${toQuoteAmount(
											acceptableEntryPrice,
											poolInfo?.baseToken?.precision
										)}`}</span>
									)}
									{(transactionType === Transaction_Type.Short ||
										transactionType === Transaction_Type.ShortLimitOrder ||
										transactionType === Transaction_Type.ShortV2 ||
										transactionType === Transaction_Type.ShortLimitOrderV2) && (
										<span>{`≥${toQuoteAmount(
											acceptableEntryPrice,
											poolInfo?.baseToken?.precision
										)}`}</span>
									)}
								</>
							) : (
								<span>{`${toQuoteAmount(0)}`}</span>
							)}
							<span>{`(${toPercent(slippage, 2, false)})`}</span>
							<Box className='cursor-pointer' onClick={() => onOpenDialog()}>
								<EditIcon />
							</Box>
						</div>
					) : (
						<div className='flex items-center space-x-1'>
							<span>-</span>
							<span>{`(${toPercent(slippage, 2, false)})`}</span>
							<Box className='cursor-pointer' onClick={() => onOpenDialog()}>
								<EditIcon />
							</Box>
						</div>
					)
				}
			/>
			{dialogIsOpen && (
				<AcceptableEntryPriceSettingDialog onClose={onCloseDialog} />
			)}
		</>
	);
}
