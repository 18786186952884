import { Reducer, createSlice } from '@reduxjs/toolkit';
import { IStakeStatistic } from 'graphql/useStakeStatisticGraph';
import { UniLiquidityToken, UniLiquidityTokenRender } from 'hooks/V3/types';

import { RootState } from 'state';
import { IStakeDetail } from 'types';

interface StateProps {
	stakeStatistic: IStakeStatistic | null;

	isLoadingStakeList: boolean;
	stakeList: Array<IStakeDetail> | null;
	lpStakeList: Array<IStakeDetail> | null;
	myUniPositions: Array<UniLiquidityTokenRender> | null;
	nativeUsdPrice: string | undefined;
	visibleStakeLpDialog: boolean;
	uniV3PositionMap: Map<string, UniLiquidityToken> | null;
	maxMultiplier: number | undefined;
}

const initialState: StateProps = {
	stakeStatistic: null,
	isLoadingStakeList: true,
	stakeList: null,
	lpStakeList: null,
	myUniPositions: null,
	nativeUsdPrice: undefined,
	visibleStakeLpDialog: false,
	uniV3PositionMap: null,
	maxMultiplier: undefined,
};

export const slice = createSlice({
	name: 'stake',
	initialState,
	reducers: {
		setStakeStatistic(state, { payload }) {
			state.stakeStatistic = payload;
		},
		setStakeList(state, { payload }) {
			state.stakeList = payload;
		},
		setIsLoadingStakeList(state, { payload }) {
			state.isLoadingStakeList = payload;
		},
		setLpStakeList(state, { payload }) {
			state.lpStakeList = payload;
		},
		setMyUniPositions(state, { payload }) {
			state.myUniPositions = payload;
		},
		setUniV3PositionMap(state, { payload }) {
			state.uniV3PositionMap = payload;
		},
		setNativeUsdPrice(state, { payload }) {
			state.nativeUsdPrice = payload;
		},
		setVisibleStakeLpDialog(state, { payload }) {
			state.visibleStakeLpDialog = payload;
		},
		setMaxMultiplier(state, { payload }) {
			state.maxMultiplier = payload;
		}
	}
});

export const {
	setStakeStatistic,
	setIsLoadingStakeList,
	setStakeList,
	setLpStakeList,
	setMyUniPositions,
	setUniV3PositionMap,
	setNativeUsdPrice,
	setVisibleStakeLpDialog,
	setMaxMultiplier
} = slice.actions;

export const earnStakeBaseState = (state: RootState) => state.earnStake;

export default slice.reducer as Reducer<typeof initialState>;
