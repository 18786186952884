import { useMemo } from 'react';

import { QUOTE_USD } from 'config/constants';
import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { selectPositionMinExecutionFee } from 'state/global/selector';
import { RecordForAdjustRiskBufferFund, TokenInfo } from 'types';
import {
	formatNumber,
	isPositive,
	parseUnits,
	shortenSymbolNative,
	toDecimalPlaces
} from 'utils';

import { positionRouterABI } from '../config/abis';
import { Contract_Write_Mode, Transaction_Type } from '../config/constants';
import { getContractAddress } from '../config/contracts';
import { useAppSelector } from '../state/hooks';
import { txBaseState } from '../state/tx/slice';
import { useComputeGasLimit } from './useComputeGasLimit';
import { useCurrentChain } from './useCurrentChain';
import { useSendTransaction } from './useSendTransaction';

const useSubmitRiskBufferFund = (
	poolAddress: string,
	quoteAmount: string,
	transactionType: Transaction_Type,
	baseToken: TokenInfo | null
) => {
	const { quoteToken } = useAppSelector(txBaseState);
	const positionMinExecutionFee = useAppSelector(selectPositionMinExecutionFee);
	const { currentChainId } = useCurrentChain();

	const { address } = useAccount();
	const { args, record, description } = useMemo(() => {
		if (!baseToken || !quoteToken || !isPositive(quoteAmount)) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		console.log('useSubmitAdjustRiskBufferFund begin ...');

		const _marginDelta = toDecimalPlaces(quoteAmount, quoteToken.decimals);
		const marginDelta = parseUnits(_marginDelta, quoteToken.decimals);
		const args =
			transactionType === Transaction_Type.IncreaseRiskBufferFund
				? [poolAddress, marginDelta]
				: [poolAddress, marginDelta, address];

		const record = {
			transactionType,
			pool: poolAddress,
			baseToken
		} as RecordForAdjustRiskBufferFund;

		const description = TransactionDescription.AdjustRiskBufferFund(
			`${shortenSymbolNative(baseToken, currentChainId)}/${QUOTE_USD}`,
			formatNumber(quoteAmount),
			transactionType === Transaction_Type.IncreaseRiskBufferFund
		);

		return {
			args,
			record,
			description
		};
	}, [
		transactionType,
		quoteToken,
		poolAddress,
		quoteAmount,
		transactionType,
		currentChainId
	]);
	const overrides = useMemo(() => {
		if (!address || !isPositive(positionMinExecutionFee)) {
			return undefined;
		}
		return {
			from: address,
			value: positionMinExecutionFee
		};
	}, [address, positionMinExecutionFee]);
	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address: getContractAddress(currentChainId, 'PositionRouter'),
			abi: positionRouterABI,
			functionName:
				transactionType === Transaction_Type.IncreaseRiskBufferFund
					? 'createIncreaseRiskBufferFundPosition'
					: 'createDecreaseRiskBufferFundPosition',
			args,
			overrides
		} as UseContractWriteConfig;
	}, [currentChainId, args]);
	const { gasLimit } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const {
		onSendTransaction,
		setTxSuccessCallback,
		isConfirmed,
		isConfirming,
		error
	} = useSendTransaction(contractWriteArgs, gasLimit);

	const onConfirm = () => {
		if (!record) return;
		onSendTransaction(record, description);
	};

	return { onConfirm, setTxSuccessCallback, isConfirmed, isConfirming, error };
};

export default useSubmitRiskBufferFund;
