import { Reducer, createSlice } from '@reduxjs/toolkit';
import { Input_Direction } from 'config/constants';

import { RootState } from 'state';

interface StateProps {
	quoteAmount: string;
	leverage: number;
	liquidityAmount: string;
	direction: Input_Direction;
	isPureAdd: boolean;
	isTips: boolean;
	dialogIsVisible: boolean;
}

const initialState: StateProps = {
	quoteAmount: '',
	leverage: 10,
	liquidityAmount: '',
	direction: Input_Direction.In,
	isPureAdd: false,
	isTips: false,
	dialogIsVisible: false
};

export const slice = createSlice({
	name: 'liquidity',
	initialState,
	reducers: {
		setQuoteAmount(state, { payload }) {
			state.quoteAmount = payload;
		},
		setLeverage(state, { payload }) {
			state.leverage = payload;
		},
		setLiquidityAmount(state, { payload }) {
			state.liquidityAmount = payload;
		},
		setDirection(state, { payload }) {
			state.direction = payload;
		},
		setIsPureAdd(state, { payload }) {
			state.isPureAdd = payload;
		},
		setIsTips(state, { payload }) {
			state.isTips = payload;
		},
		setDialogIsVisible(state, { payload }) {
			state.dialogIsVisible = payload;
		},
		reset(state) {
			state.quoteAmount = '';
			state.liquidityAmount = '';
			state.isTips = false;
		}
	}
});

export const {
	setQuoteAmount,
	setLeverage,
	setLiquidityAmount,
	setDirection,
	setIsPureAdd,
	setIsTips,
	setDialogIsVisible,
	reset
} = slice.actions;

export const liquidityBaseState = (state: RootState) => state.liquidity;

export default slice.reducer as Reducer<typeof initialState>;
