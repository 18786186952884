import React, { useEffect, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import cn from 'classnames';
import { Page, Version } from 'config/constants';

import { useCheckLogin } from 'hooks/useCurrentChain';
import { selectHasArchitect } from 'state/earn/architect/selector';
import { useAppSelector } from 'state/hooks';
import { tradeBaseState } from 'state/trade/slice';

import { CommonStyledOutlinedButton } from 'components/Common/Styled';
import StyledTab from 'components/Common/StyledTab';
import StyledTabs from 'components/Common/StyledTabs';
import Stronger from 'components/Stronger';

import { useAppBreakpoints } from '../../hooks/useAppBreakpoints';
import V2Logo from '../components/V2/V2Logo';
import Architect from './Architect';
import Liquidity from './Liquidity';
import Pool2Mining from './Pool2Mining';
import Position from './Position';
import RBFMining from './RBFMining';
import Staking from './Stake';
import { Type_Earns } from './types';

export default React.memo(function Content({
	isClaimLoading,
	claimDisabled,
	onClaim
}: {
	isClaimLoading: boolean;
	claimDisabled: boolean;
	onClaim: () => void;
}) {
	const { isBindReferralCode } = useAppSelector(tradeBaseState);
	const hasArchitect = useAppSelector(selectHasArchitect);
	const currentVersion = useAppSelector(state => state.setting.currentVersion);
	const hasRbf = useMemo(() => {
		if (currentVersion === Version.V1) {
			return true;
		} else {
			return false;
		}
	}, [currentVersion]);

	const { type: routerType } = useParams();
	const navigate = useNavigate();
	const isLogin = useCheckLogin();

	const activeTab = useMemo(() => {
		if (routerType === Type_Earns.Liquidity) {
			return Type_Earns.Liquidity;
		} else if (routerType === Type_Earns.Position) {
			return Type_Earns.Position;
		} else if (routerType === Type_Earns.RBF) {
			return Type_Earns.RBF;
		} else if (routerType === Type_Earns.Architect) {
			return Type_Earns.Architect;
		} else if (routerType === Type_Earns.Pool2) {
			return Type_Earns.Pool2;
		} else {
			return Type_Earns.Staking;
		}
	}, [routerType, hasArchitect, hasRbf]);

	useEffect(() => {
		if (!hasArchitect && activeTab === Type_Earns.Architect) {
			navigate(
				`${currentVersion === Version.V1 ? Page.EarnV1 : Page.Earn}/${
					Type_Earns.Staking
				}`
			);
		}
	}, [hasArchitect, activeTab]);

	useEffect(() => {
		if (!hasRbf && activeTab === Type_Earns.RBF) {
			navigate(
				`${currentVersion === Version.V1 ? Page.EarnV1 : Page.Earn}/${
					Type_Earns.Staking
				}`
			);
		}
	}, [hasRbf, activeTab]);

	const { isMatchMobile, isMatchPad, isMatchPc } = useAppBreakpoints();
	const theme = useTheme();

	return (
		<Paper
			sx={{
				background:
					isMatchMobile || isMatchPad
						? 'transparent'
						: theme.palette.background.paper
			}}
		>
			<div className='px-4 flex items-center justify-between underLg:px-3 underLg:block underLg:items-start'>
				<StyledTabs
					value={activeTab}
					onChange={(e, value) =>
						navigate(
							`${
								currentVersion === Version.V1 ? Page.EarnV1 : Page.Earn
							}/${value}`
						)
					}
					size='large'
					className='my-4'
					variant='scrollable'
					scrollButtons={isMatchMobile ? 'auto' : false}
					allowScrollButtonsMobile
					sx={{
						'& [aria-selected=false]': {
							fontWeight: 400
						}
					}}
				>
					<StyledTab
						className='pl-0'
						label={<Trans>Staking</Trans>}
						value={Type_Earns.Staking}
						path={'earn'}
					/>
					<StyledTab
						label={<Trans>Pool2 Mining</Trans>}
						value={Type_Earns.Pool2}
						path={'earn'}
						className='pr-1'
					/>
					<StyledTab
						label={
							<div className=''>
								<Trans>Position Mining</Trans>
								{currentVersion === Version.V2 && (
									<Badge
										badgeContent={
											<span className='relative'>
												<V2Logo className=' scale-75' />
											</span>
										}
										className='relative -ml-2'
										sx={{
											position: 'relative',
											'.MuiBadge-badge': {
												position: 'relative',
												backgroundColor: 'inherit',
												// borderRadius: '2px',
												height: '16px',
												left: '-4px',
												padding: `0px`,
												top: '2px',
												display: 'flex',
												itemsAlign: 'center'
											}
										}}
									/>
								)}
							</div>
						}
						value={Type_Earns.Position}
						path={'earn'}
					/>
					<StyledTab
						// label={<Trans>Liquidity Mining</Trans>}
						label={
							<div className=''>
								<Trans>Liquidity Mining</Trans>
								{currentVersion === Version.V2 && (
									<Badge
										badgeContent={
											<span className='relative'>
												<V2Logo className=' scale-75' />
											</span>
										}
										className='relative -ml-2'
										sx={{
											position: 'relative',
											'.MuiBadge-badge': {
												position: 'relative',
												backgroundColor: 'inherit',
												// borderRadius: '2px',
												height: '16px',
												left: '-4px',
												padding: `0px`,
												top: '2px',
												display: 'flex',
												itemsAlign: 'center'
											}
										}}
									/>
								)}
							</div>
						}
						value={Type_Earns.Liquidity}
						path={'earn'}
					/>
					<StyledTab
						label={<Trans>RBF Mining</Trans>}
						value={Type_Earns.RBF}
						path={'earn'}
						className={`${hasRbf ? 'visible' : 'hidden'}`}
					/>
					<StyledTab
						label='EFC Architects'
						value={Type_Earns.Architect}
						className={`${isLogin && hasArchitect ? 'visible' : 'hidden'}`}
						path={'earn'}
					/>
				</StyledTabs>
				<Paper
					className={cn(
						'flex underLg:block items-center space-x-2 underLg:mb-2 underLg:-mx-3',
						(isMatchMobile || isMatchPad) &&
							(activeTab !== Type_Earns.Liquidity || !isLogin)
							? 'hidden'
							: ''
					)}
				>
					{isLogin && activeTab === Type_Earns.Liquidity && (
						<Paper
							className={cn(
								'items-center underLg:mt-4 underLg:py-3 underLg:px-0 underLg:px-3',
								{ block: !isMatchPc, flex: isMatchPc }
							)}
						>
							<Typography
								variant={isMatchMobile || isMatchPad ? 'subtitle2' : 'body2'}
								component='span'
								color='text.secondary'
								className='mr-2'
							>
								{isMatchMobile || isMatchPad ? (
									<Trans>Your current liquidity mining multiplier:</Trans>
								) : (
									<Trans>My Multiplier:</Trans>
								)}
							</Typography>
							{isBindReferralCode ? (
								<Typography
									variant={isMatchMobile || isMatchPad ? 'subtitle2' : 'body1'}
									component='span'
									color='text'
									fontWeight={600}
								>
									1.1x
								</Typography>
							) : (
								<Stronger
									value={
										<Typography
											className='leading-none'
											variant='h6'
											color='text'
										>
											1.0x
										</Typography>
									}
									tooltip={
										<div>
											<Typography variant='body2'>
												<Trans>
													Binding other user&#39;s referral code can increase
													the mining multiplier to 1.1x. The larger the
													multiplier, the greater the earnings.
												</Trans>
												<Typography
													className='ml-1'
													component='span'
													color='primary'
												>
													<Link to='/referrals/traders'>{t`Bind now >>`}</Link>
												</Typography>
											</Typography>
										</div>
									}
								/>
							)}
						</Paper>
					)}
					{isLogin && (
						<CommonStyledOutlinedButton
							variant='outlined'
							color='secondary'
							onClick={onClaim}
							disabled={claimDisabled}
							className='underLg:hidden'
						>
							{isClaimLoading ? (
								<Trans>Claiming...</Trans>
							) : (
								<Trans>Claim</Trans>
							)}
						</CommonStyledOutlinedButton>
					)}
				</Paper>
			</div>
			<Divider className='underLg:hidden' />
			<div className='p-4 underLg:p-0'>
				{activeTab === Type_Earns.Staking ? <Staking /> : null}
				{activeTab === Type_Earns.Position ? <Position /> : null}
				{activeTab === Type_Earns.Pool2 ? <Pool2Mining /> : null}
				{activeTab === Type_Earns.Liquidity ? <Liquidity /> : null}
				{activeTab === Type_Earns.RBF ? <RBFMining /> : null}
				{activeTab === Type_Earns.Architect ? <Architect /> : null}
			</div>
		</Paper>
	);
});
