import { useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Version } from 'config/constants';
import { useApolloClients } from 'context/ApolloClientsContext';
import { useReferralClaimDetailsV2Request } from 'fetch/useRequest';
import { useAccount } from 'wagmi';

import _, { filter, find } from 'lodash';
import {
	Referral_Request_Reward_Type,
	Referral_Request_Type
} from 'pages/Referrals/types';
import { useAppSelector } from 'state/hooks';
import { settingBaseState } from 'state/setting/slice';
import { isPositive, plus } from 'utils';

import { useMyConnectorsQuery } from './__generated_referral__/types-and-hooks';
import { useReferralsRewardsGraph } from './useReferralsConnectorsRewardsGraph';

export function useConnectorsReferralsGraph() {
	const { clientReferral } = useApolloClients();
	const { address } = useAccount();
	const [loading, setLoading] = useState<boolean>(true);
	const [connectors, setConnectors] = useState([]);
	const { currentVersion } = useAppSelector(settingBaseState);
	const {
		rewards,
		EQURewards,
		refetch: refetchRewards
	} = useReferralsRewardsGraph(connectors);
	const {
		data,
		error,
		refetch,
		loading: isLoading
	} = useMyConnectorsQuery({
		variables: {
			account: address ? address.toLowerCase() : '',
			type: 'Connector'
		},
		skip: !address,
		client: clientReferral,
		notifyOnNetworkStatusChange: true
	});

	const {
		data: detailsList,
		// isLoading: isLoadingDetails,
		refetch: refetchDetails
	} = useReferralClaimDetailsV2Request(
		address,
		Referral_Request_Type.CONNECTOR,
		currentVersion === Version.V1
			? ''
			: Referral_Request_Reward_Type.V2_REFERRAL_POSITION
	);

	const newRewardsEQUData = useMemo(() => {
		if (!detailsList) {
			return undefined;
		}
		return detailsList.map((item: any) => {
			const totalClaimableAmount = item.token_rewards.reduce(
				(sum, reward) => plus(sum, reward.claimable_amount),
				'0'
			);
			const totalAmount = item.token_rewards.reduce(
				(sum, reward) => plus(sum, reward.total_amount),
				'0'
			);
			return {
				id: item.token_id.toString(),
				equ_rewards: item.token_rewards,
				total_claimable_amount: totalClaimableAmount,
				total_amount: totalAmount
			};
		});
	}, [detailsList]);

	useUpdateEffect(() => {
		if (!isLoading) {
			setLoading(isLoading);
		}
	}, [isLoading]);
	useUpdateEffect(() => {
		if (data?.referrers && data?.referrers.length > 0) {
			const connectorsArray = [] as Array<string>;
			data?.referrers.forEach((item: any) => {
				connectorsArray.push(item.id);
			});
			setConnectors(connectorsArray);
		}
	}, [data, data?.referrers, address]);

	const connectorsList = useMemo(() => {
		if (!data || !data?.referrers || !data?.referrers?.length) {
			return undefined;
		}

		const feeRewardPools = _.map(data?.referrers, item => {
			const feePools = _.chain(item.referralFeeRewardDetails)
				.filter(item => isPositive(item?.unclaimed))
				.map(reward => reward.pool.id)
				.value();
			return { id: item.id, feePools };
		});

		const feeRewardPoolsV2 = _.map(data?.referrers, item => {
			const feePools = _.chain(item.referralFeeRewardDetailsV2)
				.filter(item => isPositive(item?.unclaimed))
				.map(reward => reward.market.id)
				.value();
			return { id: item.id, feePools };
		});

		const referrersList = [];
		data?.referrers.forEach((obj: any) => {
			const currentEQURewards =
				EQURewards && EQURewards.find(item => item.id === obj.id);
			const newEQURewardObj = find(newRewardsEQUData, { id: obj.id });
			const currentFeeReward =
				currentVersion === Version.V1
					? find(feeRewardPools, { id: obj.id })
					: find(feeRewardPoolsV2, { id: obj.id });
			let liquidityClaimed = '0',
				positionClaimed = '0';
			for (let i = 0; i < obj.referralRewards.length; i++) {
				liquidityClaimed = plus(
					liquidityClaimed,
					obj.referralRewards[i].liquidityClaimed
				);
				positionClaimed = plus(
					positionClaimed,
					obj.referralRewards[i].positionClaimed
				);
			}
			const connectorsItem = {
				unclaimed:
					currentVersion === Version.V1
						? obj.referralFeeReward.unclaimed
						: obj.referralFeeRewardV2.unclaimed,
				claimed:
					currentVersion === Version.V1
						? obj.referralFeeReward.claimed
						: obj.referralFeeRewardV2.claimed,
				rewardsEQU: currentEQURewards ? currentEQURewards?.rewardsEQU : '0',
				newRewardsEQU: newEQURewardObj
					? newEQURewardObj?.total_claimable_amount
					: '0',
				newRewardsEQUTotal: newEQURewardObj
					? newEQURewardObj?.total_amount
					: '0',
				pools: currentEQURewards ? currentEQURewards?.pools : [],
				feePools: currentFeeReward ? currentFeeReward?.feePools : [],
				liquidityClaimed,
				positionClaimed,
				...obj
			};
			referrersList.push(connectorsItem);
		});
		return referrersList;
	}, [data, refetch, loading, rewards, EQURewards, currentVersion]);

	const connectorsRewardList = useMemo(() => {
		if (connectorsList && connectorsList.length > 0) {
			const filterClaimList = filter(
				connectorsList,
				item =>
					!(
						item.unclaimed === '0' &&
						item.newRewardsEQU === '0' &&
						item.rewardsEQU === '0'
					)
			);
			const rewardList = [];
			filterClaimList.forEach(item => {
				const claimItem = {
					id: item.id,
					rewardsEQU: item.rewardsEQU,
					newRewardsEQU: item.newRewardsEQU,
					newRewardsEQUTotal: item.newRewardsEQUTotal,
					unclaimed: item.unclaimed,
					pools: item.pools,
					feePools: item.feePools
				};

				rewardList.push(claimItem);
			});
			return rewardList;
		}
		return [];
	}, [connectorsList]);

	return useMemo(() => {
		return {
			data: connectorsList,
			connectorsRewardList,
			refetch,
			refetchRewards,
			refetchDetails,
			loading,
			error
		};
	}, [connectorsList, connectorsRewardList, refetch, loading, error]);
}
