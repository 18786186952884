import { useEffect, useState } from 'react';

import { orderBookABI } from 'config/abis';
import {
	Contract_Write_Mode,
	QUOTE_USD,
	Side,
	Transaction_Type
} from 'config/constants';
import { getContractAddress } from 'config/contracts';
import Decimal from 'decimal.js';
import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { txBaseState, updateSigning } from 'state/tx/slice';
import { BaseRecord, RecordForCancelledLimitOrder, TokenInfo } from 'types';
import { formatSize, shortenSymbolNative, toQuoteAmount } from 'utils';

import { useComputeGasLimit } from './useComputeGasLimit';
import { useCurrentChain } from './useCurrentChain';
import { useSendTransaction } from './useSendTransaction';

export function useSubmitCancelLimitOrder() {
	const dispatch = useAppDispatch();

	const { quoteToken } = useAppSelector(txBaseState);
	const { currentChainId } = useCurrentChain();
	const { address } = useAccount();

	const [contractWriteArgs, setContractWriteArgs] =
		useState<UseContractWriteConfig | null>(null);
	const [record, setRecord] = useState<RecordForCancelledLimitOrder | null>(
		null
	);
	const [description, setDescription] = useState<string>('');

	const { gasLimit, prepared } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirming, isConfirmed, error } =
		useSendTransaction(contractWriteArgs, gasLimit);

	const onConfirm = (
		poolId: string,
		orderIndex: string,
		orderId: string,
		triggerPrice: string,
		triggerAbove: boolean,
		liquidityDelta: string,
		side: Side,
		baseToken: TokenInfo,
		sizeDelta?: string
	) => {
		console.log('useSubmitCancelLimitOrder args begin ...');

		const args = [orderIndex, address];
		const record = {
			transactionType: Transaction_Type.CancelledLimitOrder,
			pool: poolId,
			orderIndex: orderIndex,
			orderId: orderId,
			baseToken,
			baseSymbol: baseToken.symbol,
			quoteSymbol: quoteToken?.symbol,
			triggerPrice: triggerPrice,
			triggerAbove: triggerAbove,
			sizeDelta: sizeDelta,
			liquidityDelta: liquidityDelta,
			side
		} as RecordForCancelledLimitOrder;

		const baseSymbolNative = shortenSymbolNative(baseToken, currentChainId);
		const poolSymbol = `${shortenSymbolNative(
			baseToken,
			currentChainId
		)}/${QUOTE_USD}`;

		const description = TransactionDescription.CancelledLimitOrder(
			poolSymbol,
			baseSymbolNative,
			formatSize(sizeDelta, baseToken.positionUnits),
			toQuoteAmount(triggerPrice, baseToken.precision, Decimal.ROUND_HALF_CEIL),
			triggerAbove,
			side
		);

		const contractWriteArgs = {
			mode: Contract_Write_Mode.Unprepared,
			address: getContractAddress(currentChainId, 'OrderBook'),
			abi: orderBookABI,
			functionName: 'cancelIncreaseOrder',
			args
		} as UseContractWriteConfig;

		setRecord(record);
		setDescription(description);
		setContractWriteArgs(contractWriteArgs);

		dispatch(updateSigning(record));
	};

	useEffect(() => {
		if (prepared) {
			onSendTransaction(record as BaseRecord, description);
		}
	}, [prepared]);

	useEffect(() => {
		if (error) {
			setContractWriteArgs(null);
		}
	}, [error]);

	return {
		onConfirm,
		isConfirming,
		isConfirmed
	};
}
