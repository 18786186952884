import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
  /** 8 bytes signed integer */
  Int8: any;
};

export type Account = {
  __typename?: 'Account';
  /** claimed reward from equ and liquidity */
  claimedRewardFromEQU: Scalars['BigDecimal'];
  claimedRewardFromLiquidity: Scalars['BigDecimal'];
  /** address */
  id: Scalars['Bytes'];
  liquidityRewards: Array<LiquidityReward>;
  /** the markets in which this account has position */
  markets: Array<Scalars['Bytes']>;
  /** the pools in which this account has reward */
  pools: Array<Scalars['Bytes']>;
  positions: Array<Position>;
  referralCode?: Maybe<ReferralCode>;
  /** the amount of referral fee */
  referralFee: Scalars['BigDecimal'];
  /** the amount of referral fee for version 2 */
  referralFeeV2: Scalars['BigDecimal'];
  /** the token ID of the `Connector` NFT type is bound to the account */
  referralParentToken: Scalars['BigInt'];
  /** the token ID of the `Member` NFT type is bound to the account */
  referralToken: Scalars['BigInt'];
  riskBufferFundRewards: Array<RiskBufferFundReward>;
  stakeDetails: Array<StakeDetail>;
};


export type AccountLiquidityRewardsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LiquidityReward_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LiquidityReward_Filter>;
};


export type AccountPositionsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Position_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Position_Filter>;
};


export type AccountRiskBufferFundRewardsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RiskBufferFundReward_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RiskBufferFundReward_Filter>;
};


export type AccountStakeDetailsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<StakeDetail_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StakeDetail_Filter>;
};

export type Account_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Account_Filter>>>;
  claimedRewardFromEQU?: InputMaybe<Scalars['BigDecimal']>;
  claimedRewardFromEQU_gt?: InputMaybe<Scalars['BigDecimal']>;
  claimedRewardFromEQU_gte?: InputMaybe<Scalars['BigDecimal']>;
  claimedRewardFromEQU_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  claimedRewardFromEQU_lt?: InputMaybe<Scalars['BigDecimal']>;
  claimedRewardFromEQU_lte?: InputMaybe<Scalars['BigDecimal']>;
  claimedRewardFromEQU_not?: InputMaybe<Scalars['BigDecimal']>;
  claimedRewardFromEQU_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  claimedRewardFromLiquidity?: InputMaybe<Scalars['BigDecimal']>;
  claimedRewardFromLiquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  claimedRewardFromLiquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  claimedRewardFromLiquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  claimedRewardFromLiquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  claimedRewardFromLiquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  claimedRewardFromLiquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  claimedRewardFromLiquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  liquidityRewards_?: InputMaybe<LiquidityReward_Filter>;
  markets?: InputMaybe<Array<Scalars['Bytes']>>;
  markets_contains?: InputMaybe<Array<Scalars['Bytes']>>;
  markets_contains_nocase?: InputMaybe<Array<Scalars['Bytes']>>;
  markets_not?: InputMaybe<Array<Scalars['Bytes']>>;
  markets_not_contains?: InputMaybe<Array<Scalars['Bytes']>>;
  markets_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']>>;
  or?: InputMaybe<Array<InputMaybe<Account_Filter>>>;
  pools?: InputMaybe<Array<Scalars['Bytes']>>;
  pools_contains?: InputMaybe<Array<Scalars['Bytes']>>;
  pools_contains_nocase?: InputMaybe<Array<Scalars['Bytes']>>;
  pools_not?: InputMaybe<Array<Scalars['Bytes']>>;
  pools_not_contains?: InputMaybe<Array<Scalars['Bytes']>>;
  pools_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']>>;
  positions_?: InputMaybe<Position_Filter>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCode_?: InputMaybe<ReferralCode_Filter>;
  referralCode_contains?: InputMaybe<Scalars['String']>;
  referralCode_contains_nocase?: InputMaybe<Scalars['String']>;
  referralCode_ends_with?: InputMaybe<Scalars['String']>;
  referralCode_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referralCode_gt?: InputMaybe<Scalars['String']>;
  referralCode_gte?: InputMaybe<Scalars['String']>;
  referralCode_in?: InputMaybe<Array<Scalars['String']>>;
  referralCode_lt?: InputMaybe<Scalars['String']>;
  referralCode_lte?: InputMaybe<Scalars['String']>;
  referralCode_not?: InputMaybe<Scalars['String']>;
  referralCode_not_contains?: InputMaybe<Scalars['String']>;
  referralCode_not_contains_nocase?: InputMaybe<Scalars['String']>;
  referralCode_not_ends_with?: InputMaybe<Scalars['String']>;
  referralCode_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referralCode_not_in?: InputMaybe<Array<Scalars['String']>>;
  referralCode_not_starts_with?: InputMaybe<Scalars['String']>;
  referralCode_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  referralCode_starts_with?: InputMaybe<Scalars['String']>;
  referralCode_starts_with_nocase?: InputMaybe<Scalars['String']>;
  referralFee?: InputMaybe<Scalars['BigDecimal']>;
  referralFeeV2?: InputMaybe<Scalars['BigDecimal']>;
  referralFeeV2_gt?: InputMaybe<Scalars['BigDecimal']>;
  referralFeeV2_gte?: InputMaybe<Scalars['BigDecimal']>;
  referralFeeV2_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  referralFeeV2_lt?: InputMaybe<Scalars['BigDecimal']>;
  referralFeeV2_lte?: InputMaybe<Scalars['BigDecimal']>;
  referralFeeV2_not?: InputMaybe<Scalars['BigDecimal']>;
  referralFeeV2_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  referralFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  referralFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  referralFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  referralFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  referralFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  referralFee_not?: InputMaybe<Scalars['BigDecimal']>;
  referralFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  referralParentToken?: InputMaybe<Scalars['BigInt']>;
  referralParentToken_gt?: InputMaybe<Scalars['BigInt']>;
  referralParentToken_gte?: InputMaybe<Scalars['BigInt']>;
  referralParentToken_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralParentToken_lt?: InputMaybe<Scalars['BigInt']>;
  referralParentToken_lte?: InputMaybe<Scalars['BigInt']>;
  referralParentToken_not?: InputMaybe<Scalars['BigInt']>;
  referralParentToken_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralToken?: InputMaybe<Scalars['BigInt']>;
  referralToken_gt?: InputMaybe<Scalars['BigInt']>;
  referralToken_gte?: InputMaybe<Scalars['BigInt']>;
  referralToken_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralToken_lt?: InputMaybe<Scalars['BigInt']>;
  referralToken_lte?: InputMaybe<Scalars['BigInt']>;
  referralToken_not?: InputMaybe<Scalars['BigInt']>;
  referralToken_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  riskBufferFundRewards_?: InputMaybe<RiskBufferFundReward_Filter>;
  stakeDetails_?: InputMaybe<StakeDetail_Filter>;
};

export enum Account_OrderBy {
  ClaimedRewardFromEqu = 'claimedRewardFromEQU',
  ClaimedRewardFromLiquidity = 'claimedRewardFromLiquidity',
  Id = 'id',
  LiquidityRewards = 'liquidityRewards',
  Markets = 'markets',
  Pools = 'pools',
  Positions = 'positions',
  ReferralCode = 'referralCode',
  ReferralCodeBlockNumber = 'referralCode__blockNumber',
  ReferralCodeBlockTimestamp = 'referralCode__blockTimestamp',
  ReferralCodeId = 'referralCode__id',
  ReferralCodeRefereeCount = 'referralCode__refereeCount',
  ReferralCodeTxHash = 'referralCode__txHash',
  ReferralFee = 'referralFee',
  ReferralFeeV2 = 'referralFeeV2',
  ReferralParentToken = 'referralParentToken',
  ReferralToken = 'referralToken',
  RiskBufferFundRewards = 'riskBufferFundRewards',
  StakeDetails = 'stakeDetails'
}

export type AggregationClaimHistory = {
  __typename?: 'AggregationClaimHistory';
  /** the owner of the reward */
  account: Scalars['Bytes'];
  /** claimed reward */
  amount: Scalars['BigDecimal'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  /** claimed fee reward */
  feeAmount: Scalars['BigDecimal'];
  /** format: <transaction hash>:<type> */
  id: Scalars['ID'];
  txHash: Scalars['Bytes'];
  /**
   * type of the aggregation claim history,
   * enum{Member,Connector,Architect,Liquidity,RiskBufferFund,Staking}
   */
  type: Scalars['String'];
};

export type AggregationClaimHistory_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['Bytes']>;
  account_contains?: InputMaybe<Scalars['Bytes']>;
  account_gt?: InputMaybe<Scalars['Bytes']>;
  account_gte?: InputMaybe<Scalars['Bytes']>;
  account_in?: InputMaybe<Array<Scalars['Bytes']>>;
  account_lt?: InputMaybe<Scalars['Bytes']>;
  account_lte?: InputMaybe<Scalars['Bytes']>;
  account_not?: InputMaybe<Scalars['Bytes']>;
  account_not_contains?: InputMaybe<Scalars['Bytes']>;
  account_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  amount?: InputMaybe<Scalars['BigDecimal']>;
  amount_gt?: InputMaybe<Scalars['BigDecimal']>;
  amount_gte?: InputMaybe<Scalars['BigDecimal']>;
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amount_lt?: InputMaybe<Scalars['BigDecimal']>;
  amount_lte?: InputMaybe<Scalars['BigDecimal']>;
  amount_not?: InputMaybe<Scalars['BigDecimal']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  and?: InputMaybe<Array<InputMaybe<AggregationClaimHistory_Filter>>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  feeAmount?: InputMaybe<Scalars['BigDecimal']>;
  feeAmount_gt?: InputMaybe<Scalars['BigDecimal']>;
  feeAmount_gte?: InputMaybe<Scalars['BigDecimal']>;
  feeAmount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  feeAmount_lt?: InputMaybe<Scalars['BigDecimal']>;
  feeAmount_lte?: InputMaybe<Scalars['BigDecimal']>;
  feeAmount_not?: InputMaybe<Scalars['BigDecimal']>;
  feeAmount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<AggregationClaimHistory_Filter>>>;
  txHash?: InputMaybe<Scalars['Bytes']>;
  txHash_contains?: InputMaybe<Scalars['Bytes']>;
  txHash_gt?: InputMaybe<Scalars['Bytes']>;
  txHash_gte?: InputMaybe<Scalars['Bytes']>;
  txHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  txHash_lt?: InputMaybe<Scalars['Bytes']>;
  txHash_lte?: InputMaybe<Scalars['Bytes']>;
  txHash_not?: InputMaybe<Scalars['Bytes']>;
  txHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  txHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  type?: InputMaybe<Scalars['String']>;
  type_contains?: InputMaybe<Scalars['String']>;
  type_contains_nocase?: InputMaybe<Scalars['String']>;
  type_ends_with?: InputMaybe<Scalars['String']>;
  type_ends_with_nocase?: InputMaybe<Scalars['String']>;
  type_gt?: InputMaybe<Scalars['String']>;
  type_gte?: InputMaybe<Scalars['String']>;
  type_in?: InputMaybe<Array<Scalars['String']>>;
  type_lt?: InputMaybe<Scalars['String']>;
  type_lte?: InputMaybe<Scalars['String']>;
  type_not?: InputMaybe<Scalars['String']>;
  type_not_contains?: InputMaybe<Scalars['String']>;
  type_not_contains_nocase?: InputMaybe<Scalars['String']>;
  type_not_ends_with?: InputMaybe<Scalars['String']>;
  type_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  type_not_in?: InputMaybe<Array<Scalars['String']>>;
  type_not_starts_with?: InputMaybe<Scalars['String']>;
  type_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  type_starts_with?: InputMaybe<Scalars['String']>;
  type_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum AggregationClaimHistory_OrderBy {
  Account = 'account',
  Amount = 'amount',
  BlockNumber = 'blockNumber',
  BlockTimestamp = 'blockTimestamp',
  FeeAmount = 'feeAmount',
  Id = 'id',
  TxHash = 'txHash',
  Type = 'type'
}

/** entity that represents the NFT for architect */
export type Architect = {
  __typename?: 'Architect';
  /** the owner of the NFT */
  account: Scalars['Bytes'];
  /** claimed reward */
  claimedReward: Scalars['BigDecimal'];
  /** NFT token id */
  id: Scalars['ID'];
  /** the mint time of the NFT */
  mintTimestamp: Scalars['BigInt'];
  /** the mint hash of the NFT */
  mintTxHash: Scalars['Bytes'];
  /** the minter of the NFT */
  minter: Scalars['Bytes'];
  perShareGrowthX64: Scalars['BigInt'];
  /** the uniform resource identifier (URI) of the NFT */
  tokenURI: Scalars['String'];
};

export type Architect_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['Bytes']>;
  account_contains?: InputMaybe<Scalars['Bytes']>;
  account_gt?: InputMaybe<Scalars['Bytes']>;
  account_gte?: InputMaybe<Scalars['Bytes']>;
  account_in?: InputMaybe<Array<Scalars['Bytes']>>;
  account_lt?: InputMaybe<Scalars['Bytes']>;
  account_lte?: InputMaybe<Scalars['Bytes']>;
  account_not?: InputMaybe<Scalars['Bytes']>;
  account_not_contains?: InputMaybe<Scalars['Bytes']>;
  account_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  and?: InputMaybe<Array<InputMaybe<Architect_Filter>>>;
  claimedReward?: InputMaybe<Scalars['BigDecimal']>;
  claimedReward_gt?: InputMaybe<Scalars['BigDecimal']>;
  claimedReward_gte?: InputMaybe<Scalars['BigDecimal']>;
  claimedReward_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  claimedReward_lt?: InputMaybe<Scalars['BigDecimal']>;
  claimedReward_lte?: InputMaybe<Scalars['BigDecimal']>;
  claimedReward_not?: InputMaybe<Scalars['BigDecimal']>;
  claimedReward_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  mintTimestamp?: InputMaybe<Scalars['BigInt']>;
  mintTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  mintTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  mintTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  mintTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  mintTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  mintTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  mintTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  mintTxHash?: InputMaybe<Scalars['Bytes']>;
  mintTxHash_contains?: InputMaybe<Scalars['Bytes']>;
  mintTxHash_gt?: InputMaybe<Scalars['Bytes']>;
  mintTxHash_gte?: InputMaybe<Scalars['Bytes']>;
  mintTxHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  mintTxHash_lt?: InputMaybe<Scalars['Bytes']>;
  mintTxHash_lte?: InputMaybe<Scalars['Bytes']>;
  mintTxHash_not?: InputMaybe<Scalars['Bytes']>;
  mintTxHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  mintTxHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  minter?: InputMaybe<Scalars['Bytes']>;
  minter_contains?: InputMaybe<Scalars['Bytes']>;
  minter_gt?: InputMaybe<Scalars['Bytes']>;
  minter_gte?: InputMaybe<Scalars['Bytes']>;
  minter_in?: InputMaybe<Array<Scalars['Bytes']>>;
  minter_lt?: InputMaybe<Scalars['Bytes']>;
  minter_lte?: InputMaybe<Scalars['Bytes']>;
  minter_not?: InputMaybe<Scalars['Bytes']>;
  minter_not_contains?: InputMaybe<Scalars['Bytes']>;
  minter_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  or?: InputMaybe<Array<InputMaybe<Architect_Filter>>>;
  perShareGrowthX64?: InputMaybe<Scalars['BigInt']>;
  perShareGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  perShareGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  perShareGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  perShareGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  perShareGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  perShareGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  perShareGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenURI?: InputMaybe<Scalars['String']>;
  tokenURI_contains?: InputMaybe<Scalars['String']>;
  tokenURI_contains_nocase?: InputMaybe<Scalars['String']>;
  tokenURI_ends_with?: InputMaybe<Scalars['String']>;
  tokenURI_ends_with_nocase?: InputMaybe<Scalars['String']>;
  tokenURI_gt?: InputMaybe<Scalars['String']>;
  tokenURI_gte?: InputMaybe<Scalars['String']>;
  tokenURI_in?: InputMaybe<Array<Scalars['String']>>;
  tokenURI_lt?: InputMaybe<Scalars['String']>;
  tokenURI_lte?: InputMaybe<Scalars['String']>;
  tokenURI_not?: InputMaybe<Scalars['String']>;
  tokenURI_not_contains?: InputMaybe<Scalars['String']>;
  tokenURI_not_contains_nocase?: InputMaybe<Scalars['String']>;
  tokenURI_not_ends_with?: InputMaybe<Scalars['String']>;
  tokenURI_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  tokenURI_not_in?: InputMaybe<Array<Scalars['String']>>;
  tokenURI_not_starts_with?: InputMaybe<Scalars['String']>;
  tokenURI_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  tokenURI_starts_with?: InputMaybe<Scalars['String']>;
  tokenURI_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum Architect_OrderBy {
  Account = 'account',
  ClaimedReward = 'claimedReward',
  Id = 'id',
  MintTimestamp = 'mintTimestamp',
  MintTxHash = 'mintTxHash',
  Minter = 'minter',
  PerShareGrowthX64 = 'perShareGrowthX64',
  TokenUri = 'tokenURI'
}

export type BlockChangedFilter = {
  number_gte: Scalars['Int'];
};

export type Block_Height = {
  hash?: InputMaybe<Scalars['Bytes']>;
  number?: InputMaybe<Scalars['Int']>;
  number_gte?: InputMaybe<Scalars['Int']>;
};

export type ClaimHistory = {
  __typename?: 'ClaimHistory';
  /** the owner of the liquidity reward */
  account: Scalars['Bytes'];
  /** claimed reward */
  amount: Scalars['BigDecimal'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  /** format: <transaction hash>:<log index> */
  id: Scalars['ID'];
  /** the owner of referral reward */
  referralToken: Scalars['BigInt'];
  txHash: Scalars['Bytes'];
  /**
   * type of the claim history,
   * enum{LiquidityReward,RiskBufferFundReward,ReferralPositionReward,ReferralLiquidityReward,ReferralFee}
   */
  type: Scalars['String'];
};

export type ClaimHistory_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['Bytes']>;
  account_contains?: InputMaybe<Scalars['Bytes']>;
  account_gt?: InputMaybe<Scalars['Bytes']>;
  account_gte?: InputMaybe<Scalars['Bytes']>;
  account_in?: InputMaybe<Array<Scalars['Bytes']>>;
  account_lt?: InputMaybe<Scalars['Bytes']>;
  account_lte?: InputMaybe<Scalars['Bytes']>;
  account_not?: InputMaybe<Scalars['Bytes']>;
  account_not_contains?: InputMaybe<Scalars['Bytes']>;
  account_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  amount?: InputMaybe<Scalars['BigDecimal']>;
  amount_gt?: InputMaybe<Scalars['BigDecimal']>;
  amount_gte?: InputMaybe<Scalars['BigDecimal']>;
  amount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  amount_lt?: InputMaybe<Scalars['BigDecimal']>;
  amount_lte?: InputMaybe<Scalars['BigDecimal']>;
  amount_not?: InputMaybe<Scalars['BigDecimal']>;
  amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  and?: InputMaybe<Array<InputMaybe<ClaimHistory_Filter>>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<ClaimHistory_Filter>>>;
  referralToken?: InputMaybe<Scalars['BigInt']>;
  referralToken_gt?: InputMaybe<Scalars['BigInt']>;
  referralToken_gte?: InputMaybe<Scalars['BigInt']>;
  referralToken_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralToken_lt?: InputMaybe<Scalars['BigInt']>;
  referralToken_lte?: InputMaybe<Scalars['BigInt']>;
  referralToken_not?: InputMaybe<Scalars['BigInt']>;
  referralToken_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txHash?: InputMaybe<Scalars['Bytes']>;
  txHash_contains?: InputMaybe<Scalars['Bytes']>;
  txHash_gt?: InputMaybe<Scalars['Bytes']>;
  txHash_gte?: InputMaybe<Scalars['Bytes']>;
  txHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  txHash_lt?: InputMaybe<Scalars['Bytes']>;
  txHash_lte?: InputMaybe<Scalars['Bytes']>;
  txHash_not?: InputMaybe<Scalars['Bytes']>;
  txHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  txHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  type?: InputMaybe<Scalars['String']>;
  type_contains?: InputMaybe<Scalars['String']>;
  type_contains_nocase?: InputMaybe<Scalars['String']>;
  type_ends_with?: InputMaybe<Scalars['String']>;
  type_ends_with_nocase?: InputMaybe<Scalars['String']>;
  type_gt?: InputMaybe<Scalars['String']>;
  type_gte?: InputMaybe<Scalars['String']>;
  type_in?: InputMaybe<Array<Scalars['String']>>;
  type_lt?: InputMaybe<Scalars['String']>;
  type_lte?: InputMaybe<Scalars['String']>;
  type_not?: InputMaybe<Scalars['String']>;
  type_not_contains?: InputMaybe<Scalars['String']>;
  type_not_contains_nocase?: InputMaybe<Scalars['String']>;
  type_not_ends_with?: InputMaybe<Scalars['String']>;
  type_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  type_not_in?: InputMaybe<Array<Scalars['String']>>;
  type_not_starts_with?: InputMaybe<Scalars['String']>;
  type_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  type_starts_with?: InputMaybe<Scalars['String']>;
  type_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum ClaimHistory_OrderBy {
  Account = 'account',
  Amount = 'amount',
  BlockNumber = 'blockNumber',
  BlockTimestamp = 'blockTimestamp',
  Id = 'id',
  ReferralToken = 'referralToken',
  TxHash = 'txHash',
  Type = 'type'
}

export type LiquidityPosition = {
  __typename?: 'LiquidityPosition';
  /** the owner of the position */
  account: Account;
  /** format: <pool address>:<position id> */
  id: Scalars['ID'];
  /** the liquidity (value) of the position */
  liquidity: Scalars['BigDecimal'];
  pool: Pool;
  /** the position id */
  positionID: Scalars['BigInt'];
};

export type LiquidityPositionV2 = {
  __typename?: 'LiquidityPositionV2';
  /** the owner of the position */
  account: Account;
  /** format: <account>:<market address> */
  id: Scalars['ID'];
  /** the liquidity (value) of the position */
  liquidity: Scalars['BigDecimal'];
  market: Market;
};

export type LiquidityPositionV2_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['String']>;
  account_?: InputMaybe<Account_Filter>;
  account_contains?: InputMaybe<Scalars['String']>;
  account_contains_nocase?: InputMaybe<Scalars['String']>;
  account_ends_with?: InputMaybe<Scalars['String']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_gt?: InputMaybe<Scalars['String']>;
  account_gte?: InputMaybe<Scalars['String']>;
  account_in?: InputMaybe<Array<Scalars['String']>>;
  account_lt?: InputMaybe<Scalars['String']>;
  account_lte?: InputMaybe<Scalars['String']>;
  account_not?: InputMaybe<Scalars['String']>;
  account_not_contains?: InputMaybe<Scalars['String']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']>;
  account_not_ends_with?: InputMaybe<Scalars['String']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_not_starts_with?: InputMaybe<Scalars['String']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_starts_with?: InputMaybe<Scalars['String']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']>;
  and?: InputMaybe<Array<InputMaybe<LiquidityPositionV2_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  liquidity?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<LiquidityPositionV2_Filter>>>;
};

export enum LiquidityPositionV2_OrderBy {
  Account = 'account',
  AccountClaimedRewardFromEqu = 'account__claimedRewardFromEQU',
  AccountClaimedRewardFromLiquidity = 'account__claimedRewardFromLiquidity',
  AccountId = 'account__id',
  AccountReferralFee = 'account__referralFee',
  AccountReferralFeeV2 = 'account__referralFeeV2',
  AccountReferralParentToken = 'account__referralParentToken',
  AccountReferralToken = 'account__referralToken',
  Id = 'id',
  Liquidity = 'liquidity',
  Market = 'market',
  MarketId = 'market__id',
  MarketSymbol = 'market__symbol'
}

export type LiquidityPosition_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['String']>;
  account_?: InputMaybe<Account_Filter>;
  account_contains?: InputMaybe<Scalars['String']>;
  account_contains_nocase?: InputMaybe<Scalars['String']>;
  account_ends_with?: InputMaybe<Scalars['String']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_gt?: InputMaybe<Scalars['String']>;
  account_gte?: InputMaybe<Scalars['String']>;
  account_in?: InputMaybe<Array<Scalars['String']>>;
  account_lt?: InputMaybe<Scalars['String']>;
  account_lte?: InputMaybe<Scalars['String']>;
  account_not?: InputMaybe<Scalars['String']>;
  account_not_contains?: InputMaybe<Scalars['String']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']>;
  account_not_ends_with?: InputMaybe<Scalars['String']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_not_starts_with?: InputMaybe<Scalars['String']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_starts_with?: InputMaybe<Scalars['String']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']>;
  and?: InputMaybe<Array<InputMaybe<LiquidityPosition_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  liquidity?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<LiquidityPosition_Filter>>>;
  pool?: InputMaybe<Scalars['String']>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars['String']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_ends_with?: InputMaybe<Scalars['String']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_gt?: InputMaybe<Scalars['String']>;
  pool_gte?: InputMaybe<Scalars['String']>;
  pool_in?: InputMaybe<Array<Scalars['String']>>;
  pool_lt?: InputMaybe<Scalars['String']>;
  pool_lte?: InputMaybe<Scalars['String']>;
  pool_not?: InputMaybe<Scalars['String']>;
  pool_not_contains?: InputMaybe<Scalars['String']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_not_in?: InputMaybe<Array<Scalars['String']>>;
  pool_not_starts_with?: InputMaybe<Scalars['String']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pool_starts_with?: InputMaybe<Scalars['String']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']>;
  positionID?: InputMaybe<Scalars['BigInt']>;
  positionID_gt?: InputMaybe<Scalars['BigInt']>;
  positionID_gte?: InputMaybe<Scalars['BigInt']>;
  positionID_in?: InputMaybe<Array<Scalars['BigInt']>>;
  positionID_lt?: InputMaybe<Scalars['BigInt']>;
  positionID_lte?: InputMaybe<Scalars['BigInt']>;
  positionID_not?: InputMaybe<Scalars['BigInt']>;
  positionID_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum LiquidityPosition_OrderBy {
  Account = 'account',
  AccountClaimedRewardFromEqu = 'account__claimedRewardFromEQU',
  AccountClaimedRewardFromLiquidity = 'account__claimedRewardFromLiquidity',
  AccountId = 'account__id',
  AccountReferralFee = 'account__referralFee',
  AccountReferralFeeV2 = 'account__referralFeeV2',
  AccountReferralParentToken = 'account__referralParentToken',
  AccountReferralToken = 'account__referralToken',
  Id = 'id',
  Liquidity = 'liquidity',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolLastMintTime = 'pool__lastMintTime',
  PoolLiquidity = 'pool__liquidity',
  PoolLiquidityRewardGrowthX64 = 'pool__liquidityRewardGrowthX64',
  PoolMintedReward = 'pool__mintedReward',
  PoolReferralLiquidity = 'pool__referralLiquidity',
  PoolReferralParentTokenPositionRewardGrowthX64 = 'pool__referralParentTokenPositionRewardGrowthX64',
  PoolReferralParentTokenRewardGrowthX64 = 'pool__referralParentTokenRewardGrowthX64',
  PoolReferralPosition = 'pool__referralPosition',
  PoolReferralTokenPositionRewardGrowthX64 = 'pool__referralTokenPositionRewardGrowthX64',
  PoolReferralTokenRewardGrowthX64 = 'pool__referralTokenRewardGrowthX64',
  PoolRewardPerSecond = 'pool__rewardPerSecond',
  PoolRiskBufferFundLiquidity = 'pool__riskBufferFundLiquidity',
  PoolRiskBufferFundRewardGrowthX64 = 'pool__riskBufferFundRewardGrowthX64',
  PoolVersion = 'pool__version',
  PositionId = 'positionID'
}

export type LiquidityReward = {
  __typename?: 'LiquidityReward';
  /** the owner of the reward */
  account: Account;
  /** claimed reward */
  claimed: Scalars['BigDecimal'];
  /** format: <account>:<pool address> */
  id: Scalars['ID'];
  /** the total liquidity provided */
  liquidity: Scalars['BigDecimal'];
  /** liquidity before the change */
  liquidityBefore: Scalars['BigDecimal'];
  pool: Pool;
  /** the reward growth per unit of liquidity, as a Q64.64 */
  rewardGrowthX64: Scalars['BigInt'];
  /** unclaimed reward */
  unclaimed: Scalars['BigDecimal'];
  version: Scalars['BigInt'];
};

export type LiquidityReward_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['String']>;
  account_?: InputMaybe<Account_Filter>;
  account_contains?: InputMaybe<Scalars['String']>;
  account_contains_nocase?: InputMaybe<Scalars['String']>;
  account_ends_with?: InputMaybe<Scalars['String']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_gt?: InputMaybe<Scalars['String']>;
  account_gte?: InputMaybe<Scalars['String']>;
  account_in?: InputMaybe<Array<Scalars['String']>>;
  account_lt?: InputMaybe<Scalars['String']>;
  account_lte?: InputMaybe<Scalars['String']>;
  account_not?: InputMaybe<Scalars['String']>;
  account_not_contains?: InputMaybe<Scalars['String']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']>;
  account_not_ends_with?: InputMaybe<Scalars['String']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_not_starts_with?: InputMaybe<Scalars['String']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_starts_with?: InputMaybe<Scalars['String']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']>;
  and?: InputMaybe<Array<InputMaybe<LiquidityReward_Filter>>>;
  claimed?: InputMaybe<Scalars['BigDecimal']>;
  claimed_gt?: InputMaybe<Scalars['BigDecimal']>;
  claimed_gte?: InputMaybe<Scalars['BigDecimal']>;
  claimed_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  claimed_lt?: InputMaybe<Scalars['BigDecimal']>;
  claimed_lte?: InputMaybe<Scalars['BigDecimal']>;
  claimed_not?: InputMaybe<Scalars['BigDecimal']>;
  claimed_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  liquidity?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidityBefore_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<LiquidityReward_Filter>>>;
  pool?: InputMaybe<Scalars['String']>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars['String']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_ends_with?: InputMaybe<Scalars['String']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_gt?: InputMaybe<Scalars['String']>;
  pool_gte?: InputMaybe<Scalars['String']>;
  pool_in?: InputMaybe<Array<Scalars['String']>>;
  pool_lt?: InputMaybe<Scalars['String']>;
  pool_lte?: InputMaybe<Scalars['String']>;
  pool_not?: InputMaybe<Scalars['String']>;
  pool_not_contains?: InputMaybe<Scalars['String']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_not_in?: InputMaybe<Array<Scalars['String']>>;
  pool_not_starts_with?: InputMaybe<Scalars['String']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pool_starts_with?: InputMaybe<Scalars['String']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']>;
  rewardGrowthX64?: InputMaybe<Scalars['BigInt']>;
  rewardGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  rewardGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  rewardGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  rewardGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  rewardGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  rewardGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  rewardGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  unclaimed?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_gt?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_gte?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  unclaimed_lt?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_lte?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_not?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  version?: InputMaybe<Scalars['BigInt']>;
  version_gt?: InputMaybe<Scalars['BigInt']>;
  version_gte?: InputMaybe<Scalars['BigInt']>;
  version_in?: InputMaybe<Array<Scalars['BigInt']>>;
  version_lt?: InputMaybe<Scalars['BigInt']>;
  version_lte?: InputMaybe<Scalars['BigInt']>;
  version_not?: InputMaybe<Scalars['BigInt']>;
  version_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum LiquidityReward_OrderBy {
  Account = 'account',
  AccountClaimedRewardFromEqu = 'account__claimedRewardFromEQU',
  AccountClaimedRewardFromLiquidity = 'account__claimedRewardFromLiquidity',
  AccountId = 'account__id',
  AccountReferralFee = 'account__referralFee',
  AccountReferralFeeV2 = 'account__referralFeeV2',
  AccountReferralParentToken = 'account__referralParentToken',
  AccountReferralToken = 'account__referralToken',
  Claimed = 'claimed',
  Id = 'id',
  Liquidity = 'liquidity',
  LiquidityBefore = 'liquidityBefore',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolLastMintTime = 'pool__lastMintTime',
  PoolLiquidity = 'pool__liquidity',
  PoolLiquidityRewardGrowthX64 = 'pool__liquidityRewardGrowthX64',
  PoolMintedReward = 'pool__mintedReward',
  PoolReferralLiquidity = 'pool__referralLiquidity',
  PoolReferralParentTokenPositionRewardGrowthX64 = 'pool__referralParentTokenPositionRewardGrowthX64',
  PoolReferralParentTokenRewardGrowthX64 = 'pool__referralParentTokenRewardGrowthX64',
  PoolReferralPosition = 'pool__referralPosition',
  PoolReferralTokenPositionRewardGrowthX64 = 'pool__referralTokenPositionRewardGrowthX64',
  PoolReferralTokenRewardGrowthX64 = 'pool__referralTokenRewardGrowthX64',
  PoolRewardPerSecond = 'pool__rewardPerSecond',
  PoolRiskBufferFundLiquidity = 'pool__riskBufferFundLiquidity',
  PoolRiskBufferFundRewardGrowthX64 = 'pool__riskBufferFundRewardGrowthX64',
  PoolVersion = 'pool__version',
  RewardGrowthX64 = 'rewardGrowthX64',
  Unclaimed = 'unclaimed',
  Version = 'version'
}

export type Market = {
  __typename?: 'Market';
  /** address of the market */
  id: Scalars['Bytes'];
  /** symbol of the market */
  symbol: Scalars['String'];
};

export type Market_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Market_Filter>>>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  or?: InputMaybe<Array<InputMaybe<Market_Filter>>>;
  symbol?: InputMaybe<Scalars['String']>;
  symbol_contains?: InputMaybe<Scalars['String']>;
  symbol_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_ends_with?: InputMaybe<Scalars['String']>;
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_gt?: InputMaybe<Scalars['String']>;
  symbol_gte?: InputMaybe<Scalars['String']>;
  symbol_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_lt?: InputMaybe<Scalars['String']>;
  symbol_lte?: InputMaybe<Scalars['String']>;
  symbol_not?: InputMaybe<Scalars['String']>;
  symbol_not_contains?: InputMaybe<Scalars['String']>;
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_starts_with?: InputMaybe<Scalars['String']>;
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum Market_OrderBy {
  Id = 'id',
  Symbol = 'symbol'
}

/** entity that represents the NFT mint history */
export type MintHistory = {
  __typename?: 'MintHistory';
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  connectorTokenID: Scalars['BigInt'];
  /** format: <transaction hash>:<log index> */
  id: Scalars['ID'];
  /** the minter of the NFT */
  minter: Scalars['Bytes'];
  /** the receiver of the NFT */
  receiver: Scalars['Bytes'];
  /** NFT token id */
  tokenID: Scalars['BigInt'];
  txHash: Scalars['Bytes'];
  /** type of the mint history, enum{Member, Connector, Architect} */
  type: Scalars['String'];
};

export type MintHistory_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<MintHistory_Filter>>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  connectorTokenID?: InputMaybe<Scalars['BigInt']>;
  connectorTokenID_gt?: InputMaybe<Scalars['BigInt']>;
  connectorTokenID_gte?: InputMaybe<Scalars['BigInt']>;
  connectorTokenID_in?: InputMaybe<Array<Scalars['BigInt']>>;
  connectorTokenID_lt?: InputMaybe<Scalars['BigInt']>;
  connectorTokenID_lte?: InputMaybe<Scalars['BigInt']>;
  connectorTokenID_not?: InputMaybe<Scalars['BigInt']>;
  connectorTokenID_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  minter?: InputMaybe<Scalars['Bytes']>;
  minter_contains?: InputMaybe<Scalars['Bytes']>;
  minter_gt?: InputMaybe<Scalars['Bytes']>;
  minter_gte?: InputMaybe<Scalars['Bytes']>;
  minter_in?: InputMaybe<Array<Scalars['Bytes']>>;
  minter_lt?: InputMaybe<Scalars['Bytes']>;
  minter_lte?: InputMaybe<Scalars['Bytes']>;
  minter_not?: InputMaybe<Scalars['Bytes']>;
  minter_not_contains?: InputMaybe<Scalars['Bytes']>;
  minter_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  or?: InputMaybe<Array<InputMaybe<MintHistory_Filter>>>;
  receiver?: InputMaybe<Scalars['Bytes']>;
  receiver_contains?: InputMaybe<Scalars['Bytes']>;
  receiver_gt?: InputMaybe<Scalars['Bytes']>;
  receiver_gte?: InputMaybe<Scalars['Bytes']>;
  receiver_in?: InputMaybe<Array<Scalars['Bytes']>>;
  receiver_lt?: InputMaybe<Scalars['Bytes']>;
  receiver_lte?: InputMaybe<Scalars['Bytes']>;
  receiver_not?: InputMaybe<Scalars['Bytes']>;
  receiver_not_contains?: InputMaybe<Scalars['Bytes']>;
  receiver_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  tokenID?: InputMaybe<Scalars['BigInt']>;
  tokenID_gt?: InputMaybe<Scalars['BigInt']>;
  tokenID_gte?: InputMaybe<Scalars['BigInt']>;
  tokenID_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenID_lt?: InputMaybe<Scalars['BigInt']>;
  tokenID_lte?: InputMaybe<Scalars['BigInt']>;
  tokenID_not?: InputMaybe<Scalars['BigInt']>;
  tokenID_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  txHash?: InputMaybe<Scalars['Bytes']>;
  txHash_contains?: InputMaybe<Scalars['Bytes']>;
  txHash_gt?: InputMaybe<Scalars['Bytes']>;
  txHash_gte?: InputMaybe<Scalars['Bytes']>;
  txHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  txHash_lt?: InputMaybe<Scalars['Bytes']>;
  txHash_lte?: InputMaybe<Scalars['Bytes']>;
  txHash_not?: InputMaybe<Scalars['Bytes']>;
  txHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  txHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  type?: InputMaybe<Scalars['String']>;
  type_contains?: InputMaybe<Scalars['String']>;
  type_contains_nocase?: InputMaybe<Scalars['String']>;
  type_ends_with?: InputMaybe<Scalars['String']>;
  type_ends_with_nocase?: InputMaybe<Scalars['String']>;
  type_gt?: InputMaybe<Scalars['String']>;
  type_gte?: InputMaybe<Scalars['String']>;
  type_in?: InputMaybe<Array<Scalars['String']>>;
  type_lt?: InputMaybe<Scalars['String']>;
  type_lte?: InputMaybe<Scalars['String']>;
  type_not?: InputMaybe<Scalars['String']>;
  type_not_contains?: InputMaybe<Scalars['String']>;
  type_not_contains_nocase?: InputMaybe<Scalars['String']>;
  type_not_ends_with?: InputMaybe<Scalars['String']>;
  type_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  type_not_in?: InputMaybe<Array<Scalars['String']>>;
  type_not_starts_with?: InputMaybe<Scalars['String']>;
  type_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  type_starts_with?: InputMaybe<Scalars['String']>;
  type_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum MintHistory_OrderBy {
  BlockNumber = 'blockNumber',
  BlockTimestamp = 'blockTimestamp',
  ConnectorTokenId = 'connectorTokenID',
  Id = 'id',
  Minter = 'minter',
  Receiver = 'receiver',
  TokenId = 'tokenID',
  TxHash = 'txHash',
  Type = 'type'
}

/** Defines the order direction, either ascending or descending */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Pool = {
  __typename?: 'Pool';
  /** pool address */
  id: Scalars['Bytes'];
  /** the last mint time */
  lastMintTime: Scalars['BigInt'];
  /** the sum of all liquidity of the pool */
  liquidity: Scalars['BigDecimal'];
  /** the reward growth per unit of liquidity, as a Q64.64 */
  liquidityRewardGrowthX64: Scalars['BigInt'];
  /** the total minting reward of the pool */
  mintedReward: Scalars['BigDecimal'];
  /** the sum of all the referral liquidity of the pool */
  referralLiquidity: Scalars['BigDecimal'];
  /** The reward growth per unit of referral token parent position, as a Q64.64 */
  referralParentTokenPositionRewardGrowthX64: Scalars['BigInt'];
  /** the reward growth per unit of referral token parent liquidity, as a Q64.64 */
  referralParentTokenRewardGrowthX64: Scalars['BigInt'];
  /** the sum of all the referral position liquidity of the pool */
  referralPosition: Scalars['BigDecimal'];
  /** the reward growth per unit of referral token position liquidity, as a Q64.64 */
  referralTokenPositionRewardGrowthX64: Scalars['BigInt'];
  /** the reward growth per unit of referral token liquidity, as a Q64.64 */
  referralTokenRewardGrowthX64: Scalars['BigInt'];
  /** the amount minted per second */
  rewardPerSecond: Scalars['BigInt'];
  /** the sum of the liquidity of all risk buffer fund of the pool */
  riskBufferFundLiquidity: Scalars['BigDecimal'];
  /** the reward growth per unit of risk buffer fund liquidity, as a Q64.64 */
  riskBufferFundRewardGrowthX64: Scalars['BigInt'];
  /** base token */
  token: Token;
  /** quote token */
  usd: Token;
  version: Scalars['BigInt'];
};

export type Pool_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Pool_Filter>>>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  lastMintTime?: InputMaybe<Scalars['BigInt']>;
  lastMintTime_gt?: InputMaybe<Scalars['BigInt']>;
  lastMintTime_gte?: InputMaybe<Scalars['BigInt']>;
  lastMintTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  lastMintTime_lt?: InputMaybe<Scalars['BigInt']>;
  lastMintTime_lte?: InputMaybe<Scalars['BigInt']>;
  lastMintTime_not?: InputMaybe<Scalars['BigInt']>;
  lastMintTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  liquidity?: InputMaybe<Scalars['BigDecimal']>;
  liquidityRewardGrowthX64?: InputMaybe<Scalars['BigInt']>;
  liquidityRewardGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  liquidityRewardGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  liquidityRewardGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  liquidityRewardGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  liquidityRewardGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  liquidityRewardGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  liquidityRewardGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  liquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  mintedReward?: InputMaybe<Scalars['BigDecimal']>;
  mintedReward_gt?: InputMaybe<Scalars['BigDecimal']>;
  mintedReward_gte?: InputMaybe<Scalars['BigDecimal']>;
  mintedReward_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  mintedReward_lt?: InputMaybe<Scalars['BigDecimal']>;
  mintedReward_lte?: InputMaybe<Scalars['BigDecimal']>;
  mintedReward_not?: InputMaybe<Scalars['BigDecimal']>;
  mintedReward_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<Pool_Filter>>>;
  referralLiquidity?: InputMaybe<Scalars['BigDecimal']>;
  referralLiquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  referralLiquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  referralLiquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  referralLiquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  referralLiquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  referralLiquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  referralLiquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  referralParentTokenPositionRewardGrowthX64?: InputMaybe<Scalars['BigInt']>;
  referralParentTokenPositionRewardGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  referralParentTokenPositionRewardGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  referralParentTokenPositionRewardGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralParentTokenPositionRewardGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  referralParentTokenPositionRewardGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  referralParentTokenPositionRewardGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  referralParentTokenPositionRewardGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralParentTokenRewardGrowthX64?: InputMaybe<Scalars['BigInt']>;
  referralParentTokenRewardGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  referralParentTokenRewardGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  referralParentTokenRewardGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralParentTokenRewardGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  referralParentTokenRewardGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  referralParentTokenRewardGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  referralParentTokenRewardGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralPosition?: InputMaybe<Scalars['BigDecimal']>;
  referralPosition_gt?: InputMaybe<Scalars['BigDecimal']>;
  referralPosition_gte?: InputMaybe<Scalars['BigDecimal']>;
  referralPosition_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  referralPosition_lt?: InputMaybe<Scalars['BigDecimal']>;
  referralPosition_lte?: InputMaybe<Scalars['BigDecimal']>;
  referralPosition_not?: InputMaybe<Scalars['BigDecimal']>;
  referralPosition_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  referralTokenPositionRewardGrowthX64?: InputMaybe<Scalars['BigInt']>;
  referralTokenPositionRewardGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  referralTokenPositionRewardGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  referralTokenPositionRewardGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralTokenPositionRewardGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  referralTokenPositionRewardGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  referralTokenPositionRewardGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  referralTokenPositionRewardGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralTokenRewardGrowthX64?: InputMaybe<Scalars['BigInt']>;
  referralTokenRewardGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  referralTokenRewardGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  referralTokenRewardGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralTokenRewardGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  referralTokenRewardGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  referralTokenRewardGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  referralTokenRewardGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  rewardPerSecond?: InputMaybe<Scalars['BigInt']>;
  rewardPerSecond_gt?: InputMaybe<Scalars['BigInt']>;
  rewardPerSecond_gte?: InputMaybe<Scalars['BigInt']>;
  rewardPerSecond_in?: InputMaybe<Array<Scalars['BigInt']>>;
  rewardPerSecond_lt?: InputMaybe<Scalars['BigInt']>;
  rewardPerSecond_lte?: InputMaybe<Scalars['BigInt']>;
  rewardPerSecond_not?: InputMaybe<Scalars['BigInt']>;
  rewardPerSecond_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  riskBufferFundLiquidity?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundLiquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundLiquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundLiquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  riskBufferFundLiquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundLiquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundLiquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  riskBufferFundLiquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  riskBufferFundRewardGrowthX64?: InputMaybe<Scalars['BigInt']>;
  riskBufferFundRewardGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  riskBufferFundRewardGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  riskBufferFundRewardGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  riskBufferFundRewardGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  riskBufferFundRewardGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  riskBufferFundRewardGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  riskBufferFundRewardGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  token?: InputMaybe<Scalars['String']>;
  token_?: InputMaybe<Token_Filter>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
  usd?: InputMaybe<Scalars['String']>;
  usd_?: InputMaybe<Token_Filter>;
  usd_contains?: InputMaybe<Scalars['String']>;
  usd_contains_nocase?: InputMaybe<Scalars['String']>;
  usd_ends_with?: InputMaybe<Scalars['String']>;
  usd_ends_with_nocase?: InputMaybe<Scalars['String']>;
  usd_gt?: InputMaybe<Scalars['String']>;
  usd_gte?: InputMaybe<Scalars['String']>;
  usd_in?: InputMaybe<Array<Scalars['String']>>;
  usd_lt?: InputMaybe<Scalars['String']>;
  usd_lte?: InputMaybe<Scalars['String']>;
  usd_not?: InputMaybe<Scalars['String']>;
  usd_not_contains?: InputMaybe<Scalars['String']>;
  usd_not_contains_nocase?: InputMaybe<Scalars['String']>;
  usd_not_ends_with?: InputMaybe<Scalars['String']>;
  usd_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  usd_not_in?: InputMaybe<Array<Scalars['String']>>;
  usd_not_starts_with?: InputMaybe<Scalars['String']>;
  usd_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  usd_starts_with?: InputMaybe<Scalars['String']>;
  usd_starts_with_nocase?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['BigInt']>;
  version_gt?: InputMaybe<Scalars['BigInt']>;
  version_gte?: InputMaybe<Scalars['BigInt']>;
  version_in?: InputMaybe<Array<Scalars['BigInt']>>;
  version_lt?: InputMaybe<Scalars['BigInt']>;
  version_lte?: InputMaybe<Scalars['BigInt']>;
  version_not?: InputMaybe<Scalars['BigInt']>;
  version_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum Pool_OrderBy {
  Id = 'id',
  LastMintTime = 'lastMintTime',
  Liquidity = 'liquidity',
  LiquidityRewardGrowthX64 = 'liquidityRewardGrowthX64',
  MintedReward = 'mintedReward',
  ReferralLiquidity = 'referralLiquidity',
  ReferralParentTokenPositionRewardGrowthX64 = 'referralParentTokenPositionRewardGrowthX64',
  ReferralParentTokenRewardGrowthX64 = 'referralParentTokenRewardGrowthX64',
  ReferralPosition = 'referralPosition',
  ReferralTokenPositionRewardGrowthX64 = 'referralTokenPositionRewardGrowthX64',
  ReferralTokenRewardGrowthX64 = 'referralTokenRewardGrowthX64',
  RewardPerSecond = 'rewardPerSecond',
  RiskBufferFundLiquidity = 'riskBufferFundLiquidity',
  RiskBufferFundRewardGrowthX64 = 'riskBufferFundRewardGrowthX64',
  Token = 'token',
  TokenDecimals = 'token__decimals',
  TokenId = 'token__id',
  TokenName = 'token__name',
  TokenReferralDiscountRate = 'token__referralDiscountRate',
  TokenSymbol = 'token__symbol',
  TokenTradingFeeRate = 'token__tradingFeeRate',
  Usd = 'usd',
  UsdDecimals = 'usd__decimals',
  UsdId = 'usd__id',
  UsdName = 'usd__name',
  UsdReferralDiscountRate = 'usd__referralDiscountRate',
  UsdSymbol = 'usd__symbol',
  UsdTradingFeeRate = 'usd__tradingFeeRate',
  Version = 'version'
}

export type Position = {
  __typename?: 'Position';
  /** the owner of the position */
  account: Account;
  /** format: <account>:<pool address> */
  id: Scalars['ID'];
  /** the entry price of the long position, as a Q64.96 */
  longEntryPriceX96: Scalars['BigInt'];
  /** the position value of the long position */
  longPosition: Scalars['BigDecimal'];
  /** the size of the long position */
  longSize: Scalars['BigDecimal'];
  pool: Pool;
  /** the entry price of short the position, as a Q64.96 */
  shortEntryPriceX96: Scalars['BigInt'];
  /** the position value of the short position */
  shortPosition: Scalars['BigDecimal'];
  /** the size of the short position */
  shortSize: Scalars['BigDecimal'];
};

export type PositionV2 = {
  __typename?: 'PositionV2';
  /** the owner of the position */
  account: Account;
  /** format: <account>:<market address> */
  id: Scalars['ID'];
  /** the entry price of the long position, as a Q64.96 */
  longEntryPriceX96: Scalars['BigInt'];
  /** the position value of the long position */
  longPosition: Scalars['BigDecimal'];
  /** the size of the long position */
  longSize: Scalars['BigDecimal'];
  market: Market;
  /** the entry price of short the position, as a Q64.96 */
  shortEntryPriceX96: Scalars['BigInt'];
  /** the position value of the short position */
  shortPosition: Scalars['BigDecimal'];
  /** the size of the short position */
  shortSize: Scalars['BigDecimal'];
};

export type PositionV2_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['String']>;
  account_?: InputMaybe<Account_Filter>;
  account_contains?: InputMaybe<Scalars['String']>;
  account_contains_nocase?: InputMaybe<Scalars['String']>;
  account_ends_with?: InputMaybe<Scalars['String']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_gt?: InputMaybe<Scalars['String']>;
  account_gte?: InputMaybe<Scalars['String']>;
  account_in?: InputMaybe<Array<Scalars['String']>>;
  account_lt?: InputMaybe<Scalars['String']>;
  account_lte?: InputMaybe<Scalars['String']>;
  account_not?: InputMaybe<Scalars['String']>;
  account_not_contains?: InputMaybe<Scalars['String']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']>;
  account_not_ends_with?: InputMaybe<Scalars['String']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_not_starts_with?: InputMaybe<Scalars['String']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_starts_with?: InputMaybe<Scalars['String']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']>;
  and?: InputMaybe<Array<InputMaybe<PositionV2_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  longEntryPriceX96?: InputMaybe<Scalars['BigInt']>;
  longEntryPriceX96_gt?: InputMaybe<Scalars['BigInt']>;
  longEntryPriceX96_gte?: InputMaybe<Scalars['BigInt']>;
  longEntryPriceX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  longEntryPriceX96_lt?: InputMaybe<Scalars['BigInt']>;
  longEntryPriceX96_lte?: InputMaybe<Scalars['BigInt']>;
  longEntryPriceX96_not?: InputMaybe<Scalars['BigInt']>;
  longEntryPriceX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  longPosition?: InputMaybe<Scalars['BigDecimal']>;
  longPosition_gt?: InputMaybe<Scalars['BigDecimal']>;
  longPosition_gte?: InputMaybe<Scalars['BigDecimal']>;
  longPosition_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  longPosition_lt?: InputMaybe<Scalars['BigDecimal']>;
  longPosition_lte?: InputMaybe<Scalars['BigDecimal']>;
  longPosition_not?: InputMaybe<Scalars['BigDecimal']>;
  longPosition_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  longSize?: InputMaybe<Scalars['BigDecimal']>;
  longSize_gt?: InputMaybe<Scalars['BigDecimal']>;
  longSize_gte?: InputMaybe<Scalars['BigDecimal']>;
  longSize_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  longSize_lt?: InputMaybe<Scalars['BigDecimal']>;
  longSize_lte?: InputMaybe<Scalars['BigDecimal']>;
  longSize_not?: InputMaybe<Scalars['BigDecimal']>;
  longSize_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<PositionV2_Filter>>>;
  shortEntryPriceX96?: InputMaybe<Scalars['BigInt']>;
  shortEntryPriceX96_gt?: InputMaybe<Scalars['BigInt']>;
  shortEntryPriceX96_gte?: InputMaybe<Scalars['BigInt']>;
  shortEntryPriceX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  shortEntryPriceX96_lt?: InputMaybe<Scalars['BigInt']>;
  shortEntryPriceX96_lte?: InputMaybe<Scalars['BigInt']>;
  shortEntryPriceX96_not?: InputMaybe<Scalars['BigInt']>;
  shortEntryPriceX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  shortPosition?: InputMaybe<Scalars['BigDecimal']>;
  shortPosition_gt?: InputMaybe<Scalars['BigDecimal']>;
  shortPosition_gte?: InputMaybe<Scalars['BigDecimal']>;
  shortPosition_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  shortPosition_lt?: InputMaybe<Scalars['BigDecimal']>;
  shortPosition_lte?: InputMaybe<Scalars['BigDecimal']>;
  shortPosition_not?: InputMaybe<Scalars['BigDecimal']>;
  shortPosition_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  shortSize?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_gt?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_gte?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  shortSize_lt?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_lte?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_not?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum PositionV2_OrderBy {
  Account = 'account',
  AccountClaimedRewardFromEqu = 'account__claimedRewardFromEQU',
  AccountClaimedRewardFromLiquidity = 'account__claimedRewardFromLiquidity',
  AccountId = 'account__id',
  AccountReferralFee = 'account__referralFee',
  AccountReferralFeeV2 = 'account__referralFeeV2',
  AccountReferralParentToken = 'account__referralParentToken',
  AccountReferralToken = 'account__referralToken',
  Id = 'id',
  LongEntryPriceX96 = 'longEntryPriceX96',
  LongPosition = 'longPosition',
  LongSize = 'longSize',
  Market = 'market',
  MarketId = 'market__id',
  MarketSymbol = 'market__symbol',
  ShortEntryPriceX96 = 'shortEntryPriceX96',
  ShortPosition = 'shortPosition',
  ShortSize = 'shortSize'
}

export type Position_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['String']>;
  account_?: InputMaybe<Account_Filter>;
  account_contains?: InputMaybe<Scalars['String']>;
  account_contains_nocase?: InputMaybe<Scalars['String']>;
  account_ends_with?: InputMaybe<Scalars['String']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_gt?: InputMaybe<Scalars['String']>;
  account_gte?: InputMaybe<Scalars['String']>;
  account_in?: InputMaybe<Array<Scalars['String']>>;
  account_lt?: InputMaybe<Scalars['String']>;
  account_lte?: InputMaybe<Scalars['String']>;
  account_not?: InputMaybe<Scalars['String']>;
  account_not_contains?: InputMaybe<Scalars['String']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']>;
  account_not_ends_with?: InputMaybe<Scalars['String']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_not_starts_with?: InputMaybe<Scalars['String']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_starts_with?: InputMaybe<Scalars['String']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']>;
  and?: InputMaybe<Array<InputMaybe<Position_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  longEntryPriceX96?: InputMaybe<Scalars['BigInt']>;
  longEntryPriceX96_gt?: InputMaybe<Scalars['BigInt']>;
  longEntryPriceX96_gte?: InputMaybe<Scalars['BigInt']>;
  longEntryPriceX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  longEntryPriceX96_lt?: InputMaybe<Scalars['BigInt']>;
  longEntryPriceX96_lte?: InputMaybe<Scalars['BigInt']>;
  longEntryPriceX96_not?: InputMaybe<Scalars['BigInt']>;
  longEntryPriceX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  longPosition?: InputMaybe<Scalars['BigDecimal']>;
  longPosition_gt?: InputMaybe<Scalars['BigDecimal']>;
  longPosition_gte?: InputMaybe<Scalars['BigDecimal']>;
  longPosition_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  longPosition_lt?: InputMaybe<Scalars['BigDecimal']>;
  longPosition_lte?: InputMaybe<Scalars['BigDecimal']>;
  longPosition_not?: InputMaybe<Scalars['BigDecimal']>;
  longPosition_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  longSize?: InputMaybe<Scalars['BigDecimal']>;
  longSize_gt?: InputMaybe<Scalars['BigDecimal']>;
  longSize_gte?: InputMaybe<Scalars['BigDecimal']>;
  longSize_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  longSize_lt?: InputMaybe<Scalars['BigDecimal']>;
  longSize_lte?: InputMaybe<Scalars['BigDecimal']>;
  longSize_not?: InputMaybe<Scalars['BigDecimal']>;
  longSize_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<Position_Filter>>>;
  pool?: InputMaybe<Scalars['String']>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars['String']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_ends_with?: InputMaybe<Scalars['String']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_gt?: InputMaybe<Scalars['String']>;
  pool_gte?: InputMaybe<Scalars['String']>;
  pool_in?: InputMaybe<Array<Scalars['String']>>;
  pool_lt?: InputMaybe<Scalars['String']>;
  pool_lte?: InputMaybe<Scalars['String']>;
  pool_not?: InputMaybe<Scalars['String']>;
  pool_not_contains?: InputMaybe<Scalars['String']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_not_in?: InputMaybe<Array<Scalars['String']>>;
  pool_not_starts_with?: InputMaybe<Scalars['String']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pool_starts_with?: InputMaybe<Scalars['String']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']>;
  shortEntryPriceX96?: InputMaybe<Scalars['BigInt']>;
  shortEntryPriceX96_gt?: InputMaybe<Scalars['BigInt']>;
  shortEntryPriceX96_gte?: InputMaybe<Scalars['BigInt']>;
  shortEntryPriceX96_in?: InputMaybe<Array<Scalars['BigInt']>>;
  shortEntryPriceX96_lt?: InputMaybe<Scalars['BigInt']>;
  shortEntryPriceX96_lte?: InputMaybe<Scalars['BigInt']>;
  shortEntryPriceX96_not?: InputMaybe<Scalars['BigInt']>;
  shortEntryPriceX96_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  shortPosition?: InputMaybe<Scalars['BigDecimal']>;
  shortPosition_gt?: InputMaybe<Scalars['BigDecimal']>;
  shortPosition_gte?: InputMaybe<Scalars['BigDecimal']>;
  shortPosition_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  shortPosition_lt?: InputMaybe<Scalars['BigDecimal']>;
  shortPosition_lte?: InputMaybe<Scalars['BigDecimal']>;
  shortPosition_not?: InputMaybe<Scalars['BigDecimal']>;
  shortPosition_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  shortSize?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_gt?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_gte?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  shortSize_lt?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_lte?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_not?: InputMaybe<Scalars['BigDecimal']>;
  shortSize_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum Position_OrderBy {
  Account = 'account',
  AccountClaimedRewardFromEqu = 'account__claimedRewardFromEQU',
  AccountClaimedRewardFromLiquidity = 'account__claimedRewardFromLiquidity',
  AccountId = 'account__id',
  AccountReferralFee = 'account__referralFee',
  AccountReferralFeeV2 = 'account__referralFeeV2',
  AccountReferralParentToken = 'account__referralParentToken',
  AccountReferralToken = 'account__referralToken',
  Id = 'id',
  LongEntryPriceX96 = 'longEntryPriceX96',
  LongPosition = 'longPosition',
  LongSize = 'longSize',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolLastMintTime = 'pool__lastMintTime',
  PoolLiquidity = 'pool__liquidity',
  PoolLiquidityRewardGrowthX64 = 'pool__liquidityRewardGrowthX64',
  PoolMintedReward = 'pool__mintedReward',
  PoolReferralLiquidity = 'pool__referralLiquidity',
  PoolReferralParentTokenPositionRewardGrowthX64 = 'pool__referralParentTokenPositionRewardGrowthX64',
  PoolReferralParentTokenRewardGrowthX64 = 'pool__referralParentTokenRewardGrowthX64',
  PoolReferralPosition = 'pool__referralPosition',
  PoolReferralTokenPositionRewardGrowthX64 = 'pool__referralTokenPositionRewardGrowthX64',
  PoolReferralTokenRewardGrowthX64 = 'pool__referralTokenRewardGrowthX64',
  PoolRewardPerSecond = 'pool__rewardPerSecond',
  PoolRiskBufferFundLiquidity = 'pool__riskBufferFundLiquidity',
  PoolRiskBufferFundRewardGrowthX64 = 'pool__riskBufferFundRewardGrowthX64',
  PoolVersion = 'pool__version',
  ShortEntryPriceX96 = 'shortEntryPriceX96',
  ShortPosition = 'shortPosition',
  ShortSize = 'shortSize'
}

export type Query = {
  __typename?: 'Query';
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  account?: Maybe<Account>;
  accounts: Array<Account>;
  aggregationClaimHistories: Array<AggregationClaimHistory>;
  aggregationClaimHistory?: Maybe<AggregationClaimHistory>;
  architect?: Maybe<Architect>;
  architects: Array<Architect>;
  claimHistories: Array<ClaimHistory>;
  claimHistory?: Maybe<ClaimHistory>;
  liquidityPosition?: Maybe<LiquidityPosition>;
  liquidityPositionV2?: Maybe<LiquidityPositionV2>;
  liquidityPositionV2S: Array<LiquidityPositionV2>;
  liquidityPositions: Array<LiquidityPosition>;
  liquidityReward?: Maybe<LiquidityReward>;
  liquidityRewards: Array<LiquidityReward>;
  market?: Maybe<Market>;
  markets: Array<Market>;
  mintHistories: Array<MintHistory>;
  mintHistory?: Maybe<MintHistory>;
  pool?: Maybe<Pool>;
  pools: Array<Pool>;
  position?: Maybe<Position>;
  positionV2?: Maybe<PositionV2>;
  positionV2S: Array<PositionV2>;
  positions: Array<Position>;
  referee?: Maybe<Referee>;
  referees: Array<Referee>;
  referralCode?: Maybe<ReferralCode>;
  referralCodes: Array<ReferralCode>;
  referralFeeReward?: Maybe<ReferralFeeReward>;
  referralFeeRewardDetail?: Maybe<ReferralFeeRewardDetail>;
  referralFeeRewardDetailV2?: Maybe<ReferralFeeRewardDetailV2>;
  referralFeeRewardDetailV2S: Array<ReferralFeeRewardDetailV2>;
  referralFeeRewardDetails: Array<ReferralFeeRewardDetail>;
  referralFeeRewards: Array<ReferralFeeReward>;
  referralReward?: Maybe<ReferralReward>;
  referralRewardV2?: Maybe<ReferralRewardV2>;
  referralRewardV2S: Array<ReferralRewardV2>;
  referralRewards: Array<ReferralReward>;
  referrer?: Maybe<Referrer>;
  referrers: Array<Referrer>;
  rewardFarmConfig?: Maybe<RewardFarmConfig>;
  rewardFarmConfigs: Array<RewardFarmConfig>;
  riskBufferFundReward?: Maybe<RiskBufferFundReward>;
  riskBufferFundRewards: Array<RiskBufferFundReward>;
  stakeDetail?: Maybe<StakeDetail>;
  stakeDetails: Array<StakeDetail>;
  stakeStatistic?: Maybe<StakeStatistic>;
  stakeStatistics: Array<StakeStatistic>;
  token?: Maybe<Token>;
  tokens: Array<Token>;
};


export type Query_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};


export type QueryAccountArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAccountsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Account_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Account_Filter>;
};


export type QueryAggregationClaimHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AggregationClaimHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AggregationClaimHistory_Filter>;
};


export type QueryAggregationClaimHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryArchitectArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryArchitectsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Architect_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Architect_Filter>;
};


export type QueryClaimHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ClaimHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ClaimHistory_Filter>;
};


export type QueryClaimHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryLiquidityPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryLiquidityPositionV2Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryLiquidityPositionV2SArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LiquidityPositionV2_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidityPositionV2_Filter>;
};


export type QueryLiquidityPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LiquidityPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidityPosition_Filter>;
};


export type QueryLiquidityRewardArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryLiquidityRewardsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LiquidityReward_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidityReward_Filter>;
};


export type QueryMarketArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryMarketsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Market_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Market_Filter>;
};


export type QueryMintHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MintHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MintHistory_Filter>;
};


export type QueryMintHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Pool_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Pool_Filter>;
};


export type QueryPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPositionV2Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPositionV2SArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PositionV2_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PositionV2_Filter>;
};


export type QueryPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Position_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Position_Filter>;
};


export type QueryRefereeArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRefereesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Referee_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Referee_Filter>;
};


export type QueryReferralCodeArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryReferralCodesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReferralCode_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ReferralCode_Filter>;
};


export type QueryReferralFeeRewardArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryReferralFeeRewardDetailArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryReferralFeeRewardDetailV2Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryReferralFeeRewardDetailV2SArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReferralFeeRewardDetailV2_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ReferralFeeRewardDetailV2_Filter>;
};


export type QueryReferralFeeRewardDetailsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReferralFeeRewardDetail_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ReferralFeeRewardDetail_Filter>;
};


export type QueryReferralFeeRewardsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReferralFeeReward_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ReferralFeeReward_Filter>;
};


export type QueryReferralRewardArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryReferralRewardV2Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryReferralRewardV2SArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReferralRewardV2_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ReferralRewardV2_Filter>;
};


export type QueryReferralRewardsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReferralReward_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ReferralReward_Filter>;
};


export type QueryReferrerArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryReferrersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Referrer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Referrer_Filter>;
};


export type QueryRewardFarmConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRewardFarmConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RewardFarmConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RewardFarmConfig_Filter>;
};


export type QueryRiskBufferFundRewardArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryRiskBufferFundRewardsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RiskBufferFundReward_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RiskBufferFundReward_Filter>;
};


export type QueryStakeDetailArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryStakeDetailsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<StakeDetail_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<StakeDetail_Filter>;
};


export type QueryStakeStatisticArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryStakeStatisticsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<StakeStatistic_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<StakeStatistic_Filter>;
};


export type QueryTokenArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokensArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Token_Filter>;
};

export type Referee = {
  __typename?: 'Referee';
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  id: Scalars['Bytes'];
  referralCode: ReferralCode;
  referralToken: Referrer;
  txHash: Scalars['Bytes'];
};

export type Referee_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Referee_Filter>>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  or?: InputMaybe<Array<InputMaybe<Referee_Filter>>>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCode_?: InputMaybe<ReferralCode_Filter>;
  referralCode_contains?: InputMaybe<Scalars['String']>;
  referralCode_contains_nocase?: InputMaybe<Scalars['String']>;
  referralCode_ends_with?: InputMaybe<Scalars['String']>;
  referralCode_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referralCode_gt?: InputMaybe<Scalars['String']>;
  referralCode_gte?: InputMaybe<Scalars['String']>;
  referralCode_in?: InputMaybe<Array<Scalars['String']>>;
  referralCode_lt?: InputMaybe<Scalars['String']>;
  referralCode_lte?: InputMaybe<Scalars['String']>;
  referralCode_not?: InputMaybe<Scalars['String']>;
  referralCode_not_contains?: InputMaybe<Scalars['String']>;
  referralCode_not_contains_nocase?: InputMaybe<Scalars['String']>;
  referralCode_not_ends_with?: InputMaybe<Scalars['String']>;
  referralCode_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referralCode_not_in?: InputMaybe<Array<Scalars['String']>>;
  referralCode_not_starts_with?: InputMaybe<Scalars['String']>;
  referralCode_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  referralCode_starts_with?: InputMaybe<Scalars['String']>;
  referralCode_starts_with_nocase?: InputMaybe<Scalars['String']>;
  referralToken?: InputMaybe<Scalars['String']>;
  referralToken_?: InputMaybe<Referrer_Filter>;
  referralToken_contains?: InputMaybe<Scalars['String']>;
  referralToken_contains_nocase?: InputMaybe<Scalars['String']>;
  referralToken_ends_with?: InputMaybe<Scalars['String']>;
  referralToken_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referralToken_gt?: InputMaybe<Scalars['String']>;
  referralToken_gte?: InputMaybe<Scalars['String']>;
  referralToken_in?: InputMaybe<Array<Scalars['String']>>;
  referralToken_lt?: InputMaybe<Scalars['String']>;
  referralToken_lte?: InputMaybe<Scalars['String']>;
  referralToken_not?: InputMaybe<Scalars['String']>;
  referralToken_not_contains?: InputMaybe<Scalars['String']>;
  referralToken_not_contains_nocase?: InputMaybe<Scalars['String']>;
  referralToken_not_ends_with?: InputMaybe<Scalars['String']>;
  referralToken_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referralToken_not_in?: InputMaybe<Array<Scalars['String']>>;
  referralToken_not_starts_with?: InputMaybe<Scalars['String']>;
  referralToken_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  referralToken_starts_with?: InputMaybe<Scalars['String']>;
  referralToken_starts_with_nocase?: InputMaybe<Scalars['String']>;
  txHash?: InputMaybe<Scalars['Bytes']>;
  txHash_contains?: InputMaybe<Scalars['Bytes']>;
  txHash_gt?: InputMaybe<Scalars['Bytes']>;
  txHash_gte?: InputMaybe<Scalars['Bytes']>;
  txHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  txHash_lt?: InputMaybe<Scalars['Bytes']>;
  txHash_lte?: InputMaybe<Scalars['Bytes']>;
  txHash_not?: InputMaybe<Scalars['Bytes']>;
  txHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  txHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
};

export enum Referee_OrderBy {
  BlockNumber = 'blockNumber',
  BlockTimestamp = 'blockTimestamp',
  Id = 'id',
  ReferralCode = 'referralCode',
  ReferralCodeBlockNumber = 'referralCode__blockNumber',
  ReferralCodeBlockTimestamp = 'referralCode__blockTimestamp',
  ReferralCodeId = 'referralCode__id',
  ReferralCodeRefereeCount = 'referralCode__refereeCount',
  ReferralCodeTxHash = 'referralCode__txHash',
  ReferralToken = 'referralToken',
  ReferralTokenAccount = 'referralToken__account',
  ReferralTokenId = 'referralToken__id',
  ReferralTokenMintTimestamp = 'referralToken__mintTimestamp',
  ReferralTokenMintTxHash = 'referralToken__mintTxHash',
  ReferralTokenMinter = 'referralToken__minter',
  ReferralTokenRefereeCount = 'referralToken__refereeCount',
  ReferralTokenReferralParentFee = 'referralToken__referralParentFee',
  ReferralTokenReferralParentFeeV2 = 'referralToken__referralParentFeeV2',
  ReferralTokenTokenUri = 'referralToken__tokenURI',
  ReferralTokenType = 'referralToken__type',
  TxHash = 'txHash'
}

export type ReferralCode = {
  __typename?: 'ReferralCode';
  accounts: Array<Account>;
  /** the block when the referral code was created */
  blockNumber: Scalars['BigInt'];
  /** the time when the referral code was created */
  blockTimestamp: Scalars['BigInt'];
  /** referral code */
  id: Scalars['ID'];
  /** the count of referee */
  refereeCount: Scalars['Int'];
  referees: Array<Referee>;
  referralFeeReward: ReferralFeeReward;
  /** the owner of the referral code */
  referralToken: Referrer;
  /** the tx hash when the referral code was created. */
  txHash: Scalars['Bytes'];
};


export type ReferralCodeAccountsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Account_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Account_Filter>;
};


export type ReferralCodeRefereesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Referee_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Referee_Filter>;
};

export type ReferralCode_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  accounts_?: InputMaybe<Account_Filter>;
  and?: InputMaybe<Array<InputMaybe<ReferralCode_Filter>>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<ReferralCode_Filter>>>;
  refereeCount?: InputMaybe<Scalars['Int']>;
  refereeCount_gt?: InputMaybe<Scalars['Int']>;
  refereeCount_gte?: InputMaybe<Scalars['Int']>;
  refereeCount_in?: InputMaybe<Array<Scalars['Int']>>;
  refereeCount_lt?: InputMaybe<Scalars['Int']>;
  refereeCount_lte?: InputMaybe<Scalars['Int']>;
  refereeCount_not?: InputMaybe<Scalars['Int']>;
  refereeCount_not_in?: InputMaybe<Array<Scalars['Int']>>;
  referees_?: InputMaybe<Referee_Filter>;
  referralFeeReward?: InputMaybe<Scalars['String']>;
  referralFeeReward_?: InputMaybe<ReferralFeeReward_Filter>;
  referralFeeReward_contains?: InputMaybe<Scalars['String']>;
  referralFeeReward_contains_nocase?: InputMaybe<Scalars['String']>;
  referralFeeReward_ends_with?: InputMaybe<Scalars['String']>;
  referralFeeReward_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referralFeeReward_gt?: InputMaybe<Scalars['String']>;
  referralFeeReward_gte?: InputMaybe<Scalars['String']>;
  referralFeeReward_in?: InputMaybe<Array<Scalars['String']>>;
  referralFeeReward_lt?: InputMaybe<Scalars['String']>;
  referralFeeReward_lte?: InputMaybe<Scalars['String']>;
  referralFeeReward_not?: InputMaybe<Scalars['String']>;
  referralFeeReward_not_contains?: InputMaybe<Scalars['String']>;
  referralFeeReward_not_contains_nocase?: InputMaybe<Scalars['String']>;
  referralFeeReward_not_ends_with?: InputMaybe<Scalars['String']>;
  referralFeeReward_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referralFeeReward_not_in?: InputMaybe<Array<Scalars['String']>>;
  referralFeeReward_not_starts_with?: InputMaybe<Scalars['String']>;
  referralFeeReward_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  referralFeeReward_starts_with?: InputMaybe<Scalars['String']>;
  referralFeeReward_starts_with_nocase?: InputMaybe<Scalars['String']>;
  referralToken?: InputMaybe<Scalars['String']>;
  referralToken_?: InputMaybe<Referrer_Filter>;
  referralToken_contains?: InputMaybe<Scalars['String']>;
  referralToken_contains_nocase?: InputMaybe<Scalars['String']>;
  referralToken_ends_with?: InputMaybe<Scalars['String']>;
  referralToken_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referralToken_gt?: InputMaybe<Scalars['String']>;
  referralToken_gte?: InputMaybe<Scalars['String']>;
  referralToken_in?: InputMaybe<Array<Scalars['String']>>;
  referralToken_lt?: InputMaybe<Scalars['String']>;
  referralToken_lte?: InputMaybe<Scalars['String']>;
  referralToken_not?: InputMaybe<Scalars['String']>;
  referralToken_not_contains?: InputMaybe<Scalars['String']>;
  referralToken_not_contains_nocase?: InputMaybe<Scalars['String']>;
  referralToken_not_ends_with?: InputMaybe<Scalars['String']>;
  referralToken_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referralToken_not_in?: InputMaybe<Array<Scalars['String']>>;
  referralToken_not_starts_with?: InputMaybe<Scalars['String']>;
  referralToken_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  referralToken_starts_with?: InputMaybe<Scalars['String']>;
  referralToken_starts_with_nocase?: InputMaybe<Scalars['String']>;
  txHash?: InputMaybe<Scalars['Bytes']>;
  txHash_contains?: InputMaybe<Scalars['Bytes']>;
  txHash_gt?: InputMaybe<Scalars['Bytes']>;
  txHash_gte?: InputMaybe<Scalars['Bytes']>;
  txHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  txHash_lt?: InputMaybe<Scalars['Bytes']>;
  txHash_lte?: InputMaybe<Scalars['Bytes']>;
  txHash_not?: InputMaybe<Scalars['Bytes']>;
  txHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  txHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
};

export enum ReferralCode_OrderBy {
  Accounts = 'accounts',
  BlockNumber = 'blockNumber',
  BlockTimestamp = 'blockTimestamp',
  Id = 'id',
  RefereeCount = 'refereeCount',
  Referees = 'referees',
  ReferralFeeReward = 'referralFeeReward',
  ReferralFeeRewardClaimed = 'referralFeeReward__claimed',
  ReferralFeeRewardId = 'referralFeeReward__id',
  ReferralFeeRewardTotalClaimed = 'referralFeeReward__totalClaimed',
  ReferralFeeRewardUnclaimed = 'referralFeeReward__unclaimed',
  ReferralToken = 'referralToken',
  ReferralTokenAccount = 'referralToken__account',
  ReferralTokenId = 'referralToken__id',
  ReferralTokenMintTimestamp = 'referralToken__mintTimestamp',
  ReferralTokenMintTxHash = 'referralToken__mintTxHash',
  ReferralTokenMinter = 'referralToken__minter',
  ReferralTokenRefereeCount = 'referralToken__refereeCount',
  ReferralTokenReferralParentFee = 'referralToken__referralParentFee',
  ReferralTokenReferralParentFeeV2 = 'referralToken__referralParentFeeV2',
  ReferralTokenTokenUri = 'referralToken__tokenURI',
  ReferralTokenType = 'referralToken__type',
  TxHash = 'txHash'
}

export type ReferralFeeReward = {
  __typename?: 'ReferralFeeReward';
  /** claimed reward */
  claimed: Scalars['BigDecimal'];
  /** format: <transaction hash>:<log index>:<version> */
  id: Scalars['ID'];
  /** total claimed reward */
  totalClaimed: Scalars['BigDecimal'];
  /** unclaimed reward */
  unclaimed: Scalars['BigDecimal'];
};

export type ReferralFeeRewardDetail = {
  __typename?: 'ReferralFeeRewardDetail';
  /** format: <pool address>:<NFT token id> */
  id: Scalars['ID'];
  pool: Pool;
  referralToken: Referrer;
  /** unclaimed reward */
  unclaimed: Scalars['BigDecimal'];
};

export type ReferralFeeRewardDetailV2 = {
  __typename?: 'ReferralFeeRewardDetailV2';
  /** format: <market address>:<NFT token id> */
  id: Scalars['ID'];
  market: Market;
  referralToken: Referrer;
  /** unclaimed reward */
  unclaimed: Scalars['BigDecimal'];
};

export type ReferralFeeRewardDetailV2_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ReferralFeeRewardDetailV2_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<ReferralFeeRewardDetailV2_Filter>>>;
  referralToken?: InputMaybe<Scalars['String']>;
  referralToken_?: InputMaybe<Referrer_Filter>;
  referralToken_contains?: InputMaybe<Scalars['String']>;
  referralToken_contains_nocase?: InputMaybe<Scalars['String']>;
  referralToken_ends_with?: InputMaybe<Scalars['String']>;
  referralToken_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referralToken_gt?: InputMaybe<Scalars['String']>;
  referralToken_gte?: InputMaybe<Scalars['String']>;
  referralToken_in?: InputMaybe<Array<Scalars['String']>>;
  referralToken_lt?: InputMaybe<Scalars['String']>;
  referralToken_lte?: InputMaybe<Scalars['String']>;
  referralToken_not?: InputMaybe<Scalars['String']>;
  referralToken_not_contains?: InputMaybe<Scalars['String']>;
  referralToken_not_contains_nocase?: InputMaybe<Scalars['String']>;
  referralToken_not_ends_with?: InputMaybe<Scalars['String']>;
  referralToken_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referralToken_not_in?: InputMaybe<Array<Scalars['String']>>;
  referralToken_not_starts_with?: InputMaybe<Scalars['String']>;
  referralToken_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  referralToken_starts_with?: InputMaybe<Scalars['String']>;
  referralToken_starts_with_nocase?: InputMaybe<Scalars['String']>;
  unclaimed?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_gt?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_gte?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  unclaimed_lt?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_lte?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_not?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum ReferralFeeRewardDetailV2_OrderBy {
  Id = 'id',
  Market = 'market',
  MarketId = 'market__id',
  MarketSymbol = 'market__symbol',
  ReferralToken = 'referralToken',
  ReferralTokenAccount = 'referralToken__account',
  ReferralTokenId = 'referralToken__id',
  ReferralTokenMintTimestamp = 'referralToken__mintTimestamp',
  ReferralTokenMintTxHash = 'referralToken__mintTxHash',
  ReferralTokenMinter = 'referralToken__minter',
  ReferralTokenRefereeCount = 'referralToken__refereeCount',
  ReferralTokenReferralParentFee = 'referralToken__referralParentFee',
  ReferralTokenReferralParentFeeV2 = 'referralToken__referralParentFeeV2',
  ReferralTokenTokenUri = 'referralToken__tokenURI',
  ReferralTokenType = 'referralToken__type',
  Unclaimed = 'unclaimed'
}

export type ReferralFeeRewardDetail_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ReferralFeeRewardDetail_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<ReferralFeeRewardDetail_Filter>>>;
  pool?: InputMaybe<Scalars['String']>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars['String']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_ends_with?: InputMaybe<Scalars['String']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_gt?: InputMaybe<Scalars['String']>;
  pool_gte?: InputMaybe<Scalars['String']>;
  pool_in?: InputMaybe<Array<Scalars['String']>>;
  pool_lt?: InputMaybe<Scalars['String']>;
  pool_lte?: InputMaybe<Scalars['String']>;
  pool_not?: InputMaybe<Scalars['String']>;
  pool_not_contains?: InputMaybe<Scalars['String']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_not_in?: InputMaybe<Array<Scalars['String']>>;
  pool_not_starts_with?: InputMaybe<Scalars['String']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pool_starts_with?: InputMaybe<Scalars['String']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']>;
  referralToken?: InputMaybe<Scalars['String']>;
  referralToken_?: InputMaybe<Referrer_Filter>;
  referralToken_contains?: InputMaybe<Scalars['String']>;
  referralToken_contains_nocase?: InputMaybe<Scalars['String']>;
  referralToken_ends_with?: InputMaybe<Scalars['String']>;
  referralToken_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referralToken_gt?: InputMaybe<Scalars['String']>;
  referralToken_gte?: InputMaybe<Scalars['String']>;
  referralToken_in?: InputMaybe<Array<Scalars['String']>>;
  referralToken_lt?: InputMaybe<Scalars['String']>;
  referralToken_lte?: InputMaybe<Scalars['String']>;
  referralToken_not?: InputMaybe<Scalars['String']>;
  referralToken_not_contains?: InputMaybe<Scalars['String']>;
  referralToken_not_contains_nocase?: InputMaybe<Scalars['String']>;
  referralToken_not_ends_with?: InputMaybe<Scalars['String']>;
  referralToken_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referralToken_not_in?: InputMaybe<Array<Scalars['String']>>;
  referralToken_not_starts_with?: InputMaybe<Scalars['String']>;
  referralToken_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  referralToken_starts_with?: InputMaybe<Scalars['String']>;
  referralToken_starts_with_nocase?: InputMaybe<Scalars['String']>;
  unclaimed?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_gt?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_gte?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  unclaimed_lt?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_lte?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_not?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum ReferralFeeRewardDetail_OrderBy {
  Id = 'id',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolLastMintTime = 'pool__lastMintTime',
  PoolLiquidity = 'pool__liquidity',
  PoolLiquidityRewardGrowthX64 = 'pool__liquidityRewardGrowthX64',
  PoolMintedReward = 'pool__mintedReward',
  PoolReferralLiquidity = 'pool__referralLiquidity',
  PoolReferralParentTokenPositionRewardGrowthX64 = 'pool__referralParentTokenPositionRewardGrowthX64',
  PoolReferralParentTokenRewardGrowthX64 = 'pool__referralParentTokenRewardGrowthX64',
  PoolReferralPosition = 'pool__referralPosition',
  PoolReferralTokenPositionRewardGrowthX64 = 'pool__referralTokenPositionRewardGrowthX64',
  PoolReferralTokenRewardGrowthX64 = 'pool__referralTokenRewardGrowthX64',
  PoolRewardPerSecond = 'pool__rewardPerSecond',
  PoolRiskBufferFundLiquidity = 'pool__riskBufferFundLiquidity',
  PoolRiskBufferFundRewardGrowthX64 = 'pool__riskBufferFundRewardGrowthX64',
  PoolVersion = 'pool__version',
  ReferralToken = 'referralToken',
  ReferralTokenAccount = 'referralToken__account',
  ReferralTokenId = 'referralToken__id',
  ReferralTokenMintTimestamp = 'referralToken__mintTimestamp',
  ReferralTokenMintTxHash = 'referralToken__mintTxHash',
  ReferralTokenMinter = 'referralToken__minter',
  ReferralTokenRefereeCount = 'referralToken__refereeCount',
  ReferralTokenReferralParentFee = 'referralToken__referralParentFee',
  ReferralTokenReferralParentFeeV2 = 'referralToken__referralParentFeeV2',
  ReferralTokenTokenUri = 'referralToken__tokenURI',
  ReferralTokenType = 'referralToken__type',
  Unclaimed = 'unclaimed'
}

export type ReferralFeeReward_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ReferralFeeReward_Filter>>>;
  claimed?: InputMaybe<Scalars['BigDecimal']>;
  claimed_gt?: InputMaybe<Scalars['BigDecimal']>;
  claimed_gte?: InputMaybe<Scalars['BigDecimal']>;
  claimed_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  claimed_lt?: InputMaybe<Scalars['BigDecimal']>;
  claimed_lte?: InputMaybe<Scalars['BigDecimal']>;
  claimed_not?: InputMaybe<Scalars['BigDecimal']>;
  claimed_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  or?: InputMaybe<Array<InputMaybe<ReferralFeeReward_Filter>>>;
  totalClaimed?: InputMaybe<Scalars['BigDecimal']>;
  totalClaimed_gt?: InputMaybe<Scalars['BigDecimal']>;
  totalClaimed_gte?: InputMaybe<Scalars['BigDecimal']>;
  totalClaimed_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalClaimed_lt?: InputMaybe<Scalars['BigDecimal']>;
  totalClaimed_lte?: InputMaybe<Scalars['BigDecimal']>;
  totalClaimed_not?: InputMaybe<Scalars['BigDecimal']>;
  totalClaimed_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  unclaimed?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_gt?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_gte?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  unclaimed_lt?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_lte?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_not?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum ReferralFeeReward_OrderBy {
  Claimed = 'claimed',
  Id = 'id',
  TotalClaimed = 'totalClaimed',
  Unclaimed = 'unclaimed'
}

export type ReferralReward = {
  __typename?: 'ReferralReward';
  /** format: <pool address>:<NFT token id> */
  id: Scalars['ID'];
  /** the total liquidity provided */
  liquidity: Scalars['BigDecimal'];
  /** liquidity before the change */
  liquidityBefore: Scalars['BigDecimal'];
  /** claimed liquidity reward */
  liquidityClaimed: Scalars['BigDecimal'];
  /** unclaimed liquidity reward */
  liquidityUnclaimed: Scalars['BigDecimal'];
  pool: Pool;
  /** the total position provided */
  position: Scalars['BigDecimal'];
  /** position before the change */
  positionBefore: Scalars['BigDecimal'];
  /** claimed position reward */
  positionClaimed: Scalars['BigDecimal'];
  /** The reward growth per unit of position liquidity, as a Q64.64 */
  positionRewardGrowthX64: Scalars['BigInt'];
  /** unclaimed position reward */
  positionUnclaimed: Scalars['BigDecimal'];
  /** the owner of the reward */
  referralToken: Referrer;
  /** the reward growth per unit of liquidity, as a Q64.64 */
  rewardGrowthX64: Scalars['BigInt'];
  /** total claimed reward */
  totalClaimed: Scalars['BigDecimal'];
  version: Scalars['BigInt'];
};

export type ReferralRewardV2 = {
  __typename?: 'ReferralRewardV2';
  /** format: <market address>:<NFT token id> */
  id: Scalars['ID'];
  /** the total liquidity provided */
  liquidity: Scalars['BigDecimal'];
  /** liquidity before the change */
  liquidityBefore: Scalars['BigDecimal'];
  market: Market;
  /** the total position provided */
  position: Scalars['BigDecimal'];
  /** position before the change */
  positionBefore: Scalars['BigDecimal'];
  /** the owner of the reward */
  referralToken: Referrer;
};

export type ReferralRewardV2_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ReferralRewardV2_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  liquidity?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidityBefore_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  market?: InputMaybe<Scalars['String']>;
  market_?: InputMaybe<Market_Filter>;
  market_contains?: InputMaybe<Scalars['String']>;
  market_contains_nocase?: InputMaybe<Scalars['String']>;
  market_ends_with?: InputMaybe<Scalars['String']>;
  market_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_gt?: InputMaybe<Scalars['String']>;
  market_gte?: InputMaybe<Scalars['String']>;
  market_in?: InputMaybe<Array<Scalars['String']>>;
  market_lt?: InputMaybe<Scalars['String']>;
  market_lte?: InputMaybe<Scalars['String']>;
  market_not?: InputMaybe<Scalars['String']>;
  market_not_contains?: InputMaybe<Scalars['String']>;
  market_not_contains_nocase?: InputMaybe<Scalars['String']>;
  market_not_ends_with?: InputMaybe<Scalars['String']>;
  market_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  market_not_in?: InputMaybe<Array<Scalars['String']>>;
  market_not_starts_with?: InputMaybe<Scalars['String']>;
  market_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  market_starts_with?: InputMaybe<Scalars['String']>;
  market_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<ReferralRewardV2_Filter>>>;
  position?: InputMaybe<Scalars['BigDecimal']>;
  positionBefore?: InputMaybe<Scalars['BigDecimal']>;
  positionBefore_gt?: InputMaybe<Scalars['BigDecimal']>;
  positionBefore_gte?: InputMaybe<Scalars['BigDecimal']>;
  positionBefore_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  positionBefore_lt?: InputMaybe<Scalars['BigDecimal']>;
  positionBefore_lte?: InputMaybe<Scalars['BigDecimal']>;
  positionBefore_not?: InputMaybe<Scalars['BigDecimal']>;
  positionBefore_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  position_gt?: InputMaybe<Scalars['BigDecimal']>;
  position_gte?: InputMaybe<Scalars['BigDecimal']>;
  position_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  position_lt?: InputMaybe<Scalars['BigDecimal']>;
  position_lte?: InputMaybe<Scalars['BigDecimal']>;
  position_not?: InputMaybe<Scalars['BigDecimal']>;
  position_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  referralToken?: InputMaybe<Scalars['String']>;
  referralToken_?: InputMaybe<Referrer_Filter>;
  referralToken_contains?: InputMaybe<Scalars['String']>;
  referralToken_contains_nocase?: InputMaybe<Scalars['String']>;
  referralToken_ends_with?: InputMaybe<Scalars['String']>;
  referralToken_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referralToken_gt?: InputMaybe<Scalars['String']>;
  referralToken_gte?: InputMaybe<Scalars['String']>;
  referralToken_in?: InputMaybe<Array<Scalars['String']>>;
  referralToken_lt?: InputMaybe<Scalars['String']>;
  referralToken_lte?: InputMaybe<Scalars['String']>;
  referralToken_not?: InputMaybe<Scalars['String']>;
  referralToken_not_contains?: InputMaybe<Scalars['String']>;
  referralToken_not_contains_nocase?: InputMaybe<Scalars['String']>;
  referralToken_not_ends_with?: InputMaybe<Scalars['String']>;
  referralToken_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referralToken_not_in?: InputMaybe<Array<Scalars['String']>>;
  referralToken_not_starts_with?: InputMaybe<Scalars['String']>;
  referralToken_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  referralToken_starts_with?: InputMaybe<Scalars['String']>;
  referralToken_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum ReferralRewardV2_OrderBy {
  Id = 'id',
  Liquidity = 'liquidity',
  LiquidityBefore = 'liquidityBefore',
  Market = 'market',
  MarketId = 'market__id',
  MarketSymbol = 'market__symbol',
  Position = 'position',
  PositionBefore = 'positionBefore',
  ReferralToken = 'referralToken',
  ReferralTokenAccount = 'referralToken__account',
  ReferralTokenId = 'referralToken__id',
  ReferralTokenMintTimestamp = 'referralToken__mintTimestamp',
  ReferralTokenMintTxHash = 'referralToken__mintTxHash',
  ReferralTokenMinter = 'referralToken__minter',
  ReferralTokenRefereeCount = 'referralToken__refereeCount',
  ReferralTokenReferralParentFee = 'referralToken__referralParentFee',
  ReferralTokenReferralParentFeeV2 = 'referralToken__referralParentFeeV2',
  ReferralTokenTokenUri = 'referralToken__tokenURI',
  ReferralTokenType = 'referralToken__type'
}

export type ReferralReward_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ReferralReward_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  liquidity?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidityBefore_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidityClaimed?: InputMaybe<Scalars['BigDecimal']>;
  liquidityClaimed_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityClaimed_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityClaimed_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidityClaimed_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityClaimed_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityClaimed_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidityClaimed_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidityUnclaimed?: InputMaybe<Scalars['BigDecimal']>;
  liquidityUnclaimed_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityUnclaimed_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityUnclaimed_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidityUnclaimed_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityUnclaimed_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityUnclaimed_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidityUnclaimed_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<ReferralReward_Filter>>>;
  pool?: InputMaybe<Scalars['String']>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars['String']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_ends_with?: InputMaybe<Scalars['String']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_gt?: InputMaybe<Scalars['String']>;
  pool_gte?: InputMaybe<Scalars['String']>;
  pool_in?: InputMaybe<Array<Scalars['String']>>;
  pool_lt?: InputMaybe<Scalars['String']>;
  pool_lte?: InputMaybe<Scalars['String']>;
  pool_not?: InputMaybe<Scalars['String']>;
  pool_not_contains?: InputMaybe<Scalars['String']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_not_in?: InputMaybe<Array<Scalars['String']>>;
  pool_not_starts_with?: InputMaybe<Scalars['String']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pool_starts_with?: InputMaybe<Scalars['String']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['BigDecimal']>;
  positionBefore?: InputMaybe<Scalars['BigDecimal']>;
  positionBefore_gt?: InputMaybe<Scalars['BigDecimal']>;
  positionBefore_gte?: InputMaybe<Scalars['BigDecimal']>;
  positionBefore_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  positionBefore_lt?: InputMaybe<Scalars['BigDecimal']>;
  positionBefore_lte?: InputMaybe<Scalars['BigDecimal']>;
  positionBefore_not?: InputMaybe<Scalars['BigDecimal']>;
  positionBefore_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  positionClaimed?: InputMaybe<Scalars['BigDecimal']>;
  positionClaimed_gt?: InputMaybe<Scalars['BigDecimal']>;
  positionClaimed_gte?: InputMaybe<Scalars['BigDecimal']>;
  positionClaimed_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  positionClaimed_lt?: InputMaybe<Scalars['BigDecimal']>;
  positionClaimed_lte?: InputMaybe<Scalars['BigDecimal']>;
  positionClaimed_not?: InputMaybe<Scalars['BigDecimal']>;
  positionClaimed_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  positionRewardGrowthX64?: InputMaybe<Scalars['BigInt']>;
  positionRewardGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  positionRewardGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  positionRewardGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  positionRewardGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  positionRewardGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  positionRewardGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  positionRewardGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  positionUnclaimed?: InputMaybe<Scalars['BigDecimal']>;
  positionUnclaimed_gt?: InputMaybe<Scalars['BigDecimal']>;
  positionUnclaimed_gte?: InputMaybe<Scalars['BigDecimal']>;
  positionUnclaimed_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  positionUnclaimed_lt?: InputMaybe<Scalars['BigDecimal']>;
  positionUnclaimed_lte?: InputMaybe<Scalars['BigDecimal']>;
  positionUnclaimed_not?: InputMaybe<Scalars['BigDecimal']>;
  positionUnclaimed_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  position_gt?: InputMaybe<Scalars['BigDecimal']>;
  position_gte?: InputMaybe<Scalars['BigDecimal']>;
  position_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  position_lt?: InputMaybe<Scalars['BigDecimal']>;
  position_lte?: InputMaybe<Scalars['BigDecimal']>;
  position_not?: InputMaybe<Scalars['BigDecimal']>;
  position_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  referralToken?: InputMaybe<Scalars['String']>;
  referralToken_?: InputMaybe<Referrer_Filter>;
  referralToken_contains?: InputMaybe<Scalars['String']>;
  referralToken_contains_nocase?: InputMaybe<Scalars['String']>;
  referralToken_ends_with?: InputMaybe<Scalars['String']>;
  referralToken_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referralToken_gt?: InputMaybe<Scalars['String']>;
  referralToken_gte?: InputMaybe<Scalars['String']>;
  referralToken_in?: InputMaybe<Array<Scalars['String']>>;
  referralToken_lt?: InputMaybe<Scalars['String']>;
  referralToken_lte?: InputMaybe<Scalars['String']>;
  referralToken_not?: InputMaybe<Scalars['String']>;
  referralToken_not_contains?: InputMaybe<Scalars['String']>;
  referralToken_not_contains_nocase?: InputMaybe<Scalars['String']>;
  referralToken_not_ends_with?: InputMaybe<Scalars['String']>;
  referralToken_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referralToken_not_in?: InputMaybe<Array<Scalars['String']>>;
  referralToken_not_starts_with?: InputMaybe<Scalars['String']>;
  referralToken_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  referralToken_starts_with?: InputMaybe<Scalars['String']>;
  referralToken_starts_with_nocase?: InputMaybe<Scalars['String']>;
  rewardGrowthX64?: InputMaybe<Scalars['BigInt']>;
  rewardGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  rewardGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  rewardGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  rewardGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  rewardGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  rewardGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  rewardGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  totalClaimed?: InputMaybe<Scalars['BigDecimal']>;
  totalClaimed_gt?: InputMaybe<Scalars['BigDecimal']>;
  totalClaimed_gte?: InputMaybe<Scalars['BigDecimal']>;
  totalClaimed_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  totalClaimed_lt?: InputMaybe<Scalars['BigDecimal']>;
  totalClaimed_lte?: InputMaybe<Scalars['BigDecimal']>;
  totalClaimed_not?: InputMaybe<Scalars['BigDecimal']>;
  totalClaimed_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  version?: InputMaybe<Scalars['BigInt']>;
  version_gt?: InputMaybe<Scalars['BigInt']>;
  version_gte?: InputMaybe<Scalars['BigInt']>;
  version_in?: InputMaybe<Array<Scalars['BigInt']>>;
  version_lt?: InputMaybe<Scalars['BigInt']>;
  version_lte?: InputMaybe<Scalars['BigInt']>;
  version_not?: InputMaybe<Scalars['BigInt']>;
  version_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum ReferralReward_OrderBy {
  Id = 'id',
  Liquidity = 'liquidity',
  LiquidityBefore = 'liquidityBefore',
  LiquidityClaimed = 'liquidityClaimed',
  LiquidityUnclaimed = 'liquidityUnclaimed',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolLastMintTime = 'pool__lastMintTime',
  PoolLiquidity = 'pool__liquidity',
  PoolLiquidityRewardGrowthX64 = 'pool__liquidityRewardGrowthX64',
  PoolMintedReward = 'pool__mintedReward',
  PoolReferralLiquidity = 'pool__referralLiquidity',
  PoolReferralParentTokenPositionRewardGrowthX64 = 'pool__referralParentTokenPositionRewardGrowthX64',
  PoolReferralParentTokenRewardGrowthX64 = 'pool__referralParentTokenRewardGrowthX64',
  PoolReferralPosition = 'pool__referralPosition',
  PoolReferralTokenPositionRewardGrowthX64 = 'pool__referralTokenPositionRewardGrowthX64',
  PoolReferralTokenRewardGrowthX64 = 'pool__referralTokenRewardGrowthX64',
  PoolRewardPerSecond = 'pool__rewardPerSecond',
  PoolRiskBufferFundLiquidity = 'pool__riskBufferFundLiquidity',
  PoolRiskBufferFundRewardGrowthX64 = 'pool__riskBufferFundRewardGrowthX64',
  PoolVersion = 'pool__version',
  Position = 'position',
  PositionBefore = 'positionBefore',
  PositionClaimed = 'positionClaimed',
  PositionRewardGrowthX64 = 'positionRewardGrowthX64',
  PositionUnclaimed = 'positionUnclaimed',
  ReferralToken = 'referralToken',
  ReferralTokenAccount = 'referralToken__account',
  ReferralTokenId = 'referralToken__id',
  ReferralTokenMintTimestamp = 'referralToken__mintTimestamp',
  ReferralTokenMintTxHash = 'referralToken__mintTxHash',
  ReferralTokenMinter = 'referralToken__minter',
  ReferralTokenRefereeCount = 'referralToken__refereeCount',
  ReferralTokenReferralParentFee = 'referralToken__referralParentFee',
  ReferralTokenReferralParentFeeV2 = 'referralToken__referralParentFeeV2',
  ReferralTokenTokenUri = 'referralToken__tokenURI',
  ReferralTokenType = 'referralToken__type',
  RewardGrowthX64 = 'rewardGrowthX64',
  TotalClaimed = 'totalClaimed',
  Version = 'version'
}

/** entity that represents NFT of the type member or connector */
export type Referrer = {
  __typename?: 'Referrer';
  /** the owner of the NFT */
  account: Scalars['Bytes'];
  children: Array<Referrer>;
  /** NFT token id */
  id: Scalars['ID'];
  /** the mint time of the NFT */
  mintTimestamp: Scalars['BigInt'];
  /** the mint hash of the NFT */
  mintTxHash: Scalars['Bytes'];
  /** the minter of the NFT */
  minter: Scalars['Bytes'];
  /** the parent of the NFT */
  parent?: Maybe<Referrer>;
  /** the pools in which this NFT has reward */
  pools: Array<Scalars['Bytes']>;
  /** the count of referee */
  refereeCount: Scalars['Int'];
  referralCodes: Array<ReferralCode>;
  /** the referral fee reward of the NFT */
  referralFeeReward: ReferralFeeReward;
  referralFeeRewardDetails: Array<ReferralFeeRewardDetail>;
  referralFeeRewardDetailsV2: Array<ReferralFeeRewardDetailV2>;
  referralFeeRewardV2: ReferralFeeReward;
  /** the amount of referral parent fee */
  referralParentFee: Scalars['BigDecimal'];
  /** the amount of referral parent fee for version 2 */
  referralParentFeeV2: Scalars['BigDecimal'];
  referralRewards: Array<ReferralReward>;
  referralRewardsV2: Array<ReferralRewardV2>;
  /** the uniform resource identifier (URI) of the NFT */
  tokenURI: Scalars['String'];
  /** type of the NFT, enum{Member,Connector} */
  type: Scalars['String'];
};


/** entity that represents NFT of the type member or connector */
export type ReferrerChildrenArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Referrer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Referrer_Filter>;
};


/** entity that represents NFT of the type member or connector */
export type ReferrerReferralCodesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReferralCode_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReferralCode_Filter>;
};


/** entity that represents NFT of the type member or connector */
export type ReferrerReferralFeeRewardDetailsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReferralFeeRewardDetail_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReferralFeeRewardDetail_Filter>;
};


/** entity that represents NFT of the type member or connector */
export type ReferrerReferralFeeRewardDetailsV2Args = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReferralFeeRewardDetailV2_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReferralFeeRewardDetailV2_Filter>;
};


/** entity that represents NFT of the type member or connector */
export type ReferrerReferralRewardsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReferralReward_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReferralReward_Filter>;
};


/** entity that represents NFT of the type member or connector */
export type ReferrerReferralRewardsV2Args = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReferralRewardV2_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReferralRewardV2_Filter>;
};

export type Referrer_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['Bytes']>;
  account_contains?: InputMaybe<Scalars['Bytes']>;
  account_gt?: InputMaybe<Scalars['Bytes']>;
  account_gte?: InputMaybe<Scalars['Bytes']>;
  account_in?: InputMaybe<Array<Scalars['Bytes']>>;
  account_lt?: InputMaybe<Scalars['Bytes']>;
  account_lte?: InputMaybe<Scalars['Bytes']>;
  account_not?: InputMaybe<Scalars['Bytes']>;
  account_not_contains?: InputMaybe<Scalars['Bytes']>;
  account_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  and?: InputMaybe<Array<InputMaybe<Referrer_Filter>>>;
  children_?: InputMaybe<Referrer_Filter>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  mintTimestamp?: InputMaybe<Scalars['BigInt']>;
  mintTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  mintTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  mintTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  mintTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  mintTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  mintTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  mintTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  mintTxHash?: InputMaybe<Scalars['Bytes']>;
  mintTxHash_contains?: InputMaybe<Scalars['Bytes']>;
  mintTxHash_gt?: InputMaybe<Scalars['Bytes']>;
  mintTxHash_gte?: InputMaybe<Scalars['Bytes']>;
  mintTxHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  mintTxHash_lt?: InputMaybe<Scalars['Bytes']>;
  mintTxHash_lte?: InputMaybe<Scalars['Bytes']>;
  mintTxHash_not?: InputMaybe<Scalars['Bytes']>;
  mintTxHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  mintTxHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  minter?: InputMaybe<Scalars['Bytes']>;
  minter_contains?: InputMaybe<Scalars['Bytes']>;
  minter_gt?: InputMaybe<Scalars['Bytes']>;
  minter_gte?: InputMaybe<Scalars['Bytes']>;
  minter_in?: InputMaybe<Array<Scalars['Bytes']>>;
  minter_lt?: InputMaybe<Scalars['Bytes']>;
  minter_lte?: InputMaybe<Scalars['Bytes']>;
  minter_not?: InputMaybe<Scalars['Bytes']>;
  minter_not_contains?: InputMaybe<Scalars['Bytes']>;
  minter_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  or?: InputMaybe<Array<InputMaybe<Referrer_Filter>>>;
  parent?: InputMaybe<Scalars['String']>;
  parent_?: InputMaybe<Referrer_Filter>;
  parent_contains?: InputMaybe<Scalars['String']>;
  parent_contains_nocase?: InputMaybe<Scalars['String']>;
  parent_ends_with?: InputMaybe<Scalars['String']>;
  parent_ends_with_nocase?: InputMaybe<Scalars['String']>;
  parent_gt?: InputMaybe<Scalars['String']>;
  parent_gte?: InputMaybe<Scalars['String']>;
  parent_in?: InputMaybe<Array<Scalars['String']>>;
  parent_lt?: InputMaybe<Scalars['String']>;
  parent_lte?: InputMaybe<Scalars['String']>;
  parent_not?: InputMaybe<Scalars['String']>;
  parent_not_contains?: InputMaybe<Scalars['String']>;
  parent_not_contains_nocase?: InputMaybe<Scalars['String']>;
  parent_not_ends_with?: InputMaybe<Scalars['String']>;
  parent_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  parent_not_in?: InputMaybe<Array<Scalars['String']>>;
  parent_not_starts_with?: InputMaybe<Scalars['String']>;
  parent_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  parent_starts_with?: InputMaybe<Scalars['String']>;
  parent_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pools?: InputMaybe<Array<Scalars['Bytes']>>;
  pools_contains?: InputMaybe<Array<Scalars['Bytes']>>;
  pools_contains_nocase?: InputMaybe<Array<Scalars['Bytes']>>;
  pools_not?: InputMaybe<Array<Scalars['Bytes']>>;
  pools_not_contains?: InputMaybe<Array<Scalars['Bytes']>>;
  pools_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']>>;
  refereeCount?: InputMaybe<Scalars['Int']>;
  refereeCount_gt?: InputMaybe<Scalars['Int']>;
  refereeCount_gte?: InputMaybe<Scalars['Int']>;
  refereeCount_in?: InputMaybe<Array<Scalars['Int']>>;
  refereeCount_lt?: InputMaybe<Scalars['Int']>;
  refereeCount_lte?: InputMaybe<Scalars['Int']>;
  refereeCount_not?: InputMaybe<Scalars['Int']>;
  refereeCount_not_in?: InputMaybe<Array<Scalars['Int']>>;
  referralCodes_?: InputMaybe<ReferralCode_Filter>;
  referralFeeReward?: InputMaybe<Scalars['String']>;
  referralFeeRewardDetailsV2_?: InputMaybe<ReferralFeeRewardDetailV2_Filter>;
  referralFeeRewardDetails_?: InputMaybe<ReferralFeeRewardDetail_Filter>;
  referralFeeRewardV2?: InputMaybe<Scalars['String']>;
  referralFeeRewardV2_?: InputMaybe<ReferralFeeReward_Filter>;
  referralFeeRewardV2_contains?: InputMaybe<Scalars['String']>;
  referralFeeRewardV2_contains_nocase?: InputMaybe<Scalars['String']>;
  referralFeeRewardV2_ends_with?: InputMaybe<Scalars['String']>;
  referralFeeRewardV2_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referralFeeRewardV2_gt?: InputMaybe<Scalars['String']>;
  referralFeeRewardV2_gte?: InputMaybe<Scalars['String']>;
  referralFeeRewardV2_in?: InputMaybe<Array<Scalars['String']>>;
  referralFeeRewardV2_lt?: InputMaybe<Scalars['String']>;
  referralFeeRewardV2_lte?: InputMaybe<Scalars['String']>;
  referralFeeRewardV2_not?: InputMaybe<Scalars['String']>;
  referralFeeRewardV2_not_contains?: InputMaybe<Scalars['String']>;
  referralFeeRewardV2_not_contains_nocase?: InputMaybe<Scalars['String']>;
  referralFeeRewardV2_not_ends_with?: InputMaybe<Scalars['String']>;
  referralFeeRewardV2_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referralFeeRewardV2_not_in?: InputMaybe<Array<Scalars['String']>>;
  referralFeeRewardV2_not_starts_with?: InputMaybe<Scalars['String']>;
  referralFeeRewardV2_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  referralFeeRewardV2_starts_with?: InputMaybe<Scalars['String']>;
  referralFeeRewardV2_starts_with_nocase?: InputMaybe<Scalars['String']>;
  referralFeeReward_?: InputMaybe<ReferralFeeReward_Filter>;
  referralFeeReward_contains?: InputMaybe<Scalars['String']>;
  referralFeeReward_contains_nocase?: InputMaybe<Scalars['String']>;
  referralFeeReward_ends_with?: InputMaybe<Scalars['String']>;
  referralFeeReward_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referralFeeReward_gt?: InputMaybe<Scalars['String']>;
  referralFeeReward_gte?: InputMaybe<Scalars['String']>;
  referralFeeReward_in?: InputMaybe<Array<Scalars['String']>>;
  referralFeeReward_lt?: InputMaybe<Scalars['String']>;
  referralFeeReward_lte?: InputMaybe<Scalars['String']>;
  referralFeeReward_not?: InputMaybe<Scalars['String']>;
  referralFeeReward_not_contains?: InputMaybe<Scalars['String']>;
  referralFeeReward_not_contains_nocase?: InputMaybe<Scalars['String']>;
  referralFeeReward_not_ends_with?: InputMaybe<Scalars['String']>;
  referralFeeReward_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  referralFeeReward_not_in?: InputMaybe<Array<Scalars['String']>>;
  referralFeeReward_not_starts_with?: InputMaybe<Scalars['String']>;
  referralFeeReward_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  referralFeeReward_starts_with?: InputMaybe<Scalars['String']>;
  referralFeeReward_starts_with_nocase?: InputMaybe<Scalars['String']>;
  referralParentFee?: InputMaybe<Scalars['BigDecimal']>;
  referralParentFeeV2?: InputMaybe<Scalars['BigDecimal']>;
  referralParentFeeV2_gt?: InputMaybe<Scalars['BigDecimal']>;
  referralParentFeeV2_gte?: InputMaybe<Scalars['BigDecimal']>;
  referralParentFeeV2_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  referralParentFeeV2_lt?: InputMaybe<Scalars['BigDecimal']>;
  referralParentFeeV2_lte?: InputMaybe<Scalars['BigDecimal']>;
  referralParentFeeV2_not?: InputMaybe<Scalars['BigDecimal']>;
  referralParentFeeV2_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  referralParentFee_gt?: InputMaybe<Scalars['BigDecimal']>;
  referralParentFee_gte?: InputMaybe<Scalars['BigDecimal']>;
  referralParentFee_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  referralParentFee_lt?: InputMaybe<Scalars['BigDecimal']>;
  referralParentFee_lte?: InputMaybe<Scalars['BigDecimal']>;
  referralParentFee_not?: InputMaybe<Scalars['BigDecimal']>;
  referralParentFee_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  referralRewardsV2_?: InputMaybe<ReferralRewardV2_Filter>;
  referralRewards_?: InputMaybe<ReferralReward_Filter>;
  tokenURI?: InputMaybe<Scalars['String']>;
  tokenURI_contains?: InputMaybe<Scalars['String']>;
  tokenURI_contains_nocase?: InputMaybe<Scalars['String']>;
  tokenURI_ends_with?: InputMaybe<Scalars['String']>;
  tokenURI_ends_with_nocase?: InputMaybe<Scalars['String']>;
  tokenURI_gt?: InputMaybe<Scalars['String']>;
  tokenURI_gte?: InputMaybe<Scalars['String']>;
  tokenURI_in?: InputMaybe<Array<Scalars['String']>>;
  tokenURI_lt?: InputMaybe<Scalars['String']>;
  tokenURI_lte?: InputMaybe<Scalars['String']>;
  tokenURI_not?: InputMaybe<Scalars['String']>;
  tokenURI_not_contains?: InputMaybe<Scalars['String']>;
  tokenURI_not_contains_nocase?: InputMaybe<Scalars['String']>;
  tokenURI_not_ends_with?: InputMaybe<Scalars['String']>;
  tokenURI_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  tokenURI_not_in?: InputMaybe<Array<Scalars['String']>>;
  tokenURI_not_starts_with?: InputMaybe<Scalars['String']>;
  tokenURI_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  tokenURI_starts_with?: InputMaybe<Scalars['String']>;
  tokenURI_starts_with_nocase?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  type_contains?: InputMaybe<Scalars['String']>;
  type_contains_nocase?: InputMaybe<Scalars['String']>;
  type_ends_with?: InputMaybe<Scalars['String']>;
  type_ends_with_nocase?: InputMaybe<Scalars['String']>;
  type_gt?: InputMaybe<Scalars['String']>;
  type_gte?: InputMaybe<Scalars['String']>;
  type_in?: InputMaybe<Array<Scalars['String']>>;
  type_lt?: InputMaybe<Scalars['String']>;
  type_lte?: InputMaybe<Scalars['String']>;
  type_not?: InputMaybe<Scalars['String']>;
  type_not_contains?: InputMaybe<Scalars['String']>;
  type_not_contains_nocase?: InputMaybe<Scalars['String']>;
  type_not_ends_with?: InputMaybe<Scalars['String']>;
  type_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  type_not_in?: InputMaybe<Array<Scalars['String']>>;
  type_not_starts_with?: InputMaybe<Scalars['String']>;
  type_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  type_starts_with?: InputMaybe<Scalars['String']>;
  type_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum Referrer_OrderBy {
  Account = 'account',
  Children = 'children',
  Id = 'id',
  MintTimestamp = 'mintTimestamp',
  MintTxHash = 'mintTxHash',
  Minter = 'minter',
  Parent = 'parent',
  ParentAccount = 'parent__account',
  ParentId = 'parent__id',
  ParentMintTimestamp = 'parent__mintTimestamp',
  ParentMintTxHash = 'parent__mintTxHash',
  ParentMinter = 'parent__minter',
  ParentRefereeCount = 'parent__refereeCount',
  ParentReferralParentFee = 'parent__referralParentFee',
  ParentReferralParentFeeV2 = 'parent__referralParentFeeV2',
  ParentTokenUri = 'parent__tokenURI',
  ParentType = 'parent__type',
  Pools = 'pools',
  RefereeCount = 'refereeCount',
  ReferralCodes = 'referralCodes',
  ReferralFeeReward = 'referralFeeReward',
  ReferralFeeRewardDetails = 'referralFeeRewardDetails',
  ReferralFeeRewardDetailsV2 = 'referralFeeRewardDetailsV2',
  ReferralFeeRewardV2 = 'referralFeeRewardV2',
  ReferralFeeRewardV2Claimed = 'referralFeeRewardV2__claimed',
  ReferralFeeRewardV2Id = 'referralFeeRewardV2__id',
  ReferralFeeRewardV2TotalClaimed = 'referralFeeRewardV2__totalClaimed',
  ReferralFeeRewardV2Unclaimed = 'referralFeeRewardV2__unclaimed',
  ReferralFeeRewardClaimed = 'referralFeeReward__claimed',
  ReferralFeeRewardId = 'referralFeeReward__id',
  ReferralFeeRewardTotalClaimed = 'referralFeeReward__totalClaimed',
  ReferralFeeRewardUnclaimed = 'referralFeeReward__unclaimed',
  ReferralParentFee = 'referralParentFee',
  ReferralParentFeeV2 = 'referralParentFeeV2',
  ReferralRewards = 'referralRewards',
  ReferralRewardsV2 = 'referralRewardsV2',
  TokenUri = 'tokenURI',
  Type = 'type'
}

export type RewardFarmConfig = {
  __typename?: 'RewardFarmConfig';
  id: Scalars['ID'];
  /** the liquidity rate as a percentage of mining, denominated in ten thousandths of a bip (i.e. 1e-8) */
  liquidityRate: Scalars['BigInt'];
  /** the referral parent token rate as a percentage of mining, denominated in ten thousandths of a bip (i.e. 1e-8) */
  referralParentTokenRate: Scalars['BigInt'];
  /** the referral token rate as a percentage of mining, denominated in ten thousandths of a bip (i.e. 1e-8) */
  referralTokenRate: Scalars['BigInt'];
  /** the reward cap */
  rewardCap: Scalars['BigDecimal'];
  /** the risk buffer fund liquidity rate as a percentage of mining, denominated in ten thousandths of a bip (i.e. 1e-8) */
  riskBufferFundLiquidityRate: Scalars['BigInt'];
};

export type RewardFarmConfig_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<RewardFarmConfig_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  liquidityRate?: InputMaybe<Scalars['BigInt']>;
  liquidityRate_gt?: InputMaybe<Scalars['BigInt']>;
  liquidityRate_gte?: InputMaybe<Scalars['BigInt']>;
  liquidityRate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  liquidityRate_lt?: InputMaybe<Scalars['BigInt']>;
  liquidityRate_lte?: InputMaybe<Scalars['BigInt']>;
  liquidityRate_not?: InputMaybe<Scalars['BigInt']>;
  liquidityRate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  or?: InputMaybe<Array<InputMaybe<RewardFarmConfig_Filter>>>;
  referralParentTokenRate?: InputMaybe<Scalars['BigInt']>;
  referralParentTokenRate_gt?: InputMaybe<Scalars['BigInt']>;
  referralParentTokenRate_gte?: InputMaybe<Scalars['BigInt']>;
  referralParentTokenRate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralParentTokenRate_lt?: InputMaybe<Scalars['BigInt']>;
  referralParentTokenRate_lte?: InputMaybe<Scalars['BigInt']>;
  referralParentTokenRate_not?: InputMaybe<Scalars['BigInt']>;
  referralParentTokenRate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralTokenRate?: InputMaybe<Scalars['BigInt']>;
  referralTokenRate_gt?: InputMaybe<Scalars['BigInt']>;
  referralTokenRate_gte?: InputMaybe<Scalars['BigInt']>;
  referralTokenRate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralTokenRate_lt?: InputMaybe<Scalars['BigInt']>;
  referralTokenRate_lte?: InputMaybe<Scalars['BigInt']>;
  referralTokenRate_not?: InputMaybe<Scalars['BigInt']>;
  referralTokenRate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  rewardCap?: InputMaybe<Scalars['BigDecimal']>;
  rewardCap_gt?: InputMaybe<Scalars['BigDecimal']>;
  rewardCap_gte?: InputMaybe<Scalars['BigDecimal']>;
  rewardCap_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  rewardCap_lt?: InputMaybe<Scalars['BigDecimal']>;
  rewardCap_lte?: InputMaybe<Scalars['BigDecimal']>;
  rewardCap_not?: InputMaybe<Scalars['BigDecimal']>;
  rewardCap_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  riskBufferFundLiquidityRate?: InputMaybe<Scalars['BigInt']>;
  riskBufferFundLiquidityRate_gt?: InputMaybe<Scalars['BigInt']>;
  riskBufferFundLiquidityRate_gte?: InputMaybe<Scalars['BigInt']>;
  riskBufferFundLiquidityRate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  riskBufferFundLiquidityRate_lt?: InputMaybe<Scalars['BigInt']>;
  riskBufferFundLiquidityRate_lte?: InputMaybe<Scalars['BigInt']>;
  riskBufferFundLiquidityRate_not?: InputMaybe<Scalars['BigInt']>;
  riskBufferFundLiquidityRate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum RewardFarmConfig_OrderBy {
  Id = 'id',
  LiquidityRate = 'liquidityRate',
  ReferralParentTokenRate = 'referralParentTokenRate',
  ReferralTokenRate = 'referralTokenRate',
  RewardCap = 'rewardCap',
  RiskBufferFundLiquidityRate = 'riskBufferFundLiquidityRate'
}

export type RiskBufferFundReward = {
  __typename?: 'RiskBufferFundReward';
  /** the owner of the reward */
  account: Account;
  /** claimed reward */
  claimed: Scalars['BigDecimal'];
  /** format: <account>:<pool address> */
  id: Scalars['ID'];
  /** the total liquidity provided */
  liquidity: Scalars['BigDecimal'];
  /** liquidity before the change */
  liquidityBefore: Scalars['BigDecimal'];
  pool: Pool;
  /** the reward growth per unit of liquidity, as a Q64.64 */
  rewardGrowthX64: Scalars['BigInt'];
  /** unclaimed reward */
  unclaimed: Scalars['BigDecimal'];
  version: Scalars['BigInt'];
};

export type RiskBufferFundReward_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['String']>;
  account_?: InputMaybe<Account_Filter>;
  account_contains?: InputMaybe<Scalars['String']>;
  account_contains_nocase?: InputMaybe<Scalars['String']>;
  account_ends_with?: InputMaybe<Scalars['String']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_gt?: InputMaybe<Scalars['String']>;
  account_gte?: InputMaybe<Scalars['String']>;
  account_in?: InputMaybe<Array<Scalars['String']>>;
  account_lt?: InputMaybe<Scalars['String']>;
  account_lte?: InputMaybe<Scalars['String']>;
  account_not?: InputMaybe<Scalars['String']>;
  account_not_contains?: InputMaybe<Scalars['String']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']>;
  account_not_ends_with?: InputMaybe<Scalars['String']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_not_starts_with?: InputMaybe<Scalars['String']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_starts_with?: InputMaybe<Scalars['String']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']>;
  and?: InputMaybe<Array<InputMaybe<RiskBufferFundReward_Filter>>>;
  claimed?: InputMaybe<Scalars['BigDecimal']>;
  claimed_gt?: InputMaybe<Scalars['BigDecimal']>;
  claimed_gte?: InputMaybe<Scalars['BigDecimal']>;
  claimed_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  claimed_lt?: InputMaybe<Scalars['BigDecimal']>;
  claimed_lte?: InputMaybe<Scalars['BigDecimal']>;
  claimed_not?: InputMaybe<Scalars['BigDecimal']>;
  claimed_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  liquidity?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidityBefore_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidityBefore_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidity_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidity_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  or?: InputMaybe<Array<InputMaybe<RiskBufferFundReward_Filter>>>;
  pool?: InputMaybe<Scalars['String']>;
  pool_?: InputMaybe<Pool_Filter>;
  pool_contains?: InputMaybe<Scalars['String']>;
  pool_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_ends_with?: InputMaybe<Scalars['String']>;
  pool_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_gt?: InputMaybe<Scalars['String']>;
  pool_gte?: InputMaybe<Scalars['String']>;
  pool_in?: InputMaybe<Array<Scalars['String']>>;
  pool_lt?: InputMaybe<Scalars['String']>;
  pool_lte?: InputMaybe<Scalars['String']>;
  pool_not?: InputMaybe<Scalars['String']>;
  pool_not_contains?: InputMaybe<Scalars['String']>;
  pool_not_contains_nocase?: InputMaybe<Scalars['String']>;
  pool_not_ends_with?: InputMaybe<Scalars['String']>;
  pool_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  pool_not_in?: InputMaybe<Array<Scalars['String']>>;
  pool_not_starts_with?: InputMaybe<Scalars['String']>;
  pool_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  pool_starts_with?: InputMaybe<Scalars['String']>;
  pool_starts_with_nocase?: InputMaybe<Scalars['String']>;
  rewardGrowthX64?: InputMaybe<Scalars['BigInt']>;
  rewardGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  rewardGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  rewardGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  rewardGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  rewardGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  rewardGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  rewardGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  unclaimed?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_gt?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_gte?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  unclaimed_lt?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_lte?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_not?: InputMaybe<Scalars['BigDecimal']>;
  unclaimed_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  version?: InputMaybe<Scalars['BigInt']>;
  version_gt?: InputMaybe<Scalars['BigInt']>;
  version_gte?: InputMaybe<Scalars['BigInt']>;
  version_in?: InputMaybe<Array<Scalars['BigInt']>>;
  version_lt?: InputMaybe<Scalars['BigInt']>;
  version_lte?: InputMaybe<Scalars['BigInt']>;
  version_not?: InputMaybe<Scalars['BigInt']>;
  version_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum RiskBufferFundReward_OrderBy {
  Account = 'account',
  AccountClaimedRewardFromEqu = 'account__claimedRewardFromEQU',
  AccountClaimedRewardFromLiquidity = 'account__claimedRewardFromLiquidity',
  AccountId = 'account__id',
  AccountReferralFee = 'account__referralFee',
  AccountReferralFeeV2 = 'account__referralFeeV2',
  AccountReferralParentToken = 'account__referralParentToken',
  AccountReferralToken = 'account__referralToken',
  Claimed = 'claimed',
  Id = 'id',
  Liquidity = 'liquidity',
  LiquidityBefore = 'liquidityBefore',
  Pool = 'pool',
  PoolId = 'pool__id',
  PoolLastMintTime = 'pool__lastMintTime',
  PoolLiquidity = 'pool__liquidity',
  PoolLiquidityRewardGrowthX64 = 'pool__liquidityRewardGrowthX64',
  PoolMintedReward = 'pool__mintedReward',
  PoolReferralLiquidity = 'pool__referralLiquidity',
  PoolReferralParentTokenPositionRewardGrowthX64 = 'pool__referralParentTokenPositionRewardGrowthX64',
  PoolReferralParentTokenRewardGrowthX64 = 'pool__referralParentTokenRewardGrowthX64',
  PoolReferralPosition = 'pool__referralPosition',
  PoolReferralTokenPositionRewardGrowthX64 = 'pool__referralTokenPositionRewardGrowthX64',
  PoolReferralTokenRewardGrowthX64 = 'pool__referralTokenRewardGrowthX64',
  PoolRewardPerSecond = 'pool__rewardPerSecond',
  PoolRiskBufferFundLiquidity = 'pool__riskBufferFundLiquidity',
  PoolRiskBufferFundRewardGrowthX64 = 'pool__riskBufferFundRewardGrowthX64',
  PoolVersion = 'pool__version',
  RewardGrowthX64 = 'rewardGrowthX64',
  Unclaimed = 'unclaimed',
  Version = 'version'
}

export type StakeDetail = {
  __typename?: 'StakeDetail';
  /** the stake account */
  account: Account;
  /** reward has claimed by stake account */
  claimedReward: Scalars['BigDecimal'];
  /** format: <type>:<account>:<stakeID> */
  id: Scalars['ID'];
  /** multiplier of stake */
  multiplier: Scalars['Int'];
  /** growthX64 of claimable reward */
  perShareGrowthX64: Scalars['BigInt'];
  /** period of stake */
  period: Scalars['Int'];
  /** auto-increment ID for EQU stakeType and NFT tokenID for LP stakeType */
  stakeID: Scalars['BigInt'];
  /** timestamp when the stake transaction is successful */
  stakeTimestamp: Scalars['BigInt'];
  /** txHash when the stake transaction is successful */
  stakeTxHash: Scalars['Bytes'];
  /** staked amount of EQU */
  stakedAmount: Scalars['BigDecimal'];
  /** the stake type, enum{EQU, LIQUIDITY} */
  type: Scalars['String'];
  /** timestamp when the unstake transaction is successful */
  unstakeTimestamp: Scalars['BigInt'];
  /** txHash when the unstake transaction is successful */
  unstakeTxHash: Scalars['Bytes'];
};

export type StakeDetail_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars['String']>;
  account_?: InputMaybe<Account_Filter>;
  account_contains?: InputMaybe<Scalars['String']>;
  account_contains_nocase?: InputMaybe<Scalars['String']>;
  account_ends_with?: InputMaybe<Scalars['String']>;
  account_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_gt?: InputMaybe<Scalars['String']>;
  account_gte?: InputMaybe<Scalars['String']>;
  account_in?: InputMaybe<Array<Scalars['String']>>;
  account_lt?: InputMaybe<Scalars['String']>;
  account_lte?: InputMaybe<Scalars['String']>;
  account_not?: InputMaybe<Scalars['String']>;
  account_not_contains?: InputMaybe<Scalars['String']>;
  account_not_contains_nocase?: InputMaybe<Scalars['String']>;
  account_not_ends_with?: InputMaybe<Scalars['String']>;
  account_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_not_starts_with?: InputMaybe<Scalars['String']>;
  account_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_starts_with?: InputMaybe<Scalars['String']>;
  account_starts_with_nocase?: InputMaybe<Scalars['String']>;
  and?: InputMaybe<Array<InputMaybe<StakeDetail_Filter>>>;
  claimedReward?: InputMaybe<Scalars['BigDecimal']>;
  claimedReward_gt?: InputMaybe<Scalars['BigDecimal']>;
  claimedReward_gte?: InputMaybe<Scalars['BigDecimal']>;
  claimedReward_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  claimedReward_lt?: InputMaybe<Scalars['BigDecimal']>;
  claimedReward_lte?: InputMaybe<Scalars['BigDecimal']>;
  claimedReward_not?: InputMaybe<Scalars['BigDecimal']>;
  claimedReward_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  multiplier?: InputMaybe<Scalars['Int']>;
  multiplier_gt?: InputMaybe<Scalars['Int']>;
  multiplier_gte?: InputMaybe<Scalars['Int']>;
  multiplier_in?: InputMaybe<Array<Scalars['Int']>>;
  multiplier_lt?: InputMaybe<Scalars['Int']>;
  multiplier_lte?: InputMaybe<Scalars['Int']>;
  multiplier_not?: InputMaybe<Scalars['Int']>;
  multiplier_not_in?: InputMaybe<Array<Scalars['Int']>>;
  or?: InputMaybe<Array<InputMaybe<StakeDetail_Filter>>>;
  perShareGrowthX64?: InputMaybe<Scalars['BigInt']>;
  perShareGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  perShareGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  perShareGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  perShareGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  perShareGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  perShareGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  perShareGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  period?: InputMaybe<Scalars['Int']>;
  period_gt?: InputMaybe<Scalars['Int']>;
  period_gte?: InputMaybe<Scalars['Int']>;
  period_in?: InputMaybe<Array<Scalars['Int']>>;
  period_lt?: InputMaybe<Scalars['Int']>;
  period_lte?: InputMaybe<Scalars['Int']>;
  period_not?: InputMaybe<Scalars['Int']>;
  period_not_in?: InputMaybe<Array<Scalars['Int']>>;
  stakeID?: InputMaybe<Scalars['BigInt']>;
  stakeID_gt?: InputMaybe<Scalars['BigInt']>;
  stakeID_gte?: InputMaybe<Scalars['BigInt']>;
  stakeID_in?: InputMaybe<Array<Scalars['BigInt']>>;
  stakeID_lt?: InputMaybe<Scalars['BigInt']>;
  stakeID_lte?: InputMaybe<Scalars['BigInt']>;
  stakeID_not?: InputMaybe<Scalars['BigInt']>;
  stakeID_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  stakeTimestamp?: InputMaybe<Scalars['BigInt']>;
  stakeTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  stakeTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  stakeTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  stakeTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  stakeTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  stakeTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  stakeTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  stakeTxHash?: InputMaybe<Scalars['Bytes']>;
  stakeTxHash_contains?: InputMaybe<Scalars['Bytes']>;
  stakeTxHash_gt?: InputMaybe<Scalars['Bytes']>;
  stakeTxHash_gte?: InputMaybe<Scalars['Bytes']>;
  stakeTxHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  stakeTxHash_lt?: InputMaybe<Scalars['Bytes']>;
  stakeTxHash_lte?: InputMaybe<Scalars['Bytes']>;
  stakeTxHash_not?: InputMaybe<Scalars['Bytes']>;
  stakeTxHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  stakeTxHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  stakedAmount?: InputMaybe<Scalars['BigDecimal']>;
  stakedAmount_gt?: InputMaybe<Scalars['BigDecimal']>;
  stakedAmount_gte?: InputMaybe<Scalars['BigDecimal']>;
  stakedAmount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  stakedAmount_lt?: InputMaybe<Scalars['BigDecimal']>;
  stakedAmount_lte?: InputMaybe<Scalars['BigDecimal']>;
  stakedAmount_not?: InputMaybe<Scalars['BigDecimal']>;
  stakedAmount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  type?: InputMaybe<Scalars['String']>;
  type_contains?: InputMaybe<Scalars['String']>;
  type_contains_nocase?: InputMaybe<Scalars['String']>;
  type_ends_with?: InputMaybe<Scalars['String']>;
  type_ends_with_nocase?: InputMaybe<Scalars['String']>;
  type_gt?: InputMaybe<Scalars['String']>;
  type_gte?: InputMaybe<Scalars['String']>;
  type_in?: InputMaybe<Array<Scalars['String']>>;
  type_lt?: InputMaybe<Scalars['String']>;
  type_lte?: InputMaybe<Scalars['String']>;
  type_not?: InputMaybe<Scalars['String']>;
  type_not_contains?: InputMaybe<Scalars['String']>;
  type_not_contains_nocase?: InputMaybe<Scalars['String']>;
  type_not_ends_with?: InputMaybe<Scalars['String']>;
  type_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  type_not_in?: InputMaybe<Array<Scalars['String']>>;
  type_not_starts_with?: InputMaybe<Scalars['String']>;
  type_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  type_starts_with?: InputMaybe<Scalars['String']>;
  type_starts_with_nocase?: InputMaybe<Scalars['String']>;
  unstakeTimestamp?: InputMaybe<Scalars['BigInt']>;
  unstakeTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  unstakeTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  unstakeTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  unstakeTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  unstakeTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  unstakeTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  unstakeTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  unstakeTxHash?: InputMaybe<Scalars['Bytes']>;
  unstakeTxHash_contains?: InputMaybe<Scalars['Bytes']>;
  unstakeTxHash_gt?: InputMaybe<Scalars['Bytes']>;
  unstakeTxHash_gte?: InputMaybe<Scalars['Bytes']>;
  unstakeTxHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  unstakeTxHash_lt?: InputMaybe<Scalars['Bytes']>;
  unstakeTxHash_lte?: InputMaybe<Scalars['Bytes']>;
  unstakeTxHash_not?: InputMaybe<Scalars['Bytes']>;
  unstakeTxHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  unstakeTxHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
};

export enum StakeDetail_OrderBy {
  Account = 'account',
  AccountClaimedRewardFromEqu = 'account__claimedRewardFromEQU',
  AccountClaimedRewardFromLiquidity = 'account__claimedRewardFromLiquidity',
  AccountId = 'account__id',
  AccountReferralFee = 'account__referralFee',
  AccountReferralFeeV2 = 'account__referralFeeV2',
  AccountReferralParentToken = 'account__referralParentToken',
  AccountReferralToken = 'account__referralToken',
  ClaimedReward = 'claimedReward',
  Id = 'id',
  Multiplier = 'multiplier',
  PerShareGrowthX64 = 'perShareGrowthX64',
  Period = 'period',
  StakeId = 'stakeID',
  StakeTimestamp = 'stakeTimestamp',
  StakeTxHash = 'stakeTxHash',
  StakedAmount = 'stakedAmount',
  Type = 'type',
  UnstakeTimestamp = 'unstakeTimestamp',
  UnstakeTxHash = 'unstakeTxHash'
}

export type StakeStatistic = {
  __typename?: 'StakeStatistic';
  /** growthX64 of architect */
  architectPerShareGrowthX64: Scalars['BigInt'];
  /** total architect reward income */
  architectReward: Scalars['BigDecimal'];
  /** total staked amount of EQU */
  equStakedAmount: Scalars['BigDecimal'];
  /** total staked amount with multiplier of EQU */
  equStakedAmountWithMultiplier: Scalars['BigDecimal'];
  /** stake_statistic */
  id: Scalars['ID'];
  /** total liquidity staked amount of EQU */
  liquidityStakedAmount: Scalars['BigDecimal'];
  /** total liquidity staked amount with multiplier of EQU */
  liquidityStakedAmountWithMultiplier: Scalars['BigDecimal'];
  /** stake multipliers list corresponds to stake periods list */
  multipliers: Array<Scalars['Int']>;
  /** growthX64 of stake */
  perShareGrowthX64: Scalars['BigInt'];
  /** stake periods list */
  periods: Array<Scalars['Int']>;
  /** total stake reward income */
  stakeReward: Scalars['BigDecimal'];
};

export type StakeStatistic_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<StakeStatistic_Filter>>>;
  architectPerShareGrowthX64?: InputMaybe<Scalars['BigInt']>;
  architectPerShareGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  architectPerShareGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  architectPerShareGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  architectPerShareGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  architectPerShareGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  architectPerShareGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  architectPerShareGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  architectReward?: InputMaybe<Scalars['BigDecimal']>;
  architectReward_gt?: InputMaybe<Scalars['BigDecimal']>;
  architectReward_gte?: InputMaybe<Scalars['BigDecimal']>;
  architectReward_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  architectReward_lt?: InputMaybe<Scalars['BigDecimal']>;
  architectReward_lte?: InputMaybe<Scalars['BigDecimal']>;
  architectReward_not?: InputMaybe<Scalars['BigDecimal']>;
  architectReward_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  equStakedAmount?: InputMaybe<Scalars['BigDecimal']>;
  equStakedAmountWithMultiplier?: InputMaybe<Scalars['BigDecimal']>;
  equStakedAmountWithMultiplier_gt?: InputMaybe<Scalars['BigDecimal']>;
  equStakedAmountWithMultiplier_gte?: InputMaybe<Scalars['BigDecimal']>;
  equStakedAmountWithMultiplier_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  equStakedAmountWithMultiplier_lt?: InputMaybe<Scalars['BigDecimal']>;
  equStakedAmountWithMultiplier_lte?: InputMaybe<Scalars['BigDecimal']>;
  equStakedAmountWithMultiplier_not?: InputMaybe<Scalars['BigDecimal']>;
  equStakedAmountWithMultiplier_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  equStakedAmount_gt?: InputMaybe<Scalars['BigDecimal']>;
  equStakedAmount_gte?: InputMaybe<Scalars['BigDecimal']>;
  equStakedAmount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  equStakedAmount_lt?: InputMaybe<Scalars['BigDecimal']>;
  equStakedAmount_lte?: InputMaybe<Scalars['BigDecimal']>;
  equStakedAmount_not?: InputMaybe<Scalars['BigDecimal']>;
  equStakedAmount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  liquidityStakedAmount?: InputMaybe<Scalars['BigDecimal']>;
  liquidityStakedAmountWithMultiplier?: InputMaybe<Scalars['BigDecimal']>;
  liquidityStakedAmountWithMultiplier_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityStakedAmountWithMultiplier_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityStakedAmountWithMultiplier_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidityStakedAmountWithMultiplier_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityStakedAmountWithMultiplier_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityStakedAmountWithMultiplier_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidityStakedAmountWithMultiplier_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidityStakedAmount_gt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityStakedAmount_gte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityStakedAmount_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  liquidityStakedAmount_lt?: InputMaybe<Scalars['BigDecimal']>;
  liquidityStakedAmount_lte?: InputMaybe<Scalars['BigDecimal']>;
  liquidityStakedAmount_not?: InputMaybe<Scalars['BigDecimal']>;
  liquidityStakedAmount_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  multipliers?: InputMaybe<Array<Scalars['Int']>>;
  multipliers_contains?: InputMaybe<Array<Scalars['Int']>>;
  multipliers_contains_nocase?: InputMaybe<Array<Scalars['Int']>>;
  multipliers_not?: InputMaybe<Array<Scalars['Int']>>;
  multipliers_not_contains?: InputMaybe<Array<Scalars['Int']>>;
  multipliers_not_contains_nocase?: InputMaybe<Array<Scalars['Int']>>;
  or?: InputMaybe<Array<InputMaybe<StakeStatistic_Filter>>>;
  perShareGrowthX64?: InputMaybe<Scalars['BigInt']>;
  perShareGrowthX64_gt?: InputMaybe<Scalars['BigInt']>;
  perShareGrowthX64_gte?: InputMaybe<Scalars['BigInt']>;
  perShareGrowthX64_in?: InputMaybe<Array<Scalars['BigInt']>>;
  perShareGrowthX64_lt?: InputMaybe<Scalars['BigInt']>;
  perShareGrowthX64_lte?: InputMaybe<Scalars['BigInt']>;
  perShareGrowthX64_not?: InputMaybe<Scalars['BigInt']>;
  perShareGrowthX64_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  periods?: InputMaybe<Array<Scalars['Int']>>;
  periods_contains?: InputMaybe<Array<Scalars['Int']>>;
  periods_contains_nocase?: InputMaybe<Array<Scalars['Int']>>;
  periods_not?: InputMaybe<Array<Scalars['Int']>>;
  periods_not_contains?: InputMaybe<Array<Scalars['Int']>>;
  periods_not_contains_nocase?: InputMaybe<Array<Scalars['Int']>>;
  stakeReward?: InputMaybe<Scalars['BigDecimal']>;
  stakeReward_gt?: InputMaybe<Scalars['BigDecimal']>;
  stakeReward_gte?: InputMaybe<Scalars['BigDecimal']>;
  stakeReward_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
  stakeReward_lt?: InputMaybe<Scalars['BigDecimal']>;
  stakeReward_lte?: InputMaybe<Scalars['BigDecimal']>;
  stakeReward_not?: InputMaybe<Scalars['BigDecimal']>;
  stakeReward_not_in?: InputMaybe<Array<Scalars['BigDecimal']>>;
};

export enum StakeStatistic_OrderBy {
  ArchitectPerShareGrowthX64 = 'architectPerShareGrowthX64',
  ArchitectReward = 'architectReward',
  EquStakedAmount = 'equStakedAmount',
  EquStakedAmountWithMultiplier = 'equStakedAmountWithMultiplier',
  Id = 'id',
  LiquidityStakedAmount = 'liquidityStakedAmount',
  LiquidityStakedAmountWithMultiplier = 'liquidityStakedAmountWithMultiplier',
  Multipliers = 'multipliers',
  PerShareGrowthX64 = 'perShareGrowthX64',
  Periods = 'periods',
  StakeReward = 'stakeReward'
}

export type Subscription = {
  __typename?: 'Subscription';
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  account?: Maybe<Account>;
  accounts: Array<Account>;
  aggregationClaimHistories: Array<AggregationClaimHistory>;
  aggregationClaimHistory?: Maybe<AggregationClaimHistory>;
  architect?: Maybe<Architect>;
  architects: Array<Architect>;
  claimHistories: Array<ClaimHistory>;
  claimHistory?: Maybe<ClaimHistory>;
  liquidityPosition?: Maybe<LiquidityPosition>;
  liquidityPositionV2?: Maybe<LiquidityPositionV2>;
  liquidityPositionV2S: Array<LiquidityPositionV2>;
  liquidityPositions: Array<LiquidityPosition>;
  liquidityReward?: Maybe<LiquidityReward>;
  liquidityRewards: Array<LiquidityReward>;
  market?: Maybe<Market>;
  markets: Array<Market>;
  mintHistories: Array<MintHistory>;
  mintHistory?: Maybe<MintHistory>;
  pool?: Maybe<Pool>;
  pools: Array<Pool>;
  position?: Maybe<Position>;
  positionV2?: Maybe<PositionV2>;
  positionV2S: Array<PositionV2>;
  positions: Array<Position>;
  referee?: Maybe<Referee>;
  referees: Array<Referee>;
  referralCode?: Maybe<ReferralCode>;
  referralCodes: Array<ReferralCode>;
  referralFeeReward?: Maybe<ReferralFeeReward>;
  referralFeeRewardDetail?: Maybe<ReferralFeeRewardDetail>;
  referralFeeRewardDetailV2?: Maybe<ReferralFeeRewardDetailV2>;
  referralFeeRewardDetailV2S: Array<ReferralFeeRewardDetailV2>;
  referralFeeRewardDetails: Array<ReferralFeeRewardDetail>;
  referralFeeRewards: Array<ReferralFeeReward>;
  referralReward?: Maybe<ReferralReward>;
  referralRewardV2?: Maybe<ReferralRewardV2>;
  referralRewardV2S: Array<ReferralRewardV2>;
  referralRewards: Array<ReferralReward>;
  referrer?: Maybe<Referrer>;
  referrers: Array<Referrer>;
  rewardFarmConfig?: Maybe<RewardFarmConfig>;
  rewardFarmConfigs: Array<RewardFarmConfig>;
  riskBufferFundReward?: Maybe<RiskBufferFundReward>;
  riskBufferFundRewards: Array<RiskBufferFundReward>;
  stakeDetail?: Maybe<StakeDetail>;
  stakeDetails: Array<StakeDetail>;
  stakeStatistic?: Maybe<StakeStatistic>;
  stakeStatistics: Array<StakeStatistic>;
  token?: Maybe<Token>;
  tokens: Array<Token>;
};


export type Subscription_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};


export type SubscriptionAccountArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAccountsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Account_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Account_Filter>;
};


export type SubscriptionAggregationClaimHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AggregationClaimHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<AggregationClaimHistory_Filter>;
};


export type SubscriptionAggregationClaimHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionArchitectArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionArchitectsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Architect_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Architect_Filter>;
};


export type SubscriptionClaimHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ClaimHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ClaimHistory_Filter>;
};


export type SubscriptionClaimHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionLiquidityPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionLiquidityPositionV2Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionLiquidityPositionV2SArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LiquidityPositionV2_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidityPositionV2_Filter>;
};


export type SubscriptionLiquidityPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LiquidityPosition_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidityPosition_Filter>;
};


export type SubscriptionLiquidityRewardArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionLiquidityRewardsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LiquidityReward_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<LiquidityReward_Filter>;
};


export type SubscriptionMarketArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionMarketsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Market_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Market_Filter>;
};


export type SubscriptionMintHistoriesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MintHistory_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<MintHistory_Filter>;
};


export type SubscriptionMintHistoryArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Pool_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Pool_Filter>;
};


export type SubscriptionPositionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPositionV2Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPositionV2SArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PositionV2_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PositionV2_Filter>;
};


export type SubscriptionPositionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Position_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Position_Filter>;
};


export type SubscriptionRefereeArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRefereesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Referee_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Referee_Filter>;
};


export type SubscriptionReferralCodeArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionReferralCodesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReferralCode_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ReferralCode_Filter>;
};


export type SubscriptionReferralFeeRewardArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionReferralFeeRewardDetailArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionReferralFeeRewardDetailV2Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionReferralFeeRewardDetailV2SArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReferralFeeRewardDetailV2_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ReferralFeeRewardDetailV2_Filter>;
};


export type SubscriptionReferralFeeRewardDetailsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReferralFeeRewardDetail_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ReferralFeeRewardDetail_Filter>;
};


export type SubscriptionReferralFeeRewardsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReferralFeeReward_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ReferralFeeReward_Filter>;
};


export type SubscriptionReferralRewardArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionReferralRewardV2Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionReferralRewardV2SArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReferralRewardV2_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ReferralRewardV2_Filter>;
};


export type SubscriptionReferralRewardsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReferralReward_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<ReferralReward_Filter>;
};


export type SubscriptionReferrerArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionReferrersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Referrer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Referrer_Filter>;
};


export type SubscriptionRewardFarmConfigArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRewardFarmConfigsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RewardFarmConfig_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RewardFarmConfig_Filter>;
};


export type SubscriptionRiskBufferFundRewardArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionRiskBufferFundRewardsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RiskBufferFundReward_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RiskBufferFundReward_Filter>;
};


export type SubscriptionStakeDetailArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionStakeDetailsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<StakeDetail_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<StakeDetail_Filter>;
};


export type SubscriptionStakeStatisticArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionStakeStatisticsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<StakeStatistic_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<StakeStatistic_Filter>;
};


export type SubscriptionTokenArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokensArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Token_Filter>;
};

export type Token = {
  __typename?: 'Token';
  /** token decimals */
  decimals: Scalars['Int'];
  /** token address */
  id: Scalars['Bytes'];
  /** token name */
  name: Scalars['String'];
  /** the discount rate for referrals,denominated in ten thousandths of a bip (i.e. 1e-8) */
  referralDiscountRate: Scalars['BigInt'];
  /** token symbol */
  symbol: Scalars['String'];
  /** the trading fee rate for user increase or decrease positions, denominated in ten thousandths of a bip (i.e. 1e-8) */
  tradingFeeRate: Scalars['BigInt'];
};

export type Token_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Token_Filter>>>;
  decimals?: InputMaybe<Scalars['Int']>;
  decimals_gt?: InputMaybe<Scalars['Int']>;
  decimals_gte?: InputMaybe<Scalars['Int']>;
  decimals_in?: InputMaybe<Array<Scalars['Int']>>;
  decimals_lt?: InputMaybe<Scalars['Int']>;
  decimals_lte?: InputMaybe<Scalars['Int']>;
  decimals_not?: InputMaybe<Scalars['Int']>;
  decimals_not_in?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['Bytes']>;
  id_contains?: InputMaybe<Scalars['Bytes']>;
  id_gt?: InputMaybe<Scalars['Bytes']>;
  id_gte?: InputMaybe<Scalars['Bytes']>;
  id_in?: InputMaybe<Array<Scalars['Bytes']>>;
  id_lt?: InputMaybe<Scalars['Bytes']>;
  id_lte?: InputMaybe<Scalars['Bytes']>;
  id_not?: InputMaybe<Scalars['Bytes']>;
  id_not_contains?: InputMaybe<Scalars['Bytes']>;
  id_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_contains_nocase?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<InputMaybe<Token_Filter>>>;
  referralDiscountRate?: InputMaybe<Scalars['BigInt']>;
  referralDiscountRate_gt?: InputMaybe<Scalars['BigInt']>;
  referralDiscountRate_gte?: InputMaybe<Scalars['BigInt']>;
  referralDiscountRate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  referralDiscountRate_lt?: InputMaybe<Scalars['BigInt']>;
  referralDiscountRate_lte?: InputMaybe<Scalars['BigInt']>;
  referralDiscountRate_not?: InputMaybe<Scalars['BigInt']>;
  referralDiscountRate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  symbol?: InputMaybe<Scalars['String']>;
  symbol_contains?: InputMaybe<Scalars['String']>;
  symbol_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_ends_with?: InputMaybe<Scalars['String']>;
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_gt?: InputMaybe<Scalars['String']>;
  symbol_gte?: InputMaybe<Scalars['String']>;
  symbol_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_lt?: InputMaybe<Scalars['String']>;
  symbol_lte?: InputMaybe<Scalars['String']>;
  symbol_not?: InputMaybe<Scalars['String']>;
  symbol_not_contains?: InputMaybe<Scalars['String']>;
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_starts_with?: InputMaybe<Scalars['String']>;
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
  tradingFeeRate?: InputMaybe<Scalars['BigInt']>;
  tradingFeeRate_gt?: InputMaybe<Scalars['BigInt']>;
  tradingFeeRate_gte?: InputMaybe<Scalars['BigInt']>;
  tradingFeeRate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tradingFeeRate_lt?: InputMaybe<Scalars['BigInt']>;
  tradingFeeRate_lte?: InputMaybe<Scalars['BigInt']>;
  tradingFeeRate_not?: InputMaybe<Scalars['BigInt']>;
  tradingFeeRate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum Token_OrderBy {
  Decimals = 'decimals',
  Id = 'id',
  Name = 'name',
  ReferralDiscountRate = 'referralDiscountRate',
  Symbol = 'symbol',
  TradingFeeRate = 'tradingFeeRate'
}

export type _Block_ = {
  __typename?: '_Block_';
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>;
  /** The block number */
  number: Scalars['Int'];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: '_Meta_';
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean'];
};

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = 'allow',
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = 'deny'
}

export type AccountInformationQueryVariables = Exact<{
  account: Scalars['ID'];
}>;


export type AccountInformationQuery = { __typename?: 'Query', account?: { __typename?: 'Account', id: any, claimedRewardFromEQU: any, claimedRewardFromLiquidity: any } };

export type ArchitectRewardsQueryQueryVariables = Exact<{
  account?: InputMaybe<Scalars['Bytes']>;
}>;


export type ArchitectRewardsQueryQuery = { __typename?: 'Query', architects: Array<{ __typename?: 'Architect', id: string, claimedReward: any, account: any, perShareGrowthX64: any }>, stakeStatistic?: { __typename?: 'StakeStatistic', architectPerShareGrowthX64: any } };

export type ClaimHistoriesQueryQueryVariables = Exact<{
  account?: InputMaybe<Scalars['Bytes']>;
  skip?: InputMaybe<Scalars['Int']>;
  type_in?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ClaimHistoriesQueryQuery = { __typename?: 'Query', aggregationClaimHistories: Array<{ __typename?: 'AggregationClaimHistory', amount: any, blockNumber: any, blockTimestamp: any, feeAmount: any, id: string, txHash: any, type: string }> };

export type ConnectorHistoriesQueryVariables = Exact<{
  account?: InputMaybe<Scalars['Bytes']>;
}>;


export type ConnectorHistoriesQuery = { __typename?: 'Query', mintHistories: Array<{ __typename?: 'MintHistory', id: string, minter: any, receiver: any, blockNumber: any, blockTimestamp: any, connectorTokenID: any, tokenID: any, txHash: any, type: string }> };

export type LiquidityRewardsQueryVariables = Exact<{
  account: Scalars['String'];
}>;


export type LiquidityRewardsQuery = { __typename?: 'Query', liquidityRewards: Array<{ __typename?: 'LiquidityReward', unclaimed: any, claimed: any, liquidity: any, rewardGrowthX64: any, id: string, pool: { __typename?: 'Pool', id: any, lastMintTime: any, liquidity: any, mintedReward: any, rewardPerSecond: any, referralLiquidity: any, liquidityRewardGrowthX64: any, token: { __typename?: 'Token', id: any } } }> };

export type MembersReferralsQueryVariables = Exact<{
  account?: InputMaybe<Scalars['Bytes']>;
  first?: InputMaybe<Scalars['Int']>;
}>;


export type MembersReferralsQuery = { __typename?: 'Query', referrers: Array<{ __typename?: 'Referrer', id: string, account: any, mintTimestamp: any, mintTxHash: any, referralCodes: Array<{ __typename?: 'ReferralCode', id: string, txHash: any, blockTimestamp: any, referees: Array<{ __typename?: 'Referee', id: any, txHash: any }> }>, referralFeeReward: { __typename?: 'ReferralFeeReward', claimed: any, unclaimed: any }, referralFeeRewardV2: { __typename?: 'ReferralFeeReward', claimed: any, unclaimed: any }, referralFeeRewardDetails: Array<{ __typename?: 'ReferralFeeRewardDetail', unclaimed: any, pool: { __typename?: 'Pool', id: any } }>, referralFeeRewardDetailsV2: Array<{ __typename?: 'ReferralFeeRewardDetailV2', unclaimed: any, market: { __typename?: 'Market', id: any } }> }> };

export type MembersReferralsByIdQueryVariables = Exact<{
  token?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  number: Scalars['Int'];
  first?: InputMaybe<Scalars['Int']>;
}>;


export type MembersReferralsByIdQuery = { __typename?: 'Query', referrers: Array<{ __typename?: 'Referrer', id: string, account: any, mintTimestamp: any, mintTxHash: any, refereeCount: number, referralCodes: Array<{ __typename?: 'ReferralCode', id: string, txHash: any, blockTimestamp: any, referees: Array<{ __typename?: 'Referee', id: any, txHash: any }> }>, referralFeeReward: { __typename?: 'ReferralFeeReward', totalClaimed: any, claimed: any, unclaimed: any }, referralFeeRewardDetails: Array<{ __typename?: 'ReferralFeeRewardDetail', unclaimed: any, pool: { __typename?: 'Pool', id: any } }> }> };

export type MiningPoolsQueryVariables = Exact<{ [key: string]: never; }>;


export type MiningPoolsQuery = { __typename?: 'Query', pools: Array<{ __typename?: 'Pool', lastMintTime: any, mintedReward: any, rewardPerSecond: any, id: any, riskBufferFundLiquidity: any, riskBufferFundRewardGrowthX64: any }> };

export type MoreReferralCodesQueryQueryVariables = Exact<{
  id: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  lastTimestamp?: InputMaybe<Scalars['BigInt']>;
}>;


export type MoreReferralCodesQueryQuery = { __typename?: 'Query', referrer?: { __typename?: 'Referrer', referralCodes: Array<{ __typename?: 'ReferralCode', id: string, txHash: any, blockTimestamp: any, referees: Array<{ __typename?: 'Referee', id: any, txHash: any }> }> } };

export type MyConnectorsQueryVariables = Exact<{
  account?: InputMaybe<Scalars['Bytes']>;
  type: Scalars['String'];
}>;


export type MyConnectorsQuery = { __typename?: 'Query', referrers: Array<{ __typename?: 'Referrer', id: string, mintTimestamp: any, mintTxHash: any, minter: any, tokenURI: string, account: any, referralFeeRewardV2: { __typename?: 'ReferralFeeReward', id: string, claimed: any, unclaimed: any }, referralFeeReward: { __typename?: 'ReferralFeeReward', id: string, claimed: any, unclaimed: any }, referralFeeRewardDetails: Array<{ __typename?: 'ReferralFeeRewardDetail', unclaimed: any, pool: { __typename?: 'Pool', id: any } }>, referralFeeRewardDetailsV2: Array<{ __typename?: 'ReferralFeeRewardDetailV2', unclaimed: any, market: { __typename?: 'Market', id: any } }>, children: Array<{ __typename?: 'Referrer', id: string, account: any, referralFeeReward: { __typename?: 'ReferralFeeReward', claimed: any, unclaimed: any }, referralRewards: Array<{ __typename?: 'ReferralReward', id: string, liquidityClaimed: any, liquidityUnclaimed: any, liquidity: any, positionClaimed: any, positionUnclaimed: any, positionRewardGrowthX64: any, rewardGrowthX64: any, position: any }> }>, referralRewards: Array<{ __typename?: 'ReferralReward', id: string, liquidityClaimed: any, liquidityUnclaimed: any, liquidity: any, positionClaimed: any, positionUnclaimed: any, positionRewardGrowthX64: any, rewardGrowthX64: any, position: any }> }> };

export type RbfMiningQueryVariables = Exact<{
  account: Scalars['String'];
}>;


export type RbfMiningQuery = { __typename?: 'Query', riskBufferFundRewards: Array<{ __typename?: 'RiskBufferFundReward', claimed: any, id: string, liquidity: any, rewardGrowthX64: any, unclaimed: any }>, pools: Array<{ __typename?: 'Pool', lastMintTime: any, mintedReward: any, rewardPerSecond: any, id: any, riskBufferFundLiquidity: any, riskBufferFundRewardGrowthX64: any }>, rewardFarmConfigs: Array<{ __typename?: 'RewardFarmConfig', riskBufferFundLiquidityRate: any, liquidityRate: any, referralParentTokenRate: any, referralTokenRate: any, rewardCap: any }> };

export type RefereesAddressQueryVariables = Exact<{
  token?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type RefereesAddressQuery = { __typename?: 'Query', referees: Array<{ __typename?: 'Referee', id: any, referralCode: { __typename?: 'ReferralCode', id: string }, referralToken: { __typename?: 'Referrer', id: string } }> };

export type ReferralCodeRefereesQueryVariables = Exact<{
  referralCode: Scalars['ID'];
}>;


export type ReferralCodeRefereesQuery = { __typename?: 'Query', referralCodes: Array<{ __typename?: 'ReferralCode', id: string, accounts: Array<{ __typename?: 'Account', referralFee: any, id: any }> }> };

export type ReferralsRewardsQueryVariables = Exact<{
  token?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ReferralsRewardsQuery = { __typename?: 'Query', referralRewards: Array<{ __typename?: 'ReferralReward', rewardGrowthX64: any, position: any, positionRewardGrowthX64: any, positionClaimed: any, positionUnclaimed: any, liquidity: any, liquidityClaimed: any, liquidityUnclaimed: any, referralToken: { __typename?: 'Referrer', id: string }, pool: { __typename?: 'Pool', id: any, lastMintTime: any, mintedReward: any, referralLiquidity: any, referralPosition: any, rewardPerSecond: any, referralTokenRewardGrowthX64: any, referralTokenPositionRewardGrowthX64: any, referralParentTokenPositionRewardGrowthX64: any, referralParentTokenRewardGrowthX64: any, token: { __typename?: 'Token', name: string, id: any, symbol: string, tradingFeeRate: any, referralDiscountRate: any, decimals: number } } }>, referralRewardV2S: Array<{ __typename?: 'ReferralRewardV2', position: any, referralToken: { __typename?: 'Referrer', id: string }, market: { __typename?: 'Market', id: any, symbol: string } }> };

export type ReferralsRewardsByBlockNumberQueryVariables = Exact<{
  token?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  number: Scalars['Int'];
  first?: InputMaybe<Scalars['Int']>;
}>;


export type ReferralsRewardsByBlockNumberQuery = { __typename?: 'Query', referralRewards: Array<{ __typename?: 'ReferralReward', rewardGrowthX64: any, position: any, positionRewardGrowthX64: any, positionClaimed: any, positionUnclaimed: any, liquidity: any, liquidityClaimed: any, liquidityUnclaimed: any, referralToken: { __typename?: 'Referrer', id: string }, pool: { __typename?: 'Pool', id: any, lastMintTime: any, mintedReward: any, referralLiquidity: any, referralPosition: any, rewardPerSecond: any, referralTokenRewardGrowthX64: any, referralTokenPositionRewardGrowthX64: any, referralParentTokenPositionRewardGrowthX64: any, referralParentTokenRewardGrowthX64: any, token: { __typename?: 'Token', name: string, id: any, symbol: string, tradingFeeRate: any, referralDiscountRate: any, decimals: number } } }> };

export type RewardsConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type RewardsConfigQuery = { __typename?: 'Query', rewardFarmConfigs: Array<{ __typename?: 'RewardFarmConfig', id: string, liquidityRate: any, referralParentTokenRate: any, referralTokenRate: any, rewardCap: any, riskBufferFundLiquidityRate: any }> };

export type RiskBufferFundRewardsQueryVariables = Exact<{
  account: Scalars['String'];
}>;


export type RiskBufferFundRewardsQuery = { __typename?: 'Query', riskBufferFundRewards: Array<{ __typename?: 'RiskBufferFundReward', unclaimed: any, claimed: any, liquidity: any, rewardGrowthX64: any, id: string, pool: { __typename?: 'Pool', id: any, lastMintTime: any, mintedReward: any, riskBufferFundLiquidity: any, riskBufferFundRewardGrowthX64: any, rewardPerSecond: any, token: { __typename?: 'Token', id: any } } }> };

export type StakeDetailsQueryVariables = Exact<{
  account?: InputMaybe<Scalars['String']>;
}>;


export type StakeDetailsQuery = { __typename?: 'Query', stakeList: Array<{ __typename?: 'StakeDetail', claimedReward: any, id: string, perShareGrowthX64: any, multiplier: number, period: number, stakeID: any, stakeTimestamp: any, stakeTxHash: any, stakedAmount: any, unstakeTimestamp: any, unstakeTxHash: any, type: string }>, stakeLpList: Array<{ __typename?: 'StakeDetail', claimedReward: any, id: string, perShareGrowthX64: any, multiplier: number, period: number, stakeID: any, stakeTimestamp: any, stakeTxHash: any, stakedAmount: any, unstakeTimestamp: any, unstakeTxHash: any, type: string }> };

export type StakeStatisticQueryVariables = Exact<{
  blockBefore: Scalars['Int'];
}>;


export type StakeStatisticQuery = { __typename?: 'Query', stakeStatistic?: { __typename?: 'StakeStatistic', architectPerShareGrowthX64: any, architectReward: any, equStakedAmount: any, id: string, liquidityStakedAmount: any, multipliers: Array<number>, perShareGrowthX64: any, periods: Array<number>, stakeReward: any, liquidityStakedAmountWithMultiplier: any, equStakedAmountWithMultiplier: any }, stakeStatisticBefore?: { __typename?: 'StakeStatistic', architectPerShareGrowthX64: any, architectReward: any, equStakedAmount: any, id: string, liquidityStakedAmount: any, multipliers: Array<number>, perShareGrowthX64: any, periods: Array<number>, stakeReward: any } };

export type TradersReferralQueryVariables = Exact<{
  account: Scalars['ID'];
}>;


export type TradersReferralQuery = { __typename?: 'Query', referee?: { __typename?: 'Referee', referralCode: { __typename?: 'ReferralCode', id: string } } };


export const AccountInformationDocument = gql`
    query AccountInformation($account: ID!) {
  account(id: $account) {
    id
    claimedRewardFromEQU
    claimedRewardFromLiquidity
  }
}
    `;

/**
 * __useAccountInformationQuery__
 *
 * To run a query within a React component, call `useAccountInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountInformationQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useAccountInformationQuery(baseOptions: Apollo.QueryHookOptions<AccountInformationQuery, AccountInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountInformationQuery, AccountInformationQueryVariables>(AccountInformationDocument, options);
      }
export function useAccountInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountInformationQuery, AccountInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountInformationQuery, AccountInformationQueryVariables>(AccountInformationDocument, options);
        }
export type AccountInformationQueryHookResult = ReturnType<typeof useAccountInformationQuery>;
export type AccountInformationLazyQueryHookResult = ReturnType<typeof useAccountInformationLazyQuery>;
export type AccountInformationQueryResult = Apollo.QueryResult<AccountInformationQuery, AccountInformationQueryVariables>;
export const ArchitectRewardsQueryDocument = gql`
    query ArchitectRewardsQuery($account: Bytes) {
  architects(where: {account: $account}, first: 1000) {
    id
    claimedReward
    account
    perShareGrowthX64
  }
  stakeStatistic(id: "stake_statistic") {
    architectPerShareGrowthX64
  }
}
    `;

/**
 * __useArchitectRewardsQueryQuery__
 *
 * To run a query within a React component, call `useArchitectRewardsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchitectRewardsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchitectRewardsQueryQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useArchitectRewardsQueryQuery(baseOptions?: Apollo.QueryHookOptions<ArchitectRewardsQueryQuery, ArchitectRewardsQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArchitectRewardsQueryQuery, ArchitectRewardsQueryQueryVariables>(ArchitectRewardsQueryDocument, options);
      }
export function useArchitectRewardsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArchitectRewardsQueryQuery, ArchitectRewardsQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArchitectRewardsQueryQuery, ArchitectRewardsQueryQueryVariables>(ArchitectRewardsQueryDocument, options);
        }
export type ArchitectRewardsQueryQueryHookResult = ReturnType<typeof useArchitectRewardsQueryQuery>;
export type ArchitectRewardsQueryLazyQueryHookResult = ReturnType<typeof useArchitectRewardsQueryLazyQuery>;
export type ArchitectRewardsQueryQueryResult = Apollo.QueryResult<ArchitectRewardsQueryQuery, ArchitectRewardsQueryQueryVariables>;
export const ClaimHistoriesQueryDocument = gql`
    query ClaimHistoriesQuery($account: Bytes, $skip: Int, $type_in: [String!]) {
  aggregationClaimHistories(
    where: {account: $account, type_in: $type_in}
    orderBy: blockTimestamp
    orderDirection: desc
    first: 1000
    skip: $skip
  ) {
    amount
    blockNumber
    blockTimestamp
    feeAmount
    id
    txHash
    type
  }
}
    `;

/**
 * __useClaimHistoriesQueryQuery__
 *
 * To run a query within a React component, call `useClaimHistoriesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimHistoriesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimHistoriesQueryQuery({
 *   variables: {
 *      account: // value for 'account'
 *      skip: // value for 'skip'
 *      type_in: // value for 'type_in'
 *   },
 * });
 */
export function useClaimHistoriesQueryQuery(baseOptions?: Apollo.QueryHookOptions<ClaimHistoriesQueryQuery, ClaimHistoriesQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClaimHistoriesQueryQuery, ClaimHistoriesQueryQueryVariables>(ClaimHistoriesQueryDocument, options);
      }
export function useClaimHistoriesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClaimHistoriesQueryQuery, ClaimHistoriesQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClaimHistoriesQueryQuery, ClaimHistoriesQueryQueryVariables>(ClaimHistoriesQueryDocument, options);
        }
export type ClaimHistoriesQueryQueryHookResult = ReturnType<typeof useClaimHistoriesQueryQuery>;
export type ClaimHistoriesQueryLazyQueryHookResult = ReturnType<typeof useClaimHistoriesQueryLazyQuery>;
export type ClaimHistoriesQueryQueryResult = Apollo.QueryResult<ClaimHistoriesQueryQuery, ClaimHistoriesQueryQueryVariables>;
export const ConnectorHistoriesDocument = gql`
    query ConnectorHistories($account: Bytes) {
  mintHistories(
    where: {minter: $account}
    orderBy: blockTimestamp
    orderDirection: desc
    first: 1000
  ) {
    id
    minter
    receiver
    blockNumber
    blockTimestamp
    connectorTokenID
    tokenID
    txHash
    type
  }
}
    `;

/**
 * __useConnectorHistoriesQuery__
 *
 * To run a query within a React component, call `useConnectorHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectorHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectorHistoriesQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useConnectorHistoriesQuery(baseOptions?: Apollo.QueryHookOptions<ConnectorHistoriesQuery, ConnectorHistoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConnectorHistoriesQuery, ConnectorHistoriesQueryVariables>(ConnectorHistoriesDocument, options);
      }
export function useConnectorHistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConnectorHistoriesQuery, ConnectorHistoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConnectorHistoriesQuery, ConnectorHistoriesQueryVariables>(ConnectorHistoriesDocument, options);
        }
export type ConnectorHistoriesQueryHookResult = ReturnType<typeof useConnectorHistoriesQuery>;
export type ConnectorHistoriesLazyQueryHookResult = ReturnType<typeof useConnectorHistoriesLazyQuery>;
export type ConnectorHistoriesQueryResult = Apollo.QueryResult<ConnectorHistoriesQuery, ConnectorHistoriesQueryVariables>;
export const LiquidityRewardsDocument = gql`
    query liquidityRewards($account: String!) {
  liquidityRewards(where: {account: $account}) {
    unclaimed
    claimed
    liquidity
    rewardGrowthX64
    id
    pool {
      id
      lastMintTime
      liquidity
      mintedReward
      rewardPerSecond
      referralLiquidity
      liquidityRewardGrowthX64
      token {
        id
      }
    }
  }
}
    `;

/**
 * __useLiquidityRewardsQuery__
 *
 * To run a query within a React component, call `useLiquidityRewardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiquidityRewardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiquidityRewardsQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useLiquidityRewardsQuery(baseOptions: Apollo.QueryHookOptions<LiquidityRewardsQuery, LiquidityRewardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LiquidityRewardsQuery, LiquidityRewardsQueryVariables>(LiquidityRewardsDocument, options);
      }
export function useLiquidityRewardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LiquidityRewardsQuery, LiquidityRewardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LiquidityRewardsQuery, LiquidityRewardsQueryVariables>(LiquidityRewardsDocument, options);
        }
export type LiquidityRewardsQueryHookResult = ReturnType<typeof useLiquidityRewardsQuery>;
export type LiquidityRewardsLazyQueryHookResult = ReturnType<typeof useLiquidityRewardsLazyQuery>;
export type LiquidityRewardsQueryResult = Apollo.QueryResult<LiquidityRewardsQuery, LiquidityRewardsQueryVariables>;
export const MembersReferralsDocument = gql`
    query MembersReferrals($account: Bytes, $first: Int) {
  referrers(
    where: {account: $account, type: "Member"}
    orderBy: mintTimestamp
    orderDirection: asc
    first: 1000
  ) {
    id
    account
    mintTimestamp
    mintTxHash
    referralCodes(first: $first, orderBy: blockNumber, orderDirection: desc) {
      id
      txHash
      blockTimestamp
      referees(first: 1000, orderBy: blockNumber) {
        id
        txHash
      }
    }
    referralFeeReward {
      claimed
      unclaimed
    }
    referralFeeRewardV2 {
      claimed
      unclaimed
    }
    referralFeeRewardDetails {
      pool {
        id
      }
      unclaimed
    }
    referralFeeRewardDetailsV2 {
      market {
        id
      }
      unclaimed
    }
  }
}
    `;

/**
 * __useMembersReferralsQuery__
 *
 * To run a query within a React component, call `useMembersReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersReferralsQuery({
 *   variables: {
 *      account: // value for 'account'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useMembersReferralsQuery(baseOptions?: Apollo.QueryHookOptions<MembersReferralsQuery, MembersReferralsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembersReferralsQuery, MembersReferralsQueryVariables>(MembersReferralsDocument, options);
      }
export function useMembersReferralsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembersReferralsQuery, MembersReferralsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembersReferralsQuery, MembersReferralsQueryVariables>(MembersReferralsDocument, options);
        }
export type MembersReferralsQueryHookResult = ReturnType<typeof useMembersReferralsQuery>;
export type MembersReferralsLazyQueryHookResult = ReturnType<typeof useMembersReferralsLazyQuery>;
export type MembersReferralsQueryResult = Apollo.QueryResult<MembersReferralsQuery, MembersReferralsQueryVariables>;
export const MembersReferralsByIdDocument = gql`
    query MembersReferralsById($token: [ID!], $number: Int!, $first: Int) {
  referrers(
    block: {number: $number}
    where: {id_in: $token, type: "Member"}
    orderBy: mintTimestamp
    orderDirection: asc
    first: 1000
  ) {
    id
    account
    mintTimestamp
    mintTxHash
    refereeCount
    referralCodes(first: $first, orderBy: blockNumber, orderDirection: desc) {
      id
      txHash
      blockTimestamp
      referees(first: 1000, orderBy: blockNumber) {
        id
        txHash
      }
    }
    referralFeeReward {
      totalClaimed
      claimed
      unclaimed
    }
    referralFeeRewardDetails {
      pool {
        id
      }
      unclaimed
    }
  }
}
    `;

/**
 * __useMembersReferralsByIdQuery__
 *
 * To run a query within a React component, call `useMembersReferralsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersReferralsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersReferralsByIdQuery({
 *   variables: {
 *      token: // value for 'token'
 *      number: // value for 'number'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useMembersReferralsByIdQuery(baseOptions: Apollo.QueryHookOptions<MembersReferralsByIdQuery, MembersReferralsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembersReferralsByIdQuery, MembersReferralsByIdQueryVariables>(MembersReferralsByIdDocument, options);
      }
export function useMembersReferralsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembersReferralsByIdQuery, MembersReferralsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembersReferralsByIdQuery, MembersReferralsByIdQueryVariables>(MembersReferralsByIdDocument, options);
        }
export type MembersReferralsByIdQueryHookResult = ReturnType<typeof useMembersReferralsByIdQuery>;
export type MembersReferralsByIdLazyQueryHookResult = ReturnType<typeof useMembersReferralsByIdLazyQuery>;
export type MembersReferralsByIdQueryResult = Apollo.QueryResult<MembersReferralsByIdQuery, MembersReferralsByIdQueryVariables>;
export const MiningPoolsDocument = gql`
    query MiningPools {
  pools(where: {lastMintTime_gt: "0"}) {
    lastMintTime
    mintedReward
    rewardPerSecond
    id
    riskBufferFundLiquidity
    riskBufferFundRewardGrowthX64
  }
}
    `;

/**
 * __useMiningPoolsQuery__
 *
 * To run a query within a React component, call `useMiningPoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMiningPoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMiningPoolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMiningPoolsQuery(baseOptions?: Apollo.QueryHookOptions<MiningPoolsQuery, MiningPoolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MiningPoolsQuery, MiningPoolsQueryVariables>(MiningPoolsDocument, options);
      }
export function useMiningPoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MiningPoolsQuery, MiningPoolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MiningPoolsQuery, MiningPoolsQueryVariables>(MiningPoolsDocument, options);
        }
export type MiningPoolsQueryHookResult = ReturnType<typeof useMiningPoolsQuery>;
export type MiningPoolsLazyQueryHookResult = ReturnType<typeof useMiningPoolsLazyQuery>;
export type MiningPoolsQueryResult = Apollo.QueryResult<MiningPoolsQuery, MiningPoolsQueryVariables>;
export const MoreReferralCodesQueryDocument = gql`
    query MoreReferralCodesQuery($id: ID!, $first: Int, $lastTimestamp: BigInt) {
  referrer(id: $id) {
    referralCodes(
      first: $first
      orderBy: blockNumber
      orderDirection: desc
      where: {blockTimestamp_lt: $lastTimestamp}
    ) {
      id
      txHash
      blockTimestamp
      referees(first: 1000, orderBy: blockNumber) {
        id
        txHash
      }
    }
  }
}
    `;

/**
 * __useMoreReferralCodesQueryQuery__
 *
 * To run a query within a React component, call `useMoreReferralCodesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMoreReferralCodesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMoreReferralCodesQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      lastTimestamp: // value for 'lastTimestamp'
 *   },
 * });
 */
export function useMoreReferralCodesQueryQuery(baseOptions: Apollo.QueryHookOptions<MoreReferralCodesQueryQuery, MoreReferralCodesQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MoreReferralCodesQueryQuery, MoreReferralCodesQueryQueryVariables>(MoreReferralCodesQueryDocument, options);
      }
export function useMoreReferralCodesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MoreReferralCodesQueryQuery, MoreReferralCodesQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MoreReferralCodesQueryQuery, MoreReferralCodesQueryQueryVariables>(MoreReferralCodesQueryDocument, options);
        }
export type MoreReferralCodesQueryQueryHookResult = ReturnType<typeof useMoreReferralCodesQueryQuery>;
export type MoreReferralCodesQueryLazyQueryHookResult = ReturnType<typeof useMoreReferralCodesQueryLazyQuery>;
export type MoreReferralCodesQueryQueryResult = Apollo.QueryResult<MoreReferralCodesQueryQuery, MoreReferralCodesQueryQueryVariables>;
export const MyConnectorsDocument = gql`
    query MyConnectors($account: Bytes, $type: String!) {
  referrers(where: {account: $account, type: $type}) {
    id
    mintTimestamp
    mintTxHash
    minter
    tokenURI
    account
    referralFeeRewardV2 {
      id
      claimed
      unclaimed
    }
    referralFeeReward {
      id
      claimed
      unclaimed
    }
    referralFeeRewardDetails {
      pool {
        id
      }
      unclaimed
    }
    referralFeeRewardDetailsV2 {
      market {
        id
      }
      unclaimed
    }
    children(where: {type: "Member"}) {
      id
      account
      referralFeeReward {
        claimed
        unclaimed
      }
      referralRewards {
        id
        liquidityClaimed
        liquidityUnclaimed
        liquidity
        positionClaimed
        positionUnclaimed
        positionRewardGrowthX64
        rewardGrowthX64
        position
      }
    }
    referralRewards {
      id
      liquidityClaimed
      liquidityUnclaimed
      liquidity
      positionClaimed
      positionUnclaimed
      positionRewardGrowthX64
      rewardGrowthX64
      position
    }
  }
}
    `;

/**
 * __useMyConnectorsQuery__
 *
 * To run a query within a React component, call `useMyConnectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyConnectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyConnectorsQuery({
 *   variables: {
 *      account: // value for 'account'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useMyConnectorsQuery(baseOptions: Apollo.QueryHookOptions<MyConnectorsQuery, MyConnectorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyConnectorsQuery, MyConnectorsQueryVariables>(MyConnectorsDocument, options);
      }
export function useMyConnectorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyConnectorsQuery, MyConnectorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyConnectorsQuery, MyConnectorsQueryVariables>(MyConnectorsDocument, options);
        }
export type MyConnectorsQueryHookResult = ReturnType<typeof useMyConnectorsQuery>;
export type MyConnectorsLazyQueryHookResult = ReturnType<typeof useMyConnectorsLazyQuery>;
export type MyConnectorsQueryResult = Apollo.QueryResult<MyConnectorsQuery, MyConnectorsQueryVariables>;
export const RbfMiningDocument = gql`
    query RBFMining($account: String!) {
  riskBufferFundRewards(where: {account: $account}) {
    claimed
    id
    liquidity
    rewardGrowthX64
    unclaimed
  }
  pools(where: {lastMintTime_gt: "0"}) {
    lastMintTime
    mintedReward
    rewardPerSecond
    id
    riskBufferFundLiquidity
    riskBufferFundRewardGrowthX64
  }
  rewardFarmConfigs {
    riskBufferFundLiquidityRate
    liquidityRate
    referralParentTokenRate
    referralTokenRate
    rewardCap
  }
}
    `;

/**
 * __useRbfMiningQuery__
 *
 * To run a query within a React component, call `useRbfMiningQuery` and pass it any options that fit your needs.
 * When your component renders, `useRbfMiningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRbfMiningQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useRbfMiningQuery(baseOptions: Apollo.QueryHookOptions<RbfMiningQuery, RbfMiningQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RbfMiningQuery, RbfMiningQueryVariables>(RbfMiningDocument, options);
      }
export function useRbfMiningLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RbfMiningQuery, RbfMiningQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RbfMiningQuery, RbfMiningQueryVariables>(RbfMiningDocument, options);
        }
export type RbfMiningQueryHookResult = ReturnType<typeof useRbfMiningQuery>;
export type RbfMiningLazyQueryHookResult = ReturnType<typeof useRbfMiningLazyQuery>;
export type RbfMiningQueryResult = Apollo.QueryResult<RbfMiningQuery, RbfMiningQueryVariables>;
export const RefereesAddressDocument = gql`
    query RefereesAddress($token: [String!]) {
  referees(where: {referralToken_in: $token}, first: 1000) {
    id
    referralCode {
      id
    }
    referralToken {
      id
    }
  }
}
    `;

/**
 * __useRefereesAddressQuery__
 *
 * To run a query within a React component, call `useRefereesAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefereesAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefereesAddressQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRefereesAddressQuery(baseOptions?: Apollo.QueryHookOptions<RefereesAddressQuery, RefereesAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RefereesAddressQuery, RefereesAddressQueryVariables>(RefereesAddressDocument, options);
      }
export function useRefereesAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RefereesAddressQuery, RefereesAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RefereesAddressQuery, RefereesAddressQueryVariables>(RefereesAddressDocument, options);
        }
export type RefereesAddressQueryHookResult = ReturnType<typeof useRefereesAddressQuery>;
export type RefereesAddressLazyQueryHookResult = ReturnType<typeof useRefereesAddressLazyQuery>;
export type RefereesAddressQueryResult = Apollo.QueryResult<RefereesAddressQuery, RefereesAddressQueryVariables>;
export const ReferralCodeRefereesDocument = gql`
    query referralCodeReferees($referralCode: ID!) {
  referralCodes(
    where: {id: $referralCode}
    orderBy: blockTimestamp
    orderDirection: desc
    first: 1000
  ) {
    id
    accounts(orderBy: id, orderDirection: asc, first: 1000) {
      referralFee
      id
    }
  }
}
    `;

/**
 * __useReferralCodeRefereesQuery__
 *
 * To run a query within a React component, call `useReferralCodeRefereesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralCodeRefereesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralCodeRefereesQuery({
 *   variables: {
 *      referralCode: // value for 'referralCode'
 *   },
 * });
 */
export function useReferralCodeRefereesQuery(baseOptions: Apollo.QueryHookOptions<ReferralCodeRefereesQuery, ReferralCodeRefereesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReferralCodeRefereesQuery, ReferralCodeRefereesQueryVariables>(ReferralCodeRefereesDocument, options);
      }
export function useReferralCodeRefereesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferralCodeRefereesQuery, ReferralCodeRefereesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReferralCodeRefereesQuery, ReferralCodeRefereesQueryVariables>(ReferralCodeRefereesDocument, options);
        }
export type ReferralCodeRefereesQueryHookResult = ReturnType<typeof useReferralCodeRefereesQuery>;
export type ReferralCodeRefereesLazyQueryHookResult = ReturnType<typeof useReferralCodeRefereesLazyQuery>;
export type ReferralCodeRefereesQueryResult = Apollo.QueryResult<ReferralCodeRefereesQuery, ReferralCodeRefereesQueryVariables>;
export const ReferralsRewardsDocument = gql`
    query ReferralsRewards($token: [String!]) {
  referralRewards(
    where: {referralToken_in: $token, pool_: {lastMintTime_gt: "0"}}
  ) {
    referralToken {
      id
    }
    pool {
      id
      token {
        name
        id
        symbol
        tradingFeeRate
        referralDiscountRate
        decimals
      }
      lastMintTime
      mintedReward
      referralLiquidity
      referralPosition
      rewardPerSecond
      referralTokenRewardGrowthX64
      referralTokenPositionRewardGrowthX64
      referralParentTokenPositionRewardGrowthX64
      referralParentTokenRewardGrowthX64
    }
    rewardGrowthX64
    position
    positionRewardGrowthX64
    positionClaimed
    positionUnclaimed
    liquidity
    liquidityClaimed
    liquidityUnclaimed
  }
  referralRewardV2S(where: {referralToken_in: $token}) {
    position
    referralToken {
      id
    }
    market {
      id
      symbol
    }
  }
}
    `;

/**
 * __useReferralsRewardsQuery__
 *
 * To run a query within a React component, call `useReferralsRewardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralsRewardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralsRewardsQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useReferralsRewardsQuery(baseOptions?: Apollo.QueryHookOptions<ReferralsRewardsQuery, ReferralsRewardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReferralsRewardsQuery, ReferralsRewardsQueryVariables>(ReferralsRewardsDocument, options);
      }
export function useReferralsRewardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferralsRewardsQuery, ReferralsRewardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReferralsRewardsQuery, ReferralsRewardsQueryVariables>(ReferralsRewardsDocument, options);
        }
export type ReferralsRewardsQueryHookResult = ReturnType<typeof useReferralsRewardsQuery>;
export type ReferralsRewardsLazyQueryHookResult = ReturnType<typeof useReferralsRewardsLazyQuery>;
export type ReferralsRewardsQueryResult = Apollo.QueryResult<ReferralsRewardsQuery, ReferralsRewardsQueryVariables>;
export const ReferralsRewardsByBlockNumberDocument = gql`
    query ReferralsRewardsByBlockNumber($token: [String!], $number: Int!, $first: Int = 1000) {
  referralRewards(
    block: {number: $number}
    where: {referralToken_in: $token, pool_: {lastMintTime_gt: "0"}}
    first: $first
  ) {
    referralToken {
      id
    }
    pool {
      id
      token {
        name
        id
        symbol
        tradingFeeRate
        referralDiscountRate
        decimals
      }
      lastMintTime
      mintedReward
      referralLiquidity
      referralPosition
      rewardPerSecond
      referralTokenRewardGrowthX64
      referralTokenPositionRewardGrowthX64
      referralParentTokenPositionRewardGrowthX64
      referralParentTokenRewardGrowthX64
    }
    rewardGrowthX64
    position
    positionRewardGrowthX64
    positionClaimed
    positionUnclaimed
    liquidity
    liquidityClaimed
    liquidityUnclaimed
  }
}
    `;

/**
 * __useReferralsRewardsByBlockNumberQuery__
 *
 * To run a query within a React component, call `useReferralsRewardsByBlockNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralsRewardsByBlockNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralsRewardsByBlockNumberQuery({
 *   variables: {
 *      token: // value for 'token'
 *      number: // value for 'number'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useReferralsRewardsByBlockNumberQuery(baseOptions: Apollo.QueryHookOptions<ReferralsRewardsByBlockNumberQuery, ReferralsRewardsByBlockNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReferralsRewardsByBlockNumberQuery, ReferralsRewardsByBlockNumberQueryVariables>(ReferralsRewardsByBlockNumberDocument, options);
      }
export function useReferralsRewardsByBlockNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferralsRewardsByBlockNumberQuery, ReferralsRewardsByBlockNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReferralsRewardsByBlockNumberQuery, ReferralsRewardsByBlockNumberQueryVariables>(ReferralsRewardsByBlockNumberDocument, options);
        }
export type ReferralsRewardsByBlockNumberQueryHookResult = ReturnType<typeof useReferralsRewardsByBlockNumberQuery>;
export type ReferralsRewardsByBlockNumberLazyQueryHookResult = ReturnType<typeof useReferralsRewardsByBlockNumberLazyQuery>;
export type ReferralsRewardsByBlockNumberQueryResult = Apollo.QueryResult<ReferralsRewardsByBlockNumberQuery, ReferralsRewardsByBlockNumberQueryVariables>;
export const RewardsConfigDocument = gql`
    query RewardsConfig {
  rewardFarmConfigs {
    id
    liquidityRate
    referralParentTokenRate
    referralTokenRate
    rewardCap
    riskBufferFundLiquidityRate
  }
}
    `;

/**
 * __useRewardsConfigQuery__
 *
 * To run a query within a React component, call `useRewardsConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardsConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardsConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useRewardsConfigQuery(baseOptions?: Apollo.QueryHookOptions<RewardsConfigQuery, RewardsConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RewardsConfigQuery, RewardsConfigQueryVariables>(RewardsConfigDocument, options);
      }
export function useRewardsConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RewardsConfigQuery, RewardsConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RewardsConfigQuery, RewardsConfigQueryVariables>(RewardsConfigDocument, options);
        }
export type RewardsConfigQueryHookResult = ReturnType<typeof useRewardsConfigQuery>;
export type RewardsConfigLazyQueryHookResult = ReturnType<typeof useRewardsConfigLazyQuery>;
export type RewardsConfigQueryResult = Apollo.QueryResult<RewardsConfigQuery, RewardsConfigQueryVariables>;
export const RiskBufferFundRewardsDocument = gql`
    query riskBufferFundRewards($account: String!) {
  riskBufferFundRewards(where: {account: $account}) {
    unclaimed
    claimed
    liquidity
    rewardGrowthX64
    id
    pool {
      id
      lastMintTime
      mintedReward
      riskBufferFundLiquidity
      riskBufferFundRewardGrowthX64
      rewardPerSecond
      token {
        id
      }
    }
  }
}
    `;

/**
 * __useRiskBufferFundRewardsQuery__
 *
 * To run a query within a React component, call `useRiskBufferFundRewardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRiskBufferFundRewardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRiskBufferFundRewardsQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useRiskBufferFundRewardsQuery(baseOptions: Apollo.QueryHookOptions<RiskBufferFundRewardsQuery, RiskBufferFundRewardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RiskBufferFundRewardsQuery, RiskBufferFundRewardsQueryVariables>(RiskBufferFundRewardsDocument, options);
      }
export function useRiskBufferFundRewardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RiskBufferFundRewardsQuery, RiskBufferFundRewardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RiskBufferFundRewardsQuery, RiskBufferFundRewardsQueryVariables>(RiskBufferFundRewardsDocument, options);
        }
export type RiskBufferFundRewardsQueryHookResult = ReturnType<typeof useRiskBufferFundRewardsQuery>;
export type RiskBufferFundRewardsLazyQueryHookResult = ReturnType<typeof useRiskBufferFundRewardsLazyQuery>;
export type RiskBufferFundRewardsQueryResult = Apollo.QueryResult<RiskBufferFundRewardsQuery, RiskBufferFundRewardsQueryVariables>;
export const StakeDetailsDocument = gql`
    query StakeDetails($account: String) {
  stakeList: stakeDetails(
    where: {account: $account, unstakeTimestamp: 0, type: "0"}
    orderBy: stakeTimestamp
    orderDirection: desc
    first: 1000
  ) {
    claimedReward
    id
    perShareGrowthX64
    multiplier
    period
    stakeID
    stakeTimestamp
    stakeTxHash
    stakedAmount
    unstakeTimestamp
    unstakeTxHash
    type
  }
  stakeLpList: stakeDetails(
    where: {account: $account, unstakeTimestamp: 0, type: "1"}
    orderBy: stakeTimestamp
    orderDirection: desc
    first: 1000
  ) {
    claimedReward
    id
    perShareGrowthX64
    multiplier
    period
    stakeID
    stakeTimestamp
    stakeTxHash
    stakedAmount
    unstakeTimestamp
    unstakeTxHash
    type
  }
}
    `;

/**
 * __useStakeDetailsQuery__
 *
 * To run a query within a React component, call `useStakeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStakeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStakeDetailsQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useStakeDetailsQuery(baseOptions?: Apollo.QueryHookOptions<StakeDetailsQuery, StakeDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StakeDetailsQuery, StakeDetailsQueryVariables>(StakeDetailsDocument, options);
      }
export function useStakeDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StakeDetailsQuery, StakeDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StakeDetailsQuery, StakeDetailsQueryVariables>(StakeDetailsDocument, options);
        }
export type StakeDetailsQueryHookResult = ReturnType<typeof useStakeDetailsQuery>;
export type StakeDetailsLazyQueryHookResult = ReturnType<typeof useStakeDetailsLazyQuery>;
export type StakeDetailsQueryResult = Apollo.QueryResult<StakeDetailsQuery, StakeDetailsQueryVariables>;
export const StakeStatisticDocument = gql`
    query StakeStatistic($blockBefore: Int!) {
  stakeStatistic(id: "stake_statistic") {
    architectPerShareGrowthX64
    architectReward
    equStakedAmount
    id
    liquidityStakedAmount
    multipliers
    perShareGrowthX64
    periods
    stakeReward
    liquidityStakedAmountWithMultiplier
    equStakedAmountWithMultiplier
  }
  stakeStatisticBefore: stakeStatistic(
    id: "stake_statistic"
    block: {number: $blockBefore}
  ) {
    architectPerShareGrowthX64
    architectReward
    equStakedAmount
    id
    liquidityStakedAmount
    multipliers
    perShareGrowthX64
    periods
    stakeReward
  }
}
    `;

/**
 * __useStakeStatisticQuery__
 *
 * To run a query within a React component, call `useStakeStatisticQuery` and pass it any options that fit your needs.
 * When your component renders, `useStakeStatisticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStakeStatisticQuery({
 *   variables: {
 *      blockBefore: // value for 'blockBefore'
 *   },
 * });
 */
export function useStakeStatisticQuery(baseOptions: Apollo.QueryHookOptions<StakeStatisticQuery, StakeStatisticQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StakeStatisticQuery, StakeStatisticQueryVariables>(StakeStatisticDocument, options);
      }
export function useStakeStatisticLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StakeStatisticQuery, StakeStatisticQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StakeStatisticQuery, StakeStatisticQueryVariables>(StakeStatisticDocument, options);
        }
export type StakeStatisticQueryHookResult = ReturnType<typeof useStakeStatisticQuery>;
export type StakeStatisticLazyQueryHookResult = ReturnType<typeof useStakeStatisticLazyQuery>;
export type StakeStatisticQueryResult = Apollo.QueryResult<StakeStatisticQuery, StakeStatisticQueryVariables>;
export const TradersReferralDocument = gql`
    query TradersReferral($account: ID!) {
  referee(id: $account) {
    referralCode {
      id
    }
  }
}
    `;

/**
 * __useTradersReferralQuery__
 *
 * To run a query within a React component, call `useTradersReferralQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradersReferralQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradersReferralQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useTradersReferralQuery(baseOptions: Apollo.QueryHookOptions<TradersReferralQuery, TradersReferralQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TradersReferralQuery, TradersReferralQueryVariables>(TradersReferralDocument, options);
      }
export function useTradersReferralLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TradersReferralQuery, TradersReferralQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TradersReferralQuery, TradersReferralQueryVariables>(TradersReferralDocument, options);
        }
export type TradersReferralQueryHookResult = ReturnType<typeof useTradersReferralQuery>;
export type TradersReferralLazyQueryHookResult = ReturnType<typeof useTradersReferralLazyQuery>;
export type TradersReferralQueryResult = Apollo.QueryResult<TradersReferralQuery, TradersReferralQueryVariables>;