import { useCallback, useMemo } from 'react';

import { orderBookV2ABI } from 'config/abis';
import {
	Contract_Write_Mode,
	QUOTE_USD,
	Side,
	Transaction_Type
} from 'config/constants';
import { getContractAddressV2 } from 'config/contracts';
import Decimal from 'decimal.js';
import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { selectOrderMinExecutionFee } from 'state/global/selector';
import { useAppSelector } from 'state/hooks';
import {
	selectCurrentAcceptableTradePrice,
	selectCurrentBasicFees,
	selectCurrentInitialMargin,
	selectCurrentLimitPrice,
	selectCurrentLiquidityDelta,
	selectCurrentMarginDelta,
	selectCurrentPositionAmount
} from 'state/limit/selector';
import { selectCurrentPool } from 'state/pools/selector';
import { poolsBaseState } from 'state/pools/slice';
import { settingBaseState } from 'state/setting/slice';
import { tradeBaseState } from 'state/trade/slice';
import { txBaseState } from 'state/tx/slice';
import { RecordForLimitOrder } from 'types';
import {
	computePriceX96,
	formatSize,
	isGreaterThan,
	isLessThan,
	isLessThanOrEqualTo,
	isPositive,
	parseUnits,
	shortenSymbolNative,
	toQuoteAmount
} from 'utils';

import { ApprovalState } from 'hooks/useApproval';
import { useComputeGasLimit } from 'hooks/useComputeGasLimit';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import { useSendTransaction } from 'hooks/useSendTransaction';
import { useSlippageTolerance } from 'hooks/useSlippageTolerance';

export function useSubmitIncreaseLimitOrderV2(
	currentApproveState: ApprovalState | undefined,
	isPluginApproved: boolean
) {
	const { transactionType, quoteToken, quoteBalance } =
		useAppSelector(txBaseState);
	const { leverage } = useAppSelector(tradeBaseState);
	const { slippage } = useAppSelector(settingBaseState);
	const { poolInfo } = useAppSelector(poolsBaseState);
	const currentPool = useAppSelector(selectCurrentPool);

	const currentMarginDelta = useAppSelector(selectCurrentMarginDelta);
	const currentPositionAmount = useAppSelector(selectCurrentPositionAmount);
	const currentBasicFees = useAppSelector(selectCurrentBasicFees);
	const currentInitialMargin = useAppSelector(selectCurrentInitialMargin);
	const currentLimitPrice = useAppSelector(selectCurrentLimitPrice);
	const orderMinExecutionFee = useAppSelector(selectOrderMinExecutionFee);
	const currentLiquidityDelta = useAppSelector(selectCurrentLiquidityDelta);
	const currentAcceptableTradePrice = useAppSelector(
		selectCurrentAcceptableTradePrice
	);

	const { currentChainId } = useCurrentChain();
	const isLogin = useCheckLogin();
	const { address } = useAccount();

	const { exactOutRate } = useSlippageTolerance();

	const { args, record, description } = useMemo(() => {
		if (
			!isLogin ||
			!poolInfo ||
			!quoteToken ||
			!isPositive(currentLimitPrice) ||
			!isPositive(quoteBalance) ||
			!isPositive(currentMarginDelta) ||
			!isPositive(currentPositionAmount) ||
			!isPositive(currentAcceptableTradePrice) ||
			isLessThan(currentMarginDelta, poolInfo.minMarginPerPosition) ||
			!isLessThanOrEqualTo(currentMarginDelta, quoteBalance) ||
			(leverage &&
				leverage !== '-' &&
				isGreaterThan(leverage, poolInfo.maxLeveragePerPosition)) ||
			!isPositive(currentInitialMargin) ||
			currentApproveState !== ApprovalState.APPROVED ||
			!isPluginApproved
		) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		console.log('useSubmitIncreaseLimit begin ...');

		const { id: pool } = poolInfo;
		const side =
			transactionType === Transaction_Type.LongLimitOrderV2
				? Side.LONG
				: Side.SHORT;
            
		// 使用用户支付的初始保证金
		const marginDelta = parseUnits(currentMarginDelta, quoteToken.decimals);

		const triggerMarketPriceX96 = computePriceX96(
			currentLimitPrice,
			poolInfo.baseToken.decimals,
			quoteToken.decimals
		);

		const acceptableTradePriceX96 = computePriceX96(
			currentAcceptableTradePrice,
			poolInfo.baseToken.decimals,
			quoteToken.decimals
		);

		const sizeDelta = parseUnits(
			currentPositionAmount,
			poolInfo.baseToken.decimals
		);

		let triggerAbove;
		if (
			isLessThanOrEqualTo(
				currentPool?.market_price as string,
				currentLimitPrice
			)
		) {
			triggerAbove = true;
		} else {
			triggerAbove = false;
		}

		const args = [
			pool,
			side,
			marginDelta,
			sizeDelta,
			triggerMarketPriceX96,
			triggerAbove,
			acceptableTradePriceX96
		];

		const record = {
			transactionType:
				side === Side.LONG
					? Transaction_Type.LongLimitOrderV2
					: Transaction_Type.ShortLimitOrderV2,
			pool: poolInfo.id,
			baseToken: poolInfo.baseToken,
			quoteAmount: currentMarginDelta,
			positionAmount: currentPositionAmount,
			entryPrice: poolInfo.price,
			initialMargin: currentInitialMargin,
			triggerMarketPrice: currentLimitPrice,
			acceptableTradePrice: currentAcceptableTradePrice,
			liquidityDelta: currentLiquidityDelta,
			triggerAbove
		} as RecordForLimitOrder;

		const baseSymbolNative = shortenSymbolNative(
			poolInfo.baseToken,
			currentChainId
		);
		const poolSymbol = `${shortenSymbolNative(
			poolInfo.baseToken,
			currentChainId
		)}/${QUOTE_USD}`;

		const description = TransactionDescription.CreateLimitOrder(
			poolSymbol,
			baseSymbolNative,
			formatSize(currentPositionAmount, poolInfo.baseToken.positionUnits),
			toQuoteAmount(
				currentLimitPrice,
				poolInfo.baseToken.precision,
				Decimal.ROUND_HALF_CEIL
			),
			triggerAbove,
			side
		);

		return {
			args,
			record,
			description
		};
	}, [
		isLogin,
		transactionType,
		poolInfo,
		quoteToken,
		currentMarginDelta,
		currentPositionAmount,
		currentAcceptableTradePrice,
		currentApproveState,
		isPluginApproved,
		quoteBalance,
		leverage,
		slippage,
		exactOutRate,
		currentLimitPrice,
		currentChainId,
		currentBasicFees,
		currentPool
	]);

	const overrides = useMemo(() => {
		if (!address || !isPositive(orderMinExecutionFee)) {
			return undefined;
		}
		return {
			from: address,
			value: orderMinExecutionFee
		};
	}, [address, orderMinExecutionFee]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address: getContractAddressV2(currentChainId, 'OrderBook'),
			abi: orderBookV2ABI,
			functionName: 'createIncreaseOrder',
			args,
			overrides
		} as UseContractWriteConfig;
	}, [currentChainId, args, overrides]);

	const { gasLimit, errMsg } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirming, isConfirmed } = useSendTransaction(
		contractWriteArgs,
		gasLimit
	);

	return {
		onConfirm: useCallback(() => {
			if (!record) return;
			onSendTransaction(record, description);
		}, [transactionType, onSendTransaction, record]),
		isConfirming,
		isConfirmed,
		errMsg
	};
}
