import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans } from '@lingui/macro';
import { Divider, FormControlLabel, Paper } from '@mui/material';
import { Dom_Size, Trade_Type, Transaction_Type } from 'config/constants';
import { TradeContext } from 'context/TradeContext';
import { IOrderItem, useMyOrdersFetch } from 'fetch/useMyOrdersFetch';
import { useAccount } from 'wagmi';

import { TransactionRecord } from 'entities/TransactionRecord';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin } from 'hooks/useCurrentChain';
import { useLocalOrders } from 'hooks/useLocalOrders';
import { useV2TradePositionRequest } from 'hooks/useLoopMyPositions';
import CancelAllOrdersV2 from 'pages/components/Operations/V2/CancelAllOrdersV2';
import { globalBaseState } from 'state/global/slice';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { selectPoolIds } from 'state/pools/selector';
import { poolsBaseState } from 'state/pools/slice';
import { selectOrderOpenType, selectOrderStatus } from 'state/setting/selector';
import { setOnlyCurrent, settingBaseState } from 'state/setting/slice';
import {
	checkOrderIsExpired,
	formatModuleNumber,
	isGreaterThan,
	isPositive
} from 'utils';

import { StyleCheckbox } from 'components/Common/StyleCheckbox';
import { CommonStyledDivider } from 'components/Common/Styled';
import StyledTab from 'components/Common/StyledTab';
import StyledTabs from 'components/Common/StyledTabs';
import SectionLoading from 'components/SectionLoading';

import { setOrders } from '../../state/trade/slice';
import ExpiredOrderWarning from '../components/ExpiredOrderWarning';
import HistoryList from '../components/Operations/HistoryList';
import PositionsList from '../components/Operations/PositionsList';
import OpenOrdersListV2 from '../components/Operations/V2/OpenOrdersListV2';
import ViewUpdaterV2 from '../components/ViewUpdaterV2';

export enum Tab_Type {
	Positions = 0,
	Orders = 1,
	History = 2
}

// View Detail Mode: 全局账户仓位列表
export default function Operation() {
	const dispatch = useAppDispatch();
	const { appTimer } = useAppSelector(globalBaseState);
	const { onlyCurrent } = useAppSelector(settingBaseState);
	const { poolAddress } = useAppSelector(poolsBaseState);
	const Order_Status = useAppSelector(selectOrderStatus);
	const Order_Open_Type = useAppSelector(selectOrderOpenType);
	const poolIds = useAppSelector(selectPoolIds);
	const { isMatchMobile, isMatchPad } = useAppBreakpoints();
	const { tradeType } = useContext(TradeContext);

	const [type, setType] = useState<Tab_Type>(Tab_Type.Positions);

	const isLogin = useCheckLogin();
	const { address } = useAccount();

	const { orderAlldRecords, orderHashes } = useLocalOrders();

	const targetPool = useMemo(() => {
		if (onlyCurrent) {
			return poolAddress;
		}
		return undefined;
	}, [poolAddress, onlyCurrent]);

	const { positionList, positionMap, isLoadingPositions } =
		useV2TradePositionRequest(onlyCurrent ? targetPool : '');

	const {
		orderList: createdList,
		loading: isLoadingOrders,
		requestsByHashes,
		refetch: refetchOrders
	} = useMyOrdersFetch(onlyCurrent ? poolAddress : undefined, orderHashes);

	// const createdList = useMemo(() => {
	// 	if (!orderList || orderList.length === 0) {
	// 		return null;
	// 	}
	// 	const _list = orderList.filter((item: IOrderItem) => {
	// 		return item.orderStatus === Order_Status.Created;
	// 	});
	// 	return _list;
	// }, [orderList]);
	const expiredOrders = useMemo(() => {
		if (!createdList) {
			return [];
		}
		return createdList.filter(order =>
			checkOrderIsExpired(order, positionMap, Order_Open_Type)
		);
	}, [createdList, positionMap]);

	useEffect(() => {
		dispatch(setOrders(expiredOrders));
	}, [expiredOrders]);

	const createdOrderTotal = useMemo(() => {
		if (!createdList || createdList.length === 0) {
			return '0';
		}
		return createdList.length;
	}, [createdList]);

	const targetPoolsId = useMemo(() => {
		if (onlyCurrent && poolAddress) {
			return [poolAddress];
		}
		return poolIds;
	}, [poolAddress, poolIds, onlyCurrent]);

	const containerHeight = useMemo(() => {
		// if (isMatchMobile) {
		// 	return 'auto';
		// }
		if (tradeType === Trade_Type.Market) {
			return Dom_Size.HEIGHT_OPERATION;
		} else {
			return Dom_Size.HEIGHT_OPERATION + 40;
		}
	}, [tradeType, isMatchMobile]);

	useUpdateEffect(() => {
		if (isLogin) {
			refetchOrders(0);
		}
	}, [isLogin, address, appTimer]);

	const onHandleChangeType = (value: Tab_Type) => {
		setType(value);
	};

	const onHandleChangeOnlyCurrent = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		dispatch(setOnlyCurrent(event.target.checked));
	};

	return (
		<Paper
			className='p-4 sm:p-3 box-border'
			sx={{
				minHeight: containerHeight
			}}
		>
			<div className='flex sm:block justify-between space-x-2 overflow-hidden'>
				<StyledTabs
					value={type}
					onChange={(event, newType) => onHandleChangeType(newType)}
				>
					<StyledTab
						className='pl-0 pr-3'
						label={
							<div className='space-x-0.5'>
								<span>
									<Trans>Positions</Trans>
								</span>
								{isLogin && positionList && isPositive(positionList.length) && (
									<span>{formatModuleNumber(positionList.length)}</span>
								)}
							</div>
						}
						value={Tab_Type.Positions}
					/>
					<StyledTab
						className='px-3'
						label={
							<div className='flex gap-1'>
								<div className='space-x-0.5'>
									<span>
										<Trans>Orders</Trans>
									</span>
									{isLogin && createdList && isPositive(createdList.length) && (
										<span>{formatModuleNumber(createdList.length)}</span>
									)}
								</div>
								<ExpiredOrderWarning
									createdOrderList={isLogin ? createdList : null}
									positionMap={positionMap}
								/>
							</div>
						}
						value={Tab_Type.Orders}
					/>
					<StyledTab
						className='px-3'
						label={<Trans>History</Trans>}
						value={Tab_Type.History}
					/>
				</StyledTabs>
				<div className='flex items-center space-x-2'>
					{isMatchPad &&
						isLogin &&
						type === Tab_Type.Orders &&
						isGreaterThan(createdOrderTotal, 0) && (
							<CancelAllOrdersV2 list={createdList} />
						)}
					{!isMatchMobile && (
						<FormControlLabel
							control={
								<StyleCheckbox
									className='py-0 -mr-1'
									onChange={onHandleChangeOnlyCurrent}
									size='small'
									checked={onlyCurrent}
								/>
							}
							label={<Trans>Hide other markets</Trans>}
							sx={{ marginRight: 0 }}
						/>
					)}
				</div>
			</div>
			<CommonStyledDivider type='solid' />
			{isMatchMobile && (
				<div className='space-y-3 mb-3'>
					<div className='flex justify-between h-6'>
						<FormControlLabel
							control={
								<StyleCheckbox
									className='py-0 -mr-1'
									onChange={onHandleChangeOnlyCurrent}
									size='small'
									checked={onlyCurrent}
								/>
							}
							label={<Trans>Hide other markets</Trans>}
							sx={{ fontSize: 12 }}
						/>
						{type === Tab_Type.Orders &&
							isLogin &&
							isGreaterThan(createdOrderTotal, 0) && (
								<CancelAllOrdersV2 list={createdList} />
							)}
					</div>
					<Divider />
				</div>
			)}
			{!poolAddress ? (
				<SectionLoading />
			) : (
				<article>
					{type === Tab_Type.Positions &&
						(isLoadingPositions || (isLogin && !positionMap) ? (
							<SectionLoading />
						) : (
							<PositionsList
								list={isLogin ? positionList : null}
								poolId={targetPool}
							/>
						))}

					{type === Tab_Type.Orders &&
						(isLoadingOrders ? (
							<SectionLoading />
						) : (
							<OpenOrdersListV2
								list={isLogin ? createdList : null}
								poolId={targetPool}
								positionMap={positionMap}
							/>
						))}

					{type === Tab_Type.History && (
						<HistoryList targetPoolsId={targetPoolsId} />
					)}
				</article>
			)}
			<ViewUpdaterV2
				positionMap={positionMap}
				orderList={createdList}
				orderAlldRecords={orderAlldRecords}
				requestsByHashes={requestsByHashes}
			/>
		</Paper>
	);
}
