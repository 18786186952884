import React, { useState } from 'react';

import { Trans } from '@lingui/macro';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Popover, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAccount, useDisconnect } from 'wagmi';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { ExplorerDataType, getExplorerLink, shortenAddress } from 'utils';

import Copy from 'components/Copy';

import {
	CommonStyledDivider,
	CommonStyledOutlinedButton
} from '../../components/Common/Styled';
import AccountAvatar from './AccountAvatar';

export default function Account() {
	const [open, setOpen] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<any | null>(null);
	const { address } = useAccount();
	const { disconnect } = useDisconnect();
	const { currentChain: chain } = useCurrentChain();
	const { isMatchMobile } = useAppBreakpoints();
	const onHandleDisconnect = () => disconnect();
	const onHandleOpen = (event: React.MouseEvent) => {
		setOpen(true);
		setAnchorEl(event.currentTarget);
	};
	const onClose = (e: React.MouseEvent) => {
		setOpen(false);
		setAnchorEl(null);
		e.stopPropagation();
	};
	const theme = useTheme();

	return (
		<Box
			className='flex space-x-2'
			onClick={onHandleOpen}
			sx={{
				borderRadius: '4px',
				padding: '4px 8px',
				background: theme.custom.selectBg,
				fontSize: '14px',
				fontWeight: 500,
				color: theme.palette.text.primary,
				'&:hover': {
					background: theme.palette.action.hover
				}
			}}
		>
			<Box className='flex cursor-pointer items-center space-x-1'>
				<AccountAvatar size={isMatchMobile ? 18 : 22} />
				{!isMatchMobile && <div>{shortenAddress(address || '')}</div>}
				<ExpandMoreIcon />
			</Box>
			<Popover
				className='translate-y-1.5 transform'
				open={open}
				anchorEl={anchorEl}
				onClose={onClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
			>
				<Box className='w-[340px] sm:w-full'>
					<Box className='px-4 py-5 sm:py-3'>
						<div className='flex justify-center'>
							<Typography className='text-lg font-medium'>Account</Typography>
						</div>
						<CommonStyledDivider type='solid' className='my-4' />
						<div className='flex items-center justify-between'>
							<div className='flex items-center'>
								<AccountAvatar size={22} />
								<Typography className='ml-1 text-base font-bold'>
									{shortenAddress(address || '')}
								</Typography>
								<Copy value={address}></Copy>
							</div>
						</div>
						<div className='flex gap-4 mt-4'>
							<CommonStyledOutlinedButton
								className='sm:mt-0.5 flex-1'
								variant='outlined'
								color='secondary'
								onClick={() => {
									window.open(
										getExplorerLink(
											chain,
											address || '',
											ExplorerDataType.ADDRESS
										),
										isMatchMobile ? '_self' : '_blank'
									);
								}}
							>
								<Trans>View on Explorer</Trans>
							</CommonStyledOutlinedButton>
							<CommonStyledOutlinedButton
								className='sm:mt-0.5 flex-1'
								variant='outlined'
								color='secondary'
								onClick={onHandleDisconnect}
							>
								<Trans>Disconnect</Trans>
							</CommonStyledOutlinedButton>
						</div>
					</Box>
				</Box>
			</Popover>
		</Box>
	);
}
