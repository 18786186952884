import { createSelector } from '@reduxjs/toolkit';
import { Transaction_Type } from 'config/constants';

import { settingBaseState } from 'state/setting/slice';
import { tradeBaseState } from 'state/trade/slice';
import { txBaseState } from 'state/tx/slice';
import {
	div,
	isGreaterThan,
	isNumeric,
	isPositive,
	multipliedBy,
	plus
} from 'utils';

import { marketBaseState } from './slice';

export const selectCurrentMarginDelta = createSelector(
	[marketBaseState, txBaseState],
	(state, txBaseState) => {
		const { transactionType } = txBaseState;
		const { marginDeltaForLong, marginDeltaForShort } = state;

		if (
			transactionType === Transaction_Type.Long ||
			transactionType === Transaction_Type.LongV2
		) {
			return marginDeltaForLong;
		} else {
			return marginDeltaForShort;
		}
	}
);

export const selectCurrentImpactFee = createSelector(
	[marketBaseState, txBaseState],
	(state, txBaseState) => {
		const { transactionType } = txBaseState;
		const { impactFeeForLong, impactFeeForShort } = state;

		if (
			transactionType === Transaction_Type.Long ||
			transactionType === Transaction_Type.LongV2
		) {
			return impactFeeForLong;
		} else {
			return impactFeeForShort;
		}
	}
);

export const selectCurrentTradingFee = createSelector(
	[marketBaseState, txBaseState],
	(state, txBaseState) => {
		const { transactionType } = txBaseState;
		const { tradingFeeForLong, tradingFeeForShort } = state;

		if (
			transactionType === Transaction_Type.Long ||
			transactionType === Transaction_Type.LongV2
		) {
			return tradingFeeForLong;
		} else {
			return tradingFeeForShort;
		}
	}
);

export const selectCurrentOriginTradingFee = createSelector(
	[marketBaseState, txBaseState],
	(state, txBaseState) => {
		const { transactionType } = txBaseState;
		const { originTradingFeeForLong, originTradingFeeForShort } = state;

		if (
			transactionType === Transaction_Type.Long ||
			transactionType === Transaction_Type.LongV2
		) {
			return originTradingFeeForLong;
		} else {
			return originTradingFeeForShort;
		}
	}
);

// 当平衡费是正数时，费用仅仅需要支付交易手续费
// 当平衡费是负数时，费用需要支付交易手续费+平衡费
export const selectCurrentBasicFees = createSelector(
	[selectCurrentImpactFee, selectCurrentTradingFee],
	(currentImpactFee, currentTradingFee) => {
		if (!currentImpactFee || !currentTradingFee) {
			return '';
		}
		if (isPositive(currentImpactFee)) {
			return currentTradingFee;
		}
		return plus(currentImpactFee, currentTradingFee);
	}
);

export const selectCurrentInitialMargin = createSelector(
	[marketBaseState, txBaseState],
	(state, txBaseState) => {
		const { transactionType } = txBaseState;
		const { initialMarginForLong, initialMarginForShort } = state;

		if (
			transactionType === Transaction_Type.Long ||
			transactionType === Transaction_Type.LongV2
		) {
			return initialMarginForLong;
		} else {
			return initialMarginForShort;
		}
	}
);

export const selectCurrentPositionAmount = createSelector(
	[marketBaseState, txBaseState],
	(state, txBaseState) => {
		const { transactionType } = txBaseState;
		const { sizeForLong, sizeForShort } = state;

		if (
			transactionType === Transaction_Type.Long ||
			transactionType === Transaction_Type.LongV2
		) {
			return sizeForLong;
		} else {
			return sizeForShort;
		}
	}
);

// 当前添加的流动性变化
export const selectCurrentLiquidityDelta = createSelector(
	[marketBaseState, tradeBaseState, selectCurrentMarginDelta],
	(state, tradeBaseState, currentMarginDelta) => {
		const { leverage } = tradeBaseState;
		if (!isPositive(currentMarginDelta) || !isPositive(leverage)) {
			return '';
		}
		return multipliedBy(currentMarginDelta, leverage);
	}
);

// 可接受的平衡影响费
export const selectCurrentAcceptableBalanceImpactFee = createSelector(
	[settingBaseState, selectCurrentLiquidityDelta],
	(settingBaseState, currentLiquidityDelta) => {
		const { impactFeeRate } = settingBaseState;
		if (!isPositive(currentLiquidityDelta) || !isPositive(impactFeeRate)) {
			return '';
		}
		return multipliedBy(-currentLiquidityDelta, div(impactFeeRate, 100));
	}
);

// 可接受的影响费是否超出预估的影响费
export const selectIsExceedBalanceImpactFee = createSelector(
	[selectCurrentAcceptableBalanceImpactFee, selectCurrentImpactFee],
	(currentAcceptableImpactFee, currentImpactFee) => {
		if (
			!isNumeric(currentAcceptableImpactFee) ||
			!isNumeric(currentImpactFee)
		) {
			return false;
		}
		return isGreaterThan(currentAcceptableImpactFee, currentImpactFee);
	}
);

export const selectCurrenDirection = createSelector(
	[marketBaseState, txBaseState],
	(state, txBaseState) => {
		const { transactionType } = txBaseState;
		const { directionForLong, directionForShort } = state;

		if (
			transactionType === Transaction_Type.Long ||
			transactionType === Transaction_Type.LongV2
		) {
			return directionForLong;
		} else {
			return directionForShort;
		}
	}
);
