import React, { ReactNode } from 'react';

export function Grids({
	cols = 3,
	children
}: {
	cols?: number;
	children: ReactNode;
}) {
	const childrens = React.Children.toArray(children);
	return (
		<section>
			{cols === 3 ? (
				<div className={`grid grid-cols-10 gap-2`}>
					<div className='col-span-4'>{childrens[0]}</div>
					<div className='col-span-3'>{childrens[1]}</div>
					{childrens[2] && <div className='col-span-3'>{childrens[2]}</div>}
				</div>
			) : (
				<div className={`flex space-x-2`}>
					<div className='flex-1'>{childrens[0]}</div>
					<div className='flex-1F flex justify-end'>{childrens[1]}</div>
				</div>
			)}
		</section>
	);
}
