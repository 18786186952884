import { useMemo } from 'react';

import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { formatNumber } from 'utils';

import { FeeDistributorABI } from '../config/abis';
import {
	Contract_Write_Mode,
	Transaction_Type
} from '../config/constants';
import { getContractAddress } from '../config/contracts';
import { RecordUnStakeAppToken } from '../types';
import { useComputeGasLimit } from './useComputeGasLimit';
import { useCurrentChain } from './useCurrentChain';
import { useSendTransaction } from './useSendTransaction';

const useSubmitUnStakeAppToken = (
	tokenId: string | undefined,
	stakedAmount: string | undefined,
	claimableAmoount: string | undefined,
) => {
	const { quoteToken, appToken } = useAppSelector(txBaseState);

	const { currentChainId } = useCurrentChain();

	const { address } = useAccount();

	const { args, record, description } = useMemo(() => {
		if (!tokenId || !quoteToken) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		const args = [[tokenId], address];

		const record = {
			transactionType: Transaction_Type.UnStakeAppToken,
			tokenId
		} as RecordUnStakeAppToken;

		const description = TransactionDescription.UnStakeAppToken(
			tokenId,
			formatNumber(stakedAmount, appToken.positionUnits),
			formatNumber(claimableAmoount, quoteToken.positionUnits)
		);

		return {
			args,
			record,
			description
		};
	}, [
		quoteToken,
		appToken,
		tokenId,
		claimableAmoount,
		currentChainId
	]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address: getContractAddress(currentChainId, 'FeeDistributor'),
			abi: FeeDistributorABI,
			functionName: 'unstake',
			args
		} as UseContractWriteConfig;
	}, [currentChainId, args]);

	const { gasLimit } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, setTxSuccessCallback, isConfirmed, isConfirming } =
		useSendTransaction(contractWriteArgs, gasLimit);

	const onConfirm = () => {
		if (!record) return;
		onSendTransaction(record, description);
	};

	return { onConfirm, setTxSuccessCallback, isConfirmed, isConfirming };
};

export default useSubmitUnStakeAppToken;
