import { useCallback, useMemo } from 'react';

import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { usePriceRangesByTolerance } from 'hooks/useSlippageTolerance';

import { PositionRouterV2ABI } from '../../../../config/abis';
import {
	Contract_Write_Mode,
	QUOTE_USD,
	Transaction_Type
} from '../../../../config/constants';
import { getContractAddressV2 } from '../../../../config/contracts';
import { TransactionDescription } from '../../../../entities/TransactionDescription';
import { ApprovalState } from '../../../../hooks/useApproval';
import { useComputeGasLimit } from '../../../../hooks/useComputeGasLimit';
import {
	useCheckLogin,
	useCurrentChain
} from '../../../../hooks/useCurrentChain';
import { useSendTransaction } from '../../../../hooks/useSendTransaction';
import { selectPositionMinExecutionFee } from '../../../../state/global/selector';
import { useAppSelector } from '../../../../state/hooks';
import { liquidityBaseState } from '../../../../state/liquidity/slice';
import { poolsBaseState } from '../../../../state/pools/slice';
import { txBaseState } from '../../../../state/tx/slice';
import { RecordForLiqudility } from '../../../../types';
import {
	formatNumber,
	isGreaterThan,
	isLessThan,
	isPositive,
	parseUnits,
	shortenSymbolNative,
	toQuoteAmount
} from '../../../../utils';

const useSubmitIncreaseLiquidityV2 = (
	currentApproveState: ApprovalState | undefined,
	isPluginApproved: boolean
) => {
	const { transactionType, quoteToken, quoteBalance } =
		useAppSelector(txBaseState);
	const positionMinExecutionFee = useAppSelector(selectPositionMinExecutionFee);
	const { leverage, quoteAmount, liquidityAmount, isPureAdd } =
		useAppSelector(liquidityBaseState);
	const { poolInfo, liquidityPools } = useAppSelector(poolsBaseState);

	const liquidityPool = useMemo(() => {
		const targetPoolAddress = poolInfo?.address.toLowerCase();
		return liquidityPools.find(item => item.address === targetPoolAddress);
	}, [poolInfo, liquidityPools]);
	const isAppendPosition = isPositive(liquidityPool?.liquidityPositions.length);

	const { currentChainId } = useCurrentChain();
	const isLogin = useCheckLogin();
	const { address } = useAccount();
	const { minPrice: minMargin, maxPrice: maxMargin } =
		usePriceRangesByTolerance(quoteAmount);

	const { args, record, description } = useMemo(() => {
		if (
			!isLogin ||
			!poolInfo ||
			!quoteToken ||
			(!isPositive(quoteBalance) && !isPureAdd) ||
			(!isPositive(quoteAmount) && !isPureAdd) ||
			!isPositive(liquidityAmount) ||
			(isLessThan(quoteAmount, poolInfo.minMarginPerLiquidityPosition) &&
				!isPureAdd &&
				!isPositive(liquidityPool?.liquidityPositions.length)) ||
			(isGreaterThan(quoteAmount, quoteBalance) && !isPureAdd) ||
			isGreaterThan(leverage, poolInfo.maxLeveragePerLiquidityPosition) ||
			(currentApproveState !== ApprovalState.APPROVED && !isPureAdd) ||
			!isPluginApproved
		) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		const { address: marketAddress } = poolInfo;
		const margin = !isPureAdd
			? parseUnits(quoteAmount, quoteToken.decimals)
			: '0';
		const liquidity = parseUnits(liquidityAmount, quoteToken.decimals);
		const acceptableMinMargin = !isPureAdd
			? parseUnits(minMargin, quoteToken.decimals)
			: '0';
		const args = [marketAddress, margin, liquidity, acceptableMinMargin];

		const record = {
			transactionType: isAppendPosition
				? Transaction_Type.AppendLiquidityV2
				: Transaction_Type.IncreaseLiquidityV2,
			pool: marketAddress,
			baseToken: poolInfo.baseToken,
			quoteAmount: quoteAmount ?? '0',
			liquidityAmount,
			entryPrice: poolInfo.price,
			id: isAppendPosition ? liquidityPool?.liquidityPositions[0].id : null
		} as RecordForLiqudility;

		const description = TransactionDescription.CreateOpenLiquidityPosition(
			`${shortenSymbolNative(poolInfo.baseToken, currentChainId)}/${QUOTE_USD}`,
			toQuoteAmount(liquidityAmount),
			quoteAmount ? formatNumber(quoteAmount) : '0'
		);

		return {
			args,
			record,
			description
		};
	}, [
		isLogin,
		transactionType,
		poolInfo,
		quoteToken,
		quoteAmount,
		liquidityAmount,
		currentApproveState,
		isPluginApproved,
		quoteBalance,
		leverage,
		currentChainId,
		isPureAdd
	]);

	const overrides = useMemo(() => {
		if (!address || !isPositive(positionMinExecutionFee)) {
			return undefined;
		}
		return {
			from: address,
			value: positionMinExecutionFee
		};
	}, [address, positionMinExecutionFee]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address: getContractAddressV2(currentChainId, 'PositionRouter'),
			abi: PositionRouterV2ABI,
			functionName: 'createIncreaseLiquidityPosition',
			args,
			overrides
		} as UseContractWriteConfig;
	}, [currentChainId, args, address, overrides]);

	const { gasLimit, errMsg } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirming, isConfirmed } = useSendTransaction(
		contractWriteArgs,
		gasLimit
	);

	return {
		onConfirm: useCallback(() => {
			if (!record) return;
			onSendTransaction(record, description);
		}, [transactionType, onSendTransaction, record]),
		isConfirming,
		isConfirmed,
		errMsg
	};
};

export default useSubmitIncreaseLiquidityV2;
