import React, { useMemo } from 'react';

import { Trans } from '@lingui/macro';

import warningIcon from '../../assets/svg/icon-warning.svg';
import Tooltip from '../../components/Common/Tooltip';
import { IOrderItem } from '../../graphql/useMyOrdersGraph';
import { IPositionItem } from '../../graphql/useMyPositionsGraph';
import { checkOrderIsExpired } from '../../utils';
import { useAppSelector } from 'state/hooks';
import { selectOrderStatus, selectOrderOpenType } from 'state/setting/selector'

const ExpiredOrderWarning = ({
	createdOrderList,
	positionMap
}: {
	createdOrderList: IOrderItem[];
	positionMap: Map<string, IPositionItem[]>;
}) => {
	const Order_Status = useAppSelector(selectOrderStatus);
	const Order_Open_Type = useAppSelector(selectOrderOpenType);
	const hasExpiredOrder = useMemo(() => {
		if (!createdOrderList) {
			return false;
		}

		return createdOrderList
			.filter(order => order.orderStatus === Order_Status.Created)
			.some(order => checkOrderIsExpired(order, positionMap, Order_Open_Type));
	}, [createdOrderList, positionMap]);

	return hasExpiredOrder ? (
		<Tooltip
			title={
				<Trans>
					After closing a position, if there are still uncancelled or unexecuted
					TP/SL orders, these orders remain valid for newly opened positions.
					Before opening new positions, please confirm whether the orders in the
					&apos;Orders&apos; section need to be cancelled.
				</Trans>
			}
		>
			<img className='mt-0.5' width={16} src={warningIcon} alt='' />
		</Tooltip>
	) : null;
};

export default ExpiredOrderWarning;
