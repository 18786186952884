import { useCallback, useMemo } from 'react';

import { positionRouterABI } from 'config/abis';
import {
	Contract_Write_Mode,
	QUOTE_USD,
	Transaction_Type
} from 'config/constants';
import { getContractAddress } from 'config/contracts';
import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { selectPositionMinExecutionFee } from 'state/global/selector';
import { useAppSelector } from 'state/hooks';
import { liquidityBaseState } from 'state/liquidity/slice';
import { txBaseState } from 'state/tx/slice';
import { RecordForLiqudility } from 'types';
import {
	formatNumber,
	isGreaterThan,
	isLessThan,
	isPositive,
	parseUnits,
	shortenSymbolNative,
	toQuoteAmount
} from 'utils';

import { ApprovalState } from './useApproval';
import { useComputeGasLimit } from './useComputeGasLimit';
import { useCheckLogin, useCurrentChain } from './useCurrentChain';
import { useSendTransaction } from './useSendTransaction';
import { poolsBaseState } from 'state/pools/slice';

export function useSubmitIncreaseLiquidity(
	currentApproveState: ApprovalState | undefined,
	isPluginApproved: boolean
) {
	const { transactionType, quoteToken, quoteBalance } =
		useAppSelector(txBaseState);
	const positionMinExecutionFee = useAppSelector(selectPositionMinExecutionFee);
	const { leverage, quoteAmount, liquidityAmount } =
		useAppSelector(liquidityBaseState);
	const { poolInfo } = useAppSelector(poolsBaseState);

	const { currentChainId } = useCurrentChain();
	const isLogin = useCheckLogin();
	const { address } = useAccount();

	const { args, record, description } = useMemo(() => {
		if (
			!isLogin ||
			!poolInfo ||
			!quoteToken ||
			!isPositive(quoteBalance) ||
			!isPositive(quoteAmount) ||
			!isPositive(liquidityAmount) ||
			isLessThan(quoteAmount, poolInfo.minMarginPerLiquidityPosition) ||
			isGreaterThan(quoteAmount, quoteBalance) ||
			isGreaterThan(leverage, poolInfo.maxLeveragePerLiquidityPosition) ||
			currentApproveState !== ApprovalState.APPROVED ||
			!isPluginApproved
		) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		const { id: pool } = poolInfo;
		const margin = parseUnits(quoteAmount, quoteToken.decimals);
		const liquidity = parseUnits(liquidityAmount, quoteToken.decimals);

		const args = [pool, margin, liquidity];

		const record = {
			transactionType: Transaction_Type.IncreaseLiquidity,
			pool: poolInfo.id,
			baseToken: poolInfo.baseToken,
			quoteAmount: quoteAmount,
			liquidityAmount,
			entryPrice: poolInfo.price
		} as RecordForLiqudility;

		const description = TransactionDescription.CreateOpenLiquidityPosition(
			`${shortenSymbolNative(poolInfo.baseToken, currentChainId)}/${QUOTE_USD}`,
			toQuoteAmount(liquidityAmount),
			formatNumber(quoteAmount)
		);

		return {
			args,
			record,
			description
		};
	}, [
		isLogin,
		transactionType,
		poolInfo,
		quoteToken,
		quoteAmount,
		liquidityAmount,
		currentApproveState,
		isPluginApproved,
		quoteBalance,
		leverage,
		currentChainId
	]);

	const overrides = useMemo(() => {
		if (!address || !isPositive(positionMinExecutionFee)) {
			return undefined;
		}
		return {
			from: address,
			value: positionMinExecutionFee
		};
	}, [address, positionMinExecutionFee]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address: getContractAddress(currentChainId, 'PositionRouter'),
			abi: positionRouterABI,
			functionName: 'createOpenLiquidityPosition',
			args,
			overrides
		} as UseContractWriteConfig;
	}, [currentChainId, args, address, overrides]);

	const { gasLimit, errMsg } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirming, isConfirmed } = useSendTransaction(
		contractWriteArgs,
		gasLimit
	);

	return {
		onConfirm: useCallback(() => {
			if (!record) return;
			onSendTransaction(record, description);
		}, [transactionType, onSendTransaction, record]),
		isConfirming,
		isConfirmed,
		errMsg
	};
}
