import {
	BASIS_POINTS_DIVISOR_BIGINT,
	DEFAULT_PRECISION,
	DEFAULT_QUOTE_PRECISION,
	Q64,
	Q64_BIGINT,
	QUOTE_USD_PRECISION,
	REFERRAL_MULTIPLIER_BIGINT,
	REWARD_CAP
} from 'config/constants';

import {
	bigIntMulDiv,
	div,
	formatUnits,
	isPositive,
	minus,
	multipliedBy,
	parseUnits,
	plus
} from 'utils';

export class RewardsUtil {
	public static calculateStakeReward(
		perShareGrowthX64: string,
		totalPerShareGrowthX64: string,
		stakedAmount: string,
		multiplier: number
	) {
		return formatUnits(
			multipliedBy(
				multipliedBy(
					div(minus(totalPerShareGrowthX64, perShareGrowthX64), Q64),
					parseUnits(stakedAmount, DEFAULT_PRECISION)
				),
				multiplier
			),
			QUOTE_USD_PRECISION
		);
	}

	public static calculateMembersReward(
		poolReward: any,
		config: any,
		blockTimestamp: string | number
	) {
		const poolInfo = poolReward.pool;
		const defaultData = {
			id: poolReward.id,
			poolId: poolInfo.id,
			rewardsEQU: poolReward.liquidityUnclaimed
		};
		const _blockTimestamp = BigInt(blockTimestamp);
		const _lastMintTime = BigInt(poolInfo.lastMintTime);
		const _mintedReward = BigInt(
			parseUnits(poolInfo.mintedReward, DEFAULT_PRECISION)
		);
		const _rewardPerSecond = BigInt(poolInfo.rewardPerSecond);
		const totalReward = this._calculateReward(
			_blockTimestamp,
			_lastMintTime,
			_mintedReward,
			_rewardPerSecond
		);
		const totalReferralLiquidity = plus(
			poolInfo.referralLiquidity,
			poolInfo.referralPosition
		);

		if (totalReferralLiquidity != '0') {
			const reward = this._splitReward(
				totalReward,
				BigInt(config.referralTokenRate)
			);
			const _referralLiquidity = BigInt(
				parseUnits(poolInfo.referralLiquidity, DEFAULT_QUOTE_PRECISION)
			);
			const _totalReferralLiquidity = BigInt(
				parseUnits(totalReferralLiquidity, DEFAULT_QUOTE_PRECISION)
			);

			const tokenRewardDelta = this._mulDivReward(
				reward,
				_referralLiquidity,
				_totalReferralLiquidity
			);

			const _totalLiquidity = BigInt(
				parseUnits(poolInfo.referralLiquidity, DEFAULT_QUOTE_PRECISION)
			);
			const tokenRewardGrowthX64 =
				this._calculatePerShareGrowthX64(tokenRewardDelta, _totalLiquidity) +
				BigInt(poolInfo.referralTokenRewardGrowthX64);

			const _totalPosition = BigInt(
				parseUnits(poolInfo.referralPosition, DEFAULT_QUOTE_PRECISION)
			);
			const tokenPositionRewardDelta = reward - tokenRewardDelta;

			const tokenPositionRewardGrowthX64 =
				this._calculatePerShareGrowthX64(
					tokenPositionRewardDelta,
					_totalPosition
				) + BigInt(poolInfo.referralTokenPositionRewardGrowthX64);

			const liquidityRewardDebtDelta = this._calculateRewardDebt(
				tokenRewardGrowthX64,
				poolReward.rewardGrowthX64,
				poolReward.liquidity
			);
			const positionRewardDebtDelta = this._calculateRewardDebt(
				tokenPositionRewardGrowthX64,
				poolReward.positionRewardGrowthX64,
				poolReward.position
			);

			const poolTotalRewardsEQU = this._calculateReferralTotalRewards(
				BigInt(parseUnits(poolReward.liquidityUnclaimed, DEFAULT_PRECISION)),
				BigInt(parseUnits(poolReward.positionUnclaimed, DEFAULT_PRECISION)),
				liquidityRewardDebtDelta,
				positionRewardDebtDelta
			);
			const EQUReward = {
				id: poolReward.id,
				poolId: poolInfo.id,
				rewardsEQU: poolTotalRewardsEQU
			};
			return EQUReward;
		}
		return defaultData;
	}

	public static calculateConnectorsReward(
		poolInfo: any,
		config: any,
		blockTimestamp: string | number
	) {
		const poolReward = poolInfo.pool;
		const defaultData = {
			id: poolInfo.referralToken.id,
			poolId: poolReward.id,
			rewardsEQU: poolInfo.liquidityUnclaimed
		};
		const _blockTimestamp = BigInt(blockTimestamp);
		const _lastMintTime = BigInt(poolReward.lastMintTime);
		const _mintedReward = BigInt(
			parseUnits(poolReward.mintedReward, DEFAULT_PRECISION)
		);
		const _rewardPerSecond = BigInt(poolReward.rewardPerSecond);
		const totalReward = this._calculateReward(
			_blockTimestamp,
			_lastMintTime,
			_mintedReward,
			_rewardPerSecond
		);
		const totalReferralLiquidity = plus(
			poolReward.referralLiquidity,
			poolReward.referralPosition
		);

		const _referralPosition = BigInt(
			parseUnits(poolReward.referralPosition, DEFAULT_QUOTE_PRECISION)
		);
		const _referralLiquidity = BigInt(
			parseUnits(poolReward.referralLiquidity, DEFAULT_QUOTE_PRECISION)
		);
		const _totalReferralLiquidity = BigInt(
			parseUnits(totalReferralLiquidity, DEFAULT_QUOTE_PRECISION)
		);
		if (
			totalReferralLiquidity !== '0' &&
			isPositive(config.referralParentTokenRate)
		) {
			const rewardUsed = this._splitReward(
				totalReward,
				BigInt(config.referralParentTokenRate)
			);
			const liquidityRewardUsed = this._mulDivReward(
				rewardUsed,
				_referralLiquidity,
				_totalReferralLiquidity
			);
			let growthDeltaX64 = this._calculatePerShareGrowthX64(
				liquidityRewardUsed,
				_referralLiquidity
			);
			const referralParentTokenRewardGrowthX64 =
				BigInt(poolReward.referralParentTokenRewardGrowthX64) +
				BigInt(growthDeltaX64);

			const positionRewardUsed = rewardUsed - liquidityRewardUsed;
			growthDeltaX64 = this._calculatePerShareGrowthX64(
				positionRewardUsed,
				_referralPosition
			);
			const referralParentTokenPositionRewardGrowthX64 =
				BigInt(poolReward.referralParentTokenPositionRewardGrowthX64) +
				growthDeltaX64;

			const liquidityRewardDebtDelta = this._calculateRewardDebt(
				referralParentTokenRewardGrowthX64,
				poolInfo.rewardGrowthX64,
				poolInfo.liquidity
			);

			const positionRewardDebtDelta = this._calculateRewardDebt(
				referralParentTokenPositionRewardGrowthX64,
				poolInfo.positionRewardGrowthX64,
				poolInfo.position
			);
			const poolTotalRewardsEQU = this._calculateReferralTotalRewards(
				BigInt(parseUnits(poolInfo.liquidityUnclaimed, DEFAULT_PRECISION)),
				BigInt(parseUnits(poolInfo.positionUnclaimed, DEFAULT_PRECISION)),
				liquidityRewardDebtDelta,
				positionRewardDebtDelta
			);

			const EQUReward = {
				id: poolInfo.referralToken.id,
				poolId: poolReward.id,
				rewardsEQU: poolTotalRewardsEQU
			};
			return EQUReward;
		} else {
			return defaultData;
		}
	}

	public static calculateLiquidityReward(
		rewardInfo: any,
		config: any,
		alreadyBoundReferralToken: boolean,
		blockTimestamp: string | number
	) {
		const {
			id,
			rewardPerSecond,
			token,
			lastMintTime,
			mintedReward,
			liquidity,
			referralLiquidity,
			liquidityRewardGrowthX64
		} = rewardInfo.pool;
		const defaultData = {
			id: token.id,
			poolId: id,
			rewardsEQU: rewardInfo.unclaimed
		};
		if (rewardPerSecond === '0') return defaultData;

		const _blockTimestamp = BigInt(blockTimestamp);
		const _mintedReward = BigInt(parseUnits(mintedReward, DEFAULT_PRECISION));
		const totalReward = this._calculateReward(
			_blockTimestamp,
			BigInt(lastMintTime),
			_mintedReward,
			BigInt(rewardPerSecond)
		);
		if (liquidity !== '0' || referralLiquidity !== '0') {
			const liquidityRewardUsed = this._splitReward(
				totalReward,
				BigInt(config.liquidityRate)
			);
			const _referralLiquidity = BigInt(
				parseUnits(referralLiquidity, DEFAULT_QUOTE_PRECISION)
			);
			const _liquidity = BigInt(parseUnits(liquidity, DEFAULT_QUOTE_PRECISION));
			const referralLiquidityWithMultiplier = this._mulDivUpReward(
				_referralLiquidity,
				REFERRAL_MULTIPLIER_BIGINT,
				BASIS_POINTS_DIVISOR_BIGINT
			);
			const poolLiquidityRewardGrowthX64 =
				this._calculatePerShareGrowthX64(
					liquidityRewardUsed,
					_liquidity + referralLiquidityWithMultiplier
				) + BigInt(liquidityRewardGrowthX64);

			const rewardDebt = this._updateLiquidityRewardDebt(
				// poolReward.address,
				// account,
				rewardInfo,
				alreadyBoundReferralToken,
				poolLiquidityRewardGrowthX64
			);
			const _liquidityUnclaimed = BigInt(
				parseUnits(rewardInfo.unclaimed, DEFAULT_PRECISION)
			);
			const poolTotalRewardsEQU = this._calculateTotalRewards(
				_liquidityUnclaimed,
				rewardDebt
			);

			const EQUReward = {
				...defaultData,
				rewardsEQU: poolTotalRewardsEQU
			};
			return EQUReward;
		}
		return defaultData;
	}

	public static calculateRBFMiningReward(
		rewardInfo: any,
		config: any,
		blockTimestamp: string | number
	) {
		const poolReward = rewardInfo.pool;
		const defaultData = {
			id: poolReward.token.id,
			poolId: poolReward.id,
			rewardsEQU: rewardInfo.unclaimed
		};

		const _blockTimestamp = BigInt(blockTimestamp);
		const _lastMintTime = BigInt(poolReward.lastMintTime);
		const _mintedReward = BigInt(
			parseUnits(poolReward.mintedReward, DEFAULT_PRECISION)
		);
		const _rewardPerSecond = BigInt(poolReward.rewardPerSecond);
		const totalReward = this._calculateReward(
			_blockTimestamp,
			_lastMintTime,
			_mintedReward,
			_rewardPerSecond
		);
		if (poolReward.riskBufferFundLiquidity != '0') {
			const rewardUsed = this._splitReward(
				totalReward,
				BigInt(config.riskBufferFundLiquidityRate)
			);
			const growthDeltaX64 = this._calculatePerShareGrowthX64(
				rewardUsed,
				BigInt(
					parseUnits(
						poolReward.riskBufferFundLiquidity,
						DEFAULT_QUOTE_PRECISION
					)
				)
			);
			const rewardGrowthAfterX64 =
				BigInt(poolReward.riskBufferFundRewardGrowthX64) +
				BigInt(growthDeltaX64);
			const rewardDebt = this._calculateRewardDebt(
				rewardGrowthAfterX64,
				rewardInfo.rewardGrowthX64,
				rewardInfo.liquidity
			);

			const _riskBufferFundRewardUnclaimed = BigInt(
				parseUnits(rewardInfo.unclaimed, DEFAULT_PRECISION)
			);
			const poolTotalRewardsEQU = this._calculateTotalRewards(
				_riskBufferFundRewardUnclaimed,
				rewardDebt
			);

			const EQUReward = {
				...defaultData,
				rewardsEQU: poolTotalRewardsEQU
			};
			return EQUReward;
		}
		return defaultData;
	}

	private static _updateLiquidityRewardDebt(
		// _pool: Address,
		// _account: Address,
		_reward: any,
		_alreadyBoundReferralToken: boolean,
		_poolRewardGrowthX64: bigint
	) {
		const _liquidity = BigInt(
			parseUnits(_reward.liquidity, DEFAULT_QUOTE_PRECISION)
		);

		const liquidity = _alreadyBoundReferralToken
			? this._mulDivReward(
					_liquidity,
					REFERRAL_MULTIPLIER_BIGINT,
					BASIS_POINTS_DIVISOR_BIGINT
			  )
			: _liquidity;
		const formatLiquidity = formatUnits(
			liquidity.toString(),
			DEFAULT_QUOTE_PRECISION
		);
		const rewardDebtDelta = this._calculateRewardDebt(
			_poolRewardGrowthX64,
			_reward.rewardGrowthX64,
			formatLiquidity
		);
		return rewardDebtDelta;
	}

	private static _calculateReward(
		_blockTimestamp: bigint,
		_lastMintTime: bigint,
		_mintedReward: bigint,
		_rewardPerSecond: bigint
	) {
		if (_blockTimestamp > _lastMintTime && REWARD_CAP > _mintedReward) {
			const amount = (_blockTimestamp - _lastMintTime) * _rewardPerSecond;
			return amount < REWARD_CAP - _mintedReward
				? amount
				: REWARD_CAP - _mintedReward;
		}
		return 0n;
	}

	private static _splitReward(_reward: bigint, _rate: bigint): bigint {
		if (!_reward || !_rate) {
			return 0n;
		}
		return bigIntMulDiv(_reward, _rate, BASIS_POINTS_DIVISOR_BIGINT);
	}

	private static _mulDivReward(
		_reward: bigint,
		_referralLiquidity: bigint,
		_totalReferralLiquidity: bigint
	): bigint {
		if (!_reward) {
			return 0n;
		}
		return bigIntMulDiv(_reward, _referralLiquidity, _totalReferralLiquidity);
	}

	private static _mulDivUpReward(
		_reward: bigint,
		_referralLiquidity: bigint,
		_totalReferralLiquidity: bigint
	): bigint {
		if (!_reward) {
			return 0n;
		}
		return bigIntMulDiv(
			_reward,
			_referralLiquidity,
			_totalReferralLiquidity,
			true
		);
	}

	private static _calculatePerShareGrowthX64(
		_amount: bigint,
		_totalLiquidity: bigint
	): bigint {
		if (_totalLiquidity != 0n) {
			return bigIntMulDiv(_amount, Q64_BIGINT, _totalLiquidity);
		}
		return 0n;
	}

	private static _calculateRewardDebt(
		_globalRewardGrowthX64: bigint,
		rewardGrowthX64: string | number,
		liquidity: string | number
	) {
		const _rewardGrowthX64 = BigInt(rewardGrowthX64);
		const _liquidity = BigInt(parseUnits(liquidity, DEFAULT_QUOTE_PRECISION));
		return bigIntMulDiv(
			_globalRewardGrowthX64 - _rewardGrowthX64,
			_liquidity,
			Q64_BIGINT
		);
	}

	private static _calculateReferralTotalRewards(
		_liquidityUnclaimed: bigint,
		_positionUnclaimed: bigint,
		_liquidityRewardDebtDelta: bigint,
		_positionRewardDebtDelta: bigint
	): string {
		return formatUnits(
			(
				_liquidityUnclaimed +
				_positionUnclaimed +
				_liquidityRewardDebtDelta +
				_positionRewardDebtDelta
			).toString(),
			DEFAULT_PRECISION
		);
	}

	private static _calculateTotalRewards(
		_unclaimed: bigint,
		_rewardDebtDelta: bigint
	): string {
		return formatUnits(
			(_unclaimed + _rewardDebtDelta).toString(),
			DEFAULT_PRECISION
		);
	}

	public static calculateLiquidityRewardV1(
		rewardInfo: any,
		alreadyBoundReferralToken: boolean
	) {
		const {
			id,
			token,
			liquidity,
			referralLiquidity,
			liquidityRewardGrowthX64
		} = rewardInfo.pool;
		const defaultData = {
			id: token.id,
			poolId: id,
			rewardsEQU: rewardInfo.unclaimed
		};

		if (liquidity !== '0' || referralLiquidity !== '0') {
			const poolLiquidityRewardGrowthX64 = BigInt(liquidityRewardGrowthX64);

			const rewardDebt = this._updateLiquidityRewardDebt(
				// poolReward.address,
				// account,
				rewardInfo,
				alreadyBoundReferralToken,
				poolLiquidityRewardGrowthX64
			);
			const _liquidityUnclaimed = BigInt(
				parseUnits(rewardInfo.unclaimed, DEFAULT_PRECISION)
			);
			const poolTotalRewardsEQU = this._calculateTotalRewards(
				_liquidityUnclaimed,
				rewardDebt
			);

			const EQUReward = {
				...defaultData,
				rewardsEQU: poolTotalRewardsEQU
			};
			return EQUReward;
		}
		return defaultData;
	}
	public static calculateRBFMiningRewardV1(rewardInfo: any) {
		const poolReward = rewardInfo.pool;
		const defaultData = {
			id: poolReward.token.id,
			poolId: poolReward.id,
			rewardsEQU: rewardInfo.unclaimed
		};
		if (poolReward.riskBufferFundLiquidity != '0') {
			const rewardGrowthAfterX64 = BigInt(
				poolReward.riskBufferFundRewardGrowthX64
			);
			const rewardDebt = this._calculateRewardDebt(
				rewardGrowthAfterX64,
				rewardInfo.rewardGrowthX64,
				rewardInfo.liquidity
			);

			const _riskBufferFundRewardUnclaimed = BigInt(
				parseUnits(rewardInfo.unclaimed, DEFAULT_PRECISION)
			);
			const poolTotalRewardsEQU = this._calculateTotalRewards(
				_riskBufferFundRewardUnclaimed,
				rewardDebt
			);

			const EQUReward = {
				...defaultData,
				rewardsEQU: poolTotalRewardsEQU
			};
			return EQUReward;
		}
		return defaultData;
	}

	public static calculateMembersRewardV1(poolReward: any) {
		const poolInfo = poolReward.pool;
		const defaultData = {
			id: poolReward.id,
			poolId: poolInfo.id,
			rewardsEQU: poolReward.liquidityUnclaimed
		};

		const totalReferralLiquidity = plus(
			poolInfo.referralLiquidity,
			poolInfo.referralPosition
		);

		if (totalReferralLiquidity != '0') {
			const tokenRewardGrowthX64 = BigInt(
				poolInfo.referralTokenRewardGrowthX64
			);

			const tokenPositionRewardGrowthX64 = BigInt(
				poolInfo.referralTokenPositionRewardGrowthX64
			);

			const liquidityRewardDebtDelta = this._calculateRewardDebt(
				tokenRewardGrowthX64,
				poolReward.rewardGrowthX64,
				poolReward.liquidity
			);
			const positionRewardDebtDelta = this._calculateRewardDebt(
				tokenPositionRewardGrowthX64,
				poolReward.positionRewardGrowthX64,
				poolReward.position
			);

			const poolTotalRewardsEQU = this._calculateReferralTotalRewards(
				BigInt(parseUnits(poolReward.liquidityUnclaimed, DEFAULT_PRECISION)),
				BigInt(parseUnits(poolReward.positionUnclaimed, DEFAULT_PRECISION)),
				liquidityRewardDebtDelta,
				positionRewardDebtDelta
			);
			const EQUReward = {
				id: poolReward.id,
				poolId: poolInfo.id,
				rewardsEQU: poolTotalRewardsEQU
			};
			return EQUReward;
		}
		return defaultData;
	}

	public static calculateConnectorsRewardV1(poolInfo: any) {
		const poolReward = poolInfo.pool;
		const defaultData = {
			id: poolInfo.referralToken.id,
			poolId: poolReward.id,
			rewardsEQU: poolInfo.liquidityUnclaimed
		};

		const totalReferralLiquidity = plus(
			poolReward.referralLiquidity,
			poolReward.referralPosition
		);

		if (totalReferralLiquidity !== '0') {
			const referralParentTokenRewardGrowthX64 = BigInt(
				poolReward.referralParentTokenRewardGrowthX64
			);

			const referralParentTokenPositionRewardGrowthX64 = BigInt(
				poolReward.referralParentTokenPositionRewardGrowthX64
			);

			const liquidityRewardDebtDelta = this._calculateRewardDebt(
				referralParentTokenRewardGrowthX64,
				poolInfo.rewardGrowthX64,
				poolInfo.liquidity
			);

			const positionRewardDebtDelta = this._calculateRewardDebt(
				referralParentTokenPositionRewardGrowthX64,
				poolInfo.positionRewardGrowthX64,
				poolInfo.position
			);
			const poolTotalRewardsEQU = this._calculateReferralTotalRewards(
				BigInt(parseUnits(poolInfo.liquidityUnclaimed, DEFAULT_PRECISION)),
				BigInt(parseUnits(poolInfo.positionUnclaimed, DEFAULT_PRECISION)),
				liquidityRewardDebtDelta,
				positionRewardDebtDelta
			);

			const EQUReward = {
				id: poolInfo.referralToken.id,
				poolId: poolReward.id,
				rewardsEQU: poolTotalRewardsEQU
			};
			return EQUReward;
		} else {
			return defaultData;
		}
	}
}
