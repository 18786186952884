import { useEffect, useMemo } from 'react';

import { Trans, t } from '@lingui/macro';
import { Typography, useTheme } from '@mui/material';
import { QUOTE_USD, Transaction_Type } from 'config/constants';

import { useCurrentChain } from 'hooks/useCurrentChain';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';

import Dialog from 'components/Common/Dialog';
import {
	CommonStyledAlertWarning,
	CommonStyledDivider
} from 'components/Common/Styled';
import TextWarning from 'components/TextWarning';

import Cell from '../../components/Common/Cell';
import ExecutionFee from '../../components/ExecutionFee';
import useSubmitRemoveLiquidity from '../../hooks/useSubmitRemoveLiquidity';
import {
	formatNumber,
	isEqualTo,
	isGreaterThan,
	isLessThan,
	isPositive,
	minus,
	shortenSymbol,
	shortenSymbolNative,
	toQuoteAmount
} from '../../utils';

const RemoveLiquidity = ({
	currentPosition,
	onClose
}: {
	currentPosition: any;
	onClose: () => void;
}) => {
	const { signingMap, quoteToken } = useAppSelector(txBaseState);

	const { currentChainId } = useCurrentChain();

	const { onConfirm, setTxSuccessCallback, isConfirmed, isConfirming } =
		useSubmitRemoveLiquidity(
			currentPosition.address,
			currentPosition.id,
			currentPosition.marginNet,
			currentPosition.liquidity,
			currentPosition.baseToken
		);

	const settlement = minus(
		currentPosition.marginNet,
		currentPosition.unrealizedLoss
	);

	const theme = useTheme();

	const disabled = useMemo(() => {
		if (signingMap.get(Transaction_Type.RemoveLiquidity)) {
			return true;
		}
		if (isConfirming) {
			return true;
		}
		if (isLessThan(settlement, 0)) {
			return true;
		}
	}, [signingMap, isConfirming]);

	const submitText = useMemo(() => {
		if (signingMap.get(Transaction_Type.RemoveLiquidity) || isConfirming) {
			return <Trans>Loading...</Trans>;
		}
		if (isLessThan(settlement, 0)) {
			return <Trans>Can not remove liquidity</Trans>;
		}
		if (isGreaterThan(currentPosition.unrealizedLoss, 0)) {
			return <Trans>Remove Anyway</Trans>;
		}
		return <Trans>Remove</Trans>;
	}, [signingMap, isConfirming, currentPosition.unrealizedLoss]);

	useEffect(() => {
		setTxSuccessCallback(() => onClose);
	}, [isConfirmed]);

	return (
		<Dialog
			onConfirm={onConfirm}
			open
			onClose={onClose}
			title={t`Remove Liquidity`}
			disabled={disabled}
			confirmLabel={submitText}
		>
			<Typography>
				{`${shortenSymbolNative(
					currentPosition.baseToken,
					currentChainId
				)}/${QUOTE_USD}`}
			</Typography>
			<div className='mt-3 space-y-1'>
				<Cell
					label={
						<Typography variant='body2'>
							<Trans>Liquidity</Trans>
						</Typography>
					}
					value={toQuoteAmount(currentPosition.liquidity)}
				/>

				<Cell
					label={
						<Typography variant='body2'>
							<Trans>Initial Margin</Trans>
						</Typography>
					}
					value={toQuoteAmount(currentPosition.initialMargin)}
				/>

				<Cell
					label={
						<Typography variant='body2'>
							<Trans>Realized Profit</Trans>
						</Typography>
					}
					value={
						<Typography
							variant='body2'
							color={
								isPositive(currentPosition.totalRealizedProfit)
									? theme.palette.success.main
									: theme.palette.text.primary
							}
						>
							{toQuoteAmount(currentPosition.totalRealizedProfit)}
						</Typography>
					}
				/>
				<Cell
					label={
						<Typography variant='body2'>
							<Trans>Temporary Loss</Trans>
						</Typography>
					}
					value={
						<Typography
							variant='body2'
							color={
								isEqualTo(currentPosition.unrealizedLoss, 0)
									? theme.palette.text.primary
									: theme.palette.error.main
							}
						>
							{toQuoteAmount(-currentPosition.unrealizedLoss)}
						</Typography>
					}
				/>

				{isGreaterThan(currentPosition.unrealizedLoss, 0) && (
					<CommonStyledAlertWarning>
						<TextWarning>
							<Trans>
								You are bearing temporary loss. If you remove liquidity now,
								this temporary loss will become a realized loss. Are you sure
								you want to proceed?
							</Trans>
						</TextWarning>
					</CommonStyledAlertWarning>
				)}

				<ExecutionFee />
			</div>
			<CommonStyledDivider />
			<div>
				<Cell
					label={
						<Typography variant='body2'>
							<Trans>Receive</Trans>
						</Typography>
					}
					value={`${formatNumber(settlement)} ${shortenSymbol(quoteToken)}`}
				/>
			</div>
		</Dialog>
	);
};

export default RemoveLiquidity;
