msgid ""
msgstr ""
"Project-Id-Version: \n"
"Report-Msgid-Bugs-To: \n"
"POT-Creation-Date: \n"
"PO-Revision-Date: \n"
"Last-Translator: \n"
"Language: \n"
"Language-Team: \n"
"Content-Type: \n"
"Content-Transfer-Encoding: \n"
"Plural-Forms: \n"

#: src/layouts/Header/Account.tsx
msgid "Disconnect"
msgstr "Disconnect"

#: src/pages/Leaderboard/Trade.tsx
msgid "It is the sum of realized PnL after closing the position."
msgstr "It is the sum of realized PnL after closing the position."

#: src/pages/components/Operations/PositionsList.tsx
#: src/pages/components/Operations/PositionsList.tsx
msgid "If <0>Index Price</0> reaches Liq. Price, the position will be liquidated, and all margins will be lost."
msgstr "If <0>Index Price</0> reaches Liq. Price, the position will be liquidated, and all margins will be lost."

#: src/pages/components/Operations/PositionHistoryList.tsx
msgid "The remaining margin of this position is insufficient to cover the funding fee. The position has been liquidated."
msgstr "The remaining margin of this position is insufficient to cover the funding fee. The position has been liquidated."

#: src/pages/Earn/Stake/StakeLpDialog.tsx
msgid "Approve NFT"
msgstr "Approve NFT"

#: src/entities/TransactionDescription.ts
msgid "Limit Long: Open {poolSymbol} Long, Size +{sizeDelta} {baseSymbol}, trigger price: {triggerPriceAbove}"
msgstr "Limit Long: Open {poolSymbol} Long, Size +{sizeDelta} {baseSymbol}, trigger price: {triggerPriceAbove}"

#: src/pages/Liquidity/Detail.tsx
msgid "LPs' fee income in the past 24 hours"
msgstr "LPs' fee income in the past 24 hours"

#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
msgid "Passive Position Changes (Live)"
msgstr "Passive Position Changes (Live)"

#: src/pages/components/ClaimHistoryDialog.tsx
#: src/pages/components/ClaimHistoryDialog.tsx
#: src/pages/components/Operations/OpenOrdersList.tsx
#: src/pages/components/Operations/OrderHistoryList.tsx
#: src/pages/components/Operations/V2/OpenOrdersListV2.tsx
msgid "Type"
msgstr "Type"

#: src/pages/Leaderboard/Mining.tsx
#: src/pages/Leaderboard/Mining.tsx
#: src/pages/Leaderboard/Mining.tsx
msgid "Mining Rewards"
msgstr "Mining Rewards"

#: src/pages/Referrals/Leaderboard/MemberInvationDialog.tsx
#: src/pages/Referrals/Members/InvationDialog.tsx
#: src/pages/Referrals/Members/PositionValueDialog.tsx
msgid "Referral Details"
msgstr "Referral Details"

#: src/pages/Earn/SummaryEQU.tsx
msgid "day"
msgstr "day"

#: src/components/TransactionSnackbar/index.tsx
msgid "Show error"
msgstr "Show error"

#: src/pages/components/Operations/PositionHistoryList.tsx
#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
msgid "No position history"
msgstr "No position history"

#: src/pages/Earn/Liquidity/index.tsx
#: src/pages/Liquidity/Detail.tsx
#: src/pages/Liquidity/LiquidityTable.tsx
msgid "Trading Fees Max APR"
msgstr "Trading Fees Max APR"

#: src/pages/Earn/Content.tsx
#: src/utils/formatEarnType.ts
msgid "Position Mining"
msgstr "Position Mining"

#: src/pages/Referrals/Leaderboard/index.tsx
msgid "Volume"
msgstr "Volume"

#: src/pages/components/Computer/index.tsx
#: src/pages/Liquidity/LiquidityComputerDialog.tsx
msgid "*The calculation is for reference only and does not include trading fee, execution fee and other actual costs."
msgstr "*The calculation is for reference only and does not include trading fee, execution fee and other actual costs."

#: src/pages/components/TradeForm/TradeFees.tsx
msgid "Fees"
msgstr "Fees"

#: src/pages/components/TradeForm/LimitForm.tsx
#: src/pages/components/TradeForm/MarketForm.tsx
msgid "Est. Margin"
msgstr "Est. Margin"

#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
msgid "Removed Liquidity"
msgstr "Removed Liquidity"

#: src/pages/Referrals/Connectors/MintDialog.tsx
msgid "You can still use Connector #{0} to mint {canCreate} Member NFTs."
msgstr "You can still use Connector #{0} to mint {canCreate} Member NFTs."

#: src/pages/components/PoolInfo.tsx
#: src/pages/Liquidity/Detail.tsx
#: src/pages/Liquidity/LiquidityInfo.tsx
#: src/pages/Liquidity/LiquidityInfo.tsx
#: src/pages/Liquidity/LiquidityTable.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
msgid "Balance Rate"
msgstr "Balance Rate"

#: src/entities/TransactionDescription.ts
msgid "Contribute to Risk Buffer Fund: Request to contribute to {poolSymbol} risk buffer fund, Amount +{marginDelta} {QUOTE_USD}"
msgstr "Contribute to Risk Buffer Fund: Request to contribute to {poolSymbol} risk buffer fund, Amount +{marginDelta} {QUOTE_USD}"

#: src/pages/components/ShareDialog.tsx
#: src/pages/Leaderboard/ShareDialog.tsx
#: src/pages/Referrals/Leaderboard/MemberShareDialog.tsx
msgid "Twitter"
msgstr "Twitter"

#: src/pages/Earn/Stake/StakeLpDialog.tsx
msgid "Select NFT"
msgstr "Select NFT"

#: src/pages/Liquidity/V2/LiquidityFormTips.tsx
msgid "Only professional market makers are advised to participate. Due to frequent severe fluctuations in price and liquidity in the cryptocurrency spot market, along with occasional price manipulation incidents, you may face the risk of losing all your margin if you lack professional risk hedging capabilities."
msgstr "Only professional market makers are advised to participate. Due to frequent severe fluctuations in price and liquidity in the cryptocurrency spot market, along with occasional price manipulation incidents, you may face the risk of losing all your margin if you lack professional risk hedging capabilities."

#: src/pages/Liquidity/Detail.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
msgid "LP's trading fee and funding fee income in the last 7 days."
msgstr "LP's trading fee and funding fee income in the last 7 days."

#: src/pages/components/TradeForm/LimitForm.tsx
#: src/pages/components/TradeForm/MarketForm.tsx
#: src/pages/Earn/Stake/StakeDialog.tsx
#: src/pages/Liquidity/LiquidityForm.tsx
#: src/pages/Liquidity/RiskBufferFundDialog.tsx
#: src/pages/Liquidity/V2/LiquidityFormV2.tsx
msgid "Balance:"
msgstr "Balance:"

#: src/pages/Liquidity/LiquidityTable.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Market/TradeTableV1.tsx
#: src/pages/Market/TradeTableV2.tsx
#: src/pages/Trade/OperationV1.tsx
#: src/pages/Trade/OperationV2.tsx
msgid "History"
msgstr "History"

#: src/pages/components/ChartsPool.tsx
msgid "Funding"
msgstr "Funding"

#: src/pages/components/TradeForm/ConfirmDialog.tsx
msgid "{0} <0>{sideLabel}</0>, Trigger Price {prefix}{price}"
msgstr "{0} <0>{sideLabel}</0>, Trigger Price {prefix}{price}"

#: src/pages/components/Operations/PositionHistoryList.tsx
#: src/pages/components/Operations/PositionHistoryList.tsx
msgid "Closing PnL"
msgstr "Closing PnL"

#: src/pages/Market/TradeTableV1.tsx
#: src/pages/Market/TradeTableV2.tsx
#: src/pages/Trade/OperationV1.tsx
#: src/pages/Trade/OperationV2.tsx
msgid "Positions"
msgstr "Positions"

#: src/pages/Liquidity/V2/RemoveLiquidityV2.tsx
msgid "Exceeds current liquidity"
msgstr "Exceeds current liquidity"

#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
msgid "Total Reduced Margin"
msgstr "Total Reduced Margin"

#: src/pages/components/Operations/PositionCells.tsx
msgid "Reduced & Settled"
msgstr "Reduced & Settled"

#: src/pages/components/PoolInfo.tsx
#: src/pages/Liquidity/LiquidityInfo.tsx
#: src/pages/Liquidity/LiquidityInfo.tsx
msgid "Average Leverage"
msgstr "Average Leverage"

#: src/pages/Earn/Stake/StakeAppTokenList.tsx
#: src/pages/Earn/Stake/StakeLpTokenList.tsx
msgid "Start Time"
msgstr "Start Time"

#: src/pages/components/Computer/ComputerEntryPrice.tsx
#: src/pages/Liquidity/LiquidityHistory.tsx
#: src/pages/Liquidity/V2/PassivePositionHistories.tsx
#: src/utils/formatTrade.ts
#: src/utils/formatTrade.ts
#: src/utils/formatTrade.ts
#: src/utils/formatTrade.ts
msgid "Open"
msgstr "Open"

#: src/pages/components/Computer/ComputerPnL.tsx
msgid "TP PnL"
msgstr "TP PnL"

#: src/pages/Market/TradeTableV1.tsx
#: src/pages/Market/TradeTableV2.tsx
#: src/pages/Trade/OperationV1.tsx
#: src/pages/Trade/OperationV2.tsx
msgid "Orders"
msgstr "Orders"

#: src/pages/components/Operations/TradeHistoryList.tsx
#: src/pages/Liquidity/LiquidityHistory.tsx
#: src/pages/Liquidity/LiquidityInfo.tsx
#: src/pages/Liquidity/RiskBufferFundDialog.tsx
#: src/pages/Liquidity/V2/LiquidityPassivePositionV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Liquidity/V2/LiquidityTradeHistoriesV2.tsx
msgid "Realized PnL"
msgstr "Realized PnL"

#: src/pages/Earn/RBFMining/index.tsx
msgid "You haven't RBF Mining yet."
msgstr "You haven't RBF Mining yet."

#: src/pages/Earn/Stake/StakeAppTokenList.tsx
#: src/pages/Earn/Stake/StakeLpTokenList.tsx
msgid "Unlocking Period"
msgstr "Unlocking Period"

#: src/layouts/Header/index.tsx
#: src/pages/Market/Links.tsx
msgid "Stats"
msgstr "Stats"

#: src/pages/components/Operations/HistoryList.tsx
#: src/pages/Liquidity/V2/LiquidityHistoryV2.tsx
msgid "Trade History"
msgstr "Trade History"

#: src/layouts/Header/index.tsx
msgid "More"
msgstr "More"

#: src/pages/Liquidity/V2/LiquidityTradeHistoriesV2.tsx
msgid "Every time there is a change in the liquidity position (including adding/reducing liquidity or adjusting margin, etc.), the system will settle the PnL of the passive position. This data represents the specific PnL amount settled during each position change."
msgstr "Every time there is a change in the liquidity position (including adding/reducing liquidity or adjusting margin, etc.), the system will settle the PnL of the passive position. This data represents the specific PnL amount settled during each position change."

#: src/pages/Earn/Stake/StakeLpTokenList.tsx
msgid "Add now >>"
msgstr "Add now >>"

#: src/pages/components/Operations/PositionHistoryList.tsx
msgid "Closed Size"
msgstr "Closed Size"

#: src/pages/components/ClaimHistoryDialog.tsx
#: src/pages/components/ClaimHistoryDialog.tsx
msgid "The {APP_TOKEN_SYMBOL} amount after the burn mechanism took effect is the sum of the burn amount and the stake/claim amount."
msgstr "The EQU amount after the burn mechanism took effect is the sum of the burn amount and the stake/claim amount."

#: src/hooks/useApprovalPlugin.ts
#: src/hooks/V2/useApprovalPluginV2.ts
msgid "Revoking Operation"
msgstr "Revoking Operation"

#: src/pages/components/TradeForm/TradeButtonMarketV2.tsx
msgid "Max to open {0}"
msgstr "Max to open {0}"

#: src/pages/Liquidity/RemoveLiquidity.tsx
msgid "Remove Anyway"
msgstr "Remove Anyway"

#: src/pages/Liquidity/Detail.tsx
#: src/pages/Liquidity/LiquidityCard.tsx
#: src/pages/Liquidity/LiquidityTable.tsx
#: src/pages/Liquidity/V2/LiquidityCardV2.tsx
msgid "24h Fee"
msgstr "24h Fee"

#: src/pages/Earn/Pool2Mining/Summary.tsx
msgid "Mining APR ({maxMultiplier}x)"
msgstr "Mining APR ({maxMultiplier}x)"

#: src/pages/Liquidity/RemoveLiquidity.tsx
#: src/pages/Liquidity/V2/RemoveLiquidityV2.tsx
msgid "Remove Liquidity"
msgstr "Remove Liquidity"

#: src/pages/Leaderboard/Mining.tsx
#: src/pages/Leaderboard/Trade.tsx
#: src/pages/Referrals/Leaderboard/index.tsx
msgid "Rank"
msgstr "Rank"

#: src/pages/components/Operations/TradeHistoryList.tsx
#: src/pages/components/TradeForm/TradeFees.tsx
msgid "Trading Fee"
msgstr "Trading Fee"

#: src/entities/TransactionDescription.ts
msgid "Rewards settlement successful."
msgstr "Rewards settlement successful."

#: src/pages/Earn/Liquidity/index.tsx
msgid "Max APR = Trading Fee Max APR + EQU Max APR"
msgstr "Max APR = Trading Fee Max APR + EQU Max APR"

#: src/pages/Earn/Position/index.tsx
msgid "Average"
msgstr "Average"

#: src/pages/Referrals/Connectors/MyConnectorNFT.tsx
msgid "to grab one now."
msgstr "to grab one now."

#: src/pages/Earn/SummaryEQU.tsx
msgid "Total Burned"
msgstr "Total Burned"

#: src/pages/Referrals/Members/index.tsx
#: src/pages/Referrals/Members/index.tsx
msgid "You don't have a referral code yet."
msgstr "You don't have a referral code yet."

#: src/hooks/useApproval.ts
#: src/hooks/useApprovalERC721.ts
#: src/hooks/useApprovalPlugin.ts
#: src/hooks/V2/useApprovalPluginV2.ts
msgid "Approval Successful"
msgstr "Approval Successful"

#: src/pages/components/Computer/ComputerTargetPrice.tsx
#: src/pages/components/Computer/index.tsx
msgid "Target Price"
msgstr "Target Price"

#: src/pages/Liquidity/AdjustLiquidityMargin.tsx
#: src/pages/Liquidity/ConfirmIncreaseLiquidity.tsx
#: src/pages/Liquidity/V2/AdjustLiquidityMarginV2.tsx
msgid "Risk Rate"
msgstr "Risk Rate"

#: src/pages/components/AdjustMarginDialog.tsx
#: src/pages/components/V2/AdjustMarginDialogV2.tsx
#: src/pages/Liquidity/AdjustLiquidityMargin.tsx
#: src/pages/Liquidity/LiquidityHistory.tsx
#: src/pages/Liquidity/V2/AdjustLiquidityMarginV2.tsx
#: src/pages/Liquidity/V2/LiquidityTradeHistoriesV2.tsx
msgid "Add Margin"
msgstr "Add Margin"

#: src/pages/components/Operations/PositionCells.tsx
#: src/pages/Liquidity/LiquidityInfo.tsx
#: src/pages/Liquidity/RiskBufferFundDialog.tsx
#: src/pages/Liquidity/V2/LiquidityPassivePositionV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
msgid "Unrealized PnL"
msgstr "Unrealized PnL"

#: src/pages/Liquidity/V2/RemoveLiquidityV2.tsx
msgid "When reducing liquidity, you can choose to use the \"Reduce-Only\" mode. The Reduce-Only mode means only reducing liquidity while keeping the margin unchanged. The leverage of the liquidity position will change accordingly after the reduction."
msgstr "When reducing liquidity, you can choose to use the \"Reduce-Only\" mode. The Reduce-Only mode means only reducing liquidity while keeping the margin unchanged. The leverage of the liquidity position will change accordingly after the reduction."

#: src/pages/components/Operations/OrderHistoryList.tsx
msgid "There are two situations when an order is cancelled by the system:"
msgstr "There are two situations when an order is cancelled by the system:"

#: src/pages/Referrals/Leaderboard/index.tsx
#: src/pages/Referrals/Leaderboard/MemberShareDialog.tsx
msgid "Rewards"
msgstr "Rewards"

#: src/pages/components/TradeForm/LimitForm.tsx
#: src/pages/components/TradeForm/MarketForm.tsx
msgid "Pay"
msgstr "Pay"

#: src/pages/Referrals/Members/RegisterCodeDialog.tsx
msgid "Code already taken."
msgstr "Code already taken."

#: src/pages/Earn/Stake/StakeAppTokenList.tsx
#: src/pages/Earn/Stake/StakeLpTokenList.tsx
msgid "{_period} day"
msgstr "{_period} day"

#: src/pages/components/Computer/ComputerPnL.tsx
msgid "SL PnL"
msgstr "SL PnL"

#: src/pages/Earn/Pool2Mining/Summary.tsx
msgid "Total Value Locked (TVL)"
msgstr "Total Value Locked (TVL)"

#: src/pages/components/PoolInfo.tsx
#: src/pages/Liquidity/LiquidityInfo.tsx
msgid "Max Leverage"
msgstr "Max Leverage"

#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
msgid "Take profit {takeProfitSymbol}"
msgstr "Take profit {takeProfitSymbol}"

#: src/pages/components/Operations/OrderHistoryList.tsx
msgid "1. Trigger price verification failed. When the order was triggered, operations made by other users resulted in the market price not meeting the trigger price you set, resulting in order cancellation."
msgstr "1. Trigger price verification failed. When the order was triggered, operations made by other users resulted in the market price not meeting the trigger price you set, resulting in order cancellation."

#: src/pages/components/Operations/PositionHistoryList.tsx
#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
msgid "Time Closed"
msgstr "Time Closed"

#: src/pages/components/TradeForm/ToggleDirection.tsx
msgid "Short {0}"
msgstr "Short {0}"

#: src/pages/Earn/Pool2Mining/Summary.tsx
msgid "APR (Multiplier = {maxMultiplier}x) = {0}/{nativeSymbol} LP APR (Uniswap) + Staking APR ({maxMultiplier}x) + Mining APR ({maxMultiplier}x)"
msgstr "APR (Multiplier = {maxMultiplier}x) = {0}/{nativeSymbol} LP APR (Uniswap) + Staking APR ({maxMultiplier}x) + Mining APR ({maxMultiplier}x)"

#: src/pages/Referrals/Traders/index.tsx
msgid "Enjoy a<0>10%</0>discount on trading fee."
msgstr "Enjoy a<0>10%</0>discount on trading fee."

#: src/pages/Referrals/Connectors/MintDialog.tsx
#: src/pages/Referrals/Connectors/MyConnectorNFT.tsx
#: src/pages/Referrals/Connectors/MyConnectorNFT.tsx
msgid "Mint"
msgstr "Mint"

#: src/pages/components/ClosePositionDialog.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV1.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV1.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV2.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV2.tsx
#: src/pages/Liquidity/V2/RemoveLiquidityV2.tsx
msgid "Insufficient Liquidity"
msgstr "Insufficient Liquidity"

#: src/pages/components/TradeForm/TradeButtonMarketV2.tsx
msgid "Exceeded the position size limit for a single user."
msgstr "Exceeded the position size limit for a single user."

#: src/pages/Liquidity/LiquidityInfo.tsx
msgid "Max Effective Liquidity"
msgstr "Max Effective Liquidity"

#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
msgid "The cumulative realized PnL of the passive positions under this liquidity position. The passive positions are automatically adjusted by the system based on market conditions. Positions may be opened or closed frequently, and the Realized PnL will also change accordingly."
msgstr "The cumulative realized PnL of the passive positions under this liquidity position. The passive positions are automatically adjusted by the system based on market conditions. Positions may be opened or closed frequently, and the Realized PnL will also change accordingly."

#: src/pages/components/ProfitLoss/ProfitLossCells.tsx
msgid "When the market price reaches the trigger price, the system will close the position at the <0>market price</0>."
msgstr "When the market price reaches the trigger price, the system will close the position at the <0>market price</0>."

#: src/pages/Earn/Stake/Summary.tsx
msgid "Total Staking Rewards"
msgstr "Total Staking Rewards"

#: src/pages/components/ShareDialog.tsx
#: src/pages/Leaderboard/ShareDialog.tsx
#: src/pages/Referrals/Leaderboard/MemberShareDialog.tsx
#: src/pages/Referrals/Members/index.tsx
#: src/pages/Referrals/Members/index.tsx
msgid "Referral Code"
msgstr "Referral Code"

#: src/pages/components/Computer/ComputerPnL.tsx
#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
msgid "TP trigger price"
msgstr "TP trigger price"

#: src/pages/Earn/Pool2Mining/Summary.tsx
#: src/pages/Earn/Stake/Summary.tsx
msgid "Overview"
msgstr "Overview"

#: src/entities/TransactionDescription.ts
msgid "Modify Partial SL: {poolSymbol}·{0}, modified trigger price {prefix}{price}"
msgstr "Modify Partial SL: {poolSymbol}·{0}, modified trigger price {prefix}{price}"

#: src/pages/components/ShareDialog.tsx
#: src/pages/Leaderboard/ShareDialog.tsx
#: src/pages/Leaderboard/ShareDialog.tsx
#: src/pages/Referrals/Leaderboard/MemberShareDialog.tsx
msgid "Scan to Explore"
msgstr "Scan to Explore"

#: src/pages/Earn/Stake/StakeLpTokenList.tsx
msgid "Add liquidity to Uniswap EQU/ETH pool (<0>full range</0>) to receive your LP NFT."
msgstr "Add liquidity to Uniswap EQU/ETH pool (<0>full range</0>) to receive your LP NFT."

#: src/entities/TransactionDescription.ts
msgid "Adjust Margin: Request to add {poolSymbol} Long position margin, Margin +{marginDelta} {QUOTE_USD}"
msgstr "Adjust Margin: Request to add {poolSymbol} Long position margin, Margin +{marginDelta} {QUOTE_USD}"

#: src/entities/TransactionDescription.ts
msgid "Bind successfully."
msgstr "Bind successfully."

#: src/pages/Earn/Stake/Summary.tsx
msgid "Staked {0} in LP NFT"
msgstr "Staked {0} in LP NFT"

#: src/components/Copy/index.tsx
#: src/pages/components/ShareDialog.tsx
#: src/pages/Leaderboard/ShareDialog.tsx
#: src/pages/Referrals/Leaderboard/MemberShareDialog.tsx
msgid "Copied"
msgstr "Copied"

#: src/components/SelectPools/index.tsx
#: src/layouts/Header/index.tsx
#: src/pages/Market/index.tsx
msgid "Markets"
msgstr "Markets"

#: src/pages/Earn/Liquidity/index.tsx
#: src/pages/Earn/Pool2Mining/Summary.tsx
#: src/pages/Earn/Position/index.tsx
#: src/pages/Earn/RBFMining/index.tsx
msgid "Daily Emission"
msgstr "Daily Emission"

#: src/pages/Earn/Stake/UnStakeLpDialog.tsx
#: src/pages/Earn/Stake/UnStakeModal.tsx
msgid "You will receive:"
msgstr "You will receive:"

#: src/components/Wallet/ConnectWalletButton.tsx
msgid "Switch Network"
msgstr "Switch Network"

#: src/pages/components/PoolInfo.tsx
#: src/pages/Earn/Liquidity/ClaimLiquidityDialog.tsx
#: src/pages/Liquidity/AdjustLiquidityMargin.tsx
#: src/pages/Liquidity/ConfirmIncreaseLiquidity.tsx
#: src/pages/Liquidity/Detail.tsx
#: src/pages/Liquidity/LiquidityCard.tsx
#: src/pages/Liquidity/LiquidityComputerDialog.tsx
#: src/pages/Liquidity/LiquidityForm.tsx
#: src/pages/Liquidity/LiquidityForm.tsx
#: src/pages/Liquidity/LiquidityHistory.tsx
#: src/pages/Liquidity/LiquidityTable.tsx
#: src/pages/Liquidity/LiquidityTable.tsx
#: src/pages/Liquidity/RemoveLiquidity.tsx
#: src/pages/Liquidity/V2/AdjustLiquidityMarginV2.tsx
#: src/pages/Liquidity/V2/LiquidityCardV2.tsx
#: src/pages/Liquidity/V2/LiquidityFormV2.tsx
#: src/pages/Liquidity/V2/LiquidityFormV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Liquidity/V2/LiquidityTradeHistoriesV2.tsx
#: src/pages/Liquidity/V2/RemoveLiquidityV2.tsx
#: src/pages/Liquidity/V2/RemoveLiquidityV2.tsx
#: src/pages/Referrals/Members/LiquidityDialog.tsx
msgid "Liquidity"
msgstr "Liquidity"

#: src/pages/components/Operations/PositionHistoryList.tsx
msgid "Opened Size"
msgstr "Opened Size"

#: src/layouts/Header/Drawer.tsx
#: src/layouts/Header/Setting.tsx
#: src/pages/components/AcceptableEntryPriceSetting/AcceptableEntryPriceSettingDialog.tsx
msgid "Slippage Settings"
msgstr "Slippage Settings"

#: src/pages/components/FeeChart.tsx
#: src/pages/Earn/Position/index.tsx
#: src/pages/Earn/RBFMining/index.tsx
msgid "APR"
msgstr "APR"

#: src/pages/Earn/ClaimAllDialog.tsx
#: src/pages/Referrals/DirectClaimDialog.tsx
msgid "Total"
msgstr "Total"

#: src/utils/formatEarnType.ts
msgid "EFC Connectors V2"
msgstr "EFC Connectors V2"

#: src/pages/components/ProfitLoss/UpdateProfitLossDialog.tsx
#: src/pages/components/ProfitLoss/UpdateProfitLossDialog.tsx
#: src/pages/components/TradeOrderPriceDialog.tsx
#: src/pages/components/TradeOrderPriceDialog.tsx
msgid "Order has been executed"
msgstr "Order has been executed"

#: src/pages/components/ModifyTisDialog.tsx
#: src/pages/components/TipsDialog.tsx
msgid "Got it"
msgstr "Got it"

#: src/pages/Referrals/Members/index.tsx
msgid "The total value of holdings by all referees under a Member NFT. The higher the value of holdings, the greater the EQU rewards earned."
msgstr "The total value of holdings by all referees under a Member NFT. The higher the value of holdings, the greater the EQU rewards earned."

#: src/layouts/Header/index.tsx
msgid "Forum"
msgstr "Forum"

#: src/pages/components/AdjustMarginDialog.tsx
#: src/pages/components/DepthChart.tsx
#: src/pages/components/DepthChart.tsx
#: src/pages/components/V2/AdjustMarginDialogV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Trade/Overview.tsx
#: src/pages/Trade/Overview.tsx
msgid "Index Price"
msgstr "Index Price"

#: src/pages/components/ImpactFeeSetting/ImpactFeeSettingDialog.tsx
msgid "Balance Impact"
msgstr "Balance Impact"

#: src/pages/components/Operations/PositionHistoryList.tsx
#: src/pages/components/Operations/TradeHistoryList.tsx
#: src/pages/components/Operations/TradeHistoryList.tsx
msgid "Closed PnL = Closed Position PnL + Cumulative Funding Fees + Liquidation Fee"
msgstr "Closed PnL = Closed Position PnL + Cumulative Funding Fees + Liquidation Fee"

#: src/pages/Earn/Stake/Summary.tsx
msgid "Max APR ({maxMultiplier}x) = 24h Rewards Value / Σ(Staked EQU Value * Multiplier) * Max Multiplier * 365 * 100%"
msgstr "Max APR ({maxMultiplier}x) = 24h Rewards Value / Σ(Staked EQU Value * Multiplier) * Max Multiplier * 365 * 100%"

#: src/components/Common/Dialog.tsx
#: src/pages/components/AdjustMarginDialog.tsx
#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
#: src/pages/components/ProfitLoss/UpdateProfitLossDialog.tsx
#: src/pages/components/TradeForm/ConfirmDialog.tsx
#: src/pages/components/TradeOrderPriceDialog.tsx
#: src/pages/Earn/Stake/StakeDialog.tsx
#: src/pages/Earn/Stake/StakeDialog.tsx
#: src/pages/Earn/Stake/StakeLpDialog.tsx
#: src/pages/Earn/Stake/StakeLpDialog.tsx
#: src/pages/Earn/Stake/UnStakeLpDialog.tsx
#: src/pages/Earn/Stake/UnStakeModal.tsx
#: src/pages/Liquidity/AdjustLiquidityMargin.tsx
#: src/pages/Liquidity/RiskBufferFundDialog.tsx
#: src/pages/Liquidity/RiskBufferFundDialog.tsx
#: src/pages/Referrals/Members/RegisterCodeDialog.tsx
#: src/pages/Referrals/Members/RegisterCodeDialog.tsx
#: src/pages/Referrals/Traders/EditCodeDialog.tsx
#: src/pages/Referrals/Traders/EditCodeDialog.tsx
#: src/pages/Referrals/Traders/InputReferralCode.tsx
#: src/pages/Referrals/Traders/InputReferralCode.tsx
msgid "Confirm"
msgstr "Confirm"

#: src/pages/Earn/SummaryEQU.tsx
msgid "{0} Statistics"
msgstr "EQU Statistics"

#: src/components/TransactionSnackbar/index.tsx
msgid "Hide error"
msgstr "Hide error"

#: src/pages/components/Operations/PositionHistoryList.tsx
msgid "Close Price"
msgstr "Close Price"

#: src/pages/Earn/Content.tsx
#: src/utils/formatEarnType.ts
msgid "Pool2 Mining"
msgstr "Pool2 Mining"

#: src/pages/Earn/Stake/StakeLpDialog.tsx
msgid "Only <0>full range</0> {0}/{nativeSymbol} LP NFTs are acceptable."
msgstr "Only <0>full range</0> EQU/ETH LP NFTs are acceptable."

#: src/pages/components/AppTokenPrice.tsx
#: src/pages/Earn/SummaryEQU.tsx
msgid "Add to wallet"
msgstr "Add to wallet"

#: src/layouts/Header/Drawer.tsx
#: src/layouts/Header/Setting.tsx
#: src/pages/components/AcceptableEntryPriceSetting/AcceptableEntryPriceSettingDialog.tsx
#: src/pages/components/ImpactFeeSetting/ImpactFeeSettingDialog.tsx
msgid "Custom"
msgstr "Custom"

#: src/pages/components/Operations/OrderHistoryList.tsx
msgid "Entire"
msgstr "Entire"

#: src/entities/TransactionDescription.ts
msgid "Position SL: {poolSymbol}·{0}, size -{sizeDelta} {baseSymbol}, SL trigger price {prefix}{price}"
msgstr "Position SL: {poolSymbol}·{0}, size -{sizeDelta} {baseSymbol}, SL trigger price {prefix}{price}"

#: src/pages/Liquidity/LiquidityComputerDialog.tsx
msgid "Max Income"
msgstr "Max Income"

#: src/pages/Leaderboard/Trade.tsx
msgid "PnL% Rank"
msgstr "PnL% Rank"

#: src/pages/Earn/Architect/ClaimArchitectDialog.tsx
#: src/pages/Earn/Stake/ClaimStakeDialog.tsx
msgid "{selectedCount} selected, total"
msgstr "{selectedCount} selected, total"

#: src/state/setting/hooks.ts
msgid "Enter a invalid slippage percentage"
msgstr "Enter a invalid slippage percentage"

#: src/pages/components/Computer/ComputerPnL.tsx
#: src/pages/Liquidity/LiquidityComputerDialog.tsx
msgid "Risk / Reward"
msgstr "Risk / Reward"

#: src/pages/Earn/Stake/StakeLpDialog.tsx
msgid "Ivalid NFT"
msgstr "Ivalid NFT"

#: src/pages/Liquidity/LiquidityTable.tsx
msgid "Positions {0}"
msgstr "Positions {0}"

#: src/entities/TransactionDescription.ts
msgid "Partial TP: {poolSymbol}·{0}, size -{sizeDelta} {baseSymbol}, TP trigger price {prefix}{price}"
msgstr "Partial TP: {poolSymbol}·{0}, size -{sizeDelta} {baseSymbol}, TP trigger price {prefix}{price}"

#: src/pages/components/Operations/PositionHistoryList.tsx
#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
msgid "Partially Closed"
msgstr "Partially Closed"

#: src/components/PriceImpact/index.tsx
msgid "The price impact is the deviation between the estimated transaction price of the order and the current index price. When it is positive number, it means that the estimated transaction price is more advantageous, and negative number is vice versa."
msgstr "The price impact is the deviation between the estimated transaction price of the order and the current index price. When it is positive number, it means that the estimated transaction price is more advantageous, and negative number is vice versa."

#: src/pages/components/FreeTradingFeeWarning.tsx
msgid "<0>90% Off</0> on trading fees for BTC & ETH, effective until March 1, 2024. Eligibility applies to anyone binding a referral code."
msgstr "<0>90% Off</0> on trading fees for BTC & ETH, effective until March 1, 2024. Eligibility applies to anyone binding a referral code."

#: src/components/SearchInput/index.tsx
msgid "Search Market"
msgstr "Search Market"

#: src/components/Wallet/ConnectWalletModal.tsx
msgid "Connecting"
msgstr "Connecting"

#: src/pages/components/Operations/PositionHistoryList.tsx
msgid "Closing PnL = Closed Position PnL + Cumulative Net Funding"
msgstr "Closing PnL = Closed Position PnL + Cumulative Net Funding"

#: src/pages/components/TradeForm/TradeFees.tsx
msgid "Referral Discount"
msgstr "Referral Discount"

#: src/pages/Referrals/Connectors/MyConnectorNFT.tsx
msgid "You don't have a Connector NFT yet. Go to"
msgstr "You don't have a Connector NFT yet. Go to"

#: src/pages/components/ClaimPrevious.tsx
#: src/pages/Earn/ClaimAllDialog.tsx
#: src/pages/Referrals/DirectClaimDialog.tsx
msgid "Claim previous {0} rewards"
msgstr "Claim previous EQU rewards"

#: src/layouts/index.tsx
msgid "Trade on V3 >>"
msgstr "Trade on V3 >>"

#: src/pages/Earn/Liquidity/index.tsx
msgid "Max APR is calculated based on LPs' trading fee income in the past 24 hours, EQU daily emission, and the Max Leverage of the pool."
msgstr "Max APR is calculated based on LPs' trading fee income in the past 24 hours, EQU daily emission, and the Max Leverage of the pool."

#: src/pages/components/Operations/PositionsList.tsx
msgid "Margin Ratio = Maintenance Margin of Current Position / (Margin + Unrealized PnL)"
msgstr "Margin Ratio = Maintenance Margin of Current Position / (Margin + Unrealized PnL)"

#: src/pages/components/DepthChart.tsx
#: src/pages/components/DepthChart.tsx
#: src/pages/components/Operations/OpenOrdersList.tsx
#: src/pages/components/Operations/V2/OpenOrdersListV2.tsx
#: src/pages/components/ProfitLoss/index.tsx
msgid "Market Price"
msgstr "Market Price"

#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
msgid "Passive Position History"
msgstr "Passive Position History"

#: src/pages/components/ClaimPeriods.tsx
msgid "You will receive"
msgstr "You will receive"

#: src/pages/Earn/Summary.tsx
msgid "Staking and Pool2 Mining are data common to both V1/V2, while Position Mining and Liquidity Mining are specific to V2."
msgstr "Staking and Pool2 Mining are data common to both V1/V2, while Position Mining and Liquidity Mining are specific to V2."

#: src/pages/Referrals/Connectors/MintHistory.tsx
msgid "Mint History"
msgstr "Mint History"

#: src/pages/Referrals/Leaderboard/index.tsx
msgid "All referral rewards generated under this Member NFT (including unclaimed rewards)."
msgstr "All referral rewards generated under this Member NFT (including unclaimed rewards)."

#: src/pages/components/ClosePositionDialog.tsx
#: src/pages/Liquidity/V2/RemoveLiquidityV2.tsx
msgid "Insufficient Margin"
msgstr "Insufficient Margin"

#: src/components/Table/StickyTable.tsx
#: src/components/Table/VirtualTable.tsx
#: src/pages/Liquidity/V2/CollapseTableV2.tsx
#: src/pages/Referrals/Leaderboard/index.tsx
msgid "No Data"
msgstr "No Data"

#: src/pages/components/ClaimPeriods.tsx
msgid "Claim Amount"
msgstr "Claim Amount"

#: src/pages/Referrals/Members/index.tsx
#: src/pages/Referrals/Members/index.tsx
msgid "Referral Link"
msgstr "Referral Link"

#: src/pages/components/TradeForm/TradeButtonLimit.tsx
#: src/pages/components/TradeForm/TradeButtonLimitV2.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV1.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV2.tsx
msgid "Margin can not be less than 0"
msgstr "Margin can not be less than 0"

#: src/pages/components/AdjustMarginDialog.tsx
#: src/pages/components/V2/AdjustMarginDialogV2.tsx
#: src/pages/Liquidity/AdjustLiquidityMargin.tsx
#: src/pages/Liquidity/V2/AdjustLiquidityMarginV2.tsx
msgid "Max:"
msgstr "Max:"

#: src/entities/TransactionDescription.ts
msgid "Modify Position TP: {poolSymbol}·{0}, modified trigger price {prefix}{price}"
msgstr "Modify Position TP: {poolSymbol}·{0}, modified trigger price {prefix}{price}"

#: src/pages/Market/Links.tsx
msgid "Buy {0}"
msgstr "Buy {0}"

#: src/pages/Referrals/Leaderboard/index.tsx
msgid "Update at: {0}"
msgstr "Update at: {0}"

#: src/pages/Referrals/Connectors/MintDialog.tsx
#: src/pages/Referrals/Connectors/MintDialog.tsx
#: src/pages/Referrals/Connectors/MintedDetailsDialog.tsx
#: src/pages/Referrals/Connectors/MintHistory.tsx
msgid "Receiving Address"
msgstr "Receiving Address"

#: src/pages/components/AdjustMarginDialog.tsx
#: src/pages/components/ClosePositionDialog.tsx
#: src/pages/components/TradeForm/LimitForm.tsx
#: src/pages/components/TradeForm/MarketForm.tsx
#: src/pages/components/V2/AdjustMarginDialogV2.tsx
#: src/pages/Earn/Stake/StakeDialog.tsx
#: src/pages/Liquidity/AdjustLiquidityMargin.tsx
#: src/pages/Liquidity/LiquidityForm.tsx
#: src/pages/Liquidity/RiskBufferFundDialog.tsx
#: src/pages/Liquidity/V2/AdjustLiquidityMarginV2.tsx
#: src/pages/Liquidity/V2/LiquidityFormV2.tsx
#: src/pages/Liquidity/V2/RemoveLiquidityV2.tsx
msgid "Max"
msgstr "Max"

#: src/pages/Referrals/Members/RegisterCodeDialog.tsx
#: src/pages/Referrals/Traders/InputReferralCode.tsx
msgid "Checking code..."
msgstr "Checking code..."

#: src/pages/components/ProfitLoss/UpdateProfitLossDialog.tsx
msgid "Modify Partial TP"
msgstr "Modify Partial TP"

#: src/pages/components/TradeOrderPriceDialog.tsx
msgid "Modify limit order"
msgstr "Modify limit order"

#: src/pages/Earn/EmptyClaim.tsx
#: src/pages/Earn/Liquidity/index.tsx
#: src/pages/Earn/Position/index.tsx
msgid "No available rewards to claim"
msgstr "No available rewards to claim"

#: src/pages/Referrals/Traders/index.tsx
msgid "Bind Referral Code"
msgstr "Bind Referral Code"

#: src/pages/components/ClaimPrevious.tsx
msgid "The following are the {0} rewards earned after the <0>burn mechanism</0> took effect."
msgstr "The following are the EQU rewards earned after the burn mechanism took effect."

#: src/components/Wallet/ConnectWalletModal.tsx
msgid "Request of type 'wallet_requestPermissions' already pending for origin {0}. Please wait."
msgstr "Request of type 'wallet_requestPermissions' already pending for origin {0}. Please wait."

#: src/pages/components/AdjustMarginDialog.tsx
#: src/pages/components/Computer/ComputerEntryPrice.tsx
#: src/pages/components/Computer/ComputerEntryPrice.tsx
#: src/pages/components/Computer/ComputerLiquidationPrice.tsx
#: src/pages/components/Computer/ComputerPnL.tsx
#: src/pages/components/Operations/OpenOrdersList.tsx
#: src/pages/components/Operations/OrderHistoryList.tsx
#: src/pages/components/Operations/PositionsList.tsx
#: src/pages/components/Operations/PositionsList.tsx
#: src/pages/components/Operations/TradeHistoryList.tsx
#: src/pages/components/Operations/V2/OpenOrdersListV2.tsx
#: src/pages/components/ProfitLoss/ProfitLossCells.tsx
#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
#: src/pages/components/TradeForm/LimitForm.tsx
#: src/pages/components/TradeForm/MarketForm.tsx
#: src/pages/components/V2/AdjustMarginDialogV2.tsx
#: src/pages/Earn/Position/index.tsx
#: src/pages/Liquidity/AdjustLiquidityMargin.tsx
#: src/pages/Liquidity/V2/LiquidityPassivePositionV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Liquidity/V2/PassivePositionHistories.tsx
#: src/pages/Referrals/Leaderboard/MemberInvationDialog.tsx
#: src/pages/Referrals/Members/InvationDialog.tsx
#: src/pages/Referrals/Members/PositionValueDialog.tsx
msgid "Size"
msgstr "Size"

#: src/components/Wallet/ConnectWalletButton.tsx
#: src/components/Wallet/ConnectWalletModal.tsx
msgid "Connect Wallet"
msgstr "Connect Wallet"

#: src/entities/TransactionDescription.ts
msgid "Cancel: Cancelled limit {poolSymbol} Short, Size +{sizeDelta} {baseSymbol}, trigger price: {triggerPriceAbove}"
msgstr "Cancel: Cancelled limit {poolSymbol} Short, Size +{sizeDelta} {baseSymbol}, trigger price: {triggerPriceAbove}"

#: src/pages/Leaderboard/index.tsx
msgid "Referral Ranking"
msgstr "Referral Ranking"

#: src/pages/components/Operations/PositionHistoryList.tsx
#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
msgid "Closed"
msgstr "Closed"

#: src/pages/components/ClaimHistoryDialog.tsx
msgid "Tx Hash"
msgstr "Tx Hash"

#: src/pages/Liquidity/Detail.tsx
#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
msgid "Trading Fees"
msgstr "Trading Fees"

#: src/layouts/index.tsx
msgid "Launch Alert: V3 is now live with Zero Trading Fees and a Loss Compensation Mechanism! Choose from 55 diverse markets. Please gradually transition your trading to V3 and make sure to join our Weekly Trading Competition to win rewards!"
msgstr "Launch Alert: V3 is now live with Zero Trading Fees and a Loss Compensation Mechanism! Choose from 55 diverse markets. Please gradually transition your trading to V3 and make sure to join our Weekly Trading Competition to win rewards!"

#: src/pages/components/TradeForm/ToggleDirection.tsx
msgid "Long {0}"
msgstr "Long {0}"

#: src/pages/components/ShareDialog.tsx
#: src/pages/Leaderboard/Mining.tsx
#: src/pages/Leaderboard/Mining.tsx
#: src/pages/Leaderboard/Trade.tsx
#: src/pages/Leaderboard/Trade.tsx
#: src/pages/Referrals/Leaderboard/MemberInvationDialog.tsx
#: src/pages/Referrals/Leaderboard/MemberShareDialog.tsx
#: src/pages/Referrals/Members/InvationDialog.tsx
#: src/pages/Referrals/Members/PositionValueDialog.tsx
msgid "Address"
msgstr "Address"

#: src/pages/Liquidity/LiquidityTable.tsx
#: src/pages/Liquidity/RemoveLiquidity.tsx
msgid "Initial Margin"
msgstr "Initial Margin"

#: src/utils/formatEarnType.ts
msgid "EFC Members V2"
msgstr "EFC Members V2"

#: src/pages/Referrals/Members/index.tsx
#: src/pages/Referrals/Members/RegisterCodeDialog.tsx
msgid "Create referral code"
msgstr "Create referral code"

#: src/pages/Referrals/Leaderboard/index.tsx
#: src/pages/Referrals/Leaderboard/MemberInvationDialog.tsx
#: src/pages/Referrals/Members/InvationDialog.tsx
#: src/pages/Referrals/Members/PositionValueDialog.tsx
msgid "Total Trading Volume"
msgstr "Total Trading Volume"

#: src/pages/components/FeeChart.tsx
#: src/pages/components/FeeChart.tsx
msgid "Current {0}"
msgstr "Current {0}"

#: src/pages/components/ClaimPeriods.tsx
msgid "No Lock"
msgstr "No Lock"

#: src/pages/Earn/Stake/StakeLpTokenList.tsx
msgid "Stake {0}/{nativeSymbol} LP NFT"
msgstr "Stake {0}/{nativeSymbol} LP NFT"

#: src/pages/components/ShareDialog.tsx
msgid "Entry Price ({QUOTE_USD})"
msgstr "Entry Price (USDT)"

#: src/pages/Earn/RBFMining/index.tsx
msgid "RBF Mining APR = Daily EQU Emission Value / Total Contribution * 365 * 100%"
msgstr "RBF Mining APR = Daily EQU Emission Value / Total Contribution * 365 * 100%"

#: src/pages/components/ShareDialog.tsx
#: src/pages/Leaderboard/ShareDialog.tsx
#: src/pages/Referrals/Leaderboard/MemberShareDialog.tsx
msgid "Choose whether to share the following information:"
msgstr "Choose whether to share the following information:"

#: src/pages/components/Operations/PositionCells.tsx
msgid "Margin = Total Added Margin + Net Funding + Reduced & Settled"
msgstr "Margin = Total Added Margin + Net Funding + Reduced & Settled"

#: src/pages/Liquidity/LiquidityInfo.tsx
msgid "About {0}/{QUOTE_USD} Pool"
msgstr "About {0}/{QUOTE_USD} Pool"

#: src/pages/Earn/Position/index.tsx
#: src/pages/Earn/Position/index.tsx
#: src/pages/Referrals/Leaderboard/MemberInvationDialog.tsx
#: src/pages/Referrals/Members/InvationDialog.tsx
#: src/pages/Referrals/Members/PositionValueDialog.tsx
msgid "Coefficient"
msgstr "Coefficient"

#: src/entities/TransactionDescription.ts
msgid "Unstaking successful, you will receive {stakedAmount} {APP_TOKEN_SYMBOL} and {claimableAmoount} {USDT_TOKEN_SYMBOL}."
msgstr "Unstaking successful, you will receive {stakedAmount} {APP_TOKEN_SYMBOL} and {claimableAmoount} {USDT_TOKEN_SYMBOL}."

#: src/components/ApproveButtonV2/index.tsx
msgid "Approve Operation (Staking)"
msgstr "Approve Operation (Staking)"

#: src/layouts/Header/Drawer.tsx
#: src/layouts/Header/Setting.tsx
msgid "Theme"
msgstr "Theme"

#: src/pages/components/Computer/ComputerEntryPrice.tsx
#: src/pages/components/Operations/TradeHistoryList.tsx
#: src/pages/Earn/Stake/StakeAppTokenList.tsx
#: src/pages/Earn/Stake/StakeLpTokenList.tsx
#: src/pages/Liquidity/LiquidityHistory.tsx
#: src/pages/Liquidity/V2/LiquidityTradeHistoriesV2.tsx
#: src/pages/Liquidity/V2/PassivePositionHistories.tsx
#: src/pages/Referrals/Connectors/MyConnectorNFT.tsx
msgid "Operation"
msgstr "Operation"

#: src/pages/Referrals/Connectors/MyConnectorNFT.tsx
msgid "3. Enjoy 10% of the total income from minted Member NFTs."
msgstr "3. Enjoy 10% of the total income from minted Member NFTs."

#: src/pages/Referrals/Members/InvationDialog.tsx
msgid "No referees yet."
msgstr "No referees yet."

#: src/pages/Referrals/Traders/EditCodeDialog.tsx
msgid "Please input <10 chars."
msgstr "Please input <10 chars."

#: src/pages/Liquidity/RemoveLiquidity.tsx
msgid "Can not remove liquidity"
msgstr "Can not remove liquidity"

#: src/pages/components/TradeForm/MarketForm.tsx
#: src/pages/components/TradeForm/MarketForm.tsx
msgid "Due to significant market volatility in the current contract market, there have been abnormal fluctuations in market prices and opening prices, making it impossible to open positions. You can continue opening positions once the prices stabilize."
msgstr "Due to significant market volatility in the current contract market, there have been abnormal fluctuations in market prices and opening prices, making it impossible to open positions. You can continue opening positions once the prices stabilize."

#: src/pages/Referrals/Members/RegisterCodeDialog.tsx
#: src/pages/Referrals/Traders/InputReferralCode.tsx
msgid "Can only bind other user's referral code."
msgstr "Can only bind other user's referral code."

#: src/pages/components/Computer/ComputerPnL.tsx
msgid "SL PnL%"
msgstr "SL PnL%"

#: src/pages/Earn/Position/index.tsx
msgid "Avg. Coefficient"
msgstr "Avg. Coefficient"

#: src/pages/components/Operations/OpenOrdersList.tsx
#: src/pages/components/Operations/V2/OpenOrdersListV2.tsx
msgid "Adjust Trigger Price"
msgstr "Adjust Trigger Price"

#: src/pages/Referrals/Leaderboard/index.tsx
msgid "Ranking based on the EQU rewards obtained from Referral Mining, updated every hour."
msgstr "Ranking based on the EQU rewards obtained from Referral Mining, updated every hour."

#: src/pages/Liquidity/V2/LiquidityFormTips.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
msgid "Please refer to the API documentation >>"
msgstr "Please refer to the API documentation >>"

#: src/pages/Earn/Position/ClaimPositionDialog.tsx
#: src/pages/Earn/Position/index.tsx
#: src/pages/Market/TradeTableV1.tsx
#: src/pages/Market/TradeTableV2.tsx
msgid "My Positions"
msgstr "My Positions"

#: src/pages/components/ClaimPrevious.tsx
msgid "The following are the {0} rewards earned before the <0>burn mechanism</0> took effect."
msgstr "The following are the EQU rewards earned before the burn mechanism took effect."

#: src/pages/components/ProfitLoss/UpdateProfitLossDialog.tsx
msgid "After modifying the trigger price, the system will execute the trigger based on the latest trigger price."
msgstr "After modifying the trigger price, the system will execute the trigger based on the latest trigger price."

#: src/utils/getTransactionType.ts
#: src/utils/getTransactionType.ts
msgid "Position TP"
msgstr "Position TP"

#: src/pages/Liquidity/V2/LiquidityFormV2.tsx
msgid "When adding more liquidity, unrealized PnL on existing liquidity positions will be settled to margin. Therefore, the updated Margin = Current Position Margin + Added Margin + Current Position's Unrealized PnL"
msgstr "When adding more liquidity, unrealized PnL on existing liquidity positions will be settled to margin. Therefore, the updated Margin = Current Position Margin + Added Margin + Current Position's Unrealized PnL"

#: src/pages/components/DepthChart.tsx
#: src/pages/components/DepthChart.tsx
msgid "Premium Rate:"
msgstr "Premium Rate:"

#: src/pages/Liquidity/index.tsx
msgid "Fail to increase liquidity"
msgstr "Fail to increase liquidity"

#: src/pages/Referrals/Members/index.tsx
msgid "Purchase NFT"
msgstr "Purchase NFT"

#: src/pages/Earn/Content.tsx
msgid "Your current liquidity mining multiplier:"
msgstr "Your current liquidity mining multiplier.:"

#: src/pages/Referrals/Traders/EditCodeDialog.tsx
#: src/pages/Referrals/Traders/InputReferralCode.tsx
msgid "Invalid referral code, please re-enter after verification."
msgstr "Invalid referral code, please re-enter after verification."

#: src/pages/components/Operations/PositionHistoryList.tsx
#: src/pages/components/Operations/PositionHistoryList.tsx
#: src/pages/components/Operations/TradeHistoryList.tsx
#: src/pages/components/Operations/TradeHistoryList.tsx
msgid "Liquidation Fee"
msgstr "Liquidation Fee"

#: src/entities/TransactionDescription.ts
msgid "Close Short: Request to close {poolSymbol} Short, Size -{sizeDelta} {baseSymbol}, Acceptable price: ≤{acceptablePrice}"
msgstr "Close Short: Request to close {poolSymbol} Short, Size -{sizeDelta} {baseSymbol}, Acceptable price: ≤{acceptablePrice}"

#: src/entities/TransactionDescription.ts
msgid "Adjust Margin: Request to add {poolSymbol} Short position margin, Margin +{marginDelta} {QUOTE_USD}"
msgstr "Adjust Margin: Request to add {poolSymbol} Short position margin, Margin +{marginDelta} {QUOTE_USD}"

#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
#: src/pages/components/TipsDialog.tsx
msgid "During rapid market fluctuations, such as upswings followed by quick downturns, Take Profit orders may initially be triggered but then fail to meet the execution conditions due to the fast-changing prices. In such scenarios, these orders will not be executed and will remain active, awaiting the next opportunity to trigger."
msgstr "During rapid market fluctuations, such as upswings followed by quick downturns, Take Profit orders may initially be triggered but then fail to meet the execution conditions due to the fast-changing prices. In such scenarios, these orders will not be executed and will remain active, awaiting the next opportunity to trigger."

#: src/utils/getTransactionType.ts
#: src/utils/getTransactionType.ts
msgid "Partial TP"
msgstr "Partial TP"

#: src/pages/Earn/ClaimAllDialogV3.tsx
#: src/pages/Earn/Summary.tsx
msgid "Claim All"
msgstr "Claim All"

#: src/pages/Liquidity/V2/LiquidityFormTips.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
msgid "To manage net open position risk, it is recommended to use hedging strategies."
msgstr "To manage net open position risk, it is recommended to use hedging strategies."

#: src/layouts/Footer/MobileFooter.tsx
#: src/layouts/Header/index.tsx
#: src/pages/Earn/Position/index.tsx
#: src/pages/Market/TradeTableV2.tsx
#: src/pages/Market/TradeTableV2.tsx
msgid "Trade"
msgstr "Trade"

#: src/pages/Referrals/Traders/index.tsx
msgid "Mining multiplier"
msgstr "Mining multiplier"

#: src/pages/Earn/Stake/StakeAppTokenList.tsx
#: src/pages/Earn/Stake/StakeLpTokenList.tsx
msgid "Switch your network before staking"
msgstr "Switch your network before staking"

#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
msgid "The TP PnL cannot be less than 0."
msgstr "The TP PnL cannot be less than 0."

#: src/pages/Referrals/Members/index.tsx
#: src/pages/Referrals/Members/index.tsx
#: src/pages/Referrals/Members/index.tsx
msgid "Member #{0}"
msgstr "Member #{0}"

#: src/pages/Liquidity/LiquidityComputerDialog.tsx
msgid "The risk/reward ratio is calculated by dividing the potential risk in trading by the expected rewards. The calculation formula is: Risk/Reward Rate = (Current Floating Loss of The Liquidity Pool * Your Liquidity Ratio) / Max Income * 100%"
msgstr "The risk/reward ratio is calculated by dividing the potential risk in trading by the expected rewards. The calculation formula is: Risk/Reward Rate = (Current Floating Loss of The Liquidity Pool * Your Liquidity Ratio) / Max Income * 100%"

#: src/pages/Earn/index.tsx
msgid "Earn rewards from trading fees and EQU mining."
msgstr "Earn rewards from trading fees and EQU mining."

#: src/pages/Earn/Stake/StakeLpTokenList.tsx
msgid "Staking amount is calculated as the amount of EQU in LP Token at the time of staking."
msgstr "Staking amount is calculated as the amount of EQU in LP Token at the time of staking."

#: src/entities/TransactionDescription.ts
msgid "Orders cancelled successfully."
msgstr "Orders cancelled successfully."

#: src/pages/Earn/SummaryEQU.tsx
msgid "Total Staked"
msgstr "Total Staked"

#: src/pages/Referrals/Connectors/MintHistory.tsx
msgid "You haven't minted yet."
msgstr "You haven't minted yet."

#: src/pages/components/AdjustMarginDialog.tsx
#: src/pages/components/ClosePositionDialog.tsx
#: src/pages/components/Computer/ComputerLeverage.tsx
#: src/pages/components/ShareDialog.tsx
#: src/pages/components/TradeForm/LimitForm.tsx
#: src/pages/components/TradeForm/MarketForm.tsx
#: src/pages/components/V2/AdjustMarginDialogV2.tsx
#: src/pages/Earn/Position/index.tsx
#: src/pages/Liquidity/AdjustLiquidityMargin.tsx
#: src/pages/Liquidity/LiquidityComputerDialog.tsx
#: src/pages/Liquidity/LiquidityForm.tsx
#: src/pages/Liquidity/LiquidityTable.tsx
#: src/pages/Liquidity/V2/AdjustLiquidityMarginV2.tsx
#: src/pages/Liquidity/V2/LiquidityFormV2.tsx
#: src/pages/Liquidity/V2/LiquidityPassivePositionV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Liquidity/V2/RemoveLiquidityV2.tsx
msgid "Leverage"
msgstr "Leverage"

#: src/pages/components/Operations/PositionCells.tsx
msgid "Unrealized PnL is calculated based on Index Price."
msgstr "Unrealized PnL is calculated based on Index Price."

#: src/pages/Earn/Content.tsx
msgid "Staking"
msgstr "Staking"

#: src/entities/TransactionDescription.ts
msgid "Cancel Position SL: {poolSymbol}·{0}, size -{sizeDelta} {baseSymbol}, trigger price {prefix}{price}"
msgstr "Cancel Position SL: {poolSymbol}·{0}, size -{sizeDelta} {baseSymbol}, trigger price {prefix}{price}"

#: src/pages/components/Operations/OpenOrdersList.tsx
#: src/pages/components/Operations/OrderHistoryList.tsx
#: src/pages/components/Operations/TradeHistoryList.tsx
#: src/pages/components/Operations/V2/OpenOrdersListV2.tsx
#: src/pages/Earn/Position/ClaimPositionDialog.tsx
#: src/pages/Earn/Position/index.tsx
#: src/pages/Market/TradeTableV1.tsx
#: src/pages/Market/TradeTableV2.tsx
#: src/pages/Referrals/Leaderboard/MemberInvationDialog.tsx
#: src/pages/Referrals/Members/InvationDialog.tsx
#: src/pages/Referrals/Members/PositionValueDialog.tsx
msgid "Market"
msgstr "Market"

#: src/pages/Referrals/Members/RegisterCodeDialog.tsx
#: src/pages/Referrals/Traders/EditCodeDialog.tsx
msgid "Enter referral code"
msgstr "Enter referral code"

#: src/pages/components/Operations/OrderHistoryList.tsx
msgid "Executed"
msgstr "Executed"

#: src/pages/Market/TradeTableV1.tsx
#: src/pages/Market/TradeTableV2.tsx
#: src/pages/Trade/Overview.tsx
#: src/pages/Trade/Overview.tsx
msgid "Funding fees are settled every hour. When the funding rate is positive, long positions pay short positions; when it's negative, short positions pay long positions."
msgstr "Funding fees are settled every hour. When the funding rate is positive, long positions pay short positions; when it's negative, short positions pay long positions."

#: src/pages/components/ClosePositionDialog.tsx
msgid "Est. Close Price"
msgstr "Est. Close Price"

#: src/pages/components/AdjustMarginDialog.tsx
#: src/pages/components/ClosePositionDialog.tsx
#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
#: src/pages/components/ProfitLoss/UpdateProfitLossDialog.tsx
#: src/pages/components/TradeForm/TradeButtonLimit.tsx
#: src/pages/components/TradeForm/TradeButtonLimitV2.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV1.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV2.tsx
#: src/pages/components/TradeOrderPriceDialog.tsx
#: src/pages/components/V2/AdjustMarginDialogV2.tsx
#: src/pages/Earn/Architect/ClaimArchitectDialog.tsx
#: src/pages/Earn/ClaimAllDialog.tsx
#: src/pages/Earn/ClaimAllDialogV3.tsx
#: src/pages/Earn/Liquidity/ClaimLiquidityDialog.tsx
#: src/pages/Earn/Pool2Mining/ClaimPool2Dialog.tsx
#: src/pages/Earn/Position/ClaimPositionDialog.tsx
#: src/pages/Earn/RBFMining/ClaimRBFDialog.tsx
#: src/pages/Earn/Stake/ClaimStakeDialog.tsx
#: src/pages/Earn/Stake/StakeDialog.tsx
#: src/pages/Earn/Stake/StakeLpDialog.tsx
#: src/pages/Liquidity/LiquidityButton.tsx
#: src/pages/Liquidity/RiskBufferFundDialog.tsx
#: src/pages/Liquidity/V2/LiquidityButtonV2.tsx
#: src/pages/Referrals/ClaimDialog.tsx
#: src/pages/Referrals/Connectors/MintDialog.tsx
#: src/pages/Referrals/DirectClaimDialog.tsx
#: src/pages/Referrals/Members/RegisterCodeDialog.tsx
#: src/pages/Referrals/Traders/EditCodeDialog.tsx
#: src/pages/Referrals/Traders/InputReferralCode.tsx
msgid "Submitting..."
msgstr "Submitting..."

#: src/pages/Earn/Stake/index.tsx
msgid "The first 7 days of the next lock-up period are the 'unlocking period', during which you can freely unlock your assets. Missing this period will result in a re-locking for the same duration."
msgstr "The first 7 days of the next lock-up period are the 'unlocking period', during which you can freely unlock your assets. Missing this period will result in a re-locking for the same duration."

#: src/entities/TransactionDescription.ts
msgid "Adjust Margin: Request to reduce {poolSymbol} Short position margin, Margin -{marginDelta} {QUOTE_USD}"
msgstr "Adjust Margin: Request to reduce {poolSymbol} Short position margin, Margin -{marginDelta} {QUOTE_USD}"

#: src/pages/components/ModifyTisDialog.tsx
msgid "Position TP/SL refers to the TP/SL of the entire position. When the latest <0>market price</0> reaches the trigger price, the system will place a TP/SL order for the entire position at the market price."
msgstr "Position TP/SL refers to the TP/SL of the entire position. When the latest <0>market price</0> reaches the trigger price, the system will place a TP/SL order for the entire position at the market price."

#: src/pages/components/Operations/OpenOrdersList.tsx
#: src/pages/components/Operations/TradeHistoryList.tsx
#: src/pages/components/Operations/V2/OpenOrdersListV2.tsx
#: src/pages/Liquidity/LiquidityHistory.tsx
#: src/pages/Liquidity/V2/LiquidityTradeHistoriesV2.tsx
#: src/pages/Liquidity/V2/PassivePositionHistories.tsx
msgid "Time"
msgstr "Time"

#: src/pages/components/V2/AdjustMarginDialogV2.tsx
#: src/pages/Liquidity/V2/AdjustLiquidityMarginV2.tsx
msgid "Reduce"
msgstr "Reduce"

#: src/utils/formatEarnType.ts
msgid "EFC Connectors"
msgstr "EFC Connectors"

#: src/pages/Liquidity/AdjustLiquidityMargin.tsx
#: src/pages/Liquidity/LiquidityTable.tsx
#: src/pages/Liquidity/LiquidityTable.tsx
#: src/pages/Liquidity/RemoveLiquidity.tsx
msgid "Realized Profit"
msgstr "Realized Profit"

#: src/pages/components/Operations/OpenOrdersList.tsx
#: src/pages/components/Operations/V2/OpenOrdersListV2.tsx
msgid "Expired"
msgstr "Expired"

#: src/pages/components/Operations/PositionHistoryList.tsx
#: src/pages/components/Operations/PositionHistoryList.tsx
#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
msgid "Liquidated"
msgstr "Liquidated"

#: src/entities/TransactionDescription.ts
msgid "Modify Trigger Price: {poolSymbol} Long, modified trigger price: {triggerPriceAbove}"
msgstr "Modify Trigger Price: {poolSymbol} Long, modified trigger price: {triggerPriceAbove}"

#: src/pages/Earn/SummaryEQU.tsx
msgid "Current Emissions"
msgstr "Current Emissions"

#: src/pages/components/Operations/PositionHistoryList.tsx
msgid "Liquidition Size"
msgstr "Liquidition Size"

#: src/pages/components/Operations/OrderHistoryList.tsx
msgid "No order history"
msgstr "No order history"

#: src/utils/getTransactionType.ts
#: src/utils/getTransactionType.ts
msgid "Partial SL"
msgstr "Partial SL"

#: src/pages/Earn/Content.tsx
#: src/pages/Earn/Summary.tsx
#: src/pages/Referrals/Summary.tsx
msgid "Claiming..."
msgstr "Claiming..."

#: src/components/ApproveButton/index.tsx
#: src/components/ApproveButtonV2/index.tsx
#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
#: src/pages/components/TradeForm/TradeButtonLimit.tsx
#: src/pages/components/TradeForm/TradeButtonLimit.tsx
#: src/pages/components/TradeForm/TradeButtonLimitV2.tsx
#: src/pages/components/TradeForm/TradeButtonLimitV2.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV1.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV2.tsx
#: src/pages/Earn/Stake/StakeDialog.tsx
#: src/pages/Liquidity/LiquidityButton.tsx
#: src/pages/Liquidity/RiskBufferFundDialog.tsx
#: src/pages/Liquidity/V2/LiquidityButtonV2.tsx
msgid "Insufficient Balance"
msgstr "Insufficient Balance"

#: src/entities/TransactionDescription.ts
msgid "Cancel: Cancelled limit {poolSymbol} Long, Size +{sizeDelta} {baseSymbol}, trigger price: {triggerPriceAbove}"
msgstr "Cancel: Cancelled limit {poolSymbol} Long, Size +{sizeDelta} {baseSymbol}, trigger price: {triggerPriceAbove}"

#: src/pages/Referrals/Leaderboard/index.tsx
msgid "Member ID"
msgstr "Member ID"

#: src/pages/Referrals/Traders/index.tsx
#: src/pages/Referrals/Traders/index.tsx
msgid "<0>0 Trading Fees</0> for BTC, effective until May 31, 2024."
msgstr "<0>0 Trading Fees</0> for BTC, effective until May 31, 2024."

#: src/pages/Earn/Position/index.tsx
msgid "Avg. APR is calculated based on the average leverage and coefficient of the markets."
msgstr "Avg. APR is calculated based on the average leverage and coefficient of the markets."

#: src/entities/TransactionDescription.ts
msgid "Position TP: {poolSymbol}·{0}, size -{sizeDelta} {baseSymbol}, TP trigger price {prefix}{price}"
msgstr "Position TP: {poolSymbol}·{0}, size -{sizeDelta} {baseSymbol}, TP trigger price {prefix}{price}"

#: src/pages/Referrals/Connectors/MintDialog.tsx
msgid "Incorrect address format"
msgstr "Incorrect address format"

#: src/state/setting/hooks.ts
msgid "Your transaction may fail"
msgstr "Your transaction may fail"

#: src/pages/Liquidity/ConfirmIncreaseLiquidity.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
msgid "Add Liquidity"
msgstr "Add Liquidity"

#: src/pages/Earn/Stake/StakeAppTokenList.tsx
#: src/pages/Earn/Stake/StakeLpTokenList.tsx
#: src/pages/Earn/Stake/UnStakeModal.tsx
msgid "Unstake"
msgstr "Unstake"

#: src/layouts/Header/index.tsx
msgid "API"
msgstr "API"

#: src/pages/components/ClosePositionDialog.tsx
#: src/pages/components/Computer/ComputerTargetPrice.tsx
#: src/pages/components/Computer/index.tsx
#: src/pages/components/ShareDialog.tsx
#: src/pages/Leaderboard/Trade.tsx
#: src/pages/Leaderboard/Trade.tsx
#: src/pages/Leaderboard/Trade.tsx
#: src/pages/Liquidity/AdjustLiquidityMargin.tsx
msgid "PnL"
msgstr "PnL"

#: src/pages/components/Operations/PositionHistoryList.tsx
#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
msgid "Time Opened"
msgstr "Time Opened"

#: src/pages/Earn/Stake/StakeDialog.tsx
#: src/pages/Earn/Stake/StakeLpDialog.tsx
msgid "Lock Duration"
msgstr "Lock Duration"

#: src/pages/components/AdjustMarginDialog.tsx
#: src/pages/components/V2/AdjustMarginDialogV2.tsx
#: src/pages/Liquidity/AdjustLiquidityMargin.tsx
#: src/pages/Liquidity/V2/AdjustLiquidityMarginV2.tsx
msgid "Exceeds the max increase"
msgstr "Exceeds the max increase"

#: src/pages/components/TradeForm/TradeButtonLimit.tsx
#: src/pages/components/TradeForm/TradeButtonLimitV2.tsx
msgid "Cannot be lower than the market price"
msgstr "Cannot be lower than the market price"

#: src/pages/Earn/RBFMining/index.tsx
#: src/pages/Liquidity/LiquidityInfo.tsx
#: src/pages/Liquidity/RiskBufferFundDialog.tsx
msgid "Withdraw"
msgstr "Withdraw"

#: src/pages/Earn/SummaryEQU.tsx
msgid "Staked {0} in {1}/{nativeSymbol} LP NFT"
msgstr "Staked {0} in {1}/{nativeSymbol} LP NFT"

#: src/pages/Liquidity/Detail.tsx
#: src/pages/Liquidity/LiquidityCard.tsx
#: src/pages/Liquidity/LiquidityTable.tsx
msgid "24h Volume ({QUOTE_USD})"
msgstr "24h Volume ({QUOTE_USD})"

#: src/pages/Liquidity/Detail.tsx
#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
msgid "Funding Fees"
msgstr "Funding Fees"

#: src/pages/Referrals/Traders/index.tsx
msgid "Trading fee rate"
msgstr "Trading fee rate"

#: src/pages/Market/Summary.tsx
#: src/pages/Market/TradeTableV1.tsx
#: src/pages/Market/TradeTableV2.tsx
#: src/pages/Trade/Overview.tsx
#: src/pages/Trade/Overview.tsx
msgid "Open Interest"
msgstr "Open Interest"

#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
msgid "Margin settlement has a 10% slippage to prevent order failure due to insufficient margin."
msgstr "Margin settlement has a 10% slippage to prevent order failure due to insufficient margin."

#: src/pages/components/Computer/ComputerEntryPrice.tsx
#: src/pages/components/Computer/ComputerLiquidationPrice.tsx
#: src/pages/components/Computer/ComputerPnL.tsx
#: src/pages/components/Computer/ComputerTargetPrice.tsx
#: src/pages/Liquidity/LiquidityComputerDialog.tsx
msgid "Calculate"
msgstr "Calculate"

#: src/pages/components/ShareDialog.tsx
#: src/pages/Leaderboard/ShareDialog.tsx
#: src/pages/Referrals/Leaderboard/MemberShareDialog.tsx
msgid "Referral Code:"
msgstr "Referral Code:"

#: src/utils/formatEarnType.ts
msgid "Position Mining V2"
msgstr "Position Mining V2"

#: src/components/ApproveButton/index.tsx
#: src/components/ApproveButton/index.tsx
#: src/components/ApproveButton/index.tsx
#: src/components/ApproveButtonV2/index.tsx
#: src/components/ApproveButtonV2/index.tsx
#: src/pages/components/TradeForm/TradeButtonLimit.tsx
#: src/pages/components/TradeForm/TradeButtonLimitV2.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV1.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV2.tsx
#: src/pages/Liquidity/LiquidityButton.tsx
#: src/pages/Liquidity/V2/LiquidityButtonV2.tsx
msgid "Approve Operation (one-time)"
msgstr "Approve Operation (one-time)"

#: src/utils/formatEarnType.ts
msgid "EFC Architects"
msgstr "EFC Architects"

#: src/utils/getTransactionType.ts
#: src/utils/getTransactionType.ts
msgid "Position SL"
msgstr "Position SL"

#: src/entities/TransactionDescription.ts
msgid "Adjust Margin: Request to reduce {poolSymbol} Long position margin, Margin -{marginDelta} {QUOTE_USD}"
msgstr "Adjust Margin: Request to reduce {poolSymbol} Long position margin, Margin -{marginDelta} {QUOTE_USD}"

#: src/layouts/Footer/MobileFooter.tsx
#: src/layouts/Header/index.tsx
#: src/pages/Earn/index.tsx
msgid "Earn"
msgstr "Earn"

#: src/pages/components/TradeForm/TradeFees.tsx
msgid "({0} of the position value)"
msgstr "({0} of the position value)"

#: src/pages/Leaderboard/index.tsx
msgid "Mining Ranking"
msgstr "Mining Ranking"

#: src/pages/Earn/Liquidity/index.tsx
msgid "Due to the maximum leverage being adjusted from 200x to 100x, the Max APR figure may be lower than the Avg. APR."
msgstr "Due to the maximum leverage being adjusted from 200x to 100x, the Max APR figure may be lower than the Avg. APR."

#: src/pages/Liquidity/AdjustLiquidityMargin.tsx
msgid "Liq.Price"
msgstr "Liq.Price"

#: src/pages/components/ClosePositionDialog.tsx
msgid "Size:"
msgstr "Size:"

#: src/pages/Earn/Stake/StakeAppTokenList.tsx
#: src/pages/Earn/Stake/StakeLpTokenList.tsx
msgid "You haven't staked yet."
msgstr "You haven't staked yet."

#: src/pages/Liquidity/V2/LiquidityPassivePositionV2.tsx
msgid "Est. Size"
msgstr "Est. Size"

#: src/pages/components/ClaimPeriods.tsx
msgid "{0} days"
msgstr "{0} days"

#: src/pages/components/ProfitLoss/UpdateProfitLossDialog.tsx
#: src/pages/components/TradeOrderPriceDialog.tsx
msgid "After modifying the trigger price, the system will execute the trigger based on the latest trigger price. Acceptable Price will be updated."
msgstr "After modifying the trigger price, the system will execute the trigger based on the latest trigger price. Acceptable Price will be updated."

#: src/pages/Referrals/Traders/index.tsx
msgid "Your current fee rate for opening and closing positions."
msgstr "Your current fee rate for opening and closing positions."

#: src/pages/Referrals/Connectors/MyConnectorNFT.tsx
msgid "2. Mint Member NFTs and distribute them to friends."
msgstr "2. Mint Member NFTs and distribute them to friends."

#: src/layouts/Header/Drawer.tsx
#: src/layouts/Header/Setting.tsx
#: src/layouts/Header/Setting.tsx
#: src/pages/components/AcceptableEntryPriceSetting/AcceptableEntryPriceSettingDialog.tsx
#: src/pages/components/ImpactFeeSetting/ImpactFeeSettingDialog.tsx
msgid "Auto"
msgstr "Auto"

#: src/pages/components/Computer/ComputerEntryPrice.tsx
#: src/pages/components/Computer/ComputerLiquidationPrice.tsx
#: src/pages/components/Computer/ComputerPnL.tsx
#: src/pages/components/Computer/ComputerTargetPrice.tsx
#: src/pages/Liquidity/LiquidityComputerDialog.tsx
msgid "Result"
msgstr "Result"

#: src/pages/Earn/Content.tsx
msgid "Binding other user's referral code can increase the mining multiplier to 1.1x. The larger the multiplier, the greater the earnings."
msgstr "Binding other user's referral code can increase the mining multiplier to 1.1x. The larger the multiplier, the greater the earnings."

#: src/pages/Referrals/Connectors/MintDialog.tsx
msgid "You can still use Connector #{0} to mint {canCreate} Member NFT."
msgstr "You can still use Connector #{0} to mint {canCreate} Member NFT."

#: src/entities/TransactionDescription.ts
msgid "Adjust Margin: Request to reduce {poolSymbol} liquidity margin, Margin -{marginDelta} {QUOTE_USD}"
msgstr "Adjust Margin: Request to reduce {poolSymbol} liquidity margin, Margin -{marginDelta} {QUOTE_USD}"

#: src/components/LongShortTag/index.tsx
#: src/components/LongShortTag/index.tsx
#: src/entities/TransactionDescription.ts
#: src/pages/components/Computer/ComputerDirection.tsx
#: src/pages/components/Operations/OpenOrdersList.tsx
#: src/pages/components/Operations/OrderHistoryList.tsx
#: src/pages/components/Operations/PositionsList.tsx
#: src/pages/components/Operations/TradeHistoryList.tsx
#: src/pages/components/Operations/V2/OpenOrdersListV2.tsx
#: src/pages/components/ShareDialog.tsx
#: src/pages/components/TradeForm/ConfirmDialog.tsx
#: src/pages/components/TradeForm/TradeButtonLimit.tsx
#: src/pages/components/TradeForm/TradeButtonLimitV2.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV1.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV2.tsx
#: src/pages/Earn/Position/index.tsx
#: src/pages/Liquidity/V2/LiquidityCardV2.tsx
#: src/pages/Liquidity/V2/LiquidityPassivePositionV2.tsx
#: src/pages/Liquidity/V2/LiquidityPassivePositionV2.tsx
#: src/pages/Liquidity/V2/LiquidityPassivePositionV2.tsx
#: src/pages/Liquidity/V2/LiquidityPassivePositionV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Liquidity/V2/PassivePositionHistories.tsx
#: src/pages/Market/TradeTableV1.tsx
#: src/pages/Market/TradeTableV2.tsx
#: src/pages/Referrals/Leaderboard/MemberInvationDialog.tsx
#: src/pages/Referrals/Members/InvationDialog.tsx
#: src/pages/Referrals/Members/PositionValueDialog.tsx
#: src/pages/Trade/MobileActions.tsx
msgid "Short"
msgstr "Short"

#: src/pages/components/AdjustMarginDialog.tsx
#: src/pages/components/ClosePositionDialog.tsx
#: src/pages/components/Operations/PositionHistoryList.tsx
#: src/pages/components/Operations/PositionsList.tsx
#: src/pages/components/Operations/PositionsList.tsx
#: src/pages/components/ProfitLoss/index.tsx
#: src/pages/components/TradeForm/MarketForm.tsx
#: src/pages/components/V2/AdjustMarginDialogV2.tsx
#: src/pages/Liquidity/V2/LiquidityPassivePositionV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
msgid "Liq. Price"
msgstr "Liq. Price"

#: src/pages/Liquidity/LiquidityTable.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
msgid "Indicates the risk rate of the current position. When the risk rate reaches 100%, a liquidation will be triggered."
msgstr "Indicates the risk rate of the current position. When the risk rate reaches 100%, a liquidation will be triggered."

#: src/pages/Referrals/Traders/index.tsx
msgid "Earn a<0>1.1x</0>boasted multiplier for liquidity mining."
msgstr "Earn a<0>1.1x</0>boasted multiplier for liquidity mining."

#: src/pages/Referrals/Leaderboard/index.tsx
msgid "ID"
msgstr "ID"

#: src/pages/Liquidity/V2/LiquidityPassivePositionV2.tsx
msgid "The estimated passive position size after adding liquidity. The passive position will be opened at the Index Price, and the actual position size may vary based on market conditions."
msgstr "The estimated passive position size after adding liquidity. The passive position will be opened at the Index Price, and the actual position size may vary based on market conditions."

#: src/pages/Earn/Position/index.tsx
#: src/pages/Earn/Position/index.tsx
msgid "Learn more about <0>Position Mining Coefficient >></0>"
msgstr "Learn more about <0>Position Mining Coefficient >></0>"

#: src/pages/components/TipsDialog.tsx
msgid "Caution: Read This Before Using"
msgstr "Caution: Read This Before Using"

#: src/pages/components/AcceptableEntryPriceSetting/AcceptableEntryPriceSettingDialog.tsx
msgid "Max slippage"
msgstr "Max slippage"

#: src/pages/Referrals/Summary.tsx
msgid "V2 data only"
msgstr "V2 data only"

#: src/pages/components/ShareDialog.tsx
#: src/pages/Leaderboard/ShareDialog.tsx
#: src/pages/Referrals/Leaderboard/MemberShareDialog.tsx
msgid "Telegram"
msgstr "Telegram"

#: src/pages/Earn/Architect/index.tsx
msgid "My NFT"
msgstr "My NFT"

#: src/components/TransactionSnackbar/index.tsx
#: src/layouts/Header/Account.tsx
msgid "View on Explorer"
msgstr "View on Explorer"

#: src/pages/components/Computer/ComputerLiquidationPrice.tsx
#: src/pages/components/Computer/ComputerPnL.tsx
#: src/pages/components/Computer/ComputerTargetPrice.tsx
#: src/pages/components/TradeForm/index.tsx
#: src/pages/Market/TradeTableV2.tsx
#: src/pages/Trade/MobileActions.tsx
msgid "trade.Market"
msgstr "Market"

#: src/entities/TransactionDescription.ts
msgid "Add Liquidity: Request to add liquidity {poolSymbol}, Liquidity +{liquidityDelta}, Margin +{marginDelta} {QUOTE_USD}"
msgstr "Add Liquidity: Request to add liquidity {poolSymbol}, Liquidity +{liquidityDelta}, Margin +{marginDelta} {QUOTE_USD}"

#: src/components/PriceImpact/index.tsx
msgid "Price Impact"
msgstr "Price Impact"

#: src/pages/Earn/Pool2Mining/Summary.tsx
#: src/pages/Earn/Stake/StakeLpTokenList.tsx
#: src/pages/Earn/Stake/Summary.tsx
msgid "Staked amount is calculated as the amount of EQU in LP Token at the time of staking."
msgstr "Staked amount is calculated as the amount of EQU in LP Token at the time of staking."

#: src/pages/components/Operations/TradeHistoryList.tsx
msgid "Transaction Price"
msgstr "Transaction Price"

#: src/pages/components/Operations/PositionHistoryList.tsx
#: src/pages/components/Operations/PositionHistoryList.tsx
#: src/pages/components/Operations/TradeHistoryList.tsx
#: src/pages/components/Operations/TradeHistoryList.tsx
msgid "Cumulative Funding Fees"
msgstr "Cumulative Funding Fees"

#: src/pages/Earn/Architect/index.tsx
#: src/pages/Referrals/Connectors/MyConnectorNFT.tsx
msgid "Claimable"
msgstr "Claimable"

#: src/layouts/Header/index.tsx
msgid "Docs"
msgstr "Docs"

#: src/pages/Liquidity/MobileActions.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
msgid "Add {0} Liquidity"
msgstr "Add {0} Liquidity"

#: src/pages/Earn/Stake/Summary.tsx
#: src/pages/Earn/SummaryEQU.tsx
msgid "Staked {0}"
msgstr "Staked {0}"

#: src/pages/components/TradeForm/TradeButtonLimit.tsx
#: src/pages/components/TradeForm/TradeButtonLimitV2.tsx
msgid "Cannot exceed the market price"
msgstr "Cannot exceed the market price"

#: src/pages/Earn/Architect/index.tsx
#: src/pages/Earn/ClaimAllDialog.tsx
#: src/pages/Earn/ClaimAllDialogV3.tsx
#: src/pages/Earn/Liquidity/index.tsx
#: src/pages/Earn/Pool2Mining/ClaimPool2Dialog.tsx
#: src/pages/Earn/Pool2Mining/Summary.tsx
#: src/pages/Earn/Position/index.tsx
#: src/pages/Earn/RBFMining/index.tsx
#: src/pages/Earn/Stake/StakeAppTokenList.tsx
#: src/pages/Earn/Stake/Summary.tsx
#: src/pages/Earn/Stake/UnStakeLpDialog.tsx
#: src/pages/Earn/Stake/UnStakeModal.tsx
#: src/pages/Earn/Summary.tsx
#: src/pages/Referrals/Connectors/MyConnectorNFT.tsx
#: src/pages/Referrals/Members/index.tsx
#: src/pages/Referrals/Members/index.tsx
#: src/pages/Referrals/Members/index.tsx
#: src/pages/Referrals/Summary.tsx
msgid "Claimable Rewards"
msgstr "Claimable Rewards"

#: src/pages/Earn/Pool2Mining/Summary.tsx
msgid "APR (Multiplier = {maxMultiplier}x)"
msgstr "APR (Multiplier = {maxMultiplier}x)"

#: src/pages/Earn/Position/index.tsx
msgid "There will be a delay in the display of rewards after opening a position, which typically does not exceed 2 hours."
msgstr "There will be a delay in the display of rewards after opening a position, which typically does not exceed 2 hours."

#: src/pages/components/ChartsPool.tsx
#: src/pages/Liquidity/Detail.tsx
msgid "Details"
msgstr "Details"

#: src/pages/components/Operations/OrderHistoryList.tsx
msgid "2. Slippage is too high. The slippage in the actual execution exceeded the acceptable slippage range you set, resulting in order cancellation."
msgstr "2. Slippage is too high. The slippage in the actual execution exceeded the acceptable slippage range you set, resulting in order cancellation."

#: src/pages/components/Operations/OpenOrdersList.tsx
#: src/pages/components/Operations/V2/OpenOrdersListV2.tsx
msgid "No open orders"
msgstr "No open orders"

#: src/pages/components/Computer/ComputerPnL.tsx
msgid "TP PnL%"
msgstr "TP PnL%"

#: src/pages/components/TipsDialog.tsx
msgid "Take Profit / Stop Loss"
msgstr "Take Profit / Stop Loss"

#: src/pages/Referrals/Connectors/MintedDetailsDialog.tsx
msgid "No Member NFTs minted yet."
msgstr "No Member NFTs minted yet."

#: src/pages/Referrals/Leaderboard/index.tsx
msgid "Referees’ Position Value"
msgstr "Referees’ Position Value"

#: src/pages/Liquidity/AdjustLiquidityMargin.tsx
#: src/pages/Liquidity/V2/LiquidityCardV2.tsx
#: src/pages/Liquidity/V2/LiquidityPassivePositionV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
msgid "Passive Position"
msgstr "Passive Position"

#: src/pages/components/TipsDialog.tsx
msgid "Do not show again within 24 hours"
msgstr "Do not show again within 24 hours"

#: src/pages/components/FreeTradingFeeWarning.tsx
msgid "<0>0 Trading Fee</0> for BTC, effective until May 31, 2024. Eligibility applies to anyone binding a referral code."
msgstr "<0>0 Trading Fee</0> for BTC, effective until May 31, 2024. Eligibility applies to anyone binding a referral code."

#: src/pages/Liquidity/AdjustLiquidityMargin.tsx
#: src/pages/Liquidity/LiquidityTable.tsx
#: src/pages/Liquidity/RemoveLiquidity.tsx
msgid "Temporary Loss"
msgstr "Temporary Loss"

#: src/layouts/Header/index.tsx
#: src/pages/Market/Links.tsx
msgid "Voting"
msgstr "Voting"

#: src/pages/Referrals/Traders/index.tsx
msgid "Your current trading fee discount for opening and closing positions."
msgstr "Your current trading fee discount for opening and closing positions."

#: src/pages/components/Computer/ComputerPnL.tsx
#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
msgid "SL trigger price"
msgstr "SL trigger price"

#: src/pages/components/DepthChart.tsx
#: src/pages/components/DepthChart.tsx
msgid "Total Size:"
msgstr "Total Size:"

#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
msgid "Decrease"
msgstr "Decrease"

#: src/pages/components/ProfitLoss/UpdateProfitLossDialog.tsx
msgid "Modify Partial SL"
msgstr "Modify Partial SL"

#: src/pages/Liquidity/LiquidityTable.tsx
msgid "Total trading fees generated by the trading users within 24 hours of the market."
msgstr "Total trading fees generated by the trading users within 24 hours of the market."

#: src/pages/Earn/Summary.tsx
msgid "The trading fee income is settled every hour."
msgstr "The trading fee income is settled every hour."

#: src/pages/components/ModifyTisDialog.tsx
msgid "Do not show again"
msgstr "Do not show again"

#: src/pages/Liquidity/RiskBufferFundDialog.tsx
msgid "Exceeds max withdrawable"
msgstr "Exceeds max withdrawable"

#: src/pages/components/FeeChart.tsx
msgid "1h Rate"
msgstr "1h Rate"

#: src/pages/Earn/Stake/StakeAppTokenList.tsx
#: src/pages/Earn/Stake/StakeLpTokenList.tsx
msgid "Locking"
msgstr "Locking"

#: src/entities/TransactionDescription.ts
msgid "Limit Short: Open {poolSymbol} Short, Size +{sizeDelta} {baseSymbol}, trigger price: {triggerPriceAbove}"
msgstr "Limit Short: Open {poolSymbol} Short, Size +{sizeDelta} {baseSymbol}, trigger price: {triggerPriceAbove}"

#: src/pages/Earn/RBFMining/ClaimRBFDialog.tsx
#: src/pages/Earn/RBFMining/index.tsx
#: src/pages/Liquidity/RiskBufferFundDialog.tsx
msgid "My Contribution"
msgstr "My Contribution"

#: src/pages/Earn/Liquidity/index.tsx
#: src/pages/Liquidity/Detail.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
msgid "Max APR is calculated based on LPs' fee income in the last 7 days, EQU daily emission, and the Max Leverage of the pool. PnL from passive position is not included."
msgstr "Max APR is calculated based on LPs' fee income in the last 7 days, EQU daily emission, and the Max Leverage of the pool. PnL from passive position is not included."

#: src/pages/components/TradeForm/ConfirmDialog.tsx
msgid "You can choose to cancel the order in 'Orders'. Are you sure to continue opening the position?"
msgstr "You can choose to cancel the order in 'Orders'. Are you sure to continue opening the position?"

#: src/pages/Leaderboard/ShareDialog.tsx
#: src/pages/Referrals/Leaderboard/MemberShareDialog.tsx
msgid "7 Days"
msgstr "7 Days"

#: src/pages/Liquidity/LiquidityTable.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
msgid "Risk"
msgstr "Risk"

#: src/pages/Earn/Liquidity/index.tsx
#: src/pages/Liquidity/Detail.tsx
#: src/pages/Liquidity/LiquidityCard.tsx
#: src/pages/Liquidity/LiquidityTable.tsx
#: src/pages/Liquidity/V2/LiquidityCardV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
msgid "My Liquidity"
msgstr "My Liquidity"

#: src/pages/Earn/Stake/StakeAppTokenList.tsx
#: src/pages/Earn/Stake/StakeDialog.tsx
#: src/pages/Earn/Stake/StakeLpDialog.tsx
#: src/pages/Earn/Stake/StakeLpTokenList.tsx
msgid "{_period} days"
msgstr "{_period} days"

#: src/components/ApproveButton/index.tsx
#: src/components/ApproveButtonV2/index.tsx
#: src/pages/components/TradeForm/TradeButtonLimit.tsx
#: src/pages/components/TradeForm/TradeButtonLimit.tsx
#: src/pages/components/TradeForm/TradeButtonLimitV2.tsx
#: src/pages/components/TradeForm/TradeButtonLimitV2.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV1.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV1.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV2.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV2.tsx
#: src/pages/Earn/Stake/StakeDialog.tsx
#: src/pages/Earn/Stake/StakeLpDialog.tsx
#: src/pages/Liquidity/LiquidityButton.tsx
#: src/pages/Liquidity/LiquidityButton.tsx
#: src/pages/Liquidity/V2/LiquidityButtonV2.tsx
#: src/pages/Liquidity/V2/LiquidityButtonV2.tsx
msgid "Approving..."
msgstr "Approving..."

#: src/pages/Referrals/Traders/index.tsx
msgid "Your current liquidity mining multiplier."
msgstr "Your current liquidity mining multiplier."

#: src/pages/Earn/Pool2Mining/Summary.tsx
msgid "{0}/{nativeSymbol} LP APR (Uniswap)"
msgstr "{0}/{nativeSymbol} LP APR (Uniswap)"

#: src/pages/Trade/OperationV1.tsx
#: src/pages/Trade/OperationV1.tsx
#: src/pages/Trade/OperationV2.tsx
#: src/pages/Trade/OperationV2.tsx
msgid "Hide other markets"
msgstr "Hide other markets"

#: src/layouts/Header/index.tsx
#: src/layouts/Header/index.tsx
#: src/pages/Market/Links.tsx
#: src/pages/Referrals/index.tsx
msgid "Referrals"
msgstr "Referrals"

#: src/pages/Referrals/Traders/index.tsx
msgid "Trading Fee Discount"
msgstr "Trading Fees Discount"

#: src/entities/TransactionDescription.ts
msgid "Staking successful, you have staked {amount} {APP_TOKEN_SYMBOL} using LP NFT (#{tokenId})."
msgstr "Staking successful, you have staked {amount} {APP_TOKEN_SYMBOL} using LP NFT (#{tokenId})."

#: src/pages/components/Operations/HistoryList.tsx
#: src/pages/Liquidity/V2/LiquidityHistoryV2.tsx
msgid "Position History"
msgstr "Position History"

#: src/pages/Earn/Architect/ClaimArchitectDialog.tsx
msgid "The following {0} rewards will be settled for you."
msgstr "The following {0} rewards will be settled for you."

#: src/pages/Referrals/Connectors/MyConnectorNFT.tsx
msgid "My Connector NFT"
msgstr "My Connector NFT"

#: src/pages/components/FeeChart.tsx
msgid "8h Rate"
msgstr "8h Rate"

#: src/components/ApproveButton/index.tsx
#: src/components/ApproveButtonV2/index.tsx
#: src/pages/components/AdjustMarginDialog.tsx
#: src/pages/components/ClosePositionDialog.tsx
#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
#: src/pages/components/ProfitLoss/UpdateProfitLossDialog.tsx
#: src/pages/components/TradeForm/TradeButtonLimit.tsx
#: src/pages/components/TradeForm/TradeButtonLimitV2.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV1.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV2.tsx
#: src/pages/components/TradeOrderPriceDialog.tsx
#: src/pages/components/V2/AdjustMarginDialogV2.tsx
#: src/pages/Earn/Architect/ClaimArchitectDialog.tsx
#: src/pages/Earn/ClaimAllDialog.tsx
#: src/pages/Earn/ClaimAllDialogV3.tsx
#: src/pages/Earn/Liquidity/ClaimLiquidityDialog.tsx
#: src/pages/Earn/Pool2Mining/ClaimPool2Dialog.tsx
#: src/pages/Earn/Position/ClaimPositionDialog.tsx
#: src/pages/Earn/RBFMining/ClaimRBFDialog.tsx
#: src/pages/Earn/Stake/ClaimStakeDialog.tsx
#: src/pages/Earn/Stake/StakeDialog.tsx
#: src/pages/Earn/Stake/StakeLpDialog.tsx
#: src/pages/Earn/Stake/UnStakeLpDialog.tsx
#: src/pages/Earn/Stake/UnStakeModal.tsx
#: src/pages/Liquidity/AdjustLiquidityMargin.tsx
#: src/pages/Liquidity/LiquidityButton.tsx
#: src/pages/Liquidity/RemoveLiquidity.tsx
#: src/pages/Liquidity/RiskBufferFundDialog.tsx
#: src/pages/Liquidity/V2/AdjustLiquidityMarginV2.tsx
#: src/pages/Liquidity/V2/LiquidityButtonV2.tsx
#: src/pages/Liquidity/V2/RemoveLiquidityV2.tsx
#: src/pages/Referrals/ClaimDialog.tsx
#: src/pages/Referrals/Connectors/MintDialog.tsx
#: src/pages/Referrals/DirectClaimDialog.tsx
#: src/pages/Referrals/Members/RegisterCodeDialog.tsx
#: src/pages/Referrals/Traders/EditCodeDialog.tsx
#: src/pages/Referrals/Traders/InputReferralCode.tsx
msgid "Loading..."
msgstr "Loading..."

#: src/pages/components/ShareDialog.tsx
#: src/pages/Leaderboard/ShareDialog.tsx
#: src/pages/Referrals/Leaderboard/MemberShareDialog.tsx
msgid "Share"
msgstr "Share"

#: src/pages/components/TradeForm/TradeButtonMarketV1.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV2.tsx
msgid "Error Price"
msgstr "Error Price"

#: src/pages/components/Operations/OpenOrdersList.tsx
#: src/pages/components/Operations/V2/OpenOrdersListV2.tsx
msgid "The available closing quantity is insufficient. The order will not take effect until there is enough position quantity."
msgstr "The available closing quantity is insufficient. The order will not take effect until there is enough position quantity."

#: src/pages/Earn/Position/index.tsx
msgid "Total Positions"
msgstr "Total Positions"

#: src/pages/components/Operations/PositionCells.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
msgid "Total Added Margin"
msgstr "Total Added Margin"

#: src/pages/components/ProfitLoss/index.tsx
msgid "Partial Position"
msgstr "Partial Position"

#: src/pages/Referrals/Members/index.tsx
msgid "By holding Member NFT, you can generate multiple referral codes and receive trading fee income and mining rewards."
msgstr "By holding Member NFT, you can generate multiple referral codes and receive mining rewards and trading fee income."

#: src/components/SelectPools/index.tsx
#: src/pages/components/ChartsPool.tsx
#: src/pages/components/ShareDialog.tsx
#: src/pages/components/TradeForm/LimitForm.tsx
#: src/pages/Liquidity/V2/PassivePositionHistories.tsx
msgid "Price"
msgstr "Price"

#: src/pages/Liquidity/RiskBufferFundDialog.tsx
msgid "Net performance ≤0"
msgstr "Net performance ≤0"

#: src/entities/TransactionDescription.ts
msgid "Unstaking successful, you will receive LP NFT (#{tokenId}) and {claimableAmoount} {USDT_TOKEN_SYMBOL}."
msgstr "Unstaking successful, you will receive LP NFT (#{tokenId}) and {claimableAmoount} {USDT_TOKEN_SYMBOL}."

#: src/entities/TransactionDescription.ts
msgid "Modify Trigger Price: {poolSymbol} Short, modified trigger price: {triggerPriceAbove}"
msgstr "Modify Trigger Price: {poolSymbol} Short, modified trigger price: {triggerPriceAbove}"

#: src/layouts/Header/Setting.tsx
msgid "The slippage refers to the difference between the expected price you can accept and the final transaction price."
msgstr "The slippage refers to the difference between the expected price you can accept and the final transaction price."

#: src/pages/Earn/Stake/StakeLpDialog.tsx
#: src/pages/Earn/Stake/StakeLpDialog.tsx
msgid "Est. Stake"
msgstr "Est. Stake"

#: src/pages/components/Operations/PositionHistoryList.tsx
#: src/pages/components/Operations/TradeHistoryList.tsx
#: src/pages/components/Operations/TradeHistoryList.tsx
msgid "Closing PnL = Closed Position PnL + Cumulative Funding Fees"
msgstr "Closing PnL = Closed Position PnL + Cumulative Funding Fees"

#: src/pages/components/Operations/PositionsList.tsx
msgid "The value is used to measure position risk. A liquidation will be triggered when the margin ratio reaches 100%."
msgstr "The value is used to measure position risk. A liquidation will be triggered when the margin ratio reaches 100%."

#: src/pages/Liquidity/V2/LiquidityFormTips.tsx
msgid "You may lose all your margin"
msgstr "You may lose all your margin"

#: src/pages/Earn/Content.tsx
#: src/utils/formatEarnType.ts
msgid "RBF Mining"
msgstr "RBF Mining"

#: src/pages/Liquidity/RiskBufferFundDialog.tsx
msgid "You cannot withdraw your contribution if the fund's net performance is negative, even after the lock-up period has expired, until the net performance turns positive."
msgstr "You cannot withdraw your contribution if the fund's net performance is negative, even after the lock-up period has expired, until the net performance turns positive."

#: src/pages/Referrals/Leaderboard/MemberShareDialog.tsx
msgid "EQU Rewards"
msgstr "EQU Rewards"

#: src/pages/components/V2/LiquidityApr.tsx
msgid "Total APR"
msgstr "Total APR"

#: src/pages/components/Operations/PositionCells.tsx
#: src/pages/components/ProfitLoss/index.tsx
msgid "TP/SL"
msgstr "TP/SL"

#: src/pages/Liquidity/V2/RemoveLiquidityV2.tsx
msgid "Insufficient maintenance margin after operation"
msgstr "Insufficient maintenance margin after operation"

#: src/pages/components/V2/LiquidityApr.tsx
msgid "EQU Mining APR"
msgstr "EQU Mining APR"

#: src/pages/Earn/SummaryEQU.tsx
msgid "Total Staked = Staked {0} in {1}/{nativeSymbol} LP NFT + Staked {2}"
msgstr "Total Staked = Staked {0} in {1}/{nativeSymbol} LP NFT + Staked {2}"

#: src/pages/components/Operations/PositionsList.tsx
#: src/pages/Liquidity/LiquidityTable.tsx
#: src/pages/Liquidity/V2/LiquidityCardV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Market/TradeTableV2.tsx
#: src/pages/Referrals/Leaderboard/MemberInvationDialog.tsx
#: src/pages/Referrals/Members/InvationDialog.tsx
#: src/pages/Referrals/Members/PositionValueDialog.tsx
msgid "No open positions"
msgstr "No open positions"

#: src/entities/TransactionDescription.ts
msgid "Modify Partial TP: {poolSymbol}·{0}, modified trigger price {prefix}{price}"
msgstr "Modify Partial TP: {poolSymbol}·{0}, modified trigger price {prefix}{price}"

#: src/entities/TransactionDescription.ts
msgid "Remove Liquidity: Request to remove {poolSymbol} liquidity, Liquidity -{liquidityDelta}, Margin -{marginDelta} {QUOTE_USD}"
msgstr "Remove Liquidity: Request to remove {poolSymbol} liquidity, Liquidity -{liquidityDelta}, Margin -{marginDelta} {QUOTE_USD}"

#: src/components/SearchInput/index.tsx
msgid "Search Pool"
msgstr "Search Pool"

#: src/pages/Earn/Stake/StakeLpDialog.tsx
msgid "There are currently no LP NFTs available for staking."
msgstr "There are currently no LP NFTs available for staking."

#: src/entities/TransactionDescription.ts
msgid "Adjust Margin: Request to add {poolSymbol} liquidity margin, Margin +{marginDelta} {QUOTE_USD}"
msgstr "Adjust Margin: Request to add {poolSymbol} liquidity margin, Margin +{marginDelta} {QUOTE_USD}"

#: src/pages/Referrals/Leaderboard/MemberShareDialog.tsx
msgid "Referral Rank"
msgstr "Referral Rank"

#: src/components/Common/Dialog.tsx
#: src/pages/components/Operations/OpenOrdersList.tsx
#: src/pages/components/Operations/OpenOrdersList.tsx
#: src/pages/components/Operations/V2/OpenOrdersListV2.tsx
#: src/pages/components/Operations/V2/OpenOrdersListV2.tsx
#: src/pages/Liquidity/V2/LiquidityFormTips.tsx
msgid "Cancel"
msgstr "Cancel"

#: src/pages/components/ClosePositionDialog.tsx
msgid "Leverage exceeds {0}x after closing"
msgstr "Leverage exceeds {0}x after closing"

#: src/components/SelectPools/index.tsx
msgid "No data found"
msgstr "No data found"

#: src/pages/Liquidity/LiquidityHistory.tsx
#: src/pages/Liquidity/V2/LiquidityTradeHistoriesV2.tsx
#: src/utils/formatTrade.ts
#: src/utils/formatTrade.ts
msgid "Liquidation"
msgstr "Liquidation"

#: src/pages/Liquidity/LiquidityInfo.tsx
msgid "The Risk Buffer Fund will bear all of the temporary losses first before impacting Liquidity Providers (LPs). If its balance becomes negative, this indicates that the LPs are facing temporary losses."
msgstr "The Risk Buffer Fund will bear all of the temporary losses first before impacting Liquidity Providers (LPs). If its balance becomes negative, this indicates that the LPs are facing temporary losses."

#: src/utils/formatEarnType.ts
msgid "Staking EQU"
msgstr ""

#: src/pages/Market/TradeTableV1.tsx
#: src/pages/Market/TradeTableV2.tsx
#: src/pages/Trade/Overview.tsx
#: src/pages/Trade/Overview.tsx
msgid "1h Funding"
msgstr "1h Funding"

#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
msgid "Reduced Margin"
msgstr "Reduced Margin"

#: src/pages/Leaderboard/Trade.tsx
msgid "PnL Rank"
msgstr "PnL Rank"

#: src/entities/TransactionDescription.ts
msgid "Cancel Partial TP: {poolSymbol}·{0}, size -{sizeDelta} {baseSymbol}, trigger price {prefix}{price}"
msgstr "Cancel Partial TP: {poolSymbol}·{0}, size -{sizeDelta} {baseSymbol}, trigger price {prefix}{price}"

#: src/components/Wallet/ConnectWalletModal.tsx
msgid "The user rejected the request."
msgstr "The user rejected the request."

#: src/pages/Referrals/Traders/InputReferralCode.tsx
#: src/pages/Referrals/Traders/InputReferralCode.tsx
msgid "Bound"
msgstr "Bound"

#: src/pages/Earn/Pool2Mining/Summary.tsx
#: src/pages/Earn/Stake/Summary.tsx
msgid "Staked EQU in LP NFT"
msgstr "Staked EQU in LP NFT"

#: src/pages/components/ProfitLoss/UpdateProfitLossDialog.tsx
#: src/pages/components/TradeOrderPriceDialog.tsx
msgid "Current trigger price"
msgstr "Current trigger price"

#: src/hooks/useLoopMyPositions.ts
#: src/hooks/useLoopMyPositions.ts
msgid "Fail to close position"
msgstr "Fail to close position"

#: src/pages/components/Operations/PositionCells.tsx
#: src/pages/components/Operations/PositionCells.tsx
msgid "Net Funding"
msgstr "Net Funding"

#: src/components/Leverage/index.tsx
#: src/components/LiqPrice/index.tsx
msgid "Liquidating"
msgstr "Liquidating"

#: src/components/ApproveButton/index.tsx
#: src/components/ApproveButtonV2/index.tsx
#: src/pages/components/TradeForm/TradeButtonLimit.tsx
#: src/pages/components/TradeForm/TradeButtonLimit.tsx
#: src/pages/components/TradeForm/TradeButtonLimitV2.tsx
#: src/pages/components/TradeForm/TradeButtonLimitV2.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV1.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV1.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV2.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV2.tsx
#: src/pages/Earn/Stake/StakeDialog.tsx
#: src/pages/Liquidity/LiquidityButton.tsx
#: src/pages/Liquidity/LiquidityButton.tsx
#: src/pages/Liquidity/V2/LiquidityButtonV2.tsx
#: src/pages/Liquidity/V2/LiquidityButtonV2.tsx
msgid "Approve {0}"
msgstr "Approve {0}"

#: src/pages/components/Computer/ComputerLiquidationPrice.tsx
#: src/pages/components/Computer/ComputerLiquidationPrice.tsx
msgid "Balance"
msgstr "Balance"

#: src/pages/components/ModifyTisDialog.tsx
msgid "When the Position TP/SL is triggered due to violent market fluctuation, the actual transaction price may vary with the acceptable price, the TP/SL order may fail to be executed."
msgstr "When the Position TP/SL is triggered due to violent market fluctuation, the actual transaction price may vary with the acceptable price, the TP/SL order may fail to be executed."

#: src/pages/components/Computer/ComputerPnL.tsx
msgid "The risk/reward ratio is calculated by dividing the potential risk in trading by the expected rewards. The calculation formula is: Risk/Reward Ratio = (Stop Loss Trigger Price - Entry Price) / (Entry Price - Take Profit Trigger Price) * 100%"
msgstr "The risk/reward ratio is calculated by dividing the potential risk in trading by the expected rewards. The calculation formula is: Risk/Reward Ratio = (Stop Loss Trigger Price - Entry Price) / (Entry Price - Take Profit Trigger Price) * 100%"

#: src/pages/Leaderboard/index.tsx
msgid "Trade Ranking"
msgstr "Trade Ranking"

#: src/pages/Liquidity/LiquidityInfo.tsx
msgid "To reduce the risk of price manipulation, there is a limit on the open interest of the pool."
msgstr "To reduce the risk of price manipulation, there is a limit on the open interest of the pool."

#: src/pages/Referrals/Members/index.tsx
msgid "You don't have a Member NFT yet. You can obtain one through the following method:"
msgstr "You don't have a Member NFT yet. You can obtain one through the following method:"

#: src/pages/components/ClaimPeriods.tsx
msgid "{0} Lock Duration"
msgstr "EQU lock duration"

#: src/entities/TransactionDescription.ts
msgid "Long: Request to open {baseSymbol} Long, Size +{sizeDelta} {baseSymbol}, Acceptable price: ≤{acceptablePrice}"
msgstr "Long: Request to open {baseSymbol} Long, Size +{sizeDelta} {baseSymbol}, Acceptable price: ≤{acceptablePrice}"

#: src/pages/Referrals/Traders/index.tsx
msgid "Enjoy a<0>50%</0>discount on trading fee."
msgstr "Enjoy a <0>50%</0> discount on trading fee."

#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
msgid "Exp. PnL"
msgstr "Exp. PnL"

#: src/pages/Earn/Content.tsx
msgid "Bind now >>"
msgstr "Bind now >>"

#: src/pages/Liquidity/V2/LiquidityButtonV2.tsx
msgid "Position risk rate > 90%"
msgstr "Position risk rate > 90%"

#: src/pages/components/TradeForm/ConfirmDialog.tsx
msgid "There is a TP/SL order in the 'Orders', which will take effect after the position is opened, and may be triggered for execution immediately. The order is:"
msgstr "There is a TP/SL order in the 'Orders', which will take effect after the position is opened, and may be triggered for execution immediately. The order is:"

#: src/pages/Liquidity/V2/RemoveLiquidityV2.tsx
msgid "The current liquidity position is in a loss state, and the loss will be deducted from the margin."
msgstr "The current liquidity position is in a loss state, and the loss will be deducted from the margin."

#: src/pages/components/TradeForm/TradeButtonLimit.tsx
#: src/pages/components/TradeForm/TradeButtonLimitV2.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV1.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV2.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV2.tsx
#: src/pages/Liquidity/LiquidityButton.tsx
#: src/pages/Liquidity/V2/LiquidityButtonV2.tsx
#: src/pages/Liquidity/V2/RemoveLiquidityV2.tsx
msgid "Exceeds the max leverage of {0}x"
msgstr "Exceeds the max leverage of {0}x"

#: src/pages/Referrals/Members/index.tsx
#: src/pages/Referrals/Members/index.tsx
msgid "Create"
msgstr "Create"

#: src/pages/Liquidity/V2/LiquidityFormV2.tsx
msgid "Addition-Only"
msgstr "Addition-Only"

#: src/pages/components/ShareDialog.tsx
#: src/pages/Leaderboard/ShareDialog.tsx
#: src/pages/Referrals/Leaderboard/MemberShareDialog.tsx
msgid ""
"Equation - The Next Generation of Decentralized Perpetual Exchange \n"
"Up to 200x leverage. No sign up or deposit required. Equation is set to revolutionize the experience of perpetual trading and the future of DeFi."
msgstr ""
"Equation - The Next Generation of Decentralized Perpetual Exchange \n"
"Up to 200x leverage. No sign up or deposit required. Equation is set to revolutionize the experience of perpetual trading and the future of DeFi."

#: src/pages/components/AdjustMarginDialog.tsx
#: src/pages/components/ClaimHistoryDialog.tsx
#: src/pages/components/ClaimHistoryDialog.tsx
#: src/pages/components/ClaimHistoryDialog.tsx
#: src/pages/components/ClaimHistoryDialog.tsx
#: src/pages/components/ClosePositionDialog.tsx
#: src/pages/components/V2/AdjustMarginDialogV2.tsx
#: src/pages/Earn/Architect/ClaimArchitectDialog.tsx
#: src/pages/Earn/Liquidity/ClaimLiquidityDialog.tsx
#: src/pages/Earn/Position/ClaimPositionDialog.tsx
#: src/pages/Earn/RBFMining/ClaimRBFDialog.tsx
#: src/pages/Earn/Stake/ClaimStakeDialog.tsx
#: src/pages/Earn/Stake/StakeDialog.tsx
#: src/pages/Liquidity/AdjustLiquidityMargin.tsx
#: src/pages/Liquidity/RiskBufferFundDialog.tsx
#: src/pages/Liquidity/V2/AdjustLiquidityMarginV2.tsx
#: src/pages/Liquidity/V2/RemoveLiquidityV2.tsx
#: src/pages/Referrals/ClaimDialog.tsx
#: src/pages/Referrals/ClaimDialog.tsx
#: src/pages/Referrals/DirectClaimDialog.tsx
msgid "Amount"
msgstr "Amount"

#: src/pages/Earn/Architect/ClaimArchitectDialog.tsx
#: src/pages/Earn/Architect/ClaimArchitectDialog.tsx
#: src/pages/Earn/Architect/ClaimArchitectDialog.tsx
#: src/pages/Earn/ClaimAllDialog.tsx
#: src/pages/Earn/ClaimAllDialog.tsx
#: src/pages/Earn/ClaimAllDialogV3.tsx
#: src/pages/Earn/ClaimAllDialogV3.tsx
#: src/pages/Earn/ClaimAllDialogV3.tsx
#: src/pages/Earn/ClaimAllDialogV3.tsx
#: src/pages/Earn/Content.tsx
#: src/pages/Earn/Liquidity/ClaimLiquidityDialog.tsx
#: src/pages/Earn/Liquidity/ClaimLiquidityDialog.tsx
#: src/pages/Earn/Liquidity/ClaimLiquidityDialog.tsx
#: src/pages/Earn/Liquidity/ClaimLiquidityDialog.tsx
#: src/pages/Earn/Pool2Mining/ClaimPool2Dialog.tsx
#: src/pages/Earn/Pool2Mining/ClaimPool2Dialog.tsx
#: src/pages/Earn/Pool2Mining/ClaimPool2Dialog.tsx
#: src/pages/Earn/Pool2Mining/ClaimPool2Dialog.tsx
#: src/pages/Earn/Pool2Mining/ClaimPool2Dialog.tsx
#: src/pages/Earn/Position/ClaimPositionDialog.tsx
#: src/pages/Earn/Position/ClaimPositionDialog.tsx
#: src/pages/Earn/Position/ClaimPositionDialog.tsx
#: src/pages/Earn/Position/ClaimPositionDialog.tsx
#: src/pages/Earn/Position/ClaimPositionDialog.tsx
#: src/pages/Earn/RBFMining/ClaimRBFDialog.tsx
#: src/pages/Earn/RBFMining/ClaimRBFDialog.tsx
#: src/pages/Earn/RBFMining/ClaimRBFDialog.tsx
#: src/pages/Earn/RBFMining/ClaimRBFDialog.tsx
#: src/pages/Earn/Stake/ClaimStakeDialog.tsx
#: src/pages/Earn/Stake/ClaimStakeDialog.tsx
#: src/pages/Earn/Stake/ClaimStakeDialog.tsx
#: src/pages/Referrals/ClaimDialog.tsx
#: src/pages/Referrals/ClaimDialog.tsx
#: src/pages/Referrals/ClaimDialog.tsx
#: src/pages/Referrals/ClaimDialog.tsx
#: src/pages/Referrals/DirectClaimDialog.tsx
#: src/pages/Referrals/DirectClaimDialog.tsx
#: src/pages/Referrals/Summary.tsx
msgid "Claim"
msgstr "Claim"

#: src/pages/components/AcceptableEntryPriceSetting/AcceptableEntryPriceSettingDialog.tsx
msgid "If the price change exceeds this percentage, your transaction will revert."
msgstr "If the price change exceeds this percentage, your transaction will revert."

#: src/layouts/Header/Drawer.tsx
#: src/layouts/Header/Setting.tsx
#: src/pages/components/AcceptableEntryPriceSetting/AcceptableEntryPriceSettingDialog.tsx
msgid "Max slippage precision is 10%"
msgstr "Max slippage precision is 10%"

#: src/layouts/Footer/MobileFooter.tsx
msgid "Home"
msgstr "Home"

#: src/pages/Referrals/Members/index.tsx
msgid "All referral rewards generated during the ownership of this Member NFT at the current address (including unclaimed rewards)."
msgstr "All referral rewards generated during the ownership of this Member NFT at the current address (including unclaimed rewards)."

#: src/pages/Liquidity/RiskBufferFundDialog.tsx
msgid "Withdrawable:"
msgstr "Withdrawable:"

#: src/pages/Liquidity/LiquidityTable.tsx
msgid "Utilized Leverage"
msgstr "Utilized Leverage"

#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
msgid "The SL PnL% cannot be less than -100%."
msgstr "The SL PnL% cannot be less than -100%."

#: src/pages/Liquidity/V2/LiquidityFormV2.tsx
msgid "When adding more liquidity, you can choose the \"Addition-Only\" mode. The Addition-Only mode means only adding liquidity while keeping the margin unchanged. The leverage of the liquidity position will change accordingly after the addition."
msgstr "When adding more liquidity, you can choose the \"Addition-Only\" mode. The Addition-Only mode means only adding liquidity while keeping the margin unchanged. The leverage of the liquidity position will change accordingly after the addition."

#: src/pages/Market/index.tsx
msgid "My Positions Only"
msgstr "My Positions Only"

#: src/pages/components/ClaimHistoryDialog.tsx
#: src/pages/Earn/Summary.tsx
#: src/pages/Earn/Summary.tsx
#: src/pages/Referrals/Summary.tsx
#: src/pages/Referrals/Summary.tsx
msgid "Claim History"
msgstr "Claim History"

#: src/layouts/Header/Network.tsx
msgid "Select a network"
msgstr "Select a network"

#: src/entities/TransactionDescription.ts
msgid "Modify Position SL: {poolSymbol}·{0}, modified trigger price {prefix}{price}"
msgstr "Modify Position SL: {poolSymbol}·{0}, modified trigger price {prefix}{price}"

#: src/pages/components/Operations/TradeHistoryList.tsx
msgid "No trade history"
msgstr "No trade history"

#: src/pages/Liquidity/V2/LiquidityFormTips.tsx
msgid "Continue Adding"
msgstr "Continue Adding"

#: src/pages/Leaderboard/Trade.tsx
msgid "Ranking based on PnL or PnL%, updated every hour."
msgstr "Ranking based on PnL or PnL%, updated every hour."

#: src/components/ApproveButtonV2/index.tsx
msgid "Approve Operation (Mining)"
msgstr "Approve Operation (Mining)"

#: src/pages/components/ExpiredOrderWarning.tsx
msgid "After closing a position, if there are still uncancelled or unexecuted TP/SL orders, these orders remain valid for newly opened positions. Before opening new positions, please confirm whether the orders in the 'Orders' section need to be cancelled."
msgstr "After closing a position, if there are still uncancelled or unexecuted TP/SL orders, these orders remain valid for newly opened positions. Before opening new positions, please confirm whether the orders in the 'Orders' section need to be cancelled."

#: src/pages/Earn/Position/index.tsx
msgid "Avg. Coefficient is the average Position Mining Coefficient of all position-holding users."
msgstr "Avg. Coefficient is the average Position Mining Coefficient of all position-holding users."

#: src/pages/components/ClosePositionDialog.tsx
msgid "When closing a position, you can choose to use the \"Reduce-Only\" mode. The Reduce-Only mode means only reducing the position size while keeping the margin unchanged. The leverage of the position will change accordingly after the reduction."
msgstr "When closing a position, you can choose to use the \"Reduce-Only\" mode. The Reduce-Only mode means only reducing the position size while keeping the margin unchanged. The leverage of the position will change accordingly after the reduction."

#: src/pages/Liquidity/index.tsx
msgid "Add {0}/{QUOTE_USD} Liquidity"
msgstr "Add {0}/{QUOTE_USD} Liquidity"

#: src/pages/components/Computer/ComputerPnL.tsx
msgid "The risk/reward ratio is calculated by dividing the potential risk in trading by the expected rewards. The calculation formula is: Risk/Reward Ratio = (Entry Price - Stop Loss Trigger Price) / (Take Profit Trigger Price - Entry Price) * 100%"
msgstr "The risk/reward ratio is calculated by dividing the potential risk in trading by the expected rewards. The calculation formula is: Risk/Reward Ratio = (Entry Price - Stop Loss Trigger Price) / (Take Profit Trigger Price - Entry Price) * 100%"

#: src/pages/Liquidity/RiskBufferFundDialog.tsx
msgid "Net Performance"
msgstr "Net Performance"

#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
msgid "Added Liquidity"
msgstr "Added Liquidity"

#: src/pages/Liquidity/Detail.tsx
#: src/pages/Liquidity/LiquidityTable.tsx
msgid "Max APR is calculated based on LPs' trading fee income in the past 24 hours, EQU daily emission, and the maximum leverage of the pool."
msgstr "Max APR is calculated based on LPs' trading fee income in the past 24 hours, EQU daily emission, and the maximum leverage of the pool."

#: src/pages/Earn/Position/index.tsx
msgid "Avg. APR"
msgstr "Avg. APR"

#: src/pages/Referrals/Connectors/MintedDetailsDialog.tsx
msgid "Mint Details"
msgstr "Mint Details"

#: src/pages/components/ClosePositionDialog.tsx
#: src/pages/Liquidity/V2/RemoveLiquidityV2.tsx
msgid "Reduce-Only"
msgstr "Reduce-Only"

#: src/pages/components/Operations/PositionHistoryList.tsx
#: src/pages/components/Operations/PositionHistoryList.tsx
#: src/pages/components/Operations/TradeHistoryList.tsx
#: src/pages/components/Operations/TradeHistoryList.tsx
msgid "Closed Position PnL"
msgstr "Closed Position PnL"

#: src/pages/Referrals/Traders/EditCodeDialog.tsx
msgid "Edit referral code"
msgstr "Edit referral code"

#: src/pages/components/AcceptableEntryPriceSetting/index.tsx
#: src/pages/components/ClosePositionDialog.tsx
#: src/pages/components/Operations/OpenOrdersList.tsx
#: src/pages/components/Operations/OrderHistoryList.tsx
#: src/pages/components/Operations/V2/OpenOrdersListV2.tsx
#: src/pages/components/ProfitLoss/UpdateProfitLossDialog.tsx
#: src/pages/components/TradeForm/MarketForm.tsx
#: src/pages/components/TradeOrderPriceDialog.tsx
msgid "Acceptable Price"
msgstr "Acceptable Price"

#: src/pages/Referrals/Leaderboard/index.tsx
msgid "Network error. Please optimize your network environment and try again."
msgstr "Network error. Please optimize your network environment and try again."

#: src/pages/Earn/SummaryEQU.tsx
msgid "Total Claimed"
msgstr "Total Claimed"

#: src/pages/components/ClosePositionDialog.tsx
#: src/pages/Liquidity/RemoveLiquidity.tsx
#: src/pages/Liquidity/V2/RemoveLiquidityV2.tsx
msgid "Receive"
msgstr "Receive"

#: src/components/ExecutionFee/ExecutionFeeV2.tsx
#: src/components/ExecutionFee/index.tsx
#: src/pages/Liquidity/AdjustLiquidityMargin.tsx
#: src/pages/Liquidity/LiquidityForm.tsx
#: src/pages/Liquidity/V2/AdjustLiquidityMarginV2.tsx
#: src/pages/Liquidity/V2/LiquidityFormV2.tsx
msgid "Execution Fee"
msgstr "Execution Fee"

#: src/hooks/useLoopMyPositions.ts
#: src/hooks/useLoopMyPositions.ts
msgid "Fail to open position"
msgstr "Fail to open position"

#: src/utils/formatEarnType.ts
msgid "Staking LP NFT"
msgstr ""

#: src/layouts/Footer/MobileFooter.tsx
#: src/layouts/Header/index.tsx
#: src/pages/Liquidity/LiquidityCard.tsx
#: src/pages/Liquidity/V2/LiquidityCardV2.tsx
msgid "Pools"
msgstr "Pools"

#: src/pages/components/Operations/CancelAllOrders.tsx
#: src/pages/components/Operations/V2/CancelAllOrdersV2.tsx
msgid "Cancel All"
msgstr "Cancel All"

#: src/pages/components/Operations/OpenOrdersList.tsx
#: src/pages/components/Operations/OrderHistoryList.tsx
#: src/pages/components/Operations/TradeHistoryList.tsx
#: src/pages/components/Operations/V2/OpenOrdersListV2.tsx
#: src/pages/Earn/Position/index.tsx
#: src/pages/Liquidity/V2/PassivePositionHistories.tsx
#: src/pages/Referrals/Leaderboard/MemberInvationDialog.tsx
#: src/pages/Referrals/Members/InvationDialog.tsx
#: src/pages/Referrals/Members/PositionValueDialog.tsx
msgid "Side"
msgstr "Side"

#: src/pages/Referrals/Leaderboard/MemberInvationDialog.tsx
#: src/pages/Referrals/Members/PositionValueDialog.tsx
msgid "Your referees currently have no positions yet."
msgstr "Your referees currently have no positions yet."

#: src/pages/components/AdjustMarginDialog.tsx
#: src/pages/components/ClosePositionDialog.tsx
#: src/pages/components/Computer/ComputerPnL.tsx
#: src/pages/components/Operations/PositionCells.tsx
#: src/pages/components/V2/AdjustMarginDialogV2.tsx
#: src/pages/Liquidity/AdjustLiquidityMargin.tsx
#: src/pages/Liquidity/ConfirmIncreaseLiquidity.tsx
#: src/pages/Liquidity/LiquidityComputerDialog.tsx
#: src/pages/Liquidity/LiquidityForm.tsx
#: src/pages/Liquidity/LiquidityForm.tsx
#: src/pages/Liquidity/LiquidityHistory.tsx
#: src/pages/Liquidity/LiquidityTable.tsx
#: src/pages/Liquidity/V2/AdjustLiquidityMarginV2.tsx
#: src/pages/Liquidity/V2/LiquidityFormV2.tsx
#: src/pages/Liquidity/V2/LiquidityFormV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Liquidity/V2/LiquidityTradeHistoriesV2.tsx
#: src/pages/Liquidity/V2/RemoveLiquidityV2.tsx
msgid "Margin"
msgstr "Margin"

#: src/pages/Earn/Stake/index.tsx
msgid "Unlockable In"
msgstr "Unlockable In"

#: src/pages/Earn/Stake/StakeAppTokenList.tsx
#: src/pages/Earn/Stake/StakeLpTokenList.tsx
msgid "My Staked"
msgstr "My Staked"

#: src/pages/components/Computer/ComputerEntryPrice.tsx
#: src/pages/components/V2/AdjustMarginDialogV2.tsx
#: src/pages/Earn/Liquidity/index.tsx
#: src/pages/Liquidity/LiquidityButton.tsx
#: src/pages/Liquidity/MobileActions.tsx
#: src/pages/Liquidity/V2/AdjustLiquidityMarginV2.tsx
#: src/pages/Liquidity/V2/LiquidityButtonV2.tsx
#: src/pages/Liquidity/V2/LiquidityTradeHistoriesV2.tsx
#: src/pages/Liquidity/V2/LiquidityTradeHistoriesV2.tsx
msgid "Add"
msgstr "Add"

#: src/entities/TransactionDescription.ts
msgid "Withdraw Risk Buffer Fund: Request to withdraw {poolSymbol} risk buffer fund, Amount -{marginDelta} {QUOTE_USD}"
msgstr "Withdraw Risk Buffer Fund: Request to withdraw {poolSymbol} risk buffer fund, Amount -{marginDelta} {QUOTE_USD}"

#: src/pages/Referrals/Leaderboard/index.tsx
#: src/pages/Referrals/Members/index.tsx
#: src/pages/Referrals/Members/index.tsx
msgid "Referees"
msgstr "Referees"

#: src/pages/Earn/Position/index.tsx
msgid "Maximum"
msgstr "Maximum"

#: src/entities/TransactionDescription.ts
msgid "Staking successful, you have staked {amount} {APP_TOKEN_SYMBOL}."
msgstr "Staking successful, you have staked {amount} {APP_TOKEN_SYMBOL}."

#: src/entities/TransactionDescription.ts
msgid "Minting successful, you used Connector #{connectorTokenId} to mint {0} Member NFTs."
msgstr "Minting successful, you used Connector #{connectorTokenId} to mint {0} Member NFTs."

#: src/pages/Leaderboard/Mining.tsx
#: src/pages/Leaderboard/Trade.tsx
msgid "Updated at {0}"
msgstr "Updated at {0}"

#: src/pages/components/Operations/OpenOrdersList.tsx
#: src/pages/components/Operations/OrderHistoryList.tsx
#: src/pages/components/Operations/V2/OpenOrdersListV2.tsx
msgid "Trigger Price"
msgstr "Trigger Price"

#: src/pages/components/ChartsPool.tsx
msgid "Depth"
msgstr "Depth"

#: src/pages/Liquidity/LiquidityHistory.tsx
#: src/pages/Liquidity/LiquidityHistory.tsx
#: src/pages/Liquidity/LiquidityHistory.tsx
#: src/pages/Liquidity/V2/LiquidityTradeHistoriesV2.tsx
#: src/pages/Liquidity/V2/LiquidityTradeHistoriesV2.tsx
#: src/pages/Liquidity/V2/LiquidityTradeHistoriesV2.tsx
#: src/pages/Liquidity/V2/PassivePositionHistories.tsx
#: src/pages/Liquidity/V2/PassivePositionHistories.tsx
#: src/pages/Liquidity/V2/PassivePositionHistories.tsx
msgid "No history"
msgstr "No history"

#: src/pages/Market/TradeTableV1.tsx
#: src/pages/Market/TradeTableV2.tsx
msgid "Price / Index"
msgstr "Price / Index"

#: src/pages/Referrals/Members/RegisterCodeDialog.tsx
msgid "Please input <10 chars"
msgstr "Please input <10 chars"

#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
msgid "The price decrease cannot exceed 100%"
msgstr "The price decrease cannot exceed 100%"

#: src/components/SelectPools/index.tsx
msgid "Change"
msgstr "Change"

#: src/pages/Liquidity/LiquidityTable.tsx
msgid "If LPs hold temporary positions that suffer losses, and the Risk Buffer Fund is insufficient to cover these losses, then the LPs will experience temporary losses."
msgstr "If LPs hold temporary positions that suffer losses, and the Risk Buffer Fund is insufficient to cover these losses, then the LPs will experience temporary losses."

#: src/pages/components/Computer/ComputerLiquidationPrice.tsx
#: src/pages/components/Computer/ComputerLiquidationPrice.tsx
msgid "Simulated position net value. Balance = Margin + Unrealized PnL"
msgstr "Simulated position net value. Balance = Margin + Unrealized PnL"

#: src/pages/Referrals/Connectors/MintDialog.tsx
msgid "Mint Member NFT"
msgstr "Mint Member NFT"

#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
msgid "The SL PnL cannot be more than 0."
msgstr "The SL PnL cannot be more than 0."

#: src/pages/Referrals/Traders/InputReferralCode.tsx
msgid "Enjoy the following benefits by binding"
msgstr "Enjoy the following benefits by binding"

#: src/pages/Liquidity/index.tsx
msgid "Fail to adjust margin"
msgstr "Fail to adjust margin"

#: src/pages/components/V2/LiquidityApr.tsx
msgid "Funding Fees APR"
msgstr "Funding Fees APR"

#: src/pages/components/Operations/CancelAllOrders.tsx
#: src/pages/components/Operations/V2/CancelAllOrdersV2.tsx
msgid "\"Cancel All\" will revoke all unexecuted orders at once, requiring only a one-time gas fee payment."
msgstr "\"Cancel all\" will revoke all unexecuted orders at once, requiring only a one-time gas fee payment."

#: src/components/LongShortTag/index.tsx
#: src/components/LongShortTag/index.tsx
#: src/entities/TransactionDescription.ts
#: src/pages/components/Computer/ComputerDirection.tsx
#: src/pages/components/Operations/OpenOrdersList.tsx
#: src/pages/components/Operations/OrderHistoryList.tsx
#: src/pages/components/Operations/PositionsList.tsx
#: src/pages/components/Operations/TradeHistoryList.tsx
#: src/pages/components/Operations/V2/OpenOrdersListV2.tsx
#: src/pages/components/ShareDialog.tsx
#: src/pages/components/TradeForm/ConfirmDialog.tsx
#: src/pages/components/TradeForm/TradeButtonLimit.tsx
#: src/pages/components/TradeForm/TradeButtonLimitV2.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV1.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV2.tsx
#: src/pages/Earn/Position/index.tsx
#: src/pages/Liquidity/V2/LiquidityCardV2.tsx
#: src/pages/Liquidity/V2/LiquidityPassivePositionV2.tsx
#: src/pages/Liquidity/V2/LiquidityPassivePositionV2.tsx
#: src/pages/Liquidity/V2/LiquidityPassivePositionV2.tsx
#: src/pages/Liquidity/V2/LiquidityPassivePositionV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Liquidity/V2/PassivePositionHistories.tsx
#: src/pages/Market/TradeTableV1.tsx
#: src/pages/Market/TradeTableV2.tsx
#: src/pages/Referrals/Leaderboard/MemberInvationDialog.tsx
#: src/pages/Referrals/Members/InvationDialog.tsx
#: src/pages/Referrals/Members/PositionValueDialog.tsx
#: src/pages/Trade/MobileActions.tsx
msgid "Long"
msgstr "Long"

#: src/components/SelectPools/index.tsx
#: src/pages/Earn/Liquidity/index.tsx
#: src/pages/Earn/RBFMining/index.tsx
#: src/pages/Liquidity/LiquidityTable.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Referrals/Members/LiquidityDialog.tsx
msgid "Pool"
msgstr "Pool"

#: src/pages/components/TradeForm/LimitForm.tsx
#: src/pages/components/TradeForm/MarketForm.tsx
#: src/pages/Liquidity/LiquidityForm.tsx
#: src/pages/Liquidity/V2/LiquidityFormV2.tsx
msgid "Leverage Slider"
msgstr "Leverage Slider"

#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Referrals/Leaderboard/index.tsx
msgid "Position"
msgstr "Position"

#: src/pages/components/Operations/OpenOrdersList.tsx
#: src/pages/components/Operations/V2/OpenOrdersListV2.tsx
msgid "This order has been temporarily invalidated. The order will remain in effect after a new position is opened."
msgstr "This order has been temporarily invalidated. The order will remain in effect after a new position is opened."

#: src/pages/components/ClosePositionDialog.tsx
msgid "Exceeds current position"
msgstr "Exceeds current position"

#: src/pages/Earn/Pool2Mining/Summary.tsx
#: src/pages/Earn/Stake/Summary.tsx
msgid "Avg. Multiplier"
msgstr "Avg. Multiplier"

#: src/pages/Earn/RBFMining/index.tsx
#: src/pages/Liquidity/LiquidityInfo.tsx
#: src/pages/Liquidity/RiskBufferFundDialog.tsx
msgid "Total Contribution"
msgstr "Total Contribution"

#: src/pages/components/ClosePositionDialog.tsx
msgid "The margin ratio is too high after closing"
msgstr "The margin ratio is too high after closing"

#: src/pages/components/Computer/ComputerLiquidationPrice.tsx
#: src/pages/components/Computer/index.tsx
msgid "Liquidation Price"
msgstr "Liquidation Price"

#: src/pages/Earn/Stake/ClaimStakeDialog.tsx
msgid "Choosing more rewards will consume more gas fee."
msgstr "Choosing more rewards will consume more gas fee."

#: src/pages/Referrals/Connectors/MyConnectorNFT.tsx
msgid "1. Obtain Connector NFT."
msgstr "1. Obtain Connector NFT."

#: src/pages/components/ClaimPeriods.tsx
msgid "Burn Amount"
msgstr "Burn Amount"

#: src/pages/Liquidity/index.tsx
msgid "Fail to remove liquidity"
msgstr "Fail to remove liquidity"

#: src/pages/Liquidity/Detail.tsx
#: src/pages/Liquidity/LiquidityTable.tsx
msgid "Max APR = Trading Fees Max APR + EQU Max APR"
msgstr "Max APR = Trading Fees Max APR + EQU Max APR"

#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
msgid "Increase"
msgstr "Increase"

#: src/state/setting/hooks.ts
msgid "Your transaction may be frontrun"
msgstr "Your transaction may be frontrun"

#: src/entities/TransactionDescription.ts
msgid "Cancel Partial SL: {poolSymbol}·{0}, size -{sizeDelta} {baseSymbol}, trigger price {prefix}{price}"
msgstr "Cancel Partial SL: {poolSymbol}·{0}, size -{sizeDelta} {baseSymbol}, trigger price {prefix}{price}"

#: src/pages/Referrals/Content.tsx
msgid "Traders"
msgstr "Traders"

#: src/utils/formatError.ts
msgid "Transaction was cancelled."
msgstr "Transaction was cancelled."

#: src/pages/Leaderboard/Mining.tsx
msgid "Ranking based on the quantity of EQU mined, updated every hour."
msgstr "Ranking based on the quantity of EQU mined, updated every hour."

#: src/pages/Liquidity/ConfirmIncreaseLiquidity.tsx
#: src/pages/Liquidity/LiquidityForm.tsx
msgid "Margin Ratio"
msgstr "Margin Ratio"

#: src/pages/components/AppTokenPrice.tsx
#: src/pages/Earn/SummaryEQU.tsx
msgid "Buy"
msgstr "Buy"

#: src/pages/Earn/Architect/index.tsx
#: src/pages/Earn/Architect/index.tsx
#: src/pages/Earn/Pool2Mining/Summary.tsx
#: src/pages/Earn/Stake/Summary.tsx
#: src/pages/Referrals/Connectors/MyConnectorNFT.tsx
#: src/pages/Referrals/Connectors/MyConnectorNFT.tsx
#: src/pages/Referrals/Members/index.tsx
#: src/pages/Referrals/Members/index.tsx
msgid "Total Rewards"
msgstr "Total Rewards"

#: src/pages/components/ProfitLoss/UpdateProfitLossDialog.tsx
msgid "Modify Position TP"
msgstr "Modify Position TP"

#: src/pages/Referrals/Traders/index.tsx
msgid "Depending on the market, your current opening and closing fees range from 0.02% to 0.1%."
msgstr "Depending on the market, your current opening and closing fees range from 0.02% to 0.1%."

#: src/pages/Earn/Stake/StakeAppTokenList.tsx
#: src/pages/Earn/Stake/StakeLpTokenList.tsx
msgid "End Time"
msgstr "End Time"

#: src/pages/Referrals/Leaderboard/MemberShareDialog.tsx
msgid "USDT Rewards"
msgstr "USDT Rewards"

#: src/pages/Market/Summary.tsx
msgid "Total Volume"
msgstr "Total Volume"

#: src/pages/components/DepthChart.tsx
#: src/pages/components/DepthChart.tsx
msgid "Price:"
msgstr "Price:"

#: src/pages/components/TipsDialog.tsx
msgid "TP/SL refers to the TP/SL of the entire/partial position. When the latest <0>market price</0> reaches the trigger price, the system will place a TP/SL order for the entire/partial position at the <1>market price</1>."
msgstr "TP/SL refers to the TP/SL of the entire/partial position. When the latest <0>market price</0> reaches the trigger price, the system will place a TP/SL order for the entire/partial position at the <1>market price</1>."

#: src/pages/Referrals/Members/LiquidityDialog.tsx
msgid "Liquidity Details"
msgstr "Liquidity Details"

#: src/pages/components/Operations/OrderHistoryList.tsx
msgid "Execution Time"
msgstr "Execution Time"

#: src/pages/Leaderboard/Mining.tsx
msgid "Mining Rank"
msgstr "Mining Rank"

#: src/pages/components/Operations/PositionHistoryList.tsx
#: src/pages/components/Operations/PositionHistoryList.tsx
#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
msgid "Closed PnL"
msgstr "Closed PnL"

#: src/pages/Earn/Liquidity/index.tsx
#: src/pages/Market/Summary.tsx
msgid "Total Liquidity"
msgstr "Total Liquidity"

#: src/pages/Referrals/Leaderboard/MemberInvationDialog.tsx
#: src/pages/Referrals/Members/index.tsx
#: src/pages/Referrals/Members/index.tsx
#: src/pages/Referrals/Members/InvationDialog.tsx
#: src/pages/Referrals/Members/PositionValueDialog.tsx
msgid "Total Position Value"
msgstr "Total Position Value"

#: src/pages/components/ClosePositionDialog.tsx
#: src/pages/components/Computer/index.tsx
#: src/pages/components/Operations/PositionHistoryList.tsx
#: src/pages/components/Operations/PositionsList.tsx
#: src/pages/components/Operations/PositionsList.tsx
#: src/pages/components/ProfitLoss/index.tsx
#: src/pages/components/TradeForm/MarketForm.tsx
#: src/pages/Liquidity/AdjustLiquidityMargin.tsx
#: src/pages/Liquidity/V2/LiquidityPassivePositionV2.tsx
#: src/pages/Liquidity/V2/LiquidityPassivePositionV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
msgid "Entry Price"
msgstr "Entry Price"

#: src/pages/Earn/Stake/StakeAppTokenList.tsx
#: src/pages/Earn/Stake/StakeLpTokenList.tsx
msgid "Connect your wallet before staking"
msgstr "Connect your wallet before staking"

#: src/pages/Earn/Content.tsx
#: src/utils/formatEarnType.ts
msgid "Liquidity Mining"
msgstr "Liquidity Mining"

#: src/pages/Liquidity/LiquidityCard.tsx
msgid "24h Fee Income"
msgstr "24h Fees Income"

#: src/entities/TransactionDescription.ts
msgid "Partial SL: {poolSymbol}·{0}, size -{sizeDelta} {baseSymbol}, SL trigger price {prefix}{price}"
msgstr "Partial SL: {poolSymbol}·{0}, size -{sizeDelta} {baseSymbol}, SL trigger price {prefix}{price}"

#: src/pages/Market/TradeTableV1.tsx
#: src/pages/Market/TradeTableV2.tsx
#: src/pages/Trade/Overview.tsx
msgid "24h Change"
msgstr "24h Change"

#: src/pages/Liquidity/LiquidityTable.tsx
#: src/pages/Liquidity/RemoveLiquidity.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
#: src/pages/Liquidity/V2/LiquidityTradeHistoriesV2.tsx
#: src/pages/Liquidity/V2/LiquidityTradeHistoriesV2.tsx
#: src/pages/Liquidity/V2/RemoveLiquidityV2.tsx
msgid "Remove"
msgstr "Remove"

#: src/pages/Earn/Liquidity/index.tsx
#: src/pages/Earn/Stake/Summary.tsx
#: src/pages/Liquidity/Detail.tsx
#: src/pages/Liquidity/LiquidityCard.tsx
#: src/pages/Liquidity/LiquidityComputerDialog.tsx
#: src/pages/Liquidity/LiquidityTable.tsx
#: src/pages/Liquidity/V2/LiquidityCardV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
msgid "Max APR"
msgstr "Max APR"

#: src/pages/Liquidity/LiquidityInfo.tsx
msgid "To reduce the risk of price manipulation, any liquidity exceeding the Max Effective Liquidity threshold will not further increase the pool's depth once the pool's liquidity surpasses this limit."
msgstr "To reduce the risk of price manipulation, any liquidity exceeding the Max Effective Liquidity threshold will not further increase the pool's depth once the pool's liquidity surpasses this limit."

#: src/pages/components/ClaimHistoryDialog.tsx
msgid "No claim history"
msgstr "No claim history"

#: src/layouts/Header/index.tsx
#: src/pages/Market/Links.tsx
#: src/pages/Referrals/Content.tsx
msgid "Leaderboard"
msgstr "Leaderboard"

#: src/pages/components/ProfitLoss/UpdateProfitLossDialog.tsx
msgid "Modify Position SL"
msgstr "Modify Position SL"

#: src/pages/components/ClaimPeriods.tsx
msgid "Stake Amount"
msgstr "Stake Amount"

#: src/pages/components/AcceptableEntryPriceSetting/AcceptableEntryPriceSettingDialog.tsx
#: src/pages/components/AcceptableEntryPriceSetting/AcceptableEntryPriceSettingDialog.tsx
#: src/pages/components/ImpactFeeSetting/ImpactFeeSettingDialog.tsx
msgid "Save"
msgstr "Save"

#: src/pages/components/V2/LiquidityApr.tsx
msgid "Trading Fees APR"
msgstr "Trading Fees APR"

#: src/pages/Earn/Architect/ClaimArchitectDialog.tsx
#: src/pages/Earn/ClaimAllDialog.tsx
#: src/pages/Earn/ClaimAllDialogV3.tsx
#: src/pages/Earn/Liquidity/ClaimLiquidityDialog.tsx
#: src/pages/Earn/Pool2Mining/ClaimPool2Dialog.tsx
#: src/pages/Earn/RBFMining/ClaimRBFDialog.tsx
#: src/pages/Referrals/ClaimDialog.tsx
#: src/pages/Referrals/DirectClaimDialog.tsx
msgid "Rewards Source"
msgstr "Rewards Source"

#: src/pages/components/ImpactFeeSetting/ImpactFeeSettingDialog.tsx
msgid "Acceptable Balance Impact"
msgstr "Acceptable Balance Impact"

#: src/pages/components/ShareDialog.tsx
msgid "Index Price ({QUOTE_USD})"
msgstr "Index Price (USDT)"

#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
msgid "Stop loss {stopLossSymbol}"
msgstr "Stop loss {stopLossSymbol}"

#: src/pages/components/Operations/OrderHistoryList.tsx
msgid "Status"
msgstr "Status"

#: src/pages/Earn/Stake/ClaimStakeDialog.tsx
#: src/pages/Earn/Stake/Summary.tsx
msgid "Staked EQU"
msgstr "Staked EQU"

#: src/pages/components/ShareDialog.tsx
#: src/pages/Leaderboard/ShareDialog.tsx
#: src/pages/Referrals/Leaderboard/MemberShareDialog.tsx
msgid "Save image"
msgstr "Save image"

#: src/utils/formatEarnType.ts
msgid "Liquidity Mining V2"
msgstr "Liquidity Mining V2"

#: src/pages/components/AppTokenPrice.tsx
msgid "The current price is calculated based on Uniswap {0}/{nativeSymbol} {1}% pool."
msgstr "The current price is calculated based on Uniswap {0}/{nativeSymbol} {1}% pool."

#: src/pages/Earn/Content.tsx
msgid "My Multiplier:"
msgstr "My Multiplier:"

#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
#: src/pages/Liquidity/V2/LiquidityTradeHistoriesV2.tsx
msgid "Fee Income"
msgstr "Fee Income"

#: src/hooks/useLoopMyPositions.ts
#: src/hooks/useLoopMyPositions.ts
msgid "Adjust Margin open position"
msgstr "Adjust Margin open position"

#: src/pages/Liquidity/LiquidityInfo.tsx
msgid "Risk Buffer Fund"
msgstr "Risk Buffer Fund"

#: src/entities/TransactionDescription.ts
msgid "Close Long: Request to close {poolSymbol} Long, Size -{sizeDelta} {baseSymbol}, Acceptable price: ≥{acceptablePrice}"
msgstr "Close Long: Request to close {poolSymbol} Long, Size -{sizeDelta} {baseSymbol}, Acceptable price: ≥{acceptablePrice}"

#: src/pages/Referrals/Members/LiquidityDialog.tsx
msgid "Your referees have not added liquidity yet."
msgstr "Your referees have not added liquidity yet."

#: src/pages/Liquidity/RiskBufferFundDialog.tsx
msgid "Unlocking Countdown"
msgstr "Unlocking Countdown"

#: src/layouts/Header/Drawer.tsx
#: src/layouts/Header/Setting.tsx
#: src/pages/components/AcceptableEntryPriceSetting/AcceptableEntryPriceSettingDialog.tsx
msgid "Min slippage precision is 0.01%"
msgstr "Min slippage precision is 0.01%"

#: src/pages/components/ProfitLoss/index.tsx
msgid "Entire Position"
msgstr "Entire Position"

#: src/pages/Earn/Pool2Mining/Summary.tsx
#: src/pages/Earn/Stake/Summary.tsx
msgid "My Data"
msgstr "My Data"

#: src/layouts/Header/Drawer.tsx
#: src/layouts/Header/Drawer.tsx
#: src/layouts/Header/Drawer.tsx
msgid "Language"
msgstr "Language"

#: src/pages/Liquidity/LiquidityInfo.tsx
msgid "Due to the maximum leverage being adjusted from 200x to 50x on November 14, 2023, the maximum leverage figure may be lower than the average leverage."
msgstr "Due to the maximum leverage being adjusted from 200x to 50x on November 14, 2023, the maximum leverage figure may be lower than the average leverage."

#: src/pages/components/TradeForm/MarketForm.tsx
msgid "Due to the significant Price Impact, this operation may lead to immediate liquidation of your position, causing unnecessary losses. Please reduce your size or leverage to avoid this."
msgstr "Due to the significant Price Impact, this operation may lead to immediate liquidation of your position, causing unnecessary losses. Please reduce your size or leverage to avoid this."

#: src/entities/TransactionDescription.ts
msgid "Referral code creation successful."
msgstr "Referral code creation successful."

#: src/pages/components/Computer/ComputerEntryPrice.tsx
#: src/pages/components/Computer/ComputerEntryPrice.tsx
#: src/pages/components/Computer/ComputerEntryPrice.tsx
#: src/pages/components/Computer/ComputerLiquidationPrice.tsx
#: src/pages/components/Computer/ComputerPnL.tsx
#: src/pages/components/Computer/ComputerTargetPrice.tsx
msgid "Entry price"
msgstr "Entry price"

#: src/pages/components/Operations/OrderHistoryList.tsx
msgid "Cancelled"
msgstr "Cancelled"

#: src/pages/Referrals/Connectors/MintHistory.tsx
msgid "Used NFT"
msgstr "Used NFT"

#: src/entities/TransactionDescription.ts
msgid "Cancel Position TP: {poolSymbol}·{0}, size -{sizeDelta} {baseSymbol}, trigger price {prefix}{price}"
msgstr "Cancel Position TP: {poolSymbol}·{0}, size -{sizeDelta} {baseSymbol}, trigger price {prefix}{price}"

#: src/pages/Liquidity/LiquidityInfo.tsx
msgid "Max Open Interest"
msgstr "Max Open Interest"

#: src/pages/Market/TradeTableV2.tsx
msgid "24h Volume"
msgstr "24h Volume"

#: src/pages/Liquidity/RemoveLiquidity.tsx
msgid "You are bearing temporary loss. If you remove liquidity now, this temporary loss will become a realized loss. Are you sure you want to proceed?"
msgstr "You are bearing temporary loss. If you remove liquidity now, this temporary loss will become a realized loss. Are you sure you want to proceed?"

#: src/pages/components/Operations/HistoryList.tsx
msgid "Order History"
msgstr "Order History"

#: src/pages/Earn/SummaryEQU.tsx
msgid "Total Emissions"
msgstr "Total Emissions"

#: src/components/LeverageListener/index.tsx
msgid "Leverage:"
msgstr "Leverage:"

#: src/pages/components/AdjustMarginDialog.tsx
#: src/pages/components/V2/AdjustMarginDialogV2.tsx
#: src/pages/Liquidity/AdjustLiquidityMargin.tsx
#: src/pages/Liquidity/V2/AdjustLiquidityMarginV2.tsx
msgid "Exceeds the max decrease"
msgstr "Exceeds the max decrease"

#: src/pages/components/ClosePositionDialog.tsx
msgid "The current position is in a loss state, and the loss will be deducted from the margin."
msgstr "The current position is in a loss state, and the loss will be deducted from the margin."

#: src/pages/components/ModifyTisDialog.tsx
msgid "Tips"
msgstr "Tips"

#: src/pages/components/AdjustMarginDialog.tsx
#: src/pages/components/Operations/PositionCells.tsx
#: src/pages/components/V2/AdjustMarginDialogV2.tsx
#: src/pages/Liquidity/AdjustLiquidityMargin.tsx
#: src/pages/Liquidity/LiquidityTable.tsx
#: src/pages/Liquidity/V2/AdjustLiquidityMarginV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
msgid "Adjust Margin"
msgstr "Adjust Margin"

#: src/pages/components/Computer/ComputerTargetPrice.tsx
#: src/pages/Leaderboard/Trade.tsx
msgid "PnL%"
msgstr "PnL%"

#: src/pages/components/Operations/PositionHistoryList.tsx
msgid "Closed PnL = Closed Position PnL + Cumulative Net Funding + Liquidation Fee"
msgstr "Closed PnL = Closed Position PnL + Cumulative Net Funding + Liquidation Fee"

#: src/pages/Liquidity/LiquidityCard.tsx
#: src/pages/Liquidity/V2/LiquidityCardV2.tsx
msgid "My Liquidity Only"
msgstr "My Liquidity Only"

#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
#: src/pages/Liquidity/V2/LiquidityPositionHistory.tsx
msgid "Added Margin"
msgstr "Added Margin"

#: src/entities/TransactionDescription.ts
msgid "Short: Request to open {baseSymbol} Short, Size +{sizeDelta} {baseSymbol}, Acceptable price: ≥{acceptablePrice}"
msgstr "Short: Request to open {baseSymbol} Short, Size +{sizeDelta} {baseSymbol}, Acceptable price: ≥{acceptablePrice}"

#: src/pages/components/ShareDialog.tsx
#: src/pages/Leaderboard/ShareDialog.tsx
#: src/pages/Referrals/Leaderboard/MemberShareDialog.tsx
msgid "Copy link"
msgstr "Copy link"

#: src/pages/Liquidity/Detail.tsx
#: src/pages/Liquidity/V2/LiquidityCardV2.tsx
#: src/pages/Liquidity/V2/LiquidityTableV2.tsx
msgid "Fee Income (7d)"
msgstr "Fee Income (7d)"

#: src/pages/components/Operations/OpenOrdersList.tsx
#: src/pages/components/Operations/OrderHistoryList.tsx
#: src/pages/components/Operations/V2/OpenOrdersListV2.tsx
#: src/pages/components/TradeForm/index.tsx
#: src/pages/Market/TradeTableV2.tsx
#: src/pages/Trade/MobileActions.tsx
msgid "Limit"
msgstr "Limit"

#: src/pages/Referrals/Content.tsx
#: src/utils/formatEarnType.ts
msgid "EFC Members"
msgstr "EFC Members"

#: src/pages/components/AdjustMarginDialog.tsx
#: src/pages/components/V2/AdjustMarginDialogV2.tsx
#: src/pages/Liquidity/AdjustLiquidityMargin.tsx
#: src/pages/Liquidity/LiquidityHistory.tsx
#: src/pages/Liquidity/V2/AdjustLiquidityMarginV2.tsx
#: src/pages/Liquidity/V2/LiquidityTradeHistoriesV2.tsx
msgid "Reduce Margin"
msgstr "Reduce Margin"

#: src/pages/Earn/Stake/Summary.tsx
#: src/pages/Earn/SummaryEQU.tsx
msgid "The calculated figure may slightly differ from the actual amount because the {0} in the {1}/{nativeSymbol} LP NFT is based on the {2} quantity in the LP Token at the time of staking."
msgstr "The calculated figure may slightly differ from the actual amount because the EQU in the EQU/ETH LP NFT is based on the EQU quantity in the LP Token at the time of staking."

#: src/pages/components/ClosePositionDialog.tsx
#: src/pages/components/ClosePositionDialog.tsx
#: src/pages/components/Operations/PositionCells.tsx
#: src/pages/Liquidity/LiquidityHistory.tsx
#: src/pages/Liquidity/V2/PassivePositionHistories.tsx
#: src/utils/formatTrade.ts
#: src/utils/formatTrade.ts
#: src/utils/formatTrade.ts
#: src/utils/formatTrade.ts
msgid "Close"
msgstr "Close"

#: src/pages/Referrals/Connectors/MintedDetailsDialog.tsx
#: src/pages/Referrals/Connectors/MintHistory.tsx
#: src/pages/Referrals/Connectors/MintHistory.tsx
msgid "Minted NFT"
msgstr "Minted NFT"

#: src/pages/Referrals/Traders/index.tsx
msgid "You have already bound a referral code."
msgstr "You have already bound a referral code."

#: src/pages/Referrals/Connectors/MyConnectorNFT.tsx
#: src/pages/Referrals/Connectors/MyConnectorNFT.tsx
msgid "Minted"
msgstr "Minted"

#: src/pages/components/ProfitLoss/ProfitLossForm.tsx
#: src/pages/components/TipsDialog.tsx
msgid "Due to the different triggering mechanisms for Stop Loss and liquidation (Stop Loss is triggered based on market price, while liquidation is triggered based on index price), if the Stop Loss price is set too close to the liquidation price, there is a risk that liquidation will occur before the Stop Loss is triggered or executed."
msgstr "Due to the different triggering mechanisms for Stop Loss and liquidation (Stop Loss is triggered based on market price, while liquidation is triggered based on index price), if the Stop Loss price is set too close to the liquidation price, there is a risk that liquidation will occur before the Stop Loss is triggered or executed."

#: src/pages/Earn/Architect/index.tsx
msgid "You haven't architect yet."
msgstr "You haven't architect yet."

#: src/pages/Liquidity/index.tsx
msgid "Transaction execution failed"
msgstr "Transaction execution failed"

#: src/pages/components/TradeForm/TradeButtonLimit.tsx
#: src/pages/components/TradeForm/TradeButtonLimitV2.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV1.tsx
#: src/pages/components/TradeForm/TradeButtonMarketV2.tsx
#: src/pages/Liquidity/LiquidityButton.tsx
#: src/pages/Liquidity/V2/LiquidityButtonV2.tsx
msgid "Min payment: {0} {1}"
msgstr "Min payment: {0} {1}"

#: src/pages/Earn/Pool2Mining/Summary.tsx
msgid "Staking APR ({maxMultiplier}x)"
msgstr "Staking APR ({maxMultiplier}x)"

#: src/pages/Earn/Stake/StakeAppTokenList.tsx
#: src/pages/Earn/Stake/StakeDialog.tsx
#: src/pages/Earn/Stake/StakeLpDialog.tsx
#: src/pages/Earn/Stake/StakeLpTokenList.tsx
msgid "Multiplier"
msgstr "Multiplier"

#: src/pages/Earn/Stake/index.tsx
msgid "Learn more"
msgstr "Learn more"
