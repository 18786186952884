import marketsJson from '../markets/tokens-arbitrum-goerli-v2.json';
import { arbitrumGoerli } from './connector/chains';
import { Env } from './enum';

const chainId = arbitrumGoerli.id;

export const WBTC_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.BTC.name,
	address: marketsJson.BTC.market,
	symbol: marketsJson.BTC.name,
	sort: marketsJson.BTC.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 4,
	precision: 2
};

export const WETH_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.ETH.name,
	address: marketsJson.ETH.market,
	symbol: marketsJson.ETH.name,
	sort: marketsJson.ETH.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
	positionUnits: 4,
	precision: 2
};

export const SOL_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.SOL.name,
	address: marketsJson.SOL.market,
	symbol: marketsJson.SOL.name,
	sort: marketsJson.SOL.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 2,
	precision: 2
};

export const ARB_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.ARB.name,
	address: marketsJson.ARB.market,
	symbol: marketsJson.ARB.name,
	sort: marketsJson.ARB.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 2,
	precision: 4
};

export const OP_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.OP.name,
	address: marketsJson.OP.market,
	symbol: marketsJson.OP.name,
	sort: marketsJson.OP.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 2,
	precision: 3
};

export const MATIC_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.MATIC.name,
	address: marketsJson.MATIC.market,
	symbol: marketsJson.MATIC.name,
	sort: marketsJson.MATIC.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 1,
	precision: 4
};

export const AVAX_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.AVAX.name,
	address: marketsJson.AVAX.market,
	symbol: marketsJson.AVAX.name,
	sort: marketsJson.AVAX.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 2,
	precision: 2
};

export const LINK_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.LINK.name,
	address: marketsJson.LINK.market,
	symbol: marketsJson.LINK.name,
	sort: marketsJson.LINK.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 3,
	precision: 3
};

export const DOGE_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.DOGE.name,
	address: marketsJson.DOGE.market,
	symbol: marketsJson.DOGE.name,
	sort: marketsJson.DOGE.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 1,
	precision: 5
};

export const XRP_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.XRP.name,
	address: marketsJson.XRP.market,
	symbol: marketsJson.XRP.name,
	sort: marketsJson.XRP.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 2,
	precision: 4
};

export const LTC_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.LTC.name,
	address: marketsJson.LTC.market,
	symbol: marketsJson.LTC.name,
	sort: marketsJson.LTC.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 3,
	precision: 2
};

export const ADA_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.ADA.name,
	address: marketsJson.ADA.market,
	symbol: marketsJson.ADA.name,
	sort: marketsJson.ADA.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 1,
	precision: 4
};

export const ETC_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.ETC.name,
	address: marketsJson.ETC.market,
	symbol: marketsJson.ETC.name,
	sort: marketsJson.ETC.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 2,
	precision: 2
};

export const BNB_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.BNB.name,
	address: marketsJson.BNB.market,
	symbol: marketsJson.BNB.name,
	sort: marketsJson.BNB.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 2,
	precision: 1
};

export const DOT_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.DOT.name,
	address: marketsJson.DOT.market,
	symbol: marketsJson.DOT.name,
	sort: marketsJson.DOT.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 2,
	precision: 3
};

export const ATOM_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.ATOM.name,
	address: marketsJson.ATOM.market,
	symbol: marketsJson.ATOM.name,
	sort: marketsJson.ATOM.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 2,
	precision: 3
};

export const NEAR_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.NEAR.name,
	address: marketsJson.NEAR.market,
	symbol: marketsJson.NEAR.name,
	sort: marketsJson.NEAR.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 1,
	precision: 3
};

export const UNI_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.UNI.name,
	address: marketsJson.UNI.market,
	symbol: marketsJson.UNI.name,
	sort: marketsJson.UNI.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 1,
	precision: 3
};

export const AAVE_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.AAVE.name,
	address: marketsJson.AAVE.market,
	symbol: marketsJson.AAVE.name,
	sort: marketsJson.AAVE.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 2,
	precision: 2
};

export const TOKEN_1INCH_ARBITRUM_GOERLI_V2 = {
	name: marketsJson['1INCH'].name,
	address: marketsJson['1INCH'].market,
	symbol: marketsJson['1INCH'].name,
	sort: marketsJson['1INCH'].index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 1,
	precision: 4
};

export const SUSHI_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.SUSHI.name,
	address: marketsJson.SUSHI.market,
	symbol: marketsJson.SUSHI.name,
	sort: marketsJson.SUSHI.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 1,
	precision: 3
};

export const GMX_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.GMX.name,
	address: marketsJson.GMX.market,
	symbol: marketsJson.GMX.name,
	sort: marketsJson.GMX.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 2,
	precision: 2
};

export const DYDX_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.DYDX.name,
	address: marketsJson.DYDX.market,
	symbol: marketsJson.DYDX.name,
	sort: marketsJson.DYDX.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 2,
	precision: 3
};

export const SNX_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.SNX.name,
	address: marketsJson.SNX.market,
	symbol: marketsJson.SNX.name,
	sort: marketsJson.SNX.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 2,
	precision: 3
};

export const MKR_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.MKR.name,
	address: marketsJson.MKR.market,
	symbol: marketsJson.MKR.name,
	sort: marketsJson.MKR.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 4,
	precision: 0
};

export const TIA_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.TIA.name,
	address: marketsJson.TIA.market,
	symbol: marketsJson.TIA.name,
	sort: marketsJson.TIA.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 2,
	precision: 4
};

export const INJ_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.INJ.name,
	address: marketsJson.INJ.market,
	symbol: marketsJson.INJ.name,
	sort: marketsJson.INJ.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 2,
	precision: 2
};

export const LDO_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.LDO.name,
	address: marketsJson.LDO.market,
	symbol: marketsJson.LDO.name,
	sort: marketsJson.LDO.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 1,
	precision: 3
};

export const TOKEN_1000PEPE_ARBITRUM_GOERLI_V2 = {
	name: marketsJson['1000PEPE'].name,
	address: marketsJson['1000PEPE'].market,
	symbol: marketsJson['1000PEPE'].name,
	sort: marketsJson['1000PEPE'].index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 0,
	precision: 7
};

export const STX_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.STX.name,
	address: marketsJson.STX.market,
	symbol: marketsJson.STX.name,
	sort: marketsJson.STX.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 1,
	precision: 4
};

export const RUNE_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.RUNE.name,
	address: marketsJson.RUNE.market,
	symbol: marketsJson.RUNE.name,
	sort: marketsJson.RUNE.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 1,
	precision: 3
};

export const FTM_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.FTM.name,
	address: marketsJson.FTM.market,
	symbol: marketsJson.FTM.name,
	sort: marketsJson.FTM.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 1,
	precision: 4
};

export const APT_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.APT.name,
	address: marketsJson.APT.market,
	symbol: marketsJson.APT.name,
	sort: marketsJson.APT.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 2,
	precision: 4
};

export const CRV_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.CRV.name,
	address: marketsJson.CRV.market,
	symbol: marketsJson.CRV.name,
	sort: marketsJson.CRV.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 1,
	precision: 4
};

export const SUI_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.SUI.name,
	address: marketsJson.SUI.market,
	symbol: marketsJson.SUI.name,
	sort: marketsJson.SUI.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 1,
	precision: 4
};

export const ORDI_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.ORDI.name,
	address: marketsJson.ORDI.market,
	symbol: marketsJson.ORDI.name,
	sort: marketsJson.ORDI.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 2,
	precision: 3
};

export const RDNT_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.RDNT.name,
	address: marketsJson.RDNT.market,
	symbol: marketsJson.RDNT.name,
	sort: marketsJson.RDNT.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 0,
	precision: 4
};

export const FIL_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.FIL.name,
	address: marketsJson.FIL.market,
	symbol: marketsJson.FIL.name,
	sort: marketsJson.FIL.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 2,
	precision: 3
};

export const SEI_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.SEI.name,
	address: marketsJson.SEI.market,
	symbol: marketsJson.SEI.name,
	sort: marketsJson.SEI.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 1,
	precision: 4
};

export const BLUR_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.BLUR.name,
	address: marketsJson.BLUR.market,
	symbol: marketsJson.BLUR.name,
	sort: marketsJson.BLUR.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 1,
	precision: 4
};

export const MINA_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.MINA.name,
	address: marketsJson.MINA.market,
	symbol: marketsJson.MINA.name,
	sort: marketsJson.MINA.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 1,
	precision: 4
};

export const NTRN_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.NTRN.name,
	address: marketsJson.NTRN.market,
	symbol: marketsJson.NTRN.name,
	sort: marketsJson.NTRN.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 1,
	precision: 4
};

export const TOKEN_1000BONK_ARBITRUM_GOERLI_V2 = {
	name: marketsJson['1000BONK'].name,
	address: marketsJson['1000BONK'].market,
	symbol: marketsJson['1000BONK'].name,
	sort: marketsJson['1000BONK'].index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 0,
	precision: 6
};

export const JTO_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.JTO.name,
	address: marketsJson.JTO.market,
	symbol: marketsJson.JTO.name,
	sort: marketsJson.JTO.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 1,
	precision: 4
};

export const PYTH_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.PYTH.name,
	address: marketsJson.PYTH.market,
	symbol: marketsJson.PYTH.name,
	sort: marketsJson.PYTH.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 1,
	precision: 4
};

export const ENS_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.ENS.name,
	address: marketsJson.ENS.market,
	symbol: marketsJson.ENS.name,
	sort: marketsJson.ENS.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 2,
	precision: 2
};

export const TRB_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.TRB.name,
	address: marketsJson.TRB.market,
	symbol: marketsJson.TRB.name,
	sort: marketsJson.TRB.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 3,
	precision: 2
};

export const PENDLE_ARBITRUM_GOERLI_V2 = {
	name: marketsJson.PENDLE.name,
	address: marketsJson.PENDLE.market,
	symbol: marketsJson.PENDLE.name,
	sort: marketsJson.PENDLE.index,
	chainId: chainId,
	decimals: 18,
	logoURI:
		'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
	positionUnits: 1,
	precision: 4
};

export const TOKENS_ARBITRUM_GOERLI_V2 = [
	WBTC_ARBITRUM_GOERLI_V2,
	WETH_ARBITRUM_GOERLI_V2,
	SOL_ARBITRUM_GOERLI_V2,
	ARB_ARBITRUM_GOERLI_V2,
	OP_ARBITRUM_GOERLI_V2,
	MATIC_ARBITRUM_GOERLI_V2,
	AVAX_ARBITRUM_GOERLI_V2,
	LINK_ARBITRUM_GOERLI_V2,
	DOGE_ARBITRUM_GOERLI_V2,
	XRP_ARBITRUM_GOERLI_V2,
	LTC_ARBITRUM_GOERLI_V2,
	ADA_ARBITRUM_GOERLI_V2,
	ETC_ARBITRUM_GOERLI_V2,
	BNB_ARBITRUM_GOERLI_V2,
	DOT_ARBITRUM_GOERLI_V2,
	ATOM_ARBITRUM_GOERLI_V2,
	NEAR_ARBITRUM_GOERLI_V2,
	UNI_ARBITRUM_GOERLI_V2,
	AAVE_ARBITRUM_GOERLI_V2,
	TOKEN_1INCH_ARBITRUM_GOERLI_V2,
	SUSHI_ARBITRUM_GOERLI_V2,
	GMX_ARBITRUM_GOERLI_V2,
	DYDX_ARBITRUM_GOERLI_V2,
	SNX_ARBITRUM_GOERLI_V2,
	MKR_ARBITRUM_GOERLI_V2,
	TIA_ARBITRUM_GOERLI_V2,
	INJ_ARBITRUM_GOERLI_V2,
	LDO_ARBITRUM_GOERLI_V2,
	TOKEN_1000PEPE_ARBITRUM_GOERLI_V2,
	STX_ARBITRUM_GOERLI_V2,
	RUNE_ARBITRUM_GOERLI_V2,
	FTM_ARBITRUM_GOERLI_V2,
	APT_ARBITRUM_GOERLI_V2,
	CRV_ARBITRUM_GOERLI_V2,
	SUI_ARBITRUM_GOERLI_V2,
	ORDI_ARBITRUM_GOERLI_V2,
	RDNT_ARBITRUM_GOERLI_V2,
	FIL_ARBITRUM_GOERLI_V2,
	SEI_ARBITRUM_GOERLI_V2,
	BLUR_ARBITRUM_GOERLI_V2,
	MINA_ARBITRUM_GOERLI_V2,
	NTRN_ARBITRUM_GOERLI_V2,
	TOKEN_1000BONK_ARBITRUM_GOERLI_V2,
	JTO_ARBITRUM_GOERLI_V2,
	PYTH_ARBITRUM_GOERLI_V2,
	ENS_ARBITRUM_GOERLI_V2,
	TRB_ARBITRUM_GOERLI_V2,
	PENDLE_ARBITRUM_GOERLI_V2
];
