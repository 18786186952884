import { useEffect } from 'react';

import { poolsBaseState } from 'state/pools/slice';
import { settingBaseState } from 'state/setting/slice';

import { useAppDispatch, useAppSelector } from '../hooks';
import { setLeverage } from './slice';

export default function LiquidityUpdater() {
	const dispatch = useAppDispatch();

	const { checkedLiquidityLeverage, liquidityLeverage } =
		useAppSelector(settingBaseState);
	const { poolInfo } = useAppSelector(poolsBaseState);

	useEffect(() => {
		if (checkedLiquidityLeverage) {
			dispatch(setLeverage(liquidityLeverage));
		} else {
			dispatch(setLeverage(0));
		}
	}, [checkedLiquidityLeverage, liquidityLeverage, poolInfo?.address]);

	return null;
}
