import { useEffect, useMemo, useState } from 'react';

import { useAccount } from 'wagmi';

import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';

import { useRiskBufferFundPositionQuery } from './__generated_trade__/types-and-hooks';

export function useRiskBufferFundPositionGraph(id: string) {
	const { appTimer } = useAppSelector(globalBaseState);
	const { address } = useAccount();
	const [loading, setLoading] = useState<boolean>(true);
	const {
		data,
		loading: isLoading,
		refetch
	} = useRiskBufferFundPositionQuery({
		variables: { id: id ? id.toLowerCase() : '' },
		skip: !address || !id
	});
	useEffect(() => {
		if (!isLoading) {
			setLoading(isLoading);
		}
	}, [isLoading]);
	useEffect(() => {
		refetch();
	}, [appTimer]);

	return useMemo(() => {
		if (!data || !data.riskBufferFundPosition) {
			return {
				data: undefined,
				loading,
				refetch
			};
		}
		return {
			data: data.riskBufferFundPosition,
			refetch
		};
	}, [data, loading, refetch]);
}
