import { Box } from '@mui/material';
import { Dom_Size } from 'config/constants';

import Loading from 'components/Svg/Icons/Loading';

export default function SectionLoading({
	full = false,
	inline = false
}: {
	full?: boolean;
	inline?: boolean;
}) {
	if (full) {
		return (
			<div className='flex h-full items-center justify-center'>
				<Loading size={24} />
			</div>
		);
	}
	if (inline) {
		return (
			<div className='flex justify-center'>
				<Loading size={20} />
			</div>
		);
	}
	return (
		<Box
			className={`flex items-center justify-center`}
			sx={{
				minHeight: Dom_Size.SECTION_LOADING
			}}
		>
			<Loading size={24} />
		</Box>
	);
}
