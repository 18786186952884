import React from 'react';
import { useUpdateEffect } from 'react-use';

import { useAccount } from 'wagmi';

import { usePositionRewardsGraph } from 'graphql/usePositionRewardsGraph';
import { useCheckLogin } from 'hooks/useCurrentChain';
import { globalBaseState } from 'state/global/slice';
import { useAppDispatch, useAppSelector } from 'state/hooks';

import { setIsLoadingPositionList, setPositionList } from './slice';

export default React.memo(function EarnPositionUpdater() {
	const dispatch = useAppDispatch();
	const { appTimer } = useAppSelector(globalBaseState);

	const isLogin = useCheckLogin();
	const { address } = useAccount();

	const {
		data: positionList,
		loading: isLoadingPositionList,
		refetch: refetchPositionList
	} = usePositionRewardsGraph();

	useUpdateEffect(() => {
		dispatch(setPositionList(positionList));
	}, [positionList]);

	useUpdateEffect(() => {
		dispatch(setIsLoadingPositionList(isLoadingPositionList));
	}, [isLoadingPositionList]);

	useUpdateEffect(() => {
		if (isLogin) {
			refetchPositionList();
		}
	}, [isLogin, address, appTimer]);

	return null;
});
