import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';

import { MaxUint256, Zero } from '@ethersproject/constants';
import { t } from '@lingui/macro';
import { Hash } from '@wagmi/core';
import { Transaction_Type } from 'config/constants';
import { erc20ABI, useAccount } from 'wagmi';

import { BigNumber, ethers } from 'ethers';
import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { RecordForApprove, TokenInfo } from 'types';
import { isLessThan, isPositive, shortenSymbol } from 'utils';

import { useTokenContract } from './useContract';
import { useCheckLogin } from './useCurrentChain';
import { useSendTransaction } from './useSendTransaction';

export enum ApprovalState {
	UNKNOWN = 'UNKNOWN',
	NOT_APPROVED = 'NOT_APPROVED',
	PENDING = 'PENDING',
	APPROVED = 'APPROVED'
}

export function useApproval(
	token: TokenInfo | null,
	amountToApprove: string | BigNumber,
	spender: string | undefined,
	type?: string
) {
	const { appTimer } = useAppSelector(globalBaseState);
	const { txVisibleSuccess, quoteBalance } = useAppSelector(txBaseState);

	const { address } = useAccount();

	const isLogin = useCheckLogin();

	const [currentAllowance, setCurrentAllowance] = useState<string>('');
	const [debouncedAmount, setDebouncedAmount] = useState<string>(
		amountToApprove.toString()
	);

	useDebounce(
		() => {
			setDebouncedAmount(amountToApprove.toString());
		},
		400,
		[amountToApprove]
	);

	const addressOrName = useMemo(() => {
		if (!token || !address) {
			return '';
		}
		if (token.isNative) {
			return '';
		} else {
			return token.address;
		}
	}, [token, address]);

	const contract = useTokenContract(addressOrName);

	const inputs = useMemo(() => {
		if (!spender || !address) {
			return undefined;
		}
		return [address, spender];
	}, [address, spender]);

	const fetchAllowance = useCallback(async () => {
		try {
			if (contract && inputs) {
				const data = await contract?.allowance(...inputs);
				// console.log('[useApproval] fetchAllowance result: ', data.toString());
				setCurrentAllowance(
					ethers.utils.formatUnits(data.toString(), token?.decimals)
				);
			}
		} catch (e) {
			console.log(`fetch allowance ${spender} Fail`);
		}
	}, [contract, inputs]);

	useEffect(() => {
		if (!contract || !isLogin || !inputs) {
			return;
		}
		// console.log(debouncedAmount);
		fetchAllowance();
	}, [
		contract,
		inputs,
		isLogin,
		address,
		txVisibleSuccess,
		quoteBalance,
		debouncedAmount,
		appTimer
	]);

	const {
		isConfirming: isApproving,
		isConfirmed,
		onSendTransaction,
		error
	} = useSendTransaction(
		{
			mode: 'recklesslyUnprepared',
			address: addressOrName as Hash,
			abi: erc20ABI,
			functionName: 'approve',
			args: useMemo(
				() => [spender, type === 'cancel' ? Zero : MaxUint256],
				[spender, type]
			)
		},
		undefined,
		true
	);

	useEffect(() => {
		if (isConfirmed) {
			if (type === 'cancel') {
				setCurrentAllowance(Zero.toString());
			}
			fetchAllowance();
		}
	}, [isConfirmed]);

	const approvalState = useMemo(() => {
		if (!isPositive(amountToApprove.toString()) || !spender || !token) {
			return ApprovalState.UNKNOWN;
		}
		if (token.isNative) {
			return ApprovalState.APPROVED;
		}
		if (isApproving) {
			return ApprovalState.PENDING;
		}
		if (!currentAllowance) {
			return ApprovalState.UNKNOWN;
		}

		// amountToApprove will be defined if currentAllowance is
		if (isLessThan(currentAllowance, amountToApprove.toString())) {
			return ApprovalState.NOT_APPROVED;
		} else {
			return ApprovalState.APPROVED;
		}
	}, [token, amountToApprove, currentAllowance, spender, isApproving, address]);

	const handleApproveCallback = useCallback(() => {
		const record = {
			transactionType: Transaction_Type.Approve,
			symbolIn: shortenSymbol(token)
		} as RecordForApprove;
		onSendTransaction(record, t`Approval Successful`);
	}, [token, onSendTransaction]);

	return {
		currentApproveState: approvalState,
		isApproving,
		isConfirmed,
		approveCallback: handleApproveCallback,
		currentAllowance,
		error
	};
}
