import { useEffect, useState } from 'react';

import { MARKETS_BLOCK_NUMBER_MAP } from '../config/env';
import { useApolloClients } from '../context/ApolloClientsContext';
import { useCurrentChain } from '../hooks/useCurrentChain';
import { globalBaseState } from '../state/global/slice';
import { useAppSelector } from '../state/hooks';
import { isLessThan } from '../utils';
import { useMarketsQuery } from './__generated_tradeV2__/types-and-hooks';

const useMarketGraph = () => {
	const { blockHeightBefore7d, blockHeightBefore24h } =
		useAppSelector(globalBaseState);
	const { clientTradeV2 } = useApolloClients();
	const { currentChainId } = useCurrentChain();
	const { appTimer } = useAppSelector(globalBaseState);

	const { data, refetch } = useMarketsQuery({
		variables: {
			block7d: Number(
				isLessThan(
					blockHeightBefore7d,
					MARKETS_BLOCK_NUMBER_MAP[currentChainId]
				)
					? MARKETS_BLOCK_NUMBER_MAP[currentChainId]
					: blockHeightBefore7d
			),
			block24h: Number(
				isLessThan(
					blockHeightBefore24h,
					MARKETS_BLOCK_NUMBER_MAP[currentChainId]
				)
					? MARKETS_BLOCK_NUMBER_MAP[currentChainId]
					: blockHeightBefore24h
			)
		},
		client: clientTradeV2
	});

	const [result, setResult] = useState<{
		markets: (typeof data)['markets'];
		past: (typeof data)['past'];
		dayPast: (typeof data)['dayPast'];
	}>({
		markets: [],
		past: [],
		dayPast: []
	});
	const [isInit, setIsInit] = useState(true);

	useEffect(() => {
		refetch();
	}, [appTimer]);
	useEffect(() => {
		if (!data || !data.markets || !data.past || !data.dayPast) {
			return;
		}
		setIsInit(false);
		setResult({
			markets: data.markets,
			past: data.past,
			dayPast: data.dayPast
		});
	}, [data]);

	return { data: result, isInit };
};

export default useMarketGraph;
