import deIcon from 'assets/svg/locales/de.svg';
import enIcon from 'assets/svg/locales/en.svg';
import esIcon from 'assets/svg/locales/es.svg';
import frIcon from 'assets/svg/locales/fr.svg';
import jaIcon from 'assets/svg/locales/ja.svg';
import koIcon from 'assets/svg/locales/ko.svg';
import ruIcon from 'assets/svg/locales/ru.svg';
import zhIcon from 'assets/svg/locales/zh.svg';
import map from 'lodash/map';

export const LOCALES = [
	{ key: 'en', name: 'English', icon: enIcon },
	{ key: 'ja', name: '日本語', icon: jaIcon },
	{ key: 'ko', name: '한국어', icon: koIcon },
	{ key: 'fr', name: 'Français', icon: frIcon },
	{ key: 'de', name: 'Deutsch', icon: deIcon },
	{ key: 'turkish', name: 'Türkçe', icon: frIcon },
	{ key: 'arabic', name: 'العربية', icon: frIcon },
	{ key: 'vn', name: 'Tiếng Việt', icon: frIcon },
	{ key: 'portuguese', name: 'Português', icon: frIcon },
	{ key: 'filipino', name: 'Filipino', icon: frIcon },
	{ key: 'spanish', name: 'Español', icon: frIcon },
	{ key: 'thai', name: 'ไทย', icon: frIcon },
	{ key: 'zh-HK', name: '繁体中文', icon: enIcon }
];
export const SUPPORTED_LOCALES = map(LOCALES, 'key');

export type SupportedLocale = (typeof SUPPORTED_LOCALES)[number] | 'pseudo';

export const DEFAULT_LOCALE: SupportedLocale = 'en';
