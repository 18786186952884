import { ReactNode, createContext, useMemo, useState } from 'react';

export interface IMobileActionContext {
	isVisibleForm: boolean;
	setIsVisibleForm: (value: boolean) => void;
}

export const MobileActionContext = createContext<IMobileActionContext>({
	isVisibleForm: false,
	setIsVisibleForm: (value: boolean) => void value
});

export function MobileActionsContextProvider({
	children
}: {
	children: ReactNode;
}) {
	const [isVisibleForm, setIsVisibleForm] = useState<boolean>(false);

	const value = useMemo(() => {
		return {
			isVisibleForm,
			setIsVisibleForm
		};
	}, [isVisibleForm, setIsVisibleForm]);

	return (
		<MobileActionContext.Provider value={value}>
			{children}
		</MobileActionContext.Provider>
	);
}
