import { useMemo } from 'react';

import { FeeDistributorABI } from 'config/abis';
import { Contract_Write_Mode, Transaction_Type } from 'config/constants';
import { getContractAddress } from 'config/contracts';
import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { RecordStakeAppToken } from 'types';
import { formatNumber, isGreaterThan, isPositive, parseUnits } from 'utils';

import { useComputeGasLimit } from './useComputeGasLimit';
import { useCurrentChain } from './useCurrentChain';
import { useSendTransaction } from './useSendTransaction';
import { ApprovalState } from './useApproval';

export function useSubmitStakeAppToken(
	amount: string,
	period: number,
	multiplier: number,
	currentApproveState: ApprovalState | undefined
) {
	const { appToken, appTokenBalance } = useAppSelector(txBaseState);

	const { currentChainId } = useCurrentChain();

	const { address } = useAccount();

	const { args, record, description } = useMemo(() => {
		if (
			!amount ||
			!appToken ||
			!isPositive(period) ||
			!address ||
			isGreaterThan(amount, appTokenBalance) ||
			currentApproveState !== ApprovalState.APPROVED
		) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		console.log('useSubmitStakeAppToken args begin ...');

		const args = [parseUnits(amount, appToken.decimals), address, period];

		const record = {
			transactionType: Transaction_Type.StakeAppToken,
			baseToken: appToken,
			amount,
			period,
			multiplier
		} as RecordStakeAppToken;

		const description = TransactionDescription.StakeAppToken(
			formatNumber(amount, appToken.positionUnits),
			period
		);

		return {
			args,
			record,
			description
		};
	}, [amount, appToken, address, period, multiplier, appTokenBalance, currentApproveState]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address: getContractAddress(currentChainId, 'FeeDistributor'),
			abi: FeeDistributorABI,
			functionName: 'stake',
			args
		} as UseContractWriteConfig;
	}, [currentChainId, args]);

	const { gasLimit } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirmed, isConfirming } = useSendTransaction(
		contractWriteArgs,
		gasLimit
	);

	const onConfirm = () => {
		if (!record) return;
		onSendTransaction(record, description);
	};

	return {
		onConfirm,
		isConfirming,
		isConfirmed
	};
}
