import { useMemo, useState } from 'react';
import { useDebounce, useUpdateEffect } from 'react-use';

import { Trans, t } from '@lingui/macro';
import { FormControl, FormHelperText, Typography } from '@mui/material';
import {
	MAX_PRECISION,
	REG_REFERRAL_CODE,
	Transaction_Type
} from 'config/constants';

import { useCheckCode } from 'hooks/useCheckCode';
import { useSubmitCreateReferralCode } from 'hooks/useSubmitCreateReferralCode';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import type { Merge } from 'type-fest';
import { checkInputReferralCode } from 'utils';

import Dialog, { DialogProps } from 'components/Common/Dialog';
import { CommonStyledDialogInput } from 'components/Common/Styled';
import { referralBaseState } from 'state/referrals/slice';
import { IReferralCodeItem } from 'graphql/useMembersReferralsGraph';

export default function RegisterCodeDialog(
	props: Merge<DialogProps, { tokenId: string }>
) {
	const { open, onClose, tokenId } = props;

	const { signingMap } = useAppSelector(txBaseState);
	const { allReferralCodes } = useAppSelector(referralBaseState);

	const [code, setCode] = useState<string>('');
	const [debouncedCode, setDebouncedCode] = useState<string>('');
	useDebounce(
		() => {
			setDebouncedCode(code);
		},
		400,
		[code]
	);
	const { isCodeRegistered, isLoading } = useCheckCode(debouncedCode);
	const isCheckingCode = debouncedCode !== code || isLoading;
	const isDuplicateError = code !== '' && isCodeRegistered && !isCheckingCode;

	const { onConfirm, isConfirming, isConfirmed } = useSubmitCreateReferralCode(
		tokenId,
		code
	);

	useUpdateEffect(() => {
		if (isConfirmed && onClose) {
			onClose();
		}
	}, [isConfirmed]);

	const [disabled, submitText] = useMemo(() => {
		if (!REG_REFERRAL_CODE.test(code)) {
			return [true, t`Confirm`];
		}
		if (signingMap.has(Transaction_Type.CreateReferralCode)) {
			return [true, t`Loading...`];
		}
		if (isConfirming) {
			return [true, t`Submitting...`];
		}
		if (isDuplicateError) {
			return [true, t`Code already taken.`];
		}
		if (isCheckingCode) {
			return [true, t`Checking code...`];
		}
		if (allReferralCodes && allReferralCodes.find((item: IReferralCodeItem) => item.id === code)) {
			return [true, t`Can only bind other user's referral code.`];
		}
		return [false, t`Confirm`];
	}, [
		code,
		signingMap,
		isConfirming,
		isLoading,
		isDuplicateError,
		isCheckingCode,
		allReferralCodes
	]);

	const onHandleChangeCode = (event: any) => {
		const value = event.target.value;
		if (value.trim() === '') {
			setCode('');
			return;
		}
		if (checkInputReferralCode(value)) {
			setCode(value);
		}
	};

	return (
		<Dialog
			onConfirm={onConfirm}
			open={open}
			onClose={onClose}
			title={t`Create referral code`}
			disabled={disabled}
			confirmLabel={submitText}
		>
			<FormControl className='w-full'>
				<CommonStyledDialogInput
					type='text'
					inputProps={{
						maxLength: MAX_PRECISION,
						inputMode: 'decimal'
					}}
					value={code}
					placeholder={t`Enter referral code`}
					onChange={onHandleChangeCode}
					fullWidth
				/>
				{code.length > 10 && (
					<FormHelperText className='mx-0'>
						<Typography color='text.secondary' variant='caption'>
							<Trans>Please input &lt;10 chars</Trans>
						</Typography>
					</FormHelperText>
				)}
			</FormControl>
		</Dialog>
	);
}
