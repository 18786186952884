import { useEffect, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { APP_TOKEN_SYMBOL, Transaction_Type, Version } from 'config/constants';
import { useReferralClaimRequestByTokenIds } from 'fetch/useRequest';
import { useAccount } from 'wagmi';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useSubmitClaimReferralsNew } from 'hooks/useSubmitClaimReferralsNew';
import { filter, flatMap, uniq } from 'lodash';
import EmptyClaim from 'pages/Earn/EmptyClaim';
import { Lockup_Period } from 'pages/Earn/types';
import {
	Referral_Request_Reward_Type,
	Referral_Request_Type
} from 'pages/Referrals/types';
import ClaimPeriods from 'pages/components/ClaimPeriods';
import { StyledFormControlLabel } from 'pages/components/StyledFormControlLabel';
import { useAppSelector } from 'state/hooks';
import { referralBaseState } from 'state/referrals/slice';
import { selectVersion } from 'state/setting/selector';
import { txBaseState } from 'state/tx/slice';
import type { Merge } from 'type-fest';
import {
	formatNumber,
	isGreaterThan,
	isNumeric,
	isPositive,
	plus,
	shortenSymbol
} from 'utils';

import ApproveButton from 'components/ApproveButton';
import ApproveButtonV2 from 'components/ApproveButtonV2';
import Dialog, { DialogProps } from 'components/Common/Dialog';
import { StyleCheckbox } from 'components/Common/StyleCheckbox';
import { CommonStyledDivider } from 'components/Common/Styled';
import Table from 'components/Table/StickyTable';

import { Type_Referrals } from './types';

export default function ClaimDialog(
	props: Merge<DialogProps, { type: Type_Referrals }>
) {
	const { onClose, type } = props;
	const { address } = useAccount();
	const { quoteToken, appToken, signingMap } = useAppSelector(txBaseState);
	const { membersRewardList, connectorsRewardList } =
		useAppSelector(referralBaseState);
	const theme = useTheme();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [quoteAmount, setQuoteAmount] = useState<string>('0');
	const [appAmount, setAppAmount] = useState<string>('0');
	const [selected, setSelected] = useState<string[]>([]);
	const [referralFeePools, setReferralFeePools] = useState<string[]>([]);
	const [referralEQUPoolIds, setReferralEQUPoolIds] = useState<string[]>([]);
	const [period, setPeriod] = useState<Lockup_Period>(Lockup_Period.HIGH);

	const { isMatchMobile, isMatchPad } = useAppBreakpoints();
	const currentVersion = useAppSelector(selectVersion);

	const transactionType = useMemo(() => {
		if (type === Type_Referrals.Members) {
			return currentVersion === Version.V1
				? Transaction_Type.ClaimMembers
				: Transaction_Type.ClaimMembersV2;
		}
		return currentVersion === Version.V1
			? Transaction_Type.ClaimConnectors
			: Transaction_Type.ClaimConnectorsV2;
	}, [type, currentVersion]);

	const rewardTypes =
		currentVersion === Version.V1
			? Referral_Request_Reward_Type.REFERRAL_POSITION
			: Referral_Request_Reward_Type.V2_REFERRAL_POSITION;
	const { data } = useReferralClaimRequestByTokenIds(
		address,
		selected,
		useMemo(() => [rewardTypes], [rewardTypes]),
		period,
		[
			type === Type_Referrals.Members
				? Referral_Request_Type.MEMBER
				: Referral_Request_Type.CONNECTOR
		]
	);

	const { onConfirm, isConfirmed, isConfirming } = useSubmitClaimReferralsNew(
		transactionType,
		referralFeePools,
		quoteAmount,
		data,
		selected,
		appAmount
	);

	const referralList = useMemo(() => {
		if (type === Type_Referrals.Members && Boolean(membersRewardList)) {
			return membersRewardList.filter(item => {
				return (
					isGreaterThan(item.unclaimed, 0) ||
					isGreaterThan(item.newRewardsEQU, 0)
				);
			});
		} else if (
			type === Type_Referrals.Connector &&
			Boolean(connectorsRewardList)
		) {
			return connectorsRewardList.filter(item => {
				return (
					isGreaterThan(item.unclaimed, 0) ||
					isGreaterThan(item.newRewardsEQU, 0)
				);
			});
		} else {
			return [];
		}
	}, [membersRewardList, connectorsRewardList, type]);

	const { i18n } = useLingui();
	const [disabled, submitText] = useMemo(() => {
		if (!isPositive(quoteAmount) && !isPositive(appAmount)) {
			return [true, t`Claim`];
		}
		if (!isNumeric(period)) {
			return [true, t`Claim`];
		}
		if (signingMap.has(transactionType)) {
			return [true, t`Loading...`];
		}
		if (isConfirming) {
			return [true, t`Submitting...`];
		}
		return [false, t`Claim`];
	}, [
		period,
		quoteAmount,
		appAmount,
		isLoading,
		isConfirming,
		signingMap,
		transactionType,
		i18n.locale
	]);

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = referralList.map(n => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event: React.ChangeEvent, id: string) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};

	useEffect(() => {
		if (selected && selected.length) {
			const filterMembers = filter(referralList, item =>
				selected.includes(item.id)
			);
			let unclaimedSum = '0',
				rewardsEQUSum = '0';
			const feePools = uniq(flatMap(filterMembers, 'feePools'));
			const EQUPools = uniq(flatMap(filterMembers, 'pools'));
			filterMembers.forEach(item => {
				unclaimedSum = plus(unclaimedSum, item.unclaimed);
				rewardsEQUSum = plus(rewardsEQUSum, item.newRewardsEQU);
			});
			setQuoteAmount(unclaimedSum);
			setAppAmount(rewardsEQUSum);
			setReferralFeePools(feePools);
			setReferralEQUPoolIds(EQUPools);
		} else {
			setQuoteAmount('0');
			setAppAmount('0');
			setReferralFeePools([]);
			setReferralEQUPoolIds([]);
		}
	}, [selected, referralList]);

	useUpdateEffect(() => {
		if (isConfirmed) {
			setIsLoading(false);
			onClose();
		}
	}, [isConfirmed]);

	const isSelected = (id: string) => selected.indexOf(id) !== -1;

	const columns = [
		{
			key: 'type',
			width: '32%',
			label: (
				<StyledFormControlLabel
					control={
						<StyleCheckbox
							checked={selected.length === referralList?.length}
							size='small'
							onChange={handleSelectAllClick}
							className='underLg:-ml-3'
						/>
					}
					label={
						<Typography variant='body2' color='inherit'>
							<Trans>Rewards Source</Trans>
						</Typography>
					}
				/>
			),
			align: 'left',
			format: (row: any) => {
				const isItemSelected = isSelected(row.id);
				return (
					<Box className='flex items-center sm:h-8 sm:leading-8'>
						<StyleCheckbox
							checked={isItemSelected}
							size='small'
							className='underLg:-ml-6 -ml-2.5'
							onChange={event => handleClick(event, row.id)}
						/>
						<Typography className='capitalize whitespace-nowrap'>{`${type} #${row.id}`}</Typography>
					</Box>
				);
			}
		},
		{
			key: 'unclaimed',
			width: '36%',
			label: (
				<Typography
					variant='body2'
					sx={{
						position: !isMatchMobile ? 'static' : 'absolute',
						top: '50%',
						right: '0',
						transform: !isMatchMobile ? 'translateY(-0%)' : 'translateY(-50%)'
					}}
				>{`${shortenSymbol(quoteToken)} ${t`Amount`}`}</Typography>
			),
			align: 'right',
			format: (row: any) => {
				return (
					<Typography
						variant='body2'
						className='sm:h-8 sm:leading-8'
					>{`${formatNumber(row.unclaimed)} ${shortenSymbol(
						quoteToken
					)}`}</Typography>
				);
			}
		},
		{
			key: 'newRewardsEQU',
			width: '32%',
			label: (
				<Typography
					variant='body2'
					sx={{
						position: !isMatchMobile ? 'static' : 'absolute',
						top: '50%',
						right: '0',
						transform: !isMatchMobile ? 'translateY(-0%)' : 'translateY(-50%)'
					}}
					className='mr-4 underLg:mr-0'
				>
					{APP_TOKEN_SYMBOL} <Trans>Amount</Trans>
				</Typography>
			),
			align: 'right',
			format: (row: any) => {
				return (
					<Typography
						className='flex-1 sm:h-8 sm:leading-8 mr-4 underLg:mr-0'
						variant='body2'
					>{`${formatNumber(
						row.newRewardsEQU,
						appToken.positionUnits
					)} ${APP_TOKEN_SYMBOL}`}</Typography>
				);
			}
		}
	];

	return (
		<Dialog
			width={!isMatchMobile ? 480 : '100%'}
			height={isMatchMobile ? 300 : 'auto'}
			title={t`Claim`}
			open={true}
			onClose={onClose}
		>
			{(referralList?.length === 0 || !referralList) && <EmptyClaim />}
			{referralList?.length !== 0 && referralList && (
				<div className='space-y-4'>
					<div className='-mx-4 underLg:-mx-0'>
						<Table
							columns={columns}
							rows={referralList ? referralList : []}
							cellColor={theme.custom.cardBg}
							origin={isMatchPad || isMatchMobile}
						/>
					</div>
					<CommonStyledDivider />
					{/* <div className='flex items-center'>
						<StyleCheckbox
							checked={selected.length === referralList?.length}
							size='small'
							className='-ml-2.5'
							onChange={handleSelectAllClick}
						/>
						<div className='flex items-center space-x-2'>
							<Typography color='text.secondary'>
								<Trans>{selectedCount} selected, total</Trans>
							</Typography>
							<Typography variant='body2'>{`${formatNumber(
								quoteAmount
							)} ${shortenSymbol(quoteToken)}`}</Typography>
							<Typography variant='body2'>{`${formatNumber(
								appAmount
							)} ${APP_TOKEN_SYMBOL}`}</Typography>
						</div>
					</div> */}
					<ClaimPeriods
						totalAmount={appAmount}
						quoteAmount={quoteAmount}
						period={period}
						setPeriod={setPeriod}
					/>
					<section>
						{currentVersion === Version.V2 ? (
							<ApproveButtonV2
								componentVariant='confirm'
								fullWidth
								onClick={onConfirm}
								disabled={disabled}
								isApproveRewardCollector
							>
								{submitText}
							</ApproveButtonV2>
						) : (
							<ApproveButton
								componentVariant='confirm'
								fullWidth
								onClick={onConfirm}
								disabled={disabled}
								isApproveRewardCollector
							>
								{submitText}
							</ApproveButton>
						)}
					</section>
				</div>
			)}
		</Dialog>
	);
}
