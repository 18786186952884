import { useCallback, useContext, useEffect, useMemo } from 'react';

import {
	Input_Direction,
	MAX_PRECISION,
	NATIVE_BUFFER_FOR_MAX,
	QUOTE_USD_PRECISION,
	Transaction_Type
} from 'config/constants';
import { TradeContext } from 'context/TradeContext';
import Decimal from 'decimal.js';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
	selectCurrenDirection,
	selectCurrentLimitPrice,
	selectCurrentMarginDelta,
	selectCurrentPositionAmount
} from 'state/limit/selector';
import { settingBaseState } from 'state/setting/slice';
import { setLeverage, tradeBaseState } from 'state/trade/slice';
import { txBaseState } from 'state/tx/slice';
import {
	checkInputNumberic,
	computeMaxAmount,
	div,
	isLessThan,
	isPositive,
	minus,
	multipliedBy,
	plus,
	toAmountString,
	toDecimalPlaces
} from 'utils';

import {
	setDirectionForLong,
	setDirectionForShort,
	setImpactFeeForLong,
	setImpactFeeForShort,
	setImpactFeeRateForLong,
	setImpactFeeRateForShort,
	setInitialMarginForLong,
	setInitialMarginForShort,
	setMarginDeltaForLong,
	setMarginDeltaForShort,
	setOriginTradingFeeForLong,
	setOriginTradingFeeForShort,
	setSizeForLong,
	setSizeForShort,
	setTradingFeeForLong,
	setTradingFeeForShort
} from '../state/limit/slice';
import { usePriceRangesByTolerance } from './useSlippageTolerance';
import { poolsBaseState } from 'state/pools/slice';
import { useCheckLogin } from './useCurrentChain';

export function useListenLimitAmount() {
	const dispatch = useAppDispatch();

	const { quoteToken, quoteBalance, transactionType } =
		useAppSelector(txBaseState);
	const { leverage } = useAppSelector(tradeBaseState);
	const { checkedTradeLeverage } = useAppSelector(settingBaseState);
	const { poolInfo } = useAppSelector(poolsBaseState);

	const currentMarginDelta = useAppSelector(selectCurrentMarginDelta);
	const currentPositionAmount = useAppSelector(selectCurrentPositionAmount);
	const currenDirection = useAppSelector(selectCurrenDirection);
	const currentLimitPrice = useAppSelector(selectCurrentLimitPrice);

	const { tradeType } = useContext(TradeContext);
	const isLogin = useCheckLogin();

	const { minPrice, maxPrice } = usePriceRangesByTolerance(currentLimitPrice);

	const entryPrice = useMemo(() => {
		if (transactionType === Transaction_Type.LongLimitOrder || transactionType === Transaction_Type.LongLimitOrderV2) {
			return maxPrice;
		} else {
			return minPrice;
		}
	}, [minPrice, maxPrice, transactionType]);

	useEffect(() => {
		if (checkedTradeLeverage) {
			if (!isPositive(entryPrice)) {
				return;
			}
			if (
				currenDirection === Input_Direction.In &&
				isPositive(currentMarginDelta)
			) {
				onSetValuesByMarginDelta(currentMarginDelta);
			}
			if (
				currenDirection === Input_Direction.Out &&
				isPositive(currentPositionAmount)
			) {
				onSetValuesByPositionAmount(currentPositionAmount);
			}
		} else {
			onSetValuesByNoneLeverage(currentMarginDelta, currentPositionAmount);
		}
	}, [tradeType, checkedTradeLeverage, leverage, entryPrice]);

	// 支付金额pay变化
	const onSetValuesByMarginDelta = (value: string) => {
		if (
			!poolInfo ||
			!isPositive(value) ||
			!isPositive(leverage) ||
			!isPositive(entryPrice)
		) {
			return;
		}

		// 因为：Pay = LiquidityDelta / Leverage + LiquidityDelta * TradingFeeRate
		// 所以：LiquidityDelta = Pay / (1 / Leverage + TradingFeeRate)
		const _liquidityDelta = toDecimalPlaces(
			div(value, plus(div(1, leverage), poolInfo.discountedTradingFeeRate)),
			QUOTE_USD_PRECISION
		);
		const _originLiquidityDelta = toDecimalPlaces(
			div(value, plus(div(1, leverage), poolInfo.tradingFeeRate)),
			QUOTE_USD_PRECISION
		);

		const _tradingFee = multipliedBy(_liquidityDelta, poolInfo.discountedTradingFeeRate);
		const _originTradingFee = multipliedBy(_originLiquidityDelta, poolInfo.tradingFeeRate);

		const _initialMargin = minus(value, _tradingFee);

		let _size;
		if (isPositive(_initialMargin)) {
			_size = div(_liquidityDelta, entryPrice);
			_size = toAmountString(
				toDecimalPlaces(_size, poolInfo.baseToken.decimals),
				MAX_PRECISION
			);
		} else {
			_size = '0';
		}

		if (transactionType === Transaction_Type.LongLimitOrder || transactionType === Transaction_Type.LongLimitOrderV2) {
			dispatch(setTradingFeeForLong(_tradingFee));
			dispatch(setOriginTradingFeeForLong(_originTradingFee));
			dispatch(setInitialMarginForLong(_initialMargin));
			dispatch(setSizeForLong(_size));
		} else {
			dispatch(setTradingFeeForShort(_tradingFee));
			dispatch(setOriginTradingFeeForShort(_originTradingFee));
			dispatch(setInitialMarginForShort(_initialMargin));
			dispatch(setSizeForShort(_size));
		}
	};

	// 仓位数量变化
	const onSetValuesByPositionAmount = (size: string) => {
		if (
			!poolInfo ||
			!quoteToken ||
			!isPositive(size) ||
			!isPositive(leverage) ||
			!isPositive(entryPrice)
		) {
			return;
		}

		const _liquidityDelta = multipliedBy(size, entryPrice);

		const _initialMargin = div(_liquidityDelta, leverage);

		const _tradingFee = multipliedBy(_liquidityDelta, poolInfo.discountedTradingFeeRate);
		const _originTradingFee = multipliedBy(_liquidityDelta, poolInfo.tradingFeeRate);

		const _marginDelta = plus(_initialMargin, _tradingFee);

		const marginDelta = toDecimalPlaces(
			_marginDelta,
			quoteToken.decimals,
			Decimal.ROUND_CEIL
		);

		if (transactionType === Transaction_Type.LongLimitOrder || transactionType === Transaction_Type.LongLimitOrderV2) {
			dispatch(setTradingFeeForLong(_tradingFee));
			dispatch(setOriginTradingFeeForLong(_originTradingFee));
			dispatch(setInitialMarginForLong(_initialMargin));
			dispatch(setMarginDeltaForLong(marginDelta));
		} else {
			dispatch(setTradingFeeForShort(_tradingFee));
			dispatch(setOriginTradingFeeForShort(_originTradingFee));
			dispatch(setInitialMarginForShort(_initialMargin));
			dispatch(setMarginDeltaForShort(marginDelta));
		}
	};

	const onReset = useCallback(() => {
		if (transactionType === Transaction_Type.LongLimitOrder || transactionType === Transaction_Type.LongLimitOrderV2) {
			dispatch(setMarginDeltaForLong(''));
			dispatch(setInitialMarginForLong(''));
			dispatch(setSizeForLong(''));
		} else {
			dispatch(setMarginDeltaForShort(''));
			dispatch(setInitialMarginForShort(''));
			dispatch(setSizeForShort(''));
		}
	}, [transactionType]);

	const hasMaxButton = useMemo(() => {
		if (!quoteToken || !isPositive(quoteBalance) || !isLogin) {
			return false;
		}
		if (
			quoteToken.isNative &&
			isLessThan(quoteBalance, NATIVE_BUFFER_FOR_MAX)
		) {
			return false;
		}
		return true;
	}, [quoteToken, quoteBalance, isLogin]);

	const onHandleChangeMarginDelta = (event: any) => {
		const value = event.target.value;

		if (value.trim() === '') {
			if (transactionType === Transaction_Type.LongLimitOrder || transactionType === Transaction_Type.LongLimitOrderV2) {
				dispatch(setMarginDeltaForLong(''));
				dispatch(setInitialMarginForLong(''));
				if (checkedTradeLeverage) {
					dispatch(setSizeForLong(''));
				}
			} else {
				dispatch(setMarginDeltaForShort(''));
				dispatch(setInitialMarginForShort(''));
				if (checkedTradeLeverage) {
					dispatch(setSizeForShort(''));
				}
			}
			if (!checkedTradeLeverage) {
				dispatch(setLeverage(0));
			}
			return;
		}
		if (checkInputNumberic(value, quoteToken?.decimals)) {
			if (transactionType === Transaction_Type.LongLimitOrder || transactionType === Transaction_Type.LongLimitOrderV2) {
				dispatch(setMarginDeltaForLong(value));
				dispatch(setDirectionForLong(Input_Direction.In));
			} else {
				dispatch(setMarginDeltaForShort(value));
				dispatch(setDirectionForShort(Input_Direction.In));
			}

			if (isPositive(entryPrice)) {
				if (checkedTradeLeverage) {
					onSetValuesByMarginDelta(value);
				} else {
					onSetValuesByNoneLeverage(value, currentPositionAmount);
				}
			}
		}
	};

	const onHandleSetMaxMarginDelta = () => {
		if (isPositive(quoteBalance)) {
			const value = computeMaxAmount(quoteBalance, quoteToken);
			if (transactionType === Transaction_Type.LongLimitOrder || transactionType === Transaction_Type.LongLimitOrderV2) {
				dispatch(setMarginDeltaForLong(value));
				dispatch(setDirectionForLong(Input_Direction.In));
			} else {
				dispatch(setMarginDeltaForShort(value));
				dispatch(setDirectionForShort(Input_Direction.In));
			}

			if (isPositive(entryPrice)) {
				if (checkedTradeLeverage) {
					onSetValuesByMarginDelta(value);
				} else {
					onSetValuesByNoneLeverage(value, currentPositionAmount);
				}
			}
		}
	};

	// change size
	const onHandleChangePositionAmount = (event: any) => {
		const value = event.target.value;
		if (value.trim() === '') {
			if (transactionType === Transaction_Type.LongLimitOrder || transactionType === Transaction_Type.LongLimitOrderV2) {
				dispatch(setSizeForLong(''));
				if (checkedTradeLeverage) {
					dispatch(setMarginDeltaForLong(''));
					dispatch(setInitialMarginForLong(''));
				}
			} else {
				dispatch(setSizeForShort(''));
				if (checkedTradeLeverage) {
					dispatch(setMarginDeltaForShort(''));
					dispatch(setInitialMarginForShort(''));
				}
			}
			if (!checkedTradeLeverage) {
				dispatch(setLeverage(0));
			}
			return;
		}
		if (checkInputNumberic(value, poolInfo?.baseToken?.decimals)) {
			if (transactionType === Transaction_Type.LongLimitOrder || transactionType === Transaction_Type.LongLimitOrderV2) {
				dispatch(setSizeForLong(value));
				dispatch(setDirectionForLong(Input_Direction.Out));
			} else {
				dispatch(setSizeForShort(value));
				dispatch(setDirectionForShort(Input_Direction.Out));
			}

			if (isPositive(entryPrice)) {
				if (checkedTradeLeverage) {
					onSetValuesByPositionAmount(value);
				} else {
					onSetValuesByNoneLeverage(currentMarginDelta, value);
				}
			}
		}
	};

	// 不锁定杠杆
	const onSetValuesByNoneLeverage = (
		quoteAmount: string,
		positionAmount: string
	) => {
		if (
			!poolInfo ||
			!transactionType ||
			!isPositive(quoteAmount) ||
			!isPositive(positionAmount) ||
			!isPositive(entryPrice)
		) {
			if (transactionType === Transaction_Type.LongLimitOrder || transactionType === Transaction_Type.LongLimitOrderV2) {
				dispatch(setImpactFeeForLong(''));
				dispatch(setImpactFeeRateForLong(''));
				dispatch(setTradingFeeForLong(''));
				dispatch(setInitialMarginForLong(''));
			} else {
				dispatch(setImpactFeeForShort(''));
				dispatch(setImpactFeeRateForShort(''));
				dispatch(setTradingFeeForShort(''));
				dispatch(setInitialMarginForShort(''));
			}

			dispatch(setLeverage(0));
			return;
		}

		const _liquidityDelta = multipliedBy(entryPrice, positionAmount);

		const _tradingFee = multipliedBy(_liquidityDelta, poolInfo.discountedTradingFeeRate);
		const _originTradingFee = multipliedBy(_liquidityDelta, poolInfo.tradingFeeRate);

		const _initialMargin = minus(quoteAmount, _tradingFee);

		const _leverage = div(_liquidityDelta, _initialMargin);

		dispatch(setLeverage(_leverage));
		if (transactionType === Transaction_Type.LongLimitOrder || transactionType === Transaction_Type.LongLimitOrderV2) {
			dispatch(setDirectionForLong(Input_Direction.In));
			dispatch(setOriginTradingFeeForLong(_originTradingFee));
			dispatch(setMarginDeltaForLong(quoteAmount));
			dispatch(setTradingFeeForLong(_tradingFee));
			dispatch(setInitialMarginForLong(_initialMargin));
			dispatch(setSizeForLong(positionAmount));
		} else {
			dispatch(setDirectionForShort(Input_Direction.In));
			dispatch(setMarginDeltaForShort(quoteAmount));
			dispatch(setTradingFeeForShort(_tradingFee));
			dispatch(setOriginTradingFeeForShort(_originTradingFee));
			dispatch(setInitialMarginForShort(_initialMargin));
			dispatch(setSizeForShort(positionAmount));
		}
	};

	return {
		hasMaxButton,
		onReset,
		onHandleChangeMarginDelta,
		onHandleSetMaxMarginDelta,
		onHandleChangePositionAmount
	};
}
