import { useTheme } from '@mui/material';

interface SVGProps {
	size?: number;
	fill?: string;
}

function Filter({ size = 14, fill }: SVGProps) {
	const theme = useTheme();
	const color = fill ? fill : theme.custom.reverseColor;
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
			<path d="M8 3.16797H14.6667" stroke={color} strokeOpacity="1" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M4.99992 14.1667L2.33325 11.5" stroke={color} strokeOpacity="1" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M5 2.83203V14.1654" stroke={color} strokeOpacity="1" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M8 6.5H13.3333" stroke={color} strokeOpacity="1" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M8 9.83203H12" stroke={color} strokeOpacity="1" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M8 13.168H10.6667" stroke={color} strokeOpacity="1" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	);
}

export default Filter;
