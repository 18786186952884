import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '..';

export enum ApplicationModal {
	WALLET = 0
}

export interface ApplicationState {
	readonly openModal: ApplicationModal | null;
}

const initialState: ApplicationState = {
	openModal: null
};

const applicationSlice = createSlice({
	name: 'application',
	initialState,
	reducers: {
		setOpenModal(state, action) {
			state.openModal = action.payload;
		}
	}
});

export const { setOpenModal } = applicationSlice.actions;

export const applicationBaseState = (state: RootState) => state.application;

export default applicationSlice.reducer;
