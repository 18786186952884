import React, { useMemo, useState } from 'react';

import { Trans } from '@lingui/macro';
import { Box, IconButton, Paper, Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import {
	DEFAULT_QUOTE_PRECISION,
	MAX_AMOUNT_FORMATTER_LIMIT,
	QUOTE_USD,
	QUOTE_USD_PRECISION,
	Version
} from 'config/constants';
import Decimal from 'decimal.js';
import { useAccount } from 'wagmi';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { useWalletModalToggle } from 'state/application/hooks';
import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { settingBaseState } from 'state/setting/slice';
import {
	amountFormatter,
	catchFn,
	div,
	formatLeverage,
	formatUnits,
	minus,
	parseUnits,
	plus,
	shortenSymbolNative,
	toFormattedPercent,
	toPercent,
	toQuoteAmount
} from 'utils';

import Cell from 'components/Common/Cell';
import { CommonStyledDivider } from 'components/Common/Styled';
import Tooltip from 'components/Common/Tooltip';
import SectionLoading from 'components/SectionLoading';
import Stronger from 'components/Stronger';
import Contributor from 'components/Svg/Icons/Contributor';

import TippingUnderline from '../../components/TippingUnderline';
import { LiquidityPositionUtil } from '../../entities/LiquidityPositionUtil';
import { useMultiTokens } from '../../fetch/useRequest';
import RiskBufferFundDialog from './RiskBufferFundDialog';

export default React.memo(function LiquidityInfo({
	className
}: {
	className?: string;
}) {
	const theme = useTheme();
	const { poolInfo } = useAppSelector(poolsBaseState);
	const { currentVersion } = useAppSelector(settingBaseState);
	const [riskBufferFundDialogIsOpen, setRiskBufferFundDialogIsOpen] =
		useState<boolean>(false);
	const { address } = useAccount();
	const { currentChainId } = useCurrentChain();
	const { isMatchMobile } = useAppBreakpoints();
	const onToggleWalletModal = useWalletModalToggle();
	const { tokensMultiPrice = { tokens: [] } } = useMultiTokens([
		poolInfo?.tokenId as string
	]);
	const indexPriceX96 = tokensMultiPrice.tokens[0]?.index_price_x96;

	const globalUnrealizedPnl = useMemo(() => {
		if (!poolInfo || !indexPriceX96) {
			return '0';
		}
		const _globalUnrealizedPnl = catchFn(() => {
			return LiquidityPositionUtil.calculateGlobalUnrealizedPnl(
				poolInfo.globalLiquidityPosition.side,
				parseUnits(
					plus(
						poolInfo.globalLiquidityPosition.netSize,
						poolInfo.globalLiquidityPosition.liquidationBufferNetSize
					),
					poolInfo.baseToken.decimals
				),
				poolInfo.globalLiquidityPosition.entryPriceX96,
				indexPriceX96
			);
		}, '');

		return div(_globalUnrealizedPnl, Decimal.pow(10, DEFAULT_QUOTE_PRECISION));
	}, [poolInfo, indexPriceX96]);

	const realizedPnL = useMemo(() => {
		if (poolInfo?.globalRiskBufferFund) {
			return minus(
				poolInfo.globalRiskBufferFund.riskBufferFund,
				poolInfo.globalRiskBufferFund.liquidity
			);
		}
		return '0';
	}, [poolInfo?.globalRiskBufferFund]);

	const onCloseRiskBufferFundDialog = () => {
		setRiskBufferFundDialogIsOpen(false);
	};

	return (
		<>
			<Paper className={classNames('p-4 sm:p-0', className)}>
				{!isMatchMobile && (
					<>
						<Typography variant='h6'>
							<Trans>
								About{' '}
								{`${shortenSymbolNative(
									poolInfo?.baseToken,
									currentChainId
								)}/${QUOTE_USD}`}{' '}
								Pool
							</Trans>
						</Typography>
						<CommonStyledDivider />
					</>
				)}
				{poolInfo ? (
					<div>
						{currentVersion === Version.V1 && (
							<>
								<Cell
									label={<Trans>Max Leverage</Trans>}
									value={formatLeverage(
										poolInfo.maxLeveragePerLiquidityPosition
									)}
								/>
								<Cell
									label={
										<Stronger
											value={<Trans>Average Leverage</Trans>}
											tooltip={
												<Typography
													variant='body2'
													color={theme.custom.orangeColor}
												>
													<Trans>
														Due to the maximum leverage being adjusted from 200x
														to 50x on November 14, 2023, the maximum leverage
														figure may be lower than the average leverage.
													</Trans>
												</Typography>
											}
										/>
									}
									value={formatLeverage(
										div(
											poolInfo.globalLiquidityPosition.liquidity,
											poolInfo.globalLiquidityPosition.margin
										)
									)}
								/>
								<Cell
									label={<Trans>Balance Rate</Trans>}
									value={toFormattedPercent(poolInfo.balanceRate)}
								/>
								<Cell
									label={
										<Box className='flex items-center'>
											<TippingUnderline
												tooltip={
													<Trans>
														The Risk Buffer Fund will bear all of the temporary
														losses first before impacting Liquidity Providers
														(LPs). If its balance becomes negative, this
														indicates that the LPs are facing temporary losses.
													</Trans>
												}
												label={<Trans>Risk Buffer Fund</Trans>}
											/>
										</Box>
									}
									value={
										<Box className='flex items-center'>
											<Stronger
												value={`${toQuoteAmount(
													plus(
														poolInfo?.globalRiskBufferFund?.riskBufferFund,
														globalUnrealizedPnl
													)
												)} ${QUOTE_USD}`}
												tooltip={
													<div>
														<Cell
															label={<Trans>Realized PnL</Trans>}
															value={`${toQuoteAmount(
																realizedPnL
															)} ${QUOTE_USD}`}
														/>
														<Cell
															label={<Trans>Unrealized PnL</Trans>}
															value={`${toQuoteAmount(
																globalUnrealizedPnl
															)} ${QUOTE_USD}`}
														/>
														<Cell
															label={<Trans>Total Contribution</Trans>}
															value={`${toQuoteAmount(
																poolInfo?.globalRiskBufferFund?.liquidity
															)} ${QUOTE_USD}`}
														/>
													</div>
												}
											/>

											{isMatchMobile ? (
												<IconButton
													className='-mr-1 p-1 mb-1'
													onClick={() =>
														address
															? setRiskBufferFundDialogIsOpen(true)
															: onToggleWalletModal()
													}
												>
													<Contributor color={theme.custom.reverseColor} />
												</IconButton>
											) : (
												<Tooltip
													title={
														<Typography>
															<Trans>Withdraw</Trans> RBF
														</Typography>
													}
												>
													<IconButton
														className='-mr-1 p-1 mb-1'
														onClick={() =>
															address
																? setRiskBufferFundDialogIsOpen(true)
																: onToggleWalletModal()
														}
													>
														<Contributor color={theme.custom.reverseColor} />
													</IconButton>
												</Tooltip>
											)}
										</Box>
									}
								/>
							</>
						)}
						{currentVersion === Version.V2 && (
							<>
								<Cell
									label={<Trans>Balance Rate</Trans>}
									value={toFormattedPercent(poolInfo.balanceRate)}
								/>
								<Cell
									label={<Trans>Average Leverage</Trans>}
									value={formatLeverage(
										poolInfo.globalLiquidityPosition.avgLeverage
									)}
								/>
								<Cell
									label={
										<TippingUnderline
											label={<Trans>Max Effective Liquidity</Trans>}
											tooltip={
												<Trans>
													To reduce the risk of price manipulation, any
													liquidity exceeding the Max Effective Liquidity
													threshold will not further increase the pool&apos;s
													depth once the pool&apos;s liquidity surpasses this
													limit.
												</Trans>
											}
										/>
									}
									value={amountFormatter(
										formatUnits(
											poolInfo.maxPriceImpactLiquidity,
											QUOTE_USD_PRECISION
										)
									)}
								/>
								<Cell
									label={
										<TippingUnderline
											label={<Trans>Max Open Interest</Trans>}
											tooltip={
												<Trans>
													To reduce the risk of price manipulation, there is a
													limit on the open interest of the pool.
												</Trans>
											}
										/>
									}
									value={
										<>
											{amountFormatter(
												poolInfo.globalPosition.maxSize,
												2,
												poolInfo.baseToken.positionUnits
											)}
											&nbsp;
											{shortenSymbolNative(poolInfo.baseToken, currentChainId)}
										</>
									}
								/>
							</>
						)}
					</div>
				) : (
					<SectionLoading />
				)}
			</Paper>
			{riskBufferFundDialogIsOpen && (
				<RiskBufferFundDialog
					onClose={onCloseRiskBufferFundDialog}
					realizedPnL={realizedPnL}
					unrealizedPnL={globalUnrealizedPnl}
					totalContribution={poolInfo.globalRiskBufferFund.liquidity}
					poolAddress={poolInfo.id}
					baseToken={poolInfo.baseToken}
				/>
			)}
		</>
	);
});
