import React, { useEffect, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans, t } from '@lingui/macro';
import { Card, CardContent, Typography, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import {
	MAX_PRECISION,
	QUOTE_USD,
	Transaction_Type,
	Version
} from 'config/constants';
import Decimal from 'decimal.js';

import { LiquidityPositionUtil } from 'entities/LiquidityPositionUtil';
import { useCurrentChain } from 'hooks/useCurrentChain';
import useSubmitAdjustMarginLiquidity from 'hooks/useSubmitAdjustMarginLiquidityV2';
import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { txBaseState } from 'state/tx/slice';
import {
	abs,
	amountFormatter,
	catchFn,
	checkInputNumberic,
	div,
	formatLeverage,
	formatNumber,
	isEqualTo,
	isGreaterThan,
	isGreaterThanOrEqual,
	isLessThan,
	isLessThanOrEqualTo,
	isNegative,
	isPositive,
	minus,
	multipliedBy,
	neg,
	plus,
	shortenSymbol,
	shortenSymbolNative,
	toAmountString,
	toDecimalPlaces,
	toPercent,
	toQuoteAmount,
	toUsd
} from 'utils';
import { calcRiskRateColor } from 'utils/riskRate';

import ApproveButton from 'components/ApproveButtonV2';
import Cell from 'components/Common/Cell';
import Dialog from 'components/Common/Dialog';
import {
	CommonStyledBaseInput,
	CommonStyledMaxButton
} from 'components/Common/Styled';
import Button from 'components/Common/StyledButton';
import Leverage from 'components/Leverage';
import ToNext from 'components/ToNext';

import LiquidityPassivePositionV2 from './LiquidityPassivePositionV2';

const AdjustLiquidityMargin = ({
	currentPosition,
	onClose
}: {
	currentPosition: Record<string, any>;
	onClose: () => void;
}) => {
	const { quoteBalance, signingMap, quoteToken } = useAppSelector(txBaseState);
	const { poolInfo, liquidityPools } = useAppSelector(poolsBaseState);
	const currentVersion = useAppSelector(state => state.setting.currentVersion);
	const liquidityPool = useMemo(() => {
		const targetPoolAddress = poolInfo?.address.toLowerCase();
		return liquidityPools.find(item => item.address === targetPoolAddress);
	}, [poolInfo, liquidityPools]);

	const [transactionType, setTransactionType] = useState(
		Transaction_Type.IncreaseMarginLiquidityV2
	);
	const [value, setValue] = useState<string>('');

	const [nextMargin, nextRealizeProfit] = useMemo(() => {
		if (!isPositive(value)) {
			return ['', ''];
		}

		const _marginNet = plus(
			currentPosition.margin,
			currentPosition?.liquidityPassivePosition?.pnl
		);

		if (transactionType === Transaction_Type.IncreaseMarginLiquidityV2) {
			return [plus(_marginNet, value), ''];
		}

		if (isGreaterThan(value, _marginNet)) {
			return ['', ''];
		}

		return [minus(_marginNet, value), '0'];
	}, [transactionType, currentPosition, value]);

	const marginWithProfit = useMemo(() => {
		return plus(
			currentPosition.margin,
			currentPosition?.liquidityPassivePosition?.pnl
		);
	}, [currentPosition]);

	const targetLeverage = useMemo(() => {
		if (!isPositive(value)) {
			return '';
		}
		if (transactionType === Transaction_Type.IncreaseMarginLiquidityV2) {
			return div(currentPosition.liquidity, plus(marginWithProfit, value));
		} else {
			if (isGreaterThan(value, marginWithProfit)) {
				return '';
			}
			return div(currentPosition.liquidity, minus(marginWithProfit, value));
		}
	}, [transactionType, marginWithProfit, value]);

	const nextNetMargin = useMemo(() => {
		if (!isPositive(value)) {
			return '';
		}
		if (transactionType === Transaction_Type.IncreaseMarginLiquidityV2) {
			return plus(currentPosition.marginNet, value);
		}

		if (isGreaterThan(value, currentPosition.marginNet)) {
			return '';
		}
		return minus(currentPosition.marginNet, value);
	}, [transactionType, currentPosition.marginNet, value]);

	const nextRiskRate = useMemo(() => {
		if (!isPositive(nextNetMargin)) {
			return '';
		}
		const _riskRate = catchFn(() => {
			return LiquidityPositionUtil.calcRiskRate(
				currentPosition.unrealizedLoss,
				currentPosition.liquidationExecutionFee,
				nextNetMargin,
				currentPosition.maxRiskRatePerLiquidityPosition
			);
		}, '');
		return _riskRate;
	}, [currentPosition, nextNetMargin]);

	const { currentChainId, currentChain } = useCurrentChain();

	const { onConfirm, isConfirmed, isConfirming, setTxSuccessCallback } =
		useSubmitAdjustMarginLiquidity(
			currentPosition.address,
			currentPosition.id,
			value,
			transactionType,
			currentPosition.baseToken
		);

	const maxDecreaseMargin = useMemo(() => {
		const passivePosition = currentPosition?.liquidityPassivePosition;
		const maintenanceMargin = LiquidityPositionUtil.calculateMaintenanceMargin(
			currentPosition?.myLiquidity,
			liquidityPool.liquidationFeeRatePerLiquidityPosition,
			liquidityPool.liquidationExecutionFee
		);
		const _maintainFeeMaxMargin = isGreaterThanOrEqual(passivePosition?.pnl, 0)
			? minus(
					plus(
						currentPosition.margin,
						multipliedBy(abs(passivePosition?.pnl), '0.95')
					),
					maintenanceMargin
			  )
			: minus(
					minus(currentPosition.margin, maintenanceMargin),
					div(
						abs(currentPosition.passivePosition?.pnl),
						'0.95' //currentPosition.maxRiskRatePerLiquidityPosition
					)
			  );

		const maintainFeeMaxMargin = isNegative(_maintainFeeMaxMargin)
			? '0'
			: _maintainFeeMaxMargin;
		const _maintainLeverageMaxMargin = isGreaterThanOrEqual(
			passivePosition?.pnl,
			0
		)
			? minus(
					plus(
						currentPosition.margin,
						multipliedBy(abs(passivePosition?.pnl), '0.95')
					),
					div(
						currentPosition.myLiquidity,
						liquidityPool.maxLeveragePerLiquidityPosition
					)
			  )
			: minus(
					minus(currentPosition.margin, div(abs(passivePosition?.pnl), '0.95')),
					div(
						currentPosition.myLiquidity,
						liquidityPool.maxLeveragePerLiquidityPosition
					)
			  );

		const maintainLeverageMaxMargin = isNegative(_maintainLeverageMaxMargin)
			? '0'
			: _maintainLeverageMaxMargin;

		return isLessThan(maintainFeeMaxMargin, maintainLeverageMaxMargin)
			? maintainFeeMaxMargin
			: maintainLeverageMaxMargin;
	}, [currentPosition, liquidityPool]);

	const { positionMinExecutionFee, nativePriceUsd } =
		useAppSelector(globalBaseState);

	const disabled = useMemo(() => {
		if (signingMap.get(transactionType)) {
			return true;
		}
		if (isConfirming) {
			return true;
		}
		if (!isPositive(value)) {
			return true;
		}
		if (
			transactionType === Transaction_Type.IncreaseMarginLiquidityV2 &&
			isGreaterThan(value, quoteBalance)
		) {
			return true;
		}

		if (
			transactionType === Transaction_Type.ReduceMarginLiquidityV2 &&
			isGreaterThan(value, maxDecreaseMargin)
		) {
			return true;
		}
	}, [value, transactionType, maxDecreaseMargin, signingMap, isConfirming]);

	const submitText = useMemo(() => {
		if (signingMap.get(transactionType) || isConfirming) {
			return <Trans>Loading...</Trans>;
		}
		if (isPositive(value)) {
			if (transactionType === Transaction_Type.IncreaseMarginLiquidityV2) {
				if (isGreaterThan(value, quoteBalance)) {
					return <Trans>Exceeds the max increase</Trans>;
				}
			}
			if (transactionType === Transaction_Type.ReduceMarginLiquidityV2) {
				if (isGreaterThan(value, maxDecreaseMargin)) {
					return <Trans>Exceeds the max decrease</Trans>;
				}
			}
		}
		if (transactionType === Transaction_Type.IncreaseMarginLiquidityV2) {
			return <Trans>Add</Trans>;
		}
		if (transactionType === Transaction_Type.ReduceMarginLiquidityV2) {
			return <Trans>Reduce</Trans>;
		}
	}, [value, transactionType, maxDecreaseMargin, signingMap, isConfirming]);

	const onHandleChangeTab = (value: typeof transactionType) =>
		setTransactionType(value);
	const onChange: React.ChangeEventHandler<HTMLInputElement> = e => {
		if (!checkInputNumberic(e.target.value, quoteToken?.decimals)) {
			return;
		}
		setValue(e.target.value);
	};
	const onDialogConfirm = () => {
		onConfirm();
	};
	const onHandleSetMaxAmount = () => {
		if (transactionType === Transaction_Type.IncreaseMarginLiquidityV2) {
			setValue(quoteBalance);
		} else {
			setValue(
				toAmountString(toDecimalPlaces(maxDecreaseMargin, 6), MAX_PRECISION)
			);
		}
	};

	useEffect(() => {
		setTxSuccessCallback(() => onClose);
	}, [isConfirmed]);

	useUpdateEffect(() => {
		setValue('');
	}, [transactionType]);

	const lightenRatio = useMemo(() => {
		if (isPositive(value) && !isGreaterThan(value, currentPosition.margin)) {
			const ratio = div(value, currentPosition.margin);
			return ratio;
		} else {
			return '0';
		}
	}, [value, currentPosition.margin]);

	const nextData = useMemo(() => {
		if (
			isPositive(value) &&
			currentPosition?.liquidityPassivePosition?.size &&
			(transactionType === Transaction_Type.IncreaseMarginLiquidityV2 ||
				(transactionType === Transaction_Type.ReduceMarginLiquidityV2 &&
					isLessThanOrEqualTo(value, maxDecreaseMargin)))
		) {
			const passivePosition = currentPosition.liquidityPassivePosition;
			const afterMargin =
				transactionType === Transaction_Type.IncreaseMarginLiquidityV2
					? plus(marginWithProfit, value)
					: minus(marginWithProfit, value);
			const afterliquidity = currentPosition.myLiquidity;
			const afterGlobalLiquidity = currentPosition?.globalLiquidity;
			//Same size after Add/Reduce margin
			const afterSize = passivePosition?.size;
			//结算后杠杆
			const afterLeverage = div(afterliquidity, afterMargin);
			//结算后已实现盈亏
			let afterPassivePositionPnl = '0';
			// 结算后预计收到的结算金额
			let receiveAmount = '';

			receiveAmount = multipliedBy(
				lightenRatio,
				currentPosition.liquidityPassivePosition?.pnl
			);
			afterPassivePositionPnl = plus(
				currentPosition.pnl,
				currentPosition.liquidityPassivePosition?.pnl
			);

			const maintenanceMargin =
				LiquidityPositionUtil.calculateMaintenanceMargin(
					afterliquidity,
					liquidityPool?.liquidationFeeRatePerLiquidityPosition,
					liquidityPool?.liquidationExecutionFee
				);

			const afterLiqPrice = LiquidityPositionUtil.calculateLiqPrice(
				maintenanceMargin,
				afterliquidity,
				afterGlobalLiquidity,
				plus(
					currentPosition?.globalLiquidityPosition?.liquidationBufferNetSize,
					currentPosition?.globalLiquidityPosition?.netSize
				),
				currentPosition?.globalLiquidityPosition.side,
				afterMargin,
				currentPosition?.liquidityPassivePosition?.indexPrice
			);

			const afterEntryPrice =
				currentPosition.liquidityPassivePosition.indexPrice ||
				liquidityPool.indexPrice;

			return {
				receiveAmount,
				afterliquidity,
				afterMargin,
				afterSize,
				afterLeverage,
				afterPassivePositionPnl,
				maintenanceMargin,
				afterLiqPrice,
				afterEntryPrice
			};
		} else {
			return {
				receiveAmount: '',
				afterliquidity: '0',
				afterMargin: '0',
				afterSize: '0',
				afterLeverage: '0',
				afterPassivePositionPnl: '0',
				maintenanceMargin: '',
				afterLiqPrice: '',
				afterEntryPrice: ''
			};
		}
	}, [
		lightenRatio,
		value,
		currentPosition,
		currentPosition.liquidityPassivePosition?.pnl,
		transactionType,
		liquidityPool,
		maxDecreaseMargin,
		marginWithProfit
	]);

	const isValidValue = useMemo(() => {
		if (transactionType === Transaction_Type.IncreaseMarginLiquidityV2) {
			return true;
		} else {
			return isPositive(value) && isLessThanOrEqualTo(value, maxDecreaseMargin);
		}
	}, [transactionType, value, maxDecreaseMargin]);

	return (
		<Dialog open onClose={onClose} title={t`Adjust Margin`}>
			<div className='flex'>
				<div className='relative z-9 flex-1'>
					<Button
						fullWidth
						componentVariant={
							transactionType === Transaction_Type.IncreaseMarginLiquidityV2
								? 'mainOutlined'
								: 'outlined'
						}
						onClick={() =>
							onHandleChangeTab(Transaction_Type.IncreaseMarginLiquidityV2)
						}
					>
						<Trans>Add Margin</Trans>
					</Button>
				</div>
				<div className='relative z-9 flex-1'>
					<Button
						fullWidth
						componentVariant={
							transactionType === Transaction_Type.ReduceMarginLiquidityV2
								? 'mainOutlined'
								: 'outlined'
						}
						onClick={() =>
							onHandleChangeTab(Transaction_Type.ReduceMarginLiquidityV2)
						}
					>
						<Trans>Reduce Margin</Trans>
					</Button>
				</div>
			</div>
			<Card className='mt-4'>
				<CardContent>
					<Cell
						label={
							<Typography variant='body1'>
								<Trans>Amount</Trans>
							</Typography>
						}
						prefix={<Trans>Max:</Trans>}
						value={`${
							transactionType === Transaction_Type.IncreaseMarginLiquidityV2
								? formatNumber(quoteBalance)
								: formatNumber(maxDecreaseMargin)
						} ${shortenSymbol(quoteToken)}`}
					/>

					<CommonStyledBaseInput
						type='text'
						inputProps={{ maxLength: MAX_PRECISION, inputMode: 'decimal' }}
						className='input'
						value={value}
						placeholder='0.0'
						onChange={onChange}
						fullWidth
						endAdornment={
							<div className='flex items-center space-x-2'>
								<CommonStyledMaxButton
									variant='outlined'
									color='secondary'
									onClick={() => onHandleSetMaxAmount()}
								>
									<Trans>Max</Trans>
								</CommonStyledMaxButton>
							</div>
						}
					/>
				</CardContent>
			</Card>
			<Typography className='mt-4'>
				{`${shortenSymbolNative(
					currentPosition.baseToken,
					currentChainId
				)}/${QUOTE_USD}`}
			</Typography>
			<div className='mt-3 space-y-1'>
				<Cell
					label={
						<Typography variant='body2'>
							<Trans>Risk Rate</Trans>
						</Typography>
					}
					value={
						<ToNext
							value={
								<Typography
									variant='body2'
									color={
										calcRiskRateColor(Number(currentPosition?.riskRatio))
											.mainColor
									}
								>
									{toPercent(currentPosition.riskRatio)}
								</Typography>
							}
							nextValue={
								isPositive(nextRiskRate) && (
									<Typography
										variant='body2'
										color={calcRiskRateColor(Number(nextRiskRate)).mainColor}
									>
										{toPercent(nextRiskRate)}
									</Typography>
								)
							}
							hasNext={isPositive(nextRiskRate)}
						/>
					}
				/>
				<Cell
					label={
						<Typography variant='body2'>
							<Trans>Margin</Trans>
						</Typography>
					}
					value={
						<ToNext
							value={toQuoteAmount(currentPosition.margin)}
							nextValue={toQuoteAmount(nextMargin)}
							hasNext={isValidValue && isPositive(nextMargin)}
						/>
					}
					unit={shortenSymbol(quoteToken)}
				/>
				<Cell
					label={
						<Typography variant='body2'>
							<Trans>Leverage</Trans>
						</Typography>
					}
					value={
						<ToNext
							value={
								<Leverage
									value={currentPosition.leverage as string}
									margin={currentPosition.marginNet}
								/>
							}
							nextValue={formatLeverage(targetLeverage)}
							hasNext={isValidValue && isPositive(targetLeverage)}
						/>
					}
				/>

				<Cell
					label={
						<Typography variant='body2'>
							<Trans>Liquidity</Trans>
						</Typography>
					}
					value={
						<ToNext
							value={toQuoteAmount(currentPosition.liquidity)}
							hasNext={false}
						/>
					}
				/>
			</div>
			<LiquidityPassivePositionV2
				liquidityPool={liquidityPool}
				liquidityValue={value}
				nextData={nextData}
				exceeds={
					(transactionType === Transaction_Type.IncreaseMarginLiquidityV2 &&
						isGreaterThan(value, quoteBalance)) ||
					(transactionType === Transaction_Type.ReduceMarginLiquidityV2 &&
						isGreaterThan(value, maxDecreaseMargin)) ||
					transactionType === Transaction_Type.IncreaseMarginLiquidityV2 ||
					transactionType === Transaction_Type.ReduceMarginLiquidityV2
				}
				nextMargin={nextMargin}
				isAdjustMargin
			></LiquidityPassivePositionV2>
			<div className='mt-4'>
				<Cell
					label={
						<Typography variant='body2' color='text.primary'>
							<Trans>Execution Fee</Trans>
						</Typography>
					}
					value={
						<div className='flex space-x-1'>
							<Typography variant='body2'>
								{`${formatNumber(neg(positionMinExecutionFee), 5)} ${
									currentChain.nativeCurrency.symbol
								}`}
							</Typography>
							<Typography variant='body2' color='secondary'>
								({toUsd(multipliedBy(nativePriceUsd, -positionMinExecutionFee))}
								)
							</Typography>
						</div>
					}
				/>
			</div>
			<div className='mt-4'>
				<ApproveButton
					componentVariant='confirm'
					fullWidth
					onClick={onDialogConfirm}
					disabled={disabled}
					amountToApprove={value}
					isApproveToken={
						transactionType === Transaction_Type.IncreaseMarginLiquidityV2
					}
					isApprovePositionRouter
				>
					{submitText}
				</ApproveButton>
			</div>
		</Dialog>
	);
};

export default AdjustLiquidityMargin;
