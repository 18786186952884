import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import {
	Popover,
	PopoverProps,
	Tab,
	TabProps,
	Tabs,
	TabsProps,
	Typography,
	styled,
	useMediaQuery,
	useTheme
} from '@mui/material';
import classNames from 'classnames';
import {
	Dom_Size,
	MAX_AMOUNT_FORMATTER_LIMIT,
	Page,
	Version
} from 'config/constants';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCurrentChain } from 'hooks/useCurrentChain';
import FundingRate from 'pages/components/FundingRate';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { selectCurrentPool } from 'state/pools/selector';
import {
	poolsBaseState,
	setPoolAddress,
	setV2PoolAddress
} from 'state/pools/slice';
import { selectVersion } from 'state/setting/selector';
import { IPoolItem } from 'types';
import {
	amountFormatter,
	isLessThan,
	multipliedBy,
	shortenSymbolNative,
	toQuoteAmount,
	toUsd
} from 'utils';

import Cell from 'components/Common/Cell';
import { CommonStyledArticle } from 'components/Common/Styled';
import FundingFeeTimer from 'components/FundingFeeTimer';
import PriceChange, { StyledPriceDirection } from 'components/PriceChange';
import SelectPools from 'components/SelectPools';

import Tooltip from '../../components/Common/Tooltip';
import TippingUnderline from '../../components/TippingUnderline';

export const StyledTabs = styled(Tabs)<TabsProps>(() => ({
	'& .MuiSvgIcon-root': {
		fontSize: 20
	},
	'& .MuiTabs-indicator': {
		display: 'none'
	}
}));

export const StyledTab = styled(Tab)<TabProps>(({ theme }) => {
	const { isMatchMobile, isMatchPad } = useAppBreakpoints();
	const isMatch2XL = useMediaQuery(`(min-width: ${Dom_Size.PAGE_CONTAINER}px)`);
	return {
		padding: 0,
		cursor: 'inherit',
		marginRight: isMatchMobile ? 0 : isMatchPad ? 28 : !isMatch2XL ? 32 : 48,
		textAlign: 'left',
		textTransform: 'unset',
		color: theme.palette.text.primary
	};
});

export const StyledPopover = styled(Popover)<PopoverProps>(({ theme }) => ({
	'& .MuiPopover-paper': {
		backgroundColor: theme.palette.background.paper,
		border: `1px solid ${theme.palette.divider}`,
		borderRadius: 0
	}
}));

export default function Overview({
	price,
	priceChange,
	indexPrice,
	positions,
	fundingRate
}: {
	price: string | undefined;
	priceChange: string | undefined;
	indexPrice: string | number;
	positions: string;
	fundingRate: string;
}) {
	const dispatch = useAppDispatch();

	const { poolAddress, pools, poolInfo } = useAppSelector(poolsBaseState);
	const currentPool = useAppSelector(selectCurrentPool);
	const currentVersion = useAppSelector(selectVersion);

	const theme = useTheme();
	const { currentChainId } = useCurrentChain();
	const navigator = useNavigate();

	const positionsUsd = useMemo(() => {
		if (!price) {
			return '-';
		}
		return multipliedBy(price, positions);
	}, [positions, price]);

	const { isMatchMobile, isUpMd, isDownLg, isUpXl } = useAppBreakpoints();
	const isUp1380 = useMediaQuery(`(min-width: 1380px)`);
	const isUp1280 = useMediaQuery(`(min-width: 1280px)`);

	const onSelect = (pool: IPoolItem) => {
		navigator(
			`${currentVersion === Version.V1 ? Page.TradeV1 : Page.Trade}/${pool.id}`
		);
		if (currentVersion === Version.V1) {
			dispatch(setPoolAddress(pool.id));
		}
		if (currentVersion === Version.V2) {
			dispatch(setV2PoolAddress(pool.id));
		}
	};

	return (
		<div>
			{!isMatchMobile ? (
				<CommonStyledArticle
					className={classNames(
						'flex items-center py-4 pl-2 pr-4',
						isUp1280
							? 'space-x-12'
							: isUpXl
							? 'space-x-8'
							: 'space-x-10 md:space-x-6'
					)}
				>
					<section className='flex items-center'>
						<SelectPools pool={currentPool} onSelect={onSelect} />
					</section>
					<div className='sm:text-left sm:flex-1 sm:w-full'>
						<StyledPriceDirection
							variant='h4'
							fontWeight={500}
							align='left'
							className={`${isMatchMobile ? 'text-left' : 'text-center'} ${
								pools.find(pool => pool.id === poolAddress)
									?.priceChangeDirection
							}`}
						>
							{price
								? toQuoteAmount(price, poolInfo?.baseToken?.precision)
								: '-'}
						</StyledPriceDirection>
						<div className='sm:block hidden'>
							<PriceChange value={priceChange} size={14}></PriceChange>
						</div>
					</div>
					<div className='sm:w-full'>
						<Typography
							variant='body2'
							align={isMatchMobile ? 'right' : 'left'}
							color='text.secondary'
						>
							<Trans>Index Price</Trans>
						</Typography>
						<Typography
							variant='body1'
							align={isMatchMobile ? 'right' : 'left'}
						>
							{toQuoteAmount(indexPrice, poolInfo?.baseToken?.precision)}
						</Typography>
					</div>
					<div>
						<Typography variant='body2' color='text.secondary'>
							<Trans>24h Change</Trans>
						</Typography>
						<PriceChange value={priceChange} size={14}></PriceChange>
					</div>
					<div className='sm:block w-[138px] md:w-[120px]'>
						<Typography
							variant='body2'
							color='text.secondary'
							align={isMatchMobile ? 'right' : 'left'}
						>
							<TippingUnderline
								tooltip={
									<div className='space-y-2'>
										<Typography variant='body2'>
											<Trans>
												Funding fees are settled every hour. When the funding
												rate is positive, long positions pay short positions;
												when it&#39;s negative, short positions pay long
												positions.
											</Trans>
										</Typography>
									</div>
								}
								label={<Trans>1h Funding</Trans>}
							/>
						</Typography>
						<div className='flex items-center sm:flex-col sm:items-end'>
							<FundingRate value={fundingRate} />
							<FundingFeeTimer />
						</div>
					</div>
					{((isUpMd && isDownLg) || isUpXl) && (
						<div className='sm:w-full'>
							<Typography
								variant='body2'
								color='text.secondary'
								align={isMatchMobile ? 'right' : 'left'}
							>
								<Trans>Open Interest</Trans>
							</Typography>
							<div className='flex flex-wrap items-center space-x-1 sm:flex-col sm:items-end'>
								<Typography variant='body1' color='text.primary'>
									{`
										${amountFormatter(positions)} 
										${shortenSymbolNative(poolInfo?.baseToken, currentChainId)}
									`}
								</Typography>
								{isUp1380 && (
									<Typography variant='body1' color='text.secondary'>
										({toUsd(positionsUsd)})
									</Typography>
								)}
							</div>
						</div>
					)}
				</CommonStyledArticle>
			) : (
				<CommonStyledArticle className='flex justify-between px-3 py-3'>
					<section className='flex-1 space-y-3'>
						<SelectPools pool={currentPool} onSelect={onSelect} />
						<div className='pl-1 space-y-0.5'>
							<StyledPriceDirection
								variant='h6'
								align='left'
								className={`leading-none ${
									pools.find(pool => pool.id === poolAddress)
										?.priceChangeDirection
								}`}
							>
								{price
									? toQuoteAmount(price, poolInfo?.baseToken?.precision)
									: '-'}
							</StyledPriceDirection>
							<PriceChange value={priceChange} size={14}></PriceChange>
						</div>
					</section>
					<section className='flex flex-col justify-end'>
						<Cell
							label={<Trans>Index Price</Trans>}
							value={
								<Typography variant='body1'>
									{toQuoteAmount(indexPrice, poolInfo?.baseToken?.precision)}
								</Typography>
							}
						/>
						<div className='flex justify-between space-x-3'>
							<Typography variant='body2' color='secondary'>
								<Tooltip
									title={
										<div className='space-y-2'>
											<Typography variant='body2'>
												<Trans>
													Funding fees are settled every hour. When the funding
													rate is positive, long positions pay short positions;
													when it&#39;s negative, short positions pay long
													positions.
												</Trans>
											</Typography>
										</div>
									}
								>
									<Typography
										className='inline-block leading-tight cursor-default underline decoration-dashed underline-offset-4'
										component='div'
										variant='body2'
									>
										<Trans>1h Funding</Trans>
									</Typography>
								</Tooltip>
							</Typography>
							<div className='flex'>
								<FundingRate value={fundingRate} />
								<div className='flex justify-end -mr-2'>
									<FundingFeeTimer />
								</div>
							</div>
						</div>
						<div className='flex justify-between'>
							<Typography variant='body1' color='text.secondary'>
								<Trans>Open Interest</Trans>
							</Typography>
							<div className='text-right'>
								<Typography variant='body1' color='text.primary'>
									{`${amountFormatter(positions)}`}
									{isLessThan(positions, MAX_AMOUNT_FORMATTER_LIMIT) &&
										shortenSymbolNative(poolInfo?.baseToken, currentChainId)}
								</Typography>
								<Typography variant='body1' color='text.secondary'>
									({toUsd(positionsUsd)})
								</Typography>
							</div>
						</div>
					</section>
				</CommonStyledArticle>
			)}
		</div>
	);
}
