import { useMemo } from 'react';

import { Trans } from '@lingui/macro';
import { Typography, alpha, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import classNames from 'classnames';

import Stronger from 'components/Stronger';

import {
	isGreaterThanOrEqual,
	isLessThan,
	isZero,
	toPercent
} from '../../utils';
import { calcRiskRateColor } from '../../utils/riskRate';

const RiskRate = ({
	risk = 0,
	size = 'normal',
	tooltip,
	noText = false
}: {
	risk: number | string;
	size?: 'small' | 'normal';
	tooltip?: React.ReactNode;
	noText?: boolean;
}) => {
	const { lightCount, mainColor } = calcRiskRateColor(Number(risk));
	const theme = useTheme();
	const formattedRisk = useMemo(() => {
		if (isZero(risk)) {
			return '0.00%';
		}
		if (isLessThan(risk, 0) || isGreaterThanOrEqual(risk, 1)) {
			return '>99.99%';
		}
		if (isGreaterThanOrEqual(risk, 0) && isLessThan(risk, 0.0001)) {
			return '<0.01%';
		}
		return toPercent(risk);
	}, [risk]);

	const grey = alpha(theme.custom.reverseColor, 0.2);

	return (
		<div className='flex items-center space-x-1'>
			<div className='flex items-center space-x-0.5'>
				{new Array(4).fill(1).map((_, index) => (
					<Box
						key={index}
						sx={{
							width: '3px',
							height: '12px',
							borderRadius: '2px',
							background: lightCount > index ? mainColor : grey
						}}
					/>
				))}
			</div>
			{!noText && (
				<Stronger
					value={
						<Typography
							className={classNames('leading-none', {
								'scale-90': size === 'small'
							})}
							variant='body2'
							sx={{
								color: 'inherit'
							}}
						>
							{formattedRisk}
						</Typography>
					}
					tooltip={
						tooltip ? (
							<Typography variant='body2'>{tooltip}</Typography>
						) : (
							void 0
						)
					}
				/>
			)}
		</div>
	);
};

export default RiskRate;
