import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Trans } from '@lingui/macro';
import {
	Box,
	Card,
	CardContent,
	Divider,
	Typography,
	useTheme
} from '@mui/material';
import cn from 'classnames';
import {
	LEVERAGE_MAKERS,
	MARK_POINTS,
	MAX_PRECISION,
	QUOTE_USD
} from 'config/constants';

import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import { globalBaseState } from 'state/global/slice';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
	liquidityBaseState,
	setIsPureAdd,
	setLiquidityAmount,
	setQuoteAmount
} from 'state/liquidity/slice';
import { poolsBaseState } from 'state/pools/slice';
import {
	setCheckedLiquidityLeverage,
	setLiquidityLeverage,
	settingBaseState
} from 'state/setting/slice';
import { selectQuoteBalanceText } from 'state/tx/selector';
import { txBaseState } from 'state/tx/slice';
import {
	computeLeverageMarks,
	div,
	formatLeverage,
	formatNumber,
	isPositive,
	multipliedBy,
	plus,
	shortenSymbol,
	toPercent,
	toQuoteAmount,
	toUsd
} from 'utils';

import Cell from 'components/Common/Cell';
import { StyleCheckbox } from 'components/Common/StyleCheckbox';
import {
	CommonStyledArticle,
	CommonStyledBaseInput,
	CommonStyledMaxButton
} from 'components/Common/Styled';
import LeverageListener from 'components/LeverageListener';
import MarksSlider from 'components/MarksSlider';
import TippingUnderline from 'components/TippingUnderline';
import ToNext from 'components/ToNext';

import LiquidityButtonV2 from './LiquidityButtonV2';
import LiquidityPassivePositionV2 from './LiquidityPassivePositionV2';
import { useListenLiquidityAmountV2 } from './hooks/useListenLiquidityAmountV2';

export default React.memo(function LiquidityFormV2() {
	const dispatch = useAppDispatch();
	const { quoteToken } = useAppSelector(txBaseState);
	const { positionMinExecutionFee, nativePriceUsd } =
		useAppSelector(globalBaseState);
	const { quoteAmount, liquidityAmount, leverage, isPureAdd } =
		useAppSelector(liquidityBaseState);

	const { checkedLiquidityLeverage } = useAppSelector(settingBaseState);
	const { poolInfo, liquidityPools } = useAppSelector(poolsBaseState);
	const quoteBalanceText = useAppSelector(selectQuoteBalanceText);
	const { currentChain } = useCurrentChain();
	const theme = useTheme();

	const liquidityPool = useMemo(() => {
		const targetPoolAddress = poolInfo?.address.toLowerCase();
		return liquidityPools.find(item => item.address === targetPoolAddress);
	}, [poolInfo, liquidityPools]);

	const isLogin = useCheckLogin();
	const leverageMarks = useMemo(() => {
		if (!poolInfo) {
			return LEVERAGE_MAKERS;
		}
		return computeLeverageMarks(
			Number(poolInfo.maxLeveragePerLiquidityPosition)
		);
	}, [poolInfo]);

	const {
		hasMaxButton,
		onHandleChangeMarginDelta,
		onHandleChangeLiquidityAmount,
		onHandleSetMaxMarginDelta
	} = useListenLiquidityAmountV2();

	const onHandlePureAddChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		dispatch(setIsPureAdd(event.target.checked));
		dispatch(setQuoteAmount(''));
		dispatch(setLiquidityAmount(''));
		if (event.target.checked) {
			dispatch(setCheckedLiquidityLeverage(false));
		} else {
			dispatch(setCheckedLiquidityLeverage(true));
		}
	};

	const onChangeLeverage = (value: number | number[]) => {
		dispatch(setLiquidityLeverage(value));
	};

	const onClickLabel = (value: number) => {
		dispatch(setLiquidityLeverage(value));
	};

	const onHandleChangeCheckedLeverage = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			dispatch(setCheckedLiquidityLeverage(event.target.checked));
		},
		[]
	);

	const [activeLeverage, setActiveLeverage] = useState<boolean>(false);

	const onChangeLeverageInput = useCallback((value: string) => {
		dispatch(setLiquidityLeverage(value));
	}, []);

	useEffect(() => {
		if (!isPositive(liquidityPool?.liquidityPositions.length) && isPureAdd) {
			dispatch(setIsPureAdd(false));
			dispatch(setCheckedLiquidityLeverage(true));
		}
	}, [liquidityPool?.liquidityPositions]);

	return (
		<CommonStyledArticle className='space-y-3'>
			<div className='space-y-3'>
				<Card
					className={cn(`card-content-input`, {
						'dark-masked': isPureAdd && theme.mode === 'dark',
						'light-masked': isPureAdd && theme.mode === 'light'
					})}
				>
					<CardContent className='relative z-1'>
						<div className='flex justify-between py-1'>
							<Typography variant='body1' color='secondary'>
								<Trans>Margin</Trans>
							</Typography>
							{isLogin && (
								<div className='flex item-baseline space-x-1'>
									<Typography variant='body2' color='secondary'>
										<Trans>Balance:</Trans>
									</Typography>
									<Typography variant='body2'>{quoteBalanceText}</Typography>
								</div>
							)}
						</div>

						<CommonStyledBaseInput
							type='text'
							inputProps={{ maxLength: MAX_PRECISION, inputMode: 'decimal' }}
							className='input'
							value={quoteAmount}
							placeholder='0.0'
							onChange={onHandleChangeMarginDelta}
							fullWidth
							endAdornment={
								<div className='flex items-center space-x-2'>
									{hasMaxButton && (
										<CommonStyledMaxButton
											variant='outlined'
											color='secondary'
											onClick={() => onHandleSetMaxMarginDelta()}
										>
											<Trans>Max</Trans>
										</CommonStyledMaxButton>
									)}
									<Typography className='font-normal' variant='h5'>
										{shortenSymbol(quoteToken)}
									</Typography>
								</div>
							}
							sx={{
								'& .MuiInputBase-input': {
									fontSize: 20
								}
							}}
						/>
					</CardContent>
				</Card>
				<Card>
					<CardContent>
						<div className='flex justify-between'>
							<Typography variant='body1' color='secondary'>
								<Trans>Liquidity</Trans>
							</Typography>
							{checkedLiquidityLeverage && (
								<LeverageListener
									active={activeLeverage}
									leverage={leverage}
									maxLeverage={poolInfo?.maxLeveragePerLiquidityPosition}
									onChangeLeverage={onChangeLeverageInput}
									onToggleActive={(value: boolean) => {
										setActiveLeverage(value);
									}}
								/>
							)}
						</div>
						<CommonStyledBaseInput
							type='text'
							inputProps={{ maxLength: MAX_PRECISION, inputMode: 'decimal' }}
							className='input'
							value={liquidityAmount}
							placeholder='0.0'
							onChange={onHandleChangeLiquidityAmount}
							fullWidth
							endAdornment={
								<Typography className='font-normal' variant='h5'>
									{QUOTE_USD}
								</Typography>
							}
							sx={{
								'& .MuiInputBase-input': {
									fontSize: 20
								}
							}}
						/>
					</CardContent>
				</Card>
			</div>
			{!isPureAdd && (
				<div>
					<div className={checkedLiquidityLeverage ? '-mb-1' : '-mb-2'}>
						<Cell
							label={<Trans>Leverage Slider</Trans>}
							value={
								<StyleCheckbox
									checked={checkedLiquidityLeverage}
									size='small'
									className='-mr-1.5'
									onChange={onHandleChangeCheckedLeverage}
									disabled={isPureAdd}
								/>
							}
						/>
					</div>
					{poolInfo && checkedLiquidityLeverage && (
						<MarksSlider
							leverage={leverage}
							marks={leverageMarks}
							max={Number(poolInfo.maxLeveragePerLiquidityPosition)}
							onChangeLeverage={onChangeLeverage}
							onClickLabel={onClickLabel}
						/>
					)}
				</div>
			)}

			<div>
				{!checkedLiquidityLeverage && (
					<Cell
						label={<Trans>Leverage</Trans>}
						value={
							<ToNext
								value={
									isPositive(liquidityPool?.liquidityPositions.length) &&
									liquidityPool &&
									Boolean(liquidityAmount) ? (
										formatLeverage(
											liquidityPool?.liquidityPositions[0]?.leverage
										)
									) : isPositive(liquidityAmount) ? (
										formatLeverage(leverage)
									) : (
										<Typography variant='body1' fontWeight={500}>
											-
										</Typography>
									)
								}
								nextValue={formatLeverage(leverage)}
								hasNext={
									isPositive(liquidityPool?.liquidityPositions.length) &&
									liquidityPool?.myLiquidity &&
									Boolean(liquidityAmount)
								}
							/>
						}
					/>
				)}
			</div>
			<div>
				<Cell
					label={
						<Typography variant='body2'>
							<TippingUnderline
								tooltip={
									<Trans>
										When adding more liquidity, you can choose the
										&quot;Addition-Only&quot; mode. The Addition-Only mode means
										only adding liquidity while keeping the margin unchanged.
										The leverage of the liquidity position will change
										accordingly after the addition.
									</Trans>
								}
								label={<Trans>Addition-Only</Trans>}
							/>
						</Typography>
					}
					value={
						<StyleCheckbox
							checked={isPureAdd}
							onChange={onHandlePureAddChange}
							size='small'
							className='-mr-2'
							disabled={
								liquidityPool &&
								!isPositive(liquidityPool?.liquidityPositions.length)
							}
						/>
					}
				/>
			</div>
			<Divider sx={{ borderStyle: 'dashed' }} className='my-2' />
			<div>
				<Cell
					label={<Trans>Liquidity</Trans>}
					value={
						<ToNext
							value={
								isPositive(liquidityPool?.liquidityPositions.length) &&
								liquidityPool &&
								Boolean(liquidityAmount)
									? toQuoteAmount(liquidityPool?.myLiquidity)
									: toQuoteAmount(liquidityAmount)
							}
							nextValue={`${toQuoteAmount(
								plus(liquidityPool?.myLiquidity || '0', liquidityAmount)
							)}`}
							hasNext={
								isPositive(liquidityPool?.liquidityPositions.length) &&
								liquidityPool?.myLiquidity &&
								Boolean(liquidityAmount)
							}
						/>
					}
				/>
				<Cell
					label={
						<Typography variant='body2'>
							<TippingUnderline
								tooltip={
									<Trans>
										When adding more liquidity, unrealized PnL on existing
										liquidity positions will be settled to margin. Therefore,
										the updated Margin = Current Position Margin + Added Margin
										+ Current Position&#39;s Unrealized PnL
									</Trans>
								}
								label={<Trans>Margin</Trans>}
							/>
						</Typography>
					}
					value={
						<ToNext
							value={
								isPositive(liquidityPool?.liquidityPositions.length) &&
								liquidityPool &&
								Boolean(liquidityAmount)
									? toQuoteAmount(
											isPositive(liquidityPool?.liquidityPositions.length) &&
												liquidityPool?.liquidityPositions[0]?.marginNet
									  )
									: toQuoteAmount(quoteAmount)
							}
							nextValue={`${toQuoteAmount(
								plus(
									plus(
										(isPositive(liquidityPool?.liquidityPositions.length) &&
											liquidityPool?.liquidityPositions[0].marginNet) ||
											'0',
										quoteAmount || '0'
									),
									liquidityPool?.liquidityPositions[0]?.liquidityPassivePosition
										?.pnl || '0'
								)
							)}`}
							hasNext={
								isPositive(liquidityPool?.liquidityPositions.length) &&
								liquidityPool?.myLiquidity &&
								Boolean(liquidityAmount)
							}
						/>
					}
				/>
				<LiquidityPassivePositionV2
					liquidityPool={liquidityPool}
					variant='small'
				></LiquidityPassivePositionV2>
				<Cell
					label={<Trans>Execution Fee</Trans>}
					value={`${formatNumber(-positionMinExecutionFee, 5)} ${
						currentChain.nativeCurrency.symbol
					} (${toUsd(multipliedBy(nativePriceUsd, -positionMinExecutionFee))})`}
				/>
			</div>
			<LiquidityButtonV2 />
		</CommonStyledArticle>
	);
});
