import React, { useMemo, useState, useCallback } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans, t } from '@lingui/macro';
import { Typography } from '@mui/material';
import {
    MAX_PRECISION,
    QUOTE_USD,
    Side,
    Transaction_Type,
    Transaction_Status,
    Version
} from 'config/constants';
import Decimal from 'decimal.js';
import { useAccount } from 'wagmi';
import { usePriceRangesByTolerance } from 'hooks/useSlippageTolerance';
import { useSubmitUpdateLimitOrder } from 'hooks/useSubmitUpdateLimitOrder';
import { useSubmitUpdateLimitOrderV2 } from 'hooks/V2/useSubmitUpdateLimitOrderV2';

import useLocalSnackbar from 'hooks/useLocalSnackbar';
import { useOrdersStatusRequest } from 'fetch/useRequest';
import { useOrdersStatusLazyGraph } from 'graphql/useMyOrdersGraph'
import { useAppSelector } from 'state/hooks';
import { settingBaseState } from 'state/setting/slice';
import { selectVersion, selectOrderStatus, selectOrderOpenType } from 'state/setting/selector'
import { txBaseState } from 'state/tx/slice';
import { checkInputNumberic, isPositive, toQuoteAmount } from 'utils';

import Dialog from 'components/Common/Dialog';
import { CommonStyledFilledInput } from 'components/Common/Styled';

import { IOrderItemWithPosition } from './Operations/OpenOrdersList';

const TradeTriggerPriceDialog = ({
    currentOrder,
    onClose
}: {
    currentOrder: IOrderItemWithPosition;
    onClose: () => void;
}) => {
    const triggerMarketPrice = new Decimal(
        currentOrder?.triggerMarketPrice
    ).toFixed(currentOrder.baseToken.precision, Decimal.ROUND_HALF_CEIL);
    const [triggerPrice, setTriggerPrice] = useState<string>(triggerMarketPrice);
    const { signingMap } = useAppSelector(txBaseState);
    const { slippage } = useAppSelector(settingBaseState);
    const Order_Status = useAppSelector(selectOrderStatus);
    const Order_Open_Type = useAppSelector(selectOrderOpenType);
    const currentVersion = useAppSelector(selectVersion);
    const { address } = useAccount();
    const currentPrice = useMemo(() => {
        return triggerPrice === triggerMarketPrice
            ? currentOrder.triggerMarketPrice
            : triggerPrice;
    }, [currentOrder.triggerMarketPrice, triggerMarketPrice, triggerPrice]);
    const { showSnackbar } = useLocalSnackbar();
    const { minPrice, maxPrice } = usePriceRangesByTolerance(
        currentPrice,
        slippage
    );
    const { fetch } = useOrdersStatusLazyGraph()
    const { trigger } = useOrdersStatusRequest(currentOrder?.orderV2Id)

    const [updateOrderType, setUpdateOrderType] = useState<Transaction_Type>(
        Transaction_Type.UpdateLimitOrderV2
    );

    const acceptableTradePrice = useMemo(() => {
        if (!isPositive(triggerPrice)) {
            return '';
        }

        if (
            currentOrder.type === Order_Open_Type.Increase &&
            currentOrder.side === Side.LONG
        ) {
            return maxPrice;
        }

        return minPrice;
    }, [currentOrder, minPrice, maxPrice, triggerPrice]);

    const { onConfirm:onConfirmV1, isConfirming:isConfirmingV1, isConfirmed:isConfirmedV1 } = useSubmitUpdateLimitOrder(
        currentOrder.poolId,
        currentOrder.index,
        triggerPrice,
        currentOrder.triggerAbove,
        currentOrder.side,
        currentOrder.baseToken,
        currentOrder.liquidityDelta,
        acceptableTradePrice
    );

    const { onConfirm:onConfirmV2, isConfirming:isConfirmingV2, isConfirmed:isConfirmedV2 } = useSubmitUpdateLimitOrderV2(
        currentOrder.poolId,
        currentOrder.index,
        triggerPrice,
        currentOrder.triggerAbove,
        currentOrder.side,
        currentOrder.baseToken,
        currentOrder.liquidityDelta,
        acceptableTradePrice
    );

    const disabled = useMemo(() => {
        if (signingMap.get(updateOrderType)) {
            return true;
        }
        if (isConfirmingV1 || isConfirmingV2) {
            return true;
        }
        if (!isPositive(triggerPrice)) {
            return true;
        }
    }, [signingMap, updateOrderType, isConfirmingV1, isConfirmingV2, triggerPrice]);

    const submitText = useMemo(() => {
        if (isConfirmingV1 || isConfirmingV2) {
            return <Trans>Submitting...</Trans>;
        }
        if (signingMap.get(updateOrderType)) {
            return <Trans>Loading...</Trans>;
        }
        return <Trans>Confirm</Trans>;
    }, [updateOrderType, signingMap, isConfirmingV1, isConfirmingV2]);

    const onChangeTriggerPrice: React.ChangeEventHandler<
        HTMLInputElement
    > = e => {
        const value = e.target.value;
        if (value.trim() === '') {
            setTriggerPrice('');
        }

        if (checkInputNumberic(value, currentOrder.baseToken.precision)) {
            setTriggerPrice(value);
        }
    };

    useUpdateEffect(() => {
        if (isConfirmedV1 || isConfirmedV2) {
            onClose();
        }
    }, [isConfirmedV1, isConfirmedV2]);

    const fetchActionV1 = useCallback(
        () => {
            return fetch({
                variables: {
                    account: address,
                    id: currentOrder.id,
                }
            });
        },
        [fetch, address, currentOrder]
    );

    const fetchActionV2 = useCallback(() => {
		return trigger();
	}, [fetch]);

    const onHandleConfirmV1 = useCallback(
        () => {
            return fetchActionV1().then(res => {
                if (res.data && res.data.orders.length > 0 && res.data.orders[0].status === Order_Status.Created) {
                    onConfirmV1()
                } else {
                    onClose()
                    showSnackbar(t`Order has been executed`, undefined, Transaction_Status.Error);
                }
            })
        },
        [fetch, address, currentOrder, onConfirmV1, onClose, showSnackbar]
    );
    const onHandleConfirmV2 = useCallback(
        () => {
            return fetchActionV2().then(res => {
                if (
                    res.data &&
                    res.data?.status === Order_Status.Created && 
                    res.data?.id === currentOrder?.orderV2Id
                ) {
                    onConfirmV2()
                } else {
                    onClose()
                    showSnackbar(t`Order has been executed`, undefined, Transaction_Status.Error);
                }
            })
        },
        [fetch, address, currentOrder, onConfirmV2, onClose, showSnackbar]
    );


    return (
        <Dialog
            onConfirm={currentVersion  === Version.V1 ? onHandleConfirmV1 : onHandleConfirmV2}
            open
            onClose={onClose}
            title={t`Modify limit order`}
            disabled={disabled}
            confirmLabel={submitText}
        >
            <div className='mt-3 space-y-1'>
                <div className='flex justify-between'>
                    <Typography variant='body2' color='secondary'>
                        <Trans>Current trigger price</Trans>
                    </Typography>
                    <Typography variant='body2'>
                        {toQuoteAmount(
                            currentOrder?.triggerMarketPrice,
                            currentOrder.baseToken.precision,
                            Decimal.ROUND_HALF_CEIL
                        )}
                    </Typography>
                </div>
            </div>
            <div className='mt-2'>
                <CommonStyledFilledInput
                    type='text'
                    inputProps={{
                        maxLength: MAX_PRECISION,
                        inputMode: 'decimal'
                    }}
                    value={triggerPrice}
                    placeholder='0.0'
                    onChange={onChangeTriggerPrice}
                    fullWidth
                    endAdornment={
                        <Typography variant='body1' color='secondary'>
                            {QUOTE_USD}
                        </Typography>
                    }
                />
            </div>
            <div className='mt-2 space-y-1'>
                <div className='flex justify-between'>
                    <Typography variant='body2' color='secondary'>
                        <Trans>Acceptable Price</Trans>
                    </Typography>
                    <Typography variant='body2'>
                        {acceptableTradePrice ? (
                            currentOrder.type === Order_Open_Type.Increase ? (
                                currentOrder.side === Side.LONG ? (
                                    <span>{`≤${toQuoteAmount(
                                        acceptableTradePrice,
                                        currentOrder.baseToken.precision,
                                        Decimal.ROUND_HALF_CEIL
                                    )} `}</span>
                                ) : (
                                    <span>{`≥${toQuoteAmount(
                                        acceptableTradePrice,
                                        currentOrder.baseToken.precision,
                                        Decimal.ROUND_HALF_CEIL
                                    )} `}</span>
                                )
                            ) : currentOrder.side === Side.LONG ? (
                                <span>{`≥${toQuoteAmount(
                                    acceptableTradePrice,
                                    currentOrder.baseToken.precision,
                                    Decimal.ROUND_HALF_CEIL
                                )} `}</span>
                            ) : (
                                <span>{`≤${toQuoteAmount(
                                    acceptableTradePrice,
                                    currentOrder.baseToken.precision,
                                    Decimal.ROUND_HALF_CEIL
                                )} `}</span>
                            )
                        ) : (
                            '-'
                        )}
                    </Typography>
                </div>
            </div>
            <div className='mt-4 space-y-1'>
                <Typography variant='body2' color='secondary'>
                    <Trans>
                        After modifying the trigger price, the system will execute the
                        trigger based on the latest trigger price. Acceptable Price will be
                        updated.
                    </Trans>
                </Typography>
            </div>
        </Dialog>
    );
};

export default TradeTriggerPriceDialog;
