import { useEffect, useMemo, useState } from 'react';

import { EQUIP2_START_TIME, Version } from 'config/constants';
import { useApolloClients } from 'context/ApolloClientsContext';
import Decimal from 'decimal.js';

import { useAllTokens } from 'hooks/useAllTokens';
import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import { settingBaseState } from 'state/setting/slice';

import { TokenInfo } from '../types';
import {
	ceilDiv,
	div,
	isGreaterThan,
	isLessThan,
	minus,
	multipliedBy
} from '../utils';
import {
	PositionsListV2Query,
	usePositionsListV2Query
} from './__generated_tradeV2__/types-and-hooks';
import {
	PositionsListQuery,
	usePositionsListQuery
} from './__generated_trade__/types-and-hooks';

const usePositionList = (
	addressList: string[],
	blockNumber: number | string
) => {
	const allTokens = useAllTokens();
	const { blockTimestamp } = useAppSelector(globalBaseState);
	const { clientTradeV2 } = useApolloClients();
	const { currentVersion } = useAppSelector(settingBaseState);
	const {
		data: dataV1,
		loading: loadingV1,
		refetch: refetchV1
	} = usePositionsListQuery({
		variables: { account_in: addressList, number: Number(blockNumber) },
		skip: currentVersion === Version.V2
	});
	const {
		data: dataV2,
		loading: loadingV2,
		refetch: refetchV2
	} = usePositionsListV2Query({
		variables: { account_in: addressList, number: Number(blockNumber) },
		skip: currentVersion === Version.V1,
		client: clientTradeV2
	});

	const [data, loading, refetch] = useMemo(() => {
		let data: PositionsListQuery | PositionsListV2Query;
		let loading;
		let refetch;
		if (currentVersion === Version.V1) {
			data = dataV1;
			loading = loadingV1;
			refetch = refetchV1;
		} else {
			data = dataV2;
			loading = loadingV2;
			refetch = refetchV2;
		}

		return [data, loading, refetch];
	}, [
		dataV1,
		loadingV1,
		refetchV1,
		dataV2,
		loadingV2,
		refetchV2,
		currentVersion
	]);

	const [positionMap, setPositionMap] = useState<
		Map<
			string,
			Array<
				(typeof data)['positions'][number] & {
					coefficientSize: string;
					coefficient: string;
					token: TokenInfo;
				}
			>
		>
	>(new Map());

	useEffect(() => {
		if (!data) {
			return;
		}
		const tempMap: typeof positionMap = new Map();
		data.positions.forEach(item => {
			const startTime = isGreaterThan(EQUIP2_START_TIME, item.entryTime)
				? EQUIP2_START_TIME
				: item.entryTime;
			const coefficient = isLessThan(minus(blockTimestamp, startTime), 0)
				? '1'
				: div(
						1,
						new Decimal(
							ceilDiv(minus(blockTimestamp, startTime), 60 * 60 * 24)
						).sqrt()
				  );
			const coefficientSize = multipliedBy(item.size, coefficient);
			const account = item.account;
			const tokenId =
				currentVersion === Version.V1 ? item.pool.token.id : item.market.id;
			const poolId = currentVersion === Version.V1 ? item.pool.id : item.id;
			const token = allTokens.get(tokenId);
			if (tempMap.has(account)) {
				const prevList = tempMap.get(account);
				tempMap.set(account, [
					...prevList,
					{
						pool: {
							id: poolId,
							token: { id: tokenId }
						},
						...item,
						coefficient: coefficient,
						coefficientSize: coefficientSize,
						token: token
					}
				]);
			} else {
				tempMap.set(account, [
					{
						pool: {
							id: poolId,
							token: { id: tokenId }
						},
						...item,
						coefficient: coefficient,
						coefficientSize: coefficientSize,
						token: token
					}
				]);
			}
		});

		setPositionMap(tempMap);
	}, [data, loading, refetch, currentVersion]);

	return {
		positionMap,
		loading: currentVersion === Version.V1 ? loadingV1 : loadingV2,
		refetch: currentVersion === Version.V1 ? refetchV1 : refetchV2
	};
};

export default usePositionList;
