import React, { ReactNode } from 'react';

import { Trans } from '@lingui/macro';
import { Box, Typography, styled } from '@mui/material';
import classNames from 'classnames';
import { Dom_Size, Page, Version } from 'config/constants/enum';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCurrentPage } from 'hooks/useCurrentPage';
import Footer from 'layouts/Footer';
import MobileFooter from 'layouts/Footer/MobileFooter';
import Header from 'layouts/Header';
import { useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';

import Notify from './Notify';

const RootLayout = styled('article')(({ theme }) => {
	const { isMatchMobile } = useAppBreakpoints();
	return {
		minHeight: '100vh',
		height: isMatchMobile ? '100vh' : 'auto',
		backgroundColor: theme.palette.background.default,
		overflowX: 'hidden',
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'column'
	};
});

const MainLayout = styled('main')(({ theme }) => {
	const { isMatchMobile, isMatchPad } = useAppBreakpoints();
	const currentVersion = useAppSelector(selectVersion);

	return {
		display: currentVersion === Version.V1 || isMatchMobile ? 'block' : 'flex',
		flex: 1,
		minHeight: isMatchMobile
			? 'auto'
			: `calc(100vh - ${
					Dom_Size.HEIGHT_HEADER + Dom_Size.HEIGHT_FOOTER + 'px'
			  })`,
		color: theme.palette.text.primary,
		boxSizing: 'border-box',
		background: theme.palette.background.default,
		padding: isMatchMobile || isMatchPad ? '' : `8px 8px`
	};
});

export default React.memo(function Layout({
	children
}: {
	children: ReactNode;
}) {
	const { isMatchMobile } = useAppBreakpoints();
	const currentPage = useCurrentPage();
	const currentVersion = useAppSelector(selectVersion);
	return (
		<RootLayout id='root-layout' className='space-y-2 sm:space-y-0'>
			<header>
				<Header />
				{(currentPage === Page.IndexV1 ||
					currentPage === Page.IndexV2 ||
					currentPage === Page.TradeV1 ||
					currentPage === Page.MarketsV1 ||
					currentPage === Page.PoolsV1 ||
					currentPage === Page.EarnV1 ||
					currentPage === Page.ReferralsV1 ||
					currentPage === Page.LeaderboardV1) &&
					currentVersion === Version.V1 && (
						<Notify width={1400}>
							<div>
								<Typography variant='inherit' component='span'>
									<Trans>
										Launch Alert: V3 is now live with Zero Trading Fees and a
										Loss Compensation Mechanism! Choose from 55 diverse markets.
										Please gradually transition your trading to V3 and make sure
										to join our Weekly Trading Competition to win rewards!
									</Trans>
								</Typography>
								<Typography
									className='ml-1'
									component='a'
									variant='inherit'
									target={isMatchMobile ? '_self' : '_blank'}
									href='https://equation.trade/?version=V3'
									sx={{
										'&:hover': {
											textDecoration: 'underline'
										}
									}}
								>
									<Trans>Trade on V3 &gt;&gt;</Trans>
								</Typography>
							</div>
						</Notify>
					)}
			</header>
			<MainLayout>
				<Box
					component='article'
					className={classNames({
						'flex flex-1': currentVersion === Version.V2 && !isMatchMobile,
						'h-full': isMatchMobile
					})}
					sx={{
						maxWidth:
							currentVersion === Version.V1 ? Dom_Size.PAGE_CONTAINER : void 0,
						margin:
							currentVersion === Version.V1 || isMatchMobile
								? '0 auto'
								: '0 12px',
						paddingBottom: isMatchMobile
							? `${Dom_Size.MOBILE_HEIGHT_BOTTOM}px`
							: 0
					}}
				>
					{children}
				</Box>
			</MainLayout>
			{isMatchMobile ? <MobileFooter /> : <Footer />}
		</RootLayout>
	);
});
