import store from 'store2';

import {
	EVENT_CHART_PRICE,
	STORAGE_KEY_PRICES
} from '../../../config/constants';
import { toDecimalPlaces } from '../../../utils';
import historyProvider from './historyProvider.js';

var _subs = [];

let timer = null;

export default {
	subscribeBars: function (
		symbolInfo,
		resolution,
		updateCb,
		uid,
		resetCache,
		lastBar,
		api,
		options
	) {
		const channelString = `${symbolInfo.ticker}-${resolution}`;
		var newSub = {
			channelString,
			uid,
			resolution,
			symbolInfo,
			lastBar: lastBar,
			listener: updateCb
		};

		window.removeEventListener(EVENT_CHART_PRICE, window.stream);
		window.stream = () => {
			const prices = store.get(STORAGE_KEY_PRICES);
			historyProvider(api, options)
				.updateBar(symbolInfo, resolution)
				.then(data => {
					const price = prices[symbolInfo?.ticker?.split('-')[1]];
					if(data.length > 1 && Number(newSub.lastBar.time) <= Number(data[0].time)) {
						const prevBar = updateBar({
							...data[0],
						}, newSub)
						updateCb(prevBar)
						newSub.lastBar = prevBar
					}
					var _lastBar = updateBar(
						{
							...data.slice(-1)[0],
							close: Number(toDecimalPlaces(price, symbolInfo.pericesion))
						},
						newSub
					)
					updateCb(_lastBar)
					newSub.lastBar = _lastBar
				});
		};
		window.addEventListener(EVENT_CHART_PRICE, window.stream);

		// timer = setInterval(() => {
		// 	historyProvider(api, options)
		// 		.updateBar(symbolInfo, resolution)
		// 		.then(data => {
		// 			const prices = store.get(STORAGE_KEY_PRICES);
		// 			const price = prices[symbolInfo?.ticker?.replace('TOKEN-', '')];
		// 			var _lastBar = updateBar(
		// 				{
		// 					...data,
		// 					close: Number(toDecimalPlaces(price, symbolInfo.pericesion))
		// 				},
		// 				newSub
		// 			);
		// 			updateCb(_lastBar);
		// 			newSub.lastBar = _lastBar;
		// 		});
		// }, 1000);
		// newSub.timer = timer;
		// _subs.push(newSub);
	},
	unsubscribeBars: function (uid) {
		var subIndex = _subs.findIndex(e => e.uid === uid);
		if (subIndex === -1) {
			return;
		}
		var sub = _subs[subIndex];
		clearInterval(sub.timer);
		_subs.splice(subIndex, 1);
	}
};

function updateBar(data, sub) {
	var lastBar = sub.lastBar;
	let resolution = sub.resolution;
	if (resolution.includes('D')) {
		// 1 day in minutes === 1440
		resolution = 1440;
	} else if (resolution.includes('W')) {
		// 1 week in minutes === 10080
		resolution = 10080;
	}
	var coeff = resolution * 60;
	var rounded = Math.floor(data.time / coeff) * coeff;
	var lastBarSec = lastBar.time;
	var _lastBar;

	if (rounded > lastBarSec) {
		_lastBar = {
			time: rounded,
			open: lastBar.close,
			high: lastBar.close,
			low: lastBar.close,
			close: data.close,
			volume: data.volume
		};
		return _lastBar
	} else {
		// update lastBar candle!
		if (data.close < lastBar.low) {
			lastBar.low = data.close;
		} else if (data.close > lastBar.high) {
			lastBar.high = data.close;
		}

		lastBar.volume = Number(data.volume);
		lastBar.close = data.close;
		_lastBar = lastBar;
		return _lastBar;
	}
}
