import { useEffect, useState } from 'react';

import { globalBaseState } from '../state/global/slice';
import { useAppSelector } from '../state/hooks';
import { usePoolDepthQueryQuery } from './__generated_trade__/types-and-hooks';

const usePoolDepth = (id: string) => {
	const [isLoading, setIsLoading] = useState(true);
	const { appTimer } = useAppSelector(globalBaseState);
	const { data, loading, refetch } = usePoolDepthQueryQuery({
		variables: { id }
	});

	useEffect(() => {
		refetch();
	}, [appTimer]);

	useEffect(() => {
		if (!loading) {
			setIsLoading(loading);
		}
	}, [loading]);

	return {
		loading: isLoading,
		priceState: data?.priceState,
		globalLiquidityPosition: data?.globalLiquidityPosition
	};
};

export default usePoolDepth;
