import React from 'react';

import { useTheme } from '@mui/material/styles';

// import { SvgProps } from '../types';

interface SVGProps {
	size?: number;
	fill?: string;
}
const Share: React.FC<React.PropsWithChildren<SVGProps>> = props => {
	const theme = useTheme();
	const { size = 20, fill } = props;
	const color = fill ? fill : theme.custom.reverseColor;
	return (
		<svg
			width={size}
			height={size}
			viewBox='0 0 14 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<g>
				<path
					d='M11.3026 11.915H2.25231V3.45641H6.58138V2.2041H1V13.1673H12.5549V8.63314H11.3026V11.915Z'
					fill={color}
					fillOpacity='0.9'
				/>
				<path
					d='M5.45046 6.56905C4.37112 7.9372 4.15119 9.24586 4.14258 9.30143L5.37845 9.5018C5.40584 9.339 6.07426 5.7527 10.891 4.86826L9.82571 6.3906L10.851 7.10833L13.0254 4.00103L10.2468 1.5332L9.41558 2.47243L10.7203 3.63004C7.91594 4.13253 6.31846 5.46858 5.45046 6.56905Z'
					fill={color}
					fillOpacity='0.9'
				/>
			</g>
		</svg>
	);
};

export default Share;
