import { useEffect, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans, t } from '@lingui/macro';
import AddIcon from '@mui/icons-material/Add';
import {
	// Avatar,
	Box,
	Button,
	InputBase,
	Typography,
	useTheme
} from '@mui/material';

import { useSubmitMintMember } from 'hooks/useSubmitMintMember';
import { isAddress, isGreaterThan, minus } from 'utils';

import Dialog from 'components/Common/Dialog';
import Remove from 'components/Svg/Icons/Remove';

// import { type } from '../../../../third-party/typechain-types/index';

interface IInputList {
	id: string;
}
interface IValues {
	[propname: string]: any;
}
const MineDialog = ({ item, onClose }: { item: any; onClose: () => void }) => {
	const theme = useTheme();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [inputValues, setInputValues] = useState<IValues>({});
	const [inputList, setInputList] = useState<IInputList[]>([]);
	const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const name = event.target.name;
		const o: IValues = {};
		o[name] = {};
		o[name].address = value;
		o[name].error = false;
		if (!isAddress(value)) {
			if (value !== '') {
				o[name].error = true;
			}
		}
		setInputValues({ ...inputValues, ...o });
	};
	const [addresses, isAddressError] = useMemo(() => {
		const addresses: string[] = [];
		let i = 0;
		if (inputValues) {
			Object.keys(inputValues).forEach(key => {
				const tmpAddress = inputValues[key].address.trim();
				if (isAddress(tmpAddress)) {
					addresses.push(tmpAddress);
				} else {
					if (tmpAddress.length > 0) {
						i++;
					}
				}
			});
		}
		return [addresses, i === 0 ? false : true];
	}, [inputValues]);

	const { onConfirm, isConfirming, isConfirmed, error } = useSubmitMintMember(
		item.id,
		addresses
	);
	const disabled = useMemo(() => {
		if (isLoading) {
			return true;
		}
		if (addresses.length === 0 || isAddressError) {
			return true;
		}

		return false;
	}, [isAddressError, addresses, isLoading]);

	const submitText = useMemo(() => {
		// if (isApproving) {
		// 	return <Trans>Approving...</Trans>;
		// }
		if (isAddressError) {
			return <Trans>Incorrect address format</Trans>;
		}
		if (isConfirming) {
			return <Trans>Submitting...</Trans>;
		}
		if (isLoading) {
			return <Trans>Loading...</Trans>;
		}

		return <Trans>Mint</Trans>;
	}, [isAddressError, isConfirming, isLoading]);
	useUpdateEffect(() => {
		if (isConfirmed) {
			onClose();
		}
	}, [isConfirmed]);
	useUpdateEffect(() => {
		if (error) {
			setIsLoading(false);
		}
	}, [error]);
	const onSubingConfirm = () => {
		setIsLoading(true);
		onConfirm();
	};
	const onAddAddress = () => {
		const addList = [...inputList];
		addList.push({ id: getRandomId() });
		setInputList(addList);
	};
	const onDelAddress = (index: number, id: string) => {
		const delList = [...inputList];
		if (delList.length < 2) {
			return;
		}
		delList.splice(index, 1);
		setInputList(delList);
		const delValue = { ...inputValues };

		delete delValue[id];
		delete delValue[id + '_error'];

		setInputValues({ ...delValue });
	};
	const [times, canCreate] = useMemo(() => {
		let times = 0;
		let canCreate = 0;
		if (item.children) {
			canCreate = 100 - item.children.length;
			if (canCreate > 5) {
				times = 5;
			} else {
				times = canCreate;
			}
		}
		return [times, canCreate];
	}, [item.children]);

	useEffect(() => {
		const list = [];
		for (let i = 0; i < times; i++) {
			list.push({ id: getRandomId() });
		}
		setInputList(list);
	}, []);

	const getRandomId = () => {
		return (
			new Date().getTime() +
			Math.floor(Math.random() * 100000) +
			1
		).toString();
	};
	return (
		<Dialog
			onConfirm={onSubingConfirm}
			open
			disabled={disabled}
			onClose={onClose}
			title={t`Mint Member NFT`}
			confirmLabel={submitText}
		>
			<Typography variant='body2' color={theme.palette.text.secondary}>
				{canCreate > 1 && (
					<Trans>
						You can still use Connector #{item.id} to mint {canCreate} Member{' '}
						NFTs.
					</Trans>
				)}
				{canCreate === 1 && (
					<Trans>
						You can still use Connector #{item.id} to mint {canCreate} Member{' '}
						NFT.
					</Trans>
				)}
			</Typography>
			{/* <input id='name' name='name' onChange={onHandleChange} /> */}
			{inputList &&
				inputList.map((item, index) => {
					return (
						<Box className='flex items-center mt-3' key={index}>
							<Box className='flex-none w-[16px] mr-2 font-bold'>
								{index + 1}
							</Box>
							<Box className='flex-1'>
								<InputBase
									type='text'
									inputProps={{
										maxLength: 42,
										inputMode: 'text',
										className: 'input ring-transparent ring-0'
									}}
									name={item.id}
									className='ring-transparent ring-0'
									value={
										inputValues[item.id] && inputValues[item.id].address
											? inputValues[item.id].address
											: ''
									}
									placeholder={t`Receiving Address`}
									onChange={onHandleChange}
									fullWidth
									sx={{
										border:
											inputValues[item.id] && inputValues[item.id].error
												? `1px solid ${theme.palette.error.main}`
												: `1px solid ${theme.palette.background.default}`,
										'& .MuiInputBase-input': {
											fontSize: 14,
											fontWeight: 400,
											backgroundColor: theme.palette.background.default
										}
									}}
								/>
							</Box>
							<Remove
								className='ml-3 rounded-none cursor-pointer'
								onClick={() => onDelAddress(index, item.id)}
								type={inputList.length < 2 ? 'disabled' : ''}
							/>
						</Box>
					);
				})}
			{/* if (condition) {} */}

			<Button
				variant='text'
				sx={{ color: theme.palette.text.primary }}
				startIcon={<AddIcon />}
				className='mt-3'
				onClick={onAddAddress}
				disabled={!isGreaterThan(minus(canCreate, inputList.length), 0)}
			>
				<Trans>Receiving Address</Trans>
			</Button>
		</Dialog>
	);
};

export default MineDialog;
