import React, { useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import cn from 'classnames';
import { Version } from 'config/constants';

import { useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';

import { CommonStyledBaseInput } from 'components/Common/Styled';
import SearchIcon from 'components/Svg/Icons/Search';

export default React.memo(function SearchInput({
	value,
	setValue,
	onKeyDown
}: {
	value: string;
	setValue: (e: any) => void;
	onKeyDown: (e: any) => void;
}) {
	const currentVersion = useAppSelector(selectVersion);
	const [showBorder, setShowBorder] = useState(false);
	const theme = useTheme();
	return (
		<Box>
			<CommonStyledBaseInput
				type='text'
				// className={cn( showBorder ? `search-input` :'')}
				inputProps={{ maxLength: 100, inputMode: 'text' }}
				value={value}
				placeholder={
					currentVersion === Version.V1 ? t`Search Pool` : t`Search Market`
				}
				onChange={setValue}
				onKeyDown={onKeyDown}
				onFocus={() => setShowBorder(true)}
				onBlur={() => setShowBorder(false)}
				fullWidth
				startAdornment={
					<div className='flex pl-3 pr-1'>
						<SearchIcon />
					</div>
				}
				sx={{
					'&.MuiInputBase-root': {
						height: '36px',
						border: '1px solid',
						borderColor: showBorder ? theme.palette.primary.main : 'transparent'
					},
					'& .MuiInputBase-input': {
						fontSize: 14,
						height: '34px'
					}
				}}
			/>
		</Box>
	);
});
