import { useCallback, useMemo } from 'react';

import { EFCABI } from 'config/abis';
import { Contract_Write_Mode, Transaction_Type } from 'config/constants';
import { getContractAddress } from 'config/contracts';
import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { useAppSelector } from 'state/hooks';
import { selectPoolIds } from 'state/pools/selector';
import { RecordMintMember } from 'types';

import { useComputeGasLimit } from './useComputeGasLimit';
import { useCurrentChain } from './useCurrentChain';
import { useSendTransaction } from './useSendTransaction';

export function useSubmitMintMember(
	connectorTokenId: string,
	receivers: Array<string> | null
) {
	const poolIds = useAppSelector(selectPoolIds);

	const { currentChainId } = useCurrentChain();
	const { address } = useAccount();

	const { args, record, description } = useMemo(() => {
		if (
			!address ||
			!connectorTokenId ||
			(receivers && receivers.length === 0)
		) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		console.log('useSubmitMintMember args begin ...');

		const args = [connectorTokenId, receivers];

		const record = {
			transactionType: Transaction_Type.MintMember,
			connectorTokenId,
			receivers
		} as RecordMintMember;

		const description = TransactionDescription.RecordMintMember(
			connectorTokenId,
			receivers as string[]
		);

		return {
			args,
			record,
			description
		};
	}, [address, poolIds, connectorTokenId, receivers]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address: getContractAddress(currentChainId, 'EFC'),
			abi: EFCABI,
			functionName: 'batchMintMember',
			args
		} as UseContractWriteConfig;
	}, [currentChainId, args]);

	const { gasLimit } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirmed, isConfirming, error } =
		useSendTransaction(contractWriteArgs, gasLimit);

	// const onConfirm = () => {
	// 	if (!record) return;
	// 	onSendTransaction(record, description);
	// };

	return {
		onConfirm: useCallback(() => {
			if (!record) return;
			console.log(record, description);
			onSendTransaction(record, description);
		}, [onSendTransaction, record, description]),
		isConfirming,
		isConfirmed,
		error
	};
	// return {
	// 	onConfirm,
	// 	isConfirming,
	// 	isConfirmed,
	// 	error
	// };
}
