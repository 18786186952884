import { ReactNode, useEffect } from 'react';

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { DEFAULT_LOCALE, SupportedLocale } from 'config/constants/locales';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { messages } from '../../locales/en/messages.po';

export async function dynamicActivate(locale: SupportedLocale) {
	const { messages } = await import(`../../locales/${locale}/messages.po`);
	i18n.load(locale, messages);
	i18n.activate(locale);
}

interface ProviderProperties {
	locale: SupportedLocale;
	onActivate?: (locale: SupportedLocale) => void;
	children: ReactNode;
}

export function Provider({ locale, onActivate, children }: ProviderProperties) {
	useEffect(() => {
		dynamicActivate(locale)
			.then(() => onActivate?.(locale))
			.catch(error => {
				console.error('Failed to activate locale', locale, error);
			});
	}, [locale, onActivate]);

	if (i18n.locale === undefined && locale === DEFAULT_LOCALE) {
		i18n.load(DEFAULT_LOCALE, messages);
		i18n.activate(DEFAULT_LOCALE);
	}

	return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
}
