import { useTheme } from '@mui/material/styles';

interface SVGProps {
	size?: number;
	fill?: string;
}

function Loading({ size = 24, fill }: SVGProps) {
	const theme = useTheme();
	return (
		<svg
			version='1.1'
			width={`${size}px`}
			height={`${size}px`}
			style={{ display: 'inline' }}
			id='loading'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 120 120'
		>
			<rect
				fill={theme.palette.primary.main}
				width='18'
				height='90'
				transform='translate(0) rotate(180 16.5 52.5)'
			>
				<animate
					attributeName='height'
					attributeType='XML'
					dur='0.75s'
					values='30; 100; 30'
					repeatCount='indefinite'
				></animate>
			</rect>
			<rect
				x='37'
				fill={theme.palette.primary.main}
				width='18'
				height='90'
				transform='translate(0) rotate(180 52.5 52.5)'
			>
				<animate
					attributeName='height'
					attributeType='XML'
					dur='0.75s'
					values='30; 100; 30'
					repeatCount='indefinite'
					begin='0.2s'
				></animate>
			</rect>
			<rect
				x='73'
				fill={theme.palette.primary.main}
				width='18'
				height='90'
				transform='translate(0) rotate(180 89.5 52.5)'
			>
				<animate
					attributeName='height'
					attributeType='XML'
					dur='0.75s'
					values='30; 100; 30'
					repeatCount='indefinite'
					begin='0.4s'
				></animate>
			</rect>
		</svg>
	);
}

export default Loading;
