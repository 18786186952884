import Decimal from 'decimal.js';

import { IGlobalUnrealizedLossMetrics, ILiquidityPosition } from 'types';
import {
	abs,
	ceilDiv,
	div,
	formatUnits,
	isEqualTo,
	isGreaterThan,
	isGreaterThanOrEqual,
	isLessThan,
	minus,
	mulDiv,
	multipliedBy,
	parseUnits,
	plus,
	toDecimalPlaces
} from 'utils';

import {
	DAYS_YEAR,
	DEFAULT_QUOTE_PRECISION,
	Q96,
	QUOTE_USD_PRECISION,
	Side
} from '../config/constants';

export class LiquidityPositionUtil {
	/**
	 * 风险率 = abs(浮动亏损)/[(保证金净值-清算执行费）*99.5%]
	 * @param unrealizedLoss
	 * @param liquidationExecutionFee
	 * @param marginNet
	 * @param maxRiskRatePerLiquidityPosition
	 * @returns
	 */
	public static calcRiskRate(
		unrealizedLoss: Decimal.Value,
		liquidationExecutionFee: Decimal.Value,
		marginNet: Decimal.Value,
		maxRiskRatePerLiquidityPosition: Decimal.Value
	) {
		return div(
			abs(unrealizedLoss),
			multipliedBy(
				minus(marginNet, liquidationExecutionFee),
				maxRiskRatePerLiquidityPosition
			)
		);
	}

	// v2风险率 = abs(未实现亏损)/(保证金净值-维持保证金(流动性 * 清算费率 + 清算执行费)）
	public static calcV2RiskRate(
		liquidity: Decimal.Value,
		liquidationFeeRate: Decimal.Value,
		liquidationExecutionFee: Decimal.Value,
		margin: Decimal.Value,
		unrealizedPnL: Decimal.Value
	) {
		const unrealizedLosses = isGreaterThan(unrealizedPnL, 0)
			? '0'
			: unrealizedPnL;
		const maintenanceMargin = this.calculateMaintenanceMargin(
			liquidity,
			liquidationFeeRate,
			liquidationExecutionFee
		);
		return div(
			new Decimal(unrealizedLosses).abs(),
			minus(margin, maintenanceMargin)
		);
	}

	/**
	 * 保证金占比 = 保证金净值 / 仓位流动性
	 * @param margin
	 * @param liquidity
	 * @returns
	 */
	public static calcMarginRate(
		margin: Decimal.Value,
		liquidity: Decimal.Value
	) {
		return div(margin, liquidity);
	}

	public static calculateGlobalUnrealizedPnl(
		side: Side,
		netSize: Decimal.Value,
		entryPriceX96: Decimal.Value,
		indexPriceX96: Decimal.Value
	) {
		let unrealizedPnL;
		if (side === Side.LONG) {
			if (isGreaterThan(entryPriceX96, indexPriceX96)) {
				unrealizedPnL = -mulDiv(
					netSize,
					minus(entryPriceX96, indexPriceX96),
					Q96,
					Decimal.ROUND_UP
				);
			} else {
				unrealizedPnL = mulDiv(
					netSize,
					minus(indexPriceX96, entryPriceX96),
					Q96
				);
			}
		} else {
			if (isLessThan(entryPriceX96, indexPriceX96)) {
				unrealizedPnL = -mulDiv(
					netSize,
					minus(indexPriceX96, entryPriceX96),
					Q96,
					Decimal.ROUND_UP
				);
			} else {
				unrealizedPnL = mulDiv(
					netSize,
					minus(entryPriceX96, indexPriceX96),
					Q96
				);
			}
		}
		return unrealizedPnL;
	}

	/**
	 * 计算LP总体的未实现亏损，如果没有亏损则返回0
	 */
	public static calculateGlobalUnrealizedLoss(
		side: Side,
		netSize: Decimal.Value,
		entryPriceX96: Decimal.Value,
		indexPriceX96: Decimal.Value,
		riskBufferFund: Decimal.Value
	) {
		let unrealizedPnL = this.calculateGlobalUnrealizedPnl(
			side,
			netSize,
			entryPriceX96,
			indexPriceX96
		);
		unrealizedPnL = plus(unrealizedPnL, riskBufferFund);
		return isGreaterThanOrEqual(unrealizedPnL, 0)
			? '0'
			: new Decimal(-unrealizedPnL).toString();
	}

	public static calculateWAMUnrealizedLoss(
		_metricsCache: IGlobalUnrealizedLossMetrics
	) {
		if (isGreaterThan(_metricsCache.liquidity, 0)) {
			return ceilDiv(
				_metricsCache.liquidityTimesUnrealizedLoss,
				_metricsCache.liquidity
			);
		}
		return '0';
	}

	public static calculatePositionUnrealizedLoss(
		positionCache: ILiquidityPosition,
		metricsCache: IGlobalUnrealizedLossMetrics,
		globalLiquidity: string,
		globalUnrealizedLoss: string
	) {
		const _positionCache = {
			...positionCache,
			entryUnrealizedLoss: parseUnits(
				positionCache.entryUnrealizedLoss,
				DEFAULT_QUOTE_PRECISION
			),
			liquidity: parseUnits(positionCache.liquidity, DEFAULT_QUOTE_PRECISION)
		} as ILiquidityPosition;
		const _metricsCache = {
			...metricsCache,
			liquidity: parseUnits(metricsCache.liquidity, DEFAULT_QUOTE_PRECISION)
		} as IGlobalUnrealizedLossMetrics;
		const _globalLiquidity = parseUnits(globalLiquidity, QUOTE_USD_PRECISION);
		const unrealizedLoss = this._calculatePositionUnrealizedLoss(
			_positionCache,
			_metricsCache,
			_globalLiquidity,
			globalUnrealizedLoss
		);
		return formatUnits(
			toDecimalPlaces(unrealizedLoss, QUOTE_USD_PRECISION),
			QUOTE_USD_PRECISION
		);
	}

	/**
	 * 仓位未实现亏损
	 */
	public static _calculatePositionUnrealizedLoss(
		_positionCache: ILiquidityPosition,
		_metricsCache: IGlobalUnrealizedLossMetrics,
		_globalLiquidity: string,
		_unrealizedLoss: string
	) {
		let positionUnrealizedLoss = '0';

		if (
			isGreaterThan(_positionCache.entryTime, _metricsCache.lastZeroLossTime)
		) {
			if (isGreaterThan(_unrealizedLoss, _positionCache.entryUnrealizedLoss)) {
				positionUnrealizedLoss = mulDiv(
					minus(_unrealizedLoss, _positionCache.entryUnrealizedLoss),
					_positionCache.liquidity,
					_globalLiquidity,
					Decimal.ROUND_UP
				);
			}
		} else {
			const wamUnrealizedLoss = this.calculateWAMUnrealizedLoss(_metricsCache);
			const liquidityDelta = minus(_globalLiquidity, _metricsCache.liquidity);
			if (isGreaterThan(_unrealizedLoss, wamUnrealizedLoss)) {
				positionUnrealizedLoss = mulDiv(
					minus(_unrealizedLoss, wamUnrealizedLoss),
					_positionCache.liquidity,
					_globalLiquidity,
					Decimal.ROUND_UP
				);
				positionUnrealizedLoss = plus(
					positionUnrealizedLoss,
					mulDiv(
						wamUnrealizedLoss,
						_positionCache.liquidity,
						liquidityDelta,
						Decimal.ROUND_UP
					)
				);
			} else {
				positionUnrealizedLoss = mulDiv(
					_unrealizedLoss,
					_positionCache.liquidity,
					liquidityDelta,
					Decimal.ROUND_UP
				);
			}
		}
		return positionUnrealizedLoss;
	}

	/**
	 * 计算仓位的已实现盈利
	 * 每个流动性单位的盈利 * 我的流动性
	 * @param globalRealizedPnLGrowth 全局已实现盈亏
	 * @param entryRealizedProfitGrowth 开仓时全局已实现盈亏
	 * @param liquidity 仓位流动性
	 */
	public static calculatePositionRealizedProfit(
		globalRealizedPnLGrowth: Decimal.Value,
		entryRealizedProfitGrowth: Decimal.Value,
		liquidity: Decimal.Value
	) {
		const realizedPnLGrowthDelta = minus(
			globalRealizedPnLGrowth,
			entryRealizedProfitGrowth
		);

		return multipliedBy(realizedPnLGrowthDelta, liquidity);
	}

	public static calculateBalanceRateX96(
		netSize: Decimal.Value,
		entryPriceX96: Decimal.Value,
		globalLiquidity: Decimal.Value,
		maxPriceImpactLiquidity: Decimal.Value
	) {
		if (isEqualTo(globalLiquidity, 0)) {
			return '0';
		}
		return mulDiv(
			netSize,
			entryPriceX96,
			Decimal.min(globalLiquidity, maxPriceImpactLiquidity),
			Decimal.ROUND_UP
		);
	}

	public static calculateApr(
		dayRevenue: string,
		liquidity: string,
		maxLeveragePerLiquidityPosition: string | number
	) {
		return new Decimal(dayRevenue)
			.div(liquidity)
			.mul(DAYS_YEAR)
			.mul(maxLeveragePerLiquidityPosition)
			.toString();
	}

	/**
	 * 计算维持保证金
	 * 维持保证金 = (流动性仓位 * 清算费率） + 清算执行费；
	 * @param liquidity
	 * @param liquidationFeeRate
	 * @param liquidationExecutionFee // 清算费执行费
	 * @returns
	 */
	public static calculateMaintenanceMargin(
		liquidity: number | string | Decimal.Value,
		liquidationFeeRate: number | string | Decimal.Value,
		liquidationExecutionFee: string | Decimal.Value
	) {
		// 清算费用
		const _liquidatioFee = multipliedBy(liquidity, liquidationFeeRate);
		return plus(_liquidatioFee, liquidationExecutionFee);
	}

	/**
	 * 计算清算价格
	 * @param maintenanceMargin
	 * @param afterLiquidity
	 * @param afterGlobalLiquidity
	 * @param totalNetSize
	 * @param side
	 * @param margin
	 * @param previousSPPrice
	 * @returns
	 */
	public static calculateLiqPrice(
		maintenanceMargin: Decimal.Value,
		afterLiquidity: Decimal.Value,
		afterGlobalLiquidity: Decimal.Value,
		totalNetSize: Decimal.Value,
		side: Side,
		margin: Decimal.Value,
		previousSPPrice: Decimal.Value
	) {
		// 维持保证金
		const liquidityRatio = div(afterLiquidity, afterGlobalLiquidity);
		const delta = div(
			div(minus(margin, maintenanceMargin), totalNetSize),
			liquidityRatio
		);
		if (side === Side.LONG) {
			return minus(previousSPPrice, delta);
		} else {
			return plus(previousSPPrice, delta);
		}
	}
}
