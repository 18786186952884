import React, { useCallback, useContext, useMemo, useState } from 'react';

import { Trans } from '@lingui/macro';
import { Card, CardContent, Typography } from '@mui/material';
import {
	LEVERAGE_MAKERS,
	MAX_PRECISION,
	QUOTE_USD,
	Transaction_Type,
	Version
} from 'config/constants';
import { TradeContext } from 'context/TradeContext';

import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import { useListenLimitAmount } from 'hooks/useListenLimitAmount';
import { current } from 'immer';
import AcceptableEntryPriceSetting from 'pages/components/AcceptableEntryPriceSetting';
import { globalBaseState } from 'state/global/slice';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
	selectCurrentInitialMargin,
	selectCurrentLimitPrice,
	selectCurrentMarginDelta,
	selectCurrentOriginTradingFee,
	selectCurrentPositionAmount,
	selectCurrentTradingFee
} from 'state/limit/selector';
import { setPriceForLong, setPriceForShort } from 'state/limit/slice';
import { poolsBaseState } from 'state/pools/slice';
import {
	setCheckedTradeLeverage,
	setTradeLeverage,
	settingBaseState
} from 'state/setting/slice';
import { selectLeverageText } from 'state/trade/selector';
import { tradeBaseState } from 'state/trade/slice';
import { selectQuoteBalanceText } from 'state/tx/selector';
import { txBaseState } from 'state/tx/slice';
import {
	checkInputNumberic,
	computeLeverageMarks,
	formatNumber,
	isNumeric,
	isPositive,
	plus,
	shortenSymbol,
	shortenSymbolNative
} from 'utils';

import Cell from 'components/Common/Cell';
import { StyleCheckbox } from 'components/Common/StyleCheckbox';
import {
	CommonStyledBaseInput,
	CommonStyledMaxButton
} from 'components/Common/Styled';
import LeverageListener from 'components/LeverageListener';
import MarksSlider from 'components/MarksSlider';
import ToNext from 'components/ToNext';

import TradeButtonLimit from './TradeButtonLimit';
import TradeButtonLimitV2 from './TradeButtonLimitV2';
import TradeFees from './TradeFees';

export default React.memo(function LimitForm() {
	const dispatch = useAppDispatch();

	const { transactionType, quoteToken } = useAppSelector(txBaseState);
	const { leverage } = useAppSelector(tradeBaseState);
	const { checkedTradeLeverage, currentVersion } =
		useAppSelector(settingBaseState);
	const { orderMinExecutionFee } = useAppSelector(globalBaseState);
	const currentMarginDelta = useAppSelector(selectCurrentMarginDelta);
	const currentPositionAmount = useAppSelector(selectCurrentPositionAmount);

	const currentLimitPrice = useAppSelector(selectCurrentLimitPrice);
	const leverageText = useAppSelector(selectLeverageText);
	const quoteBalanceText = useAppSelector(selectQuoteBalanceText);
	const currentInitialMargin = useAppSelector(selectCurrentInitialMargin);
	const currentTradingFee = useAppSelector(selectCurrentTradingFee);
	const currentOriginTradingFee = useAppSelector(selectCurrentOriginTradingFee);
	const { poolInfo } = useAppSelector(poolsBaseState);

	const { positionInfo } = useContext(TradeContext);

	const isLogin = useCheckLogin();
	const { currentChainId } = useCurrentChain();

	const leverageMarks = useMemo(() => {
		if (!poolInfo) {
			return LEVERAGE_MAKERS;
		}
		return computeLeverageMarks(poolInfo.maxLeveragePerPosition);
	}, [poolInfo]);

	const {
		hasMaxButton,
		// set pay margin delta
		onHandleChangeMarginDelta,
		// set max pay margin delta
		onHandleSetMaxMarginDelta,
		// set size delta
		onHandleChangePositionAmount
	} = useListenLimitAmount();

	const targetInitialMargin = useMemo(() => {
		if (!positionInfo || !isPositive(currentInitialMargin)) {
			return '';
		}
		return plus(currentInitialMargin, positionInfo.netMargin);
	}, [currentInitialMargin, positionInfo]);

	// set limit price
	const onSetLimitPrice = useCallback(
		(value: string) => {
			if (value.trim() === '') {
				if (
					transactionType === Transaction_Type.LongLimitOrder ||
					transactionType === Transaction_Type.LongLimitOrderV2
				) {
					dispatch(setPriceForLong(''));
				} else {
					dispatch(setPriceForShort(''));
				}
				return;
			}
			if (checkInputNumberic(value, poolInfo?.baseToken?.precision)) {
				if (
					transactionType === Transaction_Type.LongLimitOrder ||
					transactionType === Transaction_Type.LongLimitOrderV2
				) {
					dispatch(setPriceForLong(value));
				} else {
					dispatch(setPriceForShort(value));
				}
			}
		},
		[transactionType, poolInfo]
	);

	const onHandleChangePrice = useCallback(
		(event: any) => {
			const value = event.target.value;
			onSetLimitPrice(value);
		},
		[transactionType, poolInfo]
	);

	const onChangeLeverage = (value: number | number[]) => {
		dispatch(setTradeLeverage(value));
	};

	const onClickLabel = (value: number) => {
		dispatch(setTradeLeverage(value));
	};

	const onHandleChangeCheckedLeverage = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			dispatch(setCheckedTradeLeverage(event.target.checked));
		},
		[]
	);

	const [activeLeverage, setActiveLeverage] = useState<boolean>(false);

	const onChangeLeverageInput = useCallback((value: string) => {
		dispatch(setTradeLeverage(value));
	}, []);

	return (
		<article className='space-y-3'>
			<div className='space-y-3'>
				<Card>
					<CardContent>
						<Cell
							label={
								<Typography variant='body1'>
									<Trans>Price</Trans>
								</Typography>
							}
							value=''
						/>

						<CommonStyledBaseInput
							type='text'
							inputProps={{
								maxLength: MAX_PRECISION,
								inputMode: 'decimal'
							}}
							className='input'
							value={currentLimitPrice}
							placeholder='0.0'
							onChange={onHandleChangePrice}
							fullWidth
							endAdornment={
								<Typography className='font-normal' variant='h5'>
									{QUOTE_USD}
								</Typography>
							}
							sx={{
								'& .MuiInputBase-input': {
									fontSize: 20
								}
							}}
						/>
					</CardContent>
				</Card>
				<Card>
					<CardContent>
						<div className='flex justify-between py-1'>
							<Typography variant='body1' color='secondary'>
								<Trans>Pay</Trans>
							</Typography>
							{isLogin && (
								<div className='flex item-baseline space-x-1'>
									<Typography variant='body2' color='secondary'>
										<Trans>Balance:</Trans>
									</Typography>
									<Typography variant='body2'>{quoteBalanceText}</Typography>
								</div>
							)}
						</div>

						<CommonStyledBaseInput
							type='text'
							inputProps={{ maxLength: MAX_PRECISION, inputMode: 'decimal' }}
							className='input'
							value={currentMarginDelta}
							placeholder='0.0'
							onChange={onHandleChangeMarginDelta}
							fullWidth
							endAdornment={
								<div className='flex items-center space-x-2'>
									{hasMaxButton && (
										<CommonStyledMaxButton
											variant='outlined'
											color='secondary'
											onClick={() => onHandleSetMaxMarginDelta()}
										>
											<Trans>Max</Trans>
										</CommonStyledMaxButton>
									)}

									<Typography className='font-normal' variant='h5'>
										{shortenSymbol(quoteToken)}
									</Typography>
								</div>
							}
							sx={{
								'& .MuiInputBase-input': {
									fontSize: 20
								}
							}}
						/>
					</CardContent>
				</Card>
				<Card>
					<CardContent>
						<div className='flex justify-between'>
							<Typography variant='body1' color='secondary'>
								<Trans>Size</Trans>
							</Typography>
							{checkedTradeLeverage && (
								<LeverageListener
									active={activeLeverage}
									leverage={leverage}
									maxLeverage={poolInfo?.maxLeveragePerPosition}
									onChangeLeverage={onChangeLeverageInput}
									onToggleActive={(value: boolean) => {
										setActiveLeverage(value);
									}}
								/>
							)}
						</div>
						<CommonStyledBaseInput
							type='text'
							inputProps={{ maxLength: MAX_PRECISION, inputMode: 'decimal' }}
							className='input'
							value={currentPositionAmount}
							placeholder='0.0'
							onChange={onHandleChangePositionAmount}
							fullWidth
							endAdornment={
								<Typography className='font-normal' variant='h5'>
									{shortenSymbolNative(poolInfo?.baseToken, currentChainId)}
								</Typography>
							}
							sx={{
								'& .MuiInputBase-input': {
									fontSize: 20
								}
							}}
						/>
					</CardContent>
				</Card>
			</div>
			<div>
				<div className={checkedTradeLeverage ? '-mb-1' : '-mb-2'}>
					<Cell
						label={<Trans>Leverage Slider</Trans>}
						value={
							<StyleCheckbox
								checked={checkedTradeLeverage}
								size='small'
								className='-mr-2.5'
								onChange={onHandleChangeCheckedLeverage}
							/>
						}
					/>
				</div>
				{poolInfo && checkedTradeLeverage && (
					<MarksSlider
						leverage={leverage}
						marks={leverageMarks}
						max={poolInfo.maxLeveragePerPosition}
						onChangeLeverage={onChangeLeverage}
						onClickLabel={onClickLabel}
					/>
				)}
			</div>
			<div>
				{!checkedTradeLeverage && (
					<Cell
						label={<Trans>Leverage</Trans>}
						value={<ToNext value={leverageText} hasNext={false} />}
					/>
				)}

				<AcceptableEntryPriceSetting price={currentLimitPrice} />

				<Cell
					label={<Trans>Est. Margin</Trans>}
					value={
						<ToNext
							value={
								targetInitialMargin && positionInfo
									? formatNumber(positionInfo.netMargin)
									: formatNumber(currentInitialMargin)
							}
							nextValue={formatNumber(targetInitialMargin)}
							hasNext={isPositive(targetInitialMargin)}
						/>
					}
					unit={
						isNumeric(currentInitialMargin) ? shortenSymbol(quoteToken) : ''
					}
				/>

				<TradeFees
					tradingFeeRate={poolInfo?.tradingFeeRate}
					referralDiscountRate={poolInfo?.referralDiscountRate}
					tradingFee={currentTradingFee}
					originTradingFee={currentOriginTradingFee}
					executionFee={orderMinExecutionFee}
				/>
			</div>
			{currentVersion === Version.V1 ? (
				<TradeButtonLimit />
			) : (
				<TradeButtonLimitV2 />
			)}
		</article>
	);
});
