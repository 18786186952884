import { useEffect, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans } from '@lingui/macro';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import cn from 'classnames';
import {
	APP_TOKEN_SYMBOL,
	Claim_Type,
	MAX_PAGE_SIZE,
	Version
} from 'config/constants';
import { useAccount } from 'wagmi';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin } from 'hooks/useCurrentChain';
import AppTokenPrice from 'pages/components/AppTokenPrice';
import ClaimHistoryDialog from 'pages/components/ClaimHistoryDialog';
import { ModuleCard } from 'pages/components/ModuleCard';
import { useAppSelector } from 'state/hooks';
import {
	selectClaimableConnectors,
	selectClaimableMembers,
	selectConnectorsRewardEquAmount,
	selectMembersRewardEquAmount
} from 'state/referrals/selector';
import { selectVersion } from 'state/setting/selector';
import { tradeBaseState } from 'state/trade/slice';
import { txBaseState } from 'state/tx/slice';
import { formatNumber, isPositive, shortenSymbol } from 'utils';

import CommonButton from 'components/Common/StyledButton';

import Tooltip from '../../components/Common/Tooltip';
import Tip from '../../components/Svg/Icons/Tip';
import { useApolloClients } from '../../context/ApolloClientsContext';
import {
	ClaimHistoriesQueryQuery,
	useClaimHistoriesQueryLazyQuery
} from '../../graphql/__generated_referral__/types-and-hooks';
import { useManageTransactions } from '../../hooks/useAccountTransactions';
import { globalBaseState } from '../../state/global/slice';
import { selectTransactionRecordList } from '../../state/records/selector';
import ClaimPrevious from '../components/ClaimPrevious';
import { Type_Referrals } from './types';

export default function Summary({
	onDirectClaim,
	onClaim,
	isClaimLoading,
	disabled,
	type
}: {
	onDirectClaim: () => void;
	onClaim: () => void;
	isClaimLoading: boolean;
	disabled: boolean;
	type: Type_Referrals;
}) {
	const { quoteToken, appToken } = useAppSelector(txBaseState);
	const { isEnableMining } = useAppSelector(tradeBaseState);
	const { appTimer } = useAppSelector(globalBaseState);
	const currentVersion = useAppSelector(selectVersion);

	const historyTypes = useMemo(() => {
		if (currentVersion === Version.V1) {
			return [
				type === Type_Referrals.Members
					? Claim_Type.Member
					: Claim_Type.Connector
			];
		}
		return [
			type === Type_Referrals.Members
				? Claim_Type.MemberV2
				: Claim_Type.ConnectorV2
		];
	}, [currentVersion, type]);

	const [histories, setHistories] = useState<
		ClaimHistoriesQueryQuery['aggregationClaimHistories']
	>([]);
	const [loading, setLoading] = useState(false);
	const [fetching, setFetching] = useState(false);
	const [hasMore, setHasMore] = useState(true);

	const { address } = useAccount();
	const { clientReferral } = useApolloClients();
	const [fetch] = useClaimHistoriesQueryLazyQuery({
		client: clientReferral
	});
	const fetchAction = (index: number, isRefresh?: boolean) => {
		if (isRefresh === true || (hasMore && !fetching)) {
			setFetching(true);
			return fetch({
				variables: {
					account: address,
					type_in: historyTypes,
					skip: index === 0 ? 0 : histories.length
				}
			})
				.then(res => {
					if (!res.data) {
						return;
					}

					const map = new Map();
					const data = res.data.aggregationClaimHistories;
					data.forEach(history => map.set(history.txHash, history));
					transactionRecords.forEach(record => {
						if (map.has(record.hash)) {
							removeTransaction(record);
						}
					});
					setHasMore(data.length === MAX_PAGE_SIZE);
					setHistories(index === 0 ? data : [...histories, ...data]);
				})
				.finally(() => setFetching(false));
		} else {
			return Promise.resolve();
		}
	};

	useEffect(() => {
		setLoading(true);
		setHasMore(true);
		setFetching(false);
		setHistories([]);
		fetchAction(0, true).finally(() => setLoading(false));
	}, [address, currentVersion]);

	useUpdateEffect(() => {
		fetch({
			variables: {
				account: address,
				type_in: historyTypes,
				skip: 0
			}
		}).then(res => {
			if (!res.data) {
				return;
			}

			const map = new Map();
			res.data.aggregationClaimHistories.forEach(history =>
				map.set(history.txHash, history)
			);
			transactionRecords.forEach(record => {
				if (map.has(record.hash)) {
					removeTransaction(record);
				}
			});
			const newData = res.data.aggregationClaimHistories.filter(
				history => !histories.find(item => item.txHash === history.txHash)
			);
			setHistories([...newData, ...histories]);
		});
	}, [appTimer]);

	const transactionRecords = useAppSelector(selectTransactionRecordList);
	const { removeTransaction } = useManageTransactions();

	const claimableMembers = useAppSelector(selectClaimableMembers);

	const claimableConnectors = useAppSelector(selectClaimableConnectors);

	const membersRewardEquAmount = useAppSelector(selectMembersRewardEquAmount);
	const connectorsRewardEquAmount = useAppSelector(
		selectConnectorsRewardEquAmount
	);
	const { isMatchMobile, isMatchPad, isMatchPc } = useAppBreakpoints();
	const isLogin = useCheckLogin();
	const theme = useTheme();
	const [visibleClaimHistory, setVisibleClaimHistory] =
		useState<boolean>(false);

	const onOpenClaimHistory = () => {
		setVisibleClaimHistory(true);
	};

	return (
		<ModuleCard
			title={
				<div className='flex space-x-2 sm:space-x-1'>
					<div>
						<Trans>Claimable Rewards</Trans>
					</div>
					{currentVersion === Version.V2 && (
						<Tooltip
							title={
								<Typography variant='body2'>
									<Trans>V2 data only</Trans>
								</Typography>
							}
						>
							<div>
								<Tip />
							</div>
						</Tooltip>
					)}
				</div>
			}
			extra={
				<>
					{isLogin && isMatchMobile && (
						<Typography
							className='cursor-pointer'
							color='text.primary'
							variant='body1'
							align='left'
							onClick={onOpenClaimHistory}
							sx={{
								'&:hover': {
									color: theme.palette.primary.main
								}
							}}
						>
							<Trans>Claim History</Trans>
							<KeyboardArrowRight color='inherit' fontSize='small' />
						</Typography>
					)}
					{(isMatchPad || isMatchPc) && <AppTokenPrice />}
				</>
			}
		>
			<div className='flex sm:block items-center sm:h-auto sm:space-y-6'>
				<div
					className={cn(
						`flex flex-1`,
						isEnableMining ? 'flex-2 md:flex-auto' : ''
					)}
				>
					<div className='flex-1'>
						{type === Type_Referrals.Members ? (
							<Typography
								variant={isMatchMobile || isMatchPad ? 'h5' : 'h4'}
								fontSize={isMatchMobile || isMatchPad ? 16 : 20}
							>
								{`${formatNumber(
									claimableMembers.unclaimed,
									quoteToken?.positionUnits
								)} ${shortenSymbol(quoteToken)}`}
							</Typography>
						) : null}
						{type === Type_Referrals.Connector ? (
							<Typography
								variant={isMatchMobile || isMatchPad ? 'h5' : 'h4'}
								fontSize={isMatchMobile || isMatchPad ? 16 : 20}
							>
								{`${formatNumber(
									claimableConnectors.unclaimed,
									quoteToken?.positionUnits
								)} ${shortenSymbol(quoteToken)}`}
							</Typography>
						) : null}
					</div>
					{isEnableMining && (
						<div className='flex-1 sm:text-right'>
							{type === Type_Referrals.Members ? (
								<Typography
									variant={isMatchMobile || isMatchPad ? 'h5' : 'h4'}
									fontSize={isMatchMobile || isMatchPad ? 16 : 20}
								>
									{formatNumber(
										claimableMembers.newRewardsEQU,
										appToken?.positionUnits
									)}{' '}
									{APP_TOKEN_SYMBOL}
								</Typography>
							) : null}
							{type === Type_Referrals.Connector ? (
								<Typography
									variant={isMatchMobile || isMatchPad ? 'h5' : 'h4'}
									fontSize={isMatchMobile || isMatchPad ? 16 : 20}
								>
									{formatNumber(
										claimableConnectors.newRewardsEQU,
										appToken?.positionUnits
									)}{' '}
									{APP_TOKEN_SYMBOL}
								</Typography>
							) : null}
						</div>
					)}
				</div>
				<div className='flex-3 md:flex-auto flex sm:block items-center justify-between sm:space-y-3'>
					<div className='flex items-center space-x-4 sm:flex-col'>
						<CommonButton
							className='py-1 font-medium sm:mb-2'
							fullWidth={isMatchMobile}
							onClick={onClaim}
							disabled={disabled}
						>
							{isClaimLoading ? (
								<Trans>Claiming...</Trans>
							) : (
								<Trans>Claim</Trans>
							)}
						</CommonButton>
						<Typography
							className='cursor-pointer sm:hidden'
							color='text.primary'
							variant='body1'
							align='left'
							onClick={onOpenClaimHistory}
							sx={{
								'&:hover': {
									color: theme.palette.primary.main
								}
							}}
						>
							<Trans>Claim History</Trans>
							<KeyboardArrowRight color='inherit' fontSize='small' />
						</Typography>
						<ClaimPrevious
							isVisible={
								isLogin &&
								type === Type_Referrals.Members &&
								isPositive(membersRewardEquAmount)
							}
							onClaim={onDirectClaim}
						/>
						<ClaimPrevious
							isVisible={
								isLogin &&
								type === Type_Referrals.Connector &&
								isPositive(connectorsRewardEquAmount)
							}
							onClaim={onDirectClaim}
						/>
					</div>
					{/* {!isMatchPad && <AppTokenPrice />} */}
				</div>
			</div>
			{visibleClaimHistory && (
				<ClaimHistoryDialog
					open={true}
					loading={loading}
					claimHistories={histories}
					fetchAction={fetchAction}
					onClose={() => {
						setVisibleClaimHistory(false);
					}}
				/>
			)}
		</ModuleCard>
	);
}
