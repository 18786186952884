import { useCallback, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans, t } from '@lingui/macro';
import { Box, Card, CardContent, Typography } from '@mui/material';
import {
	MAINTENANCE_RATE,
	MAX_AMOUNT_FORMATTER_LIMIT,
	MAX_PRECISION,
	QUOTE_USD,
	Transaction_Type,
	Version
} from 'config/constants';
import Decimal from 'decimal.js';

import { PositionUtil } from 'entities/PositionUtil';
import { IPositionItem } from 'graphql/useMyPositionsGraph';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { useSubmitAdjustMarginTrade } from 'hooks/useSubmitAdjustMarginTrade';
import { isEmpty } from 'lodash';
import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { txBaseState } from 'state/tx/slice';
import {
	amountFormatter,
	checkInputNumberic,
	div,
	formatLeverage,
	formatNumber,
	isGreaterThan,
	isNegative,
	isPositive,
	minus,
	multipliedBy,
	plus,
	shortenSymbol,
	shortenSymbolNative,
	toAmountString,
	toDecimalPlaces,
	toQuoteAmount
} from 'utils';

import ApproveButtonV2 from 'components/ApproveButtonV2';
import Cell from 'components/Common/Cell';
import Dialog from 'components/Common/Dialog';
import {
	CommonStyledBaseInput,
	CommonStyledDivider,
	CommonStyledMaxButton
} from 'components/Common/Styled';
import Button from 'components/Common/StyledButton';
import ExecutionFee from 'components/ExecutionFee';
import Leverage from 'components/Leverage';
import LiqPrice from 'components/LiqPrice';
import LongShortTag from 'components/LongShortTag';
import ToNext from 'components/ToNext';

const AdjustMarginDialog = ({
	item,
	onClose
}: {
	item: IPositionItem;
	onClose: () => void;
}) => {
	const { quoteToken, quoteBalance, signingMap } = useAppSelector(txBaseState);
	const { poolInfo } = useAppSelector(poolsBaseState);
	const [adjustType, setAdjustType] = useState<Transaction_Type>(
		Transaction_Type.IncreaseMarginV2
	);
	const [quoteAmountForAdd, setQuoteAmountForAdd] = useState<string>('');
	const [quoteAmountForReduce, setQuoteAmountForReduce] = useState<string>('');

	const { currentChainId } = useCurrentChain();

	const currentMarginDelta = useMemo(() => {
		if (adjustType === Transaction_Type.IncreaseMarginV2) {
			return quoteAmountForAdd;
		} else {
			return quoteAmountForReduce;
		}
	}, [quoteAmountForAdd, quoteAmountForReduce, adjustType]);

	const onHandleChangeMarginDelta = useCallback(
		(event: any) => {
			const value = event.target.value;
			if (value.trim() === '') {
				if (adjustType === Transaction_Type.IncreaseMarginV2) {
					setQuoteAmountForAdd('');
				} else {
					setQuoteAmountForReduce('');
				}
			}
			if (checkInputNumberic(value, quoteToken?.decimals)) {
				if (adjustType === Transaction_Type.IncreaseMarginV2) {
					setQuoteAmountForAdd(value);
				} else {
					setQuoteAmountForReduce(value);
				}
			}
		},
		[adjustType, quoteToken, poolInfo]
	);

	const { targetInitialMargin, targetLeverage } = useMemo(() => {
		const { netMargin } = item;
		if (!isPositive(currentMarginDelta)) {
			return {
				targetInitialMargin: '',
				targetLeverage: ''
			};
		}
		let targetInitialMargin;
		let targetLeverage;
		if (adjustType === Transaction_Type.IncreaseMarginV2) {
			targetInitialMargin = plus(currentMarginDelta, netMargin);
			targetLeverage = PositionUtil.calculateLeverage(
				targetInitialMargin,
				item.liquidity
			);
		} else {
			targetInitialMargin = minus(netMargin, currentMarginDelta);
			targetLeverage = PositionUtil.calculateLeverage(
				targetInitialMargin,
				item.liquidity
			);
		}
		return {
			targetInitialMargin,
			targetLeverage
		};
	}, [currentMarginDelta, item, adjustType]);
	const targetLiqPrice = PositionUtil.calculateLiqPrice(
		item.side,
		targetInitialMargin,
		item.size,
		item.entryPrice,
		item.liquidationExecutionFee,
		item.liquidationFeeRatePerPosition,
		item.discountedTradingFeeRate
	);
	const positionIsNeverBust = isNegative(item.liqPrice);
	// 最大可减少的保证金
	const maxReducedMargin = useMemo(() => {
		if (isEmpty(poolInfo)) {
			return '';
		}
		const {
			liquidity,
			size,
			netMargin,
			maxLeveragePerPosition,
			unrealizedPnL,
			liquidationExecutionFee,
			discountedTradingFeeRate
		} = item;
		const price = poolInfo.price;

		const _maxReducedMarginByLeverage = minus(
			netMargin,
			div(liquidity, maxLeveragePerPosition)
		);

		const _liquidationFeeRate = div(1, maxLeveragePerPosition);
		const _basicMargin = multipliedBy(liquidity, _liquidationFeeRate);
		const _tradingFee = multipliedBy(
			multipliedBy(price, size),
			discountedTradingFeeRate
		);
		const _maintenanceMargin = plus(
			plus(_basicMargin, liquidationExecutionFee),
			_tradingFee
		);

		const _unrealizedPnL = isPositive(unrealizedPnL) ? 0 : unrealizedPnL;
		const _maxReducedMargin = plus(
			minus(netMargin, div(_maintenanceMargin, MAINTENANCE_RATE)),
			_unrealizedPnL
		);

		const result = Decimal.min(
			_maxReducedMarginByLeverage,
			_maxReducedMargin
		).toFixed();

		return isPositive(result) ? result : '0';
	}, [currentMarginDelta, item, poolInfo]);

	const { onSubmitAdjust, isConfirmed, isConfirming } =
		useSubmitAdjustMarginTrade(item, adjustType, currentMarginDelta, '0');

	const disabled = useMemo(() => {
		if (signingMap.get(adjustType)) {
			return true;
		}
		if (isConfirming) {
			return true;
		}
		if (!isPositive(currentMarginDelta)) {
			return true;
		}
		if (adjustType === Transaction_Type.IncreaseMarginV2) {
			if (isGreaterThan(currentMarginDelta, quoteBalance)) {
				return true;
			}
		}
		if (adjustType === Transaction_Type.ReduceMarginV2) {
			if (!isPositive(maxReducedMargin)) {
				return true;
			}
			if (isGreaterThan(currentMarginDelta, maxReducedMargin)) {
				return true;
			}
		}
		return false;
	}, [
		adjustType,
		currentMarginDelta,
		quoteBalance,
		maxReducedMargin,
		signingMap,
		isConfirming
	]);

	const submitText = useMemo(() => {
		if (isConfirming) {
			return <Trans>Submitting...</Trans>;
		}
		if (signingMap.get(adjustType)) {
			return <Trans>Loading...</Trans>;
		}

		if (isPositive(currentMarginDelta)) {
			if (adjustType === Transaction_Type.IncreaseMarginV2) {
				if (isGreaterThan(currentMarginDelta, quoteBalance)) {
					return <Trans>Exceeds the max increase</Trans>;
				}
			}
			if (adjustType === Transaction_Type.ReduceMarginV2) {
				if (isGreaterThan(currentMarginDelta, maxReducedMargin)) {
					return <Trans>Exceeds the max decrease</Trans>;
				}
			}
		}
		if (adjustType === Transaction_Type.IncreaseMarginV2) {
			return <Trans>Add</Trans>;
		}
		if (adjustType === Transaction_Type.ReduceMarginV2) {
			return <Trans>Reduce</Trans>;
		}
	}, [
		adjustType,
		currentMarginDelta,
		quoteBalance,
		maxReducedMargin,
		signingMap,
		isConfirming
	]);

	const onHandleChangeTab = (value: Transaction_Type) => setAdjustType(value);

	const onHandleSetMaxAmount = () => {
		// setCloseSize(item.size);
		if (adjustType === Transaction_Type.IncreaseMarginV2) {
			setQuoteAmountForAdd(quoteBalance);
		} else {
			setQuoteAmountForReduce(
				toAmountString(toDecimalPlaces(maxReducedMargin, 6), MAX_PRECISION)
			);
		}
	};
	const onConfirm = () => {
		onSubmitAdjust();
	};
	useUpdateEffect(() => {
		if (isConfirmed) {
			onClose();
		}
	}, [isConfirmed]);

	useUpdateEffect(() => {
		setQuoteAmountForAdd('');
		setQuoteAmountForReduce('');
	}, [adjustType]);

	return (
		<Dialog open onClose={onClose} title={t`Adjust Margin`}>
			<article className='space-y-4'>
				<Box className='flex'>
					<Box className='relative z-9 flex-1'>
						<Button
							fullWidth
							componentVariant={
								adjustType === Transaction_Type.IncreaseMarginV2
									? 'mainOutlined'
									: 'outlined'
							}
							onClick={() =>
								onHandleChangeTab(Transaction_Type.IncreaseMarginV2)
							}
						>
							<Trans>Add Margin</Trans>
						</Button>
					</Box>
					<Box className='relative z-9 flex-1'>
						<Button
							fullWidth
							componentVariant={
								adjustType === Transaction_Type.IncreaseMarginV2
									? 'outlined'
									: 'mainOutlined'
							}
							onClick={() => onHandleChangeTab(Transaction_Type.ReduceMarginV2)}
						>
							<Trans>Reduce Margin</Trans>
						</Button>
					</Box>
				</Box>
				<Card>
					<CardContent>
						<Cell
							label={
								<Typography variant='body1'>
									<Trans>Amount</Trans>
								</Typography>
							}
							prefix={<Trans>Max:</Trans>}
							value={`${
								adjustType === Transaction_Type.IncreaseMarginV2
									? formatNumber(quoteBalance, 2, true)
									: formatNumber(maxReducedMargin, 2, true)
							} ${shortenSymbol(quoteToken)}`}
						/>

						<CommonStyledBaseInput
							type='text'
							inputProps={{ maxLength: MAX_PRECISION, inputMode: 'decimal' }}
							className='input'
							value={currentMarginDelta}
							placeholder='0.0'
							onChange={onHandleChangeMarginDelta}
							fullWidth
							endAdornment={
								<div className='flex items-center space-x-2'>
									<CommonStyledMaxButton
										variant='outlined'
										color='secondary'
										onClick={() => onHandleSetMaxAmount()}
									>
										<Trans>Max</Trans>
									</CommonStyledMaxButton>
								</div>
							}
						/>
					</CardContent>
				</Card>
				<Box className='flex items-center space-x-1'>
					<LongShortTag
						side={item.side}
						baseSymbol={shortenSymbolNative(item.baseToken, currentChainId)}
						quoteSymbol={QUOTE_USD}
						margin={item.netMargin}
						leverage={item.leverage}
						type='base'
					/>
				</Box>
				<Box className='space-y-1'>
					<Cell
						label={
							<Typography variant='body2'>
								<Trans>Margin</Trans>
							</Typography>
						}
						value={
							<ToNext
								value={formatNumber(item.netMargin, 2, true)}
								nextValue={formatNumber(targetInitialMargin, 2, true)}
								hasNext={isPositive(targetInitialMargin)}
							/>
						}
						unit={shortenSymbol(quoteToken)}
					/>
					<Cell
						label={
							<Typography variant='body2'>
								<Trans>Leverage</Trans>
							</Typography>
						}
						value={
							<ToNext
								value={
									<Leverage
										value={item.leverage as string}
										margin={item.netMargin}
									/>
								}
								nextValue={formatLeverage(targetLeverage)}
								hasNext={isPositive(targetLeverage)}
							/>
						}
					/>
					<Cell
						label={
							<Typography variant='body2'>
								<Trans>Size</Trans>
							</Typography>
						}
						value={amountFormatter(
							item.size,
							2,
							item.baseToken.positionUnits,
							MAX_AMOUNT_FORMATTER_LIMIT
						)}
						unit={shortenSymbolNative(item.baseToken, currentChainId)}
					/>
					{poolInfo && (
						<Cell
							label={
								<Typography variant='body2'>
									<Trans>Index Price</Trans>
								</Typography>
							}
							value={toQuoteAmount(item.indexPrice, item.baseToken.precision)}
						/>
					)}

					{/* <Cell
						label={
							<Typography variant='body2'>
								<Trans>Entry Price</Trans>
							</Typography>
						}
						value={toQuoteAmount(item.entryPrice)}
					/> */}

					<Cell
						label={
							<Typography variant='body2'>
								<Trans>Liq. Price</Trans>
							</Typography>
						}
						value={
							<ToNext
								value={
									<LiqPrice
										value={item.liqPrice}
										margin={item.netMargin}
										precision={item.baseToken.precision}
									/>
								}
								nextValue={
									<LiqPrice
										value={targetLiqPrice}
										margin={targetInitialMargin}
										precision={item.baseToken.precision}
									/>
								}
								hasNext={targetLiqPrice !== ''}
							/>
						}
					/>
				</Box>
				<CommonStyledDivider />
				<Box className='space-y-1'>
					<ExecutionFee />
				</Box>
				<Box>
					<ApproveButtonV2
						componentVariant='confirm'
						fullWidth
						onClick={onConfirm}
						disabled={disabled}
						amountToApprove={currentMarginDelta}
						isApproveToken={adjustType === Transaction_Type.IncreaseMarginV2}
					>
						{submitText}
					</ApproveButtonV2>
				</Box>
			</article>
		</Dialog>
	);
};

export default AdjustMarginDialog;
