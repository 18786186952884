import React, { ReactNode } from 'react';

import { Typography, useTheme } from '@mui/material';

import Tooltip from 'components/Common/Tooltip';

export default React.memo(function Stronger({
	value,
	tooltip,
	isUnderline = true,
	placement
}: {
	value: ReactNode;
	tooltip?: ReactNode;
	isUnderline?: boolean;
	placement?: 'bottom' | 'top';
}) {
	const theme = useTheme();
	return (
		<Tooltip noTooltip={!tooltip} title={tooltip} placement={placement}>
			<Typography
				className='inline-block leading-none cursor-default w-fit'
				component='div'
				variant='body2'
				sx={{
					borderBottom: tooltip
						? isUnderline
							? `1px dashed ${theme.palette.secondary.main}`
							: null
						: null
				}}
			>
				{value}
			</Typography>
		</Tooltip>
	);
});
