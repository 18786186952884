import { useMemo } from 'react';

import { Side } from 'config/constants';

import { useAppSelector } from 'state/hooks';
import { selectCurrentPool } from 'state/pools/selector';

export function useIndexPriceForTrade(side: Side) {
	const currentPool = useAppSelector(selectCurrentPool);

	return useMemo(() => {
		if (!currentPool) {
			return {
				indexPrice: '',
				indexPriceX96: '',
				maxIndexPriceX96: ''
			};
		}
		if (side === Side.LONG) {
			return {
				indexPrice: currentPool.max_index_price,
				indexPriceX96: currentPool.max_index_price_x96,
				maxIndexPriceX96: currentPool.max_index_price_x96
			};
		} else {
			return {
				indexPrice: currentPool.min_index_price,
				indexPriceX96: currentPool.min_index_price_x96,
				maxIndexPriceX96: currentPool.max_index_price_x96
			};
		}
	}, [currentPool, side]);
}
