import { useContext } from 'react';

import { Trans } from '@lingui/macro';
import { Box, useTheme } from '@mui/material';
import { Side } from 'config/constants';

import CommonButton from 'components/Common/StyledButton';

import { ComputerContext } from './ComputerContext';

export default function ComputerDirection() {
	const { side, setSide } = useContext(ComputerContext);

	const theme = useTheme();

	const onHandleChangeTab = (value: Side) => {
		setSide(value);
	};

	return (
		<div className='relative flex'>
			<Box
				className={`absolute left-0 h-full w-full rounded-lg`}
				sx={{ backgroundColor: theme.custom.cardBg }}
			></Box>
			<div className='relative z-9 flex-1'>
				<CommonButton
					fullWidth
					componentVariant={side === Side.LONG ? 'long' : 'action'}
					onClick={() => onHandleChangeTab(Side.LONG)}
					className='font-medium'
				>
					<Trans>Long</Trans>
				</CommonButton>
			</div>
			<div className='relative z-9 flex-1'>
				<CommonButton
					fullWidth
					componentVariant={side === Side.SHORT ? 'short' : 'action'}
					onClick={() => onHandleChangeTab(Side.SHORT)}
					variant='contained'
					color='inherit'
					className='font-medium'
				>
					<Trans>Short</Trans>
				</CommonButton>
			</div>
		</div>
	);
}
