import { t } from '@lingui/macro';
import {
	APP_TOKEN_SYMBOL,
	QUOTE_USD,
	Side,
	USDT_TOKEN_SYMBOL
} from 'config/constants';

import { getPrefixForProfitLoss, isPositive } from 'utils';

export class TransactionDescription {
	// 流动性: 请求创建流动性
	public static CreateOpenLiquidityPosition(
		poolSymbol: string,
		liquidityDelta: string,
		marginDelta: string
	) {
		return t`Add Liquidity: Request to add liquidity ${poolSymbol}, Liquidity +${liquidityDelta}, Margin +${marginDelta} ${QUOTE_USD}`;
	}

	// 流动性: 请求调整保证金
	public static CreateAdjustLiquidityPositionMargin(
		poolSymbol: string,
		marginDelta: string,
		increase: boolean
	) {
		if (increase) {
			return t`Adjust Margin: Request to add ${poolSymbol} liquidity margin, Margin +${marginDelta} ${QUOTE_USD}`;
		} else {
			return t`Adjust Margin: Request to reduce ${poolSymbol} liquidity margin, Margin -${marginDelta} ${QUOTE_USD}`;
		}
	}

	// 流动性：请求全部移除
	public static CreateCloseLiquidityPosition(
		poolSymbol: string,
		liquidityDelta: string,
		marginDelta: string
	) {
		return t`Remove Liquidity: Request to remove ${poolSymbol} liquidity, Liquidity -${liquidityDelta}, Margin -${marginDelta} ${QUOTE_USD}`;
	}

	// 交易：请求创建仓位
	public static CreatePosition(
		baseSymbol: string,
		sizeDelta: string | number,
		acceptablePrice: string,
		side: Side
	) {
		if (side === Side.LONG) {
			return t`Long: Request to open ${baseSymbol} Long, Size +${sizeDelta} ${baseSymbol}, Acceptable price: ≤${acceptablePrice}`;
		} else {
			return t`Short: Request to open ${baseSymbol} Short, Size +${sizeDelta} ${baseSymbol}, Acceptable price: ≥${acceptablePrice}`;
		}
	}

	// 交易：请求增加保证金
	public static CreateIncreasePosition(
		poolSymbol: string,
		marginDelta: string,
		side: Side
	) {
		if (side === Side.LONG) {
			return t`Adjust Margin: Request to add ${poolSymbol} Long position margin, Margin +${marginDelta} ${QUOTE_USD}`;
		} else {
			return t`Adjust Margin: Request to add ${poolSymbol} Short position margin, Margin +${marginDelta} ${QUOTE_USD}`;
		}
	}

	// 交易：请求减少保证金
	public static CreateDecreasePosition(
		poolSymbol: string,
		marginDelta: string,
		side: Side
	) {
		if (side === Side.LONG) {
			return t`Adjust Margin: Request to reduce ${poolSymbol} Long position margin, Margin -${marginDelta} ${QUOTE_USD}`;
		} else {
			return t`Adjust Margin: Request to reduce ${poolSymbol} Short position margin, Margin -${marginDelta} ${QUOTE_USD}`;
		}
	}

	// 交易：请求平仓
	public static ClosePosition(
		poolSymbol: string,
		baseSymbol: string,
		sizeDelta: string | number,
		acceptablePrice: string,
		side: Side
	) {
		if (side === Side.LONG) {
			return t`Close Long: Request to close ${poolSymbol} Long, Size -${sizeDelta} ${baseSymbol}, Acceptable price: ≥${acceptablePrice}`;
		} else {
			return t`Close Short: Request to close ${poolSymbol} Short, Size -${sizeDelta} ${baseSymbol}, Acceptable price: ≤${acceptablePrice}`;
		}
	}

	// 创建止盈：全部仓位
	public static CreateTakeProfitPositionOrders(
		poolSymbol: string,
		baseSymbol: string,
		sizeDelta: string,
		price: string,
		side: Side,
		triggerAbove: boolean
	) {
		const prefix = getPrefixForProfitLoss(triggerAbove, side);
		return t`Position TP: ${poolSymbol}·${formatSide(
			side
		)}, size -${sizeDelta} ${baseSymbol}, TP trigger price ${prefix}${price}`;
	}

	// 修改止盈：全部仓位
	public static UpdateTakeProfitPositionOrders(
		poolSymbol: string,
		price: string,
		side: Side,
		triggerAbove: boolean
	) {
		const prefix = getPrefixForProfitLoss(triggerAbove, side);
		return t`Modify Position TP: ${poolSymbol}·${formatSide(
			side
		)}, modified trigger price ${prefix}${price}`;
	}

	// 取消止盈：全部仓位
	public static CancelTakeProfitPositionOrders(
		poolSymbol: string,
		baseSymbol: string,
		price: string,
		side: Side,
		triggerAbove: boolean,
		sizeDelta: string
	) {
		const prefix = getPrefixForProfitLoss(triggerAbove, side);
		return t`Cancel Position TP: ${poolSymbol}·${formatSide(
			side
		)}, size -${sizeDelta} ${baseSymbol}, trigger price ${prefix}${price}`;
	}

	// 创建止损：全部仓位
	public static CreateStopLossPositionOrders(
		poolSymbol: string,
		baseSymbol: string,
		sizeDelta: string,
		price: string,
		side: Side,
		triggerAbove: boolean
	) {
		const prefix = getPrefixForProfitLoss(triggerAbove, side);
		return t`Position SL: ${poolSymbol}·${formatSide(
			side
		)}, size -${sizeDelta} ${baseSymbol}, SL trigger price ${prefix}${price}`;
	}

	// 创建止盈：固定数量
	public static CreateTakeProfitPartialOrders(
		poolSymbol: string,
		baseSymbol: string,
		sizeDelta: string,
		price: string,
		side: Side,
		triggerAbove: boolean
	) {
		const prefix = getPrefixForProfitLoss(triggerAbove, side);
		return t`Partial TP: ${poolSymbol}·${formatSide(
			side
		)}, size -${sizeDelta} ${baseSymbol}, TP trigger price ${prefix}${price}`;
	}

	// 创建止损：固定数量
	public static CreateStopLossPartialOrders(
		poolSymbol: string,
		baseSymbol: string,
		sizeDelta: string,
		price: string,
		side: Side,
		triggerAbove: boolean
	) {
		const prefix = getPrefixForProfitLoss(triggerAbove, side);
		return t`Partial SL: ${poolSymbol}·${formatSide(
			side
		)}, size -${sizeDelta} ${baseSymbol}, SL trigger price ${prefix}${price}`;
	}

	// 修改止损：全部仓位
	public static UpdateStopLossPositionOrders(
		poolSymbol: string,
		price: string,
		side: Side,
		triggerAbove: boolean
	) {
		const prefix = getPrefixForProfitLoss(triggerAbove, side);
		return t`Modify Position SL: ${poolSymbol}·${formatSide(
			side
		)}, modified trigger price ${prefix}${price}`;
	}

	// 取消止损：全部仓位
	public static CancelStopLossPositionOrders(
		poolSymbol: string,
		baseSymbol: string,
		price: string,
		side: Side,
		triggerAbove: boolean,
		sizeDelta: string
	) {
		const prefix = getPrefixForProfitLoss(triggerAbove, side);
		return t`Cancel Position SL: ${poolSymbol}·${formatSide(
			side
		)}, size -${sizeDelta} ${baseSymbol}, trigger price ${prefix}${price}`;
	}

	// 修改止盈：固定数量
	public static UpdateTakeProfitPartialOrders(
		poolSymbol: string,
		price: string,
		side: Side,
		triggerAbove: boolean
	) {
		const prefix = getPrefixForProfitLoss(triggerAbove, side);
		return t`Modify Partial TP: ${poolSymbol}·${formatSide(
			side
		)}, modified trigger price ${prefix}${price}`;
	}

	// 取消止盈：固定数量
	public static CancelTakeProfitPartialOrders(
		poolSymbol: string,
		baseSymbol: string,
		price: string,
		side: Side,
		triggerAbove: boolean,
		sizeDelta: string
	) {
		const prefix = getPrefixForProfitLoss(triggerAbove, side);
		return t`Cancel Partial TP: ${poolSymbol}·${formatSide(
			side
		)}, size -${sizeDelta} ${baseSymbol}, trigger price ${prefix}${price}`;
	}

	// 修改止损：固定数量
	public static UpdateStopLossPartialOrders(
		poolSymbol: string,
		price: string,
		side: Side,
		triggerAbove: boolean
	) {
		const prefix = getPrefixForProfitLoss(triggerAbove, side);
		return t`Modify Partial SL: ${poolSymbol}·${formatSide(
			side
		)}, modified trigger price ${prefix}${price}`;
	}

	// 取消止损：固定数量
	public static CancelStopLossPartialOrders(
		poolSymbol: string,
		baseSymbol: string,
		price: string,
		side: Side,
		triggerAbove: boolean,
		sizeDelta: string
	) {
		const prefix = getPrefixForProfitLoss(triggerAbove, side);
		return t`Cancel Partial SL: ${poolSymbol}·${formatSide(
			side
		)}, size -${sizeDelta} ${baseSymbol}, trigger price ${prefix}${price}`;
	}

	// limit：创建计划仓位
	public static CreateLimitOrder(
		poolSymbol: string,
		baseSymbol: string,
		sizeDelta: string,
		triggerPrice: string,
		triggerAbove: boolean,
		side: Side
	) {
		const triggerPriceAbove = triggerAbove
			? `≥${triggerPrice}`
			: `≤${triggerPrice}`;
		if (side === Side.LONG) {
			return t`Limit Long: Open ${poolSymbol} Long, Size +${sizeDelta} ${baseSymbol}, trigger price: ${triggerPriceAbove}`;
		} else {
			return t`Limit Short: Open ${poolSymbol} Short, Size +${sizeDelta} ${baseSymbol}, trigger price: ${triggerPriceAbove}`;
		}
	}

	// limit：修改触发价格
	public static UpdatedLimitOrder(
		poolSymbol: string,
		triggerPrice: string,
		triggerAbove: boolean,
		side: Side
	) {
		const triggerPriceAbove = triggerAbove
			? `≥${triggerPrice}`
			: `≤${triggerPrice}`;
		if (side === Side.LONG) {
			return t`Modify Trigger Price: ${poolSymbol} Long, modified trigger price: ${triggerPriceAbove}`;
		} else {
			return t`Modify Trigger Price: ${poolSymbol} Short, modified trigger price: ${triggerPriceAbove}`;
		}
	}

	// limit：撤销order
	public static CancelledLimitOrder(
		poolSymbol: string,
		baseSymbol: string,
		sizeDelta: string,
		triggerPrice: string,
		triggerAbove: boolean,
		side: Side
	) {
		const triggerPriceAbove = triggerAbove
			? `≥${triggerPrice}`
			: `≤${triggerPrice}`;
		if (side === Side.LONG) {
			return t`Cancel: Cancelled limit ${poolSymbol} Long, Size +${sizeDelta} ${baseSymbol}, trigger price: ${triggerPriceAbove}`;
		} else {
			return t`Cancel: Cancelled limit ${poolSymbol} Short, Size +${sizeDelta} ${baseSymbol}, trigger price: ${triggerPriceAbove}`;
		}
	}

	// 执行调整风险缓冲基金
	public static AdjustRiskBufferFund(
		poolSymbol: string,
		marginDelta: string,
		increase: boolean
	) {
		if (increase) {
			return t`Contribute to Risk Buffer Fund: Request to contribute to ${poolSymbol} risk buffer fund, Amount +${marginDelta} ${QUOTE_USD}`;
		} else {
			return t`Withdraw Risk Buffer Fund: Request to withdraw ${poolSymbol} risk buffer fund, Amount -${marginDelta} ${QUOTE_USD}`;
		}
	}

	// 创建Referral Code
	public static CreateReferralCode(tokenId: string, code: string) {
		return t`Referral code creation successful.`;
	}

	// Bind Referral Code
	public static BindReferralCode(code: string) {
		return t`Bind successfully.`;
	}

	// Claim
	public static Claim(quoteAmount?: string, appAmount?: string) {
		// const _quoteAmount = quoteAmount ? quoteAmount.replace(',', '') : quoteAmount;
		// const _appAmount = appAmount ? appAmount.replace(',', '') : appAmount;
		// let suffix = '';
		// if (isPositive(_quoteAmount) && isPositive(_appAmount)) {
		// 	suffix = `${quoteAmount} ${USDT_TOKEN_SYMBOL} ${t`and`} ${appAmount} ${APP_TOKEN_SYMBOL}`;
		// } else if (isPositive(_quoteAmount)) {
		// 	suffix = `${quoteAmount} ${USDT_TOKEN_SYMBOL}`;
		// } else if (isPositive(_appAmount)) {
		// 	suffix = `${appAmount} ${APP_TOKEN_SYMBOL}`;
		// }
		// return `${t`Rewards settlement successful, You will receive`} ${suffix}`;

		return t`Rewards settlement successful.`;
	}
	public static RecordMintMember(
		connectorTokenId: string,
		receivers: Array<string>
	) {
		return t`Minting successful, you used Connector #${connectorTokenId} to mint ${receivers.length} Member NFTs.`;
	}

	public static StakeAppToken(amount: string, period: number) {
		return t`Staking successful, you have staked ${amount} ${APP_TOKEN_SYMBOL}.`;
	}

	public static StakeLpToken(tokenId: string, amount: string) {
		return t`Staking successful, you have staked ${amount} ${APP_TOKEN_SYMBOL} using LP NFT (#${tokenId}).`;
	}

	public static UnStakeAppToken(
		tokenId: string,
		stakedAmount: string,
		claimableAmoount: string
	) {
		return t`Unstaking successful, you will receive ${stakedAmount} ${APP_TOKEN_SYMBOL} and ${claimableAmoount} ${USDT_TOKEN_SYMBOL}.`;
	}

	public static UnStakeLpToken(tokenId: string, claimableAmoount: string) {
		return t`Unstaking successful, you will receive LP NFT (#${tokenId}) and ${claimableAmoount} ${USDT_TOKEN_SYMBOL}.`;
	}

	// limit：撤销order
	public static CancelAllOrders() {
		return t`Orders cancelled successfully.`;
	}
}

function formatSide(side: Side) {
	return side === Side.LONG ? t`Long` : t`Short`;
}
