import { useMemo } from 'react';

import Decimal from 'decimal.js';

import { useAppSelector } from 'state/hooks';
import { settingBaseState } from 'state/setting/slice';

import { div, isPositive, minus, multipliedBy, plus } from '../utils';

export function usePriceRangesByTolerance(
	value: string | undefined,
	slippage?: string | number
) {
	const { slippage: systemSlippage } = useAppSelector(settingBaseState);

	const currentSlippage = useMemo(() => {
		if (!slippage) {
			return systemSlippage;
		}
		return slippage;
	}, [systemSlippage, slippage]);

	const exactInRate = useMemo(() => {
		const base = div(currentSlippage, 100);
		const rate = minus('1', base);
		// console.log('exactInRate: ', rate.toString());
		return rate.toString();
	}, [currentSlippage]);

	const exactOutRate = useMemo(() => {
		const rate = plus(1, div(currentSlippage, 100));
		// console.log('exactOutRate: ', rate.toString());
		return rate.toString();
	}, [currentSlippage]);

	return useMemo(() => {
		if (!isPositive(value)) {
			return {
				minPrice: '',
				maxPrice: '',
				exactInRate,
				exactOutRate
			};
		}
		const minPrice = multipliedBy(value, exactInRate);
		const maxPrice = multipliedBy(value, exactOutRate);
		return {
			minPrice,
			maxPrice,
			exactInRate,
			exactOutRate
		};
	}, [value, exactInRate, exactOutRate]);
}

export function useLeverageByTolerance(
	value: string | number | undefined,
	slippage?: string | number
) {
	const { slippage: systemSlippage } = useAppSelector(settingBaseState);

	const currentSlippage = useMemo(() => {
		if (!slippage) {
			return systemSlippage;
		}
		return slippage;
	}, [systemSlippage, slippage]);

	const exactInRate = useMemo(() => {
		const base = div(currentSlippage, 100);
		const rate = minus('1', base);
		// console.log('exactInRate: ', rate.toString());
		return rate.toString();
	}, [currentSlippage]);

	return useMemo(() => {
		if (!value) {
			return '';
		}
		return multipliedBy(value, exactInRate);
	}, [value, exactInRate]);
}

export function useSlippageTolerance() {
	const { slippage } = useAppSelector(settingBaseState);

	const baseRateFromTolerance = useMemo(() => {
		// console.log('slippageTolerance: ' + slippageTolerance);
		const base = div(slippage, 100);
		return new Decimal(1).minus(base);
	}, [slippage]);

	const exactInRate = useMemo(() => {
		const base = div(slippage, 100);
		const rate = minus('1', base);
		// console.log('exactInRate: ', rate.toString());
		return rate.toString();
	}, [slippage]);

	const exactOutRate = useMemo(() => {
		const rate = plus(1, div(slippage, 100));
		// console.log('exactOutRate: ', rate.toString());
		return rate.toString();
	}, [slippage]);

	return {
		baseRateFromTolerance,
		exactInRate,
		exactOutRate
	};
}
