import { Reducer, createSlice } from '@reduxjs/toolkit';

import {
	IMemberItem,
	IReferralCodeItem
} from 'graphql/useMembersReferralsGraph';
import { RootState } from 'state';

export interface IReferralsClaim {
	rewardsEQU: string;
	unclaimed: string;
	id: any;
	memberId: string;
	referralLiquidityReward: string;
	referralPositionReward: string;
	referralFeeReward: string;
}

export interface IRewardConfig {
	id: string;
	liquidityRate: string;
	referralParentTokenRate: string;
	referralTokenRate: string;
	rewardCap: string;
	riskBufferFundLiquidityRate: string;
}

export interface IReferralRewardClaimItem {
	id: string;
	rewardsEQU: string;
	newRewardsEQU: string;
	unclaimed: string;
	claimed: string;
	pools: Array<string>;
	rewards: any[];
	liquidity?: number;
	position?: number;
}

interface StateProps {
	membersLoading: boolean;
	membersAddress: string;
	membersList: Array<IMemberItem> | null;
	membersRewardList: Array<IReferralRewardClaimItem> | null;
	membersRefereesAddressList: Array<any> | null;
	memberPoolPositionList: Array<any> | null;
	connectorsLoading: boolean;
	connectorsList: Array<IReferralRewardClaimItem> | null;
	connectorsRewardList: Array<IReferralRewardClaimItem> | null;
	rewardsConfig: IRewardConfig | null;
	allReferralCodes: Array<IReferralCodeItem> | null;
}

const initialState: StateProps = {
	membersLoading: true,
	membersAddress: '',
	membersList: null,
	membersRewardList: null,
	membersRefereesAddressList: null,
	memberPoolPositionList: null,
	connectorsLoading: true,
	connectorsList: null,
	connectorsRewardList: null,
	rewardsConfig: null,
	allReferralCodes: null
};

export const slice = createSlice({
	name: 'referrals',
	initialState,
	reducers: {
		setMembersLoading(state, { payload }) {
			state.membersLoading = payload;
		},
		setMembersAddress(state, { payload }) {
			state.membersAddress = payload;
		},
		setMembersList(state, { payload }) {
			state.membersList = payload;
		},
		setMembersRewardList(state, { payload }) {
			state.membersRewardList = payload;
		},
		setMembersRefereesAddressList(state, { payload }) {
			state.membersRefereesAddressList = payload;
		},
		setMemberPoolPositionList(state, { payload }) {
			state.memberPoolPositionList = payload;
		},
		setConnectorsLoading(state, { payload }) {
			state.connectorsLoading = payload;
		},
		setConnectorsList(state, { payload }) {
			state.connectorsList = payload;
		},
		setConnectorsRewardList(state, { payload }) {
			state.connectorsRewardList = payload;
		},
		setRewardsConfig(state, { payload }) {
			state.rewardsConfig = payload;
		},
		setAllReferralCodes(state, { payload }) {
			state.allReferralCodes = payload;
		}
	}
});

export const {
	setMembersLoading,
	setMembersAddress,
	setMembersList,
	setMembersRewardList,
	setMembersRefereesAddressList,
	setMemberPoolPositionList,
	setConnectorsLoading,
	setConnectorsList,
	setConnectorsRewardList,
	setRewardsConfig,
	setAllReferralCodes
} = slice.actions;

export const referralBaseState = (state: RootState) => state.referrals;

export default slice.reducer as Reducer<typeof initialState>;
