import { useMemo } from 'react';

import { FeeDistributorABI } from 'config/abis';
import { Contract_Write_Mode, Transaction_Type } from 'config/constants';
import { getContractAddress } from 'config/contracts';
import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { RecordStakeLpToken } from 'types';
import { formatNumber, isPositive } from 'utils';

import { ApprovalState } from './useApproval';
import { useComputeGasLimit } from './useComputeGasLimit';
import { useCurrentChain } from './useCurrentChain';
import { useSendTransaction } from './useSendTransaction';
import { UniLiquidityTokenRender } from './V3/types';
import { getPercentByFeeAmount } from './V3/utils';

export function useSubmitStakeLpToken(
	liquidityToken: UniLiquidityTokenRender | null,
	period: number,
	multiplier: number,
	approvalState: ApprovalState,
) {
	const { appToken, appTokenBalance } = useAppSelector(txBaseState);

	const { currentChainId } = useCurrentChain();

	const { address } = useAccount();

	const { args, record, description } = useMemo(() => {
		if (
			!liquidityToken ||
			!appToken ||
			!isPositive(period) ||
			!isPositive(liquidityToken.baseAmount) ||
			!address ||
			approvalState !== ApprovalState.APPROVED
		) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		console.log('useSubmitStakeLpToken args begin ...');

		const { id: tokenId, baseAmount } = liquidityToken;

		const args = [tokenId, address, period];

		const record = {
			transactionType: Transaction_Type.StakeLpToken,
			tokenId,
			period,
			multiplier,
			amount: baseAmount,
		} as RecordStakeLpToken;

		const description = TransactionDescription.StakeLpToken(
			tokenId,
			formatNumber(baseAmount, appToken.positionUnits),
		);

		return {
			args,
			record,
			description
		};
	}, [
		appToken,
		address,
		period,
		multiplier,
		appTokenBalance,
		approvalState,
		liquidityToken
	]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address: getContractAddress(currentChainId, 'FeeDistributor'),
			abi: FeeDistributorABI,
			functionName: 'stakeV3Pos',
			args
		} as UseContractWriteConfig;
	}, [currentChainId, args]);

	const { gasLimit } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirmed, isConfirming } = useSendTransaction(
		contractWriteArgs,
		gasLimit
	);

	const onConfirm = () => {
		if (!record) return;
		onSendTransaction(record, description);
	};

	return {
		onConfirm,
		isConfirming,
		isConfirmed
	};
}
