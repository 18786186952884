import { Avatar, useTheme } from '@mui/material';
import cn from 'classnames';

import lightLogo from 'assets/svg/v2/lightLogo.svg';
import logo from 'assets/svg/v2/logo.svg';

interface V2LogoProps {
	className?: string;
	width?: number;
	height?: number;
}

export default function V2Logo(props: V2LogoProps) {
	const { className, width = 24, height = 18 } = props;
	const theme = useTheme();

	return (
		<Avatar
			alt='V2'
			src={theme.mode === 'light' ? lightLogo : logo}
			sx={{ width: width, height: height }}
			className={cn(`rounded-none ${className}`)}
		/>
	);
}
