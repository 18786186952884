import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import {
	MAX_AMOUNT_FORMATTER_LIMIT,
	Q96,
	Side, // Transaction_Status
	Version
} from 'config/constants';
import Decimal from 'decimal.js';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import { get, isEmpty, isNull } from 'lodash';
import { useAppSelector } from 'state/hooks';
import { liquidityBaseState } from 'state/liquidity/slice';
import {
	amountFormatter,
	checkOrderIsExpired,
	div,
	formatLeverage,
	formatModuleNumber,
	formatNumber,
	isLessThanOrEqualTo,
	isNegative,
	isPositive,
	isZero,
	multipliedBy,
	plus,
	shortenSymbol,
	shortenSymbolNative,
	toPercent,
	toQuoteAmount
} from 'utils';

import Cell from 'components/Common/Cell';
import LiqPrice from 'components/LiqPrice';
import TippingUnderline from 'components/TippingUnderline';
import ToNext from 'components/ToNext';

export default function LiquidityPassivePositionV2(props: any) {
	const {
		liquidityPool,
		liquidityValue,
		nextData,
		removeAllLiquidity = false,
		exceeds = false,
		variant = 'large',
		isAdjustMargin,
		nextMargin
	} = props;
	const { liquidityAmount } = useAppSelector(liquidityBaseState);
	const { currentChainId } = useCurrentChain();
	const { isMatchMobile } = useAppBreakpoints();
	const theme = useTheme();
	const estSize = useMemo(() => {
		if (!liquidityPool || !liquidityAmount) {
			return 0;
		}
		const { globalLiquidityPosition } = liquidityPool;
		const { liquidity, liquidationBufferNetSize, netSize } =
			globalLiquidityPosition;

		if (
			isPositive(liquidityPool?.liquidityPositions.length) &&
			liquidityPool?.myLiquidity
		) {
			const liquidityRatio = div(
				plus(liquidityAmount, liquidityPool?.myLiquidity),
				plus(liquidity, liquidityAmount)
			);
			return multipliedBy(
				plus(liquidationBufferNetSize, netSize),
				liquidityRatio
			);
		} else {
			const liquidityRatio = div(
				liquidityAmount,
				plus(liquidity, liquidityAmount)
			);
			return multipliedBy(
				plus(liquidationBufferNetSize, netSize),
				liquidityRatio
			);
		}
	}, [liquidityPool, liquidityAmount]);

	const entryPrice = useMemo(() => {
		if (!liquidityPool || !liquidityAmount) {
			return undefined;
		}
		const { indexPrice } = liquidityPool;
		return indexPrice;
	}, [liquidityPool, liquidityAmount]);
	const passivePositionValue = multipliedBy(
		get(
			liquidityPool,
			'liquidityPositions[0].liquidityPassivePosition.entryPrice',
			0
		),
		get(liquidityPool, 'liquidityPositions[0].liquidityPassivePosition.size', 0)
	);
	const nextLeverage = div(passivePositionValue, nextMargin);

	return (
		<Box
			className='flex-1 p-2 my-4'
			sx={{
				background: theme.custom.selectBg
			}}
		>
			<Typography>
				<Trans>Passive Position</Trans>
			</Typography>
			{variant === 'small' && (
				<Box component='div'>
					<Cell
						label={
							<Typography variant='body2'>
								<TippingUnderline
									tooltip={
										<Trans>
											The estimated passive position size after adding
											liquidity. The passive position will be opened at the
											Index Price, and the actual position size may vary based
											on market conditions.
										</Trans>
									}
									label={<Trans>Est. Size</Trans>}
								/>
							</Typography>
						}
						value={
							<ToNext
								value={
									isPositive(liquidityPool?.liquidityPositions.length) &&
									liquidityPool &&
									Boolean(liquidityAmount) &&
									!isNull(
										liquidityPool?.liquidityPositions[0]
											?.liquidityPassivePosition?.size
									) ? (
										<div
											className={classNames('flex items-center space-x-1', {
												'justify-end': isMatchMobile
											})}
										>
											<aside>
												{liquidityPool?.liquidityPositions[0]
													?.liquidityPassivePosition?.side === Side.LONG && (
													<Typography color='success.main' noWrap>
														<span>
															<Trans>Long</Trans>
														</span>
													</Typography>
												)}
												{liquidityPool?.liquidityPositions[0]
													?.liquidityPassivePosition?.side === Side.SHORT && (
													<Typography color='error.main' noWrap>
														<span>
															<Trans>Short</Trans>
														</span>
													</Typography>
												)}
											</aside>
											<div>
												<Typography color='text.primary'>
													{amountFormatter(
														liquidityPool?.liquidityPositions[0]
															?.liquidityPassivePosition?.size,
														2,
														liquidityPool?.baseToken.positionUnits,
														MAX_AMOUNT_FORMATTER_LIMIT
													)}{' '}
												</Typography>
											</div>
										</div>
									) : isPositive(liquidityAmount) && isPositive(estSize) ? (
										<div
											className={classNames('flex items-center space-x-1', {
												'justify-end': isMatchMobile
											})}
										>
											<aside>
												{liquidityPool?.globalLiquidityPosition.side ===
													Side.LONG && (
													<Typography color='success.main' noWrap>
														<span>
															<Trans>Long</Trans>
														</span>
													</Typography>
												)}
												{liquidityPool?.globalLiquidityPosition.side ===
													Side.SHORT && (
													<Typography color='error.main' noWrap>
														<span>
															<Trans>Short</Trans>
														</span>
													</Typography>
												)}
											</aside>
											<div>
												<Typography color='text.primary'>
													{amountFormatter(
														estSize,
														2,
														liquidityPool?.baseToken.positionUnits,
														MAX_AMOUNT_FORMATTER_LIMIT
													)}{' '}
												</Typography>
											</div>
										</div>
									) : (
										<Typography variant='body1' fontWeight={500}>
											-
										</Typography>
									)
								}
								nextValue={
									<Typography color='text.primary'>
										{amountFormatter(
											estSize,
											2,
											liquidityPool?.baseToken.positionUnits,
											MAX_AMOUNT_FORMATTER_LIMIT
										)}{' '}
									</Typography>
								}
								hasNext={
									isPositive(liquidityPool?.liquidityPositions.length) &&
									liquidityPool?.myLiquidity &&
									Boolean(liquidityAmount) &&
									!isNull(
										liquidityPool?.liquidityPositions[0]
											?.liquidityPassivePosition?.size
									)
								}
							/>
						}
						unit={
							isPositive(liquidityAmount) && isPositive(estSize)
								? shortenSymbolNative(liquidityPool?.baseToken, currentChainId)
								: null
						}
					/>

					<Cell
						label={<Trans>Entry Price</Trans>}
						value={
							<ToNext
								value={
									isPositive(liquidityPool?.liquidityPositions.length) &&
									liquidityPool?.myLiquidity &&
									Boolean(liquidityAmount)
										? toQuoteAmount(
												liquidityPool?.liquidityPositions[0]
													?.liquidityPassivePosition?.entryPrice,
												liquidityPool?.baseToken.precision
										  )
										: isPositive(entryPrice) &&
										  (isPositive(
												liquidityPool?.globalLiquidityPosition
													?.liquidationBufferNetSize
										  ) ||
												isPositive(
													liquidityPool?.globalLiquidityPosition?.netSize
												))
										? toQuoteAmount(
												entryPrice,
												liquidityPool?.baseToken.precision
										  )
										: '-'
								}
								nextValue={toQuoteAmount(
									entryPrice,
									liquidityPool?.baseToken.precision
								)}
								hasNext={
									isPositive(liquidityPool?.liquidityPositions.length) &&
									liquidityPool?.myLiquidity &&
									Boolean(liquidityAmount) &&
									!isNull(
										liquidityPool?.liquidityPositions[0]
											?.liquidityPassivePosition?.size
									)
								}
							/>
						}
					/>
				</Box>
			)}

			{variant === 'large' && (
				<Box component='div'>
					<Cell
						label={<Trans>Size</Trans>}
						value={
							<ToNext
								value={
									isPositive(liquidityPool?.liquidityPositions.length) &&
									isPositive(
										liquidityPool?.liquidityPositions[0]
											?.liquidityPassivePosition?.side
									) &&
									liquidityPool ? (
										<div
											className={classNames('flex items-center space-x-1', {
												'justify-end': isMatchMobile
											})}
										>
											<aside>
												{liquidityPool?.liquidityPositions[0]
													?.liquidityPassivePosition?.side === Side.LONG && (
													<Typography color='success.main' noWrap>
														<span>
															<Trans>Long</Trans>
														</span>
													</Typography>
												)}
												{liquidityPool?.liquidityPositions[0]
													?.liquidityPassivePosition?.side === Side.SHORT && (
													<Typography color='error.main' noWrap>
														<span>
															<Trans>Short</Trans>
														</span>
													</Typography>
												)}
											</aside>
											<div>
												<Typography color='text.primary'>
													{amountFormatter(
														liquidityPool?.liquidityPositions[0]
															?.liquidityPassivePosition?.size,
														2,
														liquidityPool?.baseToken.positionUnits,
														MAX_AMOUNT_FORMATTER_LIMIT
													)}{' '}
												</Typography>
											</div>
										</div>
									) : isPositive(liquidityAmount) && isPositive(estSize) ? (
										<div
											className={classNames('flex items-center space-x-1', {
												'justify-end': isMatchMobile
											})}
										>
											<aside>
												{liquidityPool?.globalLiquidityPosition.side ===
													Side.LONG && (
													<Typography color='success.main' noWrap>
														<span>
															<Trans>Long</Trans>
														</span>
													</Typography>
												)}
												{liquidityPool?.globalLiquidityPosition.side ===
													Side.SHORT && (
													<Typography color='error.main' noWrap>
														<span>
															<Trans>Short</Trans>
														</span>
													</Typography>
												)}
											</aside>
											<div>
												<Typography color='text.primary'>
													{amountFormatter(
														estSize,
														2,
														liquidityPool?.baseToken.positionUnits,
														MAX_AMOUNT_FORMATTER_LIMIT
													)}
												</Typography>
											</div>
										</div>
									) : (
										<Typography variant='body1' fontWeight={500}>
											-
										</Typography>
									)
								}
								nextValue={
									<Typography color='text.primary'>
										{amountFormatter(
											nextData.afterSize,
											2,
											liquidityPool?.baseToken.positionUnits,
											MAX_AMOUNT_FORMATTER_LIMIT
										)}
									</Typography>
								}
								hasNext={
									isPositive(liquidityValue) &&
									Boolean(
										liquidityPool?.liquidityPositions[0]
											?.liquidityPassivePosition.size
									) &&
									!exceeds
								}
							/>
						}
						unit={shortenSymbolNative(liquidityPool?.baseToken, currentChainId)}
					/>
					{isAdjustMargin && (
						<Cell
							label={<Trans>Leverage</Trans>}
							value={
								<ToNext
									value={formatLeverage(
										get(
											liquidityPool,
											'liquidityPositions[0].liquidityPassivePosition.myLeverage',
											0
										)
									)}
									nextValue={formatLeverage(nextLeverage)}
									hasNext={Boolean(nextMargin)}
								/>
							}
						/>
					)}
					<Cell
						label={<Trans>Entry Price</Trans>}
						value={
							<ToNext
								value={toQuoteAmount(
									liquidityPool?.liquidityPositions[0]?.liquidityPassivePosition
										?.entryPrice,
									liquidityPool?.baseToken.precision
								)}
								nextValue={toQuoteAmount(
									liquidityPool?.indexPrice,
									liquidityPool?.baseToken.precision
								)}
								hasNext={
									isPositive(liquidityPool?.indexPrice) &&
									isPositive(nextData.afterEntryPrice) &&
									!isEmpty(
										liquidityPool?.liquidityPositions[0]
											?.liquidityPassivePosition
									) &&
									liquidityPool?.liquidityPositions[0]?.liquidityPassivePosition
										?.entryPrice
								}
							/>
						}
					/>
					<Cell
						label={<Trans>Liq. Price</Trans>}
						value={
							<ToNext
								value={
									isNull(
										liquidityPool?.liquidityPositions[0]
											?.liquidityPassivePosition?.liquidationPrice
									) ? (
										<Typography variant='body1' fontWeight={500}>
											-
										</Typography>
									) : isPositive(
											liquidityPool?.liquidityPositions[0]
												?.liquidityPassivePosition?.liquidationPrice
									  ) ? (
										<LiqPrice
											value={
												liquidityPool?.liquidityPositions[0]
													?.liquidityPassivePosition?.liquidationPrice
											}
											precision={liquidityPool?.baseToken.precision}
										/>
									) : (
										'-'
									)
								}
								nextValue={
									<Typography color='text.primary'>
										<LiqPrice
											value={nextData.afterLiqPrice}
											precision={liquidityPool?.baseToken.precision}
										/>
									</Typography>
								}
								hasNext={
									nextData.afterLiqPrice &&
									isPositive(liquidityValue) &&
									nextData &&
									Boolean(
										liquidityPool?.liquidityPositions[0]
											?.liquidityPassivePosition.size
									) &&
									!removeAllLiquidity
								}
							/>
						}
					/>
					<Cell
						label={<Trans>Unrealized PnL</Trans>}
						value={
							isPositive(liquidityPool?.liquidityPositions.length) &&
							isPositive(
								liquidityPool?.liquidityPositions[0]?.liquidityPassivePosition
									?.side
							) &&
							liquidityPool ? (
								<Typography
									variant='body2'
									color={
										isPositive(
											liquidityPool?.liquidityPositions[0]
												?.liquidityPassivePosition?.pnl
										)
											? theme.palette.success.main
											: isNegative(
													liquidityPool?.liquidityPositions[0]
														?.liquidityPassivePosition?.pnl
											  )
											? theme.palette.error.main
											: theme.palette.text.primary
									}
								>
									{toQuoteAmount(
										liquidityPool?.liquidityPositions[0]
											?.liquidityPassivePosition?.pnl,
										2,
										Decimal.ROUND_DOWN,
										true
									)}
								</Typography>
							) : (
								<Typography variant='body1' fontWeight={500}>
									-
								</Typography>
							)
						}
					/>
					<Cell
						label={
							<Typography variant='body2'>
								<Trans>Realized PnL</Trans>
							</Typography>
						}
						value={
							<ToNext
								value={
									isPositive(liquidityPool?.liquidityPositions.length) &&
									isPositive(
										liquidityPool?.liquidityPositions[0]
											?.liquidityPassivePosition?.side
									) &&
									liquidityPool ? (
										<Typography
											variant='body2'
											color={
												isPositive(liquidityPool?.liquidityPositions[0]?.pnl)
													? theme.palette.success.main
													: isNegative(
															liquidityPool?.liquidityPositions[0]?.pnl
													  )
													? theme.palette.error.main
													: theme.palette.text.primary
											}
										>
											{toQuoteAmount(
												liquidityPool?.liquidityPositions[0]?.pnl,
												2,
												Decimal.ROUND_DOWN,
												true
											)}
										</Typography>
									) : (
										<Typography variant='body1' fontWeight={500}>
											-
										</Typography>
									)
								}
								nextValue={
									<Typography
										variant='body2'
										color={
											isPositive(nextData.afterPassivePositionPnl)
												? theme.palette.success.main
												: theme.palette.error.main
										}
									>
										{toQuoteAmount(
											nextData.afterPassivePositionPnl,
											2,
											Decimal.ROUND_DOWN,
											true
										)}
									</Typography>
								}
								hasNext={
									!isZero(nextData.afterPassivePositionPnl) &&
									isPositive(liquidityValue) &&
									isPositive(liquidityPool?.liquidityPositions.length) &&
									isPositive(
										liquidityPool?.liquidityPositions[0]
											?.liquidityPassivePosition?.side
									) &&
									liquidityPool
								}
							/>
						}
					/>
				</Box>
			)}
		</Box>
	);
}
