import { styled } from '@mui/material'
import { dataRanges } from 'config/constants'
import Box from '@mui/material/Box'

export default function Ranges(props: any) {
	const { onChange, value = 'ALL' } = props
	const handleChange = (e: any, value: any) => {
		onChange(value)
	}
	return (
		<StyledGroupRanges className='inline-block space-x-3 rounded-sm px-2 py-3'>
			{dataRanges.map(item => (
				<Box
					className={`period inline cursor-pointer ${
						item.value === value ? 'active' : ''
					}`}
					key={item.value}
					onClick={(e: any) => handleChange(e, item)}
				>
					{item.label}
				</Box>
			))}
		</StyledGroupRanges>
	)
}

const StyledGroupRanges = styled(Box)`
	background: ${({ theme }) => theme.palette.background.paper};
	border-radius: 4px;
	.period {
		border-radius: 4px;
		padding: 6px 14px;
		font-weight: 500;
		color: ${({ theme }) => theme.palette.text.secondary};
		&.active {
			background: ${({ theme }) => theme.custom.cardBg};
			color: ${({ theme }) => theme.palette.text.primary};
		}
		&:hover {
			color: ${({ theme }) => theme.palette.text.primary};
		}
	}
`
