import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import {
	App_Theme,
	Dom_Size,
	Page,
	QUOTE_USD,
	Version
} from 'config/constants';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import { isEmpty } from 'lodash';
import FreeTradingFeeWarning, {
	Type_Free_Trading_Fee
} from 'pages/components/FreeTradingFeeWarning';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
	poolsBaseState,
	setPoolAddress,
	setV2PoolAddress
} from 'state/pools/slice';
import {
	div,
	isGreaterThan,
	isNumeric,
	isZero,
	plus,
	shortenSymbolNative,
	toPercent,
	toQuoteAmount
} from 'utils';

import Cell from 'components/Common/Cell';
import { CommonStyledDivider } from 'components/Common/Styled';
import StyledTab from 'components/Common/StyledTab';
import StyledTabs from 'components/Common/StyledTabs';
import SectionLoading from 'components/SectionLoading';

import Stronger from '../../components/Stronger';
import TippingUnderline from '../../components/TippingUnderline';
import LiquidityApr from '../components/V2/LiquidityApr';
import BalanceRateChart from './BalanceRateChart';
import LiquidityInfo from './LiquidityInfo';
import { Tab_Chart_Type } from './LiquidityTable';
import MobileActions from './MobileActions';
import BalanceRateChartV2 from './V2/BalanceRateChart';

export default function LiquidityDetail(props: any) {
	const { pool } = useParams();
	const { poolAddress } = useAppSelector(poolsBaseState);
	const { rows, contentRender } = props;
	const dispatch = useAppDispatch();
	const currentVersion = useAppSelector(state => state.setting.currentVersion);

	const navigate = useNavigate();
	const targetPool = useMemo(() => {
		return poolAddress && poolAddress !== pool ? poolAddress : pool;
	}, [poolAddress, pool]);
	const row = useMemo(() => {
		return rows?.find((row: any) => row.id === targetPool?.toLowerCase()) || {};
	}, [targetPool, rows]);

	useEffect(() => {
		if (isEmpty(row)) {
			return;
		}
		if (targetPool !== poolAddress) {
			if (currentVersion === Version.V1) {
				dispatch(setPoolAddress(row.id));
			} else {
				dispatch(setV2PoolAddress(row.id));
			}
		}
		if (!pool) {
			setTimeout(() => {
				navigate(
					`${
						currentVersion === Version.V1 ? Page.Pools : Page.PoolsV1
					}/${targetPool}`
				);
			}, 300);
		}
	}, [row, currentVersion, pool]);

	const { currentChainId } = useCurrentChain();
	const { isMatchMobile } = useAppBreakpoints();
	const isLogin = useCheckLogin();

	/* 	useEffect(() => {
		if (!isEmpty(row)) {
			return;
		}
		if(targetPool !==)
		
	}, [targetPool, currentVersion, row]); */

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}, []);

	const [activeTab, setActiveTab] = useState<Tab_Chart_Type>(
		Tab_Chart_Type.BalanceRate
	);

	const onHandleChangeActiveTab = (value: Tab_Chart_Type) => {
		setActiveTab(value);
	};
	const theme = useTheme();

	return (
		<Box
			className='h-full'
			sx={{
				paddingBottom: isMatchMobile ? `${Dom_Size.HEIGHT_LARGE_BUTTON}px` : 0,
				background:
					theme.palette.mode === App_Theme.dark
						? theme.palette.background.default
						: theme.custom.positiveColor
			}}
		>
			<div className='flex justify-between w-full py-4 px-3 space-x-3'>
				<section className='flex-1 space-y-4'>
					<Typography className='font-bold' variant='h5'>
						{`${shortenSymbolNative(
							row.baseToken,
							currentChainId
						)}/${QUOTE_USD}`}
					</Typography>
					<Box component='div'>
						{currentVersion === Version.V1 && (
							<Stronger
								value={
									<Typography
										className='leading-none'
										variant='h5'
										color={
											isNumeric(row.maxApr) && isGreaterThan(row.maxApr, 0)
												? 'success.main'
												: 'text.secondary'
										}
									>
										{isNumeric(row.maxApr)
											? toPercent(row.maxApr)
											: toPercent(0)}
									</Typography>
								}
								tooltip={
									<div>
										<Cell
											label={<Trans>Trading Fees Max APR</Trans>}
											value={
												isNumeric(row.liquidityApr)
													? toPercent(row.liquidityApr)
													: toPercent(0)
											}
										/>
										<Cell
											label='EQU Max APR'
											value={
												isNumeric(row.rbfApr)
													? toPercent(row.rbfApr)
													: toPercent(0)
											}
										/>
									</div>
								}
							/>
						)}
						<Box
							sx={{ color: theme.palette.text.secondary }}
							className='flex items-center'
						>
							<TippingUnderline
								placement='bottom'
								tooltip={
									currentVersion === Version.V1 ? (
										<div>
											<Typography>
												<Trans>
													Max APR is calculated based on LPs&#39; trading fee
													income in the past 24 hours, EQU daily emission, and
													the maximum leverage of the pool.
												</Trans>
											</Typography>
											<Typography className='mt-2'>
												<Trans>
													Max APR = Trading Fees Max APR + EQU Max APR
												</Trans>
											</Typography>
										</div>
									) : (
										<Typography>
											<Trans>
												Max APR is calculated based on LPs&apos; fee income in
												the last 7 days, EQU daily emission, and the Max
												Leverage of the pool. PnL from passive position is not
												included.
											</Trans>
										</Typography>
									)
								}
								label={
									<Typography color='text.secondary' variant='body2'>
										<Trans>Max APR</Trans>
									</Typography>
								}
							/>
						</Box>
						{currentVersion === Version.V2 && (
							<Typography className='flex gap-1 mt-0.5' variant='body2'>
								<LiquidityApr item={row} />
							</Typography>
						)}
					</Box>
				</section>
				<section className='flex-1'>
					{currentVersion === Version.V1 && (
						<Cell
							label={<Trans>24h Volume ({QUOTE_USD})</Trans>}
							value={
								<Typography variant='body1'>
									{toQuoteAmount(row.volume24h)}
								</Typography>
							}
						/>
					)}
					<Cell
						label={
							<TippingUnderline
								label={
									currentVersion === Version.V1 ? (
										<Trans>24h Fee</Trans>
									) : (
										<Trans>Fee Income (7d)</Trans>
									)
								}
								tooltip={
									currentVersion === Version.V1 ? (
										<Trans>LPs&apos; fee income in the past 24 hours</Trans>
									) : (
										<Trans>
											LP&apos;s trading fee and funding fee income in the last 7
											days.
										</Trans>
									)
								}
								placement='bottom'
							/>
						}
						value={
							<div className='flex gap-1'>
								<TippingUnderline
									label={
										<Typography variant='body1'>
											{currentVersion === Version.V1
												? toQuoteAmount(row.tradingFee24h)
												: toQuoteAmount(plus(row.fee7d, row.fundingFee7d))}
										</Typography>
									}
									placement='bottom'
									tooltip={
										<div>
											<Cell
												label={<Trans>Trading Fees</Trans>}
												value={toQuoteAmount(row.fee7d)}
											/>
											<Cell
												label={<Trans>Funding Fees</Trans>}
												value={toQuoteAmount(row.fundingFee7d)}
											/>
										</div>
									}
								/>
								{isZero(row.referralDiscountRate) && (
									<FreeTradingFeeWarning type={Type_Free_Trading_Fee.Warning} />
								)}
							</div>
						}
					/>
					<Cell
						label={<Trans>Liquidity</Trans>}
						value={
							<Typography variant='body1'>
								{toQuoteAmount(row.liquidity)}
							</Typography>
						}
					/>
					<Cell
						label={<Trans>My Liquidity</Trans>}
						value={
							<Typography variant='body1'>
								{isLogin ? toQuoteAmount(row.myLiquidity) : '-'}
							</Typography>
						}
					/>
				</section>
			</div>
			<Divider />
			<Box className='relative py-4 px-4 sm:p-3 sm:py-3'>
				<StyledTabs
					variant='standard'
					value={activeTab}
					onChange={(event, newType: number) =>
						onHandleChangeActiveTab(newType)
					}
				>
					<StyledTab
						label={<Trans>Balance Rate</Trans>}
						value={Tab_Chart_Type.BalanceRate}
						className='pl-0'
					/>
					<StyledTab
						label={<Trans>Details</Trans>}
						value={Tab_Chart_Type.Details}
					/>
				</StyledTabs>
				<CommonStyledDivider />
				{activeTab === Tab_Chart_Type.BalanceRate ? (
					<Box
						sx={{
							height: 320
						}}
					>
						{isEmpty(row) ? (
							<SectionLoading full={true} />
						) : currentVersion === Version.V1 ? (
							<BalanceRateChart poolId={row.id} expand={true} poolInfo={row} />
						) : (
							<BalanceRateChartV2 />
						)}
					</Box>
				) : (
					<LiquidityInfo className='bg-inherit' />
				)}
			</Box>
			<Divider />
			{isEmpty(row) ? null : (
				<Box sx={{ minHeight: Dom_Size.HEIGHT_OPERATION }}>
					{contentRender(row)}
				</Box>
			)}
			<MobileActions />
		</Box>
	);
}
