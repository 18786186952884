import { t } from '@lingui/macro';
import { captureException } from '@sentry/react';
import { Side, Transaction_Type } from 'config/constants';

import { isZero } from './mathUtils';

export function getTransactionTypeForProfitLoss(
	triggerAbove: boolean,
	side: Side,
	sizeDelta?: string
): Transaction_Type {
	if (isZero(sizeDelta)) {
		if (side === Side.LONG) {
			return triggerAbove === true
				? Transaction_Type.CreateTakeProfitPosition
				: Transaction_Type.CreateStopLossPosition;
		} else {
			return triggerAbove === true
				? Transaction_Type.CreateStopLossPosition
				: Transaction_Type.CreateTakeProfitPosition;
		}
	} else {
		if (side === Side.LONG) {
			return triggerAbove === true
				? Transaction_Type.CreateTakeProfitPartial
				: Transaction_Type.CreateStopLossPartial;
		} else {
			return triggerAbove === true
				? Transaction_Type.CreateStopLossPartial
				: Transaction_Type.CreateTakeProfitPartial;
		}
	}
}

export function getTransactionTypeForProfitLossV2(
	triggerAbove: boolean,
	side: Side,
	sizeDelta?: string
): Transaction_Type {
	if (isZero(sizeDelta)) {
		if (side === Side.LONG) {
			return triggerAbove === true
				? Transaction_Type.CreateTakeProfitPositionV2
				: Transaction_Type.CreateStopLossPositionV2;
		} else {
			return triggerAbove === true
				? Transaction_Type.CreateStopLossPositionV2
				: Transaction_Type.CreateTakeProfitPositionV2;
		}
	} else {
		if (side === Side.LONG) {
			return triggerAbove === true
				? Transaction_Type.CreateTakeProfitPartialV2
				: Transaction_Type.CreateStopLossPartialV2;
		} else {
			return triggerAbove === true
				? Transaction_Type.CreateStopLossPartialV2
				: Transaction_Type.CreateTakeProfitPartialV2;
		}
	}
}

export function getTransactionTypeForLimit(side: Side) {
	return side === Side.LONG
		? Transaction_Type.LongLimitOrder
		: Transaction_Type.ShortLimitOrder;
}

export function getTransactionTypeForLimitV2(side: Side) {
	return side === Side.LONG
		? Transaction_Type.LongLimitOrderV2
		: Transaction_Type.ShortLimitOrderV2;
}

export function getPrefixForProfitLoss(triggerAbove: boolean, side: Side) {
	if (side === Side.LONG) {
		return triggerAbove === true ? '≥' : '≤';
	} else {
		return triggerAbove === true ? '≥' : '≤';
	}
}

export function getPrefixForLimit(triggerAbove: boolean) {
	return triggerAbove === true ? '≥' : '≤';
}

export function getTriggerAboveForCreateProftLoss(
	transactionType: Transaction_Type,
	side: Side
) {
	let result = true;
	switch (transactionType) {
		case Transaction_Type.CreateTakeProfitPosition:
		case Transaction_Type.CreateTakeProfitPartial:
			result = side === Side.LONG;
			break;
		case Transaction_Type.CreateStopLossPosition:
		case Transaction_Type.CreateStopLossPartial:
			result = side !== Side.LONG;
			break;
		case Transaction_Type.CreateTakeProfitPositionV2:
		case Transaction_Type.CreateTakeProfitPartialV2:
			result = side === Side.LONG;
			break;
		case Transaction_Type.CreateStopLossPositionV2:
		case Transaction_Type.CreateStopLossPartialV2:
			result = side !== Side.LONG;
			break;
		default: {
			const customError = new Error('unexpect transactionType');
			captureException(customError);
			return result;
		}
	}
	return result;
}

export function getProfitLossTitle(transactionType: Transaction_Type) {
	if (transactionType === Transaction_Type.CreateTakeProfitPosition) {
		return t`Position TP`;
	} else if (transactionType === Transaction_Type.CreateStopLossPosition) {
		return t`Position SL`;
	} else if (transactionType === Transaction_Type.CreateTakeProfitPartial) {
		return t`Partial TP`;
	} else if (transactionType === Transaction_Type.CreateStopLossPartial) {
		return t`Partial SL`;
	}
}

export function getProfitLossTitleV2(transactionType: Transaction_Type) {
	if (transactionType === Transaction_Type.CreateTakeProfitPositionV2) {
		return t`Position TP`;
	} else if (transactionType === Transaction_Type.CreateStopLossPositionV2) {
		return t`Position SL`;
	} else if (transactionType === Transaction_Type.CreateTakeProfitPartialV2) {
		return t`Partial TP`;
	} else if (transactionType === Transaction_Type.CreateStopLossPartialV2) {
		return t`Partial SL`;
	}
}
