import { TransactionReceipt, Web3Provider } from '@ethersproject/providers';
import { Hash } from '@wagmi/core';
import { Transaction_Status, Transaction_Type } from 'config/constants';
import moment from 'moment';

import { BaseRecord } from 'types';

const TRANSACTION_STATUS_STEP_FIR = 3000;
const TRANSACTION_STATUS_STEP_SEC = 20000;
const TRANSACTION_STATUS_TIMES = 20;
const TRANSACTION_STATUS_TIMES_MAX = 60;
const TRANSACTION_RESEND_FETCH = 3; // times
const TRANSACTION_STATUS_TIMEOUT = 120;

export interface ITransaction {
	hash: Hash;
	description: string;
	status: Transaction_Status;
	type: Transaction_Type;
	date: Date;
	record: BaseRecord;
	syncing: boolean;
}

export class TransactionRecord {
	hash: Hash;
	description: string;
	status: Transaction_Status;
	type: Transaction_Type;
	date: Date;
	record: BaseRecord;
	counter: number;
	resendCounter: number;
	onTransactionComplete: (
		transaction: TransactionRecord,
		receipt?: TransactionReceipt
	) => void;
	provider: Web3Provider | undefined;
	tx: ITransaction;
	syncing: boolean;
	receipt?: TransactionReceipt;
	error?: Error | null;

	constructor(
		tx: ITransaction,
		provider: Web3Provider | undefined,
		onTransactionComplete: (
			transaction: TransactionRecord,
			receipt?: TransactionReceipt
		) => void
	) {
		const { hash, description, status, type, date, record, syncing } = tx;
		this.tx = tx;
		this.hash = hash;
		this.description = description;
		this.status = status;
		this.type = type;
		this.date = date;
		this.counter = 0;
		this.resendCounter = 0;
		this.record = record;
		this.syncing = syncing;
		this.onTransactionComplete = onTransactionComplete;
		this.provider = provider;
		this.start();
		this.error = null;
	}

	start(): void {
		if (
			this.counter >= TRANSACTION_STATUS_TIMES_MAX ||
			this.status !== Transaction_Status.Pending
		) {
			return;
		}
		if (this.counter > TRANSACTION_STATUS_TIMES) {
			setTimeout(() => {
				this.counter++;
				this.fetch();
			}, TRANSACTION_STATUS_STEP_SEC);
		} else {
			setTimeout(() => {
				this.counter++;
				this.fetch();
			}, TRANSACTION_STATUS_STEP_FIR);
		}
	}

	fetch(): void {
		if (!this.provider) {
			return;
		}
		try {
			this.provider.getTransactionReceipt(this.hash).then(receipt => {
				if (receipt && receipt.status === 1) {
					this.status = Transaction_Status.Success;
					this.tx.status = Transaction_Status.Success;
					this.onTransactionComplete(this, receipt);
				} else if (receipt && receipt.status === 0) {
					this.error = new Error('Transaction Error');
					this.doError(receipt);
				} else {
					if (
						moment().diff(this.date, 'seconds') <= TRANSACTION_STATUS_TIMEOUT
					) {
						this.start();
					} else {
						this.error = new Error('Transaction Timeout');
						this.doError(receipt);
					}
				}
			});
		} catch (e: any) {
			console.log('error', e);
			if (this.resendCounter < TRANSACTION_RESEND_FETCH) {
				this.start();
				this.resendCounter++;
			} else {
				this.error = e;
				this.doError();
			}
		}
	}

	doError(receipt?: TransactionReceipt, error?: Error): void {
		this.status = Transaction_Status.Error;
		this.tx.status = Transaction_Status.Error;
		this.syncing = false;
		this.tx.syncing = false;
		this.onTransactionComplete(this, receipt);
	}
}
