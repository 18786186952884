import React from 'react';

import { Trans, t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Dialog from '../../../components/Common/Dialog';
import Table from '../../../components/Table/StickyTable';
import { QUOTE_USD } from '../../../config/constants';
import { useAllTokens } from '../../../hooks/useAllTokens';
import { useAppBreakpoints } from '../../../hooks/useAppBreakpoints';
import { useCurrentChain } from '../../../hooks/useCurrentChain';
import { shortenSymbolNative, toQuoteAmount } from '../../../utils';
import NoData from 'components/NoData';

const LiquidityDialog = (props: { onClose: () => void; data: any }) => {
	const { onClose, data } = props;
	const { isMatchMobile } = useAppBreakpoints();
	const { currentChainId } = useCurrentChain();
	const allTokens = useAllTokens();

	const columns = [
		{
			key: 'pool',
			width: '50%',
			label: <Trans>Pool</Trans>,
			align: 'left',
			format: (row: any) => {
				const token = allTokens.get(row.pool.token.id);
				return (
					<Typography variant={isMatchMobile ? 'inherit' : 'h6'}>
						{shortenSymbolNative(token, currentChainId)}/{QUOTE_USD}
					</Typography>
				);
			}
		},
		{
			key: 'liquidity',
			width: '50%',
			label: <Trans>Liquidity</Trans>,
			align: 'right',
			format: (row: any) => {
				return (
					<Typography variant={isMatchMobile ? 'inherit' : 'h6'}>
						{toQuoteAmount(row.liquidity)}
					</Typography>
				);
			}
		}
	];
	const theme = useTheme();

	return (
		<Dialog
			open
			className='px-0'
			onClose={onClose}
			title={t`Liquidity Details`}
		>
			{data && data.length > 0 ? (
				<Table columns={columns} rows={data} cellColor={theme.custom.cardBg} origin={isMatchPad}/>
			) : (
				<NoData size='large'>
					<Trans>Your referees have not added liquidity yet.</Trans>
				</NoData>
			)}
		</Dialog>
	);
};

export default LiquidityDialog;
