// Import { useActiveLocale } from 'hooks/useActiveLocale'
import { ReactNode, useCallback, useEffect } from 'react';

import {
	DEFAULT_LOCALE,
	LOCALES,
	SupportedLocale
} from 'config/constants/locales';

import { useAppSelector } from 'state/hooks';
import { settingBaseState } from 'state/setting/slice';

import { Provider, dynamicActivate } from './I18n';

dynamicActivate(DEFAULT_LOCALE);

export function LanguageProvider({ children }: { children: ReactNode }) {
	const { activeLocale } = useAppSelector(settingBaseState);

	// Const [, setUserLocale] = useUserLocaleManager()

	const onActivate = useCallback(
		(locale: SupportedLocale) => {
			document.documentElement.setAttribute('lang', locale);
			// SetUserLocale(locale) // stores the selected locale to persist across sessions
		},
		// [setUserLocale]
		[]
	);
	useEffect(() => {
		const localeName = LOCALES.find(item => item.key === activeLocale)?.name;
		window.gtag('event', 'current_language', {
			user_language: `/${localeName}`
		});
	}, [activeLocale]);

	return (
		<Provider locale={activeLocale} onActivate={onActivate}>
			{children}
		</Provider>
	);
}
