import { useEffect, useMemo, useState } from 'react';

import { Hash } from '@wagmi/core';
import { useAccount } from 'wagmi';

import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import useIsWindowVisible from 'hooks/useIsWindowVisible';

import { Transaction_Type } from '../config/constants';
import { globalBaseState } from '../state/global/slice';
import { useAppSelector } from '../state/hooks';
import { selectTransactionRecordList } from '../state/records/selector';
import { useLiquidityPositionsRequest } from './useRequest';

const enum HASH_TYPE {
	OPEN = 'INCREASE_LIQUIDITY_POSITION',
	CLOSE = 'DECREASE_LIQUIDITY_POSITION',
	ADJUST_MARGIN = 'ADJUST_LIQUIDITY_POSITION_MARGIN'
}

export const useLiquidityPositionsFetch = () => {
	const { appTimer } = useAppSelector(globalBaseState);
	const { address } = useAccount();
	const { isNetworkError } = useCurrentChain();
	const isLogin = useCheckLogin();
	const transactionRecords = useAppSelector(selectTransactionRecordList);
	const isWindowVisible = useIsWindowVisible();
	const liquidityRecordsHash = transactionRecords
		.filter(
			record =>
				record.record.transactionType ===
					Transaction_Type.IncreaseLiquidityV2 ||
				record.record.transactionType === Transaction_Type.AppendLiquidityV2 ||
				record.record.transactionType ===
					Transaction_Type.IncreaseMarginLiquidityV2 ||
				record.record.transactionType ===
					Transaction_Type.ReduceMarginLiquidityV2 ||
				record.record.transactionType === Transaction_Type.RemoveLiquidityV2
		)
		.map(record => record.hash);

	const { data, mutate, isValidating } = useLiquidityPositionsRequest({
		account: isNetworkError ? undefined : address,
		status: 'OPENED',
		hashes: liquidityRecordsHash,
		enabled: true
	});
	useEffect(() => {
		if (isWindowVisible) {
			mutate();
		}
	}, [appTimer, isWindowVisible]);

	const defaultResult = {
		positions: [],
		requestData: {
			adjustLiquidityPositionMarginRequestsMap: new Map(),
			closeLiquidityPositionRequestsMap: new Map(),
			openLiquidityPositionRequestsMap: new Map()
		}
	};
	const [result, setResult] = useState(defaultResult);
	const [isInit, setIsInit] = useState(isLogin);

	useEffect(() => {
		const adjustLiquidityPositionMarginRequestsMap = new Map<
			Hash,
			typeof data.data.requestsByHashes
		>();
		const closeLiquidityPositionRequestsMap = new Map<
			Hash,
			typeof data.data.requestsByHashes
		>();
		const openLiquidityPositionRequestsMap = new Map<
			Hash,
			typeof data.data.requestsByHashes
		>();

		if (!data || !data.data || !data.data.liquidityPositions) {
			if (isNetworkError) {
				setResult(defaultResult);
			}
			return;
		}
		setIsInit(false);
		data.data.requestsByHashes.forEach(item => {
			if (item.type === HASH_TYPE.ADJUST_MARGIN) {
				adjustLiquidityPositionMarginRequestsMap.set(item.createdHash, item);
			}
			if (item.type === HASH_TYPE.CLOSE) {
				closeLiquidityPositionRequestsMap.set(item.createdHash, item);
			}
			if (item.type === HASH_TYPE.OPEN) {
				openLiquidityPositionRequestsMap.set(item.createdHash, item);
			}
		});

		const requestData = {
			adjustLiquidityPositionMarginRequestsMap,
			closeLiquidityPositionRequestsMap,
			openLiquidityPositionRequestsMap
		};

		setResult({ positions: data.data.liquidityPositions, requestData });
	}, [data, isNetworkError]);

	return { data: result, isValidating, isInit };
};

export default useLiquidityPositionsFetch;
