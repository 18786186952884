import { createSelector } from '@reduxjs/toolkit';

import { RewardsUtil } from 'entities/RewardsUtil';
import _ from 'lodash';
import { ClaimCalldata, Type_Earns } from 'pages/Earn/types';
import { tradeBaseState } from 'state/trade/slice';
import { txBaseState } from 'state/tx/slice';
import { isGreaterThan, isZero, plus } from 'utils';

import { earnLiquidityBaseState } from './slice';

// v3
export const selectClaimableLiquidityList = createSelector(
	[earnLiquidityBaseState],
	state => {
		const { liquidityList } = state;
		if (!liquidityList || liquidityList.length === 0) {
			return null;
		}
		return _.orderBy(
			liquidityList.filter(item => isGreaterThan(item.claimableIncome, 0)),
			function (o) {
				return parseFloat(o.claimableIncome);
			},
			['desc']
		);
	}
);

// v3
export const selectclaimableLiquidityAmount = createSelector(
	[earnLiquidityBaseState],
	state => {
		const { liquidityList } = state;
		if (!liquidityList || liquidityList.length === 0) {
			return '0';
		}
		return liquidityList.reduce(
			(pre, cur) => plus(pre, cur.claimableIncome),
			'0'
		);
	}
);

// v1
export const selectClaimLiquidityCalldatas = createSelector(
	[tradeBaseState, txBaseState, earnLiquidityBaseState],
	(tradeBaseState, txBaseState, state) => {
		const { isBindReferralCode } = tradeBaseState;
		const { appToken } = txBaseState;
		const { previousLiquidityList } = state;
		if (!previousLiquidityList || previousLiquidityList.length === 0) {
			return null;
		}
		const results = [] as Array<ClaimCalldata>;
		const _previousLiquidityList = previousLiquidityList.filter(
			item =>
				isGreaterThan(
					item.pool.liquidityRewardGrowthX64,
					item.rewardGrowthX64
				) || isGreaterThan(item.unclaimed, 0)
		);
		if (_previousLiquidityList.length === 0) {
			return null;
		}
		const ids = _previousLiquidityList.map(item => item.pool.id);
		const appAmount = _previousLiquidityList.reduce((pre, cur) => {
			const rbrReward = RewardsUtil.calculateLiquidityRewardV1(
				cur,
				isBindReferralCode
			);
			return plus(pre, rbrReward.rewardsEQU);
		}, '0');
		if (isZero(appAmount)) {
			return null;
		}
		results.push({
			type: Type_Earns.Liquidity,
			ids,
			rewardToken: appToken,
			amount: appAmount
		});
		return results;
	}
);

// v1
export const selectPreviousClaimableLiquidityAmount = createSelector(
	[selectClaimLiquidityCalldatas],
	claimLiquidityCalldatas => {
		if (!claimLiquidityCalldatas) return '0';
		return claimLiquidityCalldatas.reduce(
			(pre, cur) => plus(pre, cur.amount),
			'0'
		);
	}
);
