import { ReactNode, useMemo } from 'react';

import { Trans } from '@lingui/macro';
import { useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import {
	QUOTE_USD,
	TIME_STAMP_END_TRADING_FEE,
	Version
} from 'config/constants';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin } from 'hooks/useCurrentChain';
import { isEmpty } from 'lodash';
import FreeTradingFeeWarning, {
	Type_Free_Trading_Fee
} from 'pages/components/FreeTradingFeeWarning';
import { selectClaimableStakeList } from 'state/earn/stake/selector';
import { earnStakeBaseState } from 'state/earn/stake/slice';
import { useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';
import { txBaseState } from 'state/tx/slice';
import {
	div,
	formatNumber,
	isAfterEndTime,
	isNumeric,
	multipliedBy,
	plus,
	shortenSymbol,
	toPercent
} from 'utils';

import Cell from 'components/Common/Cell';
import Module from 'components/Module';
import Stronger from 'components/Stronger';
import TippingUnderline from 'components/TippingUnderline';

import useAccountQuery from '../../../graphql/useAccount';

function StakeLpTiping() {
	return (
		<TippingUnderline
			tooltip={
				<Typography variant='body2'>
					<Trans>
						Staked amount is calculated as the amount of EQU in LP Token at the
						time of staking.
					</Trans>
				</Typography>
			}
			label={
				<Typography variant='body2'>
					<Trans>Staked EQU in LP NFT</Trans>
				</Typography>
			}
		/>
	);
}

function LoginContainer({ children }: { children: ReactNode }) {
	const isLogin = useCheckLogin();
	const { isMatchMobile } = useAppBreakpoints();

	if (!isLogin) {
		return (
			<Typography variant='body2' fontWeight={isMatchMobile ? 600 : 400}>
				-
			</Typography>
		);
	}
	return <>{children}</>;
}

export default function StakeSummary(props: any) {
	const { equAPR, stakedList } = props;
	const theme = useTheme();
	const { appToken, nativeSymbol, quoteToken } = useAppSelector(txBaseState);
	const { stakeStatistic, maxMultiplier } = useAppSelector(earnStakeBaseState);
	const currentVersion = useAppSelector(selectVersion);

	const claimableStakeList = useAppSelector(selectClaimableStakeList);

	const { data: accountInformation, loading, refetch } = useAccountQuery();
	const { isMatchMobile, isMatchPad } = useAppBreakpoints();

	const totalUserStakedEqu = useMemo(() => {
		refetch();
		return stakedList?.reduce((all: number | string, current) => {
			return plus(all, current.stakedAmount);
		}, 0);
	}, [stakedList]);

	const avgUserMultiplier = useMemo(() => {
		if (isEmpty(stakedList) || !totalUserStakedEqu) {
			return 0;
		}
		const multiplier = stakedList?.reduce((all: number | string, current) => {
			return plus(all, multipliedBy(current.multiplier, current.stakedAmount));
		}, 0);
		return div(multiplier, totalUserStakedEqu);
	}, [stakedList, totalUserStakedEqu]);

	const totalClaimableRewards = useMemo(() => {
		if (isEmpty(claimableStakeList)) {
			return 0;
		}
		return claimableStakeList.reduce((all: number | string, current) => {
			return plus(all, current.claimableReward);
		}, 0);
	}, [claimableStakeList]);

	const totalRewards = useMemo(() => {
		if (
			!accountInformation ||
			!accountInformation ||
			!accountInformation.account
		) {
			return 0;
		}

		return plus(
			totalClaimableRewards,
			accountInformation?.account.claimedRewardFromEQU
		);
	}, [totalClaimableRewards, accountInformation]);

	return (
		<Paper
			className='sm:space-y-0 -mt-4 underLg:mt-0'
			sx={{
				background:
					isMatchMobile || isMatchPad
						? 'transparent'
						: theme.palette.background.paper
			}}
		>
			<div className='grid grid-cols-2 sm:grid-cols-1 gap-7 underLg:gap-2'>
				<Module
					title={<Trans>Overview</Trans>}
					sx={{
						background:
							isMatchMobile || isMatchPad
								? theme.palette.background.paper
								: 'transparent'
					}}
					className='sm:space-y-1'
				>
					<Cell
						label={<Trans>Max APR</Trans>}
						value={
							<div className='flex items-center space-x-1'>
								<Stronger
									value={
										<Typography
											component='span'
											className='leading-none'
											variant='body2'
											color={
												equAPR && isNumeric(equAPR)
													? 'success.main'
													: 'secondary'
											}
										>
											{equAPR && isNumeric(equAPR) ? toPercent(equAPR) : '-'}
										</Typography>
									}
									tooltip={
										<div>
											<Typography
												className='mb-2'
												align='inherit'
												variant='body2'
											>
												<Trans>
													Max APR ({maxMultiplier}x) = 24h Rewards Value /
													Σ(Staked EQU Value * Multiplier) * Max Multiplier *
													365 * 100%
												</Trans>
											</Typography>
										</div>
									}
								/>
								{/* {currentVersion === Version.V1 &&
									!isAfterEndTime(TIME_STAMP_END_TRADING_FEE) && (
										<FreeTradingFeeWarning
											type={Type_Free_Trading_Fee.Warning}
										/>
									)} */}
							</div>
						}
					/>
					<Cell
						label={
							<Typography variant='body2' color='secondary'>
								<Trans>Staked {shortenSymbol(appToken)}</Trans>
							</Typography>
						}
						value={
							<Typography
								variant='body2'
								align={isMatchMobile ? 'right' : 'center'}
							>
								{`${formatNumber(
									stakeStatistic?.equStakedAmount,
									appToken?.positionUnits
								)} ${shortenSymbol(appToken)}`}
							</Typography>
						}
					/>
					<Cell
						label={
							<div className='flex justify-center sm:justify-end items-center'>
								<TippingUnderline
									tooltip={
										<Typography variant='body2'>
											<Trans>
												The calculated figure may slightly differ from the
												actual amount because the {shortenSymbol(appToken)} in
												the {shortenSymbol(appToken)}/{nativeSymbol} LP NFT is
												based on the {shortenSymbol(appToken)} quantity in the
												LP Token at the time of staking.
											</Trans>
										</Typography>
									}
									label={
										<Trans>Staked {shortenSymbol(appToken)} in LP NFT</Trans>
									}
								/>
							</div>
						}
						value={
							<div
								className={cn(
									'flex space-x-0.5 items-center',
									isMatchMobile ? 'mr-1 justify-end' : ' justify-center'
								)}
							>
								<Typography
									variant='body2'
									align={isMatchMobile ? 'left' : 'center'}
								>
									{`${formatNumber(
										stakeStatistic?.liquidityStakedAmount,
										appToken?.positionUnits
									)} ${shortenSymbol(appToken)}`}
								</Typography>
							</div>
						}
					/>
					<Cell
						label={<Trans>Total Staking Rewards</Trans>}
						value={
							<Typography variant='body2'>
								{`${formatNumber(stakeStatistic?.stakeReward)} ${shortenSymbol(
									quoteToken
								)}`}
							</Typography>
						}
					/>
				</Module>
				<Module
					title={<Trans>My Data</Trans>}
					sx={{
						background:
							isMatchMobile || isMatchPad
								? theme.palette.background.paper
								: 'transparent'
					}}
					className='sm:space-y-1'
				>
					<Cell
						label={<Trans>Staked EQU</Trans>}
						value={
							<LoginContainer>
								<Typography variant='body2'>
									{`${formatNumber(
										totalUserStakedEqu,
										appToken?.positionUnits
									)} ${shortenSymbol(appToken)}`}
								</Typography>
							</LoginContainer>
						}
					/>
					<Cell
						label={<Trans>Avg. Multiplier</Trans>}
						value={
							<LoginContainer>
								<Typography variant='body2'>
									{`${formatNumber(avgUserMultiplier, 1)}x`}
								</Typography>
							</LoginContainer>
						}
					/>
					<Cell
						label={<Trans>Total Rewards</Trans>}
						value={
							<LoginContainer>
								<div className='flex justify-end space-x-3'>
									<Typography variant='body2'>
										{loading
											? '-'
											: `${formatNumber(totalRewards, 2)} ${QUOTE_USD}`}
									</Typography>
								</div>
							</LoginContainer>
						}
					/>
					<Cell
						label={<Trans>Claimable Rewards</Trans>}
						value={
							<LoginContainer>
								<div className='flex justify-end space-x-3'>
									<Typography variant='body2'>
										{`${formatNumber(totalClaimableRewards)} ${QUOTE_USD}`}
									</Typography>
								</div>
							</LoginContainer>
						}
					/>
				</Module>
			</div>
		</Paper>
	);
}
