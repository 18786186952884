import React, { useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { Close } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
	Box,
	Collapse,
	Divider,
	Drawer,
	InputAdornment,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
	styled
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { App_Theme, DEFAULT_SETTING_SLIPPAGE } from 'config/constants';
import { LOCALES } from 'config/constants/locales';

import Footer from 'layouts/Footer';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
	setActiveLocale,
	setSlippage,
	setSlippageType,
	settingBaseState
} from 'state/setting/slice';

import Dialog from 'components/Common/Dialog';
import { CommonStyledOutlineInput } from 'components/Common/Styled';
import ThemeButton from 'components/ThemeButton';

import { useAppBreakpoints } from '../../hooks/useAppBreakpoints';

const StyledDrawer = styled(Drawer)(({ theme }) => {
	const { isMatchMobile } = useAppBreakpoints();
	return {
		'& .MuiDrawer-paper': {
			width: isMatchMobile ? '100%' : 475,
			background:
				theme.palette.mode === App_Theme.dark
					? theme.custom.cardBg
					: theme.palette.background,
			borderRadius: 0,
			padding: '20px 0px 0 0px'
		},
		'&.MuiDrawer-root': {
			zIndex: '5'
		}
	};
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
	'&.MuiToggleButtonGroup-root': {
		width: '58%',
		border: `1px solid ${theme.palette.secondary.main}`,
		borderRadius: '4px'
	},
	'& .MuiToggleButtonGroup-grouped': {
		margin: theme.spacing(0.5),
		border: 0,
		'&.Mui-selected': {
			backgroundColor: theme.palette.divider,
			'&:hover': {
				backgroundColor: theme.palette.divider
			}
		},
		'&:not(:first-of-type)': {
			border: 0,
			marginLeft: 0,
			borderRadius: theme.shape.borderRadius
		},
		'&:first-of-type': {
			border: 0,
			borderRadius: theme.shape.borderRadius
		}
	}
}));

const DrawerHeader = () => {
	const { slippage, slippageType, activeLocale } =
		useAppSelector(settingBaseState);
	const [open, setOpen] = useState(false);
	const [tempSlippageValue, setTempSlippageValue] = useState('');
	const [expand, setExpand] = useState(true);
	const [expandLanguage, setExpandLanguage] = useState(false);

	const [errors, setErrors] = useState({}) as any;
	const theme = useTheme();
	const dispatch = useAppDispatch();

	const onHandleAlignment = (
		event: React.MouseEvent<HTMLElement>,
		newType: string
	) => {
		if (newType !== null) {
			dispatch(setSlippageType(newType));
			if (newType === 'auto') {
				dispatch(setSlippage(DEFAULT_SETTING_SLIPPAGE));
			}
		}
	};

	const onHandleChangeSlippageAmount = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const value = event.target.value;
		if (isNaN(Number(value)) || !value) {
			setTempSlippageValue('');
			setErrors({ [event.currentTarget.name]: true });
		} else {
			setTempSlippageValue(value);
			if (Number(value) > 10 || Number(value) < 0.01) {
				setErrors({ [event.currentTarget.name]: true });
			} else {
				setErrors({ [event.currentTarget.name]: false });
			}
		}
	};

	const toggleDrawer = (newOpen: boolean) => () => {
		setOpen(newOpen);
	};

	const onClose = () => {
		setOpen(false);
		if (tempSlippageValue && !errors.custom) {
			dispatch(setSlippage(tempSlippageValue));
		}
		setErrors({});
		setTempSlippageValue('');
	};

	const onToggleExpand = () => setExpand(!expand);
	const onToggleExpandLanguage = () => setExpandLanguage(!expandLanguage);

	const onSelectLanguage = (locale: string) => {
		dispatch(setActiveLocale(locale));
		setOpen(false);
		setExpandLanguage(false);
	};
	const { isMatchMobile } = useAppBreakpoints();

	return (
		<Box>
			<MoreHorizIcon
				className='cursor-pointer'
				fontSize='medium'
				onClick={toggleDrawer(true)}
				sx={{
					color: theme.palette.text.primary
				}}
			/>
			<StyledDrawer anchor='right' open={open} onClose={toggleDrawer(false)}>
				<IconButton
					aria-label='close'
					onClick={toggleDrawer(false)}
					sx={{
						width: '14px',
						height: '14px',
						position: 'absolute',
						right: 18,
						top: 20,
						color: theme.palette.text.primary,
						fontWeight: 'bold'
					}}
				>
					<CloseIcon />
				</IconButton>
				<Box className='mt-10 px-3'>
					<Box className='flex cursor-pointer justify-between'>
						<Box className='flex items-center'>
							<Typography className='text-sm' variant='body2'>
								<Trans>Theme</Trans>
							</Typography>
						</Box>

						<Box>
							<ThemeButton />
						</Box>
					</Box>
					<Divider className='my-5' sx={{ borderStyle: 'dashed' }} />
					<Box
						onClick={onToggleExpand}
						className='flex cursor-pointer justify-between'
					>
						<div className='flex items-center'>
							<Typography className='text-sm' variant='body2'>
								<Trans>Slippage Settings</Trans>
							</Typography>
						</div>
						<div className='flex items-center'>
							{expand ? (
								<KeyboardArrowDownIcon
									fontSize='medium'
									sx={{
										color: theme.palette.secondary.main
									}}
								/>
							) : (
								<KeyboardArrowRightIcon
									fontSize='medium'
									sx={{
										color: theme.palette.secondary.main
									}}
								/>
							)}
						</div>
					</Box>
					<Collapse
						sx={{ border: 'none' }}
						in={expand}
						timeout='auto'
						unmountOnExit
					>
						<Box className='mt-3 flex justify-between itmes-start'>
							<StyledToggleButtonGroup
								size='small'
								value={slippageType}
								exclusive
								onChange={onHandleAlignment}
								aria-label='text alignment'
								sx={{ width: '58%' }}
							>
								<ToggleButton
									value='auto'
									aria-label='left aligned'
									className='grow capitalize'
								>
									<Trans>Auto</Trans>
								</ToggleButton>
								<ToggleButton
									value='custom'
									aria-label='right aligned'
									className='grow capitalize'
								>
									<Trans>Custom</Trans>
								</ToggleButton>
							</StyledToggleButtonGroup>
							{slippageType === 'auto' && (
								<CommonStyledOutlineInput
									readOnly
									type='text'
									inputProps={{ maxLength: 10, inputMode: 'decimal' }}
									className='input px-2'
									defaultValue={DEFAULT_SETTING_SLIPPAGE}
									placeholder={DEFAULT_SETTING_SLIPPAGE}
									endAdornment={
										<InputAdornment
											position='end'
											sx={{
												'.MuiTypography-body1': {
													color: theme.palette.text.primary
												}
											}}
										>
											%
										</InputAdornment>
									}
									sx={{
										'&.MuiOutlinedInput-root': {
											width: '36%',
											border: `1px solid ${theme.palette.secondary.main}`,
											color: theme.palette.secondary.main
										},
										'.MuiOutlinedInput-input': {
											textAlign: 'right'
										}
									}}
								/>
							)}

							{slippageType === 'custom' && (
								<CommonStyledOutlineInput
									type='text'
									name='custom'
									autoComplete='off'
									inputProps={{ maxLength: 10, inputMode: 'decimal' }}
									className='input px-2'
									value={
										tempSlippageValue || errors.custom
											? tempSlippageValue
											: slippage
									}
									onChange={onHandleChangeSlippageAmount}
									onBlur={onClose}
									placeholder={DEFAULT_SETTING_SLIPPAGE}
									error={errors?.custom}
									endAdornment={
										<InputAdornment
											position='end'
											sx={{
												'.MuiTypography-body1': {
													color: theme.palette.text.primary
												}
											}}
										>
											%
										</InputAdornment>
									}
									sx={{
										'&.MuiOutlinedInput-root': {
											width: '36%',
											border: `1px solid ${theme.palette.secondary.main}`,
											'&.Mui-error': {
												border: `1px solid ${theme.palette.error.main}`
											}
											// color: theme.palette.secondary.main,
										},
										'.MuiOutlinedInput-input': {
											textAlign: 'right'
										}
									}}
								/>
							)}
						</Box>
						{slippageType === 'custom' && errors?.custom ? (
							<Typography
								className='w-full mt-1'
								variant='body2'
								align='right'
								color='error.main'
							>
								{Number(tempSlippageValue) < 0.01
									? t`Min slippage precision is 0.01%`
									: ''}
								{Number(tempSlippageValue) > 10
									? t`Max slippage precision is 10%`
									: ''}
							</Typography>
						) : null}
					</Collapse>
					<Divider className='mt-5' sx={{ borderStyle: 'dashed' }} />
				</Box>
				{isMatchMobile ? (
					<Box className='px-3'>
						<Box
							className='mt-5 flex cursor-pointer justify-between'
							onClick={onToggleExpandLanguage}
						>
							<Box className='flex'>
								<Typography className='text-sm' variant='body2'>
									<Trans>Language</Trans>
								</Typography>
							</Box>
							<Box className='flex items-center'>
								{isMatchMobile && (
									<Typography className='text-sm' variant='body2'>
										{LOCALES.find(locale => locale.key === activeLocale)?.name}
									</Typography>
								)}
								<KeyboardArrowDownIcon
									fontSize='medium'
									sx={{
										color: theme.palette.secondary.main
									}}
								/>
							</Box>
						</Box>
						<Dialog
							open={expandLanguage}
							title={
								<Typography variant='h6' fontSize={18}>
									<Trans>Language</Trans>
								</Typography>
							}
							onClose={() => setExpandLanguage(false)}
						>
							<div className='grid grid-cols-2 gap-8 mt-4'>
								{LOCALES.map(locale => (
									<Typography
										key={locale.key}
										variant='h6'
										fontWeight={500}
										color={
											locale.key === activeLocale
												? 'primary.main'
												: 'text.secondary'
										}
										className='flex items-center'
										onClick={() => onSelectLanguage(locale.key)}
									>
										{locale.name}
										{locale.key === activeLocale && (
											<DoneRoundedIcon fontSize='small' className='ml-2' />
										)}
									</Typography>
								))}
							</div>
						</Dialog>
					</Box>
				) : (
					<Box sx={{ height: 'calc(100vh - 174px - 165px)' }} className='px-3'>
						<Box
							className='mt-5 flex cursor-pointer justify-between'
							onClick={onToggleExpandLanguage}
						>
							<Box className='flex'>
								<Typography className='text-sm' variant='body2'>
									<Trans>Language</Trans>
								</Typography>
							</Box>
							<Box className='flex items-center'>
								{expandLanguage ? (
									<KeyboardArrowDownIcon fontSize='medium' />
								) : (
									<KeyboardArrowRightIcon fontSize='medium' />
								)}
							</Box>
						</Box>
						<Collapse
							sx={{ border: 'none', maxHeight: 'calc(100% - 84px)' }}
							in={expandLanguage}
							timeout='auto'
							unmountOnExit
							className='overflow-y-auto'
						>
							<Box className='flex flex-wrap pt-1 pb-5'>
								{LOCALES.map(locale => {
									return (
										<Box
											className='flex w-full cursor-pointer items-center pt-5'
											component='div'
											key={locale.key}
											onClick={() => onSelectLanguage(locale.key)}
										>
											<Box className='flex items-center justify-center space-x-2'>
												<Typography
													component='div'
													color={
														locale.key === activeLocale
															? 'text.primary'
															: 'text.secondary'
													}
													sx={{
														fontSize: '0.875rem !important'
													}}
												>
													{locale.name}
												</Typography>
											</Box>
											{locale.key === activeLocale && (
												<DoneRoundedIcon
													fontSize='small'
													className='ml-4'
													sx={{
														color: theme.palette.text.primary
													}}
												/>
											)}
										</Box>
									);
								})}
							</Box>
						</Collapse>
					</Box>
				)}

				<Box className='absolute bottom-0 w-full'>
					<Footer />
				</Box>
			</StyledDrawer>
		</Box>
	);
};

export default DrawerHeader;
