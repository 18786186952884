import React from 'react';

import { SvgProps } from '../types';

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = props => {
	return (
		<svg width='13' height='14' viewBox='0 0 13 14' {...props}>
			<path
				d='M9.16192 0.57436L1.84981 0.574219C1.22065 0.574219 0.711052 1.08381 0.711052 1.71298L0.710938 10.11L2.32924 10.1101V2.13881H9.16181L9.16192 0.57436ZM10.8699 3.00723H4.60677C3.9776 3.00723 3.46801 3.5174 3.46801 4.146V11.8919C3.46801 12.5205 3.9776 13.0307 4.60677 13.0307H10.8699C11.4985 13.0307 12.0087 12.5205 12.0087 11.8919V4.146C12.0087 3.5174 11.4985 3.00723 10.8699 3.00723ZM5.01295 4.52348H10.5242V11.4805H5.01295V4.52348Z'
				fill={props.fill}
			/>
		</svg>
	);
};

export default Icon;
