import { useMemo } from 'react';

import { EFCABI } from 'config/abis';
import { Contract_Write_Mode, Transaction_Type } from 'config/constants';
import { getContractAddress } from 'config/contracts';
import { UseContractWriteConfig, UsePrepareContractWriteConfig } from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { RecordForCreateReferralCode } from 'types';
import { checkInputReferralCode } from 'utils';

import { useComputeGasLimit } from './useComputeGasLimit';
import { useCurrentChain } from './useCurrentChain';
import { useSendTransaction } from './useSendTransaction';

export function useSubmitCreateReferralCode(
	tokenId: string | undefined,
	code: string
) {
	const { currentChainId } = useCurrentChain();

	const { args, record, description } = useMemo(() => {
		if (!tokenId || !checkInputReferralCode(code)) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		console.log('useSubmitCreateReferralCode args begin ...');

		const args = [tokenId, code];

		const record = {
			transactionType: Transaction_Type.CreateReferralCode,
			tokenId,
			code
		} as RecordForCreateReferralCode;

		const description = TransactionDescription.CreateReferralCode(
			tokenId,
			code
		);

		return {
			args,
			record,
			description
		};
	}, [tokenId, code]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address: getContractAddress(currentChainId, 'EFC'),
			abi: EFCABI,
			functionName: 'registerCode',
			args
		} as UseContractWriteConfig;
	}, [currentChainId, args]);

	const { gasLimit } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirmed, isConfirming } = useSendTransaction(
		contractWriteArgs,
		gasLimit
	);

	const onConfirm = () => {
		if (!record) return;
		onSendTransaction(record, description);
	};

	return {
		onConfirm,
		isConfirming,
		isConfirmed
	};
}
