import { useEffect, useState } from 'react';

import {
	QUOTE_USD_PRECISION,
	Side,
	Transaction_Status,
	Transaction_Type
} from 'config/constants';
import { useAccount } from 'wagmi';

import { PositionUtil } from 'entities/PositionUtil';
import { useAllTokens } from 'hooks/useAllTokens';
import { useCheckLogin } from 'hooks/useCurrentChain';
import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { tradeBaseState } from 'state/trade/slice';
import { txBaseState } from 'state/tx/slice';
import { IPoolItem, TokenInfo } from 'types';
import {
	catchFn,
	div,
	formatRate,
	formatUnits,
	multipliedBy,
	neg,
	parseUnits,
	plus
} from 'utils';

import { useMyPositionsQuery } from './__generated_trade__/types-and-hooks';

export interface IPositionItem {
	id: string;
	poolId: string;
	pool: any;
	baseToken: TokenInfo;
	entryPrice: string;
	entryTime: string;
	size: string;
	// 初始保证金
	margin: string;
	// 减仓已实现盈亏
	realizedPnL: string;
	// 当前仓位总的已实现盈亏：减仓已实现盈亏 + 总资金费
	realizedPnLTotal: string;
	leverage: number | string;
	side: Side;
	maxLeveragePerPosition: number;

	// 资金费总计
	fundingFeeTotal: string;
	// 保证金净值
	netMargin: string;
	// 价格影响费用
	// priceImpactFee: string;
	// 未实现盈亏
	unrealizedPnL: string;
	// 未实现盈亏率
	unrealizedPnLRate: string;
	// 强平价格
	liqPrice: string;
	// 预估平仓费
	liqFee: string;
	marginIncreased: string;
	marginDecreased: string;
	tradingFeeByClosing: string;
	reducedAndSettled: string;
	//已实现盈亏（保证金实际盈亏）
	profitBalance: string;
	// 保证金净值 = 初始保证金（Initial Margin）+保证金净盈亏（Net Margin PnL）
	netValue: string;
	// 维持保证金率原始值
	liquidationFeeRatePerPositionOriginal: number;
	// 维持保证金率
	liquidationFeeRatePerPosition: string;
	// 流动性
	liquidity: string;
	// 静态流动性费率
	tradingFeeRate: string;
	// PL仓位结算手续费
	liquidationExecutionFee: string;
	// 已支付的交易费
	tradingFee: string;

	// 当前池子价格
	price: string;
	minPrice: string;
	maxPrice: string;
	indexPrice: string;
	marketPrice: string;
	minIndexPrice: string;
	maxIndexPrice: string;
	minMarketPrice: string;
	maxMarketPrice: string;
	marginRate: string;

	status?: Transaction_Status;
	transactionType?: Transaction_Type;
	blockTimestamp?: number;
	discountedTradingFeeRate: string;
}

export function useMyPostionsGraph(params:any) {
	const {poolId = '', enabled} = params;
	const { address } = useAccount();
	const { blockTimestamp } = useAppSelector(globalBaseState);
	const { quoteToken } = useAppSelector(txBaseState);
	const { isBindReferralCode } = useAppSelector(tradeBaseState);
	const { poolMap } = useAppSelector(poolsBaseState);
	const [isLoadingPositions, setIsLoadingPositions] = useState<boolean>(true);
	const isLogin = useCheckLogin();
	const [positionMap, setPositionMap] = useState<Map<
		string,
		Array<IPositionItem>
	> | null>(null);
	const [positionList, setPositionList] = useState<Array<IPositionItem> | null>(
		null
	);

	const {
		data,
		loading,
		refetch: refetchPositions
	} = useMyPositionsQuery({
		variables: { account: address },
		skip: !enabled
	});

	const allTokens = useAllTokens();
	
	useEffect(() => {
		if (!loading) {
			setIsLoadingPositions(loading);
		}
	}, [loading]);

	useEffect(() => {
		if (!isLogin) {
			setPositionMap(null);
		}
	}, [isLogin]);

	useEffect(() => {
		if (!data || !data.positions || !poolMap.size || !quoteToken) {
			return;
		}

		const positionMap = new Map<string, Array<IPositionItem>>();
		const positionList = [] as Array<IPositionItem>;

		data?.positions?.forEach(item => {
			if (poolId && poolId !== item.pool.id) {
				return;
			}

			const {
				fundingFee,
				tradingFee,
				realizedPnL,
				marginIncreased,
				marginDecreased,
				tradingFeeByClosing
			} = item;

			const {
				id: tokenId,
				price,
				// priceX96,
				decimals,
				liquidationFeeRatePerPosition,
				tradingFeeRate,
				liquidationExecutionFee: _liquidationExecutionFee,
				maxPrice,
				minPrice,
				maxLeveragePerPosition
			} = item.pool.token;

			const baseToken = allTokens.get(tokenId);
			// console.log(pools, tokenId);
			// 取中心化当前池子各种价格
			const currentPool = poolMap.get(item.pool.id) as IPoolItem;
			const {
				index_price = price,
				market_price = price,
				min_index_price = minPrice,
				max_index_price = maxPrice,
				min_market_price = minPrice,
				max_market_price = maxPrice,
				referralDiscountRate
			} = currentPool;

			// 累计资金费
			const fundingFeeTotal = catchFn(() => {
				// 浮动资金费
				const { longFundingRateGrowthX96, shortFundingRateGrowthX96 } =
					item.pool.globalPosition;
				const _globalFundingRateGrowthX96 =
					item.side === Side.LONG
						? longFundingRateGrowthX96
						: shortFundingRateGrowthX96;
				// 仓位进行调整需要清算的资金费率
				const requiredFundingFee = formatUnits(
					PositionUtil.calculateFundingFee(
						_globalFundingRateGrowthX96,
						item.fundingRateGrowthX96,
						parseUnits(item.size, decimals)
					),
					QUOTE_USD_PRECISION
				);

				return plus(requiredFundingFee, fundingFee);
			}, '0');

			const _price =
				item.side === Side.LONG ? max_index_price : min_index_price;

			const unrealizedPnL = catchFn(() => {
				return PositionUtil.calculateUnrealizedPnL(
					item.side,
					item.size,
					item.entryPrice,
					_price
				);
			}, '0');

			// 减少保证金和平仓提取的margin
			const reducedAndSettled = plus(
				plus(marginDecreased, realizedPnL),
				tradingFeeByClosing
			);

			// 保证金净值
			const netMargin = plus(
				marginIncreased,
				plus(reducedAndSettled, fundingFeeTotal)
			);

			// 未实现盈亏率
			const unrealizedPnLRate = div(unrealizedPnL, netMargin);

			// PL仓位结算手续费
			const liquidationExecutionFee = formatUnits(
				_liquidationExecutionFee,
				QUOTE_USD_PRECISION
			);

			const _liquidationFeeRatePerPosition = formatRate(
				liquidationFeeRatePerPosition
			);
			const _tradingFeeRate = formatRate(tradingFeeRate);

			let discountedTradingFeeRate = _tradingFeeRate;
			if (isBindReferralCode) {
				discountedTradingFeeRate = multipliedBy(
					_tradingFeeRate,
					referralDiscountRate
				);
			}

			// 计算预估爆仓价
			const liqPrice = catchFn(() => {
				return PositionUtil.calculateLiqPrice(
					item.side,
					netMargin,
					item.size,
					item.entryPrice,
					liquidationExecutionFee,
					_liquidationFeeRatePerPosition,
					discountedTradingFeeRate
				);
			}, '');

			// 计算预估平仓费
			const liqFee = catchFn(() => {
				const _liquidityNow = multipliedBy(item.size, index_price);
				return neg(multipliedBy(_liquidityNow, discountedTradingFeeRate));
			}, '');

			// 计算仓位净值
			const netValue = plus(plus(netMargin, unrealizedPnL), liqFee);
			// 计算杠杆倍数
			const _leverage = PositionUtil.calculateLeverage(
				netMargin,
				item.liquidity
			);

			// 计算保证金率
			const _maintenanceMargin = PositionUtil.calculateMaintenanceMargin(
				item.entryPrice,
				item.size,
				_price,
				_liquidationFeeRatePerPosition,
				discountedTradingFeeRate,
				liquidationExecutionFee
			);
			const marginRate = div(
				_maintenanceMargin,
				plus(netMargin, unrealizedPnL)
			);

			const realizedPnLTotal = plus(item.realizedPnL, fundingFeeTotal);

			// console.log('------------start-----------');
			// console.log('size', item.size);
			// console.log('entryPrice', item.entryPrice);
			// console.log('indexPrice', _price);
			// console.log(
			// 	'liquidationFeeRate',
			// 	div(liquidationFeeRatePerPosition, 10000)
			// );
			// console.log('liquidationExecutionFee', liquidationExecutionFee);
			// console.log('maintenanceMargin', _maintenanceMargin);
			// console.log('netMargin', netMargin);
			// console.log('unrealizedPnL', unrealizedPnL);
			// console.log('------------end-----------');

			const positionItem = {
				id: item.id,
				poolId: item.pool.id,
				pool: item.pool,
				baseToken,
				entryPrice: item.entryPrice,
				entryTime: item.entryTime,
				size: item.size,
				margin: item.margin,
				realizedPnL: item.realizedPnL,
				realizedPnLTotal,
				leverage: _leverage,
				side: item.side,

				fundingFeeTotal,
				netMargin,
				unrealizedPnL,
				unrealizedPnLRate,
				reducedAndSettled,
				marginIncreased,
				marginDecreased,
				tradingFeeByClosing,
				netValue,
				liqPrice,
				liqFee,
				liquidationFeeRatePerPositionOriginal: liquidationFeeRatePerPosition,
				liquidationFeeRatePerPosition: formatRate(
					liquidationFeeRatePerPosition
				),
				liquidity: multipliedBy(item.entryPrice, item.size),
				tradingFeeRate: _tradingFeeRate,
				liquidationExecutionFee,
				tradingFee,

				price,
				minPrice,
				maxPrice,
				maxLeveragePerPosition,
				indexPrice: index_price,
				marketPrice: market_price,
				minIndexPrice: min_index_price,
				maxIndexPrice: max_index_price,
				minMarketPrice: min_market_price,
				maxMarketPrice: max_market_price,
				blockTimestamp: blockTimestamp,
				marginRate,
				discountedTradingFeeRate
			} as IPositionItem;

			const target = positionMap.get(positionItem.poolId);
			if (!target) {
				positionMap.set(positionItem.poolId, [positionItem]);
			} else {
				target.push(positionItem);
			}
			positionList.push(positionItem);
		});

		setPositionMap(positionMap);
		setPositionList(positionList);
	}, [data, poolMap, quoteToken, poolId, isBindReferralCode]);

	return {
		positionList,
		positionMap,
		isLoadingPositions,
		refetchPositions
	};
}
