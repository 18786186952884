import { useEffect, useMemo, useState } from 'react';

import { Trans } from '@lingui/macro';
import { Button, Typography, useTheme } from '@mui/material';

import { size } from 'lodash';
import {
	checkInputInteger,
	isGreaterThanOrEqual,
	isLessThanOrEqualTo,
	isPositive
} from 'utils';

import { CommonStyledBaseInput } from 'components/Common/Styled';

const ONE_CHAR_PX = 8;

export default function LeverageListener({
	active = false,
	leverage,
	maxLeverage,
	onChangeLeverage,
	onToggleActive
}: {
	active: boolean;
	leverage: string | number;
	maxLeverage: string | number | undefined;
	onChangeLeverage: (value: string) => void;
	onToggleActive: (value: boolean) => void;
}) {
	const theme = useTheme();

	const [value, setValue] = useState<string>('');

	useEffect(() => {
		setValue(String(leverage));
	}, [leverage]);

	const inputWidth = useMemo(() => {
		const len = size(String(value));
		return Math.max(ONE_CHAR_PX * len, ONE_CHAR_PX);
	}, [value]);

	const onHandleChangeLeverage = (event: any) => {
		const value = event.target.value;
		if (value.trim() === '') {
			setValue('');
			return;
		}
		if (
			checkInputInteger(value) &&
			isGreaterThanOrEqual(value, 1) &&
			maxLeverage &&
			isLessThanOrEqualTo(value, maxLeverage)
		) {
			setValue(value);
		}
	};

	const onHandleToggleActive = () => {
		if (!active) {
			onToggleActive(true);
		}
	};

	const onHandleBlurLeverage = () => {
		onToggleActive(false);
		if (isPositive(value)) {
			onChangeLeverage(value);
		}
	};

	const handleKeyPress = (event: any) => {
		if (event.key === 'Enter') {
			onHandleBlurLeverage();
		}
	};

	useEffect(() => {
		if (active) {
			setValue(String(leverage));
		}
	}, [active]);

	return (
		<Button
			variant='text'
			className='flex items-center rounded-full -mr-2'
			onClick={onHandleToggleActive}
			sx={{
				minWidth: 'auto',
				height: 'auto',
				padding: '4px 8px',
				color: theme.palette.text.primary,
				'&:hover': {
					backgroundColor: !active ? theme.palette.action.hover : 'inherit'
				}
			}}
		>
			<Typography variant='body2' color='secondary'>
				<Trans>Leverage:</Trans>&nbsp;
			</Typography>
			{!active ? (
				<Typography variant='body2'>{leverage}</Typography>
			) : (
				<CommonStyledBaseInput
					type='text'
					inputProps={{ maxLength: 6, inputMode: 'decimal' }}
					value={value}
					// eslint-disable-next-line jsx-a11y/no-autofocus
					autoFocus={true}
					placeholder='0.00'
					onChange={onHandleChangeLeverage}
					onBlur={onHandleBlurLeverage}
					onKeyUp={handleKeyPress}
					sx={{
						'& .MuiInputBase-input': {
							width: inputWidth,
							textAlign: 'right',
							height: 'auto',
							fontSize: theme.typography.body2.fontSize
						}
					}}
				/>
			)}
			<Typography variant='body2'>{isPositive(leverage) ? 'x' : ''}</Typography>
		</Button>
	);
}
