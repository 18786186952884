import React, { useContext } from 'react';

import { Trans, t } from '@lingui/macro';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Decimal from 'decimal.js';

import Dialog from '../../../components/Common/Dialog';
import { QUOTE_USD, Side } from '../../../config/constants';
import { TradeContext } from '../../../context/TradeContext';
import { IOrderItem } from '../../../graphql/useMyOrdersGraph';
import { useCurrentChain } from '../../../hooks/useCurrentChain';
import {
	getPrefixForProfitLoss,
	getProfitLossTitle,
	getTriggerAboveForCreateProftLoss,
	shortenSymbolNative,
	toQuoteAmount
} from '../../../utils';

const ConfirmDialog = ({
	open,
	onClose,
	onConfirm,
	orders
}: {
	open: boolean;
	onConfirm: () => void;
	onClose: () => void;
	orders: IOrderItem[];
}) => {
	const { tradeDirection } = useContext(TradeContext);
	const sideLabel = tradeDirection === Side.SHORT ? t`Short` : t`Long`;
	const theme = useTheme();
	const { currentChainId } = useCurrentChain();

	return (
		<Dialog
			size='large'
			title={`${t`Confirm`} ${sideLabel}`}
			open={open}
			onClose={onClose}
			onConfirm={() => {
				onClose();
				onConfirm();
			}}
			onCancel={onClose}
		>
			<div className='space-y-4'>
				<Typography color='secondary'>
					<Trans>
						There is a TP/SL order in the &apos;Orders&apos;, which will take
						effect after the position is opened, and may be triggered for
						execution immediately. The order is:
					</Trans>
				</Typography>

				<div className='space-y-1'>
					{orders.map(order => {
						const prefix = getPrefixForProfitLoss(
							getTriggerAboveForCreateProftLoss(
								order.transactionType,
								tradeDirection
							),
							tradeDirection
						);
						const price = toQuoteAmount(
							order.triggerMarketPrice,
							order.baseToken.precision,
							Decimal.ROUND_HALF_CEIL
						);
						return (
							<Box
								sx={{ background: theme.custom.ListSelectBg }}
								className='px-3 py-2 rounded-sm'
								key={order.id}
							>
								<Typography fontWeight={500}>
									{`${shortenSymbolNative(
										order.baseToken,
										currentChainId
									)}/${QUOTE_USD}`}{' '}
									<Trans>
										{getProfitLossTitle(order.transactionType)}{' '}
										<Typography
											component='span'
											color={
												tradeDirection === Side.SHORT
													? 'error.main'
													: 'success.main'
											}
											fontWeight={500}
										>
											{sideLabel}
										</Typography>
										, Trigger Price {prefix}
										{price}
									</Trans>
								</Typography>
							</Box>
						);
					})}
				</div>
				<Typography color='secondary'>
					<Trans>
						You can choose to cancel the order in &apos;Orders&apos;. Are you
						sure to continue opening the position?
					</Trans>
				</Typography>
			</div>
		</Dialog>
	);
};

export default ConfirmDialog;
