import { useMemo } from 'react';

import { Trans } from '@lingui/macro';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { Transaction_Type } from 'config/constants';

import { TransactionRecord } from 'entities/TransactionRecord';
import { IOrderItem } from 'graphql/useMyOrdersGraph';
import { useSubmitCancelAllOrders } from 'hooks/useSubmitCancelAllOrders';
import { debounce } from 'lodash';
import { useAppSelector } from 'state/hooks';
import { selectTransactionRecordList } from 'state/records/selector';
import { selectOrderStatus, selectOrderOpenType } from 'state/setting/selector'
import { txBaseState } from 'state/tx/slice';
import { RecordForCancelAllOrders } from 'types';

import Loading from 'components/Svg/Icons/Loading';
import Tipping from 'components/Tipping';

export default function CancelAllOrders({
	list
}: {
	list: Array<IOrderItem> | null;
}) {
	const transactionRecords = useAppSelector(selectTransactionRecordList);
	const Order_Open_Type = useAppSelector(selectOrderOpenType);
	const { signingMap } = useAppSelector(txBaseState);

	const { onConfirm } = useSubmitCancelAllOrders();

	const hasSigningCanceledRecord = useMemo(() => {
		const signingCanceledRecord =
			signingMap.get(Transaction_Type.CancelledLimitOrder) ||
			signingMap.get(Transaction_Type.CancelTakeProfitPosition) ||
			signingMap.get(Transaction_Type.CancelStopLossPosition) ||
			signingMap.get(Transaction_Type.CancelTakeProfitPartial) ||
			signingMap.get(Transaction_Type.CancelStopLossPartial) ||
			null;
		return Boolean(signingCanceledRecord);
	}, [signingMap]);

	const canceledRecordsFromLocal = useMemo(() => {
		if (!transactionRecords || !transactionRecords.length) {
			return null;
		}
		const filteredTransactionRecords = transactionRecords;
		return filteredTransactionRecords.filter((txRecord: TransactionRecord) => {
			const record = txRecord.record;
			return (
				record.transactionType === Transaction_Type.CancelledLimitOrder ||
				record.transactionType === Transaction_Type.CancelTakeProfitPosition ||
				record.transactionType === Transaction_Type.CancelStopLossPosition ||
				record.transactionType === Transaction_Type.CancelTakeProfitPartial ||
				record.transactionType === Transaction_Type.CancelStopLossPartial
			);
		});
	}, [transactionRecords]);

	const hasCancelAllOrdersFromLocal = useMemo(() => {
		if (!transactionRecords || !transactionRecords.length) {
			return false;
		}
		const records = transactionRecords.filter((txRecord: TransactionRecord) => {
			return txRecord.type === Transaction_Type.CancelAllOrders;
		});
		return records && records.length > 0;
	}, [transactionRecords]);

	const signingCanceledAllRecord = useMemo(() => {
		return signingMap.get(
			Transaction_Type.CancelAllOrders
		) as RecordForCancelAllOrders;
	}, [signingMap]);

	const { createdLimitList, createdProfitLossList } = useMemo(() => {
		if (!list || list.length === 0) {
			return {
				createdLimitList: null,
				createdProfitLossList: null
			};
		}
		const createdLimitList = list.filter((item: IOrderItem) => {
			return item.type === Order_Open_Type.Increase;
		});
		const createdProfitLossList = list.filter((item: IOrderItem) => {
			return item.type === Order_Open_Type.Decrease;
		});
		return {
			createdLimitList,
			createdProfitLossList
		};
	}, [list]);

	const disabledCancelAll = useMemo(() => {
		if (
			(!createdLimitList || !createdLimitList.length) &&
			(!createdProfitLossList || !createdProfitLossList.length)
		) {
			return true;
		}
		if (hasSigningCanceledRecord) {
			return true;
		}
		// 只要有一个取消操作，就禁用全部取消
		if (canceledRecordsFromLocal && canceledRecordsFromLocal.length > 0) {
			return true;
		}
		return false;
	}, [
		createdLimitList,
		createdProfitLossList,
		hasSigningCanceledRecord,
		canceledRecordsFromLocal
	]);

	const onCancelAllOrders = debounce(() => {
		const limitOrderIds = createdLimitList
			? createdLimitList.map(item => item.index)
			: null;
		const profitLossOrderIds = createdProfitLossList
			? createdProfitLossList.map(item => item.index)
			: null;
		onConfirm(limitOrderIds, profitLossOrderIds);
	}, 300);

	return (
		<>
			{signingCanceledAllRecord || hasCancelAllOrdersFromLocal ? (
				<div className='inline-block h-6 text-right'>
					<Loading size={20} />
				</div>
			) : (
				<div className='flex items-center justify-end'>
					<Button
						variant='text'
						size='small'
						className={classNames('p-0 h-6')}
						color='primary'
						onClick={onCancelAllOrders}
						disabled={disabledCancelAll}
					>
						<Trans>Cancel All</Trans>
					</Button>
					<Tipping
						title={
							<Trans>
								&quot;Cancel All&quot; will revoke all unexecuted orders at
								once, requiring only a one-time gas fee payment.
							</Trans>
						}
					/>
				</div>
			)}
		</>
	);
}
