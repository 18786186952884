import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'state/hooks';

import { ApplicationModal, applicationBaseState, setOpenModal } from './slice';

export function useModalOpen(modal: ApplicationModal): boolean {
	const { openModal } = useAppSelector(applicationBaseState);
	return openModal === modal;
}

export function useToggleModal(modal: ApplicationModal): () => void {
	const open = useModalOpen(modal);
	const dispatch = useAppDispatch();
	return useCallback(
		() => dispatch(setOpenModal(open ? null : modal)),
		[dispatch, modal, open]
	);
}

export function useWalletModalToggle(): () => void {
	return useToggleModal(ApplicationModal.WALLET);
}

export { useAppDispatch };
