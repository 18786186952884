import {
	MAX_INPUT_NUMBER,
	MAX_PRECISION,
	NATIVE_BUFFER_FOR_MAX
} from 'config/constants';
import Decimal from 'decimal.js';

import { TokenInfo } from '../types';
import { toAmountFloor, toDecimalPlaces } from './formatCurrencyAmount';
import { minus } from './mathUtils';

/**
 * Calculate the user based on the account balance to get the maximum number of operable
 * @param balance
 * @param token
 * @returns
 */
export function computeMaxAmount(
	balance: string,
	token: TokenInfo | null
): string {
	const _balance = new Decimal(balance);
	let value: string;
	if (_balance.greaterThanOrEqualTo(MAX_INPUT_NUMBER)) {
		value = String(MAX_INPUT_NUMBER);
	} else {
		value = toAmountFloor(
			toDecimalPlaces(balance, token?.decimals),
			MAX_PRECISION
		);
	}
	if (token?.isNative) {
		value = minus(value, NATIVE_BUFFER_FOR_MAX);
	}
	return value;
}
