import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Paper, { PaperProps } from '@mui/material/Paper';
import { alpha, styled } from '@mui/material/styles';
import { Page, Version } from 'config/constants';

import { useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';

/* eslint-disable import/no-unresolved */
import 'swiper/css';

/* eslint-disable import/no-unresolved */
import 'swiper/css/pagination';

/* eslint-disable import/no-unresolved */
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import bannerPool2 from '../../assets/images/banner/banner-pool2.jpg';
import bannerPosition from '../../assets/images/banner/banner-position.jpg';
import bannerReferral from '../../assets/images/banner/banner-referral.jpg';
import bannerV2 from '../../assets/images/banner/banner-v2.jpg';

const StyledMarquee = styled(Swiper)(({ theme }) => ({
	color: theme.palette.common.white,
	fontSize: 'inherit',
	'.swiper-pagination': {
		'.swiper-pagination-bullet': {
			opacity: 0.5,
			background:
				theme.palette.mode === 'dark' ? alpha('#fff', 0.5) : alpha('#000', 0.5),
			'&.swiper-pagination-bullet-active': {
				opacity: 1,
				background:
					theme.palette.mode === 'dark' ? alpha('#fff', 1) : alpha('#000', 0.9)
			}
		}
	}
}));

const StyledListCard = styled(Paper)<PaperProps>(({ theme }) => ({
	borderRadius: theme.spacing(1),
	background:
		theme.palette.mode === 'dark'
			? 'linear-gradient(238deg, rgba(0, 255, 194, 0.31) 0%, rgba(4, 144, 68, 0.00) 39.06%), radial-gradient(122.51% 97.82% at 0% -1.95%, #77D964 0%, rgba(42, 187, 123, 0.15) 68.62%, rgba(7, 173, 133, 0.00) 100%), #00595F;'
			: 'linear-gradient(66.14deg, rgba(255, 255, 255, 0.6) -1.6%, rgba(128, 238, 184, 0.6) 97.87%), #F9FAFD',
	'.title': {
		borderBottom: `1px dashed ${alpha(theme.palette.divider, 0.1)}`,
		padding: theme.spacing(1, 0, 2)
	}
}));

const Banner = () => {
	const currentVersion = useAppSelector(selectVersion);
	return (
		<div className='px-3'>
			<StyledMarquee
				loop={false}
				init={true}
				initialSlide={0}
				spaceBetween={30}
				centeredSlides={true}
				pagination={{
					clickable: true,
					type: 'bullets'
				}}
				autoplay={{
					delay: 5000,
					disableOnInteraction: false
				}}
				className='mySwiper'
				modules={[Autoplay, Pagination]}
			>
				{currentVersion === Version.V1 ? (
					<>
						<SwiperSlide>
							<StyledListCard className='relative overflow-hidden'>
								<Link to={`${Page.EarnV1}/pool2`}>
									<img src={bannerPool2} className='w-full' alt='swipper-img' />
								</Link>
							</StyledListCard>
						</SwiperSlide>
						<SwiperSlide>
							<StyledListCard className='relative overflow-hidden'>
								<Link to={`${Page.EarnV1}/position`}>
									<img
										src={bannerPosition}
										className='w-full'
										alt='swipper-img'
									/>
								</Link>
							</StyledListCard>
						</SwiperSlide>
						<SwiperSlide>
							<StyledListCard className='relative overflow-hidden'>
								<Link to={Page.ReferralsV1}>
									<img
										src={bannerReferral}
										className='w-full'
										alt='swipper-img'
									/>
								</Link>
							</StyledListCard>
						</SwiperSlide>
					</>
				) : (
					<SwiperSlide>
						<StyledListCard className='relative overflow-hidden'>
							<Link to={Page.Pools}>
								<img src={bannerV2} className='w-full' alt='swipper-img' />
							</Link>
						</StyledListCard>
					</SwiperSlide>
				)}
			</StyledMarquee>
		</div>
	);
};

export default Banner;
