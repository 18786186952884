import { ReactNode, useState } from 'react';

import { Close } from '@mui/icons-material';
import { Alert, Box, IconButton, alpha, useTheme } from '@mui/material';
import classNames from 'classnames';
import { Dom_Size } from 'config/constants';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';

import Notification from 'components/Svg/Icons/Notification';

export default function Notify({
	children,
	width = Dom_Size.ACTIVITY_CONTAINER,
	className
}: {
	children: ReactNode;
	width?: number | string;
	className?: string;
}) {
	const theme = useTheme();
	const { isMatchMobile, isMatchPad } = useAppBreakpoints();
	const [visible, setVisible] = useState<boolean>(true);

	return visible ? (
		<Box
			component='div'
			className={classNames('flex items-center')}
			sx={{
				backgroundColor: alpha(theme.palette.text.primary, 0.15),
				padding: isMatchMobile || isMatchPad ? '' : `0 8px`
			}}
		>
			<Alert
				className={classNames('mx-auto items-center justify-center', className)}
				icon={<Notification fontSize={isMatchMobile ? 'small' : 'medium'} />}
				action={
					<IconButton onClick={() => setVisible(false)}>
						<Close sx={{ width: isMatchMobile ? 20 : 'auto' }} />
					</IconButton>
				}
				sx={{
					width: isMatchPad
						? 'calc(100% - 32px)'
						: isMatchMobile
						? 'calc(100% - 24px)'
						: width,
					minHeight: Dom_Size.MOBILE_HEIGHT_BOTTOM,
					fontSize: isMatchMobile ? 13 : 16,
					color: '#FF7B1B',
					'& .MuiAlert-action': {
						paddingLeft: isMatchMobile ? '0' : '16px'
					}
				}}
			>
				{children}
			</Alert>
		</Box>
	) : null;
}
