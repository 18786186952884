import React, { useEffect, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans, t } from '@lingui/macro';
import { Card, CardContent, Typography, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Decimal from 'decimal.js';

import { LiquidityPositionUtil } from 'entities/LiquidityPositionUtil';
import { useCurrentChain } from 'hooks/useCurrentChain';

import ApproveButton from 'components/ApproveButton';
import Leverage from 'components/Leverage';

import Cell from '../../components/Common/Cell';
import Dialog from '../../components/Common/Dialog';
import {
	CommonStyledBaseInput,
	CommonStyledDivider,
	CommonStyledMaxButton
} from '../../components/Common/Styled';
import Button from '../../components/Common/StyledButton';
import ToNext from '../../components/ToNext';
import {
	MAX_PRECISION,
	QUOTE_USD,
	Transaction_Type,
	Version
} from '../../config/constants';
import useSubmitAdjustMarginLiquidity from '../../hooks/useSubmitAdjustMarginLiquidity';
import { globalBaseState } from '../../state/global/slice';
import { useAppSelector } from '../../state/hooks';
import { txBaseState } from '../../state/tx/slice';
import {
	amountFormatter,
	catchFn,
	checkInputNumberic,
	div,
	formatLeverage,
	formatNumber,
	isEqualTo,
	isGreaterThan,
	isLessThan,
	isLessThanOrEqualTo,
	isNegative,
	isPositive,
	minus,
	multipliedBy,
	plus,
	shortenSymbol,
	shortenSymbolNative,
	toAmountString,
	toDecimalPlaces,
	toPercent,
	toQuoteAmount,
	toUsd
} from '../../utils';
import { calcRiskRateColor } from '../../utils/riskRate';

const AdjustLiquidityMargin = ({
	currentPosition,
	onClose
}: {
	currentPosition: Record<string, any>;
	onClose: () => void;
}) => {
	const { quoteBalance, signingMap, quoteToken } = useAppSelector(txBaseState);
	const currentVersion = useAppSelector(state => state.setting.currentVersion);

	const [transactionType, setTransactionType] = useState(
		Transaction_Type.IncreaseMarginLiquidity
	);
	const [value, setValue] = useState<string>('');

	const [nextMargin, nextRealizeProfit] = useMemo(() => {
		if (!isPositive(value)) {
			return ['', ''];
		}

		if (transactionType === Transaction_Type.IncreaseMarginLiquidity) {
			return [plus(currentPosition.marginNet, value), ''];
		}

		const _marginNet = plus(
			currentPosition.marginNet,
			currentPosition.totalRealizedProfit
		);
		if (isGreaterThan(value, _marginNet)) {
			return ['', ''];
		}
		// 用利润抵扣
		if (isLessThanOrEqualTo(value, currentPosition.totalRealizedProfit)) {
			return ['', minus(currentPosition.totalRealizedProfit, value)];
		}
		return [
			minus(
				currentPosition.marginNet,
				minus(value, currentPosition.totalRealizedProfit)
			),
			'0'
		];
	}, [
		transactionType,
		currentPosition.marginNet,
		currentPosition.totalRealizedProfit,
		value
	]);

	const targetLeverage = useMemo(() => {
		if (!isPositive(value)) {
			return '';
		}
		if (transactionType === Transaction_Type.IncreaseMarginLiquidity) {
			return div(
				currentPosition.liquidity,
				plus(currentPosition.marginNet, value)
			);
		} else {
			if (isGreaterThan(value, currentPosition.marginNet)) {
				return '';
			}
			return div(
				currentPosition.liquidity,
				minus(currentPosition.marginNet, value)
			);
		}
	}, [transactionType, currentPosition.marginNet, value]);

	const nextNetMargin = useMemo(() => {
		if (!isPositive(value)) {
			return '';
		}
		if (transactionType === Transaction_Type.IncreaseMarginLiquidity) {
			return plus(currentPosition.marginNet, value);
		}

		if (isGreaterThan(value, currentPosition.marginNet)) {
			return '';
		}
		return minus(currentPosition.marginNet, value);
	}, [transactionType, currentPosition.marginNet, value]);

	const nextRiskRate = useMemo(() => {
		if (!isPositive(nextNetMargin)) {
			return '';
		}
		const _riskRate = catchFn(() => {
			return LiquidityPositionUtil.calcRiskRate(
				currentPosition.unrealizedLoss,
				currentPosition.liquidationExecutionFee,
				nextNetMargin,
				currentPosition.maxRiskRatePerLiquidityPosition
			);
		}, '');
		return _riskRate;
	}, [currentPosition, nextNetMargin]);

	const { currentChainId, currentChain } = useCurrentChain();

	const { onConfirm, isConfirmed, isConfirming, setTxSuccessCallback } =
		useSubmitAdjustMarginLiquidity(
			currentPosition.address,
			currentPosition.id,
			value,
			transactionType,
			currentPosition.baseToken
		);

	const maxDecreaseMargin = useMemo(() => {
		const maintainFeeMaxMargin = isEqualTo(currentPosition.unrealizedLoss, 0)
			? multipliedBy(
					minus(
						currentPosition.marginNet,
						currentPosition.liquidationExecutionFee
					),
					currentPosition.maxRiskRatePerLiquidityPosition
			  )
			: minus(
					minus(
						currentPosition.marginNet,
						currentPosition.liquidationExecutionFee
					),
					div(
						currentPosition.unrealizedLoss,
						currentPosition.maxRiskRatePerLiquidityPosition
					)
			  );
		const _maintainLeverageMaxMargin = minus(
			currentPosition.marginNet,
			div(
				currentPosition.liquidity,
				currentPosition.maxLeveragePerLiquidityPosition
			)
		);
		const maintainLeverageMaxMargin = isNegative(_maintainLeverageMaxMargin)
			? '0'
			: _maintainLeverageMaxMargin;
		return isLessThan(maintainFeeMaxMargin, maintainLeverageMaxMargin)
			? maintainFeeMaxMargin
			: maintainLeverageMaxMargin;
	}, [currentPosition]);

	const { positionMinExecutionFee, nativePriceUsd } =
		useAppSelector(globalBaseState);

	const disabled = useMemo(() => {
		if (signingMap.get(transactionType)) {
			return true;
		}
		if (isConfirming) {
			return true;
		}
		if (!isPositive(value)) {
			return true;
		}
		if (
			transactionType === Transaction_Type.ReduceMarginLiquidity &&
			isGreaterThan(value, maxDecreaseMargin)
		) {
			return true;
		}
		return (
			transactionType === Transaction_Type.IncreaseMarginLiquidity &&
			isGreaterThan(value, quoteBalance)
		);
	}, [value, transactionType, maxDecreaseMargin, signingMap, isConfirming]);

	const submitText = useMemo(() => {
		if (signingMap.get(transactionType) || isConfirming) {
			return <Trans>Loading...</Trans>;
		}
		if (isPositive(value)) {
			if (transactionType === Transaction_Type.IncreaseMarginLiquidity) {
				if (isGreaterThan(value, quoteBalance)) {
					return <Trans>Exceeds the max increase</Trans>;
				}
			}
			if (transactionType === Transaction_Type.ReduceMarginLiquidity) {
				if (isGreaterThan(value, maxDecreaseMargin)) {
					return <Trans>Exceeds the max decrease</Trans>;
				}
			}
		}
		return <Trans>Confirm</Trans>;
	}, [value, transactionType, maxDecreaseMargin, signingMap, isConfirming]);

	const onHandleChangeTab = (value: typeof transactionType) =>
		setTransactionType(value);
	const onChange: React.ChangeEventHandler<HTMLInputElement> = e => {
		if (!checkInputNumberic(e.target.value, quoteToken?.decimals)) {
			return;
		}
		setValue(e.target.value);
	};
	const onDialogConfirm = () => {
		onConfirm();
	};
	const onHandleSetMaxAmount = () => {
		if (transactionType === Transaction_Type.IncreaseMarginLiquidity) {
			setValue(quoteBalance);
		} else {
			setValue(
				toAmountString(toDecimalPlaces(maxDecreaseMargin, 6), MAX_PRECISION)
			);
		}
	};

	useEffect(() => {
		setTxSuccessCallback(() => onClose);
	}, [isConfirmed]);

	useUpdateEffect(() => {
		setValue('');
	}, [transactionType]);

	const theme = useTheme();

	const passivePosition = useMemo(() => {
		return currentPosition?.liquidityPassivePosition || null;
	}, [currentPosition]);

	return (
		<Dialog open onClose={onClose} title={t`Adjust Margin`}>
			<div className='flex'>
				<div className='relative z-9 flex-1'>
					<Button
						fullWidth
						componentVariant={
							transactionType === Transaction_Type.IncreaseMarginLiquidity
								? 'mainOutlined'
								: 'outlined'
						}
						onClick={() =>
							onHandleChangeTab(Transaction_Type.IncreaseMarginLiquidity)
						}
					>
						<Trans>Add Margin</Trans>
					</Button>
				</div>
				<div className='relative z-9 flex-1'>
					<Button
						fullWidth
						componentVariant={
							transactionType === Transaction_Type.ReduceMarginLiquidity
								? 'mainOutlined'
								: 'outlined'
						}
						onClick={() =>
							onHandleChangeTab(Transaction_Type.ReduceMarginLiquidity)
						}
					>
						<Trans>Reduce Margin</Trans>
					</Button>
				</div>
			</div>
			<Card className='mt-4'>
				<CardContent>
					<Cell
						label={
							<Typography variant='body1'>
								<Trans>Amount</Trans>
							</Typography>
						}
						prefix={<Trans>Max:</Trans>}
						value={`${
							transactionType === Transaction_Type.IncreaseMarginLiquidity
								? formatNumber(quoteBalance)
								: formatNumber(maxDecreaseMargin)
						} ${shortenSymbol(quoteToken)}`}
					/>

					<CommonStyledBaseInput
						type='text'
						inputProps={{ maxLength: MAX_PRECISION, inputMode: 'decimal' }}
						className='input'
						value={value}
						placeholder='0.0'
						onChange={onChange}
						fullWidth
						endAdornment={
							<div className='flex items-center space-x-2'>
								<CommonStyledMaxButton
									variant='outlined'
									color='secondary'
									onClick={() => onHandleSetMaxAmount()}
								>
									<Trans>Max</Trans>
								</CommonStyledMaxButton>
							</div>
						}
					/>
				</CardContent>
			</Card>
			<Typography className='mt-4'>
				{`${shortenSymbolNative(
					currentPosition.baseToken,
					currentChainId
				)}/${QUOTE_USD}`}
			</Typography>
			<div className='mt-3 space-y-1'>
				<Cell
					label={
						<Typography variant='body2'>
							<Trans>Margin</Trans>
						</Typography>
					}
					value={
						<ToNext
							value={toQuoteAmount(currentPosition.marginNet)}
							nextValue={toQuoteAmount(nextMargin)}
							hasNext={isPositive(nextMargin)}
						/>
					}
					unit={shortenSymbol(quoteToken)}
				/>
				<Cell
					label={
						<Typography variant='body2'>
							<Trans>Leverage</Trans>
						</Typography>
					}
					value={
						<ToNext
							value={
								<Leverage
									value={currentPosition.leverage as string}
									margin={currentPosition.marginNet}
								/>
							}
							nextValue={formatLeverage(targetLeverage)}
							hasNext={isPositive(targetLeverage)}
						/>
					}
				/>

				<Cell
					label={
						<Typography variant='body2'>
							<Trans>Liquidity</Trans>
						</Typography>
					}
					value={
						<ToNext
							value={toQuoteAmount(currentPosition.liquidity)}
							hasNext={false}
						/>
					}
				/>

				<Cell
					label={<Trans>Realized Profit</Trans>}
					value={
						<ToNext
							value={
								<Typography
									variant='body2'
									color={
										isPositive(currentPosition.totalRealizedProfit)
											? theme.palette.success.main
											: theme.palette.text.primary
									}
								>
									{toQuoteAmount(currentPosition.totalRealizedProfit)}
								</Typography>
							}
							nextValue={toQuoteAmount(nextRealizeProfit)}
							hasNext={nextRealizeProfit !== ''}
						/>
					}
				/>

				<Cell
					label={
						<Typography variant='body2'>
							<Trans>Temporary Loss</Trans>
						</Typography>
					}
					value={
						<ToNext
							value={
								<Typography
									variant='body2'
									color={
										isGreaterThan(currentPosition.unrealizedLoss, 0)
											? theme.palette.error.main
											: 'inherit'
									}
								>
									{toQuoteAmount(-currentPosition.unrealizedLoss)}
								</Typography>
							}
							hasNext={false}
						/>
					}
				/>

				<Cell
					label={
						<Typography variant='body2'>
							<Trans>Risk Rate</Trans>
						</Typography>
					}
					value={
						<ToNext
							value={
								<Typography
									variant='body2'
									color={
										calcRiskRateColor(Number(currentPosition?.riskRatio))
											.mainColor
									}
								>
									{toPercent(currentPosition.riskRatio)}
								</Typography>
							}
							nextValue={
								isPositive(nextRiskRate) && (
									<Typography
										variant='body2'
										color={calcRiskRateColor(Number(nextRiskRate)).mainColor}
									>
										{toPercent(nextRiskRate)}
									</Typography>
								)
							}
							hasNext={isPositive(nextRiskRate)}
						/>
					}
				/>
			</div>
			{currentVersion === Version.V2 ? (
				<Paper className='p-4 space-y-2 mt-2'>
					<Typography>
						<Trans>Passive Position</Trans>
					</Typography>
					<div className='space-y-1'>
						<Cell
							label={<Trans>Size</Trans>}
							value={amountFormatter(passivePosition?.size)}
						/>
						<Cell
							label={<Trans>Entry Price</Trans>}
							value={`${formatNumber(
								passivePosition?.entryPrice,
								quoteToken.positionUnits
							)} ${quoteToken.symbol}`}
						/>
						<Cell
							label={<Trans>Liq.Price</Trans>}
							value={`${formatNumber(
								passivePosition?.liquidationPrice,
								quoteToken.positionUnits
							)} ${quoteToken.symbol}`}
						/>
						<Cell
							label={<Trans>PnL</Trans>}
							value={toQuoteAmount(
								passivePosition?.realizedPnl,
								2,
								Decimal.ROUND_DOWN,
								true
							)}
						/>
					</div>
				</Paper>
			) : null}
			<CommonStyledDivider />
			<div className='mt-4'>
				<Cell
					label={
						<Typography variant='body2' color='text.primary'>
							<Trans>Execution Fee</Trans>
						</Typography>
					}
					value={
						<div className='flex space-x-1'>
							<Typography variant='body2'>
								{`${formatNumber(positionMinExecutionFee, 5)} ${
									currentChain.nativeCurrency.symbol
								}`}
							</Typography>
							<Typography variant='body2' color='secondary'>
								({toUsd(multipliedBy(nativePriceUsd, -positionMinExecutionFee))}
								)
							</Typography>
						</div>
					}
				/>
			</div>
			<div className='mt-4'>
				<ApproveButton
					componentVariant='confirm'
					fullWidth
					onClick={onDialogConfirm}
					disabled={disabled}
					amountToApprove={value}
					isApproveToken={
						transactionType === Transaction_Type.IncreaseMarginLiquidity
					}
					isApprovePositionRouter
				>
					{submitText}
				</ApproveButton>
			</div>
		</Dialog>
	);
};

export default AdjustLiquidityMargin;
