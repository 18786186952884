import {
	Alert,
	AlertProps,
	Button,
	Divider,
	DividerProps,
	IconButton,
	IconButtonProps,
	InputBase,
	InputBaseProps,
	List,
	ListProps,
	OutlinedInput,
	OutlinedInputProps,
	alpha,
	styled
} from '@mui/material';
import { App_Theme } from 'config/constants';

import styledComponents from 'styled-components';

export interface ExtendDividerProps extends DividerProps {
	type?: string;
}
export const CommonStyledArticle = styledComponents.article<{
	disabled?: boolean;
}>`
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
`;

export const CommonStyledDivider = styled(Divider)<ExtendDividerProps>(
	({ theme, type }) => ({
		marginTop: theme.spacing(1.5),
		marginBottom: theme.spacing(1.5),
		borderColor: alpha(theme.palette.text.primary, 0.1),
		borderStyle: type ?? 'dashed'
	})
);

export const CommonStyledFilledInput = styled(
	(props: OutlinedInputProps & { valuetype?: string }) => {
		const onChange = (event: any) => {
			let value = event.target.value;
			value = value.replace('。', '.');
			if (props.onChange) {
				props.onChange({ ...event, target: { ...event.target, value } });
			}
		};
		return <OutlinedInput {...props} onChange={onChange} />;
	}
)<OutlinedInputProps>(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === App_Theme.dark
			? theme.palette.background.paper
			: theme.custom.cardBg,
	'& .MuiInputBase-input': {
		backgroundColor:
			theme.palette.mode === App_Theme.dark
				? theme.palette.background.paper
				: theme.custom.cardBg,
		boxSizing: 'border-box',
		textAlign: 'right',
		fontWeight: 400,
		height: 44,
		padding: 16,
		fontSize: 16
	},
	'&:hover .MuiOutlinedInput-notchedOutline': {
		border: '0 none'
	},
	'.MuiOutlinedInput-notchedOutline': {
		borderWidth: `1px !important`,
		border: '0 none',
		'&:hover': {
			border: '0 none'
		}
	},
	'&.Mui-focused': {
		'.MuiOutlinedInput-notchedOutline': {
			border: '0 none',
			boxShadow: 'none'
		}
	}
}));

export const CommonStyledOutlineInput = styled(
	(props: OutlinedInputProps & { valuetype?: string }) => {
		const onChange = (event: any) => {
			let value = event.target.value;
			value = value.replace('。', '.');
			if (props.onChange) {
				props.onChange({ ...event, target: { ...event.target, value } });
			}
		};
		return <OutlinedInput {...props} onChange={onChange} />;
	}
)<OutlinedInputProps>(({ theme }) => ({
	padding: '0',
	border: '0 none !important',
	'& .MuiOutlinedInput-input': {
		boxSizing: 'border-box',
		textAlign: 'right',
		height: 40,
		padding: '0',
		fontSize: 16
	},
	'.MuiOutlinedInput-notchedOutline': {
		borderWidth: `1px`,
		borderColor: theme.palette.secondary.main,
		'&:hover': {
			borderColor: `${theme.palette.primary.main}`
		}
	},
	'&.Mui-focused': {
		'.MuiOutlinedInput-notchedOutline': {
			borderWidth: `1px`,
			borderColor: `${theme.palette.primary.main}`
		}
	},
	'&:hover .MuiOutlinedInput-notchedOutline': {
		borderWidth: `1px`,
		borderColor: `${theme.palette.primary.main}`
	},
	'.MuiOutlinedInput-input': {
		padding: 0
	}
}));

export const CommonStyledBaseInput = styled(
	(props: InputBaseProps & { valuetype?: string }) => {
		const onChange = (event: any) => {
			let value = event.target.value;
			value = value.replace('。', '.');
			if (props.onChange) {
				props.onChange({ ...event, target: { ...event.target, value } });
			}
		};
		return <InputBase {...props} onChange={onChange} />;
	}
)<InputBaseProps>(({ theme }) => ({
	padding: '0',
	border: 0,
	'& .MuiInputBase-input': {
		boxSizing: 'border-box',
		textAlign: 'left',
		fontWeight: 400,
		height: 44,
		padding: '0',
		fontSize: 16,
		'&:focus': {
			boxShadow: 'none'
		}
	}
}));

export const CommonStyledDialogInput = styled(
	(props: InputBaseProps & { valuetype?: string }) => {
		const onChange = (event: any) => {
			let value = event.target.value;
			value = value.replace('。', '.');
			if (props.onChange) {
				props.onChange({ ...event, target: { ...event.target, value } });
			}
		};
		return <InputBase {...props} onChange={onChange} />;
	}
)<InputBaseProps>(({ theme }) => ({
	padding: '0 10px',
	backgroundColor: theme.palette.background.paper,
	border: 0,
	'& .MuiInputBase-input': {
		backgroundColor:
			theme.palette.mode === App_Theme.dark
				? theme.palette.background.paper
				: theme.custom.cardBg,
		boxSizing: 'border-box',
		textAlign: 'left',
		fontWeight: 400,
		height: 44,
		padding: '0',
		fontSize: 16,
		'&:focus': {
			boxShadow: 'none'
		}
	}
}));

export const CommonStyledSwitchDirectionButton = styled(
	IconButton
)<IconButtonProps>(({ theme }) => {
	return {
		color: theme.palette.text.primary,
		minWidth: 0,
		width: 40,
		height: 40,
		borderRadius: '22px',
		boxShadow: `0px 2px 4px ${theme.custom.ListBorder}`,
		fontSize: '24px',
		backgroundColor: theme.palette.background.paper,
		'&:hover': {
			background: theme.palette.action.hover
		}
	};
});

export const StyledTipIconButton = styled(IconButton)<IconButtonProps>(
	({ theme }) => ({
		border: `1px solid ${alpha(theme.palette.divider, 0.4)}`,
		margin: `0 ${theme.spacing(0.5)}`,
		width: 14,
		height: 14
	})
);

export const CommonStyledSelectedButton = styled(Button)(({ theme }) => ({
	borderRadius: 32,
	minWidth: 'auto',
	height: 32,
	padding: '0 4px',
	marginRight: -4,
	textAlign: 'left',
	color: theme.palette.text.primary,
	'& .MuiButton-startIcon': {
		marginRight: theme.breakpoints.down('sm')
			? theme.spacing(0.5)
			: theme.spacing(1)
	},
	'& .MuiButton-endIcon': {
		marginLeft: theme.breakpoints.down('sm')
			? theme.spacing(0)
			: theme.spacing(0.5)
	}
}));

export const CommonStyledChooseButton = styled(Button)(() => {
	return {
		borderRadius: 32,
		minWidth: 'auto',
		textTransform: 'none',
		textAlign: 'left',
		padding: '6px 12px'
	};
});

export const CommonStyledMaxButton = styled(Button)(({ theme }) => {
	return {
		minWidth: 'auto',
		height: 24,
		padding: '0 16px',
		fontSize: 14,
		color: theme.palette.text.primary,
		'&:hover': {
			border: `1px solid ${theme.palette.primary.main}`
		}
	};
});

export const CommonStyledOutlinedButton = styled(Button)(({ theme }) => {
	return {
		minWidth: 'auto',
		height: 32,
		padding: '0 16px',
		fontSize: 14,
		fontWeight: 400,
		color: theme.palette.text.primary,
		'&:hover': {
			border: `1px solid ${theme.palette.primary.main}`
		},
		'@media screen and (max-width: 768px)': {
			padding: '4px 12px',
			fontSize: 12,
			height: 'auto'
		},
		'&:disabled': {
			opacity: theme.palette.action.disabledOpacity,
			'& .MuiTypography-root': {
				opacity: theme.palette.action.disabledOpacity
			}
		}
	};
});

export const CommonStyledList = styled(List)<ListProps>(({ theme }) => ({
	'& .MuiListItem-root': {
		borderBottom: `1px dashed ${alpha(theme.palette.text.primary, 0.06)}`
	}
}));

export const CommonStyledAlertWarning = styled(Alert)<AlertProps>(
	({ theme }) => {
		return {
			backgroundColor:
				theme.palette.mode === App_Theme.dark
					? theme.palette.background.paper
					: '#F5F5F5',
			padding: '6px 12px',
			'& .MuiAlert-icon': {
				display: 'none'
			},
			'& .MuiAlert-message': {
				color: theme.custom.warningColor
			}
		};
	}
);
