import { LinkProps } from '@mui/material';
import { styled } from '@mui/material/styles';

// const StyledLinkComponent = styled('a')<any>(
// 	({ theme, color, underline = true }) => ({
// 		color: color || theme.palette.primary.main,
// 		textDecoration: underline ? 'underline' : 'none',
// 		'&:hover': {
// 			cursor: 'pointer',
// 			color: color ? alpha(color, 0.8) : alpha(theme.palette.primary.main, 0.8)
// 		}
// 	})
// );

const StyledLinkComponent = styled('a')<any>(
	({ theme, underline = 'none', type = '' }) => {
		if (type === 'white') {
			return {
				color: theme.palette.text.primary,
				textDecoration: underline === 'none' ? 'none' : 'underline',
				'&:hover': {
					cursor: 'pointer',
					color: theme.palette.primary.main
				}
			};
		}
		return {
			color: theme.palette.text.secondary,
			textDecoration: underline === 'none' ? 'none' : 'underline',
			'&:hover': {
				cursor: 'pointer',
				color: theme.palette.primary.main
			}
		};
	}
);

function StyledLink(props: LinkProps) {
	const { children } = props;

	return <StyledLinkComponent {...props}>{children}</StyledLinkComponent>;
}

export default StyledLink;
