import {
	NATIVE_CURRENCY,
	WRAPPED_NATIVE_CURRENCY
} from 'config/constants/tokens';

import { TokenInfo } from 'types';

export function getCurrencyAddress(token: TokenInfo | null) {
	if (!token) return undefined;
	if (token.isNative) {
		return token.wrapped?.address;
	} else {
		return token.address;
	}
}

export function getCurrencyAddressByChain(
	token: TokenInfo | null,
	chainId: number
) {
	if (!token) return undefined;
	if (token.isNative) {
		return NATIVE_CURRENCY[chainId].name;
	} else if (token?.symbol === WRAPPED_NATIVE_CURRENCY[chainId]?.symbol) {
		return NATIVE_CURRENCY[chainId].name;
	} else {
		return token.address;
	}
}

export function getAddressForUrl(token: TokenInfo) {
	if (token.isNative) {
		return token.symbol;
	} else {
		return token.address;
	}
}
