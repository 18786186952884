import React, { useEffect } from 'react';
import { useInterval, useUpdateEffect } from 'react-use';

import { useAccount } from 'wagmi';

import { useCheckLogin } from 'hooks/useCurrentChain';
import { useFarmClaimDetailsV2Request } from 'fetch/useRequest';
import { Reward_Type } from 'pages/Earn/types';
import { globalBaseState } from 'state/global/slice';
import { useAppDispatch, useAppSelector } from 'state/hooks';

import {
	setClaimableAppAmount,
	setIsLoadingPool2Rewards,
	setDailyEmission,
	setTotalAppAmount,
	setUniAPR,
	setUniTvl
} from './slice';
import { useUniV3Apr } from 'hooks/V3/useUniV3Apr';
import useIsWindowVisible from 'hooks/useIsWindowVisible';
import { TIME_ONE_MINUTE_TO_SECOND } from 'config/constants';

export default React.memo(function EarnPool2Updater() {
	const dispatch = useAppDispatch();
	const { appTimer } = useAppSelector(globalBaseState);

	const isLogin = useCheckLogin();
	const { address } = useAccount();
	const isWindowVisible = useIsWindowVisible();

	const {
		isLoading,
		claimable_amount,
		total_amount,
		data,
		refetch: refetchRewards
	} = useFarmClaimDetailsV2Request(address, Reward_Type.POOL2);

	const { uniAPR, uniTotalTvlUSD, refechUniPools } = useUniV3Apr();

	useEffect(() => {
		refechUniPools();
	}, []);

	useInterval(
		() => {
			refechUniPools();
		},
		isWindowVisible ? TIME_ONE_MINUTE_TO_SECOND : null
	);

	useUpdateEffect(() => {
		dispatch(setUniAPR(uniAPR));
	}, [uniAPR]);

	useUpdateEffect(() => {
		dispatch(setUniTvl(uniTotalTvlUSD));
	}, [uniTotalTvlUSD]);

	useUpdateEffect(() => {
		dispatch(setIsLoadingPool2Rewards(isLoading));
	}, [isLoading]);

	useUpdateEffect(() => {
		dispatch(setTotalAppAmount(total_amount));
	}, [total_amount]);

	useUpdateEffect(() => {
		dispatch(setClaimableAppAmount(claimable_amount));
	}, [claimable_amount]);

	useUpdateEffect(() => {
		if (data && data[0]) {
			dispatch(setDailyEmission(data[0].daily_emission_amount));
		}
	}, [data]);

	useUpdateEffect(() => {
		if (isLogin) {
			refetchRewards();
		}
	}, [isLogin, address, appTimer]);

	return null;
});
