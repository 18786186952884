import React, { useMemo, useState } from 'react';
import Countdown from 'react-countdown-simple';
import { useInterval } from 'react-use';

import { Typography } from '@mui/material';

export default React.memo(function FundingFeeTimer() {
	const [currentHours, setCurrentHours] = useState<number>(
		new Date().getHours()
	);

	useInterval(() => {
		if (new Date().getHours() !== currentHours) {
			setCurrentHours(new Date().getHours());
		}
	}, 1000);

	const oneHour = useMemo(() => {
		return new Date(
			new Date().setHours(new Date().getHours() + 1, 0, 0)
		).toISOString();
	}, [currentHours]);

	return (
		<Countdown
			targetDate={oneHour}
			formatType='dd_hh_mm_ss'
			renderer={({ minutes, seconds }) => (
				<Typography variant='body2' color='secondary' className="sm:w-[42px]">
					({minutes}:{seconds > 9 ? seconds : '0' + seconds.toString()})
				</Typography>
			)}
		/>
	);
});
