import React, { useContext, useState } from 'react';

import { Trans } from '@lingui/macro';
import { IconButton, Paper, Typography, useTheme } from '@mui/material';
import { Dom_Size, Version } from 'config/constants';
import {
	MobileActionContext,
	MobileActionsContextProvider
} from 'context/MobileActionsContext';

import { useCurrentChain } from 'hooks/useCurrentChain';
import { useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';
import { shortenSymbolNative } from 'utils';

import Dialog from 'components/Common/Dialog';
import CommonStyledButton from 'components/Common/StyledButton';

import ComputerIcon from '../../components/Svg/Icons/Computer';
import { poolsBaseState } from '../../state/pools/slice';
import V2Logo from '../components/V2/V2Logo';
import LiquidityComputerDialog from './LiquidityComputerDialog';
import LiquidityForm from './LiquidityForm';
import LiquidityFormTips from './V2/LiquidityFormTips';
import LiquidityFormV2 from './V2/LiquidityFormV2';

export function MobileActionsContent() {
	const { poolInfo } = useAppSelector(poolsBaseState);
	const currentVersion = useAppSelector(selectVersion);
	const { currentChainId } = useCurrentChain();

	const { isVisibleForm, setIsVisibleForm } = useContext(MobileActionContext);

	const onHandleCloseForm = () => {
		setIsVisibleForm(false);
	};

	const [addConfirmTips, setAddConfirmTips] = useState(false);
	const [visibleComputer, setVisibleComputer] = useState(false);
	const onOpenComputerDialog = () => setVisibleComputer(true);
	const onClose = () => setVisibleComputer(false);
	const theme = useTheme();
	const { liquidityPools } = useAppSelector(poolsBaseState);

	return (
		<article>
			<Paper
				className='flex space-x-3 px-3'
				sx={{
					position: 'fixed',
					width: '100%',
					bottom: Dom_Size.MOBILE_HEIGHT_BOTTOM
				}}
			>
				<CommonStyledButton
					className='flex flex-col justify-center'
					variant='contained'
					size='large'
					fullWidth
					onClick={() => setAddConfirmTips(true)}
				>
					<Typography
						className='whitespace-normal'
						color='inherit'
						variant='h6'
						fontWeight={500}
					>
						<Trans>Add</Trans>
					</Typography>
				</CommonStyledButton>
			</Paper>
			<Dialog open={isVisibleForm} onClose={onHandleCloseForm}>
				<div className='flex flex-1 justify-between items-center pt-2 pb-4'>
					<div className='flex items-center'>
						<Trans>
							Add {shortenSymbolNative(poolInfo?.baseToken, currentChainId)}{' '}
							Liquidity
						</Trans>
						{currentVersion === Version.V2 && (
							<V2Logo className='ml-1' width={18} height={14} />
						)}
					</div>
					<IconButton className='-mr-1' onClick={onOpenComputerDialog}>
						<ComputerIcon
							color={theme.palette.mode === 'dark' ? 'white' : 'black'}
						/>
					</IconButton>
				</div>
				{currentVersion === Version.V1 ? (
					<LiquidityForm />
				) : (
					<LiquidityFormV2 />
				)}
			</Dialog>
			{visibleComputer && (
				<LiquidityComputerDialog poolData={liquidityPools} onClose={onClose} />
			)}
			{addConfirmTips && (
				<LiquidityFormTips
					onConfirm={() => setIsVisibleForm(true)}
					onClose={() => setAddConfirmTips(false)}
				/>
			)}
		</article>
	);
}

export default function MobileActions() {
	return (
		<MobileActionsContextProvider>
			<MobileActionsContent />
		</MobileActionsContextProvider>
	);
}
