import { arbitrum, arbitrumGoerli } from 'config/constants/connector/chains';

import { TokenInfo } from 'types';

import { Env } from './enum';
import {
	EQU_ARBITRUM,
	TOKENS_ARBITRUM,
	UNI_WETH_ARBITRUM,
	USDT_ARBITRUM,
	WETH_ARBITRUM
} from './tokens-arbitrum';
import {
	EQU_ARBITRUM_GOERLI_DEV,
	EQU_ARBITRUM_GOERLI_PROD,
	TOKENS_ARBITRUM_GOERLI,
	UNI_WETH_ARBITRUM_GOERLI_DEV,
	UNI_WETH_ARBITRUM_GOERLI_PROD,
	USDT_ARBITRUM_GOERLI_DEV,
	USDT_ARBITRUM_GOERLI_PROD,
	WETH_ARBITRUM_GOERLI
} from './tokens-arbitrum-goerli';
import { TOKENS_ARBITRUM_GOERLI_V2 } from './tokens-arbitrum-goerli-v2';
import { TOKENS_ARBITRUM_V2 } from './tokens-arbitrum-v2';

export const EMPTY_TOKEN = {
	name: 'Empty Token',
	address: '',
	symbol: '',
	decimals: 6,
	chainId: 10,
	logoURI: '/default-token.svg'
};

type ChainTokenList = {
	readonly [chainId: string]: TokenInfo[];
};

export const WRAPPED_NATIVE_CURRENCY: { [chainId: number]: TokenInfo } = {
	[arbitrumGoerli.id]: WETH_ARBITRUM_GOERLI,
	[arbitrum.id]: WETH_ARBITRUM
};

export const UNI_WRAPPED_NATIVE_CURRENCY: { [chainId: number]: TokenInfo } = {
	[arbitrumGoerli.id]:
		(import.meta.env.MODE as Env) === Env.development
			? UNI_WETH_ARBITRUM_GOERLI_DEV
			: UNI_WETH_ARBITRUM_GOERLI_PROD,
	[arbitrum.id]: UNI_WETH_ARBITRUM
};

export const NATIVE_CURRENCY: { [chainId: number]: TokenInfo } = {
	[arbitrumGoerli.id]: {
		chainId: arbitrumGoerli.id,
		address: arbitrumGoerli.nativeCurrency?.symbol,
		...arbitrumGoerli.nativeCurrency,
		isToken: false,
		isNative: true,
		wrapped: WRAPPED_NATIVE_CURRENCY[arbitrumGoerli.id],
		precision: WETH_ARBITRUM_GOERLI.precision,
		positionUnits: WETH_ARBITRUM_GOERLI.positionUnits
	},
	[arbitrum.id]: {
		chainId: arbitrum.id,
		address: arbitrum.nativeCurrency?.symbol,
		...arbitrum.nativeCurrency,
		isToken: false,
		isNative: true,
		wrapped: WRAPPED_NATIVE_CURRENCY[arbitrum.id],
		precision: WETH_ARBITRUM.precision,
		positionUnits: WETH_ARBITRUM.positionUnits
	}
};

export const USDT_CURRENCY: { [chainId: number]: TokenInfo } = {
	[arbitrumGoerli.id]:
		(import.meta.env.MODE as Env) === Env.development
			? USDT_ARBITRUM_GOERLI_DEV
			: USDT_ARBITRUM_GOERLI_PROD,
	[arbitrum.id]: USDT_ARBITRUM
};

export const EQU_CURRENCY: { [chainId: number]: TokenInfo } = {
	[arbitrumGoerli.id]:
		(import.meta.env.MODE as Env) === Env.development
			? EQU_ARBITRUM_GOERLI_DEV
			: EQU_ARBITRUM_GOERLI_PROD,
	[arbitrum.id]: EQU_ARBITRUM
};

export const ALL_TOKENS: ChainTokenList = {
	[arbitrumGoerli.id]: [
		NATIVE_CURRENCY[arbitrumGoerli.id],
		...TOKENS_ARBITRUM_GOERLI,
		...TOKENS_ARBITRUM_GOERLI_V2
	],
	[arbitrum.id]: [
		NATIVE_CURRENCY[arbitrum.id],
		...TOKENS_ARBITRUM,
		...TOKENS_ARBITRUM_V2
	]
};
