import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

import { Trans } from '@lingui/macro';
import { Badge, Typography } from '@mui/material';
import { Transaction_Type, Version } from 'config/constants';
import { usePoolsFetch } from 'fetch/usePoolsFetch';

import { usePoolsGraph } from 'graphql/usePoolsGraph';
import { useCurrentChain } from 'hooks/useCurrentChain';
import ActivityContainer from 'pages/components/ActivityContainer';
import { setIsLoadingRBFList, setRBFList } from 'state/earn/RBF/slice';
import EarnRBFUpdater from 'state/earn/RBF/updater';
import EarnArchitectUpdater from 'state/earn/architect/updater';
import EarnLiquidityUpdater from 'state/earn/liquidity/updater';
import EarnPool2Updater from 'state/earn/pool2/updater';
import EarnPositionUpdater from 'state/earn/position/updater';
import EarnStakeUpdater from 'state/earn/stake/updater';
import { globalBaseState } from 'state/global/slice';
import {
	setPoolMap,
	setPools,
	setV2PoolMap,
	setV2Pools
} from 'state/pools/slice';
import { selectVersion } from 'state/setting/selector';

import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { selectTransactionRecordList } from '../../state/records/selector';
import V2Logo from '../components/V2/V2Logo';
import ClaimArchitectDialog from './Architect/ClaimArchitectDialog';
import Content from './Content';
import ClaimLiquidityDialog from './Liquidity/ClaimLiquidityDialog';
import ClaimPool2Dialog from './Pool2Mining/ClaimPool2Dialog';
import ClaimPositionDialog from './Position/ClaimPositionDialog';
import ClaimRBFDialog from './RBFMining/ClaimRBFDialog';
import ClaimStakeDialog from './Stake/ClaimStakeDialog';
import Summary from './Summary';
import SummaryEQU from './SummaryEQU';
import { Type_Earns } from './types';

function UpdaterV1() {
	const dispatch = useAppDispatch();
	const { appTimer } = useAppSelector(globalBaseState);
	const { poolList, poolMap, refetchPools } = usePoolsGraph();
	const { currentChainId } = useCurrentChain();
	useUpdateEffect(() => {
		refetchPools();
	}, [currentChainId, appTimer]);
	useEffect(() => {
		if (poolList && poolList.length > 0) {
			dispatch(setPools(poolList));
		}
	}, [poolList]);
	useEffect(() => {
		if (poolMap && poolMap.size > 0) {
			dispatch(setPoolMap(poolMap));
		}
	}, [poolMap]);
	return null;
}

function UpdaterV2() {
	const dispatch = useAppDispatch();
	const { appTimer } = useAppSelector(globalBaseState);
	const { poolList, poolMap, refetchPools } = usePoolsFetch();
	const { currentChainId } = useCurrentChain();
	useUpdateEffect(() => {
		refetchPools();
	}, [currentChainId, appTimer]);
	useEffect(() => {
		if (poolList && poolList.length > 0) {
			dispatch(setV2Pools(poolList));
		}
	}, [poolList]);
	useEffect(() => {
		if (poolMap && poolMap.size > 0) {
			dispatch(setV2PoolMap(poolMap));
		}
	}, [poolMap]);
	// 重置Rbf
	useEffect(() => {
		dispatch(setIsLoadingRBFList(false));
		dispatch(setRBFList(null));
	}, []);
	return null;
}

export default function Earn() {
	const transactionRecords = useAppSelector(selectTransactionRecordList);
	const currentVersion = useAppSelector(selectVersion);

	const { type: routerType } = useParams();

	const [visibleClaim, setVisibleClaim] = useState<boolean>(false);

	const {
		claimStakeRecords,
		claimPool2Records,
		claimPositionRecords,
		claimLiqudiityRecords,
		claimRBFRecords,
		claimArchitectRecords,
		claimAllRecords
	} = useMemo(() => {
		const claimStakeRecords = transactionRecords.filter(
			record => record.type === Transaction_Type.ClaimStake
		);
		const claimPool2Records = transactionRecords.filter(
			record => record.type === Transaction_Type.ClaimPool2
		);
		const claimPositionRecords = transactionRecords.filter(
			record =>
				record.type ===
				(currentVersion === Version.V1
					? Transaction_Type.ClaimPosition
					: Transaction_Type.ClaimPositionV2)
		);
		const claimLiqudiityRecords = transactionRecords.filter(
			record =>
				record.type ===
				(currentVersion === Version.V1
					? Transaction_Type.ClaimLiqudiity
					: Transaction_Type.ClaimLiqudiityV2)
		);
		const claimRBFRecords = transactionRecords.filter(
			record => record.type === Transaction_Type.ClaimRBF
		);
		const claimArchitectRecords = transactionRecords.filter(
			record => record.type === Transaction_Type.ClaimArchitect
		);
		const claimAllRecords = transactionRecords.filter(
			record => record.type === Transaction_Type.ClaimAllEarns
		);
		return {
			claimStakeRecords,
			claimPool2Records,
			claimPositionRecords,
			claimLiqudiityRecords,
			claimRBFRecords,
			claimArchitectRecords,
			claimAllRecords
		};
	}, [transactionRecords, currentVersion]);

	const activeTab = useMemo(() => {
		if (routerType === Type_Earns.Position) {
			return Type_Earns.Position;
		} else if (routerType === Type_Earns.Liquidity) {
			return Type_Earns.Liquidity;
		} else if (routerType === Type_Earns.RBF) {
			return Type_Earns.RBF;
		} else if (routerType === Type_Earns.Architect) {
			return Type_Earns.Architect;
		} else if (routerType === Type_Earns.Pool2) {
			return Type_Earns.Pool2;
		} else {
			return Type_Earns.Staking;
		}
	}, [routerType]);

	const isClaimLoading = useMemo(() => {
		if (activeTab === Type_Earns.Staking && claimStakeRecords.length) {
			return true;
		}
		if (activeTab === Type_Earns.Pool2 && claimPool2Records.length) {
			return true;
		}
		if (activeTab === Type_Earns.Position && claimPositionRecords.length) {
			return true;
		}
		if (activeTab === Type_Earns.Liquidity && claimLiqudiityRecords.length) {
			return true;
		}
		if (currentVersion === Version.V1) {
			if (activeTab === Type_Earns.RBF && claimRBFRecords.length) {
				return true;
			}
		}
		if (activeTab === Type_Earns.Architect && claimArchitectRecords.length) {
			return true;
		}
		return false;
	}, [
		activeTab,
		currentVersion,
		claimStakeRecords,
		claimPool2Records,
		claimPositionRecords,
		claimLiqudiityRecords,
		claimRBFRecords,
		claimArchitectRecords
	]);

	const isClaimAllLoading = useMemo(() => {
		return (
			claimStakeRecords.length > 0 ||
			claimPositionRecords.length > 0 ||
			claimLiqudiityRecords.length > 0 ||
			claimRBFRecords.length > 0 ||
			claimArchitectRecords.length > 0
		);
	}, [
		claimStakeRecords,
		claimPositionRecords,
		claimLiqudiityRecords,
		claimRBFRecords,
		claimArchitectRecords
	]);

	const onOpenClaim = () => {
		setVisibleClaim(true);
	};

	return (
		<ActivityContainer
			title={
				<div className='flex items-center'>
					<Typography variant='inherit'>
						<Trans>Earn</Trans>
					</Typography>
					{currentVersion === Version.V2 && (
						<V2Logo className='mt-1 ml-1.5' width={30} height={21} />
					)}
				</div>
			}
			subTitle={
				<Typography
					component='span'
					variant='h5'
					color='text.secondary'
					className='leading-7 text-sm sm:text-xs mt-4'
				>
					<Trans>Earn rewards from trading fees and EQU mining.</Trans>
				</Typography>
			}
		>
			<SummaryEQU />
			<Summary isClaimAllLoading={isClaimAllLoading} />
			<Content
				onClaim={onOpenClaim}
				claimDisabled={isClaimLoading || claimAllRecords.length > 0}
				isClaimLoading={isClaimLoading}
			/>
			{visibleClaim &&
				(activeTab === Type_Earns.Staking ? (
					<ClaimStakeDialog
						open={true}
						onClose={() => {
							setVisibleClaim(false);
						}}
					/>
				) : activeTab === Type_Earns.Position ? (
					<ClaimPositionDialog
						open={true}
						onClose={() => {
							setVisibleClaim(false);
						}}
					/>
				) : activeTab === Type_Earns.Pool2 ? (
					<ClaimPool2Dialog
						open={true}
						onClose={() => {
							setVisibleClaim(false);
						}}
					/>
				) : activeTab === Type_Earns.Liquidity ? (
					<ClaimLiquidityDialog
						open={true}
						onClose={() => {
							setVisibleClaim(false);
						}}
					/>
				) : activeTab === Type_Earns.RBF ? (
					<ClaimRBFDialog
						open={true}
						onClose={() => {
							setVisibleClaim(false);
						}}
					/>
				) : activeTab === Type_Earns.Architect ? (
					<ClaimArchitectDialog
						open={true}
						onClose={() => {
							setVisibleClaim(false);
						}}
					/>
				) : null)}

			<>
				<EarnStakeUpdater />
				<EarnPositionUpdater />
				<EarnPool2Updater />
				<EarnLiquidityUpdater />
				{currentVersion === Version.V1 && <EarnRBFUpdater />}
				<EarnArchitectUpdater />
				{currentVersion === Version.V1 ? <UpdaterV1 /> : <UpdaterV2 />}
			</>
		</ActivityContainer>
	);
}
