import React from 'react';

import { SvgProps } from '../types';

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = props => {
	return (
		<svg
			width='24'
			height='25'
			viewBox='0 0 24 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M12 22.5858C17.28 22.5858 21.6 18.2658 21.6 12.9858C21.6 7.70584 17.28 3.38584 12 3.38584C6.72 3.38584 2.4 7.70584 2.4 12.9858C2.4 18.2658 6.72 22.5858 12 22.5858ZM12 0.98584C18.6 0.98584 24 6.38584 24 12.9858C24 19.5858 18.6 24.9858 12 24.9858C5.4 24.9858 0 19.5858 0 12.9858C0 6.38584 5.4 0.98584 12 0.98584Z'
				fill='#0CBA71'
			/>
			<path
				d='M17.7146 10.8557C17.7146 11.201 17.584 11.5343 17.3467 11.7724L11.8292 17.32C11.58 17.5581 11.2477 17.701 10.9036 17.701C10.5595 17.701 10.2273 17.5581 9.98997 17.32L7.22526 14.5462C6.98794 14.3081 6.85742 13.9748 6.85742 13.6295C6.85742 13.2843 6.99981 12.9629 7.23713 12.7129C7.48631 12.4748 7.80668 12.3438 8.15079 12.3319C8.49489 12.3319 8.81527 12.4629 9.06445 12.701L10.9036 14.5462L15.5075 9.92715C15.7567 9.68906 16.0771 9.55811 16.4212 9.55811C16.7653 9.57001 17.0857 9.70096 17.3349 9.95096C17.5722 10.1891 17.7146 10.5105 17.7146 10.8557Z'
				fill='#0CBA71'
			/>
		</svg>
	);
};

export default Icon;
