import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans, t } from '@lingui/macro';
import { Card, CardContent, Typography, useTheme } from '@mui/material';
import { MAX_PRECISION, QUOTE_USD, Side, Version } from 'config/constants';

import { PositionUtil } from 'entities/PositionUtil';
import { UtilHelper } from 'entities/UtilHelper';
import { IPositionItem } from 'graphql/useMyPositionsGraph';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { useSubmitCloseTrade } from 'hooks/useSubmitCloseTrade';
import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { settingBaseState } from 'state/setting/slice';
import { txBaseState } from 'state/tx/slice';
import { IPoolItem } from 'types';
import {
	catchFn,
	checkInputNumberic,
	div,
	formatLeverage,
	formatNumber,
	isEqualTo,
	isGreaterThan,
	isLessThan,
	isNegative,
	isPositive,
	isZero,
	minus,
	multipliedBy,
	plus,
	shortenSymbol,
	shortenSymbolNative,
	toPercent,
	toQuoteAmount
} from 'utils';

import ApproveButton from 'components/ApproveButton';
import ApproveButtonV2 from 'components/ApproveButtonV2';
import Cell from 'components/Common/Cell';
import { StyleCheckbox } from 'components/Common/StyleCheckbox';
import {
	CommonStyledBaseInput,
	CommonStyledDivider,
	CommonStyledMaxButton
} from 'components/Common/Styled';
import LiqPrice from 'components/LiqPrice';
import LongShortTag from 'components/LongShortTag';
import Tipping from 'components/Tipping';
import ToNext from 'components/ToNext';

import Dialog from '../../components/Common/Dialog';
import PriceImpact from '../../components/PriceImpact';
import TippingUnderline from '../../components/TippingUnderline';
import { PriceUtil } from '../../entities/PriceUtil';
import { PriceUtil as PriceUtilV2 } from '../../entities/V2/PriceUtil';
import useAcceptableEntryPrice from '../../hooks/useAcceptableEntryPrice';
import { selectVersion } from '../../state/setting/selector';
import TradeFees from './TradeForm/TradeFees';

const ClosePositionDialog = ({
	item,
	onClose
}: {
	item: IPositionItem;
	onClose: () => void;
}) => {
	const { quoteToken } = useAppSelector(txBaseState);
	const { slippage } = useAppSelector(settingBaseState);
	const { poolMap } = useAppSelector(poolsBaseState);

	const [isPureReduction, setIsPureReduction] = useState<boolean>(false);
	const [closeSize, setCloseSize] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [confirmLabel, setConfirmLabel] = useState<string>('');
	const [defaultLabel] = useState<string>(t`Close`);

	const { currentChainId } = useCurrentChain();
	const theme = useTheme();
	const currentVersion = useAppSelector(selectVersion);

	const currentPool = useMemo(() => {
		if (!poolMap.size) {
			return null;
		}
		return poolMap.get(item.poolId) as IPoolItem;
	}, [poolMap, item.poolId]);

	const tradePrice = useMemo(() => {
		if (item && currentPool && isPositive(closeSize)) {
			const _side = UtilHelper.flipSide(item.side);
			const indexPriceX96 =
				_side === Side.LONG
					? currentPool.max_index_price_x96
					: currentPool.min_index_price_x96;
			if (currentVersion === Version.V1) {
				return catchFn(() => {
					return PriceUtil.calculateMarketPrice(
						closeSize,
						currentPool.globalLiquidityPosition,
						currentPool.priceState,
						_side,
						indexPriceX96,
						currentPool.maxPriceX96,
						item.baseToken.decimals
					);
				}, '');
			} else {
				return catchFn(() => {
					return PriceUtilV2.calculateMarketPrice(
						closeSize,
						currentPool.globalLiquidityPosition,
						currentPool.priceState,
						_side,
						indexPriceX96,
						currentPool.baseToken.decimals
					);
				}, '');
			}
		}
	}, [item, currentPool, closeSize]);

	//减仓比率
	const lightenRatio = useMemo(() => {
		if (!isPositive(tradePrice)) {
			return '0';
		}
		if (isPositive(closeSize) && !isGreaterThan(closeSize, item.size)) {
			const ratio = div(closeSize, item.size);
			if (isEqualTo(ratio, 1)) setIsPureReduction(false);
			return div(closeSize, item.size);
		} else {
			return '0';
		}
	}, [closeSize, item.size, tradePrice]);

	const acceptableClosingPrice = useMemo(() => {
		if (
			currentPool &&
			tradePrice &&
			item &&
			isPositive(closeSize) &&
			quoteToken &&
			isPositive(lightenRatio)
		) {
			const price =
				item.side === Side.LONG
					? multipliedBy(tradePrice, minus(1, div(slippage, 100)))
					: multipliedBy(tradePrice, plus(1, div(slippage, 100)));

			return price;
		} else {
			return '';
		}
	}, [currentPool, item, slippage, closeSize, lightenRatio, tradePrice]);

	const { Component } = useAcceptableEntryPrice(tradePrice);

	//需要结算的Liquidity
	const netLiquidityDelta = useMemo(() => {
		if (lightenRatio) {
			return multipliedBy(lightenRatio, item.liquidity);
		} else {
			return '0';
		}
	}, [lightenRatio, item.liquidity]);

	const [discountedTradingFee, originTradingFee] = useMemo(() => {
		if (isZero(netLiquidityDelta)) {
			return ['', ''];
		}
		return [
			multipliedBy(netLiquidityDelta, item.discountedTradingFeeRate),
			multipliedBy(netLiquidityDelta, item.tradingFeeRate)
		];
	}, [netLiquidityDelta, item]);

	const data = useMemo(() => {
		if (isPositive(lightenRatio) && tradePrice) {
			// let liquidityDelta = '0'
			//结算后剩余仓位
			let afterMargin = '';
			//结算仓位
			let settlement = '';
			//结算后杠杆
			let afterLeverage = '0';
			//结算后爆仓价
			let targetLiqPrice = '';

			//总体未实现盈亏
			const UnrealizedPnL = PositionUtil.calculateUnrealizedPnL(
				item.side,
				item.size,
				item.entryPrice,
				tradePrice
			);
			//即将结算的未实现盈亏
			const closeUnrealizedPnL = multipliedBy(lightenRatio, UnrealizedPnL);
			//结算后剩余的size
			const afterSize = minus(item.size, closeSize);
			//待结算的总值 = 保证金净值 + 即将结算的未实现盈亏

			if (isPureReduction) {
				//结算的附加值
				const added = minus(closeUnrealizedPnL, discountedTradingFee);
				//如果附加值大于零则直接结算附加值 如果附加值小于零 将要从保证金净值中扣除
				if (isPositive(added)) {
					afterMargin = item.netMargin;
					settlement = added;
				} else {
					afterMargin = plus(item.netMargin, added);
					settlement = '0';
				}
				//结算后杠杆
				afterLeverage = PositionUtil.calculateLeverage(
					afterMargin,
					minus(item.liquidity, netLiquidityDelta)
				);
				//结算后爆仓价
				targetLiqPrice = catchFn(() => {
					return PositionUtil.calculateLiqPrice(
						item.side,
						afterMargin,
						afterSize,
						item.entryPrice,
						item.liquidationExecutionFee,
						item.liquidationFeeRatePerPosition,
						item.discountedTradingFeeRate
					);
				}, '');
			} else {
				//
				const total = plus(item.netMargin, closeUnrealizedPnL);
				settlement = plus(
					multipliedBy(lightenRatio, item.netMargin),
					closeUnrealizedPnL
				);
				afterMargin = minus(minus(total, settlement), discountedTradingFee);
				//当平全部的时候 交易手续费从结算中出； 否则从剩余的margin中出
				if (isEqualTo(lightenRatio, 1)) {
					afterMargin = '0';
					settlement = minus(settlement, discountedTradingFee);
				}
				afterLeverage = PositionUtil.calculateLeverage(
					afterMargin,
					minus(item.liquidity, netLiquidityDelta)
				);
			}

			const maintenanceMargin = PositionUtil.calculateMaintenanceMargin(
				item.entryPrice,
				minus(item.size, closeSize),
				tradePrice,
				item.liquidationFeeRatePerPosition,
				item.discountedTradingFeeRate,
				item.liquidationExecutionFee
			);

			return {
				liquidityDelta: netLiquidityDelta,
				closeUnrealizedPnL: closeUnrealizedPnL,
				afterMargin: afterMargin,
				settlement: settlement,
				afterLeverage: afterLeverage,
				targetLiqPrice: targetLiqPrice,
				maintenanceMargin
			};
		} else {
			return {
				liquidityDelta: '0',
				closeUnrealizedPnL: '',
				afterMargin: '',
				settlement: '',
				afterLeverage: '0',
				targetLiqPrice: '',
				maintenanceMargin: ''
			};
		}
	}, [
		lightenRatio,
		discountedTradingFee,
		netLiquidityDelta,
		isPureReduction,
		item,
		tradePrice
	]);
	const { onConfirm, isConfirmed, isConfirming, error } = useSubmitCloseTrade(
		item,
		acceptableClosingPrice,
		isEqualTo(lightenRatio, 1) ? '0' : data.settlement,
		closeSize,
		slippage
		// currentSlippage
	);
	const isDisabled = useMemo(() => {
		setConfirmLabel(defaultLabel);
		if (!quoteToken || !isPositive(closeSize) || !currentPool) {
			return true;
		}
		if (
			!isEqualTo(lightenRatio, 1) &&
			!isEqualTo(data.afterMargin, 0) &&
			isGreaterThan(data.maintenanceMargin, data.afterMargin)
		) {
			setConfirmLabel(t`The margin ratio is too high after closing`);
			return true;
		}
		if (isGreaterThan(closeSize, item.size)) {
			setConfirmLabel(t`Exceeds current position`);
			return true;
		}
		if (!isPositive(tradePrice)) {
			setConfirmLabel(t`Insufficient Liquidity`);
			return true;
		}
		if (isLessThan(plus(data.settlement, item.margin), 0)) {
			setConfirmLabel(t`Insufficient Margin`);
			return true;
		}

		if (isConfirming) {
			setConfirmLabel(t`Submitting...`);
			return true;
		}

		if (
			isGreaterThan(
				data.afterLeverage,
				currentPool?.maxLeveragePerPosition ||
					currentPool?.token?.maxLeveragePerPosition
			)
		) {
			setConfirmLabel(
				t`Leverage exceeds ${
					currentPool?.maxLeveragePerPosition ||
					currentPool?.token?.maxLeveragePerPosition
				}x after closing`
			);
			return true;
		}
		if (isLoading) {
			setConfirmLabel(t`Loading...`);
			return true;
		}
		return false;
	}, [
		closeSize,
		isConfirming,
		isLoading,
		tradePrice,
		data.maintenanceMargin,
		data.afterMargin,
		data.settlement,
		data.afterLeverage,
		item.size,
		item.margin,
		currentPool
	]);
	const onHandleChangeCloseSize = useCallback(
		(event: any) => {
			const value = event.target.value;
			if (value.trim() === '') {
				setCloseSize(value);
			}
			if (checkInputNumberic(value, item.baseToken?.decimals)) {
				setCloseSize(value);
			}
		},
		[item.baseToken, currentPool]
	);

	const onHandlePureReductionChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setIsPureReduction(event.target.checked);
	};
	const onHandleSetMaxAmount = () => {
		setCloseSize(item.size);
		setIsPureReduction(false);
	};

	const onDialogConfirm = () => {
		setIsLoading(true);
		onConfirm();
	};

	useEffect(() => {
		setConfirmLabel(defaultLabel);
	}, []);

	useUpdateEffect(() => {
		if (isConfirmed) {
			onClose();
		}
	}, [isConfirmed]);
	useUpdateEffect(() => {
		if (error) {
			setConfirmLabel(defaultLabel);
			setIsLoading(false);
		}
	}, [error]);

	return (
		<Dialog open onClose={onClose} title={t`Close`}>
			<article className='space-y-4'>
				<section className='space-y-4'>
					<Card>
						<CardContent>
							<Cell
								label={
									<Typography variant='body1'>
										<Trans>Amount</Trans>
									</Typography>
								}
								prefix={<Trans>Size:</Trans>}
								value={`${formatNumber(
									item.size,
									item.baseToken.positionUnits,
									true
								)} ${shortenSymbolNative(item.baseToken, currentChainId)}`}
							/>

							<CommonStyledBaseInput
								type='text'
								inputProps={{ maxLength: MAX_PRECISION, inputMode: 'decimal' }}
								className='input'
								value={closeSize}
								placeholder='0.0'
								onChange={onHandleChangeCloseSize}
								fullWidth
								endAdornment={
									<div className='flex items-center space-x-2'>
										<CommonStyledMaxButton
											variant='outlined'
											color='secondary'
											onClick={() => onHandleSetMaxAmount()}
										>
											<Trans>Max</Trans>
										</CommonStyledMaxButton>
									</div>
								}
							/>
						</CardContent>
					</Card>
					<div className='space-y-1'>
						<Cell
							label={
								<Typography variant='body2'>
									<TippingUnderline
										tooltip={
											<Trans>
												When closing a position, you can choose to use the
												&quot;Reduce-Only&quot; mode. The Reduce-Only mode means
												only reducing the position size while keeping the margin
												unchanged. The leverage of the position will change
												accordingly after the reduction.
											</Trans>
										}
										label={<Trans>Reduce-Only</Trans>}
									/>
								</Typography>
							}
							value={
								<StyleCheckbox
									checked={isPureReduction}
									onChange={onHandlePureReductionChange}
									size='small'
									className='-mr-2.5'
									disabled={isEqualTo(lightenRatio, 1)}
								/>
							}
						/>

						<Cell
							label={
								<Typography variant='body2'>
									<Trans>Acceptable Price</Trans>
								</Typography>
							}
							value={
								<Typography variant='body2' className='flex items-center'>
									{`${
										acceptableClosingPrice
											? item.side === Side.SHORT
												? '≤'
												: '≥'
											: ''
									}${toQuoteAmount(
										acceptableClosingPrice,
										item.baseToken.precision
									)}`}
									<span className='ml-1'>{`(${toPercent(
										slippage,
										2,
										false
									)})`}</span>
									&nbsp;
									{Component}
								</Typography>
							}
						/>
					</div>
				</section>
				<CommonStyledDivider />
				<section>
					<LongShortTag
						side={item.side}
						baseSymbol={shortenSymbolNative(item.baseToken, currentChainId)}
						quoteSymbol={QUOTE_USD}
						leverage={item.leverage as string}
						type='base'
						margin={item.netMargin}
					/>
				</section>
				<section className='space-y-1'>
					<Cell
						label={
							<Typography variant='body2'>
								<Trans>Leverage</Trans>
							</Typography>
						}
						value={
							<ToNext
								value={formatLeverage(item.leverage)}
								nextValue={formatLeverage(data.afterLeverage)}
								hasNext={isPositive(data.afterLeverage)}
							/>
						}
					/>
					<Cell
						label={
							<Typography variant='body2'>
								<Trans>Margin</Trans>
							</Typography>
						}
						value={
							<div className='flex items-center space-x-1'>
								{!isPositive(item.unrealizedPnL) &&
									isPositive(data.afterMargin) &&
									isPureReduction && (
										<Tipping
											title={
												<Trans>
													The current position is in a loss state, and the loss
													will be deducted from the margin.
												</Trans>
											}
											type='warning'
										/>
									)}

								<ToNext
									value={`${formatNumber(item.netMargin)} ${shortenSymbol(
										quoteToken
									)}`}
									nextValue={`${formatNumber(data.afterMargin)} ${shortenSymbol(
										quoteToken
									)}`}
									hasNext={
										data.afterMargin !== '' && !isLessThan(data.afterMargin, 0)
									}
								/>
							</div>
						}
					/>
					<Cell
						label={
							<Typography variant='body2'>
								<Trans>Entry Price</Trans>
							</Typography>
						}
						value={toQuoteAmount(item.entryPrice, item?.baseToken.precision)}
					/>
					<Cell
						label={
							<Typography variant='body2'>
								<Trans>Liq. Price</Trans>
							</Typography>
						}
						value={
							<ToNext
								value={
									<LiqPrice
										value={item.liqPrice}
										precision={item?.baseToken.precision}
									/>
								}
								nextValue={
									isPositive(data.targetLiqPrice) ? (
										<LiqPrice
											value={data.targetLiqPrice}
											precision={item?.baseToken.precision}
										/>
									) : (
										'-'
									)
								}
								hasNext={isPureReduction && data.targetLiqPrice !== ''}
							/>
						}
					/>
				</section>
				<CommonStyledDivider />
				<section className='space-y-1'>
					<PriceImpact
						tradePrice={tradePrice}
						side={item.side === Side.LONG ? Side.SHORT : Side.LONG}
						paramIndexPrice={item.indexPrice}
					/>
					<Cell
						label={
							<Typography variant='body2'>
								<Trans>Est. Close Price</Trans>
							</Typography>
						}
						value={
							tradePrice
								? toQuoteAmount(tradePrice, item?.baseToken.precision)
								: '-'
						}
					/>
					<Cell
						label={
							<Typography variant='body2'>
								<Trans>PnL</Trans>
							</Typography>
						}
						value={
							<Typography
								variant='body2'
								color={
									isPositive(data.closeUnrealizedPnL)
										? theme.palette.success.main
										: isNegative(data.closeUnrealizedPnL)
										? theme.palette.error.main
										: ''
								}
							>
								{data.closeUnrealizedPnL
									? toQuoteAmount(data.closeUnrealizedPnL)
									: '-'}
							</Typography>
						}
					/>
					<TradeFees
						tradingFeeRate={currentPool?.tradingFeeRate}
						referralDiscountRate={currentPool?.referralDiscountRate}
						tradingFee={discountedTradingFee}
						originTradingFee={originTradingFee}
					/>
					{/* <Cell
						label={
							<Typography variant='body2' color='inherit'>
								<Trans>Fees</Trans>
							</Typography>
						}
						value={
							!isEqualTo(tradingFee, '0') ? (
								<Stronger
									value={toQuoteAmount(
										neg(totalFee),
										2,
										Decimal.ROUND_DOWN,
										true
									)}
									tooltip={
										<div>
											<Cell
												label={<Trans>Trading Fee</Trans>}
												value={`${toQuoteAmount(
													neg(tradingFee),
													2,
													Decimal.ROUND_DOWN,
													true
												)} (${toPercent(neg(item.tradingFeeRate))})`}
											/>
											<ExecutionFee />
										</div>
									}
								/>
							) : (
								'-'
							)
						}
					/> */}
				</section>
				<CommonStyledDivider />
				<section className='space-y-1'>
					<Cell
						label={
							<Typography variant='body2'>
								<Trans>Receive</Trans>
							</Typography>
						}
						value={
							data.settlement === ''
								? '-'
								: `${formatNumber(data.settlement)} ${shortenSymbol(
										quoteToken
								  )}`
						}
					/>
				</section>
				<section>
					{currentVersion === Version.V1 ? (
						<ApproveButton
							componentVariant='confirm'
							fullWidth
							onClick={onDialogConfirm}
							disabled={isDisabled}
							isApprovePositionRouter
						>
							{confirmLabel}
						</ApproveButton>
					) : (
						<ApproveButtonV2
							componentVariant='confirm'
							fullWidth
							onClick={onDialogConfirm}
							disabled={isDisabled}
							isApprovePositionRouter
						>
							{confirmLabel}
						</ApproveButtonV2>
					)}
				</section>
			</article>
		</Dialog>
	);
};

export default ClosePositionDialog;
