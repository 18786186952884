import React, { useContext, useMemo, useState } from 'react';

import { Trans } from '@lingui/macro';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import {
	CardContent,
	IconButton,
	MenuItem,
	Popover,
	Typography,
	useTheme
} from '@mui/material';
import {
	MAX_PRECISION,
	QUOTE_USD,
	QUOTE_USD_PRECISION,
	Side
} from 'config/constants';

import {
	checkInputNumberic,
	div,
	isPositive,
	minus,
	multipliedBy,
	plus,
	toDecimalPlaces,
	toQuoteAmount
} from 'utils';

import Cell from 'components/Common/Cell';
import {
	CommonStyledDivider,
	CommonStyledFilledInput,
	CommonStyledMaxButton
} from 'components/Common/Styled';
import CommonButton from 'components/Common/StyledButton';

import { useAppBreakpoints } from '../../../hooks/useAppBreakpoints';
import { ComputerContext } from './ComputerContext';
import ComputerDirection from './ComputerDirection';
import ComputerLeverage from './ComputerLeverage';
import { StyledCard } from './StyledCard';

enum Type_Profit {
	ROE = 'ROE',
	Profit = 'Profit'
}

export default React.memo(function ComputerTargetPrice() {
	const { side, currentPool, leverage, marketPrice } =
		useContext(ComputerContext);

	const theme = useTheme();

	const [targetPrice, setTargetPrice] = useState<string>('');
	const [entryPrice, setEntryPrice] = useState<string>('');
	const [type, setType] = useState<Type_Profit>(Type_Profit.ROE);
	const [value, setValue] = useState<string>('');

	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);

	const handleShowPop = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleChangeType = (value: Type_Profit) => {
		handleClose();
		setType(value);
		setValue('');
	};

	const onChangeEntryPrice: React.ChangeEventHandler<HTMLInputElement> = e => {
		const value = e.target.value;
		if (value.trim() === '') {
			setEntryPrice('');
		}
		if (checkInputNumberic(value, QUOTE_USD_PRECISION)) {
			setEntryPrice(value);
		}
	};

	const onChangeValue: React.ChangeEventHandler<HTMLInputElement> = e => {
		const value = e.target.value;
		if (value.trim() === '') {
			setValue('');
		}
		if (type === Type_Profit.ROE && checkInputNumberic(value, 2)) {
			setValue(value);
		}
		if (
			type === Type_Profit.Profit &&
			checkInputNumberic(value, QUOTE_USD_PRECISION)
		) {
			setValue(value);
		}
	};

	const onHandleSetMarketPrice = () => {
		if (isPositive(marketPrice)) {
			setEntryPrice(
				toDecimalPlaces(marketPrice, currentPool?.baseToken.precision)
			);
		}
	};
	const { isMatchMobile } = useAppBreakpoints();

	const disabled = useMemo(() => {
		if (
			!isPositive(leverage) ||
			!isPositive(entryPrice) ||
			!isPositive(value)
		) {
			return true;
		}
		return false;
	}, [leverage, entryPrice, value]);

	const onHandleConfirm = () => {
		if (
			!isPositive(entryPrice) ||
			!isPositive(leverage) ||
			!isPositive(value)
		) {
			return;
		}
		let targetPrice: string;
		// 用户输入回报率时=[1+（回报率/杠杆倍数）]*开仓价格
		if (type === Type_Profit.ROE) {
			const _value = div(value, 100);
			const multiplier =
				side === Side.LONG
					? plus(1, div(_value, leverage))
					: minus(1, div(_value, leverage));
			targetPrice = multipliedBy(multiplier, entryPrice);
		} else {
			targetPrice =
				side === Side.LONG
					? plus(entryPrice, div(value, leverage))
					: minus(entryPrice, div(value, leverage));
		}

		setTargetPrice(targetPrice);
	};

	return (
		<article className='flex sm:flex-col-reverse space-x-4 sm:space-x-0'>
			<section className='flex-1 flex flex-col justify-between sm:mt-4 sm:space-y-4'>
				<div className='space-y-4'>
					<ComputerDirection />
					<ComputerLeverage />
					<div className='space-y-2'>
						<CommonStyledFilledInput
							type='text'
							inputProps={{
								maxLength: MAX_PRECISION,
								inputMode: 'decimal'
							}}
							sx={{
								'& .MuiInputBase-input': {
									paddingRight: '8px'
								}
							}}
							value={entryPrice}
							onChange={onChangeEntryPrice}
							fullWidth
							startAdornment={
								<div className='flex items-center space-x-1'>
									<Typography
										className='min-w-max'
										variant='body2'
										color='secondary'
									>
										<Trans>Entry price</Trans>
									</Typography>
									<CommonStyledMaxButton
										className='px-2 font-normal text-xs'
										variant='outlined'
										color='secondary'
										onClick={onHandleSetMarketPrice}
									>
										<Trans>trade.Market</Trans>
									</CommonStyledMaxButton>
								</div>
							}
							endAdornment={
								<Typography
									className='text-right'
									variant='body2'
									color='secondary'
								>
									{QUOTE_USD}
								</Typography>
							}
						/>
						<CommonStyledFilledInput
							type='text'
							inputProps={{
								maxLength: MAX_PRECISION,
								inputMode: 'decimal'
							}}
							sx={{
								'& .MuiInputBase-input': {
									paddingRight: '8px'
								}
							}}
							value={value}
							onChange={onChangeValue}
							fullWidth
							startAdornment={
								<Typography
									className='min-w-max'
									variant='body2'
									color='secondary'
								>
									{type === Type_Profit.ROE ? (
										<Trans>PnL%</Trans>
									) : (
										<Trans>PnL</Trans>
									)}
								</Typography>
							}
							endAdornment={
								<div className='flex items-center min-w-max justify-end'>
									<Typography variant='body2' color='secondary'>
										{type === Type_Profit.ROE ? '%' : QUOTE_USD}
									</Typography>
									<IconButton className='p-0 -mr-1' onClick={handleShowPop}>
										{anchorEl ? (
											<ArrowDropUp
												sx={{ color: theme.palette.text.secondary }}
											/>
										) : (
											<ArrowDropDown
												sx={{ color: theme.palette.text.secondary }}
											/>
										)}
									</IconButton>
									<Popover
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'right'
										}}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'right'
										}}
										open={Boolean(anchorEl)}
										anchorEl={anchorEl}
										onClose={handleClose}
									>
										<MenuItem
											className='w-[102px]'
											value={Type_Profit.ROE}
											onClick={() => handleChangeType(Type_Profit.ROE)}
											sx={{
												color:
													type === Type_Profit.ROE
														? theme.palette.text.primary
														: theme.palette.text.secondary
											}}
										>
											<div>
												%
												{type === Type_Profit.ROE && (
													<DoneRoundedIcon
														fontSize='small'
														className='ml-4 font-bold'
														sx={{ color: theme.palette.primary.main }}
													/>
												)}
											</div>
										</MenuItem>
										<MenuItem
											value={Type_Profit.Profit}
											onClick={() => handleChangeType(Type_Profit.Profit)}
											sx={{
												color:
													type === Type_Profit.Profit
														? theme.palette.text.primary
														: theme.palette.text.secondary
											}}
										>
											<div>
												{QUOTE_USD}
												{type === Type_Profit.Profit && (
													<DoneRoundedIcon
														fontSize='small'
														className='ml-4 font-bold'
														sx={{ color: theme.palette.primary.main }}
													/>
												)}
											</div>
										</MenuItem>
									</Popover>
								</div>
							}
						/>
					</div>
				</div>

				<CommonButton
					className='flex flex-col justify-center'
					variant='contained'
					fullWidth
					disabled={disabled}
					onClick={onHandleConfirm}
				>
					<Typography
						className='whitespace-normal text-sm'
						color='inherit'
						variant='h6'
						fontWeight={500}
					>
						<Trans>Calculate</Trans>
					</Typography>
				</CommonButton>
			</section>
			<StyledCard isMatchMobile={isMatchMobile} className='flex-1'>
				<CardContent className='space-y-4'>
					<Typography variant='h6'>
						<Trans>Result</Trans>
					</Typography>
					<CommonStyledDivider />
					<div className='space-y-2'>
						<Cell
							label={<Trans>Target Price</Trans>}
							value={
								<Typography
									variant='body2'
									noWrap
									title={toQuoteAmount(
										targetPrice,
										currentPool?.baseToken.precision
									)}
								>
									{toQuoteAmount(targetPrice, currentPool?.baseToken.precision)}
								</Typography>
							}
						/>
					</div>
				</CardContent>
			</StyledCard>
		</article>
	);
});
