import { useEffect, useMemo, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { getContractAddress } from 'config/contracts';

import { useApproval } from 'hooks/useApproval';
import { useApprovalPlugin } from 'hooks/useApprovalPlugin';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { isGreaterThan } from 'utils';

// import Loading from 'components/Svg/Icons/Loading';
// import LogoLoading from 'components/Svg/Icons/NewLogoLoading';

const ApproveTest = () => {
	const { currentChainId } = useCurrentChain();
	const { quoteToken, quoteBalance } = useAppSelector(txBaseState);
	const orderBookContractAddress = useMemo(() => {
		return getContractAddress(currentChainId, 'OrderBook');
	}, [currentChainId]);
	const positionRouterContractAddress = useMemo(() => {
		return getContractAddress(currentChainId, 'PositionRouter');
	}, [currentChainId]);
	const routerContractAddress = useMemo(() => {
		return getContractAddress(currentChainId, 'Router');
	}, [currentChainId]);
	const [type, setType] = useState<string>('');
	const [revokeOrderBookPlugin, setRevokeOrderBookPlugin] =
		useState<boolean>(false);
	const [revokePositionRouterPlugin, setRevokePositionRouterPlugin] =
		useState<boolean>(false);
	const {
		isApproving,
		isConfirmed: approveIsConfirmed,
		approveCallback,
		currentAllowance
	} = useApproval(quoteToken, '10000', routerContractAddress, type);

	const {
		isPluginApproved,
		onApprovePlugin,
		error: pluginError
	} = useApprovalPlugin(orderBookContractAddress, revokeOrderBookPlugin);

	const {
		isPluginApproved: isPositionRouterPluginApproved,
		onApprovePlugin: onPositionRouterApprovePlugin,
		error: positionRouterPluginError
	} = useApprovalPlugin(
		positionRouterContractAddress,
		revokePositionRouterPlugin
	);

	useEffect(() => {
		if (isGreaterThan(currentAllowance, 0)) {
			setType('cancel');
		} else {
			setType('');
		}
	}, [currentAllowance]);

	useEffect(() => {
		if (isPluginApproved) {
			setRevokeOrderBookPlugin(true);
		} else {
			setRevokeOrderBookPlugin(false);
		}
	}, [isPluginApproved]);

	useEffect(() => {
		if (isPositionRouterPluginApproved) {
			setRevokePositionRouterPlugin(true);
		} else {
			setRevokePositionRouterPlugin(false);
		}
	}, [isPositionRouterPluginApproved]);
	return (
		<>
			{/* <Loading /> */}
			{/* <LogoLoading /> */}
			<Box className='space-y-4'>
				<Box>
					<Typography>Token Balance:</Typography>
					{quoteBalance}
				</Box>
				<Box>
					<Typography>Token:</Typography>
					{currentAllowance}
					<br />
					<Button size='small' variant='contained' onClick={approveCallback}>
						{isGreaterThan(currentAllowance, 0)
							? 'Unapprove Token'
							: 'Approve Token'}
					</Button>
				</Box>
				<Box>
					<Typography>Order Book Plugin:</Typography>
					{isPluginApproved ? 'Approved' : 'Not approved'}
					<br />
					<Button size='small' variant='contained' onClick={onApprovePlugin}>
						{isPluginApproved
							? 'Unapprove OrderBook Plugin'
							: 'Approve OrderBook Plugin'}
					</Button>
				</Box>
				<Box>
					<Typography>Position Router Plugin:</Typography>
					{isPositionRouterPluginApproved ? 'Approved' : 'Not approved'}
					<br />
					<Button
						size='small'
						variant='contained'
						onClick={onPositionRouterApprovePlugin}
					>
						{isPositionRouterPluginApproved
							? 'Unapprove PositionRouter Plugin'
							: 'Approve PositionRouter Plugin'}
					</Button>
				</Box>
			</Box>
		</>
	);
};
export default ApproveTest;
