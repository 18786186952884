import { useCallback, useMemo } from 'react';

import { orderBookABI, orderBookV2ABI } from 'config/abis';
import {
	Contract_Write_Mode,
	Profit_Loss_Type,
	QUOTE_USD,
	Side,
	Transaction_Type,
	Version
} from 'config/constants';
import { getContractAddress, getContractAddressV2 } from 'config/contracts';
import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { selectOrderMinExecutionFee } from 'state/global/selector';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import {
	BaseRecord,
	RecordForCreateProfitLossPosition,
	TokenInfo
} from 'types';
import {
	computePriceX96,
	div,
	formatSize,
	getTriggerAboveForCreateProftLoss,
	isEqualTo,
	isNegative,
	isPositive,
	minus,
	multipliedBy,
	parseUnits,
	plus,
	shortenSymbolNative,
	toDecimalPlaces,
	toQuoteAmount
} from 'utils';

import { settingBaseState } from '../state/setting/slice';
import { useComputeGasLimit } from './useComputeGasLimit';
import { useCurrentChain } from './useCurrentChain';
import { useSendTransaction } from './useSendTransaction';

export function useSubmitCreateProfitLoss(
	type: Profit_Loss_Type,
	poolId: string,
	side: Side,
	takeProfitPrice: string,
	stopLossPrice: string,
	takeProfitSize: string,
	stopLossSize: string,
	takeProfitMargin: string,
	stopLossMargin: string,
	baseToken: TokenInfo | null
) {
	// console.log(
	// 	takeProfitPrice + '___',
	// 	stopLossPrice + '___',
	// 	takeProfitSize + '___',
	// 	stopLossSize + '___',
	// 	takeProfitMargin + '___',
	// 	stopLossMargin + '___'
	// );
	const { quoteToken } = useAppSelector(txBaseState);
	const { slippage } = useAppSelector(settingBaseState);
	const { currentChainId } = useCurrentChain();
	const { address } = useAccount();
	const orderMinExecutionFee = useAppSelector(selectOrderMinExecutionFee);
	const currentVersion = useAppSelector(state => state.setting.currentVersion);

	const { args, record, description } = useMemo(() => {
		if (
			!baseToken ||
			!quoteToken ||
			(!isPositive(takeProfitPrice) && !isPositive(stopLossPrice)) ||
			(!isPositive(takeProfitSize) && !isPositive(stopLossSize)) ||
			(takeProfitMargin === '' && stopLossMargin === '') ||
			isNegative(stopLossMargin)
		) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}
		if (!isPositive(takeProfitSize)) {
			takeProfitSize = '0';
		}
		if (!isPositive(stopLossSize)) {
			stopLossSize = '0';
		}
		if (!isPositive(takeProfitPrice)) {
			takeProfitPrice = '0';
		}
		if (!isPositive(stopLossPrice)) {
			stopLossPrice = '0';
		}
		if (!isPositive(takeProfitMargin)) {
			takeProfitMargin = '0';
		}
		if (!isPositive(stopLossMargin)) {
			stopLossMargin = '0';
		}
		console.log('useSubmitProfitLossPostion args begin ...');

		const _takeProfitPrice = toDecimalPlaces(
			takeProfitPrice,
			quoteToken.decimals
		);
		const _takeProfitPriceX96 = computePriceX96(
			_takeProfitPrice,
			baseToken.decimals,
			quoteToken.decimals
		);
		const _stopLossPrice = toDecimalPlaces(stopLossPrice, quoteToken.decimals);
		const _stopLossPriceX96 = computePriceX96(
			_stopLossPrice,
			baseToken.decimals,
			quoteToken.decimals
		);
		const acceptableTakeProfitPrice =
			side === Side.LONG
				? multipliedBy(takeProfitPrice, minus(1, div(slippage, 100)))
				: multipliedBy(takeProfitPrice, plus(1, div(slippage, 100)));
		const _acceptableTakeProfitPriceX96 = computePriceX96(
			acceptableTakeProfitPrice,
			baseToken.decimals,
			quoteToken.decimals
		);
		const acceptableStopLossPrice =
			side === Side.LONG
				? multipliedBy(stopLossPrice, minus(1, div(slippage, 100)))
				: multipliedBy(stopLossPrice, plus(1, div(slippage, 100)));
		const _acceptableStopLossPriceX96 = computePriceX96(
			acceptableStopLossPrice,
			baseToken.decimals,
			quoteToken.decimals
		);

		const takeProfitSizeDelta = isEqualTo(takeProfitMargin, 0)
			? '0'
			: toDecimalPlaces(takeProfitSize, baseToken.decimals);
		const _takeProfitSizeDelta = parseUnits(
			takeProfitSizeDelta,
			baseToken.decimals
		);
		const stopLossSizeDelta = isEqualTo(stopLossMargin, 0)
			? '0'
			: toDecimalPlaces(stopLossSize, baseToken.decimals);
		const _stopLossSizeDelta = parseUnits(
			stopLossSizeDelta,
			baseToken.decimals
		);

		const takeProfitMarginDelta = toDecimalPlaces(
			takeProfitMargin,
			quoteToken.decimals
		);
		const _takeProfitMarginDelta = parseUnits(
			takeProfitMarginDelta,
			quoteToken.decimals
		);

		const stopLossMarginDelta = toDecimalPlaces(
			stopLossMargin,
			quoteToken.decimals
		);
		const _stopLossMarginDelta = parseUnits(
			stopLossMarginDelta,
			quoteToken.decimals
		);
		let args;
		if (isPositive(takeProfitPrice) && isPositive(stopLossPrice)) {
			args = [
				poolId,
				side,
				[_takeProfitMarginDelta, _stopLossMarginDelta],
				[_takeProfitSizeDelta, _stopLossSizeDelta],
				[_takeProfitPriceX96, _stopLossPriceX96],
				[_acceptableTakeProfitPriceX96, _acceptableStopLossPriceX96],
				address
			];
		} else if (isPositive(takeProfitPrice)) {
			const _triggerAbove = side === Side.LONG ? true : false;
			args = [
				poolId,
				side,
				_takeProfitMarginDelta,
				_takeProfitSizeDelta,
				_takeProfitPriceX96,
				_triggerAbove,
				_acceptableTakeProfitPriceX96,
				address
			];
		} else {
			const _triggerAbove = side === Side.LONG ? false : true;
			args = [
				poolId,
				side,
				_stopLossMarginDelta,
				_stopLossSizeDelta,
				_stopLossPriceX96,
				_triggerAbove,
				_acceptableStopLossPriceX96,
				address
			];
		}

		const record = [] as Array<BaseRecord>;
		const description = [] as Array<string>;

		const _poolSymbol = `${shortenSymbolNative(
			baseToken,
			currentChainId
		)}/${QUOTE_USD}`;

		const _baseSymbol = shortenSymbolNative(baseToken, currentChainId);

		if (isPositive(takeProfitPrice)) {
			const _transactionType =
				type === Profit_Loss_Type.Entire
					? currentVersion === Version.V1
						? Transaction_Type.CreateTakeProfitPosition
						: Transaction_Type.CreateTakeProfitPositionV2
					: currentVersion === Version.V1
					? Transaction_Type.CreateTakeProfitPartial
					: Transaction_Type.CreateTakeProfitPartialV2;
			const _triggerAbove = getTriggerAboveForCreateProftLoss(
				_transactionType,
				side
			);
			const recordTakeProfit = {
				transactionType: _transactionType,
				pool: poolId,
				baseToken,
				side,
				triggerPrice: takeProfitPrice,
				triggerAbove: _triggerAbove,
				acceptablePrice: acceptableTakeProfitPrice,
				sizeDelta: takeProfitSize
			} as RecordForCreateProfitLossPosition;
			record.push(recordTakeProfit);

			if (type === Profit_Loss_Type.Entire) {
				const descriptionTakeProfit =
					TransactionDescription.CreateTakeProfitPositionOrders(
						_poolSymbol,
						_baseSymbol,
						formatSize(takeProfitSize, baseToken.positionUnits),
						toQuoteAmount(takeProfitPrice, baseToken.precision),
						side,
						_triggerAbove
					);
				description.push(descriptionTakeProfit);
			} else {
				const descriptionTakeProfit =
					TransactionDescription.CreateTakeProfitPartialOrders(
						_poolSymbol,
						_baseSymbol,
						formatSize(takeProfitSize, baseToken.positionUnits),
						toQuoteAmount(takeProfitPrice, baseToken.precision),
						side,
						_triggerAbove
					);
				description.push(descriptionTakeProfit);
			}
		}

		if (isPositive(stopLossPrice)) {
			const _transactionType =
				type === Profit_Loss_Type.Entire
					? currentVersion === Version.V1
						? Transaction_Type.CreateStopLossPosition
						: Transaction_Type.CreateStopLossPositionV2
					: currentVersion === Version.V1
					? Transaction_Type.CreateStopLossPartial
					: Transaction_Type.CreateStopLossPartialV2;
			const _triggerAbove = getTriggerAboveForCreateProftLoss(
				_transactionType,
				side
			);
			const recordStopLoss = {
				transactionType: _transactionType,
				pool: poolId,
				baseToken,
				side,
				triggerPrice: stopLossPrice,
				triggerAbove: _triggerAbove,
				acceptablePrice: acceptableStopLossPrice,
				sizeDelta: stopLossSize
			} as RecordForCreateProfitLossPosition;
			record.push(recordStopLoss);

			if (type === Profit_Loss_Type.Entire) {
				const descriptionStopLoss =
					TransactionDescription.CreateStopLossPositionOrders(
						_poolSymbol,
						_baseSymbol,
						formatSize(stopLossSize, baseToken.positionUnits),
						toQuoteAmount(stopLossPrice, baseToken.precision),
						side,
						_triggerAbove
					);
				description.push(descriptionStopLoss);
			} else {
				const descriptionStopLoss =
					TransactionDescription.CreateStopLossPartialOrders(
						_poolSymbol,
						_baseSymbol,
						formatSize(stopLossSize, baseToken.positionUnits),
						toQuoteAmount(stopLossPrice, baseToken.precision),
						side,
						_triggerAbove
					);
				description.push(descriptionStopLoss);
			}
		}

		return {
			args,
			record,
			description
		};
	}, [
		type,
		address,
		baseToken,
		quoteToken,
		poolId,
		takeProfitPrice,
		stopLossPrice,
		takeProfitSize,
		stopLossPrice,
		takeProfitMargin,
		stopLossMargin
	]);

	const overrides = useMemo(() => {
		if (!address || !isPositive(orderMinExecutionFee)) {
			return undefined;
		}
		return {
			from: address,
			value:
				isPositive(takeProfitPrice) && isPositive(stopLossPrice)
					? multipliedBy(orderMinExecutionFee, 2)
					: orderMinExecutionFee
		};
	}, [address, orderMinExecutionFee, takeProfitPrice, stopLossPrice]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return isPositive(takeProfitPrice) && isPositive(stopLossPrice)
			? ({
					mode: Contract_Write_Mode.Unprepared,
					address:
						currentVersion === Version.V2
							? getContractAddressV2(currentChainId, 'OrderBook')
							: getContractAddress(currentChainId, 'OrderBook'),
					abi: currentVersion === Version.V2 ? orderBookV2ABI : orderBookABI,
					functionName: 'createTakeProfitAndStopLossOrders',
					args,
					overrides
			  } as UseContractWriteConfig)
			: ({
					mode: Contract_Write_Mode.Unprepared,
					address:
						currentVersion === Version.V2
							? getContractAddressV2(currentChainId, 'OrderBook')
							: getContractAddress(currentChainId, 'OrderBook'),
					abi: currentVersion === Version.V2 ? orderBookV2ABI : orderBookABI,
					functionName: 'createDecreaseOrder',
					args,
					overrides
			  } as UseContractWriteConfig);
	}, [
		currentChainId,
		args,
		overrides,
		takeProfitPrice,
		stopLossPrice,
		currentVersion,
		orderBookV2ABI,
		orderBookABI
	]);

	const { gasLimit } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirming, isConfirmed, error } =
		useSendTransaction(contractWriteArgs, gasLimit);

	return {
		onConfirm: useCallback(() => {
			if (!record) return;
			onSendTransaction(record, description);
		}, [onSendTransaction, record, description]),
		isConfirming,
		isConfirmed,
		error
	};
}
