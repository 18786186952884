import React, { useMemo, useRef, useState } from 'react';

import { t } from '@lingui/macro';
import { Box, alpha, useTheme } from '@mui/material';
import { DEFAULT_PRECISION, DEFAULT_QUOTE_PRECISION } from 'config/constants';
import Decimal from 'decimal.js';

import { PriceUtil } from 'entities/PriceUtil';
import { PriceUtil as PriceUtilV2 } from 'entities/V2/PriceUtil';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { get, isEmpty, uniqBy } from 'lodash';
import {
	formatNumber,
	formatUnits,
	getPointOnLine,
	isEqualTo,
	isGreaterThan,
	isLessThan,
	isPositive,
	parseUnits,
	plus,
	toDecimalPlaces,
	toPercent,
	toQuoteAmount
} from 'utils';

import SectionLoading from 'components/SectionLoading';

import { Dom_Size, Q96, Side } from '../../config/constants';
import usePoolDepth from '../../graphql/usePoolDepth';
import { useAppSelector } from '../../state/hooks';
import { poolsBaseState } from '../../state/pools/slice';

interface IPoint {
	size: number | string;
	price: string;
	premiumRate: string;
	// 是否为溢价函数的端点
	isOriginPoint?: boolean;
	isIndexPrice?: boolean;
	isMarketPrice?: boolean;
	// 是否有爆仓点
	hasBuffer?: boolean;
	// 爆仓点存贮size大小
	bufferSize?: IPoint['size'];
	// 是否在x轴上显示文本
	isXAxisLabel?: boolean;
}

// 左右价格偏差
const PRICE_SPREAD_RATE = 0.02;
// x坐标轴上显示的个数
const LABEL_COUNT = 3;

const DepthChartV1 = ({
	id,
	tokenSymbol
}: {
	id: string;
	tokenSymbol: string;
}) => {
	const { pools } = useAppSelector(poolsBaseState);
	const theme = useTheme();
	const currentPool = pools.find(
		pool => pool.id === id
	) as (typeof pools)[number];
	const precision = currentPool.baseToken.precision;
	const positionUnits = currentPool.baseToken.positionUnits;
	const {
		loading,
		priceState = {
			liquidationBufferNetSizes: [],
			premiumRateX96: '0',
			priceVertices: [],
			pendingVertexIndex: 0,
			indexPriceUsedX96: 0
		},
		globalLiquidityPosition = {
			liquidity: '0',
			netSize: '0',
			liquidationBufferNetSize: '0',
			side: Side.LONG
		}
	} = usePoolDepth(id);

	const [_priceState, _globalLiquidityPosition] = useMemo(() => {
		const newPriceState = {
			...priceState,
			maxPriceImpactLiquidity: BigInt(
				currentPool.priceState.maxPriceImpactLiquidity
			),
			// premiumRateX96: BigInt(priceState.premiumRateX96),
			priceVertices: priceState.priceVertices.map((item: any) => ({
				premiumRateX96: BigInt(item.premiumRateX96),
				size: BigInt(parseUnits(item.size, DEFAULT_PRECISION))
			}))
			// liquidationBufferNetSizes: priceState.liquidationBufferNetSizes.map(
			// 	item => BigInt(parseUnits(item, DEFAULT_PRECISION))
			// )
		};
		const newGlobalLiquidityPosition = {
			...currentPool.globalLiquidityPosition,
			// netSize: BigInt(
			// 	parseUnits(globalLiquidityPosition?.netSize, DEFAULT_PRECISION)
			// ),
			// liquidationBufferNetSize: BigInt(
			// 	parseUnits(
			// 		globalLiquidityPosition.liquidationBufferNetSize,
			// 		DEFAULT_PRECISION
			// 	)
			// ),
			liquidity: BigInt(
				parseUnits(globalLiquidityPosition.liquidity, DEFAULT_QUOTE_PRECISION)
			)
		};

		return [newPriceState, newGlobalLiquidityPosition];
	}, [priceState]);

	const pendingPriceVertices = useMemo(() => {
		if (
			_priceState &&
			currentPool.globalLiquidityPosition &&
			_priceState.priceVertices.length > 0
		) {
			if (priceState.pendingVertexIndex !== 0) {
				const { priceStateCache } = PriceUtil.changePriceVertex(
					0,
					_priceState.pendingVertexIndex,
					_priceState,
					_globalLiquidityPosition,
					BigInt(priceState.indexPriceUsedX96)
				);

				const priceVertices = priceStateCache.priceVertices.map(
					(item: any) => ({
						premiumRateX96: item.premiumRateX96.toString(),
						size: formatUnits(String(item.size), DEFAULT_PRECISION)
					})
				);
				return priceVertices;
			}
		}
		return priceState.priceVertices;
	}, [_priceState, _globalLiquidityPosition]);

	const getPremiumRate = (price: IPoint['price']) =>
		new Decimal(price).div(currentPool.index_price).minus(1).toString();

	const priceVertices = uniqBy(
		priceState.priceVertices.map((item, index) => ({ ...item, index })),
		'premiumRateX96'
	);

	const liquidationBufferNetSizes = priceState.liquidationBufferNetSizes.filter(
		(_, index) => priceVertices.find(item => item.index === index)
	);

	// 用户开多，LP持空，平衡点在右侧
	// 用户开空，LP持多，平衡点在左侧
	const pendingVerticesL =
		globalLiquidityPosition.side === Side.SHORT
			? pendingPriceVertices
			: priceVertices;

	const pendingVerticesR =
		globalLiquidityPosition.side === Side.LONG
			? pendingPriceVertices
			: priceVertices;

	/**
	 * x轴为价格，从左往右，逐渐增大
	 * y轴为size，从下往上，逐渐增大
	 * 用户开多仓，加剧不平衡，价格往右走
	 * 用户开空仓，加剧不平衡，价格往左走
	 *
	 * 假如用户开空，LP持多，则用户去往价格更低的size，应该比初始状态少，所以左侧size应该用正数减正数（取绝对值后更小），右侧size应该用负数减正数（取绝对值后更大）
	 * 假如用户开多，LP持空，则用户去往价格更高的size，应该比初始状态少，所以左侧size应该用负数减正数（取绝对值后更大），右侧size应该用正数减正数（取绝对值后更小）
	 *
	 * 图像的y轴，可能是从低到高，也有可能是从高到低
	 */
	// 平衡点左侧，价格更低数据
	const lowerPriceData = pendingVerticesL
		.slice(1)
		.map(item => ({
			size: new Decimal(item.size)
				.mul(globalLiquidityPosition.side === Side.LONG ? 1 : -1)
				.minus(globalLiquidityPosition.netSize)
				.toNumber(),
			price: new Decimal(item.premiumRateX96)
				.neg()
				.div(Q96)
				.plus(1)
				.mul(currentPool.index_price)
				.toString(),
			premiumRate: new Decimal(item.premiumRateX96).neg().div(Q96).toString(),
			isOriginPoint: true
		}))
		.reverse();
	// 平衡点右侧，价格更高数据
	const higherPriceData = pendingVerticesR.slice(1).map(item => ({
		size: new Decimal(item.size)
			.mul(globalLiquidityPosition.side === Side.LONG ? -1 : 1)
			.minus(globalLiquidityPosition.netSize)
			.toNumber(),
		price: new Decimal(item.premiumRateX96)
			.div(Q96)
			.plus(1)
			.mul(currentPool.index_price)
			.toString(),
		premiumRate: new Decimal(item.premiumRateX96).div(Q96).toString(),
		isOriginPoint: true
	}));

	const addMarketPricePoint = (
		previousValue: IPoint[],
		currentValue: IPoint
	) => {
		if (currentValue.size === 0) {
			return [...previousValue, { ...currentValue, isMarketPrice: true }];
		}
		// 如果前后两点正负号不一致，代表两点之间有零点
		if (
			previousValue.length !== 0 &&
			new Decimal(currentValue.size).s !==
				new Decimal(previousValue[previousValue.length - 1].size).s &&
			previousValue[previousValue.length - 1].size !== 0
		) {
			const size = 0;
			const price = currentPool.price;
			const marketPricePoint = {
				size,
				price,
				premiumRate: getPremiumRate(price),
				isMarketPrice: true
			};
			return [...previousValue, marketPricePoint, currentValue];
		}
		return [...previousValue, currentValue];
	};

	// 找出爆仓点，在合适的位置后插一个点，并标记
	const markLiquidationBufferPoint = (
		previousValue: IPoint[],
		currentValue: IPoint,
		currentIndex: number,
		array: IPoint[]
	) => {
		if (currentIndex !== array.length - 1) {
			return [];
		}

		const liquidationBufferPoints = liquidationBufferNetSizes
			.map((size, index) => ({ size, index }))
			.filter(item => item.size !== '0');

		for (let i = 0; i < liquidationBufferPoints.length; i++) {
			// 用户开多，LP持空，爆仓点在标记价格右侧
			if (globalLiquidityPosition.side === Side.SHORT) {
				const currentIndex =
					array.findIndex(item => item.isIndexPrice) +
					liquidationBufferPoints[i].index +
					i;
				array.splice(currentIndex + 1, 0, {
					...array[currentIndex],
					hasBuffer: true,
					bufferSize: liquidationBufferPoints[i].size
				});
			} else {
				// 用户开空，LP持多，爆仓点在标记价格左侧
				const currentIndex =
					array.findIndex(item => item.isIndexPrice) -
					liquidationBufferPoints[i].index;
				array.splice(currentIndex + 1, 0, {
					...array[currentIndex],
					hasBuffer: true,
					bufferSize: liquidationBufferPoints[i].size,
					isMarketPrice: false
				});
			}
		}
		return array;
	};

	// 根据标记的爆仓点，修改数据
	const updateLiquidationBufferPoint = (
		previousValue: IPoint[],
		currentValue: IPoint,
		currentIndex: number,
		array: IPoint[]
	) => {
		if (currentIndex !== array.length - 1) {
			return [];
		}

		const liquidationBufferPoints = array
			.map((item, index) => ({
				...item,
				index
			}))
			.filter(item => item.hasBuffer);
		let result = array;
		for (let i = 0; i < liquidationBufferPoints.length; i++) {
			if (globalLiquidityPosition.side === Side.SHORT) {
				result = result.map((item, index) => {
					if (index < liquidationBufferPoints[i].index) {
						return {
							...item,
							size: plus(
								item.size,
								liquidationBufferPoints[i].bufferSize as IPoint['size']
							)
						};
					} else {
						return item;
					}
				});
			}

			if (globalLiquidityPosition.side === Side.LONG) {
				result = result.map((item, index) => {
					if (index >= liquidationBufferPoints[i].index) {
						return {
							...item,
							size: plus(
								item.size,
								liquidationBufferPoints[i].bufferSize as IPoint['size']
							)
						};
					} else {
						return item;
					}
				});
			}
		}

		return result;
	};

	// 只拿市场价格左右价差范围内的数据
	const slicePointByPriceSpreadRate = (
		previousValue: IPoint[],
		currentValue: IPoint,
		currentIndex: number,
		array: IPoint[]
	) => {
		const marketPricePointIndex = array.findIndex(item => item.isMarketPrice);
		const marketPrice = array[marketPricePointIndex].price;

		const getPriceSpreadRate = (price: IPoint['price']) =>
			new Decimal(price).div(marketPrice).minus(1);

		const priceSpreadBetweenIndexAndMarket = getPriceSpreadRate(
			currentPool.index_price
		).abs();
		const priceSpreadRate = Decimal.max(
			PRICE_SPREAD_RATE,
			toDecimalPlaces(
				priceSpreadBetweenIndexAndMarket.toString(),
				2,
				Decimal.ROUND_UP
			)
		).toString();

		// 价格更低的区间
		if (currentIndex < marketPricePointIndex) {
			// 如果第一个点在价差范围内，则直接返回
			if (
				currentIndex === 0 &&
				getPriceSpreadRate(currentValue.price).gt(-priceSpreadRate)
			) {
				return [{ ...currentValue, isXAxisLabel: true }];
			}

			const lastPoint =
				currentIndex === 0 ? currentValue : array[currentIndex - 1];
			const nextPoint =
				currentIndex === 0 && marketPricePointIndex === 1
					? array[marketPricePointIndex]
					: currentValue;

			// 如果前后点穿过了价差，则左边界点为被穿过的点
			if (
				getPriceSpreadRate(lastPoint.price).lt(-priceSpreadRate) &&
				getPriceSpreadRate(nextPoint.price).gt(-priceSpreadRate)
			) {
				const price = new Decimal(1)
					.minus(priceSpreadRate)
					.mul(marketPrice)
					.toString();

				const size = getPointOnLine(
					price,
					lastPoint.size,
					lastPoint.price,
					nextPoint.size,
					nextPoint.price
				);

				const point: IPoint = {
					size,
					price,
					premiumRate: getPremiumRate(price),
					isXAxisLabel: true
				};
				return [...previousValue, point, nextPoint];
			}
			if (getPriceSpreadRate(currentValue.price).gt(-priceSpreadRate)) {
				return [...previousValue, currentValue];
			}
		}

		// 价格更高的区间
		if (currentIndex >= marketPricePointIndex) {
			if (
				currentIndex === array.length - 1 &&
				getPriceSpreadRate(currentValue.price).lt(priceSpreadRate)
			) {
				return [...previousValue, { ...currentValue, isXAxisLabel: true }];
			}
			// 如果某个点在价差范围内，则直接返回
			if (getPriceSpreadRate(currentValue.price).lt(priceSpreadRate)) {
				return [...previousValue, currentValue];
			}

			const lastPoint = array[currentIndex - 1];
			// 如果前后点穿过了价差，则右边界点为被穿过的点
			if (
				getPriceSpreadRate(lastPoint.price).lt(priceSpreadRate) &&
				getPriceSpreadRate(currentValue.price).gt(priceSpreadRate)
			) {
				const price = new Decimal(1)
					.plus(priceSpreadRate)
					.mul(marketPrice)
					.toString();

				const size = getPointOnLine(
					price,
					lastPoint.size,
					lastPoint.price,
					currentValue.size,
					currentValue.price
				);

				const point: IPoint = {
					size,
					price,
					premiumRate: getPremiumRate(price),
					isXAxisLabel: true
				};
				return [...previousValue, point];
			}
		}
		return previousValue;
	};

	const addXAxisLabelPoint = (
		previousValue: IPoint[],
		currentValue: IPoint,
		currentIndex: number,
		array: IPoint[]
	) => {
		const marketPrice = array.find(item => item.isMarketPrice)?.price as string;

		const countToSupply = LABEL_COUNT - 3;
		const priceStep = new Decimal(array[array.length - 1].price)
			.minus(array[0].price)
			.div(LABEL_COUNT - 1)
			.toString();
		const targetPriceArr = [];
		let overMarketPrice = false;
		const marketPriceStep = new Decimal(marketPrice)
			.minus(array[0].price)
			.div(priceStep)
			.toDecimalPlaces(0, Decimal.ROUND_HALF_CEIL)
			.toNumber();

		for (let i = 0; i < countToSupply; i++) {
			const price = new Decimal(priceStep)
				.mul(overMarketPrice ? i + 2 : i + 1)
				.plus(array[0].price)
				.toString();

			overMarketPrice = overMarketPrice || i === marketPriceStep - 2;

			if (!previousValue.find(item => item.price === price)) {
				targetPriceArr.push(price);
			}
		}

		if (currentIndex === 0) {
			return [currentValue];
		}

		const points: IPoint[] = [];
		while (targetPriceArr.length) {
			const targetPrice = targetPriceArr[0] as string;
			const leftPointIndex = array.findIndex(
				(value, index) =>
					index !== array.length - 1 &&
					isGreaterThan(targetPrice, value.price) &&
					isLessThan(targetPrice, array[index + 1].price)
			);
			if (leftPointIndex !== -1 && leftPointIndex === currentIndex - 1) {
				const leftPoint = array[leftPointIndex];
				const rightPoint = array[leftPointIndex + 1];
				const point: IPoint = {
					price: targetPrice,
					size: getPointOnLine(
						targetPrice,
						leftPoint.size,
						leftPoint.price,
						rightPoint.size,
						rightPoint.price
					),
					premiumRate: getPremiumRate(targetPrice),
					isXAxisLabel: true
				};
				points.push(point);
				targetPriceArr.shift();
			} else {
				break;
			}
		}
		return [...previousValue, ...points, currentValue];
	};

	const addPoints = (
		previousValue: IPoint[],
		currentValue: IPoint,
		currentIndex: number,
		array: IPoint[]
	) => {
		if (currentIndex !== array.length - 1) {
			return [];
		}

		const totalCount = 500;
		const marketPriceIndex = array.findIndex(item => item.isMarketPrice);
		const marketPricePoint = array[marketPriceIndex];

		if (marketPriceIndex === 0 || marketPriceIndex === array.length - 1) {
			return array;
		}

		// 低于市场价的点
		const leftArray = array.slice(0, marketPriceIndex);
		const leftToApply = totalCount - leftArray.length;
		const leftStep = new Decimal(marketPricePoint.price)
			.minus(array[0].price)
			.div(leftToApply + 1)
			.toString();

		// 高于市场价的点
		const rightArray = array.slice(marketPriceIndex + 1);
		const rightToApply = totalCount - rightArray.length;
		const rightStep = new Decimal(array[array.length - 1].price)
			.minus(marketPricePoint.price)
			.div(rightToApply + 1);

		// 将平均价格，插入到左侧关键点中
		const result: IPoint[] = [array[0]];
		let startIndex = 1;
		for (let i = 0; i < leftToApply; i++) {
			const price = new Decimal(leftStep)
				.mul(i + 1)
				.add(array[0].price)
				.toString();
			if (isLessThan(price, array[startIndex].price)) {
				const size = getPointOnLine(
					price,
					array[startIndex - 1].size,
					array[startIndex - 1].price,
					array[startIndex].size,
					array[startIndex].price
				);
				result.push({ price, size, premiumRate: getPremiumRate(price) });
			} else {
				result.push(array[startIndex]);
				startIndex += 1;
			}
		}
		// 将没走到的关键点补全
		for (let i = startIndex; i < marketPriceIndex + 1; i++) {
			result.push(array[i]);
		}
		startIndex = marketPriceIndex + 1;

		// 将平均价格，插入到右侧关键点中
		for (let i = 0; i < rightToApply; i++) {
			const price = new Decimal(rightStep)
				.mul(i + 1)
				.add(array[marketPriceIndex].price)
				.toString();
			if (isLessThan(price, array[startIndex].price)) {
				const size = getPointOnLine(
					price,
					array[startIndex - 1].size,
					array[startIndex - 1].price,
					array[startIndex].size,
					array[startIndex].price
				);
				result.push({ price, size, premiumRate: getPremiumRate(price) });
			} else {
				result.push(array[startIndex]);
				startIndex += 1;
			}
		}
		// 将没走到的关键点补全
		for (let i = startIndex; i < array.length; i++) {
			result.push(array[i]);
		}

		return result;
	};

	// 补充数据点，将当前市场价格放在中间
	const moveMarketPriceToCenter = (
		previousValue: IPoint[],
		currentValue: IPoint,
		currentIndex: number,
		array: IPoint[]
	) => {
		const originPointIndex = array.findIndex(item => item.isMarketPrice);
		const leftCount = array.slice(0, originPointIndex).length;
		const rightCount = array.slice(originPointIndex + 1).length;

		// 往零size点左边加
		if (leftCount < rightCount) {
			const countToAdd = rightCount - leftCount;
			if (currentIndex === 1) {
				const points: IPoint[] = [];
				const sizeStep = new Decimal(
					previousValue[previousValue.length - 1].size
				)
					.minus(currentValue.size)
					.div(countToAdd + 1)
					.toNumber();
				for (let i = 0; i < countToAdd; i++) {
					const size = new Decimal(sizeStep)
						.mul(i + 1)
						.neg()
						.add(previousValue[previousValue.length - 1].size)
						.toNumber();
					// 2个点可能是同一个点，导致price算不出来，1个点确定不了一条直线
					const price = getPointOnLine(
						size,
						previousValue[previousValue.length - 1].price,
						previousValue[previousValue.length - 1].size,
						currentValue.price,
						currentValue.size
					);
					points.push({
						size,
						price,
						premiumRate: getPremiumRate(price)
					});
				}
				return [...previousValue, ...points, currentValue];
			} else {
				return [...previousValue, currentValue];
			}
		}
		// 往零size点右边加
		if (leftCount > rightCount) {
			const countToAdd = leftCount - rightCount;
			if (currentIndex === array.length - 1) {
				const points: IPoint[] = [];
				const sizeStep = new Decimal(currentValue.size)
					.minus(previousValue[previousValue.length - 1].size)
					.div(countToAdd + 1)
					.toNumber();
				for (let i = 0; i < countToAdd; i++) {
					const size = new Decimal(sizeStep)
						.mul(i + 1)
						.add(previousValue[previousValue.length - 1].size)
						.toNumber();
					const price = getPointOnLine(
						size,
						previousValue[previousValue.length - 1].price,
						previousValue[previousValue.length - 1].size,
						currentValue.price,
						currentValue.size
					);
					points.push({
						size,
						price,
						premiumRate: getPremiumRate(price)
					});
				}
				return [...previousValue, ...points, currentValue];
			}
			return [...previousValue, currentValue];
		}
		// 左右数量一样，不加点
		return [...previousValue, currentValue];
	};

	const chartData = useMemo(() => {
		if (
			!lowerPriceData.length ||
			!higherPriceData.length ||
			globalLiquidityPosition.liquidity === '0' ||
			globalLiquidityPosition.netSize === '0'
		) {
			return [
				{
					size: 0,
					price: toQuoteAmount(currentPool.index_price, precision),
					isIndexPrice: true,
					isOriginPoint: true,
					premiumRate: '0',
					isXAxisLabel: true
				}
			];
		}

		return [
			...lowerPriceData,
			{
				size: new Decimal(globalLiquidityPosition.netSize).neg().toNumber(),
				price: currentPool.index_price,
				isIndexPrice: true,
				isOriginPoint: true,
				premiumRate: '0'
			},
			...higherPriceData
		]
			.reduce(addMarketPricePoint, [])
			.map(item => ({
				...item,
				size: new Decimal(item.size).abs().toNumber()
			}))
			.reduce(markLiquidationBufferPoint, [])
			.reduce(updateLiquidationBufferPoint, [])
			.reduce(slicePointByPriceSpreadRate, [])
			.reduce(addXAxisLabelPoint, [])
			.reduce(addPoints, [])
			.reduce(moveMarketPriceToCenter, [])
			.map(item => ({
				...item,
				size: new Decimal(item.size).toNumber(),
				price: toQuoteAmount(item.price, precision)
			}));
	}, [
		priceState,
		globalLiquidityPosition,
		toDecimalPlaces(currentPool.index_price)
	]);

	const marketPriceIndex = chartData.findIndex(item => item.isMarketPrice);
	const indexPriceIndex = chartData.findIndex(item => item.isIndexPrice);
	const [selectedIndex, setSelectedIndex] = useState(-1);

	const priceLabel = t`Price:`;
	const sizeLabel = t`Total Size:`;
	const prLabel = t`Premium Rate:`;
	const marketPriceLabel = t`Market Price`;
	const indexPriceLabel = t`Index Price`;

	const options = {
		title: {
			text: ''
		},
		credits: {
			enabled: false
		},
		legend: {
			layout: 'vertical',
			backgroundColor: 'transparent',
			floating: true,
			align: 'center',
			verticalAlign: 'bottom',
			x: 0,
			y: 10,
			useHTML: true,
			labelFormat:
				chartData.length > 10 || chartData.length === 1
					? `<div style="font-size: 12px;color: ${alpha(
							theme.custom.reverseColor,
							0.6
					  )};cursor: default;display: flex;column-gap: 16px;"><div>${marketPriceLabel}</div><div><div style='border-bottom: 1px solid ${alpha(
							theme.custom.reverseColor,
							0.6
					  )};height: 50%;width: 50px'></div></div><div>${indexPriceLabel}&nbsp;&nbsp;&nbsp;. . . . . . . .</div></div>`
					: '',
			symbolWidth: 0,
			symbolHeight: 0
		},
		plotOptions: {
			series: {
				events: {
					legendItemClick: function () {
						return false;
					}
				},
				states: {
					inactive: {
						opacity: 1
					}
				},
				point: {
					events: {
						mouseOver: function () {
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							setSelectedIndex(this.index);
						},
						mouseOut: function () {
							setSelectedIndex(-1);
						}
					}
				},
				marker: {
					symbol: 'circle',
					enabled: true,
					radius: 2.5,
					lineWidth: 1,
					lineColor: null
				},
				shadow: false,
				animation: false
			}
		},
		xAxis: {
			categories: chartData.map(item => item.price),
			tickPositions: chartData
				.map((item, index) =>
					item.isXAxisLabel || item.isMarketPrice ? index : false
				)
				.filter(item => item !== false),
			labels: {
				style: {
					color: '#8B919D'
				}
			},
			plotLines: [
				{
					value: marketPriceIndex,
					color: alpha(theme.custom.reverseColor, 0.2),
					width: 2
				},
				{
					value: indexPriceIndex,
					color: alpha(theme.custom.reverseColor, 0.2),
					width: 2,
					dashStyle: 'dot'
				},
				{
					value: selectedIndex,
					color: alpha(theme.custom.reverseColor, 0.2),
					width: selectedIndex === -1 ? 0 : 2,
					dashStyle: 'dash'
				}
			]
		},
		yAxis: {
			gridLineWidth: 0,
			gridLineColor: null,
			title: {
				text: ''
			},
			labels: {
				style: {
					color: '#8B919D'
				}
			}
		},
		series: [
			{
				type: 'area',
				data: [
					...chartData.slice(0, marketPriceIndex + 1).map(item => item.size),
					...chartData.slice(marketPriceIndex + 1).map(() => null)
				],
				marker: {
					enabled: false
				},
				showInLegend: false,
				lineColor: {
					linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
					stops: [
						['0', 'rgb(0, 122, 78)'],
						['100%', 'rgb(12, 186, 113)']
					]
				},
				fillColor: 'rgba(12, 186, 113, 0.1)'
			},
			{
				type: 'area',
				data: [
					...chartData.slice(0, marketPriceIndex).map(() => null),
					...chartData.slice(marketPriceIndex).map(item => item.size)
				],
				lineColor: {
					linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
					stops: [
						['0%', '#f04a54'],
						['100%', '#bb212a']
					]
				},
				fillColor: 'rgba(240, 74, 84, 0.1)',
				marker: {
					enabled: false
				},
				showInLegend: false
			},
			{
				type: 'area',
				data: []
			}
		],
		chart: {
			type: 'area',
			backgroundColor: 'transparent',
			zoomType: 'none',
			height: Dom_Size.CHART,
			marginRight: 20,
			marginBottom: 60,
			animation: false
		},
		tooltip: {
			backgroundColor: theme.custom.dialogBg,
			shadow: false,
			borderWidth: 1,
			borderColor: theme.palette.divider,
			useHTML: true,
			style: {
				color: theme.palette.text.primary
			},
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			formatter: function () {
				const priceData: string =
					`<div style="display: flex;justify-content: space-between"><div style="margin-right: 24px;font-family: ${theme.typography.fontFamily};color: ${theme.palette.secondary.main}">${priceLabel}</div>` +
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					` ${toQuoteAmount(this.point.category)} </div>`;

				const sizeData: string =
					`<div style="display: flex;justify-content: space-between"><div style="margin-right: 24px;font-family: ${theme.typography.fontFamily};color: ${theme.palette.secondary.main}">${sizeLabel}</div>` +
					` ${formatNumber(
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						this.point.y,
						positionUnits
					)} ${tokenSymbol}</div>`;

				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				const premiumRate = chartData[this.point.x].premiumRate;
				let prNum;
				if (isEqualTo(premiumRate, 0)) {
					prNum = toPercent(premiumRate);
				} else if (new Decimal(premiumRate).abs().lessThan(0.0001)) {
					if (isPositive(premiumRate)) {
						prNum = '<0.01%';
					} else {
						prNum = '<-0.01%';
					}
				} else {
					prNum = toPercent(premiumRate);
				}
				const prData =
					`<div style="display: flex;justify-content: space-between"><div style="margin-right: 24px;font-family: ${theme.typography.fontFamily};color: ${theme.palette.secondary.main}">${prLabel}</div>` +
					` ${prNum}</div>`;
				return `<div style="padding:6px;">${priceData} ${sizeData} ${prData}</div>`;
			}
		},

		colors: ['#007A4E', '#F04A54']
	};

	return (
		<Box
			sx={{
				height: Dom_Size.CHART
			}}
		>
			{!loading ? (
				<HighchartsReact highcharts={Highcharts} options={options} />
			) : (
				<SectionLoading full={true} />
			)}
		</Box>
	);
};

const DepthChartV2 = ({
	id,
	tokenSymbol
}: {
	id: string;
	tokenSymbol: string;
}) => {
	const { pools } = useAppSelector(poolsBaseState);

	const ref = useRef(null);
	const height = get(ref, 'current.clientHeight', Dom_Size.CHART);

	const theme = useTheme();
	const currentPool = pools.find(
		pool => pool.id === id
	) as (typeof pools)[number];

	const precision = currentPool.baseToken.precision;
	const positionUnits = currentPool.baseToken.positionUnits;
	const {
		priceState = {
			liquidationBufferNetSizes: [],
			premiumRateX96: '0',
			priceVertices: [],
			pendingVertexIndex: 0,
			indexPriceUsedX96: 0
		},
		globalLiquidityPosition = {
			liquidity: '0',
			netSize: '0',
			liquidationBufferNetSize: '0',
			side: Side.LONG
		}
	} = currentPool;

	const [_priceState, _globalLiquidityPosition] = useMemo(() => {
		const newPriceState = {
			...priceState,
			maxPriceImpactLiquidity: BigInt(
				currentPool.priceState.maxPriceImpactLiquidity
			),
			// premiumRateX96: BigInt(priceState.premiumRateX96),
			priceVertices: priceState.priceVertices.map((item: any) => ({
				premiumRateX96: BigInt(item.premiumRateX96),
				size: BigInt(parseUnits(item.size, DEFAULT_PRECISION))
			}))
			// liquidationBufferNetSizes: priceState.liquidationBufferNetSizes.map(
			// 	item => BigInt(parseUnits(item, DEFAULT_PRECISION))
			// )
		};
		const newGlobalLiquidityPosition = {
			...currentPool.globalLiquidityPosition,
			// netSize: BigInt(
			// 	parseUnits(globalLiquidityPosition?.netSize, DEFAULT_PRECISION)
			// ),
			// liquidationBufferNetSize: BigInt(
			// 	parseUnits(
			// 		globalLiquidityPosition.liquidationBufferNetSize,
			// 		DEFAULT_PRECISION
			// 	)
			// ),
			liquidity: BigInt(
				parseUnits(globalLiquidityPosition.liquidity, DEFAULT_QUOTE_PRECISION)
			)
		};

		return [newPriceState, newGlobalLiquidityPosition];
	}, [priceState]);

	const pendingPriceVertices = useMemo(() => {
		if (
			_priceState &&
			currentPool.globalLiquidityPosition &&
			_priceState.priceVertices.length > 0
		) {
			if (priceState.pendingVertexIndex !== 0) {
				const { priceVertices } = PriceUtilV2.changePriceVertex(
					{
						priceState: _priceState,
						globalLiquidityPosition: _globalLiquidityPosition
					},
					{
						maxPriceImpactLiquidity: BigInt(
							_priceState.maxPriceImpactLiquidity
						),
						liquidationVertexIndex: _priceState.liquidationVertexIndex,
						vertices: _globalLiquidityPosition.tokenVertices.map(item => ({
							balanceRate: BigInt(item.balanceRate),
							premiumRate: BigInt(item.premiumRate)
						}))
					},
					BigInt(priceState.indexPriceUsedX96),
					0,
					_priceState.pendingVertexIndex
				);
				return priceVertices.map(item => ({
					premiumRateX96: item.premiumRateX96.toString(),
					size: formatUnits(String(item.size), DEFAULT_PRECISION)
				}));
			}
		}
		return priceState.priceVertices;
	}, [_priceState, _globalLiquidityPosition]);

	const getPremiumRate = (price: IPoint['price']) =>
		new Decimal(price).div(currentPool.indexPrice).minus(1).toString();

	const priceVertices = uniqBy(
		priceState.priceVertices.map((item, index) => ({ ...item, index })),
		'premiumRateX96'
	);

	const liquidationBufferNetSizes = priceState.liquidationBufferNetSizes.filter(
		(_, index) => priceVertices.find(item => item.index === index)
	);

	// 用户开多，LP持空，平衡点在右侧
	// 用户开空，LP持多，平衡点在左侧
	const pendingVerticesL =
		globalLiquidityPosition.side === Side.SHORT
			? pendingPriceVertices
			: priceVertices;

	const pendingVerticesR =
		globalLiquidityPosition.side === Side.LONG
			? pendingPriceVertices
			: priceVertices;

	/**
	 * x轴为价格，从左往右，逐渐增大
	 * y轴为size，从下往上，逐渐增大
	 * 用户开多仓，加剧不平衡，价格往右走
	 * 用户开空仓，加剧不平衡，价格往左走
	 *
	 * 假如用户开空，LP持多，则用户去往价格更低的size，应该比初始状态少，所以左侧size应该用正数减正数（取绝对值后更小），右侧size应该用负数减正数（取绝对值后更大）
	 * 假如用户开多，LP持空，则用户去往价格更高的size，应该比初始状态少，所以左侧size应该用负数减正数（取绝对值后更大），右侧size应该用正数减正数（取绝对值后更小）
	 *
	 * 图像的y轴，可能是从低到高，也有可能是从高到低
	 */
	// 平衡点左侧，价格更低数据
	const lowerPriceData = pendingVerticesL
		.slice(1)
		.map(item => ({
			size: new Decimal(item.size)
				.mul(globalLiquidityPosition.side === Side.LONG ? 1 : -1)
				.minus(globalLiquidityPosition.netSize)
				.toNumber(),
			price: new Decimal(item.premiumRateX96)
				.neg()
				.div(Q96)
				.plus(1)
				.mul(currentPool.indexPrice)
				.toString(),
			premiumRate: new Decimal(item.premiumRateX96).neg().div(Q96).toString(),
			isOriginPoint: true
		}))
		.reverse();

	// 平衡点右侧，价格更高数据
	const higherPriceData = pendingVerticesR.slice(1).map(item => ({
		size: new Decimal(item.size)
			.mul(globalLiquidityPosition.side === Side.LONG ? -1 : 1)
			.minus(globalLiquidityPosition.netSize)
			.toNumber(),
		price: new Decimal(item.premiumRateX96)
			.div(Q96)
			.plus(1)
			.mul(currentPool.indexPrice)
			.toString(),
		premiumRate: new Decimal(item.premiumRateX96).div(Q96).toString(),
		isOriginPoint: true
	}));

	const addMarketPricePoint = (
		previousValue: IPoint[],
		currentValue: IPoint
	) => {
		if (currentValue.size === 0) {
			return [...previousValue, { ...currentValue, isMarketPrice: true }];
		}
		// 如果前后两点正负号不一致，代表两点之间有零点
		if (
			previousValue.length !== 0 &&
			new Decimal(currentValue.size).s !==
				new Decimal(previousValue[previousValue.length - 1].size).s &&
			previousValue[previousValue.length - 1].size !== 0
		) {
			const size = 0;
			const price = currentPool.price;
			const marketPricePoint = {
				size,
				price,
				premiumRate: getPremiumRate(price),
				isMarketPrice: true
			};
			return [...previousValue, marketPricePoint, currentValue];
		}
		return [...previousValue, currentValue];
	};

	// 找出爆仓点，在合适的位置后插一个点，并标记
	const markLiquidationBufferPoint = (
		previousValue: IPoint[],
		currentValue: IPoint,
		currentIndex: number,
		array: IPoint[]
	) => {
		if (currentIndex !== array.length - 1) {
			return [];
		}

		const liquidationBufferPoints = liquidationBufferNetSizes
			.map((size, index) => ({ size, index }))
			.filter(item => !isEqualTo(item.size, '0'));

		for (let i = 0; i < liquidationBufferPoints.length; i++) {
			// 用户开多，LP持空，爆仓点在标记价格右侧
			if (globalLiquidityPosition.side === Side.SHORT) {
				const currentIndex =
					array.findIndex(item => item.isIndexPrice) +
					liquidationBufferPoints[i].index +
					i;
				array.splice(currentIndex + 1, 0, {
					...array[currentIndex],
					hasBuffer: true,
					bufferSize: liquidationBufferPoints[i].size
				});
			} else {
				// 用户开空，LP持多，爆仓点在标记价格左侧
				const currentIndex =
					array.findIndex(item => item.isIndexPrice) -
					liquidationBufferPoints[i].index;
				array.splice(currentIndex + 1, 0, {
					...array[currentIndex],
					hasBuffer: true,
					bufferSize: liquidationBufferPoints[i].size,
					isMarketPrice: false
				});
			}
		}
		return array;
	};

	// 根据标记的爆仓点，修改数据
	const updateLiquidationBufferPoint = (
		previousValue: IPoint[],
		currentValue: IPoint,
		currentIndex: number,
		array: IPoint[]
	) => {
		if (currentIndex !== array.length - 1) {
			return [];
		}

		const liquidationBufferPoints = array
			.map((item, index) => ({
				...item,
				index
			}))
			.filter(item => item.hasBuffer);
		let result = array;
		for (let i = 0; i < liquidationBufferPoints.length; i++) {
			if (globalLiquidityPosition.side === Side.SHORT) {
				result = result.map((item, index) => {
					if (index < liquidationBufferPoints[i].index) {
						return {
							...item,
							size: plus(
								item.size,
								liquidationBufferPoints[i].bufferSize as IPoint['size']
							)
						};
					} else {
						return item;
					}
				});
			}

			if (globalLiquidityPosition.side === Side.LONG) {
				result = result.map((item, index) => {
					if (index >= liquidationBufferPoints[i].index) {
						return {
							...item,
							size: plus(
								item.size,
								liquidationBufferPoints[i].bufferSize as IPoint['size']
							)
						};
					} else {
						return item;
					}
				});
			}
		}

		return result;
	};

	// 只拿市场价格左右价差范围内的数据
	const slicePointByPriceSpreadRate = (
		previousValue: IPoint[],
		currentValue: IPoint,
		currentIndex: number,
		array: IPoint[]
	) => {
		const marketPricePointIndex = array.findIndex(item => item.isMarketPrice);
		const marketPrice = array[marketPricePointIndex].price;

		const getPriceSpreadRate = (price: IPoint['price']) =>
			new Decimal(price).div(marketPrice).minus(1);

		const priceSpreadBetweenIndexAndMarket = getPriceSpreadRate(
			currentPool.indexPrice
		).abs();
		const priceSpreadRate = Decimal.max(
			PRICE_SPREAD_RATE,
			toDecimalPlaces(
				priceSpreadBetweenIndexAndMarket.toString(),
				2,
				Decimal.ROUND_UP
			)
		).toString();

		// 价格更低的区间
		if (currentIndex < marketPricePointIndex) {
			// 如果第一个点在价差范围内，则直接返回
			if (
				currentIndex === 0 &&
				getPriceSpreadRate(currentValue.price).gt(-priceSpreadRate)
			) {
				return [{ ...currentValue, isXAxisLabel: true }];
			}

			const lastPoint =
				currentIndex === 0 ? currentValue : array[currentIndex - 1];
			const nextPoint =
				currentIndex === 0 && marketPricePointIndex === 1
					? array[marketPricePointIndex]
					: currentValue;

			// 如果前后点穿过了价差，则左边界点为被穿过的点
			if (
				getPriceSpreadRate(lastPoint.price).lt(-priceSpreadRate) &&
				getPriceSpreadRate(nextPoint.price).gt(-priceSpreadRate)
			) {
				const price = new Decimal(1)
					.minus(priceSpreadRate)
					.mul(marketPrice)
					.toString();

				const size = getPointOnLine(
					price,
					lastPoint.size,
					lastPoint.price,
					nextPoint.size,
					nextPoint.price
				);

				const point: IPoint = {
					size,
					price,
					premiumRate: getPremiumRate(price),
					isXAxisLabel: true
				};
				return [...previousValue, point, nextPoint];
			}
			if (getPriceSpreadRate(currentValue.price).gt(-priceSpreadRate)) {
				return [...previousValue, currentValue];
			}
		}

		// 价格更高的区间
		if (currentIndex >= marketPricePointIndex) {
			if (
				currentIndex === array.length - 1 &&
				getPriceSpreadRate(currentValue.price).lt(priceSpreadRate)
			) {
				return [...previousValue, { ...currentValue, isXAxisLabel: true }];
			}
			// 如果某个点在价差范围内，则直接返回
			if (getPriceSpreadRate(currentValue.price).lt(priceSpreadRate)) {
				return [...previousValue, currentValue];
			}

			const lastPoint = array[currentIndex - 1];
			// 如果前后点穿过了价差，则右边界点为被穿过的点
			if (
				getPriceSpreadRate(lastPoint.price).lt(priceSpreadRate) &&
				getPriceSpreadRate(currentValue.price).gt(priceSpreadRate)
			) {
				const price = new Decimal(1)
					.plus(priceSpreadRate)
					.mul(marketPrice)
					.toString();

				const size = getPointOnLine(
					price,
					lastPoint.size,
					lastPoint.price,
					currentValue.size,
					currentValue.price
				);

				const point: IPoint = {
					size,
					price,
					premiumRate: getPremiumRate(price),
					isXAxisLabel: true
				};
				return [...previousValue, point];
			}
		}
		return previousValue;
	};

	const addXAxisLabelPoint = (
		previousValue: IPoint[],
		currentValue: IPoint,
		currentIndex: number,
		array: IPoint[]
	) => {
		const marketPrice = array.find(item => item.isMarketPrice)?.price as string;

		const countToSupply = LABEL_COUNT - 3;
		const priceStep = new Decimal(array[array.length - 1].price)
			.minus(array[0].price)
			.div(LABEL_COUNT - 1)
			.toString();
		const targetPriceArr = [];
		let overMarketPrice = false;
		const marketPriceStep = new Decimal(marketPrice)
			.minus(array[0].price)
			.div(priceStep)
			.toDecimalPlaces(0, Decimal.ROUND_HALF_CEIL)
			.toNumber();

		for (let i = 0; i < countToSupply; i++) {
			const price = new Decimal(priceStep)
				.mul(overMarketPrice ? i + 2 : i + 1)
				.plus(array[0].price)
				.toString();

			overMarketPrice = overMarketPrice || i === marketPriceStep - 2;

			if (!previousValue.find(item => item.price === price)) {
				targetPriceArr.push(price);
			}
		}

		if (currentIndex === 0) {
			return [currentValue];
		}

		const points: IPoint[] = [];
		while (targetPriceArr.length) {
			const targetPrice = targetPriceArr[0] as string;
			const leftPointIndex = array.findIndex(
				(value, index) =>
					index !== array.length - 1 &&
					isGreaterThan(targetPrice, value.price) &&
					isLessThan(targetPrice, array[index + 1].price)
			);
			if (leftPointIndex !== -1 && leftPointIndex === currentIndex - 1) {
				const leftPoint = array[leftPointIndex];
				const rightPoint = array[leftPointIndex + 1];
				const point: IPoint = {
					price: targetPrice,
					size: getPointOnLine(
						targetPrice,
						leftPoint.size,
						leftPoint.price,
						rightPoint.size,
						rightPoint.price
					),
					premiumRate: getPremiumRate(targetPrice),
					isXAxisLabel: true
				};
				points.push(point);
				targetPriceArr.shift();
			} else {
				break;
			}
		}
		return [...previousValue, ...points, currentValue];
	};

	const addPoints = (
		previousValue: IPoint[],
		currentValue: IPoint,
		currentIndex: number,
		array: IPoint[]
	) => {
		if (isEmpty(array) || currentIndex !== array.length - 1) {
			return [];
		}

		const totalCount = 500;
		const marketPriceIndex = array.findIndex(item => item.isMarketPrice);
		const marketPricePoint = array.find(item => item.isMarketPrice);

		if (marketPriceIndex < 0 || isEmpty(marketPricePoint)) {
			return array;
		}

		// 低于市场价的点
		const leftArray = array.slice(0, marketPriceIndex);
		const leftToApply = totalCount - leftArray.length;
		const leftStep = new Decimal(marketPricePoint.price)
			.minus(array[0].price)
			.div(leftToApply + 1)
			.toString();

		// 高于市场价的点
		const rightArray = array.slice(marketPriceIndex + 1);
		const rightToApply = totalCount - rightArray.length;
		const rightStep = new Decimal(array[array.length - 1].price)
			.minus(marketPricePoint.price)
			.div(rightToApply + 1);

		// 将平均价格，插入到左侧关键点中
		const result: IPoint[] = [array[0]];
		let startIndex = 1;
		for (let i = 0; i < leftToApply; i++) {
			const price = new Decimal(leftStep)
				.mul(i + 1)
				.add(array[0].price)
				.toString();
			if (isLessThan(price, array[startIndex]?.price)) {
				const size = getPointOnLine(
					price,
					array[startIndex - 1].size,
					array[startIndex - 1].price,
					array[startIndex].size,
					array[startIndex].price
				);
				result.push({ price, size, premiumRate: getPremiumRate(price) });
			} else {
				result.push(array[startIndex]);
				startIndex += 1;
			}
		}
		// 将没走到的关键点补全
		for (let i = startIndex; i < marketPriceIndex + 1; i++) {
			result.push(array[i]);
		}
		startIndex = marketPriceIndex + 1;

		// 将平均价格，插入到右侧关键点中
		for (let i = 0; i < rightToApply; i++) {
			const price = new Decimal(rightStep)
				.mul(i + 1)
				.add(array[marketPriceIndex].price)
				.toString();
			if (isLessThan(price, array[startIndex]?.price)) {
				const size = getPointOnLine(
					price,
					array[startIndex - 1].size,
					array[startIndex - 1].price,
					array[startIndex].size,
					array[startIndex].price
				);
				result.push({ price, size, premiumRate: getPremiumRate(price) });
			} else {
				result.push(array[startIndex]);
				startIndex += 1;
			}
		}
		// 将没走到的关键点补全
		for (let i = startIndex; i < array.length; i++) {
			result.push(array[i]);
		}

		return result;
	};

	// 补充数据点，将当前市场价格放在中间
	const moveMarketPriceToCenter = (
		previousValue: IPoint[],
		currentValue: IPoint,
		currentIndex: number,
		array: IPoint[]
	) => {
		const originPointIndex = array.findIndex(item => item.isMarketPrice);
		const leftCount = array.slice(0, originPointIndex).length;
		const rightCount = array.slice(originPointIndex + 1).length;

		// 往零size点左边加
		if (leftCount < rightCount) {
			const countToAdd = rightCount - leftCount;
			if (currentIndex === 1) {
				const points: IPoint[] = [];
				const sizeStep = new Decimal(
					previousValue[previousValue.length - 1].size
				)
					.minus(currentValue.size)
					.div(countToAdd + 1)
					.toNumber();
				for (let i = 0; i < countToAdd; i++) {
					const size = new Decimal(sizeStep)
						.mul(i + 1)
						.neg()
						.add(previousValue[previousValue.length - 1].size)
						.toNumber();
					// 2个点可能是同一个点，导致price算不出来，1个点确定不了一条直线
					const price = getPointOnLine(
						size,
						previousValue[previousValue.length - 1].price,
						previousValue[previousValue.length - 1].size,
						currentValue.price,
						currentValue.size
					);
					points.push({
						size,
						price,
						premiumRate: getPremiumRate(price)
					});
				}
				return [...previousValue, ...points, currentValue];
			} else {
				return [...previousValue, currentValue];
			}
		}
		// 往零size点右边加
		if (leftCount > rightCount) {
			const countToAdd = leftCount - rightCount;
			if (currentIndex === array.length - 1) {
				const points: IPoint[] = [];
				const sizeStep = new Decimal(currentValue.size)
					.minus(previousValue[previousValue.length - 1].size)
					.div(countToAdd + 1)
					.toNumber();
				for (let i = 0; i < countToAdd; i++) {
					const size = new Decimal(sizeStep)
						.mul(i + 1)
						.add(previousValue[previousValue.length - 1].size)
						.toNumber();
					const price = getPointOnLine(
						size,
						previousValue[previousValue.length - 1].price,
						previousValue[previousValue.length - 1].size,
						currentValue.price,
						currentValue.size
					);
					points.push({
						size,
						price,
						premiumRate: getPremiumRate(price)
					});
				}
				return [...previousValue, ...points, currentValue];
			}
			return [...previousValue, currentValue];
		}
		// 左右数量一样，不加点
		return [...previousValue, currentValue];
	};

	const chartData = useMemo(() => {
		if (
			!lowerPriceData.length ||
			!higherPriceData.length ||
			isEqualTo(globalLiquidityPosition.liquidity, 0)
		) {
			return [
				{
					size: 0,
					price: toQuoteAmount(currentPool.indexPrice, precision),
					isIndexPrice: true,
					isOriginPoint: true,
					premiumRate: '0',
					isXAxisLabel: true
				}
			];
		}

		return [
			...lowerPriceData,
			{
				size: new Decimal(globalLiquidityPosition.netSize).neg().toNumber(),
				price: currentPool.indexPrice,
				isIndexPrice: true,
				isOriginPoint: true,
				premiumRate: '0'
			},
			...higherPriceData
		]
			.filter(item => !isEmpty(item))
			.reduce(addMarketPricePoint, [])
			.map(item => ({
				...item,
				size: new Decimal(item.size).abs().toNumber()
			}))
			.reduce(markLiquidationBufferPoint, [])
			.reduce(updateLiquidationBufferPoint, [])
			.reduce(slicePointByPriceSpreadRate, [])
			.reduce(addXAxisLabelPoint, [])
			.reduce(addPoints, [])
			.reduce(moveMarketPriceToCenter, [])
			.filter(item => !isEmpty(item))
			.map(item => {
				return {
					...item,
					size: new Decimal(item?.size).toNumber(),
					price: toQuoteAmount(item.price, precision)
				};
			});
	}, [
		priceState,
		globalLiquidityPosition,
		toDecimalPlaces(currentPool.indexPrice)
	]);

	const marketPriceIndex = chartData.findIndex(item => item.isMarketPrice);
	const indexPriceIndex = chartData.findIndex(item => item.isIndexPrice);
	const [selectedIndex, setSelectedIndex] = useState(-1);

	const priceLabel = t`Price:`;
	const sizeLabel = t`Total Size:`;
	const prLabel = t`Premium Rate:`;
	const marketPriceLabel = t`Market Price`;
	const indexPriceLabel = t`Index Price`;

	const options = {
		title: {
			text: ''
		},
		credits: {
			enabled: false
		},
		legend: {
			layout: 'vertical',
			backgroundColor: 'transparent',
			floating: true,
			align: 'center',
			verticalAlign: 'bottom',
			x: 0,
			y: 10,
			useHTML: true,
			labelFormat:
				chartData.length > 10 || chartData.length === 1
					? `<div style="font-size: 12px;color: ${alpha(
							theme.custom.reverseColor,
							0.6
					  )};cursor: default;display: flex;column-gap: 16px;"><div>${marketPriceLabel}</div><div><div style='border-bottom: 1px solid ${alpha(
							theme.custom.reverseColor,
							0.6
					  )};height: 50%;width: 50px'></div></div><div>${indexPriceLabel}&nbsp;&nbsp;&nbsp;. . . . . . . .</div></div>`
					: '',
			symbolWidth: 0,
			symbolHeight: 0
		},
		plotOptions: {
			series: {
				events: {
					legendItemClick: function () {
						return false;
					}
				},
				states: {
					inactive: {
						opacity: 1
					}
				},
				point: {
					events: {
						mouseOver: function () {
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							setSelectedIndex(this.index);
						},
						mouseOut: function () {
							setSelectedIndex(-1);
						}
					}
				},
				marker: {
					symbol: 'circle',
					enabled: true,
					radius: 2.5,
					lineWidth: 1,
					lineColor: null
				},
				shadow: false,
				animation: false
			}
		},
		xAxis: {
			categories: chartData.map(item => item.price),
			tickPositions: chartData
				.map((item, index) =>
					item.isXAxisLabel || item.isMarketPrice ? index : false
				)
				.filter(item => item !== false),
			labels: {
				style: {
					color: '#8B919D'
				}
			},
			plotLines: [
				{
					value: marketPriceIndex,
					color: alpha(theme.custom.reverseColor, 0.2),
					width: 2
				},
				{
					value: indexPriceIndex,
					color: alpha(theme.custom.reverseColor, 0.2),
					width: 2,
					dashStyle: 'dot'
				},
				{
					value: selectedIndex,
					color: alpha(theme.custom.reverseColor, 0.2),
					width: selectedIndex === -1 ? 0 : 2,
					dashStyle: 'dash'
				}
			]
		},
		yAxis: {
			gridLineWidth: 0,
			gridLineColor: null,
			title: {
				text: ''
			},
			labels: {
				style: {
					color: '#8B919D'
				}
			}
		},
		series: [
			{
				type: 'area',
				data: [
					...chartData.slice(0, marketPriceIndex + 1).map(item => item.size),
					...chartData.slice(marketPriceIndex + 1).map(() => null)
				],
				marker: {
					enabled: false
				},
				showInLegend: false,
				lineColor: {
					linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
					stops: [
						['0', 'rgb(0, 122, 78)'],
						['100%', 'rgb(12, 186, 113)']
					]
				},
				fillColor: 'rgba(12, 186, 113, 0.1)'
			},
			{
				type: 'area',
				data: [
					...chartData.slice(0, marketPriceIndex).map(() => null),
					...chartData.slice(marketPriceIndex).map(item => item.size)
				],
				lineColor: {
					linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
					stops: [
						['0%', '#f04a54'],
						['100%', '#bb212a']
					]
				},
				fillColor: 'rgba(240, 74, 84, 0.1)',
				marker: {
					enabled: false
				},
				showInLegend: false
			},
			{
				type: 'area',
				data: []
			}
		],
		chart: {
			type: 'area',
			backgroundColor: 'transparent',
			zoomType: 'none',
			height: height,
			marginRight: 20,
			marginBottom: 60,
			animation: false
		},
		tooltip: {
			backgroundColor: theme.custom.dialogBg,
			shadow: false,
			borderWidth: 1,
			borderColor: theme.palette.divider,
			useHTML: true,
			style: {
				color: theme.palette.text.primary
			},
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			formatter: function () {
				const priceData: string =
					`<div style="display: flex;justify-content: space-between"><div style="margin-right: 24px;font-family: ${theme.typography.fontFamily};color: ${theme.palette.secondary.main}">${priceLabel}</div>` +
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					` ${toQuoteAmount(this.point.category)} </div>`;

				const sizeData: string =
					`<div style="display: flex;justify-content: space-between"><div style="margin-right: 24px;font-family: ${theme.typography.fontFamily};color: ${theme.palette.secondary.main}">${sizeLabel}</div>` +
					` ${formatNumber(
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						this.point.y,
						positionUnits
					)} ${tokenSymbol}</div>`;

				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				const premiumRate = chartData[this.point.x].premiumRate;
				let prNum;
				if (isEqualTo(premiumRate, 0)) {
					prNum = toPercent(premiumRate);
				} else if (new Decimal(premiumRate).abs().lessThan(0.0001)) {
					if (isPositive(premiumRate)) {
						prNum = '<0.01%';
					} else {
						prNum = '<-0.01%';
					}
				} else {
					prNum = toPercent(premiumRate);
				}
				const prData =
					`<div style="display: flex;justify-content: space-between"><div style="margin-right: 24px;font-family: ${theme.typography.fontFamily};color: ${theme.palette.secondary.main}">${prLabel}</div>` +
					` ${prNum}</div>`;
				return `<div style="padding:6px;">${priceData} ${sizeData} ${prData}</div>`;
			}
		},

		colors: ['#007A4E', '#F04A54']
	};

	return (
		<Box ref={ref} className='w-full'>
			{ref.current ? (
				<HighchartsReact highcharts={Highcharts} options={options} />
			) : (
				<SectionLoading full={true} />
			)}
		</Box>
	);
};

export { DepthChartV1, DepthChartV2 };
