import React, { useState } from 'react';

import { Trans } from '@lingui/macro';
import { Box, Button, ButtonProps, styled, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton/IconButton';
import { History_Filter_Type, Page, Version } from 'config/constants';
import { useAccount } from 'wagmi';

import { useCurrentPage } from 'hooks/useCurrentPage';

import StyledTabs from 'components/Common/StyledTabs';

import downloadIcon from '../../../assets/svg/download.svg';
import { useAppBreakpoints } from '../../../hooks/useAppBreakpoints';
import { useCheckLogin } from '../../../hooks/useCurrentChain';
import { useAppSelector } from '../../../state/hooks';
import OrderHistoryList from './OrderHistoryList';
import PositionHistoryList from './PositionHistoryList';
import TradeHistoryList from './TradeHistoryList';

export const StyledButton = styled(Button)<ButtonProps & { selected: boolean }>(
	({ theme, selected }) => ({
		height: 32,
		padding: '0 16px',
		backgroundColor: selected ? theme.palette.action.hover : 'inherit',
		color: selected ? theme.palette.text.primary : theme.palette.text.secondary,
		borderColor: selected ? theme.palette.action.hover : theme.palette.divider,
		fontWeight: 400,
		fontSize: 14,
		'&:hover': {
			borderColor: theme.palette.action.hover,
			backgroundColor: theme.palette.action.hover,
			color: theme.palette.text.primary
		}
	})
);

export default function HistoryList({
	targetPoolsId
}: {
	targetPoolsId: string[];
}) {
	const currentPage = useCurrentPage();
	const isLogin = useCheckLogin();
	const { isMatchMobile } = useAppBreakpoints();
	const currentVersion = useAppSelector(state => state.setting.currentVersion);

	const [historyType, setHistoryType] = useState<History_Filter_Type>(
		History_Filter_Type.Trade
	);

	const [downloadTradeHistory, setDownloadTradeHistory] = useState(false);
	const [downloadPositionHistory, setDownloadPositionHistory] = useState(false);
	const downLoad = () => {
		if (historyType === History_Filter_Type.Trade) {
			setDownloadTradeHistory(true);
		} else {
			setDownloadPositionHistory(true);
		}
	};

	const theme = useTheme();
	const { address } = useAccount();

	return (
		<div className='space-y-4 sm:space-y-3'>
			<StyledTabs
				value={0}
				size='large'
				variant='scrollable'
				scrollButtons='auto'
				allowScrollButtonsMobile
				sx={{
					'& [aria-selected=false]': {
						fontWeight: 400
					},
					'& .MuiTabs-scroller': {
						margin: 0
					},
					'& .MuiTabScrollButton-root:first-of-type': {
						left: '-5px',
						top: 0,
						height: '32px',
						width: '36px',
						backgroundImage: `linear-gradient(-270deg,${theme.custom.paperBg} 72.22%,hsla(0,0%,7%,0) 100%)`,
						opacity: 1,
						justifyContent: 'start'
					},
					'& .MuiTabScrollButton-root:last-of-type': {
						right: '-5px',
						top: 0,
						height: '32px',
						width: '36px',
						backgroundImage: `linear-gradient(270deg,${theme.custom.paperBg} 72.22%,hsla(0,0%,7%,0) 100%)`,
						opacity: 1,
						justifyContent: 'end'
					}
				}}
			>
				<div className='flex flex-1 gap-2 justify-between items-center'>
					<section className='flex space-x-2'>
						<StyledButton
							className='block'
							variant='outlined'
							size='small'
							onClick={() => setHistoryType(History_Filter_Type.Trade)}
							selected={historyType === History_Filter_Type.Trade}
						>
							<Trans>Trade History</Trans>
						</StyledButton>
						<StyledButton
							className='block'
							variant='outlined'
							size='small'
							color='secondary'
							onClick={() => setHistoryType(History_Filter_Type.Position)}
							selected={historyType === History_Filter_Type.Position}
						>
							<Trans>Position History</Trans>
						</StyledButton>
						<StyledButton
							className='block'
							variant='outlined'
							size='small'
							color='secondary'
							onClick={() => setHistoryType(History_Filter_Type.Order)}
							selected={historyType === History_Filter_Type.Order}
						>
							<Trans>Order History</Trans>
						</StyledButton>
					</section>
					{isLogin &&
						!isMatchMobile &&
						currentVersion === Version.V2 &&
						historyType !== History_Filter_Type.Order && (
							<IconButton onClick={downLoad}>
								<img width={20} src={downloadIcon} alt='' />
							</IconButton>
						)}
				</div>
			</StyledTabs>
			<Box>
				{historyType === History_Filter_Type.Trade && (
					<TradeHistoryList
						key={`TradeHistory_${targetPoolsId[0]}_${targetPoolsId.length}_${address}`}
						poolIds={targetPoolsId}
						full={currentPage !== Page.Markets}
						download={downloadTradeHistory}
						finishDownload={() => setDownloadTradeHistory(false)}
					/>
				)}
				{historyType === History_Filter_Type.Position && (
					<PositionHistoryList
						key={`TradeHistory_${targetPoolsId[0]}_${targetPoolsId.length}_${address}`}
						poolIds={targetPoolsId}
						download={downloadPositionHistory}
						finishDownload={() => setDownloadPositionHistory(false)}
					/>
				)}
				{historyType === History_Filter_Type.Order && (
					<OrderHistoryList
						key={`TradeHistory_${targetPoolsId[0]}_${targetPoolsId.length}_${address}`}
						poolIds={targetPoolsId}
						full={currentPage !== Page.Markets}
					/>
				)}
			</Box>
		</div>
	);
}
