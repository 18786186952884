import { useMemo } from 'react';

import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';

import { useCurrentChain } from 'hooks/useCurrentChain';
import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import { formatNumber, isPositive, multipliedBy, toUsd } from 'utils';

import Cell from 'components/Common/Cell';

export default function ExecutionFeeV2({
	quantity = 1,
	executionFee
}: {
	quantity?: number;
	executionFee?: string;
}) {
	const { nativePriceUsd, positionMinExecutionFee } =
		useAppSelector(globalBaseState);

	const { currentChain } = useCurrentChain();

	const computedExecutionFee = useMemo(() => {
		const _value = executionFee || positionMinExecutionFee;
		return -multipliedBy(_value, quantity);
	}, [positionMinExecutionFee, quantity, executionFee]);

	const executionFeeUsd = useMemo(() => {
		if (isPositive(nativePriceUsd) && computedExecutionFee) {
			return multipliedBy(nativePriceUsd, computedExecutionFee);
		} else {
			return '';
		}
	}, [nativePriceUsd, computedExecutionFee]);

	return (
		<Cell
			label={
				<Typography variant='body2'>
					<Trans>Execution Fee</Trans>
				</Typography>
			}
			value={
				<div className='flex space-x-1'>
					<Typography variant='body2'>
						{`${formatNumber(computedExecutionFee, 5)} ${
							currentChain.nativeCurrency.symbol
						}`}
					</Typography>
					<Typography variant='body2' color='secondary'>
						({toUsd(executionFeeUsd)})
					</Typography>
				</div>
			}
		/>
	);
}
