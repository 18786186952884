import { useCallback, useContext } from 'react';

import { Trans, t } from '@lingui/macro';
import { Box, useTheme } from '@mui/material';
import { App_Theme, Side } from 'config/constants';
import { TradeContext } from 'context/TradeContext';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { shortenSymbolNative } from 'utils';

import CommonButton from 'components/Common/StyledButton';

export default function ToggleDirection({
	unselectedComponentVariant = 'transparent'
}: {
	unselectedComponentVariant?: React.ComponentProps<
		typeof CommonButton
	>['componentVariant'];
}) {
	const dispatch = useAppDispatch();

	const { poolInfo } = useAppSelector(poolsBaseState);

	const { tradeDirection, setTradeDirection } = useContext(TradeContext);

	const { currentChainId } = useCurrentChain();

	const { isMatchMobile } = useAppBreakpoints();

	const theme = useTheme();

	const onHandleChangeTab = useCallback(
		(value: Side) => {
			setTradeDirection(value);
		},
		[dispatch, setTradeDirection]
	);

	return (
		<div className='relative flex'>
			<Box
				className={`absolute left-0 h-full w-full rounded-lg`}
				sx={{ backgroundColor: theme.custom.cardBg }}
			></Box>
			<div className='relative z-9 flex-1'>
				<CommonButton
					fullWidth
					componentVariant={
						tradeDirection === Side.LONG
							? 'long'
							: isMatchMobile && theme.palette.mode === App_Theme.dark
							? 'action'
							: unselectedComponentVariant
					}
					variant='contained'
					color='inherit'
					onClick={() => onHandleChangeTab(Side.LONG)}
				>
					<Trans>
						Long {shortenSymbolNative(poolInfo?.baseToken, currentChainId)}
					</Trans>
				</CommonButton>
			</div>
			<div className='relative z-9 flex-1'>
				<CommonButton
					fullWidth
					componentVariant={
						tradeDirection === Side.SHORT
							? 'short'
							: isMatchMobile && theme.palette.mode === App_Theme.dark
							? 'action'
							: unselectedComponentVariant
					}
					onClick={() => onHandleChangeTab(Side.SHORT)}
					variant='contained'
					color='inherit'
				>
					<Trans>
						Short {shortenSymbolNative(poolInfo?.baseToken, currentChainId)}
					</Trans>
				</CommonButton>
			</div>
		</div>
	);
}
