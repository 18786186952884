import React, {
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useUpdate, useUpdateEffect } from 'react-use';

import { Trans } from '@lingui/macro';
import ArrowDownIcon from '@mui/icons-material/ExpandMore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
	Box,
	Button,
	IconButton,
	Paper,
	Typography,
	useTheme
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import classNames from 'classnames';
import {
	App_Theme,
	Dom_Size,
	Order_Open_Type_V2,
	Order_Status_V2,
	Page,
	QUOTE_USD,
	Side,
	Trade_Type,
	Transaction_Type
} from 'config/constants';
import { IOrderItem, useMyOrdersFetch } from 'fetch/useMyOrdersFetch';
import { useAccount } from 'wagmi';

import { TransactionRecord } from 'entities/TransactionRecord';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import useIsWindowVisible from 'hooks/useIsWindowVisible';
import { useLocalOrders } from 'hooks/useLocalOrders';
import { useV2TradePositionRequest } from 'hooks/useLoopMyPositions';
import ChartsPool, { Chart_Tab_Type } from 'pages/components/ChartsPool';
import FreeTradingFeeWarning from 'pages/components/FreeTradingFeeWarning';
import FundingRate from 'pages/components/FundingRate';
import HistoryList from 'pages/components/Operations/HistoryList';
import PositionsList from 'pages/components/Operations/PositionsList';
import OpenOrdersListV2 from 'pages/components/Operations/V2/OpenOrdersListV2';
import ViewUpdaterV2 from 'pages/components/ViewUpdaterV2';
import { globalBaseState } from 'state/global/slice';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { poolsBaseState, setV2PoolAddress } from 'state/pools/slice';
import { selectTransactionRecordList } from 'state/records/selector';
import { setTransactionType, txBaseState } from 'state/tx/slice';
import { IPoolItem } from 'types';
import {
	amountFormatter,
	checkOrderIsExpired,
	div,
	formatModuleNumber,
	isGreaterThan,
	isLessThanOrEqualTo,
	isNegative,
	isNumeric,
	isPositive,
	isZero,
	multipliedBy,
	plus,
	shortenSymbolNative,
	toPercent,
	toQuoteAmount,
	toUsd
} from 'utils';

import { DirectionCell } from 'components/Common/Cell';
import { StyleCheckbox } from 'components/Common/StyleCheckbox';
import { CommonStyledDivider } from 'components/Common/Styled';
import StyledTab from 'components/Common/StyledTab';
import StyledTabs from 'components/Common/StyledTabs';
import FundingFeeTimer from 'components/FundingFeeTimer';
import PriceChange, {
	StyledPriceChangeContainer
} from 'components/PriceChange';
import SectionLoading from 'components/SectionLoading';

import Dialog from '../../components/Common/Dialog';
import NoData from '../../components/NoData';
import ComputerIcon from '../../components/Svg/Icons/Computer';
import TippingUnderline from '../../components/TippingUnderline';
import { TradeContext } from '../../context/TradeContext';
import useEffectEvent from '../../hooks/useEffectEvent';
import { selectCurrentPool } from '../../state/pools/selector';
import {
	setOnlyUsedMarkets,
	settingBaseState
} from '../../state/setting/slice';
import { setOrders } from '../../state/trade/slice';
import Computer from '../components/Computer';
import ExpiredOrderWarning from '../components/ExpiredOrderWarning';
import LimitForm from '../components/TradeForm/LimitForm';
import MarketForm from '../components/TradeForm/MarketForm';
import ToggleDirection from '../components/TradeForm/ToggleDirection';
import V2Logo from '../components/V2/V2Logo';

export enum Tab_Type {
	Positions = 0,
	Orders = 1,
	History = 2
}

interface HeaderProps {
	key: string;
	width: string;
	label: ReactNode;
	align: 'inherit' | 'left' | 'center' | 'right' | 'justify';
	cellRender: (row: IPoolItem) => JSX.Element;
	invisible?: boolean;
}

interface RowState {
	tabType: Tab_Type;
	expand: boolean;
	chartType: Chart_Tab_Type;
}

const initalRowState = {
	tabType: Tab_Type.Positions,
	expand: false,
	chartType: Chart_Tab_Type.Price
};

const FORMATED_POSITION_AMOUNT_MAX = 999_999_999;

export default React.memo(function TradeTable({
	poolList
}: {
	poolList: Array<IPoolItem>;
}) {
	const { appTimer } = useAppSelector(globalBaseState);
	const { onlyUsedMarkets } = useAppSelector(settingBaseState);
	const { poolAddress } = useAppSelector(poolsBaseState);
	const { signingMap } = useAppSelector(txBaseState);
	const transactionRecords = useAppSelector(selectTransactionRecordList);
	const isWindowVisible = useIsWindowVisible();
	const currentPool = useAppSelector(selectCurrentPool);
	const { tradeDirection, dialogIsVisible, setDialogIsVisible } =
		useContext(TradeContext);

	const [rowStates, setRowStates] = useState<Record<string, RowState>>({});

	const dispatch = useAppDispatch();

	const update = useUpdate();
	const theme = useTheme();
	const { address } = useAccount();
	const isLogin = useCheckLogin();
	const { currentChainId } = useCurrentChain();
	const { isMatchMobile, isMatchPc } = useAppBreakpoints();
	const navigator = useNavigate();

	const { positionMap, isLoadingPositions: isLoading } =
		useV2TradePositionRequest();
	const { orderAlldRecords, orderHashes } = useLocalOrders();

	const filteredPoolList = poolList.filter(pool =>
		onlyUsedMarkets && isLogin ? positionMap?.get(pool.id) : true
	);
	const isLoadingPositions = isLoading || (isLogin && !positionMap);

	const ref = useRef(null);
	const jumpToTargetPool = useEffectEvent(() => {
		if (isMatchMobile) {
			return;
		}

		const targetIndex =
			filteredPoolList.findIndex(pool => pool.address === poolAddress) || 0;
		const totalHeight = filteredPoolList
			.slice(0, targetIndex)
			.reduce((previousValue, pool) => {
				const positions = positionMap?.get(pool.id) || [];
				const longPosition = positions.find(item => item.side === Side.LONG);
				const shortPosition = positions.find(item => item.side === Side.SHORT);
				const padding = 12;
				// 目前的布局，多空双开的高度为80，没有仓位或者只有一个方向的高度为58
				const height = longPosition && shortPosition ? 80 : 58;
				return previousValue + height + padding;
			}, 0);
		if (ref.current) {
			ref.current.scrollTop = totalHeight;
		}
	});
	useEffect(() => {
		jumpToTargetPool();
	}, [poolAddress, positionMap?.size]);

	const {
		ordersMap,
		loading: isLoadingOrders,
		requestsByHashes,
		refetch: refetchOrders
	} = useMyOrdersFetch(undefined, orderHashes);

	const onResetRowStates = useCallback((poolId: string) => {
		setRowStates(state => {
			return {
				[poolId]: state[poolId] || initalRowState
			};
		});
	}, []);

	useEffect(() => {
		if (!poolAddress) {
			return;
		}
		onResetRowStates(poolAddress);
	}, [poolAddress]);

	const canceledRecordsFromLocal = useMemo(() => {
		if (!transactionRecords || !transactionRecords.length) {
			return null;
		}
		const filteredTransactionRecords = transactionRecords;
		return filteredTransactionRecords.filter((txRecord: TransactionRecord) => {
			const record = txRecord.record;
			return (
				record.transactionType === Transaction_Type.CancelledLimitOrderV2 ||
				record.transactionType ===
					Transaction_Type.CancelTakeProfitPositionV2 ||
				record.transactionType === Transaction_Type.CancelStopLossPositionV2 ||
				record.transactionType === Transaction_Type.CancelTakeProfitPartialV2 ||
				record.transactionType === Transaction_Type.CancelStopLossPartialV2
			);
		});
	}, [transactionRecords]);

	const hasSigningCanceledRecord = useMemo(() => {
		const signingCanceledRecord =
			signingMap.get(Transaction_Type.CancelledLimitOrderV2) ||
			signingMap.get(Transaction_Type.CancelTakeProfitPositionV2) ||
			signingMap.get(Transaction_Type.CancelStopLossPositionV2) ||
			signingMap.get(Transaction_Type.CancelTakeProfitPartialV2) ||
			signingMap.get(Transaction_Type.CancelStopLossPartialV2) ||
			null;
		return Boolean(signingCanceledRecord);
	}, [signingMap]);

	const disabledCancelAll = useMemo(() => {
		if (hasSigningCanceledRecord) {
			return true;
		}
		// 只要有一个取消操作，就禁用全部取消
		if (canceledRecordsFromLocal && canceledRecordsFromLocal.length > 0) {
			return true;
		}
		return false;
	}, [hasSigningCanceledRecord, canceledRecordsFromLocal]);

	const [orderList, createdOrderList] = useMemo(() => {
		if (!poolAddress || !ordersMap || !ordersMap.has(poolAddress)) {
			return [null, null];
		}
		const orderList = ordersMap.get(poolAddress) || [];
		const createdOrderList = orderList.filter((item: IOrderItem) => {
			return item.orderStatus === Order_Status_V2.Created;
		});
		return [orderList, createdOrderList];
	}, [ordersMap, poolAddress]);

	const expiredOrders = useMemo(() => {
		if (!createdOrderList) {
			return [];
		}
		return createdOrderList.filter(order =>
			checkOrderIsExpired(order, positionMap, Order_Open_Type_V2)
		);
	}, [createdOrderList, positionMap]);
	useEffect(() => {
		dispatch(setOrders(expiredOrders));
	}, [expiredOrders]);

	const onHandleSetCurrentPool = (row: IPoolItem) => {
		dispatch(setV2PoolAddress(row.id));
		if (isMatchMobile) {
			navigator(`${Page.Trade}/${row.id}`);
		} else {
			navigator(`${Page.Markets}/${row.id}`);
		}
	};

	const onHandleChangeOperationType = (value: Tab_Type, rowState: RowState) => {
		rowState.tabType = value;
		update();
	};

	const onHandleChangeChartType = (value: number, rowState: RowState) => {
		rowState.chartType = value;
		update();
	};

	const onHandleShowChart = (e: any, pool: IPoolItem) => {
		e.stopPropagation();
		onResetRowStates(pool.id);
		onHandleSetCurrentPool(pool);
		setRowStates(state => {
			return {
				[pool.id]: { ...state[pool.id], expand: !state[pool.id].expand }
			};
		});
	};

	const targetPoolsId = useMemo(() => {
		if (!poolAddress) {
			return null;
		}
		return [poolAddress];
	}, [poolAddress]);

	const headers: Array<HeaderProps> = [
		{
			key: 'baseSymbol',
			width: '17%',
			label: <Trans>Market</Trans>,
			align: 'left',
			cellRender: (row: IPoolItem) => {
				return (
					<div className='flex items-center justify-start'>
						<Box
							sx={!isMatchMobile && { maxWidth: 'calc(100% - 38px)' }}
							className='flex space-x-1 flex-wrap'
						>
							<Typography
								className='overflow-hidden break-words'
								fontWeight='medium'
								variant='h6'
							>
								{`${shortenSymbolNative(
									row.baseToken,
									currentChainId
								)}/${QUOTE_USD}`}
							</Typography>
						</Box>
						<Typography
							onClick={e => onHandleShowChart(e, row)}
							color='text.secondary'
							className={classNames(
								'relative text-base -right-2 cursor-pointer rounded-full p-0.5 sm:hidden',
								row.id === poolAddress && rowStates[row.id]?.expand
									? 'rotate-180'
									: 'rotate-0'
							)}
							component='span'
							sx={{
								'&:hover': {
									backgroundColor: theme.palette.action.hover
								}
							}}
						>
							<ArrowDownIcon color='inherit' />
						</Typography>
					</div>
				);
			}
		},
		{
			key: 'marketPrice',
			width: '12%',
			label: <Trans>Price / Index</Trans>,
			align: 'left',
			cellRender: (row: IPoolItem) => {
				return (
					<div>
						<Typography variant='body2'>
							{toQuoteAmount(row.price, row.baseToken?.precision)}
						</Typography>
						<Typography variant='body2' color='secondary'>
							{toQuoteAmount(row.indexPrice, row.baseToken?.precision)}
						</Typography>
					</div>
				);
			}
		},
		{
			key: 'price',
			width: '12%',
			label: <Trans>24h Change</Trans>,
			align: 'left',
			cellRender: (row: IPoolItem) => {
				return <PriceChange size={16} value={row.priceChange}></PriceChange>;
			}
		},
		{
			key: 'fundingRate',
			width: '12%',
			label: (
				<Typography variant='body2' color='text.secondary' align='left'>
					<TippingUnderline
						label={<Trans>1h Funding</Trans>}
						tooltip={
							<div className='space-y-2'>
								<Typography variant='body2'>
									<Trans>
										Funding fees are settled every hour. When the funding rate
										is positive, long positions pay short positions; when
										it&#39;s negative, short positions pay long positions.
									</Trans>
								</Typography>
							</div>
						}
					/>
				</Typography>
			),
			align: 'left',
			cellRender: (row: IPoolItem) => {
				return (
					<Box>
						<FundingRate value={row.globalPosition.fundingRate} />
						<FundingFeeTimer />
					</Box>
				);
			}
		},
		{
			key: 'positions',
			width: '15%',
			label: (
				<Box className='whitespace-nowrap'>
					<Trans>Open Interest</Trans>
				</Box>
			),
			align: 'left',
			cellRender: (row: IPoolItem) => {
				const total = multipliedBy(row.price, row.positions);
				return (
					<div className='sm:flex sm:justify-end sm:flex-col'>
						<Typography variant='body2'>
							{amountFormatter(row.positions)}{' '}
							{shortenSymbolNative(row.baseToken, currentChainId)}
						</Typography>
						<Typography variant='body2' color='secondary'>
							{toUsd(total)}
						</Typography>
					</div>
				);
			}
		},
		{
			key: 'volume',
			width: '15%',
			label: (
				<Box className='whitespace-nowrap'>
					<Trans>24h Volume</Trans>
				</Box>
			),
			align: 'left',
			cellRender: (row: IPoolItem) => (
				<div className='sm:flex sm:justify-end sm:flex-col'>
					<Typography variant='body2'>
						{amountFormatter(row.volumeValue24h)}{' '}
						{shortenSymbolNative(row.baseToken, currentChainId)}
					</Typography>
					<Typography variant='body2' color='secondary'>
						{toUsd(row.volume24h)}
					</Typography>
				</div>
			),
			invisible: !isMatchPc
		},
		{
			key: 'myPositions',
			width: '29%',
			label: (
				<>
					{!isMatchMobile && isLogin && (
						<StyleCheckbox
							checked={onlyUsedMarkets}
							onChange={e => dispatch(setOnlyUsedMarkets(e.target.checked))}
							size='small'
							sx={{ height: '16px', paddingLeft: 0 }}
						/>
					)}
					<Trans>My Positions</Trans>
				</>
			),
			align: 'left',
			cellRender: (row: IPoolItem) => {
				if (!positionMap || !positionMap.size || !isLogin) {
					return (
						<Typography variant='body1' fontWeight={500}>
							-
						</Typography>
					);
				}
				const positions = positionMap.get(row.id);
				if (!positions) {
					return (
						<Typography variant='body1' fontWeight={500}>
							-
						</Typography>
					);
				}
				const longPosition = positions.find(item => item.side === Side.LONG);
				const shortPosition = positions.find(item => item.side === Side.SHORT);
				if (!longPosition?.size && !shortPosition?.size) {
					return (
						<Typography variant='body1' fontWeight={500}>
							-
						</Typography>
					);
				}

				return (
					<div
						className={classNames(
							'flex items-center space-x-1 overflow-hidden',
							{
								'justify-end': isMatchMobile
							}
						)}
					>
						{!isMatchMobile && (
							<div className='space-y-1'>
								{longPosition && (
									<Box
										className='px-2.5 py-1 md:px-4 md:py-2 rounded flex gap-2'
										sx={{ background: theme.custom.paperBg }}
									>
										<Typography color='success.main' noWrap>
											L
										</Typography>
										<Typography color='text.primary'>
											{amountFormatter(
												longPosition.size,
												2,
												row.baseToken.positionUnits
											)}
											&nbsp;
											{shortenSymbolNative(row.baseToken, currentChainId)}
										</Typography>
										<Typography
											color={
												isPositive(longPosition.unrealizedPnL)
													? theme.palette.success.main
													: isNegative(longPosition.unrealizedPnL)
													? theme.palette.error.main
													: theme.palette.text.primary
											}
										>
											{/*{toQuoteAmount(longPosition.unrealizedPnL, 2, 1, true)}*/}
											{isNumeric(longPosition.unrealizedPnLRate) &&
												isGreaterThan(longPosition.netMargin, 0) && (
													<span>
														{/*(*/}
														{`${
															isPositive(longPosition.unrealizedPnLRate)
																? '+'
																: ''
														}${toPercent(longPosition.unrealizedPnLRate)}`}
														{/*)*/}
													</span>
												)}
										</Typography>
									</Box>
								)}
								{shortPosition && (
									<Box
										className='px-2.5 py-1 md:px-4 md:py-2 rounded flex gap-2'
										sx={{ background: theme.custom.paperBg }}
									>
										<Typography color='error.main' noWrap>
											S
										</Typography>
										<Typography color='text.primary'>
											{amountFormatter(
												shortPosition.size,
												2,
												row.baseToken.positionUnits
											)}
											&nbsp;
											{shortenSymbolNative(row.baseToken, currentChainId)}
										</Typography>
										<Typography
											color={
												isPositive(shortPosition.unrealizedPnL)
													? theme.palette.success.main
													: isNegative(shortPosition.unrealizedPnL)
													? theme.palette.error.main
													: theme.palette.text.primary
											}
										>
											{/*{toQuoteAmount(shortPosition.unrealizedPnL, 2, 1, true)}*/}
											{isNumeric(shortPosition.unrealizedPnLRate) &&
												isGreaterThan(shortPosition.netMargin, 0) && (
													<span>
														{/*(*/}
														{`${
															isPositive(shortPosition.unrealizedPnLRate)
																? '+'
																: ''
														}${toPercent(shortPosition.unrealizedPnLRate)}`}
														{/*)*/}
													</span>
												)}
										</Typography>
									</Box>
								)}
							</div>
						)}
						{isMatchMobile && (
							<Box className='flex-col space-y-0.5'>
								{longPosition && (
									<Box
										className='px-2 py-1 rounded'
										sx={{ background: theme.custom.BlinkTagColor }}
									>
										<div className='flex gap-1 justify-end text-xs'>
											<Typography color='success.main' noWrap>
												<Trans>Long</Trans>
											</Typography>
											<Typography color='text.primary'>
												{isLessThanOrEqualTo(
													longPosition.size,
													FORMATED_POSITION_AMOUNT_MAX
												)
													? amountFormatter(
															longPosition.size,
															2,
															row.baseToken.positionUnits
													  )
													: amountFormatter(
															longPosition.size,
															2,
															row.baseToken.positionUnits
													  )}{' '}
												{/*{shortenSymbolNative(row.baseToken, currentChainId)}*/}
											</Typography>
										</div>
									</Box>
								)}
								{shortPosition && (
									<Box
										className='px-2 py-1 rounded'
										sx={{ background: theme.custom.BlinkTagColor }}
									>
										<div className='flex gap-1 justify-end text-xs'>
											<Typography color='error.main' noWrap>
												<Trans>Short</Trans>
											</Typography>
											<Typography color='text.primary'>
												{isLessThanOrEqualTo(
													shortPosition.size,
													FORMATED_POSITION_AMOUNT_MAX
												)
													? amountFormatter(
															shortPosition.size,
															2,
															row.baseToken.positionUnits
													  )
													: amountFormatter(
															shortPosition.size,
															2,
															row.baseToken.positionUnits
													  )}{' '}
												{/*{shortenSymbolNative(row.baseToken, currentChainId)}*/}
											</Typography>
										</div>
									</Box>
								)}
							</Box>
						)}
					</div>
				);
			}
		},
		{
			key: 'index',
			width: '10%',
			label: '',
			align: 'right',
			cellRender: () => (
				<Button
					variant='outlined'
					onClick={() => {
						setDialogIsVisible(true);
					}}
					className='font-normal'
					sx={{
						height: '32px',
						color: alpha(theme.palette.primary.main, 0.8),
						border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
						'&:hover': {
							border: `1px solid ${theme.palette.primary.main}`,
							background: alpha(theme.palette.primary.main, 0.12)
						}
					}}
				>
					<Trans>Trade</Trans>
				</Button>
			)
		}
	].filter(item => !item.invisible);
	const regex = /(\d+(?:\.\d+)?)(?=%)/;
	const totalWidth = headers.reduce(
		(prev, cur) => plus(prev, cur.width.match(regex)[1]),
		'0'
	);
	const headersProxy = headers.map(item => {
		return {
			...item,
			width: toPercent(div(item.width.match(regex)[1], totalWidth))
		};
	});

	useUpdateEffect(() => {
		if (isLogin && isWindowVisible) {
			refetchOrders(0);
		}
	}, [isLogin, address, appTimer, isWindowVisible]);

	const [tradeType, setTradeType] = useState(Trade_Type.Market);
	const onHandleChangeType = (_, newValue: Trade_Type) => {
		setTradeType(newValue);
	};

	const onHandleCloseForm = () => setDialogIsVisible(false);

	const [visibleComputer, setVisibleComputer] = useState(false);
	const onOpenComputerDialog = () => {
		setVisibleComputer(true);
		setDialogIsVisible(false);
	};
	const onCloseComputerDialog = () => setVisibleComputer(false);

	useEffect(() => {
		if (tradeDirection === Side.LONG) {
			if (tradeType === Trade_Type.Market) {
				dispatch(setTransactionType(Transaction_Type.LongV2));
			} else {
				dispatch(setTransactionType(Transaction_Type.LongLimitOrderV2));
			}
		} else {
			if (tradeType === Trade_Type.Market) {
				dispatch(setTransactionType(Transaction_Type.ShortV2));
			} else {
				dispatch(setTransactionType(Transaction_Type.ShortLimitOrderV2));
			}
		}
	}, [tradeDirection, tradeType]);

	return (
		<Box className='px-4 pt-1 pb-4 flex flex-col overflow-hidden sm:p-0'>
			{visibleComputer && (
				<Computer
					onClose={onCloseComputerDialog}
					pool={currentPool}
					side={tradeDirection}
					back={() => {
						onCloseComputerDialog();
						setDialogIsVisible(true);
					}}
				/>
			)}
			<Dialog
				titleAlign='left'
				title={<Trans>Trade</Trans>}
				open={dialogIsVisible}
				onClose={onHandleCloseForm}
			>
				<Box className='space-y-3 mt-2'>
					<ToggleDirection unselectedComponentVariant='withoutHoverAction' />
					<div className='flex justify-between'>
						<div className='flex items-center'>
							<StyledTabs value={tradeType} onChange={onHandleChangeType}>
								<StyledTab
									className='pl-0'
									label={<Trans>trade.Market</Trans>}
									value={Trade_Type.Market}
								/>

								<StyledTab
									label={<Trans>Limit</Trans>}
									value={Trade_Type.Limit}
								/>
							</StyledTabs>
							<V2Logo className='ml-1' width={18} height={14} />
						</div>
						<IconButton className='-mr-1' onClick={onOpenComputerDialog}>
							<ComputerIcon
								color={theme.mode === App_Theme.dark ? 'white' : 'black'}
							/>
						</IconButton>
					</div>
					{tradeType === Trade_Type.Market ? <MarketForm /> : <LimitForm />}
				</Box>
			</Dialog>
			<div>
				<div className='px-4 md:px-3 sm:hidden flex'>
					{headersProxy.map((header, index) => (
						<Typography
							key={index}
							align={header.align}
							width={header.width}
							variant='body2'
							color='secondary'
							className='py-4'
						>
							{header.label}
						</Typography>
					))}
				</div>
				<div
					ref={ref}
					className={classNames(
						'overflow-auto space-y-3 md:space-y-2 sm:space-y-2 no-scrollbar',
						{
							'flex justify-center h-[300px] items-center':
								filteredPoolList.length === 0
						}
					)}
					style={{
						background: isMatchMobile ? theme.palette.background.default : '',
						maxHeight: 'calc(100vh - 80px)'
					}}
				>
					{filteredPoolList.length === 0 ? (
						isLoadingPositions ? (
							<SectionLoading inline={true} />
						) : (
							<NoData size='large'>
								<Trans>No open positions</Trans>
							</NoData>
						)
					) : (
						filteredPoolList.map((row: IPoolItem, rowIndex: number) => (
							<Box
								key={rowIndex}
								className='rounded flex-col'
								sx={{
									border:
										row.id === poolAddress
											? `1px solid ${theme.custom.ListBorder}`
											: `1px solid ${theme.custom.ListSelectBg}`,
									borderWidth: isMatchMobile ? '0px' : '1px'
								}}
							>
								{/* 统计类数据 */}
								<Box
									className={classNames(
										'relative px-4 md:px-3 sm:px-3 py-2 sm:py-5',
										{
											'cursor-pointer': row.id !== poolAddress,
											rounded: row.id !== poolAddress
										}
									)}
									onClick={() => onHandleSetCurrentPool(row)}
									sx={{
										background: isMatchMobile
											? theme.palette.background.paper
											: row.id === poolAddress
											? theme.custom.ListSelectBg
											: theme.custom.ListBg,
										'&:hover': {
											background:
												row.id !== poolAddress ? theme.custom.ListHoverBg : ''
										}
									}}
								>
									{row.id === poolAddress && (
										<FiberManualRecordIcon
											className='absolute top-2 left-2 sm:hidden'
											sx={{
												width: 10,
												height: 10,
												color: theme.palette.primary.main
											}}
										/>
									)}

									<div className='flex items-center sm:hidden'>
										{headersProxy.map(header => (
											<Typography
												component='div'
												key={header.key}
												width={header.width}
												align={header.align}
												variant='body2'
												color='text.main'
											>
												{header.cellRender(row)}
											</Typography>
										))}
									</div>
									<div className='hidden sm:block'>
										<div className='space-y-4'>
											<div className='flex items-center space-x-3'>
												<div className='flex-1 flex justify-between'>
													{headers[0].cellRender(row)}
													<Typography variant='h6'>
														{toQuoteAmount(row.price, row.baseToken?.precision)}
													</Typography>
												</div>
												<div className='flex justify-end'>
													<StyledPriceChangeContainer value={row.priceChange}>
														{`${
															isPositive(row.priceChange) ? '+' : ''
														}${toPercent(row.priceChange)}`}
													</StyledPriceChangeContainer>
												</div>
											</div>
											<div className='flex items-center justify-between space-x-3'>
												<div className='flex-1 space-y-3'>
													<div className='flex-1 flex gap-1'>
														<DirectionCell
															label={headers[3].label}
															value={headers[3].cellRender(row)}
														/>
														<DirectionCell
															label={headers[4].label}
															value={headers[4].cellRender(row)}
														/>
														<DirectionCell
															label={headers[5].label}
															value={headers[5].cellRender(row)}
															align='right'
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Box>

								{row.id === poolAddress &&
								rowStates[row.id] &&
								rowStates[row.id].expand ? (
									<Paper
										className='sm:hidden'
										sx={{
											height: rowStates[row.id].expand
												? Dom_Size.CHART + 80
												: 'auto'
										}}
									>
										<ChartsPool
											pool={row}
											activeTab={rowStates[row.id].chartType}
											price={row.price}
											onHandleChangeActiveTab={(newTab: number) =>
												onHandleChangeChartType(newTab, rowStates[row.id])
											}
										/>
									</Paper>
								) : null}

								{row.id === poolAddress && (
									<Box
										component='section'
										className={classNames(
											'rounded-none sm:hidden',
											row.id === poolAddress ? 'p-4' : undefined
										)}
										sx={{
											borderTop:
												positionMap && positionMap.has(row.id)
													? '1px solid rgba(255, 255, 255, 0.08)'
													: '0 none'
										}}
									>
										<StyledTabs
											value={rowStates[row.id]?.tabType || Tab_Type.Positions}
											onChange={(event, newType) =>
												onHandleChangeOperationType(newType, rowStates[row.id])
											}
										>
											<StyledTab
												className='pl-0'
												label={
													<div className='space-x-0.5'>
														<span>
															<Trans>Positions</Trans>
														</span>
														{isLogin &&
															positionMap &&
															positionMap.has(row.id) && (
																<span>
																	{formatModuleNumber(
																		positionMap.get(row.id)?.length
																	)}
																</span>
															)}
													</div>
												}
												value={Tab_Type.Positions}
											/>
											<StyledTab
												label={
													<Box className='flex items-center gap-1'>
														<div className='space-x-0.5'>
															<Trans>Orders</Trans>
															{isLogin &&
																createdOrderList &&
																createdOrderList.length > 0 && (
																	<span>
																		{formatModuleNumber(
																			createdOrderList.length
																		)}
																	</span>
																)}
														</div>
														<ExpiredOrderWarning
															createdOrderList={isLogin ? createdOrderList : []}
															positionMap={positionMap}
														/>
													</Box>
												}
												value={Tab_Type.Orders}
											/>
											<StyledTab
												label={<Trans>History</Trans>}
												value={Tab_Type.History}
											/>
										</StyledTabs>
										<CommonStyledDivider type='solid' />
										<div className=' min-h-[20px]'>
											{rowStates[row.id]?.tabType === Tab_Type.Positions &&
												(isLoadingPositions ? (
													<SectionLoading inline={true} />
												) : (
													<PositionsList
														list={
															!isLogin
																? null
																: positionMap && positionMap.has(row.id)
																? positionMap.get(row.id)
																: null
														}
														poolId={row.id}
													/>
												))}

											{rowStates[row.id]?.tabType === Tab_Type.Orders &&
												(isLoadingOrders ? (
													<SectionLoading />
												) : (
													<OpenOrdersListV2
														list={isLogin ? orderList : null}
														poolId={row.id}
														positionMap={positionMap}
														full={false}
														disabledCancelAll={disabledCancelAll}
													/>
												))}
											{rowStates[row.id]?.tabType === Tab_Type.History && (
												<HistoryList targetPoolsId={targetPoolsId} />
											)}
										</div>
									</Box>
								)}
							</Box>
						))
					)}
				</div>
			</div>
			<ViewUpdaterV2
				positionMap={positionMap}
				orderList={orderList}
				orderAlldRecords={orderAlldRecords}
				requestsByHashes={requestsByHashes}
			/>
		</Box>
	);
});
