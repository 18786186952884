/* eslint-disable react/jsx-no-useless-fragment */
import { Box, Paper, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';

import MintHistory from './MintHistory';
import MyConnectorNFT from './MyConnectorNFT';

export default function Connectors() {
	const { isMatchMobile, isMatchPad } = useAppBreakpoints();
	const theme = useTheme();
	return (
		<>
			{isMatchMobile || isMatchPad ? (
				<>
					<MyConnectorNFT />
					<Paper>
						<MintHistory />
					</Paper>
				</>
			) : (
				<Paper>
					<MyConnectorNFT />
					<Divider />
					<MintHistory />
				</Paper>
			)}
		</>
	);
}
