import React from 'react';

import { Trans } from '@lingui/macro';
import { Paper, Typography } from '@mui/material';
import { QUOTE_USD, Version } from 'config/constants';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import {
	div,
	formatLeverage,
	shortenSymbolNative,
	toFormattedPercent,
	toQuoteAmount
} from 'utils';

import Cell from 'components/Common/Cell';
import { CommonStyledDivider } from 'components/Common/Styled';
import SectionLoading from 'components/SectionLoading';

import { settingBaseState } from '../../state/setting/slice';

export default React.memo(function PoolInfo({
	className
}: {
	className?: string;
}) {
	const { poolInfo } = useAppSelector(poolsBaseState);
	const { currentVersion } = useAppSelector(settingBaseState);

	const { currentChainId } = useCurrentChain();
	const { isMatchMobile } = useAppBreakpoints();

	return (
		<Paper className={`p-4 sm:p-2 sm:pt-0 ${className}`}>
			{poolInfo ? (
				<div>
					{!isMatchMobile && (
						<>
							<Typography variant='h6'>
								{`${shortenSymbolNative(
									poolInfo.baseToken,
									currentChainId
								)}/${QUOTE_USD}`}
							</Typography>
							<CommonStyledDivider />
						</>
					)}
					<div>
						{currentVersion === Version.V1 && (
							<Cell
								label={<Trans>Max Leverage</Trans>}
								value={formatLeverage(poolInfo.maxLeveragePerPosition)}
							/>
						)}
						<Cell
							label={<Trans>Average Leverage</Trans>}
							value={formatLeverage(
								div(
									poolInfo.globalPosition.liquidity,
									poolInfo.globalPosition.margin
								)
							)}
						/>

						{/*<Cell label={<Trans>Exit Price</Trans>} value={toQuoteAmount(exitPrice)} />*/}

						<Cell
							label={<Trans>Liquidity</Trans>}
							value={toQuoteAmount(poolInfo.globalLiquidityPosition.liquidity)}
						/>

						<Cell
							label={<Trans>Balance Rate</Trans>}
							value={toFormattedPercent(poolInfo.balanceRate)}
						/>
					</div>
				</div>
			) : (
				<SectionLoading />
			)}
		</Paper>
	);
});
