import { useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { DAYS_YEAR } from 'config/constants';
import { arbitrum } from 'config/constants/connector/chains';
import { EQU_CURRENCY } from 'config/constants/tokens';
import { UNI_GRAPH_ADDRESS } from 'config/env';
import moment from 'moment';

import { useCurrentChain } from 'hooks/useCurrentChain';
import { div, minus, multipliedBy, plus } from 'utils';

import { ARBITRUM_ONE_BLOCKS_ADDRESS } from './constants';
import { useComputeUniPoolAddressesForGraph } from './useComputePoolAddress';

export interface PoolFields {
	id: string;
	feeTier: string;
	liquidity: string;
	sqrtPrice: string;
	tick: string;
	token0: {
		id: string;
		symbol: string;
		name: string;
		decimals: string;
		derivedETH: string;
	};
	token1: {
		id: string;
		symbol: string;
		name: string;
		decimals: string;
		derivedETH: string;
	};
	token0Price: string;
	token1Price: string;
	volumeUSD: string;
	volumeToken0: string;
	volumeToken1: string;
	txCount: string;
	totalValueLockedToken0: string;
	totalValueLockedToken1: string;
	totalValueLockedUSD: string;
}

const GET_UNI_POOLS_V3 = gql`
	query TokenWeb($chain: Chain!, $address: String = null) {
		token(chain: $chain, address: $address) {
			id
			decimals
			name
			chain
			address
			symbol
			standard
			market(currency: USD) {
				id
				totalValueLocked {
					id
					value
					currency
					__typename
				}
				price {
					id
					value
					currency
					__typename
				}
				volume24H: volume(duration: DAY) {
					id
					value
					currency
					__typename
				}
				priceHigh52W: priceHighLow(duration: YEAR, highLow: HIGH) {
					id
					value
					__typename
				}
				priceLow52W: priceHighLow(duration: YEAR, highLow: LOW) {
					id
					value
					__typename
				}
				__typename
			}
			project {
				id
				description
				homepageUrl
				twitterName
				logoUrl
				tokens {
					id
					chain
					address
					__typename
				}
				markets(currencies: [USD]) {
					id
					fullyDilutedValuation {
						id
						value
						currency
						__typename
					}
					marketCap {
						id
						value
						currency
						__typename
					}
					__typename
				}
				__typename
			}
			__typename
		}
	}
`;

const GET_UNI_POOLS_V4 = gql`
	query TopV3Pools(
		$chain: Chain!
		$first: Int!
		$cursor: Float
		$tokenAddress: String
	) {
		topV3Pools(
			first: $first
			chain: $chain
			tokenFilter: $tokenAddress
			tvlCursor: $cursor
		) {
			id
			protocolVersion
			address
			totalLiquidity {
				value
				__typename
			}
			feeTier
			token0 {
				...PoolToken
				__typename
			}
			token1 {
				...PoolToken
				__typename
			}
			txCount
			volume24h: cumulativeVolume(duration: DAY) {
				value
				__typename
			}
			volumeWeek: cumulativeVolume(duration: WEEK) {
				value
				__typename
			}
			__typename
		}
	}

	fragment PoolToken on Token {
		id
		address
		name
		symbol
		chain
		decimals
		project {
			id
			logo {
				id
				url
				__typename
			}
			__typename
		}
		__typename
	}
`;
function useUniInfoClient() {
	const { currentChainId } = useCurrentChain();

	return useMemo(() => {
		return new ApolloClient({
			uri: UNI_GRAPH_ADDRESS[currentChainId],
			cache: new InMemoryCache(),
			defaultOptions: {
				watchQuery: { fetchPolicy: 'no-cache', errorPolicy: 'ignore' },
				query: { fetchPolicy: 'no-cache', errorPolicy: 'all' }
			}
		});
	}, [currentChainId]);
}

const GET_BLOCK_NUMBER = gql`
	query BlockNumbers(
		$timestampBefore: String!
		$timestampBeforeUpper: String!
		$timestamp: String!
		$timestampUpper: String!
	) {
		blocks(first: 1, orderBy: timestamp, orderDirection: desc) {
			timestamp
			number
		}
		blocksBefore: blocks(
			first: 1
			where: {
				timestamp_lte: $timestampBefore
				timestamp_lt: $timestampBeforeUpper
			}
			orderBy: timestamp
			orderDirection: desc
		) {
			timestamp
			number
		}
		blocksBefore7d: blocks(
			first: 1
			where: { timestamp_lte: $timestamp, timestamp_lt: $timestampUpper }
			orderBy: timestamp
			orderDirection: desc
		) {
			timestamp
			number
		}
	}
`;

export function useGetBlockNumbersForUni() {
	const { currentChain, currentChainId } = useCurrentChain();

	const [blockLatest, setBlockLatest] = useState<number>(0);
	const [blockLatestTimestamp, setBlockLatestTimestamp] = useState<number>(0);
	const [blockBefore24h, setBlockBefore24h] = useState<number>(0);
	const [blockBefore7d, setBlockBefore7d] = useState<number>(0);

	const uniBlocksClient = useMemo(() => {
		return new ApolloClient({
			uri: ARBITRUM_ONE_BLOCKS_ADDRESS,
			cache: new InMemoryCache(),
			defaultOptions: {
				watchQuery: { fetchPolicy: 'no-cache', errorPolicy: 'ignore' },
				query: { fetchPolicy: 'no-cache', errorPolicy: 'all' }
			}
		});
	}, [currentChainId]);

	const refechBlockNumbers = () => {
		// if (currentChain.testnet) {
		// 	return;
		// }
		const timeStampBefore24h = moment().subtract(1, 'days').unix().toString();
		const timeStampBefore7d = moment().subtract(7, 'days').unix().toString();
		uniBlocksClient
			.query({
				query: GET_BLOCK_NUMBER,
				variables: {
					timestampBefore: timeStampBefore24h,
					timestampBeforeUpper: plus(timeStampBefore24h, 600),
					timestamp: timeStampBefore7d,
					timestampUpper: plus(timeStampBefore7d, 600)
				}
			})
			.then(result => {
				const blockInfoOfLastest = result.data.blocks[0];
				const blockInfoOfBefore24h = result.data.blocksBefore[0];
				const blockInfoOfBefore7d = result.data.blocksBefore7d[0];

				setBlockLatest(blockInfoOfLastest.number);
				setBlockLatestTimestamp(blockInfoOfLastest.timestamp);

				setBlockBefore24h(Number(blockInfoOfBefore24h.number));
				setBlockBefore7d(Number(blockInfoOfBefore7d.number));
			})
			.catch(error => {
				console.error(`Failed to get block numbers: `, error);
			});
	};

	return {
		blockLatest,
		blockLatestTimestamp,
		blockBefore24h,
		blockBefore7d,
		refechBlockNumbers
	};
}

export function useUniV3Apr() {
	const uniInfoClient = useUniInfoClient();

	const [APR, setAPR] = useState<string>('');
	const [totalTvlUSD, setTotalTvlUSD] = useState<string>('');
	const [volumeUSD24h, setVolumeUSD24h] = useState<string>('');
	const { blockBefore24h, refechBlockNumbers } = useGetBlockNumbersForUni();

	const uniPoolAddresses = useComputeUniPoolAddressesForGraph();

	useUpdateEffect(() => {
		if (!uniPoolAddresses) {
			return;
		}
		uniInfoClient
			.query({
				query: GET_UNI_POOLS_V3,
				variables: {
					address: EQU_CURRENCY[arbitrum.id].address,
					chain: 'ARBITRUM'
				}
			})
			.then(result => {
				if (result) {
					const { token } = result.data;
					if (!token) {
						return;
					}
					const volumeUSD24h = token?.market?.volume24H?.value;
					setVolumeUSD24h(volumeUSD24h);
				}
			})
			.catch(error => {
				console.error(`Failed to get pools v3 info: `, error);
			});
	}, [uniPoolAddresses]);

	useUpdateEffect(() => {
		if (!uniPoolAddresses) {
			return;
		}
		uniInfoClient
			.query({
				query: GET_UNI_POOLS_V4,
				variables: {
					tokenAddress: EQU_CURRENCY[arbitrum.id].address,
					first: 20,
					chain: 'ARBITRUM'
				}
			})
			.then(result => {
				if (result) {
					const { topV3Pools: liquidityPools } = result.data;
					if (!liquidityPools || liquidityPools.length === 0) {
						return;
					}

					const feePercentage = '0.01';
					const tatalFee24h = volumeUSD24h
						? multipliedBy(volumeUSD24h, feePercentage)
						: '0';
					let totalTvlUSD = '0';

					liquidityPools.forEach((current: PoolFields) => {
						const tvlUSD = current
							? parseFloat(current.totalLiquidity.value)
							: 0;
						totalTvlUSD = plus(totalTvlUSD, tvlUSD);
					});

					const APR = div(multipliedBy(tatalFee24h, DAYS_YEAR), totalTvlUSD);
					setAPR(APR);
					setTotalTvlUSD(totalTvlUSD);
				}
			})
			.catch(error => {
				console.error(`Failed to get pools v4 info: `, error);
			});
	}, [volumeUSD24h, uniPoolAddresses]);

	return {
		uniAPR: APR,
		uniTotalTvlUSD: totalTvlUSD,
		refechUniPools: () => {
			refechBlockNumbers();
		}
	};
}
