import { useMemo } from 'react';

import { useApolloClients } from 'context/ApolloClientsContext';
import { useAccount } from 'wagmi';
import { useRefereesAddressQuery } from './__generated_referral__/types-and-hooks';
import { values, groupBy } from 'lodash'

export function useRefereesAddressGraph(token: string[]) {
	const { clientReferral } = useApolloClients();
	const { address } = useAccount();
	const { data, loading, refetch } = useRefereesAddressQuery({
		variables: {
			token: token
		},
		skip: !(token.length > 0),
		client: clientReferral,
		notifyOnNetworkStatusChange: true
	});

	return useMemo(() => {
		if (!data || !data?.referees || !data?.referees?.length) {
			return {
				data: undefined,
				loading,
				refetch
			};
		}

		const list = values(groupBy(data?.referees, 'referralCode.id')).map(group => ({
            address: group.map(item => item.id),
            referralCode: group[0].referralCode.id,
            referralToken: group[0].referralToken.id,
        }));
        
		return {
            list,
			loading,
			refetch
		};
	}, [data, refetch, loading]);
}
