import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Group, Image, Layer, Rect, Stage, Text } from 'react-konva';

import { Trans, t } from '@lingui/macro';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Avatar,
	Box,
	FormControlLabel,
	List,
	ListItem,
	MenuItem,
	Popover,
	Tooltip,
	Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import moment from 'moment/moment';
import { useAccount } from 'wagmi';

import QRCode from 'qrcode';
import useImage from 'use-image';

import Dialog from 'components/Common/Dialog';

import CopyLink from '../../../assets/svg/copy-link.svg';
import ChristmasSvg from '../../../assets/svg/leaderboard/christmas.svg';
import labelBgSvg from '../../../assets/svg/leaderboard/labelBg.svg';
import Illustration from '../../../assets/svg/leaderboard/share-logo.svg';
import UsdtLogo from '../../../assets/svg/leaderboard/usdt.svg';
import Logo from '../../../assets/svg/m-logo.svg';
import SaveImage from '../../../assets/svg/save-image.svg';
import Bg from '../../../assets/svg/share-bg.svg';
import ShareLogo from '../../../assets/svg/share-logo.svg';
import Telegram from '../../../assets/svg/telegram.svg';
import Twitter from '../../../assets/svg/twitter.svg';
import { StyleCheckbox } from '../../../components/Common/StyleCheckbox';
import { APP_TOKEN_SYMBOL } from '../../../config/constants';
import { useAppBreakpoints } from '../../../hooks/useAppBreakpoints';
import useCopyClipboard from '../../../hooks/useCopyClipboard';
import { useAppSelector } from '../../../state/hooks';
import { txBaseState } from '../../../state/tx/slice';
import { shortenAddress, shortenSymbol, toQuoteAmount } from '../../../utils';

const BgImage = props => {
	const [image] = useImage(Bg);
	return <Image {...props} image={image} />;
};
const IllustrationImage = props => {
	const [image] = useImage(Illustration);
	return <Image {...props} image={image} />;
};

const QrImage = props => {
	// eslint-disable-next-line react/prop-types
	const [image] = useImage(props.qrUrl);
	return <Image {...props} image={image} />;
};

const MemberShareDialog = (props: {
	onClose: () => void;
	member: any;
	isAllTime: boolean;
}) => {
	const { onClose, member, isAllTime } = props;

	const { isMatchMobile } = useAppBreakpoints();
	const { address } = useAccount();
	const theme = useTheme();
	const { quoteToken } = useAppSelector(txBaseState);

	const screenWidth = document.documentElement.clientWidth;
	const containerMarginLeft = 24;
	const itemSpace = 8;

	const time = `${moment(member.time)
		.utc()
		.format('MMM D, YYYY HH:mm:ss')} (UTC)`;

	const [shareLogo] = useImage(ShareLogo);
	const [logo] = useImage(Logo);
	const [usdtLogo] = useImage(UsdtLogo);
	const [christmas] = useImage(ChristmasSvg);
	const [labelBg] = useImage(labelBgSvg);

	const feeLabelRef = React.useRef(null);
	const eventLabelRef = React.useRef(null);
	const feeLabelWidth = useMemo(() => {
		let width = 0;
		if (feeLabelRef.current !== null) {
			width = feeLabelRef.current.getTextWidth();
		}
		return width;
	}, [feeLabelRef.current]);
	const eventLabelWidth = useMemo(() => {
		let width = 0;
		if (eventLabelRef.current !== null) {
			width = eventLabelRef.current.getTextWidth();
		}
		return width;
	}, [eventLabelRef.current]);
	const labelContainerWidth = useMemo(() => {
		return Math.max(feeLabelWidth, eventLabelWidth) + 24;
	}, [feeLabelWidth, eventLabelWidth]);

	const rankRef = React.useRef(null);
	const rankWidth = useMemo(() => {
		let width = 0;
		if (rankRef.current !== null) {
			width = rankRef.current.getTextWidth();
		}
		return width;
	}, [rankRef.current]);
	const rankEndX = isAllTime ? rankWidth : rankWidth + containerMarginLeft;

	const pastTimeRef = React.useRef(null);
	const pastTimeWidth = useMemo(() => {
		let width = 0;
		if (isAllTime) {
			return width;
		}

		if (pastTimeRef.current !== null) {
			width = pastTimeRef.current.getTextWidth();
		}
		return width;
	}, [pastTimeRef.current, isAllTime]);
	const pastTimeEndX = rankEndX + itemSpace + pastTimeWidth;

	const stageRef = React.useRef(null);

	const [isAddress, setIsAddress] = useState(true);
	const [isEqu, setIsEqu] = useState(true);
	const [isUsdt, setIsUsdt] = useState(true);

	const [anchorEl, setAnchorEl] = useState(null);
	const [activeCode, setActiveCode] = useState(null);
	const shareUrl = `${document.location.origin}${
		activeCode ? '/referrals/traders?code=' + activeCode : ''
	}`;
	const [qrUrl, setQrUrl] = useState('');
	useEffect(() => {
		QRCode.toDataURL(
			shareUrl,
			{ errorCorrectionLevel: 'L', width: 204, height: 204, margin: 2 },
			function (err, url) {
				if (err) {
					console.log(err);
				}
				if (url) {
					setQrUrl(url);
				}
			}
		);
	}, [shareUrl]);

	const referralCodes = member.referralCodes || [];
	useEffect(() => {
		if (referralCodes.length > 0) {
			setActiveCode(referralCodes[0].id);
		}
	}, [referralCodes]);
	const onSelectCode = (code: string) => {
		setActiveCode(code);
		setAnchorEl(null);
	};
	const onHandleClose = () => setAnchorEl(null);

	const onHandleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const equRewards = member.equ_amount || 0;
	const equRewardsRef = useRef(null);
	const equRewardsWidth = useMemo(() => {
		let width = 0;
		if (equRewardsRef.current !== null) {
			width = equRewardsRef.current.getTextWidth() + 28;
		}
		return width;
	}, [equRewardsRef.current]);
	const equRewardsEndX = containerMarginLeft + equRewardsWidth;

	const usdRewards = member.totalFeeReward || 0;

	const [isCopied, setCopied] = useCopyClipboard();
	const onHandleCopy = () => {
		setCopied(shareUrl);
	};

	const generateRandomNumFour = quantity => {
		let result = '';
		for (let i = 0; i < quantity; i++) {
			result += Math.floor(Math.random() * 10).toString();
		}
		return result;
	};
	const downloadURI = (uri, name) => {
		const link = document.createElement('a');
		link.download = name;
		link.href = uri;
		link.target = '_blank';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};
	const handleExport = () => {
		const uri = stageRef.current.toDataURL({ pixelRatio: 2 });
		downloadURI(uri, `equation_sharing_${generateRandomNumFour(4)}.png`);
	};

	const [stageWidth, stageHeight, scale] = useMemo(() => {
		const width = 400;
		const height = 424;
		const mobilWidth = screenWidth;
		const mobileRate = mobilWidth / width;
		const mobileHeight = mobileRate * height;
		const stageWidth = isMatchMobile ? mobilWidth : width;
		const stageHeight = isMatchMobile ? mobileHeight : height;
		const scale = isMatchMobile ? mobileRate : 1;
		return [stageWidth, stageHeight, scale];
	}, [screenWidth, isMatchMobile]);

	return (
		<Dialog
			open
			title={t`Share`}
			isTitle={false}
			onClose={onClose}
			width={isMatchMobile ? '100%' : 'auto'}
			height={isMatchMobile ? '100%' : 'auto'}
		>
			<Box
				width={stageWidth}
				height={stageHeight}
				className='relative sm:-left-3'
			>
				<Box
					width={stageWidth}
					height={stageHeight}
					className='relative left-0 top-0 z-20'
				></Box>
				<Box className='absolute left-0 top-0 z-10'>
					<Stage
						width={stageWidth}
						height={stageHeight}
						scaleX={scale}
						scaleY={scale}
						ref={stageRef}
					>
						<Layer>
							<Rect x={0} y={0} width={400} height={424} fill='#111111' />
							<Image
								x={containerMarginLeft}
								y={24}
								image={shareLogo}
								height={30}
								width={115}
							/>
							<BgImage x={19.57} y={0} />
							<IllustrationImage x={170} y={240} />
							{time && (
								<Text
									x={245}
									y={32}
									text={time}
									fill='white'
									fontSize={12}
									opacity={0.7}
								/>
							)}
							{/* <Group>
								<Image
									x={stageWidth - labelContainerWidth - 12}
									y={48}
									image={christmas}
									height={46}
									width={46}
									zIndex={100}
								/>
								<Image
									x={stageWidth - labelContainerWidth}
									y={74}
									image={labelBg}
									width={labelContainerWidth}
									height={42}
								/>

								<Text
									x={stageWidth - feeLabelWidth - 8}
									y={82}
									text={t`0 Trading Fee`}
									fontFamily='Noto Sans'
									fill={theme.palette.primary.main}
									fontSize={12}
									ref={feeLabelRef}
								/>
								<Text
									x={stageWidth - eventLabelWidth - 8}
									y={98}
									text={t`Event is Underway`}
									fill={theme.palette.primary.main}
									fontSize={12}
									fontFamily='Noto Sans'
									ref={eventLabelRef}
								/>
							</Group> */}
							{isAddress && (
								<Group>
									<Rect
										x={containerMarginLeft}
										y={102}
										width={108}
										height={24}
										cornerRadius={4}
										fillLinearGradientStartPoint={{ x: 0, y: 0 }}
										fillLinearGradientEndPoint={{ x: 132, y: 24 }}
										fillLinearGradientColorStops={[
											0,
											'#0E2E0E',
											0.96,
											'#1C362B',
											1,
											'#1C362B'
										]}
									/>

									<Text
										x={36}
										y={109}
										text={shortenAddress(address || '')}
										fill='white'
										fontSize={12}
										opacity={0.9}
										fontFamily='Noto Sans'
									/>
								</Group>
							)}

							<Group>
								<Text
									x={containerMarginLeft}
									y={174}
									text={t`Referral Rank`}
									fill='white'
									fontFamily='Noto Sans'
									fontSize={16}
									opacity={0.9}
									ref={rankRef}
								/>
								{!isAllTime && (
									<>
										<Rect
											x={rankEndX + itemSpace}
											y={170}
											width={pastTimeWidth + 16}
											height={22}
											cornerRadius={4}
											fillLinearGradientStartPoint={{ x: 0, y: 0 }}
											fillLinearGradientEndPoint={{ x: 132, y: 24 }}
											// background: linear-gradient(270.19deg, #1C362B 3.82%, #0E2E0E 98.39%);
											fillLinearGradientColorStops={[
												0,
												'#0E2E0E',
												0.96,
												'#1C362B',
												1,
												'#1C362B'
											]}
										/>
										<Text
											x={rankEndX + itemSpace * 2}
											y={175}
											text={t`7 Days`}
											fontFamily='Noto Sans'
											fill={theme.palette.success.main}
											fontSize={14}
											ref={pastTimeRef}
										/>
									</>
								)}
								<Text
									x={pastTimeEndX + itemSpace * 4}
									y={140}
									text={member.rank}
									fill={theme.palette.primary.main}
									fontSize={64}
									fontFamily='Noto Sans'
									fontStyle='600'
								/>

								{(isEqu || isUsdt) && (
									<Text
										x={24}
										y={216}
										text={t`Rewards`}
										fontFamily='Noto Sans'
										fill='#8B919D'
										fontSize={14}
									/>
								)}

								{/* 页面上不显示，仅仅为了计算宽度 */}
								<>
									<Text
										x={containerMarginLeft}
										y={242}
										text={`${toQuoteAmount(equRewards)} ${APP_TOKEN_SYMBOL}`}
										fontFamily='Noto Sans'
										fill='white'
										fontSize={20}
										opacity={0}
										ref={equRewardsRef}
									/>
									<Text
										x={equRewardsEndX}
										y={242}
										text={`${toQuoteAmount(usdRewards)} ${shortenSymbol(
											quoteToken
										)}`}
										fontFamily='Noto Sans'
										fill='white'
										fontSize={20}
										opacity={0}
									/>
								</>

								{isEqu && isUsdt ? (
									<>
										<Text
											x={containerMarginLeft}
											y={242}
											text={`${toQuoteAmount(equRewards)} ${APP_TOKEN_SYMBOL}`}
											fontFamily='Noto Sans'
											fill='white'
											fontSize={20}
											opacity={0.9}
										/>
										<Text
											x={equRewardsEndX}
											y={242}
											text={`${toQuoteAmount(usdRewards)} ${shortenSymbol(
												quoteToken
											)}`}
											fontFamily='Noto Sans'
											fill='white'
											fontSize={20}
											opacity={0.9}
										/>
									</>
								) : (
									<>
										{isEqu && (
											<Text
												x={containerMarginLeft}
												y={242}
												text={`${toQuoteAmount(
													equRewards
												)} ${APP_TOKEN_SYMBOL}`}
												fontFamily='Noto Sans'
												fill='white'
												fontSize={20}
												opacity={0.9}
											/>
										)}
										{isUsdt && (
											<Text
												x={containerMarginLeft}
												y={242}
												text={`${toQuoteAmount(usdRewards)} ${shortenSymbol(
													quoteToken
												)}`}
												fontFamily='Noto Sans'
												fill='white'
												fontSize={20}
												opacity={0.9}
											/>
										)}
									</>
								)}
							</Group>
							{activeCode && (
								<Group>
									<Text
										x={82}
										y={350}
										text={t`Referral Code`}
										fontFamily='Noto Sans'
										fill='#8B919D'
										fontSize={12}
									/>
									<Text
										x={82}
										y={369}
										text={activeCode}
										fontFamily='Noto Sans'
										fill='white'
										fontSize={14}
										opacity={0.9}
									/>
								</Group>
							)}
							<Text
								x={82}
								y={390}
								text={t`Scan to Explore`}
								fontFamily='Noto Sans'
								fill='white'
								fontSize={12}
							/>
							{qrUrl && (
								<QrImage width={50} height={50} x={24} y={350} qrUrl={qrUrl} />
							)}
						</Layer>
					</Stage>
				</Box>
			</Box>
			<Typography className='mt-5' sx={{ fontSize: 12 }}>
				<Trans>Choose whether to share the following information:</Trans>
			</Typography>
			<Box className='mt-2'>
				<FormControlLabel
					className='mr-5'
					control={
						<StyleCheckbox
							className='py-0 -mr-1'
							onChange={event => {
								setIsAddress(event.target.checked);
							}}
							size='small'
							checked={isAddress}
						/>
					}
					label={<Trans>Address</Trans>}
					sx={{ '.MuiTypography-root': { fontSize: 12 } }}
				/>
				<FormControlLabel
					className='mr-5'
					control={
						<StyleCheckbox
							className='py-0 -mr-1'
							onChange={event => {
								setIsEqu(event.target.checked);
							}}
							size='small'
							checked={isEqu}
						/>
					}
					label={<Trans>EQU Rewards</Trans>}
					sx={{ '.MuiTypography-root': { fontSize: 12 } }}
				/>
				<FormControlLabel
					className='mr-5'
					control={
						<StyleCheckbox
							className='py-0 -mr-1'
							onChange={event => {
								setIsUsdt(event.target.checked);
							}}
							size='small'
							checked={isUsdt}
						/>
					}
					label={<Trans>USDT Rewards</Trans>}
					sx={{ '.MuiTypography-root': { fontSize: 12 } }}
				/>
			</Box>
			<Box className='flex mt-5 space-x-8'>
				<Box
					className='flex flex-col items-center cursor-pointer'
					onClick={onHandleCopy}
				>
					<Tooltip
						title={<Trans>Copied</Trans>}
						placement='top'
						open={isCopied}
						arrow
					>
						<Avatar
							alt='Copy Link'
							src={CopyLink}
							sx={{ width: 32, height: 32 }}
						/>
					</Tooltip>
					<Typography className='mt-1' sx={{ fontSize: 12 }}>
						<Trans>Copy link</Trans>
					</Typography>
				</Box>
				{!isMatchMobile && (
					<Box
						className='flex flex-col items-center cursor-pointer'
						onClick={handleExport}
					>
						<Avatar
							alt='Save Image'
							src={SaveImage}
							sx={{ width: 32, height: 32 }}
						/>
						<Typography className='mt-1' sx={{ fontSize: 12 }}>
							<Trans>Save image</Trans>
						</Typography>
					</Box>
				)}

				<a
					href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${t`Equation - The Next Generation of Decentralized Perpetual Exchange 
						Up to 200x leverage. No sign up or deposit required. Equation is set to revolutionize the experience of perpetual trading and the future of DeFi.
						`}`}
					target='_blank'
					rel='noopener noreferrer'
				>
					<Box className='flex flex-col items-center cursor-pointer'>
						<Avatar
							alt='Twitter'
							src={Twitter}
							sx={{ width: 32, height: 32 }}
						/>
						<Typography className='mt-1' sx={{ fontSize: 12 }}>
							<Trans>Twitter</Trans>
						</Typography>
					</Box>
				</a>
				<a
					href={`https://t.me/share/url?url=${shareUrl}`}
					target='_blank'
					rel='noopener noreferrer'
				>
					<Box className='flex flex-col items-center'>
						<Avatar
							alt='Telegram'
							src={Telegram}
							sx={{ width: 32, height: 32 }}
						/>
						<Typography className='mt-1' sx={{ fontSize: 12 }}>
							<Trans>Telegram</Trans>
						</Typography>
					</Box>
				</a>
			</Box>
			<Box className='mt-5'>
				{referralCodes.length > 0 && (
					<Box className='flex items-center'>
						<Typography sx={{ fontSize: 12 }} className='mr-1'>
							<Trans>Referral Code:</Trans>
						</Typography>
						<List component='nav' aria-label='Device settings'>
							<ListItem
								id='lock-button'
								aria-haspopup='listbox'
								aria-controls='lock-menu'
								aria-label='when device is locked'
								aria-expanded={open ? 'true' : undefined}
								onClick={onHandleClickListItem}
								className='p-0'
							>
								<Box
									className='flex '
									sx={{
										borderRadius: '4px',
										padding: '4px 12px',
										background: theme.custom.selectBg,
										fontSize: '12px',
										fontWeight: isMatchMobile ? 400 : 500,
										color: theme.palette.text.primary,
										'&:hover': {
											background: theme.palette.action.hover
										}
									}}
								>
									<Box className='flex items-center justify-between cursor-pointer sm:space-x-0 w-[116px] sm:w-[126px]'>
										{activeCode}
										{anchorEl ? (
											isMatchMobile ? (
												<ArrowDropUpIcon />
											) : (
												<ExpandLessIcon />
											)
										) : isMatchMobile ? (
											<ArrowDropDownIcon />
										) : (
											<ExpandMoreIcon />
										)}
									</Box>
								</Box>
							</ListItem>
						</List>
						<Popover
							open={anchorEl}
							anchorEl={anchorEl}
							className={`${
								isMatchMobile ? '-translate-y-1' : 'translate-y-1'
							} transform`}
							onClose={onHandleClose}
							anchorOrigin={{
								vertical: isMatchMobile ? 'top' : 'bottom',
								horizontal: 'center'
							}}
							transformOrigin={{
								vertical: isMatchMobile ? 'bottom' : 'top',
								horizontal: 'center'
							}}
						>
							<Box className='overflow-y-auto max-h-52'>
								{referralCodes.map(item => {
									return (
										<MenuItem
											className={`flex ${
												referralCodes.length < 5 ? 'w-[140px]' : 'w-[134px]'
											} cursor-pointer items-center justify-between sm:w-[150px]`}
											component='div'
											key={item.id}
											onClick={() => onSelectCode(item.id)}
											sx={{ paddingRight: '5px' }}
										>
											<Typography
												variant='inherit'
												className='truncate'
												color={
													item.id === activeCode
														? 'text.primary'
														: 'text.secondary'
												}
											>
												{item.id}
											</Typography>

											<DoneRoundedIcon
												fontSize='small'
												className={classNames(' font-bold', {
													'opacity-0': item.id !== activeCode
												})}
												sx={{ color: theme.palette.text.primary }}
											/>
										</MenuItem>
									);
								})}
							</Box>
						</Popover>
					</Box>
				)}
			</Box>
		</Dialog>
	);
};

export default MemberShareDialog;
