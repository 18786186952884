import { useContext, useEffect } from 'react';

import { Order_Status_V2, Side, Transaction_Type } from 'config/constants';
import { TradeContext } from 'context/TradeContext';
import { IOrderItem } from 'fetch/useMyOrdersFetch';
import { IPositionItem } from 'graphql/useMyPositionsGraph';

import { TransactionRecord } from 'entities/TransactionRecord';
import { useManageTransactions } from 'hooks/useAccountTransactions';
import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { txBaseState } from 'state/tx/slice';
import { IRequestItem } from 'types';
import { isLocalStorageFull } from 'utils';
import { intersectionWith } from 'lodash';

export default function ViewUpdaterV2({
	positionMap,
	orderList,
	requestsByHashes,
	orderAlldRecords
}: {
	positionMap: Map<string, Array<IPositionItem>> | null;
	orderList: Array<IOrderItem> | null;
	requestsByHashes: Array<IRequestItem> | undefined;
	orderAlldRecords: Array<TransactionRecord>;
}) {
	const { transactionType } = useAppSelector(txBaseState);
	const { poolInfo } = useAppSelector(poolsBaseState);
	const { setPositionInfo } = useContext(TradeContext);

	const { removeTransaction } = useManageTransactions();

	// 将当前操作的仓位放入全局
	useEffect(() => {
		if (!poolInfo || !positionMap) {
			setPositionInfo(null);
			return;
		}
		const currentPositionList = positionMap.get(poolInfo.id);
		if (!currentPositionList) {
			setPositionInfo(null);
			return;
		}
		const target = currentPositionList.find(item => {
			return (
				((	transactionType === Transaction_Type.Long ||
					transactionType === Transaction_Type.LongV2 ||
					transactionType === Transaction_Type.LongLimitOrderV2) &&
					item.side === Side.LONG) ||
				((	transactionType === Transaction_Type.Short ||
					transactionType === Transaction_Type.ShortV2 ||
					transactionType === Transaction_Type.ShortLimitOrderV2) &&
					item.side === Side.SHORT)
			);
		});
		setPositionInfo(target || null);
	}, [positionMap, poolInfo, transactionType]);

	useEffect(() => {
		if (
			!orderList ||
			!requestsByHashes ||
			requestsByHashes.length === 0 ||
			!orderAlldRecords ||
			orderAlldRecords.length === 0
		) {
			return;
		}

		// 创建
		let results = intersectionWith(
			orderAlldRecords,
			requestsByHashes,
			(txRecord, request) => {
				return (
					request.status === Order_Status_V2.Created &&
					txRecord.hash === request.createdHash
				);
			}
		);

		// 更新
		results = results.concat(intersectionWith(
			orderAlldRecords,
			requestsByHashes,
			(txRecord, request) => {
				return (
					request.status === Order_Status_V2.Created &&
					txRecord.hash === request.updatedHash
				);
			}
		));

		// 取消或者其他
		results = results.concat(intersectionWith(
			orderAlldRecords,
			requestsByHashes,
			(txRecord, request) => {
				return (
					request.status === Order_Status_V2.Cancelled &&
					txRecord.hash === request.cancelledHash 
				);
			}
		));

		//其他
		results = results.concat(intersectionWith(
			orderAlldRecords,
			requestsByHashes,
			(txRecord, request) => {
				return (
					request.status !== Order_Status_V2.Created &&
					request.status !== Order_Status_V2.Cancelled &&
					txRecord.hash === request.executedHash 
				);
			}
		));
		
		results.forEach((item: TransactionRecord) => {
			item.syncing = false;
			if (!isLocalStorageFull()) {
				removeTransaction(item);
			} else {
				console.warn('isLocalStorageFull: true !!!');
			}
		});
	}, [orderList, requestsByHashes, orderAlldRecords]);

	return null;
}
