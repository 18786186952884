import { useCallback, useMemo, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { Box, Typography, useTheme } from '@mui/material';
import { Profit_Loss_Type, QUOTE_USD } from 'config/constants';

import { IPositionItem } from 'graphql/useMyPositionsGraph';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { IPoolItem } from 'types';
import { isPositive, shortenSymbolNative, toQuoteAmount } from 'utils';

import StyledTab from 'components/Common/StyledTab';
import StyledTabs from 'components/Common/StyledTabs';
import LongShortTag from 'components/LongShortTag';

import Dialog from '../../../components/Common/Dialog';
import { CommonStyledDivider } from '../../../components/Common/Styled';
import ProfitLossForm from './ProfitLossForm';

const ProfitLossDialog = ({
	item,
	onClose
}: {
	item: IPositionItem;
	onClose: () => void;
}) => {
	const { poolMap } = useAppSelector(poolsBaseState);
	const theme = useTheme();
	const [type, setType] = useState<Profit_Loss_Type>(Profit_Loss_Type.Entire);

	const marketPrice = useMemo(() => {
		const currentPool = poolMap.get(item.poolId) as IPoolItem;
		return currentPool.marketPrice;
	}, [poolMap, item]);

	const onHandleChangeType = useCallback(
		(event: React.SyntheticEvent, newValue: Profit_Loss_Type) => {
			setType(newValue);
		},
		[]
	);

	const { currentChainId } = useCurrentChain();

	return (
		<Dialog open onClose={onClose} title={t`TP/SL`}>
			<article>
				<LongShortTag
					side={item.side}
					baseSymbol={shortenSymbolNative(item.baseToken, currentChainId)}
					quoteSymbol={QUOTE_USD}
					leverage={item.leverage as string}
					margin={item.netMargin}
					type='base'
				/>
				<Box
					className='grid grid-cols-2 gap-0.5 mt-2'
					sx={{ color: theme.palette.text.secondary }}
				>
					<Box className=''>
						<Typography variant='body2'>
							<Trans>Entry Price</Trans>:{' '}
							{toQuoteAmount(item.entryPrice, item?.baseToken.precision)}
						</Typography>
					</Box>
					<Box className='text-right'>
						<Typography variant='body2'>
							<Trans>Market Price</Trans>:{' '}
							<Typography
								variant='body2'
								component='span'
								fontWeight={500}
								color={theme.palette.text.primary}
							>
								{toQuoteAmount(marketPrice, item?.baseToken.precision)}
							</Typography>
						</Typography>
					</Box>
					<Box className='col-span-2'>
						<Typography variant='body2'>
							<Trans>Liq. Price</Trans>:{' '}
							{isPositive(item.liqPrice)
								? toQuoteAmount(item.liqPrice, item?.baseToken.precision)
								: '-'}
						</Typography>
					</Box>
				</Box>
				{/* <PositionCells item={item} quantity={quantity} /> */}
				<CommonStyledDivider className='my-5' />
				<StyledTabs
					value={type}
					onChange={onHandleChangeType}
					sx={{ backgroundColor: 'transparent' }}
				>
					<StyledTab
						className='pl-0'
						label={<Trans>Entire Position</Trans>}
						value={Profit_Loss_Type.Entire}
					/>

					<StyledTab
						label={<Trans>Partial Position</Trans>}
						value={Profit_Loss_Type.Partial}
					/>
				</StyledTabs>
				<Box className='mt-5'>
					<ProfitLossForm item={item} onClose={onClose} type={type} />
				</Box>
			</article>
		</Dialog>
	);
};

export default ProfitLossDialog;
