import { useCallback, useEffect, useMemo, useState } from 'react';

import { TransactionReceipt, Web3Provider } from '@ethersproject/providers';
import { Hash, SendTransactionResult } from '@wagmi/core';
import { Transaction_Status } from 'config/constants';
import { useSnackbar } from 'notistack';
import { UseContractWriteConfig, useAccount, useContractWrite } from 'wagmi';

import { BigNumber } from 'ethers';
import { isArray } from 'lodash';
import {
	removeSigning,
	setTxVisibleError,
	setTxVisibleSuccess,
	updateSigning
} from 'state/tx/slice';
import useSWRImmutable from 'swr/immutable';
import { BaseRecord } from 'types';
import { formatError } from 'utils';

import { TransactionRecord } from '../entities/TransactionRecord';
import { globalBaseState } from '../state/global/slice';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { useManageTransactions } from './useAccountTransactions';

export function useSendTransaction(
	contractWriteArgs: UseContractWriteConfig | null,
	gasLimit: BigNumber | string | undefined | null,
	directly = false
) {
	const dispatch = useAppDispatch();

	const { chain } = useAppSelector(globalBaseState);
	const { connector } = useAccount();
	const { enqueueSnackbar } = useSnackbar();

	// show Snackbar contractWrite success
	const showSnackbar = useCallback(
		(
			description: string,
			txHash: Hash | undefined,
			type: Transaction_Status,
			more?: string
		) => {
			enqueueSnackbar('ready', {
				variant: 'TransactionSnackbar',
				description,
				txHash,
				type,
				more
			});
			if (type === Transaction_Status.Success) {
				dispatch(setTxVisibleSuccess(true));
			} else if (type === Transaction_Status.Error) {
				dispatch(setTxVisibleError(true));
			}
		},
		[enqueueSnackbar]
	);

	const [isConfirming, setIsConfirming] = useState<boolean>(false);
	const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
	const [currentRecord, setCurrentRecord] = useState<
		BaseRecord | Array<BaseRecord> | null
	>(null);
	const [error, setError] = useState<Error | null | undefined>(null);
	const [description, setDescription] = useState<string | Array<string>>('');

	const [txSuccessCallback, setTxSuccessCallback] = useState<
		(current?: TransactionRecord, receipt?: TransactionReceipt) => () => void
	>(() => () => undefined);

	const { data: provider } = useSWRImmutable(
		connector && ['web3-library', connector, chain],
		async () => {
			const provider = await connector?.getProvider();
			return new Web3Provider(provider, 'any');
		}
	);
	const { addTransaction, updateTransaction, removeTransaction } =
		useManageTransactions();

	useEffect(() => {
		if (directly) {
			setIsConfirming(false);
			setIsConfirmed(false);
		}
	}, [directly]);

	const updateTx = (txData: SendTransactionResult) => {
		if (!txData?.hash || !provider || !currentRecord) {
			return;
		}

		let records: Array<BaseRecord>;
		if (!isArray(currentRecord)) {
			records = [currentRecord];
		} else {
			records = currentRecord;
		}

		let descriptions: Array<string>;
		if (!isArray(description)) {
			descriptions = [description];
		} else {
			descriptions = description;
		}

		records.forEach((record: BaseRecord, index: number) => {
			const transactionRecord = new TransactionRecord(
				{
					hash: txData?.hash,
					description: descriptions[index],
					status: Transaction_Status.Pending,
					type: record?.transactionType,
					date: new Date(),
					record,
					syncing: true
				},
				provider,
				(current, receipt) => {
					if (receipt?.status !== 0) {
						setIsConfirming(false);
						setIsConfirmed(true);
						txSuccessCallback && txSuccessCallback(current, receipt);
						showSnackbar(current.description, current.hash, current.status);
					}
					if (current.status === Transaction_Status.Error) {
						setError(current.error);
						setIsConfirming(false);
						removeTransaction(current);
						dispatch(removeSigning(formatRecord(currentRecord)));
						showSnackbar(formatError(current.error), current.hash, current.status);
					}
					setTxSuccessCallback(() => () => undefined);
					updateTransaction(current);
				}
			);
			addTransaction(transactionRecord);
		});
	};

	const overrides = useMemo(() => {
		if (!contractWriteArgs) return undefined;
		if (!gasLimit) {
			// console.log('no gas !!!!!');
			return {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				...contractWriteArgs.overrides
			};
		}
		// console.log('gasLimit: ', gasLimit.toString());
		return {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			...contractWriteArgs.overrides,
			gasLimit
		};
	}, [contractWriteArgs, gasLimit]);

	const { write } = useContractWrite({
		...(contractWriteArgs as UseContractWriteConfig),
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		overrides,
		enabled: !contractWriteArgs,
		onMutate: data => {
			console.log('[onMutate]: ', data);
		},
		onSettled(data) {
			if (data) {
				dispatch(removeSigning(formatRecord(currentRecord)));
				setIsConfirming(true);
				updateTx(data);
			}
		},
		onSuccess(data) {
			console.log('[onSuccess] data', data);
		},
		onError(error) {
			setError(error);
			if (error) {
				const errorMessage = formatError(error, chain);
				let more = error.message;
				if (errorMessage === error.message || error?.code === 4001) {
					more = '';
				}
				showSnackbar(errorMessage, undefined, Transaction_Status.Error, more);
			}

			dispatch(removeSigning(formatRecord(currentRecord)));
		}
	});

	const onSendTransaction = (
		record: BaseRecord | Array<BaseRecord>,
		description: string | Array<string>
	) => {
		if (write) {
			write();
			setCurrentRecord(record);
			dispatch(updateSigning(formatRecord(record)));
			setDescription(description);
			setError(null);
		}
	};

	return {
		isConfirming,
		isConfirmed,
		onSendTransaction,
		setTxSuccessCallback,
		error
	};
}

function formatRecord(record: BaseRecord | Array<BaseRecord> | null) {
	if (isArray(record)) {
		return record[0];
	}
	return record;
}
