import React, { useEffect, useMemo, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import ArrowDownIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';

import { sortBy } from 'lodash';
import { settingBaseState } from 'state/setting/slice';

import NoData from 'components/NoData';

import Dialog from '../../../components/Common/Dialog';
import StyledLink from '../../../components/Common/StyledLink';
import SectionLoading from '../../../components/SectionLoading';
import {
	MAX_AMOUNT_FORMATTER_LIMIT,
	QUOTE_USD,
	Side,
	Version
} from '../../../config/constants';
import { useApolloClients } from '../../../context/ApolloClientsContext';
import { useUserTransactionQueryQuery } from '../../../graphql/__generated_stats__/types-and-hooks';
import usePositionList from '../../../graphql/usePositionList';
import { useAllTokens } from '../../../hooks/useAllTokens';
import { useCurrentChain } from '../../../hooks/useCurrentChain';
import { globalBaseState } from '../../../state/global/slice';
import { useAppSelector } from '../../../state/hooks';
import {
	ExplorerDataType,
	amountFormatter,
	formatNumber,
	getExplorerLink,
	isEqualTo,
	isGreaterThan,
	plus,
	shortenAddress,
	shortenSymbolNative,
	toQuoteAmount
} from '../../../utils';

const PositionValueDialog = (props: {
	onClose: () => void;
	accounts: string[];
	blockNumber?: number | string;
}) => {
	const { onClose, accounts, blockNumber } = props;
	const { currentChainId } = useCurrentChain();
	const allTokens = useAllTokens();
	const { currentChain: chain } = useCurrentChain();
	const { appTimer } = useAppSelector(globalBaseState);
	const { clientStats, clientStatsV2 } = useApolloClients();
	const theme = useTheme();
	const { blockHeight } = useAppSelector(globalBaseState);
	const { currentVersion } = useAppSelector(settingBaseState);

	const [expandAddress, setExpandAddress] = useState<string | null>(null);
	const {
		positionMap,
		loading: positionLoading,
		refetch
	} = usePositionList(accounts, Number(blockNumber || blockHeight));

	const bgColor =
		theme.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)';

	const { data: userDataV1, loading: userLoadingV1 } =
		useUserTransactionQueryQuery({
			variables: {
				account_in: accounts,
				number: Number(blockHeight)
			},
			client: clientStats,
			skip: currentVersion === Version.V2
		});

	const { data: userDataV2, loading: userLoadingV2 } =
		useUserTransactionQueryQuery({
			variables: {
				account_in: accounts,
				number: Number(blockHeight)
			},
			client: clientStatsV2,
			skip: currentVersion === Version.V1
		});

	const [userData, userLoading] = useMemo(() => {
		let userData, loading;
		if (currentVersion === Version.V1) {
			userData = userDataV1;
			loading = userLoadingV1;
		} else {
			userData = userDataV2;
			loading = userLoadingV2;
		}

		return [userData, loading];
	}, [userDataV1, userLoadingV1, userDataV2, userLoadingV2, currentVersion]);

	const [userDataMap, setUserDataMap] = useState(new Map());
	useEffect(() => {
		const map = new Map();
		if (!userData) {
			return;
		}
		userData.users.forEach(user => {
			map.set(user.id, user.userTransactionStatistics);
		});
		setUserDataMap(map);
	}, [userData]);

	const [loading, setLoading] = useState(true);
	useEffect(() => {
		if (!userLoading && !positionLoading) {
			setLoading(false);
		}
	}, [positionLoading, userLoading]);

	useEffect(() => {
		refetch();
	}, [appTimer]);

	const list = useMemo(() => {
		return Array.from(positionMap.keys())
			.map(item => {
				const positions = positionMap.get(item) || [];
				const userTransactionStatistics = userDataMap.get(item) || [];
				const vol = userTransactionStatistics.reduce(
					(prev, cur) => plus(prev, cur.volumeUSD),
					'0'
				);

				return {
					account: item,
					positions: sortBy(positions, item => item.token.sort),
					positionValue: positions.reduce(
						(prev, cur) => plus(prev, cur.liquidity),
						'0'
					),
					vol
				};
			})
			.sort((a, b) => {
				if (!isEqualTo(a.positionValue, b.positionValue)) {
					return isGreaterThan(a.positionValue, b.positionValue) ? -1 : 1;
				}
				return isGreaterThan(a.vol, b.vol) ? -1 : 1;
			});
	}, [positionMap, userDataMap]);

	useEffect(() => {
		if (list.length > 0) {
			setExpandAddress(list[0].account);
		}
	}, [list.length]);

	const outerColumns = [
		{
			key: 'address',
			label: <Trans>Address</Trans>,
			className: 'flex-2 flex items-center',
			format: (row: (typeof list)[number]) => {
				return (
					<StyledLink
						target='_blank'
						href={getExplorerLink(
							chain,
							row.account || '',
							ExplorerDataType.ADDRESS
						)}
						className='underline'
						type='white'
					>
						{shortenAddress(row.account)}
					</StyledLink>
				);
			}
		},
		{
			key: 'vol',
			className: 'flex justify-end items-center text-right flex-3',
			label: <Trans>Total Trading Volume</Trans>,
			format: (row: (typeof list)[number]) => {
				return (
					<Typography variant='body2'>{toQuoteAmount(row.vol)}</Typography>
				);
			}
		},
		{
			key: 'value',
			className: 'flex justify-end items-center text-right flex-3',
			label: <Trans>Total Position Value</Trans>,
			format: (row: (typeof list)[number]) => {
				const isExpand = expandAddress === row.account;
				return (
					<div className='flex items-center'>
						<Typography variant='body2'>
							{toQuoteAmount(row.positionValue)}
						</Typography>
						<Typography color='text.secondary' component='span'>
							<ArrowDownIcon
								className={classNames(isExpand ? 'rotate-180' : 'rotate-0')}
							/>
						</Typography>
					</div>
				);
			}
		}
	];
	const innerColumns = [
		{
			key: 'market',
			width: '30%',
			label: <Trans>Market</Trans>,
			className: 'flex-1.5',
			format: row => {
				const token = allTokens.get(row.pool.token.id);
				return (
					<Typography variant='body2'>
						{`${shortenSymbolNative(token, currentChainId)}/${QUOTE_USD}`}
					</Typography>
				);
			}
		},
		{
			key: 'side',
			width: '30%',
			label: <Trans>Side</Trans>,
			className: 'flex-1.5',
			format: row => {
				return (
					<Typography
						variant='body2'
						color={row.side === Side.SHORT ? 'error.main' : 'success.main'}
					>
						{row.side === Side.SHORT ? t`Short` : t`Long`}
					</Typography>
				);
			}
		},
		{
			key: 'size',
			width: '30%',
			label: <Trans>Size</Trans>,
			className: 'flex-1.5',
			format: row => {
				const token = allTokens.get(row.pool.token.id);
				return (
					<Typography variant='body2'>
						{amountFormatter(
							row.size,
							2,
							token.positionUnits,
							MAX_AMOUNT_FORMATTER_LIMIT
						)}{' '}
						{shortenSymbolNative(token, currentChainId)}
					</Typography>
				);
			}
		},
		{
			key: 'coefficient',
			width: '30%',
			label: <Trans>Coefficient</Trans>,
			className: 'flex flex-1 justify-end',
			format: row => {
				return (
					<Typography variant='body2'>
						{`${formatNumber(row.coefficient, 2, true)}x`}
					</Typography>
				);
			}
		}
	];

	return (
		<Dialog
			open
			width={600}
			className='px-0 sm:px-3'
			onClose={onClose}
			title={t`Referral Details`}
		>
			{loading && <SectionLoading />}
			{!loading &&
				(list.length > 0 ? (
					<div className='px-6 sm:px-0'>
						<div className='mb-3 flex gap-4'>
							{outerColumns.map(column => (
								<div className={column.className} key={column.key}>
									<Typography
										color={theme.palette.text.secondary}
										variant='body2'
										component='div'
									>
										{column.label}
									</Typography>
								</div>
							))}
						</div>
						<Box className='space-y-2'>
							{list.map(item => {
								return (
									<div key={item.account}>
										<Box
											className='flex cursor-pointer gap-4 p-3'
											sx={{
												background: bgColor,
												borderRadius: '4px'
											}}
											onClick={_ => setExpandAddress(item.account)}
										>
											{outerColumns.map(column => (
												<div className={column.className} key={column.key}>
													<Typography variant='body2' component='div'>
														{column.format(item)}
													</Typography>
												</div>
											))}
										</Box>
										{expandAddress === item.account && (
											<Box
												className='mt-1 space-y-2'
												sx={{
													background: bgColor,
													borderRadius: '0 0 4px 4px'
												}}
											>
												{item.positions.length !== 0 ? (
													<div className='overflow-hidden'>
														<div className='flex pt-3 px-3'>
															{innerColumns.map(column => (
																<Typography
																	key={column.key}
																	className={column.className}
																	color={theme.palette.text.secondary}
																	variant='body2'
																	component='div'
																>
																	{column.label}
																</Typography>
															))}
														</div>
														<div className='overflow-auto max-h-[250px] py-2 px-3'>
															{item.positions.map(item => (
																<div
																	className='flex'
																	key={`${item?.pool?.id}-${item.side}`}
																>
																	{innerColumns.map(column => (
																		<Typography
																			key={column.key}
																			className={column.className}
																			variant='body2'
																			component='div'
																		>
																			{column.format(item)}
																		</Typography>
																	))}
																</div>
															))}
														</div>
													</div>
												) : (
													<NoData className='p-3'>
														<Trans>No open positions</Trans>
													</NoData>
												)}
											</Box>
										)}
									</div>
								);
							})}
						</Box>
					</div>
				) : (
					<NoData size='large'>
						<Trans>Your referees currently have no positions yet.</Trans>
					</NoData>
				))}
		</Dialog>
	);
};

export default PositionValueDialog;
