import { useTheme } from '@mui/material';

interface SVGProps {
	size?: number;
	fill?: string;
}

function Edit({ size = 14, fill }: SVGProps) {
	const theme = useTheme();
	const color = fill ? fill : theme.custom.reverseColor;
	return (
		<svg
			width={size}
			height={size}
			viewBox='0 0 14 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_744_16677)'>
				<path
					d='M12.6744 3.62824L12.6745 3.62832L12.6835 3.61938C13.0889 3.21393 13.091 2.55857 12.6896 2.15058L11.6533 1.06518L11.6469 1.05845L11.6403 1.05187C11.2338 0.646816 10.5745 0.644475 10.1662 1.05277L9.89316 1.32579L9.83154 1.38529L4.23886 6.78565L4.23878 6.78557L4.2295 6.79485C4.04314 6.98121 3.90515 7.21382 3.82844 7.46977L3.82825 7.47042L3.82782 7.47186L3.82781 7.47186L3.82694 7.47481L4.54644 7.68653L3.82694 7.47482L3.82693 7.47485L3.8269 7.47497L3.82676 7.47544L3.8262 7.47733L3.82401 7.48477L3.81557 7.51348L3.78416 7.6202L3.67767 7.9821L3.40473 8.90963L3.35863 9.0663L3.3457 9.11025L3.34198 9.1229L3.34098 9.12626L3.34073 9.12713L3.34066 9.12735L3.34065 9.12741L3.34064 9.12742L3.34064 9.12742L4.06014 9.33914L3.34064 9.12743L3.00359 10.2728L4.18176 10.0792L4.24585 10.0687L4.41877 10.0403L5.97445 9.78459L6.02161 9.77684L6.0674 9.76317L6.06894 9.76271L6.06975 9.76246C6.32304 9.68652 6.55353 9.55066 6.73912 9.36681L12.6744 3.62824Z'
					stroke={color}
					strokeOpacity='0.9'
					strokeWidth='1.5'
				/>
				<path
					d='M0.875 13.0881H13.125'
					stroke={color}
					strokeOpacity='0.9'
					strokeWidth='1.5'
					strokeLinecap='round'
				/>
			</g>
			<defs>
				<clipPath id='clip0_744_16677'>
					<rect width='14' height='14' fill={color} />
				</clipPath>
			</defs>
		</svg>
	);
}

export default Edit;
