import React, { ReactNode } from 'react';

import { Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import { App_Theme } from 'config/constants';

import Tooltip from 'components/Common/Tooltip';

import warningIcon from '../../assets/svg/icon-warning.svg';

export default function TextWarning({
	children,
	className,
	dark,
	backgroundColor
}: {
	children: ReactNode;
	className?: string;
	dark?: boolean;
	backgroundColor?: string;
}) {
	const theme = useTheme();
	return (
		<Typography
			component='div'
			variant='body2'
			color={theme.custom.orangeColor}
			className={classNames(
				'font-normal text-xs leading-5 rounded',
				className,
				{
					'p-3': dark === true
				}
			)}
			sx={{
				backgroundColor: backgroundColor
					? backgroundColor
					: theme.palette.mode === App_Theme.dark
					? theme.palette.background.paper
					: '#F5F5F5'
			}}
		>
			<img
				className='mr-0.5 mb-0.5 inline-block'
				width={16}
				src={warningIcon}
				alt=''
			/>
			{children}
		</Typography>
	);
}

export function TooltipWarning({
	children,
	className
}: {
	children: ReactNode;
	className?: string;
}) {
	return (
		<Tooltip title={children}>
			<img
				className={classNames('mb-0.5 mr-0.5 inline-block', className)}
				width={16}
				src={warningIcon}
				alt=''
			/>
		</Tooltip>
	);
}
