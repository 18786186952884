function Computer(props: any) {
	const { color = 'white' } = props;
	return (
		<svg
			width='15'
			height='17'
			viewBox='0 0 15 17'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M11.5846 6.2734H3.5565V4.26298H11.5846V6.2734ZM0 1.58717H14.9945V16.1889C14.9945 16.6325 14.6349 16.9922 14.1913 16.9922H0.803279C0.359641 16.9922 0 16.6325 0 16.1889V1.58717ZM1.60739 0.688528V15.4804H13.4452V0.688528H1.60739ZM3.74571 7.80919H5.34145V9.34073H3.74571V7.80919ZM0 1.58717H14.9945V0.803278C14.9945 0.35964 14.6349 0 14.1913 0H0.803279C0.359641 0 0 0.35964 0 0.803279V1.58717Z'
				fill={ color }
				fillOpacity='0.9'
			/>
			<path
				d='M8.28939 7.80919H6.68681V9.34073H8.28939V7.80919Z'
				fill={ color }
				fillOpacity='0.9'
			/>
			<path
				d='M11.3651 7.65681H9.98619V12.3899H11.3651V7.65681Z'
				fill={ color }
				fillOpacity='0.9'
			/>
			<path
				d='M8.28939 10.9387H6.68681V12.4702H8.28939V10.9387Z'
				fill={ color }
				fillOpacity='0.9'
			/>
			<path
				d='M5.34145 10.9387H3.73886V12.4702H5.34145V10.9387Z'
				fill={ color }
				fillOpacity='0.9'
			/>
		</svg>
	);
}

export default Computer;
