import { useMemo } from 'react';

import { RewardCollectorABI } from 'config/abis';
import {
	Contract_Write_Mode,
	Transaction_Type,
	Version
} from 'config/constants';
import { selectContractByVersion } from 'config/contracts';
import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';
import { txBaseState } from 'state/tx/slice';
import { RecordForClaimPosition } from 'types';
import { formatNumber, isPositive } from 'utils';

import { useComputeGasLimit } from './useComputeGasLimit';
import { useCurrentChain } from './useCurrentChain';
import { useSendTransaction } from './useSendTransaction';

export function useSubmitClaimPosition(
	calldata: string | undefined,
	appAmount: string
) {
	const { appToken } = useAppSelector(txBaseState);
	const currentVersion = useAppSelector(selectVersion);

	const { currentChainId } = useCurrentChain();
	const { address } = useAccount();

	const { record, description } = useMemo(() => {
		if (!isPositive(appAmount) || !appToken) {
			return {
				record: null,
				description: ''
			};
		}

		console.log('useSubmitClaimPosition args begin ...');

		// console.log('--------------');
		// console.log(JSON.stringify(results));

		const record = {
			transactionType:
				currentVersion === Version.V1
					? Transaction_Type.ClaimPosition
					: Transaction_Type.ClaimPositionV2,
			appAmount
		} as RecordForClaimPosition;

		const description = TransactionDescription.Claim(
			undefined,
			formatNumber(appAmount, appToken.positionUnits)
		);

		return {
			record,
			description
		};
	}, [address, appToken, appAmount, currentVersion]);

	const contractWriteArgs = useMemo(() => {
		if (!calldata) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address: selectContractByVersion(
				currentVersion,
				currentChainId,
				'RewardCollector'
			),
			abi: RewardCollectorABI,
			functionName: 'multicall',
			args: [[calldata]]
		} as UseContractWriteConfig;
	}, [calldata, currentChainId, currentVersion]);

	const { gasLimit } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirmed, isConfirming, error } =
		useSendTransaction(contractWriteArgs, gasLimit);

	const onConfirm = () => {
		if (!record) return;
		onSendTransaction(record, description);
	};

	return {
		onConfirm,
		isConfirming,
		isConfirmed,
		error
	};
}
