import { formatNumber } from 'utils';

import { useFundingRateHistoriesQuery } from './__generated_trade__/types-and-hooks';

export function useFundingRateHistoriesByPool(pool: string, limit: number) {
	const { data, ...restProps } = useFundingRateHistoriesQuery({
		variables: { pool: pool ? pool.toLowerCase() : '', first: limit }
	});

	return {
		data: data?.fundingRateHistories.map(item => ({
			...item,
			x: Number(item.ts) * 1000,
			y: Number(formatNumber(Number(item.fundingRate) * 100, 7))
		})),
		...restProps
	};
}
