import { useMemo } from 'react';

import { orderBookABI } from 'config/abis';
import {
	Contract_Write_Mode,
	QUOTE_USD,
	Side,
	Transaction_Type
} from 'config/constants';
import { getContractAddress } from 'config/contracts';
import Decimal from 'decimal.js';
import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { useAppSelector } from 'state/hooks';
import { settingBaseState } from 'state/setting/slice';
import { txBaseState } from 'state/tx/slice';
import { selectOrderOpenType } from 'state/setting/selector'
import {
	RecordForUpdateProfitLossPartial,
	RecordForUpdateProfitLossPosition,
	TokenInfo
} from 'types';
import { computePriceX96, isPositive, shortenSymbolNative, toQuoteAmount } from 'utils';

import { useComputeGasLimit } from './useComputeGasLimit';
import { useCurrentChain } from './useCurrentChain';
import { useSendTransaction } from './useSendTransaction';
import { usePriceRangesByTolerance } from './useSlippageTolerance';

export function useSubmitUpdateProfitLoss(
	poolId: string,
	transactionType: Transaction_Type,
	orderIndex: string,
	triggerPrice: string,
	side: Side,
	baseToken: TokenInfo | null,
	triggerAbove: boolean
) {
	const { quoteToken } = useAppSelector(txBaseState);
	const { slippage } = useAppSelector(settingBaseState);
	const Order_Open_Type = useAppSelector(selectOrderOpenType);

	const { currentChainId } = useCurrentChain();
	const { address } = useAccount();

	const { minPrice, maxPrice } = usePriceRangesByTolerance(
		triggerPrice,
		slippage
	);

	const { args, record, description } = useMemo(() => {
		if (!baseToken || !quoteToken || !isPositive(triggerPrice)) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		console.log('useSubmitUpdateProfitLoss args begin ...');
		const _triggerMarketPriceX96 = computePriceX96(
			triggerPrice,
			baseToken.decimals,
			quoteToken.decimals
		);

		const acceptableTradePriceX96 =
			side === Side.LONG
				? computePriceX96(minPrice, baseToken.decimals, quoteToken.decimals)
				: computePriceX96(maxPrice, baseToken.decimals, quoteToken.decimals);

		const args = [orderIndex, _triggerMarketPriceX96, acceptableTradePriceX96];

		const _poolSymbol = `${shortenSymbolNative(
			baseToken,
			currentChainId
		)}/${QUOTE_USD}`;

		const _triggerPrice = toQuoteAmount(
			triggerPrice,
			baseToken.precision,
			Decimal.ROUND_HALF_CEIL
		);

		let record;
		let description = '';
		switch (transactionType) {
			// 全仓：修改止盈
			case Transaction_Type.CreateTakeProfitPosition:
				record = {
					transactionType: Transaction_Type.UpdateTakeProfitPosition,
					pool: poolId,
					orderId: `${Order_Open_Type.Decrease}-${orderIndex}`,
					baseToken,
					side,
					triggerPrice,
					triggerAbove
				} as RecordForUpdateProfitLossPosition;
				description = TransactionDescription.UpdateTakeProfitPositionOrders(
					_poolSymbol,
					_triggerPrice,
					side,
					triggerAbove
				);
				break;
			// 全仓：修改止损
			case Transaction_Type.CreateStopLossPosition:
				record = {
					transactionType: Transaction_Type.UpdateStopLossPosition,
					pool: poolId,
					orderId: `${Order_Open_Type.Decrease}-${orderIndex}`,
					baseToken,
					side,
					triggerPrice,
					triggerAbove
				} as RecordForUpdateProfitLossPosition;
				description = TransactionDescription.UpdateStopLossPositionOrders(
					_poolSymbol,
					_triggerPrice,
					side,
					triggerAbove
				);
				break;
			// 部分：修改止盈
			case Transaction_Type.CreateTakeProfitPartial:
				record = {
					transactionType: Transaction_Type.UpdateTakeProfitPartial,
					pool: poolId,
					orderId: `${Order_Open_Type.Decrease}-${orderIndex}`,
					baseToken,
					side,
					triggerPrice
				} as RecordForUpdateProfitLossPartial;
				description = TransactionDescription.UpdateTakeProfitPartialOrders(
					_poolSymbol,
					_triggerPrice,
					side,
					triggerAbove
				);
				break;
			// 部分：修改止损
			case Transaction_Type.CreateStopLossPartial:
				record = {
					transactionType: Transaction_Type.UpdateStopLossPartial,
					pool: poolId,
					orderId: `${Order_Open_Type.Decrease}-${orderIndex}`,
					baseToken,
					side,
					triggerPrice
				} as RecordForUpdateProfitLossPartial;
				description = TransactionDescription.UpdateStopLossPartialOrders(
					_poolSymbol,
					_triggerPrice,
					side,
					triggerAbove
				);
				break;
		}

		return {
			args,
			record,
			description
		};
	}, [
		address,
		baseToken,
		quoteToken,
		transactionType,
		orderIndex,
		side,
		poolId,
		triggerPrice,
		minPrice,
		maxPrice
	]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address: getContractAddress(currentChainId, 'OrderBook'),
			abi: orderBookABI,
			functionName: 'updateDecreaseOrder',
			args
		} as UseContractWriteConfig;
	}, [currentChainId, args]);

	const { gasLimit } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirmed, isConfirming, error } =
		useSendTransaction(contractWriteArgs, gasLimit);

	const onConfirm = () => {
		if (!record) return;
		onSendTransaction(record, description);
	};

	return {
		onConfirm,
		isConfirming,
		isConfirmed,
		error
	};
}
