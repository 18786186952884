import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

import { Trans } from '@lingui/macro';
import { Page, Version } from 'config/constants';
import { useAccount } from 'wagmi';

import { useCheckLogin } from 'hooks/useCurrentChain';
import { isEmpty } from 'lodash';
import { useAppSelector } from 'state/hooks';
import { selectHasConnectors } from 'state/referrals/selector';
import { referralBaseState } from 'state/referrals/slice';
import { selectVersion } from 'state/setting/selector';
import { tradeBaseState } from 'state/trade/slice';

import StyledTab from 'components/Common/StyledTab';
import StyledTabs from 'components/Common/StyledTabs';

import Connectors from './Connectors';
import Leaderboard from './Leaderboard';
import Members from './Members';
import Summary from './Summary';
import Traders from './Traders';
import { Type_Referrals } from './types';

export default React.memo(function Content({
	isClaimLoading,
	claimDisabled,
	onDirectClaim,
	onClaim
}: {
	isClaimLoading: boolean;
	claimDisabled: boolean;
	onDirectClaim: () => void;
	onClaim: () => void;
}) {
	const { type: routerType } = useParams();
	const navigate = useNavigate();
	const { isBindReferralCode } = useAppSelector(tradeBaseState);
	const currentVersion = useAppSelector(selectVersion);
	const hasConnectors = useAppSelector(selectHasConnectors);

	const activeTab = useMemo(() => {
		if (routerType === Type_Referrals.Leaderboard) {
			return Type_Referrals.Leaderboard;
		} else if (routerType === Type_Referrals.Connector) {
			return Type_Referrals.Connector;
		} else if (routerType === Type_Referrals.Members) {
			return Type_Referrals.Members;
		} else {
			return Type_Referrals.Traders;
		}
	}, [routerType]);

	useUpdateEffect(() => {
		// 由有Connector的角色跳到其他角色
		if (!hasConnectors && activeTab === Type_Referrals.Connector) {
			if (isBindReferralCode === undefined) {
				return;
			}
			if (isBindReferralCode === true) {
				navigate(
					`${
						currentVersion === Version.V1 ? Page.ReferralsV1 : Page.Referrals
					}/${Type_Referrals.Members}`
				);
				return;
			}
			navigate(
				`${currentVersion === Version.V1 ? Page.ReferralsV1 : Page.Referrals}/${
					Type_Referrals.Traders
				}`
			);
		}
	}, [hasConnectors, isBindReferralCode]);

	const isLogin = useCheckLogin();

	const { membersList } = useAppSelector(referralBaseState);
	const { address } = useAccount();

	return (
		<div className='space-y-4 underLg:space-y-2 sm:flex sm:flex-col sm:flex-1'>
			<div className='flex items-center justify-between sm:px-1'>
				<StyledTabs
					className='md:ml-1'
					value={activeTab}
					onChange={(e, value) =>
						navigate(
							`${
								currentVersion === Version.V1
									? Page.ReferralsV1
									: Page.Referrals
							}/${value}`
						)
					}
					size='large'
					variant='scrollable'
					scrollButtons='auto'
					allowScrollButtonsMobile
					sx={{
						'& [aria-selected=false]': {
							fontWeight: 400
						}
					}}
				>
					<StyledTab
						className='sm:text-sm sm:mr-1'
						label={<Trans>Traders</Trans>}
						value={Type_Referrals.Traders}
						path='referrals'
					/>
					<StyledTab
						className='sm:text-sm sm:mr-1'
						label={<Trans>EFC Members</Trans>}
						value={Type_Referrals.Members}
						path='referrals'
					/>
					<StyledTab
						className={`sm:text-sm sm:mr-1 ${
							isLogin && hasConnectors ? 'block' : 'hidden'
						}`}
						label='EFC Connectors'
						value={Type_Referrals.Connector}
						path='referrals'
					/>
					{currentVersion === Version.V1 && (
						<StyledTab
							className='sm:text-sm sm:mr-1'
							label={<Trans>Leaderboard</Trans>}
							value={Type_Referrals.Leaderboard}
							path='referrals'
						/>
					)}
				</StyledTabs>
			</div>
			<div className='space-y-2 sm:flex sm:flex-col sm:flex-1'>
				{activeTab !== Type_Referrals.Traders && isLogin ? (
					<>
						{activeTab === Type_Referrals.Members && !isEmpty(membersList) ? (
							<Summary
								isClaimLoading={isClaimLoading}
								onClaim={onClaim}
								onDirectClaim={onDirectClaim}
								disabled={claimDisabled}
								type={routerType as Type_Referrals}
							/>
						) : null}
						{activeTab === Type_Referrals.Connector ? (
							<Summary
								isClaimLoading={isClaimLoading}
								onClaim={onClaim}
								onDirectClaim={onDirectClaim}
								disabled={claimDisabled}
								type={routerType as Type_Referrals}
							/>
						) : null}
					</>
				) : null}
				<div className='sm:py-0 sm:flex-1'>
					{activeTab === Type_Referrals.Traders ? <Traders /> : null}
					{activeTab === Type_Referrals.Members ? (
						<Members key={address} />
					) : null}
					{activeTab === Type_Referrals.Connector ? <Connectors /> : null}
					{activeTab === Type_Referrals.Leaderboard ? <Leaderboard /> : null}
				</div>
			</div>
		</div>
	);
});
