import React, { useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardControlKeyIcon from '@mui/icons-material/KeyboardControlKey';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
	Avatar,
	Box,
	Collapse,
	Divider,
	InputAdornment,
	ToggleButton,
	ToggleButtonGroup,
	Tooltip,
	Typography
} from '@mui/material';
import Popover from '@mui/material/Popover/Popover';
import { styled, useTheme } from '@mui/material/styles';
import { DEFAULT_SETTING_SLIPPAGE } from 'config/constants';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
	setSlippage,
	setSlippageType,
	settingBaseState
} from 'state/setting/slice';

import { CommonStyledOutlineInput } from 'components/Common/Styled';
import ThemeButton from 'components/ThemeButton';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
	'&.MuiToggleButtonGroup-root': {
		border: `1px solid ${theme.palette.secondary.main}`,
		borderRadius: '4px'
	},
	'& .MuiToggleButtonGroup-grouped': {
		margin: theme.spacing(0.5),
		border: 0,
		'&.Mui-selected': {
			backgroundColor: theme.palette.divider,
			'&:hover': {
				backgroundColor: theme.palette.divider
			}
		},
		'&:not(:first-of-type)': {
			border: 0,
			marginLeft: 0,
			borderRadius: theme.shape.borderRadius
		},
		'&:first-of-type': {
			border: 0,
			borderRadius: theme.shape.borderRadius
		}
	}
}));

const Setting = () => {
	const { slippage, slippageType } = useAppSelector(settingBaseState);

	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
	const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
	const [expand, setExpand] = useState(false);

	const dispatch = useAppDispatch();
	const theme = useTheme();
	const [errors, setErrors] = useState({}) as any;
	const [tempSlippageValue, setTempSlippageValue] = useState('');
	// const [slippageType, setSlippageType] = React.useState('auto');
	const onOpenTooltip = () => setTooltipIsOpen(true);
	// const onCloseTooltip = () => setTooltipIsOpen(false);
	const onHandleAlignment = (
		event: React.MouseEvent<HTMLElement>,
		newType: string
	) => {
		if (newType !== null) {
			dispatch(setSlippageType(newType));
			if (newType === 'auto') {
				dispatch(setSlippage(DEFAULT_SETTING_SLIPPAGE));
			}
		}
	};

	const onOpen = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
		setOpen(true);
		event && event?.currentTarget && setAnchorEl(event.currentTarget);
	};

	const onClose = () => {
		setOpen(false);
		if (tempSlippageValue && !errors.custom) {
			dispatch(setSlippage(tempSlippageValue));
		}
		setErrors({});
		setTempSlippageValue('');
	};

	const onToggleExpand = () => setExpand(!expand);

	const onHandleChangeSlippageAmount = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const value = event.target.value;
		if (isNaN(Number(value)) || !value) {
			setTempSlippageValue('');
			setErrors({ [event.currentTarget.name]: true });
		} else {
			setTempSlippageValue(value);
			if (Number(value) > 10 || Number(value) < 0.01) {
				setErrors({ [event.currentTarget.name]: true });
			} else {
				setErrors({ [event.currentTarget.name]: false });
			}
		}
	};

	return (
		<Box
			sx={{
				borderRadius: '4px',
				'&:hover': {
					textDecoration: 'none',
					backgroundColor: theme.palette.action.hover
				}
			}}
		>
			<MoreHorizIcon
				className='cursor-pointer'
				fontSize='medium'
				onClick={onOpen}
				sx={{
					color: theme.palette.text.primary
				}}
			/>

			<Popover
				open={open}
				anchorEl={anchorEl}
				className='translate-y-2 transform'
				onClose={() => setOpen(false)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
			>
				<div className='py-5'>
					<Box className='flex cursor-pointer justify-between  px-4'>
						<Box className='flex items-center'>
							<Typography className='ml-2 text-sm' variant='body2'>
								<Trans>Theme</Trans>
							</Typography>
						</Box>

						<Box>
							<ThemeButton />
						</Box>
					</Box>
					<Divider sx={{ borderStyle: 'dashed' }} className='my-4 mx-4' />
					<Box
						onClick={onToggleExpand}
						className='flex cursor-pointer justify-between  px-4'
					>
						<div className='flex items-center'>
							<Tooltip
								title={
									<div className='py-2 px-3'>
										<Trans>
											The slippage refers to the difference between the expected
											price you can accept and the final transaction price.
										</Trans>
									</div>
								}
								placement='bottom'
								arrow
							>
								<Typography
									onClick={onOpenTooltip}
									sx={{ borderBottom: '1px dashed #8B919D' }}
									className='ml-2 text-sm'
									variant='body2'
								>
									<Trans>Slippage Settings</Trans>
								</Typography>
							</Tooltip>
						</div>
						<div className='flex items-center'>
							<Typography className='text-sm' variant='body2'>
								{slippage === DEFAULT_SETTING_SLIPPAGE ? (
									<Trans>Auto</Trans>
								) : (
									`${slippage}%`
								)}
							</Typography>
							{expand ? (
								<KeyboardControlKeyIcon
									fontSize='medium'
									sx={{
										color: theme.palette.secondary.main
									}}
								/>
							) : (
								<KeyboardArrowDownIcon
									fontSize='medium'
									sx={{
										color: theme.palette.secondary.main
									}}
								/>
							)}
						</div>
					</Box>
					<Collapse
						sx={{ border: 'none' }}
						in={expand}
						timeout='auto'
						className='px-4'
					>
						<Box className='mt-3 flex justify-between gap-4 items-start'>
							<StyledToggleButtonGroup
								size='small'
								value={slippageType}
								exclusive
								onChange={onHandleAlignment}
								aria-label='text alignment'
							>
								<ToggleButton
									value='auto'
									aria-label='left aligned'
									className='grow capitalize'
								>
									<Trans>Auto</Trans>
								</ToggleButton>
								<ToggleButton
									value='custom'
									aria-label='right aligned'
									className='grow capitalize'
								>
									<Trans>Custom</Trans>
								</ToggleButton>
							</StyledToggleButtonGroup>
							{slippageType === 'auto' && (
								<CommonStyledOutlineInput
									readOnly
									type='text'
									inputProps={{ maxLength: 10, inputMode: 'decimal' }}
									className='input px-2'
									defaultValue={DEFAULT_SETTING_SLIPPAGE}
									placeholder={DEFAULT_SETTING_SLIPPAGE}
									endAdornment={
										<InputAdornment
											position='end'
											sx={{
												'.MuiTypography-body1': {
													color: theme.palette.text.primary
												}
											}}
										>
											%
										</InputAdornment>
									}
									sx={{
										'&.MuiOutlinedInput-root': {
											width: '120px',
											border: `1px solid ${theme.palette.secondary.main}`,
											color: theme.palette.secondary.main
										},
										'.MuiOutlinedInput-input': {
											textAlign: 'right'
										},
										'.MuiOutlinedInput-notchedOutline': {
											border: `1px solid ${theme.palette.secondary.main} !important`
										}
									}}
								/>
							)}
							{slippageType === 'custom' && (
								<div className=''>
									<CommonStyledOutlineInput
										type='text'
										name='custom'
										autoComplete='off'
										inputProps={{ maxLength: 10, inputMode: 'decimal' }}
										className='input px-2'
										value={
											tempSlippageValue || errors.custom
												? tempSlippageValue
												: slippage
										}
										onChange={onHandleChangeSlippageAmount}
										onBlur={onClose}
										placeholder={DEFAULT_SETTING_SLIPPAGE}
										error={errors?.custom}
										endAdornment={
											<InputAdornment
												position='end'
												sx={{
													'.MuiTypography-body1': {
														color: theme.palette.text.primary
													}
												}}
											>
												%
											</InputAdornment>
										}
										sx={{
											'&.MuiOutlinedInput-root': {
												width: '120px',
												border: `1px solid ${theme.palette.secondary.main}`,
												'&.Mui-error': {
													border: `1px solid ${theme.palette.error.main}`
												}
												// color: theme.palette.secondary.main,
											},
											'.MuiOutlinedInput-input': {
												textAlign: 'right'
											}
										}}
									/>
								</div>
							)}
						</Box>
						{slippageType === 'custom' && errors?.custom ? (
							<Typography
								className='w-full mt-1'
								variant='body2'
								align='right'
								color='error.main'
							>
								{Number(tempSlippageValue) < 0.01
									? t`Min slippage precision is 0.01%`
									: ''}
								{Number(tempSlippageValue) > 10
									? t`Max slippage precision is 10%`
									: ''}
							</Typography>
						) : null}
					</Collapse>
				</div>
			</Popover>
		</Box>
	);
};

export default Setting;
