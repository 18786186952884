import React, { useContext, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans, t } from '@lingui/macro';
import { Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import { MSG_ERROR_APPROVE, Side, Version } from 'config/constants';
import { getContractAddress, getContractAddressV2 } from 'config/contracts';
import { MobileActionContext } from 'context/MobileActionsContext';
import { TradeContext } from 'context/TradeContext';
import Decimal from 'decimal.js';

import { ApprovalState, useApproval } from 'hooks/useApproval';
import { useApprovalPlugin } from 'hooks/useApprovalPlugin';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import { useSubmitIncreaseTradeMarket } from 'hooks/useSubmitIncreaseTradeMarket';
import { debounce } from 'lodash';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
	selectCurrentInitialMargin,
	selectCurrentMarginDelta,
	selectCurrentPositionAmount,
	selectIsExceedBalanceImpactFee
} from 'state/market/selector';
import { marketBaseState, reset } from 'state/market/slice';
import { poolsBaseState } from 'state/pools/slice';
import { selectOrderOpenType } from 'state/setting/selector';
import { settingBaseState } from 'state/setting/slice';
import { setLeverage, tradeBaseState } from 'state/trade/slice';
import { txBaseState } from 'state/tx/slice';
import {
	div,
	formatSize,
	isGreaterThan,
	isGreaterThanOrEqual,
	isLessThan,
	isPositive,
	minus,
	multipliedBy,
	plus,
	shortenSymbol
} from 'utils';

import CommonStyledButton from 'components/Common/StyledButton';
import TextWarning from 'components/TextWarning';
import ConnectWalletButton from 'components/Wallet/ConnectWalletButton';

import useApprovalPluginV2 from '../../../hooks/V2/useApprovalPluginV2';
import useSubmitIncreaseTradeMarketV2 from '../../../hooks/V2/useSubmitIncreaseTradeMarketV2';
import ConfirmDialog from './ConfirmDialog';
import ImpactFeeWarning from './ImpactFeeWarning';

export default React.memo(function TradeButtonMarketV2() {
	const dispatch = useAppDispatch();

	const { transactionType, quoteToken, quoteBalance, signingMap } =
		useAppSelector(txBaseState);
	const { leverage } = useAppSelector(tradeBaseState);
	const { entryPrice } = useAppSelector(marketBaseState);
	const { poolInfo } = useAppSelector(poolsBaseState);

	const currentMarginDelta = useAppSelector(selectCurrentMarginDelta);
	const currentPositionAmount = useAppSelector(selectCurrentPositionAmount);
	const currentInitialMargin = useAppSelector(selectCurrentInitialMargin);
	const isExceedBalanceImpactFee = useAppSelector(
		selectIsExceedBalanceImpactFee
	);
	const { tradeDirection, positionInfo, setDialogIsVisible } =
		useContext(TradeContext);
	const { setIsVisibleForm } = useContext(MobileActionContext);

	const theme = useTheme();
	const isLogin = useCheckLogin();
	const { currentChainId } = useCurrentChain();

	const routerContractV2Address = useMemo(() => {
		return getContractAddressV2(currentChainId, 'Router');
	}, [currentChainId]);

	const positionRouterContractV2Address = useMemo(() => {
		return getContractAddressV2(currentChainId, 'PositionRouter');
	}, [currentChainId]);

	const { currentApproveState, approveCallback } = useApproval(
		quoteToken,
		currentMarginDelta,
		routerContractV2Address
	);
	// 创建仓位授权
	const { isPluginApproved, isPluginApproving, onApprovePlugin } =
		useApprovalPluginV2(positionRouterContractV2Address);

	const { orders } = useAppSelector(tradeBaseState);
	const Order_Open_Type = useAppSelector(selectOrderOpenType);
	const tpSlOrders = orders.filter(
		order => order.type !== Order_Open_Type.Increase
	);
	const sameSideOrders = tpSlOrders.filter(
		order => order.side === tradeDirection && order.poolId === poolInfo?.id
	);
	const hasSameSideOrder = sameSideOrders.length !== 0;
	const [open, setOpen] = useState(false);
	const onClose = () => setOpen(false);

	const maxSizeToOpen = useMemo(() => {
		if (!poolInfo) {
			return '0';
		}
		const gpMaxToOpen = minus(
			poolInfo?.globalPosition?.maxSize,
			plus(poolInfo.globalPosition.longSize, poolInfo.globalPosition.shortSize)
		);
		const positionMaxToOpen = minus(
			poolInfo?.globalPosition?.maxSizePerPosition,
			positionInfo?.size || 0
		);

		return Decimal.min(gpMaxToOpen, positionMaxToOpen).isNeg()
			? '0'
			: Decimal.min(gpMaxToOpen, positionMaxToOpen).toString();
	}, [poolInfo, tradeDirection, currentPositionAmount, positionInfo]);

	const { onConfirm, isConfirming, isConfirmed, errMsg } =
		useSubmitIncreaseTradeMarketV2(currentApproveState, isPluginApproved);
	const { checkedTradeLeverage } = useAppSelector(settingBaseState);

	const nextMargin = useMemo(() => {
		if (positionInfo) {
			return plus(positionInfo.margin, currentMarginDelta);
		}
		return currentMarginDelta;
	}, [positionInfo, currentMarginDelta]);
	const nextValue = useMemo(() => {
		if (positionInfo) {
			return plus(
				positionInfo.liquidity,
				multipliedBy(entryPrice, currentPositionAmount)
			);
		}
		return multipliedBy(entryPrice, currentPositionAmount);
	}, [positionInfo, currentPositionAmount]);

	const nextLeverage = useMemo(() => {
		if (isPositive(nextValue) && isPositive(nextMargin)) {
			return div(nextValue, nextMargin);
		}
		return '1';
	}, [nextValue, nextMargin]);

	const canSubmit = useMemo(() => {
		if (entryPrice === '0' && isPositive(currentMarginDelta)) {
			return false;
		}
		if (entryPrice === '0' && isPositive(currentPositionAmount)) {
			return false;
		}
		if (isLessThan(entryPrice, 0)) {
			return false;
		}
		if (
			!poolInfo ||
			!isPositive(quoteBalance) ||
			!isPositive(currentMarginDelta) ||
			!isPositive(currentPositionAmount)
		) {
			return false;
		}
		if (!isPositive(currentInitialMargin)) {
			return false;
		}
		if (isLessThan(quoteBalance, currentMarginDelta)) {
			return false;
		}
		if (currentApproveState === ApprovalState.NOT_APPROVED) {
			return true;
		}
		if (currentApproveState === ApprovalState.PENDING) {
			return false;
		}
		if (!isPluginApproved) {
			return true;
		}
		if (isPluginApproving) {
			return false;
		}
		if (
			!positionInfo &&
			isLessThan(currentMarginDelta, poolInfo?.minMarginPerPosition)
		) {
			return false;
		}
		if (
			isPositive(leverage) &&
			isGreaterThan(leverage, poolInfo?.maxLeveragePerPosition)
		) {
			return false;
		}
		if (signingMap.get(transactionType)) {
			return false;
		}
		if (isConfirming) {
			return false;
		}
		if (errMsg && errMsg.indexOf(MSG_ERROR_APPROVE) >= 0) {
			return true;
		}
		if (isGreaterThan(currentPositionAmount, maxSizeToOpen)) {
			return false;
		}
		if (isGreaterThan(nextLeverage, poolInfo?.maxLeveragePerPosition)) {
			return false;
		}
		return true;
	}, [
		poolInfo,
		currentMarginDelta,
		currentPositionAmount,
		currentApproveState,
		isPluginApproved,
		isPluginApproving,
		quoteBalance,
		leverage,
		isConfirming,
		currentInitialMargin,
		signingMap,
		transactionType,
		entryPrice,
		positionInfo,
		errMsg,
		maxSizeToOpen,
		nextLeverage
	]);

	const submitText = useMemo(() => {
		if (entryPrice === '0' && isPositive(currentMarginDelta)) {
			return <Trans>Insufficient Liquidity</Trans>;
		}
		if (entryPrice === '0' && isPositive(currentPositionAmount)) {
			return <Trans>Insufficient Liquidity</Trans>;
		}
		if (isLessThan(entryPrice, 0)) {
			return <Trans>Error Price</Trans>;
		}
		if (
			poolInfo &&
			isPositive(currentMarginDelta) &&
			isPositive(currentPositionAmount)
		) {
			// 用户输入的支付金额 < 开仓需要支付的费用
			if (!isPositive(currentInitialMargin)) {
				return <Trans>Margin can not be less than 0</Trans>;
			}
			// token balance
			if (
				isGreaterThanOrEqual(quoteBalance, 0) &&
				isLessThan(quoteBalance, currentMarginDelta)
			) {
				return <Trans>Insufficient Balance</Trans>;
			}

			// 授权token中
			if (currentApproveState === ApprovalState.PENDING) {
				return (
					<span>
						<Trans>Approving...</Trans>
					</span>
				);
			}
			// 未授权token
			if (currentApproveState === ApprovalState.NOT_APPROVED) {
				return (
					<span>
						<Trans>Approve {`${shortenSymbol(quoteToken)}`}</Trans>
					</span>
				);
			}
			if (!isPluginApproved) {
				return <Trans>Approve Operation (one-time)</Trans>;
			}
			if (isPluginApproving) {
				return <Trans>Approving...</Trans>;
			}
			if (
				!positionInfo &&
				isLessThan(currentMarginDelta, poolInfo?.minMarginPerPosition)
			) {
				return (
					<Trans>
						Min payment: {poolInfo?.minMarginPerPosition}{' '}
						{shortenSymbol(quoteToken)}
					</Trans>
				);
			}
			if (isGreaterThan(leverage, poolInfo?.maxLeveragePerPosition)) {
				return (
					<Trans>
						Exceeds the max leverage of {poolInfo?.maxLeveragePerPosition}x
					</Trans>
				);
			}
		}
		if (signingMap.get(transactionType)) {
			return <Trans>Loading...</Trans>;
		}
		if (isConfirming) {
			return <Trans>Submitting...</Trans>;
		}
		if (errMsg && errMsg.indexOf(MSG_ERROR_APPROVE) >= 0) {
			return (
				<span>
					<Trans>Approve {`${shortenSymbol(quoteToken)}`}</Trans>
				</span>
			);
		}
		if (isGreaterThan(currentPositionAmount, maxSizeToOpen)) {
			return (
				<Trans>
					Max to open{' '}
					{formatSize(maxSizeToOpen, poolInfo?.baseToken?.positionUnits)}
				</Trans>
			);
		}

		if (isGreaterThan(nextLeverage, poolInfo?.maxLeveragePerPosition)) {
			return (
				<Trans>
					Exceeds the max leverage of {poolInfo?.maxLeveragePerPosition}x
				</Trans>
			);
		}

		return tradeDirection === Side.LONG ? (
			<Trans>Long</Trans>
		) : (
			<Trans>Short</Trans>
		);
	}, [
		poolInfo,
		currentMarginDelta,
		currentPositionAmount,
		currentApproveState,
		isPluginApproved,
		isPluginApproving,
		quoteBalance,
		transactionType,
		leverage,
		isConfirming,
		currentInitialMargin,
		signingMap,
		tradeDirection,
		entryPrice,
		currentPositionAmount,
		positionInfo,
		errMsg,
		maxSizeToOpen,
		nextLeverage
	]);

	useUpdateEffect(() => {
		if (isConfirming) {
			dispatch(reset());
			if (!checkedTradeLeverage) {
				dispatch(setLeverage(0));
			}
		}
	}, [isConfirming]);

	useUpdateEffect(() => {
		if (isConfirmed) {
			setIsVisibleForm(false);
			setDialogIsVisible(false);
		}
	}, [isConfirmed]);

	const beforeConfirm = () => {
		if (hasSameSideOrder) {
			setOpen(true);
			return;
		} else {
			onHandleConfirm();
		}
	};
	const onHandleConfirm = debounce(() => {
		if (
			currentApproveState === ApprovalState.NOT_APPROVED ||
			(errMsg && errMsg.indexOf(MSG_ERROR_APPROVE) >= 0)
		) {
			approveCallback();
			return;
		}
		if (isPluginApproved === false) {
			onApprovePlugin();
		}
		onConfirm();
	}, 300);

	return (
		<div>
			{!isLogin ? (
				<ConnectWalletButton fullWidth className='h-12' />
			) : (
				<>
					{isGreaterThan(currentPositionAmount, maxSizeToOpen) && (
						<TextWarning
							className='py-3 flex justify-center'
							backgroundColor={'transparent !important'}
						>
							<Trans>Exceeded the position size limit for a single user.</Trans>
						</TextWarning>
					)}
					<div className={classNames(!canSubmit && 'cursor-not-allowed')}>
						<CommonStyledButton
							className='flex flex-col justify-center'
							variant='contained'
							componentVariant={tradeDirection === Side.LONG ? 'long' : 'short'}
							size='large'
							fullWidth
							disabled={!canSubmit}
							onClick={beforeConfirm}
						>
							<Typography
								className='whitespace-normal'
								color='inherit'
								variant='h6'
								fontWeight={500}
							>
								{submitText}
							</Typography>
						</CommonStyledButton>
						{isExceedBalanceImpactFee && <ImpactFeeWarning />}
					</div>
				</>
			)}
			<ConfirmDialog
				open={open}
				onClose={onClose}
				onConfirm={onHandleConfirm}
				orders={sameSideOrders}
			/>
		</div>
	);
});
