import { List, Paper, useTheme } from '@mui/material';
import { App_Theme } from 'config/constants';

import LogoFooterDark from 'assets/svg/logo-footer-dark.svg';
import LogoFooter from 'assets/svg/logo-footer.svg';
import MobileLogo from 'assets/svg/m-logo-full.svg';
import DiscordLogo from 'assets/svg/social/discord.svg';
import MediumLogo from 'assets/svg/social/medium.svg';
import TelegramLogo from 'assets/svg/social/telegram.svg';
import TwitterLogo from 'assets/svg/social/twitter.svg';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';

import StyledLink from 'components/Common/StyledLink';

const SocialList = [
	{
		name: 'discord',
		logo: DiscordLogo,
		link: 'https://discord.gg/ywFrewsxBH'
	},
	{
		name: 'twitter',
		logo: TwitterLogo,
		link: 'https://twitter.com/EquationDAO'
	},
	{
		name: 'medium',
		logo: MediumLogo,
		link: 'https://medium.com/@EquationDAO'
	},
	{
		name: 'telegram',
		logo: TelegramLogo,
		link: 'https://t.me/EquationDao'
	}
];
export default function Footer() {
	const theme = useTheme();
	const { isMatchMobile } = useAppBreakpoints();

	return (
		<footer className='relative'>
			<Paper
				className='flex flex-col items-center justify-center space-x-2 rounded-none pt-6 pb-10'
				sx={{
					backgroundColor: isMatchMobile
						? theme.custom.dialogBg
						: theme.custom.paperBg
				}}
			>
				<img
					src={theme.palette.mode === 'light' ? LogoFooter : LogoFooterDark}
					alt='Logo'
					className={isMatchMobile ? 'h-6' : 'h-8'}
					style={{
						// filter: 'grayscale(100%)',
						opacity: theme.palette.mode === App_Theme.dark ? 0.6 : 1
					}}
				/>
				<List className='flex mt-8'>
					{SocialList.map((item, index) => {
						return (
							<StyledLink
								key={item.name}
								href={item.link}
								target='_blank'
								rel='noreferrer'
								className={isMatchMobile ? 'mx-5' : 'mx-11'}
							>
								<img
									src={item.logo}
									alt={item.name}
									className={'w-7.5 h-7.5'}
								/>
							</StyledLink>
						);
					})}
				</List>
			</Paper>
		</footer>
	);
}
