import { createSelector } from '@reduxjs/toolkit';
import { Transaction_Type } from 'config/constants';

import { ethers } from 'ethers';
import { txBaseState } from 'state/tx/slice';

import { globalBaseState } from './slice';

export const selectPositionMinExecutionFee = createSelector(
	[globalBaseState],
	state => {
		const { positionMinExecutionFee } = state;
		if (positionMinExecutionFee) {
			return ethers.utils.parseEther(positionMinExecutionFee).toString();
			// return positionMinExecutionFee;
		} else {
			return '0';
		}
	}
);

export const selectOrderMinExecutionFee = createSelector(
	[globalBaseState],
	state => {
		const { orderMinExecutionFee } = state;
		if (orderMinExecutionFee) {
			return ethers.utils.parseEther(orderMinExecutionFee).toString();
			// return orderMinExecutionFee;
		} else {
			return '0';
		}
	}
);

export const selectCurrentExecutionFee = createSelector(
	[globalBaseState, txBaseState],
	(state, txBaseState) => {
		const { transactionType } = txBaseState;
		const { orderMinExecutionFee, positionMinExecutionFee } = state;
		if (
			transactionType === Transaction_Type.LongLimitOrder ||
			transactionType === Transaction_Type.ShortLimitOrder
		) {
			return orderMinExecutionFee;
		}
		return positionMinExecutionFee;
	}
);


export const selectTheme = createSelector(globalBaseState, state => state.theme);