import { useCallback, useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { App_Theme } from 'config/constants';
import moment from 'moment';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { isEmpty } from 'lodash';
import {
	Line,
	LineChart as LineChartComponent,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts';

import SectionLoading from 'components/SectionLoading';

export const StyledTooltip = styled('div')(({ theme }) => ({
	background: theme.custom.dialogBg,
	borderColor: theme.palette.divider,
	borderWidth: 1,
	boxShadow: 'unset',
	padding: theme.spacing(2),
	outline: 0,
	borderRadius: theme.spacing(0.5)
}));

export default function LineChart(props: any) {
	const { data = [], loading, tickFormatter, interval, ...restProps } = props;
	const sameFundingRate =
		data.length && data.every(item => item.y === data[0].y);

	const theme = useTheme();

	const xTickFormatter = useCallback(
		(value: string) => {
			if (tickFormatter) {
				return tickFormatter(value);
			} else {
				return data.length < 48
					? moment(value).format('HH')
					: moment(value).format('DD');
			}
		},
		[data]
	);

	const { isMatchMobile } = useAppBreakpoints();

	const dataMin = useMemo(() => {
		return isEmpty(data) ? 0 : Math.min(...data.map(item => Number(item.y)));
	}, [data]);
	const dataMax = useMemo(() => {
		return isEmpty(data) ? 0 : Math.max(...data.map(item => Number(item.y)));
	}, [data]);

	const valueAbs = Math.max(Math.abs(dataMin), Math.abs(dataMax));

	const maxYLength = (valueAbs * 1.2).toFixed(2).toString().length;

	return !loading ? (
		<ResponsiveContainer width={'100%'} height='100%'>
			<LineChartComponent
				data={data}
				margin={{
					top: 36,
					right: isMatchMobile ? 8 : 32,
					left: 4
				}}
				{...restProps}
			>
				<XAxis
					dataKey='x'
					tickFormatter={xTickFormatter}
					tick={{
						fontSize: 12,
						stroke: alpha(theme.palette.text.secondary, 0.3),
						strokeWidth: 0.5
					}}
					tickLine={{
						stroke: alpha(theme.palette.text.secondary, 0.3),
						strokeWidth: 0.5
					}}
					axisLine={{
						stroke: alpha(theme.palette.text.secondary, 0.3),
						strokeWidth: 0.5
					}}
					interval={
						interval
							? interval
							: data.length < 48
							? Math.floor(data.length / (isMatchMobile ? 5 : 8))
							: 22
					}
					padding={{
						left: isMatchMobile ? 10 : 40,
						right: isMatchMobile ? 10 : 40
					}}
				/>
				<YAxis
					dataKey='y'
					tickFormatter={value => {
						return Math.abs(value) < 1
							? `${Number(value).toFixed(2)}%`
							: `${Number(value).toFixed(2)}%`;
					}}
					domain={isEmpty(data) ? [-1, 1] : [-(valueAbs * 1.2), valueAbs * 1.2]}
					width={(maxYLength + 1) * 10}
					tick={{
						fontSize: 12,
						stroke: alpha(theme.palette.text.secondary, 0.3),
						strokeWidth: 0.5
					}}
					tickLine={{
						stroke: alpha(theme.palette.text.secondary, 0.3),
						strokeWidth: 0.5
					}}
					axisLine={{
						stroke: alpha(theme.palette.text.secondary, 0.3),
						strokeWidth: 0.5
					}}
					tickCount={8}
					range={[-1, 1]}
				/>
				<ReferenceLine
					y={0}
					stroke={alpha(theme.palette.text.secondary, 0.3)}
					strokeDasharray={'10, 6'}
				/>
				<defs>
					<linearGradient id={'liner-fill'} x1='1' y1='0' x2='0' y2='0'>
						<stop offset='0.02%' stopColor={'#17B7CD'} stopOpacity={1} />
						<stop offset='25%' stopColor={'#12B886'} stopOpacity={1} />
						<stop
							offset='100%'
							stopColor={
								theme.palette.mode === App_Theme.dark ? '#90AC00' : '#C2E800'
							}
							stopOpacity={1}
						/>
					</linearGradient>
				</defs>
				{!isEmpty(data) ? (
					<Tooltip
						wrapperStyle={{
							borderImage: 'none !important',
							background: 'transparent',
							boxShadow: 'unset'
						}}
						content={({ active, payload, label }) => {
							if (active && payload && payload.length) {
								return (
									<StyledTooltip>
										<Typography
											variant='caption'
											color='text.secondary'
											component='div'
										>
											{`${moment(label).format('MMM D, YYYY HH:mm')}`}
										</Typography>
										<Typography
											variant='caption'
											color={
												Number(payload[0].value) >= 0
													? 'success.main'
													: 'error.main'
											}
											component='div'
										>
											{`${Number(payload[0].payload?.originY)}%`}
										</Typography>
									</StyledTooltip>
								);
							}
							return null;
						}}
					/>
				) : null}
				<Line
					isAnimationActive={false}
					strokeWidth={4}
					dataKey='y'
					stroke={sameFundingRate ? '#90AC00' : `url(#liner-fill)`}
					dot={false}
				/>
			</LineChartComponent>
		</ResponsiveContainer>
	) : (
		<Box className='flex items-center justify-center h-full w-full'>
			<SectionLoading full={true} />
		</Box>
	);
}
