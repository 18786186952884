export enum Token_Type {
	Input = 0,
	Output = 1
}

export enum App_Theme {
	light = 'light',
	dark = 'dark'
}

export enum TipLevel {
	Info = 'info',
	Warning = 'warning',
	Error = 'error'
}

export enum Contract_Write_Mode {
	Unprepared = 'recklesslyUnprepared',
	Prepared = 'prepared'
}

export enum Dom_Size {
	PAGE_CONTAINER = 1440,
	ACTIVITY_CONTAINER = 1200,
	TRADE_FORM = 380,
	LIST_SCOLL = 400,
	CHART = 420,
	PADDING_BASE = 12,
	DIALOG_WIDTH_LARGE = 540,
	DIALOG_PADDING_BASE = 24,
	HEIGHT_HEADER = 64,
	HEIGHT_FOOTER = 182,
	MOBILE_HEIGHT_BOTTOM = 60,
	HEIGHT_LARGE_BUTTON = 48,
	HEIGHT_OPERATION = 345,
	SECTION_LOADING = 160,
	CLAIM_SECTION_LOADING = 224
}

export enum Media_Query_Size {
	SM = 640,
	MD = 768,
	LG = 1024,
	XL = 1200,
	'2XL' = 1440,
	'3XL' = 1920,
	'4XL' = 2560
}

export enum Page {
	Index = '/',
	IndexV1 = '/v1',
	IndexV2 = '/v1/',
	Trade = '/trade',
	TradeV1 = '/v1/trade',
	Markets = '/markets',
	MarketsV1 = '/v1/markets',
	Pools = '/pools',
	PoolsV1 = '/v1/pools',
	Referrals = '/referrals',
	ReferralsV1 = '/v1/referrals',
	ReferralsTraders = '/referrals/traders',
	ReferralsTradersV1 = '/v1/referrals/traders',
	ReferralsEFCMembers = '/referrals/members',
	ReferralsEFCMembersV1 = '/v1/referrals/members',
	ReferralsConnectors = '/referrals/connectors',
	ReferralsConnectorsV1 = '/v1/referrals/connectors',
	ReferralsLeaderboard = '/referrals/leaderboard',
	ReferralsLeaderboardV1 = '/v1/referrals/leaderboard',
	Earn = '/earn',
	EarnV1 = '/v1/earn',
	EarnStaking = '/earn/staking',
	EarnLiquidity = '/earn/liquidity',
	EarnAbf = '/earn/rbf',
	EarnAbfV1 = '/v1/earn/rbf',
	EarnArchitect = '/earn/architect',
	Leaderboard = '/leaderboard',
	LeaderboardV1 = '/v1/leaderboard'
}

export enum Order_Type {
	Open = 0,
	History = 1,
	SwapHistory = 2
}

export enum Order_Status_V1 {
	Created = 'Created',
	Executed = 'Executed',
	Cancelled = 'Cancelled'
}

export enum Order_Status_V2 {
	Created = 'CREATED',
	Executed = 'EXECUTED',
	Cancelled = 'CANCELLED'
}

export enum Order_Open_Type_V1 {
	Increase = 'Increase',
	Decrease = 'Decrease'
}

export enum Order_Open_Type_V2 {
	Increase = 'INCREASE',
	Decrease = 'DECREASE'
}

export enum Orders_Filter_Type {
	All = 0,
	Limit = 1,
	ProfitLoss = 2
}

export enum History_Filter_Type {
	Trade = 0,
	Position = 1,
	Order = 2
}

export enum Env {
	development = 'development',
	production = 'production'
}

export enum Module {
	Trade = 'Trade',
	Referral = 'Referral',
	Stats = 'Stats',
	TradeV2 = 'TradeV2',
	StatsV2 = 'StatsV2'
}

export enum Transaction_Type {
	Approve = 'Approve',
	Long = 'Long',
	LongV2 = 'LongV2',
	Short = 'Short',
	ShortV2 = 'ShortV2',
	LongLimitOrder = 'LongLimitOrder',
	LongLimitOrderV2 = 'LongLimitOrderV2',
	ShortLimitOrder = 'ShortLimitOrder',
	ShortLimitOrderV2 = 'ShortLimitOrderV2',
	UpdateLimitOrder = 'UpdateLimitOrder',
	UpdateLimitOrderV2 = 'UpdateLimitOrderV2',
	CancelledLimitOrder = 'CancelledLimitOrder',
	CancelledLimitOrderV2 = 'CancelledLimitOrderV2',
	IncreaseMargin = 'IncreaseMargin',
	IncreaseMarginV2 = 'IncreaseMarginV2',
	ReduceMargin = 'ReduceMargin',
	ReduceMarginV2 = 'ReduceMarginV2',
	IncreaseLiquidity = 'IncreaseLiquidity',
	IncreaseLiquidityV2 = 'IncreaseLiquidityV2',
	AppendLiquidityV2 = 'AppendLiquidityV2',
	IncreaseMarginLiquidity = 'IncreaseMarginLiquidity',
	IncreaseMarginLiquidityV2 = 'IncreaseMarginLiquidityV2',
	ReduceMarginLiquidity = 'ReduceMarginLiquidity',
	ReduceMarginLiquidityV2 = 'ReduceMarginLiquidityV2',
	RemoveLiquidity = 'RemoveLiquidity',
	RemoveLiquidityV2 = 'RemoveLiquidityV2',
	Close = 'Close',
	CloseV2 = 'CloseV2',
	TakeProfitStopLossAll = 'TakeProfitStopLossAll',
	TakeProfitStopLossFixed = 'TakeProfitStopLossFixed',
	CreateTakeProfitPosition = 'CreateTakeProfitPosition',
	CreateTakeProfitPositionV2 = 'CreateTakeProfitPositionV2',
	CreateTakeProfitPartial = 'CreateTakeProfitPartial',
	CreateTakeProfitPartialV2 = 'CreateTakeProfitPartialV2',
	CreateStopLossPosition = 'CreateStopLossPosition',
	CreateStopLossPositionV2 = 'CreateStopLossPositionV2',
	CreateStopLossPartial = 'CreateStopLossPartial',
	CreateStopLossPartialV2 = 'CreateStopLossPartialV2',
	UpdateTakeProfitPosition = 'UpdateTakeProfitPosition',
	UpdateTakeProfitPositionV2 = 'UpdateTakeProfitPositionV2',
	UpdateStopLossPosition = 'UpdateStopLossPosition',
	UpdateStopLossPositionV2 = 'UpdateStopLossPositionV2',
	UpdateTakeProfitPartial = 'UpdateTakeProfitPartial',
	UpdateTakeProfitPartialV2 = 'UpdateTakeProfitPartialV2',
	UpdateStopLossPartial = 'UpdateStopLossPartial',
	UpdateStopLossPartialV2 = 'UpdateStopLossPartialV2',
	CancelTakeProfitPosition = 'CancelTakeProfitPosition',
	CancelTakeProfitPositionV2 = 'CancelTakeProfitPositionV2',
	CancelStopLossPosition = 'CancelStopLossPosition',
	CancelStopLossPositionV2 = 'CancelStopLossPositionV2',
	CancelTakeProfitPartial = 'CancelTakeProfitPartial',
	CancelTakeProfitPartialV2 = 'CancelTakeProfitPartialV2',
	CancelStopLossPartial = 'CancelStopLossPartial',
	CancelStopLossPartialV2 = 'CancelStopLossPartialV2',
	IncreaseRiskBufferFund = 'IncreaseRiskBufferFund',
	ReduceRiskBufferFund = 'ReduceRiskBufferFund',
	CreateReferralCode = 'CreateReferralCode',
	BindReferralCode = 'BindReferralCode',
	ClaimAllReferrals = 'ClaimAllReferrals',
	ClaimMembers = 'ClaimMembers',
	ClaimMembersV2 = 'ClaimMembersV2',
	ClaimConnectors = 'ClaimConnectors',
	ClaimConnectorsV2 = 'ClaimConnectorsV2',
	ClaimAllEarns = 'ClaimAllEarns',
	ClaimPreviousAllEarns = 'ClaimPreviousAllEarns',
	ClaimStake = 'ClaimStake',
	ClaimPosition = 'ClaimPosition',
	ClaimPositionV2 = 'ClaimPositionV2',
	ClaimPool2 = 'ClaimPool2',
	ClaimLiqudiity = 'ClaimLiqudiity',
	ClaimLiqudiityV2 = 'ClaimLiqudiityV2',
	ClaimRBF = 'ClaimRBF',
	ClaimArchitect = 'ClaimArchitect',
	MintMember = 'MintMember',
	StakeAppToken = 'StakeAppToken',
	StakeLpToken = 'StakeLpToken',
	UnStakeAppToken = 'UnStakeAppToken',
	UnStakeLpToken = 'UnStakeLpToken',
	CancelAllOrders = 'CancelAllOrders',
	CancelAllOrdersV2 = 'CancelAllOrdersV2'
}

export enum Trade_Type {
	Market,
	Limit,
	Trigger
}

export enum Trade_Color {
	Long = 'long',
	Short = 'short'
}

export enum Input_Direction {
	In = 'In',
	Out = 'Out'
}

export enum Transaction_Status {
	Wallet = 'wallet',
	Pending = 'pending',
	Complete = 'complete',
	Success = 'success',
	Error = 'error',
	Reject = 'reject'
}

export enum Account_Transaction_Type {
	Trades = 'trades',
	Orders = 'orders'
}

export enum Margin_Adjust_Type {
	Add = 'Add',
	Reduce = 'Reduce'
}

export enum Impact_Type {
	Auto = 'auto',
	Custom = 'custom'
}

export enum Price_Benchmark {
	Market = 'market',
	Mark = 'mark'
}

export enum Profit_Loss_Type {
	Entire = 'Entire',
	Partial = 'Partial'
}

export enum TakeProfitStopLoss_Type {
	TakeProfit = 'takeProfit',
	StopLoss = 'stopLoss'
}

export enum Request_Status {
	Cancelled = 'Cancelled'
}

export const enum PriceFunctionGroup {
	GROUP_1_1 = 1,
	GROUP_2_1 = 2,
	GROUP_3_1 = 3,
	GROUP_4_1 = 4,
	GROUP_5_1 = 5,
	GROUP_6_1 = 6,
	GROUP_1_2 = 7,
	GROUP_2_2 = 8,
	GROUP_3_2 = 9,
	GROUP_4_2 = 10,
	GROUP_5_2 = 11,
	GROUP_6_2 = 12
}

export enum Side {
	LONG = 1,
	SHORT = 2
}

export enum Version {
	V1 = 'V1',
	V2 = 'V2'
}

export const SideFlip = (side: Side) =>
	side === Side.LONG ? Side.SHORT : Side.LONG;

export enum PositionOperation {
	PAY = 1,
	SIZE = 2
}

export enum Stake_Type {
	EQU = '0',
	LP = '1'
}

export enum Claim_Type {
	Member = 'Member',
	MemberV2 = 'MemberV2',
	Connector = 'Connector',
	ConnectorV2 = 'ConnectorV2',
	Staking = 'Staking',
	StakingLp = 'LiquidityNFTStaking',
	Liquidity = 'Liquidity',
	LiquidityV2 = 'LiquidityV2',
	RiskBufferFund = 'RiskBufferFund',
	Architect = 'Architect',
	Position = 'Position',
	PositionV2 = 'PositionV2',
	Pool2 = 'Pool2'
}

export enum Mint_Type {
	Member = 'Member',
	Connector = 'Connector',
	Architect = 'Architect',
	Position = 'Position'
}

export enum Leaderboard_Rank {
	Gold = 1,
	Silver = 2,
	Copper = 3
}
