import { Reducer, createSlice } from '@reduxjs/toolkit';
import { App_Theme, STORAGE_KEY_APP_THEME } from 'config/constants';
import { DEFAULT_CHAIN } from 'config/constants/connector/chains';
import { ChainConfig } from 'config/constants/connector/types';

import { BigNumber } from 'ethers';
import store from 'store2';
import { changeThemeForHtml } from 'utils';

import { RootState } from '..';

interface StateProps {
	theme: App_Theme;
	chain: ChainConfig;
	blockHeight: number;
	blockHeightBefore24h: number;
	blockHeightBefore7d: number;
	blockTimestamp: number;
	nativePriceUsd: string;
	gasPrice: BigNumber | null;
	positionMinExecutionFee: string;
	orderMinExecutionFee: string;
	appTimer: number;
}

const initialState: StateProps = {
	theme: store.get(STORAGE_KEY_APP_THEME, App_Theme.dark),
	chain: DEFAULT_CHAIN,
	blockHeight: 0,
	blockHeightBefore24h: 0,
	blockHeightBefore7d: 0,
	blockTimestamp: 0,
	nativePriceUsd: '',
	gasPrice: null,
	positionMinExecutionFee: '',
	orderMinExecutionFee: '',
	appTimer: 0
};

export const global = createSlice({
	name: 'global',
	initialState,
	reducers: {
		setTheme(state, { payload = App_Theme.dark }) {
			state.theme = payload;
			store.set(STORAGE_KEY_APP_THEME, payload);
			changeThemeForHtml(payload);
		},
		setChain(state, { payload }) {
			state.chain = payload;
		},
		setBlockHeight(state, { payload }) {
			state.blockHeight = payload;
		},
		setBlockHeightBefore24h(state, { payload }) {
			state.blockHeightBefore24h = payload;
		},
		setBlockHeightBefore7d(state, { payload }) {
			state.blockHeightBefore7d = payload;
		},
		setBlockTimestamp(state, { payload }) {
			state.blockTimestamp = payload;
		},
		setNativePriceUsd(state, { payload }) {
			state.nativePriceUsd = payload;
		},
		setGasPrice(state, { payload }) {
			state.gasPrice = payload;
		},
		setPositionMinExecutionFee(state, { payload }) {
			state.positionMinExecutionFee = payload;
		},
		setOrderMinExecutionFee(state, { payload }) {
			state.orderMinExecutionFee = payload;
		},
		setAppTimer(state, { payload }) {
			state.appTimer = payload;
		}
	}
});

export const globalBaseState = (state: RootState) => state.global;

export const {
	setTheme,
	setChain,
	setBlockHeight,
	setBlockHeightBefore24h,
	setBlockHeightBefore7d,
	setBlockTimestamp,
	setNativePriceUsd,
	setGasPrice,
	setPositionMinExecutionFee,
	setOrderMinExecutionFee,
	setAppTimer
} = global.actions;

export default global.reducer as Reducer<typeof initialState>;
