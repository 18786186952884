import {
	Action,
	AnyAction,
	ThunkAction,
	combineReducers,
	configureStore
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { persistReducer } from 'reduxjs-toolkit-persist';
import autoMergeLevel2 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'reduxjs-toolkit-persist/lib/storage';

import applicationReducer from './application/slice';
import earnRBFReducer from './earn/RBF/slice';
import earnArchitectReducer from './earn/architect/slice';
import earnLiquidityReducer from './earn/liquidity/slice';
import earnPositionReducer from './earn/position/slice';
import earnPool2Reducer from './earn/pool2/slice';
import earnReducer from './earn/slice';
import earnStakeReducer from './earn/stake/slice';
import { updateVersion } from './global/actions';
import globalReducer from './global/slice';
import limitReducer from './limit/slice';
import liquidityReducer from './liquidity/slice';
import marketReducer from './market/slice';
import poolsReducer from './pools/slice';
import recorsdReducer from './records/slice';
import referralsReducer from './referrals/slice';
import settingReducer from './setting/slice';
import tradeReducer from './trade/slice';
import txReducer from './tx/slice';

const rootReducers = combineReducers({
	global: globalReducer,
	tx: txReducer,
	trade: tradeReducer,
	market: marketReducer,
	limit: limitReducer,
	liquidity: liquidityReducer,
	application: applicationReducer,
	setting: settingReducer,
	records: recorsdReducer,
	pools: poolsReducer,
	earnStake: earnStakeReducer,
	earnPosition: earnPositionReducer,
	earnPool2: earnPool2Reducer,
	earnLiquidity: earnLiquidityReducer,
	earnRBF: earnRBFReducer,
	earnArchitect: earnArchitectReducer,
	earn: earnReducer,
	referrals: referralsReducer
});

const persistConfig = {
	key: 'root',
	storage: storage,
	stateReconciler: autoMergeLevel2,
	whitelist: ['global', 'setting', 'pools', 'trade', 'earnPool2']
};

const persistedReducer = persistReducer<any, AnyAction>(
	persistConfig,
	rootReducers
);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware => {
		return getDefaultMiddleware({
			serializableCheck: false
		});
	},
	devTools: import.meta.env.NODE_ENV !== 'production'
});

setupListeners(store.dispatch);
export type RootState = ReturnType<typeof rootReducers>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	any,
	Action<string>
>;
export interface ActionReducer<T, V extends Action = Action> {
	(state: T | undefined, action: V): T;
}

store.dispatch(updateVersion());
