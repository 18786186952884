import { Trans, t } from '@lingui/macro';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';

import { IArchitectItem } from 'graphql/useArchitectRewardsGraph';
import { earnArchitectBaseState } from 'state/earn/architect/slice';

import NoData from 'components/NoData';
import SectionLoading from 'components/SectionLoading';

import Cell from '../../../components/Common/Cell';
import Table from '../../../components/Table/StickyTable';
import { useAppBreakpoints } from '../../../hooks/useAppBreakpoints';
import { useAppSelector } from '../../../state/hooks';
import { txBaseState } from '../../../state/tx/slice';
import { formatNumber, plus, shortenSymbol } from '../../../utils';
import { Type_Earns } from '../types';

export default function Architect() {
	const { architectList } = useAppSelector(earnArchitectBaseState);
	const { quoteToken } = useAppSelector(txBaseState);
	const { isMatchMobile, isMatchPad } = useAppBreakpoints();

	const columns = [
		{
			key: 'ownerNft',
			width: '15%',
			label: <Trans>My NFT</Trans>,
			align: 'left',
			format: (row: IArchitectItem) => {
				return (
					<Typography
						variant={isMatchMobile ? 'inherit' : 'h6'}
						fontWeight={400}
						className='capitalize'
					>{`${Type_Earns.Architect} #${row.id}`}</Typography>
				);
			}
		},
		{
			key: 'totalFee',
			width: '20%',
			label: <Trans>Total Rewards</Trans>,
			align: 'right',
			format: (row: IArchitectItem) => {
				return (
					<Typography
						variant={isMatchMobile ? 'inherit' : 'h6'}
						fontWeight={400}
					>
						{`${formatNumber(
							plus(row.claimedReward, row.claimableReward)
						)} ${shortenSymbol(quoteToken)}`}
					</Typography>
				);
			}
		},
		{
			key: 'income',
			width: '33%',
			label: <Trans>Claimable Rewards</Trans>,
			align: 'right',
			format: (row: IArchitectItem) => {
				return (
					<Typography
						variant={isMatchMobile ? 'inherit' : 'h6'}
						fontWeight={400}
					>
						{`${formatNumber(row.claimableReward)} ${shortenSymbol(
							quoteToken
						)}`}
					</Typography>
				);
			}
		}
	];

	return (
		<div>
			{!architectList ? (
				<SectionLoading />
			) : architectList.length > 0 ? (
				<Table
					fontSize={14}
					columns={columns}
					rows={architectList}
					rowsRender={
						<div className='grid grid-cols-2 sm:grid-cols-1 gap-2'>
							{architectList.map((row, index) => {
								return (
									<Paper key={index} className='space-y-1 px-3 py-4'>
										<Typography
											className='capitalize'
											variant='h6'
											fontSize={14}
											fontWeight={500}
										>
											{`${Type_Earns.Architect} #${row.id}`}
										</Typography>
										<Cell
											label={t`Total Rewards`}
											value={`${formatNumber(
												plus(row.claimedReward, row.claimableReward)
											)} ${shortenSymbol(quoteToken)}`}
										/>
										<Cell
											label={t`Claimable`}
											value={`${formatNumber(
												row.claimableReward
											)} ${shortenSymbol(quoteToken)}`}
										/>
									</Paper>
								);
							})}
						</div>
					}
				/>
			) : (
				<NoData size='large'>
					<Trans>You haven&#39;t architect yet.</Trans>
				</NoData>
			)}
		</div>
	);
}
