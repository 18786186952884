import { useTheme } from '@mui/material/styles';

function Search() {
	// eslint-disable-next-line react/prop-types
	const theme = useTheme();
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			fill='none'
			viewBox='0 0 16 16'
		>
			<path
				fill={theme.custom.SearchColor}
				fillOpacity='0.9'
				fillRule='evenodd'
				d='M7.467 1a6.467 6.467 0 103.972 11.57l2.364 2.364a.8.8 0 101.132-1.131l-2.365-2.365A6.467 6.467 0 007.466 1zM2.6 7.467a4.867 4.867 0 119.733 0 4.867 4.867 0 01-9.733 0z'
				clipRule='evenodd'
			></path>
		</svg>
	);
}

export default Search;
