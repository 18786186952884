import { useMemo } from 'react';

import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import { Side } from 'config/constants';

import { useIndexPriceForTrade } from 'hooks/useIndexPriceForTrade';

import {
	div,
	isGreaterThan,
	isGreaterThanOrEqual,
	isLessThan,
	isZero,
	minus,
	multipliedBy,
	toDecimalPlaces
} from '../../utils';
import Cell from '../Common/Cell';
import TippingUnderline from '../TippingUnderline';

const PriceImpact = (props: {
	tradePrice: string | undefined;
	side: Side;
	paramIndexPrice?: string;
}) => {
	const { tradePrice, side, paramIndexPrice } = props;

	const { indexPrice: poolIndexPrice } = useIndexPriceForTrade(side);

	const indexPrice = paramIndexPrice ? paramIndexPrice : poolIndexPrice;
	const { priceImpactRate, color } = useMemo(() => {
		if (!tradePrice || !indexPrice || isZero(tradePrice)) {
			return {
				priceImpactRate: '-',
				color: 'inherit'
			};
		}
		let rate;
		if (side === Side.LONG) {
			rate = div(minus(indexPrice, tradePrice), indexPrice);
		} else {
			rate = div(minus(tradePrice, indexPrice), indexPrice);
		}

		const _rate = multipliedBy(rate, 100);

		return {
			priceImpactRate: toPercentFormatted(_rate),
			color: isZero(_rate)
				? 'inherit'
				: isGreaterThanOrEqual(_rate, 0.01)
				? 'success.main'
				: 'inherit'
		};
	}, [tradePrice, side]);

	return (
		<Cell
			label={
				<div className='flex items-center'>
					<TippingUnderline
						tooltip={
							<Trans>
								The price impact is the deviation between the estimated
								transaction price of the order and the current index price. When
								it is positive number, it means that the estimated transaction
								price is more advantageous, and negative number is vice versa.
							</Trans>
						}
						label={<Trans>Price Impact</Trans>}
					/>
				</div>
			}
			value={
				<Typography variant='body2' color={color}>
					{priceImpactRate}
				</Typography>
			}
		/>
	);
};

export default PriceImpact;

function toPercentFormatted(value: string, precision = 2): string {
	try {
		if (isZero(value)) {
			return '0.00%';
		}
		if (isGreaterThan(value, -0.01) && isLessThan(value, 0.01)) {
			return `<0.01%`;
		}
		if (isGreaterThanOrEqual(value, 0.01)) {
			return `+${toDecimalPlaces(value, precision)}%`;
		}
		return `${toDecimalPlaces(value, precision)}%`;
	} catch (e) {
		return '-';
	}
}
