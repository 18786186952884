import { useMemo } from 'react';

import { useAccount } from 'wagmi';

import _ from 'lodash';
import { IRequestItem } from 'types';

import { useTradePositionRequestsQuery } from './__generated_trade__/types-and-hooks';

const useTradePositionRequests = () => {
	const { address } = useAccount();

	const {
		data = {
			increasePositionRequests: [] as Array<IRequestItem>,
			decreasePositionRequests: [] as Array<IRequestItem>
		},
		networkStatus,
		refetch
	} = useTradePositionRequestsQuery({
		variables: { account: address },
		notifyOnNetworkStatusChange: true
	});

	const requestData = useMemo(() => {
		if (!data) {
			return [];
		}
		const { increasePositionRequests = [], decreasePositionRequests = [] } =
			data;
		return _.concat(
			increasePositionRequests,
			decreasePositionRequests
		) as Array<IRequestItem>;
	}, [data]);

	return { networkStatus, requestData, refetch };
};

export default useTradePositionRequests;
