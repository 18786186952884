import { ReactNode } from 'react';

import { Box, Typography } from '@mui/material';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';

export default function Module(props: {
	title?: ReactNode;
	titleColor?: string;
	extra?: ReactNode;
	size?: string;
	children?: ReactNode;
	className?: string;
	sx?: any
}) {
	const {
		title,
		titleColor,
		extra,
		size = 'large',
		children,
		className = '',
		...restProps
	} = props;
	const { isMatchMobile, isMatchPad } = useAppBreakpoints();
	return (
		<Box
			className={`space-y-3 rounded px-4 py-5 underLg:px-3 underLg:py-4 ${className}`}
			{...restProps}
		>
			{title && (
				<div className='flex items-center justify-between'>
					<Typography
						variant={
							size === 'large' ? 'h6' : isMatchMobile || isMatchPad ? 'inherit' : 'body1'
						}
						color={titleColor ? titleColor : undefined}
						className='flex-1'
						fontSize={isMatchMobile || isMatchPad ? 14 : 16}
						fontWeight={500}
					>
						{title}
					</Typography>
					{extra ? extra : null}
				</div>
			)}
			{children}
		</Box>
	);
}
