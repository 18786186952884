import { useMemo, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { Paper, Typography, useTheme } from '@mui/material';
import {
	APP_TOKEN_SYMBOL,
	QUOTE_USD,
	Transaction_Status,
	Transaction_Type
} from 'config/constants';

import { TransactionRecord } from 'entities/TransactionRecord';
import { IRBFMiningPool } from 'graphql/useRiskBufferFundRewardsGraph';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import _ from 'lodash';
import { earnRBFBaseState } from 'state/earn/RBF/slice';
import { useAppSelector } from 'state/hooks';
import { selectTransactionRecordList } from 'state/records/selector';
import { txBaseState } from 'state/tx/slice';
import {
	formatNumber,
	isGreaterThan,
	isZero,
	shortenSymbolNative,
	toPercent,
	toQuoteAmount
} from 'utils';

import Cell from 'components/Common/Cell';
import { CommonStyledOutlinedButton } from 'components/Common/Styled';
import NoData from 'components/NoData';
import SectionLoading from 'components/SectionLoading';
import Loading from 'components/Svg/Icons/Loading';
import Table from 'components/Table/StickyTable';
import Tipping from 'components/Tipping';

import TippingUnderline from '../../../components/TippingUnderline';
import RiskBufferFundDialog from '../../Liquidity/RiskBufferFundDialog';

export default function RBFMining() {
	const theme = useTheme();
	const { appToken } = useAppSelector(txBaseState);
	const { RBFList, isLoadingRBFList } = useAppSelector(earnRBFBaseState);
	const transactionRecordList = useAppSelector(selectTransactionRecordList);

	const isLogin = useCheckLogin();
	const { currentChainId } = useCurrentChain();
	const { isMatchMobile, isMatchPad } = useAppBreakpoints();

	const [riskBufferFundDialogIsOpen, setRiskBufferFundDialogIsOpen] =
		useState<boolean>(false);
	const [currentItem, setCurrentItem] = useState<IRBFMiningPool | null>(null);

	const increaseRiskBufferFundRecords = useMemo(() => {
		if (!transactionRecordList.length) {
			return null;
		}
		return transactionRecordList.filter((tx: TransactionRecord) => {
			return tx.type === Transaction_Type.IncreaseRiskBufferFund;
		});
	}, [transactionRecordList]);

	const mergedList = useMemo(() => {
		if (
			!increaseRiskBufferFundRecords ||
			increaseRiskBufferFundRecords.length === 0
		) {
			return RBFList;
		}
		if (!RBFList) {
			return null;
		}
		const mergedList = RBFList.map((item: IRBFMiningPool) => {
			const target = _.find(
				increaseRiskBufferFundRecords,
				(txRecord: TransactionRecord) => {
					return txRecord.record.pool === item.id;
				}
			);
			if (target) {
				return {
					...item,
					status: Transaction_Status.Pending
				};
			} else {
				return item;
			}
		});
		return mergedList;
	}, [RBFList, increaseRiskBufferFundRecords]);

	const onCloseRiskBufferFundDialog = () => {
		setRiskBufferFundDialogIsOpen(false);
	};

	const onHandleContribute = item => {
		setCurrentItem(item);
		setRiskBufferFundDialogIsOpen(true);
	};
	const columns = [
		{
			key: 'LPsforRBF',
			width: '12%',
			label: <Trans>Pool</Trans>,
			align: 'left',
			format: (row: IRBFMiningPool) => {
				return (
					<div className='flex space-x-1'>
						<Typography
							variant='h6'
							fontSize={isMatchMobile || isMatchPad ? 14 : 16}
							fontWeight={500}
						>
							{`${shortenSymbolNative(
								row.baseToken,
								currentChainId
							)}/${QUOTE_USD}`}
						</Typography>
						{row.status === Transaction_Status.Pending && <Loading size={20} />}
					</div>
				);
			}
		},
		{
			key: 'APR',
			width: '10%',
			label: (
				<div className='flex justify-end items-center'>
					<TippingUnderline
						tooltip={
							<Typography>
								<Trans>
									RBF Mining APR = Daily EQU Emission Value / Total Contribution
									* 365 * 100%
								</Trans>
							</Typography>
						}
						label={<Trans>APR</Trans>}
					/>
				</div>
			),
			align: 'right',
			format: (row: IRBFMiningPool) => {
				return row.apr &&
					isGreaterThan(row.apr, 0) &&
					isGreaterThan(row.totalContribution, 0) ? (
					<Typography variant='body1' color='success.main'>
						{toPercent(row.apr)}
					</Typography>
				) : (
					<Typography variant='body1' color='secondary'>
						{toPercent(0)}
					</Typography>
				);
			}
		},
		{
			key: 'OutputRate',
			width: '14%',
			label: <Trans>Daily Emission</Trans>,
			align: 'right',
			format: (row: IRBFMiningPool) => {
				return (
					<Typography variant='body2'>
						{row.riskBufferFundRewardPerDay
							? `${formatNumber(
									row.riskBufferFundRewardPerDay,
									2,
									true
							  )} ${APP_TOKEN_SYMBOL}`
							: '-'}
					</Typography>
				);
			}
		},
		{
			key: 'TotalContribution',
			width: '18%',
			label: <Trans>Total Contribution</Trans>,
			align: 'right',
			format: (row: IRBFMiningPool) => {
				return (
					<Typography variant='body2'>
						{row.totalContribution && isGreaterThan(row.totalContribution, 0)
							? `${toQuoteAmount(row.totalContribution)} ${QUOTE_USD}`
							: '-'}
					</Typography>
				);
			}
		},
		{
			key: 'MyContribution',
			width: '16%',
			label: <Trans>My Contribution</Trans>,
			align: isMatchPad ? 'left' : 'right',
			format: (row: IRBFMiningPool) => {
				if (!isLogin) {
					return <Typography variant='body2'>-</Typography>;
				}
				if (!row.riskBufferFundReward || !row.riskBufferFundReward.liquidity) {
					return <Typography variant='body2'>{`0 ${QUOTE_USD}`}</Typography>;
				}
				return (
					<Typography variant='body2'>
						{`${toQuoteAmount(
							row.riskBufferFundReward.liquidity
						)} ${QUOTE_USD}`}
					</Typography>
				);
			}
		},
		{
			key: 'ClaimableRewards',
			width: '16%',
			label: <Trans>Claimable Rewards</Trans>,
			align: 'right',
			format: (row: IRBFMiningPool) => {
				if (!isLogin) {
					return <Typography variant='body2'>-</Typography>;
				}
				if (!row.claimableIncome || isZero(row.claimableIncome)) {
					return (
						<Typography variant='body2'>{`0 ${APP_TOKEN_SYMBOL}`}</Typography>
					);
				}
				return (
					<Typography variant='body2'>
						{`${formatNumber(
							row.claimableIncome,
							appToken.positionUnits
						)} ${APP_TOKEN_SYMBOL}`}
					</Typography>
				);
			}
		},
		{
			key: 'op',
			width: '14%',
			label: '',
			align: isMatchMobile ? 'left' : 'right',
			format: (row: IRBFMiningPool) => {
				return (
					<CommonStyledOutlinedButton
						variant='outlined'
						color='secondary'
						onClick={() => onHandleContribute(row)}
						disabled={
							!isLogin ||
							!row.riskBufferFundReward ||
							!row.riskBufferFundReward.liquidity
						}
					>
						<Trans>Withdraw</Trans>
					</CommonStyledOutlinedButton>
				);
			}
		}
	];

	return (
		<div>
			{isLoadingRBFList ? (
				<SectionLoading />
			) : mergedList && mergedList.length > 0 ? (
				<Table
					fontSize={14}
					columns={columns}
					rows={mergedList}
					rowsRender={
						<div
							className='grid grid-cols-2 sm:grid-cols-1 gap-2'
							style={{ background: theme.palette.background.default }}
						>
							{mergedList.map((item, index) => {
								return (
									<Paper key={index} className='space-y-1 px-3 py-4'>
										<div className='flex items-center justify-between'>
											<div>
												{columns
													.find(column => column.key === 'LPsforRBF')
													?.format(item)}
											</div>
											<div>
												{columns
													.find(column => column.key === 'op')
													?.format(item)}
											</div>
										</div>
										{columns
											.filter(
												column => !['LPsforRBF', 'op'].includes(column.key)
											)
											.map((column, columnIndex) => {
												return (
													<Cell
														label={column.label}
														value={column.format(item)}
														key={columnIndex}
													/>
												);
											})}
									</Paper>
								);
							})}
						</div>
					}
				/>
			) : (
				<NoData size='large'>
					<Trans>You haven&#39;t RBF Mining yet.</Trans>
				</NoData>
			)}
			{riskBufferFundDialogIsOpen && currentItem && (
				<RiskBufferFundDialog
					onClose={onCloseRiskBufferFundDialog}
					realizedPnL={currentItem.realizedPnL}
					unrealizedPnL={currentItem.unrealizedPnL}
					totalContribution={currentItem.totalContribution}
					poolAddress={currentItem.id}
					baseToken={currentItem.baseToken}
				/>
			)}
		</div>
	);
}
