import React, { useCallback, useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { Trans, t } from '@lingui/macro';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Box, Divider, ListItem, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import Decimal from 'decimal.js';
import moment from 'moment';
import { useAccount } from 'wagmi';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';

import Cell from 'components/Common/Cell';
import { CommonStyledList } from 'components/Common/Styled';
import Description from 'components/Description';
import NoData from 'components/NoData';
import Stronger from 'components/Stronger';

import Dialog from '../../../components/Common/Dialog';
import Leverage from '../../../components/Leverage';
import SectionLoading from '../../../components/SectionLoading';
import { DEFAULT_PAGE_SIZE, QUOTE_USD } from '../../../config/constants';
import { useLiquidityPositionHistoriesRequest } from '../../../fetch/useRequest';
import { Position_History_Status_V2 } from '../../../graphql/useMyPositionHistoryGraph';
import { useCheckLogin, useCurrentChain } from '../../../hooks/useCurrentChain';
import { useAppSelector } from '../../../state/hooks';
import { poolsBaseState } from '../../../state/pools/slice';
import {
	abs,
	div,
	formatDate,
	formatNumber,
	isNegative,
	isPositive,
	plus,
	shortenSymbolNative,
	toQuoteAmount
} from '../../../utils';
import PassivePositionHistories from './PassivePositionHistories';

const LiquidityPositionHistory = ({ market }: { market: string }) => {
	const { address } = useAccount();
	const isLogin = useCheckLogin();
	const { currentChainId } = useCurrentChain();
	const { poolInfo } = useAppSelector(poolsBaseState);
	const { isMatchMobile } = useAppBreakpoints();
	const theme = useTheme();

	const [from, setFrom] = useState(null);
	const [data, setData] = useState([]);
	const [hasMore, setHasMore] = useState(true);
	const [fetching, setFetching] = useState(false);
	const [loading, setLoading] = useState(false);

	const { trigger } = useLiquidityPositionHistoriesRequest({
		account: address,
		market,
		from,
		limit: DEFAULT_PAGE_SIZE
	});

	const fetchAction = useCallback(
		(index: number) => {
			if (hasMore && !fetching && address) {
				setFetching(true);
				return trigger()
					.then(res => {
						setHasMore(res.data.hasMore);
						setData(
							index === 0
								? res.data.positionHistories
								: [...data, ...res.data.positionHistories]
						);
						setFrom(res.data.next);
					})
					.finally(() => setFetching(false));
			} else {
				return Promise.resolve();
			}
		},
		[address, hasMore, fetching]
	);

	useEffect(() => {
		setData([]);
		setLoading(true);
		fetchAction(0).finally(() => setLoading(false));
	}, []);

	const statusMap = {
		[Position_History_Status_V2.Closed]: <Trans>Closed</Trans>,
		[Position_History_Status_V2.PartiallyClosed]: (
			<Trans>Partially Closed</Trans>
		),
		[Position_History_Status_V2.Liquidated]: <Trans>Liquidated</Trans>
	};

	const rows = data.map(item => ({
		leverage: abs(div(item.closedLiquidity, item.marginIncreased)),
		entryTime: formatDate(moment(item.entryTime)),
		closedTime: formatDate(moment(item.closedTime)),
		status: item.status,
		marginIncreased: item.marginIncreased,
		marginDecreased: item.marginDecreased,
		openedLiquidity: item.openedLiquidity,
		closedLiquidity: item.closedLiquidity,
		pnl: item.pnl,
		fee: item.fee,
		fundingFee: item.fundingFee,
		totalFee: plus(item.fee, item.fundingFee),
		id: item.id
	}));
	const height = isMatchMobile ? 226 : 118;

	const [id, setId] = useState(null);
	const onClose = () => setId(null);

	return (
		<>
			{loading && <SectionLoading />}
			{!loading && (
				<CommonStyledList>
					{isLogin && rows.length ? (
						<Virtuoso
							endReached={fetchAction}
							style={{
								height: Math.min(height * 5, height * rows.length),
								overflowY: 'auto'
							}}
							data={rows}
							itemContent={(index, item) =>
								!isMatchMobile ? (
									<ListItem
										key={index}
										className='pr-1'
										sx={{ padding: `6px 0px` }}
									>
										<Box className={classNames('w-full space-y-1')}>
											<aside className='flex items-center justify-between'>
												<section className='flex items-center space-x-5'>
													<div className='flex items-center'>
														<Typography variant='h6'>{`${shortenSymbolNative(
															poolInfo.baseToken,
															currentChainId
														)}/${QUOTE_USD}`}</Typography>

														<Typography variant='body1'>
															<span className='ml-1'>
																<Leverage value={item.leverage} />
															</span>
														</Typography>
													</div>
													<Typography
														variant='body2'
														color='primary.main'
														className='cursor-pointer'
														onClick={() => setId(item.id)}
													>
														<Trans>Passive Position History</Trans>
														<KeyboardArrowRight
															color='inherit'
															fontSize='small'
														/>
													</Typography>
												</section>
												<Typography variant='body2' color='secondary'>
													{statusMap[item.status]}
												</Typography>
											</aside>
											<div className='flex justify-between flex-wrap py-1 w-full items-start'>
												<section className='space-y-3 my-1.5'>
													<Description label={<Trans>Time Opened</Trans>}>
														<Typography variant='body2'>
															{item.entryTime}
														</Typography>
													</Description>
													<Description label={<Trans>Time Closed</Trans>}>
														<Typography variant='body2'>
															{item.closedTime}
														</Typography>
													</Description>
												</section>
												<section className='space-y-3 my-1.5'>
													<Description label={<Trans>Added Margin</Trans>}>
														<Typography variant='body2'>
															{formatNumber(item.marginIncreased)}
														</Typography>
													</Description>
													<Description label={<Trans>Reduced Margin</Trans>}>
														<Typography variant='body2'>
															{formatNumber(item.marginDecreased)}
														</Typography>
													</Description>
												</section>

												<section className='space-y-3 my-1.5'>
													<Description label={<Trans>Added Liquidity</Trans>}>
														<Typography variant='body2'>
															{formatNumber(item.openedLiquidity)}
														</Typography>
													</Description>
													<Description label={<Trans>Removed Liquidity</Trans>}>
														<Typography variant='body2'>
															{formatNumber(item.closedLiquidity)}
														</Typography>
													</Description>
												</section>

												<section className='my-1.5 block space-y-3 sm:flex sm:gap-4 sm:space-y-0'>
													<Description label={<Trans>Fee Income</Trans>}>
														<Stronger
															value={
																<Typography
																	variant='body2'
																	color={
																		isPositive(item.totalFee)
																			? theme.palette.success.main
																			: theme.palette.text.primary
																	}
																>
																	{toQuoteAmount(
																		item.totalFee,
																		2,
																		Decimal.ROUND_DOWN,
																		true
																	)}
																</Typography>
															}
															tooltip={
																<div>
																	<Cell
																		label={<Trans>Trading Fees</Trans>}
																		value={<div>{formatNumber(item.fee)}</div>}
																	/>
																	<Cell
																		label={<Trans>Funding Fees</Trans>}
																		value={
																			<div>{formatNumber(item.fundingFee)}</div>
																		}
																	/>
																</div>
															}
														/>
													</Description>
													<Description label={<Trans>Closed PnL</Trans>}>
														<Typography
															variant='body2'
															color={
																isPositive(item.pnl)
																	? theme.palette.success.main
																	: isNegative(item.pnl)
																	? theme.palette.error.main
																	: theme.palette.text.primary
															}
														>
															{toQuoteAmount(
																item.pnl,
																2,
																Decimal.ROUND_DOWN,
																true
															)}
														</Typography>
													</Description>
												</section>
											</div>
										</Box>
									</ListItem>
								) : (
									<ListItem
										key={index}
										className='pr-1'
										sx={{ padding: `6px 0px` }}
									>
										<Box className={classNames('w-full space-y-1')}>
											<aside className='flex-col'>
												<Typography
													variant='body2'
													color='primary.main'
													className='cursor-pointer text-sm'
													onClick={() => setId(item.id)}
												>
													<Trans>Passive Position History</Trans>
													<KeyboardArrowRight
														color='inherit'
														fontSize='small'
													/>
												</Typography>
												<section className='flex justify-between mt-3'>
													<div className='flex items-center'>
														<Typography
															variant='body1'
															className='text-sm'
														>{`${shortenSymbolNative(
															poolInfo.baseToken,
															currentChainId
														)}/${QUOTE_USD}`}</Typography>
														·
														<Typography variant='body1'>
															<Leverage value={item.leverage} />
														</Typography>
													</div>
													<Typography
														variant='body2'
														color='secondary'
														className='text-sm'
													>
														{statusMap[item.status]}
													</Typography>
												</section>
											</aside>
											<div className='flex justify-between flex-wrap py-1 w-full items-start'>
												<section className='space-y-3 my-1.5'>
													<Description label={<Trans>Time Opened</Trans>}>
														<Typography variant='body2'>
															{item.entryTime}
														</Typography>
													</Description>
													<Description label={<Trans>Time Closed</Trans>}>
														<Typography variant='body2'>
															{item.closedTime}
														</Typography>
													</Description>
												</section>
												<section className='space-y-3 my-1.5'>
													<Description label={<Trans>Added Margin</Trans>}>
														<Typography variant='body2'>
															{formatNumber(item.marginIncreased)}
														</Typography>
													</Description>
													<Description label={<Trans>Reduced Margin</Trans>}>
														<Typography variant='body2'>
															{formatNumber(item.marginDecreased)}
														</Typography>
													</Description>
												</section>

												<section className='space-y-3 my-1.5'>
													<Description
														label={<Trans>Fee Income</Trans>}
														align='right'
														className='text-right'
													>
														<Stronger
															value={
																<Typography
																	variant='body2'
																	color={
																		isPositive(item.totalFee)
																			? theme.palette.success.main
																			: theme.palette.text.primary
																	}
																>
																	{toQuoteAmount(
																		item.totalFee,
																		2,
																		Decimal.ROUND_DOWN,
																		true
																	)}
																</Typography>
															}
															tooltip={
																<div>
																	<Cell
																		label={<Trans>Trading Fees</Trans>}
																		value={<div>{formatNumber(item.fee)}</div>}
																	/>
																	<Cell
																		label={<Trans>Funding Fees</Trans>}
																		value={
																			<div>{formatNumber(item.fundingFee)}</div>
																		}
																	/>
																</div>
															}
														/>
													</Description>
													<Description
														label={<Trans>Closed PnL</Trans>}
														align='right'
													>
														<Typography
															variant='body2'
															color={
																isPositive(item.pnl)
																	? theme.palette.success.main
																	: isNegative(item.pnl)
																	? theme.palette.error.main
																	: theme.palette.text.primary
															}
														>
															{toQuoteAmount(
																item.pnl,
																2,
																Decimal.ROUND_DOWN,
																true
															)}
														</Typography>
													</Description>
												</section>

												<section className='my-1.5 flex justify-between w-full'>
													<Description label={<Trans>Added Liquidity</Trans>}>
														<Typography variant='body2'>
															{formatNumber(item.openedLiquidity)}
														</Typography>
													</Description>
													<Description
														label={<Trans>Removed Liquidity</Trans>}
														align='right'
													>
														<Typography variant='body2'>
															{formatNumber(item.closedLiquidity)}
														</Typography>
													</Description>
												</section>
											</div>
											<Divider
												className='mb-5'
												sx={{ borderStyle: 'dashed' }}
											/>
										</Box>
									</ListItem>
								)
							}
						/>
					) : (
						<NoData>
							<Trans>No position history</Trans>
						</NoData>
					)}
					{loading && <SectionLoading full />}
				</CommonStyledList>
			)}
			<Dialog
				size='large'
				title={t`Passive Position History`}
				open={id}
				onClose={onClose}
			>
				<PassivePositionHistories
					market={market}
					liquidity_position_history_id={id}
				/>
			</Dialog>
		</>
	);
};

export default LiquidityPositionHistory;
