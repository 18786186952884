import { BigNumber } from 'ethers';

/**
 * Returns the gas value plus a margin for unexpected or variable gas costs
 * @param value the gas value to pad
 */
export function calculateGasMargin(
	value: BigNumber | string | number
): BigNumber {
	return BigNumber.from(value).mul(120).div(100);
}
