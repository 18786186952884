import React from 'react';

import { Chain } from 'wagmi';

export type SvgProps = React.SVGProps<SVGSVGElement> & { fill?: string };

export enum ConnectorNames {
	MetaMask = "MetaMask",
	Injected = 'injected',
	WalletConnect = 'WalletConnect',
	Coinbase = 'Coinbase Wallet',
	Okxwallet = 'Okxwallet',
	Bitget = 'Bitget',
	Rabby = 'Rabby',
	Trust = 'Trust',
	Bybit = 'Bybit'
}

export type Login = (connectorId: ConnectorNames) => void;

export interface ConnectorConfig {
	id: string;
	title: string;
	icon: string;
	connectorId: ConnectorNames;
	href?: string;
	installed?: boolean;
}

export interface ChainConfig extends Chain {
	icon: string;
}
