import { useMemo } from 'react';

import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { selectVersion } from 'state/setting/selector';

import {
	Contract_Write_Mode,
	Transaction_Type,
	Version
} from '../config/constants';
import { selectContractByVersion } from '../config/contracts';
import { TransactionDescription } from '../entities/TransactionDescription';
import { useAppSelector } from '../state/hooks';
import { txBaseState } from '../state/tx/slice';
import { RecordForClaimArchitects } from '../types';
import { formatNumber, isPositive, parseUnits } from '../utils';
import { useComputeGasLimit } from './useComputeGasLimit';
import { useCurrentChain } from './useCurrentChain';
import { useSelectRewardConnectorByVersion } from './useSelectByVersion';
import { useSendTransaction } from './useSendTransaction';

const useSubmitClaimArchitect = (tokenIds: string[], quoteAmount: string) => {
	const { address } = useAccount();
	const { quoteToken } = useAppSelector(txBaseState);
	const currentVersion = useAppSelector(selectVersion);
	const { currentChainId } = useCurrentChain();

	const { rewardCollectorInterface, rewardCollectorABI } =
		useSelectRewardConnectorByVersion();

	const { args, record, description } = useMemo(() => {
		if (!address || !quoteToken || !isPositive(quoteAmount)) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}
		const results = [];
		const quoteValue = parseUnits(quoteAmount, quoteToken.decimals);
		if (isPositive(quoteValue)) {
			results.push(
				rewardCollectorInterface.encodeFunctionData(
					currentVersion === Version.V1
						? 'collectArchitectRewardBatch'
						: 'collectContractsV1ArchitectRewardBatch',
					[tokenIds]
				)
			);

			results.push(
				rewardCollectorInterface.encodeFunctionData('sweepToken', [
					quoteToken.address,
					quoteValue,
					address
				])
			);
		}

		const record = {
			transactionType: Transaction_Type.ClaimArchitect,
			tokenIds,
			quoteAmount
		} as RecordForClaimArchitects;
		const description = TransactionDescription.Claim(
			formatNumber(quoteAmount, quoteToken.positionUnits)
		);

		return {
			args: [results],
			record,
			description
		};
	}, [
		address,
		tokenIds,
		quoteAmount,
		quoteToken,
		currentVersion,
		rewardCollectorInterface
	]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address: selectContractByVersion(
				currentVersion,
				currentChainId,
				'RewardCollector'
			),
			abi: rewardCollectorABI,
			functionName: 'multicall',
			args
		} as UseContractWriteConfig;
	}, [currentChainId, args, currentVersion, rewardCollectorABI]);

	const { gasLimit } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirmed, isConfirming, error } =
		useSendTransaction(contractWriteArgs, gasLimit);

	const onConfirm = () => {
		if (!record) return;
		onSendTransaction(record, description);
	};

	return {
		onConfirm,
		isConfirming,
		isConfirmed,
		error
	};
};

export default useSubmitClaimArchitect;
