import { useEffect, useMemo, useState } from 'react';

import { useApolloClients } from 'context/ApolloClientsContext';
import Decimal from 'decimal.js';
import { useAccount } from 'wagmi';

import { plus } from '../utils';
import { useReferralCodeRefereesQuery } from './__generated_referral__/types-and-hooks';

export function useReferralCodeRefereesGraph(referralCode: string) {
	const { clientReferral } = useApolloClients();
	const { address } = useAccount();
	const [loading, setLoading] = useState<boolean>(true);
	const {
		data,
		loading: isLoading,
		refetch
	} = useReferralCodeRefereesQuery({
		variables: {
			referralCode: referralCode
		},
		skip: !address,
		client: clientReferral
	});

	useEffect(() => {
		if (!isLoading) {
			setLoading(isLoading);
		}
	}, [isLoading]);

	return useMemo(() => {
		if (!data || !data?.referralCodes || !data?.referralCodes?.length) {
			return {
				data: [],
				loading,
				refetch
			};
		}
		return {
			data: data.referralCodes[0].accounts,
			loading,
			refetch
		};
	}, [data, refetch, loading]);
}
