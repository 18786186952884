import { useEffect } from 'react';
import { useInterval, useUpdateEffect } from 'react-use';

import { GLOBAL_INTERVAL_TIMEOUT } from 'config/constants';
import { DEFAULT_CHAIN } from 'config/constants/connector/chains';
import { WRAPPED_NATIVE_CURRENCY } from 'config/constants/tokens';
import { getGasConfigRequest } from 'fetch/useRequest';
import moment from 'moment';

import { ethers } from 'ethers';
import { useTokenMetaGraph } from 'graphql/useTokenMetaGraph';
import { useGetBlockNumbersForUni } from 'hooks/V3/useUniV3Apr';
import { useCurrentChain } from 'hooks/useCurrentChain';
import useIsWindowVisible from 'hooks/useIsWindowVisible';
import store from 'store2';

import { useGetBlockNumbers } from '../../hooks/useBlockNumber';
import { useAppDispatch, useAppSelector } from '../hooks';
import { settingBaseState } from '../setting/slice';
import {
	setAppTimer,
	setBlockHeight,
	setBlockHeightBefore7d,
	setBlockHeightBefore24h,
	setBlockTimestamp,
	setChain,
	setNativePriceUsd,
	setOrderMinExecutionFee,
	setPositionMinExecutionFee
} from './slice';

export default function GlobalUpdater() {
	const dispatch = useAppDispatch();

	const isWindowVisible = useIsWindowVisible();
	const { currentChain, currentChainId, checkChain } = useCurrentChain();

	const {
		blockLatest,
		blockLatestTimestamp,
		blockBefore24h,
		blockBefore7d,
		refechBlockNumbers
	} = useGetBlockNumbers();

	const {
		blockLatest: blockLatestForUni,
		blockLatestTimestamp: blockLatestTimestampForUni,
		blockBefore24h: blockBefore24hForUni,
		blockBefore7d: blockBefore7dForUni,
		refechBlockNumbers: refechBlockNumbersForUni
	} = useGetBlockNumbersForUni();

	useUpdateEffect(() => {
		if (currentChain.testnet) {
			if (blockLatest) {
				// console.log('[new block number] blockNumber: ', blockLatest);
				// console.log('blockLatestTimestamp: ', blockLatestTimestamp);
				dispatch(setBlockHeight(blockLatest));
				dispatch(setBlockTimestamp(blockLatestTimestamp));
			}
			return;
		}
		if (blockLatestForUni) {
			// console.log('[new block number] blockLatestForUni: ', blockLatestForUni);
			// console.log('blockLatestTimestampForUni: ', blockLatestTimestampForUni);
			dispatch(setBlockHeight(blockLatestForUni));
			dispatch(setBlockTimestamp(blockLatestTimestampForUni));
		}
	}, [
		currentChain,
		blockLatest,
		blockLatestTimestamp,
		blockLatestForUni,
		blockLatestTimestampForUni
	]);

	useUpdateEffect(() => {
		if (currentChain.testnet) {
			if (blockBefore24h) {
				dispatch(setBlockHeightBefore24h(blockBefore24h));
			}
			if (blockBefore7d) {
				dispatch(setBlockHeightBefore7d(blockBefore7d));
			}
			return;
		}
		if (blockBefore24hForUni) {
			dispatch(setBlockHeightBefore24h(blockBefore24hForUni));
		}
		if (blockBefore7dForUni) {
			dispatch(setBlockHeightBefore7d(blockBefore7dForUni));
		}
	}, [blockBefore24h, blockBefore7d, blockBefore24hForUni, currentChain]);

	// Eth美元价格
	const { data } = useTokenMetaGraph(
		WRAPPED_NATIVE_CURRENCY[currentChainId].address
	);

	useEffect(() => {
		if (data?.token?.price) {
			dispatch(setNativePriceUsd(data?.token?.price));
		}
	}, [data]);

	// 全局后端配置
	const { currentVersion } = useAppSelector(settingBaseState);
	const { data: dataConfigInfo, refetch: refetchConfigInfo } =
		getGasConfigRequest(currentVersion);
	useEffect(() => {
		if (
			!dataConfigInfo ||
			!dataConfigInfo.position_execution_fee ||
			!dataConfigInfo.order_execution_fee
		) {
			return;
		}
		const positionMinExecutionFee = dataConfigInfo.position_execution_fee;
		const orderMinExecutionFee = dataConfigInfo.order_execution_fee;
		dispatch(
			setPositionMinExecutionFee(
				ethers.utils.formatEther(positionMinExecutionFee)
			)
		);
		dispatch(
			setOrderMinExecutionFee(ethers.utils.formatEther(orderMinExecutionFee))
		);
	}, [dataConfigInfo]);

	useEffect(() => {
		dispatch(setAppTimer(moment().unix()));
		if (currentChain.testnet) {
			refechBlockNumbers();
			return;
		}
		refechBlockNumbersForUni();
	}, [currentChain]);

	useInterval(
		() => {
			refetchConfigInfo();
			dispatch(setAppTimer(moment().unix()));
			if (currentChain.testnet) {
				refechBlockNumbers();
				return;
			}
			refechBlockNumbersForUni();
		},
		isWindowVisible ? GLOBAL_INTERVAL_TIMEOUT : null
	);

	// 清理wagmi的缓存
	useEffect(() => {
		store.remove('wagmi.cache');
	}, []);

	// 设置当前默认链
	useEffect(() => {
		if (!checkChain(currentChainId)) {
			dispatch(setChain(DEFAULT_CHAIN));
		}
	}, []);

	return null;
}
