import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUpdate } from 'react-use';

import { Trans } from '@lingui/macro';
import ArrowDownIcon from '@mui/icons-material/ExpandMore';
import { Box, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import {
	Dom_Size,
	MAX_AMOUNT_FORMATTER_LIMIT,
	Page,
	QUOTE_USD,
	Transaction_Status,
	Transaction_Type,
	Version
} from 'config/constants';
import { useAccount } from 'wagmi';

import { TransactionRecord } from 'entities/TransactionRecord';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import _ from 'lodash';
import FreeTradingFeeWarning, {
	Type_Free_Trading_Fee
} from 'pages/components/FreeTradingFeeWarning';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
	poolsBaseState,
	setPoolAddress,
	setV2PoolAddress
} from 'state/pools/slice';
import { selectTransactionRecordList } from 'state/records/selector';
import { selectVersion } from 'state/setting/selector';
import { txBaseState } from 'state/tx/slice';
import type { Merge } from 'type-fest';
import {
	RecordForAdjustMarginLiquidity,
	RecordForLiqudility,
	RecordForRemoveLiquidity
} from 'types';
import {
	amountFormatter,
	div,
	formatLeverage,
	formatModuleNumber,
	formatNumber,
	isEqualTo,
	isGreaterThan,
	isPositive,
	isZero,
	neg,
	shortenSymbol,
	shortenSymbolNative,
	toPercent,
	toQuoteAmount
} from 'utils';

import Cell from 'components/Common/Cell';
import { CommonStyledDivider } from 'components/Common/Styled';
import CommonButton from 'components/Common/StyledButton';
import StyledTab from 'components/Common/StyledTab';
import StyledTabs from 'components/Common/StyledTabs';
import Description from 'components/Description';
import NoData from 'components/NoData';
import CollapseTable from 'components/PairsList/CollapseTable';
import Stronger from 'components/Stronger';
import Edit from 'components/Svg/Icons/Edit';
import Loading from 'components/Svg/Icons/Loading';

import TippingUnderline from '../../components/TippingUnderline';
import AdjustLiquidityMargin from './AdjustLiquidityMargin';
import BalanceRateChart from './BalanceRateChart';
import Detail from './Detail';
import LiquidityCard from './LiquidityCard';
import LiquidityHistory from './LiquidityHistory';
import RemoveLiquidity from './RemoveLiquidity';
import RiskRate from './RiskRate';

export enum Tab_Type {
	Positions = 0,
	TradeHistory = 1,
	PassivePositionHistory
}

export enum Tab_Chart_Type {
	BalanceRate = 0,
	Details = 1
}

export interface RowState {
	tabType: Tab_Type;
	expand: boolean;
}

const LiquidityTable = () => {
	const { liquidityPools, poolInfo, poolAddress } =
		useAppSelector(poolsBaseState);
	const { quoteToken } = useAppSelector(txBaseState);
	const transactionRecords = useAppSelector(selectTransactionRecordList);
	const currentVersion = useAppSelector(selectVersion);
	const { pool } = useParams();
	const navigator = useNavigate();

	const theme = useTheme();

	const [adjustMarginIsOpen, setAdjustMarginIsOpen] = useState(false);
	const [currentPosition, setCurrentPosition] = useState({});
	const [removeMarginIsOpen, setRemoveMarginIsOpen] = useState(false);

	const dispatch = useAppDispatch();
	const update = useUpdate();

	const [rowStates, setRowStates] = useState<Record<string, RowState>>({});

	const { currentChainId } = useCurrentChain();
	const { isMatchMobile } = useAppBreakpoints();
	const isLogin = useCheckLogin();

	const isMatchDown2XL = useMediaQuery(
		`(max-width: ${Dom_Size.PAGE_CONTAINER}px)`
	);

	const rows = useMemo(() => {
		return liquidityPools.map(item => {
			const currentPoolTransactionRecords = (
				transactionRecords.filter(record => record.record) as Merge<
					TransactionRecord,
					{
						record:
							| RecordForLiqudility
							| RecordForAdjustMarginLiquidity
							| RecordForRemoveLiquidity;
					}
				>[]
			)
				.filter(record => record.record.pool === item.address)
				.filter(record => record.status !== Transaction_Status.Error);

			const increaseLiquidityTransactionRecords =
				currentPoolTransactionRecords.filter(
					record =>
						record.record.transactionType === Transaction_Type.IncreaseLiquidity
				) as Merge<TransactionRecord, { record: RecordForLiqudility }>[];

			const adjustMarginTransactionRecords =
				currentPoolTransactionRecords.filter(record =>
					[
						Transaction_Type.IncreaseMarginLiquidity,
						Transaction_Type.ReduceMarginLiquidity
					].includes(record.record.transactionType)
				) as Merge<
					TransactionRecord,
					{ record: RecordForAdjustMarginLiquidity }
				>[];

			const removeLiquidityTransactionRecords =
				currentPoolTransactionRecords.filter(
					record =>
						record.record.transactionType === Transaction_Type.RemoveLiquidity
				) as Merge<TransactionRecord, { record: RecordForRemoveLiquidity }>[];

			return {
				...item,
				liquidityPositions: [
					...(increaseLiquidityTransactionRecords.map(record => ({
						myLiquidity: Number(record.record.liquidityAmount),
						margin: record.record.quoteAmount,
						initialMargin: record.record.quoteAmount,
						marginNet: record.record.quoteAmount,
						liquidationExecutionFee: 0,
						pnl: 0,
						unrealizedLoss: '0',
						marginRate: div(
							record.record.quoteAmount,
							record.record.liquidityAmount
						),
						realizedProfit: 0,
						floatRealizedProfit: 0,
						riskRatio: 0,
						status: Transaction_Status.Pending
					})) as unknown as (typeof liquidityPools)[number]['liquidityPositions']),
					...item.liquidityPositions
						.filter(position => position.status !== 'Liquidated')
						.map(position => {
							const currentAdjustMarginTransactionRecord =
								adjustMarginTransactionRecords.find(
									record => record.record.positionId === position.id
								);

							const currentRemoveLiquidityTransactionRecord =
								removeLiquidityTransactionRecords.find(
									record => record.record.positionId === position.id
								);

							return {
								...position,
								status:
									(currentAdjustMarginTransactionRecord ||
										currentRemoveLiquidityTransactionRecord) &&
									Transaction_Status.Pending
							};
						})
				]
			};
		});
	}, [liquidityPools, transactionRecords.length]);

	const focusedRowIndex = useMemo(() => {
		if (!rows || !rows.length || !poolInfo) {
			return -1;
		}
		return rows.findIndex(item => item.id === poolAddress);
	}, [rows, poolAddress]);

	const onResetRowStates = useCallback(
		(poolId: string) => {
			const result = {} as Record<string, RowState>;
			_.map(rowStates, (item: RowState, key: string) => {
				if (key !== poolId) {
					result[key] = {
						tabType: Tab_Type.Positions,
						expand: false
					};
				} else {
					result[key] = item;
				}
			});
			setRowStates(result);
		},
		[rowStates, setRowStates]
	);

	const onHandleSetCurrentPool = (row: any) => {
		if (currentVersion === Version.V1) {
			dispatch(setPoolAddress(row.id));
		}
		if (currentVersion === Version.V2) {
			dispatch(setV2PoolAddress(row.id));
		}
		navigator(
			`${currentVersion === Version.V1 ? Page.PoolsV1 : Page.Pools}/${row.id}`
		);
		onResetRowStates(row.id);
	};

	const setCurrentPositionProxy = (
		item: (typeof rows)[number]['liquidityPositions'][number]
	) =>
		setCurrentPosition({
			...item,
			liquidity: item.myLiquidity
		});

	const onOpenAdjustMargin = (
		item: (typeof rows)[number]['liquidityPositions'][number]
	) => {
		setCurrentPositionProxy(item);
		setAdjustMarginIsOpen(true);
	};
	const onOpenRemoveMargin = (
		item: (typeof rows)[number]['liquidityPositions'][number]
	) => {
		setCurrentPositionProxy(item);
		setRemoveMarginIsOpen(true);
	};
	const onCloseAdjustMarginDialog = () => setAdjustMarginIsOpen(false);
	const onCloseRemoveMarginDialog = () => setRemoveMarginIsOpen(false);

	const onHandleShowChart = (e: any, pool: any) => {
		e.stopPropagation();
		onResetRowStates(pool.id);

		if (currentVersion === Version.V1) {
			dispatch(setPoolAddress(pool.id));
		}
		if (currentVersion === Version.V2) {
			dispatch(setV2PoolAddress(pool.id));
		}
		setRowStates((state: any) => ({
			...state,
			[pool.id]: { ...state[pool.id], expand: !state[pool.id]?.expand }
		}));
	};

	useEffect(() => {
		if (rows.length > 0) {
			const result = {} as Record<string, RowState>;
			rows.forEach((item: any) => {
				result[item.id] = {
					tabType: Tab_Type.Positions,
					expand: false
				};
			});
			setRowStates(result);
		}
	}, [rows.length]);

	const headers: {
		[key: string]: any;
		cellRender?: (item: (typeof liquidityPools)[number]) => void;
	}[] = [
		{
			key: 'baseSymbol',
			label: <Trans>Pool</Trans>,
			align: 'left',
			width: '13%',
			cellRender: row => {
				return (
					<Typography variant={isMatchMobile ? 'inherit' : 'h6'}>
						{`${shortenSymbolNative(
							row.baseToken,
							currentChainId
						)}/${QUOTE_USD}`}
					</Typography>
				);
			}
		},
		{
			key: 'apr',
			label: (
				<div className='flex items-center'>
					<TippingUnderline
						tooltip={
							<div>
								<Typography>
									<Trans>
										Max APR is calculated based on LPs&#39; trading fee income
										in the past 24 hours, EQU daily emission, and the maximum
										leverage of the pool.
									</Trans>
								</Typography>
								<Typography className='mt-2'>
									<Trans>Max APR = Trading Fees Max APR + EQU Max APR</Trans>
								</Typography>
							</div>
						}
						label={
							<Typography variant='body2'>
								<Trans>Max APR</Trans>
							</Typography>
						}
					/>
				</div>
			),
			align: 'left',
			width: '15%',
			cellRender: row => {
				return (
					<Stronger
						value={
							<Typography
								className='leading-none'
								variant='h6'
								color={
									row.maxApr && isGreaterThan(row.maxApr, 0)
										? 'success.main'
										: 'secondary'
								}
							>
								{row.maxApr && isGreaterThan(row.maxApr, 0)
									? toPercent(row.maxApr)
									: toPercent(0)}
							</Typography>
						}
						tooltip={
							<div>
								<Cell
									label={<Trans>Trading Fees Max APR</Trans>}
									value={
										row.liquidityApr
											? toPercent(row.liquidityApr)
											: toPercent(0)
									}
								/>

								<Cell
									label='EQU Max APR'
									value={row.rbfApr ? toPercent(row.rbfApr) : toPercent(0)}
								/>
							</div>
						}
					/>
				);
			}
		},

		{
			key: 'totalRevenue',
			label: <Trans>24h Volume ({QUOTE_USD})</Trans>,
			align: 'left',
			width: '20%',
			cellRender: row => <>{toQuoteAmount(row.volume24h)}</>
		},

		{
			key: 'dayRevenue',
			label: (
				<div className='flex items-center -mr-0.5'>
					<TippingUnderline
						label={<Trans>24h Fee</Trans>}
						tooltip={
							<Trans>
								Total trading fees generated by the trading users within 24
								hours of the market.
							</Trans>
						}
					/>
				</div>
			),
			align: 'left',
			width: '15%',
			cellRender: row => (
				<div className='flex items-center space-x-1'>
					<Typography variant='body2'>
						{toQuoteAmount(row.tradingFee24h)}
					</Typography>
					{/* {isZero(row.referralDiscountRate) && (
						<FreeTradingFeeWarning type={Type_Free_Trading_Fee.Warning} />
					)} */}
				</div>
			)
		},

		{
			key: 'liquidity',
			label: <Trans>Liquidity</Trans>,
			align: 'left',
			width: '18%',
			cellRender: row => <>{amountFormatter(row.liquidity, 2, 2)}</>
		},
		{
			key: 'myLiquidity',
			label: <Trans>My Liquidity</Trans>,
			align: 'left',
			width: '15%',
			cellRender: row => {
				if (!isLogin) {
					return '-';
				}
				return (
					<div>
						<Typography variant='body2'>
							{amountFormatter(row.myLiquidity, 2, 2)}
						</Typography>
					</div>
				);
			}
		},
		{
			key: 'balanceRate',
			width: '4%',
			label: '',
			align: 'right',
			cellRender: row => (
				<div className='flex items-center justify-end'>
					<Typography
						onClick={e => onHandleShowChart(e, row)}
						color='text.secondary'
						className={classNames(
							'relative text-base -right-2 cursor-pointer rounded-full p-0.5',
							rowStates[row.id]?.expand ? 'rotate-180' : 'rotate-0'
						)}
						component='span'
						sx={{
							'&:hover': {
								backgroundColor: theme.palette.action.hover
							}
						}}
					>
						<ArrowDownIcon color='inherit' />
					</Typography>
				</div>
			)
		}
	];

	const onHandleChangeOperationType = (value: Tab_Type, rowState: RowState) => {
		rowState.tabType = value;
		update();
	};
	const { address } = useAccount();

	const contentRender = (row: (typeof rows)[number]) => (
		<Box
			sx={
				{
					// border: isMatchMobile ? '0px' : '1px solid rgba(255, 255, 255, 0.08)',
					// borderTop: '0',
					// borderRadius: '0 0 4px 4px'
				}
			}
		>
			{rowStates[row.id]?.expand ? (
				<Box className='relative py-4 px-4 sm:p-3 sm:hidden'>
					<StyledTabs value={Tab_Chart_Type.BalanceRate}>
						<StyledTab
							label={<Trans>Balance Rate</Trans>}
							value={Tab_Chart_Type.BalanceRate}
							className='pl-0'
						/>
					</StyledTabs>
					<CommonStyledDivider type='solid' />
					<BalanceRateChart
						poolId={row.id}
						expand={rowStates[row.id].expand}
						poolInfo={row}
					/>
				</Box>
			) : null}
			{row.id === poolAddress && (
				<Box className='relative py-4 px-4 sm:p-3'>
					<StyledTabs
						value={rowStates[row.id]?.tabType || Tab_Type.Positions}
						onChange={(_, value) =>
							onHandleChangeOperationType(value, rowStates[row.id])
						}
					>
						<StyledTab
							className='pl-0'
							label={
								<Trans>
									Positions{' '}
									{isLogin && row.liquidityPositions.length > 0
										? formatModuleNumber(row.liquidityPositions.length)
										: null}
								</Trans>
							}
							value={Tab_Type.Positions}
						/>

						<StyledTab
							label={<Trans>History</Trans>}
							value={Tab_Type.TradeHistory}
						/>
					</StyledTabs>
					<CommonStyledDivider type='solid' />
					{rowStates[row.id]?.tabType === Tab_Type.TradeHistory && (
						<LiquidityHistory key={address} poolId={row.id} />
					)}
					{rowStates[row.id]?.tabType !== Tab_Type.TradeHistory && (
						<Box
							className='space-y-5 pr-2 sm:pr-0'
							sx={{
								maxHeight: !isMatchMobile ? Dom_Size.LIST_SCOLL : 'auto',
								overflowY: 'auto'
							}}
						>
							{!isLogin || row.liquidityPositions.length === 0 ? (
								<NoData>
									<Trans>No open positions</Trans>
								</NoData>
							) : (
								row.liquidityPositions.map((item, itemIndex) => (
									<Box
										sx={{
											borderBottom:
												itemIndex !== row.liquidityPositions.length - 1
													? '1px dashed rgba(255, 255, 255, 0.1)'
													: ''
										}}
										className={classNames(
											'flex justify-between sm:flex-col sm:space-y-4',
											{
												'pb-5': itemIndex !== row.liquidityPositions.length - 1
											}
										)}
										key={itemIndex}
									>
										<Box className='flex-1'>
											<div className='grid grid-cols-4 gap-y-3 sm:grid-cols-3'>
												<Description
													vertical={isMatchDown2XL}
													label={<Trans>Liquidity</Trans>}
												>
													<div className='flex space-x-3'>
														<Typography variant='body2'>
															{toQuoteAmount(item.myLiquidity)}
														</Typography>
														{item.status === Transaction_Status.Pending && (
															<Loading size={16} />
														)}
													</div>
												</Description>
												<Description
													vertical={isMatchDown2XL}
													label={<Trans>Leverage</Trans>}
												>
													<Typography variant='body2'>
														{isPositive(item.leverage)
															? formatLeverage(item.leverage)
															: '-'}
													</Typography>
												</Description>
												<Description
													vertical={isMatchDown2XL}
													label={<Trans>Realized Profit</Trans>}
												>
													<Typography
														variant='body2'
														color={
															isPositive(item.totalRealizedProfit)
																? theme.palette.success.main
																: theme.palette.text.primary
														}
													>
														{toQuoteAmount(item.totalRealizedProfit)}
													</Typography>
												</Description>
												<Description
													vertical={isMatchDown2XL}
													label={<Trans>Margin</Trans>}
												>
													<div className='flex items-center'>
														<Stronger
															tooltip={
																<div className='w-[250px] space-y-1'>
																	<div className='flex justify-between'>
																		<div>
																			<Trans>Initial Margin</Trans>
																		</div>
																		<div>
																			{formatNumber(item.initialMargin)}{' '}
																			{shortenSymbol(quoteToken)}
																		</div>
																	</div>
																	<div className='flex justify-between'>
																		<div>
																			<Trans>Realized Profit</Trans>
																		</div>
																		<div>
																			{toQuoteAmount(item.totalRealizedProfit)}
																		</div>
																	</div>
																</div>
															}
															value={`${formatNumber(
																item.marginNet
															)} ${shortenSymbol(quoteToken)}`}
														/>
														<IconButton
															className={classNames(
																{
																	'opacity-30':
																		item.status === Transaction_Status.Pending
																},
																'cursor-pointer sm:hidden'
															)}
															disabled={
																item.status === Transaction_Status.Pending
															}
															onClick={() => onOpenAdjustMargin(item)}
														>
															<Edit />
														</IconButton>
													</div>
												</Description>
												<Description
													valueWrap={isMatchDown2XL}
													vertical={isMatchDown2XL}
													label={<Trans>Utilized Leverage</Trans>}
												>
													<Typography variant='body2'>
														{formatLeverage(item.utilizedLeverage)}
													</Typography>
												</Description>
												<Description
													vertical={isMatchDown2XL}
													label={<Trans>Temporary Loss</Trans>}
												>
													<Stronger
														value={
															<Typography
																className='space-x-1 whitespace-nowrap leading-none'
																component='span'
																variant='body2'
																color={
																	isEqualTo(item.unrealizedLoss, 0)
																		? 'inherit'
																		: 'error.main'
																}
															>
																<span>
																	{toQuoteAmount(neg(item.unrealizedLoss))}
																</span>
																{!isZero(item.unrealizedLoss) && (
																	<span>
																		{`(${toPercent(
																			div(
																				neg(item.unrealizedLoss),
																				item.marginNet
																			)
																		)})`}
																	</span>
																)}
															</Typography>
														}
														tooltip={
															<div>
																<Trans>
																	If LPs hold temporary positions that suffer
																	losses, and the Risk Buffer Fund is
																	insufficient to cover these losses, then the
																	LPs will experience temporary losses.
																</Trans>
															</div>
														}
													/>
												</Description>
												<Description
													vertical={isMatchDown2XL}
													label={<Trans>Risk</Trans>}
												>
													<Typography component='div' variant='body2'>
														<RiskRate
															risk={item.riskRatio}
															tooltip={
																<Trans>
																	Indicates the risk rate of the current
																	position. When the risk rate reaches 100%, a
																	liquidation will be triggered.
																</Trans>
															}
														/>
													</Typography>
												</Description>
											</div>
										</Box>
										<div className='flex items-center space-x-3'>
											{isMatchMobile && (
												<CommonButton
													onClick={() => onOpenAdjustMargin(item)}
													componentVariant='outlined'
													size='small'
													color='secondary'
													disabled={item.status === Transaction_Status.Pending}
												>
													<Typography color='textPrimary' variant='body2'>
														<Trans>Adjust Margin</Trans>
													</Typography>
												</CommonButton>
											)}
											<CommonButton
												onClick={() => onOpenRemoveMargin(item)}
												componentVariant='outlined'
												size='small'
												color='secondary'
												disabled={item.status === Transaction_Status.Pending}
											>
												<Typography color='textPrimary' variant='body2'>
													<Trans>Remove</Trans>
												</Typography>
											</CommonButton>
										</div>
									</Box>
								))
							)}

							{/* {row.liquidityPositions.length !== 0 &&
								} */}
						</Box>
					)}
				</Box>
			)}
		</Box>
	);

	return (
		<Paper
			className='flex-1 sm:border-t'
			sx={{
				borderColor: theme.palette.divider,
				background: isMatchMobile
					? 'transparent'
					: theme.palette.background.paper
			}}
		>
			{isMatchMobile && pool ? (
				<Detail
					rows={rows}
					contentRender={contentRender}
					poolsChartState={rowStates}
				/>
			) : (
				<Paper
					className='flex-1'
					sx={{
						background: isMatchMobile
							? 'transparent'
							: theme.palette.background.paper
					}}
				>
					{isMatchMobile ? (
						<LiquidityCard
							isLoading={liquidityPools.length === 0}
							rows={rows}
							onClickRow={onHandleSetCurrentPool}
						/>
					) : (
						<CollapseTable
							isLoading={liquidityPools.length === 0}
							headers={headers}
							focusedRowIndex={focusedRowIndex}
							rows={rows}
							contentRender={contentRender}
							onClickRow={onHandleSetCurrentPool}
						/>
					)}
				</Paper>
			)}

			{adjustMarginIsOpen && (
				<AdjustLiquidityMargin
					currentPosition={currentPosition}
					onClose={onCloseAdjustMarginDialog}
				/>
			)}

			{removeMarginIsOpen && (
				<RemoveLiquidity
					currentPosition={currentPosition}
					onClose={onCloseRemoveMarginDialog}
				/>
			)}
		</Paper>
	);
};

export default LiquidityTable;
