import { useCallback, useContext, useMemo } from 'react';
import { useUpdateEffect } from 'react-use';

import {
	Input_Direction,
	MAX_PRECISION,
	NATIVE_BUFFER_FOR_MAX
} from 'config/constants';
import Decimal from 'decimal.js';

import { useCheckLogin } from 'hooks/useCurrentChain';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
	liquidityBaseState,
	setDirection,
	setIsPureAdd,
	setLeverage,
	setLiquidityAmount,
	setQuoteAmount
} from 'state/liquidity/slice';
import { poolsBaseState } from 'state/pools/slice';
import { settingBaseState } from 'state/setting/slice';
import { txBaseState } from 'state/tx/slice';
import {
	checkInputNumberic,
	computeMaxAmount,
	div,
	isLessThan,
	isPositive,
	multipliedBy,
	plus,
	toAmountString,
	toDecimalPlaces
} from 'utils';

export function useListenLiquidityAmountV2() {
	const dispatch = useAppDispatch();

	const { quoteToken, quoteBalance } = useAppSelector(txBaseState);
	const { leverage, quoteAmount, liquidityAmount, direction } =
		useAppSelector(liquidityBaseState);

	const { checkedLiquidityLeverage } = useAppSelector(settingBaseState);
	const { poolInfo, liquidityPools } = useAppSelector(poolsBaseState);

	const liquidityPool = useMemo(() => {
		const targetPoolAddress = poolInfo?.address.toLowerCase();
		return liquidityPools.find(item => item.address === targetPoolAddress);
	}, [poolInfo, liquidityPools]);

	const unrealizedPnl = useMemo(() => {
		if (liquidityPool?.liquidityPositions.length) {
			return liquidityPool?.liquidityPositions[0]?.liquidityPassivePosition
				?.pnl;
		} else {
			return '0';
		}
	}, [liquidityPool]);

	const isLogin = useCheckLogin();

	useUpdateEffect(() => {
		if (checkedLiquidityLeverage) {
			if (direction === Input_Direction.In && isPositive(quoteAmount)) {
				onSetValuesByMarginDelta(quoteAmount);
			}
			if (direction === Input_Direction.Out && isPositive(liquidityAmount)) {
				onSetValuesByLiquidityAmount(liquidityAmount);
			}
		} else {
			if (isPositive(quoteAmount) && isPositive(liquidityAmount)) {
				onSetValuesByNoneLeverage(quoteAmount, liquidityAmount, unrealizedPnl);
			}
			if (
				!isPositive(quoteAmount) &&
				isPositive(liquidityPool?.liquidityPositions.length)
			) {
				onSetValuesByPureAdd(
					liquidityPool?.liquidityPositions[0]?.marginNet,
					liquidityAmount,
					liquidityPool?.myLiquidity
				);
			}
		}
	}, [checkedLiquidityLeverage, leverage, liquidityPool]);

	// 支付金额变化
	const onSetValuesByMarginDelta = useCallback(
		(value: string) => {
			if (!poolInfo || !quoteToken || !leverage) {
				return;
			}
			const _positionAmount = multipliedBy(value, leverage);
			const positionAmount = toAmountString(
				toDecimalPlaces(_positionAmount, quoteToken.decimals),
				MAX_PRECISION
			);
			dispatch(setLiquidityAmount(positionAmount));
		},
		[poolInfo, leverage, quoteToken]
	);

	// 流动性数量变化
	const onSetValuesByLiquidityAmount = useCallback(
		(value: string) => {
			if (!poolInfo || !quoteToken || !leverage) {
				return;
			}

			const _quoteAmount = div(value, leverage);
			const quoteAmount = toAmountString(
				toDecimalPlaces(_quoteAmount, quoteToken.decimals, Decimal.ROUND_CEIL),
				MAX_PRECISION
			);

			dispatch(setQuoteAmount(quoteAmount));
		},
		[poolInfo, leverage, quoteToken, liquidityAmount]
	);

	const onReset = useCallback(() => {
		dispatch(setQuoteAmount(''));
		dispatch(setLiquidityAmount(''));
	}, []);

	const hasMaxButton = useMemo(() => {
		if (!quoteToken || !isPositive(quoteBalance) || !isLogin) {
			return false;
		}
		if (
			quoteToken.isNative &&
			isLessThan(quoteBalance, NATIVE_BUFFER_FOR_MAX)
		) {
			return false;
		}
		return true;
	}, [quoteToken, quoteBalance, isLogin]);

	const onHandleChangeMarginDelta = useCallback(
		(event: any) => {
			const value = event.target.value;
			if (value.trim() === '') {
				dispatch(setQuoteAmount(''));
				if (checkedLiquidityLeverage) {
					dispatch(setLiquidityAmount(''));
				} else {
					dispatch(setLeverage(0));
				}
				return;
			}
			if (checkInputNumberic(value, quoteToken?.decimals)) {
				dispatch(setQuoteAmount(value));
				dispatch(setDirection(Input_Direction.In));
				if (checkedLiquidityLeverage) {
					onSetValuesByMarginDelta(value);
				} else {
					if (isPositive(liquidityAmount)) {
						onSetValuesByNoneLeverage(value, liquidityAmount, unrealizedPnl);
					}
				}
			}
		},
		[quoteToken, poolInfo, checkedLiquidityLeverage, leverage, liquidityAmount]
	);

	const onHandleSetMaxMarginDelta = () => {
		if (isPositive(quoteBalance)) {
			const value = computeMaxAmount(quoteBalance, quoteToken);

			dispatch(setQuoteAmount(value));
			dispatch(setDirection(Input_Direction.In));

			if (checkedLiquidityLeverage) {
				onSetValuesByMarginDelta(value);
			} else {
				if (isPositive(liquidityAmount)) {
					onSetValuesByNoneLeverage(value, liquidityAmount, unrealizedPnl);
				}
			}
		}
	};

	const onHandleChangeLiquidityAmount = (event: any) => {
		const value = event.target.value;
		if (value.trim() === '') {
			dispatch(setLiquidityAmount(''));
			if (checkedLiquidityLeverage) {
				dispatch(setQuoteAmount(''));
			} else {
				dispatch(setLeverage(0));
			}
			return;
		}
		if (checkInputNumberic(value, quoteToken?.decimals)) {
			dispatch(setLiquidityAmount(value));
			dispatch(setDirection(Input_Direction.Out));

			if (checkedLiquidityLeverage) {
				onSetValuesByLiquidityAmount(value);
			} else {
				if (isPositive(quoteAmount)) {
					onSetValuesByNoneLeverage(quoteAmount, value, unrealizedPnl);
				} else {
					if (isPositive(liquidityPool?.liquidityPositions.length)) {
						onSetValuesByPureAdd(
							liquidityPool?.liquidityPositions[0]?.marginNet,
							value,
							liquidityPool?.myLiquidity
						);
					}
				}
			}
		}
	};

	// 不锁定杠杆
	const onSetValuesByNoneLeverage = useCallback(
		(quoteAmount: string, liquidityAmount: string, unrealizedPnl: string) => {
			let leverage;
			if (isPositive(liquidityPool?.liquidityPositions.length)) {
				leverage = toDecimalPlaces(
					div(
						plus(liquidityAmount, liquidityPool?.myLiquidity),
						plus(
							plus(
								liquidityPool?.liquidityPositions[0].marginNet,
								quoteAmount || '0'
							),
							unrealizedPnl
						)
					),
					2,
					Decimal.ROUND_HALF_CEIL
				);
			} else {
				leverage = toDecimalPlaces(
					div(liquidityAmount, quoteAmount),
					2,
					Decimal.ROUND_HALF_CEIL
				);
			}
			dispatch(setLeverage(leverage));
		},
		[liquidityPool]
	);

	const onSetValuesByPureAdd = useCallback(
		(quoteAmount: string, liquidityAmount: string, myLiquidity: string) => {
			const leverage = toDecimalPlaces(
				div(
					plus(liquidityAmount, myLiquidity),
					plus(quoteAmount, unrealizedPnl)
				),
				2,
				Decimal.ROUND_HALF_CEIL
			);
			dispatch(setLeverage(leverage));
		},
		[unrealizedPnl]
	);

	return {
		hasMaxButton,
		onReset,
		onHandleChangeMarginDelta,
		onHandleSetMaxMarginDelta,
		onHandleChangeLiquidityAmount,
		onSetValuesByPureAdd
	};
}
