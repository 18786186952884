import { Q1, Q96, QUOTE_USD_PRECISION, Side } from 'config/constants';
import Decimal from 'decimal.js';
import moment from 'moment';

import { cloneDeep } from 'lodash';
import {
	IGlobalFundingRateSample,
	IGlobalLiquidityPosition,
	IPriceState
} from 'types';
import {
	ceilDiv,
	div,
	isGreaterThan,
	isGreaterThanOrEqual,
	isLessThan,
	isZero,
	minus,
	mod,
	mulDiv,
	multipliedBy,
	parseUnits,
	plus,
	trunc
} from 'utils';

const ADJUST_FUNDING_RATE_INTERVAL = 60 * 60;
const SAMPLE_BALANCE_RATE_INTERVAL = 5;
const SAMPLE_PREMIUM_RATE_INTERVAL = 5;
const MAX_SAMPLE_COUNT = 720;
const FUNDING_RATE_BASIS_POINT_DIVISOR = 100_000_000;
const BALANCE_RATE_CLAMP_BOUNDARY_X96 = new Decimal(
	'4951760157141521099596497'
);

export class FundingRateUtil {
	public static caculateFundingRate(
		sample: IGlobalFundingRateSample,
		globalLiquidityPosition: IGlobalLiquidityPosition,
		priceState: IPriceState,
		interestRate: string,
		maxFundingRate: string
	) {
		const fundingRateDeltaX96 = this.samplePremiumRate(
			sample,
			globalLiquidityPosition,
			priceState,
			interestRate,
			moment().unix()
		);
		const fundingRate = div(
			this.adjustFundingRate(fundingRateDeltaX96, maxFundingRate),
			Q96
		);
		return fundingRate;
	}

	/// @notice Sample the premium rate
	/// @param _sample The global funding rate sample
	/// @param _position The global liquidity position
	/// @param _priceState The global price state
	/// @param _interestRate The interest rate used to calculate the funding rate,
	/// denominated in ten thousandths of a bip (i.e. 1e-8)
	/// @param _currentTimestamp The current timestamp
	/// @return shouldAdjustFundingRate Whether to adjust the funding rate
	/// @return fundingRateDeltaX96 The delta of the funding rate, as a Q160.96
	private static samplePremiumRate(
		_sample: IGlobalFundingRateSample,
		_position: IGlobalLiquidityPosition,
		_priceState: IPriceState,
		_interestRate: string,
		_currentTimestamp: number | string
	): string {
		const _positionCache = cloneDeep(_position);
		_positionCache.liquidity = parseUnits(
			_positionCache.liquidity,
			QUOTE_USD_PRECISION
		);

		const { lastAdjustFundingRateTime } = _sample;

		// 上一次的整体采样时间点 + 一个小时
		let maxSamplingTime = plus(
			lastAdjustFundingRateTime,
			ADJUST_FUNDING_RATE_INTERVAL
		);

		// 特殊情况，刚好跨过整点，如果最大采样时间点<当前时间，重置当前时间为最大采样时间点
		if (isLessThan(maxSamplingTime, _currentTimestamp)) {
			_currentTimestamp = maxSamplingTime;
		}

		// 最近的采样时间点
		const lastSamplingTime = plus(
			lastAdjustFundingRateTime,
			multipliedBy(_sample.sampleCount, SAMPLE_BALANCE_RATE_INTERVAL)
		);

		// 当前时间需 >= 最近采样时间点
		if (isLessThan(_currentTimestamp, lastSamplingTime)) {
			_currentTimestamp = lastSamplingTime;
		}
		let timeDelta = minus(_currentTimestamp, lastSamplingTime);

		let totalSampleCount = plus(
			_sample.sampleCount,
			trunc(div(timeDelta, SAMPLE_BALANCE_RATE_INTERVAL))
		);

		if (isGreaterThanOrEqual(totalSampleCount, MAX_SAMPLE_COUNT)) {
			totalSampleCount = minus(totalSampleCount, MAX_SAMPLE_COUNT);
			_sample.sampleCount = '0';
			_sample.cumulativePremiumRateX96 = '0';
			_sample.lastAdjustFundingRateTime = minus(
				_currentTimestamp,
				mod(_currentTimestamp, ADJUST_FUNDING_RATE_INTERVAL)
			);
			maxSamplingTime = plus(
				_sample.lastAdjustFundingRateTime,
				ADJUST_FUNDING_RATE_INTERVAL
			);
			timeDelta = multipliedBy(totalSampleCount, SAMPLE_BALANCE_RATE_INTERVAL);
		}

		const _premiumRateX96 = mulDiv(_priceState.premiumRateX96, _priceState?.basisIndexPriceX96, _priceState?.indexPriceX96, Decimal.ROUND_CEIL);

		return this._samplePremiumRate(
			_sample,
			_positionCache.side,
			_premiumRateX96,
			_interestRate,
			maxSamplingTime,
			timeDelta
		);
	}

	public static _samplePremiumRate(
		_sample: IGlobalFundingRateSample,
		_side: Side,
		_premiumRateX96: string,
		_interestRate: Decimal.Value,
		_maxSamplingTime: Decimal.Value,
		_timeDelta: Decimal.Value
	): string {
		const premiumRateX96Int =
			_side === Side.LONG ? String(-_premiumRateX96) : _premiumRateX96;

		const sampleCountDelta = Decimal.floor(
			div(_timeDelta, SAMPLE_PREMIUM_RATE_INTERVAL)
		).toFixed();
		const sampleCountAfter = Decimal.floor(
			plus(_sample.sampleCount, sampleCountDelta)
		).toFixed();
		const cumulativePremiumRateDeltaX96 = multipliedBy(
			premiumRateX96Int,
			new Decimal(_sample.sampleCount)
				.plus(1)
				.plus(sampleCountAfter)
				.mul(sampleCountDelta)
				.div(Q1)
				.toFixed()
		);
		const cumulativePremiumRateAfterX96 = plus(
			_sample.cumulativePremiumRateX96,
			cumulativePremiumRateDeltaX96
		);

		// if (isLessThan(sampleCountAfter, REQUIRED_SAMPLE_COUNT)) {
		// 	return '0';
		// }

		const _PREMIUM_RATE_AVG_DENOMINATOR = new Decimal(1)
			.plus(sampleCountAfter)
			.mul(sampleCountAfter)
			.div(2)
			.mul(8)
			.toFixed();

		const premiumRateAvgX96 = isZero(_PREMIUM_RATE_AVG_DENOMINATOR)
			? '0'
			: ceilDiv(cumulativePremiumRateAfterX96, _PREMIUM_RATE_AVG_DENOMINATOR);

		return plus(
			premiumRateAvgX96,
			this._clamp(premiumRateAvgX96, _interestRate)
		);
	}

	private static _clamp(
		_balanceRateAvgX96: Decimal.Value,
		_interestRate: Decimal.Value
	) {
		const interestRateX96 = mulDiv(
			_interestRate,
			Q96,
			FUNDING_RATE_BASIS_POINT_DIVISOR,
			Decimal.ROUND_UP
		);
		const rateDeltaX96 = minus(interestRateX96, _balanceRateAvgX96);
		if (isGreaterThan(rateDeltaX96, BALANCE_RATE_CLAMP_BOUNDARY_X96)) {
			return BALANCE_RATE_CLAMP_BOUNDARY_X96;
		} else if (isLessThan(rateDeltaX96, -BALANCE_RATE_CLAMP_BOUNDARY_X96)) {
			return -BALANCE_RATE_CLAMP_BOUNDARY_X96;
		} else {
			return rateDeltaX96;
		}
	}

	public static adjustFundingRate(
		fundingRateDeltaX96: Decimal.Value,
		maxFundingRate: Decimal.Value
	) {
		const maxFundingRateX96 = ceilDiv(
			multipliedBy(maxFundingRate, Q96),
			FUNDING_RATE_BASIS_POINT_DIVISOR
		);
		if (isGreaterThan(fundingRateDeltaX96, maxFundingRateX96)) {
			fundingRateDeltaX96 = maxFundingRateX96;
		} else if (isLessThan(fundingRateDeltaX96, -maxFundingRateX96)) {
			fundingRateDeltaX96 = -maxFundingRateX96;
		}

		return fundingRateDeltaX96;
	}
}
