import { useCallback, useEffect, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { Trans, t } from '@lingui/macro';
import { Divider, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { DEFAULT_PAGE_SIZE, Version } from 'config/constants';
import Decimal from 'decimal.js';
import { toCamelCase, useLiquidityHistoriesRequest } from 'fetch/useRequest';
import moment from 'moment';
import { useAccount } from 'wagmi';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { multiply } from 'lodash';
import { useAppSelector } from 'state/hooks';
import { selectTransationOperation } from 'state/setting/selector';

import { DirectionCell } from '../../components/Common/Cell';
import NoData from '../../components/NoData';
import SectionLoading from '../../components/SectionLoading';
import VirtualTable from '../../components/Table/VirtualTable';
import { LiquidityHistoryQuery } from '../../graphql/__generated_trade__/types-and-hooks';
import useLiquidityHistory from '../../graphql/useLiquidityHistoryGraph';
import { useCheckLogin, useCurrentChain } from '../../hooks/useCurrentChain';
import {
	ExplorerDataType,
	formatDate,
	getExplorerLink,
	isNegative,
	isPositive,
	toQuoteAmount
} from '../../utils';

const LiquidityHistory = ({ poolId }: { poolId: string }) => {
	const TransationOperation = useAppSelector(selectTransationOperation);
	const { address } = useAccount();
	const { isMatchPad, isMatchPc, isMatchMobile } = useAppBreakpoints();
	const isLogin = useCheckLogin();
	const currentVersion = useAppSelector(state => state.setting.currentVersion);

	const [from, setFrom] = useState(0);

	const { trigger } = useLiquidityHistoriesRequest(address, {
		pool: poolId,
		from,
		limit: DEFAULT_PAGE_SIZE
	});

	const { fetch } = useLiquidityHistory();

	const [data, setData] = useState<
		LiquidityHistoryQuery['transactionHistories']
	>([]);
	const [loading, setLoading] = useState(true);
	const [fetching, setFetching] = useState(false);
	const [hasMore, setHasMore] = useState(true);

	const resetState = useCallback(() => {
		if (!currentVersion) {
			return;
		}
		setFrom(0);
		setHasMore(true);
		setFetching(false);
	}, [from, currentVersion]);

	useEffect(() => {
		if (!currentVersion) {
			return;
		}
		resetState();
	}, [currentVersion]);

	const fetchAction = useCallback(
		(index: number) => {
			if (!currentVersion) {
				return;
			}
			if (currentVersion === Version.V1) {
				if (hasMore && !fetching && address) {
					setFetching(true);
					return fetch({
						variables: {
							account: address,
							poolId,
							skip: index === 0 ? 0 : data.length
						}
					})
						.then(res => {
							setHasMore(
								res.data.transactionHistories.length === DEFAULT_PAGE_SIZE
							);
							setData(
								index === 0
									? res.data.transactionHistories
									: [...data, ...res.data.transactionHistories]
							);
						})
						.finally(() => setFetching(false));
				} else {
					return Promise.resolve();
				}
			} else if (currentVersion === Version.V2) {
				if (hasMore && !fetching && address) {
					setFetching(true);
					return trigger()
						.then(res => {
							setHasMore(
								res.data.transactionHistories.length === DEFAULT_PAGE_SIZE
							);
							setData(
								index === 0
									? res.data.transactionHistories
									: [...data, ...res.data.transactionHistories]
							);
						})
						.finally(() => setFetching(false));
				} else {
					return Promise.resolve();
				}
			}
		},
		[address, poolId, data, hasMore, fetching, currentVersion]
	);

	useEffect(() => {
		setData([]);
		fetchAction(0).finally(() => setLoading(false));
	}, []);

	const { currentChain: chain } = useCurrentChain();

	const HISTORY_LABEL_MAP = {
		[TransationOperation.LiquidityPositionClosed]: <Trans>Close</Trans>,
		[TransationOperation.LiquidityPositionOpened]: <Trans>Open</Trans>,
		[TransationOperation.LiquidityPositionLiquidated]: (
			<Trans>Liquidation</Trans>
		)
	};

	const theme = useTheme();
	const rows = data
		.map(row => ({ ...row, data: toCamelCase(JSON.parse(row.data)) }))
		.map(row => {
			return {
				...row,
				time: multiply(row.blockTimestamp, 1000),
				liquidity: row.data.liquidityDelta,
				margin: row.data.marginDelta,
				operation: row.operation as keyof typeof HISTORY_LABEL_MAP,
				pnl:
					row.operation === TransationOperation.LiquidityPositionClosed ||
					row.operation === TransationOperation.PositionLiquidated
						? row.data.realizedPnLDelta
						: '-',
				fee:
					row.operation === TransationOperation.LiquidityPositionClosed
						? `-${row.data.priceImpactFee}`
						: '-',
				txHash: row.txHash
			};
		});
	const columns = [
		{
			id: 'time',
			label: t`Time`,
			width: '24%',
			format: (row: (typeof rows)[number]) =>
				formatDate(row.time, 'MMM D, HH:mm:ss')
		},
		{
			id: 'type',
			label: t`Operation`,
			width: '16%',
			format: (row: (typeof rows)[number]) => {
				if (
					row.operation !== TransationOperation.LiquidityPositionMarginAdjusted
				) {
					return HISTORY_LABEL_MAP[row.operation];
				} else {
					return isPositive(row.margin) ? (
						<Trans>Add Margin</Trans>
					) : (
						<Trans>Reduce Margin</Trans>
					);
				}
			}
		},
		{
			id: 'liquidity',
			label: t`Liquidity`,
			width: '20%',
			format: (row: (typeof rows)[number]) =>
				toQuoteAmount(row.liquidity, 2, Decimal.ROUND_DOWN, true)
		},
		{
			id: 'margin',
			label: (
				<Typography
					align={isMatchMobile ? 'left' : 'right'}
					variant='inherit'
				>{t`Margin`}</Typography>
			),
			width: '20%',
			format: (row: (typeof rows)[number]) => (
				<Typography align={isMatchMobile ? 'left' : 'right'} variant='inherit'>
					{toQuoteAmount(row.margin, 2, Decimal.ROUND_DOWN, true)}
				</Typography>
			)
		},
		{
			id: 'pnl',
			label: (
				<Typography
					align={isMatchPc ? 'right' : 'left'}
					variant='inherit'
				>{t`Realized PnL`}</Typography>
			),
			width: '20%',
			format: (row: (typeof rows)[number]) => (
				<Typography
					className={classNames('flex items-center space-x-1', {
						'justify-start': !isMatchPc,
						'justify-end': isMatchPc
					})}
					align={isMatchPc ? 'right' : 'left'}
					variant='body2'
					color={
						isPositive(row.pnl)
							? theme.palette.success.main
							: isNegative(row.pnl)
							? theme.palette.error.main
							: theme.palette.text.primary
					}
				>
					{toQuoteAmount(row.pnl)}
				</Typography>
			)
		}
	];

	const filteredColumn = useMemo(() => {
		return currentVersion === Version.V2 ? columns.slice(0, -1) : columns;
	}, [currentVersion]);

	const onRowClick = (row: (typeof rows)[number]) => {
		const link = getExplorerLink(
			chain,
			row.txHash || '',
			ExplorerDataType.TRANSACTION
		);
		window.open(link);
	};

	return (
		<div>
			{isLogin ? (
				loading ? (
					<SectionLoading />
				) : !isMatchPc ? (
					<div className='mt-3'>
						{rows.length > 0 && (
							<Virtuoso
								endReached={fetchAction}
								style={{ height: 400 }}
								className={classNames({ 'pr-4': isMatchPad })}
								data={rows}
								itemContent={(columnIndex, item) => {
									return (
										<div className={classNames({ 'pr-4': isMatchPad })}>
											<div
												className={classNames(
													`grid grid-cols-${
														columns.length > 2 ? 3 : 2
													} gap-4 gap-y-3`,
													isMatchPad
														? `grid-cols-${columns.length > 2 ? 4 : 2}`
														: `grid-cols-${columns.length > 2 ? 3 : 2}`
												)}
											>
												{columns.map((column: any, columnIndex: number) => (
													<DirectionCell
														key={columnIndex}
														label={column.label}
														align={
															column.align
																? column.align
																: columnIndex &&
																  (columnIndex + 1) % (isMatchPad ? 4 : 3) === 0
																? 'right'
																: 'left'
														}
														value={column.format(item)}
													/>
												))}
											</div>
											<Divider className='my-4' />
										</div>
									);
								}}
							/>
						)}
						{rows.length === 0 && (
							<NoData>
								<Trans>No history</Trans>
							</NoData>
						)}
					</div>
				) : (
					<div className='-mx-4'>
						<VirtualTable
							rows={rows}
							columns={filteredColumn}
							itemHeight={44}
							cellColor={theme.custom.paperBg}
							loadMore={fetchAction}
							onRowClick={onRowClick}
							noDataNode={
								<NoData className='text-sm ml-4'>
									<Trans>No history</Trans>
								</NoData>
							}
							isPointer
						/>
					</div>
				)
			) : (
				<NoData>
					<Trans>No history</Trans>
				</NoData>
			)}
		</div>
	);
};

export default LiquidityHistory;
