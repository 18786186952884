import { useMemo } from 'react';

import { Side, Transaction_Type } from 'config/constants';
import Decimal from 'decimal.js';

import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { div, isPositive, minus, multipliedBy, plus } from 'utils';
import { poolsBaseState } from 'state/pools/slice';

// 用户开仓，计算对应的爆仓价格
export function useCalculateLiqPrice(
	side: Side,
	margin: string,
	size: string,
	liquidity: string,
	// 清算费率
	liquidationFeeRatePerPosition: string | number,
	// 交易费率
	tradingFeeRate: string | number,
	// 清算手续费
	liquidationExecutionFee: string,
	entryPrice: string | undefined
) {
	const tradePrice = entryPrice;
	return useMemo(() => {
		if (
			!isPositive(margin) ||
			!isPositive(size) ||
			!isPositive(liquidity) ||
			!isPositive(liquidationFeeRatePerPosition) ||
			!tradePrice
		) {
			return '';
		}
		if (side === Side.LONG) {
			return new Decimal(margin)
				.minus(multipliedBy(tradePrice, size))
				.minus(liquidationExecutionFee)
				.div(
					new Decimal(liquidationFeeRatePerPosition)
						.plus(tradingFeeRate)
						.minus(1)
						.mul(size)
				)
				.toString();
		} else {
			return new Decimal(margin)
				.plus(multipliedBy(tradePrice, size))
				.minus(liquidationExecutionFee)
				.div(
					new Decimal(liquidationFeeRatePerPosition)
						.plus(tradingFeeRate)
						.plus(1)
						.mul(size)
				)
				.toString();
		}
	}, [
		side,
		margin,
		size,
		liquidity,
		liquidationFeeRatePerPosition,
		tradingFeeRate,
		liquidationExecutionFee,
		tradePrice
	]);
}

export function useCalculateLiqPriceForLimit(
	entryPrice: string | number | undefined,
	leverage: string | number
) {
	const { transactionType } = useAppSelector(txBaseState);
	const { poolInfo } = useAppSelector(poolsBaseState);

	return useMemo(() => {
		if (!poolInfo || !entryPrice || !isPositive(leverage)) {
			return '';
		}
		const {
			liquidationFeeRatePerPosition,
			liquidationExecutionFee,
			tradingFeeRate
		} = poolInfo;
		const _feeRate = plus(
			plus(1, liquidationFeeRatePerPosition),
			tradingFeeRate
		);
		if (transactionType === Transaction_Type.LongLimitOrder) {
			const _leverageRate = minus(1, div(1, leverage));
			return new Decimal(entryPrice)
				.mul(_leverageRate)
				.mul(_feeRate)
				.plus(liquidationExecutionFee)
				.toFixed();
		} else {
			const _leverageRate = plus(1, div(1, leverage));
			return new Decimal(entryPrice)
				.mul(_leverageRate)
				.mul(_feeRate)
				.minus(liquidationExecutionFee)
				.toFixed();
		}
	}, [poolInfo, entryPrice, leverage, transactionType]);
}
