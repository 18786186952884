import { Typography } from '@mui/material';

import { CommonStyledAlertWarning } from 'components/Common/Styled';

const ImpactFeeWarning = () => {
	return (
		<CommonStyledAlertWarning className='text-left pt-0'>
			<Typography variant='body2'>
				The acceptable balance impact refers to the balance fee that you are
				willing to pay at most.
			</Typography>
		</CommonStyledAlertWarning>
	);
};

export default ImpactFeeWarning;
