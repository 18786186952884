import { useCallback, useEffect, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { Trans } from '@lingui/macro';
import {
	Avatar,
	Box,
	ListItem,
	Tooltip,
	Typography,
	useTheme
} from '@mui/material';
import classNames from 'classnames';
import {
	DEFAULT_PAGE_SIZE,
	Dom_Size,
	MAX_AMOUNT_FORMATTER_LIMIT,
	MAX_PAGE_SIZE,
	QUOTE_USD,
	Side,
	Version
} from 'config/constants';
import { usePositionHistoriesRequest } from 'fetch/useRequest';
import moment from 'moment';
import { useAccount } from 'wagmi';
import * as xlsx from 'xlsx';

import warningIcon from 'assets/svg/icon-warning.svg';
import axios from 'axios';
import {
	Position_History_Status_V1,
	Position_History_Status_V2,
	formatPostionHistoryV1,
	formatPostionHistoryV2,
	useMyPostionHistoryLazyGraph
} from 'graphql/useMyPositionHistoryGraph';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import { isEqual } from 'lodash';
import { useAppSelector } from 'state/hooks';
import { selectPoolIds } from 'state/pools/selector';
import { selectVersion } from 'state/setting/selector';
import {
	amountFormatter,
	div,
	formatLeverage,
	formatUnixDate,
	isNegative,
	isPositive,
	plus,
	shortenSymbolNative,
	toQuoteAmount
} from 'utils';

import Cell from 'components/Common/Cell';
import { CommonStyledList } from 'components/Common/Styled';
import Description from 'components/Description';
import LongShortTag from 'components/LongShortTag';
import NoData from 'components/NoData';
import SectionLoading from 'components/SectionLoading';
import Stronger from 'components/Stronger';

import { BACKEND_HOST_URL } from '../../../config/backend';
import { MyPositionHistoryQuery } from '../../../graphql/__generated_trade__/types-and-hooks';
import { poolsBaseState } from '../../../state/pools/slice';

export default function PositionHistoryList({
	poolIds,
	download,
	finishDownload
}: {
	poolIds: string[];
	download: boolean;
	finishDownload: () => void;
}) {
	const theme = useTheme();
	const { address } = useAccount();
	const allPoolIds = useAppSelector(selectPoolIds);
	const currentVersion = useAppSelector(selectVersion);
	const { pools } = useAppSelector(poolsBaseState);

	const Position_History_Status =
		currentVersion === Version.V1
			? Position_History_Status_V1
			: Position_History_Status_V2;
	const { currentChainId } = useCurrentChain();
	const { isMatchMobile, isMatchPad, isMatchPc } = useAppBreakpoints();
	const isLogin = useCheckLogin();

	const { fetch } = useMyPostionHistoryLazyGraph();
	const [from, setFrom] = useState<string | undefined>(undefined);
	const [data, setData] = useState<MyPositionHistoryQuery['positionHistories']>(
		[]
	);
	const [loading, setLoading] = useState(true);
	const [fetching, setFetching] = useState(false);
	const [hasMore, setHasMore] = useState(true);

	const targetPool = useMemo(() => {
		if (isEqual(poolIds, allPoolIds)) {
			return '';
		}
		return poolIds && poolIds[0];
	}, [poolIds]);

	const exportData = async () => {
		const response = await axios.get(
			`${
				BACKEND_HOST_URL[currentChainId].V2
			}/position-histories/${address}?&market=${targetPool}&limit=${1000}`
		);
		const result = response.data.data.position_histories.map(item => {
			const baseToken = pools.find(pool => {
				return pool.id.toLowerCase() === item.market_address.toLowerCase();
			}).baseToken;
			return {
				Market: `${shortenSymbolNative(
					baseToken,
					currentChainId
				)}/${QUOTE_USD}`,
				Side: item.side === Side.LONG ? 'Long' : 'Short',
				Leverage: formatLeverage(div(item.closed_liquidity, item.margin)),
				Status: item.liquidator_closed
					? 'Liquidated'
					: item.status === Position_History_Status.Closed
					? 'Closed'
					: item.status === Position_History_Status.PartiallyClosed
					? 'Partially Closed'
					: 'Liquidated',
				OpenTime: formatUnixDate(moment(item.entryTime).unix()),
				CloseTime:
					item.status === Position_History_Status.PartiallyClosed
						? '-'
						: formatUnixDate(moment(item.closed_time).unix()),
				EntryPrice: toQuoteAmount(item.entry_price, baseToken.precision),
				ClosePrice: toQuoteAmount(
					item.status === Position_History_Status_V2.Liquidated
						? item.liquidation_price
						: div(item.closed_liquidity, item.closed_size),
					baseToken.precision
				),
				OpenedSize: `${amountFormatter(
					item.size,
					2,
					baseToken?.positionUnits,
					MAX_AMOUNT_FORMATTER_LIMIT
				)} ${shortenSymbolNative(baseToken, currentChainId)}`,
				ClosedSize: `${amountFormatter(
					item.closed_size,
					2,
					baseToken?.positionUnits,
					MAX_AMOUNT_FORMATTER_LIMIT
				)} ${shortenSymbolNative(baseToken, currentChainId)}`,
				ClosedPnl: toQuoteAmount(
					plus(plus(item.realized_pnl, item.funding_fee), item.liquidation_fee),
					16
				)
			};
		});
		const jsonWorkSheet = xlsx.utils.json_to_sheet(result);
		const workBook = {
			SheetNames: ['positionHistory'],
			Sheets: {
				['positionHistory']: jsonWorkSheet
			}
		};
		xlsx.writeFile(
			workBook,
			`positionHistory_${moment().format('YYYY/MM/DD HH:mm')}.csv`
		);
		finishDownload();
	};
	useEffect(() => {
		if (download) {
			exportData();
		}
	}, [download]);

	const { trigger } = usePositionHistoriesRequest(
		address,
		targetPool,
		from,
		DEFAULT_PAGE_SIZE
	);

	const fetchActionV1 = useCallback(
		(index: number) => {
			if (hasMore && !fetching && address) {
				setFetching(true);
				return fetch({
					variables: {
						account: address,
						poolId: poolIds,
						skip: index === 0 ? 0 : data.length,
						first: isMatchMobile ? MAX_PAGE_SIZE : DEFAULT_PAGE_SIZE
					}
				})
					.then(res => {
						setHasMore(res.data.positionHistories.length === DEFAULT_PAGE_SIZE);
						setData(
							index === 0
								? res.data.positionHistories
								: [...data, ...res.data.positionHistories]
						);
					})
					.finally(() => setFetching(false));
			} else {
				return Promise.resolve();
			}
		},
		[address, poolIds, data, isMatchMobile, hasMore, fetching]
	);

	const fetchActionV2 = useCallback(
		(index: number) => {
			if (hasMore && !fetching && address) {
				setFetching(true);
				return trigger()
					.then(res => {
						setHasMore(res.data.hasMore);
						setData(
							index === 0
								? res.data.positionHistories
								: [...data, ...res.data.positionHistories]
						);
						setFrom(
							res.data.positionHistories.length === 0
								? undefined
								: res.data.hasMore
								? res.data.next
								: from
						);
					})
					.finally(() => setFetching(false));
			} else {
				return Promise.resolve();
			}
		},
		[address, poolIds, data, isMatchMobile, hasMore, fetching]
	);

	useEffect(() => {
		setData([]);
		if (currentVersion === Version.V1) {
			fetchActionV1(0).finally(() => setLoading(false));
		}
		if (currentVersion === Version.V2) {
			fetchActionV2(0).finally(() => setLoading(false));
		}
	}, [currentVersion]);

	const positionHistories =
		currentVersion === Version.V1
			? formatPostionHistoryV1({ positionHistories: data })
			: formatPostionHistoryV2({ positionHistories: data });
	const height = isMatchPc ? 140 : 220;

	return (
		<>
			{loading && <SectionLoading />}
			{!loading && (
				<CommonStyledList>
					{isLogin && positionHistories?.length ? (
						<Virtuoso
							endReached={
								currentVersion === Version.V1 ? fetchActionV1 : fetchActionV2
							}
							style={{
								height: Math.min(height * 3, height * positionHistories.length)
							}}
							data={positionHistories}
							itemContent={(index, item) => (
								<ListItem key={index}>
									<Box
										className={classNames('w-full space-y-2', {
											'mt-3': index !== 0
										})}
									>
										<aside className='flex items-center justify-between pr-2'>
											<LongShortTag
												type='base'
												side={item.side}
												baseSymbol={shortenSymbolNative(
													item.baseToken,
													currentChainId
												)}
												quoteSymbol={QUOTE_USD}
												leverage={item.leverage}
											/>
											{item.liquidatorClosed ? (
												<Tooltip
													title={
														<div className='py-2 px-3'>
															<Trans>
																The remaining margin of this position is
																insufficient to cover the funding fee. The
																position has been liquidated.
															</Trans>
														</div>
													}
													placement='bottom'
													arrow
												>
													<Box className='flex items-center'>
														<Avatar
															src={warningIcon}
															sx={{
																width: 14,
																height: 14
															}}
														/>
														<Typography
															className='ml-1'
															variant='body2'
															color='secondary'
														>
															<Trans>Liquidated</Trans>
														</Typography>
													</Box>
												</Tooltip>
											) : (
												<Typography variant='body2' color='secondary'>
													{item.status === Position_History_Status.Closed ? (
														<Trans>Closed</Trans>
													) : item.status ===
													  Position_History_Status.PartiallyClosed ? (
														<Trans>Partially Closed</Trans>
													) : (
														<Trans>Liquidated</Trans>
													)}
												</Typography>
											)}
										</aside>
										<div className='grid gap-2 grid-cols-3 sm:grid-cols-2 py-1 w-full'>
											<section className='space-y-3'>
												<Description
													vertical={isMatchPad}
													label={<Trans>Time Opened</Trans>}
												>
													<Typography variant='body2'>
														{currentVersion === Version.V1 &&
															formatUnixDate(
																item.entryTime,
																'MMM D, YYYY HH:mm:ss'
															)}
														{currentVersion === Version.V2 &&
															formatUnixDate(
																moment(item.entryTime).unix(),
																'MMM D, YYYY HH:mm:ss'
															)}
													</Typography>
												</Description>
												<Description
													vertical={isMatchPad}
													label={<Trans>Time Closed</Trans>}
												>
													<Typography variant='body2'>
														{item.status ===
														Position_History_Status.PartiallyClosed
															? '-'
															: currentVersion === Version.V1
															? formatUnixDate(
																	item.closedTime,
																	'MMM D, YYYY HH:mm:ss'
															  )
															: formatUnixDate(
																	moment(item.closedTime).unix(),
																	'MMM D, YYYY HH:mm:ss'
															  )}
													</Typography>
												</Description>
											</section>
											<section className='space-y-3'>
												<Description
													vertical={isMatchPad}
													label={<Trans>Entry Price</Trans>}
												>
													<Typography variant='body2'>
														{toQuoteAmount(
															item.entryPrice,
															item?.baseToken.precision
														)}
													</Typography>
												</Description>
												<Description
													vertical={isMatchPad}
													label={
														item.status ===
														Position_History_Status.Liquidated ? (
															<Trans>Liq. Price</Trans>
														) : (
															<Trans>Close Price</Trans>
														)
													}
												>
													<Typography variant='body2'>
														{toQuoteAmount(
															item.closedPrice || '',
															item?.baseToken.precision
														)}
													</Typography>
												</Description>
											</section>

											<section className='space-y-3'>
												<Description
													vertical={isMatchPad}
													label={<Trans>Opened Size</Trans>}
												>
													<Typography variant='body2'>
														{`${amountFormatter(
															item.size,
															2,
															item.baseToken?.positionUnits,
															MAX_AMOUNT_FORMATTER_LIMIT
														)} ${shortenSymbolNative(
															item.baseToken,
															currentChainId
														)}`}
													</Typography>
												</Description>
												<Description
													vertical={isMatchPad}
													label={
														item.status ===
														Position_History_Status.Liquidated ? (
															<Trans>Liquidition Size</Trans>
														) : (
															<Trans>Closed Size</Trans>
														)
													}
												>
													<Typography variant='body2'>
														{`${amountFormatter(
															item.closedSize,
															2,
															item.baseToken?.positionUnits,
															MAX_AMOUNT_FORMATTER_LIMIT
														)} ${shortenSymbolNative(
															item.baseToken,
															currentChainId
														)}`}
													</Typography>
												</Description>
											</section>
											{currentVersion === Version.V1 ? (
												<section className='space-y-3'>
													<Description
														vertical={isMatchPad}
														label={
															item.status ===
															Position_History_Status.PartiallyClosed ? (
																<Trans>Closing PnL</Trans>
															) : (
																<Trans>Closed PnL</Trans>
															)
														}
													>
														<Stronger
															value={
																<Typography
																	align='inherit'
																	className='leading-none'
																	color={
																		isPositive(item.realizedPnLTotal)
																			? theme.palette.success.main
																			: isNegative(item.realizedPnLTotal)
																			? theme.palette.error.main
																			: theme.palette.text.primary
																	}
																	variant='body2'
																>
																	{toQuoteAmount(item.realizedPnLTotal)}
																</Typography>
															}
															tooltip={
																<div>
																	<Typography
																		className='mb-2 w-[280px]'
																		align='inherit'
																		variant='body2'
																	>
																		{item.status ===
																		Position_History_Status.Liquidated ? (
																			<Trans>
																				Closed PnL = Closed Position PnL +
																				Cumulative Funding Fees + Liquidation
																				Fee
																			</Trans>
																		) : (
																			<Trans>
																				Closing PnL = Closed Position PnL +
																				Cumulative Funding Fees
																			</Trans>
																		)}
																	</Typography>

																	<Cell
																		label={<Trans>Closed Position PnL</Trans>}
																		value={toQuoteAmount(item.realizedPnL)}
																	/>

																	<Cell
																		label={
																			<Trans>Cumulative Funding Fees</Trans>
																		}
																		value={toQuoteAmount(item.fundingFee)}
																	/>

																	{item.status ===
																		Position_History_Status.Liquidated && (
																		<Cell
																			label={<Trans>Liquidation Fee</Trans>}
																			value={toQuoteAmount(item.liquidationFee)}
																		/>
																	)}
																</div>
															}
														/>
													</Description>
												</section>
											) : (
												<section className='space-y-3'>
													<Description
														vertical={isMatchPad}
														label={
															item.status ===
															Position_History_Status.PartiallyClosed ? (
																<Trans>Closing PnL</Trans>
															) : (
																<Trans>Closed PnL</Trans>
															)
														}
													>
														<Stronger
															value={
																<Typography
																	align='inherit'
																	className='leading-none'
																	color={
																		isPositive(item.realizedPnlTotal)
																			? theme.palette.success.main
																			: isNegative(item.realizedPnlTotal)
																			? theme.palette.error.main
																			: theme.palette.text.primary
																	}
																	variant='body2'
																>
																	{toQuoteAmount(item.realizedPnlTotal)}
																</Typography>
															}
															tooltip={
																<div>
																	<Typography
																		className='mb-2 w-[280px]'
																		align='inherit'
																		variant='body2'
																	>
																		{item.status ===
																		Position_History_Status.Liquidated ? (
																			<Trans>
																				Closed PnL = Closed Position PnL +
																				Cumulative Net Funding + Liquidation Fee
																			</Trans>
																		) : (
																			<Trans>
																				Closing PnL = Closed Position PnL +
																				Cumulative Net Funding
																			</Trans>
																		)}
																	</Typography>

																	<Cell
																		label={<Trans>Closed Position PnL</Trans>}
																		value={toQuoteAmount(item.realizedPnl)}
																	/>

																	<Cell
																		label={
																			<Trans>Cumulative Funding Fees</Trans>
																		}
																		value={toQuoteAmount(item.fundingFee)}
																	/>

																	{item.status ===
																		Position_History_Status.Liquidated && (
																		<Cell
																			label={<Trans>Liquidation Fee</Trans>}
																			value={toQuoteAmount(item.liquidationFee)}
																		/>
																	)}
																</div>
															}
														/>
													</Description>
												</section>
											)}
										</div>
									</Box>
								</ListItem>
							)}
						/>
					) : (
						<NoData>
							<Trans>No position history</Trans>
						</NoData>
					)}
					{loading && <SectionLoading full />}
				</CommonStyledList>
			)}
		</>
	);
}
