import { useEffect, useState } from 'react';

import {
	QUOTE_USD_PRECISION,
	Side,
	Transaction_Status,
	Transaction_Type,
	Version
} from 'config/constants';
import { useAccount } from 'wagmi';

import { PositionUtil } from 'entities/PositionUtil';
import { useCheckLogin } from 'hooks/useCurrentChain';
import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { selectVersion } from 'state/setting/selector';
import { tradeBaseState } from 'state/trade/slice';
import { txBaseState } from 'state/tx/slice';
import { IPoolItem, IRequestItem, TokenInfo } from 'types';
import {
	catchFn,
	div,
	formatUnits,
	multipliedBy,
	neg,
	parseUnits,
	plus
} from 'utils';

import { useMyPositionsRequest } from './useRequest';

export interface IPositionItem {
	id: number;
	poolId: string;
	baseToken: TokenInfo;
	entryPrice: string;
	entryTime: string;
	size: string;
	// 初始保证金
	margin: string;
	// 减仓已实现盈亏
	realizedPnl: string;
	// 当前仓位总的已实现盈亏：减仓已实现盈亏 + 总资金费
	realizedPnLTotal: string;
	leverage: number | string;
	side: Side;
	maxLeveragePerPosition: number;

	// 资金费总计
	fundingFeeTotal: string;
	// 保证金净值
	netMargin: string;
	// 价格影响费用
	// priceImpactFee: string;
	// 未实现盈亏
	unrealizedPnL: string;
	// 未实现盈亏率
	unrealizedPnLRate: string;
	// 强平价格
	liqPrice: string;
	// 预估平仓费
	liqFee: string;
	marginIncreased: string;
	marginDecreased: string;
	tradingFeeByClosing: string;
	reducedAndSettled: string;
	//已实现盈亏（保证金实际盈亏）
	// profitBalance: string;
	// 保证金净值 = 初始保证金（Initial Margin）+保证金净盈亏（Net Margin PnL）
	netValue: string;
	// 维持保证金率原始值
	// liquidationFeeRatePerPositionOriginal: number;
	// 维持保证金率
	liquidationFeeRatePerPosition: string;
	// 流动性
	liquidity: string;
	// 静态流动性费率
	tradingFeeRate: string;
	// PL仓位结算手续费
	liquidationExecutionFee: string;
	// 已支付的交易费
	tradingFee: string;

	// 当前池子价格
	price: string;
	minPrice: string;
	maxPrice: string;
	indexPrice: string;
	marketPrice: string;
	minIndexPrice: string;
	maxIndexPrice: string;
	minMarketPrice: string;
	maxMarketPrice: string;
	marginRate: string;

	status?: Transaction_Status;
	transactionType?: Transaction_Type;
	blockTimestamp?: number;
	discountedTradingFeeRate: string;
	coefficient?: string;
}

interface ParamsProps {
	poolId?: string;
	hashes?: Array<string>;
	enabled: boolean;
}

export function useMyPostionsFetch(params: ParamsProps) {
	const { poolId, hashes, enabled } = params;
	const { address } = useAccount();
	const { blockTimestamp } = useAppSelector(globalBaseState);
	const { quoteToken } = useAppSelector(txBaseState);
	const { isBindReferralCode } = useAppSelector(tradeBaseState);
	const { poolMap } = useAppSelector(poolsBaseState);
	const currentVersion = useAppSelector(selectVersion);

	const [isLoadingPositions, setIsLoadingPositions] = useState<boolean>(true);
	const [positionMap, setPositionMap] = useState<Map<
		string,
		Array<IPositionItem>
	> | null>(null);
	const [positionList, setPositionList] = useState<Array<IPositionItem> | null>(
		null
	);
	const [requestsByHashes, setRequestsByHashes] = useState<IRequestItem[]>([]);

	const {
		data,
		isLoading,
		refetch: refetchPositions
	} = useMyPositionsRequest({ account: address, hashes, enabled });

	const isLogin = useCheckLogin();

	useEffect(() => {
		if (!isLoading) {
			setIsLoadingPositions(isLoading);
		}
	}, [isLoading]);

	useEffect(() => {
		if (!isLogin) {
			setPositionMap(null);
		}
	}, [isLogin]);

	useEffect(() => {
		if (!data || !poolMap.size || !quoteToken || isLoading || !isLogin) {
			return;
		}

		const positionMap = new Map<string, Array<IPositionItem>>();
		const positionList = [] as Array<IPositionItem>;

		data?.positions?.forEach(item => {
			const currentPoolAddress = item.marketAddress.toLowerCase();
			if (poolId && poolId !== currentPoolAddress) {
				return;
			}

			const {
				id,
				fundingFee,
				tradingFee,
				realizedPnl,
				marginIncreased,
				marginDecreased,
				tradingFeeByClosing
			} = item;

			const currentPool = poolMap.get(currentPoolAddress) as IPoolItem;

			const baseToken = currentPool?.baseToken;

			const { decimals } = baseToken;

			const {
				liquidationFeeRatePerPosition,
				tradingFeeRate,
				liquidationExecutionFee,
				price,
				indexPrice,
				marketPrice,
				minIndexPrice,
				maxIndexPrice,
				minMarketPrice,
				maxMarketPrice,
				referralDiscountRate,
				maxLeveragePerPosition
			} = currentPool;

			// console.log(baseToken.symbol, indexPrice);

			// 累计资金费
			const fundingFeeTotal = catchFn(() => {
				// 浮动资金费
				const { longFundingRateGrowthX96, shortFundingRateGrowthX96 } =
					currentPool.globalPosition;
				const _globalFundingRateGrowthX96 =
					item.side === Side.LONG
						? longFundingRateGrowthX96
						: shortFundingRateGrowthX96;
				// 仓位进行调整需要清算的资金费率
				const requiredFundingFee = formatUnits(
					PositionUtil.calculateFundingFee(
						_globalFundingRateGrowthX96,
						item.fundingRateGrowthX96,
						parseUnits(item.size, decimals)
					),
					QUOTE_USD_PRECISION
				);

				return plus(requiredFundingFee, fundingFee);
			}, '0');
			// console.log('item.side: ', item.side);
			const _price = item.side === Side.LONG ? maxIndexPrice : minIndexPrice;

			const unrealizedPnL = catchFn(() => {
				return PositionUtil.calculateUnrealizedPnL(
					item.side,
					item.size,
					item.entryPrice,
					_price
				);
			}, '0');

			// 减少保证金和平仓提取的margin
			const reducedAndSettled = plus(
				plus(marginDecreased, realizedPnl),
				tradingFeeByClosing
			);

			// 保证金净值
			const netMargin = plus(
				marginIncreased,
				plus(reducedAndSettled, fundingFeeTotal)
			);

			// 未实现盈亏率
			const unrealizedPnLRate = div(unrealizedPnL, netMargin);

			let discountedTradingFeeRate = tradingFeeRate;
			if (isBindReferralCode) {
				discountedTradingFeeRate = multipliedBy(
					tradingFeeRate,
					referralDiscountRate
				);
			}

			// 计算预估爆仓价
			const liqPrice = catchFn(() => {
				return PositionUtil.calculateLiqPrice(
					item.side,
					netMargin,
					item.size,
					item.entryPrice,
					liquidationExecutionFee,
					liquidationFeeRatePerPosition,
					discountedTradingFeeRate
				);
			}, '');

			// 计算预估平仓费
			const liqFee = catchFn(() => {
				const _liquidityNow = multipliedBy(item.size, indexPrice);
				return neg(multipliedBy(_liquidityNow, discountedTradingFeeRate));
			}, '');

			// 计算仓位净值
			const netValue = plus(plus(netMargin, unrealizedPnL), liqFee);
			// 计算杠杆倍数
			const _leverage = PositionUtil.calculateLeverage(
				netMargin,
				item.liquidity
			);

			// 计算保证金率
			const _maintenanceMargin = PositionUtil.calculateMaintenanceMargin(
				item.entryPrice,
				item.size,
				_price,
				liquidationFeeRatePerPosition,
				discountedTradingFeeRate,
				liquidationExecutionFee
			);
			const marginRate = div(
				_maintenanceMargin,
				plus(netMargin, unrealizedPnL)
			);

			const realizedPnLTotal = plus(item.realizedPnL, fundingFeeTotal);

			// console.log('------------start-----------');
			// console.log('size', item.size);
			// console.log('entryPrice', item.entryPrice);
			// console.log('indexPrice', _price);
			// console.log(
			// 	'liquidationFeeRate',
			// 	div(liquidationFeeRatePerPosition, 10000)
			// );
			// console.log('liquidationExecutionFee', liquidationExecutionFee);
			// console.log('maintenanceMargin', _maintenanceMargin);
			// console.log('netMargin', netMargin);
			// console.log('unrealizedPnL', unrealizedPnL);
			// console.log('------------end-----------');

			const positionItem = {
				id,
				poolId: currentPoolAddress,
				// pool: item.pool,
				baseToken,
				entryPrice: item.entryPrice,
				entryTime: item.entryTime,
				size: item.size,
				margin: item.margin,
				realizedPnl: item.realizedPnl,
				realizedPnLTotal,
				leverage: _leverage,
				side: item.side,

				fundingFeeTotal,
				netMargin,
				unrealizedPnL,
				unrealizedPnLRate,
				reducedAndSettled,
				marginIncreased,
				marginDecreased,
				tradingFeeByClosing,
				netValue,
				liqPrice,
				liqFee,
				liquidationFeeRatePerPosition,
				liquidity: multipliedBy(item.entryPrice, item.size),
				tradingFeeRate,
				liquidationExecutionFee,
				tradingFee,

				price,
				maxLeveragePerPosition,
				indexPrice: indexPrice,
				marketPrice: marketPrice,
				minIndexPrice: minIndexPrice,
				maxIndexPrice: maxIndexPrice,
				minMarketPrice: minMarketPrice,
				maxMarketPrice: maxMarketPrice,
				blockTimestamp: blockTimestamp,
				marginRate,
				discountedTradingFeeRate
			} as IPositionItem;

			const target = positionMap.get(currentPoolAddress);
			if (!target) {
				positionMap.set(currentPoolAddress, [positionItem]);
			} else {
				target.push(positionItem);
			}
			positionList.push(positionItem);
		});

		setPositionMap(positionMap);
		setRequestsByHashes(data.requestsByHashes);
		setPositionList(positionList);
	}, [
		data,
		quoteToken,
		poolMap,
		poolId,
		isBindReferralCode,
		isLoading,
		currentVersion
	]);

	return {
		positionList,
		positionMap,
		requestsByHashes,
		isLoadingPositions,
		refetchPositions
	};
}
