function Contributor(props: any) {
	const { color = 'white' } = props;
	return (
		<svg
			width='21'
			height='19'
			viewBox='0 0 21 19'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<circle
				cx='10.7332'
				cy='8.67034'
				r='7.06874'
				stroke={color}
				strokeOpacity='0.9'
				strokeWidth='1.28523'
			/>
			<path
				d='M10.9815 12.9884V3.64132H11.5803V12.9884H10.9815ZM12.9969 6.44545C12.9531 6.07546 12.7754 5.78823 12.4639 5.58377C12.1523 5.3793 11.7701 5.27706 11.3174 5.27706C10.9863 5.27706 10.6967 5.33062 10.4484 5.43772C10.2025 5.54482 10.0102 5.69209 9.87149 5.87951C9.73518 6.06694 9.66703 6.27993 9.66703 6.51848C9.66703 6.71808 9.71449 6.88968 9.80942 7.0333C9.90679 7.17448 10.0309 7.29253 10.1818 7.38746C10.3328 7.47996 10.491 7.55664 10.6565 7.61749C10.822 7.67591 10.9742 7.72338 11.1129 7.75989L11.8724 7.96436C12.0671 8.01547 12.2837 8.08606 12.5223 8.17613C12.7633 8.26619 12.9933 8.38911 13.2124 8.5449C13.4339 8.69825 13.6164 8.89541 13.76 9.13639C13.9036 9.37737 13.9755 9.67312 13.9755 10.0236C13.9755 10.4277 13.8696 10.7928 13.6578 11.119C13.4485 11.4452 13.1418 11.7044 12.7377 11.8967C12.3361 12.089 11.848 12.1852 11.2736 12.1852C10.738 12.1852 10.2743 12.0987 9.88245 11.9259C9.49299 11.7531 9.18628 11.5121 8.96234 11.203C8.74084 10.8938 8.61548 10.5348 8.58627 10.1259H9.52098C9.54532 10.4082 9.64025 10.6419 9.80577 10.8269C9.97373 11.0095 10.1855 11.1458 10.4411 11.2358C10.6991 11.3235 10.9766 11.3673 11.2736 11.3673C11.6192 11.3673 11.9296 11.3113 12.2046 11.1993C12.4797 11.0849 12.6975 10.9267 12.8582 10.7247C13.0188 10.5202 13.0992 10.2817 13.0992 10.009C13.0992 9.76075 13.0298 9.55872 12.891 9.40293C12.7523 9.24715 12.5697 9.12057 12.3434 9.02321C12.117 8.92584 11.8724 8.84065 11.6095 8.76762L10.6894 8.50473C10.1052 8.33678 9.64269 8.09702 9.30191 7.78545C8.96113 7.47388 8.79074 7.06616 8.79074 6.56229C8.79074 6.14362 8.90392 5.7785 9.1303 5.46693C9.35911 5.15292 9.66581 4.90951 10.0504 4.73669C10.4374 4.56143 10.8695 4.4738 11.3466 4.4738C11.8285 4.4738 12.257 4.56021 12.6318 4.73303C13.0067 4.90342 13.3036 5.1371 13.5227 5.43407C13.7442 5.73103 13.8611 6.06816 13.8732 6.44545H12.9969Z'
				fill={color}
				fillOpacity='0.9'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2.58721 12.5244H0.436279V18.9505H20.9999V12.5244H18.8488C18.6349 12.9747 18.3847 13.4043 18.102 13.8096H19.7146V17.6653H10.7222C10.7208 17.6653 10.7194 17.6653 10.718 17.6653C10.7166 17.6653 10.7152 17.6653 10.7138 17.6653H1.72144V13.8096H3.33399C3.05129 13.4043 2.8011 12.9747 2.58721 12.5244Z'
				fill={color}
				fillOpacity='0.9'
			/>
		</svg>
	);
}

export default Contributor;
