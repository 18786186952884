import { useMemo } from 'react';

import {
	Contract_Write_Mode,
	REWARDS_MULTICALL_RATE,
	Transaction_Type
} from 'config/constants';
import { selectContractByVersion } from 'config/contracts';
import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';
import { txBaseState } from 'state/tx/slice';
import { RecordForClaimReferrals } from 'types';
import { formatNumber, isPositive, multipliedBy, parseUnits } from 'utils';

import { useComputeGasLimit } from './useComputeGasLimit';
import { useCurrentChain } from './useCurrentChain';
import { useSelectRewardConnectorByVersion } from './useSelectByVersion';
import { useSendTransaction } from './useSendTransaction';

export function useSubmitClaimReferralsNew(
	transactionType: Transaction_Type,
	feePoolIds: Array<string> | [],
	quoteAmount: string,
	calldata: string | undefined,
	tokenIds: Array<string> | null,
	appAmount: string
) {
	const { quoteToken, appToken } = useAppSelector(txBaseState);
	const { currentChainId } = useCurrentChain();
	const { address } = useAccount();
	const currentVersion = useAppSelector(selectVersion);

	const { rewardCollectorInterface, rewardCollectorABI } =
		useSelectRewardConnectorByVersion();

	const { args, record, description } = useMemo(() => {
		if (
			!address ||
			!tokenIds ||
			!appToken ||
			(!isPositive(quoteAmount) && !isPositive(appAmount))
		) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		const results = [] as Array<string>;

		const quoteValue = parseUnits(
			multipliedBy(quoteAmount, REWARDS_MULTICALL_RATE),
			quoteToken.decimals
		);

		if (isPositive(quoteValue)) {
			results.push(
				rewardCollectorInterface.encodeFunctionData('collectReferralFeeBatch', [
					feePoolIds,
					tokenIds
				])
			);

			results.push(
				rewardCollectorInterface.encodeFunctionData('sweepToken', [
					quoteToken.address,
					quoteValue,
					address
				])
			);
		}

		if (calldata) {
			results.push(calldata);
		}

		console.log('useSubmitClaimReferralsNew args begin ...');

		const record = {
			transactionType,
			tokenIds,
			quoteAmount,
			appAmount
		} as unknown as RecordForClaimReferrals;

		const description = TransactionDescription.Claim(
			formatNumber(quoteAmount, quoteToken.positionUnits),
			formatNumber(appAmount, appToken.positionUnits)
		);

		return {
			args: [results],
			record,
			description
		};
	}, [
		address,
		tokenIds,
		appAmount,
		appToken,
		quoteAmount,
		quoteToken,
		calldata,
		rewardCollectorInterface
	]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}

		return {
			mode: Contract_Write_Mode.Unprepared,
			address: selectContractByVersion(
				currentVersion,
				currentChainId,
				'RewardCollector'
			),
			abi: rewardCollectorABI,
			functionName: 'multicall',
			args
		} as UseContractWriteConfig;
	}, [currentChainId, currentVersion, args, rewardCollectorABI]);

	const { gasLimit } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirmed, isConfirming, error } =
		useSendTransaction(contractWriteArgs, gasLimit);

	const onConfirm = () => {
		if (!record) return;
		onSendTransaction(record, description);
	};

	return {
		onConfirm,
		isConfirming,
		isConfirmed,
		error
	};
}
