import { SvgProps } from 'components/Svg/types';

// hover状态
export function EarnActive({ fill = 'white' }: SvgProps) {
	return (
		<svg
			width='26'
			height='25'
			viewBox='0 0 26 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_19041_52422)'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M25.1893 13.5793C23.932 16.0724 19.0116 17.9323 13.1333 17.9323C7.43841 17.9323 2.64255 16.1866 1.20557 13.8108V17.4272C1.20005 17.4971 1.19727 17.5673 1.19727 17.6378C1.19727 20.7354 6.56982 23.2465 13.1972 23.2465C19.8246 23.2465 25.1972 20.7354 25.1972 17.6378C25.1972 17.5694 25.1945 17.5013 25.1893 17.4334V13.5793Z'
					fill={fill}
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M25.189 8.82211C23.2522 10.9971 18.6282 12.528 13.232 12.528C7.78929 12.528 3.11833 10.9706 1.20523 8.76562V10.4744C1.19994 10.5429 1.19727 10.6116 1.19727 10.6807C1.19727 13.7783 6.56982 16.2894 13.1972 16.2894C19.8246 16.2894 25.1972 13.7783 25.1972 10.6807C25.1972 10.6108 25.1944 10.5413 25.189 10.472V8.82211Z'
					fill={fill}
				/>
				<path
					d='M25.1976 6.06348C25.1976 7.91646 20.4587 10.7754 13.1977 10.7754C5.75725 10.7754 1.19775 8.27401 1.19775 6.06348C1.19775 3.69396 6.57031 1.35156 13.1977 1.35156C19.8251 1.35156 25.1976 3.66342 25.1976 6.06348Z'
					fill={fill}
				/>
			</g>
			<defs>
				<clipPath id='clip0_19041_52422'>
					<rect
						width='25.9615'
						height='24.0385'
						fill={fill}
						transform='translate(0.019043 0.19043)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
}

export function Earn({ fill = 'white' }: SvgProps) {
	return (
		<svg
			width='26'
			height='25'
			viewBox='0 0 26 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_19481_30239)'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M23.6857 12.8604C23.3252 13.4064 22.7564 13.9125 22.0203 14.3604H23.6888V17.414L23.6932 17.4713C23.6955 17.501 23.6967 17.5309 23.6967 17.561C23.6967 18.1821 23.0994 19.2087 21.0468 20.1681C19.1153 21.0709 16.3415 21.6697 13.1967 21.6697C10.052 21.6697 7.27814 21.0709 5.34662 20.1681C3.29409 19.2087 2.69678 18.1821 2.69678 17.561C2.69678 17.53 2.698 17.4992 2.70042 17.4685L2.70508 17.4096V14.3604H4.43134C3.69527 13.9125 3.12643 13.4064 2.76593 12.8604H1.20508V17.3504C1.19956 17.4203 1.19678 17.4905 1.19678 17.561C1.19678 20.6586 6.56933 23.1697 13.1967 23.1697C19.8241 23.1697 25.1967 20.6586 25.1967 17.561C25.1967 17.4926 25.194 17.4245 25.1888 17.3566V12.8604H23.6857Z'
					fill='#8B919D'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M2.41979 7.03711H1.20508V12.0359C1.19956 12.1058 1.19678 12.176 1.19678 12.2465C1.19678 12.317 1.19956 12.3872 1.20508 12.4571V13.9229H1.74193C3.26689 16.2016 7.81864 17.8552 13.1967 17.8552C18.5748 17.8552 23.1265 16.2016 24.6515 13.9229H25.1888V12.4509C25.194 12.383 25.1967 12.3149 25.1967 12.2465C25.1967 12.1781 25.194 12.11 25.1888 12.0421V7.03711H23.9625C23.77 7.57189 23.3735 8.07711 22.8087 8.53711H23.6888V12.0995L23.6932 12.1567C23.6955 12.1865 23.6967 12.2164 23.6967 12.2465C23.6967 12.2766 23.6955 12.3065 23.6932 12.3363L23.6888 12.3935V12.6643L23.4049 13.0886C22.9042 13.8368 21.7417 14.6758 19.8748 15.3273C18.0536 15.9629 15.741 16.3552 13.1967 16.3552C10.6524 16.3552 8.33981 15.9629 6.51865 15.3273C4.6517 14.6758 3.48923 13.8368 2.98854 13.0886L2.70508 12.665V12.3979L2.70042 12.339C2.698 12.3083 2.69678 12.2775 2.69678 12.2465C2.69678 12.2155 2.698 12.1847 2.70042 12.154L2.70508 12.0951V8.53711H3.5736C3.00887 8.07711 2.61231 7.57189 2.41979 7.03711Z'
					fill='#8B919D'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M21.0468 9.54198C23.0994 8.58264 23.6967 7.556 23.6967 6.9349C23.6967 6.31381 23.0994 5.28717 21.0468 4.32783C19.1153 3.42504 16.3415 2.82617 13.1967 2.82617C10.052 2.82617 7.27814 3.42504 5.34662 4.32783C3.29409 5.28717 2.69678 6.31381 2.69678 6.9349C2.69678 7.556 3.29409 8.58264 5.34662 9.54198C7.27814 10.4448 10.052 11.0436 13.1967 11.0436C16.3415 11.0436 19.1153 10.4448 21.0468 9.54198ZM13.1967 12.5436C19.8241 12.5436 25.1967 10.0325 25.1967 6.9349C25.1967 3.83729 19.8241 1.32617 13.1967 1.32617C6.56933 1.32617 1.19678 3.83729 1.19678 6.9349C1.19678 10.0325 6.56933 12.5436 13.1967 12.5436Z'
					fill='#8B919D'
				/>
			</g>
			<defs>
				<clipPath id='clip0_19481_30239'>
					<rect
						width='25.9615'
						height='24.0385'
						fill='white'
						transform='translate(0.019043 0.228516)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
