import { useEffect, useState } from 'react';

import {
	Order_Open_Type_V1,
	Order_Open_Type_V2,
	Order_Status_V1,
	Order_Status_V2,
	Side,
	Transaction_Status,
	Transaction_Type
} from 'config/constants';

import { useAllTokens } from 'hooks/useAllTokens';
import { sortBy, toNumber } from 'lodash';
import { TokenInfo } from 'types';
import {
	getTransactionTypeForLimit,
	getTransactionTypeForProfitLoss,
	multipliedBy
} from 'utils';

import {
	MyOrderHistoryQuery,
	useMyOrderHistoryLazyQuery
} from './__generated_trade__/types-and-hooks';

import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';

export interface IOrderItem {
	id: string;
	// pool id
	poolId: string;
	type: Order_Open_Type_V1;
	/*
        针对Limit订单
        LongLimitOrder = 'LongLimitOrder',
        ShortLimitOrder = 'ShortLimitOrder',
        针对止盈止损订单
        CreateTakeProfitPosition = 'CreateTakeProfitPosition',
        CreateTakeProfitPartial = 'CreateTakeProfitPartial',
        CreateStopLossPosition = 'CreateTakeProfitPosition',
        CreateStopLossPartial = 'CreateTakeProfitPartial',
    */
	transactionType: Transaction_Type;
	// current tx hash
	txHash: string;
	// created tx hash
	createdHash: string;
	// executed tx hash
	executedHash: string;
	// cancelled tx hash
	cancelledHash: string;
	// pool Position index
	index: string;
	// type
	side: Side;
	// user
	account: string;
	// orders add
	triggerAbove: boolean;
	// orders by $
	liquidityDelta: string;
	// pay
	marginDelta: string;
	// trigger price
	triggerMarketPrice: string;
	// acceptable price
	acceptableTradePrice: string;
	// mark Price
	indexPrice: string;
	baseToken: TokenInfo;
	createdTimestamp: string | number;
	cancelledTimestamp: string | number;
	executedTimestamp: string | number;
	// timestamp: number;
	// Created  Executed Cancelled
	status?: Transaction_Status;
	orderStatus?: Order_Status_V1;
	positionAmount?: string;
	sizeDelta?: string;
}

export function useMyOrderHistoryLazyGraph() {
	const [fetch] = useMyOrderHistoryLazyQuery();
	return { fetch };
}

export function formatMyOrderHistoryV1(data: {
	orders: MyOrderHistoryQuery['orders'];
}) {
	const allTokens = useAllTokens();
	if (!data || !data.orders) {
		return [];
	}
	const { orders = [] } = data;
	const orderList = [] as Array<IOrderItem>;
	orders.forEach(item => {
		const transactionType =
			item.type === Order_Open_Type_V1.Increase
				? getTransactionTypeForLimit(item.side)
				: getTransactionTypeForProfitLoss(
						item.triggerAbove,
						item.side,
						item.sizeDelta
				  );
		const orderItem = {
			id: item.id,
			transactionType,
			// created tx hash
			createdHash: item.createdHash,
			// executed tx hash
			executedHash: item.executedHash,
			// cancelled tx hash
			cancelledHash: item.cancelledHash,
			txHash:
				item.status === Order_Status_V1.Created
					? item.createdHash
					: item.status === Order_Status_V1.Cancelled
					? item.cancelledHash
					: item.executedHash,
			poolId: item.pool.id,
			type: item.type,
			index: item.index,
			side: item.side,
			account: item.account,
			triggerAbove: item.triggerAbove,
			liquidityDelta: multipliedBy(item.sizeDelta, item.triggerMarketPrice),
			marginDelta: item.marginDelta,
			triggerMarketPrice: item.triggerMarketPrice,
			acceptableTradePrice: item.acceptableTradePrice,
			indexPrice: item.pool.token.price,
			baseToken: allTokens.get(item.pool.token.id),
			createdTimestamp: item.createdTimestamp,
			cancelledTimestamp: item.cancelledTimestamp,
			executedTimestamp: item.executedTimestamp,
			sizeDelta: item.sizeDelta,
			orderStatus: item.status
		} as IOrderItem;

		orderList.push(orderItem);
	});

	return sortBy(orderList, o => {
		return -o.timestamp;
	});
}

export function formatMyOrderHistoryV2(data: {
	orders: MyOrderHistoryQuery['orders'];
}) {
	const allTokens = useAllTokens();
	if (!data || !data.orders) {
		return [];
	}
	const { orders = [] } = data;
	const orderList = [] as Array<IOrderItem>;
	const { pools } = useAppSelector(poolsBaseState);
	
	orders.forEach(item => {
		const filteredPool = pools.filter(pool => {
			return pool.id.toLowerCase() === item.market.toLowerCase();
		});
		
		const baseToken = filteredPool.length > 0 ? filteredPool[0]?.baseToken : {};
		const transactionType =
			item.type === Order_Open_Type_V2.Increase
				? getTransactionTypeForLimit(item.side)
				: getTransactionTypeForProfitLoss(
						item.triggerAbove,
						item.side,
						item.sizeDelta
				);
		const orderItem = {
			id: item.id,
			transactionType,
			// created tx hash
			createdHash: item.createdHash,
			// executed tx hash
			executedHash: item.executedHash,
			// cancelled tx hash
			cancelledHash: item.cancelledHash,
			txHash: item.status === Order_Status_V2.Created
				? item.createdHash
				: item.status === Order_Status_V2.Cancelled
					? item.cancelledHash
					: item.executedHash,
			poolId: item.pool,
			type: item.type,
			index: item.index,
			side: item.side,
			account: item.account,
			triggerAbove: item.triggerAbove,
			liquidityDelta: multipliedBy(item.sizeDelta, item.triggerMarketPrice),
			marginDelta: item.marginDelta,
			triggerMarketPrice: item.triggerMarketPrice,
			acceptableTradePrice: item.acceptableTradePrice,
			indexPrice: filteredPool.length > 0 ? filteredPool[0]?.indexPrice : '0',
			baseToken: baseToken,
			createdTimestamp: item.createdTimestamp,
			cancelledTimestamp: item.cancelledTimestamp,
			executedTimestamp: item.executedTimestamp,
			sizeDelta: item.sizeDelta,
			orderStatus: item.status
		} as unknown as IOrderItem;

		orderList.push(orderItem);
	});

	return sortBy(orderList, o => {
		return -o.timestamp;
	});
}