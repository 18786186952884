import { useMemo } from 'react';

import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import { MAX_FOMATTED_PRICE } from 'config/constants';

import { isGreaterThan, isNegative, isPositive, toQuoteAmount } from 'utils';

export default function LiqPrice({
	value,
	margin,
	precision
}: {
	value: string;
	margin?: string;
	precision?: number;
}) {
	const formattedPrice = useMemo(() => {
		if (isPositive(value)) {
			if (isGreaterThan(value, MAX_FOMATTED_PRICE)) {
				return `>${MAX_FOMATTED_PRICE}`;
			}
			return toQuoteAmount(value, precision);
		}
	}, [value]);
	return (
		<Typography component='span' variant='body2'>
			{margin && isNegative(margin) ? (
				<Trans>Liquidating</Trans>
			) : isNegative(value) ? (
				'-'
			) : (
				formattedPrice
			)}
		</Typography>
	);
}
