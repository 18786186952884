import Box from '@mui/material/Box';

import StakeSummary from './Summary';
import StakeLpTokenList from '../Stake/StakeLpTokenList';
import Divider from '@mui/material/Divider';

export default function Pool2Mining() {
	return (
		<Box className='underLg:space-y-2 -mx-4 underLg:mx-0'>
			<StakeSummary />
			<Divider className='sm:hidden md:hidden' />
			<div className='underLg:space-y-2'>
				<StakeLpTokenList />
			</div>
		</Box>
	);
}
