import { createSelector } from '@reduxjs/toolkit';

import { IPoolItem } from 'types';

import { poolsBaseState } from './slice';

export const selectPoolIds = createSelector(poolsBaseState, state => {
	return state.pools.map((pool: IPoolItem) => {
		return pool.id;
	});
});

export const selectCurrentPool = createSelector(poolsBaseState, state => {
	const { poolAddress, poolMap } = state;
	if (!poolMap || !poolMap.size) {
		return null;
	}
	return poolMap.get(poolAddress);
});

export const selectCurrentPrice = createSelector(
	[selectCurrentPool],
	(currentPool) => {
		if (!currentPool) {
			return undefined;
		}
		return currentPool.price;
	}
);

export const selectCurrentMarketPrice = createSelector(
	[selectCurrentPool],
	(currentPool) => {
		if (!currentPool) {
			return undefined;
		}
		return currentPool.marketPrice;
	}
);

export const selectCurrentIndexPrice = createSelector(
	[selectCurrentPool],
	(currentPool) => {
		if (!currentPool) {
			return undefined;
		}
		return currentPool.indexPrice;
	}
);

export const selectCurrentPriceChange = createSelector(
	[selectCurrentPool],
	(currentPool) => {
		if (!currentPool) {
			return undefined;
		}
		return currentPool.priceChange;
	}
);
