import { useMemo } from 'react';

import { useApolloClients } from 'context/ApolloClientsContext';
import { useAccount } from 'wagmi';

import { useConnectorHistoriesQuery } from './__generated_referral__/types-and-hooks';
import { IMintHistory } from 'types';

export function useConnectorHistoriesGraph() {
	const { clientReferral } = useApolloClients();
	const { address } = useAccount();
	const { data, error, refetch, loading } = useConnectorHistoriesQuery({
		variables: {
			account: address as string
		},
		skip: !address,
		client: clientReferral
	});

	return useMemo(() => {
		return {
			data: !data || !data.mintHistories ? null : data.mintHistories as Array<IMintHistory>,
			refetch,
			loading,
			error
		};
	}, [data, refetch, loading, error]);
}
