import React, { useEffect } from 'react';
import { useUpdateEffect } from 'react-use';

import { useApolloClients } from 'context/ApolloClientsContext';
import { useAccount } from 'wagmi';

import { useLiquidityRewardsQuery } from 'graphql/__generated_referral__/types-and-hooks';
import { useLiquidityRewardsGraph } from 'graphql/useLiquidityRewardsGraph';
import { useCheckLogin } from 'hooks/useCurrentChain';
import { globalBaseState } from 'state/global/slice';
import { useAppDispatch, useAppSelector } from 'state/hooks';

import {
	setIsLoadingLiquidityList,
	setLiquidityList,
	setPreviousLiquidityList
} from './slice';

export default React.memo(function EarnLiquidityUpdater() {
	const dispatch = useAppDispatch();
	const { appTimer } = useAppSelector(globalBaseState);

	const isLogin = useCheckLogin();
	const { address } = useAccount();
	const { clientReferral } = useApolloClients();

	const { data, loading, refetch } = useLiquidityRewardsGraph();

	useUpdateEffect(() => {
		dispatch(setLiquidityList(data));
	}, [data]);

	useEffect(() => {
		dispatch(setIsLoadingLiquidityList(loading));
	}, [loading]);

	// FIXME
	const {
		data: liquidityRewardsRep,
		refetch: refetchPreviousLiquidityRewards
	} = useLiquidityRewardsQuery({
		variables: {
			account: address ? address.toLowerCase() : ''
		},
		skip: !address,
		client: clientReferral
	});

	useEffect(() => {
		if (liquidityRewardsRep) {
			dispatch(setPreviousLiquidityList(liquidityRewardsRep.liquidityRewards));
		}
	}, [liquidityRewardsRep]);

	useUpdateEffect(() => {
		if (isLogin) {
			refetch();
			refetchPreviousLiquidityRewards();
		}
	}, [isLogin, address, appTimer]);

	return null;
});
