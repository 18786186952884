import { Reducer, createSlice } from '@reduxjs/toolkit';
import { Transaction_Type } from 'config/constants/enum';
import { IV3Pool } from 'hooks/V3/types';

import { produce } from 'immer';
import { RootState } from 'state';
import { BaseRecord, TokenInfo } from 'types';

interface StateProps {
	txVisibleSuccess: boolean;
	txVisibleError: boolean;
	txErrorMessage: string;
	transactionType: Transaction_Type;
	signingMap: Map<Transaction_Type, BaseRecord | null>;
	quoteToken: TokenInfo | null;
	quoteBalance: string;
	nativeBalance: string;
	nativeSymbol: string;
	appToken: TokenInfo | null;
	appTokenBalance: string;
	txDescription: string;
	nativeUsdPrice: string | undefined;
	uniWeth: TokenInfo | null;
	uniV3Pools: Array<IV3Pool> | null;
	uniV3PoolMap: Map<string, IV3Pool> | null;
	appTokenUsdPrice: string | undefined;
	totalStakedWithMultiplier: string;
}

const initialState: StateProps = {
	txVisibleSuccess: false,
	txVisibleError: false,
	txErrorMessage: '',
	transactionType: Transaction_Type.Long,
	signingMap: new Map<Transaction_Type, BaseRecord | null>(),
	quoteToken: null,
	quoteBalance: '',
	nativeBalance: '',
	nativeSymbol: '',
	appToken: null,
	appTokenBalance: '',
	txDescription: '',
	nativeUsdPrice: undefined,
	uniWeth: null,
	uniV3Pools: null,
	uniV3PoolMap: null,
	appTokenUsdPrice: undefined,
	totalStakedWithMultiplier: '',
};

export const slice = createSlice({
	name: 'tx',
	initialState,
	reducers: {
		setTxVisibleSuccess(state, { payload }) {
			state.txVisibleSuccess = payload;
		},
		setTxVisibleError(state, { payload }) {
			state.txVisibleError = payload;
		},
		setSigningMap(state, { payload }) {
			state.signingMap = payload;
		},
		updateSigning(state, { payload }: { payload: BaseRecord | null }) {
			produce(state.signingMap, () => {
				if (payload) {
					const current = new Map(state.signingMap);
					current.set(payload.transactionType, payload);
					state.signingMap = current;
				}
			});
		},
		removeSigning(state, { payload }: { payload: BaseRecord | null }) {
			produce(state.signingMap, () => {
				if (payload) {
					const current = new Map(state.signingMap);
					current.delete(payload.transactionType);
					state.signingMap = current;
				}
			});
		},
		setTxErrorMessage(state, { payload }) {
			state.txErrorMessage = payload;
		},
		setQuoteToken(state, { payload }) {
			state.quoteToken = payload;
		},
		setQuoteBalance(state, { payload }) {
			state.quoteBalance = payload;
		},
		setNativeBalance(state, { payload }) {
			state.nativeBalance = payload;
		},
		setNativeSymbol(state, { payload }) {
			state.nativeSymbol = payload;
		},
		setAppToken(state, { payload }) {
			state.appToken = payload;
		},
		setAppTokenBalance(state, { payload }) {
			state.appTokenBalance = payload;
		},
		setTransactionType(state, { payload }) {
			state.transactionType = payload;
		},
		setTxDescription(state, { payload }) {
			state.txDescription = payload;
		},
		setNativeUsdPrice(state, { payload }) {
			state.nativeUsdPrice = payload;
		},
		setUniWeth(state, { payload }) {
			state.uniWeth = payload;
		},
		setUniV3Pools(state, { payload }) {
			state.uniV3Pools = payload;
		},
		setUniV3PoolMap(state, { payload }) {
			state.uniV3PoolMap = payload;
		},
		setAppTokenUsdPrice(state, { payload }) {
			state.appTokenUsdPrice = payload;
		},
		setTotalStakedWithMultiplier(state, { payload }) {
			state.totalStakedWithMultiplier = payload;
		},
		reset(state) {
			state.txErrorMessage = '';
			produce(state.signingMap, () => {
				state.signingMap = new Map();
			});
		}
	}
});

export const {
	setTxVisibleError,
	setTxVisibleSuccess,
	setTxErrorMessage,
	setSigningMap,
	updateSigning,
	removeSigning,
	setQuoteToken,
	setQuoteBalance,
	setNativeBalance,
	setNativeSymbol,
	setTransactionType,
	setTxDescription,
	setAppToken,
	setAppTokenBalance,
	setNativeUsdPrice,
	setUniWeth,
	setUniV3Pools,
	setUniV3PoolMap,
	setAppTokenUsdPrice,
	setTotalStakedWithMultiplier,
	reset
} = slice.actions;

export const txBaseState = (state: RootState) => state.tx;

export default slice.reducer as Reducer<typeof initialState>;
