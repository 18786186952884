import React, { useEffect, useMemo, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import {
	Box,
	InputAdornment,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
	styled,
	useTheme
} from '@mui/material';
import { DEFAULT_SETTING_SLIPPAGE } from 'config/constants';
import Decimal from 'decimal.js';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
	setBalanceType,
	setImpactFeeRate,
	setSlippage,
	setSlippageType,
	settingBaseState
} from 'state/setting/slice';
import { isEqualTo, isPositive } from 'utils';

import Cell from 'components/Common/Cell';
import { CommonStyledOutlineInput } from 'components/Common/Styled';

import Dialog from '../../../components/Common/Dialog';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
	({ theme }) => ({
		'&.MuiToggleButtonGroup-root': {
			width: '250px',
			border: `1px solid ${theme.palette.secondary.main}`,
			borderRadius: '4px'
		},
		'& .MuiToggleButtonGroup-grouped': {
			margin: theme.spacing(0.5),
			border: 0,
			'&.Mui-selected': {
				backgroundColor: theme.palette.divider,
				'&:hover': {
					backgroundColor: theme.palette.divider
				}
			},
			'&:not(:first-of-type)': {
				border: 0,
				marginLeft: 0,
				borderRadius: theme.shape.borderRadius
			},
			'&:first-of-type': {
				border: 0,
				borderRadius: theme.shape.borderRadius
			}
		}
	})
);

const ImpactFeeSettingDialog = ({ onClose }: { onClose: () => void }) => {
	const dispatch = useAppDispatch();
	const theme = useTheme();
	const { slippageType, slippage } = useAppSelector(settingBaseState);
	const [slippageNum, setSlippageNum] = useState<string>(slippage);
	const [errors, setErrors] = useState<any>({});

	const onHandleChangeSlippageAmount = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const value = event.target.value;
		if (isNaN(Number(value)) || !value) {
			setSlippageNum('');
			setErrors({ [event.currentTarget.name]: true });
		} else {
			setSlippageNum(value);
			if (Number(value) > 10 || Number(value) < 0.01) {
				setErrors({ [event.currentTarget.name]: true });
			} else {
				setErrors({ [event.currentTarget.name]: false });
			}
		}
	};

	const onHandleAlignment = (
		event: React.MouseEvent<HTMLElement>,
		newType: string
	) => {
		if (newType !== null) {
			dispatch(setSlippageType(newType));
			if (newType === 'auto') {
				setSlippageNum(DEFAULT_SETTING_SLIPPAGE);
			}
			if (newType === 'custom') {
				setSlippageNum(slippage);
			}
		}
	};

	const onConfirm = () => {
		if (isPositive(slippageNum)) {
			dispatch(setSlippage(slippageNum));
			onClose();
		}
	};

	const disabled = useMemo(() => {
		// if (slippageType === 'auto') {
		//     return true;
		// }
		if (!isPositive(slippageNum) || errors?.custom) {
			return true;
		}
	}, [slippageType, slippageNum, errors]);

	const confirmLabel = useMemo(() => {
		if (slippageType === 'custom' && errors?.custom) {
			if (Number(slippageNum) < 0.01) {
				return t`Min slippage precision is 0.01%`;
			}
			if (Number(slippageNum) > 10) {
				return t`Max slippage precision is 10%`;
			}
			return t`Save`;
		}
		return t`Save`;
	}, [slippageType, errors, slippageNum]);

	return (
		<Dialog
			onConfirm={onConfirm}
			open
			onClose={onClose}
			title={t`Slippage Settings`}
			disabled={disabled}
			confirmLabel={confirmLabel}
		>
			<article className='space-y-4'>
				<Box className='flex flex-col'>
					<Cell
						label={
							<Typography variant='body2'>
								<Trans>Max slippage</Trans>
							</Typography>
						}
						value={`${slippage}%`}
					/>
					<Box className='flex justify-between mt-2'>
						<StyledToggleButtonGroup
							size='small'
							value={slippageType}
							exclusive
							onChange={onHandleAlignment}
							aria-label='text alignment'
							sx={{ background: 'none' }}
						>
							<ToggleButton
								value='auto'
								aria-label='left aligned'
								className='w-[120px] capitalize'
							>
								<Trans>Auto</Trans>
							</ToggleButton>
							<ToggleButton
								value='custom'
								aria-label='right aligned'
								className='w-[120px] capitalize'
							>
								<Trans>Custom</Trans>
							</ToggleButton>
						</StyledToggleButtonGroup>
						{slippageType === 'auto' && (
							<CommonStyledOutlineInput
								readOnly
								type='text'
								inputProps={{ maxLength: 10, inputMode: 'decimal' }}
								className='input px-2'
								defaultValue={DEFAULT_SETTING_SLIPPAGE}
								placeholder={DEFAULT_SETTING_SLIPPAGE}
								endAdornment={
									<InputAdornment
										position='end'
										sx={{
											'.MuiTypography-body1': {
												color: theme.palette.primary.contrastText
											}
										}}
									>
										%
									</InputAdornment>
								}
								sx={{
									'&.MuiOutlinedInput-root': {
										width: '100px',
										border: `1px solid ${theme.palette.secondary.main}`,
										color: theme.palette.secondary.main,
										background: 'none'
									},
									'.MuiOutlinedInput-input': {
										textAlign: 'right',
										background: 'none'
									}
								}}
							/>
						)}

						{slippageType === 'custom' && (
							<CommonStyledOutlineInput
								type='text'
								name='custom'
								autoComplete='off'
								inputProps={{ maxLength: 10, inputMode: 'decimal' }}
								className='input px-2'
								value={slippageNum}
								onChange={onHandleChangeSlippageAmount}
								placeholder={DEFAULT_SETTING_SLIPPAGE}
								endAdornment={
									<InputAdornment
										position='end'
										sx={{
											'.MuiTypography-body1': {
												color: theme.palette.primary.contrastText
											}
										}}
									>
										%
									</InputAdornment>
								}
								error={errors?.custom}
								sx={{
									'&.MuiOutlinedInput-root': {
										width: '100px',
										border: `1px solid ${theme.palette.secondary.main}`,
										'&.Mui-error': {
											border: `1px solid ${theme.palette.error.main}`
										},
										background: 'none'
									},
									'.MuiOutlinedInput-input': {
										textAlign: 'right',
										background: 'none'
									}
								}}
							/>
						)}
					</Box>

					<div className='mt-4 space-y-1'>
						<Typography variant='body2' color='secondary'>
							<Trans>
								If the price change exceeds this percentage, your transaction
								will revert.
							</Trans>
						</Typography>
					</div>
				</Box>
			</article>
		</Dialog>
	);
};

export default ImpactFeeSettingDialog;
