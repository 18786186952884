import Tab, { TabProps } from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

interface StyledTabsProps extends TabProps {
	size?: string;
    path?: string;
}

const StyledTab = styled((props: StyledTabsProps) => (
	<Tab disableRipple {...props} />
))(({ theme, path, size = '' }) => ({
	position: 'relative',
	textTransform: 'none',
	fontWeight: 400,
	minWidth: '60px',
	fontSize: '14px',
	marginRight: theme.spacing(0),
	padding: size === 'large' ? '1px 14px 20px' : '4px 8px',
	'&.Mui-focusVisible': {
		backgroundColor: 'rgba(100, 95, 228, 0.32)'
	},
	'@media screen and (max-width: 768px)': {
		fontSize: path === 'earn' ||  path === 'referrals'? '16px !important' :'14px !important',
		minWidth: 'auto',
		paddingRight: '8px !important',
		marginRight: '0px !important'
	}
}));

export default StyledTab;
