import { useEffect, useMemo, useState } from 'react';

import { DAYS_YEAR, Version } from 'config/constants';
import { useApolloClients } from 'context/ApolloClientsContext';
import {
	useFarmClaimDetailsV2Request,
	useLiquidityPositionsRequest
} from 'fetch/useRequest';
import { useAccount } from 'wagmi';

import { LiquidityPositionUtil } from 'entities/LiquidityPositionUtil';
import { useCheckLogin } from 'hooks/useCurrentChain';
import useIsWindowVisible from 'hooks/useIsWindowVisible';
import { sortBy } from 'lodash';
import { Reward_Type } from 'pages/Earn/types';
import AppTokenPrice from 'pages/components/AppTokenPrice';
import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { selectVersion } from 'state/setting/selector';
import { txBaseState } from 'state/tx/slice';
import { TokenInfo } from 'types';

import {
	catchFn,
	div,
	isGreaterThan,
	isZero,
	multipliedBy,
	plus
} from '../utils';
import { useLiquidityRewardsQuery } from './__generated_referral__/types-and-hooks';

export type ILiquidityMiningPools = {
	id: string;
	liquidityRewardPerSecond?: string;
	liquidityRewardPerDay?: string;
	rbfAvgApr?: string;
	rbfApr?: string;
	liquidityAvgApr?: string;
	liquidityApr?: string;
	maxApr?: string;
	avgApr?: string;
	baseToken?: TokenInfo;
	liquidity?: string;
	claimableIncome?: string;
	claimedIncome?: string;
	totalIncome?: string;
	myLiquidity?: string;
	[key: string]: any;
};

export function useLiquidityRewardsGraph() {
	const { clientReferral } = useApolloClients();
	const { appTimer } = useAppSelector(globalBaseState);
	const { appTokenUsdPrice } = useAppSelector(txBaseState);
	const currentVersion = useAppSelector(selectVersion);
	const { pools: poolList } = useAppSelector(poolsBaseState);
	const isWindowVisible = useIsWindowVisible();
	const { address } = useAccount();
	const isLogin = useCheckLogin();

	const [isLoading, setIsLoading] = useState<boolean>(true);

	const rewardType = useMemo(() => {
		if (currentVersion === Version.V1) {
			return Reward_Type.LIQUIDITY;
		}
		return Reward_Type.V2_LIQUIDITY;
	}, [currentVersion]);

	const {
		data: detailsList,
		isLoading: isLoadingDetails,
		refetch: refetchDetails
	} = useFarmClaimDetailsV2Request(address, rewardType);

	const {
		data: liquidityListV1,
		loading: isLoadingLiquidityListV1,
		refetch: refetchLiquidityListV1
	} = useLiquidityRewardsQuery({
		variables: {
			account: address ? address.toLowerCase() : ''
		},
		skip: !isLogin || currentVersion === Version.V2,
		client: clientReferral,
		notifyOnNetworkStatusChange: true
	});

	const {
		data: liquidityListV2,
		isLoading: isLoadingLiquidityListV2,
		mutate: refetchLiquidityListV2
	} = useLiquidityPositionsRequest({
		account: isLogin ? address : undefined,
		status: 'OPENED',
		enabled: currentVersion === Version.V2
	});

	const liquidityList = useMemo(() => {
		if (currentVersion === Version.V2) {
			return liquidityListV2?.data?.liquidityPositions;
		}
		return liquidityListV1?.liquidityRewards;
	}, [currentVersion, liquidityListV2, liquidityListV1]);

	const isLoadingLiquidityList = useMemo(() => {
		if (currentVersion === Version.V2) {
			return isLoadingLiquidityListV2;
		}
		return isLoadingLiquidityListV1;
	}, [currentVersion, isLoadingLiquidityListV2, isLoadingLiquidityListV1]);

	const refetchLiquidityList = useMemo(() => {
		if (currentVersion === Version.V2) {
			return refetchLiquidityListV2;
		}
		return refetchLiquidityListV1;
	}, [currentVersion, refetchLiquidityListV2, refetchLiquidityListV1]);

	useEffect(() => {
		if (!isLoadingDetails && !isLoadingLiquidityList) {
			setIsLoading(isLoadingDetails);
		}
	}, [isLoadingDetails, isLoadingLiquidityList]);

	const refetch = () => {
		refetchDetails();
		refetchLiquidityList();
	};

	useEffect(() => {
		if (isWindowVisible) {
			refetch();
		}
	}, [appTimer, isWindowVisible]);

	return useMemo(() => {
		const dataList: ILiquidityMiningPools[] = [];
		if (!detailsList || poolList.length === 0) {
			return {
				data: [],
				loading: isLoading,
				refetch
			};
		}
		detailsList?.map(item => {
			const dataItem: ILiquidityMiningPools = {
				id: item.pool,
				claimableIncome: item.claimable_amount,
				liquidityRewardPerDay: item.daily_emission_amount
			};
			const poolId = item.pool.toLowerCase();
			const poolListItem = poolList.find(pli => pli.id === poolId);
			if (poolListItem) {
				dataItem.baseToken = poolListItem.baseToken;
				dataItem.liquidity = poolListItem.globalLiquidityPosition.liquidity;
				if (currentVersion === Version.V1) {
					dataItem.feeMaxApr = catchFn(() => {
						if (isZero(poolListItem.globalLiquidityPosition.liquidity)) {
							return '0';
						}
						return LiquidityPositionUtil.calculateApr(
							poolListItem.realizedProfit24h || '0',
							poolListItem.globalLiquidityPosition.liquidity,
							poolListItem.maxLeveragePerLiquidityPosition ||
								poolListItem.token.maxLeveragePerLiquidityPosition
						);
					}, '0');

					dataItem.feeAvgApr = catchFn(() => {
						if (isZero(poolListItem.globalLiquidityPosition.liquidity)) {
							return '0';
						}
						return LiquidityPositionUtil.calculateApr(
							poolListItem.realizedProfit24h || '0',
							poolListItem.globalLiquidityPosition.liquidity,
							div(
								poolListItem.globalLiquidityPosition.liquidity,
								poolListItem.globalLiquidityPosition.margin
							)
						);
					}, '0');

					if (
						dataItem.liquidity &&
						isGreaterThan(dataItem.liquidity, 0) &&
						AppTokenPrice
					) {
						dataItem.rewardMaxApr = catchFn(() => {
							return multipliedBy(
								div(
									multipliedBy(
										multipliedBy(
											dataItem.liquidityRewardPerDay,
											appTokenUsdPrice
										),
										poolListItem.maxLeveragePerLiquidityPosition ||
											poolListItem.token.maxLeveragePerLiquidityPosition
									),
									dataItem.liquidity
								),
								DAYS_YEAR
							);
						}, '0');
						dataItem.rewardAvgApr = catchFn(() => {
							return multipliedBy(
								div(
									multipliedBy(
										multipliedBy(
											dataItem.liquidityRewardPerDay,
											appTokenUsdPrice
										),
										div(
											poolListItem.globalLiquidityPosition.liquidity,
											poolListItem.globalLiquidityPosition.margin
										)
									),
									dataItem.liquidity
								),
								DAYS_YEAR
							);
						}, '0');
					}
					dataItem.maxApr = plus(dataItem.feeMaxApr, dataItem.rewardMaxApr);
					dataItem.avgApr = plus(dataItem.feeAvgApr, dataItem.rewardAvgApr);
				} else {
					dataItem.maxApr = poolListItem.liquidityMaxApr;
					dataItem.feeMaxApr = poolListItem.feeMaxApr;
					dataItem.fundingFeeMaxApr = poolListItem.fundingFeeMaxApr;
					dataItem.rewardMaxApr = poolListItem.rewardMaxApr;
					dataItem.avgApr = poolListItem.liquidityAvgApr;
					dataItem.feeAvgApr = poolListItem.feeAvgApr;
					dataItem.fundingFeeAvgApr = poolListItem.fundingFeeAvgApr;
					dataItem.rewardAvgApr = poolListItem.rewardAvgApr;
				}
			}
			if (liquidityList && liquidityList.length > 0) {
				const targetLiquidity = liquidityList.find(innerItem => {
					if (currentVersion === Version.V2) {
						return (
							innerItem.market?.toLowerCase() === dataItem.id?.toLowerCase()
						);
					}
					return (
						innerItem.id ===
						address.toLowerCase() + ':' + dataItem.id.toLowerCase()
					);
				});
				if (targetLiquidity) {
					dataItem.myLiquidity = targetLiquidity.liquidity;
				}
			}
			dataList.push(dataItem);
		});

		const sortList = sortBy(dataList, o => {
			return o?.baseToken?.sort;
		});
		return {
			data: sortList,
			loading: false,
			refetch
		};
	}, [
		poolList,
		isLoading,
		detailsList,
		liquidityList,
		appTokenUsdPrice,
		isLogin,
		address,
		currentVersion
	]);
}
