import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Divider, Paper, useTheme } from '@mui/material';
import classNames from 'classnames';
import { Dom_Size, Page, Version } from 'config/constants';
import { TradeContextProvider } from 'context/TradeContext';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin } from 'hooks/useCurrentChain';
import { useCurrentPage } from 'hooks/useCurrentPage';
import PoolInfo from 'pages/components/PoolInfo';
import TradeUpdaterV1 from 'pages/components/TradeUpdaterV1';
import TradeUpdaterV2 from 'pages/components/TradeUpdaterV2';
import { useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';

import TradeForm from '../components/TradeForm';
import MobileActions from './MobileActions';
import OperationV1 from './OperationV1';
import OperationV2 from './OperationV2';
import TradeMain from './TradeMain';

export default function Trade() {
	const isLogin = useCheckLogin();
	const { isMatchMobile, isMatchPad, isMatchPc } = useAppBreakpoints();
	const theme = useTheme();
	const navigate = useNavigate();
	const currentPage = useCurrentPage();
	const currentVersion = useAppSelector(selectVersion);

	useEffect(() => {
		if (
			currentPage !== Page.Index &&
			currentPage !== Page.Trade &&
			currentPage !== Page.IndexV1 &&
			currentPage !== Page.TradeV1 &&
			currentPage !== Page.IndexV2
		) {
			navigate(`${Page.Index}`);
		}
	}, [currentPage]);

	useEffect(() => {
		window?.scrollTo(0, 0);
	}, []);

	let layout: ReactNode | null = null;
	if (isMatchPc) {
		layout = (
			<Box
				className={classNames('flex space-x-2', {
					'flex-1': currentVersion === Version.V2
				})}
			>
				<main className='flex-1 space-y-2'>
					<TradeMain />
					{currentVersion === Version.V1 && <OperationV1 />}
					{currentVersion === Version.V2 && <OperationV2 />}
				</main>
				<Box
					component='aside'
					className='space-y-2'
					sx={{
						width:
							currentVersion === Version.V1
								? `${Dom_Size.TRADE_FORM}px`
								: `20%`,
						minWidth: `${Dom_Size.TRADE_FORM}px`
					}}
				>
					<TradeForm className='flex-1' />
					<PoolInfo className='flex-1' />
				</Box>
			</Box>
		);
	} else if (isMatchPad) {
		layout = (
			<Box className='space-y-2 flex-1'>
				<Paper>
					<TradeMain />
				</Paper>
				<Box component='aside' className='flex space-x-2'>
					<TradeForm className='flex-1' />
					<PoolInfo className='flex-1' />
				</Box>
				{currentVersion === Version.V1 && <OperationV1 />}
				{currentVersion === Version.V2 && <OperationV2 />}
			</Box>
		);
	} else {
		layout = (
			<Box
				className='space-y-2 border-t'
				sx={{
					borderColor: theme.palette.divider,
					paddingBottom: isLogin
						? `${Dom_Size.HEIGHT_LARGE_BUTTON}px`
						: undefined
				}}
			>
				<Paper>
					<TradeMain />
					<div className='overflow-hidden'>
						{isMatchMobile && <Divider />}
						{currentVersion === Version.V1 && <OperationV1 />}
						{currentVersion === Version.V2 && <OperationV2 />}
					</div>
				</Paper>
			</Box>
		);
	}

	return (
		<TradeContextProvider>
			<div
				className={classNames({
					'flex flex-1': currentVersion === Version.V2 && !isMatchMobile
				})}
			>
				{layout}
				{currentVersion === Version.V1 ? (
					<TradeUpdaterV1 />
				) : (
					<TradeUpdaterV2 />
				)}
				{isMatchMobile && <MobileActions />}
			</div>
		</TradeContextProvider>
	);
}
