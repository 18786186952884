import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import { Box, Divider, Paper, Typography, useTheme } from '@mui/material';
import {
	APP_TOKEN_SYMBOL,
	Transaction_Status,
	Transaction_Type
} from 'config/constants';
import { useAccount } from 'wagmi';

import { TransactionRecord } from 'entities/TransactionRecord';
import {
	IMemberItem,
	IRefereeItem,
	IReferralCodeItem
} from 'graphql/useMembersReferralsGraph';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin } from 'hooks/useCurrentChain';
import _, { find, isEmpty } from 'lodash';
import qs from 'qs';
import { useAppSelector } from 'state/hooks';
import { selectTransactionRecordList } from 'state/records/selector';
import { referralBaseState } from 'state/referrals/slice';
import { tradeBaseState } from 'state/trade/slice';
import { txBaseState } from 'state/tx/slice';
import { RecordForCreateReferralCode } from 'types';
import {
	formatNumber,
	isGreaterThan,
	plus,
	shortenReferralsLink,
	shortenSymbol,
	toQuoteAmount
} from 'utils';

import { CommonStyledOutlinedButton } from 'components/Common/Styled';
import Copy from 'components/Copy';
import Module from 'components/Module';
import SectionLoading from 'components/SectionLoading';
import Loading from 'components/Svg/Icons/Loading';
import Tipping from 'components/Tipping';
import ConnectWalletButton from 'components/Wallet/ConnectWalletButton';

import { DirectionCell } from '../../../components/Common/Cell';
import CollapseTable from '../../../components/PairsList/CollapseTable';
import VirtualTable from '../../../components/Table/VirtualTable';
import TippingUnderline from '../../../components/TippingUnderline';
import { useApolloClients } from '../../../context/ApolloClientsContext';
import { useMoreReferralCodesQueryLazyQuery } from '../../../graphql/__generated_referral__/types-and-hooks';
import InvitationDialog from './InvationDialog';
import LiquidityDialog from './LiquidityDialog';
import PositionValueDialog from './PositionValueDialog';
import RegisterCodeDialog from './RegisterCodeDialog';

export const codeCount = 100;
function Guide() {
	const { isMatchMobile } = useAppBreakpoints();
	const isLogin = useCheckLogin();

	return (
		<Module className='px-4 sm:px-3 py-20 sm:pt-10 sm:pb-56 text-center space-y-5 sm:text-left sm:space-y-4'>
			{isLogin ? (
				<section className='space-y-4'>
					<Typography
						variant='h6'
						className='sm:text-sm sm:font-normal text-center'
					>
						<Trans>
							You don&#39;t have a Member NFT yet. You can obtain one through
							the following method:
						</Trans>
					</Typography>
					<div className='flex justify-center space-x-4'>
						{/* <CommonStyledOutlinedButton
							className='flex items-center'
							variant='outlined'
							color='secondary'
							href={import.meta.env.VITE_WEBSITE_APPLY_MEMBER_NFT}
							target={isMatchMobile ? '_self' : '_blank'}
							// sx={{
							// 	color: theme.palette.action.disabled,
							// 	opacity: theme.palette.action.disabledOpacity,
							// 	borderColor: theme.palette.divider,
							// 	"&:hover": {
							// 		borderColor: theme.palette.divider
							// 	}
							// }}
						>
							<Typography
								variant='body2'
								color='inherit'
							>{t`Apply for NFT`}</Typography>
						</CommonStyledOutlinedButton> */}
						<CommonStyledOutlinedButton
							variant='outlined'
							color='secondary'
							href='https://opensea.io/collection/equation-founders-club'
							target={isMatchMobile ? '_self' : '_blank'}
						>
							{t`Purchase NFT`}
						</CommonStyledOutlinedButton>
					</div>
				</section>
			) : (
				<ConnectWalletButton className='sm:w-full' />
			)}
			<section className='space-y-2 sm:space-y-0.5'>
				<Typography variant='body2' color='text.secondary'>
					<Trans>
						By holding Member NFT, you can generate multiple referral codes and
						receive trading fee income and mining rewards.
					</Trans>
				</Typography>
			</section>
		</Module>
	);
}

function convert(txRecord: TransactionRecord) {
	const record = txRecord.record as RecordForCreateReferralCode;
	return {
		id: record.code,
		txHash: txRecord.hash,
		referees: [],
		status: Transaction_Status.Pending,
		blockTimestamp: ''
	} as IRefereeItem;
}

export default function Members() {
	const { isEnableMining } = useAppSelector(tradeBaseState);
	const { quoteToken, appToken } = useAppSelector(txBaseState);
	const { membersLoading, membersAddress, membersList, membersRewardList } =
		useAppSelector(referralBaseState);
	const transactionRecordList = useAppSelector(selectTransactionRecordList);
	const { address } = useAccount();
	const { isMatchMobile, isMatchPad } = useAppBreakpoints();
	const theme = useTheme();
	const [visibleRegisterCode, setVisibleRegisterCode] =
		useState<boolean>(false);
	const [visibleInvitation, setVisibleInvitation] = useState<boolean>(false);
	const [currentTokenId, setCurrentTokenId] = useState<string>('');
	const [referralCode, setReferralCode] = useState<string>('');
	const { search } = useLocation();
	const searchMember = useMemo(() => {
		return search ? qs.parse(search.replace('?', '')) : '';
	}, [search]);

	const [liquidityDetailId, setLiquidityDetailId] = useState('');
	const [addressOfMember, setAddressOfMember] = useState([]);
	const [positionValueDetailId, setPositionValueDetailId] = useState('');
	const setAddressOfMemberProxy = (member: (typeof list)[number]) => {
		const address = member.referralCodes.reduce(
			(prev, cur) => [...prev, ...cur.referees.map(item => item.id)],
			[]
		);
		setPositionValueDetailId(member.id);
		setAddressOfMember(address);
	};

	const isLogin = useCheckLogin();

	const onOpenInvitation = (id: string) => {
		setReferralCode(id);
		setVisibleInvitation(true);
	};

	const onHandleCreateCode = (tokenId: string) => {
		setCurrentTokenId(tokenId);
		setVisibleRegisterCode(true);
	};

	const columns = [
		{
			key: 'referralCode',
			width: '40%',
			label: <Trans>Referral Code</Trans>,
			align: 'left',
			format: (row: IReferralCodeItem) => {
				return (
					<div className='flex items-center space-x-0'>
						<Typography
							sx={{
								maxWidth: '400px',
								textOverflow: 'ellipsis',
								overflow: 'hidden'
							}}
							variant='body2'
						>
							{row.id}{' '}
						</Typography>
						<Copy value={row.id}></Copy>
					</div>
				);
			}
		},
		{
			key: 'totalFeeRewardQuote',
			width: '40%',
			label: <Trans>Referral Link</Trans>,
			align: 'left',
			format: (row: IReferralCodeItem) => {
				return (
					<div className='flex items-center space-x-0'>
						<Typography
							sx={{
								maxWidth: '400px',
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								whiteSpace: 'nowrap'
							}}
							variant='body2'
						>{`${document.location.origin}/referrals/traders?code=${row.id}`}</Typography>
						<Copy
							value={`${document.location.origin}/referrals/traders?code=${row.id}`}
						></Copy>
					</div>
				);
			}
		},
		{
			key: 'count',
			width: '20%',
			label: <Trans>Referees</Trans>,
			align: 'right',
			format: (row: IReferralCodeItem) => {
				if (row.status === Transaction_Status.Pending) {
					return <Loading size={20} />;
				}
				return (
					<Typography
						variant='body2'
						className='cursor-pointer'
						onClick={() => onOpenInvitation(row.id)}
						sx={{
							'&:hover': {
								color: theme.palette.primary.main
							}
						}}
					>
						{row?.referees?.length ?? 0} &gt;
					</Typography>
				);
			}
		}
	];

	const mobileColumns = [
		{
			key: 'referralCode',
			width: '40%',
			label: (
				<Typography
					fontSize={12}
					variant='body1'
					color='text.secondary'
					className='mb-1'
				>
					<Trans>Referral Code</Trans>
				</Typography>
			),
			align: 'left',
			format: (row: any) => {
				return (
					<div className='flex items-center space-x-1'>
						<Typography
							sx={{
								maxWidth: '200px',
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								fontSize: '12px !important'
							}}
							variant='body2'
						>
							{row.id}{' '}
						</Typography>
						<Copy value={row.id}></Copy>
					</div>
				);
			}
		},
		{
			key: 'totalFeeRewardQuote',
			width: '40%',
			label: <Trans>Referral Link</Trans>,
			align: 'left',
			format: (row: IReferralCodeItem) => {
				return (
					<div className='flex items-center space-x-0'>
						<Typography fontSize={12} variant='body2'>
							{shortenReferralsLink(
								`${document.location.origin}/referrals/traders?code=${row.id}`,
								row.id?.length
							)}
						</Typography>
						<Copy
							value={`${document.location.origin}/referrals/traders?code=${row.id}`}
						></Copy>
					</div>
				);
			}
		},
		{
			key: 'count',
			width: '20%',
			label: (
				<Typography
					fontSize={12}
					variant='body1'
					color='text.secondary'
					className='mb-1s'
				>
					<Trans>Referees</Trans>
				</Typography>
			),
			align: 'right',
			format: (row: any) => {
				return (
					<Typography
						variant='body2'
						className='cursor-pointer'
						onClick={() => onOpenInvitation(row.id)}
						sx={{
							fontSize: '12px !important',
							'&:hover': {
								color: theme.palette.primary.main
							}
						}}
					>
						{row?.referees?.length ?? 0} &gt;
					</Typography>
				);
			}
		}
	];

	const miningHeaders: {
		[key: string]: any;
	}[] = [
		{
			key: 'id',
			label: (
				<Typography variant='body1' color='text.secondary'>
					Token ID
				</Typography>
			),
			align: 'left',
			width: '20%',
			cellRender: (member: IMemberItem) => {
				return (
					<Typography
						variant={isMatchMobile || isMatchPad ? 'subtitle2' : 'h6'}
					>{t`Member #${member.id}`}</Typography>
				);
			}
		},
		{
			key: 'value',
			label: (
				<div className='flex items-center'>
					<TippingUnderline
						tooltip={
							<Trans>
								The total value of holdings by all referees under a Member NFT.
								The higher the value of holdings, the greater the EQU rewards
								earned.
							</Trans>
						}
						label={<Trans>Total Position Value</Trans>}
					/>
				</div>
			),
			align: 'left',
			width: '20%',
			cellRender: (member: IMemberItem) => {
				const memberReward = find(membersRewardList, { id: member.id });
				return (
					<Typography
						component='span'
						variant='body2'
						className='sm:text-sm cursor-pointer'
						sx={{
							'&:hover': {
								color: theme.palette.primary.main
							}
						}}
						onClick={() => {
							setAddressOfMemberProxy(member);
						}}
					>
						{`${toQuoteAmount(memberReward?.position || 0)}`} &gt;
					</Typography>
				);
			}
		},
		{
			key: 'totalRewards',
			width: '25%',
			label: (
				<div className='flex'>
					<TippingUnderline
						tooltip={
							<Trans>
								All referral rewards generated during the ownership of this
								Member NFT at the current address (including unclaimed rewards).
							</Trans>
						}
						label={<Trans>Total Rewards</Trans>}
					/>
				</div>
			),
			align: 'left',
			cellRender: (member: IMemberItem) => {
				const memberReward = find(membersRewardList, { id: member.id });
				return (
					<div className='flex justify-start space-x-2'>
						<Typography variant='body2' fontWeight={400}>
							{`${formatNumber(
								memberReward?.totalFeeReward || 0
							)} ${shortenSymbol(quoteToken)}`}
						</Typography>
						{isEnableMining && (
							<Typography variant='body2' fontWeight={400}>{`${formatNumber(
								plus(
									plus(
										plus(
											memberReward?.liquidityClaimed || 0,
											memberReward?.positionClaimed || 0
										),
										plus(
											memberReward?.liquidityUnclaimed || 0,
											memberReward?.positionUnclaimed || 0
										)
									),
									memberReward?.newRewardsTotalEQU
								),
								appToken.positionUnits || 0
							)} ${APP_TOKEN_SYMBOL}`}</Typography>
						)}
					</div>
				);
			}
		},
		{
			key: 'income',
			label: (
				<Typography variant='body1' color='text.secondary' className='-ml-4'>
					<Trans>Claimable Rewards</Trans>
				</Typography>
			),
			align: 'left',
			width: '25%',
			cellRender: (member: IMemberItem) => {
				const memberReward = find(membersRewardList, { id: member.id });
				return (
					<Box className='flex -ml-4'>
						<Typography variant='body2' className='sm:text-sm'>{`${formatNumber(
							memberReward?.unclaimed || 0
						)} ${shortenSymbol(quoteToken)}`}</Typography>
						{isEnableMining && (
							<Typography
								variant='body2'
								className='ml-3 sm:text-sm'
							>{`${formatNumber(
								memberReward?.newRewardsEQU || 0,
								appToken.positionUnits
							)} ${APP_TOKEN_SYMBOL}`}</Typography>
						)}
					</Box>
				);
			}
		},
		{
			key: 'operation',
			label: '',
			align: 'right',
			width: '10%',
			cellRender: (member: IMemberItem) => {
				return (
					<CommonStyledOutlinedButton
						variant='outlined'
						color='secondary'
						onClick={() => onHandleCreateCode(member.id)}
					>
						<Trans>Create</Trans>
					</CommonStyledOutlinedButton>
				);
			}
		}
	];

	const headers: {
		[key: string]: any;
	}[] = [
		{
			key: 'id',
			label: (
				<Typography variant='body1' color='text.secondary'>
					Token ID
				</Typography>
			),
			align: 'left',
			width: '40%',
			cellRender: (member: IMemberItem) => {
				return <Typography variant='h6'>{t`Member #${member.id}`}</Typography>;
			}
		},
		{
			key: 'income',
			label: (
				<Typography variant='body1' color='text.secondary' className='-ml-4'>
					<Trans>Claimable Rewards</Trans>
				</Typography>
			),
			align: 'left',
			width: '40%',
			cellRender: (member: IMemberItem) => {
				const memberReward = find(membersRewardList, { id: member.id });
				return (
					<Box className='flex -ml-4'>
						<Typography variant='body2' className='sm:text-sm'>{`${formatNumber(
							memberReward?.unclaimed || 0
						)} ${shortenSymbol(quoteToken)}`}</Typography>
						{isEnableMining && (
							<Typography
								variant='body2'
								className='ml-3 sm:text-sm'
							>{`${formatNumber(
								memberReward?.newRewardsEQU || 0
							)} ${APP_TOKEN_SYMBOL}`}</Typography>
						)}
					</Box>
				);
			}
		},
		{
			key: 'operation',
			label: '',
			align: 'right',
			width: '20%',
			cellRender: (member: IMemberItem) => {
				return (
					<CommonStyledOutlinedButton
						variant='outlined'
						color='secondary'
						onClick={() => onHandleCreateCode(member.id)}
					>
						<Trans>Create referral code</Trans>
					</CommonStyledOutlinedButton>
				);
			}
		}
	];

	const createReferralCoderRecords = useMemo(() => {
		return transactionRecordList.filter(tx => {
			return tx.type === Transaction_Type.CreateReferralCode;
		});
	}, [transactionRecordList]);

	const list = useMemo(() => {
		if (!createReferralCoderRecords || !createReferralCoderRecords.length) {
			return membersList;
		}
		if (!membersList) {
			return null;
		}
		const membersListCache = _.cloneDeep(membersList);

		membersListCache.forEach((item: IMemberItem) => {
			const _filteredRecords = createReferralCoderRecords.filter(txRecord => {
				return txRecord.record.tokenId === item.id;
			});
			let convertedList = [];
			_filteredRecords.forEach(item => {
				convertedList = convertedList.concat(convert(item));
			});
			item.referralCodes = convertedList.concat(item.referralCodes);
		});
		return membersListCache;
	}, [membersList, createReferralCoderRecords]);

	const { clientReferral } = useApolloClients();
	const [fetch] = useMoreReferralCodesQueryLazyQuery({
		client: clientReferral
	});

	const [focusedRowIndex, setFocusedRowIndex] = useState(0);
	const onClickRow = (_, rowIndex: number) => {
		setFocusedRowIndex(rowIndex);
		setReferralCodes(list[rowIndex].referralCodes);
		setHasMore(true);
	};

	useEffect(() => {
		if (isEmpty(searchMember) || isEmpty(list)) {
			return;
		}
		const memberIndex = list?.findIndex(
			item => item.id === searchMember.member
		);
		if (memberIndex > 0) {
			setFocusedRowIndex(memberIndex);
		}
	}, [searchMember, list]);

	const [referralCodes, setReferralCodes] = useState<
		(typeof list)[number]['referralCodes']
	>([]);

	// 组件初始化，写数据
	useEffect(() => {
		if (referralCodes.length === 0 && list && membersAddress === address) {
			setReferralCodes(list[focusedRowIndex].referralCodes);
			setHasMore(list[focusedRowIndex].referralCodes.length === codeCount);
		}
	}, [focusedRowIndex, list, membersAddress, address]);

	useEffect(() => {
		if (list && list[focusedRowIndex] && membersAddress === address) {
			const newCodes = list[focusedRowIndex].referralCodes.filter(
				code => !referralCodes.find(item => item.id === code.id)
			);
			const result = [...newCodes, ...referralCodes].map(item => ({
				...item,
				status: createReferralCoderRecords.find(
					record => record.record.code === item.id
				)
					? Transaction_Status.Pending
					: void 0
			}));
			setReferralCodes(result);
		}
	}, [list, createReferralCoderRecords, membersAddress, address]);

	const [hasMore, setHasMore] = useState(false);
	const loadMore = () => {
		if (hasMore) {
			fetch({
				variables: {
					id: list[focusedRowIndex].id,
					lastTimestamp: referralCodes[referralCodes.length - 1].blockTimestamp,
					first: codeCount
				}
			}).then(res => {
				setHasMore(res.data.referrer.referralCodes.length === codeCount);
				setReferralCodes([
					...referralCodes,
					...res.data.referrer.referralCodes
				]);
			});
		}
	};

	const contentRender = (member: IMemberItem, index: number) => (
		<div>
			{index === focusedRowIndex && (
				<div className='py-3'>
					{referralCodes && referralCodes.length ? (
						<VirtualTable
							columns={columns}
							rows={referralCodes}
							itemHeight={44}
							cellColor={theme.custom.paperBg}
							loadMore={loadMore}
						/>
					) : (
						<Typography
							component='div'
							className='flex py-6 justify-center items-center'
							color='text.secondary'
						>
							<Trans>{`You don't have a referral code yet.`}</Trans>
						</Typography>
					)}
				</div>
			)}
		</div>
	);

	return (
		<Paper className='px-3 sm:px-0 pt-3 sm:pt-0'>
			{isLogin ? (
				membersLoading ? (
					<div className='py-12 sm:py-24'>
						<SectionLoading />
					</div>
				) : (
					<>
						{list && list.length !== 0 && (
							<>
								{isMatchMobile && (
									<Box
										sx={{ backgroundColor: theme.palette.background.default }}
									>
										{list.map((member, index) => {
											const memberReward = find(membersRewardList, {
												id: member.id
											});
											return (
												<Box
													key={member.id}
													sx={{ backgroundColor: theme.custom.paperBg }}
													className='px-3 py-4 mb-2 last:mb-0'
													onClick={() => onClickRow(member, index)}
												>
													<div className='flex flex-1 mb-2 justify-between'>
														<Typography variant='h6'>{t`Member #${member.id}`}</Typography>
														<CommonStyledOutlinedButton
															variant='outlined'
															color='secondary'
															onClick={() => onHandleCreateCode(member.id)}
														>
															<Trans>Create</Trans>
														</CommonStyledOutlinedButton>
													</div>
													<DirectionCell
														className='flex items-center justify-between mt-2 sm:space-y-0'
														label={<Trans>Total Position Value</Trans>}
														value={
															<Typography
																component='span'
																variant='body2'
																className='text-xs'
																sx={{
																	'&:hover': {
																		color: theme.palette.primary.main
																	}
																}}
																onClick={() => {
																	setAddressOfMemberProxy(member);
																}}
															>
																{`${toQuoteAmount(
																	memberReward?.position || 0
																)}`}{' '}
																&gt;
															</Typography>
														}
													/>
													<DirectionCell
														className='flex items-center justify-between sm:space-y-0 mt-2'
														label={<Trans>Total Rewards</Trans>}
														value={
															<Box className='flex mt-0'>
																<Typography
																	variant='body2'
																	className='text-sm sm:text-xs'
																>
																	{`${formatNumber(
																		memberReward?.totalFeeReward || 0
																	)} ${shortenSymbol(quoteToken)}`}
																</Typography>
																<Typography
																	variant='body2'
																	className='ml-3 text-sm sm:text-xs'
																>
																	{`${formatNumber(
																		plus(
																			plus(
																				plus(
																					memberReward?.liquidityClaimed || 0,
																					memberReward?.positionClaimed || 0
																				),
																				plus(
																					memberReward?.liquidityUnclaimed || 0,
																					memberReward?.positionUnclaimed || 0
																				)
																			),
																			memberReward?.newRewardsTotalEQU
																		),
																		appToken.positionUnits || 0
																	)} ${APP_TOKEN_SYMBOL}`}
																</Typography>
															</Box>
														}
													/>
													<DirectionCell
														className='flex items-center justify-between sm:space-y-0 sm:mt-2'
														label={<Trans>Claimable Rewards</Trans>}
														value={
															<Box className='flex mt-0'>
																<Typography
																	variant='body2'
																	className='text-sm sm:text-xs'
																>{`${formatNumber(
																	memberReward?.unclaimed || 0
																)} ${shortenSymbol(quoteToken)}`}</Typography>
																<Typography
																	variant='body2'
																	className='ml-3 text-sm sm:text-xs'
																>{`${formatNumber(
																	memberReward?.newRewardsEQU || 0,
																	appToken.positionUnits
																)} ${APP_TOKEN_SYMBOL}`}</Typography>
															</Box>
														}
													/>
													{index === focusedRowIndex && (
														<>
															<Divider
																sx={{ borderBottomStyle: 'dashed' }}
																className='my-4'
															/>
															<VirtualTable
																columns={mobileColumns}
																rows={referralCodes}
																maxHeight={155}
																itemHeight={26}
																cellColor={theme.custom.paperBg}
																loadMore={loadMore}
																noDataMsg={
																	<Trans>{`You don't have a referral code yet.`}</Trans>
																}
															/>
														</>
													)}
												</Box>
											);
										})}
									</Box>
								)}
								{!isMatchMobile && (
									<div className='-m-3'>
										<CollapseTable
											headers={isEnableMining ? miningHeaders : headers}
											rows={list}
											isLoading={false}
											focusedRowIndex={focusedRowIndex}
											contentRender={contentRender}
											onClickRow={onClickRow}
										/>
									</div>
								)}
							</>
						)}
						{(!list || list.length === 0) && <Guide />}
					</>
				)
			) : (
				<div>
					<Guide />
				</div>
			)}

			{visibleRegisterCode && (
				<RegisterCodeDialog
					tokenId={currentTokenId}
					open={true}
					onClose={() => {
						setVisibleRegisterCode(false);
					}}
				/>
			)}
			{visibleInvitation && (
				<InvitationDialog
					referralCode={referralCode}
					tokenId={currentTokenId}
					open={true}
					onClose={() => {
						setVisibleInvitation(false);
					}}
				/>
			)}
			{liquidityDetailId !== '' && (
				<LiquidityDialog
					onClose={() => setLiquidityDetailId('')}
					data={(
						membersRewardList.find(item => item.id === liquidityDetailId)
							?.rewards || []
					).filter(item => isGreaterThan(item.liquidity, 0))}
				/>
			)}
			{positionValueDetailId !== '' && (
				<PositionValueDialog
					onClose={() => {
						setAddressOfMember([]);
						setPositionValueDetailId('');
					}}
					accounts={addressOfMember}
				/>
			)}
		</Paper>
	);
}
