import { useEffect, useMemo, useState } from 'react';

import { useAccount } from 'wagmi';

import { useAllTokens } from 'hooks/useAllTokens';
import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { txBaseState } from 'state/tx/slice';
import { TokenInfo } from 'types';
import { div, plus } from 'utils';

import { Side } from '../config/constants';
import {
	MyPositionHistoryQuery,
	useMyPositionHistoryLazyQuery,
	useMyPositionHistoryQuery
} from './__generated_trade__/types-and-hooks';

export enum Position_History_Status_V1 {
	Closed = 'Closed',
	PartiallyClosed = 'PartialClosed',
	Liquidated = 'Liquidated'
}

export enum Position_History_Status_V2 {
	Closed = 'CLOSED',
	PartiallyClosed = 'PARTIAL_CLOSED',
	Liquidated = 'LIQUIDATED'
}

export interface IPositionHistoryItem {
	baseToken: TokenInfo;
	closedLiquidity: string;
	closedSize: string;
	closedTime: string;
	closedPrice?: string;
	entryPrice: string;
	entryTime: string;
	entryTxHash: string;
	leverage?: string;
	id: string;
	liquidationPrice: string;
	liquidationSize: string;
	liquidationFee: string;
	liquidatorClosed: boolean;
	side: Side;
	margin: string;
	// 当前仓位总的已实现盈亏：减仓已实现盈亏 + 总资金费
	realizedPnLTotal: string;
	realizedPnL: string;
	realizedPnLRate: string;
	fundingFee: string;
	size: string;
	status: Position_History_Status_V1 | Position_History_Status_V2 | string;
}

export function useMyPostionHistoryLazyGraph() {
	const [fetch] = useMyPositionHistoryLazyQuery();
	return { fetch };
}

export function formatPostionHistoryV1(
	data: {positionHistories: MyPositionHistoryQuery['positionHistories']}
) {
	const { quoteToken } = useAppSelector(txBaseState);
	const { pools } = useAppSelector(poolsBaseState);
	const allTokens = useAllTokens();
	if (!data || !data.positionHistories || !pools || !quoteToken) {
		return [];
	}
	return data?.positionHistories?.map(item => {
		const {
			closedLiquidity,
			closedSize,
			closedTime,
			entryPrice,
			entryTime,
			entryTxHash,
			id,
			liquidationPrice,
			liquidationSize,
			liquidationFee,
			liquidatorClosed,
			side,
			margin,
			realizedPnL,
			realizedPnLRate,
			fundingFee,
			size,
			status
		} = item;

		const { id: tokenId } = item.pool.token;

		const baseToken = allTokens.get(tokenId);

		const closedPrice =
			status === Position_History_Status_V1.Liquidated
				? liquidationPrice
				: div(closedLiquidity, closedSize);

		const leverage = div(closedLiquidity, margin);

		const realizedPnLTotal = plus(
			plus(realizedPnL, fundingFee),
			liquidationFee
		);

		return {
			baseToken,
			closedLiquidity,
			closedSize,
			closedPrice,
			closedTime,
			entryPrice,
			entryTime,
			entryTxHash,
			leverage,
			id,
			liquidationPrice,
			liquidationSize,
			liquidationFee,
			liquidatorClosed,
			side,
			margin,
			realizedPnLTotal,
			realizedPnL,
			realizedPnLRate,
			fundingFee,
			size,
			status
		} as IPositionHistoryItem;
	});
}

export function formatPostionHistoryV2(data: {
	positionHistories: MyPositionHistoryQuery['positionHistories'];
}) {
	const { quoteToken } = useAppSelector(txBaseState);
	const { pools } = useAppSelector(poolsBaseState);
	
	if (!data || !data.positionHistories || !pools || !quoteToken) {
		return [];
	}
	return data?.positionHistories?.map(item => {
		const {
			closedLiquidity,
			closedSize,
			closedTime,
			entryPrice,
			entryTime,
			entryTxHash,
			id,
			liquidationPrice,
			liquidationSize,
			liquidationFee,
			liquidatorClosed,
			side,
			margin,
			realizedPnl,
			realizedPnlRate,
			fundingFee,
			size,
			status,
			marketAddress = ''
		} = item;

		const filteredPool = pools.filter(pool => {
			return pool.id.toLowerCase() === marketAddress?.toLowerCase();
		});
		
		const baseToken = filteredPool.length > 0 ? filteredPool[0].baseToken : {};
		const closedPrice =
			status === Position_History_Status_V2.Liquidated
				? liquidationPrice
				: div(closedLiquidity, closedSize);

		const leverage = div(closedLiquidity, margin);

		const realizedPnlTotal = plus(
			plus(realizedPnl, fundingFee),
			liquidationFee
		);

		return {
			baseToken,
			closedLiquidity,
			closedSize,
			closedPrice,
			closedTime,
			entryPrice,
			entryTime,
			entryTxHash,
			leverage,
			id,
			liquidationPrice,
			liquidationSize,
			liquidationFee,
			liquidatorClosed,
			side,
			margin,
			realizedPnlTotal,
			realizedPnl,
			realizedPnlRate,
			fundingFee,
			size,
			status
		} as IPositionHistoryItem;
	});
}

export function useMyPostionHistoryGraph(poolId: string, skip: number) {
	const [loading, setLoading] = useState<boolean>(true);
	const { address } = useAccount();
	const { quoteToken } = useAppSelector(txBaseState);

	const {
		data,
		loading: isLoading,
		refetch
	} = useMyPositionHistoryQuery({
		variables: { account: address, poolId, skip },
		skip: !address
	});

	const allTokens = useAllTokens();
	const { pools } = useAppSelector(poolsBaseState);

	const list = useMemo(() => {
		if (!data || !data.positionHistories || !pools || !quoteToken) {
			return [];
		}

		return data.positionHistories.map(item => {
			const {
				closedLiquidity,
				closedSize,
				closedTime,
				entryPrice,
				entryTime,
				entryTxHash,
				id,
				liquidationPrice,
				liquidationSize,
				liquidatorClosed,
				side,
				margin,
				realizedPnL,
				realizedPnLRate,
				size,
				status
			} = item;

			const { id: tokenId } = item.pool.token;

			const baseToken = allTokens.get(tokenId);

			const closedPrice =
				status === Position_History_Status_V1.Liquidated
					? liquidationPrice
					: div(closedLiquidity, closedSize);

			const leverage = div(closedLiquidity, margin);

			return {
				baseToken,
				closedLiquidity,
				closedSize,
				closedPrice,
				closedTime,
				entryPrice,
				entryTime,
				entryTxHash,
				leverage,
				id,
				liquidationPrice,
				liquidationSize,
				liquidatorClosed,
				side,
				margin,
				realizedPnL,
				realizedPnLRate,
				size,
				status
			} as unknown as IPositionHistoryItem;
		});
	}, [data, pools, quoteToken]);

	useEffect(() => {
		if (!isLoading) {
			setLoading(isLoading);
		}
	}, [isLoading]);

	return {
		data: list,
		loading,
		refetch
	};
}
