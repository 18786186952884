import { useEffect, useMemo, useState } from 'react';

import {
	DAYS_YEAR,
	DEFAULT_QUOTE_PRECISION,
	Transaction_Status
} from 'config/constants';
import { useApolloClients } from 'context/ApolloClientsContext';
import Decimal from 'decimal.js';
import { useAccount } from 'wagmi';

import { LiquidityPositionUtil } from 'entities/LiquidityPositionUtil';
import { useAllTokens } from 'hooks/useAllTokens';
import { sortBy } from 'lodash';
import { Reward_Type } from 'pages/Earn/types';
import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { TokenInfo } from 'types';

import {
	useFarmClaimDetailsV2Request,
	useMultiTokens
} from '../fetch/useRequest';
import {
	catchFn,
	div,
	isGreaterThan,
	minus,
	multipliedBy,
	parseUnits,
	plus
} from '../utils';
import { useRiskBufferFundRewardsQuery } from './__generated_referral__/types-and-hooks';
import usePoolsGlobal from './usePoolsGlobal';

export type IRBFMiningPool = {
	riskBufferFundRewardPerSecond?: string;
	riskBufferFundRewardPerDay?: string;
	apr?: string;
	id: string;

	baseToken?: TokenInfo;
	unrealizedPnL?: string;
	realizedPnL?: string;
	riskBufferFund?: string;
	totalContribution?: string;
	claimableIncome?: string;
	claimedIncome?: string;
	totalIncome?: string;
	riskBufferFundReward?:
		| {
				unclaimed: string;
				claimed: string;
				liquidity: string;
				rewardGrowthX64: string;
				id: string;
		  }
		| any;
	status?: Transaction_Status;
};

export function useRiskBufferFundRewardsGraph() {
	const { clientReferral } = useApolloClients();
	const { appTimer } = useAppSelector(globalBaseState);
	const { appTokenUsdPrice } = useAppSelector(txBaseState);
	const { address } = useAccount();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const allTokens = useAllTokens();
	const {
		data: rbfList,
		loading,
		refetch: RBFRefetch
	} = useRiskBufferFundRewardsQuery({
		variables: { account: address ? address.toLowerCase() : '' },
		skip: !address,
		client: clientReferral
	});
	const {
		data: detailsList,
		isLoading: isLoadingDetails,
		refetch: refetchDetails
	} = useFarmClaimDetailsV2Request(address, Reward_Type.RBF);
	const {
		data: poolList,
		refetch: refetchPools,
		loading: poolsLoading
	} = usePoolsGlobal();
	const { tokensMultiPrice = { tokens: [] } } = useMultiTokens(
		poolList?.map(pool => pool.token.id)
	);

	useEffect(() => {
		if (!loading && !poolsLoading && !isLoadingDetails) {
			setIsLoading(loading);
		}
	}, [loading, poolsLoading, isLoadingDetails]);
	const refetch = () => {
		refetchDetails();
		refetchPools();
		RBFRefetch();
	};
	useEffect(() => {
		refetch();
	}, [appTimer]);

	return useMemo(() => {
		if ((address && !rbfList) || !poolList) {
			return {
				data: undefined,
				loading: true,
				refetch
			};
		}
		const dataList: IRBFMiningPool[] = [];
		if (detailsList && detailsList?.length > 0) {
			detailsList?.map(item => {
				const dataItem: IRBFMiningPool = {
					id: item.pool,
					claimableIncome: item.claimable_amount,
					riskBufferFundRewardPerDay: item.daily_emission_amount
				};
				const poolListItem = poolList.find(
					pli => pli.id === dataItem.id.toLowerCase()
				);
				if (poolListItem) {
					dataItem.baseToken = allTokens.get(poolListItem.token.id);
					// const { tokensMultiPrice = { tokens: [] } } = useMultiTokens([
					// 	poolListItem.token.id as string
					// ]);
					const indexPriceX96 =
						tokensMultiPrice.tokens.find(
							(token: any) =>
								token.address.toLowerCase() === poolListItem.token.id
						)?.index_price_x96 || 0;
					const _globalUnrealizedPnl = catchFn(() => {
						return LiquidityPositionUtil.calculateGlobalUnrealizedPnl(
							poolListItem.globalLiquidityPosition.side,
							parseUnits(
								plus(
									poolListItem.globalLiquidityPosition.netSize,
									poolListItem.globalLiquidityPosition.liquidationBufferNetSize
								),
								dataItem.baseToken.decimals
							),
							poolListItem.globalLiquidityPosition.entryPriceX96,
							indexPriceX96
						);
					}, '');

					dataItem.unrealizedPnL = div(
						_globalUnrealizedPnl,
						Decimal.pow(10, DEFAULT_QUOTE_PRECISION)
					);
					dataItem.realizedPnL = minus(
						poolListItem.globalRiskBufferFund.riskBufferFund,
						poolListItem.globalRiskBufferFund.liquidity
					);
					dataItem.riskBufferFund = plus(
						poolListItem.globalRiskBufferFund.riskBufferFund,
						dataItem.unrealizedPnL
					);
					dataItem.totalContribution =
						poolListItem.globalRiskBufferFund.liquidity;
					dataItem.apr = '0';
					if (
						dataItem.totalContribution &&
						isGreaterThan(dataItem.totalContribution, 0) &&
						appTokenUsdPrice
					) {
						dataItem.apr = catchFn(() => {
							return multipliedBy(
								div(
									multipliedBy(
										dataItem.riskBufferFundRewardPerDay,
										appTokenUsdPrice
									),
									dataItem.totalContribution
								),
								DAYS_YEAR
							);
						}, '0');
					}
				}
				dataItem.riskBufferFundReward = {};
				if (address) {
					const rbfListItem = rbfList.riskBufferFundRewards.find(rli => {
						return (
							rli.id === address.toLowerCase() + ':' + dataItem.id.toLowerCase()
						);
					});
					if (rbfListItem) {
						dataItem.riskBufferFundReward = rbfListItem;
					}
				}

				dataList.push(dataItem);
			});
		} else {
			rbfList?.riskBufferFundRewards?.map(rbfListItem => {
				const dataItem: IRBFMiningPool = {
					id: rbfListItem.pool.id,
					// claimableIncome: item.claimable_amount,
					riskBufferFundRewardPerDay: rbfListItem.pool.rewardPerSecond
				};
				const poolListItem = poolList.find(
					pli => pli.id === dataItem.id.toLowerCase()
				);
				if (poolListItem) {
					dataItem.baseToken = allTokens.get(poolListItem.token.id);
					// const { tokensMultiPrice = { tokens: [] } } = useMultiTokens([
					// 	poolListItem.token.id as string
					// ]);
					const indexPriceX96 =
						tokensMultiPrice.tokens.find(
							(token: any) =>
								token.address.toLowerCase() === poolListItem.token.id
						)?.index_price_x96 || 0;
					const _globalUnrealizedPnl = catchFn(() => {
						return LiquidityPositionUtil.calculateGlobalUnrealizedPnl(
							poolListItem.globalLiquidityPosition.side,
							parseUnits(
								plus(
									poolListItem.globalLiquidityPosition.netSize,
									poolListItem.globalLiquidityPosition.liquidationBufferNetSize
								),
								dataItem.baseToken.decimals
							),
							poolListItem.globalLiquidityPosition.entryPriceX96,
							indexPriceX96
						);
					}, '');

					dataItem.unrealizedPnL = div(
						_globalUnrealizedPnl,
						Decimal.pow(10, DEFAULT_QUOTE_PRECISION)
					);
					dataItem.realizedPnL = minus(
						poolListItem.globalRiskBufferFund.riskBufferFund,
						poolListItem.globalRiskBufferFund.liquidity
					);
					dataItem.riskBufferFund = plus(
						poolListItem.globalRiskBufferFund.riskBufferFund,
						dataItem.unrealizedPnL
					);
					dataItem.totalContribution =
						poolListItem.globalRiskBufferFund.liquidity;
					dataItem.apr = '0';
					if (
						dataItem.totalContribution &&
						isGreaterThan(dataItem.totalContribution, 0) &&
						appTokenUsdPrice
					) {
						dataItem.apr = catchFn(() => {
							return multipliedBy(
								div(
									multipliedBy(
										dataItem.riskBufferFundRewardPerDay,
										appTokenUsdPrice
									),
									dataItem.totalContribution
								),
								DAYS_YEAR
							);
						}, '0');
					}
				}
				dataItem.riskBufferFundReward = {};
				if (rbfListItem) {
					dataItem.riskBufferFundReward = rbfListItem;
				}
				dataList.push(dataItem);
			});
		}

		const sortList = sortBy(dataList, o => {
			return o?.baseToken?.sort;
		});

		return {
			data: sortList,
			loading: false,
			refetch
		};
	}, [
		detailsList,
		poolList,
		rbfList,
		tokensMultiPrice,
		isLoading,
		appTokenUsdPrice
	]);
}
