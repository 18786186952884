import { createSelector } from '@reduxjs/toolkit';

import { orderBy } from 'lodash';
import { isGreaterThan, plus } from 'utils';

import { earnPositionBaseState } from './slice';

export const selectClaimablePositionList = createSelector(
	[earnPositionBaseState],
	state => {
		const { positionList } = state;
		if (!positionList || positionList.length === 0) {
			return null;
		}
		return orderBy(
			positionList.filter(item => isGreaterThan(item.claimableAmount, 0)),
			function (o) {
				return parseFloat(o.claimableAmount);
			},
			['desc']
		);
	}
);

export const selectClaimablePositionAmount = createSelector(
	[selectClaimablePositionList],
	claimablePositionList => {
		if (!claimablePositionList || claimablePositionList.length === 0)
			return '0';
		return claimablePositionList.reduce(
			(pre, cur) => plus(pre, cur.claimableAmount),
			'0'
		);
	}
);

export const selectClaimablePositionAmountV2 = createSelector(
	[earnPositionBaseState],
	state => {
		const { positionList } = state;
		if (!positionList || positionList.length === 0) {
			return '0';
		}
		return positionList.reduce(
			(pre, cur) => plus(pre, cur.claimableAmount),
			'0'
		);
	}
);
