import { arbitrum, arbitrumGoerli } from 'config/constants/connector/chains';

import { Env } from './constants';

interface ApiConfig {
	V1: string;
	V2: string;
}

const BACKEND_HOST_DEV: Record<number, ApiConfig> = {
	[arbitrumGoerli.id]: {
		V1: 'https://api.metacontract.pro/v1',
		V2: 'https://api-goldfish-sepolia.metacontract.pro/v1'
	},
	[arbitrum.id]: {
		V1: 'https://api-arbitrum.equation.trade/v1',
		V2: 'https://api-arbitrum.equation.trade/v1'
	}
};

const BACKEND_HOST_PROD: Record<number, ApiConfig> = {
	[arbitrumGoerli.id]: {
		V1: 'https://api-arbitrum-goerli.equation.org/v1',
		V2: 'https://api-arbitrum-goerli.equation.org/v1'
	},
	[arbitrum.id]: {
		V1: 'https://api-arbitrum.equation.trade/v1',
		V2: 'https://api-v2-arbitrum.equation.trade/v1'
	}
};

const BACKEND_HOST = {
	[Env.development]: BACKEND_HOST_DEV,
	[Env.production]: BACKEND_HOST_PROD
};

export const BACKEND_HOST_URL: Record<number, ApiConfig> =
	BACKEND_HOST[import.meta.env.MODE as Env];
