import React, { useCallback, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans, t } from '@lingui/macro';
import { Box, FormControlLabel, Typography, useTheme } from '@mui/material';
import {
	AMOUNT_MAKERS,
	MAX_PRECISION,
	Profit_Loss_Type,
	QUOTE_USD,
	Side,
	TakeProfitStopLoss_Type,
	Version
} from 'config/constants';

import { PositionUtil } from 'entities/PositionUtil';
import { TakeProfitStopLossUtil } from 'entities/TakeProfitStopLossUtil';
import { IPositionItem } from 'graphql/useMyPositionsGraph';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { useSubmitCreateProfitLoss } from 'hooks/useSubmitCreateProfitLoss';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { ITakeProfitStopLoss } from 'types';
import {
	catchFn,
	checkInputNumberic,
	div,
	isGreaterThan,
	isGreaterThanOrEqual,
	isLessThan,
	isNegative,
	isNumeric,
	isPositive,
	minus,
	multipliedBy,
	shortenSymbolNative,
	toDecimalPlaces,
	toPercent,
	toQuoteAmount
} from 'utils';

import ApproveButton from 'components/ApproveButton';
import ApproveButtonV2 from 'components/ApproveButtonV2';
import { StyleCheckbox } from 'components/Common/StyleCheckbox';
import {
	CommonStyledAlertWarning,
	CommonStyledBaseInput
} from 'components/Common/Styled';
import MarksSlider from 'components/MarksSlider';
import TextWarning from 'components/TextWarning';

import warningIcon from '../../../assets/svg/icon-warning.svg';
import ProfitLossCells from './ProfitLossCells';

interface IProfitLossTypeData {
	[Profit_Loss_Type.Entire]: ITakeProfitStopLoss;
	[Profit_Loss_Type.Partial]: ITakeProfitStopLoss;
}
const defaultData: ITakeProfitStopLoss = {
	triggerPrice: '',
	quoteChange: '',
	profitLoss: '',
	rateOfReturn: '',
	orderSize: '',
	orderMargin: '',
	isDisplay: true
};
const initProfitLossTypeData: IProfitLossTypeData = {
	[Profit_Loss_Type.Entire]: defaultData,
	[Profit_Loss_Type.Partial]: defaultData
};
const ProfitLossForm = ({
	item,
	type,
	onClose
}: {
	item: IPositionItem;
	type: Profit_Loss_Type;
	onClose: () => void;
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { quoteToken, signingMap } = useAppSelector(txBaseState);
	const [takeProfitData, setTakeProfitData] = useState<IProfitLossTypeData>(
		initProfitLossTypeData
	);
	const [stopLossData, setStopLossData] = useState<IProfitLossTypeData>(
		initProfitLossTypeData
	);
	// const [entireStopLoss, setEntireStopLoss] =
	// 	useState<ITakeProfitStopLoss>(initData);
	// const [partialTakeProfit, setPartialTakeProfit] =
	// 	useState<ITakeProfitStopLoss>(initData);
	// const [partialStopLoss, setPartialStopLoss] =
	// 	useState<ITakeProfitStopLoss>(initData);

	const [orderSize, setOrderSize] = useState<string>('');
	const [sizeSlider, setSizeSlider] = useState<number>(0);
	const takeProfitSymbol = item.side === Side.LONG ? '≥' : '≤';
	const stopLossSymbol = item.side === Side.LONG ? '≤' : '≥';
	const theme = useTheme();
	const { currentChainId } = useCurrentChain();
	const currentVersion = useAppSelector(state => state.setting.currentVersion);

	const onHandleChangeTakeProfit = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setTakeProfitData({
			...takeProfitData,
			[Profit_Loss_Type[type]]: {
				...takeProfitData[Profit_Loss_Type[type]],
				isDisplay: event.target.checked
			}
		});
	};
	const onHandleChangeStopLoss = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setStopLossData({
			...stopLossData,
			[Profit_Loss_Type[type]]: {
				...stopLossData[Profit_Loss_Type[type]],
				isDisplay: event.target.checked
			}
		});
	};
	const onHandleChangePrice = useCallback(
		(event: any, tpslType: TakeProfitStopLoss_Type) => {
			const value = event.target.value;
			if (value.trim() === '') {
				if (tpslType === TakeProfitStopLoss_Type.TakeProfit) {
					setTakeProfitData({
						...takeProfitData,
						[Profit_Loss_Type[type]]: defaultData
					});
				} else {
					setStopLossData({
						...stopLossData,
						[Profit_Loss_Type[type]]: defaultData
					});
				}
				return;
			}

			if (checkInputNumberic(value, item.baseToken?.precision, true)) {
				const newData = catchFn(
					() => {
						if (!isPositive(value)) {
							return {
								triggerPrice: value,
								quoteChange: ''
							};
						}
						const quoteChange = TakeProfitStopLossUtil.calculateQuoteChange(
							item.entryPrice,
							value,
							item.side,
							tpslType
						);

						return {
							triggerPrice: value,
							quoteChange: toDecimalPlaces(multipliedBy(100, quoteChange), 2)
						};
					},
					{ triggerPrice: '0', quoteChange: '0' }
				);

				if (tpslType === TakeProfitStopLoss_Type.TakeProfit) {
					setTakeProfitData({
						...takeProfitData,
						[Profit_Loss_Type[type]]: {
							...takeProfitData[Profit_Loss_Type[type]],
							...newData
						}
					});
				} else {
					setStopLossData({
						...stopLossData,
						[Profit_Loss_Type[type]]: {
							...stopLossData[Profit_Loss_Type[type]],
							...newData
						}
					});
				}
			}
		},
		[item, quoteToken, type, takeProfitData, stopLossData, orderSize]
	);

	const onHandleChangeQuoteChange = useCallback(
		(event: any, tpslType: TakeProfitStopLoss_Type) => {
			const value = event.target.value;
			if (value.trim() === '') {
				if (tpslType === TakeProfitStopLoss_Type.TakeProfit) {
					setTakeProfitData({
						...takeProfitData,
						[Profit_Loss_Type[type]]: defaultData
					});
				} else {
					setStopLossData({
						...stopLossData,
						[Profit_Loss_Type[type]]: defaultData
					});
				}
				return;
			}
			if (checkInputNumberic(value, 2, true)) {
				const newData = catchFn(
					() => {
						if (!isNumeric(value)) {
							return {
								triggerPrice: '',
								quoteChange: value
							};
						}
						const triggerPrice = TakeProfitStopLossUtil.calculateTriggerPrice(
							item.entryPrice,
							div(value, 100),
							item.side,
							tpslType
						);

						return {
							triggerPrice: toDecimalPlaces(
								triggerPrice,
								item.baseToken?.precision
							),
							quoteChange: value
						};
					},
					{ triggerPrice: '0', quoteChange: '0' }
				);

				if (tpslType === TakeProfitStopLoss_Type.TakeProfit) {
					setTakeProfitData({
						...takeProfitData,
						[Profit_Loss_Type[type]]: {
							...takeProfitData[Profit_Loss_Type[type]],
							...newData
						}
					});
				} else {
					setStopLossData({
						...stopLossData,
						[Profit_Loss_Type[type]]: {
							...stopLossData[Profit_Loss_Type[type]],
							...newData
						}
					});
				}
			}
		},
		[item, quoteToken, type, takeProfitData, stopLossData, orderSize]
	);
	const onHandleChangesizeSlider = useCallback((newValue: number) => {
		const size = multipliedBy(div(newValue, 100), item.size);
		setOrderSize(size);
		setSizeSlider(newValue);
	}, []);
	const onClickLabel = (value: number) => {
		const size = multipliedBy(div(value, 100), item.size);
		setOrderSize(size);
		setSizeSlider(value);
	};
	const onHandleChangeSize = useCallback((event: any) => {
		const value = event.target.value;
		if (checkInputNumberic(value, item.baseToken.decimals)) {
			let number = 0;
			if (isPositive(value)) {
				number = parseFloat(multipliedBy(div(value, item.size), 100));
			}
			setOrderSize(value);
			setSizeSlider(number);
		}
	}, []);
	const calculateUnrealizedPnL = (
		item: IPositionItem,
		type: TakeProfitStopLoss_Type,
		price: string,
		size: string
	) => {
		const _type = type === TakeProfitStopLoss_Type.TakeProfit ? true : false;
		if (!isPositive(price) || !isPositive(size)) {
			return '0';
		}
		return PositionUtil.calculateUnrealizedPnL(
			item.side === Side.LONG
				? _type
					? Side.LONG
					: Side.SHORT
				: _type
				? Side.SHORT
				: Side.LONG,
			size,
			item.entryPrice,
			price
		);
	};
	const [takeProfit, stopLoss] = useMemo(() => {
		const _takeProfitData = { ...takeProfitData[Profit_Loss_Type[type]] };
		const _stopLossData = { ...stopLossData[Profit_Loss_Type[type]] };
		const size = type === Profit_Loss_Type.Entire ? item.size : orderSize;
		let tpProfitLoss = '0';
		if (
			isNumeric(_takeProfitData.quoteChange) &&
			isPositive(_takeProfitData.triggerPrice) &&
			isPositive(size)
		) {
			tpProfitLoss = TakeProfitStopLossUtil.calculateProfitLoss(
				item.entryPrice,
				_takeProfitData.triggerPrice,
				size,
				item.side
			);
		}
		const tpRateOfReturn = TakeProfitStopLossUtil.calculateRateOfReturn(
			tpProfitLoss,
			item.netMargin
		);
		let slProfitLoss = '0';
		if (
			isNumeric(_stopLossData.quoteChange) &&
			isPositive(_stopLossData.triggerPrice) &&
			isPositive(size)
		) {
			slProfitLoss = TakeProfitStopLossUtil.calculateProfitLoss(
				item.entryPrice,
				_stopLossData.triggerPrice,
				size,
				item.side
			);
		}
		const slRateOfReturn = TakeProfitStopLossUtil.calculateRateOfReturn(
			slProfitLoss,
			item.netMargin
		);
		let _takeProfitMargin = '';
		let _stopLossMargin = '';
		if (_takeProfitData.isDisplay) {
			if (type === Profit_Loss_Type.Entire) {
				_takeProfitMargin = '0';
			} else {
				if (isPositive(sizeSlider)) {
					_takeProfitMargin = multipliedBy(
						div(multipliedBy(item.netMargin, sizeSlider), 100),
						1 - 0.1
					);
				}
			}
		}
		if (_stopLossData.isDisplay) {
			if (type === Profit_Loss_Type.Entire) {
				_stopLossMargin = '0';
			} else {
				if (isPositive(sizeSlider)) {
					const unrealizedPnL = calculateUnrealizedPnL(
						item,
						TakeProfitStopLoss_Type.StopLoss,
						_stopLossData.triggerPrice,
						size
					);
					_stopLossMargin = multipliedBy(
						div(
							multipliedBy(minus(item.netMargin, unrealizedPnL), sizeSlider),
							100
						),
						1 - 0.1
					);
				}
			}
		}

		return [
			{
				...takeProfitData[Profit_Loss_Type[type]],
				profitLoss: tpProfitLoss,
				rateOfReturn: tpRateOfReturn,
				orderSize: size,
				orderMargin: _takeProfitMargin
			},
			{
				...stopLossData[Profit_Loss_Type[type]],
				profitLoss: slProfitLoss,
				rateOfReturn: slRateOfReturn,
				orderSize: size,
				orderMargin: _stopLossMargin
			}
		];
	}, [item, type, takeProfitData, stopLossData, orderSize]);

	const { onConfirm, isConfirming, isConfirmed, error } =
		useSubmitCreateProfitLoss(
			type,
			item.poolId,
			item.side,
			takeProfit.isDisplay ? takeProfit.triggerPrice : '',
			stopLoss.isDisplay ? stopLoss.triggerPrice : '',
			takeProfit.isDisplay ? takeProfit.orderSize : '',
			stopLoss.isDisplay ? stopLoss.orderSize : '',
			takeProfit.isDisplay ? takeProfit.orderMargin : '',
			stopLoss.isDisplay ? stopLoss.orderMargin : '',
			item.baseToken
		);

	const disabled = useMemo(() => {
		if (isLoading) {
			return true;
		}

		if (isConfirming || isLoading) {
			return true;
		}
		if (!takeProfit.isDisplay && !stopLoss.isDisplay) {
			return true;
		}
		if (takeProfit.isDisplay) {
			if (
				!isPositive(takeProfit.triggerPrice) ||
				!isPositive(takeProfit.quoteChange) ||
				!isPositive(takeProfit.orderSize)
			) {
				return true;
			}
			if (isGreaterThan(takeProfit.orderSize, item.size)) {
				return true;
			}
		}
		if (stopLoss.isDisplay) {
			if (
				!isPositive(stopLoss.triggerPrice) ||
				!isPositive(stopLoss.quoteChange) ||
				!isPositive(stopLoss.orderSize)
			) {
				return true;
			}
			if (isGreaterThan(stopLoss.orderSize, item.size)) {
				return true;
			}
			if (isLessThan(stopLoss.rateOfReturn, -1)) {
				return true;
			}
		}
		return false;
	}, [takeProfit, stopLoss, isLoading]);

	const submitText = useMemo(() => {
		if (isLoading) {
			return <Trans>Loading...</Trans>;
		}
		if (isConfirming) {
			return <Trans>Submitting...</Trans>;
		}
		if (takeProfit.isDisplay) {
			if (
				item.side === Side.SHORT &&
				isGreaterThanOrEqual(takeProfit.quoteChange, 100)
			) {
				return <Trans>The price decrease cannot exceed 100%</Trans>;
			}
			if (isGreaterThan(takeProfit.orderSize, item.size)) {
				return <Trans>Insufficient Balance</Trans>;
			}
			if (
				takeProfit.quoteChange !== '' &&
				isLessThan(takeProfit.quoteChange, '0')
			) {
				return <Trans>The TP PnL cannot be less than 0.</Trans>;
			}
		}
		if (stopLoss.isDisplay) {
			if (
				item.side === Side.LONG &&
				isGreaterThanOrEqual(stopLoss.quoteChange, 100)
			) {
				return <Trans>The price decrease cannot exceed 100%</Trans>;
			}
			if (isGreaterThan(stopLoss.orderSize, item.size)) {
				return <Trans>Insufficient Balance</Trans>;
			}
			if (
				stopLoss.quoteChange !== '' &&
				isLessThan(stopLoss.quoteChange, '0')
			) {
				return <Trans>The SL PnL cannot be more than 0.</Trans>;
			}
			if (isLessThan(stopLoss.rateOfReturn, -1)) {
				return <Trans>The SL PnL% cannot be less than -100%.</Trans>;
			}
		}
		return <Trans>Confirm</Trans>;
	}, [
		signingMap,
		isConfirming,
		isConfirmed,
		takeProfit,
		stopLoss,
		isLoading,
		item.side,
		item.size
	]);

	useUpdateEffect(() => {
		if (isConfirmed) {
			onClose();
		}
	}, [isConfirmed]);
	useUpdateEffect(() => {
		if (error) {
			setIsLoading(false);
		}
	}, [error]);
	const onDialogConfirm = () => {
		setIsLoading(true);
		onConfirm();
	};

	// const valueLabelFormat = (value: number) => {
	// 	return `${parseInt(value.toString())}%`;
	// };
	return (
		<article>
			{/* 止盈输入框 */}
			<Box>
				<Box className='flex items-center justify-between'>
					<FormControlLabel
						control={
							<StyleCheckbox
								onChange={onHandleChangeTakeProfit}
								size='small'
								checked={takeProfit.isDisplay}
							/>
						}
						label={
							<Typography
								variant='body2'
								className='-ml-1.5'
								fontWeight={500}
								color={theme.palette.text.primary}
								sx={{ minWidth: '100px' }}
							>
								<Trans>Take profit {takeProfitSymbol}</Trans>
							</Typography>
						}
					/>
					{takeProfit.isDisplay && (
						<Typography
							variant='body2'
							color={theme.palette.text.secondary}
							textAlign='right'
						>
							<Typography
								variant='body2'
								sx={{ display: 'inline-block' }}
								className='mr-2'
							>
								<Trans>Exp. PnL</Trans>
							</Typography>
							<Box className='inline-block space-x-1'>
								<Typography
									className='inline-block'
									variant='body2'
									color={
										isPositive(takeProfit.profitLoss)
											? theme.palette.success.main
											: ''
									}
								>
									{isPositive(takeProfit.profitLoss)
										? `+${toQuoteAmount(takeProfit.profitLoss)}`
										: '-'}
								</Typography>
								<Typography
									className='inline-block'
									variant='body2'
									color={
										isPositive(takeProfit.profitLoss)
											? theme.palette.success.main
											: ''
									}
								>
									(
									{isPositive(takeProfit.rateOfReturn)
										? `+${toPercent(takeProfit.rateOfReturn)}`
										: '-'}
									)
								</Typography>
							</Box>
						</Typography>
					)}
				</Box>
				{takeProfit.isDisplay && (
					<Box>
						<Box className='flex flex-row space-x-2'>
							<CommonStyledBaseInput
								type='text'
								inputProps={{
									maxLength: MAX_PRECISION,
									inputMode: 'decimal'
								}}
								className='px-3 basis-8/12'
								value={takeProfit.triggerPrice}
								placeholder={t`TP trigger price`}
								onChange={event =>
									onHandleChangePrice(event, TakeProfitStopLoss_Type.TakeProfit)
								}
								fullWidth
								endAdornment={
									<Typography variant='body1' color='secondary'>
										{QUOTE_USD}
									</Typography>
								}
							/>
							<CommonStyledBaseInput
								type='text'
								inputProps={{
									maxLength: MAX_PRECISION,
									inputMode: 'decimal'
								}}
								className='px-3 basis-4/12'
								value={takeProfit.quoteChange}
								placeholder={
									item.side === Side.LONG ? t`Increase` : t`Decrease`
								}
								onChange={event =>
									onHandleChangeQuoteChange(
										event,
										TakeProfitStopLoss_Type.TakeProfit
									)
								}
								fullWidth
								endAdornment={
									<Typography variant='body1' color='secondary'>
										%
									</Typography>
								}
							/>
						</Box>
						<Typography
							className='inline'
							variant='body2'
							color={theme.custom.orangeColor}
						>
							<img
								className='mr-0.5 mb-0.5 inline-block'
								width={16}
								src={warningIcon}
								alt=''
							/>
							<Trans>
								During rapid market fluctuations, such as upswings followed by
								quick downturns, Take Profit orders may initially be triggered
								but then fail to meet the execution conditions due to the
								fast-changing prices. In such scenarios, these orders will not
								be executed and will remain active, awaiting the next
								opportunity to trigger.
							</Trans>
						</Typography>
					</Box>
				)}
			</Box>
			{/* 止损输入框 */}
			<Box className='mt-5'>
				<Box className='flex items-center justify-between'>
					<FormControlLabel
						control={
							<StyleCheckbox
								onChange={onHandleChangeStopLoss}
								size='small'
								checked={stopLoss.isDisplay}
							/>
						}
						label={
							<Typography
								variant='body2'
								className='-ml-1.5'
								fontWeight={500}
								color={theme.palette.text.primary}
								sx={{ minWidth: '100px' }}
							>
								<Trans>Stop loss {stopLossSymbol}</Trans>
							</Typography>
						}
					/>
					{stopLoss.isDisplay && (
						<Typography
							variant='body2'
							color={theme.palette.text.secondary}
							textAlign='right'
						>
							<Typography
								variant='body2'
								sx={{ display: 'inline-block' }}
								className='mr-2'
							>
								<Trans>Exp. PnL</Trans>
							</Typography>
							<Box className='inline-block space-x-1'>
								<Typography
									className='inline-block'
									variant='body2'
									color={
										isNegative(stopLoss.profitLoss)
											? theme.palette.error.main
											: ''
									}
								>
									{isNegative(stopLoss.profitLoss)
										? toQuoteAmount(stopLoss.profitLoss)
										: '-'}
								</Typography>
								<Typography
									className='inline-block'
									variant='body2'
									color={
										isNegative(stopLoss.profitLoss)
											? theme.palette.error.main
											: ''
									}
								>
									(
									{isNegative(stopLoss.rateOfReturn)
										? toPercent(stopLoss.rateOfReturn)
										: '-'}
									)
								</Typography>
							</Box>
						</Typography>
					)}
				</Box>

				{stopLoss.isDisplay && (
					<>
						<Box className='flex flex-row space-x-2'>
							<CommonStyledBaseInput
								type='text'
								inputProps={{
									maxLength: MAX_PRECISION,
									inputMode: 'decimal'
								}}
								className='px-3 basis-8/12'
								value={stopLoss.triggerPrice}
								placeholder={t`SL trigger price`}
								onChange={event =>
									onHandleChangePrice(event, TakeProfitStopLoss_Type.StopLoss)
								}
								fullWidth
								endAdornment={
									<Typography variant='body1' color='secondary'>
										{QUOTE_USD}
									</Typography>
								}
							/>
							<CommonStyledBaseInput
								type='text'
								inputProps={{
									maxLength: MAX_PRECISION,
									inputMode: 'decimal'
								}}
								className='px-3 basis-4/12'
								value={stopLoss.quoteChange}
								placeholder={
									item.side === Side.LONG ? t`Decrease` : t`Increase`
								}
								onChange={() =>
									onHandleChangeQuoteChange(
										event,
										TakeProfitStopLoss_Type.StopLoss
									)
								}
								fullWidth
								endAdornment={
									<Typography variant='body1' color='secondary'>
										%
									</Typography>
								}
							/>
						</Box>
						<div className='mt-2.5'>
							<Typography
								className='inline'
								variant='body2'
								color={theme.custom.orangeColor}
							>
								<img
									className='mr-0.5 mb-0.5 inline-block'
									width={16}
									src={warningIcon}
									alt=''
								/>
								<Trans>
									Due to the different triggering mechanisms for Stop Loss and
									liquidation (Stop Loss is triggered based on market price,
									while liquidation is triggered based on index price), if the
									Stop Loss price is set too close to the liquidation price,
									there is a risk that liquidation will occur before the Stop
									Loss is triggered or executed.
								</Trans>
							</Typography>
						</div>
					</>
				)}
			</Box>
			{type === Profit_Loss_Type.Partial && (
				<Box className='mt-5'>
					<CommonStyledBaseInput
						type='text'
						inputProps={{
							maxLength: MAX_PRECISION,
							inputMode: 'decimal'
						}}
						className='px-3'
						value={orderSize}
						placeholder={t`Size`}
						onChange={onHandleChangeSize}
						fullWidth
						endAdornment={
							<Typography variant='body1' color='secondary'>
								{shortenSymbolNative(item.baseToken, currentChainId)}
							</Typography>
						}
					/>
					<MarksSlider
						leverage={sizeSlider}
						marks={AMOUNT_MAKERS}
						min={0}
						max={100}
						type='light'
						onChangeLeverage={onHandleChangesizeSlider}
						onClickLabel={onClickLabel}
					/>
				</Box>
			)}
			<Box className='mt-5'>
				<ProfitLossCells
					item={item}
					size={takeProfit.orderSize}
					quantity={takeProfit.isDisplay && stopLoss.isDisplay ? 2 : 1}
				/>
			</Box>
			{type === Profit_Loss_Type.Partial && (
				<Box className='mt-4'>
					<CommonStyledAlertWarning>
						<TextWarning>
							<Trans>
								Margin settlement has a 10% slippage to prevent order failure
								due to insufficient margin.
							</Trans>
						</TextWarning>
					</CommonStyledAlertWarning>
				</Box>
			)}
			<Box className='mt-4'>
				{currentVersion === Version.V1 ? (
					<ApproveButton
						componentVariant='confirm'
						fullWidth
						onClick={onDialogConfirm}
						disabled={disabled}
						isApproveOrderBook
					>
						{submitText}
					</ApproveButton>
				) : (
					<ApproveButtonV2
						componentVariant='confirm'
						fullWidth
						onClick={onDialogConfirm}
						disabled={disabled}
						isApproveOrderBook
					>
						{submitText}
					</ApproveButtonV2>
				)}
			</Box>
		</article>
	);
};

export default ProfitLossForm;
