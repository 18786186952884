import { useMemo, useState } from 'react';

import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { usePoolFundingRate } from 'fetch/useRequest';
import moment from 'moment';

import { useFundingRateHistoriesByPool } from 'graphql/useFundingRateHistories';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { isEmpty } from 'lodash';

import StyledChip from 'components/Common/StyledChip';
import LineChart from 'components/chart/line';

import { Dom_Size } from '../../config/constants';
import {
	isPositive,
	isZero,
	multipliedBy,
	toDecimalPlaces,
	toPercent
} from '../../utils';

export interface PriceChartProps {
	id: string;
	currentFee: string | number;
}

export function FeeChartV2(props: PriceChartProps) {
	const { id, currentFee = 0 } = props;
	const params = useMemo(() => {
		return id ? { market: id, from: moment().unix(), limit: 168 } : null;
	}, [id]);
	const { data, isLoading: loading } = usePoolFundingRate(params);
	const [currentActiveRate, setCurrentActiveRate] = useState(tabs[0]);
	const [showCurrentFee, setCurrentFeeShow] = useState(true);
	const currentActiveTab = useMemo(() => {
		return tabs.find(item => item.value === currentActiveRate.value);
	}, [currentActiveRate]);
	const refactorData = useMemo(() => {
		return data?.fundingRateHistories
			?.map(item => {
				return {
					...item,
					originY: Number(
						toDecimalPlaces(
							multipliedBy(
								item.fundingRate,
								multipliedBy(currentActiveTab.value, 100)
							),
							6
						)
					),
					x: item.ts * 1000,
					y: multipliedBy(
						item.fundingRate,
						multipliedBy(currentActiveTab.value, 100)
					)
				};
			})
			.reverse();
	}, [data, currentActiveTab]);

	const onCurrentActiveRate = (rate: any) => {
		setCurrentActiveRate(rate);
	};

	const currentRate = useMemo(() => {
		return multipliedBy(currentFee, currentActiveRate.value);
	}, [currentActiveRate, currentFee]);

	const { isMatchMobile } = useAppBreakpoints();
	const { i18n } = useLingui();

	return (
		<Box
			sx={{
				height: Dom_Size.CHART
			}}
			className='w-full relative flex'
		>
			<LineChart
				data={refactorData || []}
				loading={loading}
				onMouseLeave={() => {
					setCurrentFeeShow(true);
				}}
				onMouseMove={(state: any) => {
					if (!isEmpty(state.activePayload)) {
						setCurrentFeeShow(false);
					} else {
						setCurrentFeeShow(true);
					}
				}}
			/>
			<Box className='absolute right-0 -top-1 z-[10] w-full space-x-2 flex items-center justify-end'>
				{showCurrentFee ? (
					<div className='absolute w-full top-0 flex items-center sm:items-start flex-col justify-center'>
						<Typography
							className='sm:ml-20'
							color='text.secondary'
							variant='body1'
						>
							<Trans>Current {currentActiveTab.label}</Trans>
						</Typography>
						{isPositive(currentRate) ? (
							<Typography
								className='sm:ml-20'
								variant='body1'
								color='warning.main'
							>
								{`+${toPercent(currentRate, 6)}`}
							</Typography>
						) : isZero(currentRate) ? (
							<Typography className='sm:ml-20' variant='body1'>
								{toPercent(currentRate, 6)}
							</Typography>
						) : (
							<Typography
								className='sm:ml-20'
								variant='body1'
								color='warning.main'
							>
								{toPercent(currentRate, 6)}
							</Typography>
						)}
					</div>
				) : null}
				<div className='flex z-[999]'>
					{tabs.map(tab => (
						<StyledChip
							label={tab.label}
							key={tab.value}
							onClick={() => onCurrentActiveRate(tab)}
							className={cn(
								currentActiveRate.value === tab.value ? 'active' : ''
							)}
							sx={{
								borderRadius: '4px',
								'.MuiChip-label': {
									padding: isMatchMobile ? '2px 8px' : '4px 24px'
								}
							}}
						/>
					))}
				</div>
			</Box>
		</Box>
	);
}

export function FeeChartV1(props: PriceChartProps) {
	const { id, currentFee = 0 } = props;
	const { data, loading } = useFundingRateHistoriesByPool(id, 168);
	const [currentActiveRate, setCurrentActiveRate] = useState(tabs[0]);
	const [showCurrentFee, setCurrentFeeShow] = useState(true);
	const currentActiveTab = useMemo(() => {
		return tabs.find(item => item.value === currentActiveRate.value);
	}, [currentActiveRate]);
	const refactorData = useMemo(() => {
		return data
			?.map(item => ({
				...item,
				originY: toDecimalPlaces(
					multipliedBy(
						item.fundingRate,
						multipliedBy(currentActiveTab.value, 100)
					),
					6
				),
				y: Number(multipliedBy(item.y, currentActiveTab.value))
			}))
			.reverse();
	}, [data, currentActiveTab]);

	const onCurrentActiveRate = (rate: any) => {
		setCurrentActiveRate(rate);
	};

	const currentRate = useMemo(() => {
		return multipliedBy(currentFee, currentActiveRate.value);
	}, [currentActiveRate, currentFee]);

	const { isMatchMobile } = useAppBreakpoints();
	const { i18n } = useLingui();

	return (
		<div className='h-full w-full relative flex'>
			<LineChart
				data={refactorData || []}
				loading={loading}
				onMouseLeave={() => {
					setCurrentFeeShow(true);
				}}
				onMouseMove={(state: any) => {
					if (!isEmpty(state.activePayload)) {
						setCurrentFeeShow(false);
					} else {
						setCurrentFeeShow(true);
					}
				}}
				height={Dom_Size.CHART}
			/>
			<Box className='absolute right-0 -top-1 z-[10] w-full space-x-2 flex items-center justify-end'>
				{showCurrentFee ? (
					<div className='absolute w-full top-0 flex items-center sm:items-start flex-col justify-center'>
						<Typography
							className='sm:ml-20'
							color='text.secondary'
							variant='body1'
						>
							<Trans>Current {currentActiveTab.label}</Trans>
						</Typography>
						{isPositive(currentRate) ? (
							<Typography
								className='sm:ml-20'
								variant='body1'
								color='warning.main'
							>
								{`+${toPercent(currentRate, 6)}`}
							</Typography>
						) : isZero(currentRate) ? (
							<Typography className='sm:ml-20' variant='body1'>
								{toPercent(currentRate, 6)}
							</Typography>
						) : (
							<Typography
								className='sm:ml-20'
								variant='body1'
								color='warning.main'
							>
								{toPercent(currentRate, 6)}
							</Typography>
						)}
					</div>
				) : null}
				<div className='flex z-[999]'>
					{tabs.map(tab => (
						<StyledChip
							label={tab.label}
							key={tab.value}
							onClick={() => onCurrentActiveRate(tab)}
							className={cn(
								currentActiveRate.value === tab.value ? 'active' : ''
							)}
							sx={{
								borderRadius: '4px',
								'.MuiChip-label': {
									padding: isMatchMobile ? '2px 8px' : '4px 24px'
								}
							}}
						/>
					))}
				</div>
			</Box>
		</div>
	);
}

const tabs = [
	{
		label: <Trans>1h Rate</Trans>,
		value: 1
	},
	{
		label: <Trans>8h Rate</Trans>,
		value: 8
	},
	{
		label: <Trans>APR</Trans>,
		value: 8760
	}
];
