import { useCallback, useEffect, useMemo, useState } from 'react';

import {
	MAX_PAGE_SIZE,
	Order_Open_Type_V2,
	Order_Status_V2,
	Side,
	Transaction_Status,
	Transaction_Type
} from 'config/constants';
import { useOrdersRequest } from 'fetch/useRequest';
import { useAccount } from 'wagmi';

import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { selectOrderOpenType, selectOrderStatus } from 'state/setting/selector';
import { IRequestItem, OrderId, TokenInfo } from 'types';
import {
	getTransactionTypeForLimitV2,
	getTransactionTypeForProfitLossV2,
	multipliedBy
} from 'utils';

export interface IOrderItem {
	id: string;
	orderV2Id: string;
	// pool id
	poolId: string;
	type: Order_Open_Type_V2;
	/*
		针对Limit订单
		LongLimitOrder = 'LongLimitOrder',
		ShortLimitOrder = 'ShortLimitOrder',
		针对止盈止损订单
		CreateTakeProfitPosition = 'CreateTakeProfitPosition',
		CreateTakeProfitPartial = 'CreateTakeProfitPartial',
		CreateStopLossPosition = 'CreateTakeProfitPosition',
		CreateStopLossPartial = 'CreateTakeProfitPartial',
	*/
	transactionType: Transaction_Type;
	// current tx hash
	txHash: string;
	// created tx hash
	createdHash: string;
	updatedHash: string;
	// executed tx hash
	executedHash: string;
	// cancelled tx hash
	cancelledHash: string;
	// pool Position index
	index: string;
	// type
	side: Side;
	// user
	account: string;
	// orders add
	triggerAbove: boolean;
	// orders by $
	liquidityDelta: string;
	// pay
	marginDelta: string;
	// trigger price
	triggerMarketPrice: string;
	// acceptable price
	acceptableTradePrice: string;
	// mark Price
	indexPrice: string;
	baseToken: TokenInfo;
	createdTimestamp: string | number;
	cancelledTimestamp: string | number;
	executedTimestamp: string | number;
	updatedTimestamp: string | number;
	lastOperationTimestamp: string | number;
	// Created  Executed Cancelled
	status?: Transaction_Status;
	orderStatus?: Order_Status_V2;
	positionAmount?: string;
	sizeDelta?: string;
	expired?: boolean;
}

export function useMyOrdersFetch(poolId?: string, hashes?: Array<string>) {
	const [from, setFrom] = useState(0);
	const [data, setData] = useState([]);
	const [requestsByHashes, setRequestsByHashes] = useState<
		Array<IRequestItem> | undefined
	>(undefined);
	const [loading, setLoading] = useState(true);
	const [fetching, setFetching] = useState(false);
	const [orderList, setOrderList] = useState<Array<IOrderItem> | null>(null);
	const [ordersMap, setOrdersMap] = useState<Map<
		string,
		Array<IOrderItem>
	> | null>(null);
	const { address } = useAccount();
	const { pools } = useAppSelector(poolsBaseState);
	const Order_Status = useAppSelector(selectOrderStatus);
	const Order_Open_Type = useAppSelector(selectOrderOpenType);

	const ordersParams = useMemo(() => {
		return {
			account: address,
			market: poolId,
			status: 'OPENED',
			from: undefined,
			limit: MAX_PAGE_SIZE,
			hashes
		};
	}, [address, poolId, hashes]);

	const { trigger } = useOrdersRequest(ordersParams);

	const fetchAction = useCallback(
		(index: number) => {
			if (!fetching && address) {
				setFetching(true);
				return trigger()
					.then(res => {
						setData(index === 0 ? res.data.orders : [...res.data.orders]);
						setFrom(
							res.data.orders.length === 0
								? undefined
								: res.data.hasMore
								? res.data.next
								: from
						);
						setRequestsByHashes(res.data.requestsByHashes);
					})
					.finally(() => setFetching(false));
			} else {
				return Promise.resolve();
			}
		},
		[address, data, fetching, ordersParams]
	);

	useEffect(() => {
		if (!data) {
			return;
		}

		const orderListArray = [] as Array<IOrderItem>;
		const ordersMap = new Map<string, Array<IOrderItem>>();
		data.forEach(item => {
			const filteredPool = pools.filter(pool => {
				return pool.id.toLowerCase() === item.market.toLowerCase();
			});
			const baseToken =
				filteredPool.length > 0 ? filteredPool[0]?.baseToken : {};
			if (poolId && item.market.toLowerCase() !== poolId.toLowerCase()) {
				return;
			}
			const transactionType =
				item.type === Order_Open_Type.Increase
					? getTransactionTypeForLimitV2(item.side)
					: getTransactionTypeForProfitLossV2(
							item.triggerAbove,
							item.side,
							item.sizeDelta
					  );

			const orderStatus = item.status;

			const orderItem = {
				...item,
				id: `${item.type}-${item.index}` as OrderId,
				orderV2Id: item.id,
				transactionType,
				txHash:
					orderStatus === Order_Status.Created
						? item.createdHash
						: orderStatus === Order_Status.Cancelled
						? item.cancelledHash
						: item.executedHash,
				poolId: item.market.toLowerCase(),
				liquidityDelta: multipliedBy(item.sizeDelta, item.triggerMarketPrice),
				indexPrice: filteredPool.length > 0 ? filteredPool[0]?.indexPrice : '0',
				baseToken: baseToken,
				expired: false,
				orderStatus
			} as IOrderItem;

			orderListArray.push(orderItem);

			const target = ordersMap.get(orderItem.poolId);
			if (!target) {
				ordersMap.set(orderItem.poolId, [orderItem]);
			} else {
				target.push(orderItem);
			}
		});
		setOrderList(orderListArray);
		setOrdersMap(ordersMap);
	}, [data, poolId]);

	useEffect(() => {
		setLoading(true);
		setData([]);
		fetchAction(0).finally(() => setLoading(false));
	}, [poolId]);

	return {
		orderList,
		ordersMap,
		loading,
		requestsByHashes,
		refetch: fetchAction
	};
}
