import { SvgProps } from 'components/Svg/types';

// hover状态
export function TradeActive({ fill = 'white' }: SvgProps) {
	return (
		<svg
			width='27'
			height='25'
			viewBox='0 0 27 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4.28809 1.19043C3.18352 1.19043 2.28809 2.08586 2.28809 3.19043V20.3443C2.28809 21.4488 3.18351 22.3443 4.28808 22.3443H21.4419C22.5465 22.3443 23.4419 21.4488 23.4419 20.3443V3.19043C23.4419 2.08586 22.5465 1.19043 21.4419 1.19043H4.28809ZM6.28811 10.1159C6.28811 10.3011 6.43826 10.4513 6.62348 10.4513L18.5583 10.4512C18.8361 10.4512 18.9934 10.133 18.8248 9.91228L15.5883 5.67562C15.3936 5.4207 14.9864 5.55841 14.9864 5.87921V8.41051H6.62348C6.43826 8.41051 6.28811 8.56066 6.28811 8.74588V10.1159ZM18.8943 13.5197C18.8943 13.3345 18.7441 13.1843 18.5589 13.1843L6.62407 13.1843C6.34634 13.1843 6.18897 13.5026 6.35757 13.7233L9.59408 17.96C9.78882 18.2149 10.196 18.0772 10.196 17.7564V15.2251H18.5589C18.7441 15.2251 18.8943 15.0749 18.8943 14.8897V13.5197Z'
				fill={fill}
			/>
		</svg>
	);
}

export function Trade({ fill = 'white' }: SvgProps) {
	return (
		<svg
			width='27'
			height='25'
			viewBox='0 0 27 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M3.03809 3.07542C3.03809 2.42755 3.56329 1.90234 4.21116 1.90234H21.5189C22.1667 1.90234 22.6919 2.42755 22.6919 3.07542V20.3831C22.6919 21.031 22.1667 21.5562 21.5189 21.5562H4.21116C3.56329 21.5562 3.03809 21.031 3.03809 20.3831V3.07542Z'
				stroke='#8B919D'
				strokeWidth='1.5'
			/>
			<path
				d='M18.5589 13.146C18.7441 13.146 18.8943 13.2962 18.8943 13.4814V14.8514C18.8943 15.0366 18.7441 15.1868 18.5589 15.1868H10.196V17.7181C10.196 18.0389 9.78882 18.1766 9.59408 17.9217L6.35757 13.685C6.18897 13.4643 6.34634 13.146 6.62407 13.146L18.5589 13.146Z'
				fill='#8B919D'
			/>
			<path
				d='M6.62348 10.413C6.43826 10.413 6.28811 10.2628 6.28811 10.0776V8.70758C6.28811 8.52236 6.43826 8.37221 6.62348 8.37221H14.9864V5.84091C14.9864 5.52011 15.3936 5.3824 15.5883 5.63732L18.8248 9.87398C18.9934 10.0947 18.8361 10.4129 18.5583 10.4129L6.62348 10.413Z'
				fill='#8B919D'
			/>
		</svg>
	);
}
