import { useMemo, useState } from 'react';

import { getContractAddress } from 'config/contracts';

import { useCurrentChain } from 'hooks/useCurrentChain';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';

import { Fee_Amount } from './types';
import { computePoolAddress } from './utils';
import { DEFAULT_POOL_ADDRESS_FOR_ARBITRUM_ONE } from './constants';
import { useAsync } from 'react-use';
import useWeb3Provider from 'hooks/useWeb3Provider';

async function isContract(addresses: Array<string> | null, provider) {
  try {
		if (!addresses) {
			return false;
		}
    const codePromises = addresses.map(address => provider.getCode(address));
    const codes = await Promise.all(codePromises);

    // Check if each code is not empty
    return codes.map(code => code !== '0x');
  } catch (error) {
    console.error('Error checking contracts:', error);
    return Array(addresses.length).fill(false);
  }
}

export function useComputeUniPoolAddresses() {
	const { appToken, uniWeth } = useAppSelector(txBaseState);

	const { currentChainId, currentChain } = useCurrentChain();
	const Web3Provider = useWeb3Provider();

	const [validAddresses, setValidAddresses] = useState<Array<string> | null>(null);

	const factoryAddress = useMemo(() => {
		return getContractAddress(currentChainId, 'UniPoolFactory');
	}, [currentChainId]);

	const uniFeeAmounts = useMemo(() => {
		if (currentChain.testnet) {
			return [Fee_Amount.LOW, Fee_Amount.MEDIUM, Fee_Amount.HIGH];
		}
		return [
			Fee_Amount.LOWEST,
			Fee_Amount.LOW,
			Fee_Amount.MEDIUM,
			Fee_Amount.HIGH
		];
	}, [currentChain]);

	const allAddresses = useMemo(() => {
		if (!appToken || !factoryAddress || !uniWeth) {
			return null;
		}
		return uniFeeAmounts.map((fee: Fee_Amount) => {
			return computePoolAddress({
				factoryAddress,
				tokenA: appToken,
				tokenB: uniWeth,
				fee
			});
		});
	}, [uniFeeAmounts, appToken, uniWeth, currentChain]);

	useAsync(async () => {
		if (!allAddresses) {
			return;
		}
		const values = await isContract(allAddresses, Web3Provider);
		const results = allAddresses.filter((address: string, index: number) => {
			return values[index] === true;
		});
		setValidAddresses(results);
	}, [allAddresses, Web3Provider]);

	return validAddresses;
}

export function useComputeUniPoolAddressesForGraph() {
	const uniPoolAddresses = useComputeUniPoolAddresses();
	const { currentChain } = useCurrentChain();

	return useMemo(() => {
		if (!uniPoolAddresses) {
			return null;
		}
		if (currentChain.testnet) {
			return DEFAULT_POOL_ADDRESS_FOR_ARBITRUM_ONE.map((address: string) => {
				return address.toLowerCase();
			});
		}
		return uniPoolAddresses.map((address: string) => {
			return address.toLowerCase();
		});
	}, [uniPoolAddresses, currentChain]);
}
