import { arbitrum, arbitrumGoerli } from 'config/constants/connector/chains';

import { Env, Module } from './constants';
import { USDT_CURRENCY } from './constants/tokens';

type ModuleMap = Record<Module, string>;

const GRAPH_DEV: Record<number, ModuleMap> = {
	[arbitrumGoerli.id]: {
		[Module.Trade]:
			'https://graph-arbitrum-sepolia.equation.org/subgraphs/name/equation-dev',
		[Module.Referral]:
			'https://graph-arbitrum-sepolia.equation.org/subgraphs/name/earn-dev',
		[Module.Stats]:
			'https://graph-arbitrum-sepolia.equation.org/subgraphs/name/stats-dev',
		[Module.TradeV2]:
			'https://graph-arbitrum-sepolia.equation.org/subgraphs/name/equation-v2-dev',
		[Module.StatsV2]:
			'https://graph-arbitrum-sepolia.equation.org/subgraphs/name/stats-v2-dev'
	},
	[arbitrum.id]: {
		[Module.Trade]:
			'https://graph-arbitrum.equation.trade/subgraphs/name/equation-arbitrum',
		[Module.Referral]:
			'https://graph-arbitrum.equation.trade/subgraphs/name/equation-earn-arbitrum',
		[Module.Stats]:
			'https://graph-arbitrum.equation.trade/subgraphs/name/equation-stats-arbitrum',
		[Module.TradeV2]:
			'https://graph-arbitrum-sepolia.equation.org/subgraphs/name/equation-v2-dev',
		[Module.StatsV2]:
			'https://graph-arbitrum-sepolia.equation.org/subgraphs/name/stats-v2-dev'
	}
};

const GRAPH_PROD: Record<number, ModuleMap> = {
	[arbitrumGoerli.id]: {
		[Module.Trade]:
			'https://graph-arbitrum-sepolia.equation.org/subgraphs/name/equation-dev',
		[Module.Referral]:
			'https://graph-arbitrum-sepolia.equation.org/subgraphs/name/earn-dev',
		[Module.Stats]:
			'https://graph-arbitrum-sepolia.equation.org/subgraphs/name/stats-dev',
		[Module.TradeV2]:
			'https://graph-arbitrum-sepolia.equation.org/subgraphs/name/equation-v2-dev',
		[Module.StatsV2]:
			'https://graph-arbitrum-sepolia.equation.org/subgraphs/name/stats-v2-dev'
	},
	[arbitrum.id]: {
		[Module.Trade]:
			'https://graph-arbitrum.equation.trade/subgraphs/name/equation-arbitrum',
		[Module.Referral]:
			'https://graph-arbitrum.equation.trade/subgraphs/name/equation-earn-arbitrum',
		[Module.Stats]:
			'https://graph-arbitrum.equation.trade/subgraphs/name/equation-stats-arbitrum',
		[Module.TradeV2]:
			'https://graph-arbitrum.equation.trade/subgraphs/name/equation-v2-arbitrum',
		[Module.StatsV2]:
			'https://graph-arbitrum.equation.trade/subgraphs/name/equation-stats-v2-arbitrum'
	}
};

const GRAPH_HOST = {
	[Env.development]: GRAPH_DEV,
	[Env.production]: GRAPH_PROD
};

export const GRAPH_API_URL: Record<number, ModuleMap> =
	GRAPH_HOST[import.meta.env.MODE as Env];

const BLOCK_GRAPH_DEV: Record<number, string> = {
	[arbitrumGoerli.id]:
		'https://api.studio.thegraph.com/query/45560/blocks-on-sepolia/v0.0.2',
	[arbitrum.id]:
		'https://graph-arbitrum.equation.trade/subgraphs/name/blocks-arbitrum'
};

const BLOCK_GRAPH_PROD: Record<number, string> = {
	[arbitrumGoerli.id]:
		'https://api.studio.thegraph.com/query/45560/blocks-on-sepolia/v0.0.2',
	[arbitrum.id]:
		'https://graph-arbitrum.equation.trade/subgraphs/name/blocks-arbitrum'
};

const BLOCK_GRAPH = {
	[Env.development]: BLOCK_GRAPH_DEV,
	[Env.production]: BLOCK_GRAPH_PROD
};

export const BLOCK_GRAPH_URL: Record<number, string> =
	BLOCK_GRAPH[import.meta.env.MODE as Env];

const DEFAULT_POOL_DEV: Record<number, string> = {
	[arbitrumGoerli.id]: '0xcdb6Ae424BFB8d2247677bf831aAac81424A715d',
	[arbitrum.id]: '0xE8489d514AEF77c5730dDE4eAC46b8F2d9fFD21C'
};

const DEFAULT_POOL_PROD: Record<number, string> = {
	[arbitrumGoerli.id]: '0xcdb6Ae424BFB8d2247677bf831aAac81424A715d',
	[arbitrum.id]: '0xE8489d514AEF77c5730dDE4eAC46b8F2d9fFD21C'
};

const DEFAULT_POOL = {
	[Env.development]: DEFAULT_POOL_DEV,
	[Env.production]: DEFAULT_POOL_PROD
};

const DEFAULT_POOL_DEV_V2: Record<number, string> = {
	[arbitrumGoerli.id]: '0xdf5B5baCF722D7663Efa2C4Cd3D5658e92595Ec2',
	[arbitrum.id]: '0xdf5B5baCF722D7663Efa2C4Cd3D5658e92595Ec2'
};

const DEFAULT_POOL_PROD_V2: Record<number, string> = {
	[arbitrumGoerli.id]: '0xDE43a589Bc5b6136C4B7Ec24311D61713Cd5dF37',
	[arbitrum.id]: '0xDE43a589Bc5b6136C4B7Ec24311D61713Cd5dF37'
};

const DEFAULT_POOL_V2 = {
	[Env.development]: DEFAULT_POOL_DEV_V2,
	[Env.production]: DEFAULT_POOL_PROD_V2
};

export const DEFAULT_POOL_ADDRESS: Record<number, string> =
	DEFAULT_POOL[import.meta.env.MODE as Env];

export const DEFAULT_POOL_ADDRESS_V2: Record<number, string> =
	DEFAULT_POOL_V2[import.meta.env.MODE as Env];

const USDT_DEV: Record<number, string> = {
	[arbitrumGoerli.id]: USDT_CURRENCY[arbitrumGoerli.id].address,
	[arbitrum.id]: USDT_CURRENCY[arbitrum.id].address
};

const USDT_PROD: Record<number, string> = {
	[arbitrumGoerli.id]: USDT_CURRENCY[arbitrumGoerli.id].address,
	[arbitrum.id]: USDT_CURRENCY[arbitrum.id].address
};

const USDT = {
	[Env.development]: USDT_DEV,
	[Env.production]: USDT_PROD
};

export const USDT_ADDRESS: Record<number, string> =
	USDT[import.meta.env.MODE as Env];

const START_BLOCK_NUMBER_DEV: Record<number, number> = {
	[arbitrumGoerli.id]: 3153655,
	[arbitrum.id]: 142245091
};

const START_BLOCK_NUMBER_PROD: Record<number, number> = {
	[arbitrumGoerli.id]: 3153655,
	[arbitrum.id]: 142245091
};

export const MARKETS_BLOCK_NUMBER_MAP = {
	[arbitrumGoerli.id]: 25093741,
	[arbitrum.id]: 174255153
};

const START_BLOCK_NUMBERS = {
	[Env.development]: START_BLOCK_NUMBER_DEV,
	[Env.production]: START_BLOCK_NUMBER_PROD
};

export const START_BLOCK_NUMBER: Record<number, number> =
	START_BLOCK_NUMBERS[import.meta.env.MODE as Env];

const UNI_GRAPH_ADDRESS_DEV: Record<number, string> = {
	[arbitrumGoerli.id]:
		// 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-arbitrum-one',
		'https://api.thegraph.com/subgraphs/name/messari/uniswap-v3-arbitrum',
	[arbitrum.id]:
		// 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-arbitrum-one'
		'https://api.thegraph.com/subgraphs/name/messari/uniswap-v3-arbitrum'
};

const UNI_GRAPH_ADDRESS_PROD: Record<number, string> = {
	[arbitrumGoerli.id]:
		// 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-arbitrum-one',
		// 'https://api.thegraph.com/subgraphs/name/messari/uniswap-v3-arbitrum',
		'https://interface.gateway.uniswap.org/v1/graphql',
	[arbitrum.id]:
		// 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-arbitrum-one',
		// 'https://api.thegraph.com/subgraphs/name/messari/uniswap-v3-arbitrum',
		'https://interface.gateway.uniswap.org/v1/graphql'
};

const UNI_GRAPH_ADDRESSES = {
	[Env.development]: UNI_GRAPH_ADDRESS_DEV,
	[Env.production]: UNI_GRAPH_ADDRESS_PROD
};

export const UNI_GRAPH_ADDRESS: Record<number, string> =
	UNI_GRAPH_ADDRESSES[import.meta.env.MODE as Env];

export const EVENT_SITE = 'https://event.equation.trade';
export const TRADE_SITE = 'https://equation.trade';
