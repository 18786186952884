import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import { Box, Divider, Typography, alpha, useTheme } from '@mui/material';
import { Dom_Size, Page, QUOTE_USD, Side, Version } from 'config/constants';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import FreeTradingFeeWarning, {
	Type_Free_Trading_Fee
} from 'pages/components/FreeTradingFeeWarning';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setOnlyUsedPools, settingBaseState } from 'state/setting/slice';
import {
	amountFormatter,
	div,
	formatLeverage,
	isGreaterThan,
	isNumeric,
	isZero,
	plus,
	shortenSymbolNative,
	toPercent,
	toQuoteAmount
} from 'utils';

import Cell, { DirectionCell } from 'components/Common/Cell';
import { StyleCheckbox } from 'components/Common/StyleCheckbox';
import SectionLoading from 'components/SectionLoading';
import TippingUnderline from 'components/TippingUnderline';

import NoData from '../../../components/NoData';
import RiskRate from '../RiskRate';
import BlinkTag from './BlinkTag';

const LiquidityCardV2 = ({
	rows,
	isLoading,
	onClickRow,
	getSizeDiff
}: {
	rows: any[];
	isLoading: boolean;
	onClickRow: (params: string) => void;
	getSizeDiff: (params: string) => void;
}) => {
	const { currentChainId } = useCurrentChain();
	const isLogin = useCheckLogin();
	const theme = useTheme();
	const navigate = useNavigate();
	const { isMatchMobile } = useAppBreakpoints();
	const dispatch = useAppDispatch();
	const { onlyUsedPools } = useAppSelector(settingBaseState);
	const currentVersion = useAppSelector(state => state.setting.currentVersion);
	const loadingHeight = useMemo(() => {
		return (
			window.innerHeight -
			Dom_Size.HEIGHT_HEADER -
			Dom_Size.MOBILE_HEIGHT_BOTTOM
		);
	}, [window.innerHeight]);

	return (
		<Box>
			{isLogin && currentVersion === Version.V2 && (
				<div className='flex justify-between p-4'>
					<Trans>Pools</Trans>
					<div className='flex'>
						<StyleCheckbox
							checked={onlyUsedPools}
							onChange={e => dispatch(setOnlyUsedPools(e.target.checked))}
							size='small'
							sx={{ height: '16px' }}
						/>
						<Typography color='text.secondary' variant='body2'>
							<Trans>My Liquidity Only</Trans>
						</Typography>
					</div>
				</div>
			)}
			{isLoading && (
				<Box
					sx={{
						backgroundColor: theme.palette.background.default,
						height: loadingHeight
					}}
				>
					<SectionLoading full={true} />
				</Box>
			)}

			{!isLoading &&
				rows.map(row => {
					const liquidityPassivePosition =
						row?.liquidityPositions[0]?.liquidityPassivePosition || {};
					const sizeDiff = getSizeDiff(liquidityPassivePosition);
					const myLiquidity = row.liquidityPositions.reduce(
						(pre, cur) => plus(pre, cur.liquidity),
						'0'
					);
					return (
						<Box
							key={row.id}
							className='flex justify-between px-3 py-4 mb-2 last:mb-0 space-x-3 w-full'
							sx={{
								background: isMatchMobile
									? theme.palette.background.paper
									: theme.custom.ListBg
							}}
							onClick={() => {
								onClickRow(row.id);
								navigate(
									`${
										currentVersion === Version.V1 ? Page.PoolsV1 : Page.Pools
									}/${row.id}`
								);
							}}
						>
							<Box className='space-y-9 w-[35%]'>
								<Typography className='font-medium' variant='h6'>
									{`${shortenSymbolNative(
										row.baseToken,
										currentChainId
									)}/${QUOTE_USD}`}
								</Typography>
								<Box component='div'>
									<DirectionCell
										label={<Trans>Max APR</Trans>}
										value={
											<div className='flex items-center gap-1'>
												<Typography variant='body2' className='text-[11px]'>
													{toPercent(row.liquidityMaxApr)}
												</Typography>
											</div>
										}
									/>
								</Box>
							</Box>
							<div className='flex space-x-4 justify-between w-[65%]'>
								<Box className='space-y-6'>
									<DirectionCell
										label={
											currentVersion === Version.V1 ? (
												<Trans>24h Fee</Trans>
											) : (
												<Trans>Fee Income (7d)</Trans>
											)
										}
										value={
											<div className='flex items-center space-x-1'>
												<Typography variant='body1'>
													{currentVersion === Version.V1
														? toQuoteAmount(row.tradingFee24h)
														: toQuoteAmount(plus(row.fee7d, row.fundingFee7d))}
												</Typography>
												{isZero(row.referralDiscountRate) && (
													<FreeTradingFeeWarning
														type={Type_Free_Trading_Fee.Warning}
													/>
												)}
											</div>
										}
									/>
									<DirectionCell
										label={<Trans>My Liquidity</Trans>}
										value={
											<Typography variant='body1'>
												{isLogin ? amountFormatter(myLiquidity, 2, 2) : '-'}
											</Typography>
										}
									/>
								</Box>
								<Box className='space-y-6'>
									<DirectionCell
										align='right'
										label={<Trans>Liquidity</Trans>}
										value={
											<Typography variant='body1'>
												{amountFormatter(row.liquidity, 2, 2)}
											</Typography>
										}
									/>
									<DirectionCell
										align='right'
										label={<Trans>Passive Position</Trans>}
										value={
											<>
												{liquidityPassivePosition &&
												liquidityPassivePosition.size ? (
													<BlinkTag
														key={theme.mode}
														blinkKey={`${liquidityPassivePosition.side}-${liquidityPassivePosition.size}`}
														activeColor={alpha(
															isGreaterThan(sizeDiff, 0)
																? theme.palette.success.main
																: theme.palette.error.main,
															0.16
														)}
														bgColor={theme.custom.BlinkTagColor}
													>
														<div className='flex gap-4 px-2 py-1'>
															<Typography
																variant='body2'
																className='flex gap-1'
																color={
																	liquidityPassivePosition.side === Side.SHORT
																		? 'error.main'
																		: 'success.main'
																}
															>
																{liquidityPassivePosition.side === Side.SHORT
																	? t`Short`
																	: t`Long`}
																<div>
																	{formatLeverage(
																		liquidityPassivePosition.myLeverage
																	)}
																</div>
															</Typography>
															<RiskRate
																risk={row.liquidityPositions[0]?.riskRatio}
																noText
															/>
														</div>
													</BlinkTag>
												) : (
													'-'
												)}
											</>
										}
									/>
								</Box>
							</div>
						</Box>
					);
				})}
			{!isLoading && rows.length === 0 && (
				<NoData size='large'>
					<Trans>No open positions</Trans>
				</NoData>
			)}
		</Box>
	);
};

export default LiquidityCardV2;
