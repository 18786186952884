import { memo, useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import cn from 'classnames';
import Decimal from 'decimal.js';
import moment from 'moment/moment';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { isEmpty } from 'lodash';
import { multipliedBy, toDecimalPlaces } from 'utils';

import StyledChip from 'components/Common/StyledChip';

import LineChart from '../../components/chart/line';
import { Dom_Size } from '../../config/constants';

interface ChartProps {
	poolId: string;
	expand: boolean;
	poolInfo: any;
}

const BalanceRateChart = memo(function BalanceRateChart(props: ChartProps) {
	const { expand, poolId, poolInfo } = props;
	const [activeTab, setActiveTab] = useState(tabs[0]);

	const changeActiveTab = tab => {
		setActiveTab(tab);
	};

	const data = useMemo(() => {
		if (isEmpty(poolInfo)) {
			return [];
		}
		const priceState1Hs = poolInfo.priceState1Hs || [];
		if (priceState1Hs.length === 0) {
			return [];
		}

		const firstHourTs = priceState1Hs[0].ts;
		const lastHourTs = moment()
			.set({ minutes: 0, seconds: 0 })
			.unix()
			.toString();
		const brMap = new Map<string, string>();
		priceState1Hs.forEach((price: any) => {
			brMap.set(price.ts, price.balanceRate);
		});
		let lastBr = priceState1Hs[0].balanceRate;

		const result = [];
		for (let i = Number(firstHourTs); i <= Number(lastHourTs); i += 60 * 60) {
			if (brMap.get(i.toString())) {
				lastBr = brMap.get(i.toString());
			}
			result.push({
				x: i * 1000,
				y: Number(
					multipliedBy(toDecimalPlaces(lastBr, 4, Decimal.ROUND_UP), 100)
				),
				originY: Number(
					toDecimalPlaces(multipliedBy(lastBr, 100), 6, Decimal.ROUND_UP)
				)
			});
		}
		return result;
	}, [poolInfo]);

	const { isMatchMobile } = useAppBreakpoints();

	return (
		<Box sx={{ height: 320 }} className='relative'>
			<div className='h-full relative'>
				<LineChart
					data={data.slice(-activeTab.number)}
					tickFormatter={(value: string) =>
						isMatchMobile
							? moment(value).format('DD')
							: moment(value).format('DD')
					}
					margin={{
						top: 32,
						right: isMatchMobile ? 8 : 32
					}}
					height={320}
					interval={Math.floor(data.slice(-activeTab.number).length / 8)}
				/>
			</div>
			<Box className='absolute right-0 -top-1 w-full space-x-2 flex items-center justify-end'>
				<div className='flex'>
					{tabs.map(tab => (
						<StyledChip
							label={tab.label}
							key={tab.value}
							onClick={() => changeActiveTab(tab)}
							className={cn(activeTab.value === tab.value ? 'active' : '')}
							sx={{
								borderRadius: '4px',
								'.MuiChip-label': {
									padding: isMatchMobile ? '2px 8px' : '4px 24px'
								}
							}}
						/>
					))}
				</div>
			</Box>
		</Box>
	);
});

export default BalanceRateChart;

const tabs = [
	{
		label: '7d',
		value: 7,
		number: 168
	},
	{
		label: '14d',
		value: 14,
		number: 336
	},
	{
		label: '30d',
		value: 30,
		number: 720
	},
	{
		label: '60d',
		value: 60,
		number: 1440
	}
	/* {
		label: '90d',
		value: 90,
		number: 2160, 
	}, */
];
