import { useEffect, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans, t } from '@lingui/macro';
import { styled } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import {
	APP_TOKEN_SYMBOL,
	App_Theme,
	MAX_PRECISION,
	Transaction_Type,
	Dom_Size
} from 'config/constants';
import { getContractAddress } from 'config/contracts';

import { ApprovalState, useApproval } from 'hooks/useApproval';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { useSubmitStakeAppToken } from 'hooks/useSubmitStakeAppToken';
import { cloneDeep, debounce } from 'lodash';
import { earnStakeBaseState } from 'state/earn/stake/slice';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import {
	checkInputNumberic,
	formatNumber,
	isGreaterThan,
	isPositive,
	shortenSymbol
} from 'utils';

import Dialog, { DialogProps } from 'components/Common/Dialog';
import {
	CommonStyledDialogInput,
	CommonStyledMaxButton
} from 'components/Common/Styled';
import {useAppBreakpoints} from 'hooks/useAppBreakpoints';

export const StyledRadio = styled(FormControlLabel)`
	background: ${({ theme }) =>
		theme.palette.mode === App_Theme.dark
			? theme.palette.background.paper
			: theme.custom.cardBg};
	border-radius: 4px;
	margin-left: 0;
	margin-right: 0;
	position: relative;
	padding: 14px 4px;
	display: flex;
	justify-content: center;
	.MuiRadio-root {
		position: absolute;
		left: 4px;
		top: 4px;
		padding: 0;
	}
`;

export default function StakeDialog(props: DialogProps) {
	const { open, onClose } = props;

	const { appTokenBalance, appToken, signingMap } = useAppSelector(txBaseState);
	const { stakeStatistic } = useAppSelector(earnStakeBaseState);

	const { currentChainId } = useCurrentChain();

	const [period, setPeriod] = useState<number | undefined>(undefined);
	const [multiplier, setMultiplier] = useState<number | undefined>(undefined);
	const [stakeAmount, setStakeAmount] = useState<string>('');

	const feeDistributorContractAddress = useMemo(() => {
		return getContractAddress(currentChainId, 'FeeDistributor');
	}, [currentChainId]);

	const { currentApproveState, approveCallback } = useApproval(
		appToken,
		stakeAmount,
		feeDistributorContractAddress
	);

	const { onConfirm, isConfirming, isConfirmed } = useSubmitStakeAppToken(
		stakeAmount,
		period,
		multiplier,
		currentApproveState
	);

	const [periods, multipliers] = useMemo(() => {
		if (!stakeStatistic) {
			return [null, null];
		}
		const _stakeStatistic = cloneDeep(stakeStatistic);
		return [
			_stakeStatistic.periods.reverse(),
			_stakeStatistic.multipliers.reverse()
		];
	}, [stakeStatistic]);

	useEffect(() => {
		if (periods && multipliers && !period) {
			setPeriod(periods[0]);
			setMultiplier(multipliers[0]);
		}
	}, [periods, multipliers]);

	useUpdateEffect(() => {
		if (isConfirmed && onClose) {
			setStakeAmount('');
			onClose();
		}
	}, [isConfirmed]);

	const [disabled, submitText] = useMemo(() => {
		if (!isPositive(stakeAmount) || !isPositive(period)) {
			return [true, t`Confirm`];
		}
		if (isGreaterThan(stakeAmount, appTokenBalance)) {
			return [true, t`Insufficient Balance`];
		}
		if (currentApproveState === ApprovalState.NOT_APPROVED) {
			return [false, t`Approve ${shortenSymbol(appToken)}`];
		}
		if (currentApproveState === ApprovalState.PENDING) {
			return [true, t`Approving...`];
		}
		if (signingMap.has(Transaction_Type.StakeAppToken)) {
			return [true, t`Loading...`];
		}
		if (isConfirming) {
			return [true, t`Submitting...`];
		}
		return [false, t`Confirm`];
	}, [
		stakeAmount,
		signingMap,
		isConfirming,
		appToken,
		appTokenBalance,
		currentApproveState
	]);

	const onHandleChangeInput = (event: any) => {
		const value = event.target.value;
		if (value.trim() === '') {
			setStakeAmount('');
			return;
		}
		if (checkInputNumberic(value, appToken?.decimals)) {
			setStakeAmount(value);
		}
	};

	const onHandleConfirm = debounce(() => {
		if (currentApproveState === ApprovalState.NOT_APPROVED) {
			approveCallback();
			return;
		}
		onConfirm();
	}, 300);

	const onHandleSetMaxAmount = () => {
		if (isPositive(appTokenBalance)) {
			setStakeAmount(appTokenBalance);
		}
	};

	const onHandleSelectDuration = (index: number) => {
		if (stakeStatistic) {
			setPeriod(periods[index]);
			setMultiplier(multipliers[index]);
		}
	};

	const {isMatchMobile} = useAppBreakpoints()

	return (
		<Dialog
			open={open}
			onClose={onClose}
			title={`Stake ${APP_TOKEN_SYMBOL}`}
			disabled={disabled}
			confirmLabel={submitText}
			onConfirm={onHandleConfirm}
			width={isMatchMobile ? '100%' : Dom_Size.DIALOG_WIDTH_LARGE}
		>
			<div className='space-y-4'>
				<section className='space-y-1'>
					<div className='flex justify-between'>
						<Typography variant='body1' color='secondary'>
							<Trans>Amount</Trans>
						</Typography>
						<div className='flex space-x-1'>
							<Typography variant='body2' color='secondary'>
								<Trans>Balance:</Trans>
							</Typography>
							<Typography variant='body2'>
								{`${formatNumber(appTokenBalance)} ${shortenSymbol(appToken)}`}
							</Typography>
						</div>
					</div>
					<CommonStyledDialogInput
						type='text'
						inputProps={{
							maxLength: MAX_PRECISION,
							inputMode: 'decimal'
						}}
						value={stakeAmount}
						placeholder='0.0'
						onChange={onHandleChangeInput}
						fullWidth
						endAdornment={
							isPositive(appTokenBalance) && (
								<CommonStyledMaxButton
									variant='outlined'
									className='font-normal'
									color='secondary'
									onClick={() => onHandleSetMaxAmount()}
								>
									<Trans>Max</Trans>
								</CommonStyledMaxButton>
							)
						}
					/>
				</section>
				<section className='space-y-1'>
					{periods && multipliers && period && (
						<>
							<Typography variant='body1' color='text.secondary'>
								<Trans>Lock Duration</Trans>
							</Typography>
							<RadioGroup row className='grid grid-cols-3 gap-2' value={period}>
								{periods.map((_period: number, index: number) => {
									return (
										<StyledRadio
											key={index}
											value={_period}
											control={<Radio size='small' />}
											onClick={() => onHandleSelectDuration(index)}
											label={
												<Typography
													color={
														period === _period
															? 'text.primary'
															: 'text.secondary'
													}
													align='center'
												>
													<div>
														<Trans>{_period} days</Trans>
													</div>
													<div className='flex space-x-1'>
														<Typography
															variant='body2'
															color={
																period === _period
																	? 'inherit'
																	: 'text.secondary'
															}
														>
															<Trans>Multiplier</Trans>
														</Typography>
														<Typography variant='body2' color='text.primary'>
															{multipliers[index]}x
														</Typography>
													</div>
												</Typography>
											}
										/>
									);
								})}
							</RadioGroup>
						</>
					)}
				</section>
			</div>
		</Dialog>
	);
}
