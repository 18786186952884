import { useTheme } from '@mui/material/styles';

function Remove(props) {
	// eslint-disable-next-line react/prop-types
	const { size = 20, type = '' } = props;
	const theme = useTheme();
	const strokeColor =
		type === 'disabled'
			? theme.palette.text.disabled
			: theme.palette.text.primary;

	return (
		<svg
			width={size}
			height={size}
			viewBox='0 0 20 21'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			style={{
				display: 'inline'
			}}
			{...props}
		>
			<path
				d='M16.25 2.99805H3.75C3.05964 2.99805 2.5 3.55769 2.5 4.24805V16.748C2.5 17.4384 3.05964 17.998 3.75 17.998H16.25C16.9404 17.998 17.5 17.4384 17.5 16.748V4.24805C17.5 3.55769 16.9404 2.99805 16.25 2.99805Z'
				stroke={strokeColor}
				strokeOpacity='0.9'
				strokeLinejoin='round'
			/>
			<path
				d='M6.66699 10.498H13.3337'
				stroke={strokeColor}
				strokeOpacity='0.9'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}

export default Remove;
