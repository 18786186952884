import React, { ReactNode } from 'react';

import { Box, Typography, useTheme } from '@mui/material';
import { Dom_Size } from 'config/constants';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';

export default React.memo(function ActivityContainer({
	title,
	subTitle,
	children
}: {
	title: ReactNode;
	subTitle?: ReactNode;
	children: ReactNode;
}) {
	const { isMatchMobile, isMatchPad } = useAppBreakpoints();
	const theme = useTheme();
	return (
		<Box
			className='pt-8 space-y-2 md:pt-4 sm:pt-6 sm:flex sm:flex-col sm:border-t mb-4 underLg:mb-2'
			component='article'
			sx={{
				borderColor: theme.palette.divider,
				width: '100%',
				maxWidth: Dom_Size.ACTIVITY_CONTAINER,
				margin: '0 auto'
			}}
		>
			<div className='mb-4 sm:mb-2 underLg:px-3'>
				<Typography variant={isMatchMobile || isMatchPad ? 'h3' : 'h1'}>
					{title}
				</Typography>
				<Typography variant={isMatchMobile ? 'body2' : 'h6'} className='mt-0.5'>
					{subTitle}
				</Typography>
			</div>
			<div className='flex-grow space-y-2'>{children}</div>
		</Box>
	);
});
