import { useCallback, useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { Trans, t } from '@lingui/macro';
import { Divider, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import Decimal from 'decimal.js';
import moment from 'moment';
import { useAccount } from 'wagmi';

import { multiply } from 'lodash';

import { DirectionCell } from '../../../components/Common/Cell';
import NoData from '../../../components/NoData';
import SectionLoading from '../../../components/SectionLoading';
import VirtualTable from '../../../components/Table/VirtualTable';
import TippingUnderline from '../../../components/TippingUnderline';
import { DEFAULT_PAGE_SIZE } from '../../../config/constants';
import {
	toCamelCase,
	useLiquidityHistoriesRequest
} from '../../../fetch/useRequest';
import { TransationOperationV2 } from '../../../graphql/useTransactionHistoriesGraph';
import { useAppBreakpoints } from '../../../hooks/useAppBreakpoints';
import { useCheckLogin, useCurrentChain } from '../../../hooks/useCurrentChain';
import {
	ExplorerDataType,
	formatDate,
	getExplorerLink,
	isNegative,
	isPositive,
	plus,
	toQuoteAmount
} from '../../../utils';

const LiquidityTradeHistoriesV2 = ({ market }: { market: string }) => {
	const theme = useTheme();
	const { address } = useAccount();
	const { isMatchPad, isMatchPc, isMatchMobile } = useAppBreakpoints();
	const isLogin = useCheckLogin();

	const [from, setFrom] = useState(0);

	const { trigger } = useLiquidityHistoriesRequest(address, {
		market,
		from,
		limit: DEFAULT_PAGE_SIZE
	});

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [fetching, setFetching] = useState(false);
	const [hasMore, setHasMore] = useState(true);

	const fetchAction = useCallback(
		(index: number) => {
			if (hasMore && !fetching && address) {
				setFetching(true);
				return trigger()
					.then(res => {
						setHasMore(res.data.hasMore);
						setData(
							index === 0
								? res.data.transactionHistories
								: [...data, ...res.data.transactionHistories]
						);
						setFrom(
							res.data.transactionHistories[
								res.data.transactionHistories.length - 1
							]?.id
						);
					})
					.finally(() => setFetching(false));
			} else {
				return Promise.resolve();
			}
		},
		[address, data, hasMore, fetching]
	);

	useEffect(() => {
		setData([]);
		fetchAction(0).finally(() => setLoading(false));
	}, []);

	const { currentChain: chain } = useCurrentChain();

	const HISTORY_LABEL_MAP = {
		[TransationOperationV2.LiquidityPositionClosed]: <Trans>Remove</Trans>,
		[TransationOperationV2.LiquidityPositionOpened]: <Trans>Add</Trans>,
		[TransationOperationV2.LiquidityPositionLiquidated]: (
			<Trans>Liquidation</Trans>
		),
		[TransationOperationV2.PositionIncreased]: <Trans>Add</Trans>,
		[TransationOperationV2.PositionDecreased]: <Trans>Remove</Trans>,
		[TransationOperationV2.LiquidityIncreaseMargin]: <Trans>Add Margin</Trans>,
		[TransationOperationV2.LiquidityDecreaseMargin]: (
			<Trans>Reduce Margin</Trans>
		)
	};

	const rows = data
		.map(row => ({ ...row, data: toCamelCase(JSON.parse(row.data)) }))
		.map(row => {
			return {
				...row,
				time: multiply(moment(row.blockTimestamp).unix(), 1000),
				liquidity: row.data.liquidityDelta,
				margin: row.data.marginDelta,
				operation: row.operation as keyof typeof HISTORY_LABEL_MAP,
				pnl: row.data.pnl,
				txHash: row.txHash,
				feesIncome: plus(row.data.feeDelta, row.data.fundingFeeDelta)
			};
		});
	const columns = [
		{
			id: 'time',
			label: t`Time`,
			width: '16%',
			format: (row: (typeof rows)[number]) => (
				<Typography
					align='left'
					variant='inherit'
					className='whitespace-nowrap'
				>
					{formatDate(row.time, 'MMM D, HH:mm:ss')}
				</Typography>
			)
		},
		{
			id: 'type',
			label: t`Operation`,
			width: '10%',
			format: (row: (typeof rows)[number]) => HISTORY_LABEL_MAP[row.operation]
		},
		{
			id: 'liquidity',
			label: t`Liquidity`,
			width: '13%',
			format: (row: (typeof rows)[number]) =>
				toQuoteAmount(row.liquidity, 2, Decimal.ROUND_DOWN, true)
		},
		{
			id: 'margin',
			label: (
				<Typography align='left' variant='inherit'>{t`Margin`}</Typography>
			),
			width: '10%',
			format: (row: (typeof rows)[number]) => (
				<Typography align='left' variant='inherit'>
					{toQuoteAmount(row.margin, 2, Decimal.ROUND_DOWN, true)}
				</Typography>
			)
		},
		{
			id: 'liquidity',
			label: t`Fee Income`,
			width: '10%',
			format: (row: (typeof rows)[number]) => (
				<Typography
					align='left'
					variant='inherit'
					color={
						isPositive(row.feesIncome)
							? theme.palette.success.main
							: theme.palette.text.primary
					}
				>
					{toQuoteAmount(row.feesIncome, 2, Decimal.ROUND_DOWN, true)}
				</Typography>
			)
		},
		{
			id: 'pnl',
			label: (
				<TippingUnderline
					tooltip={
						<Typography>
							<Trans>
								Every time there is a change in the liquidity position
								(including adding/reducing liquidity or adjusting margin, etc.),
								the system will settle the PnL of the passive position. This
								data represents the specific PnL amount settled during each
								position change.
							</Trans>
						</Typography>
					}
					label={
						<Typography variant='body2'>
							<Trans>Realized PnL</Trans>
						</Typography>
					}
				/>
			),
			width: '10%',
			align: isMatchPad ? 'left' : 'right',
			format: (row: (typeof rows)[number]) => {
				return (
					<Typography
						className={classNames('flex items-center space-x-1', {
							'justify-end': !isMatchPad,
							'justify-start': isMatchPad
						})}
						variant='body2'
						align='right'
						color={
							isPositive(row.data.pnlDelta)
								? theme.palette.success.main
								: isNegative(row.data.pnlDelta)
								? theme.palette.error.main
								: theme.palette.text.primary
						}
					>
						{toQuoteAmount(row.data.pnlDelta, 2, Decimal.ROUND_DOWN, true)}
					</Typography>
				);
			}
		}
	];

	const onRowClick = (row: (typeof rows)[number]) => {
		const link = getExplorerLink(
			chain,
			row.txHash || '',
			ExplorerDataType.TRANSACTION
		);
		window.open(link);
	};
	const mobileHeight = 102;

	return (
		<div>
			{isLogin ? (
				loading ? (
					<SectionLoading />
				) : !isMatchPc ? (
					<div className='mt-3'>
						{rows.length > 0 && (
							<Virtuoso
								endReached={fetchAction}
								style={{
									height: Math.min(2 * mobileHeight, rows.length * mobileHeight)
								}}
								className={classNames({ 'pr-4': isMatchPad })}
								data={rows}
								itemContent={(columnIndex, item) => {
									return (
										<div className={classNames({ 'pr-4': isMatchPad })}>
											<div
												className={classNames(
													`grid grid-cols-${
														columns.length > 2 ? 3 : 2
													} gap-4 gap-y-3`,
													isMatchPad
														? `grid-cols-${columns.length > 2 ? 4 : 2}`
														: `grid-cols-${columns.length > 2 ? 3 : 2}`
												)}
											>
												{columns.map((column: any, columnIndex: number) => (
													<DirectionCell
														key={columnIndex}
														label={column.label}
														align={
															column.align
																? column.align
																: columnIndex &&
																  (columnIndex + 1) % (isMatchPad ? 4 : 3) === 0
																? 'right'
																: 'left'
														}
														value={column.format(item)}
													/>
												))}
											</div>
											<Divider className='my-4' />
										</div>
									);
								}}
							/>
						)}
						{rows.length === 0 && (
							<NoData>
								<Trans>No history</Trans>
							</NoData>
						)}
					</div>
				) : (
					<div className='-mx-4'>
						<VirtualTable
							rows={rows}
							columns={columns}
							itemHeight={42}
							cellColor={theme.custom.paperBg}
							loadMore={fetchAction}
							onRowClick={onRowClick}
							noDataNode={
								<NoData className='text-sm ml-4'>
									<Trans>No history</Trans>
								</NoData>
							}
							isPointer
						/>
					</div>
				)
			) : (
				<NoData>
					<Trans>No history</Trans>
				</NoData>
			)}
		</div>
	);
};

export default LiquidityTradeHistoriesV2;
