import { useCallback, useEffect, useMemo, useState } from 'react';

import { MaxUint256, Zero } from '@ethersproject/constants';
import { EFCABI } from 'config/abis';
import { getContractAddress } from 'config/contracts';
import { Address, useAccount, useContractRead } from 'wagmi';

import { BigNumber, ethers } from 'ethers';

import { useCheckLogin, useCurrentChain } from './useCurrentChain';

export function useCheckCode(code: string) {
	const { currentChainId } = useCurrentChain();
	const isLogin = useCheckLogin();
	const [isCodeRegistered, setIsCodeRegistered] = useState(true);

	const { data, isFetching: isLoading } = useContractRead({
		address: getContractAddress(currentChainId, 'EFC') as Address,
		abi: EFCABI,
		functionName: 'codeTokens',
		args: [code],
		enabled: Boolean(code)
	});

	useEffect(() => {
		if (isLogin && data && data?.toString() !== '0') {
			setIsCodeRegistered(true);
		} else {
			if (code !== '') {
				setIsCodeRegistered(false);
			} else {
				setIsCodeRegistered(true);
			}
		}
	}, [data, isLogin, code]);

	return {
		isCodeRegistered,
		isLoading
	};
}
