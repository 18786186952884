import { memo, useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import cn from 'classnames';
import Decimal from 'decimal.js';
import { usePoolBalanceRate } from 'fetch/useRequest';
import moment from 'moment/moment';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { isEmpty } from 'lodash';
import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { multipliedBy, toDecimalPlaces } from 'utils';

import StyledChip from 'components/Common/StyledChip';
import Loading from 'components/SectionLoading';
import LineChart from 'components/chart/line';

import { Dom_Size } from '../../../config/constants';

const BalanceRateChart = memo(function BalanceRateChart() {
	const [activeTab, setActiveTab] = useState(tabs[0]);
	const { poolInfo } = useAppSelector(poolsBaseState);

	const changeActiveTab = tab => {
		setActiveTab(tab);
	};

	const params = useMemo(() => {
		if (isEmpty(poolInfo)) {
			return null;
		}
		return {
			market: poolInfo.id,
			from: moment().unix(),
			to: moment().subtract(activeTab.value, 'days').unix()
		};
	}, [poolInfo]);

	const { data: fetchedData } = usePoolBalanceRate(params);

	const data = useMemo(() => {
		if (isEmpty(fetchedData)) {
			return [];
		}
		const priceState1Hs = fetchedData.balanceRates || [];
		if (priceState1Hs.length === 0) {
			return [];
		}
		const firstHourTs = priceState1Hs.slice(-1)[0].ts;
		const lastHourTs = moment().utc().startOf('hour').unix();
		const brMap = new Map<string, string>();
		priceState1Hs.forEach((item: any) => {
			brMap.set(item.ts, item.balanceRate);
		});

		const result = [];
		for (let i = firstHourTs; i <= lastHourTs; i += 60 * 60) {
			const currentItem = brMap.get(i);
			if (i === firstHourTs) {
				const firstBar = priceState1Hs.slice(-1)[0];
				result.push({
					...firstBar,
					x: firstBar.ts * 1000,
					y: Number(
						multipliedBy(
							toDecimalPlaces(firstBar.balanceRate, 4, Decimal.ROUND_UP),
							100
						)
					),
					originY: Number(
						toDecimalPlaces(
							multipliedBy(firstBar.balanceRate, 100),
							6,
							Decimal.ROUND_UP
						)
					)
				});
			} else {
				if (!currentItem) {
					result.push({
						...result.slice(-1)[0],
						x: i * 1000
					});
				} else {
					result.push({
						x: i * 1000,
						y: Number(
							multipliedBy(
								toDecimalPlaces(currentItem, 4, Decimal.ROUND_UP),
								100
							)
						),
						originY: Number(
							toDecimalPlaces(
								multipliedBy(currentItem, 100),
								6,
								Decimal.ROUND_UP
							)
						)
					});
				}
			}
		}
		return result;
	}, [fetchedData]);

	const { isMatchMobile } = useAppBreakpoints();

	return (
		<Box
			sx={{
				height: 320
			}}
			className='relative w-full'
		>
			<div className='h-full relative'>
				{isEmpty(poolInfo) ? (
					<Loading />
				) : (
					<LineChart
						data={data.slice(-activeTab.number)}
						tickFormatter={(value: string) =>
							isMatchMobile
								? moment(value).format('DD')
								: moment(value).format('DD')
						}
						margin={{
							top: 32,
							right: isMatchMobile ? 8 : 32
						}}
						height={320}
						interval={Math.floor(data.slice(-activeTab.number).length / 8)}
					/>
				)}
			</div>
			<Box className='absolute right-0 -top-1 w-full space-x-2 flex items-center justify-end'>
				<div className='flex'>
					{tabs.map(tab => (
						<StyledChip
							label={tab.label}
							key={tab.value}
							onClick={() => changeActiveTab(tab)}
							className={cn(activeTab.value === tab.value ? 'active' : '')}
							sx={{
								borderRadius: '4px',
								'.MuiChip-label': {
									padding: isMatchMobile ? '2px 8px' : '4px 24px'
								}
							}}
						/>
					))}
				</div>
			</Box>
		</Box>
	);
});

export default BalanceRateChart;

const tabs = [
	{
		label: '7d',
		value: 7,
		number: 168
	},
	{
		label: '14d',
		value: 14,
		number: 336
	},
	{
		label: '30d',
		value: 30,
		number: 720
	},
	{
		label: '60d',
		value: 60,
		number: 1440
	}
	/* {
		label: '90d',
		value: 90,
		number: 2160, 
	}, */
];
