interface SVGProps {
	size?: number;
	fill?: string;
}

function Check({ size = 14, fill = 'white' }: SVGProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox='0 0 12 13'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M6 11.7941C8.64 11.7941 10.8 9.63414 10.8 6.99414C10.8 4.35414 8.64 2.19414 6 2.19414C3.36 2.19414 1.2 4.35414 1.2 6.99414C1.2 9.63414 3.36 11.7941 6 11.7941ZM6 0.994141C9.3 0.994141 12 3.69414 12 6.99414C12 10.2941 9.3 12.9941 6 12.9941C2.7 12.9941 0 10.2941 0 6.99414C0 3.69414 2.7 0.994141 6 0.994141Z'
				fill={fill}
			/>
			<path
				d='M8.85728 5.93006C8.85728 6.10268 8.79202 6.26935 8.67336 6.38839L5.91458 9.1622C5.78999 9.28125 5.62387 9.35268 5.45182 9.35268C5.27976 9.35268 5.11364 9.28125 4.99499 9.1622L3.61263 7.7753C3.49397 7.65625 3.42871 7.48958 3.42871 7.31696C3.42871 7.14435 3.49991 6.98363 3.61856 6.85863C3.74315 6.73958 3.90334 6.67411 4.07539 6.66815C4.24745 6.66815 4.40763 6.73363 4.53222 6.85268L5.45182 7.7753L7.75377 5.46577C7.87836 5.34673 8.03855 5.28125 8.2106 5.28125C8.38265 5.2872 8.54284 5.35268 8.66743 5.47768C8.78609 5.59673 8.85728 5.75744 8.85728 5.93006Z'
				fill={fill}
			/>
		</svg>
	);
}

export default Check;
