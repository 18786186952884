import { useMemo } from 'react';

import {
	Contract_Write_Mode,
	Transaction_Type,
	Version
} from 'config/constants';
import { selectContractByVersion } from 'config/contracts';
import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { concat, isEmpty } from 'lodash';
import { ClaimCalldata, Type_Earns } from 'pages/Earn/types';
import { useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';
import { txBaseState } from 'state/tx/slice';
import { RecordForClaimAllEarns } from 'types';
import { formatNumber, isPositive, parseUnits, plus } from 'utils';

import { useComputeGasLimit } from './useComputeGasLimit';
import { useCurrentChain } from './useCurrentChain';
import { useSelectRewardConnectorByVersion } from './useSelectByVersion';
import { useSendTransaction } from './useSendTransaction';

export function useSubmitClaimAllEarnsV3(
	quoteTokenCalldatas: Array<ClaimCalldata> | null,
	appTokenCalldatas: Array<string> | null,
	appAmount: string
) {
	const { quoteToken, appToken } = useAppSelector(txBaseState);
	const currentVersion = useAppSelector(selectVersion);

	const { currentChainId } = useCurrentChain();
	const { address } = useAccount();

	const { rewardCollectorInterface, rewardCollectorABI } =
		useSelectRewardConnectorByVersion();

	const { args, record, description } = useMemo(() => {
		if (!address || !appToken || !quoteToken) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		if (
			!appTokenCalldatas &&
			(!quoteTokenCalldatas || quoteTokenCalldatas.length === 0)
		) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		let results = [] as Array<string>;
		let quoteAmount = '0';

		console.log('useSubmitClaimAllEarnsV3 args begin ...');

		if (appTokenCalldatas && appTokenCalldatas?.length > 0) {
			results = concat(results, appTokenCalldatas);
		}

		if (quoteTokenCalldatas && quoteTokenCalldatas.length > 0) {
			quoteTokenCalldatas.forEach((item: ClaimCalldata) => {
				switch (item.type) {
					case Type_Earns.StakingLp:
						results.push(
							rewardCollectorInterface.encodeFunctionData(
								currentVersion === Version.V1
									? 'collectV3PosStakingRewardBatch'
									: 'collectContractsV1V3PosStakingRewardBatch',
								[item.ids]
							)
						);
						quoteAmount = plus(quoteAmount, item.amount);
						return;
					case Type_Earns.Staking:
						results.push(
							rewardCollectorInterface.encodeFunctionData(
								currentVersion === Version.V1
									? 'collectStakingRewardBatch'
									: 'collectContractsV1StakingRewardBatch',
								[item.ids]
							)
						);
						quoteAmount = plus(quoteAmount, item.amount);
						return;
					case Type_Earns.Architect:
						results.push(
							rewardCollectorInterface.encodeFunctionData(
								currentVersion === Version.V1
									? 'collectArchitectRewardBatch'
									: 'collectContractsV1ArchitectRewardBatch',
								[item.ids]
							)
						);
						quoteAmount = plus(quoteAmount, item.amount);
						return;
					default:
						void 0;
				}
			});
		}

		if (isPositive(quoteAmount)) {
			results.push(
				rewardCollectorInterface.encodeFunctionData('sweepToken', [
					quoteToken.address,
					parseUnits(quoteAmount, quoteToken.decimals),
					address
				])
			);
		}

		const record = {
			transactionType: Transaction_Type.ClaimAllEarns,
			quoteAmount,
			appAmount
		} as RecordForClaimAllEarns;

		const description = TransactionDescription.Claim(
			formatNumber(quoteAmount, quoteToken.positionUnits),
			formatNumber(appAmount, appToken.positionUnits)
		);

		return {
			args: [results],
			record,
			description
		};
	}, [
		address,
		quoteTokenCalldatas,
		appTokenCalldatas,
		appToken,
		quoteToken,
		appAmount,
		currentChainId,
		currentVersion,
		rewardCollectorInterface
	]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address: selectContractByVersion(
				currentVersion,
				currentChainId,
				'RewardCollector'
			),
			abi: rewardCollectorABI,
			functionName: 'multicall',
			args
		} as UseContractWriteConfig;
	}, [currentChainId, args, currentVersion, rewardCollectorABI]);

	const { gasLimit } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirmed, isConfirming, error } =
		useSendTransaction(contractWriteArgs, gasLimit);

	const onConfirm = () => {
		if (!record) return;
		onSendTransaction(record, description);
	};

	return {
		onConfirm,
		isConfirming,
		isConfirmed,
		error
	};
}
