import { useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans, t } from '@lingui/macro';
import { FormControlLabel, MenuList, Typography } from '@mui/material';
import { Claim_Type, Transaction_Type, Version } from 'config/constants';
import {
	useFarmClaimMixRequestByPools,
	useFarmClaimV2RequestByPools
} from 'fetch/useRequest';
import { useAccount } from 'wagmi';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useSubmitClaimAllEarnsV3 } from 'hooks/useSubmitClaimAllEarnsV3';
import ClaimPeriods from 'pages/components/ClaimPeriods';
import { StyledFormControlLabel } from 'pages/components/StyledFormControlLabel';
import { selectClaimableRBFAmountV3 } from 'state/earn/RBF/selector';
import {
	selectClaimArchitectCalldatas,
	selectClaimableArchitectAmount
} from 'state/earn/architect/selector';
import { selectclaimableLiquidityAmount } from 'state/earn/liquidity/selector';
import {
	selectClaimLpStakeCalldatas,
	selectClaimableLpStakeQuoteAmount
} from 'state/earn/pool2/selector';
import { earnPool2BaseState } from 'state/earn/pool2/slice';
import { selectClaimablePositionAmount } from 'state/earn/position/selector';
import {
	selectClaimStakeCalldatas,
	selectClaimableStakeAmount
} from 'state/earn/stake/selector';
import { useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';
import { txBaseState } from 'state/tx/slice';
import {
	formatClaimType,
	formatNumber,
	isEqualTo,
	isNumeric,
	isPositive,
	plus,
	shortenSymbol
} from 'utils';

import ApproveButton from 'components/ApproveButton';
import ApproveButtonV2 from 'components/ApproveButtonV2';
import Dialog, { DialogProps } from 'components/Common/Dialog';
import { StyleCheckbox } from 'components/Common/StyleCheckbox';
import { CommonStyledDivider } from 'components/Common/Styled';

import EmptyClaim from './EmptyClaim';
import { ClaimCalldata, Lockup_Period, Reward_Type } from './types';

export default function ClaimAllDialogV2(props: DialogProps) {
	const { onClose } = props;

	const { quoteToken, appToken, signingMap } = useAppSelector(txBaseState);
	const currentVersion = useAppSelector(selectVersion);

	const claimStakeCalldatas = useAppSelector(selectClaimStakeCalldatas);
	const claimLpStakeCalldatas = useAppSelector(selectClaimLpStakeCalldatas);
	const claimArchitectCalldatas = useAppSelector(selectClaimArchitectCalldatas);

	const claimableStakeAmount = useAppSelector(selectClaimableStakeAmount);
	const claimablePositionAmount = useAppSelector(selectClaimablePositionAmount);

	const { claimableAppAmount: claimablePool2AppAmount } =
		useAppSelector(earnPool2BaseState);
	const claimablePool2QuoteAmount = useAppSelector(
		selectClaimableLpStakeQuoteAmount
	);

	const claimableLiquidityAmount = useAppSelector(
		selectclaimableLiquidityAmount
	);
	const claimableRBFAmount = useAppSelector(selectClaimableRBFAmountV3);
	const claimableArchitectAmount = useAppSelector(
		selectClaimableArchitectAmount
	);

	const [selectedStaking, setSelectedStaking] = useState<boolean>(false);
	const [selectedStakingLp, setSelectedStakingLp] = useState<boolean>(false);
	const [selectedPosition, setSelectedPosition] = useState<boolean>(false);
	const [selectedPool2, setSelectedPool2] = useState<boolean>(false);
	const [selectedLiquidity, setSelectedLiquidity] = useState<boolean>(false);
	const [selectedRBF, setSelectedRBF] = useState<boolean>(false);
	const [selectedArchitect, setSelectedArchitect] = useState<boolean>(false);
	const [period, setPeriod] = useState<Lockup_Period>(Lockup_Period.HIGH);

	const { isMatchMobile, isMatchPad } = useAppBreakpoints();
	const { address } = useAccount();

	const claimableCount = useMemo(() => {
		let count = 0;
		if (isPositive(claimableStakeAmount)) {
			count++;
		}
		if (isPositive(claimablePositionAmount)) {
			count++;
		}
		if (isPositive(claimablePool2QuoteAmount)) {
			count++;
		}
		if (isPositive(claimablePool2AppAmount)) {
			count++;
		}
		if (isPositive(claimableLiquidityAmount)) {
			count++;
		}
		if (isPositive(claimableRBFAmount)) {
			count++;
		}
		if (isPositive(claimableArchitectAmount)) {
			count++;
		}
		return count;
	}, [
		claimableStakeAmount,
		claimablePositionAmount,
		claimablePool2AppAmount,
		claimablePool2QuoteAmount,
		claimableLiquidityAmount,
		claimableRBFAmount,
		claimableArchitectAmount
	]);

	const {
		quoteTokenCalldatas,
		selectedCount,
		quoteAmount,
		appAmount,
		rewardTypes
	} = useMemo(() => {
		let selectedCount = 0;
		let appAmount = '0';
		let quoteAmount = '0';
		let results = [] as Array<ClaimCalldata>;
		let rewardTypes = [];

		if (selectedStaking && claimStakeCalldatas) {
			results = results.concat(claimStakeCalldatas);
			selectedCount++;
			quoteAmount = plus(quoteAmount, claimableStakeAmount);
		}
		if (selectedPosition && isPositive(claimablePositionAmount)) {
			rewardTypes = rewardTypes.concat(
				currentVersion === Version.V2
					? Reward_Type.V2_POSITION
					: Reward_Type.POSITION
			);
			selectedCount++;
			appAmount = plus(appAmount, claimablePositionAmount);
		}
		if (selectedStakingLp && claimLpStakeCalldatas) {
			results = results.concat(claimLpStakeCalldatas);
			selectedCount++;
			quoteAmount = plus(quoteAmount, claimablePool2QuoteAmount);
		}
		if (selectedPool2 && isPositive(claimablePool2AppAmount)) {
			rewardTypes = rewardTypes.concat(Reward_Type.POOL2);
			selectedCount++;
			appAmount = plus(appAmount, claimablePool2AppAmount);
		}
		if (selectedLiquidity && isPositive(claimableLiquidityAmount)) {
			rewardTypes = rewardTypes.concat(
				currentVersion === Version.V2
					? Reward_Type.V2_LIQUIDITY
					: Reward_Type.LIQUIDITY
			);
			selectedCount++;
			appAmount = plus(appAmount, claimableLiquidityAmount);
		}
		if (selectedRBF && isPositive(claimableRBFAmount)) {
			rewardTypes = rewardTypes.concat(Reward_Type.RBF);
			selectedCount++;
			appAmount = plus(appAmount, claimableRBFAmount);
		}
		if (selectedArchitect && claimArchitectCalldatas) {
			results = results.concat(claimArchitectCalldatas);
			selectedCount++;
			quoteAmount = plus(quoteAmount, claimableArchitectAmount);
		}

		return {
			quoteTokenCalldatas: results,
			selectedCount,
			quoteAmount,
			appAmount,
			rewardTypes
		};
	}, [
		selectedStaking,
		selectedPosition,
		selectedStakingLp,
		selectedPool2,
		selectedLiquidity,
		selectedRBF,
		selectedArchitect,
		claimStakeCalldatas,
		claimLpStakeCalldatas,
		claimablePositionAmount,
		claimablePool2QuoteAmount,
		claimablePool2AppAmount,
		claimArchitectCalldatas,
		claimableStakeAmount,
		claimableLiquidityAmount,
		claimableRBFAmount,
		claimableArchitectAmount,
		currentVersion
	]);

	const selectedAll = useMemo(() => {
		if (isEqualTo(selectedCount, claimableCount)) {
			return true;
		}
		return false;
	}, [selectedCount, claimableCount]);

	const { data: appTokenCalldatas, isLoading: isLoadingAppTokenCalldatas } =
		useFarmClaimMixRequestByPools(
			address,
			null,
			rewardTypes,
			period,
			currentVersion === Version.V1 ? '1' : '2'
		);

	const { onConfirm, isConfirmed, isConfirming } = useSubmitClaimAllEarnsV3(
		quoteTokenCalldatas,
		appTokenCalldatas,
		appAmount
	);

	useUpdateEffect(() => {
		if (isConfirmed) {
			onClose();
		}
	}, [isConfirmed]);

	const [disabled, submitText] = useMemo(() => {
		if (!isPositive(quoteAmount) && !isPositive(appAmount)) {
			return [true, t`Claim`];
		}
		if (isPositive(appAmount) && !isNumeric(period)) {
			return [true, t`Claim`];
		}
		if (isLoadingAppTokenCalldatas) {
			return [true, t`Claim`];
		}
		if (signingMap.has(Transaction_Type.ClaimAllEarns)) {
			return [true, t`Loading...`];
		}
		if (isConfirming) {
			return [true, t`Submitting...`];
		}
		return [false, t`Claim`];
	}, [
		quoteAmount,
		appAmount,
		isConfirming,
		signingMap,
		isLoadingAppTokenCalldatas,
		period
	]);

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setSelectedStaking(true);
			setSelectedPosition(true);
			setSelectedStakingLp(true);
			setSelectedPool2(true);
			setSelectedLiquidity(true);
			setSelectedRBF(true);
			setSelectedArchitect(true);
		} else {
			setSelectedStaking(false);
			setSelectedPosition(false);
			setSelectedStakingLp(false);
			setSelectedPool2(false);
			setSelectedLiquidity(false);
			setSelectedRBF(false);
			setSelectedArchitect(false);
		}
	};

	return (
		<Dialog
			size='large'
			height={isMatchMobile || isMatchPad ? 300 : 'auto'}
			title={t`Claim All`}
			open={true}
			onClose={onClose}
		>
			{claimableCount ? (
				<div className='space-y-4'>
					<section className='space-y-1'>
						<div className='flex'>
							<FormControlLabel
								control={
									<StyleCheckbox
										checked={selectedAll}
										size='small'
										onChange={handleSelectAllClick}
									/>
								}
								label={
									<div className='flex items-center justify-between'>
										<Typography variant='body2' color='secondary'>
											<Trans>Rewards Source</Trans>
										</Typography>
										<Typography
											className='flex-1'
											variant='body2'
											color='secondary'
											align='right'
										>
											<Trans>Claimable Rewards</Trans>
										</Typography>
									</div>
								}
								sx={{
									width: 'calc(100% + 11px)',
									marginRight: 0,
									'& .MuiFormControlLabel-label': {
										flex: 1
									}
								}}
							/>
						</div>
						<MenuList>
							{claimStakeCalldatas && (
								<StyledFormControlLabel
									control={
										<StyleCheckbox
											checked={selectedStaking}
											size='small'
											onChange={event =>
												setSelectedStaking(event.target.checked)
											}
										/>
									}
									label={
										<div className='w-full flex items-center justify-between'>
											<Typography variant='body2' color='text.primary'>
												{formatClaimType(Claim_Type.Staking)}
											</Typography>
											<Typography variant='body2' color='text.primary'>
												{`${formatNumber(
													claimableStakeAmount,
													quoteToken.positionUnits
												)} ${shortenSymbol(quoteToken)}`}
											</Typography>
										</div>
									}
								/>
							)}
							{isPositive(claimablePool2QuoteAmount) && (
								<StyledFormControlLabel
									control={
										<StyleCheckbox
											checked={selectedStakingLp}
											size='small'
											onChange={event =>
												setSelectedStakingLp(event.target.checked)
											}
										/>
									}
									label={
										<div className='w-full flex items-center justify-between'>
											<Typography variant='body2' color='text.primary'>
												{formatClaimType(Claim_Type.StakingLp)}
											</Typography>
											<Typography variant='body2' color='text.primary'>
												{`${formatNumber(
													claimablePool2QuoteAmount,
													quoteToken.positionUnits
												)} ${shortenSymbol(quoteToken)}`}
											</Typography>
										</div>
									}
								/>
							)}
							{isPositive(claimablePool2AppAmount) && (
								<StyledFormControlLabel
									control={
										<StyleCheckbox
											checked={selectedPool2}
											size='small'
											onChange={event => setSelectedPool2(event.target.checked)}
										/>
									}
									label={
										<div className='w-full flex items-center justify-between'>
											<Typography variant='body2' color='text.primary'>
												{formatClaimType(Claim_Type.Pool2)}
											</Typography>
											<Typography variant='body2' color='text.primary'>
												{`${formatNumber(
													claimablePool2AppAmount,
													appToken.positionUnits
												)} ${shortenSymbol(appToken)}`}
											</Typography>
										</div>
									}
								/>
							)}
							{isPositive(claimablePositionAmount) && (
								<StyledFormControlLabel
									control={
										<StyleCheckbox
											checked={selectedPosition}
											size='small'
											onChange={event =>
												setSelectedPosition(event.target.checked)
											}
										/>
									}
									label={
										<div className='w-full flex items-center justify-between'>
											<Typography variant='body2' color='text.primary'>
												{formatClaimType(
													currentVersion === Version.V2
														? Claim_Type.PositionV2
														: Claim_Type.Position
												)}
											</Typography>
											<Typography variant='body2' color='text.primary'>
												{`${formatNumber(
													claimablePositionAmount,
													appToken.positionUnits
												)} ${shortenSymbol(appToken)}`}
											</Typography>
										</div>
									}
								/>
							)}
							{isPositive(claimableLiquidityAmount) && (
								<StyledFormControlLabel
									control={
										<StyleCheckbox
											checked={selectedLiquidity}
											size='small'
											onChange={event =>
												setSelectedLiquidity(event.target.checked)
											}
										/>
									}
									label={
										<div className='w-full flex items-center justify-between'>
											<Typography variant='body2' color='text.primary'>
												{formatClaimType(
													currentVersion === Version.V2
														? Claim_Type.LiquidityV2
														: Claim_Type.Liquidity
												)}
											</Typography>
											<Typography variant='body2' color='text.primary'>
												{`${formatNumber(
													claimableLiquidityAmount,
													appToken.positionUnits
												)} ${shortenSymbol(appToken)}`}
											</Typography>
										</div>
									}
								/>
							)}
							{isPositive(claimableRBFAmount) && (
								<StyledFormControlLabel
									control={
										<StyleCheckbox
											checked={selectedRBF}
											size='small'
											onChange={event => setSelectedRBF(event.target.checked)}
										/>
									}
									label={
										<div className='w-full flex items-center justify-between'>
											<Typography variant='body2' color='text.primary'>
												{formatClaimType(Claim_Type.RiskBufferFund)}
											</Typography>
											<Typography variant='body2' color='text.primary'>
												{`${formatNumber(
													claimableRBFAmount,
													appToken.positionUnits
												)} ${shortenSymbol(appToken)}`}
											</Typography>
										</div>
									}
								/>
							)}
							{claimArchitectCalldatas && (
								<StyledFormControlLabel
									control={
										<StyleCheckbox
											checked={selectedArchitect}
											size='small'
											onChange={event =>
												setSelectedArchitect(event.target.checked)
											}
										/>
									}
									label={
										<div className='w-full flex items-center justify-between'>
											<Typography variant='body2' color='text.primary'>
												{formatClaimType(Claim_Type.Architect)}
											</Typography>
											<Typography variant='body2' color='text.primary'>
												{`${formatNumber(
													claimableArchitectAmount,
													quoteToken.positionUnits
												)} ${shortenSymbol(quoteToken)}`}
											</Typography>
										</div>
									}
								/>
							)}
						</MenuList>
					</section>
					<CommonStyledDivider />
					<ClaimPeriods
						quoteAmount={quoteAmount}
						totalAmount={appAmount}
						period={period}
						setPeriod={setPeriod}
					/>
					<section>
						{currentVersion === Version.V2 ? (
							<ApproveButtonV2
								componentVariant='confirm'
								fullWidth
								onClick={onConfirm}
								disabled={disabled}
								isApproveRewardCollector={isPositive(appAmount)}
								isApproveRewardCollectorToV1={isPositive(quoteAmount)}
							>
								{submitText}
							</ApproveButtonV2>
						) : (
							<ApproveButton
								componentVariant='confirm'
								fullWidth
								onClick={onConfirm}
								disabled={disabled}
								isApproveRewardCollector
							>
								{submitText}
							</ApproveButton>
						)}
					</section>
				</div>
			) : (
				<EmptyClaim />
			)}
		</Dialog>
	);
}
