import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Page, Version } from 'config/constants';

import { useAppSelector } from 'state/hooks';
import { settingBaseState } from 'state/setting/slice';

const excludesPaths = [
	Page.EarnAbf,
	Page.EarnAbfV1,
	Page.Leaderboard,
	Page.LeaderboardV1,
	Page.ReferralsLeaderboard,
	Page.ReferralsLeaderboardV1
];

export default function useRouterRedirect() {
	const navigate = useNavigate();
	const location = useLocation();
	const { currentVersion } = useAppSelector(settingBaseState);

	useEffect(() => {
		if (excludesPaths.some(path => location.pathname.startsWith(path))) {
			if (
				currentVersion === Version.V2 &&
				location.pathname.startsWith(Page.EarnAbfV1)
			) {
				navigate(Page.EarnStaking);
			}
			if (
				currentVersion === Version.V2 &&
				location.pathname.startsWith(Page.ReferralsLeaderboardV1)
			) {
				navigate(Page.ReferralsTraders);
			}
			if (
				currentVersion === Version.V2 &&
				location.pathname.startsWith(Page.LeaderboardV1)
			) {
				navigate(Page.Trade);
			}
			return;
		}
		if (currentVersion === Version.V1 && !location.pathname.startsWith('/v1')) {
			navigate(`/v1${location.pathname}`);
		} else if (
			currentVersion === Version.V2 &&
			location.pathname.startsWith('/v1')
		) {
			navigate(`${location.pathname.replace('/v1', '')}`);
		}
	}, [currentVersion, location.pathname]);
}
