import React, { useContext, useMemo, useState } from 'react';
import { useList } from 'react-use';

import { Trans, t } from '@lingui/macro';
import AddIcon from '@mui/icons-material/Add';
import {
	Button,
	Card,
	CardContent,
	IconButton,
	Typography,
	useTheme
} from '@mui/material';
import {
	MAX_PRECISION,
	QUOTE_USD,
	QUOTE_USD_PRECISION
} from 'config/constants';

import { useCurrentChain } from 'hooks/useCurrentChain';
import {
	checkInputNumberic,
	div,
	formatNumber,
	isGreaterThan,
	isPositive,
	isZero,
	minus,
	multipliedBy,
	plus,
	shortenSymbolNative
} from 'utils';

import Cell from 'components/Common/Cell';
import {
	CommonStyledDivider,
	CommonStyledFilledInput,
	CommonStyledOutlinedButton
} from 'components/Common/Styled';
import CommonButton from 'components/Common/StyledButton';
import Remove from 'components/Svg/Icons/Remove';

import { useAppBreakpoints } from '../../../hooks/useAppBreakpoints';
import { ComputerContext } from './ComputerContext';
import ComputerDirection from './ComputerDirection';

interface IValue {
	entryPrice: string;
	amount: string;
}

const MAX_NUMBERS = 10;

export default React.memo(function ComputerEntryPrice() {
	const { currentPool } = useContext(ComputerContext);
	const theme = useTheme();
	const { currentChainId } = useCurrentChain();

	const [targetPrice, setTargetPrice] = useState<string>('');

	const [list, { push, updateAt, removeAt }] = useList<IValue>([
		{
			entryPrice: '',
			amount: ''
		} as IValue
	]);

	const disabled = useMemo(() => {
		let disabled = false;
		list.forEach((item: IValue) => {
			if (!isPositive(item.entryPrice) || !isPositive(item.amount)) {
				disabled = true;
			}
		});
		return disabled;
	}, [list]);

	const onChangeEntryPrice = (event, item: IValue, index: number) => {
		const value = event.target.value;
		if (value.trim() === '') {
			updateAt(index, {
				...item,
				entryPrice: ''
			});
		}
		if (checkInputNumberic(value, QUOTE_USD_PRECISION)) {
			updateAt(index, {
				...item,
				entryPrice: value
			});
		}
	};

	const onChangeAmount = (event, item: IValue, index: number) => {
		const value = event.target.value;
		if (value.trim() === '') {
			updateAt(index, {
				...item,
				amount: ''
			});
		}
		if (checkInputNumberic(value, currentPool?.baseToken.decimals)) {
			updateAt(index, {
				...item,
				amount: value
			});
		}
	};

	const onHandleConfirm = () => {
		const totalValue = list.reduce(
			(pre, cur) => plus(pre, multipliedBy(cur.entryPrice, cur.amount)),
			'0'
		);
		const totalSize = list.reduce((pre, cur) => plus(pre, cur.amount), '0');
		setTargetPrice(isZero(totalSize) ? '0' : div(totalValue, totalSize));
	};

	const onAdd = () => {
		push({
			entryPrice: '',
			amount: ''
		});
	};

	const { isMatchMobile } = useAppBreakpoints();

	return (
		<article className='space-y-4'>
			<section className='flex flex-row sm:flex-col-reverse gap-4'>
				<div className='flex-1 sm:w-full'>
					<ComputerDirection />
				</div>
				<Card className='flex-1'>
					<CardContent className='space-y-4'>
						<Typography variant='h6'>
							<Trans>Result</Trans>
						</Typography>
						<CommonStyledDivider />
						<div className='space-y-2'>
							<Cell
								label={<Trans>Entry price</Trans>}
								value={
									<Typography variant='body2' noWrap>
										{formatNumber(targetPrice)}
									</Typography>
								}
							/>
						</div>
					</CardContent>
				</Card>
			</section>
			<CommonStyledDivider />
			<div className='space-y-2 min-h-[218px] overflow-auto'>
				<div className='flex items-center space-x-4'>
					{!isMatchMobile && (
						<Typography
							className='flex-1 text-center'
							variant='body2'
							color='text.secondary'
							fontWeight={400}
						>
							<Trans>Open</Trans>
						</Typography>
					)}
					<Typography
						color='text.secondary'
						variant='body2'
						fontWeight={400}
						className='flex-5'
					>
						<Trans>Entry price</Trans>
					</Typography>
					<Typography
						color='text.secondary'
						variant='body2'
						fontWeight={400}
						className='flex-5'
					>
						<Trans>Size</Trans>
					</Typography>
					<Typography
						color='text.secondary'
						className='flex-2 text-center'
						variant='body2'
						fontWeight={400}
					>
						<Trans>Operation</Trans>
					</Typography>
				</div>
				{list.map((item: IValue, index) => (
					<div className='flex items-center space-x-4' key={index}>
						{!isMatchMobile && (
							<Typography className='flex-1 text-center' variant='body2'>
								{index + 1}
							</Typography>
						)}
						<CommonStyledFilledInput
							className='flex-5'
							type='text'
							inputProps={{
								maxLength: MAX_PRECISION,
								inputMode: 'decimal'
							}}
							sx={{
								'& .MuiInputBase-input': {
									textAlign: 'left',
									fontSize: isMatchMobile ? '12px' : '14px',
									paddingRight: '8px'
								}
							}}
							placeholder={t`Entry price`}
							value={item.entryPrice}
							onChange={event => onChangeEntryPrice(event, item, index)}
							fullWidth
							endAdornment={
								<Typography variant='body2' color='secondary'>
									{QUOTE_USD}
								</Typography>
							}
						/>
						<CommonStyledFilledInput
							className='flex-5'
							type='text'
							inputProps={{
								maxLength: MAX_PRECISION,
								inputMode: 'decimal'
							}}
							sx={{
								'& .MuiInputBase-input': {
									textAlign: 'left',
									fontSize: isMatchMobile ? '12px' : '14px',
									paddingRight: '8px'
								}
							}}
							placeholder={t`Size`}
							value={item.amount}
							onChange={event => onChangeAmount(event, item, index)}
							fullWidth
							endAdornment={
								<Typography variant='body2' color='secondary'>
									{shortenSymbolNative(currentPool?.baseToken, currentChainId)}
								</Typography>
							}
						/>
						<div className='flex-2 text-center'>
							<IconButton
								onClick={() => removeAt(index)}
								disabled={list.length === 1}
								sx={{
									opacity: list.length === 1 ? 0.5 : 1
								}}
							>
								<Remove className='flex-1 rounded-none cursor-pointer' />
							</IconButton>
						</div>
					</div>
				))}
				<Button
					variant='text'
					sx={{ color: theme.palette.text.primary }}
					color='secondary'
					className='px-2'
					startIcon={<AddIcon className='text-base' />}
					onClick={onAdd}
					disabled={list.length >= MAX_NUMBERS}
				>
					<Trans>Add</Trans>
				</Button>
			</div>
			<CommonButton
				className='flex flex-col justify-center'
				variant='contained'
				fullWidth
				disabled={disabled}
				onClick={onHandleConfirm}
			>
				<Typography
					className='whitespace-normal text-sm'
					color='inherit'
					variant='h6'
					fontWeight={500}
				>
					<Trans>Calculate</Trans>
				</Typography>
			</CommonButton>
		</article>
	);
});
