import React, { ReactNode } from 'react';

import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { Typography } from '@mui/material';

export default React.memo(function ToNext({
	value,
	nextValue,
	hasNext
}: {
	value: ReactNode;
	hasNext: boolean;
	nextValue?: ReactNode;
}) {
	return (
		<div className='flex items-center space-x-1'>
			<Typography variant='body2'>
				{value}
			</Typography>
			{hasNext && (
				<>
					<TrendingFlatIcon fontSize='inherit' color='secondary' />
					<Typography variant='body2' color='inherit'>
						{nextValue}
					</Typography>
				</>
			)}
		</div>
	);
});
