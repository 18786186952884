import { useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { Box, FormControlLabel, Typography, useTheme } from '@mui/material';
import moment from 'moment';

import { useAppDispatch } from 'state/hooks';
import { setTakeProfitStopLossTis } from 'state/setting/slice';

import { StyleCheckbox } from 'components/Common/StyleCheckbox';
import TextWarning from 'components/TextWarning';

import Dialog from '../../components/Common/Dialog';

const TipsDialog = ({
	onClose,
	onConfirm
}: {
	onClose: () => void;
	onConfirm: () => void;
}) => {
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const [checked, setChecked] = useState(false);
	const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};
	const onDialogConfirm = () => {
		if (checked) {
			dispatch(setTakeProfitStopLossTis(moment().valueOf()));
		} else {
			dispatch(setTakeProfitStopLossTis(null));
		}
		onConfirm();
	};

	return (
		<Dialog
			onConfirm={onDialogConfirm}
			open
			onClose={onClose}
			title={t`Caution: Read This Before Using`}
			confirmLabel={t`Got it`}
		>
			<Box color={theme.palette.text.secondary}>
				<Typography variant='body2'>
					<Trans>Take Profit / Stop Loss</Trans>
				</Typography>
				<Typography variant='body2' className='mt-1'>
					<Trans>
						TP/SL refers to the TP/SL of the entire/partial position. When the
						latest{' '}
						<Typography
							className='inline'
							variant='body2'
							color={theme.custom.orangeColor}
						>
							market price
						</Typography>{' '}
						reaches the trigger price, the system will place a TP/SL order for
						the entire/partial position at the{' '}
						<Typography
							className='inline'
							variant='body2'
							color={theme.custom.orangeColor}
						>
							market price
						</Typography>
						.
					</Trans>
				</Typography>

				<Box className='mt-6'>
					<TextWarning className='p-3'>
						<Trans>
							During rapid market fluctuations, such as upswings followed by
							quick downturns, Take Profit orders may initially be triggered but
							then fail to meet the execution conditions due to the
							fast-changing prices. In such scenarios, these orders will not be
							executed and will remain active, awaiting the next opportunity to
							trigger.
						</Trans>
					</TextWarning>
					<TextWarning className='p-3 pt-0'>
						<Trans>
							Due to the different triggering mechanisms for Stop Loss and
							liquidation (Stop Loss is triggered based on market price, while
							liquidation is triggered based on index price), if the Stop Loss
							price is set too close to the liquidation price, there is a risk
							that liquidation will occur before the Stop Loss is triggered or
							executed.
						</Trans>
					</TextWarning>
				</Box>
				<Box className='flex items-center mt-2'>
					<FormControlLabel
						control={
							<StyleCheckbox
								onChange={onHandleChange}
								size='small'
								checked={checked}
							/>
						}
						label={<Trans>Do not show again within 24 hours</Trans>}
						sx={{ fontSize: 12 }}
					/>
				</Box>
			</Box>
		</Dialog>
	);
};

export default TipsDialog;
