import React from 'react';

import { SvgProps } from '../types';

const Notification: React.FC<React.PropsWithChildren<SvgProps>> = props => {
	const { fontSize } = props;
	return (
		<svg
			width={ fontSize === 'small' ? 18 : 24 }
			height={ fontSize === 'small' ? 18 : 24 }
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_19948_44727)'>
				<path opacity='0.01' d='M24 0H0V24H24V0Z' fill='#202425' />
				<path
					d='M9.63358 2.79669C8.22151 3.28859 6.99752 4.20774 6.1314 5.42665C5.26527 6.64555 4.79995 8.10379 4.79998 9.59909V15.2471C3.43438 15.4983 2.39998 16.6951 2.39998 18.1327C2.39998 18.2799 2.51998 18.3991 2.66638 18.3991H21.3336C21.4042 18.3991 21.472 18.371 21.5219 18.3211C21.5719 18.2711 21.6 18.2033 21.6 18.1327C21.6 17.4471 21.36 16.7831 20.9216 16.256C20.4832 15.7289 19.8741 15.3719 19.2 15.2471V9.59909C19.2 8.10379 18.7347 6.64555 17.8686 5.42665C17.0024 4.20774 15.7784 3.28859 14.3664 2.79669C14.2718 2.23749 13.9823 1.72985 13.5491 1.36378C13.1159 0.997716 12.5671 0.796875 12 0.796875C11.4328 0.796875 10.884 0.997716 10.4509 1.36378C10.0177 1.72985 9.72814 2.23749 9.63358 2.79669Z'
					fill='#FF7B1B'
				/>
				<path
					opacity='0.6'
					d='M16 19.5071C15.9785 19.4203 15.9286 19.3431 15.8581 19.288C15.7876 19.2329 15.7007 19.203 15.6112 19.2031H8.3888C8.29933 19.203 8.21241 19.2329 8.14193 19.288C8.07144 19.3431 8.02147 19.4203 8 19.5071C8.07842 20.5138 8.534 21.4538 9.27563 22.1391C10.0173 22.8243 10.9902 23.2044 12 23.2031C13.0098 23.2044 13.9827 22.8243 14.7244 22.1391C15.466 21.4538 15.9216 20.5138 16 19.5071Z'
					fill='#FF7B1B'
				/>
			</g>
			<defs>
				<clipPath id='clip0_19948_44727'>
					<rect
						width='24'
						height='24'
						fill='white'
						transform='matrix(-1 0 0 1 24 0)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default Notification;
