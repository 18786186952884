import { useContext, useMemo } from 'react';

import Decimal from 'decimal.js';

import { poolsBaseState } from 'state/pools/slice';

import {
	DEFAULT_QUOTE_PRECISION,
	Input_Direction,
	Side,
	Transaction_Type
} from '../config/constants';
import { TradeContext } from '../context/TradeContext';
import { PositionUtil } from '../entities/PositionUtil';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import {
	selectCurrentMarginDelta,
	selectCurrentPositionAmount
} from '../state/market/selector';
import {
	marketBaseState,
	setDirectionForLong,
	setDirectionForShort,
	setMarginDeltaForLong,
	setMarginDeltaForShort
} from '../state/market/slice';
import { setTradeLeverage, settingBaseState } from '../state/setting/slice';
import { txBaseState } from '../state/tx/slice';
import {
	div,
	isLessThan,
	isLessThanOrEqualTo,
	isPositive,
	minus,
	multipliedBy,
	plus,
	toDecimalPlaces
} from '../utils';
import { useIndexPriceForTrade } from './useIndexPriceForTrade';

const useDangerPosition = (
	onSetValuesByNoneLeverage: (pay: string, size: string) => void,
	onCalculateValuesByNoneLeverage: (
		pay: string,
		size: string
	) => {
		tradingFee: string;
		netMargin: string;
		leverage: string;
	}
) => {
	const currentPositionAmount = useAppSelector(selectCurrentPositionAmount);
	const currentMarginDelta = useAppSelector(selectCurrentMarginDelta);
	const { poolInfo } = useAppSelector(poolsBaseState);

	const { entryPrice } = useAppSelector(marketBaseState);
	const { transactionType } = useAppSelector(txBaseState);
	const dispatch = useAppDispatch();

	const side =
		transactionType === Transaction_Type.Long ||
		transactionType === Transaction_Type.LongV2
			? Side.LONG
			: Side.SHORT;

	const { indexPrice } = useIndexPriceForTrade(side);

	const { positionInfo } = useContext(TradeContext);

	// 开仓后的保证金
	const marginAfter = useMemo(() => {
		if (!isPositive(currentMarginDelta)) {
			return '';
		}
		if (!positionInfo) {
			return currentMarginDelta;
		}
		return plus(currentMarginDelta, positionInfo.netMargin);
	}, [positionInfo, currentMarginDelta]);

	// 开仓操作后的浮亏
	const unrealizedPnL = useMemo(() => {
		if (!entryPrice || !indexPrice || !isPositive(currentPositionAmount)) {
			return '0';
		}
		const currentLoss = PositionUtil.calculateUnrealizedPnL(
			side,
			currentPositionAmount,
			entryPrice,
			indexPrice
		);
		if (!positionInfo) {
			return currentLoss;
		}
		const positionLoss = PositionUtil.calculateUnrealizedPnL(
			positionInfo.side,
			positionInfo.size,
			positionInfo.entryPrice,
			indexPrice
		);
		return plus(currentLoss, positionLoss);
	}, [side, currentPositionAmount, entryPrice, positionInfo, indexPrice]);

	// 开仓后的维持保证金
	const maintenanceMargin = useMemo(() => {
		if (
			!poolInfo ||
			!entryPrice ||
			!indexPrice ||
			!isPositive(currentPositionAmount)
		) {
			return '0';
		}

		const _liquidationExecutionFee = poolInfo.liquidationExecutionFee;
		if (!positionInfo) {
			// 开仓时维持保证金 = (开仓价值 * 清算费率）+ (仓位价值 * 交易费率）+ 清算执行费；
			const maintenanceMargin = PositionUtil.calculateMaintenanceMargin(
				entryPrice,
				currentPositionAmount,
				indexPrice,
				poolInfo.liquidationFeeRatePerPosition,
				poolInfo.discountedTradingFeeRate,
				_liquidationExecutionFee
			);
			// console.log('maintenanceMargin: ', maintenanceMargin);
			return maintenanceMargin;
		}
		if (!isPositive(currentPositionAmount)) {
			return '0';
		}
		// 加仓时维持保证金 =（当前仓位价值 + 新开仓价值）* 清算费率 + (当前仓位价值 + 加仓部分的仓位价值）* 交易费率 + 清算执行费
		const totalSize = plus(currentPositionAmount, positionInfo.size);
		const totalLiquidity = plus(
			multipliedBy(entryPrice, currentPositionAmount),
			positionInfo.liquidity
		);

		const averagePrice = div(totalLiquidity, totalSize);
		const maintenanceMargin = PositionUtil.calculateMaintenanceMargin(
			averagePrice,
			totalSize,
			indexPrice,
			poolInfo.liquidationFeeRatePerPosition,
			poolInfo.discountedTradingFeeRate,
			_liquidationExecutionFee
		);
		// console.log('maintenanceMargin: ', maintenanceMargin);

		return maintenanceMargin;
	}, [poolInfo, positionInfo, currentPositionAmount, entryPrice, indexPrice]);

	const dangerInfo = useMemo(() => {
		if (!poolInfo || !isPositive(entryPrice)) {
			return { isDangerPosition: false, value: null };
		}
		if (
			!poolInfo ||
			!isPositive(currentPositionAmount) ||
			!isPositive(currentMarginDelta) ||
			!isPositive(marginAfter) ||
			isLessThan(currentMarginDelta, poolInfo.minMarginPerPosition)
		) {
			return { isDangerPosition: false, value: null };
		}

		const _liquidityDelta = multipliedBy(
			currentPositionAmount,
			entryPrice || '0'
		);

		const _tradingFee = multipliedBy(
			_liquidityDelta,
			poolInfo.discountedTradingFeeRate
		);
		const _netMargin = plus(minus(marginAfter, _tradingFee), unrealizedPnL);
		const isDangerPosition = isLessThanOrEqualTo(_netMargin, maintenanceMargin);

		// 维持保证金不足，需要增加保证金
		let pay = currentMarginDelta;

		if (isDangerPosition) {
			pay = plus(
				div(
					minus(maintenanceMargin, _netMargin),
					poolInfo.maxRiskRatePerLiquidityPosition
				),
				currentMarginDelta
			);
		}

		const value = toDecimalPlaces(
			pay,
			DEFAULT_QUOTE_PRECISION,
			Decimal.ROUND_CEIL
		);

		return {
			isDangerPosition,
			value
		};
	}, [
		entryPrice,
		currentPositionAmount,
		currentMarginDelta,
		poolInfo,
		marginAfter,
		unrealizedPnL,
		maintenanceMargin
	]);

	const { checkedTradeLeverage } = useAppSelector(settingBaseState);

	const fixDanger = () => {
		if (!poolInfo || !dangerInfo || !dangerInfo.value || !entryPrice) {
			return;
		}

		const { leverage } = onCalculateValuesByNoneLeverage(
			dangerInfo.value,
			currentPositionAmount
		);

		if (
			transactionType === Transaction_Type.Long ||
			transactionType === Transaction_Type.LongV2
		) {
			dispatch(setDirectionForLong(Input_Direction.In));
			dispatch(setMarginDeltaForLong(dangerInfo.value));
		} else {
			dispatch(setDirectionForShort(Input_Direction.In));
			dispatch(setMarginDeltaForShort(dangerInfo.value));
		}
		if (checkedTradeLeverage) {
			dispatch(
				setTradeLeverage(toDecimalPlaces(leverage, 0, Decimal.ROUND_DOWN))
			);
		} else {
			onSetValuesByNoneLeverage(dangerInfo.value, currentPositionAmount);
		}
	};

	return { dangerInfo, fixDanger };
};

export default useDangerPosition;
