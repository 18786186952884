import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';

export default function Tooltip(
	props: TooltipProps & { noTooltip?: boolean; placement?: 'bottom' | 'top' }
) {
	const { children, placement = 'top' } = props;
	const { isMatchMobile } = useAppBreakpoints();

	if (props.noTooltip) {
		return children;
	}

	return isMatchMobile ? (
		<MuiTooltip
			enterTouchDelay={0}
			arrow={true}
			placement={placement}
			leaveTouchDelay={5000}
			{...props}
		>
			{children}
		</MuiTooltip>
	) : (
		<MuiTooltip
			enterTouchDelay={0}
			arrow={true}
			placement={placement}
			{...props}
		>
			{children}
		</MuiTooltip>
	);
}
