import { useEffect, useState } from 'react';

import { QUOTE_USD_PRECISION, Side, Version } from 'config/constants';

import { UtilHelper } from 'entities/UtilHelper';
import { useAllTokens } from 'hooks/useAllTokens';
import { useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';
import { tradeBaseState } from 'state/trade/slice';
import {
	IGlobalLiquidityPosition,
	IGlobalPosition,
	IPriceState,
	TokenInfo
} from 'types';
import {
	catchFn,
	div,
	formatRate,
	formatUnits,
	isZero,
	minus,
	multipliedBy,
	neg,
	plus,
	toDecimalPlaces
} from 'utils';

import { usePoolInfoQuery } from './__generated_trade__/types-and-hooks';

export interface IPoolInfo {
	address: string;
	id: string;
	tokenId: string;
	baseToken: TokenInfo;
	price: string;
	// LP仓位的最小开仓保证金
	minMarginPerLiquidityPosition: string;
	// LP仓位最大杠杆倍数
	maxLeveragePerLiquidityPosition: string | number;
	// LP仓位的最大风险率
	maxRiskRatePerLiquidityPosition: string;
	// 用户仓位的最小开仓保证金
	minMarginPerPosition: string;
	// 用户仓位最大杠杆倍数
	maxLeveragePerPosition: number;
	// 维持保证金率
	liquidationFeeRatePerPosition: string;
	// 交易费率
	tradingFeeRate: string;
	// 折扣之后的交易费率
	discountedTradingFeeRate: string;
	// 爆仓手续费
	liquidationExecutionFee: string;
	// 用于计算用户调整仓位时对LP的平衡率影响的流动性最大值
	maxPriceImpactLiquidity: string;
	balanceRate: string;

	globalPosition: IGlobalPosition;
	globalLiquidityPosition: IGlobalLiquidityPosition;

	maxPrice: string;
	maxPriceX96: string;
	minPrice: string;
	transactionFees: string;
	referralDiscountRate: string;
	priceState: IPriceState;
	globalRiskBufferFund: {
		riskBufferFund: string;
		liquidity: string;
	};
}

export function usePoolInfoGraph(poolAddress: string | undefined) {
	const { isBindReferralCode } = useAppSelector(tradeBaseState);
	const currentVersion = useAppSelector(selectVersion);

	const {
		data: _data,
		loading: isLoading,
		refetch
	} = usePoolInfoQuery({
		variables: { id: poolAddress ? poolAddress.toLowerCase() : '' },
		skip: !poolAddress && !(currentVersion === Version.V1)
	});

	const allTokens = useAllTokens();

	const [loading, setLoading] = useState<boolean>(true);
	const [data, setData] = useState<IPoolInfo | null>(null);

	useEffect(() => {
		if (!isLoading) {
			setLoading(isLoading);
		}
	}, [isLoading]);

	useEffect(() => {
		if (!_data || !_data.pool || !_data.priceState) {
			return;
		}

		const {
			id: tokenId,
			price,
			minMarginPerLiquidityPosition,
			maxLeveragePerLiquidityPosition,
			maxRiskRatePerLiquidityPosition,
			minMarginPerPosition,
			maxLeveragePerPosition,
			liquidationFeeRatePerPosition,
			tradingFeeRate,
			referralDiscountRate,
			liquidationExecutionFee,
			maxPriceImpactLiquidity,
			minPrice,
			maxPrice,
			maxPriceX96,
			vertices,
			liquidationVertexIndex
		} = _data.pool.token;

		const {
			premiumRateX96,
			pendingVertexIndex,
			priceVertices,
			liquidationBufferNetSizes
		} = _data.priceState;

		const _priceVertices = priceVertices.map((item, index) => {
			return {
				...item,
				premiumRate: formatRate(vertices[index].premiumRate)
			};
		});

		const priceState = {
			maxPriceImpactLiquidity,
			premiumRateX96,
			pendingVertexIndex,
			currentVertexIndex: UtilHelper.computeCurrentVertexIndex(
				_data.pool.globalLiquidityPosition.netSize,
				_priceVertices
			),
			priceVertices: _priceVertices,
			liquidationBufferNetSizes,
			liquidationVertexIndex
		} as IPriceState;

		const { netSize, liquidationBufferNetSize, side } =
			_data.pool.globalLiquidityPosition;

		const globalPosition = _data.pool
			.globalPosition as unknown as IGlobalPosition;
		const _globalLiquidityPosition = _data.pool.globalLiquidityPosition;

		const globalLiquidityPosition = {
			..._globalLiquidityPosition,
			tokenVertices: vertices
		} as IGlobalLiquidityPosition;

		const balanceRate = catchFn(() => {
			let totalNetSize = plus(netSize, liquidationBufferNetSize);
			totalNetSize = side === Side.LONG ? neg(totalNetSize) : totalNetSize;
			const _globalNetLiquidity = toDecimalPlaces(
				multipliedBy(totalNetSize, price),
				QUOTE_USD_PRECISION
			);
			if (isZero(globalLiquidityPosition.liquidity)) {
				return '0';
			}
			return div(_globalNetLiquidity, globalLiquidityPosition.liquidity);
		}, '0');

		const transactionFees = minus(
			globalLiquidityPosition.tradingFee,
			_data.pastPools?.globalLiquidityPosition.tradingFee || 0
		);

		// 交易费率
		const _tradingFeeRate = formatRate(tradingFeeRate);
		// 折扣率
		const _referralDiscountRate = formatRate(referralDiscountRate);
		// 最终的交易费率
		let discountedTradingFeeRate = _tradingFeeRate;
		if (isBindReferralCode) {
			discountedTradingFeeRate = multipliedBy(
				_tradingFeeRate,
				_referralDiscountRate
			);
		}

		const result = {
			id: _data.pool.id,
			tokenId,
			baseToken: allTokens.get(tokenId),
			price,
			minMarginPerLiquidityPosition: formatUnits(
				minMarginPerLiquidityPosition,
				QUOTE_USD_PRECISION
			),
			maxLeveragePerLiquidityPosition,
			maxRiskRatePerLiquidityPosition: formatRate(
				maxRiskRatePerLiquidityPosition
			),
			minMarginPerPosition: formatUnits(
				minMarginPerPosition,
				QUOTE_USD_PRECISION
			),
			maxLeveragePerPosition,
			liquidationFeeRatePerPosition: formatRate(liquidationFeeRatePerPosition),
			tradingFeeRate: formatRate(tradingFeeRate),
			discountedTradingFeeRate,
			globalPosition,
			liquidationExecutionFee: formatUnits(
				liquidationExecutionFee,
				QUOTE_USD_PRECISION
			),
			balanceRate,

			globalLiquidityPosition,

			minPrice,
			maxPrice,
			maxPriceX96,
			transactionFees,

			priceState,
			referralDiscountRate: formatRate(referralDiscountRate),
			globalRiskBufferFund: _data.pool.globalRiskBufferFund
		} as unknown as IPoolInfo;

		setData(result as IPoolInfo);
	}, [_data, isBindReferralCode]);

	return {
		data,
		loading,
		refetch
	};
}
