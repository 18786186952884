import { useCallback, useMemo, useState } from 'react';

import { BLACK_ADDRESS } from 'config/constants';
import { erc20ABI, useAccount } from 'wagmi';

import { formatUnits } from 'ethers/lib/utils.js';
import { TokenInfo } from 'types';
import { ethers } from 'ethers';
import useWeb3Provider from './useWeb3Provider';
import { useCurrentChain } from './useCurrentChain';
import { useUpdateEffect } from 'react-use';

export function useTokenSupply(token: TokenInfo | null) {
	const Web3Provider = useWeb3Provider();

	const [supply, setSupply] = useState('');

	const addressOrName = useMemo(() => {
		if (!token) {
			return '';
		}
		return !token.isNative ? token.address || '' : '';
	}, [token]);

	const contract = useMemo(() => {
		if (!Web3Provider || !addressOrName) {
			return null;
		}
		return new ethers.Contract(addressOrName, erc20ABI, Web3Provider);
	}, [Web3Provider, addressOrName]);

	const refetch = useCallback(async () => {
		if (!contract || !token) {
			return;
		}
		try {
			const repBalance = await contract.totalSupply();
			setSupply(formatUnits(repBalance, token.decimals));
		} catch (e: any) {
			// setBurnedBalance('');
		}
	}, [contract, token]);

	useUpdateEffect(() => {
		refetch();
	}, [Web3Provider]);

	return {
		totalSupply: supply,
		refetchTotalSupply: refetch
	};
}

export function useTokenBurnedBalance(token: TokenInfo | null) {
	const [burnedBalance, setBurnedBalance] = useState('');
	const Web3Provider = useWeb3Provider();
	const { address } = useAccount();
	const { currentChainId } = useCurrentChain();

	const addressOrName = useMemo(() => {
		if (!token) {
			return '';
		}
		return !token.isNative ? token.address || '' : '';
	}, [token]);

	const contract = useMemo(() => {
		if (!Web3Provider || !addressOrName) {
			return null;
		}
		return new ethers.Contract(addressOrName, erc20ABI, Web3Provider);
	}, [Web3Provider, addressOrName, erc20ABI]);

	const refetchBalance = useCallback(async () => {
		if (!contract || !token) {
			return;
		}
		try {
			const repBalance = await contract.balanceOf(BLACK_ADDRESS);
			setBurnedBalance(formatUnits(repBalance, token.decimals));
		} catch (e: any) {
			// setBurnedBalance('');
		}
	}, [contract, token, address, currentChainId]);

	useUpdateEffect(() => {
		refetchBalance();
	}, [contract]);

	return {
		burnedBalance,
		refetchBurnedBalance: refetchBalance
	};
}
