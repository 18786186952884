import { Env } from 'config/constants';

export function catchFn<T, U>(func: () => T, defaultValue: U): T | U {
	if (import.meta.env.MODE === Env.development) {
		return func();
	}
	try {
		const result = func();
		return result;
	} catch (error: any) {
		console.error(error.message);
		return defaultValue;
	}
}
