import React, { useState } from 'react';

import { Trans } from '@lingui/macro';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
	Box,
	Button,
	Chip,
	List,
	ListItem,
	MenuItem,
	Typography,
	useTheme
} from '@mui/material';
import classNames from 'classnames';
import { QUOTE_USD, Version } from 'config/constants';

import { useCurrentChain } from 'hooks/useCurrentChain';
import { StyledPopover } from 'pages/Trade/Overview';
import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { selectVersion } from 'state/setting/selector';
import { IPoolItem } from 'types';
import { isZero, shortenSymbolNative, toPercent, toQuoteAmount } from 'utils';

import { CommonStyledDivider } from 'components/Common/Styled';
import { LeverageCard } from 'components/Leverage';
import NoData from 'components/NoData';
import PriceChange from 'components/PriceChange';
import SearchInput from 'components/SearchInput';

export default React.memo(function SelectPools({
	pool,
	onSelect,
	simpleMode = false,
	router = 'Markets'
}: {
	pool: IPoolItem | null;
	onSelect: (pool: IPoolItem) => void;
	simpleMode?: boolean;
	router?: string;
}) {
	const { pools } = useAppSelector(poolsBaseState);
	const currentVersion = useAppSelector(selectVersion);
	const { currentChainId } = useCurrentChain();
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [searchKeyword, setSearchKeyword] = useState('');
	const filteredPools = pools.filter(pool => {
		return (
			pool?.baseSymbol.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1
		);
	});
	const onHandleShowPop = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const onHandleClose = () => {
		setAnchorEl(null);
		setSearchKeyword('');
	};

	const onHandleChangePool = (pool: IPoolItem) => {
		onSelect(pool);
		onHandleClose();
	};

	const onHandleSearchKeyword = e => {
		const value = e.target.value;
		if (value.trim() === '') {
			setSearchKeyword('');
		} else {
			setSearchKeyword(e.target.value);
		}
	};
	const onHandleKeyDown = e => {
		// if (e.key === "Enter" && filteredOptions.length > 0) {
		//   	onSelectOption(filteredOptions[0]);
		// }
	};

	return (
		<div>
			<Button
				className={classNames('h-auto', {
					'sm:pl-1': !simpleMode,
					'sm:pl-0': simpleMode
				})}
				variant='text'
				size='small'
				endIcon={
					anchorEl ? (
						<ExpandLess sx={{ color: theme.palette.text.primary }} />
					) : (
						<ExpandMore sx={{ color: theme.palette.text.primary }} />
					)
				}
				onClick={onHandleShowPop}
				sx={{
					color: theme.palette.text.primary,
					padding: simpleMode ? 0 : `0 ${theme.spacing(1)}`,
					'&:hover': {
						backgroundColor: 'inherit',
						color: theme.palette.primary.main
					},
					'&:hover .MuiSvgIcon-root': {
						color: theme.palette.primary.main
					}
				}}
			>
				<div className='flex items-center space-x-1'>
					<Typography variant='h5' fontWeight='medium'>
						{`${shortenSymbolNative(
							pool?.baseToken,
							currentChainId
						)}/${QUOTE_USD}`}
					</Typography>
				</div>
			</Button>
			<StyledPopover
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: simpleMode ? -11 : -22,
					horizontal: simpleMode ? 2 : 8
				}}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={onHandleClose}
			>
				<List
					className={classNames({
						'w-[344px]': !simpleMode,
						'w-[200px]': simpleMode
					})}
					sx={{
						maxHeight: '370px',
						height: '370px',
						overflowY: 'scroll'
					}}
				>
					<Box
						className='flex-col items-center px-4 py-3 sticky top-0 z-50'
						sx={{
							backgroundColor: theme.palette.background.paper
						}}
					>
						<SearchInput
							value={searchKeyword}
							setValue={onHandleSearchKeyword}
							onKeyDown={onHandleKeyDown}
						/>
						<Box className='flex items-center mt-2'>
							<Typography
								className='flex-2'
								variant='body1'
								color='text.secondary'
							>
								{currentVersion === Version.V1 ? (
									<Trans>Pool</Trans>
								) : (
									<Trans>Markets</Trans>
								)}
							</Typography>
							{!simpleMode && (
								<Typography
									className='flex-1 text-right'
									variant='body1'
									color='text.secondary'
								>
									<Trans>Price</Trans>
								</Typography>
							)}
							{!simpleMode && (
								<Typography
									className='flex-1 text-right'
									variant='body1'
									color='text.secondary'
								>
									<Trans>Change</Trans>
								</Typography>
							)}
						</Box>
					</Box>
					<CommonStyledDivider className='my-0' />
					{filteredPools.length > 0 &&
						filteredPools.map((item: IPoolItem, index: number) => (
							<ListItem
								key={index}
								className='flex px-4 py-3'
								onClick={() => onHandleChangePool(item)}
								sx={{
									backgroundColor:
										pool?.id === item.id
											? theme.custom.selectActive
											: 'inherit',
									'&:hover': {
										backgroundColor: theme.custom.selectActive
									}
								}}
							>
								<div className='flex-2 flex items-center space-x-1'>
									<Typography variant='body1'>
										{`${shortenSymbolNative(
											item.baseToken,
											currentChainId
										)}/${QUOTE_USD}`}
									</Typography>
									{currentVersion === Version.V2 && router === 'Markets' ? (
										<LeverageCard value={item?.maxLeveragePerPosition} />
									) : null}
									{currentVersion === Version.V2 && router === 'Pools' ? (
										<Chip label={toPercent(item?.tradingFeeRate)} />
									) : null}
								</div>
								{!simpleMode && (
									<Typography className='flex-1 text-right' variant='body1'>
										{toQuoteAmount(item.price, item.baseToken.precision)}
									</Typography>
								)}
								{!simpleMode && (
									<Typography
										className='flex-1 flex justify-end'
										variant='body1'
									>
										<PriceChange
											value={item.priceChange}
											size={14}
										></PriceChange>
									</Typography>
								)}
							</ListItem>
						))}
					{filteredPools.length === 0 && (
						<NoData className='pt-2 pl-4'>
							<Trans>No data found</Trans>
						</NoData>
					)}
				</List>
			</StyledPopover>
		</div>
	);
});
