import { useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { Box, FormControlLabel, Typography, useTheme } from '@mui/material';

import { useAppDispatch } from 'state/hooks';
import { setTakeProfitStopLossModifyTis } from 'state/setting/slice';

import { StyleCheckbox } from 'components/Common/StyleCheckbox';

import Dialog from '../../components/Common/Dialog';

const ModifyTisDialog = ({ onClose }: { onClose: () => void }) => {
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const [checked, setChecked] = useState(false);
	const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};
	const onConfirm = () => {
		if (checked) {
			dispatch(setTakeProfitStopLossModifyTis(true));
		}
		onClose();
	};

	return (
		<Dialog
			onConfirm={onConfirm}
			open
			onClose={onClose}
			title={t`Tips`}
			confirmLabel={t`Got it`}
		>
			<Box color={theme.palette.text.secondary}>
				<Typography variant='body2' className='mt-1'>
					<Trans>
						Position TP/SL refers to the TP/SL of the entire position. When the
						latest{' '}
						<Typography
							className='inline'
							variant='body2'
							color={theme.custom.orangeColor}
						>
							market price
						</Typography>{' '}
						reaches the trigger price, the system will place a TP/SL order for
						the entire position at the market price.
					</Trans>
				</Typography>

				<Typography
					variant='body2'
					className='mt-4'
					color={theme.custom.orangeColor}
				>
					<Trans>
						When the Position TP/SL is triggered due to violent market
						fluctuation, the actual transaction price may vary with the
						acceptable price, the TP/SL order may fail to be executed.
					</Trans>
				</Typography>
				<Box className='flex items-center mt-2'>
					<FormControlLabel
						control={
							<StyleCheckbox
								onChange={onHandleChange}
								size='small'
								checked={checked}
							/>
						}
						label={<Trans>Do not show again</Trans>}
						sx={{ fontSize: 12 }}
					/>
				</Box>
			</Box>
		</Dialog>
	);
};

export default ModifyTisDialog;
