import { Trans } from '@lingui/macro';
import { KeyboardArrowRight } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';

import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { shortenSymbol } from 'utils';

import StyledLink from 'components/Common/StyledLink';

export default function ClaimPrevious({
	onClaim,
	isVisible,
	className
}: {
	onClaim: () => void;
	isVisible: boolean;
	className?: string;
}) {
	const { appToken } = useAppSelector(txBaseState);
	const theme = useTheme();
	return (
		<>
			{isVisible && (
				<StyledLink
					onClick={onClaim}
					className={classNames(`flex items-center`, className)}
					sx={{
						color: theme.palette.text.primary,
						fontSize: theme.typography.body1
					}}
				>
					<Trans>Claim previous {shortenSymbol(appToken)} rewards</Trans>
					<KeyboardArrowRight
						color='inherit'
						fontSize='small'
						className='mt-0.5'
					/>
				</StyledLink>
			)}
		</>
	);
}

export function ClaimPreviousTip() {
	const { appToken } = useAppSelector(txBaseState);
	const theme = useTheme();
	return (
		<Typography color={theme.custom.orangeColor}>
			<Trans>
				The following are the {shortenSymbol(appToken)} rewards earned before
				the{' '}
				<StyledLink
					underline='always'
					href={import.meta.env.VITE_WEBSITE_EQUIP_1}
					target='_blank'
					sx={{
						color: theme.custom.orangeColor
					}}
				>
					burn mechanism
				</StyledLink>{' '}
				took effect.
			</Trans>
		</Typography>
	);
}

export function ClaimAfterTip() {
	const { appToken } = useAppSelector(txBaseState);
	const theme = useTheme();
	return (
		<Typography color={theme.custom.orangeColor}>
			<Trans>
				The following are the {shortenSymbol(appToken)} rewards earned after
				the{' '}
				<StyledLink
					underline='always'
					href={import.meta.env.VITE_WEBSITE_EQUIP_1}
					target='_blank'
					sx={{
						color: theme.custom.orangeColor
					}}
				>
					burn mechanism
				</StyledLink>{' '}
				took effect.
			</Trans>
		</Typography>
	);
}
