import React, { useEffect, useMemo, useState } from 'react';

import { Trans } from '@lingui/macro';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Card, CardContent, Typography } from '@mui/material';
import Decimal from 'decimal.js';

import { selectVersion } from 'state/setting/selector';

import Cell from '../../components/Common/Cell';
import Dialog from '../../components/Common/Dialog';
import {
	CommonStyledDivider,
	CommonStyledFilledInput
} from '../../components/Common/Styled';
import CommonButton from '../../components/Common/StyledButton';
import MarksSlider from '../../components/MarksSlider';
import SelectPools from '../../components/SelectPools';
import {
	LEVERAGE_MAKERS,
	MAX_PRECISION,
	QUOTE_USD,
	QUOTE_USD_PRECISION,
	Version
} from '../../config/constants';
import useLiquidityPoolsGraph from '../../graphql/useLiquidityPoolsGraph';
import { useAppBreakpoints } from '../../hooks/useAppBreakpoints';
import { useAppSelector } from '../../state/hooks';
import { poolsBaseState } from '../../state/pools/slice';
import {
	checkInputInteger,
	checkInputNumberic,
	computeLeverageMarks,
	formatNumber,
	isGreaterThanOrEqual,
	isLessThanOrEqualTo,
	isPositive,
	multipliedBy,
	toFormattedPercent,
	toPercent
} from '../../utils';

const LiquidityComputerDialog = (props: {
	onClose: () => void;
	back?: () => void;
	poolData: ReturnType<typeof useLiquidityPoolsGraph>['data'];
}) => {
	const { onClose, back, poolData } = props;

	const { isMatchPc, isMatchMobile } = useAppBreakpoints();
	const { pools, poolInfo, poolAddress } = useAppSelector(poolsBaseState);
	const currentVersion = useAppSelector(selectVersion);
	const [poolAddressState, setPoolAddressState] = useState('');
	useEffect(() => setPoolAddressState(poolAddress), [poolAddress]);
	const currentPool = pools.find(pool => pool.id === poolAddressState);

	const currentPoolData = poolData.find(
		item => item.address === poolAddressState
	);

	const [leverage, setLeverage] = useState('1');
	const leverageMarks = useMemo(() => {
		if (!currentPool) {
			return LEVERAGE_MAKERS;
		}
		return computeLeverageMarks(
			Number(currentPool?.maxLeveragePerLiquidityPosition)
		);
	}, [currentPool]);
	const onChangeLeverage = value => {
		setLeverage(String(value));
	};
	const onClickLabel = value => {
		setLeverage(String(value));
	};
	const onHandleChangeLeverage = e => {
		if (!currentPool) {
			return;
		}
		const value = e.target.value;
		if (value.trim() === '') {
			setLeverage('');
			return;
		}

		if (
			checkInputInteger(value) &&
			isGreaterThanOrEqual(value, 1) &&
			isPositive(currentPool?.maxLeveragePerLiquidityPosition) &&
			isLessThanOrEqualTo(value, currentPool?.maxLeveragePerLiquidityPosition)
		) {
			setLeverage(value);
		}
	};

	const onChangeMargin = e => {
		const value = e.target.value;
		if (value.trim() === '') {
			setMargin('');
		}
		if (checkInputNumberic(value, QUOTE_USD_PRECISION)) {
			setMargin(e.target.value);
		}
	};

	const [margin, setMargin] = useState<string>('');
	const [liquidity, setLiquidity] = useState('-');
	const [maxApr, setMaxApr] = useState('-');
	const [maxIncome, setMaxIncome] = useState('');
	const [riskAndRewardRate, setRiskAndRewardRate] = useState('-');

	const globalUnrealizedLoss = useMemo(() => {
		if (currentPoolData) {
			if (currentVersion === Version.V1) {
				return currentPoolData.globalUnrealizedLoss;
			} else {
				return '0';
			}
		} else {
			return '0';
		}
	}, [currentVersion, currentPoolData]);

	const onHandleConfirm = () => {
		const liquidityValue = multipliedBy(margin, leverage);
		const maxAprValue = currentPoolData.maxApr;
		const maxIncomeValue = multipliedBy(margin, maxAprValue);
		const riskAndRewardRateValue = new Decimal(globalUnrealizedLoss)
			.mul(currentPoolData.myLiquidity)
			.div(currentPoolData.liquidity)
			.div(maxIncomeValue)
			.toString();
		setLiquidity(liquidityValue);
		setMaxApr(maxAprValue);
		setMaxIncome(maxIncomeValue);
		setRiskAndRewardRate(riskAndRewardRateValue);
	};

	const disabled =
		leverage === '' || margin === '' || margin === '0' || !currentPoolData;

	useEffect(() => {
		if (isPositive(currentPool?.maxLeveragePerLiquidityPosition)) {
			setLeverage('1');
		}
	}, [currentPool?.maxLeveragePerLiquidityPosition]);
	return (
		<Dialog
			open
			height={isMatchMobile ? '100%' : 'auto'}
			width={isMatchPc ? 692 : '100%'}
			onClose={onClose}
			titleAlign='left'
			title={
				<div className='flex items-center gap-6'>
					{back && (
						<ArrowBackIosNewIcon
							onClick={back}
							className='cursor-pointer'
							fontSize='small'
						/>
					)}
					<SelectPools
						pool={currentPool}
						onSelect={value => {
							setPoolAddressState(value.id);
						}}
						simpleMode
					/>
				</div>
			}
		>
			<Typography
				className='flex sm:flex-col-reverse gap-4'
				sx={{
					minHeight: '240px'
				}}
			>
				<div className='flex-1 flex flex-col justify-between sm:space-y-4'>
					<div className='space-y-4'>
						<div className='space-y-1'>
							<CommonStyledFilledInput
								type='text'
								inputProps={{
									maxLength: MAX_PRECISION,
									inputMode: 'decimal'
								}}
								sx={{
									'& .MuiInputBase-input': {
										paddingRight: '8px'
									}
								}}
								value={leverage}
								onChange={onHandleChangeLeverage}
								fullWidth
								startAdornment={
									<Typography
										className='min-w-max'
										variant='body2'
										color='secondary'
									>
										<Trans>Leverage</Trans>
									</Typography>
								}
								endAdornment={
									<Typography
										color='secondary'
										fontSize={isMatchMobile ? 12 : 14}
										variant='h6'
									>
										x
									</Typography>
								}
							/>
							<MarksSlider
								leverage={leverage ? Number(leverage) : 1}
								marks={leverageMarks}
								max={Number(currentPool?.maxLeveragePerLiquidityPosition)}
								onChangeLeverage={onChangeLeverage}
								onClickLabel={onClickLabel}
							/>
						</div>
						<CommonStyledFilledInput
							type='text'
							inputProps={{
								maxLength: MAX_PRECISION,
								inputMode: 'decimal'
							}}
							sx={{
								'& .MuiInputBase-input': {
									paddingRight: '8px'
								}
							}}
							value={margin}
							onChange={onChangeMargin}
							fullWidth
							startAdornment={
								<Typography
									className='min-w-max'
									variant='body2'
									color='secondary'
								>
									<Trans>Margin</Trans>
								</Typography>
							}
							endAdornment={
								<Typography variant='body2' color='secondary'>
									{QUOTE_USD}
								</Typography>
							}
						/>
					</div>
					<CommonButton
						className='flex flex-col justify-center'
						variant='contained'
						fullWidth
						disabled={disabled}
						onClick={onHandleConfirm}
					>
						<Typography
							className='whitespace-normal text-sm'
							color='inherit'
							variant='h6'
							fontWeight={500}
						>
							<Trans>Calculate</Trans>
						</Typography>
					</CommonButton>
				</div>
				<Card className='flex-1'>
					<CardContent className='space-y-4'>
						<Typography variant='h6'>
							<Trans>Result</Trans>
						</Typography>
						<CommonStyledDivider />
						<div className='space-y-2'>
							<Cell
								label={<Trans>Liquidity</Trans>}
								value={<Typography variant='body2'>{liquidity}</Typography>}
							/>
							<Cell
								label={<Trans>Max APR</Trans>}
								value={
									<Typography variant='body2'>{toPercent(maxApr)}</Typography>
								}
							/>
							<Cell
								label={<Trans>Max Income</Trans>}
								value={
									<Typography variant='body2'>
										{formatNumber(maxIncome)}
									</Typography>
								}
							/>
							{currentVersion === Version.V1 && (
								<Cell
									label={<Trans>Risk / Reward</Trans>}
									value={
										<Typography variant='body2'>
											{riskAndRewardRate === '-'
												? '-'
												: toFormattedPercent(riskAndRewardRate)}
										</Typography>
									}
								/>
							)}
						</div>
						{currentVersion === Version.V1 && (
							<>
								<CommonStyledDivider />
								<Typography variant='body2' fontSize={12} color='secondary'>
									<Trans>
										The risk/reward ratio is calculated by dividing the
										potential risk in trading by the expected rewards. The
										calculation formula is: Risk/Reward Rate = (Current Floating
										Loss of The Liquidity Pool * Your Liquidity Ratio) / Max
										Income * 100%
									</Trans>
								</Typography>
							</>
						)}
					</CardContent>
				</Card>
			</Typography>
			<Typography className='mt-4' variant='body2' color='secondary'>
				<Trans>
					*The calculation is for reference only and does not include trading
					fee, execution fee and other actual costs.
				</Trans>
			</Typography>
		</Dialog>
	);
};

export default LiquidityComputerDialog;
