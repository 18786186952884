import { useMemo } from 'react';

import { routerABI } from 'config/abis';
import { erc20ABI, erc721ABI } from 'wagmi';

import { Contract } from 'ethers';

import useWeb3Provider from './useWeb3Provider';

export function useContract<T extends Contract = Contract>(
	address: string,
	ABI: any,
	withSignerIfPossible = true
): T | null {
	const providerOrSigner = useWeb3Provider(withSignerIfPossible);
	const canReturnContract = useMemo(
		() => address && ABI && providerOrSigner,
		[address, ABI, providerOrSigner]
	);

	return useMemo(() => {
		if (!canReturnContract) return null;
		try {
			return new Contract(address, ABI, providerOrSigner);
		} catch (error) {
			console.error('Failed to get contract', error);
			return null;
		}
	}, [address, ABI, providerOrSigner, canReturnContract]) as T;
}

export function useTokenContract(
	tokenAddress: string,
	withSignerIfPossible?: boolean
) {
	return useContract(tokenAddress, erc20ABI, withSignerIfPossible);
}

export function usePluginContract(
	address: string,
	withSignerIfPossible?: boolean
) {
	return useContract(address, routerABI, withSignerIfPossible);
}

export function useERC721Contract(nftAddress?: string) {
  return useContract(nftAddress, erc721ABI, true)
}
