import React from 'react';

import Decimal from 'decimal.js';
import moment from 'moment';

import historyProvider from './historyProvider';
import stream from './stream';

const supportedResolutions = ['1', '5', '15', '30', '60', '240', '1D'];

const config = {
	supports_search: true,
	supports_group_request: false,
	supported_resolutions: supportedResolutions,
	supports_marks: false,
	supports_time: true
};
export default (api, options) => ({
	onReady: cb => {
		setTimeout(() => cb(config));
	},
	searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
		//console.log("====Search Symbols running");
	},
	resolveSymbol: (
		symbolName,
		onSymbolResolvedCallback,
		onResolveErrorCallback
	) => {
		historyProvider(api, options)
			.resolveSymbol(
				symbolName,
				onSymbolResolvedCallback,
				onResolveErrorCallback
			)
			.then(symbol_stub => {
				const { pricescale, pericesion } = options;
				onSymbolResolvedCallback({
					...symbol_stub,
					type: 'crypto',
					data_status: 'streaming',
					pericesion: pericesion,
					volume_precision: 2,
					pricescale: new Decimal(10)
						.pow(pricescale > 16 ? 16 : pricescale)
						.toNumber()
				});
			});
	},
	getBars: function (
		symbolInfo,
		resolution,
		periodParams,
		onHistoryCallback,
		onErrorCallback
	) {
		const { from, to, countBack, firstDataRequest } = periodParams;
		const refactorTo = to > moment().unix() ? moment().unix() : to
		historyProvider(api, options)
			.getBars(symbolInfo, resolution, from, refactorTo, firstDataRequest, countBack)
			.then(bars => {
				if (bars.length) {
					onHistoryCallback(bars, { noData: false });
				} else {
					onHistoryCallback(bars, { noData: true });
				}
			})
			.catch(error => {
				onErrorCallback(error);
			});
	},
	subscribeBars: (
		symbolInfo,
		resolution,
		onRealtimeCallback,
		subscribeUID,
		onResetCacheNeededCallback
	) => {
		/* console.log(
      "=====subscribeBars runnning",
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback
    ); */
		stream.subscribeBars(
			symbolInfo,
			resolution,
			onRealtimeCallback,
			subscribeUID,
			onResetCacheNeededCallback,
			historyProvider(api, options).history[symbolInfo.ticker].lastBar,
			api,
			options
		);
	},
	unsubscribeBars: subscriberUID => {
		//console.log('=====unsubscribeBars running', subscriberUID);
		stream.unsubscribeBars(subscriberUID);
	},
	getServerTime: cb => {
		historyProvider(api, options).getTime().then(resp => {
			cb(resp.data.server_time_unix)
		})
	}
});
