import { useMemo } from 'react';

import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import { QUOTE_USD } from 'config/constants';
import Decimal from 'decimal.js';

import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import { tradeBaseState } from 'state/trade/slice';
import {
	div,
	isEqualTo,
	isNumeric,
	isPositive,
	isZero,
	minus,
	multipliedBy,
	neg,
	plus,
	toPercent,
	toQuoteAmount
} from 'utils';

import Cell from 'components/Common/Cell';
import ExecutionFee from 'components/ExecutionFee';
import Stronger from 'components/Stronger';

import FreeTradingFeeWarning, {
	Type_Free_Trading_Fee
} from '../FreeTradingFeeWarning';

export default function TradeFees({
	tradingFeeRate,
	referralDiscountRate,
	tradingFee,
	originTradingFee,
	executionFee
}: {
	tradingFeeRate: string; // 0.0005
	referralDiscountRate: string; // 0 or 0.9
	tradingFee: string;
	originTradingFee: string;
	executionFee?: string;
}) {
	const { nativePriceUsd, positionMinExecutionFee } =
		useAppSelector(globalBaseState);
	const { isBindReferralCode } = useAppSelector(tradeBaseState);

	const computedExecutionFee = useMemo(() => {
		return executionFee || positionMinExecutionFee;
	}, [positionMinExecutionFee, executionFee]);

	// 最终折算的折扣率
	const discountedTradingFeeRate = useMemo(() => {
		if (!isPositive(tradingFeeRate)) {
			return '0';
		}
		if (!isBindReferralCode) {
			return tradingFeeRate;
		}
		return multipliedBy(tradingFeeRate, referralDiscountRate);
	}, [tradingFeeRate, referralDiscountRate, isBindReferralCode]);

	// 展示的折扣，最终的手续费
	const [displayedDiscount, discountedTradingFee] = useMemo(() => {
		if (!isPositive(referralDiscountRate)) {
			return ['1', '0'];
		}
		const displayedDiscount = minus(1, referralDiscountRate);
		const discountedTradingFee = div(tradingFee, referralDiscountRate);

		return [displayedDiscount, discountedTradingFee];
	}, [tradingFee, referralDiscountRate, tradingFeeRate]);

	const executionFeeUsd = useMemo(() => {
		if (isPositive(nativePriceUsd) && computedExecutionFee) {
			return multipliedBy(nativePriceUsd, computedExecutionFee);
		} else {
			return '';
		}
	}, [nativePriceUsd, computedExecutionFee]);

	const totalFees = useMemo(() => {
		if (!tradingFee || !executionFeeUsd) {
			return '';
		}
		return plus(tradingFee, executionFeeUsd);
	}, [tradingFee, executionFeeUsd]);

	return (
		<Cell
			label={
				<div className='flex space-x-1'>
					<Typography variant='body2' color='inherit'>
						<Trans>Fees</Trans>
					</Typography>
					{isZero(referralDiscountRate) && <FreeTradingFeeWarning />}
					{isEqualTo(referralDiscountRate, 0.1) && (
						<FreeTradingFeeWarning type={Type_Free_Trading_Fee.Discount} />
					)}
				</div>
			}
			value={
				tradingFeeRate && isNumeric(totalFees) ? (
					<Stronger
						value={`${toQuoteAmount(
							neg(totalFees),
							2,
							Decimal.ROUND_DOWN
						)} ${QUOTE_USD}`}
						tooltip={
							<div>
								<div className='flex items-baseline justify-between space-x-4'>
									<aside>
										<Typography variant='body2' lineHeight={1}>
											<Trans>Trading Fee</Trans>
										</Typography>
										<Typography variant='body2' fontSize={12} color='secondary'>
											<Trans>
												(
												{toPercent(
													discountedTradingFeeRate,
													isPositive(discountedTradingFeeRate) ? 3 : 0
												)}{' '}
												of the position value)
											</Trans>
										</Typography>
									</aside>
									<div className='flex items-center space-x-1'>
										<div className='flex space-x-1'>
											{/* 最终的交易手续费 */}
											<Typography variant='body2' className='whitespace-nowrap'>
												{`${toQuoteAmount(
													neg(tradingFee),
													2,
													Decimal.ROUND_DOWN
												)}`}
											</Typography>
											<Typography variant='body2'>{QUOTE_USD}</Typography>
											{/* 原始的交易手续费 */}
											{isBindReferralCode && (
												<Typography
													variant='body2'
													color='secondary'
													className='line-through'
												>
													{`(${toQuoteAmount(originTradingFee, 2)})`}
												</Typography>
											)}
										</div>
									</div>
								</div>
								{isBindReferralCode && (
									<Cell
										label={
											<Typography variant='body2' color='text.primary'>
												<Trans>Referral Discount</Trans>
											</Typography>
										}
										value={
											<div className='flex space-x-1'>
												<Typography variant='body2' color='success.main'>
													{`${toPercent(displayedDiscount)}`}
												</Typography>
											</div>
										}
									/>
								)}

								<ExecutionFee executionFee={computedExecutionFee} />
							</div>
						}
					/>
				) : (
					'-'
				)
			}
		/>
	);
}
