import { useRef } from 'react';

// React官方新hook，当前版本没有，自己写一个
const useEffectEvent = fn => {
	const ref = useRef(fn);
	ref.current = fn;
	return (...args) => ref.current(...args);
};

export default useEffectEvent;
