import { useEffect, useMemo, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import {
	Card,
	CardContent,
	Divider,
	Typography,
	useTheme
} from '@mui/material';
import {
	DEFAULT_PRECISION,
	MAX_PRECISION,
	QUOTE_USD,
	Transaction_Type
} from 'config/constants';
import Decimal from 'decimal.js';

import { LiquidityPositionUtil } from 'entities/LiquidityPositionUtil';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { isNull } from 'lodash';
import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { txBaseState } from 'state/tx/slice';
import {
	checkInputNumberic,
	div,
	formatLeverage,
	formatNumber,
	isEqualTo,
	isGreaterThan,
	isLessThan,
	isPositive,
	isZero,
	minus,
	multipliedBy,
	plus,
	shortenSymbol,
	shortenSymbolNative,
	toDecimalPlaces,
	toQuoteAmount
} from 'utils';

import Cell from 'components/Common/Cell';
import Dialog from 'components/Common/Dialog';
import { StyleCheckbox } from 'components/Common/StyleCheckbox';
import {
	CommonStyledAlertWarning,
	CommonStyledBaseInput,
	CommonStyledDivider,
	CommonStyledMaxButton
} from 'components/Common/Styled';
import Tooltip from 'components/Common/Tooltip';
import ExecutionFeeV2 from 'components/ExecutionFee/ExecutionFeeV2';
import { TooltipWarning } from 'components/TextWarning';
import TippingUnderline from 'components/TippingUnderline';
import ToNext from 'components/ToNext';

import LiquidityPassivePositionV2 from './LiquidityPassivePositionV2';
import useSubmitRemoveLiquidityV2 from './hooks/useSubmitRemoveLiquidityV2';

const RemoveLiquidity = ({
	currentPosition,
	onClose
}: {
	currentPosition: any;
	onClose: () => void;
}) => {
	const { signingMap, quoteToken } = useAppSelector(txBaseState);
	const { currentChainId } = useCurrentChain();
	const [value, setValue] = useState<string>('');
	const [isPureReduction, setIsPureReduction] = useState<boolean>(false);
	const [marginWarn, setMarginWarn] = useState<boolean>(false);
	const { poolInfo, liquidityPools } = useAppSelector(poolsBaseState);

	const liquidityPool = useMemo(() => {
		const targetPoolAddress = poolInfo?.address.toLowerCase();
		return liquidityPools.find(item => item.address === targetPoolAddress);
	}, [poolInfo, liquidityPools]);

	const settlement = plus(
		currentPosition.marginNet,
		currentPosition.liquidityPassivePosition?.pnl
	);

	const theme = useTheme();

	const onHandleSetMaxAmount = () => {
		setValue(currentPosition.myLiquidity);
	};

	const onHandleRemoveLiquidity: React.ChangeEventHandler<
		HTMLInputElement
	> = e => {
		if (!checkInputNumberic(e.target.value, quoteToken?.decimals)) {
			return;
		}
		setValue(e.target.value);
	};

	const onHandlePureReductionChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setIsPureReduction(event.target.checked);
	};

	//减仓比率
	const lightenRatio = useMemo(() => {
		if (
			isPositive(value) &&
			!isGreaterThan(value, currentPosition.myLiquidity)
		) {
			const ratio = div(value, currentPosition.myLiquidity);
			if (isEqualTo(ratio, 1)) setIsPureReduction(false);
			return ratio;
		} else {
			return '0';
		}
	}, [value, currentPosition.myLiquidity]);

	const nextData = useMemo(() => {
		if (isPositive(lightenRatio) && isPositive(value)) {
			const { liquidity, liquidationBufferNetSize, netSize } =
				currentPosition.globalLiquidityPosition;
			//结算后剩余流动性
			const afterliquidity = minus(currentPosition.myLiquidity, value);
			const afterGlobalLiquidity = minus(
				currentPosition?.globalLiquidity,
				value
			);
			const liquidityRatio = div(afterliquidity, minus(liquidity, value));
			//结算后剩余的size
			const afterSize = multipliedBy(
				plus(liquidationBufferNetSize, netSize),
				liquidityRatio
			);
			//结算后剩余保证金
			let afterMargin = '0';
			//结算后已实现盈亏
			let afterPassivePositionPnl = '0';
			// 结算后预计收到的结算金额
			let receiveAmount = '';

			//after entry price
			let afterEntryPrice = '';

			if (isPureReduction) {
				if (isPositive(currentPosition.liquidityPassivePosition?.pnl)) {
					receiveAmount = multipliedBy(
						lightenRatio,
						currentPosition.liquidityPassivePosition?.pnl
					);
					afterMargin = plus(
						currentPosition.marginNet,
						multipliedBy(
							minus(1, lightenRatio),
							currentPosition.liquidityPassivePosition?.pnl
						)
					);
					afterPassivePositionPnl = plus(
						currentPosition.pnl,
						currentPosition.liquidityPassivePosition?.pnl
					);
					setMarginWarn(false);
				} else {
					receiveAmount = '0';
					afterMargin = plus(
						currentPosition.marginNet,
						currentPosition.liquidityPassivePosition?.pnl
					);
					afterPassivePositionPnl = plus(
						currentPosition.pnl,
						currentPosition.liquidityPassivePosition?.pnl
					);
					setMarginWarn(true);
				}
			} else {
				if (isPositive(currentPosition.liquidityPassivePosition?.pnl)) {
					receiveAmount = plus(
						multipliedBy(lightenRatio, currentPosition.marginNet),
						multipliedBy(
							lightenRatio,
							currentPosition.liquidityPassivePosition?.pnl
						)
					);
					afterMargin = plus(
						multipliedBy(minus(1, lightenRatio), currentPosition.marginNet),
						multipliedBy(
							minus(1, lightenRatio),
							currentPosition.liquidityPassivePosition?.pnl
						)
					);
					afterPassivePositionPnl = plus(
						currentPosition.pnl,
						currentPosition.liquidityPassivePosition?.pnl
					);
					setMarginWarn(false);
				} else {
					receiveAmount = isPositive(
						plus(
							multipliedBy(lightenRatio, currentPosition.marginNet),
							multipliedBy(
								lightenRatio,
								currentPosition.liquidityPassivePosition?.pnl
							)
						)
					)
						? plus(
								multipliedBy(lightenRatio, currentPosition.marginNet),
								multipliedBy(
									lightenRatio,
									currentPosition.liquidityPassivePosition?.pnl
								)
						  )
						: '0';
					afterMargin = plus(
						multipliedBy(minus(1, lightenRatio), currentPosition.marginNet),
						multipliedBy(
							minus(1, lightenRatio),
							currentPosition.liquidityPassivePosition?.pnl
						)
					);
					afterPassivePositionPnl = plus(
						currentPosition.pnl,
						currentPosition.liquidityPassivePosition?.pnl
					);
					setMarginWarn(false);
				}
			}
			// 结算后杠杆
			const afterLeverage = div(afterliquidity, afterMargin);
			// 维持保证金
			const maintenanceMargin =
				LiquidityPositionUtil.calculateMaintenanceMargin(
					afterliquidity,
					liquidityPool?.liquidationFeeRatePerLiquidityPosition,
					liquidityPool?.liquidationExecutionFee
				);
			// 结算后的爆仓价
			const afterLiqPrice = LiquidityPositionUtil.calculateLiqPrice(
				maintenanceMargin,
				afterliquidity,
				afterGlobalLiquidity,
				plus(
					currentPosition?.globalLiquidityPosition?.liquidationBufferNetSize,
					currentPosition?.globalLiquidityPosition?.netSize
				),
				currentPosition?.globalLiquidityPosition.side,
				afterMargin,
				currentPosition?.liquidityPassivePosition?.indexPrice
			);
			//提交合约的marginDelta
			const marginDelta = toDecimalPlaces(receiveAmount, DEFAULT_PRECISION);

			afterEntryPrice = liquidityPool.indexPrice;

			return {
				receiveAmount,
				afterliquidity,
				afterMargin,
				afterSize,
				afterLeverage,
				afterPassivePositionPnl,
				maintenanceMargin,
				afterLiqPrice,
				marginDelta,
				afterEntryPrice
			};
		} else {
			return {
				receiveAmount: '',
				afterliquidity: '0',
				afterMargin: '0',
				afterSize: '0',
				afterLeverage: '0',
				afterPassivePositionPnl: '0',
				maintenanceMargin: '',
				afterLiqPrice: '',
				marginDelta: '0',
				afterEntryPrice: ''
			};
		}
	}, [
		lightenRatio,
		value,
		isPureReduction,
		currentPosition,
		currentPosition.liquidityPassivePosition?.pnl,
		liquidityPool
	]);

	const { onConfirm, setTxSuccessCallback, isConfirmed, isConfirming } =
		useSubmitRemoveLiquidityV2(
			currentPosition.address,
			nextData.marginDelta,
			nextData.afterMargin,
			value,
			currentPosition.baseToken,
			currentPosition.myLiquidity
		);

	const disabled = useMemo(() => {
		if (signingMap.get(Transaction_Type.RemoveLiquidityV2)) {
			return true;
		}
		if (
			!quoteToken ||
			!isPositive(value) ||
			!liquidityPool ||
			!currentPosition
		) {
			return true;
		}
		if (
			!isEqualTo(lightenRatio, 1) &&
			!isEqualTo(nextData.afterMargin, 0) &&
			isGreaterThan(nextData.maintenanceMargin, nextData.afterMargin)
		) {
			return true;
		}
		if (isGreaterThan(value, currentPosition.myLiquidity)) {
			return true;
		}
		if (
			isEqualTo(value, liquidityPool?.globalLiquidityPosition.liquidity) &&
			isEqualTo(
				currentPosition.myLiquidity,
				liquidityPool?.globalLiquidityPosition.liquidity
			) &&
			!isNull(
				liquidityPool?.liquidityPositions[0]?.liquidityPassivePosition?.size
			)
		) {
			return true;
		}
		if (isConfirming) {
			return true;
		}
		if (isLessThan(settlement, 0)) {
			return true;
		}
		if (
			!isPureReduction &&
			isPositive(nextData.receiveAmount) &&
			isGreaterThan(
				nextData.afterLeverage,
				poolInfo.maxLeveragePerLiquidityPosition
			)
		) {
			return true;
		}
	}, [
		signingMap,
		isConfirming,
		currentPosition,
		liquidityPool,
		value,
		nextData,
		poolInfo
	]);

	const submitText = useMemo(() => {
		if (signingMap.get(Transaction_Type.RemoveLiquidityV2) || isConfirming) {
			return <Trans>Loading...</Trans>;
		}
		if (
			!isEqualTo(lightenRatio, 1) &&
			!isEqualTo(nextData.afterMargin, 0) &&
			isGreaterThan(nextData.maintenanceMargin, nextData.afterMargin)
		) {
			return <Trans>Insufficient maintenance margin after operation</Trans>;
		}
		if (isGreaterThan(value, currentPosition.myLiquidity)) {
			return <Trans>Exceeds current liquidity</Trans>;
		}
		if (
			isEqualTo(value, liquidityPool?.globalLiquidityPosition.liquidity) &&
			isEqualTo(
				currentPosition.myLiquidity,
				liquidityPool?.globalLiquidityPosition.liquidity
			) &&
			!isNull(
				liquidityPool?.liquidityPositions[0]?.liquidityPassivePosition?.size
			)
		) {
			return <Trans>Insufficient Liquidity</Trans>;
		}
		if (isLessThan(settlement, 0)) {
			return <Trans>Insufficient Margin</Trans>;
		}
		if (
			!isPureReduction &&
			isPositive(nextData.receiveAmount) &&
			isGreaterThan(
				nextData.afterLeverage,
				poolInfo.maxLeveragePerLiquidityPosition
			)
		) {
			return (
				<Trans>
					Exceeds the max leverage of {poolInfo.maxLeveragePerLiquidityPosition}
					x
				</Trans>
			);
		}
		return <Trans>Remove</Trans>;
	}, [
		signingMap,
		isConfirming,
		currentPosition.liquidityPassivePosition?.pnl,
		currentPosition,
		liquidityPool,
		value,
		nextData,
		poolInfo
	]);

	useEffect(() => {
		setTxSuccessCallback(() => onClose);
	}, [isConfirmed]);

	return (
		<Dialog
			onConfirm={onConfirm}
			open
			onClose={onClose}
			title={t`Remove Liquidity`}
			disabled={disabled}
			confirmLabel={submitText}
		>
			<Typography>
				{`${shortenSymbolNative(
					currentPosition.baseToken,
					currentChainId
				)}/${QUOTE_USD}`}
			</Typography>
			<Card className='mt-4'>
				<CardContent>
					<Cell
						label={
							<Typography variant='body1'>
								<Trans>Amount</Trans>
							</Typography>
						}
						prefix={
							<>
								<Trans>Liquidity</Trans>:
							</>
						}
						value={`${formatNumber(currentPosition.myLiquidity)}`}
					/>

					<CommonStyledBaseInput
						type='text'
						inputProps={{ maxLength: MAX_PRECISION, inputMode: 'decimal' }}
						className='input'
						value={value}
						placeholder='0.0'
						onChange={onHandleRemoveLiquidity}
						fullWidth
						endAdornment={
							<div className='flex items-center space-x-2'>
								<CommonStyledMaxButton
									variant='outlined'
									color='secondary'
									onClick={() => onHandleSetMaxAmount()}
								>
									<Trans>Max</Trans>
								</CommonStyledMaxButton>
							</div>
						}
					/>
				</CardContent>
			</Card>
			<div className='mt-3'>
				<Cell
					label={
						<Typography variant='body2'>
							<TippingUnderline
								tooltip={
									<Trans>
										When reducing liquidity, you can choose to use the
										&quot;Reduce-Only&quot; mode. The Reduce-Only mode means
										only reducing liquidity while keeping the margin unchanged.
										The leverage of the liquidity position will change
										accordingly after the reduction.
									</Trans>
								}
								label={<Trans>Reduce-Only</Trans>}
							/>
						</Typography>
					}
					value={
						<StyleCheckbox
							checked={isPureReduction}
							onChange={onHandlePureReductionChange}
							size='small'
							className='-mr-2'
							disabled={isEqualTo(lightenRatio, 1)}
						/>
					}
				/>
			</div>
			<CommonStyledDivider />
			<div className='mt-3 space-y-1'>
				<Cell
					label={
						<Typography variant='body2'>
							<Trans>Liquidity</Trans>
						</Typography>
					}
					value={
						<ToNext
							value={toQuoteAmount(currentPosition.liquidity)}
							nextValue={toQuoteAmount(nextData.afterliquidity)}
							hasNext={
								isPositive(value) &&
								!isGreaterThan(value, currentPosition.myLiquidity)
							}
						/>
					}
				/>

				<Cell
					label={
						<Typography variant='body2'>
							<Trans>Margin</Trans>
						</Typography>
					}
					value={
						<ToNext
							value={
								<Typography variant='body2' className='flex'>
									{marginWarn && (
										<TooltipWarning>
											<Trans>
												The current liquidity position is in a loss state, and
												the loss will be deducted from the margin.
											</Trans>
										</TooltipWarning>
									)}
									{toQuoteAmount(currentPosition.marginNet)}
								</Typography>
							}
							nextValue={toQuoteAmount(nextData.afterMargin)}
							hasNext={
								isPositive(value) &&
								!isGreaterThan(value, currentPosition.myLiquidity)
							}
						/>
					}
					unit={shortenSymbol(quoteToken)}
				/>

				<Cell
					label={
						<Typography variant='body2'>
							<Trans>Leverage</Trans>
						</Typography>
					}
					value={
						<ToNext
							value={formatLeverage(currentPosition.leverage)}
							nextValue={formatLeverage(nextData.afterLeverage)}
							hasNext={
								isPositive(value) &&
								!isEqualTo(value, currentPosition.myLiquidity) &&
								!isGreaterThan(value, currentPosition.myLiquidity)
							}
						/>
					}
				/>

				<LiquidityPassivePositionV2
					liquidityPool={liquidityPool}
					liquidityValue={value}
					nextData={nextData}
					removeAllLiquidity={isEqualTo(value, currentPosition.myLiquidity)}
					exceeds={
						isGreaterThan(value, currentPosition.myLiquidity) ||
						(isEqualTo(
							currentPosition.myLiquidity,
							liquidityPool?.globalLiquidityPosition.liquidity
						) &&
							!isNull(
								liquidityPool?.liquidityPositions[0]?.liquidityPassivePosition
									?.size
							) &&
							isLessThan(value, currentPosition.myLiquidity))
					}
				></LiquidityPassivePositionV2>
				<ExecutionFeeV2 />
			</div>
			<div>
				<Cell
					label={
						<Typography variant='body2'>
							<Trans>Receive</Trans>
						</Typography>
					}
					value={
						nextData.receiveAmount === ''
							? '-'
							: `${formatNumber(nextData.receiveAmount)} ${shortenSymbol(
									quoteToken
							  )}`
					}
				/>
			</div>
		</Dialog>
	);
};

export default RemoveLiquidity;
