import { TokenInfo } from '../types';
import { getCurrencyAddress } from './getCurrencyAddress';

/**
 * Check whether the addresses of the two tokens are the same
 * @param baseToken
 * @param quoteToken
 * @returns
 */
export function sortsEqual(
	baseToken: TokenInfo | null,
	quoteToken: TokenInfo | null
) {
	if (!baseToken || !quoteToken) {
		return false;
	}
	if (
		getCurrencyAddress(baseToken).toLocaleLowerCase() ===
		getCurrencyAddress(quoteToken).toLocaleLowerCase()
	) {
		return true;
	}
	return false;
}

export function sortsBefore(baseToken: TokenInfo, quoteToken: TokenInfo) {
	return (
		getCurrencyAddress(baseToken).toLocaleLowerCase() <
		getCurrencyAddress(quoteToken).toLocaleLowerCase()
	);
}
