import React, { useContext, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { MSG_ERROR_APPROVE } from 'config/constants';
import { Side, Transaction_Type, Version } from 'config/constants/enum';
import { getContractAddress, getContractAddressV2 } from 'config/contracts';
import { MobileActionContext } from 'context/MobileActionsContext';
import { TradeContext } from 'context/TradeContext';

import useApprovalPluginV2 from 'hooks/V2/useApprovalPluginV2';
import { useSubmitIncreaseLimitOrderV2 } from 'hooks/V2/useSubmitIncreaseLimitOrderV2';
import { ApprovalState, useApproval } from 'hooks/useApproval';
import { useApprovalPlugin } from 'hooks/useApprovalPlugin';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import { useSubmitIncreaseLimitOrder } from 'hooks/useSubmitIncreaseLimitOrder';
import { debounce } from 'lodash';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
	selectCurrentInitialMargin,
	selectCurrentLimitPrice,
	selectCurrentMarginDelta,
	selectCurrentPositionAmount
} from 'state/limit/selector';
import { reset } from 'state/limit/slice';
import { selectCurrentPrice } from 'state/pools/selector';
import { poolsBaseState } from 'state/pools/slice';
import { selectOrderOpenType, selectVersion } from 'state/setting/selector';
import { tradeBaseState } from 'state/trade/slice';
import { txBaseState } from 'state/tx/slice';
import {
	isGreaterThan,
	isGreaterThanOrEqual,
	isLessThan,
	isPositive,
	shortenSymbol,
	toDecimalPlaces
} from 'utils';

import CommonStyledButton from 'components/Common/StyledButton';
import ConnectWalletButton from 'components/Wallet/ConnectWalletButton';

import ConfirmDialog from './ConfirmDialog';

export default React.memo(function TradeButtonLimit() {
	const dispatch = useAppDispatch();

	const {
		transactionType,
		quoteToken,
		quoteBalance,
		nativeBalance,
		signingMap
	} = useAppSelector(txBaseState);
	const { leverage } = useAppSelector(tradeBaseState);
	const { poolInfo } = useAppSelector(poolsBaseState);

	const currentMarginDelta = useAppSelector(selectCurrentMarginDelta);
	const currentPositionAmount = useAppSelector(selectCurrentPositionAmount);
	const currentLimitPrice = useAppSelector(selectCurrentLimitPrice);
	const currentInitialMargin = useAppSelector(selectCurrentInitialMargin);
	const currentPrice = useAppSelector(selectCurrentPrice);
	const currentVersion = useAppSelector(selectVersion);

	const { tradeDirection, positionInfo } = useContext(TradeContext);
	const { setIsVisibleForm } = useContext(MobileActionContext);

	const isLogin = useCheckLogin();
	const { currentChainId } = useCurrentChain();

	const routerContractAddress = useMemo(() => {
		return getContractAddress(currentChainId, 'Router');
	}, [currentChainId]);

	const routerContractV2Address = useMemo(() => {
		return getContractAddressV2(currentChainId, 'Router');
	}, [currentChainId]);

	const orderBookContractAddress = useMemo(() => {
		return getContractAddress(currentChainId, 'OrderBook');
	}, [currentChainId]);

	const orderBookContractV2Address = useMemo(() => {
		return getContractAddressV2(currentChainId, 'OrderBook');
	}, [currentChainId]);

	const { currentApproveState, approveCallback } = useApproval(
		quoteToken,
		currentMarginDelta,
		routerContractAddress
	);

	// 创建订单授权
	const { isPluginApproved, isPluginApproving, onApprovePlugin } =
		useApprovalPlugin(orderBookContractAddress);

	const { onConfirm, isConfirming, isConfirmed, errMsg } =
		useSubmitIncreaseLimitOrder(currentApproveState, isPluginApproved);

	const formatedPrice = useMemo(() => {
		if (!poolInfo || !isPositive(currentPrice)) {
			return '';
		}
		return toDecimalPlaces(currentPrice || '', poolInfo.baseToken.precision);
	}, [currentPrice, poolInfo]);

	const canSubmit = useMemo(() => {
		if (poolInfo && isPositive(formatedPrice)) {
			if (
				tradeDirection === Side.LONG &&
				isGreaterThan(currentLimitPrice, formatedPrice)
			) {
				return false;
			}
			if (
				tradeDirection === Side.SHORT &&
				isLessThan(currentLimitPrice, formatedPrice)
			) {
				return false;
			}
		}
		if (
			!poolInfo ||
			!isPositive(currentLimitPrice) ||
			!isPositive(quoteBalance) ||
			!isPositive(currentMarginDelta) ||
			!isPositive(currentPositionAmount)
		) {
			return false;
		}
		if (!isPositive(currentInitialMargin)) {
			return false;
		}
		if (isLessThan(quoteBalance, currentMarginDelta)) {
			return false;
		}
		if (currentApproveState === ApprovalState.NOT_APPROVED) {
			return true;
		}
		if (currentApproveState === ApprovalState.PENDING) {
			return false;
		}
		if (!isPluginApproved) {
			return true;
		}
		if (isPluginApproving) {
			return false;
		}
		if (!isPositive(nativeBalance)) {
			return false;
		}
		if (isLessThan(currentMarginDelta, poolInfo.minMarginPerPosition)) {
			return false;
		}
		if (isGreaterThan(leverage, poolInfo.maxLeveragePerPosition)) {
			return false;
		}
		if (signingMap.get(transactionType)) {
			return false;
		}
		if (isConfirming) {
			return false;
		}
		if (errMsg && errMsg.indexOf(MSG_ERROR_APPROVE) >= 0) {
			return true;
		}
		return true;
	}, [
		poolInfo,
		currentMarginDelta,
		currentPositionAmount,
		currentApproveState,
		isPluginApproved,
		isPluginApproving,
		quoteBalance,
		nativeBalance,
		leverage,
		isConfirming,
		currentInitialMargin,
		signingMap,
		transactionType,
		tradeDirection,
		currentLimitPrice,
		formatedPrice,
		errMsg
	]);

	const submitText = useMemo(() => {
		if (poolInfo && isPositive(formatedPrice)) {
			if (
				tradeDirection === Side.LONG &&
				isGreaterThan(currentLimitPrice, formatedPrice)
			) {
				return <Trans>Cannot exceed the market price</Trans>;
			}
			if (
				tradeDirection === Side.SHORT &&
				isLessThan(currentLimitPrice, formatedPrice)
			) {
				return <Trans>Cannot be lower than the market price</Trans>;
			}
		}
		if (
			poolInfo &&
			isPositive(currentMarginDelta) &&
			isPositive(currentPositionAmount)
		) {
			// 用户输入的支付金额 < 开仓需要支付的费用
			if (!isPositive(currentInitialMargin)) {
				return <Trans>Margin can not be less than 0</Trans>;
			}
			// token balance
			if (
				isGreaterThanOrEqual(quoteBalance, 0) &&
				isLessThan(quoteBalance, currentMarginDelta)
			) {
				return <Trans>Insufficient Balance</Trans>;
			}
			// 授权token中
			if (currentApproveState === ApprovalState.PENDING) {
				return (
					<span>
						<Trans>Approving...</Trans>
					</span>
				);
			}
			// 未授权token
			if (currentApproveState === ApprovalState.NOT_APPROVED) {
				return (
					<span>
						<Trans>Approve {`${shortenSymbol(quoteToken)}`}</Trans>
					</span>
				);
			}
			if (!isPluginApproved) {
				return <Trans>Approve Operation (one-time)</Trans>;
			}
			if (isPluginApproving) {
				return <Trans>Approving...</Trans>;
			}
			if (isLessThan(currentMarginDelta, poolInfo.minMarginPerPosition)) {
				return (
					<Trans>
						Min payment: {poolInfo.minMarginPerPosition}{' '}
						{shortenSymbol(quoteToken)}
					</Trans>
				);
			}
			if (isGreaterThan(leverage, poolInfo.maxLeveragePerPosition)) {
				return (
					<Trans>
						Exceeds the max leverage of {poolInfo.maxLeveragePerPosition}x
					</Trans>
				);
			}

			// fee balance
			if (!isPositive(nativeBalance)) {
				return <Trans>Insufficient Balance</Trans>;
			}
		}
		if (signingMap.get(transactionType)) {
			return <Trans>Loading...</Trans>;
		}
		if (isConfirming) {
			return <Trans>Submitting...</Trans>;
		}
		if (errMsg && errMsg.indexOf(MSG_ERROR_APPROVE) >= 0) {
			return (
				<span>
					<Trans>Approve {`${shortenSymbol(quoteToken)}`}</Trans>
				</span>
			);
		}
		return tradeDirection === Side.LONG ? (
			<Trans>Long</Trans>
		) : (
			<Trans>Short</Trans>
		);
	}, [
		poolInfo,
		currentMarginDelta,
		currentPositionAmount,
		currentApproveState,
		isPluginApproved,
		isPluginApproving,
		quoteBalance,
		nativeBalance,
		transactionType,
		leverage,
		isConfirming,
		currentInitialMargin,
		signingMap,
		tradeDirection,
		currentLimitPrice,
		formatedPrice,
		errMsg
	]);

	useUpdateEffect(() => {
		if (isConfirming) {
			dispatch(reset());
		}
	}, [isConfirming]);

	useUpdateEffect(() => {
		if (isConfirmed) {
			setIsVisibleForm(false);
		}
	}, [isConfirmed]);

	const [open, setOpen] = useState(false);
	const onClose = () => setOpen(false);
	const { orders } = useAppSelector(tradeBaseState);
	const Order_Open_Type = useAppSelector(selectOrderOpenType);
	const tpSlOrders = orders.filter(
		order => order.type !== Order_Open_Type.Increase
	);
	const sameSideOrders = tpSlOrders.filter(
		order => order.side === tradeDirection && order.poolId === poolInfo?.id
	);
	const hasSameSideOrder = sameSideOrders.length !== 0;

	const beforeConfirm = () => {
		if (hasSameSideOrder) {
			setOpen(true);
			return;
		} else {
			onHandleConfirm();
		}
	};
	const onHandleConfirm = debounce(() => {
		if (
			currentApproveState === ApprovalState.NOT_APPROVED ||
			(errMsg && errMsg.indexOf(MSG_ERROR_APPROVE) >= 0)
		) {
			approveCallback();
			return;
		}
		if (isPluginApproved === false) {
			onApprovePlugin();
		}

		onConfirm();
	}, 300);

	return (
		<div>
			{!isLogin ? (
				<ConnectWalletButton fullWidth className='h-12' />
			) : (
				<div className={classNames(!canSubmit && 'cursor-not-allowed')}>
					<CommonStyledButton
						className='flex flex-col justify-center'
						variant='contained'
						componentVariant={tradeDirection === Side.LONG ? 'long' : 'short'}
						size='large'
						fullWidth
						disabled={!canSubmit}
						onClick={beforeConfirm}
					>
						<Typography
							className='whitespace-normal'
							color='inherit'
							variant='h6'
							fontWeight={500}
						>
							{submitText}
						</Typography>
					</CommonStyledButton>
				</div>
			)}
			<ConfirmDialog
				open={open}
				onClose={onClose}
				onConfirm={onHandleConfirm}
				orders={sameSideOrders}
			/>
		</div>
	);
});
