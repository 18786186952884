import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState
} from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans } from '@lingui/macro';
import { Divider, FormControlLabel, Paper } from '@mui/material';
import { Dom_Size, Trade_Type } from 'config/constants';
import { TradeContext } from 'context/TradeContext';
import { useAccount } from 'wagmi';

import { IOrderItem, useMyOrdersGraph } from 'graphql/useMyOrdersGraph';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin } from 'hooks/useCurrentChain';
import { useV1TradePositionRequest } from 'hooks/useLoopMyPositions';
import { isEmpty } from 'lodash';
import CancelAllOrders from 'pages/components/Operations/CancelAllOrders';
import { globalBaseState } from 'state/global/slice';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { selectPoolIds } from 'state/pools/selector';
import { poolsBaseState } from 'state/pools/slice';
import { selectOrderOpenType, selectOrderStatus } from 'state/setting/selector';
import { setOnlyCurrent, settingBaseState } from 'state/setting/slice';
import { setOrders } from 'state/trade/slice';
import {
	checkOrderIsExpired,
	formatModuleNumber,
	isGreaterThan,
	isPositive
} from 'utils';

import { StyleCheckbox } from 'components/Common/StyleCheckbox';
import { CommonStyledDivider } from 'components/Common/Styled';
import StyledTab from 'components/Common/StyledTab';
import StyledTabs from 'components/Common/StyledTabs';
import SectionLoading from 'components/SectionLoading';

import ExpiredOrderWarning from '../components/ExpiredOrderWarning';
import HistoryList from '../components/Operations/HistoryList';
import OpenOrdersList from '../components/Operations/OpenOrdersList';
import PositionsList from '../components/Operations/PositionsList';
import ViewUpdaterV1 from '../components/ViewUpdaterV1';

export enum Tab_Type {
	Positions = 0,
	Orders = 1,
	History = 2
}

// View Detail Mode: 全局账户仓位列表
export default function Operation() {
	const dispatch = useAppDispatch();
	const { appTimer } = useAppSelector(globalBaseState);
	const { onlyCurrent } = useAppSelector(settingBaseState);
	const { poolAddress } = useAppSelector(poolsBaseState);
	const Order_Status = useAppSelector(selectOrderStatus);
	const Order_Open_Type = useAppSelector(selectOrderOpenType);
	const poolIds = useAppSelector(selectPoolIds);
	const { isMatchMobile, isMatchPad } = useAppBreakpoints();
	const { tradeType } = useContext(TradeContext);
	const [type, setType] = useState<Tab_Type>(Tab_Type.Positions);
	const isLogin = useCheckLogin();
	const { address } = useAccount();
	const targetPool = useMemo(() => {
		if (onlyCurrent) {
			return poolAddress;
		}
		return undefined;
	}, [poolAddress, onlyCurrent]);

	const { positionList, positionMap, isLoadingPositions } =
		useV1TradePositionRequest(targetPool);

	const {
		allOrderList,
		orderList,
		loading: isLoadingOrders,
		refetch: refetchOrders
	} = useMyOrdersGraph(onlyCurrent ? targetPool : '');

	useUpdateEffect(() => {
		if (isLogin) {
			refetchOrders();
		}
	}, [isLogin, address, appTimer]);

	const createdList = useMemo(() => {
		if (!orderList || isEmpty(orderList)) {
			return;
		}
		const _list = orderList.filter((item: IOrderItem) => {
			return item.orderStatus === Order_Status.Created;
		});
		return _list;
	}, [orderList]);

	const expiredOrders = useMemo(() => {
		if (!createdList) {
			return [];
		}
		return createdList.filter(order =>
			checkOrderIsExpired(order, positionMap, Order_Open_Type)
		);
	}, [createdList, positionMap]);

	useEffect(() => {
		dispatch(setOrders(expiredOrders));
	}, [expiredOrders]);

	const createdOrderTotal = useMemo(() => {
		if (isEmpty(createdList)) {
			return '0';
		}
		return createdList.length;
	}, [createdList]);

	const targetPoolsId = useMemo(() => {
		if (onlyCurrent && poolAddress) {
			return [poolAddress];
		}
		return poolIds;
	}, [poolAddress, poolIds, onlyCurrent]);

	const containerHeight = useMemo(() => {
		if (tradeType === Trade_Type.Market) {
			return Dom_Size.HEIGHT_OPERATION;
		} else {
			return Dom_Size.HEIGHT_OPERATION + 40;
		}
	}, [tradeType, isMatchMobile]);

	const onHandleChangeType = (value: Tab_Type) => {
		setType(value);
	};

	const onHandleChangeOnlyCurrent = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		dispatch(setOnlyCurrent(event.target.checked));
	};

	return (
		<Paper
			className='p-4 sm:p-3 box-border sm:w-full'
			sx={{
				minHeight: containerHeight
			}}
		>
			<div className='flex sm:block justify-between space-x-2 overflow-hidden'>
				<StyledTabs
					value={type}
					onChange={(event, newType) => onHandleChangeType(newType)}
				>
					<StyledTab
						className='pl-0 pr-3'
						label={
							<div className='space-x-0.5'>
								<span>
									<Trans>Positions</Trans>
								</span>
								{isLogin && positionList && isPositive(positionList.length) && (
									<span>{formatModuleNumber(positionList.length)}</span>
								)}
							</div>
						}
						value={Tab_Type.Positions}
					/>
					<StyledTab
						className='px-3'
						label={
							<div className='flex gap-1'>
								<div className='space-x-0.5'>
									<span>
										<Trans>Orders</Trans>
									</span>
									{isLogin && isPositive(createdOrderTotal) && (
										<span>{formatModuleNumber(createdOrderTotal)}</span>
									)}
								</div>
								<ExpiredOrderWarning
									createdOrderList={isLogin ? createdList : []}
									positionMap={positionMap}
								/>
							</div>
						}
						value={Tab_Type.Orders}
					/>
					<StyledTab
						className='px-3'
						label={<Trans>History</Trans>}
						value={Tab_Type.History}
					/>
				</StyledTabs>
				<div className='flex items-center space-x-2'>
					{isMatchPad &&
						isLogin &&
						type === Tab_Type.Orders &&
						isGreaterThan(createdOrderTotal, 0) && (
							<CancelAllOrders list={createdList} />
						)}
					{!isMatchMobile && (
						<FormControlLabel
							control={
								<StyleCheckbox
									className='py-0 -mr-1'
									onChange={onHandleChangeOnlyCurrent}
									size='small'
									checked={onlyCurrent}
								/>
							}
							label={<Trans>Hide other markets</Trans>}
							sx={{ marginRight: 0 }}
						/>
					)}
				</div>
			</div>
			<CommonStyledDivider type='solid' />
			{isMatchMobile && (
				<div className='space-y-3 mb-3'>
					<div className='flex justify-between h-6'>
						<FormControlLabel
							control={
								<StyleCheckbox
									className='py-0 -mr-1'
									onChange={onHandleChangeOnlyCurrent}
									size='small'
									checked={onlyCurrent}
								/>
							}
							label={<Trans>Hide other markets</Trans>}
							sx={{ fontSize: 12 }}
						/>
						{type === Tab_Type.Orders &&
							isLogin &&
							isGreaterThan(createdOrderTotal, 0) && (
								<CancelAllOrders list={createdList} />
							)}
					</div>
					<Divider />
				</div>
			)}
			{!poolAddress ? (
				<SectionLoading />
			) : (
				<article>
					{type === Tab_Type.Positions &&
						(isLoadingPositions || (isLogin && !positionMap) ? (
							<SectionLoading />
						) : (
							<PositionsList
								list={isLogin ? positionList : null}
								poolId={targetPool}
							/>
						))}

					{type === Tab_Type.Orders &&
						(isLoadingOrders ? (
							<SectionLoading />
						) : (
							<OpenOrdersList
								list={isLogin ? orderList : null}
								poolId={targetPool}
								positionMap={positionMap}
							/>
						))}

					{type === Tab_Type.History && (
						<HistoryList targetPoolsId={targetPoolsId} />
					)}
				</article>
			)}
			<ViewUpdaterV1 positionMap={positionMap} orderList={allOrderList} />
		</Paper>
	);
}
