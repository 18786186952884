import { useEffect, useMemo, useState } from 'react';

import { QUOTE_USD_PRECISION, Side } from 'config/constants';
import { usePoolsFetch } from 'fetch/usePoolsFetch';

import { isEmpty, isNull } from 'lodash';
import { catchFn, formatUnits, isPositive, toDecimalPlaces } from 'utils';
import { div, minus, multipliedBy, plus } from 'utils/mathUtils';

import { LiquidityPositionUtil } from '../entities/LiquidityPositionUtil';
import { useLiquidityPositionsFetch } from '../fetch/useLiquidityPositionsFetch';
import { useCheckLogin } from '../hooks/useCurrentChain';
import { useAppSelector } from '../state/hooks';
import { poolsBaseState } from '../state/pools/slice';

const useLpPositions = () => {
	const { data, isValidating, isInit } = useLiquidityPositionsFetch();
	const isLogin = useCheckLogin();
	const { pools: poolList } = useAppSelector(poolsBaseState);

	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		if (isLoading) {
			setIsLoading(isValidating);
		}
	}, [isValidating]);
	useEffect(() => {
		if (isLogin) {
			setIsLoading(true);
		}
	}, [isLogin]);

	const [pools, setPools] = useState([]);
	const [result, setResult] = useState([]);
	const [requestData, setRequestData] = useState({
		adjustLiquidityPositionMarginRequestsMap: new Map(),
		closeLiquidityPositionRequestsMap: new Map(),
		openLiquidityPositionRequestsMap: new Map()
	});
	useEffect(() => {
		if (isEmpty(poolList)) {
			setPools([]);
			return;
		}
		const tempPools = poolList.map(item => {
			const indexPriceX96 = item.indexPriceX96;
			const myLiquidity = data.positions
				.filter(position => position.market.toLowerCase() === item.id)
				.reduce((pre, cur) => plus(pre, cur.liquidity), '0');

			return {
				...item,
				address: item.address,
				baseSymbol: item.baseToken.symbol,
				baseToken: item.baseToken,
				liquidationExecutionFee: item.liquidationExecutionFee,
				liquidationFeeRatePerLiquidityPosition:
					item.liquidationFeeRatePerLiquidityPosition,
				globalLiquidityPosition: item.globalLiquidityPosition,
				id: item.id,
				indexPrice: item.indexPrice,
				indexPriceX96,
				liquidity: item.globalLiquidityPosition.liquidity,
				liquidityPositions: [],
				priceState1Hs: item.priceState,
				totalRealizedPnL: item.globalLiquidityPosition.realizedProfit,
				totalRevenue: item.globalLiquidityPosition.realizedProfit,
				tradingFee: item.globalLiquidityPosition.tradingFee,
				feeAvgApr: item.feeAvgApr,
				fundingFeeAvgApr: item.fundingFeeAvgApr,
				rewardAvgApr: item.rewardAvgApr,
				liquidityAvgApr: item.liquidityAvgApr,
				feeMaxApr: item.feeMaxApr,
				fundingFeeMaxApr: item.fundingFeeMaxApr,
				rewardMaxApr: item.rewardMaxApr,
				liquidityMaxApr: item.liquidityMaxApr,
				maxApr: item.liquidityMaxApr,
				tradingFee24h: plus(item.fee24h, item.fundingFee24h),
				myLiquidity
			};
		});
		setPools(tempPools);
	}, [poolList, data]);

	useEffect(() => {
		if (isInit) {
			return;
		}
		const temp = data.positions.reduce((pools, position) => {
			return pools.map(pool => {
				if (pool.id === position.market.toLowerCase()) {
					const floatRealizedProfit = catchFn(() => {
						return LiquidityPositionUtil.calculatePositionRealizedProfit(
							pool.globalLiquidityPosition.realizedProfitGrowth,
							position.entryRealizedProfitGrowth,
							position.liquidity
						);
					}, '0');
					const totalRealizedProfit = plus(
						position.profitBalance,
						floatRealizedProfit
					);
					const marginNet = isPositive(position.margin) ? position.margin : '';
					const positionPnl = position.pnl;

					const passivePositionPnl =
						position.liquidityPassivePosition.side === Side.LONG
							? multipliedBy(
									minus(
										position?.liquidityPassivePosition?.indexPrice,
										position?.liquidityPassivePosition?.entryPrice
									),
									position?.liquidityPassivePosition?.size
							  )
							: multipliedBy(
									minus(
										position?.liquidityPassivePosition?.entryPrice,
										position?.liquidityPassivePosition?.indexPrice
									),
									position?.liquidityPassivePosition?.size
							  );
					const riskRatio = catchFn(() => {
						if (!isPositive(marginNet)) {
							return '1';
						}
						return LiquidityPositionUtil.calcV2RiskRate(
							position.liquidity,
							pool.liquidationFeeRatePerLiquidityPosition,
							pool.liquidationExecutionFee,
							marginNet,
							passivePositionPnl
						);
					}, '0');
					const balanceRate = div(
						multipliedBy(pool.indexPrice, pool.globalLiquidityPosition.netSize),
						pool.globalLiquidityPosition.liquidity
					);

					// 把中心化接口的数据格式转化成业务数据格式
					const formattedPosition = {
						address: position.market,
						balanceRate,
						baseSymbol: pool.baseToken.symbol,
						baseToken: pool.baseToken,
						entryTxHash: position.entryTxHash,
						floatRealizedProfit,
						globalLiquidity: pool.globalLiquidityPosition.liquidity,
						globalLiquidityPosition: pool.globalLiquidityPosition,
						globalNetLiquidity: toDecimalPlaces(
							multipliedBy(
								pool.globalLiquidityPosition.netSize,
								pool.globalLiquidityPosition.entryPrice
							),
							QUOTE_USD_PRECISION
						),
						id: position.id,
						leverage: position.leverage,
						liquidity: position.liquidity,
						liquidationExecutionFee: formatUnits(
							pool.liquidationExecutionFee,
							QUOTE_USD_PRECISION
						),
						initialMargin: plus(
							position.marginDecreased,
							position.marginIncreased
						),
						marginIncreased: position.marginIncreased,
						marginDecreased: position.marginDecreased,
						margin: position.margin,
						marginNet,
						marginRate: LiquidityPositionUtil.calcMarginRate(
							marginNet,
							position.liquidity
						),
						maxRiskRatePerLiquidityPosition:
							pool.maxRiskRatePerLiquidityPosition,
						myLiquidity: position.liquidity,
						riskRatio,
						status: position.status,
						totalRealizedProfit,
						pnl: positionPnl,
						utilizedLeverage: position.utilizedLeverage,
						tradingFee: position.fee,
						fundingFee: position.fundingFee,
						liquidityPassivePosition: {
							...position.liquidityPassivePosition,
							pnl: passivePositionPnl,
							address: pool.address,
							myLeverage: !isNull(position.liquidityPassivePosition.size)
								? div(
										multipliedBy(
											position.liquidityPassivePosition.size,
											position.liquidityPassivePosition.entryPrice
										),
										marginNet
								  )
								: null
						}
					};

					const liquidityPositions = [
						...pool.liquidityPositions,
						formattedPosition
					];
					return {
						...pool,
						liquidityPositions
					};
				} else {
					return pool;
				}
			});
		}, pools);
		setResult(temp);
		setRequestData(data.requestData);
	}, [data, pools]);

	return {
		data: result,
		requestData,
		isLoading
	};
};

export default useLpPositions;
