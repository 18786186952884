import React, { ForwardedRef, useMemo } from 'react';
import { TableVirtuoso } from 'react-virtuoso';

import { t } from '@lingui/macro';
import { Typography, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Dom_Size } from '../../config/constants';
import { useAppBreakpoints } from '../../hooks/useAppBreakpoints';
import NoData from '../NoData';

const VirtualTable = (props: {
	columns: any[];
	rows: any[];
	maxHeight?: number;
	itemHeight?: number;
	isPointer?: boolean;
	cellColor?: string;
	noDataMsg?: React.ReactNode;
	onRowClick?: (row: any) => void;
	noDataNode?: React.ReactNode;
	loadMore?: (index: number) => void;
}) => {
	const {
		columns,
		rows,
		maxHeight = Dom_Size.LIST_SCOLL,
		itemHeight = 40,
		isPointer = false,
		cellColor,
		noDataMsg = '',
		onRowClick = () => void 0,
		loadMore = () => void 0,
		noDataNode = (
			<NoData className='text-sm'>{noDataMsg ? noDataMsg : t`No Data`}</NoData>
		)
	} = props;

	const { isMatchMobile } = useAppBreakpoints();
	const theme = useTheme();

	const tableComponents = useMemo(
		() => ({
			Table,
			TableHead: React.forwardRef(
				(props: any, ref: ForwardedRef<HTMLTableSectionElement>) => (
					<TableHead {...props} ref={ref}>
						<tr>{props.children}</tr>
					</TableHead>
				)
			),
			TableRow: (props: any) => (
				<TableRow
					{...props}
					hover
					role='checkbox'
					tabIndex={-1}
					sx={{
						cursor: isPointer ? 'pointer' : 'default',
						backgroundColor: cellColor
							? cellColor
							: theme.palette.background.paper
					}}
					onClick={() => onRowClick(props.item)}
				>
					{props.children}
				</TableRow>
			)
		}),
		[]
	);
	const backgroundColor = cellColor
		? cellColor
		: theme.palette.background.paper;

	return (
		<Paper sx={{ background: cellColor }} className='w-full'>
			{rows.length === 0 && noDataNode}
			{rows.length !== 0 && (
				<TableVirtuoso
					endReached={loadMore}
					style={{
						height: Math.min(maxHeight, itemHeight * (rows.length + 1))
					}}
					data={rows}
					components={tableComponents}
					fixedHeaderContent={() =>
						columns.map((column, index) => (
							<TableCell
								key={index}
								align={column.align}
								style={{
									minWidth: column.minWidth,
									width: column.width,
									backgroundColor,
									color: theme.palette.text.secondary,
									padding: !isMatchMobile ? void 0 : 0
								}}
								className='capitalize'
							>
								<Typography variant='body2'>{column.label}</Typography>
							</TableCell>
						))
					}
					itemContent={(index, row) =>
						columns.map((column, index) => (
							<TableCell
								key={index}
								align={column.align}
								style={{
									padding: !isMatchMobile ? void 0 : 0,
									backgroundColor,
									height: isMatchMobile ? void 0 : 44
								}}
							>
								<Typography variant='body2'>{column.format(row)}</Typography>
							</TableCell>
						))
					}
				/>
			)}
		</Paper>
	);
};

export default VirtualTable;
