import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import { Badge, Typography } from '@mui/material';
import { Transaction_Type, Version } from 'config/constants';
import { ReferralsContextProvider } from 'context/ReferralsContext';

import ActivityContainer from 'pages/components/ActivityContainer';
import { useAppSelector } from 'state/hooks';
import { selectTransactionRecordList } from 'state/records/selector';
import ReferralsUpdater from 'state/referrals/updater';

import V2Logo from '../components/V2/V2Logo';
import ClaimDialog from './ClaimDialog';
import Content from './Content';
import DirectClaimDialog from './DirectClaimDialog';
import { Type_Referrals } from './types';

export default function Referrals() {
	const transactionRecords = useAppSelector(selectTransactionRecordList);
	const currentVersion = useAppSelector(state => state.setting.currentVersion);

	const { type: routerType } = useParams();

	const [directVisibleClaim, setDirectVisibleClaim] = useState<boolean>(false);
	const [visibleClaim, setVisibleClaim] = useState<boolean>(false);

	const { claimMembersRecords, claimConnectorsRecords } = useMemo(() => {
		const claimMembersRecords = transactionRecords.filter(
			record => record.type === Transaction_Type.ClaimMembers
		);
		const claimMembersRecordsV2 = transactionRecords.filter(
			record => record.type === Transaction_Type.ClaimMembersV2
		);
		const claimConnectorsRecords = transactionRecords.filter(
			record => record.type === Transaction_Type.ClaimConnectors
		);
		const claimConnectorsRecordsV2 = transactionRecords.filter(
			record => record.type === Transaction_Type.ClaimConnectorsV2
		);
		return {
			claimMembersRecords:
				currentVersion === Version.V1
					? claimMembersRecords
					: claimMembersRecordsV2,
			claimConnectorsRecords:
				currentVersion === Version.V1
					? claimConnectorsRecords
					: claimConnectorsRecordsV2
		};
	}, [transactionRecords, currentVersion]);

	const activeTab = useMemo(() => {
		if (routerType === Type_Referrals.Connector) {
			return Type_Referrals.Connector;
		} else if (routerType === Type_Referrals.Members) {
			return Type_Referrals.Members;
		} else {
			return Type_Referrals.Traders;
		}
	}, [routerType]);

	const isClaimLoading = useMemo(() => {
		if (activeTab === Type_Referrals.Members && claimMembersRecords.length) {
			return true;
		}
		if (
			activeTab === Type_Referrals.Connector &&
			claimConnectorsRecords.length
		) {
			return true;
		}
		return false;
	}, [activeTab, claimMembersRecords, claimConnectorsRecords]);

	// const isClaimAllLoading = useMemo(() => {
	// 	return claimMembersRecords.length > 0 || claimMembersRecords.length > 0;
	// }, [claimMembersRecords, claimConnectorsRecords]);

	const onDirectClaim = () => {
		setDirectVisibleClaim(true);
	};

	const onOpenClaim = () => {
		setVisibleClaim(true);
	};

	return (
		<ReferralsContextProvider>
			<ActivityContainer
				title={
					<div className='flex items-center'>
						<Typography variant='inherit'>
							<Trans>Referrals</Trans>
						</Typography>
						{currentVersion === Version.V2 && (
							<V2Logo className='mt-1 ml-1.5' width={30} height={21} />
						)}
					</div>
				}
				// subTitle={
				// 	<Typography
				// 		component='span'
				// 		variant='h5'
				// 		color='text.secondary'
				// 		className='leading-7 text-sm sm:text-xs mt-4'
				// 	>
				// 		<Trans>Earn mining rewards and trading fee income by referral.</Trans>
				// 	</Typography>
				// }
			>
				{/* <Summary isClaimAllLoading={isClaimAllLoading} /> */}
				<Content
					onDirectClaim={onDirectClaim}
					onClaim={onOpenClaim}
					claimDisabled={isClaimLoading}
					isClaimLoading={isClaimLoading}
				/>
				{directVisibleClaim && (
					<DirectClaimDialog
						type={activeTab}
						open={true}
						onClose={() => {
							setDirectVisibleClaim(false);
						}}
					/>
				)}
				{visibleClaim && (
					<ClaimDialog
						type={activeTab}
						open={true}
						onClose={() => {
							setVisibleClaim(false);
						}}
					/>
				)}
			</ActivityContainer>
			<ReferralsUpdater />
		</ReferralsContextProvider>
	);
}
