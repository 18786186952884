import React from 'react';

import { Trans } from '@lingui/macro';
import { Typography, useTheme } from '@mui/material';

import { IPositionItem } from 'graphql/useMyPositionsGraph';
import { useCurrentChain } from 'hooks/useCurrentChain';
import { formatNumber, isPositive, shortenSymbolNative } from 'utils';

import Cell from 'components/Common/Cell';
import ExecutionFee from 'components/ExecutionFee';
import { useAppSelector } from 'state/hooks';
import { globalBaseState } from 'state/global/slice';

export default React.memo(function ProfitLossCells({
	item,
	size,
	quantity
}: {
	item: IPositionItem;
	size: string;
	quantity: number;
}) {
	const { orderMinExecutionFee } = useAppSelector(globalBaseState);

	const theme = useTheme();
	const { currentChainId } = useCurrentChain();

	return (
		<>
			<div className='space-y-1'>
				<Cell
					label={
						<Typography variant='body2'>
							<Trans>Size</Trans>
						</Typography>
					}
					value={
						isPositive(size)
							? formatNumber(size, item.baseToken.positionUnits, true) +
							  ' ' +
							  shortenSymbolNative(item.baseToken, currentChainId)
							: '-'
					}
				/>

				<ExecutionFee quantity={quantity} executionFee={orderMinExecutionFee} />
			</div>
			<Typography
				variant='body2'
				color={theme.palette.text.secondary}
				className='mt-3'
			>
				<Trans>
					When the market price reaches the trigger price, the system will close
					the position at the{' '}
					<Typography
						component='span'
						variant='body2'
						color={theme.palette.text.primary}
						sx={{ fontWeight: 500 }}
					>
						market price
					</Typography>
					.
				</Trans>
			</Typography>
		</>
	);
});
