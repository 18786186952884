import { useEffect, useMemo } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans, t } from '@lingui/macro';
import { Box, Paper, Typography } from '@mui/material';
import {
	Mint_Type,
	Transaction_Status,
	Transaction_Type
} from 'config/constants';

// import { getContractAddress } from 'config/contracts';
import { TransactionRecord } from 'entities/TransactionRecord';
import { useConnectorHistoriesGraph } from 'graphql/useConnectorHistoriesGraph';
import { useManageTransactions } from 'hooks/useAccountTransactions';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import _ from 'lodash';
import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import { selectTransactionRecordList } from 'state/records/selector';
import { IMintHistory, RecordMintMember } from 'types';
import {
	ExplorerDataType,
	getExplorerLink,
	shortenAddress,
	shortenHash
} from 'utils';

import StyledLink from 'components/Common/StyledLink';
import Module from 'components/Module';
import NoData from 'components/NoData';
import SectionLoading from 'components/SectionLoading';
import Loading from 'components/Svg/Icons/Loading';
import Table from 'components/Table/StickyTable';

function convert(txRecord: TransactionRecord) {
	const record = txRecord.record as RecordMintMember;
	return record.receivers.map((receiver: string) => {
		return {
			minter: '',
			receiver,
			blockNumber: '',
			blockTimestamp: '',
			connectorTokenID: record.connectorTokenId,
			tokenID: '',
			txHash: txRecord.hash,
			type: Mint_Type.Connector,
			status: Transaction_Status.Pending
		} as IMintHistory;
	});
}

export default function MyConnectorNFT() {
	const { appTimer } = useAppSelector(globalBaseState);
	const transactionRecordList = useAppSelector(selectTransactionRecordList);

	const { currentChain: chain } = useCurrentChain();
	const { isMatchMobile, isMatchPad } = useAppBreakpoints();
	const isLogin = useCheckLogin();

	const mintMemberRecords = useMemo(() => {
		return transactionRecordList.filter((tx: TransactionRecord) => {
			return tx.type === Transaction_Type.MintMember;
		});
	}, [transactionRecordList]);

	const { removeTransaction } = useManageTransactions();
	const { data, refetch } = useConnectorHistoriesGraph();

	useUpdateEffect(() => {
		if (isLogin) {
			refetch();
		}
	}, [isLogin, appTimer]);

	const list = useMemo(() => {
		const recordList = data ?? [];
		if (!mintMemberRecords || !mintMemberRecords.length) {
			return data;
		}
		let convertedList = [] as Array<IMintHistory>;
		mintMemberRecords.forEach((item: TransactionRecord) => {
			convertedList = convertedList.concat(convert(item));
		});

		return convertedList.concat(recordList);
	}, [data, mintMemberRecords]);

	useEffect(() => {
		if (!data || !mintMemberRecords || mintMemberRecords.length === 0) {
			return;
		}
		const results = _.intersectionWith(
			mintMemberRecords,
			data,
			(txRecord, current: IMintHistory) => {
				return txRecord.hash === current.txHash;
			}
		);
		results.forEach((item: TransactionRecord) => {
			removeTransaction(item);
		});
	}, [data, mintMemberRecords]);

	const columns = [
		{
			key: 'mintHash',
			label: 'Mint hash',
			width: '30%',
			align: 'left',
			format: (row: IMintHistory) => {
				return (
					<div className='flex space-x-1'>
						<StyledLink
							className='font-normal'
							target='_blank'
							href={getExplorerLink(
								chain,
								row.txHash,
								ExplorerDataType.TRANSACTION
							)}
							type='white'
						>
							{shortenHash(row.txHash)}
						</StyledLink>
						{row.status === Transaction_Status.Pending && <Loading size={20} />}
					</div>
				);
			}
		},

		{
			key: 'usedNFT',
			label: <Trans>Used NFT</Trans>,
			width: '30%',
			align: 'left',
			format: (row: IMintHistory) => {
				return (
					<Typography variant='body2' fontWeight={400}>
						Connector #{row.connectorTokenID}
					</Typography>
				);
			}
		},
		{
			key: 'mintedNFT',
			label: <Trans>Minted NFT</Trans>,
			width: '20%',
			align: 'left',
			format: (row: IMintHistory) => {
				return (
					<Box>
						{row.tokenID ? (
							<Typography variant='body2' fontWeight={400}>
								Member #{row.tokenID}
							</Typography>
						) : (
							'-'
						)}
					</Box>
				);
			}
		},
		{
			key: 'reciveAddress',
			label: <Trans>Receiving Address</Trans>,
			align: 'right',
			format: (row: IMintHistory) => {
				return (
					<StyledLink
						className='font-normal'
						target='_blank'
						href={getExplorerLink(
							chain,
							row.receiver,
							ExplorerDataType.ADDRESS
						)}
						type='white'
					>
						{shortenAddress(row.receiver)}
					</StyledLink>
				);
			}
		}
	];

	const mobileColumns = [
		{
			key: 'mintHash',
			label: <Typography variant='body2'>Mint hash</Typography>,
			align: 'left',
			width: '50%',
			format: (row: any) => {
				return (
					<div className='flex space-x-1'>
						<StyledLink
							className='font-normal text-xs'
							target={isMatchMobile ? '_self' : '_blank'}
							href={getExplorerLink(
								chain,
								row.txHash,
								ExplorerDataType.TRANSACTION
							)}
							type='white'
						>
							{shortenHash(row.txHash)}
						</StyledLink>
						{row.status === Transaction_Status.Pending && <Loading size={20} />}
					</div>
				);
			}
		},
		{
			key: 'mintedNFT',
			label: (
				<Typography variant='body2'>
					<Trans>Minted NFT</Trans>
				</Typography>
			),
			align: 'right',
			width: '50%',
			format: (row: IMintHistory) => {
				// const EFCAddress = getContractAddress(currentChainId, 'EFC');
				return (
					<Box>
						{row.tokenID ? (
							<Typography variant='body2' fontWeight={400}>
								Member #{row.tokenID}
							</Typography>
						) : (
							'-'
						)}
					</Box>
				);
			}
		}
	];
	return (
		<Paper>
			<Module title={<Trans>Mint History</Trans>}>
				{!list ? (
					<SectionLoading />
				) : list.length > 0 ? (
					<Table
						fontSize={14}
						columns={isMatchMobile ? mobileColumns : columns}
						rows={list}
						origin={isMatchMobile || isMatchPad}
					/>
				) : (
					<NoData size='large'>{t`You haven't minted yet.`}</NoData>
				)}
			</Module>
		</Paper>
	);
}
