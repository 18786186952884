import { useMemo } from 'react';

import { Transaction_Type } from 'config/constants';

import { TransactionRecord } from 'entities/TransactionRecord';
import _ from 'lodash';
import { useAppSelector } from 'state/hooks';
import { selectTransactionRecordList } from 'state/records/selector';

export function useLocalOrders() {
	const transactionRecords = useAppSelector(selectTransactionRecordList);

	const [orderRecords, orderCancelAllRecords, orderAlldRecords] =
		useMemo(() => {
			const orderRecords = _.filter(
				transactionRecords,
				(txRecord: TransactionRecord) => {
					const record = txRecord.record;
					return (
						// 创建
						record.transactionType === Transaction_Type.LongLimitOrderV2 ||
						record.transactionType === Transaction_Type.ShortLimitOrderV2 ||
						record.transactionType ===
							Transaction_Type.CreateTakeProfitPositionV2 ||
						record.transactionType ===
							Transaction_Type.CreateStopLossPositionV2 ||
						record.transactionType ===
							Transaction_Type.CreateTakeProfitPartialV2 ||
						record.transactionType === Transaction_Type.CreateStopLossPartialV2 ||
						// 更新
						record.transactionType === Transaction_Type.UpdateLimitOrderV2 ||
						record.transactionType ===
							Transaction_Type.UpdateTakeProfitPositionV2 ||
						record.transactionType ===
							Transaction_Type.UpdateStopLossPositionV2 ||
						record.transactionType ===
							Transaction_Type.UpdateTakeProfitPartialV2 ||
						record.transactionType === Transaction_Type.UpdateStopLossPartialV2 ||
						// 取消
						record.transactionType === Transaction_Type.CancelledLimitOrderV2 ||
						record.transactionType ===
							Transaction_Type.CancelTakeProfitPositionV2 ||
						record.transactionType ===
							Transaction_Type.CancelStopLossPositionV2 ||
						record.transactionType ===
							Transaction_Type.CancelTakeProfitPartialV2 ||
						record.transactionType === Transaction_Type.CancelStopLossPartialV2
					);
				}
			);
			const orderCancelAllRecords = _.filter(
				transactionRecords,
				(txRecord: TransactionRecord) => {
					const record = txRecord.record;
					return (
						// 取消全部
						record.transactionType === Transaction_Type.CancelAllOrdersV2
					);
				}
			);
			const orderUnCreatedRecords = orderRecords.concat(orderCancelAllRecords);
			return [
				orderRecords,
				orderCancelAllRecords,
				orderUnCreatedRecords
			];
		}, [transactionRecords]);

	const orderHashes = useMemo(() => {
		return orderAlldRecords.map((txRecord: TransactionRecord) => {
			return txRecord.hash;
		});
	}, [orderAlldRecords]);

	return {
		orderHashes,
		orderRecords,
		orderCancelAllRecords,
		orderAlldRecords,
	};
}
