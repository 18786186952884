import React from 'react';

const ArrowOutward = (props: any) => {
	const { color = '#8182A3' } = props;

	return (
		<svg
			{...props}
			width='12'
			height='9'
			viewBox='0 0 12 9'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g opacity='0.8'>
				<path
					d='M9.08796 1.38867L2.54492 7.72448'
					stroke={color}
					strokeWidth='1.28571'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>

				<path
					d='M3.83789 1.38867H9.08789V6.63867'
					stroke={color}
					strokeWidth='1.28571'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</g>
		</svg>
	);
};

export default ArrowOutward;
