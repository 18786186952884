import { ReactNode, createContext, useMemo, useState } from 'react';

import { Side } from 'config/constants';

import { IPoolItem } from 'types';

export interface IComputerContext {
	currentPool: IPoolItem | null;
	setCurrentPool: (value: IPoolItem) => void;
	side: Side;
	setSide: (value: Side) => void;
	leverage: string;
	setLeverage: (value: string) => void;
	marketPrice: string;
	setMarketPrice: (value: string) => void;
}

export const ComputerContext = createContext<IComputerContext>({
	currentPool: null,
	setCurrentPool: (value: IPoolItem) => void value,
	side: Side.LONG,
	setSide: (value: Side) => void value,
	leverage: '',
	setLeverage: (value: string) => value,
	marketPrice: '',
	setMarketPrice: (value: string) => value
});

export function ComputerContextProvider({ children }: { children: ReactNode }) {
	const [currentPool, setCurrentPool] = useState<IPoolItem | null>(null);
	const [side, setSide] = useState<Side>(Side.LONG);
	const [leverage, setLeverage] = useState<string>('1');
	const [marketPrice, setMarketPrice] = useState<string>('0');

	const value = useMemo(() => {
		return {
			currentPool,
			setCurrentPool,
			side,
			setSide,
			leverage,
			setLeverage,
			marketPrice,
			setMarketPrice
		};
	}, [
		currentPool,
		setCurrentPool,
		side,
		setSide,
		leverage,
		setLeverage,
		marketPrice,
		setMarketPrice
	]);

	return (
		<ComputerContext.Provider value={value}>
			{children}
		</ComputerContext.Provider>
	);
}
