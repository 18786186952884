import { useMemo } from 'react';

import { t } from '@lingui/macro';

import { isEmpty } from 'lodash';
import { useAppSelector } from 'state/hooks';
import { poolsBaseState } from 'state/pools/slice';
import { amountFormatter, multipliedBy, plus, toQuoteAmount } from 'utils';

import { DirectionCell } from '../../components/Common/Cell';

interface SummaryDataProps {
	volume: number | string;
	liquidity: number | string;
	riskBufferFund: number | string;
	openInterest: number | string;
}

export default function Summary() {
	const { pools, poolMap } = useAppSelector(poolsBaseState);
	const summaryData = useMemo(() => {
		if (isEmpty(pools)) {
			return {
				volume: 0,
				liquidity: 0,
				riskBufferFund: 0,
				openInterest: 0
			};
		}
		return pools.reduce(
			(all: SummaryDataProps, current: any) => {
				const currentOpenInterestBase = Math.max(
					Number(current.globalPosition.longSize),
					Number(current.globalPosition.shortSize)
				);
				const currentOpenInterest = multipliedBy(
					multipliedBy(currentOpenInterestBase, 2),
					current?.index_price
				);
				return {
					...all,
					volume: plus(all.volume, current?.volumeUSD),
					liquidity: plus(
						all.liquidity,
						current.globalLiquidityPosition.liquidity
					),
					openInterest: plus(all.openInterest, currentOpenInterest)
				};
			},
			{
				volume: 0,
				liquidity: 0,
				riskBufferFund: 0,
				openInterest: 0
			}
		);
	}, [pools]);
	return (
		<div className='grid grid-cols-3 gap-2 px-3'>
			<DirectionCell
				label={t`Total Volume`}
				value={
					<div className='text-sm'>
						{summaryData.volume === 0
							? '-'
							: `$${amountFormatter(summaryData.volume, 2)}`}
					</div>
				}
				align='left'
			/>
			<DirectionCell
				label={t`Open Interest`}
				value={
					<div className='text-sm'>
						{summaryData.openInterest === 0
							? '-'
							: `$${amountFormatter(summaryData.openInterest, 2)}`}
					</div>
				}
				align='center'
			/>
			<DirectionCell
				label={t`Total Liquidity`}
				value={
					<div className='text-sm'>
						{summaryData.liquidity === 0
							? '-'
							: `$${amountFormatter(summaryData.liquidity, 2)}`}
					</div>
				}
				align='right'
			/>
		</div>
	);
}
