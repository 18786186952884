import { Reducer, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'state';

interface StateProps {
	isLoadingPool2Rewards: boolean;
	totalAppAmount: string | undefined;
	claimableAppAmount: string | undefined;
	dailyEmission: string | undefined;
	uniAPR: string;
	uniTvl: string;
}

const initialState: StateProps = {
	isLoadingPool2Rewards: true,
	totalAppAmount: undefined,
	claimableAppAmount: undefined,
	dailyEmission: undefined,
	uniAPR: '',
	uniTvl: '',
};

export const slice = createSlice({
	name: 'earnPool2',
	initialState,
	reducers: {
		setIsLoadingPool2Rewards(state, { payload }) {
			state.isLoadingPool2Rewards = payload;
		},
		setTotalAppAmount(state, { payload }) {
			state.totalAppAmount = payload;
		},
		setClaimableAppAmount(state, { payload }) {
			state.claimableAppAmount = payload;
		},
		setDailyEmission(state, { payload }) {
			state.dailyEmission = payload;
		},
		setUniAPR(state, { payload }) {
			state.uniAPR = payload;
		},
		setUniTvl(state, { payload }) {
			state.uniTvl = payload;
		},
	}
});

export const {
	setIsLoadingPool2Rewards,
	setTotalAppAmount,
	setClaimableAppAmount,
	setDailyEmission,
	setUniAPR,
	setUniTvl,
} = slice.actions;

export const earnPool2BaseState = (state: RootState) => state.earnPool2;

export default slice.reducer as Reducer<typeof initialState>;
