import React, { useContext, useMemo, useState } from 'react';

import { Trans } from '@lingui/macro';
import { CardContent, Tooltip as MuiTooltip, Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {
	MAX_PRECISION,
	QUOTE_USD,
	QUOTE_USD_PRECISION
} from 'config/constants';

import { PositionUtil } from 'entities/PositionUtil';
import { useCurrentChain } from 'hooks/useCurrentChain';
import {
	checkInputNumberic,
	isPositive,
	shortenSymbolNative,
	toDecimalPlaces
} from 'utils';

import Cell from 'components/Common/Cell';
import {
	CommonStyledDivider,
	CommonStyledFilledInput,
	CommonStyledMaxButton
} from 'components/Common/Styled';
import CommonButton from 'components/Common/StyledButton';

import LiqPrice from '../../../components/LiqPrice';
import TippingUnderline from '../../../components/TippingUnderline';
import { useAppBreakpoints } from '../../../hooks/useAppBreakpoints';
import { ComputerContext } from './ComputerContext';
import ComputerDirection from './ComputerDirection';
import { StyledCard } from './StyledCard';

export default React.memo(function ComputerLiquidationPrice() {
	const { side, currentPool, marketPrice } = useContext(ComputerContext);

	const { currentChainId } = useCurrentChain();

	const [targetLiqPrice, setTargetLiqPrice] = useState<string>('');
	const [entryPrice, setEntryPrice] = useState<string>('');
	const [amount, setAmount] = useState<string>('');
	const [balance, setBalance] = useState<string>('');

	const onChangeEntryPrice: React.ChangeEventHandler<HTMLInputElement> = e => {
		const value = e.target.value;
		if (value.trim() === '') {
			setEntryPrice('');
		}
		if (checkInputNumberic(value, QUOTE_USD_PRECISION)) {
			setEntryPrice(value);
		}
	};

	const onChangeAmount: React.ChangeEventHandler<HTMLInputElement> = e => {
		const value = e.target.value;
		if (value.trim() === '') {
			setAmount('');
		}
		if (checkInputNumberic(value, currentPool?.baseToken.decimals)) {
			setAmount(value);
		}
	};

	const onChangeBalance: React.ChangeEventHandler<HTMLInputElement> = e => {
		const value = e.target.value;
		if (value.trim() === '') {
			setBalance('');
		}
		if (checkInputNumberic(value, QUOTE_USD_PRECISION)) {
			setBalance(value);
		}
	};

	const onHandleSetMarketPrice = () => {
		if (isPositive(marketPrice)) {
			setEntryPrice(
				toDecimalPlaces(marketPrice, currentPool?.baseToken.precision)
			);
		}
	};

	const disabled = useMemo(() => {
		if (
			!isPositive(entryPrice) ||
			!isPositive(amount) ||
			!isPositive(balance)
		) {
			return true;
		}
		return false;
	}, [entryPrice, amount, balance]);

	const onHandleConfirm = () => {
		if (
			!currentPool ||
			!isPositive(entryPrice) ||
			!isPositive(amount) ||
			!isPositive(balance)
		) {
			return;
		}

		// （开仓价值* 清算费率）+(x*数量*交易费率） +清算执行费/余额=100%，求x;
		const targetLiqPrice = PositionUtil.calculateLiqPrice(
			side,
			balance,
			amount,
			entryPrice,
			currentPool.liquidationExecutionFee,
			currentPool.liquidationFeeRatePerPosition,
			currentPool.tradingFeeRate
		);

		setTargetLiqPrice(targetLiqPrice);
	};
	const { isMatchMobile } = useAppBreakpoints();
	const [open, setOpen] = useState(false);

	return (
		<article className='flex sm:flex-col-reverse space-x-4 sm:space-x-0'>
			<section className='flex-1 flex flex-col justify-between sm:mt-4 sm:space-y-4'>
				<div className='space-y-4'>
					<ComputerDirection />
					<div className='space-y-2'>
						<CommonStyledFilledInput
							type='text'
							inputProps={{
								maxLength: MAX_PRECISION,
								inputMode: 'decimal'
							}}
							sx={{
								'& .MuiInputBase-input': {
									paddingRight: '8px'
								}
							}}
							value={entryPrice}
							onChange={onChangeEntryPrice}
							fullWidth
							startAdornment={
								<div className='flex items-center space-x-1'>
									<Typography
										className='min-w-max'
										variant='body2'
										color='secondary'
									>
										<Trans>Entry price</Trans>
									</Typography>
									<CommonStyledMaxButton
										className='px-2 font-normal text-xs'
										variant='outlined'
										color='secondary'
										onClick={onHandleSetMarketPrice}
									>
										<Trans>trade.Market</Trans>
									</CommonStyledMaxButton>
								</div>
							}
							endAdornment={
								<Typography
									className='text-right'
									variant='body2'
									color='secondary'
								>
									{QUOTE_USD}
								</Typography>
							}
						/>
						<CommonStyledFilledInput
							type='text'
							inputProps={{
								maxLength: MAX_PRECISION,
								inputMode: 'decimal'
							}}
							sx={{
								'& .MuiInputBase-input': {
									paddingRight: '8px'
								}
							}}
							value={amount}
							onChange={onChangeAmount}
							fullWidth
							startAdornment={
								<Typography
									className='min-w-max'
									variant='body2'
									color='secondary'
								>
									<Trans>Size</Trans>
								</Typography>
							}
							endAdornment={
								<Typography
									className='text-right'
									variant='body2'
									color='secondary'
								>
									{shortenSymbolNative(currentPool?.baseToken, currentChainId)}
								</Typography>
							}
						/>
					</div>
					<CommonStyledFilledInput
						type='text'
						inputProps={{
							maxLength: MAX_PRECISION,
							inputMode: 'decimal'
						}}
						sx={{
							'& .MuiInputBase-input': {
								paddingRight: '8px'
							}
						}}
						value={balance}
						onChange={onChangeBalance}
						fullWidth
						startAdornment={
							<div className='flex items-center'>
								{isMatchMobile ? (
									<ClickAwayListener
										onClickAway={() => {
											setOpen(false);
										}}
									>
										<MuiTooltip
											enterTouchDelay={0}
											arrow={true}
											open={open}
											placement='bottom'
											title={
												<Trans>
													Simulated position net value. Balance = Margin +
													Unrealized PnL
												</Trans>
											}
										>
											<Typography
												onClick={() => setOpen(true)}
												className='mx-0.5 inline-block leading-tight cursor-default underline decoration-dashed underline-offset-2 decoration-1'
												component='span'
												color='secondary'
											>
												<Trans>Balance</Trans>
											</Typography>
										</MuiTooltip>
									</ClickAwayListener>
								) : (
									<TippingUnderline
										tooltip={
											<Trans>
												Simulated position net value. Balance = Margin +
												Unrealized PnL
											</Trans>
										}
										label={
											<Typography
												className='min-w-[30px]'
												variant='body2'
												color='secondary'
											>
												<Trans>Balance</Trans>
											</Typography>
										}
									/>
								)}
							</div>
						}
						endAdornment={
							<Typography
								className='text-right'
								variant='body2'
								color='secondary'
							>
								{QUOTE_USD}
							</Typography>
						}
					/>
				</div>

				<CommonButton
					className='flex flex-col justify-center'
					variant='contained'
					fullWidth
					disabled={disabled}
					onClick={onHandleConfirm}
				>
					<Typography
						className='whitespace-normal text-sm'
						color='inherit'
						variant='h6'
						fontWeight={500}
					>
						<Trans>Calculate</Trans>
					</Typography>
				</CommonButton>
			</section>
			<StyledCard isMatchMobile={isMatchMobile} className='flex-1'>
				<CardContent className='space-y-4'>
					<Typography variant='h6'>
						<Trans>Result</Trans>
					</Typography>
					<CommonStyledDivider />
					<div className='space-y-2'>
						<Cell
							label={<Trans>Liquidation Price</Trans>}
							value={targetLiqPrice ? <LiqPrice value={targetLiqPrice} /> : '-'}
						/>
					</div>
				</CardContent>
			</StyledCard>
		</article>
	);
});
