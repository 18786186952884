import { Trans, t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getContractAddress } from 'config/contracts';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCurrentChain } from 'hooks/useCurrentChain';
import type { Merge } from 'type-fest';
import { ExplorerDataType, getExplorerLink, shortenAddress } from 'utils';

import Dialog, { DialogProps } from 'components/Common/Dialog';
import StyledLink from 'components/Common/StyledLink';
import NoData from 'components/NoData';
import Table from 'components/Table/StickyTable';

export default function MintedHistoryDialog(
	props: Merge<DialogProps, { childrens: any }>
) {
	const { onClose, childrens } = props;
	const { currentChain: chain, currentChainId } = useCurrentChain();
	const theme = useTheme();
	const { isMatchMobile, isMatchPad } = useAppBreakpoints();

	const columns = [
		{
			key: 'Minted NFT',
			width: '50%',
			label: (
				<Typography fontSize={14} variant='h1' component='div'>
					<Trans>Minted NFT</Trans>
				</Typography>
			),
			align: 'left',
			format: (row: any) => {
				const EFCAddress = getContractAddress(currentChainId, 'EFC');
				return (
					<StyledLink
						type='white'
						target={isMatchMobile ? '_self' : '_blank'}
						href={getExplorerLink(
							chain,
							EFCAddress,
							ExplorerDataType.NFT,
							row.id
						)}
					>
						Member #{row.id}
					</StyledLink>
				);
			}
		},
		{
			key: 'address',
			width: '50%',
			label: (
				<Typography fontSize={14} variant='h1' component='div'>
					<Trans>Receiving Address</Trans>
				</Typography>
			),
			align: 'right',
			format: (row: any) => {
				return (
					<StyledLink
						type='white'
						target={isMatchMobile ? '_self' : '_blank'}
						href={getExplorerLink(
							chain,
							row.account || '',
							ExplorerDataType.ADDRESS
						)}
					>
						{shortenAddress(row.account)}
					</StyledLink>
				);
			}
		}
	];

	return (
		<Dialog
			className='px-0 sm:px-4'
			open
			onClose={onClose}
			title={t`Mint Details`}
		>
			{childrens && childrens.length > 0 ? (
				<Table
					columns={columns}
					rows={childrens ? childrens : []}
					cellColor={theme.custom.cardBg}
					origin={isMatchPad}
				/>
			) : (
				<NoData size='large'>
					<Trans>No Member NFTs minted yet.</Trans>
				</NoData>
			)}
		</Dialog>
	);
}
