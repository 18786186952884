import * as Sentry from '@sentry/react';

export function sentryMessage(message: string, extra?: object) {
	// eslint-disable-next-line import/namespace
	Sentry.withScope(scope => {
		if (extra) {
			for (const key in extra) {
				scope.setExtra(key, extra[key]);
			}
		}
		// eslint-disable-next-line import/namespace
		Sentry.captureMessage(message);
	});
}
