import { useMemo } from 'react';

import { Interface } from '@ethersproject/abi';
import { RewardCollectorABI } from 'config/abis';
import { Contract_Write_Mode, Transaction_Type } from 'config/constants';
import { getContractAddress } from 'config/contracts';
import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { ClaimCalldata, Type_Earns } from 'pages/Earn/types';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { RecordForClaimAllEarns } from 'types';
import { isPositive, parseUnits, plus } from 'utils';

import { useComputeGasLimit } from './useComputeGasLimit';
import { useCurrentChain } from './useCurrentChain';
import { useSendTransaction } from './useSendTransaction';

const rewardCollectorInterface = new Interface(RewardCollectorABI);

export function useSubmitClaimAllEarns(
	claimCalldatas: Array<ClaimCalldata> | null
) {
	const { appToken } = useAppSelector(txBaseState);

	const { currentChainId } = useCurrentChain();
	const { address } = useAccount();

	const { args, record, description } = useMemo(() => {
		if (!address || !appToken) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		const results = [] as Array<string>;
		let appAmount = '0';

		console.log('useSubmitClaimAllEarns args begin ...');

		if (claimCalldatas && claimCalldatas.length > 0) {
			claimCalldatas.forEach((item: ClaimCalldata) => {
				switch (item.type) {
					case Type_Earns.Liquidity:
						results.push(
							rewardCollectorInterface.encodeFunctionData(
								'collectFarmLiquidityRewardBatch',
								[item.ids]
							)
						);
						appAmount = plus(appAmount, item.amount);
						return;
					case Type_Earns.RBF:
						results.push(
							rewardCollectorInterface.encodeFunctionData(
								'collectFarmRiskBufferFundRewardBatch',
								[item.ids]
							)
						);
						appAmount = plus(appAmount, item.amount);
						return;
					default:
						void 0;
				}
			});
		}

		if (isPositive(appAmount)) {
			results.push(
				rewardCollectorInterface.encodeFunctionData('sweepToken', [
					appToken.address,
					parseUnits(appAmount, appToken.decimals),
					address
				])
			);
		}

		const record = {
			transactionType: Transaction_Type.ClaimPreviousAllEarns,
			appAmount
		} as RecordForClaimAllEarns;

		const description = TransactionDescription.Claim();

		return {
			args: [results],
			record,
			description
		};
	}, [address, claimCalldatas, appToken]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address: getContractAddress(currentChainId, 'RewardCollector'),
			abi: RewardCollectorABI,
			functionName: 'multicall',
			args
		} as UseContractWriteConfig;
	}, [currentChainId, args]);

	const { gasLimit } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirmed, isConfirming, error } =
		useSendTransaction(contractWriteArgs, gasLimit);

	const onConfirm = () => {
		if (!record) return;
		onSendTransaction(record, description);
	};

	return {
		onConfirm,
		isConfirming,
		isConfirmed,
		error
	};
}
