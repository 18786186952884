import { ChainConfig } from 'config/constants/connector/types';
import { useSwitchNetwork as useWagmiSwitchNetwork } from 'wagmi';

import { setChain } from 'state/global/slice';
import { useAppDispatch } from 'state/hooks';

import { useCurrentChain } from './useCurrentChain';

export function useSwitchNetwork() {
	const dispatch = useAppDispatch();
	const { switchNetworkAsync } = useWagmiSwitchNetwork();
	const { checkChain } = useCurrentChain();
	const onSwitchNetwork = (chain: ChainConfig) => {
		switchNetworkAsync?.(chain.id).then(() => {
			// 对支持的链，进行持久化存储当前链
			if (checkChain(chain.id)) {
				dispatch(setChain(chain));
			}
		});
	};

	return {
		onSwitchNetwork
	};
}
