import React from 'react';

import { Trans, t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { DEFAULT_UNI_POOL_FEE_AMOUNT } from 'hooks/V3/constants';
import { useUniV3SwapLink } from 'hooks/V3/useUniV3LiquidityLink';
import { formattedFeeAmount } from 'hooks/V3/utils';
import { useAddTokenToWallet } from 'hooks/useAddTokenToWallet';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { shortenSymbol, toUsd } from 'utils';

import { CommonStyledOutlinedButton } from 'components/Common/Styled';
import TokenLogo from 'components/Svg/Icons/TokenLogo';

import TippingUnderline from '../../components/TippingUnderline';

export default React.memo(function AppTokenPrice() {
	const { appTokenUsdPrice, appToken, nativeSymbol } =
		useAppSelector(txBaseState);

	const { isMatchMobile } = useAppBreakpoints();
	const uniV3SwapLink = useUniV3SwapLink();

	const { onSubmit } = useAddTokenToWallet();
	const theme = useTheme();

	return (
		<aside className='flex items-center space-x-4 sm:hidden'>
			<div className='flex items-center space-x-2'>
				<TokenLogo />
				<TippingUnderline
					underlineColor={theme.palette.secondary.main}
					tooltip={
						<Typography variant='body2'>
							<Trans>
								The current price is calculated based on Uniswap{' '}
								{shortenSymbol(appToken)}/{nativeSymbol}{' '}
								{formattedFeeAmount(DEFAULT_UNI_POOL_FEE_AMOUNT)}% pool.
							</Trans>
						</Typography>
					}
					label={
						<Typography
							className='flex items-center'
							fontSize={14}
							color='text.primary'
						>
							{toUsd(appTokenUsdPrice, 4)}
						</Typography>
					}
				/>
			</div>
			<CommonStyledOutlinedButton
				variant='outlined'
				color='secondary'
				className='sm:hidden'
				href={uniV3SwapLink}
				target={isMatchMobile ? '_self' : '_blank'}
			>
				<Trans>Buy</Trans>
			</CommonStyledOutlinedButton>
			<CommonStyledOutlinedButton
				variant='outlined'
				color='secondary'
				onClick={() => onSubmit(appToken)}
			>
				<Trans>Add to wallet</Trans>
			</CommonStyledOutlinedButton>
		</aside>
	);
});
