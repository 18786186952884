import { useCallback, useEffect, useMemo, useState } from 'react';

import { Trans } from '@lingui/macro';
import { useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {
	Stake_Type,
	Transaction_Status,
	Transaction_Type
} from 'config/constants';
import { useAccount } from 'wagmi';

import { TransactionRecord } from 'entities/TransactionRecord';
import { useUniV3LiquidityLink } from 'hooks/V3/useUniV3LiquidityLink';
import { getUniV3PositionDetailLink } from 'hooks/V3/utils';
import { useManageTransactions } from 'hooks/useAccountTransactions';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin } from 'hooks/useCurrentChain';
import { cloneDeep, intersectionWith } from 'lodash';
import {
	earnStakeBaseState,
	setVisibleStakeLpDialog
} from 'state/earn/stake/slice';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { selectTransactionRecordList } from 'state/records/selector';
import { txBaseState } from 'state/tx/slice';
import { IStakeDetail, RecordStakeLpToken, RecordUnStakeLpToken } from 'types';
import { formatNumber, formatUnixDate, shortenSymbol } from 'utils';

import Cell from 'components/Common/Cell';
import { CommonStyledOutlinedButton } from 'components/Common/Styled';
import StyledLink from 'components/Common/StyledLink';
import Module from 'components/Module';
import NoData from 'components/NoData';
import SectionLoading from 'components/SectionLoading';
import Stronger from 'components/Stronger';
import Loading from 'components/Svg/Icons/Loading';

import Table, { HeaderProps } from '../../../components/Table/StickyTable';
import TippingUnderline from '../../../components/TippingUnderline';
import StakeLpDialog from './StakeLpDialog';
import UnStakeLpDialog from './UnStakeLpDialog';
import { RemainingDurationTipping } from './index';

export function AddLiquidityGuide() {
	const { isMatchMobile } = useAppBreakpoints();
	const uniV3LiquidityLink = useUniV3LiquidityLink();
	const theme = useTheme();
	return (
		<Typography variant='body2' color='secondary' className='space-x-1'>
			<Trans>
				Add liquidity to Uniswap EQU/ETH pool (
				<Typography component='span' variant='body2' color='text.primary'>
					<Trans>full range</Trans>
				</Typography>
				) to receive your LP NFT.
			</Trans>

			<Typography
				component='a'
				href={uniV3LiquidityLink}
				target={isMatchMobile ? '_self' : '_blank'}
				color='inherit'
				className='inline cursor-pointer underline underline-offset-2 decoration-1'
				sx={{
					'&:hover': {
						color: theme.palette.primary.main
					}
				}}
			>
				<Trans>Add now &gt;&gt;</Trans>
			</Typography>
		</Typography>
	);
}

function converStakeLpDetail(txRecord: TransactionRecord) {
	const record = txRecord.record as RecordStakeLpToken;
	return {
		id: txRecord.hash,
		stakeID: record.tokenId,
		type: Stake_Type.LP,
		perShareGrowthX64: '0',
		multiplier: record.multiplier,
		period: record.period,
		duration: record.period,
		stakeTxHash: txRecord.hash,
		stakedAmount: record.amount,
		enableUnstake: false,
		claimableReward: '0',
		status: Transaction_Status.Pending
	} as IStakeDetail;
}

export default function StakeLpTokenList() {
	const dispatch = useAppDispatch();
	const { appToken, nativeSymbol } = useAppSelector(txBaseState);
	const { isLoadingStakeList, lpStakeList, visibleStakeLpDialog } =
		useAppSelector(earnStakeBaseState);
	const transactionRecordList = useAppSelector(selectTransactionRecordList);

	const isLogin = useCheckLogin();
	const theme = useTheme();

	const [unstakeLpOpen, setUnstakeLpOpen] = useState(false);
	const [currentStakeLpDetail, setCurrentStakeLpDetail] =
		useState<IStakeDetail | null>(null);

	const { removeTransaction } = useManageTransactions();

	const { isConnected } = useAccount();

	const { isMatchMobile, isMatchPad } = useAppBreakpoints();

	const { stakeLpTokenRecords, unStakeLpTokenRecords } = useMemo(() => {
		const stakeLpTokenRecords = transactionRecordList.filter(
			(tx: TransactionRecord) => {
				return tx.type === Transaction_Type.StakeLpToken;
			}
		);
		const unStakeLpTokenRecords = transactionRecordList.filter(
			(tx: TransactionRecord) => {
				return tx.type === Transaction_Type.UnStakeLpToken;
			}
		);
		return {
			stakeLpTokenRecords,
			unStakeLpTokenRecords
		};
	}, [transactionRecordList]);

	const mergedStakeLpList0 = useMemo(() => {
		if (lpStakeList && stakeLpTokenRecords) {
			const convertedList = stakeLpTokenRecords.map(record => {
				return converStakeLpDetail(record);
			});
			return convertedList.concat(lpStakeList);
		}
		if (lpStakeList) {
			return lpStakeList;
		}
		if (stakeLpTokenRecords || stakeLpTokenRecords.length > 0) {
			return stakeLpTokenRecords.map(record => {
				return converStakeLpDetail(record);
			});
		}
	}, [lpStakeList, stakeLpTokenRecords]);

	const mergedStakeLpList = useMemo(() => {
		if (!unStakeLpTokenRecords || unStakeLpTokenRecords.length === 0) {
			return mergedStakeLpList0;
		}
		const mergedStakeLpList0Cache = cloneDeep(mergedStakeLpList0);
		const results = intersectionWith(
			mergedStakeLpList0Cache,
			unStakeLpTokenRecords,
			(current: IStakeDetail, txRecord: TransactionRecord) => {
				return (
					current.stakeID === (txRecord.record as RecordUnStakeLpToken)?.tokenId
				);
			}
		);
		results.forEach((item: IStakeDetail) => {
			item.status = Transaction_Status.Pending;
		});
		return mergedStakeLpList0Cache;
	}, [mergedStakeLpList0, unStakeLpTokenRecords]);

	// 同步移除，质押NFT的记录
	useEffect(() => {
		if (
			!lpStakeList ||
			!stakeLpTokenRecords ||
			stakeLpTokenRecords.length === 0
		) {
			return;
		}
		const results = intersectionWith(
			stakeLpTokenRecords,
			lpStakeList,
			(txRecord, current: IStakeDetail) => {
				return txRecord.hash === current.stakeTxHash;
			}
		);
		results.forEach((item: TransactionRecord) => {
			removeTransaction(item);
		});
	}, [stakeLpTokenRecords, lpStakeList]);

	// 同步移除，解除质押NFT的记录
	useEffect(() => {
		if (
			!lpStakeList ||
			!unStakeLpTokenRecords ||
			unStakeLpTokenRecords.length === 0
		) {
			return;
		}
		unStakeLpTokenRecords.forEach((txRecord: TransactionRecord) => {
			const record = txRecord.record as RecordUnStakeLpToken;
			// 在质押列表中寻找是否具有和本地记录一致的目标数据
			// 如果没有，则说明这条移除的记录已经同步完成
			const target = lpStakeList.find(item => item.stakeID === record.tokenId);
			if (!target) {
				removeTransaction(txRecord);
			}
		});
	}, [unStakeLpTokenRecords, lpStakeList]);

	const columnsForLpToken: Array<HeaderProps> = [
		{
			key: 'stakedAmount',
			width: '26%',
			label: (
				<div className='flex items-center'>
					<TippingUnderline
						tooltip={
							<Typography variant='body2'>
								<Trans>
									Staked amount is calculated as the amount of EQU in LP Token
									at the time of staking.
								</Trans>
							</Typography>
						}
						label={
							<Typography variant='body2'>
								<Trans>My Staked</Trans>
							</Typography>
						}
					/>
				</div>
			),
			align: 'left',
			format: (row: IStakeDetail) => {
				return (
					<Typography variant='body2' fontWeight={isMatchMobile ? 600 : 400}>
						{`${formatNumber(
							row.stakedAmount,
							appToken.positionUnits
						)} ${shortenSymbol(appToken)}`}
					</Typography>
				);
			}
		},
		{
			key: 'type',
			width: '20%',
			label: <Typography variant='body2'>LP NFT</Typography>,
			align: isMatchMobile ? 'center' : 'left',
			format: (row: IStakeDetail) => {
				return (
					<StyledLink
						type='white'
						target={isMatchMobile ? '_self' : '_blank'}
						href={getUniV3PositionDetailLink(row.stakeID)}
					>
						{`#${row.stakeID}`}
					</StyledLink>
				);
			}
		},
		{
			key: 'multiplier',
			width: '8%',
			label: <Trans>Multiplier</Trans>,
			align: 'center',
			format: (row: IStakeDetail) => {
				return <Typography variant='body2'>{row.multiplier}x</Typography>;
			}
		},
		{
			key: 'duration',
			width: '24%',
			label: <RemainingDurationTipping className='md:justify-start' />,
			align: isMatchPad ? 'left' : 'right',
			format: (row: IStakeDetail) => {
				const _period = row.duration;
				return (
					<Stronger
						value={
							<Typography variant='body2' fontWeight={400}>
								{row.enableUnstake ? (
									<Trans>Unlocking Period</Trans>
								) : _period === 1 ? (
									<Trans>{_period} day</Trans>
								) : (
									<Trans>{_period} days</Trans>
								)}
							</Typography>
						}
						tooltip={
							<div className='space-y-3'>
								<div className='w-56'>
									<Cell
										label={<Trans>Start Time</Trans>}
										value={formatUnixDate(
											row.beginTime,
											'MMM D, YYYY HH:mm:ss'
										)}
									/>
									<Cell
										label={<Trans>End Time</Trans>}
										value={formatUnixDate(row.endTime, 'MMM D, YYYY HH:mm:ss')}
									/>
								</div>
							</div>
						}
					/>
				);
			}
		},
		{
			key: 'operation',
			width: '24%',
			label: (
				<Typography variant='body2'>
					<Trans>Operation</Trans>
				</Typography>
			),
			align: isMatchPad ? 'left' : 'right',
			format: (row: IStakeDetail) => {
				if (row.status === Transaction_Status.Pending) {
					return <Loading size={20} />;
				}
				if (row.enableUnstake) {
					return (
						<Typography
							component='a'
							className='cursor-pointer'
							color='primary'
							onClick={() => {
								setCurrentStakeLpDetail(row);
								setUnstakeLpOpen(true);
							}}
						>
							<Trans>Unstake</Trans>
						</Typography>
					);
				}
				return (
					<Typography color='secondary'>
						<Trans>Locking</Trans>
					</Typography>
				);
			}
		}
	];

	const onHandleShowStakeLpToken = useCallback(() => {
		dispatch(setVisibleStakeLpDialog(true));
	}, [dispatch, setVisibleStakeLpDialog]);

	const onHandleCloseStakeLpToken = useCallback(() => {
		dispatch(setVisibleStakeLpDialog(false));
	}, [dispatch, setVisibleStakeLpDialog]);

	return (
		<Paper className=''>
			<Module
				className=''
				title={
					<div className='w-full sm:space-y-3'>
						<div className='flex items-center justify-between space-x-2  w-full'>
							<Typography
								component='div'
								noWrap={true}
								className='flex items-center sm:items-start sm:flex-col sm:space-y-1 flex-1'
							>
								<div className='flex items-center mr-2'>
									<TippingUnderline
										underlineColor={theme.palette.secondary.main}
										tooltip={
											<Typography variant='body2'>
												<Trans>
													Staking amount is calculated as the amount of EQU in
													LP Token at the time of staking.
												</Trans>
											</Typography>
										}
										label={
											<Typography
												noWrap
												variant={
													isMatchMobile || isMatchPad ? 'inherit' : 'body1'
												}
												fontSize={isMatchMobile || isMatchPad ? 14 : 16}
												fontWeight={500}
												color='text.primary'
											>
												<Trans>
													Stake {`${shortenSymbol(appToken)}/${nativeSymbol}`}{' '}
													LP NFT
												</Trans>
											</Typography>
										}
									/>
								</div>
							</Typography>
							<div className='sm:mt-0'>
								{isLogin && (
									<CommonStyledOutlinedButton
										variant='outlined'
										color='secondary'
										onClick={onHandleShowStakeLpToken}
										size='small'
									>
										Stake NFT
									</CommonStyledOutlinedButton>
								)}
							</div>
						</div>
						<div>
							<AddLiquidityGuide />
						</div>
					</div>
				}
			>
				<div>
					{isLogin ? (
						isLoadingStakeList ? (
							<SectionLoading />
						) : mergedStakeLpList && mergedStakeLpList.length > 0 ? (
							<div className='mt-4'>
								<Table
									fontSize={14}
									columns={columnsForLpToken}
									rows={mergedStakeLpList}
									rowsRender={
										<div className='-mb-4 grid grid-cols-2 sm:grid-cols-1'>
											{mergedStakeLpList.map(
												(item: IStakeDetail, index: number) => {
													return (
														<Paper
															key={index}
															className='space-y-1 py-4'
															sx={{
																borderTop: `1px dashed ${theme.palette.divider}`,
																paddingLeft: isMatchMobile
																	? 0
																	: index % 2 === 0
																	? 0
																	: '20px',
																paddingRight: isMatchMobile
																	? 0
																	: index % 2 === 0
																	? '20px'
																	: 0
															}}
														>
															<div className='flex items-center justify-between'>
																<div>
																	{columnsForLpToken
																		.find(
																			column => column.key === 'stakedAmount'
																		)
																		?.format(item)}
																</div>
																<div>
																	{columnsForLpToken
																		.find(column => column.key === 'operation')
																		?.format(item)}
																</div>
															</div>
															{columnsForLpToken
																.filter(
																	column =>
																		!['stakedAmount', 'operation'].includes(
																			column.key
																		)
																)
																.map((column, columnIndex) => {
																	return (
																		<Cell
																			label={column.label}
																			value={column.format(item)}
																			key={columnIndex}
																		/>
																	);
																})}
														</Paper>
													);
												}
											)}
										</div>
									}
								/>
							</div>
						) : (
							<NoData height={100} size='large' align='center'>
								<Trans>You haven&#39;t staked yet.</Trans>
							</NoData>
						)
					) : (
						<NoData height={100} size='large' align='center'>
							{!isConnected ? (
								<Trans>Connect your wallet before staking</Trans>
							) : (
								<Trans>Switch your network before staking</Trans>
							)}
						</NoData>
					)}
				</div>
			</Module>

			{visibleStakeLpDialog && (
				<StakeLpDialog open={true} onClose={onHandleCloseStakeLpToken} />
			)}
			{unstakeLpOpen && currentStakeLpDetail && (
				<UnStakeLpDialog
					item={currentStakeLpDetail}
					open={true}
					onClose={() => {
						setUnstakeLpOpen(false);
					}}
				/>
			)}
		</Paper>
	);
}
