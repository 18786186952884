import type { ReactElement } from 'react';
import React from 'react';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';

import SectionLoading from 'components/SectionLoading';

export default function CollapseTable<T>({
	isLoading = false,
	focusedRowIndex,
	headers,
	contentRender,
	rows,
	onClickRow
}: {
	isLoading: boolean;
	focusedRowIndex?: number;
	headers: any[];
	rows: T[];
	contentRender?: (item: T, index: number) => React.ReactElement;
	onClickRow: (item: T, index: number) => void;
}): ReactElement {
	const { isMatchMobile } = useAppBreakpoints();
	const theme = useTheme();
	return (
		<Box className='p-4 pt-1'>
			<div className='flex w-full sm:hidden'>
				{headers.map((header, index) => (
					<Typography
						key={index}
						align={header.align}
						width={header.width}
						variant='body2'
						color='secondary'
						className='py-4 px-4'
					>
						{header.label}
					</Typography>
				))}
			</div>
			<div
				className={classNames('space-y-4 md:space-y-2', {
					'flex justify-center h-[300px] items-center': isLoading
				})}
			>
				{isLoading && <SectionLoading />}
				{!isLoading &&
					rows.map((row, rowIndex) => (
						<Box
							className='relative rounded'
							key={rowIndex}
							sx={{
								border:
									focusedRowIndex === rowIndex
										? `1px solid ${theme.custom.ListBorder}`
										: `1px solid ${theme.custom.ListSelectBg}`,
								borderWidth: isMatchMobile ? '0px' : '1px'
							}}
						>
							<Box
								className={classNames('relative', {
									'cursor-pointer': focusedRowIndex !== rowIndex,
									rounded: focusedRowIndex !== rowIndex
								})}
								onClick={() => onClickRow(row, rowIndex)}
								sx={{
									background:
										focusedRowIndex === rowIndex
											? theme.custom.ListSelectBg
											: theme.custom.ListBg,
									'&:hover': {
										background: theme.custom.ListHoverBg
									}
								}}
							>
								{focusedRowIndex === rowIndex && (
									<FiberManualRecordIcon
										className='absolute top-2 left-2'
										sx={{
											width: 10,
											height: 10,
											color: theme.palette.primary.main
										}}
									/>
								)}

								<div className='flex items-center sm:hidden'>
									{headers.map(header => (
										<Typography
											component='div'
											key={header.key}
											width={header.width}
											align={header.align}
											variant='body2'
											color='text.main'
											className='py-5 px-4'
										>
											{header.cellRender
												? header.cellRender(row)
												: row[header.key]}
										</Typography>
									))}
								</div>
							</Box>
							{contentRender && <Box>{contentRender(row, rowIndex)}</Box>}
						</Box>
					))}
			</div>
		</Box>
	);
}
