import { toDecimalPlaces } from './formatCurrencyAmount';
import { isLessThan } from './mathUtils';

export function formatLeverage(value: string | number, precision = 2) {
	if (!value) {
		return '-';
	}
	if (isNaN(Number(value))) {
		return '0x';
	}
	if (isLessThan(value, 0.01)) {
		return '0.01x';
	}
	return `${toDecimalPlaces(value, precision)}x`;
}
