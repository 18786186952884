import { useMemo } from 'react';

import { Interface } from '@ethersproject/abi';
import { RewardCollectorABI } from 'config/abis';
import {
	Contract_Write_Mode,
	REWARDS_MULTICALL_RATE,
	Transaction_Type
} from 'config/constants';
import { getContractAddress } from 'config/contracts';
import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { RecordForClaimReferrals } from 'types';
import { formatNumber, isPositive, multipliedBy, parseUnits } from 'utils';

import { useComputeGasLimit } from './useComputeGasLimit';
import { useCurrentChain } from './useCurrentChain';
import { useSendTransaction } from './useSendTransaction';

const rewardCollectorInterface = new Interface(RewardCollectorABI);

export function useSubmitClaimReferrals(
	transactionType: Transaction_Type,
	referralTokens: Array<string> | null,
	feePoolIds: Array<string> | [],
	EQUPoolIds: Array<string> | [],
	quoteAmount: string,
	appAmount: string
) {
	const { quoteToken, appToken } = useAppSelector(txBaseState);
	const { currentChainId } = useCurrentChain();
	const { address } = useAccount();
	const { args, record, description } = useMemo(() => {
		if (
			!address ||
			!referralTokens ||
			!quoteToken ||
			!appToken ||
			(!isPositive(quoteAmount) && !isPositive(appAmount))
		) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		console.log('useSubmitClaimReferral args begin ...');

		const results = [] as Array<string>;

		const quoteValue = parseUnits(
			multipliedBy(quoteAmount, REWARDS_MULTICALL_RATE),
			quoteToken.decimals
		);
		const appValue = parseUnits(
			multipliedBy(appAmount, REWARDS_MULTICALL_RATE),
			appToken.decimals
		);

		// if (isPositive(quoteValue)) {
		// 	results.push(
		// 		rewardCollectornterface.encodeFunctionData('collectReferralFeeBatch', [
		// 			feePoolIds,
		// 			referralTokens
		// 		])
		// 	);

		// 	results.push(
		// 		rewardCollectornterface.encodeFunctionData('sweepToken', [
		// 			quoteToken.address,
		// 			quoteValue,
		// 			address
		// 		])
		// 	);
		// }

		results.push(
			rewardCollectorInterface.encodeFunctionData(
				'collectFarmReferralRewardBatch',
				[EQUPoolIds, referralTokens]
			)
		);

		results.push(
			rewardCollectorInterface.encodeFunctionData('sweepToken', [
				appToken.address,
				appValue,
				address
			])
		);

		const record = {
			transactionType,
			referralTokens,
			quoteAmount,
			appAmount
		} as RecordForClaimReferrals;

		const description = TransactionDescription.Claim(
			formatNumber(quoteAmount, quoteToken.positionUnits),
			formatNumber(appAmount, appToken.positionUnits)
		);

		return {
			args: [results],
			record,
			description
		};
	}, [
		address,
		feePoolIds,
		EQUPoolIds,
		referralTokens,
		quoteAmount,
		appAmount,
		quoteToken,
		appToken,
		transactionType
	]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address: getContractAddress(currentChainId, 'RewardCollector'),
			abi: RewardCollectorABI,
			functionName: 'multicall',
			args
		} as UseContractWriteConfig;
	}, [currentChainId, args]);

	const { gasLimit } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirmed, isConfirming, error } =
		useSendTransaction(contractWriteArgs, gasLimit);

	const onConfirm = () => {
		if (!record) return;
		onSendTransaction(record, description);
	};

	return {
		onConfirm,
		isConfirming,
		isConfirmed,
		error
	};
}
