import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

import { Address } from '@wagmi/core';
import {
	LOADING_INTERVAL_TIMEOUT,
	Page,
	Transaction_Type
} from 'config/constants';
import { useAccount } from 'wagmi';

import { TransactionRecord } from 'entities/TransactionRecord';
import { useConnectorsReferralsGraph } from 'graphql/useConnectorsReferralsGraph';
import {
	IRefereeItem,
	useMembersReferralsGraph
} from 'graphql/useMembersReferralsGraph';
import { useManageTransactions } from 'hooks/useAccountTransactions';
import { useCheckLogin } from 'hooks/useCurrentChain';
import { useCurrentPage } from 'hooks/useCurrentPage';
import { usePrevious } from 'hooks/useLatest';
import _ from 'lodash';
import { globalBaseState } from 'state/global/slice';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { selectTransactionRecordList } from 'state/records/selector';

import {
	setAllReferralCodes,
	setConnectorsList,
	setConnectorsLoading,
	setConnectorsRewardList,
	setMemberPoolPositionList,
	setMembersList,
	setMembersLoading,
	setMembersRefereesAddressList,
	setMembersRewardList
} from './slice';

export default function ReferralsUpdater() {
	const dispatch = useAppDispatch();
	const { appTimer } = useAppSelector(globalBaseState);
	const transactionRecordList = useAppSelector(selectTransactionRecordList);

	const { pathname } = useLocation();
	const isLogin = useCheckLogin();
	const { address } = useAccount();
	const currentPage = useCurrentPage();
	const { removeTransaction } = useManageTransactions();
	const prevAddress: Address = usePrevious(address);

	const {
		data: membersList,
		membersRewardList,
		refereesAddressList,
		allReferralCodes,
		poolPositionList,
		loading: loadingMembers,
		refetch: refetchMembers,
		refetchRewards: refetchMembersRewards,
		refetchDetails: refetchMembersDetails,
		refetchRefereesAddress: refetchMembersRefereesAddress
	} = useMembersReferralsGraph();

	const {
		data: connectorsList,
		connectorsRewardList,
		loading: loadingConnectors,
		refetch: refetchConnectors,
		refetchRewards: refetchConnectorsRewards,
		refetchDetails: refetchConnectorsDetails
	} = useConnectorsReferralsGraph();

	useUpdateEffect(() => {
		setTimeout(() => {
			if (!loadingMembers) {
				dispatch(setMembersLoading(loadingMembers));
			}
		}, LOADING_INTERVAL_TIMEOUT);

		if (address && prevAddress !== address) {
			dispatch(setMembersLoading(true));
		}
	}, [loadingMembers, prevAddress, address]);

	useUpdateEffect(() => {
		if (isLogin && membersList && membersRewardList) {
			dispatch(setMembersList(membersList));
			dispatch(setMembersRewardList(membersRewardList));
			dispatch(setMembersRefereesAddressList(refereesAddressList));
		} else {
			dispatch(setMembersList(null));
			dispatch(setMembersRewardList(null));
			dispatch(setMembersRefereesAddressList(null));
		}
	}, [membersList, membersRewardList, refereesAddressList, address, isLogin]);

	useUpdateEffect(() => {
		if (isLogin && allReferralCodes) {
			dispatch(setAllReferralCodes(allReferralCodes));
		} else {
			dispatch(setAllReferralCodes(null));
		}
	}, [isLogin, allReferralCodes]);

	useUpdateEffect(() => {
		if (isLogin && poolPositionList) {
			dispatch(setMemberPoolPositionList(poolPositionList));
		} else {
			dispatch(setMemberPoolPositionList(null));
		}
	}, [isLogin, poolPositionList]);

	useUpdateEffect(() => {
		dispatch(setAllReferralCodes(null));
	}, [address]);

	useEffect(() => {
		return () => {
			dispatch(setMembersRewardList(null));
		};
	}, []);

	useUpdateEffect(() => {
		dispatch(setConnectorsLoading(loadingConnectors));
		if (isLogin && connectorsList && connectorsRewardList) {
			dispatch(setConnectorsList(connectorsList));
			dispatch(setConnectorsRewardList(connectorsRewardList));
		} else {
			dispatch(setConnectorsList(null));
			dispatch(setConnectorsRewardList(null));
		}
	}, [
		connectorsList,
		connectorsRewardList,
		loadingConnectors,
		address,
		isLogin
	]);

	useUpdateEffect(() => {
		if (
			(isLogin && currentPage === Page.Referrals) ||
			currentPage === Page.ReferralsV1
		) {
			if (
				pathname === Page.ReferralsEFCMembers ||
				pathname === Page.ReferralsEFCMembersV1
			) {
				refetchMembers();
				refetchMembersRewards();
				refetchMembersDetails();
				refetchMembersRefereesAddress();
			}
			if (
				pathname === Page.ReferralsConnectors ||
				pathname === Page.ReferralsConnectorsV1
			) {
				refetchConnectors();
				refetchConnectorsRewards();
				refetchConnectorsDetails();
			}
		}
	}, [isLogin, address, appTimer, currentPage, pathname]);

	const createReferralCoderRecords = useMemo(() => {
		return transactionRecordList.filter((tx: TransactionRecord) => {
			return tx.type === Transaction_Type.CreateReferralCode;
		});
	}, [transactionRecordList]);

	useEffect(() => {
		if (
			!allReferralCodes ||
			!createReferralCoderRecords ||
			createReferralCoderRecords.length === 0
		) {
			return;
		}
		const results = _.intersectionWith(
			createReferralCoderRecords,
			allReferralCodes,
			(txRecord, current: IRefereeItem) => {
				return txRecord.hash === current.txHash;
			}
		);
		results.forEach((item: TransactionRecord) => {
			removeTransaction(item);
		});
	}, [allReferralCodes, createReferralCoderRecords]);

	return null;
}
