import {styled, Chip, ChipProps, alpha} from '@mui/material'

const StyledChip = styled(Chip)<ChipProps>`
	background: transparent;
	color: ${({ theme }) => theme.palette.text.secondary};
	&:hover {
		color: ${({ theme }) => theme.palette.text.primary};
		background: transparent;
	}
	&:active {
		box-shadow: none;
	}
	&.active {
		color: ${({ theme }) => theme.palette.text.primary};
		background: ${({ theme }) => alpha(theme.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black, 0.1)};
	}
	.MuiChip-label {
		font-size: 12px;
		padding: 4px 12px !important;
	}
`;

export default StyledChip