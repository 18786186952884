import { useMemo } from 'react';

import {
	Contract_Write_Mode,
	Transaction_Type,
	Version
} from 'config/constants';
import { selectContractByVersion } from 'config/contracts';
import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';
import { txBaseState } from 'state/tx/slice';
import { RecordForClaimStakes } from 'types';
import { formatNumber, parseUnits } from 'utils';

import { useComputeGasLimit } from './useComputeGasLimit';
import { useCurrentChain } from './useCurrentChain';
import { useSelectRewardConnectorByVersion } from './useSelectByVersion';
import { useSendTransaction } from './useSendTransaction';

export function useSubmitClaimStake(
	stakeIDs: Array<string> | null,
	appQuoteAmount: string
) {
	const { quoteToken } = useAppSelector(txBaseState);
	const currentVersion = useAppSelector(selectVersion);

	const { currentChainId } = useCurrentChain();
	const { address } = useAccount();

	const { rewardCollectorInterface, rewardCollectorABI } =
		useSelectRewardConnectorByVersion();

	const { args, record, description } = useMemo(() => {
		if (!address || !stakeIDs) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		console.log('useSubmitClaimStake args begin ...');

		const results = [] as Array<string>;

		const _appQuoteAmount = parseUnits(appQuoteAmount, quoteToken.decimals);
		results.push(
			rewardCollectorInterface.encodeFunctionData(
				currentVersion === Version.V1
					? 'collectStakingRewardBatch'
					: 'collectContractsV1StakingRewardBatch',
				[stakeIDs]
			)
		);
		results.push(
			rewardCollectorInterface.encodeFunctionData('sweepToken', [
				quoteToken.address,
				_appQuoteAmount,
				address
			])
		);

		const record = {
			transactionType: Transaction_Type.ClaimStake,
			stakeIDs,
			quoteAmount: appQuoteAmount
		} as RecordForClaimStakes;

		const description = TransactionDescription.Claim(
			formatNumber(appQuoteAmount, quoteToken.positionUnits)
		);

		return {
			args: [results],
			record,
			description
		};
	}, [
		address,
		stakeIDs,
		appQuoteAmount,
		quoteToken,
		currentVersion,
		rewardCollectorInterface
	]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address: selectContractByVersion(
				currentVersion,
				currentChainId,
				'RewardCollector'
			),
			abi: rewardCollectorABI,
			functionName: 'multicall',
			args
		} as UseContractWriteConfig;
	}, [currentChainId, args, currentVersion, rewardCollectorABI]);

	const { gasLimit } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirmed, isConfirming, error } =
		useSendTransaction(contractWriteArgs, gasLimit);

	const onConfirm = () => {
		if (!record) return;
		onSendTransaction(record, description);
	};

	return {
		onConfirm,
		isConfirming,
		isConfirmed,
		error
	};
}
