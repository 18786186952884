import { useMemo } from 'react';

import { useTheme } from '@mui/material';
import { BACKEND_HOST_URL } from 'config/backend';
import { Dom_Size, Version } from 'config/constants';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';

import Loading from '../../components/SectionLoading';
import TVChartContainer from '../../components/tv-chart/index.jsx';
import { useCurrentChain } from '../../hooks/useCurrentChain';
import { selectTheme } from '../../state/global/selector';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
	settingBaseState,
	updateSelectedTVInterval
} from '../../state/setting/slice';

export interface PriceChartProps {
	id: string;
	pricescale: number;
	height?: string;
}

export default function PriceChart(props: PriceChartProps) {
	const { id, pricescale, height } = props;
	const { currentChainId } = useCurrentChain();
	const dispatch = useAppDispatch();
	const theme = useTheme();
	const { isMatchMobile } = useAppBreakpoints();
	const { activeLocale, currentVersion } = useAppSelector(settingBaseState);
	const selectedTvinterval = useAppSelector(
		state => state.setting.tvInterval[id] || '15'
	);

	const baseUrl = useMemo(() => {
		if (currentVersion === Version.V1) {
			return `${BACKEND_HOST_URL[currentChainId].V1}/tradingview/udf/`;
		} else if (currentVersion === Version.V2) {
			return `${BACKEND_HOST_URL[currentChainId].V2}/tradingview/udf/`;
		} else {
			return `${BACKEND_HOST_URL[currentChainId].V1}/tradingview/udf/`;
		}
	}, [currentChainId, currentVersion]);
	const currentTheme = useAppSelector(selectTheme);
	const changeInterval = interval => {
		dispatch(updateSelectedTVInterval({ [id]: interval }));
	};

	const symbol = useMemo(() => {
		if (currentVersion === Version.V1) {
			return `TOKEN-${id}`;
		} else if (currentVersion === Version.V2) {
			return `MARKET-${id}`;
		} else {
			return id;
		}
	}, [id, currentVersion]);
	return (
		<div className='h-full flex-1'>
			{id ? (
				<TVChartContainer
					symbol={symbol}
					pricescale={pricescale}
					baseUrl={baseUrl}
					theme={currentTheme}
					themes={theme}
					chainId={currentChainId}
					isGraph={false}
					isMatchMobile={isMatchMobile}
					locale={activeLocale}
					interval={selectedTvinterval}
					onChangeInterval={changeInterval}
					height={height}
				/>
			) : (
				<Loading />
			)}
		</div>
	);
}
