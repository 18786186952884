import { Side, TakeProfitStopLoss_Type } from 'config/constants';

import { div, minus, multipliedBy, plus } from 'utils';

export class TakeProfitStopLossUtil {
	public static calculateQuoteChange(
		_entryPrice: string,
		_triggerPrice: string,
		_side: Side,
		_type: TakeProfitStopLoss_Type
	) {
		let quoteChange = '';
		if (_side === Side.LONG) {
			if (_type === TakeProfitStopLoss_Type.TakeProfit) {
				quoteChange = minus(div(_triggerPrice, _entryPrice), 1);
			} else {
				quoteChange = minus(1, div(_triggerPrice, _entryPrice));
			}
		} else {
			if (_type === TakeProfitStopLoss_Type.TakeProfit) {
				quoteChange = minus(1, div(_triggerPrice, _entryPrice));
			} else {
				quoteChange = minus(div(_triggerPrice, _entryPrice), 1);
			}
		}
		return quoteChange;
	}

	public static calculateTriggerPrice(
		_entryPrice: string,
		_quoteChange: string,
		_side: Side,
		_type: TakeProfitStopLoss_Type
	) {
		let triggerPrice = '';
		if (_side === Side.LONG) {
			if (_type === TakeProfitStopLoss_Type.TakeProfit) {
				triggerPrice = multipliedBy(_entryPrice, plus(1, _quoteChange));
			} else {
				triggerPrice = multipliedBy(_entryPrice, minus(1, _quoteChange));
			}
		} else {
			if (_type === TakeProfitStopLoss_Type.TakeProfit) {
				triggerPrice = multipliedBy(_entryPrice, minus(1, _quoteChange));
			} else {
				triggerPrice = multipliedBy(_entryPrice, plus(1, _quoteChange));
			}
		}
		return triggerPrice;
	}

	public static calculateProfitLoss(
		_entryPrice: string,
		_triggerPrice: string,
		_size: string,
		_side: Side
	) {
		let ProfitLoss = '';
		if (_side === Side.LONG) {
			ProfitLoss = multipliedBy(_size, minus(_triggerPrice, _entryPrice));
		} else {
			ProfitLoss = multipliedBy(_size, minus(_entryPrice, _triggerPrice));
		}
		return ProfitLoss;
	}

	public static calculateRateOfReturn(_profitLoss: string, _netMargin: string) {
		let RateOfReturn = '';
		if (_profitLoss && _netMargin) {
			RateOfReturn = div(_profitLoss, _netMargin);
		}
		return RateOfReturn;
	}
}
