import { useCallback } from 'react';

import { Trans } from '@lingui/macro';
import { ButtonProps } from '@mui/material';
import { DEFAULT_CHAIN } from 'config/constants/connector/chains';
import { useAccount, useNetwork } from 'wagmi';

import { useCurrentChain } from 'hooks/useCurrentChain';
import { useSwitchNetwork } from 'hooks/useSwitchNetwork';
import { useWalletModalToggle } from 'state/application/hooks';

import Button from 'components/Common/StyledButton';

const ConnectWalletButton = ({ ...props }: ButtonProps) => {
	const { isConnected } = useAccount();
	const onToggleWalletModal = useWalletModalToggle();
	const { onSwitchNetwork } = useSwitchNetwork();
	const { isNetworkError } = useCurrentChain();
	
	const onHandleChange = useCallback(() => {
		if (!isConnected) {
			onToggleWalletModal();
			return;
		}
		onSwitchNetwork(DEFAULT_CHAIN);
	}, [onSwitchNetwork]);

	return (
		<Button onClick={onHandleChange} {...props}>
			{!isConnected ? (
				<Trans>Connect Wallet</Trans>
			) : (
				isNetworkError && <Trans>Switch Network</Trans>
			)}
		</Button>
	);
};

export default ConnectWalletButton;
