import React from 'react';

import { Trans } from '@lingui/macro';
import { Typography, useTheme } from '@mui/material';
import { Side } from 'config/constants';

import Leverage from 'components/Leverage';

export default React.memo(function LongShortTag({
	side,
	baseSymbol,
	quoteSymbol,
	leverage,
	type,
	margin
}: {
	side: Side;
	baseSymbol: string;
	quoteSymbol: string;
	leverage?: number | string;
	type?: string;
	margin?: string;
}) {
	const theme = useTheme();
	if (type === 'base') {
		return (
			<section className='flex items-center space-x-2'>
				<Typography variant='h6'>{`${baseSymbol}/${quoteSymbol}`}</Typography>
				<Typography
					className='space-x-1'
					variant='body1'
					sx={{
						color:
							side === Side.LONG
								? theme.palette.success.main
								: theme.palette.error.main
					}}
				>
					{side === Side.LONG ? <Trans>Long</Trans> : <Trans>Short</Trans>}
					{leverage && (
						<span className='ml-1'>
							<Leverage value={leverage as string} margin={margin} />
						</span>
					)}
				</Typography>
			</section>
		);
	}
	return (
		<section className='flex items-center'>
			<Typography
				className='rounded py-0.5 px-1 mr-2'
				variant='body2'
				sx={{
					color: theme.palette.common.white,
					backgroundColor:
						side === Side.LONG
							? theme.palette.success.main
							: theme.palette.error.main
				}}
			>
				{side === Side.LONG ? <Trans>Long</Trans> : <Trans>Short</Trans>}
			</Typography>
			{leverage && (
				<>
					<Leverage value={leverage as string} margin={margin} />
					<Typography variant='body1' className='mx-1'>
						·
					</Typography>
				</>
			)}
			<Typography variant='body1'>{`${baseSymbol}/${quoteSymbol}`}</Typography>
		</section>
	);
});
