export enum Type_Referrals {
	Traders = 'traders',
	Members = 'members',
	Connector = 'connectors',
	Leaderboard = 'leaderboard'
}

export enum Referral_Request_Type {
	MEMBER = 'MEMBER',
	CONNECTOR = 'CONNECTOR'
}

export enum Referral_Request_Reward_Type {
	REFERRAL_POSITION = 'REFERRAL_POSITION',
	REFERRAL_LIQUIDITY = 'REFERRAL_LIQUIDITY',
	V2_REFERRAL_POSITION = 'V2_REFERRAL_POSITION'
}
