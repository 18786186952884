import { createSelector } from '@reduxjs/toolkit';

import { formatNumber, plus, shortenSymbol } from '../../utils';
import { txBaseState } from './slice';

export const selectNativeBalanceText = createSelector(txBaseState, state => {
	const { nativeBalance, nativeSymbol } = state;
	if (!nativeBalance || !nativeSymbol) {
		return '';
	}
	return `${formatNumber(nativeBalance)} ${nativeSymbol}`;
});

export const selectQuoteBalanceText = createSelector([txBaseState], state => {
	const { quoteBalance, quoteToken } = state;
	if (!quoteToken || !quoteBalance) {
		return '-';
	}
	return `${formatNumber(quoteBalance)} ${shortenSymbol(quoteToken)}`;
});
