import { useModalOpen, useWalletModalToggle } from 'state/application/hooks';
import { ApplicationModal } from 'state/application/slice';

import ConnectWalletModal from 'components/Wallet/ConnectWalletModal';

function GlobalComponents() {
	const isOpenSelectWallet = useModalOpen(ApplicationModal.WALLET);
	const toggleWalletModal = useWalletModalToggle();

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{isOpenSelectWallet && (
				<ConnectWalletModal isOpen={true} onToggle={toggleWalletModal} />
			)}
		</>
	);
}

export default GlobalComponents;
