import { ReactNode } from 'react';

import { Typography } from '@mui/material';
import cn from 'classnames';

import Loading from '../Svg/Icons/Loading';

interface CellProps {
	label: ReactNode;
	value: ReactNode;
	prefix?: ReactNode;
	unit?: ReactNode;
	loading?: boolean;
	size?: string;
	direction?: string | 'row' | 'col';
	align?: 'left' | 'right' | 'center';
	className?: string;
}

export default function Cell({
	label,
	value,
	prefix,
	unit,
	loading,
	className
}: CellProps) {
	return (
		<div
			className={cn(
				'flex h-6 items-center justify-between space-x-2',
				className
			)}
		>
			<div className='flex items-center'>
				<Typography
					variant='body2'
					component='span'
					color='text.secondary'
					className='whitespace-nowrap'
				>
					{label}
				</Typography>
			</div>
			{loading ? (
				<Loading size={16} />
			) : (
				<div className='flex items-center space-x-1 text-right overflow-hidden'>
					{prefix && (
						<Typography
							className='min-w-min'
							noWrap={true}
							component='span'
							variant='body2'
							color='text.secondary'
						>
							{prefix}
						</Typography>
					)}

					<Typography
						className='w-full'
						component='div'
						variant='body2'
						color='text.primary'
					>
						{value}
					</Typography>
					{unit && (
						<Typography
							className='min-w-min'
							noWrap={true}
							component='span'
							variant='body2'
						>
							{unit}
						</Typography>
					)}
				</div>
			)}
		</div>
	);
}

export const DirectionCell = (props: CellProps) => {
	const { align = 'left', label, value, size, className } = props;
	return (
		<div className={cn(`space-y-1 w-full sm:space-y-0.5 ${className}`)}>
			<Typography variant='body2' color='text.secondary' align={align}>
				{label}
			</Typography>
			<Typography
				variant={size === 'small' ? 'body2' : 'body2'}
				color='text.primary'
				align={align}
			>
				{value}
			</Typography>
		</div>
	);
};
