/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
	Box,
	FormControl,
	FormHelperText,
	Typography,
	useTheme
} from '@mui/material';
import classNames from 'classnames';
import {
	REFERRAL_CODE_LENGTH,
	REG_REFERRAL_CODE,
	Transaction_Type
} from 'config/constants';

import { TransactionRecord } from 'entities/TransactionRecord';
import { useManageTransactions } from 'hooks/useAccountTransactions';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckCode } from 'hooks/useCheckCode';
import { useSubmitBindReferralCode } from 'hooks/useSubmitBindReferralCode';
import { useAppSelector } from 'state/hooks';
import { selectTransactionRecordList } from 'state/records/selector';
import { tradeBaseState } from 'state/trade/slice';
import { txBaseState } from 'state/tx/slice';
import { checkInputReferralCode } from 'utils';

import { CommonStyledBaseInput } from 'components/Common/Styled';
import CommonStyledButton from 'components/Common/StyledButton';
import Check from 'components/Svg/Icons/Check';
import { IReferralCodeItem } from 'graphql/useMembersReferralsGraph';
import { referralBaseState } from 'state/referrals/slice';

export default function InputReferralCode() {
	const theme = useTheme();
	const { signingMap } = useAppSelector(txBaseState);
	const { isBindReferralCode, referralCode } =
		useAppSelector(tradeBaseState);
	const { allReferralCodes } = useAppSelector(referralBaseState);
	const transactionRecordList = useAppSelector(selectTransactionRecordList);
	const { isMatchMobile } = useAppBreakpoints();
	const [code, setCode] = useState<string>('');
	const [debouncedCode, setDebouncedCode] = useState<string>('');
	const { isCodeRegistered, isLoading } = useCheckCode(debouncedCode);
	const { onConfirm, isConfirming } = useSubmitBindReferralCode(code);
	const { removeTransaction } = useManageTransactions();

	useDebounce(
		() => {
			setDebouncedCode(code);
		},
		400,
		[code]
	);

	// 是否绑定的是自己的邀请码
	const isBindingSelfCode = useMemo(() => {
		if (allReferralCodes && allReferralCodes.find((item: IReferralCodeItem) => item.id === debouncedCode)) {
			return true;
		}
		return false;
	}, [allReferralCodes, debouncedCode]);

	const isCheckingCode = debouncedCode !== code || isLoading;
	// 是否检查code通过
	const isDuplicateError = code !== '' && !isCodeRegistered && !isCheckingCode;

	const onHandleChangeCode = (event: any) => {
		const value = event.target.value;
		if (value.trim() === '') {
			setCode('');
			return;
		}
		if (
			checkInputReferralCode(value) ||
			(codeFromUrl && codeFromUrl.indexOf(value) > -1)
		) {
			setCode(value);
		}
	};

	const onHandleBindCode = () => {
		if (isCodeRegistered) {
			onConfirm();
		}
	};

	const [searchParams] = useSearchParams();
	const codeFromUrl = searchParams.get('code') || '';

	const [hasBindReferralCode, bindReferralCodeRecords] = useMemo(() => {
		const list = transactionRecordList.filter((tx: TransactionRecord) => {
			return tx.type === Transaction_Type.BindReferralCode;
		});
		return [Boolean(list.length), list];
	}, [transactionRecordList]);

	useEffect(() => {
		if (isBindReferralCode) {
			setCode(referralCode);
		} else {
			setCode(codeFromUrl);
		}
	}, [isBindReferralCode, referralCode, codeFromUrl]);

	useEffect(() => {
		if (isBindReferralCode && bindReferralCodeRecords.length > 0) {
			removeTransaction(bindReferralCodeRecords[0]);
		}
	}, [isBindReferralCode, bindReferralCodeRecords]);
	const { i18n } = useLingui();

	const [disabled, submitText] = useMemo(() => {
		if (!REG_REFERRAL_CODE.test(code) || isDuplicateError || isBindingSelfCode) {
			return [true, t`Confirm`];
		}
		if (
			signingMap.has(Transaction_Type.BindReferralCode) ||
			hasBindReferralCode
		) {
			return [true, t`Loading...`];
		}
		if (isConfirming) {
			return [true, t`Submitting...`];
		}
		if (isCheckingCode) {
			return [true, t`Checking code...`];
		}
		return [false, t`Confirm`];
	}, [
		code,
		signingMap,
		isConfirming,
		isCheckingCode,
		isDuplicateError,
		i18n.locale,
		hasBindReferralCode,
		isBindingSelfCode
	]);

	return (
		<>
			<FormControl className='w-full'>
				<CommonStyledBaseInput
					type='text'
					inputProps={{ maxLength: REFERRAL_CODE_LENGTH, inputMode: 'text' }}
					className='input pl-3 font-normal'
					value={code}
					placeholder={
						i18n.locale === 'vn' || i18n.locale === 'de' || i18n.locale === 'fr'
							? 'Enjoy the following benefits by binding'
							: t`Enjoy the following benefits by binding`
					}
					onChange={onHandleChangeCode}
					fullWidth
					disabled={isBindReferralCode}
					sx={{
						'& .MuiInputBase-input': {
							fontSize: '14px',
							fontWeight: 400,
							// height: 40,
							'&.Mui-disabled': {
								textFillColor: theme.palette.text.primary
							}
						}
					}}
					endAdornment={
						isMatchMobile ? (
							<>
								{isBindReferralCode && (
									<Typography
										className='pl-2 pr-3 py-1.5 flex items-center space-x-1 flex-shrink-0'
										color='text.primary'
									>
										<Check size={16} fill={theme.custom.reverseColor} />
										<div>
											<Trans>Bound</Trans>
										</div>
									</Typography>
								)}
							</>
						) : (
							<>
								{isBindReferralCode ? (
									<Typography
										className='pl-2 pr-3 py-1.5 flex items-center space-x-1 flex-shrink-0'
										color='text.primary'
									>
										<Check size={16} fill={theme.custom.reverseColor} />
										<div>
											<Trans>Bound</Trans>
										</div>
									</Typography>
								) : (
									<Box>
										<CommonStyledButton
											disabled={disabled}
											className='rounded-l-none h-11'
											// endIcon={<SendIcon />}
											onClick={() => onHandleBindCode()}
										>
											{submitText}
										</CommonStyledButton>
									</Box>
								)}
							</>
						)
					}
				/>
				{!isBindReferralCode && isMatchMobile && (
					<CommonStyledButton
						disabled={disabled}
						className='mt-4 w-full'
						onClick={() => onHandleBindCode()}
					>
						{submitText}
					</CommonStyledButton>
				)}

				<FormHelperText className='mx-0'>
					{isDuplicateError && !isBindReferralCode && (
						<Typography color='#F04A54' variant='caption'>
							<Trans>
								Invalid referral code, please re-enter after verification.
							</Trans>
						</Typography>
					)}
					{isBindingSelfCode && !isBindReferralCode && (
						<Typography color='#F04A54' variant='caption'>
							<Trans>Can only bind other user&#39;s referral code.</Trans>
						</Typography>
					)}
				</FormHelperText>
			</FormControl>
		</>
	);
}
