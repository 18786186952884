import { createSelector } from '@reduxjs/toolkit';

import { RewardsUtil } from 'entities/RewardsUtil';
import _ from 'lodash';
import { ClaimCalldata, Type_Earns } from 'pages/Earn/types';
import { txBaseState } from 'state/tx/slice';
import { isGreaterThan, isZero, plus } from 'utils';

import { earnRBFBaseState } from './slice';

// v3
export const selectClaimableRBFList = createSelector(
	[earnRBFBaseState],
	state => {
		const { RBFList } = state;
		if (!RBFList || RBFList.length === 0) {
			return null;
		}
		return _.orderBy(
			RBFList.filter(item => isGreaterThan(item.claimableIncome, 0)),
			function (o) {
				return parseFloat(o.claimableIncome);
			},
			['desc']
		);
	}
);

// v3
export const selectClaimableRBFAmountV3 = createSelector(
	[earnRBFBaseState],
	state => {
		const { RBFList } = state;
		if (!RBFList || RBFList.length === 0) {
			return '0';
		}
		return RBFList.reduce((pre, cur) => plus(pre, cur.claimableIncome), '0');
	}
);

// v1
export const selectClaimRBFCalldatas = createSelector(
	[txBaseState, earnRBFBaseState],
	(txBaseState, state) => {
		const { appToken } = txBaseState;
		const { previousRBFList } = state;
		if (!previousRBFList || previousRBFList.length === 0) {
			return null;
		}
		const results = [] as Array<ClaimCalldata>;
		const _previewRBFList = previousRBFList.filter(
			item =>
				isGreaterThan(
					item.pool.riskBufferFundRewardGrowthX64,
					item.rewardGrowthX64
				) || isGreaterThan(item.unclaimed, 0)
		);
		if (_previewRBFList.length === 0) {
			return null;
		}
		const ids = _previewRBFList.map(item => item.pool.id);
		const appAmount = _previewRBFList.reduce((pre, cur) => {
			const rbrReward = RewardsUtil.calculateRBFMiningRewardV1(cur);
			return plus(pre, rbrReward.rewardsEQU);
		}, '0');
		if (isZero(appAmount)) {
			return null;
		}
		results.push({
			type: Type_Earns.RBF,
			ids,
			rewardToken: appToken,
			amount: appAmount
		});
		return results;
	}
);

// v1
export const selectPreviousClaimableRBFAmount = createSelector(
	[selectClaimRBFCalldatas],
	claimRBFCalldatas => {
		if (!claimRBFCalldatas) return '0';
		return claimRBFCalldatas.reduce((pre, cur) => plus(pre, cur.amount), '0');
	}
);
