import { useMemo } from 'react';

import { BACKEND_HOST_URL } from 'config/backend';
import {
	GLOBAL_INTERVAL_TIMEOUT,
	MARKET_INTERVAL,
	PRICES_INTERVAL_TIMEOUT,
	PRICE_FETCH_INTERVAL
} from 'config/constants';
import { arbitrum } from 'config/constants/connector/chains';

import { useCurrentChain } from 'hooks/useCurrentChain';
import { isEmpty } from 'lodash';
import {
	IPoolReward,
	IPositionFarmClaimRep,
	Lockup_Period,
	Reward_Type
} from 'pages/Earn/types';
import {
	Referral_Request_Reward_Type,
	Referral_Request_Type
} from 'pages/Referrals/types';
import qs from 'qs';
import useSWR, { Middleware, SWRHook } from 'swr';
import useSWRMutation from 'swr/mutation';
import { isNumeric } from 'utils';

export function toCamelCase(obj: any) {
	let result = obj;
	if (!result) {
		return result;
	} else if (typeof obj === 'object') {
		if (obj instanceof Array) {
			result = obj.map(toCamelCase);
		} else {
			result = {};
			for (const key in obj) {
				// eslint-disable-next-line no-prototype-builtins
				if (obj.hasOwnProperty(key)) {
					const newKey = key.replace(/(_\w)/g, k => k[1].toUpperCase());
					result[newKey] = toCamelCase(obj[key]);
				}
			}
		}
	}
	return result;
}

const fetcher = async (...args: Parameters<typeof fetch>) => {
	const response = await fetch(...args);
	const data = await response.json();
	return data;
};

const postFetcher = (url: string, params: Record<string, unknown>) =>
	fetch(url, {
		method: 'post',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(params)
	}).then(r => r.json());

const callFetcher = async (...args: Parameters<typeof fetch>) => {
	const response = await fetch(...args);
	const data = await response.json();
	return toCamelCase(data);
};

const sendFetcher = (url: string, params: Record<string, unknown>) =>
	fetch(url, {
		method: 'post',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(params)
	})
		.then(r => r.json())
		.then(r => {
			return toCamelCase(r);
		});

export const useTokensPrice = (addresses: Array<string> | null) => {
	const fetchedAddress = useMemo(() => {
		if (isEmpty(addresses)) {
			return [];
		}
		return addresses.filter(item => item);
	}, [addresses]);
	const { currentChainId } = useCurrentChain();
	const tokensPriceUrl = `${BACKEND_HOST_URL[currentChainId].V1}/tokens/24hr`;
	const { data, isLoading, mutate } = useSWR(
		!isEmpty(fetchedAddress) ? [tokensPriceUrl, fetchedAddress] : null,
		([tokensPriceUrl, addresses]) => postFetcher(tokensPriceUrl, { addresses }),
		{
			refreshInterval: PRICES_INTERVAL_TIMEOUT,
			refreshWhenHidden: true,
			refreshWhenOffline: true
		}
	);
	return {
		tokensPrice: data?.data,
		fetchTokenPriceIsLoading: isLoading,
		fetchTokensPrice: mutate
	};
};

export const useMultiTokens = (addresses: Array<string> | null) => {
	const fetchedAddress = useMemo(() => {
		if (isEmpty(addresses)) {
			return [];
		}
		return addresses.filter(item => item);
	}, [addresses]);
	const { currentChainId } = useCurrentChain();
	const multiTokensUrl = `${BACKEND_HOST_URL[currentChainId].V1}/tokens/by-addresses`;
	const { data, isLoading, mutate } = useSWR(
		!isEmpty(fetchedAddress) ? [multiTokensUrl, fetchedAddress] : null,
		([multiTokensUrl, addresses]) => postFetcher(multiTokensUrl, { addresses }),
		{
			refreshInterval: PRICES_INTERVAL_TIMEOUT,
			refreshWhenHidden: true,
			refreshWhenOffline: true
		}
	);
	return {
		tokensMultiPrice: data?.data,
		fetchMultiTokensIsLoading: isLoading,
		fetchPoolsMultiPrice: mutate
	};
};

export const useFarmClaimRequest = (address: string) => {
	const { currentChainId } = useCurrentChain();
	const url = `${BACKEND_HOST_URL[currentChainId].V1}/farm/claim?account=${address}`;
	const { data, isLoading, mutate } = useSWR(address ? url : null, fetcher);
	return {
		data: data?.data as IPositionFarmClaimRep | undefined,
		isLoading,
		refetch: mutate
	};
};

export const useFarmClaimRequestByPools = (
	address: string,
	pools: Array<string> | null
) => {
	const { currentChainId } = useCurrentChain();
	const url = useMemo(() => {
		if (!pools || pools.length === 0) {
			return null;
		}
		const _pools = pools.map((pool: string) => {
			return `pools=${pool}`;
		});
		return `${
			BACKEND_HOST_URL[currentChainId].V1
		}/farm/claim?account=${address}&${_pools.join('&')}`;
	}, [pools]);

	const { data, isLoading, mutate } = useSWR(
		address && pools && pools.length > 0 ? url : null,
		fetcher
	);
	return {
		data: data?.data as IPositionFarmClaimRep | undefined,
		isLoading,
		refetch: mutate
	};
};

export const useFarmClaimV2RequestByPools = (
	address: string,
	pools: Array<string> | null,
	rewardTypes: Array<Reward_Type> | null,
	period: Lockup_Period | undefined
) => {
	const { currentChainId } = useCurrentChain();

	const url = useMemo(() => {
		if (!rewardTypes || rewardTypes.length === 0 || !isNumeric(period)) {
			return null;
		}

		if (pools && pools.length === 0) {
			return null;
		}

		const _reward_types = rewardTypes.map((type: Reward_Type) => {
			return `reward_type=${type}`;
		});

		const url = `${
			BACKEND_HOST_URL[currentChainId].V1
		}/farm/claim-v2?account=${address}&${_reward_types.join(
			'&'
		)}&lockup_period=${period}`;

		if (pools && pools.length > 0) {
			const _pools = pools.map((pool: string) => {
				return `pools=${pool}`;
			});
			return url + `&${_pools.join('&')}`;
		}

		return url;
	}, [pools, rewardTypes, period]);

	const { data, isLoading, mutate } = useSWR(
		address && rewardTypes && rewardTypes.length > 0 && isNumeric(period)
			? url
			: null,
		fetcher
	);

	return {
		data: data?.data?.calldata as string | undefined,
		isLoading,
		refetch: mutate
	};
};

export const useFarmClaimMixRequestByPools = (
	address: string,
	pools: Array<string> | null,
	rewardTypes: Array<Reward_Type> | null,
	period: Lockup_Period | undefined,
	version?: string
) => {
	const { currentChainId } = useCurrentChain();

	const url = useMemo(() => {
		if (!rewardTypes || rewardTypes.length === 0 || !isNumeric(period)) {
			return null;
		}

		if (pools && pools.length === 0) {
			return null;
		}

		const _reward_types = rewardTypes.map((type: Reward_Type) => {
			return `reward_type=${type}`;
		});

		const url = `${
			BACKEND_HOST_URL[currentChainId].V1
		}/farm/claim-mixed?account=${address}&${_reward_types.join(
			'&'
		)}&lockup_period=${period}&contract_version=${version}`;

		if (pools && pools.length > 0) {
			const _pools = pools.map((pool: string) => {
				return `pools=${pool}`;
			});
			return url + `&${_pools.join('&')}`;
		}

		return url;
	}, [pools, rewardTypes, period]);

	const { data, isLoading, mutate } = useSWR(
		address && rewardTypes && rewardTypes.length > 0 && isNumeric(period)
			? url
			: null,
		fetcher
	);

	return {
		data: data?.data?.calldatas as Array<string> | null,
		isLoading,
		refetch: mutate
	};
};

export const useFarmClaimDetailsV2Request = (
	address: string,
	rewardType: Reward_Type
) => {
	const { currentChainId } = useCurrentChain();

	const url = useMemo(() => {
		if (!address) {
			return `${BACKEND_HOST_URL[currentChainId].V1}/farm/claim-details?reward_type=${rewardType}`;
		}
		return `${BACKEND_HOST_URL[currentChainId].V1}/farm/claim-details?address=${address}&reward_type=${rewardType}`;
	}, [rewardType, currentChainId, address]);

	const { data, isLoading, mutate } = useSWR(url, fetcher);
	const restProps = useMemo(() => {
		if (isEmpty(data) || isEmpty(data?.data)) {
			return {};
		}
		const { pool_rewards, ...restProps } = data?.data as any;
		return restProps;
	}, [data]);

	return {
		data: data?.data?.pool_rewards as Array<IPoolReward> | undefined,
		...restProps,
		isLoading,
		refetch: mutate
	};
};

export const useReferralClaimDetailsV2Request = (
	address: string,
	referralType: Referral_Request_Type,
	rewardTypes?: Referral_Request_Reward_Type | ''
) => {
	const { currentChainId } = useCurrentChain();

	const url = useMemo(() => {
		if (!address) {
			return null;
		}
		return `${
			BACKEND_HOST_URL[currentChainId].V1
		}/referral/claim-details?address=${address}&token_type=${referralType}${
			rewardTypes ? '&reward_types=' + rewardTypes : ''
		}`;
	}, [referralType, currentChainId, address, rewardTypes]);

	const { data, isLoading, mutate } = useSWR(url, fetcher);

	return {
		data: data?.data?.token_rewards as Array<IPoolReward> | undefined,
		poolData: data?.data?.pool_rewards,
		isLoading,
		refetch: mutate
	};
};

export const useReferralClaimRequestByTokenIds = (
	address: string,
	tokenIds: Array<string> | null,
	rewardTypes: Array<Referral_Request_Reward_Type> | null,
	period: Lockup_Period | undefined,
	tokenTypes: Array<Referral_Request_Type> | null
) => {
	const { currentChainId } = useCurrentChain();

	const url = useMemo(() => {
		if (
			!tokenIds ||
			tokenIds.length === 0 ||
			!rewardTypes ||
			rewardTypes.length === 0 ||
			!isNumeric(period)
		) {
			return null;
		}

		const _reward_types = rewardTypes.map(
			(type: Referral_Request_Reward_Type) => {
				return `reward_types=${type}`;
			}
		);
		const _token_ids = tokenIds.map((id: string) => {
			return `token_ids=${id}`;
		});
		return `${
			BACKEND_HOST_URL[currentChainId].V1
		}/referral/claim?account=${address}&${_reward_types.join(
			'&'
		)}&lockup_period=${period}&token_types=${tokenTypes}&${_token_ids.join(
			'&'
		)}`;
	}, [tokenIds, rewardTypes, period]);

	const { data, isLoading, mutate } = useSWR(
		address &&
			tokenIds &&
			tokenIds.length > 0 &&
			rewardTypes &&
			rewardTypes.length > 0 &&
			isNumeric(period)
			? url
			: null,
		fetcher
	);

	return {
		data: data?.data?.calldata as string | undefined,
		isLoading,
		refetch: mutate
	};
};

export const getServerTimeStamp = () => {
	const { currentChainId } = useCurrentChain();
	const url = `${BACKEND_HOST_URL[currentChainId].V1}/tradingview/udf/time`;
	const { data, isLoading, mutate } = useSWR(url, fetcher);
	return {
		data: data?.data,
		isLoading,
		refetch: mutate
	};
};

export const getGasConfigRequest = (version: string) => {
	const { currentChainId } = useCurrentChain();
	const url = `${BACKEND_HOST_URL[currentChainId][version]}/contract/gas-config`;
	const { data, isLoading, mutate } = useSWR(url, fetcher);
	return {
		data: data?.data,
		isLoading,
		refetch: mutate
	};
};

export function useNativeTokenPrice() {
	const url = `${
		BACKEND_HOST_URL[arbitrum.id].V1
	}/tokens/equ-supply?source=trade`;
	const { data, isLoading, mutate } = useSWR(url, fetcher, {
		refreshInterval: GLOBAL_INTERVAL_TIMEOUT
	});
	return {
		data: data?.data,
		isLoading,
		refetch: mutate
	};
}

export function useReferralLeaderboard(range: string) {
	const { currentChainId } = useCurrentChain();
	const url = `${BACKEND_HOST_URL[currentChainId].V1}/leaderboard/referral?resolution=${range}`;
	const { data, isLoading, mutate } = useSWR(url, fetcher);
	return {
		data: data?.data,
		isLoading,
		refetch: mutate
	};
}
export function useTradeLeaderboard(params: any) {
	const { currentChainId } = useCurrentChain();
	const url = isEmpty(params)
		? null
		: `${
				BACKEND_HOST_URL[currentChainId].V1
		  }/leaderboard/trading?${qs.stringify(params)}`;
	const { data, isLoading, mutate } = useSWR(url, fetcher, {
		keepPreviousData: true
	});
	return {
		data: data?.data,
		isLoading,
		refetch: mutate
	};
}

export const useMiningLeaderboard = (params: { resolution: string }) => {
	const { currentChainId } = useCurrentChain();
	const url = isEmpty(params)
		? null
		: `${BACKEND_HOST_URL[currentChainId].V1}/leaderboard/mining?${qs.stringify(
				params
		  )}`;
	const { data, isLoading, mutate } = useSWR(url, fetcher, {
		keepPreviousData: true
	});
	return {
		data: data?.data,
		isLoading,
		refetch: mutate
	};
};

export const useTokensPriceV2 = (addresses: Array<string> | null) => {
	const fetchedAddress = useMemo(() => {
		if (isEmpty(addresses)) {
			return [];
		}
		return addresses.filter(item => item);
	}, [addresses?.length]);
	const { currentChainId } = useCurrentChain();
	const tokensPriceUrl = `${BACKEND_HOST_URL[currentChainId].V2}/prices/24hr`;
	const { data, isLoading, mutate } = useSWR(
		!isEmpty(fetchedAddress) ? [tokensPriceUrl, fetchedAddress] : null,
		([tokensPriceUrl, addresses]) => sendFetcher(tokensPriceUrl, { addresses }),
		{
			refreshInterval: PRICE_FETCH_INTERVAL,
			refreshWhenHidden: true,
			refreshWhenOffline: true
		}
	);
	return {
		tokensPrice: data?.data,
		fetchTokenPriceIsLoading: isLoading,
		fetchTokensPrice: mutate
	};
};

export const useMultiTokensV2 = (addresses: Array<string> | null) => {
	const fetchedAddress = useMemo(() => {
		if (isEmpty(addresses)) {
			return [];
		}
		return addresses.filter(item => item);
	}, [addresses?.length]);
	const { currentChainId } = useCurrentChain();
	const multiTokensUrl = `${BACKEND_HOST_URL[currentChainId].V2}/prices/by-addresses`;
	const { data, isLoading, mutate } = useSWR(
		!isEmpty(fetchedAddress) ? [multiTokensUrl, fetchedAddress] : null,
		([multiTokensUrl, addresses]) => sendFetcher(multiTokensUrl, { addresses }),
		{
			refreshInterval: PRICE_FETCH_INTERVAL,
			refreshWhenHidden: true,
			refreshWhenOffline: true
		}
	);
	return {
		tokensMultiPrice: data?.data,
		fetchMultiTokensIsLoading: isLoading,
		fetchPoolsMultiPrice: mutate
	};
};
export const usePoolsRequest = () => {
	const { currentChainId } = useCurrentChain();
	const url = `${BACKEND_HOST_URL[currentChainId].V2}/markets`;
	const { data, isLoading, mutate } = useSWR(url, callFetcher);
	return {
		data: data?.data,
		isLoading,
		refetch: mutate
	};
};

export const usePoolInfoRequest = (address: string | undefined) => {
	const { currentChainId } = useCurrentChain();
	const url = address
		? `${BACKEND_HOST_URL[currentChainId].V2}/markets/${address}`
		: null;
	const { data, isLoading, mutate } = useSWR(url, callFetcher);
	return {
		data: data?.data,
		isLoading,
		refetch: mutate
	};
};

export function useMyPositionsRequest(params) {
	const { currentChainId, isNetworkError } = useCurrentChain();
	const { enabled } = params;
	const key = `${BACKEND_HOST_URL[currentChainId].V2}/positions`;

	const { data, isLoading, mutate, isValidating } = useSWR(
		params.account && enabled && !isNetworkError ? key : null,
		key => sendFetcher(key, params)
	);

	return {
		data: data?.data,
		isLoading,
		refetch: mutate,
		isValidating
	};
}
export const useOrdersRequest = (params: {
	account: string;
	market: string;
	status: string;
	limit: number;
	from: string | undefined;
	hashes: Array<string> | undefined;
}) => {
	const { currentChainId } = useCurrentChain();
	const url = `${BACKEND_HOST_URL[currentChainId].V2}/orders`;
	const { trigger } = useSWRMutation(
		params.account ? [url, params] : null,
		([url, params]) => sendFetcher(url, params)
	);
	return { trigger };
};

export const useOrdersStatusRequest = (id: string | undefined) => {
	const { currentChainId } = useCurrentChain();
	const url = id ? `${BACKEND_HOST_URL[currentChainId].V2}/orders/${id}` : null;
	const { trigger } = useSWRMutation(url, callFetcher);
	return { trigger };
};

export const useTradeHistoriesRequest = (
	account: string,
	pool: string,
	from: number,
	limit: number
) => {
	const { currentChainId } = useCurrentChain();

	const url = useMemo(() => {
		if (!account && !isNumeric(limit) && !from) {
			return null;
		}
		return `${BACKEND_HOST_URL[currentChainId].V2}/trade-histories/${account}?&market=${pool}&from=${from}&limit=${limit}`;
	}, [account, pool, limit, from]);

	const { trigger } = useSWRMutation(
		account && limit && isNumeric(limit) ? url : null,
		callFetcher
	);

	return {
		trigger
	};
};

export const usePositionHistoriesRequest = (
	address: string,
	pool: string,
	from: string | undefined,
	limit: number
) => {
	const { currentChainId } = useCurrentChain();

	const url = useMemo(() => {
		if (!address || !isNumeric(limit)) {
			return null;
		}
		const _url = `${BACKEND_HOST_URL[currentChainId].V2}/position-histories/${address}?&market=${pool}&limit=${limit}`;
		if (from) {
			return `${_url}&from=${from}`;
		}
		return _url;
	}, [address, pool, limit, from]);

	const { trigger } = useSWRMutation(
		address && limit && isNumeric(limit) ? url : null,
		callFetcher
	);

	return {
		trigger
	};
};
export const useLiquidityHistoriesRequest = (
	account?: string,
	params?: {
		market: string;
		limit: number;
		from: number;
	}
) => {
	const { currentChainId } = useCurrentChain();

	const { trigger } = useSWRMutation(
		!account
			? null
			: `${
					BACKEND_HOST_URL[currentChainId].V2
			  }/liquidity-histories/${account}?${qs.stringify(params)}`,
		callFetcher
	);

	return { trigger };
};

export const useLiquidityPositionHistoriesRequest = (params: {
	account: string;
	market: string;
	limit: number;
	from: string | number;
}) => {
	const { currentChainId } = useCurrentChain();
	const key = params.account
		? `${BACKEND_HOST_URL[currentChainId].V2}/liquidity-positions/${
				params.account
		  }/histories?${qs.stringify(params)}`
		: null;

	const { trigger } = useSWRMutation(params.account ? [key] : null, () =>
		callFetcher(key)
	);

	return { trigger };
};

export const usePassivePositionHistoriesRequest = (params: {
	account: string;
	market: string;
	limit: number;
	from: string | number;
	liquidity_position_history_id?: number;
}) => {
	const { currentChainId } = useCurrentChain();
	const key = params.account
		? `${BACKEND_HOST_URL[currentChainId].V2}/liquidity-positions/${
				params.account
		  }/passive-position-histories?${qs.stringify(params)}`
		: null;

	const { trigger } = useSWRMutation(params.account ? [key] : null, () =>
		callFetcher(key)
	);
	return { trigger };
};

export const useLiquidityPositionsRequest = (params: {
	account: string;
	status: string;
	pool?: string;
	limit?: number;
	from?: number;
	hashes?: string[];
	enabled?: boolean;
}) => {
	const { currentChainId } = useCurrentChain();

	const key =
		params.enabled && params.account
			? `${BACKEND_HOST_URL[currentChainId].V2}/liquidity-positions`
			: null;

	const { data, mutate, isLoading, isValidating } = useSWR(
		params.account ? [key] : null,
		() =>
			sendFetcher(
				`${BACKEND_HOST_URL[currentChainId].V2}/liquidity-positions`,
				params
			)
	);

	return { data, isLoading, isValidating, mutate };
};

export function usePoolBalanceRate(params: any) {
	const fetchedParmas = useMemo(() => {
		if (isEmpty(params)) {
			return null;
		}
		return params;
	}, [params]);
	const { currentChainId } = useCurrentChain();
	const url = `${
		BACKEND_HOST_URL[currentChainId].V2
	}/markets/balance-rates?${qs.stringify(fetchedParmas)}`;
	const { data, isLoading, mutate } = useSWR(
		isEmpty(fetchedParmas) ? null : url,
		callFetcher,
		{
			refreshInterval: 0,
			keepPreviousData: true
		}
	);
	return {
		data: data?.data,
		isLoading,
		refetch: mutate
	};
}

export function usePoolFundingRate(params: any) {
	const fetchedParmas = useMemo(() => {
		if (isEmpty(params)) {
			return null;
		}
		return params;
	}, [params]);
	const { currentChainId } = useCurrentChain();
	const url = `${
		BACKEND_HOST_URL[currentChainId].V2
	}/markets/funding-rate-histories?${qs.stringify(fetchedParmas)}`;
	const { data, isLoading, mutate } = useSWR(
		isEmpty(fetchedParmas) ? null : url,
		callFetcher,
		{ refreshInterval: 0, keepPreviousData: true }
	);
	return {
		data: data?.data,
		isLoading,
		refetch: mutate
	};
}
