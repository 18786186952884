import { createSelector } from '@reduxjs/toolkit';

import _ from 'lodash';
import { ClaimCalldata, Type_Earns } from 'pages/Earn/types';
import { txBaseState } from 'state/tx/slice';
import { isGreaterThan, plus } from 'utils';

import { earnStakeBaseState } from './slice';

export const selectClaimableStakeList = createSelector(
	[earnStakeBaseState],
	state => {
		const { stakeList } = state;
		if (!stakeList || stakeList.length === 0) {
			return null;
		}
		return _.orderBy(
			stakeList.filter(item => isGreaterThan(item.claimableReward, 0)),
			o => {
				return parseFloat(o.claimableReward);
			},
			['desc']
		);
	}
);

export const selectClaimableLpStakeList = createSelector(
	[earnStakeBaseState],
	state => {
		const { lpStakeList } = state;
		if (!lpStakeList || lpStakeList.length === 0) {
			return null;
		}
		return _.orderBy(
			lpStakeList.filter(item => isGreaterThan(item.claimableReward, 0)),
			o => {
				return parseFloat(o.claimableReward);
			},
			['desc']
		);
	}
);

export const selectClaimableAllStakeList = createSelector(
	[selectClaimableStakeList, selectClaimableLpStakeList],
	(claimableStakeList, claimableLpStakeList) => {
		if (!claimableStakeList && !claimableLpStakeList) {
			return null;
		}
		return _.merge(claimableLpStakeList, claimableStakeList);
	}
);

// 单纯质押EQU的可领取奖励calldatas
export const selectClaimStakeCalldatas = createSelector(
	[txBaseState, selectClaimableStakeList],
	(txBaseState, claimableStakeList) => {
		const { quoteToken } = txBaseState;
		if (!claimableStakeList || claimableStakeList.length === 0) {
			return null;
		}
		const results = [] as Array<ClaimCalldata>;
		const stakeIDs = claimableStakeList.map(item => item.stakeID);
		const quoteAmount = claimableStakeList.reduce(
			(pre, cur) => plus(pre, cur.claimableReward),
			'0'
		);
		results.push({
			type: Type_Earns.Staking,
			ids: stakeIDs,
			rewardToken: quoteToken,
			amount: quoteAmount
		});
		return results;
	}
);

// 可领取的USDT
export const selectClaimableStakeAmount = createSelector(
	[selectClaimStakeCalldatas],
	claimStakeCalldatas => {
		if (!claimStakeCalldatas) return '0';
		return claimStakeCalldatas.reduce((pre, cur) => plus(pre, cur.amount), '0');
	}
);

export const selectValidMyUniPositions = createSelector(
	[earnStakeBaseState],
	state => {
		const { myUniPositions } = state;
		if (!myUniPositions || myUniPositions.length === 0) {
			return null;
		}
		return myUniPositions.filter(item => isGreaterThan(item.baseAmount, 0));
	}
);
