import { useEffect, useMemo } from 'react';
import { useUpdateEffect } from 'react-use';

import { t } from '@lingui/macro';
import {
	Request_Status,
	Transaction_Status,
	Transaction_Type
} from 'config/constants';
import { Order_Status_V2 } from 'config/constants/enum';
import { useMyPostionsFetch } from 'fetch/useMyPositionsFetch';
import { useAccount } from 'wagmi';

import { TransactionRecord } from 'entities/TransactionRecord';
import { useMyPostionsGraph } from 'graphql/useMyPositionsGraph';
import useTradePositionRequests from 'graphql/useTradePositionRequests';
import useIsWindowVisible from 'hooks/useIsWindowVisible';
import { intersectionWith } from 'lodash';
import { globalBaseState } from 'state/global/slice';
import { useAppSelector } from 'state/hooks';
import { selectTransactionRecordList } from 'state/records/selector';
import { selectOrderStatus } from 'state/setting/selector';
import { IRequestItem } from 'types';
import { isLocalStorageFull } from 'utils';

import { sentryMessage } from '../utils/sentry';
import { useManageTransactions } from './useAccountTransactions';
import { useCheckLogin } from './useCurrentChain';
import useLocalSnackbar from './useLocalSnackbar';

export function useV1TradePositionRequest(poolId?: string) {
	const { appTimer } = useAppSelector(globalBaseState);
	const transactionRecords = useAppSelector(selectTransactionRecordList);
	const Order_Status = useAppSelector(selectOrderStatus);
	const isLogin = useCheckLogin();
	const { address } = useAccount();
	const isWindowVisible = useIsWindowVisible();

	const { removeTransaction } = useManageTransactions();
	const { showSnackbar } = useLocalSnackbar();

	const { requestData, refetch: refetchRequests } = useTradePositionRequests();

	const { positionList, positionMap, isLoadingPositions, refetchPositions } =
		useMyPostionsGraph({ poolId: poolId, enabled: true });

	useEffect(() => {
		if (requestData) {
			refetchPositions();
		}
	}, [requestData]);

	useUpdateEffect(() => {
		if (isLogin && isWindowVisible) {
			refetchRequests();
		}
	}, [isLogin, address, appTimer, isWindowVisible]);

	// 处理正在同步的交易
	useEffect(() => {
		if (!requestData) {
			return;
		}
		const results = intersectionWith(
			transactionRecords,
			requestData,
			(txRecord, request) => {
				return (
					request.status !== Order_Status.Created &&
					txRecord.hash === request.createdHash
				);
			}
		);

		const cancelledResults = intersectionWith(
			requestData,
			transactionRecords,
			(request, txRecord) => {
				return (
					request.createdHash === txRecord.hash &&
					request.status === Request_Status.Cancelled
				);
			}
		);

		cancelledResults.forEach((item: IRequestItem) => {
			const txRecord = transactionRecords.find(
				(tx: any) => tx.hash === item.createdHash
			);

			if (txRecord) {
				const record = txRecord.record;
				if (
					record.transactionType === Transaction_Type.Long ||
					record.transactionType === Transaction_Type.Short
				) {
					// 创建仓位失败
					showSnackbar(
						t`Fail to open position`,
						item.cancelledHash,
						Transaction_Status.Error
					);
				}
				if (
					record.transactionType === Transaction_Type.IncreaseMargin ||
					record.transactionType === Transaction_Type.ReduceMargin
				) {
					// 调整保证金失败
					showSnackbar(
						t`Adjust Margin open position`,
						item.cancelledHash,
						Transaction_Status.Error
					);
				}
				if (record.transactionType === Transaction_Type.Close) {
					// 平仓失败
					showSnackbar(
						t`Fail to close position`,
						item.cancelledHash,
						Transaction_Status.Error
					);
				}
			}
		});

		results.forEach((item: TransactionRecord) => {
			item.syncing = false;
			if (!isLocalStorageFull()) {
				removeTransaction(item);
			} else {
				console.warn('isLocalStorageFull: true !!!');
			}
		});
	}, [positionList]);

	return {
		positionList,
		positionMap,
		isLoadingPositions,
		refetchPositions
	};
}

export function useV2TradePositionRequest(poolId?: string, from?: string) {
	const transactionRecords = useAppSelector(selectTransactionRecordList);
	const timeoutRecords = useAppSelector(state => state.records.timeoutRecords);
	const isLogin = useCheckLogin();
	const { address } = useAccount();
	const isWindowVisible = useIsWindowVisible();
	const { appTimer } = useAppSelector(globalBaseState);

	const { removeTransaction } = useManageTransactions();
	const { showSnackbar } = useLocalSnackbar();

	const filteredTxRecords = useMemo(() => {
		return transactionRecords.filter((txRecord: TransactionRecord) => {
			return (
				txRecord.type === Transaction_Type.LongV2 ||
				txRecord.type === Transaction_Type.ShortV2 ||
				txRecord.type === Transaction_Type.IncreaseMarginV2 ||
				txRecord.type === Transaction_Type.ReduceMarginV2 ||
				txRecord.type === Transaction_Type.CloseV2
			);
		});
	}, [transactionRecords]);

	const hashes = useMemo(() => {
		if (filteredTxRecords.length === 0) {
			return undefined;
		}
		return filteredTxRecords.map((txRecord: TransactionRecord) => {
			return txRecord.hash;
		});
	}, [filteredTxRecords]);

	const {
		positionList,
		positionMap,
		isLoadingPositions,
		requestsByHashes,
		refetchPositions
	} = useMyPostionsFetch({ poolId: poolId || '', hashes, enabled: true });

	useUpdateEffect(() => {
		if (isLogin && isWindowVisible) {
			refetchPositions();
		}
	}, [isLogin, address, isWindowVisible, appTimer]);

	useEffect(() => {
		if (timeoutRecords.size > 0 && requestsByHashes.length > 0) {
			sentryMessage('timeoutRecords', {
				data: {
					requestsByHashes: JSON.stringify(requestsByHashes),
					timeoutRecords: Array.from(timeoutRecords.keys()).toString(),
					hashes: JSON.stringify(hashes)
				}
			});
		}
	}, [requestsByHashes, timeoutRecords, hashes]);
	// 处理正在同步的交易
	useEffect(() => {
		if (!requestsByHashes || !requestsByHashes.length) {
			return;
		}

		const results = intersectionWith(
			filteredTxRecords,
			requestsByHashes,
			(txRecord, request) => {
				return (
					request.status !== Order_Status_V2.Created &&
					txRecord.hash === request.createdHash
				);
			}
		);

		const cancelledResults = intersectionWith(
			requestsByHashes,
			filteredTxRecords,
			(request, txRecord) => {
				return (
					request.createdHash === txRecord.hash &&
					request.status === Order_Status_V2.Cancelled
				);
			}
		);

		cancelledResults.forEach((item: IRequestItem) => {
			const txRecord = filteredTxRecords.find(
				(tx: any) => tx.hash === item.createdHash
			);

			if (txRecord) {
				const record = txRecord.record;
				if (
					record.transactionType === Transaction_Type.LongV2 ||
					record.transactionType === Transaction_Type.ShortV2
				) {
					// 创建仓位失败
					showSnackbar(
						t`Fail to open position`,
						item.cancelledHash,
						Transaction_Status.Error
					);
				}
				if (
					record.transactionType === Transaction_Type.IncreaseMarginV2 ||
					record.transactionType === Transaction_Type.ReduceMarginV2
				) {
					// 调整保证金失败
					showSnackbar(
						t`Adjust Margin open position`,
						item.cancelledHash,
						Transaction_Status.Error
					);
				}
				if (record.transactionType === Transaction_Type.CloseV2) {
					// 平仓失败
					showSnackbar(
						t`Fail to close position`,
						item.cancelledHash,
						Transaction_Status.Error
					);
				}
			}
		});

		results.forEach((item: TransactionRecord) => {
			item.syncing = false;
			// console.log('remove begin: ', item.hash);
			if (!isLocalStorageFull()) {
				removeTransaction(item);
			} else {
				console.warn('isLocalStorageFull: true !!!');
				sentryMessage('isLocalStorageFull', {
					data: {
						record: JSON.stringify(item)
					}
				});
			}
		});
	}, [requestsByHashes, filteredTxRecords]);

	return {
		positionList,
		positionMap,
		isLoadingPositions,
		refetchPositions
	};
}
