import React, { useContext, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Trans } from '@lingui/macro';
import { Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import { MSG_ERROR_APPROVE, Transaction_Type } from 'config/constants';
import { getContractAddress } from 'config/contracts';
import { MobileActionContext } from 'context/MobileActionsContext';

import { ApprovalState, useApproval } from 'hooks/useApproval';
import { useApprovalPlugin } from 'hooks/useApprovalPlugin';
import { useCheckLogin, useCurrentChain } from 'hooks/useCurrentChain';
import { useSubmitIncreaseLiquidity } from 'hooks/useSubmitIncreaseLiquidity';
import { debounce } from 'lodash';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { liquidityBaseState, reset } from 'state/liquidity/slice';
import { poolsBaseState } from 'state/pools/slice';
import { txBaseState } from 'state/tx/slice';
import { isGreaterThan, isLessThan, isPositive, shortenSymbol } from 'utils';

import StyledButton from 'components/Common/StyledButton';
import ConnectWalletButton from 'components/Wallet/ConnectWalletButton';

import ConfirmIncreaseLiquidity from './ConfirmIncreaseLiquidity';

export default React.memo(function LiquidityButton() {
	const dispatch = useAppDispatch();

	const { quoteToken, quoteBalance, signingMap } = useAppSelector(txBaseState);
	const { leverage, quoteAmount, liquidityAmount } =
		useAppSelector(liquidityBaseState);
	const { poolInfo } = useAppSelector(poolsBaseState);

	const { setIsVisibleForm } = useContext(MobileActionContext);

	const isLogin = useCheckLogin();
	const theme = useTheme();
	const { currentChainId } = useCurrentChain();

	const routerContractAddress = useMemo(() => {
		return getContractAddress(currentChainId, 'Router');
	}, [currentChainId]);

	const positionRouterContractAddress = useMemo(() => {
		return getContractAddress(currentChainId, 'PositionRouter');
	}, [currentChainId]);

	const { currentApproveState, approveCallback } = useApproval(
		quoteToken,
		quoteAmount,
		routerContractAddress
	);

	const { isPluginApproved, isPluginApproving, onApprovePlugin } =
		useApprovalPlugin(positionRouterContractAddress);

	const { onConfirm, isConfirming, isConfirmed, errMsg } =
		useSubmitIncreaseLiquidity(currentApproveState, isPluginApproved);

	const canSubmit = useMemo(() => {
		if (
			!poolInfo ||
			!isPositive(quoteBalance) ||
			!isPositive(quoteAmount) ||
			!isPositive(liquidityAmount)
		) {
			return false;
		}
		if (isLessThan(quoteBalance, quoteAmount)) {
			return false;
		}
		if (currentApproveState === ApprovalState.NOT_APPROVED) {
			return true;
		}
		if (currentApproveState === ApprovalState.PENDING) {
			return false;
		}
		if (!isPluginApproved) {
			return true;
		}
		if (isPluginApproving) {
			return false;
		}
		if (isLessThan(quoteAmount, poolInfo.minMarginPerLiquidityPosition)) {
			return false;
		}
		if (isGreaterThan(leverage, poolInfo.maxLeveragePerLiquidityPosition)) {
			return false;
		}
		if (signingMap.get(Transaction_Type.IncreaseLiquidity)) {
			return false;
		}
		if (isConfirming) {
			return false;
		}
		if (errMsg && errMsg.indexOf(MSG_ERROR_APPROVE) >= 0) {
			return true;
		}
		return true;
	}, [
		poolInfo,
		quoteAmount,
		liquidityAmount,
		currentApproveState,
		isPluginApproving,
		quoteBalance,
		leverage,
		isConfirming,
		signingMap,
		errMsg
	]);

	const submitText = useMemo(() => {
		if (poolInfo && isPositive(quoteAmount)) {
			// token balance
			if (isLessThan(quoteBalance, quoteAmount)) {
				return <Trans>Insufficient Balance</Trans>;
			}
			// approve state
			if (currentApproveState === ApprovalState.PENDING) {
				return (
					<span>
						<Trans>Approving...</Trans>
					</span>
				);
			}
			if (currentApproveState === ApprovalState.NOT_APPROVED) {
				return (
					<span>
						<Trans>Approve {`${shortenSymbol(quoteToken)}`}</Trans>
					</span>
				);
			}
			if (!isPluginApproved) {
				return <Trans>Approve Operation (one-time)</Trans>;
			}
			if (isPluginApproving) {
				return <Trans>Approving...</Trans>;
			}

			if (isLessThan(quoteAmount, poolInfo.minMarginPerLiquidityPosition)) {
				return (
					<Trans>
						Min payment: {poolInfo.minMarginPerLiquidityPosition}{' '}
						{shortenSymbol(quoteToken)}
					</Trans>
				);
			}
			if (
				isPositive(leverage) &&
				isGreaterThan(leverage, poolInfo.maxLeveragePerLiquidityPosition)
			) {
				return (
					<Trans>
						Exceeds the max leverage of{' '}
						{poolInfo.maxLeveragePerLiquidityPosition}x
					</Trans>
				);
			}
			if (signingMap.get(Transaction_Type.IncreaseLiquidity)) {
				return <Trans>Loading...</Trans>;
			}
			if (isConfirming) {
				return <Trans>Submitting...</Trans>;
			}
			if (errMsg && errMsg.indexOf(MSG_ERROR_APPROVE) >= 0) {
				return (
					<span>
						<Trans>Approve {`${shortenSymbol(quoteToken)}`}</Trans>
					</span>
				);
			}
		}
		return <Trans>Add</Trans>;
	}, [
		poolInfo,
		quoteAmount,
		currentApproveState,
		isPluginApproved,
		isPluginApproving,
		quoteBalance,
		leverage,
		isConfirming,
		signingMap,
		errMsg
	]);

	useUpdateEffect(() => {
		if (isConfirming) {
			dispatch(reset());
		}
	}, [isConfirming]);

	useUpdateEffect(() => {
		if (isConfirmed) {
			setIsVisibleForm(false);
		}
	}, [isConfirmed]);

	const [confirmOpen, onSetConfirmOpen] = useState(false);

	const onHandleConfirm = debounce(() => {
		if (
			currentApproveState === ApprovalState.NOT_APPROVED ||
			(errMsg && errMsg.indexOf(MSG_ERROR_APPROVE) >= 0)
		) {
			approveCallback();
			return;
		}
		if (isPluginApproved === false) {
			onApprovePlugin();
		}
		onConfirm();
	}, 300);

	return (
		<div>
			{!isLogin ? (
				<ConnectWalletButton fullWidth />
			) : (
				<div className={classNames(!canSubmit && 'cursor-not-allowed')}>
					<StyledButton
						className='flex flex-col justify-center'
						variant='contained'
						size='large'
						fullWidth
						disabled={!canSubmit}
						onClick={onHandleConfirm}
					>
						<Typography
							className='whitespace-normal'
							color='inherit'
							variant='h6'
						>
							{submitText}
						</Typography>
					</StyledButton>
				</div>
			)}

			{confirmOpen && (
				<ConfirmIncreaseLiquidity
					open={true}
					onHandleClose={() => onSetConfirmOpen(false)}
					onSubmit={() => onConfirm()}
				/>
			)}
		</div>
	);
});
