import { useMemo } from 'react';

import { useTheme } from '@mui/material';

import { isNegative, isPositive, toDecimalPlaces } from 'utils';

export function useFormatPropertyColor(value: string) {
	const theme = useTheme();

	return useMemo(() => {
		return isPositive(value)
			? theme.palette.success.main
			: isNegative(value)
			? theme.palette.error.main
			: theme.palette.text.primary;
	}, [value]);
}
