import { Trans } from '@lingui/macro';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import { Dom_Size, Version } from 'config/constants';

import { IPoolInfo } from 'graphql/usePoolInfoGraph';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useAppSelector } from 'state/hooks';
import { IPoolItem } from 'types';

import { CommonStyledDivider } from 'components/Common/Styled';
import StyledTab from 'components/Common/StyledTab';
import StyledTabs from 'components/Common/StyledTabs';

import { useCurrentChain } from '../../hooks/useCurrentChain';
import { shortenSymbolNative } from '../../utils';
import { DepthChartV1, DepthChartV2 } from './DepthChart';
import { FeeChartV1, FeeChartV2 } from './FeeChart';
import PoolInfo from './PoolInfo';
import PriceChart from './PriceChart';

export enum Chart_Tab_Type {
	Price = 0,
	Fee = 1,
	Depth = 2,
	Detail = 3
}

export interface PriceChartProps {
	pool: IPoolItem | IPoolInfo;
	activeTab: Chart_Tab_Type;
	price: string | number;
	height?: string;
	onHandleChangeActiveTab: any;
}

export default function ChartsPool(props: PriceChartProps) {
	const {
		pool,
		activeTab = Chart_Tab_Type.Price,
		price,
		onHandleChangeActiveTab,
		height = `${Dom_Size.CHART}px`
	} = props;

	const { currentChainId } = useCurrentChain();
	const { isMatchMobile } = useAppBreakpoints();
	const theme = useTheme();

	const currentVersion = useAppSelector(state => state.setting.currentVersion);

	return (
		<Box
			className={classNames('relative pb-4 flex flex-col h-full', {
				'flex-1': currentVersion === Version.V2
			})}
		>
			<StyledTabs
				value={activeTab || 0}
				onChange={(event, newType: number) => onHandleChangeActiveTab(newType)}
				className='px-4'
				variant='scrollable'
				scrollButtons='auto'
				allowScrollButtonsMobile
				sx={{
					'&.MuiTabs-root': {
						minHeight: '42px'
					},
					'& .MuiTabScrollButton-root:first-of-type': {
						width: '36px !important',
						top: 0,
						left: -8,
						height: '42px'
					},
					'& .MuiTabScrollButton-root:last-of-type': {
						width: '36px !important',
						top: 0,
						right: -8,
						height: '42px'
					}
				}}
			>
				<StyledTab
					className='pl-0 py-3'
					label={<Trans>Price</Trans>}
					value={Chart_Tab_Type.Price}
				/>
				<StyledTab label={<Trans>Depth</Trans>} value={Chart_Tab_Type.Depth} />
				<StyledTab label={<Trans>Funding</Trans>} value={Chart_Tab_Type.Fee} />
				{isMatchMobile && (
					<StyledTab
						label={<Trans>Details</Trans>}
						value={Chart_Tab_Type.Detail}
					/>
				)}
			</StyledTabs>
			<CommonStyledDivider className='mt-0' type='solid' />
			<Box
				component='div'
				className={classNames('flex-1 px-4 sm:px-2 relative', {
					flex:
						currentVersion === Version.V2 && activeTab !== Chart_Tab_Type.Detail
				})}
			>
				{activeTab === Chart_Tab_Type.Price && (
					<PriceChart
						id={pool.baseToken.address}
						pricescale={Number(pool.baseToken.precision)}
						height={height}
					/>
				)}
				{activeTab === Chart_Tab_Type.Depth &&
					(currentVersion === Version.V1 ? (
						<DepthChartV1
							id={pool.id}
							tokenSymbol={shortenSymbolNative(pool.baseToken, currentChainId)}
						/>
					) : (
						<DepthChartV2
							id={pool.id}
							tokenSymbol={shortenSymbolNative(pool.baseToken, currentChainId)}
						/>
					))}
				{activeTab === Chart_Tab_Type.Fee &&
					(currentVersion === Version.V1 ? (
						<FeeChartV1
							id={pool.id}
							currentFee={pool.globalPosition.fundingRate}
						/>
					) : (
						<FeeChartV2
							id={pool.id}
							currentFee={pool.globalPosition.fundingRate}
						/>
					))}
				{activeTab === Chart_Tab_Type.Detail && <PoolInfo />}
			</Box>
		</Box>
	);
}
