import { useMemo } from 'react';

import {
	Contract_Write_Mode,
	Transaction_Type,
	Version
} from 'config/constants';
import { selectContractByVersion } from 'config/contracts';
import {
	UseContractWriteConfig,
	UsePrepareContractWriteConfig,
	useAccount
} from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { useAppSelector } from 'state/hooks';
import { selectVersion } from 'state/setting/selector';
import { txBaseState } from 'state/tx/slice';
import { RecordForClaimPool2 } from 'types';
import { formatNumber, isPositive, parseUnits } from 'utils';

import { useComputeGasLimit } from './useComputeGasLimit';
import { useCurrentChain } from './useCurrentChain';
import { useSelectRewardConnectorByVersion } from './useSelectByVersion';
import { useSendTransaction } from './useSendTransaction';

export function useSubmitClaimPool2(
	tokenIds: Array<string> | null,
	calldata: string | undefined,
	appAmount: string | undefined,
	quoteAmount: string | undefined
) {
	const { appToken, quoteToken } = useAppSelector(txBaseState);
	const currentVersion = useAppSelector(selectVersion);

	const { currentChainId } = useCurrentChain();
	const { address } = useAccount();

	const { rewardCollectorInterface, rewardCollectorABI } =
		useSelectRewardConnectorByVersion();

	const { args, record, description } = useMemo(() => {
		if (!appToken || !quoteToken) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		console.log('useSubmitClaimPool2 args begin ...');

		const results = [] as Array<string>;

		if (calldata) {
			results.push(calldata);
		}

		if (tokenIds && tokenIds.length > 0) {
			results.push(
				rewardCollectorInterface.encodeFunctionData(
					currentVersion === Version.V1
						? 'collectV3PosStakingRewardBatch'
						: 'collectContractsV1V3PosStakingRewardBatch',
					[tokenIds]
				)
			);
		}

		if (isPositive(quoteAmount)) {
			results.push(
				rewardCollectorInterface.encodeFunctionData('sweepToken', [
					quoteToken.address,
					parseUnits(quoteAmount, quoteToken.decimals),
					address
				])
			);
		}

		// console.log('--------------');
		// console.log(JSON.stringify(results));

		const record = {
			transactionType: Transaction_Type.ClaimPool2,
			appAmount,
			quoteAmount
		} as RecordForClaimPool2;

		const description = TransactionDescription.Claim(
			isPositive(quoteAmount)
				? formatNumber(quoteAmount, quoteToken.positionUnits)
				: undefined,
			isPositive(appAmount)
				? formatNumber(appAmount, appToken.positionUnits)
				: undefined
		);

		return {
			args: [results],
			record,
			description
		};
	}, [
		address,
		appToken,
		quoteToken,
		appAmount,
		quoteAmount,
		tokenIds,
		calldata,
		currentVersion,
		rewardCollectorInterface
	]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address: selectContractByVersion(
				currentVersion,
				currentChainId,
				'RewardCollector'
			),
			abi: rewardCollectorABI,
			functionName: 'multicall',
			args
		} as UseContractWriteConfig;
	}, [currentChainId, args, currentVersion, rewardCollectorABI]);

	const { gasLimit } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirmed, isConfirming, error } =
		useSendTransaction(contractWriteArgs, gasLimit);

	const onConfirm = () => {
		if (!record) return;
		onSendTransaction(record, description);
	};

	return {
		onConfirm,
		isConfirming,
		isConfirmed,
		error
	};
}
