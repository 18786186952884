import { useEffect, useMemo, useState } from 'react';

import { UsePrepareContractWriteConfig, usePrepareContractWrite } from 'wagmi';

import { BigNumber } from 'ethers';
import { calculateGasMargin, formatError } from 'utils';

import { useCurrentChain } from './useCurrentChain';
import { usePrevious } from 'react-use';
import { isEmpty, isEqual } from 'lodash';

export function useComputeGasLimit(
	contractWriteArgs: UsePrepareContractWriteConfig | null
) {
	const { currentChain } = useCurrentChain();

	const [gasLimit, setGasLimit] = useState<BigNumber | null>(null);
	const [prepared, setPrepared] = useState<boolean>(false);
	const [error, setError] = useState<Error | null | undefined>(null);
	const [errMsg, setErrMsg] = useState<string | undefined>(undefined);

	const preContractWriteArgs = usePrevious(contractWriteArgs);

	useEffect(() => {
		setPrepared(false);
	}, [contractWriteArgs]);

	const enabled = useMemo(() => {
		return !isEmpty(contractWriteArgs) && !isEqual(contractWriteArgs, preContractWriteArgs)
	}, [contractWriteArgs, preContractWriteArgs]);

	usePrepareContractWrite({
		...contractWriteArgs,
		enabled,
		// enabled: !isEmpty(contractWriteArgs),
		onSettled: (data, error) => {
			console.log('[usePrepareContractWriteProxyNew] Settled', { data, error });
		},
		onSuccess: data => {
			// console.log('[usePrepareContractWriteProxyNew] Success', { data });
			if (data.request) {
				setGasLimit(calculateGasMargin(data.request.gasLimit));
				// console.log('data.request.gasLimit', data.request.gasLimit.toString());
				setPrepared(true);
			}
			setError(null);
			setErrMsg(undefined);
		},
		onError(error) {
			setGasLimit(null);
			setError(error);
			setErrMsg(formatError(error, currentChain));
		}
	});

	return {
		gasLimit,
		prepared,
		error,
		errMsg
	};
}
