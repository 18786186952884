import React, { useEffect, useMemo, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { BACKEND_API_SWAGGER } from 'config/constants';

import warningIcon from 'assets/svg/icon-warning.svg';
import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setIsTips } from 'state/liquidity/slice';
import { txBaseState } from 'state/tx/slice';

import Dialog from 'components/Common/Dialog';
import StyledLink from 'components/Common/StyledLink';

const LiquidityFormTips = ({
	onConfirm,
	onClose
}: {
	onConfirm: () => void;
	onClose: () => void;
}) => {
	const dispatch = useAppDispatch();
	const isTips = useAppSelector(state => state.liquidity.isTips);
	const { isMatchMobile } = useAppBreakpoints();
	const onDialogConfirm = () => {
		dispatch(setIsTips(true));
		onConfirm();
		onClose();
	};

	return (
		<Dialog
			open
			onClose={onClose}
			title={
				<Box className='flex items-center'>
					<img className='mr-1 -mb-0.5' width={16} src={warningIcon} alt='' />
					<Typography variant='h5'>
						<Trans>You may lose all your margin</Trans>
					</Typography>
				</Box>
			}
			confirmLabel={t`Continue Adding`}
			onConfirm={onDialogConfirm}
			cancelLabel={t`Cancel`}
			onCancel={onClose}
		>
			<Typography className='mt-1' color='text.secondary'>
				<Trans>
					Only professional market makers are advised to participate. Due to
					frequent severe fluctuations in price and liquidity in the
					cryptocurrency spot market, along with occasional price manipulation
					incidents, you may face the risk of losing all your margin if you lack
					professional risk hedging capabilities.
				</Trans>
			</Typography>
			<Typography className='mt-1' color='text.secondary'>
				<Trans>
					To manage net open position risk, it is recommended to use hedging
					strategies.
				</Trans>
			</Typography>
			<Typography className='mt-1' color='text.secondary'>
				<StyledLink
					type='white'
					href={BACKEND_API_SWAGGER}
					target={isMatchMobile ? '_self' : '_blank'}
					sx={{
						textDecoration: 'underline'
					}}
				>
					<Trans>Please refer to the API documentation &gt;&gt;</Trans>
				</StyledLink>
			</Typography>
		</Dialog>
	);
};

export default LiquidityFormTips;
