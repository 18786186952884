import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import Typography from '@mui/material/Typography';
import { Page } from 'config/constants';

import { isEmpty } from 'lodash';
import ReferralsRanking from 'pages/Referrals/Leaderboard';
import ActivityContainer from 'pages/components/ActivityContainer';
import ReferralsUpdater from 'state/referrals/updater';

import StyledTab from 'components/Common/StyledTab';
import StyledTabs from 'components/Common/StyledTabs';

import MiningRanking from './Mining';
import TradeRanking from './Trade';

export default function Index() {
	const params = useParams();
	const [activeTab, setActiveTab] = useState('trade');
	const navigate = useNavigate();
	useEffect(() => {
		if (isEmpty(params) || !tabs.some(item => item.value === params.type)) {
			return;
		}
		setActiveTab(params.type);
	}, [params]);
	const handleChangeTab = (e, value) => {
		navigate(`${Page.LeaderboardV1}/${value}`);
	};

	return (
		<ActivityContainer
			title={
				<StyledTabs
					value={activeTab}
					onChange={handleChangeTab}
					className='-mx-2'
					size='large'
					variant='scrollable'
					scrollButtons='auto'
					allowScrollButtonsMobile
				>
					<StyledTab label={<Trans>Trade Ranking</Trans>} value='trade' />
					<StyledTab label={<Trans>Mining Ranking</Trans>} value='mining' />
					<StyledTab label={<Trans>Referral Ranking</Trans>} value='referral' />
				</StyledTabs>
			}
			subTitle={null}
		>
			{activeTab === 'trade' ? <TradeRanking /> : null}
			{activeTab === 'mining' ? <MiningRanking /> : null}
			{activeTab === 'referral' ? <ReferralsRanking /> : null}
			<ReferralsUpdater />
		</ActivityContainer>
	);
}

const tabs = [
	{
		label: 'Trade Ranking',
		value: 'trade'
	},
	{
		label: 'Mining Ranking',
		value: 'mining'
	},
	{
		label: 'Referral Ranking',
		value: 'referral'
	}
];
