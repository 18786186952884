import { useState } from 'react';

import { Trans } from '@lingui/macro';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Transaction_Status } from 'config/constants';
import { useAccount } from 'wagmi';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useCheckLogin } from 'hooks/useCurrentChain';
import { earnStakeBaseState } from 'state/earn/stake/slice';
import { useAppSelector } from 'state/hooks';
import { txBaseState } from 'state/tx/slice';
import { IStakeDetail } from 'types';
import { formatNumber, formatUnixDate, shortenSymbol } from 'utils';

import Cell from 'components/Common/Cell';
import { CommonStyledOutlinedButton } from 'components/Common/Styled';
import Module from 'components/Module';
import NoData from 'components/NoData';
import SectionLoading from 'components/SectionLoading';
import Stronger from 'components/Stronger';
import Loading from 'components/Svg/Icons/Loading';

import Table, { HeaderProps } from '../../../components/Table/StickyTable';
import StakeDialog from './StakeDialog';
import UnStakeDialog from './UnStakeModal';
import { RemainingDurationTipping } from './index';

export default function StakeAppTokenList({
	stakedList
}: {
	equAPR: string;
	stakedList: any[];
}) {
	const { quoteToken, appToken } = useAppSelector(txBaseState);
	const { isLoadingStakeList } = useAppSelector(earnStakeBaseState);
	const isLogin = useCheckLogin();
	const theme = useTheme();

	const [stakeOpen, setStakeOpen] = useState(false);
	const [unstakeOpen, setUnstakeOpen] = useState(false);
	const [currentStakeDetail, setCurrentStakeDetail] =
		useState<IStakeDetail | null>(null);

	const { isMatchMobile, isMatchPad } = useAppBreakpoints();
	const { isConnected } = useAccount();

	const columnsForAppToken: Array<HeaderProps> = [
		{
			key: 'stakedAmount',
			width: '20%',
			label: (
				<Typography variant='body2'>
					<Trans>My Staked</Trans>
				</Typography>
			),
			align: 'left',
			format: (row: IStakeDetail) => {
				return (
					<Typography variant='body2' fontWeight={isMatchMobile ? 600 : 400}>
						{`${formatNumber(
							row.stakedAmount,
							appToken.positionUnits
						)} ${shortenSymbol(appToken)}`}
					</Typography>
				);
			}
		},
		{
			key: 'multiplier',
			width: '8%',
			label: <Trans>Multiplier</Trans>,
			align: 'center',
			format: (row: IStakeDetail) => {
				return (
					<Typography variant='body2' fontWeight={400}>
						{row.multiplier}x
					</Typography>
				);
			}
		},
		{
			key: 'claimableReward',
			width: '24%',
			label: <Trans>Claimable Rewards</Trans>,
			align: 'right',
			format: (row: IStakeDetail) => {
				return (
					<Typography variant='body2' fontWeight={400}>{`${formatNumber(
						row.claimableReward
					)} ${shortenSymbol(quoteToken)}`}</Typography>
				);
			}
		},
		{
			key: 'duration',
			width: '28%',
			label: <RemainingDurationTipping />,
			align: 'right',
			format: (row: IStakeDetail) => {
				const _period = row.duration;
				return (
					<Stronger
						value={
							<Typography variant='body2' fontWeight={400}>
								{row.enableUnstake ? (
									<Trans>Unlocking Period</Trans>
								) : _period === 1 ? (
									<Trans>{_period} day</Trans>
								) : (
									<Trans>{_period} days</Trans>
								)}
							</Typography>
						}
						tooltip={
							<div className='space-y-3'>
								<div className='w-56'>
									<Cell
										label={<Trans>Start Time</Trans>}
										value={formatUnixDate(
											row.beginTime,
											'MMM D, YYYY HH:mm:ss'
										)}
									/>
									<Cell
										label={<Trans>End Time</Trans>}
										value={formatUnixDate(row.endTime, 'MMM D, YYYY HH:mm:ss')}
									/>
								</div>
							</div>
						}
					/>
				);
			}
		},
		{
			key: 'operation',
			width: '20%',
			label: (
				<Typography variant='body2'>
					<Trans>Operation</Trans>
				</Typography>
			),
			align: isMatchPad ? 'left' : 'right',
			format: (row: IStakeDetail) => {
				if (row.status === Transaction_Status.Pending) {
					return <Loading size={20} />;
				}
				if (row.enableUnstake) {
					return (
						<Typography
							fontWeight={400}
							component='a'
							className='cursor-pointer'
							color='primary'
							onClick={() => {
								setCurrentStakeDetail(row);
								setUnstakeOpen(true);
							}}
						>
							<Trans>Unstake</Trans>
						</Typography>
					);
				}
				return (
					<Typography color='secondary'>
						<Trans>Locking</Trans>
					</Typography>
				);
			}
		}
	];

	// const filteredColumnsForAppToke = useMemo(() => {
	// 	return isMatchMobile
	// 		? columnsForAppToken.filter(item =>
	// 				['stakedAmount', 'operation'].includes(item.key)
	// 		  )
	// 		: columnsForAppToken;
	// }, [columnsForAppToken, isMatchMobile]);

	return (
		<Paper>
			<Module
				className='md:pt-0'
				title={
					<div className='w-full sm:space-y-4'>
						<div className='flex items-center justify-between space-x-2  w-full'>
							<Typography
								noWrap={true}
								className='flex items-center sm:items-start sm:flex-col sm:space-y-1 flex-1 underLg:text-sm'
							>
								<Typography
									noWrap
									variant={isMatchMobile || isMatchPad ? 'inherit' : 'h6'}
									className='pr-2'
									fontWeight={500}
								>
									Stake {shortenSymbol(appToken)}
								</Typography>
							</Typography>
							<div>
								{isLogin && (
									<CommonStyledOutlinedButton
										variant='outlined'
										color='secondary'
										onClick={() => {
											setStakeOpen(true);
										}}
									>
										Stake {shortenSymbol(appToken)}
									</CommonStyledOutlinedButton>
								)}
							</div>
						</div>
					</div>
				}
			>
				<div>
					{isLogin ? (
						isLoadingStakeList ? (
							<SectionLoading />
						) : stakedList && stakedList.length > 0 ? (
							<div className='mt-4'>
								{isMatchMobile || isMatchPad ? (
									<Box className='-mb-4 grid grid-cols-2 sm:grid-cols-1'>
										{stakedList.map((item: IStakeDetail, index: number) => {
											return (
												<Paper
													key={index}
													className='space-y-1 py-4'
													sx={{
														borderTop: `1px dashed ${theme.palette.divider}`,
														paddingLeft: isMatchMobile
															? 0
															: index % 2 === 0
															? 0
															: '20px',
														paddingRight: isMatchMobile
															? 0
															: index % 2 === 0
															? '20px'
															: 0
													}}
												>
													<div className='flex items-center justify-between'>
														<div>
															{columnsForAppToken
																.find(column => column.key === 'stakedAmount')
																?.format(item)}
														</div>
														<div>
															{columnsForAppToken
																.find(column => column.key === 'operation')
																?.format(item)}
														</div>
													</div>
													{columnsForAppToken
														.filter(
															column =>
																!['stakedAmount', 'operation'].includes(
																	column.key
																)
														)
														.map((column, columnIndex) => {
															return (
																<Cell
																	label={column.label}
																	value={column.format(item)}
																	key={columnIndex}
																/>
															);
														})}
												</Paper>
											);
										})}
									</Box>
								) : (
									<Table
										fontSize={14}
										columns={columnsForAppToken}
										rows={stakedList}
									/>
								)}
							</div>
						) : (
							<NoData height={100} size='large' align='center'>
								<Trans>You haven&#39;t staked yet.</Trans>
							</NoData>
						)
					) : (
						<NoData height={100} size='large' align='center'>
							{!isConnected ? (
								<Trans>Connect your wallet before staking</Trans>
							) : (
								<Trans>Switch your network before staking</Trans>
							)}
						</NoData>
					)}
				</div>
			</Module>

			{stakeOpen && (
				<StakeDialog
					open={true}
					onClose={() => {
						setStakeOpen(false);
					}}
				/>
			)}
			{unstakeOpen && currentStakeDetail && (
				<UnStakeDialog
					item={currentStakeDetail}
					open={true}
					onClose={() => {
						setUnstakeOpen(false);
					}}
				/>
			)}
		</Paper>
	);
}
