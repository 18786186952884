import { useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { Box, Paper, Typography, useTheme } from '@mui/material';
// import Divider from '@mui/material/Divider';
import { APP_TOKEN_SYMBOL } from 'config/constants';

import { useAppBreakpoints } from 'hooks/useAppBreakpoints';
import { useAppSelector } from 'state/hooks';
import { referralBaseState } from 'state/referrals/slice';
import { tradeBaseState } from 'state/trade/slice';
import { txBaseState } from 'state/tx/slice';
import { formatNumber, plus, shortenSymbol } from 'utils';

import { CommonStyledOutlinedButton } from 'components/Common/Styled';
import StyledLink from 'components/Common/StyledLink';
import Module from 'components/Module';
import SectionLoading from 'components/SectionLoading';
import Table from 'components/Table/StickyTable';

import Cell from '../../../components/Common/Cell';
import MintDialog from './MintDialog';
import MintedDetails from './MintedDetailsDialog';

export default function MyConnectorNFT() {
	const [mintDialogIsOpen, setMintDialogIsOpen] = useState(false);
	const [mintedDetailsDialogIsOpen, setMintedDetailsDialog] = useState(false);
	const [currentItem, setCurrentItem] = useState<any | null>(null);
	const [currentChildren, setCurrentChildren] = useState<any | null>(null);
	const theme = useTheme();
	const { isMatchMobile, isMatchPad } = useAppBreakpoints();
	const { quoteToken, appToken } = useAppSelector(txBaseState);
	const { isEnableMining } = useAppSelector(tradeBaseState);
	const { connectorsLoading: loading, connectorsList } =
		useAppSelector(referralBaseState);

	const onOpenMintedHistoryDialog = (row: any) => {
		setCurrentChildren(row.children);
		setMintedDetailsDialog(true);
	};

	const onCloseMintedHistoryDialog = () => {
		setMintedDetailsDialog(false);
	};

	const columns = [
		{
			key: 'connectorNFTID',
			width: '20%',
			label: 'Token ID',
			align: 'left',
			format: (row: any) => {
				return (
					<Typography
						variant='body2'
						fontWeight={400}
					>{`Connector #${row.id}`}</Typography>
				);
			}
		},
		{
			key: 'minted',
			width: '20%',
			label: <Trans>Minted</Trans>,
			align: 'left',
			format: (row: any) => {
				return (
					<Box
						className='flex items-center cursor-pointer'
						sx={{
							'&:hover': {
								color: `${theme.palette.primary.main} !important`
							}
						}}
						onClick={() => onOpenMintedHistoryDialog(row)}
					>
						<Typography variant='body2' fontWeight={400} component='span'>
							{row.children.length}
							<Typography
								variant='body2'
								className='mr-1'
								fontWeight={400}
								component='span'
							>
								/100 &gt;
							</Typography>
						</Typography>
					</Box>
				);
			}
		},
		{
			key: 'totalRevenue',
			width: '25%',
			label: <Trans>Total Rewards</Trans>,
			align: 'left',
			format: (row: any) => {
				return (
					<div className='flex justify-start space-x-2'>
						<Typography variant='body2' fontWeight={400}>{`${formatNumber(
							plus(row.unclaimed, row.claimed)
						)} ${shortenSymbol(quoteToken)}`}</Typography>
						{isEnableMining && (
							<Typography variant='body2' fontWeight={400}>{`${formatNumber(
								plus(
									plus(
										plus(row.liquidityClaimed, row.positionClaimed),
										row.rewardsEQU
									),
									row.newRewardsEQUTotal
								),
								appToken.positionUnits
							)} ${APP_TOKEN_SYMBOL}`}</Typography>
						)}
					</div>
				);
			}
		},
		{
			key: 'referralFeeRewardQuote',
			width: '25%',
			label: <Trans>Claimable Rewards</Trans>,
			align: 'left',
			format: (row: any) => {
				return (
					<div className='flex space-x-2'>
						<Typography variant='body2' fontWeight={400}>{`${formatNumber(
							row.unclaimed
						)} ${shortenSymbol(quoteToken)}`}</Typography>
						{isEnableMining && (
							<Typography variant='body2' fontWeight={400}>{`${formatNumber(
								row.newRewardsEQU,
								appToken.positionUnits
							)} ${APP_TOKEN_SYMBOL}`}</Typography>
						)}
					</div>
				);
			}
		},
		{
			key: 'operate',
			width: '10%',
			label: <Trans>Operation</Trans>,
			align: isMatchPad ? 'left' : 'right',
			format: (row: any) => {
				return (
					<CommonStyledOutlinedButton
						variant='outlined'
						color='secondary'
						disabled={row.children.length === 100}
						onClick={() => onOpenMintDialog(row)}
					>
						<Trans>Mint</Trans>
					</CommonStyledOutlinedButton>
				);
			}
		}
	];

	const mobileColumns = columns.filter(item =>
		['connectorNFTID', 'referralFeeRewardQuote', 'operate'].includes(item.key)
	);

	const onOpenMintDialog = (item: any) => {
		setCurrentItem(item);
		setMintDialogIsOpen(true);
	};
	const onCloseMintDialog = () => {
		setMintDialogIsOpen(false);
	};

	return (
		<Module
			title={!isMatchMobile && !isMatchPad && <Trans>My Connector NFT</Trans>}
			className='underLg:-mx-3 underLg:pt-0 underLg:pb-2'
		>
			{loading ? (
				<SectionLoading />
			) : connectorsList && connectorsList.length > 0 ? (
				<Table
					columns={isMatchMobile ? mobileColumns : columns}
					rows={connectorsList}
					rowsRender={
						<div className='grid grid-cols-2 sm:grid-cols-1 gap-2'>
							{connectorsList.map((row, index) => {
								return (
									<Paper key={index} className='py-4 px-3'>
										<div className='flex items-center justify-between mb-1'>
											<Typography variant='h6' fontSize={14} fontWeight={500}>
												{`Connector #${row.id}`}
											</Typography>
											<CommonStyledOutlinedButton
												variant='outlined'
												color='secondary'
												disabled={row.children.length === 100}
												onClick={() => onOpenMintDialog(row)}
											>
												<Trans>Mint</Trans>
											</CommonStyledOutlinedButton>
										</div>
										<Cell
											label={t`Minted`}
											value={
												<Box
													className='flex items-center cursor-pointer'
													sx={{
														'&:hover': {
															color: `${theme.palette.primary.main} !important`
														}
													}}
													onClick={() => onOpenMintedHistoryDialog(row)}
												>
													<Typography variant='body2' fontWeight={400}>
														{row?.children.length}
													</Typography>
													<Typography variant='body2' fontWeight={400}>
														/100
													</Typography>
													&gt;
												</Box>
											}
										/>
										<Cell
											label={t`Claimable`}
											value={
												<div className='flex space-x-2'>
													<Typography variant='body2'>{`${formatNumber(
														row.unclaimed
													)} ${shortenSymbol(quoteToken)}`}</Typography>
													{isEnableMining && (
														<Typography variant='body2'>{`${formatNumber(
															row.newRewardsEQU,
															appToken.positionUnits
														)} ${APP_TOKEN_SYMBOL}`}</Typography>
													)}
												</div>
											}
										/>
										<Cell
											label={t`Total Rewards`}
											value={
												<div className='flex justify-start space-x-2'>
													<Typography
														variant='body2'
														fontWeight={400}
													>{`${formatNumber(
														plus(row.unclaimed, row.claimed)
													)} ${shortenSymbol(quoteToken)}`}</Typography>
													{isEnableMining && (
														<Typography
															variant='body2'
															fontWeight={400}
														>{`${formatNumber(
															plus(
																plus(
																	plus(
																		row.liquidityClaimed,
																		row.positionClaimed
																	),
																	row.rewardsEQU
																),
																row.newRewardsEQUTotal
															),
															appToken.positionUnits
														)} ${APP_TOKEN_SYMBOL}`}</Typography>
													)}
												</div>
											}
										/>
									</Paper>
								);
							})}
						</div>
					}
				/>
			) : (
				<Box className='w-[558px] mx-auto mt-6 text-center sm:w-full sm:text-left space-y-4'>
					<Typography variant='h6' className='font-normal flex space-x-1'>
						<span>{t`You don't have a Connector NFT yet. Go to`}</span>
						<StyledLink
							target={isMatchMobile ? '_self' : '_blank'}
							href='https://opensea.io'
							type='white'
						>
							Opensea
						</StyledLink>
						<span>{t`to grab one now.`}</span>
					</Typography>
					<section className='space-y-1'>
						<Typography variant='body2' color='secondary'>
							{t`1. Obtain Connector NFT.`}
						</Typography>
						<Typography variant='body2' color='secondary'>
							{t`2. Mint Member NFTs and distribute them to friends.`}
						</Typography>
						<Typography variant='body2' color='secondary'>
							{t`3. Enjoy 10% of the total income from minted Member NFTs.`}
						</Typography>
					</section>
				</Box>
			)}
			{mintDialogIsOpen && currentItem && (
				<MintDialog item={currentItem} onClose={onCloseMintDialog} />
			)}
			{mintedDetailsDialogIsOpen && currentChildren && (
				<MintedDetails
					childrens={currentChildren}
					onClose={onCloseMintedHistoryDialog}
				/>
			)}
		</Module>
	);
}
