/* eslint-disable react/prop-types */
import { forwardRef, useState } from 'react';

import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { Avatar, Box, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover/Popover';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import { LOCALES } from 'config/constants/locales';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setActiveLocale, settingBaseState } from 'state/setting/slice';

const LanguagePopover = forwardRef(function LanguagePopover(props: any, ref) {
	const { activeLocale } = useAppSelector(settingBaseState);
	const dispatch = useAppDispatch();

	const [showList, setShowList] = useState(false);
	const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
	const onOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		setShowList(true);
		event && event?.currentTarget && setAnchorEl(event.currentTarget);
	};
	const theme = useTheme();
	const onSelectLanguage = (locale: string) => {
		dispatch(setActiveLocale(locale));
		setShowList(false);
	};

	return (
		<Box
			sx={{
				padding: '4px',
				borderRadius: '4px',
				'&:hover': {
					textDecoration: 'none',
					backgroundColor: theme.palette.action.hover
				}
			}}
		>
			<Box className='flex cursor-pointer justify-between' onClick={onOpen}>
				<svg
					width='20'
					height='20'
					viewBox='0 0 20 20'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<g clipPath='url(#clip0_16159_30046)'>
						<path
							d='M10.0002 0.833496C15.0628 0.833496 19.1668 4.93749 19.1668 10.0002C19.1668 15.0628 15.0628 19.1668 10.0002 19.1668C4.93749 19.1668 0.833496 15.0628 0.833496 10.0002C0.833496 4.93749 4.93749 0.833496 10.0002 0.833496ZM13.0393 10.7641H6.96103C7.12985 14.6633 8.56902 17.6391 10.0002 17.6391C11.4309 17.6391 12.8705 14.6633 13.0393 10.7641ZM5.43173 10.7641L2.39909 10.7644C2.67943 13.5855 4.49443 15.9551 6.9977 17.0264C6.09746 15.4914 5.52836 13.2604 5.43173 10.7644V10.7641ZM17.6012 10.7644H14.5686C14.472 13.2608 13.9029 15.4914 13.0026 17.026C15.5059 15.9554 17.3209 13.5855 17.6012 10.7644ZM6.99808 2.97429L6.91329 3.01096C4.45318 4.09874 2.676 6.44693 2.39947 9.23627H5.43211C5.52874 6.73989 6.09784 4.50895 6.99808 2.97429ZM10.0002 2.36127L9.94287 2.3628C8.5312 2.44072 7.12794 5.38933 6.96103 9.23627H13.0393C12.8705 5.33739 11.4313 2.36127 10.0002 2.36127ZM13.0026 2.97391L13.0588 3.07131C13.9266 4.60214 14.4739 6.79183 14.5682 9.23627H17.6009C17.3209 6.41485 15.5055 4.04527 13.0022 2.97391H13.0026Z'
							fill={theme.palette.text.primary}
							fillOpacity='0.9'
						/>
					</g>
					<defs>
						<clipPath id='clip0_16159_30046'>
							<rect width='20' height='20' fill={theme.palette.text.primary} />
						</clipPath>
					</defs>
				</svg>
			</Box>
			<Popover
				open={showList}
				anchorEl={anchorEl}
				className='translate-y-2 transform'
				onClose={() => setShowList(false)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
			>
				<Box>
					{LOCALES.map(locale => {
						return (
							<MenuItem
								className='flex cursor-pointer items-center justify-between'
								component='div'
								key={locale.key}
								onClick={() => onSelectLanguage(locale.key)}
							>
								<Box className='flex items-center justify-center text-sm  pl-2'>
									<Typography
										variant='inherit'
										color={
											locale.key === activeLocale
												? 'text.primary'
												: 'text.secondary'
										}
									>
										{locale.name}
									</Typography>
								</Box>
								<DoneRoundedIcon
									fontSize='small'
									className={classNames('mr-1 font-bold', {
										'opacity-0': locale.key !== activeLocale
									})}
									sx={{ color: theme.palette.primary.main }}
								/>
							</MenuItem>
						);
					})}
				</Box>
			</Popover>
		</Box>
	);
});

export default LanguagePopover;
