import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { App_Theme } from 'config/constants';

import { globalBaseState, setTheme } from 'state/global/slice';
import { useAppDispatch, useAppSelector } from 'state/hooks';

const StyleSwitch = styled(Switch)(({ theme }) => ({
	width: 44,
	height: 24,
	padding: 0,
	'& .MuiSwitch-switchBase': {
		margin: 2,
		padding: 0,
		// transform: 'translateX(6px)',
		'&.Mui-checked': {
			color: '#5E6168',
			transform: 'translateX(20px)',
			'& .MuiSwitch-thumb:before': {
				backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 18 18"><path fill="${encodeURIComponent(
					'#fff'
				)}" d="M11.9633 14.2046C13.397 13.3982 14.4833 12.1034 14.9953 10.5908C15.5072 9.07822 15.4051 7.46485 14.7104 6.08777C14.0157 4.7107 12.7821 3.67644 11.2672 3.20109C9.75242 2.72574 8.07361 2.84606 6.58155 3.53693C6.48127 3.59972 6.40074 3.68875 6.35006 3.79287C6.29937 3.89699 6.28077 4.01158 6.29659 4.1223C6.31241 4.23302 6.36194 4.33496 6.43899 4.41535C6.51604 4.49574 6.61718 4.55102 6.72976 4.57427C7.23423 4.67738 7.70541 4.88162 8.11369 5.17415C8.52198 5.46669 8.85856 5.8412 9.10229 6.27418C9.58126 7.12034 9.68207 8.1348 9.38254 9.09439C9.08301 10.054 8.40768 10.8801 7.50511 11.391C7.04611 11.6514 6.54185 11.8207 6.02358 11.8883C5.50531 11.9559 4.98408 11.9204 4.49221 11.784C4.38383 11.7542 4.26708 11.7558 4.15606 11.7888C4.04504 11.8218 3.94451 11.8847 3.86661 11.9699C3.78872 12.0551 3.7368 12.159 3.71713 12.269C3.69746 12.379 3.71088 12.4904 3.75577 12.5898C4.61126 13.8144 5.92792 14.6592 7.44679 14.9581C8.96565 15.2569 10.5768 14.9881 11.9633 14.2046Z"/></svg>')`
			},
			'& + .MuiSwitch-track': {
				opacity: 1,
				backgroundColor:
					theme.palette.mode === 'dark'
						? 'rgba(255, 255, 255, 0.10)'
						: 'rgba(0, 0, 0, 0.20)'
			}
		}
	},
	'& .MuiSwitch-thumb': {
		// backgroundColor: theme.palette.mode === 'dark' ? '#5E6168' : '#FFFFF',
		width: 20,
		height: 20,
		'&:before': {
			content: "''",
			position: 'absolute',
			width: '100%',
			height: '100%',
			left: 0,
			top: 0,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="19" width="20" viewBox="0 0 19 20"><path fill="${encodeURIComponent(
				'#000'
			)}" d="M12.2408 6.16474L13.276 5.12957L14.1384 5.99206L13.1033 7.02723L12.2408 6.16474ZM13.8512 9.44195H15.5762V10.5916H13.8512V9.44195ZM8.67642 3.75H9.82607V5.41686H8.67642V3.75ZM4.36405 5.99206L5.22651 5.12957L6.2617 6.16474L5.45645 6.96991L4.36405 5.99206ZM6.2617 13.8697L5.22651 14.904L4.42048 14.0996L5.45645 13.0645L6.2617 13.8697ZM4.65128 10.5916H2.9263V9.44195H4.65128V10.5916ZM9.2508 6.56649C11.1484 6.56649 12.7007 8.11962 12.7007 10.0164C12.7007 11.9139 11.1484 13.4662 9.2508 13.4662C7.35321 13.4662 5.80096 11.9139 5.80096 10.0164C5.80096 8.11962 7.35321 6.56649 9.2508 6.56649ZM9.82607 16.2844H8.67642V14.6167H9.82607V16.2844ZM14.1384 14.0415L13.276 14.904L12.2408 13.8696L13.0452 13.0645L14.1384 14.0415Z"/></svg>')`
		}
	},
	'& .MuiSwitch-track': {
		opacity: 1,
		backgroundColor:
			theme.palette.mode === 'dark'
				? 'rgba(255, 255, 255, 0.10)'
				: 'rgba(0, 0, 0, 0.20)',
		borderRadius: 24 / 2
	}
}));

export default function ThemeButton() {
	const { theme } = useAppSelector(globalBaseState);
	const dispatch = useAppDispatch();

	const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setTheme(event.target.checked ? App_Theme.dark : App_Theme.light));
	};

	return (
		<StyleSwitch checked={theme === App_Theme.dark} onChange={onHandleChange} />
	);
}
