import Tabs, { TabsProps } from '@mui/material/Tabs';
import { styled, alpha } from '@mui/material/styles';
import StyledChip from 'components/Common/StyledChip';

interface TabsExtendProps extends TabsProps {
	size?: string;
}

const StyledTabs = styled((props: TabsExtendProps) => {
	const { size, ...rest } = props;
	return (
		<Tabs
			{...rest}
			TabIndicatorProps={{
				children: <span className='MuiTabs-indicatorSpan' />
			}}
		/>
	);
})(({ theme, size = 'small' }) => {
	return {
		position: 'relative',
		'& .MuiTab-root': {
			fontSize: size === 'large' ? '18px' : '16px',
			marginRight: size === 'large' ? 12 : '',
			'&.Mui-selected': {
				color: theme.palette.primary.main
			}
		},
		'& .MuiTabs-indicator': {
			display: 'flex',
			justifyContent: 'center',
			backgroundColor: 'transparent'
		},
		'& .MuiTabs-indicatorSpan': {
			display: 'none',
			width: '40%',
			backgroundColor: theme.palette.text.primary
		},
		'& .MuiSvgIcon-root': {
			fontSize: 20
		},
		'& .MuiTabs-scroller': {
			margin: 0,
		},
		'& .MuiTabs-scrollButtons': {
			width: `24px !important`,
			position: 'absolute',
			zIndex: 9,
			'&:first-of-type': {
				left: '-9px',
				top: '5px',
				background: `linear-gradient(90deg, ${theme.mode === 'dark' ?  theme.palette.background.default : theme.palette.background.default} 16.15%, ${theme.mode === 'dark' ?  alpha(theme.palette.background.default, 0.6) : alpha(theme.palette.background.default, 0.6)} 100%)`
			},
			'&:last-of-type': {
				right: '-9px',
				top: '5px',
				background: `linear-gradient(270deg, ${theme.mode === 'dark' ?  theme.palette.background.default : theme.palette.background.default} 16.15%, ${theme.mode === 'dark' ?  alpha(theme.palette.background.default, 0.6) : alpha(theme.palette.background.default, 0.6)} 100%)`
			}
		},
		'.MuiTabs-scrollButtons.Mui-disabled': {
			display: 'none'
		}
	};
});

export default StyledTabs;
