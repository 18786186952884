import { useMemo } from 'react';

import { EFCABI } from 'config/abis';
import { Contract_Write_Mode, Transaction_Type } from 'config/constants';
import { getContractAddress } from 'config/contracts';
import { UseContractWriteConfig, UsePrepareContractWriteConfig } from 'wagmi';

import { TransactionDescription } from 'entities/TransactionDescription';
import { checkInputReferralCode } from 'utils';

import { useComputeGasLimit } from './useComputeGasLimit';
import { useCurrentChain } from './useCurrentChain';
import { useSendTransaction } from './useSendTransaction';
import { RecordForBindReferralCode } from 'types';

export function useSubmitBindReferralCode(code: string) {
	const { currentChainId } = useCurrentChain();

	const { args, record, description } = useMemo(() => {
		if (!checkInputReferralCode(code)) {
			return {
				args: undefined,
				record: null,
				description: ''
			};
		}

		console.log('useSubmitBindReferralCode args begin ...');

		const args = [code];

		const record = {
			transactionType: Transaction_Type.BindReferralCode,
			code
		} as RecordForBindReferralCode;
		const description = TransactionDescription.BindReferralCode(code);

		return {
			args,
			record,
			description
		};
	}, [code]);

	const contractWriteArgs = useMemo(() => {
		if (!args) {
			return null;
		}
		return {
			mode: Contract_Write_Mode.Unprepared,
			address: getContractAddress(currentChainId, 'EFC'),
			abi: EFCABI,
			functionName: 'bindCode',
			args
		} as UseContractWriteConfig;
	}, [currentChainId, args]);

	const { gasLimit } = useComputeGasLimit(
		contractWriteArgs as unknown as UsePrepareContractWriteConfig
	);

	const { onSendTransaction, isConfirmed, isConfirming } = useSendTransaction(
		contractWriteArgs,
		gasLimit
	);

	const onConfirm = () => {
		if (!record) return;
		onSendTransaction(record, description);
	};

	return {
		onConfirm,
		isConfirming,
		isConfirmed
	};
}
